import React from "react";
import ReactLoading from "react-loading";

import "../../css/main.css";

function CustomGridLoading({ loading, type, color, loadingMessage }) {
    const cType = type ? type : "bubbles";
    const cColor = color ? color : "#8f9cf4";
    return (
        <>
            <ReactLoading id={"gridloading"} type={cType} color={cColor} />
            {/* <p className="loadingMessage"> {loadingMessage} </p> */}
            <p><i className="fa fa-hourglass-half" aria-hidden="true" style={{ color: "rgb(65, 68, 75)" }}></i>{"　" + loadingMessage} </p>

        </>
    );
}
export default CustomGridLoading;
