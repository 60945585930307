import React, { useState, useEffect, useRef } from 'react';
import 'react-dyn-tabs/style/react-dyn-tabs.min.css';
import 'react-dyn-tabs/themes/react-dyn-tabs-bootstrap.css';
//import 'react-dyn-tabs/themes/react-dyn-tabs-basic.css';
//import 'react-dyn-tabs/themes/react-dyn-tabs-bootstrap.min.css';
import useDynTabs from 'react-dyn-tabs';
import '../../css/main.css';
// import * as Common from '@/utils/Common';

import { useHistory } from 'react-router-dom';

/* 🔸🔸🔸 납입지시 🔸🔸🔸 */
/*➖➖🔻기초관리(A01)🔻➖➖*/
import REP02 from '@/pages/A01/REP02'; //자료생성(수동))(✔️)
import ROM4L from '@/pages/A01/ROM4L'; //ERP_품목관리)(✔️)
import ROM4A from '@/pages/A01/ROM4A'; //용기당수량관리(❌)
import ROM13 from '@/pages/A01/ROM13'; //T2업체 품목관리(✔️)
import ROM12 from '@/pages/A01/ROM12'; //차량번호관리(✔️)
import ROM72 from '@/pages/A01/ROM72'; //단가변동현황(✔️)
/*➖➖🔻ERP_OEM 출하관리(A02)🔻➖➖*/
import ROM3H from '@/pages/A02/ROM3H'; //납품서발행(VMI)(✔️)
import ROM3L from '@/pages/A02/ROM3L'; //납품서발행(PO)(✔️)
import ROM3J from '@/pages/A02/ROM3J'; //발행취소/재인쇄(✔️)
import ROM4H from '@/pages/A02/ROM4H'; //부품식별표(VMI)(✔️)
import ROM4N from '@/pages/A02/ROM4N'; //부품식별표(PO)(✔️)
import ROM4P from '@/pages/A02/ROM4P'; //출하정보전송(✔️)
import ROM4V from '@/pages/A02/ROM4V'; //부품식별표(KIA 2D)(✔️)
import ROM3T from '@/pages/A02/ROM3T'; //납품서삭제내역(✔️)
import ROM3V from '@/pages/A02/ROM3V'; //백지카드 발행(✔️)
import REX3I from '@/pages/A02/REX3I'; //납품서 일괄발행(VMI)(✔️)
/*➖➖🔻ERP_CKD 출하관리(A03)🔻➖➖*/
import ROM3I from '@/pages/A03/ROM3I'; //납품서발행(✔️)
import ROM3K from '@/pages/A03/ROM3K'; //발행취소/재인쇄(✔️)
import ROM4K from '@/pages/A03/ROM4K'; //부품식별표(중포장)(✔️)
import ROM4R from '@/pages/A03/ROM4R'; //부품식별표(용기)(✔️)
import ROM4Q from '@/pages/A03/ROM4Q'; //출하정보전송(✔️)
import ROM4T from '@/pages/A03/ROM4T'; //납품서삭제내역(✔️)
import ROMAA from '@/pages/A03/ROMAA'; //납품서그룹발행(✔️)
import ROMAB from '@/pages/A03/ROMAB'; //그룹 발행취소/재인쇄(✔️)
/*➖➖🔻시작차 출하관리(A04)🔻➖➖*/
import ROM81 from '@/pages/A04/ROM81'; //납품서발행(✔️)
import ROM82 from '@/pages/A04/ROM82'; //발행취소/재인쇄(✔️)
import ROM84 from '@/pages/A04/ROM84'; //부품식별표(✔️)
import ROM85 from '@/pages/A04/ROM85'; //시작차 입고현황(✔️)
import ROM86 from '@/pages/A04/ROM86'; //시작차 발주현황(✔️)
/*➖➖🔻현황관리(A05)🔻➖➖*/
import ROM3Z from '@/pages/A05/ROM3Z'; //납품서 발행현황(✔️)
import ROM3Y from '@/pages/A05/ROM3Y'; //PO 미닙현황조회(✔️)
import ROM3W from '@/pages/A05/ROM3W'; //CKD 미납현황조회(✔️)
import ROM90 from '@/pages/A05/ROM90'; //매출현황(✔️)
import ROM91 from '@/pages/A05/ROM91'; //검수현황(✔️)
import ROM92 from '@/pages/A05/ROM92'; //납품대비검수현황(✔️)
/*➖➖🔻협력사재고관리(A07)🔻➖➖*/
import ROM77 from '@/pages/A07/ROM77'; //대상품목입력(✔️)
import ROM78 from '@/pages/A07/ROM78'; //협력사재고입력/전송(✔️)
import ROM79 from '@/pages/A07/ROM79'; //협력사재고전송현황(✔️)

/* 🔸🔸🔸 수신자료관리 🔸🔸🔸 */
/*➖➖🔻기초관리(B01)🔻➖➖*/
import REP01 from '@/pages/B01/REP01'; //자료생성(수동)(✔️)
import RNT30 from '@/pages/B01/RNT30'; //Body seq 219 코드등록(✔️)
/*➖➖🔻ERP_자재정보조회(B02)🔻➖➖*/
import REPD0 from '@/pages/B02/REPD0'; // [ERPD0]품목정보(✔️)
import REPD1 from '@/pages/B02/REPD1'; // [ERPD1]일검수통보(✔️)
import REPD2 from '@/pages/B02/REPD2'; // [ERPD2]주간소요량(✔️)
import REPD3 from '@/pages/B02/REPD3'; // [ERPD3]주간소요량Line별(✔️)
import REPD9 from '@/pages/B02/REPD9'; // [ERPD9]월검수집계(✔️)
import REPDH from '@/pages/B02/REPDH'; // [ERPDH]HPC-Code 기초정보(✔️)
import RKDD0 from '@/pages/B02/RKDD0'; // [CKDD0]품목정보(✔️)
import RKDD1 from '@/pages/B02/RKDD1'; // [CKDD1]일검수통보(✔️)
import RKDD2 from '@/pages/B02/RKDD2'; // [CKDD2]주간소요량_발주(✔️)
import RKDD9 from '@/pages/B02/RKDD9'; // [CKDD9]월검수집계(✔️)
/*➖➖🔻ERP_생산정보조회(B03)🔻➖➖*/
import REPPA from '@/pages/B03/REPPA'; // [PA] BODY_SEQ_219(✔️)
import REPP0 from '@/pages/B03/REPP0'; // [PO]BODY투입계획_상세(✔️)
import REPP2 from '@/pages/B03/REPP2'; // [P2]BODY투입계획_종합(✔️)
import REPP4 from '@/pages/B03/REPP4'; // [P4]TRIM투입계획(✔️)
import REPP6 from '@/pages/B03/REPP6'; // [P6]서열부품집계_상세(✔️)
import REPP7 from '@/pages/B03/REPP7'; // [P7]서열부품집계_종합(✔️)
import REPP8 from '@/pages/B03/REPP8'; // [P8]모듈부품집계_상세(✔️)
import REPP9 from '@/pages/B03/REPP9'; // [P9]모듈부품집계_종합(✔️)
import REPPC from '@/pages/B03/REPPC'; // [PC]ALC_Ⅱ계획현황_상세(✔️)
import REPPD from '@/pages/B03/REPPD'; // [PD]ALC_Ⅱ계획현황_종합(✔️)
import REPPI from '@/pages/B03/REPPI'; // [P]WIRE서열계_상세(✔️)
import REPPJ from '@/pages/B03/REPPJ'; // [P]WIRE서열계_종합(✔️)
import REPPS from '@/pages/B03/REPPS'; // [PS]서열종합(+28)(✔️)

import RNTM1 from '@/pages/B04/RNTM1'; //모듈BOM (❌)
/*➖➖🔻CLAIM정보자료조회(B05)🔻➖➖*/ 
import RNTW0 from '@/pages/B05/RNTW0'; //[W1] 클래임(✔️)
/*➖➖🔻ERP_모듈정보조회(B06)🔻➖➖*/  
import RNTC8 from '@/pages/B06/RNTC8'; //[C1]모듈단가현황(✔️)
import RNTC9 from '@/pages/B06/RNTC9'; //[C2]모듈단가상세내역(✔️)
import RNTC10 from '@/pages/B06/RNTC10'; //[C3]모듈별정산현황(✔️)
import RNTC11 from '@/pages/B06/RNTC11'; //[C4]사양차이정산상세(✔️)
import RNTC12 from '@/pages/B06/RNTC12'; // [C5]단가차이정산상세(✔️)
import RNTC13 from '@/pages/B06/RNTC13'; // [C6]ZERO단가조건상세(✔️)
import RNTC14 from '@/pages/B06/RNTC14'; // [C7]정단가조건정산상세(✔️)

/* 🔸🔸🔸 시스템관리 🔸🔸🔸 */
/*➖➖🔻시스템관리(C01)🔻➖➖*/
import RSY10 from '@/pages/C01/RSY10'; //코드관리(✔️)
import RSY12 from '@/pages/C01/RSY12'; //업체관리(✔️)
import RSY11 from '@/pages/C01/RSY11'; //사용자관리(✔️)
/*➖➖🔻운영관리(C02)🔻➖➖*/ 
import RSY31 from '@/pages/C02/RSY31'; //프로그램등록관리(✔️)
import RSY32 from '@/pages/C02/RSY32'; //사용현황(✔️)
import RSY40 from '@/pages/C02/RSY40'; //납품서 이력현황(✔️)
import RSY41 from '@/pages/C02/RSY41'; //납품서현황(✔️)
import RSY42 from '@/pages/C02/RSY42'; //출하전송현황(✔️)
import RSY43 from '@/pages/C02/RSY43'; //상단공지(✔️)
import RSY44 from '@/pages/C02/RSY44'; //팝업관리(✔️)

/* 🔸🔸🔸 고객지원 🔸🔸🔸 */
/*➖➖🔻커뮤니티(D01)🔻➖➖*/
import RSY50 from '@/pages/D01/RSY50'; //공지사항(✔️)
import RSY51 from '@/pages/D01/RSY51'; //QnA(✔️)
import RSY52 from '@/pages/D01/RSY52'; //FAQ(✔️)
import GNGS from '@/pages/D01/GNGS'; //기능개선 요청/제안(✔️)


let current = '';
let previous = '';

// const master = Common.getToken('masterKey');

function TabPage({ props, location, panel }) {
	const history = useHistory();
	let _instance, isVertical;

	const [Tablist, Panellist, ready] = useDynTabs({
		onChange: function ({ currentData, previousData, closedTabIDs, openedTabIDs }) {
			current = currentData.selectedTabID;
			previous = previousData.selectedTabID;

			if (currentData.selectedTabID != '') {
				if (current == previous) {
					history.push(history.location.pathname);
				} else {
					history.push(currentData.selectedTabID);
				}
			}
		},
		//   tabs: [
		//     {id: 'clsop', title: '모든 탭 닫기', iconClass: 'fa fa-home', closable: true},
		//   ],
	});

	const getTitle = (path) => {
		switch (path) {
			case 'REP02':
				return '자료생성(수동)';
			case 'ROM4L':
				return 'ERP_품목관리';
			case 'ROM4A':
				return '용기당수량관리';
			case 'ROM13':
				return 'T2업체품목관리';
			case 'ROM12':
				return '차량번호관리';
			case 'ROM72':
				return '단가변동현황';
			case 'ROM3H':
				return '납품서발행(VMI)';
			case 'ROM3L':
				return '납품서발행(PO)';
			case 'ROM3J':
				return '발행취소/재인쇄';
			case 'ROM4H':
				return '부품식별표(VMI)';
			case 'ROM4N':
				return '부품식별표(PO)';
			case 'ROM4P':
				return '출하정보전송';
			case 'ROM4V':
				return '부품식별표(KIA 2D)';
			case 'ROM3T':
				return '납품서삭제내역';
			case 'ROM3V':
				return '백지카드 발행';
			case 'REX3I':
				return '납품서 일괄발행(VMI)';
			case 'ROM3I':
				return '납품서발행(CKD)';
			case 'ROM3K':
				return '발행취소/재인쇄(CKD)';
			case 'ROM4K':
				return '부품식별표(중포장)';
			case 'ROM4R':
				return '부품식별표(용기)';
			// case 'ROM4X':
			//     return '부품식별표(중포장2D)';
			case 'ROM4Q':
				return '출하정보전송(CKD)';
			case 'ROM4T':
				return '납품서 삭제내역(CKD)';
			case 'ROMAA':
				return '납품서 그룹발행(CKD)';
			case 'ROMAB':
				return '납품서 발행취소/재인쇄(CKD)';
			case 'ROM81':
				return '납품서발행';
			case 'ROM82':
				return '발행취소/재인쇄';
			case 'ROM84':
				return '부품식별표';
			case 'ROM85':
				return '시작차 입고현황';
			case 'ROM86':
				return '시작차 발주현황';
			case 'ROM3Z':
				return '납품서 발행현황';
			case 'ROM3Y':
				return 'PO미납현황조회';
			case 'ROM3W':
				return 'CKD미납현황조회';
			case 'ROM90':
				return '매출현황';
			case 'ROM91':
				return '검수현황';
			case 'ROM92':
				return '납품대비검수현황';
			case 'ROM77':
				return '대상품목입력';
			case 'ROM78':
				return '협력사재고입력/전송';
			case 'ROM79':
				return '협력사재고전송현황';
			case 'REP01':
				return '자료생성(수동)';
			case 'RNT30':
				return 'BodySeq219코드등록';
			case 'REPD0':
				return 'EPRD0품목정보';
			case 'REPD1':
				return 'EPRD1일검수통보';
			case 'REPD2':
				return 'EPRD2주간소요량';
			case 'REPD3':
				return 'EPRD3주간소요량line별';
			case 'REPD9':
				return 'EPRD9월검수집계';
			case 'REPDH':
				return 'EPRDH HPC-Code기초정보';
			case 'RKDD0':
				return 'CKDD0품목정보';
			case 'RKDD1':
				return 'CKDD1일검수통보';
			case 'RKDD2':
				return 'CKDD2주간소요량_발주';
			case 'RKDD9':
				return 'CKDD9월검수집계';
			case 'REPPA':
				return 'BODY_SEQ_219';
			case 'REPP0':
				return 'BODY투입계획_상세';
			case 'REPP2':
				return 'BODY투입계획_종합';
			case 'REPP4':
				return 'TRIM투입계획';
			case 'REPP6':
				return '서열부품집계_상세';
			case 'REPP7':
				return '서열부품집계_종합';
			case 'REPP8':
				return '모듈부품집계_상세';
			case 'REPP9':
				return '모듈부품집계_종합';
			case 'REPPC':
				return 'ALC_Ⅱ계획현황_상세';
			case 'REPPD':
				return 'ALC_Ⅱ계획현황_종합';
			case 'REPPI':
				return 'WIRE서열계_상세';
			case 'REPPJ':
				return 'WIRE서열계_종합';
			case 'REPPS':
				return '서열종합(+28)';
			case 'RNTM1':
				return '모듈BOM';
			case 'RNTW0':
				return '클래임';
			case 'RNTC8':
				return '모듈별단가현황';
			case 'RNTC9':
				return '모듈단가상세내역';
			case 'RNTC10':
				return '모듈별정산현황';
			case 'RNTC11':
				return '사양차이정산상세';
			case 'RNTC12':
				return '단가차이정산상세';
			case 'RNTC13':
				return 'ZERO단가정산상세';
			case 'RNTC14':
				return '정단가조건정산상세';
			case 'RSY10':
				return '코드관리';
			case 'RSY11':
				return '사용자관리';
			case 'RSY12':
				return '업체관리';
			case 'RSY31':
				return '프로그램 등록화면';
			case 'RSY32':
				return '사용현황';
			case 'RSY40':
				return '납품서 이력현황';
			case 'RSY41':
				return '납품서현황';
			case 'RSY42':
				return '출하전송 현황';
			case 'RSY43':
				return '상단공지';
			case 'RSY50':
				return '공지사항';
			case 'RSY51':
				return 'QnA';
			case 'RSY52':
				return 'FAQ';
			case 'GNGS':
				return '기능개선 요청/제안';
			case 'MyInf':
				return '내정보';
			case 'RSY44':
				return '팝업관리';
		}
	};
	const getPanel = (path) => {
		switch (path) {
			case 'REP02':
				return REP02;
			case 'ROM4L':
				return ROM4L;
			case 'ROM4A':
				return ROM4A;
			case 'ROM13':
				return ROM13;
			case 'ROM12':
				return ROM12;
			case 'ROM72':
				return ROM72;
			case 'ROM3H':
				return ROM3H;
			case 'ROM3L':
				return ROM3L;
			case 'ROM3J':
				return ROM3J;
			case 'ROM4H':
				return ROM4H;
			case 'ROM4N':
				return ROM4N;
			case 'ROM4P':
				return ROM4P;
			case 'ROM4V':
				return ROM4V;
			case 'ROM3T':
				return ROM3T;
			case 'ROM3V':
				return ROM3V;
			case 'REX3I':
				return REX3I;
			case 'ROM3I':
				return ROM3I;
			case 'ROM3K':
				return ROM3K;
			case 'ROM4K':
				return ROM4K;
			case 'ROM4R':
				return ROM4R;
			// case 'ROM4X':
			//     return ROM4X;
			case 'ROM4Q':
				return ROM4Q;
			case 'ROM4T':
				return ROM4T;
			case 'ROMAA':
				return ROMAA;
			case 'ROMAB':
				return ROMAB;
			case 'ROM81':
				return ROM81;
			case 'ROM82':
				return ROM82;
			case 'ROM84':
				return ROM84;
			case 'ROM85':
				return ROM85;
			case 'ROM86':
				return ROM86;
			case 'ROM3Z':
				return ROM3Z;
			case 'ROM3Y':
				return ROM3Y;
			case 'ROM3W':
				return ROM3W;
			case 'ROM90':
				return ROM90;
			case 'ROM91':
				return ROM91;
			case 'ROM92':
				return ROM92;
			case 'ROM77':
				return ROM77;
			case 'ROM78':
				return ROM78;
			case 'ROM79':
				return ROM79;
			case 'REP01':
				return REP01;
			case 'RNT30':
				return RNT30;
			case 'REPD0':
				return REPD0;
			case 'REPD1':
				return REPD1;
			case 'REPD2':
				return REPD2;
			case 'REPD3':
				return REPD3;
			case 'REPD9':
				return REPD9;
			case 'REPDH':
				return REPDH;
			case 'RKDD0':
				return RKDD0;
			case 'RKDD1':
				return RKDD1;
			case 'RKDD2':
				return RKDD2;
			case 'RKDD9':
				return RKDD9;
			case 'REPPA':
				return REPPA;
			case 'REPP0':
				return REPP0;
			case 'REPP2':
				return REPP2;
			case 'REPP4':
				return REPP4;
			case 'REPP6':
				return REPP6;
			case 'REPP7':
				return REPP7;
			case 'REPP8':
				return REPP8;
			case 'REPP9':
				return REPP9;
			case 'REPPC':
				return REPPC;
			case 'REPPD':
				return REPPD;
			case 'REPPI':
				return REPPI;
			case 'REPPJ':
				return REPPJ;
			case 'REPPS':
				return REPPS;
			case 'RNTM1':
				return RNTM1;
			case 'RNTW0':
				return RNTW0;
			case 'RNTC8':
				return RNTC8;
			case 'RNTC9':
				return RNTC9;
			case 'RNTC10':
				return RNTC10;
			case 'RNTC11':
				return RNTC11;
			case 'RNTC12':
				return RNTC12;
			case 'RNTC13':
				return RNTC13;
			case 'RNTC14':
				return RNTC14;
			case 'RSY10':
				return RSY10;
			case 'RSY11':
				return RSY11;
			case 'RSY12':
				return RSY12;
			case 'RSY31':
				return RSY31;
			case 'RSY32':
				return RSY32;
			case 'RSY40':
				return RSY40;
			case 'RSY41':
				return RSY41;
			case 'RSY43':
				return RSY43;
			case 'RSY42':
				return RSY42;
			case 'RSY50':
				return RSY50;
			case 'RSY51':
				return RSY51;
			case 'RSY52':
				return RSY52;
			case 'GNGS':
				return GNGS;
			case 'RSY44':
				return RSY44;
		}
	};

	const [menuId, setMenuId] = useState('');
	const [menu, setMenu] = useState([]);

	const actions = {
		closeSelectedTab: () => {
			_instance.getData()['openTabIDs'].map((is, i) => {
				if (_instance.getData().openTabIDs[i] === _instance.getData().selectedTabID) {
					return null;
				}
				return _instance.close(_instance.getData().openTabIDs[i]);
			});
		},
	};

	ready((instance) => {
		_instance = instance;
		isVertical = _instance.getOption('isVertical');
	});

	useEffect(() => {
		ready((instance) => {
			setMenuId(location);
			if (location != null && location != menuId) {
				menu.push(location);
				current = location;

				if (_instance != null) {
					let id = current;
					let path = '';
					if (_instance.isOpen(id)) {
						_instance.select(id).then(() => {
							//history.push(id);
						});
					} else {
						if (id.length > 10) {
							if (id.substring(5, 9) === 'RNTC') {
								path = id.substring(5, 11);
							} else {
								path = id.substring(5, 10);
							}
						} else {
							path = id.substring(5);
						}

						let title = getTitle(path);
						let panel2 = getPanel(path);

						if (id.substring(1, 4) !== 'D02') {
							_instance
								.open({
									id: id,
									title: title,
									panelComponent: panel2,
								})
								.then(() => {
									_instance.select(id);
								});
						}
					}
				}
			}
		});
	});
	return (
		<>
			<div style={{ height: '100%', display: isVertical ? 'inline-block' : 'block' }}>
				{/* { master && master === 'Y' ? 
					( */}
                    <div style={{position : 'absolute', zIndex : '1', right : "0px" }}>
                        <button style={{ float: 'right', height: '35px', margin: '0px', backgroundColor: '#e6e6e6', border: 'none' }}>
                            <img className="btn_help" style={{ cursor: 'pointer' }} src="/images/button/redo_icon.png" alt="현재 문서만 남기고 닫기" title="현재 문서만 남기고 닫기" 
                                onClick={actions.closeSelectedTab}/>
                        </button>
                    </div>
                     {/* ) 
                     :     
					 '' } */}
				<Tablist></Tablist>
				<Panellist></Panellist>
			</div>
		</>
	);
}

export default TabPage;
