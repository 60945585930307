//시스템관리 - 시스템관리
import { Route, Switch } from 'react-router-dom';
import RSY10 from '@/pages/C01/RSY10';  //코드관리
import RSY11 from '@/pages/C01/RSY11';  //사용자관리
import RSY12 from '@/pages/C01/RSY12';  //업체관리
import RSY44 from '@/pages/C02/RSY44';

function C01() {
    return ( 
        <Switch>
            <Route path='/C01/RSY10' component={RSY10} />
            <Route path='/C01/RSY11' component={RSY11} />
            <Route path='/C01/RSY12' component={RSY12} />            
            {/* <Route path={['/C01/PopupMng', '/C01/PopupMng/:id']} component={PopupMng} /> */}
        </Switch>
    );
}

export default C01;