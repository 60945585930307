/**
 * @Page ROM4R
 * @Description [ROM4R]납입지시 - ERP_CKD 출하관리 - 부품식별표발행(용기)
 **/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from '@/components/molecules/CustomAlert';
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common';
import CommonUtil from '@/utils/CommonUtil';
import ReactToPrint from 'react-to-print';

import WrapCkdLabelPrintC from '@/components/molecules/WrapCkdLabelPrintC';
import WrapCkdLabelPrintPercentQR from '@/components/molecules/WrapCkdLabelPrintPercentQR';
import WrapCkdLabelPrintPercentQRB from '@/components/molecules/WrapCkdLabelPrintPercentQRB';

// 신규 인쇄소스
import WrapCkdLabelPrint_C_NEW from '@/components/molecules/WrapCkdLabelPrint_C_NEW';

import WrapCkdLabelPrint_QR_A_NEW from '@/components/molecules/WrapCkdLabelPrint_QR_A_NEW';
import WrapCkdLabelPrint_QR_B_NEW from '@/components/molecules/WrapCkdLabelPrint_QR_B_NEW';

const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER;
const CODES = require('@/config/commonCode.json');
const Utils = new CommonUtil();

// Print Style CSS Setting
const pageStyle = `
 @media all {
     .pagebreak {
     display: none;
     }
 }
 
 @media print {
     html, body {
     height: initial !important;
     overflow: initial !important;
     -webkit-print-color-adjust: exact;
     }
 }
 
 @media print {
     .pagebreak {
     margin-top:1rem;
     display: block;
     page-break-after: always;
     }
 }
 
 @page {
     size: A4 landscape !important;    
     margin:7mm 10mm;
 }
 body, div, table, td{
     font-family:굴림체,Gulim ;
 }

 `;

const pageStyle2 = `

    @page{size: A4 landscape;  margin: 0;  }  // 20231020오후 최종

    body, div, table, td{
        font-family:굴림체,Gulim ;

    }
    @media print {

        html, body {
             height: initial !important;
             overflow: initial !important;
            -webkit-print-color-adjust: exact; // 프린트 컬러 옵션
            }

    .page-break_5 { page-break-inside:avoid; page-break-after:always }
    .page-break { page-break-inside:avoid; page-break-after:always }
    }

    `;

function ROM4R() {
	const token = Common.getToken();
	const userId = Common.getToken('id');
	const tVnt1 = Common.getToken('vand');
	const tComp = Common.getToken('comp');
	const bigi3 = Common.getToken('bigi3');
	let bigi2 = '';
	const grdRom4rMain = useRef();
	const printRef = useRef();
	const printBtnRef = useRef();
	const printRef2 = useRef();
	const printBtnRef2 = useRef();
	const printRef3 = useRef();
	const printBtnRef3 = useRef();

	const ckVnt1 =
		Common.getCookie('ck_vnt1' + userId) === null || Common.getCookie('ck_vnt1' + userId) === '' || Common.getCookie('ck_vnt1' + userId) === 'undefined'
			? tVnt1
			: Common.getCookie('ck_vnt1' + userId);

	const [idata, setData] = useState({
		kVnt1: ckVnt1,                     // T1업체
		kComp: tComp,                      // 회사구분
		kPlnt: '',                         // 공장
		kNidt: Common.getToday(),          // 납기일자
		kNidtGubn: 'B',                    // 납기일자 구분 (만 / 이후)
		chkNpdt: 'Y',                      // 납품일자 CheckBox
		kNpdt: Common.getToday(),          // 납품일자
		chkLtno: 'Y',                      // Lot No CheckBox
		kLtno: Common.getToday(),          // Lot No
		kPtno: '',                         // 품번
		kType: '',                         // 식별표타입
		kTypeNm: '',                       // 식별표타입
		kGubn: 'N',                        // 식별표인쇄
		kGubnNm: '',                       // 식별표인쇄
		chkDlgb: 'N',                      // 삭제분
		chkMetal: 'N',                     // 중금속확인필
		kStart: '1',                       // START 출력 시작 위치
		kCkdOem: 'CKD2',
		bigiGubn: '',
		vnt1List: [],                      // T1업체
		plntList: [],                      // 공장
		gateList: [],                      // 하치장
		typeList: [],                      // 식별표타입
		kStck: '1',                        // hk31 전용 부착면수
	});

	const [message, setMessage] = React.useState({
		openAlert: false,
		severity: 'success',
		msg: '',
	});
	const { openAlert, severity, msg } = message;

	const [dlgMessage, setDlgMessage] = React.useState({
		openDlg: false,
		dlgTrans: '',
		dlgMsg: '',
	});
	const { openDlg, dlgTrans, dlgMsg } = dlgMessage;

	let [inserts, setInsert] = useState({});

	let [bigi, setBigi] = React.useState('');
	let [searchApi, setSearchApi] = useState(false);
	let [clearGrid, setClearGrid] = useState(false);

	const [gridApi, setGridApi] = useState(null);

	let [printdata, setPrintData] = useState([]);
	let [printdata2, setPrintData2] = useState([]);
	let [printdata3, setPrintData3] = useState([]);

	const onClearApi = useCallback(async (e) => {
		setSearchApi(false);
	}, []);

	let [loading, setLoading] = useState(false);
	const [panded, setPanded] = React.useState(true);
	const [style, setStyle] = useState({ height: 'calc(100vh - 60px - 50px - 146px  - 50px)' });

	/**
	 * 공통코드 조회 (조회조건)
	 */
	const onSearchCommon = (para, name) => {
		let commonData = idata;

		const fetchData = async (para, name) => {
			let param = encodeURI(para);
			const query = SERVER_URL + '/comcd/search?query=' + param;
			await fetch(query, { headers: { Authorization: token } })
				.then((resp) => resp.json())
				.then((data) => {
					if (data.success) {
						commonData[name] = data.data;
						setData({ ...commonData });
					}
				});
		};
		fetchData(para, name);
	};

	/**
	 * 식별표 타입 조회
	 * @param {*} para
	 * @param {*} name
	 */
	const onSearchCommon1 = (para, name) => {
		let commonData = idata;

		const fetchData1 = async (para, name) => {
			let param = encodeURI(para);
			const query = SERVER_URL + '/comcd/search1?query=' + param;
			await fetch(query, { headers: { Authorization: token } })
				.then((resp) => resp.json())
				.then((data) => {
					if (data.success) {
						commonData['kType'] = '';
						commonData['kTypeNm'] = '';
						commonData[name] = data.data;
						setData({ ...commonData });
					}
				});
		};
		fetchData1(para, name);
	};

	/**
	 * bigi 조회
	 * @param {*} para
	 * @param {*} name
	 */
	const onSearchCommon2 = (para, name) => {
		let commonData = idata;

		const fetchData1 = async (para, name) => {
			let param = encodeURI(para);
			const query = SERVER_URL + '/comcd/search1?query=' + param;
			await fetch(query, { headers: { Authorization: token } })
				.then((resp) => resp.json())
				.then((data) => {
					if (data.success) {
						if (data.data[0].text === 'Y') {
							setBigi(data.data[0].text);
							bigi2 = data.data[0].text;
							commonData[name] = data.data[0].text;
						} else {
							setBigi(data.data[0].text);
							bigi2 = data.data[0].text;
							commonData[name] = data.data[0].text;
						}
						setData({ ...commonData });
					}
				});
		};
		fetchData1(para, name);
	};
	/**
	 *MOUNT
	 */
	useEffect(() => {
		//공통코드 조회 (T1업체)
		onSearchCommon(`{"pgmid":"VNT1"}`, 'vnt1List');
		//공통코드 조회 (공장)
		onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"CKD"}`, 'plntList');
		//공통코드 조회 (식별표타입)
		onSearchCommon1(`{"pgmid":"CODE", "vand":"****", "gocd":"PTCD"}`, 'typeList');
		//공통코드 조회 (BIGI)
		onSearchCommon2(`{"pgmid":"BIGI", "vand":"****", "gocd":"${userId}"}`, 'bigiGubn');

		// setTimeout(() => alert(`   소포장 품목 출력 시 인쇄매수를 선택하여 인쇄할 수 있도록 \n   '인쇄매수' 탭이 추가되었습다.\n   이용에 참조바랍니다.\n\n   (부착면수 → '인쇄매수' 탭 변경)`), 500);
	}, []);

	/**
	 * Control 변경 Event
	 * @param {*} e
	 */
	const onChange = async (e) => {
		const { name, value } = e.target;


		let newSearchs = idata;
		newSearchs[name] = value;
		setData({ ...newSearchs });

		//공장 변경시 하치장 조회
		if (name === 'kPlnt') {
			onSearchCommon(`{"pgmid":"VGATE","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}"}`, 'gateList');

			let newData = idata;
			newData['kStck'] = '1'; // 공장 선택시 디폴트 값 다시 셋팅진행한다.
			setData({ ...newData });
		}
		//T1업체, 회사구분 변경시
		else if (name === 'kVnt1' || name === 'kComp') {
			newSearchs['plntList'] = [];
			newSearchs['gateList'] = [];
			newSearchs['kPlnt'] = '';
			setData({ ...newSearchs });
			onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"CKD"}`, 'plntList');
		}
		//하치장, 미소요량 Check 시 조회 Call
		else if (name === 'kGubn') {
			onSearch(); //조회 Call
		}
	};

	/**
	 * Grid Event
	 * @param {*} e
	 */
	const grdRom4rMain_onCellValueChanged = async (e) => {
		let result;

		//납품수량과 용기당수 계산을 하여 용기수 Setting
		if (e.column.colId === 'npqt' || e.column.colId === 'qypc') {
			if (e.data.npqt === '0' || e.data.npqt === '') {
				e.node.setDataValue('caseqty', 0);
				e.node.setSelected(false);
			} else {
				if (e.data.npqt !== '' || e.data.npqt !== null) {
					result = Math.ceil(parseInt(e.data.npqt) / parseInt(e.data.qypc));
					if (result === Infinity) {
						//용기당 수가 0일때 용기수 빈 값
						result = '';
					} else {
						result = result;
					}
				} else {
					result = 0;
				}
				e.node.setDataValue('caseqty', result);
			}
		}
	};

	const grdRom4rMain_onRowClicked = async (e) => {};

	/**
	 * 검색조건
	 */
	const optionsX = {
		0: [
			//T1업체
			{
				formatter: 'select',
				name: 'kVnt1',
				labelName: 'T1업체',
				type: 'text',
				defaultValue: idata.kVnt1,
				codeMode: true,
				selectAll: '',
				codes: null,
				comms: idata.vnt1List,
				align: 'left',
			},
			//회사구분
			{
				formatter: 'select',
				name: 'kComp',
				labelName: '회사구분',
				type: 'text',
				defaultValue: idata.kComp,
				codeMode: true,
				selectAll: '',
				codes: CODES['comp'],
				comms: null,
				align: 'left',
			},
			{ formatter: "blank", align: "right" },
			//납품일자
			{
				formatter: 'checkInput', 
				labelOptions: [{ name: 'chkNpdt', labelName: '납품일자', defaultValue: idata.chkNpdt }],

				name: 'kNpdt',
				type: 'date',
				desc: '',
				readOnly: false,
				defaultValue: idata.kNpdt,
				align: 'left',

				labelType: 'insert1',
			},
			{
				formatter: 'select',
				name: 'kStck',
				labelName: '인쇄 매수',
				type: 'text',
				defaultValue: idata.kStck,
				codeMode: false,
				selectAll: '',
				items: [
					{ text: '1장', comCode: '1' },
					{ text: '2장', comCode: '2' }
				],
			},
		],
		1: [
			//공장
			{
				formatter: 'select',
				name: 'kPlnt',
				labelName: '공장',
				type: 'text',
				defaultValue: idata.kPlnt,
				codeMode: false,
				selectAll: 'none',
				codes: null,
				comms: idata.plntList,
			},
			//납기일자  & 만or이후 comboBox
			{
				formatter: 'dateCombo',
				name: 'kNidt',
				name2: 'kNidtGubn',
				labelName: '납기일자',
				defaultValue: idata.kNidt,
				defaultValue2: idata.kNidtGubn,
				codeMode2: false,
				items2: [
					{ text: '만', comCode: 'A' },
					{ text: '이후', comCode: 'B' },
				],
				labelType: 'none',
			},
			{ formatter: "blank", align: "right" },
			//LOT NO
			{
				formatter: 'checkInput',
				labelOptions: [{ name: 'chkLtno', labelName: 'LOT NO', defaultValue: idata.chkLtno }],

				name: 'kLtno',
				type: 'date',
				desc: '',
				readOnly: false,
				defaultValue: idata.kLtno,
				align: 'left',

				labelType: 'insert1',
			},
			//Start
			{
				formatter: 'select',
				name: 'kStart',
				labelName: 'START',
				type: 'text',
				defaultValue: idata.kStart,
				labelType: 'left',
				codeMode: false,
				selectAll: '',
				codes: idata.kType && idata.kType === 'B' ? CODES['startB'] : idata.kType && idata.kType === 'C' ? CODES['startC'] : CODES['start'],
				comms: null,
				align: 'right',
				labelType: 'insert1',
			},
			// { formatter: 'blank', align: 'right' },
		],
		2: [
			//품번
			{
				formatter: 'input',
				name: 'kPtno',
				labelName: '품번',
				type: 'text',
				desc: '',
				readOnly: false,
				defaultValue: idata.kPtno,
				align: 'left',
				labelType: 'none',
			},
			//식별표타입 + 삭제분
			{
				formatter: 'selectCheck',
				name: 'kGubn',
				labelName: '식별표타입',
				type: 'text',
				defaultValue: idata.kGubn,
				codeMode: false,
				selectAll: '',
				items: [
					{ text: '전체', comCode: '*' },
					{ text: '인쇄', comCode: 'Y' },
					{ text: '미인쇄', comCode: 'N' },
				],
				name2: 'chkDlgb',
				labelName2: ' ',
				options2: [{ name: 'chkDlgb', labelName: '삭제분', defaultValue: idata.chkDlgb }],
			},
			{ formatter: "blank", align: "right" },
			//중금속확인필
			{
				formatter: 'checkSpan',
				name: 'chkMetal',
				labelName: ' ',
				options: [{ name: 'chkMetal', labelName: '중금속 확인필', defaultValue: idata.chkMetal }],

				labelType: 'insert1',
			},
			
			//식별표타입
			{
				formatter: 'select',
				name: 'kType',
				labelName: '식별표 TYPE',
				type: 'text',
				defaultValue: idata.kType,
				codeMode: true,
				selectAll: 'none',
				codes: null,
				comms: idata.typeList.filter((i) => i['comCode'] !== 'C'),
				labelType: 'insert1',
			},
		],
	};

	/**
	 * 조회
	 * @param {*} e
	 * @returns
	 */
	const onSearch = async (e) => {
		setClearGrid(true); // Detail Grid 초기화

		//T1업체선택 Check
		if (idata.kVnt1 === '') {
			setMessage({ openAlert: true, severity: 'info', msg: '1차업체를 선택하십시요 !' });
			document.getElementById('kVnt1').focus();
			return;
		}

		//회사구분선택 Check
		if (idata.kComp === '') {
			setMessage({ openAlert: true, severity: 'info', msg: '회사구분을 선택하십시요 !' });
			document.getElementById('kComp').focus();
			return;
		}

		//공장선택 Check
		if (idata.kPlnt === '') {
			setMessage({ openAlert: true, severity: 'info', msg: '공장을 선택하십시요 !' });
			document.getElementById('kPlnt').focus();
			return;
		}
		// Cookie 값 셋팅
		Common.setCookie('ck_vnt1' + userId, idata.kVnt1, 1);

		let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                     "kDlgb":"${idata.chkDlgb}","kNidtGubn":"${idata.kNidtGubn}","kNidt":"${idata.kNidt}",
                     "kPtno":"${idata.kPtno}","kGubn":"${idata.kGubn}","kType":"${idata.kType}","kStck":"${idata.kStck}"}`;

		let param = encodeURI(para);

		const query = SERVER_URL + '/rom4r/search?query=' + param;
		setSearchApi(query); // Search API
	};

	//deleteBlank
	function deleteBlank(argval) {
		if (argval === null || argval === undefined) return '';

		for (var i = argval.length - 1; i >= 0; i--) {
			if (argval.charCodeAt(i) !== 12288 && argval.charCodeAt(i) !== 32)
				//charCodeAt(i)
				return argval.substring(0, i + 1);
		}
		return argval.length > 0 ? argval.substring(0, 0) : '';
	}

	/**
	 * 인쇄
	 * @param {*} e
	 * @returns
	 */
	const onPrint = async (e) => {
		let inputFlag = true;

		// 납품일자 선택 Check
		if (idata.chkNpdt === 'Y') {
			if (idata.kNpdt === '') {
				setMessage({ openAlert: true, severity: 'info', msg: '납품일자를 선택하세요.' });
				document.getElementById('kNpdt').focus();
				return;
			}
		}

		// LOT NO 선택 Check
		if (idata.chkLtno === 'Y') {
			if (idata.kLtno === '') {
				setMessage({ openAlert: true, severity: 'info', msg: 'LOT NO를 선택하세요.' });
				document.getElementById('kLtno').focus();
				return;
			}
		}

		//식별표타입 선택 Check
		if (idata.kType === '') {
			setMessage({ openAlert: true, severity: 'info', msg: '식별표타입을 선택하십시요 !' });
			document.getElementById('kType').focus();
			return;
		}

		//select nodes
		let selectedData = grdRom4rMain.current.getSelectedNodes();

		// 선택 값 체크
		if (selectedData.length === 0) {
			//Alert 창 OPEN
			setMessage({ openAlert: true, severity: 'info', msg: '선택한 데이터가 없습니다.' });
			return;
		}

		//필수입력항목 Check
		selectedData.forEach((node) => {
			if (node.data.npqt === '' || node.data.npqt === '0') {
				setMessage({ openAlert: true, severity: 'info', msg: '납품수량을 입력하여 주십시오.' });
				inputFlag = false;
				grdRom4rMain.current.SetFocus(node.rowIndex, 'npqt');
				return;
			} else if (parseInt(node.data.npqt) > parseInt(node.data.plqt)) {
				setMessage({ openAlert: true, severity: 'info', msg: '납품수량은 오더수량보다 클 수 없습니다.' });
				inputFlag = false;
				grdRom4rMain.current.SetFocus(node.rowIndex, 'npqt');
				return;
			} else if (node.data.stck === '' || node.data.stck === '0') {
				setMessage({ openAlert: true, severity: 'info', msg: '부착면수를 입력하세요.' });
				inputFlag = false;
				grdRom4rMain.current.SetFocus(node.rowIndex, 'stck');
				return;
			} else if (node.data.qypc === '' || node.data.qypc === '0') {
				setMessage({ openAlert: true, severity: 'info', msg: '용기당수를 입력하세요.' });
				inputFlag = false;
				grdRom4rMain.current.SetFocus(node.rowIndex, 'qypc');
				return;
			}
		});

		if (!inputFlag) return;

		if (idata.kType === 'A') {
			//출력 확인 메세지
			setDlgMessage({ openDlg: true, dlgTrans: 'P', dlgMsg: '선택하신 부품 식별표는 [ ' + idata.kType + ' 4매 ] 입니다. 부품식별지 용지를 확인 하십시오. 인쇄 하시겠습니까?' });
		}
		if (idata.kType === 'B') {
			//출력 확인 메세지
			setDlgMessage({ openDlg: true, dlgTrans: 'P', dlgMsg: '선택하신 부품 식별표는 [ ' + idata.kType + ' 6매 ] 입니다. 부품식별지 용지를 확인 하십시오. 인쇄 하시겠습니까?' });
		}
		if (idata.kType === 'C') {
			//출력 확인 메세지
			setDlgMessage({ openDlg: true, dlgTrans: 'P', dlgMsg: '선택하신 부품 식별표는 [ ' + idata.kType + ' 12매 ] 입니다. 부품식별지 용지를 확인 하십시오. 인쇄 하시겠습니까?' });
		}
	};

	const onPrint2 = async (printData) => {
		let ptdata = JSON.stringify(printData);
		let body = {
			userCode: userId,
			type: 'ckd',
			json: ptdata.replaceAll('"', "'"),
			state: 'REQ',
		};
		let data = JSON.stringify(body);

		// await fetch('http://rpa.bigibot.com:9400/system/print', { method: 'POST', headers: { 'X-AppKey': 'to1adn6pq_sw', 'Content-Type': 'application/json' }, body: data })
		const query = SERVER_URL + "/bigi/print"; 
        await fetch(query, { method: "POST", headers: {"Content-Type":"application/json"}, body: data })
			.then((resp) => resp.json())
			.then((data) => {
				window.location.href = data.data.replaceAll("\"","");
				setMessage({ openAlert: true, severity: 'success', msg: 'BIGI를 확인해주세요.' });
			})
			.catch((error) => {
				if (error) {
					setMessage({ openAlert: true, severity: 'error', msg: 'BIGI 프로그램을 재시작 후 다시 시도해주세요.' });
				}
			});
	};

	const editCellClicked = (params) => {
		// plqt order qty
		// npqt  납품수량
		// qypc 용기당수
		// caseqty 부착면수
		// default Cell Click 이벤트
		setGridApi(params);
		let gridApi = params.api;

		let rowIdx = params.rowIndex;

		let data = params.data;

		if (params.column.colId === 'npqt') {
			if (data.npqt === '0' || data.npqt === '') {
				gridApi.stopEditing(true); // Grid EditMode stop

				var rowNode = gridApi.getRowNode(rowIdx);
				var rowNode2 = gridApi.getDisplayedRowAtIndex(rowIdx); //정렬, 필터링... 하면 변경된 index가져옴

				rowNode2.setDataValue('npqt', data.plqt); // 발주수량 값 받아와서 납품수량npqt 셋팅

				gridApi.startEditingCell({
					rowIndex: rowIdx,
					colKey: 'npqt', // 납품수량npqt edit Mode
				}); // Grid EditMode start
			}
		}
	};

	/**
	 * 파일생성
	 * @param {*} e
	 */
	const onUpload = async (e) => {};

	/**
	 * 신규 Row추가
	 * @param {*} e
	 */
	const onAddRow = async (e) => {};

	/**
	 * 입력
	 * @param {*} e
	 */
	const onInsert = async (e) => {};

	/**
	 * 수정
	 * @param {*} e
	 * @returns
	 */
	const onUpdate = async (e) => {};

	/**
	 * 삭제
	 * @param {*} e
	 */
	const onDelete = async (e) => {};

	const fetchDataCall = () => {
		//select nodes
		let selectedData = grdRom4rMain.current.getSelectedData();

		const fetchData = async () => {
			let selectedDataItems = [];
			let selectDataList = {};
			let selectPtnoList = [];
			let dataList = [];
			let printList = {};

			selectedData.forEach((data) => {
				selectPtnoList.push(data.ptno);
				selectDataList = {
					vnt1: idata.kVnt1,
					comp: idata.kComp,
					plnt: idata.kPlnt,
					ltno: idata.kLtno,
					gate: idata.kGate,
					ptno: data.ptno,
					stck: idata.kStck, // 부착면수는 상태값을 가지고만 처리한다.
					qypc: data.qypc,
					npqt: data.npqt,
					caseqty: data.caseqty,
					nidt: data.nidt,
					chpk: data.chpk,
					csno: data.csno,
					ctlt: data.ctlt,
					npqt: data.npqt,
					pdno: data.pdno,
					keygroup: data.nidt + data.ptno + data.chpk + data.csno + data.ctlt,
					vnyn: data.vnyn === '' || data.vnyn === 'N' ? 'N' : 'Y',
				};
				selectedDataItems.push(selectDataList);
				dataList.push(selectDataList.keygroup);
			});
			//const query = SERVER_URL + "/rom4v/print";
			const query = SERVER_URL + '/rom4r/print';
			let param = JSON.stringify({
				parmData: {
					vnt1: idata.kVnt1,
					comp: idata.kComp,
					plnt: idata.kPlnt,
					ltno: idata.chkLtno === 'Y' ? idata.kLtno : 'N', // LOT NO 데이터에 빈값 던질 경우, backend 에러가... 안쓰이지만 "N" 값으로 대체
					gate: idata.kGate,
					npdt: idata.chkNpdt === 'Y' ? idata.kNpdt : 'N', // 납품일자 데이터에 빈값 던질 경우, backend 에러가... 안쓰이지만 "N" 값으로 대체
					chkNpdt: idata.chkNpdt,
					chkLtno: idata.chkLtno,
					dataList: dataList,
					ptnoList: selectPtnoList,
					kCkdOem: 'CKD2',
					kDlgb: idata.chkDlgb,
					selectedData: selectedDataItems,
				},
			});

			//인쇄데이터
			let detailPrintInfo = [];
			let detailPrintInfo2 = {};
			let printcnt = 0;
			let resultQypc;
			let realPrintCnt;

			//  await fetch(query, { headers: { Authorization: token } })
			await fetch(query, { method: 'POST', headers: { Authorization: token, 'Content-Type': 'application/json' }, body: param })
				.then((resp) => resp.json())
				.then((data) => {
					if (data.success) {
						// 성공 메시지 처리 필요
						setMessage({ openAlert: true, severity: 'success', msg: '인쇄 조회 성공 하였습니다.' });

						if (idata.kType === 'A') {
							setPrintData(data.data);

							for (let i = 0; i < data.data.length; i++) {
								resultQypc = Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC); // 용기수
								realPrintCnt = resultQypc * data.data[i].STCK;                              // 용기수*부착면수 -> 실제 부품식별표 갯수
								printcnt = printcnt + parseInt(realPrintCnt);
								detailPrintInfo2 = {
									area: data.data[i].TMP1,                  // 지역
									vehicleType: data.data[i].TMP3,           // 차종
									itemNo: data.data[i].TMP5,                // 품번
									itemNm: data.data[i].TMP6,                // 품명
									quantity: parseInt(selectedData[i].npqt), // 수량
									companyNm: data.data[i].TMP10,            // 회사명
									companyCd: data.data[i].TMP12,            // 회사코드
									containerNo: data.data[i].TMP17,          // 용기번호
									attached: data.data[i].STCK,              // 부착면수
									container: data.data[i].QYPC,             // 용기당수
									contents1: data.data[i].TMP25,            // 이중인쇄1
									xPos1: 1.0 * data.data[i].TMP26,          // 이중인쇄 x1
									yPos1: 1.0 * data.data[i].TMP27,          // 이중인쇄 y2
									color1: data.data[i].TMP28,               // 이중인쇄컬러1
									fontSize1: 1.0 * data.data[i].TMP29,      // 이중인쇄폰트사이즈1
									contents2: data.data[i].TMP30,            // 이중인쇄2
									xPos2: 1.0 * data.data[i].TMP31,          // 이중인쇄 x2
									yPos2: 1.0 * data.data[i].TMP32,          // 이중인쇄 y2
									color2: data.data[i].TMP33,               // 이중인쇄컬러2
									fontSize2: 1.0 * data.data[i].TMP34,      // 이중인쇄폰트사이즈2
									gw: 1.0 * data.data[i].TMP35,             // G/W
									nw: 1.0 * data.data[i].TMP20,             // G/W
									orderNo: data.data[i].TMP21,              // Order
									fromOrderNo: data.data[i].TMP22,          // fromOrder
									toOrderNo: data.data[i].TMP23,            // toOrder
									isRHD: data.data[i].TMP36,                // RHD
									prcd: data.data[i].TMP15,                 //  tmp15 조립코드
									issueNo: ' ',
									resultQypc: Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC), // 용기수
									realPrintCnt: resultQypc * data.data[i].STCK,                              // 용기수*부착면수 -> 실제 부품식별표 갯수
									nidt: idata.chkNpdt === 'Y' ? data.data[i].TMP9 : ' ',
									pdno: data.data[i].PDNO,
									location: data.data[i].TMP4,
								};
								detailPrintInfo.push(detailPrintInfo2);
							}
							printList = {
								type: 4,
								ckdGubn: 'CKD2',
								gubn: '',
								seq: data.data[0].SEQ - 1,
								factoryCode: data.data[0].TMP2,   // 공장
								location: data.data[0].TMP4,      // 하치장
								isMetal: idata.chkMetal === 'Y' ? true : false,   // 중금속확인필
								lotNo: idata.chkLtno === 'Y' ? Common.getLOT(data.data[0].TMP13) : '',  // LOT NO 날짜 가져오기  data.TMP13:"N";
								deliveryDate: idata.kNpdt,       // 납품일자
								start: parseInt(idata.kStart) - 1,
								printcnt: printcnt,
								posx: data.data[0].POSX,
								posy: data.data[0].POSY,
								ckdDetailPrintInfo: detailPrintInfo,
							};
							if (data.data[0].BIGI === 'Y') {
								setBigi('Y');
								bigi2 = 'Y';
							} else {
								setBigi('N');
								bigi2 = 'N';
							}

							if (bigi2 === 'Y') {
								onPrint2(printList);
							} else {
								printBtnRef.current.dispatchEvent(new Event('click', { bubbles: true }));
							}
						}
						if (idata.kType === 'B') {
							setPrintData2(data.data);

							for (let i = 0; i < data.data.length; i++) {
								resultQypc = Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC);    // 용기수
								realPrintCnt = resultQypc * data.data[i].STCK;                                 // 용기수*부착면수 -> 실제 부품식별표 갯수
								printcnt = printcnt + parseInt(realPrintCnt);
								detailPrintInfo2 = {
									area: data.data[i].TMP1,                  // 지역
									vehicleType: data.data[i].TMP3,           // 차종
									itemNo: data.data[i].TMP5,                // 품번
									itemNm: data.data[i].TMP6,                // 품명
									quantity: parseInt(selectedData[i].npqt), // 수량
									companyNm: data.data[i].TMP10,            // 회사명
									companyCd: data.data[i].TMP12,            // 회사코드
									containerNo: data.data[i].TMP17,          // 용기번호
									attached: data.data[i].STCK,              // 부착면수
									container: data.data[i].QYPC,             // 용기당수
									contents1: data.data[i].TMP25,            // 이중인쇄1
									xPos1: 1.0 * data.data[i].TMP26,          // 이중인쇄 x1
									yPos1: 1.0 * data.data[i].TMP27,          // 이중인쇄 y2
									color1: data.data[i].TMP28,               // 이중인쇄컬러1
									fontSize1: 1.0 * data.data[i].TMP29,      // 이중인쇄폰트사이즈1
									contents2: data.data[i].TMP30,            // 이중인쇄2
									xPos2: 1.0 * data.data[i].TMP31,          // 이중인쇄 x2
									yPos2: 1.0 * data.data[i].TMP32,          // 이중인쇄 y2
									color2: data.data[i].TMP33,               // 이중인쇄컬러2
									fontSize2: 1.0 * data.data[i].TMP34,      // 이중인쇄폰트사이즈2
									gw: 1.0 * data.data[i].TMP35,             // G/W
									nw: 1.0 * data.data[i].TMP20,             // G/W
									orderNo: data.data[i].TMP21,              // Order
									fromOrderNo: data.data[i].TMP22,          // fromOrder
									toOrderNo: data.data[i].TMP23,            // toOrder
									isRHD: data.data[i].TMP36,                // RHD
									prcd: data.data[i].TMP15,                 //  tmp15 조립코드
									issueNo: ' ',
									resultQypc: Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC), // 용기수
									realPrintCnt: resultQypc * data.data[i].STCK,                              // 용기수*부착면수 -> 실제 부품식별표 갯수
									nidt: idata.chkNpdt === 'Y' ? data.data[i].TMP9 : ' ',
									pdno: data.data[i].PDNO,
									location: data.data[i].TMP4,
								};
								detailPrintInfo.push(detailPrintInfo2);
							}
							printList = {
								type: 6,
								ckdGubn: 'CKD2',
								gubn: '',
								seq: data.data[0].SEQ - 1,
								factoryCode: data.data[0].TMP2, // 공장
								location: data.data[0].TMP4,    // 하치장
								isMetal: idata.chkMetal === 'Y' ? true : false,  // 중금속확인필
								lotNo: idata.chkLtno === 'Y' ? Common.getLOT(data.data[0].TMP13) : '',  // LOT NO 날짜 가져오기  data.TMP13:"N";
								deliveryDate: idata.kNpdt,  // 납품일자
								start: parseInt(idata.kStart) - 1,
								printcnt: printcnt,
								posx: data.data[0].POSX,
								posy: data.data[0].POSY,
								ckdDetailPrintInfo: detailPrintInfo,
							};
							if (data.data[0].BIGI === 'Y') {
								setBigi('Y');
								bigi2 = 'Y';
							} else {
								setBigi('N');
								bigi2 = 'N';
							}

							if (bigi2 === 'Y') {
								onPrint2(printList);
							} else {
								printBtnRef2.current.dispatchEvent(new Event('click', { bubbles: true }));
							}
						}
						if (idata.kType === 'C') {
							setPrintData3(data.data);

							for (let i = 0; i < data.data.length; i++) {
								resultQypc = Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC);   // 용기수
								realPrintCnt = resultQypc * data.data[i].STCK;                                // 용기수*부착면수 -> 실제 부품식별표 갯수
								printcnt = printcnt + parseInt(realPrintCnt);
								detailPrintInfo2 = {
									area: data.data[i].TMP1,                  // 지역
									vehicleType: data.data[i].TMP3,           // 차종
									itemNo: data.data[i].TMP5,                // 품번
									itemNm: data.data[i].TMP6,                // 품명
									quantity: parseInt(selectedData[i].npqt), // 수량
									companyNm: data.data[i].TMP10,            // 회사명
									companyCd: data.data[i].TMP12,            // 회사코드
									containerNo: data.data[i].TMP17,          // 용기번호
									attached: data.data[i].STCK,              // 부착면수
									container: data.data[i].QYPC,             // 용기당수
									contents1: data.data[i].TMP25,            // 이중인쇄1
									xPos1: 1.0 * data.data[i].TMP26,          // 이중인쇄 x1
									yPos1: 1.0 * data.data[i].TMP27,          // 이중인쇄 y2
									color1: data.data[i].TMP28,               // 이중인쇄컬러1
									fontSize1: 1.0 * data.data[i].TMP29,      // 이중인쇄폰트사이즈1
									contents2: data.data[i].TMP30,            // 이중인쇄2
									xPos2: 1.0 * data.data[i].TMP31,          // 이중인쇄 x2
									yPos2: 1.0 * data.data[i].TMP32,          // 이중인쇄 y2
									color2: data.data[i].TMP33,               // 이중인쇄컬러2
									fontSize2: 1.0 * data.data[i].TMP34,      // 이중인쇄폰트사이즈2
									gw: 1.0 * data.data[i].TMP35,             // G/W
									nw: 1.0 * data.data[i].TMP20,             // G/W
									orderNo: data.data[i].TMP21,              // Order
									fromOrderNo: data.data[i].TMP22,          // fromOrder
									toOrderNo: data.data[i].TMP23,            // toOrder
									isRHD: data.data[i].TMP36,                // RHD
									prcd: data.data[i].TMP15,                 //  tmp15 조립코드
									issueNo: ' ',
									resultQypc: Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC), // 용기수
									realPrintCnt: resultQypc * data.data[i].STCK,                              // 용기수*부착면수 -> 실제 부품식별표 갯수
									nidt: idata.chkNpdt === 'Y' ? data.data[i].TMP9 : ' ',
									pdno: data.data[i].PDNO,
									location: data.data[i].TMP4,
								};
								detailPrintInfo.push(detailPrintInfo2);
							}
							printList = {
								type: 12,
								ckdGubn: 'CKD2',
								gubn: '',
								seq: data.data[0].SEQ - 1,
								factoryCode: data.data[0].TMP2,  // 공장
								location: data.data[0].TMP4,     // 하치장
								isMetal: idata.chkMetal === 'Y' ? true : false, // 중금속확인필
								lotNo: idata.chkLtno === 'Y' ? Common.getLOT(data.data[0].TMP13) : '', // LOT NO 날짜 가져오기  data.TMP13:"N";
								deliveryDate: idata.kNpdt,      // 납품일자
								start: parseInt(idata.kStart) - 1,
								printcnt: printcnt,
								posx: data.data[0].POSX,
								posy: data.data[0].POSY,
								ckdDetailPrintInfo: detailPrintInfo,
							};
							if (data.data[0].BIGI === 'Y') {
								setBigi('Y');
								bigi2 = 'Y';
							} else {
								setBigi('N');
								bigi2 = 'N';
							}

							if (bigi2 === 'Y') {
								onPrint2(printList);
							} else {
								printBtnRef3.current.dispatchEvent(new Event('click', { bubbles: true }));
							}
						}
						setTimeout(() => onSearch(), 300);
					}

					if (data.error) {
						setMessage({ openAlert: true, severity: 'error', msg: '조회 실패' });
					}
				})
				.catch((error) => {
					// 에러 응답 처리
					if (error) {
						setMessage({ openAlert: true, severity: 'error', msg: '서버 상태를 확인해 주세요. 관리자에게 문의해주세요.' });
					}
				});
		};

		fetchData();
	};

	//Transaction AlertDialogResult
	const AlertDialogResult = async (e) => {
		setDlgMessage({ ...dlgMessage, openDlg: false });

		//DialogResult
		if (e.result == 'N') return;

		//Transaction 구분
		switch (e.Trans) {
			//등록
			case 'I':
				break;
			//수정
			case 'U':
				break;
			//출력
			case 'P':
				let newDataB = idata;
				newDataB['kStck'] = idata['kStck'] ? idata['kStck'] : '1';
				setData({ ...newDataB });
				fetchDataCall();
				break;
		}
	};

	/**
	 * 엑셀
	 * @param {*} e
	 */
	const onExcel = async (e) => {
		let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                "kDlgb":"${idata.chkDlgb}","kNidtGubn":"${idata.kNidtGubn}","kNidt":"${idata.kNidt}",
                "kPtno":"${idata.kPtno}","kGubn":"${idata.kGubn}","kType":"${idata.kType}"}`;

		let param = encodeURIComponent(para);
		const query = SERVER_URL + '/rom4r/excel?query=' + param;

		setLoading(true);

		await fetch(query, { headers: { Authorization: token } })
			.then((resp) => {
				return resp.blob();
			})
			.then((blob) => {
				const href = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = href;
				link.setAttribute('download', 'rom4r.xls');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((err) => {
				return Promise.reject({ Error: 'error', err });
			});

		setLoading(false);
		setMessage({ openAlert: true, severity: 'success', msg: '엑셀파일이 생성되었습니다.' });
	};

	/**
	 * Grid Bind Data
	 * @returns
	 */
	const onGridBindData = (para) => {};

	//Cell Key Down
	const grdRom4rMain_onCellKeyDown = async (e) => {
		var focusedCell;
		var rowIndex;
		var colId;
		var rowData;
		var cellData;
		var rowNode2;
		if (e.event.code == 'ArrowDown') {
			grdRom4rMain.current.SetFocus(e.node.rowIndex + 1, e.column.colId);
			focusedCell = gridApi.api.getFocusedCell();
			rowIndex = focusedCell.rowIndex;
			colId = focusedCell.column.getId();
			rowData = gridApi.api.getRowNode(rowIndex).data;
			cellData = rowData[colId];
			rowNode2 = gridApi.api.getDisplayedRowAtIndex(e.node.rowIndex + 1);
			gridApi.api.stopEditing(true);

			if (colId === 'npqt') {
				cellData = rowData['plqt']; //오더수량
			}

			rowNode2.setDataValue(colId, cellData);

			gridApi.api.startEditingCell({
				rowIndex: e.node.rowIndex + 1,
				colKey: colId, // 납품수량
			});
		} else if (e.event.code == 'NumpadEnter' || e.event.code == 'Enter') {
			//NumpadEnter
			gridApi.api.tabToNextCell();
			focusedCell = gridApi.api.getFocusedCell();
			rowIndex = focusedCell.rowIndex;
			colId = focusedCell.column.getId();
			rowData = gridApi.api.getRowNode(rowIndex).data;
			cellData = rowData[colId];
			rowNode2 = gridApi.api.getDisplayedRowAtIndex(e.node.rowIndex + 1);
			var rowNode2;

			if (colId === 'npqt') {
				rowNode2 = gridApi.api.getDisplayedRowAtIndex(e.node.rowIndex + 1);
			} else {
				rowNode2 = gridApi.api.getDisplayedRowAtIndex(e.node.rowIndex);
			}

			if (colId === 'npqt') {
				gridApi.api.stopEditing(true);
				rowNode2.setDataValue('npqt', rowNode2.data.plqt); //납품수량
				gridApi.api.startEditingCell({
					rowIndex: e.node.rowIndex + 1,
					colKey: 'npqt', // 납품수량
				});
			} else {
				rowNode2.setDataValue(colId, cellData);
			}
		}
	};

	/**
	 *Grid Column 정의
	 */
	//Main Grid Column
	const columnDefs = [
		{
			headerName: 'vnt1',
			field: 'vnt1',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 0,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: true,
		},
		{
			headerName: 'comp',
			field: 'comp',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 0,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: true,
		},
		{
			headerName: 'plnt',
			field: 'plnt',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 0,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: true,
		},
		{
			headerName: 'pdno',
			field: 'pdno',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 0,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: true,
		},
		{
			headerName: 'type',
			field: 'type',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 0,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: true,
		},
		{
			headerName: 'ltno',
			field: 'ltno',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 0,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: true,
		},
		{
			headerName: 'carc',
			field: 'carc',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 0,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: true,
		},
		{
			headerName: 'star',
			field: 'star',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 0,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: true,
		},
		{
			headerName: 'spgb',
			field: 'spgb',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 0,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: true,
		},
		{
			headerName: 'vnyn',
			field: 'vnyn',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 0,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: true,
		},
		{
			headerName: '납기일자',
			field: 'nidt',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			maxWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			valueGetter: Utils.dateGetter,
			hide: false,
			cellStyle: (params) => {
				//cell배경색 변경
				if (params.data.spgb === 'Y') {
					return { background: 'rgb(255 207 255 / 52%)' };
				}
				return null;
			},
		},
		{
			headerName: '품번',
			field: 'ptno',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 180,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			valueGetter: Utils.partnoGetter,
			hide: false,
			cellStyle: (params) => {
				//cell배경색 변경
				if (params.data.spgb === 'Y') {
					return { background: 'rgb(255 207 255 / 52%)' };
				}
				return null;
			},
		},
		{
			headerName: '포장배치',
			field: 'chpk',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
			cellStyle: (params) => {
				//cell배경색 변경
				if (params.data.spgb === 'Y') {
					return { background: 'rgb(255 207 255 / 52%)' };
				}
				return null;
			},
		},
		{
			headerName: '오더(From-To)',
			field: 'oder',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 240,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
			cellStyle: (params) => {
				//cell배경색 변경
				if (params.data.spgb === 'Y') {
					return { background: 'rgb(255 207 255 / 52%)' };
				}
				return null;
			},
		},
		{
			headerName: 'Order Qty',
			field: 'plqt',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			cellEditor: 'numericEditor',
			singleClickEdit: true,
			hide: false,
			cellStyle: (params) => {
				//cell배경색 변경
				if (params.data.spgb === 'Y') {
					return { background: 'rgb(255 207 255 / 52%)' };
				}
				return null;
			},
		},
		{
			headerName: '납품수량',
			field: 'npqt',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: true,
			headerClass: 'editCell',
			onCellClicked: editCellClicked,
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			hide: false,
			cellStyle: (params) => {
				//cell배경색 변경
				if (params.data.spgb === 'Y') {
					return { background: 'rgb(255 207 255 / 52%)' };
				}
				return null;
			},
		},
		{
			headerName: '용기당수',
			field: 'qypc',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: true,
			headerClass: 'editCell',
			onCellClicked: editCellClicked,
			cellClass: ['nocheckbox', 'alignR'],
			cellEditor: 'numericEditor',
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
			cellStyle: (params) => {
				if (params.data.spgb === 'Y' && params.data.vnyn === 'Y') {
					return { background: 'rgb(255 207 255 / 52%)', color: 'rgb(243 0 0)', fontWeight: 'bold' };
				} else if ((params.data.spgb === 'Y' && params.data.vnyn === 'N') || (params.data.spgb === 'Y' && params.data.vnyn === '')) {
					return { background: 'rgb(255 207 255 / 52%)' };
				} else if (params.data.vnyn === 'Y') {
					return { color: 'rgb(243 0 0)', fontWeight: 'bold' };
				}
				return null;
			},
		},
		{
			headerName: '용기수',
			field: 'caseqty',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			hide: false,
			cellStyle: (params) => {
				//cell배경색 변경
				if (params.data.spgb === 'Y') {
					return { background: 'rgb(255 207 255 / 52%)' };
				}
				return null;
			},
		},
		{
			headerName: '부착면수',
			field: 'stck',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: true,
			headerClass: 'editCell',
			onCellClicked: editCellClicked,
			cellClass: ['nocheckbox', 'alignR'],
			cellEditor: 'numericEditor',
			singleClickEdit: true,
			hide: true,
			cellStyle: (params) => {
				//cell배경색 변경
				if (params.data.spgb === 'Y') {
					return { background: 'rgb(255 207 255 / 52%)' };
				}
				return null;
			},
		},
		{
			headerName: '하치장',
			field: 'ctlt',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
			cellStyle: (params) => {
				//cell배경색 변경
				if (params.data.spgb === 'Y') {
					return { background: 'rgb(255 207 255 / 52%)' };
				}
				return null;
			},
		},
		{
			headerName: '국가',
			field: 'nanm',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: false,
			cellStyle: (params) => {
				//cell배경색 변경
				if (params.data.spgb === 'Y') {
					return { background: 'rgb(255 207 255 / 52%)' };
				}
				return null;
			},
		},
		{
			headerName: 'CASE',
			field: 'csno',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
			cellStyle: (params) => {
				//cell배경색 변경
				if (params.data.spgb === 'Y') {
					return { background: 'rgb(255 207 255 / 52%)' };
				}
				return null;
			},
		},
	];

	const onClick = async (e) => {
		setPanded(!panded);

		if (panded === true) {
			setStyle({
				height: 'calc(100vh - 60px - 50px - 50px)',
			});
		} else {
			setStyle({
				height: 'calc(100vh - 60px - 50px - 146px  - 50px)',
			});
		}
	};

	return (
		<>
			{/* Dialog FRAME */}
			<AlertDialog open={openDlg} trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

			{/* Alert FRAME */}
			<CustomAlert
				open={openAlert}
				severity={severity}
				duration={3000}
				message={msg}
				onClose={() => {
					setMessage({ ...message, openAlert: false });
				}}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			/>

			{/* Ribbon Frame : 프로그램 Path 및 버튼 */}
			<Ribbon
				onInfoMsg={'그리드의 용기당수량의 빨강색표시는 <br/> 납입지시 > 기초관리 > ERP_품목관리화면의 사용자지정 용기당수량항목의 사용유무로 표출됩니다.'}
				onSearch={onSearch}
				onSearchName={null}
				onPrint={onPrint}
				onPrintName={'인쇄'}
				onExcel={onExcel}
				onExcelName={null}
			>
				{'납입지시 > ERP_CKD 출하관리 > '}
				<b style={{ color: '#cd5c5c', fontFamily: 'PretendardMedium, NanumGothic, sans-serif' }}>부품식별표발행(용기)</b>
			</Ribbon>

			{/* ContentXearch Frame : 검색조건 */}
			<ContentXearch optionsX={optionsX} onChange={onChange} onClick={onClick} />

			<Content style={style}>
				{' '}
				{/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:146px */}
				<div className={'grid_type01'}>
					{' '}
					{/* 상단 FRAME */}
					<div className={'grid_wrap01'}>
						{' '}
						{/* 상단 wrap FRAME */}
						<div className="grid_cont_box" style={{ height: 'calc(100%)' }}>
							<Grid
								ref={grdRom4rMain}
								gridId={'ROM4R'}                 // 필수) Grid Id 정의
								columnDefs={columnDefs}          // 필수) 컬럼 정의
								pagination={true}                // 쪽수 매기기 여부
								paginationCsv={false}            // CSV 사용구분 - pagination과 동시에 사용 필요
								paginationPageSize={'1000'}      // 쪽수 1Page 당 Row수
								rowMultiSelectWithClick={true}   // 멀티 클릭 가능 여부
								suppressRowClickSelection={true} // 클릭으로 선택 하는것을 방지
								rowSelection={'multiple'}        // 여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
								enableRangeSelection={false}        
								enableCellTextSelection={true}   // cell selection drag mode
								isFirst={true}                   // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
								displayNo={false}
								searchApi={searchApi}            // 조회
								onClearApi={onClearApi}          // 초기화
								clearGrid={clearGrid}
								onClearGridFlag={setClearGrid}
								onCellValueChangedEvent={grdRom4rMain_onCellValueChanged}
								onRowClickEvent={grdRom4rMain_onRowClicked}
								onCellKeyDownEvent={grdRom4rMain_onCellKeyDown}
							/>
						</div>
					</div>
				</div>
			</Content>

			{/* 프린트 */}
			{bigi == 'Y' /* 비기사용여부 */ ? (
				<></>
			) : Common.getToken('propt') === 'Y' /* 신규 인쇄 사용여부 */ ? (
				<>
					{/* ==NEW== "A(4)" TYPE QRCODE */}
					<div className="printArea">
						<ReactToPrint trigger={() => <button className="btn" ref={printBtnRef} style={{ display: 'none' }} />} pageStyle={pageStyle2} content={() => printRef.current} />
						<WrapCkdLabelPrint_QR_A_NEW ref={printRef} printData={printdata} idata={idata} />
					</div>

					{/* ==NEW== "B(6)" TYPE QRCODE */}
					<div className="printArea">
						<ReactToPrint trigger={() => <button className="btn" ref={printBtnRef2} style={{ display: 'none' }} />} pageStyle={pageStyle2} content={() => printRef2.current} />
						<WrapCkdLabelPrint_QR_B_NEW ref={printRef2} printData={printdata2} idata={idata} /> 
					</div>

					{/* ==NEW== "C(12)" TYPE BARCODE */}
					<div className="printArea">
						<ReactToPrint trigger={() => <button className="btn" ref={printBtnRef3} style={{ display: 'none' }} />} pageStyle={pageStyle2} content={() => printRef3.current} />
						<WrapCkdLabelPrint_C_NEW ref={printRef3} printData={printdata3} idata={idata} />
					</div>
				</>
			) : (
				<>
					{/* --기존-- "A(4)" TYPE QRCODE */}
					<div className="printArea">
						<ReactToPrint trigger={() => <button className="btn" ref={printBtnRef} style={{ display: 'none' }} />} pageStyle={pageStyle} content={() => printRef.current} />
						<WrapCkdLabelPrintPercentQR ref={printRef} printData={printdata} idata={idata} />
					</div>

					{/* --기존-- "B(6)" TYPE QRCODE */}
					<div className="printArea">
						<ReactToPrint trigger={() => <button className="btn" ref={printBtnRef2} style={{ display: 'none' }} />} pageStyle={pageStyle} content={() => printRef2.current} />
						<WrapCkdLabelPrintPercentQRB ref={printRef2} printData={printdata2} idata={idata} /> 
					</div>

					{/* --기존-- "C(12)" TYPE BARCODE */}
					<div className="printArea">
						<ReactToPrint trigger={() => <button className="btn" ref={printBtnRef3} style={{ display: 'none' }} />} pageStyle={pageStyle} content={() => printRef3.current} />
						<WrapCkdLabelPrintC ref={printRef3} printData={printdata3} idata={idata} />
					</div>
				</>
			)}
		</>
	);
}
export default ROM4R;
