/**
 * @Page ROM86
 * @Description [ROM86]납입지시 - 시작차 출하관리 - 시작차 발주현황
 **/
import AlertDialog from "@/components/material-UI/AlertDialog";
import CustomAlert from "@/components/molecules/CustomAlert";
import Ribbon from "@/components/organisms/Ribbon";
import ContentXearch from "@/components/organisms/ContentXearch";
import Content from "@/components/organisms/Content";
import Grid from "@/components/grid/agGrid";
import React, {useCallback, useEffect, useRef, useState} from "react";
import * as Common from "@/utils/Common";
import CommonUtil from "@/utils/CommonUtil";
import Popup from '@/pages/Popup/Popup';    
import Loading from "src/components/atoms/CustomLoading2";


const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const Utils = new CommonUtil();


function ROM86() {
    const token = Common.getToken();
    const userId = Common.getToken("id");
    // const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const bigi = Common.getToken("bigi");
    const grdRom86Main = useRef();   //Grid Main
    // const printRef = useRef();
    // const printBtnRef = useRef();

    const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
    let [loading, setLoading] = useState(false); 

    //Data
    const [idata, setData] = useState({
        //조회조건
        kGubn: `(+)`,
        kGubn2: 'A',
        kSort: '1',
        kOdno: "",
        kDlno: "",
        kFndt: "",
        kTndt: "",
        // kVnt1 : tVnt1,      //T1업체
        kVnt1 : ckVnt1,        //T1업체     
        kComp: 'EHKR',       //회사구분
        kPlnt: "",          //공장
        kGate: "",          //하치장
        kPldt: Common.getToday(),  //소요일자
        kPtno2 : "",         //품번
        kFidt_f: Common.getToday(),   //시작일
        kTelno: "",         //연락처
        kFidt_t: Common.getToday(),   //종료일
        kUsyn: "*",
        kInfo_vand: "",
        kProj:"",


        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장
        projList : [],  //프로젝트
        canoList : [],  //차량번호
        compList : [],  //회사
    });


    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;

    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      // Dialog Transaction(I,U,D)
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;

    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 

    //적용호차 Popup
    const [typeOpen, setTypeOpen] = React.useState(false);
    const [data, setDatas] = useState([]);

    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 100px  - 50px)"});

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
    }, []);



    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success)
                    {
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }

    const onSearchCommonAsync = async (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success)
                    {
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        await fetchData(para, name);
    }



    /**
     *MOUNT
     */
    useEffect( () => {
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
        
        //공통코드 조회 (공장)
        onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");
        //공통코드 조회 (차량번호)
        onSearchCommon(`{"pgmid":"CARN"}`, "canoList");
        //공통코드 조회 (회사)
        onSearchCommon(`{"pgmid":"COMP"}`, "compList");
        // 프로젝트 조회
        onSearchCommon(`{"pgmid":"PROJ","kVand":"${idata.kVnt1}"}`, "projList")
    }, []);



    /**
     * Control 변경 Event
     * @param {*} e
     */
    const onChange = async (e) => {
        const {name, value, desc} = e.target;

        let newSearchs = idata;
        newSearchs[name] = value;
        newSearchs[name+"Nm"] = desc;
        setData({ ...newSearchs });

    }

    const grdRom86Main_onRowClicked = async (e) => {
    }


    const editCellClicked =  (params) =>{
        let data =  [];

        data["kVnt1"] = idata.kVnt1;
        data["kPlnt"] = idata.kPlnt;
        data["kComp"] = idata.kComp;
        data["odno"] = params.data.odno;

        setDatas({ ...data });

        setDatas(data);
        setTypeOpen(true);
    }



    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //T1업체
            {
                formatter: "select", name: "kVnt1", labelName: "T1업체", type: "text", defaultValue: idata.kVnt1,  
                codeMode: true,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: idata.vnt1List, 
                align: "left", 
            },
            //현황
            {
                formatter: "radio", name: "kGubn2", labelName: "현황", type: "text", desc: "", readOnly: false, defaultValue: idata.kGubn2,
                options: [{ "name": "kGubn1", "labelName": "유효발주", "setValue": "A" }, { "name": "kGubn2", "labelName": "발주이력", "setValue": "H" }], 
                align: "left", 
                labelType: "none", 
            },
            //  // 발행일자 시작
            {
                formatter: "date2", type: "date", labelName: "발주일자", name: "kFidt_f", defaultValue: idata.kFidt_f
                , name2: "kFidt_t", defaultValue2: idata.kFidt_t
                , align: "left" // (우측 정렬)
                , labelType: "none" 
            },
            // 발행일자 종료
            // {
            //     formatter: "input", name: "kFidt_t", type: "date", labelName: "~"
            //     , defaultValue: idata.kFidt_t
            //     , align: "left" // (우측 정렬)
            //     , labelType: "none" 
            // },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
        ],
        1: [
             //회사구분
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": true,
                "selectAll": "",
                //"codes": CODES["comp"],
                "comms": null,
                "align": "left", 
                "items": [{ "comCode": "EHKR", "text": "남양시작차" }]
            }, 
            //프로젝트
            {
                "formatter": "select", "name": "kProj", "labelName": "프로젝트", "type": "text", "defaultValue": idata.kProj,  
                "codeMode": true,
                "selectAll": true, // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                "codes": null,
                "comms": idata.projList,
                "align": "left", 
            }, 
            //정렬순서
            {
                "formatter": "select", "name": "kSort", "labelName": "정렬순서", "type": "text",  
                "codeMode": false,
                "selectAll": false, // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                //"codes": null,
                "comms": null,
                "align": "left", 
                "items": [{ "comCode": "1", "text": "발주일자+품번+발주번호" },{ "comCode": "2", "text": "발주일자+발주번호+품번" },{ "comCode": "3", "text": "품번+발주일자+발주번호" }
                ,{ "comCode": "4", "text": "품번+발주번호+발주일자" },{ "comCode": "5", "text": "발주번호+발주일자+품번" },{ "comCode": "6", "text": "발주번호+품번+발주일자" }]
            }, 
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
        ],
        2:[
            // 공장
            {
                formatter: "select", name: "kPlnt", labelName: "공장", type: "text",  
                codeMode: false,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: idata.plntList,
                align: "left", 
            },
            // 발주번호
            {
                formatter: "input", name: "kOdno", type: "text", labelName: "발주번호"
                , defaultValue: idata.kOdno
                , align: "left" // (우측 정렬)
                , labelType: "none" 
            },
            // 품번
            {
                formatter: "input", name: "kPtno2", type: "text", labelName: "품번"
                , defaultValue: idata.kPtno2
                , align: "left" // (우측 정렬)
                , labelType: "none" 
            },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
        ]
    }

    

    /**
     * 조회
     * @param {*} e
     * @returns
     */
    const onSearch = async (e) => {
        if(idata.kPlnt === ''){
            idata.kPlnt=idata.plntList[0].comCode;
        }

        if(Common.getDateCa(idata.kFidt_f,6,'m')<idata.kFidt_t){
            setMessage({ openAlert : true, 
                severity : "info",
                msg : "발행일자는 6개월 이내로 설정바랍니다"});          
            return ;
        }


        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);

        let para = `{
                        "kVnt1": "${idata.kVnt1}", "kComp": "${idata.kComp}",
                        "kGubn2": "${idata.kGubn2}", "kProj": "${idata.kProj}",
                        "kSort": "${idata.kSort}", "kPlnt": "${idata.kPlnt}", "kPtno": "${idata.kPtno2}",
                        "kFidtf": "${idata.kFidt_f.replaceAll('-','')}",
                        "kFidtt": "${idata.kFidt_t.replaceAll('-','')}",
                        "kOdno": "${idata.kOdno}"
                     }`;

        let param = encodeURI(para);

        const query = SERVER_URL + "/rom86/search?query=" + param;
        setSearchApi(query); // Search API
    }



    /**
     * 신규 Row추가
     * @param {*} e
     */
    const onAddRow = async (e) => {
    }



    /**
     * 입력 (발행)
     * @param {*} e
     */
    const onInsert = async (e) => {

    }



    /**
     * 수정
     * @param {*} e
     * @returns
     */
    const onUpdate = async (e) => {
    }

    /**
     * 삭제
     * @param {*} e
     */
    const onDelete = async (e) => {
    }

    /**
     * 엑셀
     * @param {*} e
     */
    const onExcel = async (e) => {
        if(idata.kPlnt === ''){
            idata.kPlnt=idata.plntList[0].comCode;
        }

        if(Common.getDateCa(idata.kFidt_f,6,'m')<idata.kFidt_t){
            setMessage({ openAlert : true, 
                severity : "info",
                msg : "발행일자는 6개월 이내로 설정바랍니다"});          
            return ;
        }

        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);

        let para = `{
                        "kVnt1": "${idata.kVnt1}", "kComp": "${idata.kComp}",
                        "kGubn2": "${idata.kGubn2}", "kProj": "${idata.kProj}",
                        "kSort": "${idata.kSort}", "kPlnt": "${idata.kPlnt}", "kPtno": "${idata.kPtno2}",
                        "kFidtf": "${idata.kFidt_f.replaceAll('-','')}",
                        "kFidtt": "${idata.kFidt_t.replaceAll('-','')}",
                        "kOdno": "${idata.kOdno}"
                     }`;


        let param = encodeURI(para);

        const query = SERVER_URL + "/rom86/excel?query=" + param;

        //엑셀파일 조회 시 로딩오픈
        setLoading(true);
        // setLoading(false);

        await fetch(query, {headers: {Authorization: token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'rom86.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })

        //엑셀파일 조회 시 로딩 닫기
        // setLoading(true);
        setLoading(false);
    
        // 엑셀파일 생성 메세지
        setMessage({ openAlert : true, 
                     severity : "success",
                     msg : "엑셀파일이 생성되었습니다."});
    }
    


    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans)
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D2" :
                break;

            case "D3" :
                break;
        }
    }


    /**
     *Grid Column 정의
     */
        //Main Grid Column
    const mainColumnDefs = [
            { headerName: "프로젝트명", field: "proj", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
            { headerName: "발주일자", field: "ordt", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,valueFormatter: Utils.dateFormatter, hide: false },
            { headerName: "발주번호", field: "odno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
            { headerName: "발주수량", field: "odqt", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "잔량", field: "jaqt", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "입고량", field: "igto", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "담당자", field: "crdx", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "구분", field: "odgb", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "납기일자", field: "nidt", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,valueFormatter: Utils.dateFormatter, hide: false },
            { headerName: "프로젝트설명", field: "prnm", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
            { headerName: "UPG", field: "upg", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
            { headerName: "부품사양", field: "optn", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
            { headerName: "품명", field: "ptnm", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
            { headerName: "생성일시", field: "isdt", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "적용호차", field: "jyno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", onCellClicked: editCellClicked,cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false
            ,cellStyle: params => {//cell배경색 변경
                {return {color: "#022dff",fontWeight:"bold",cursor:"pointer"};}} },
        ];

    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px)"
                });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 100px  - 50px)"
                });
        }
    }

    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({...message, openAlert:false})}} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} />

            <Popup type="JynoPopup" open={typeOpen} onClose={()=> {setTypeOpen(false)}} data = {data} />

            {/* Loading */}
            <Loading loading={loading} /> 

            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onSearch={onSearch} onSearchName={null}     //조회
                onExcel={onExcel} onExcelName ={null}   //Excel
            >
                {"납입지시 > 시작차 출하관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>시작차 발주현황</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick={onClick}/>


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:100px */}
                <div className={"grid_type01"}> {/* 상단 FRAME */}
                    <div className="grid_cont_box" style={{ height: "calc(93%)" }}>
                        <Grid
                            ref={grdRom86Main}
                            gridId={"ROM86"}                    //  (필수) Grid Id 정의
                            columnDefs={mainColumnDefs}             //  (필수) 컬럼 정의
                            pagination={true}                  //  쪽수 매기기 여부
                            paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                            paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                            rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                            suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                            rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                            enableRangeSelection={false}        //
                            enableCellTextSelection={true}      // cell selection drag mode
                            isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                            displayNo = {false}
                            searchApi={searchApi}               // 조회
                            onClearApi={onClearApi}             // 초기화
                            onRowClickEvent={grdRom86Main_onRowClicked}
                        />
                    </div>
                </div>
            </Content>
        </>
    )
}
export default ROM86;