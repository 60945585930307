import React from 'react';

import '../../css/main.css';
// import '@/css/main.css';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         '& .MuiTextField-root': {
//             margin: theme.spacing(1),
//             width: '25ch',
//         },
//     },
// }));

function CustomForm({ children, name, align, forId,type,page, check, labelAlign }) {
    // const classes = useStyles();

    let formBoxStyle = {
        marginRight: "auto"
    }

    let rom3zstyle = {display: "block"}
    let rom3zlabelstyle = {marginRight: "auto"}

    
    if (align) {
        if (align === "left") {
            formBoxStyle = {
                marginRight: "auto"
            }
        } else if (align === "right") {

            formBoxStyle = {
                marginLeft: "auto",
            }
        }
    }

    // 납입지시 > 현황관리 > 납품서 발행현황 [ ERP ] rom3z일때 검색조건 위치 조정..
    if(page === "ROM3Z"){
        if(forId==="kGubnn"||forId==="kVnt1"){
            rom3zstyle = {display: "none",textAlign: "right",float: "right",width: "130px"}
            if(forId==="kGubnn"){
                rom3zlabelstyle = {display: "flex",width:"142px"}
            }else{
                rom3zlabelstyle = {display:"contents"}
            }
        }else if(forId === "kPlnt"){
            rom3zstyle = {display: "block",textAlign: "right",float: "right",width: "82px",minWidth:"53px"}
            rom3zlabelstyle = {marginRight: "auto",width:"59%"}
        }else if(forId === "kFndt"){
            rom3zstyle = {display: "block",textAlign: "right",float: "right",width: "127px",minWidth:"129px"}
            rom3zlabelstyle = {marginRight: "auto",width:"77%"} // min-width: 23%
        }else if(forId === "kComp"){
            rom3zstyle = {display: "block",textAlign: "right",float: "right",width: "131px"}
            rom3zlabelstyle = {marginRight: "auto",width:"52%"}
        }else{
            rom3zstyle = {display: "block",textAlign: "right",float: "right",width: "131px"}
        }
    }


    return (
        <>
            <div className={"form_box"} style={page ==="ROM3Z"?rom3zlabelstyle:formBoxStyle}>

            {check?
                <div className="h_Label1R" style={labelAlign?{textAlign:labelAlign}:{textAlign:"right"}}>
                    {check}
                </div>
                
            :            
                 <div className={"h_Label1R" + (type ? " h_Label1R_" + type : "")}  style={forId==="chk1"?{display: "none"}:rom3zstyle} > {/*style={forId === "kGubnn"?{display: "none"}:{display: "flex"}}*/}
                  
                    {name}
                </div>
            }

                {children}

            </div>
        </>
    );
}
// export default withRouter(Header);
export default CustomForm;