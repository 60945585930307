/**
 * @Page ROMAA
 * @Description [ROMAA]납입지시 - ERP_CKD출하관리 - 납품서그룹발행
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import GridRibbon from "@/components/organisms/GridRibbon";
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
 import ReactToPrint from "react-to-print";
 import CkdCardPrint from "@/components/molecules/CkdCardPrint";
 import Loading from "src/components/atoms/CustomLoading2"; 

  
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();

 
 // Print Style CSS Setting
 const pageStyle = `
@media all {
    .pagebreak {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .pagebreak {
      margin-top:1rem;
      display: block;
      page-break-after: always;
    }
  }
  
  @page {
    size: A4 portrait !important;
    margin:7mm 10mm;
  }
  body, div, table, td{
      font-family:굴림체,Gulim ;
  }

`;

 function ROMAA() {
 
    const token = Common.getToken();
    const userId = Common.getToken("id");
    // const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const bigi = Common.getToken("bigi");
    const grdRomaaMain = useRef();   //Grid Main
    const grdRomaaList = useRef();   //납품서 발행현황 Grid
    const grdRomaaDetail= useRef();  //납품서 상세현황 Grid
    const printRef = useRef();
    const printBtnRef = useRef();


    const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
    const ckName = Common.getCookie("ck_ename")===null?"":Common.getCookie("ck_ename"); 
    const ckTelno = Common.getCookie("ck_telno")===null?"":Common.getCookie("ck_telno");     
    const ckJmno = Common.getCookie("ck_jmno")===null?"":Common.getCookie("ck_jmno"); 

    const pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크
    const pattern_num = /[0-9]/;	// 숫자 
    const pattern_spc = /[~!.'/@#$%^&*()_+|<>?\\:={}-]/; // 특수문자
    const pattern_eng = /[a-zA-Z]/;	// 문자 

    
    //Data
    const [idata, setData] = useState({
        //조회조건
        // kVnt1 : tVnt1,      //T1업체
        kVnt1 : ckVnt1,        //T1업체     
        kComp: tComp,       //회사구분
        kPlnt: "",          //공장
        kGate: "",          //하치장
        kNidt: Common.getToday(),  //납기일자
        kPtno : "",         //품번
        kCano : "",         //차량번호          
        kGubn: "N",         //납기일자 구분
        kName: ckName,          //담당자    
        kSttm: Common.getToday(),   //출발일자
        kSthh: Common.getHour(),    //출발시간
        kTelno: ckTelno,         //연락처   
        kEdtm: Common.getToday(),   //도착일자
        kEdhh: Common.getHour(),    //도착시간 
        kJmno: ckJmno,         //주민번호   

        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장
        gateList : [],  //하치장
        canoList : [],  //차량번호
    }); 
    
            
    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;
  
  
    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      // Dialog Transaction(I,U,D)
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
  
   
    // Params (Insert, Update Parameter)
    let [inserts, setInsert] = useState({}); 

    let [loading, setLoading] = useState(false); 

    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    let [insertApi, setInsertApi] = useState(false); 
    let [searchApi2, setSearchApi2] = useState(false); 
    let [deleteApi2, setDeleteApi2] = useState(false); 
    let [searchApi3, setSearchApi3] = useState(false); 
    let [updateApi3, setUpdateApi3] = useState(false); 
    let [deleteApi3, setDeleteApi3] = useState(false); 
    let [printdata, setPrintData] = useState([]);   
    let [pos, setPos] = useState([]); //납품서 좌표

    let [clearGrid, setClearGrid] = useState(false);    
    let [clearGrid3, setClearGrid3] = useState(false);  
    
    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 146px - 50px)"});

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
        setInsertApi(false);
    }, []);

    const onClearApi2 = useCallback(async (e) => {
        setSearchApi2(false);
        setDeleteApi2(false);
    }, []);

    const onClearApi3 = useCallback(async (e) => {
        setSearchApi3(false);
        setUpdateApi3(false);
        setDeleteApi3(false);
    }, []);

    


    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }
     

    
    /**
     *MOUNT 
    */
    useEffect( () => {
        
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
        //공통코드 조회 (공장)
        onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"CKD"}`, "plntList");
        //공통코드 조회 (차량번호)
        onSearchCommon(`{"pgmid":"CARN"}`, "canoList");
        //납품서 발행현황조회
        onSearch2();
        
        setTimeout(() => 
            alert(`안녕하세요\n현대자동차에서 납품서를 품목, FROM ~TO 오더 별로
묶음 발행하는 기능을 필요로 해서 만들어진  신규화면입니다\n기존 납품서와는 달리 CASE 번호는 표기 되지 않으며
FROM ~ TO 오더, 품번 기준으로 묶음 발행됩니다.\n자세한 사항은 질문게시판이나, 아이티엔제이 고객지원 팀에게
연락 주십시오`) 
        , 500);

    }, []);
  
    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value} = e.target;
        let val = "";

        //숫자만 입력 Check (주민번호)
        if (name === "kJmno" ) {
            if (pattern_spc.test(value) || pattern_kor.test(value) || pattern_eng.test(value)) return
        }

        let newSearchs = idata; 
        newSearchs[name] = value
        setData({ ...newSearchs });


        if (name === "kVnt1" || name === "kComp")  {//t1업체, 회사 변경 시
            setClearGrid(true); // grid 1 초기화
            
            newSearchs["plntList"] = [];
            newSearchs["gateList"] = [];
            newSearchs["kPlnt"] = "";
            newSearchs["kGate"] = "";
            setData({ ...newSearchs });

            //공통코드 조회 (공장)
            onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"CKD"}`, "plntList");

            //납품서 발행현황 조회
            onSearch2();
        }else if (name === "kPlnt") { //공장 변경 시 하치장 조회

            setClearGrid(true); // grid 1 초기화

            val = Common.changeEDTM(idata.kSttm,idata.kSthh);
            
            newSearchs["kGate"] = "";
            newSearchs["kEdhh"] = val.endHour;

            setData({ ...newSearchs });
            
            onSearchCommon(`{"pgmid":"CKDGATE","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", "kGubn":"${idata.kGubn}", "kNidt":"${idata.kNidt}"}`, "gateList")//공통코드 하치장조회
        }else if(name === "kNidt"){//납기일자 변경 시 하치장 조회
            setClearGrid(true); // grid 1 초기화

            onSearchCommon(`{"pgmid":"CKDGATE","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", "kGubn":"${idata.kGubn}", "kNidt":"${idata.kNidt}"}`, "gateList")//공통코드 하치장 조회
        }else if(name === "kSttm"){//출발일자가 선택되면 도착일자도 변경
            setClearGrid(true); // grid 1 초기화

            newSearchs["kEdtm"] = value;
            
            setData({ ...newSearchs });
        }
        else if(name === "kSthh"){//출발시간이 선택되면 도착시간 = 출발시간 +1
            setClearGrid(true); // grid 1 초기화

         
           val = Common.changeEDTM(idata.kSttm,value);

           newSearchs["kEdtm"] = val.endDate;
           newSearchs["kEdhh"] = val.endHour;

            setData({ ...newSearchs });
            
        }else if (name === "kGate" )  {//하치장 변경시 조회 Call
            onSearch(); //조회 Call
        }
    }
 
  
  
    /**
     * Grid Event 
     * @param {*} e 
     */
    const grdRomaaMain_onCellValueChanged = async (e) => {

        let data = e.data;

        if (data.npqt === "0" || data.npqt === "") {
            e.node.setSelected(false);
        }

      //  { headerName: "소요수량", field: "plqt",
        
        //발주량 대비 납품수량 Check
        if (e.column.colId === "npqt") {

            let npqt = !isNaN(parseInt(data.npqt)) ? parseInt(data.npqt) : 0;//납품수량
            let jqty = !isNaN(parseInt(data.jqty)) ? parseInt(data.jqty) : 0;//D2잔량
            let rjqty = !isNaN(parseInt(data.rjqty)) ? parseInt(data.rjqty) : 0;//랩도스잔량

            if (npqt  > jqty) {
                setMessage({ openAlert : true, 
                             severity : "info",
                             msg : "발주 잔량보다 더 많이 납품할 수 없습니다."});
                e.node.setDataValue('npqt', 0);                             
                return 
            }
            else {
                e.node.setDataValue('jaqt', jqty - npqt );    
                e.node.setDataValue('rjaqt', rjqty - npqt);    
            }
        }
    }

    const grdRomaaMain_onCellClicked = async (e) => {
        let data = e.data;
        if (e.column.colId === "npqt") { 

            if (data.bccc !=='C') {
                let npqt = !isNaN(parseInt(data.npqt)) ? parseInt(data.npqt) : 0;//납품수량
                let jqty = !isNaN(parseInt(data.jqty)) ? parseInt(data.jqty) : 0;//D2잔량
                let rjqty = !isNaN(parseInt(data.rjqty)) ? parseInt(data.rjqty) : 0;//랩도스잔량

                if (npqt === 0 ) {
                    grdRomaaMain.current.SetFocus(-1, "npqt");
                    e.node.setDataValue('npqt', jqty);  
                    e.node.setDataValue('jaqt', jqty - npqt );    
                    e.node.setDataValue('rjaqt', rjqty - npqt); 
                    grdRomaaMain.current.SetFocus(e.rowIndex, "npqt");
                }
            }
        }
    }

    const grdRomaaMain_onRowClicked = async (e) => {
        
    }

    //납품서 발행현황 CellValueChanged
    const grdRomaaList_onCellValueChanged = async (e) => {

    }
    //납품서 상세현황 CellValueChanged
    const grdRomaaDetail_onCellValueChanged = async (e) => {
    }

    //남품서 발행현황 Row Click
    const grdRomaaList_onRowClicked = async (e) => {

        let dlno = e.data.wrcdlno;
        let wrcvnt1 = e.data.wrcvnt1;
        

        if (dlno != null || dlno != "") 
        {
            //납품서 상세현황 조회
            onSearch3(wrcvnt1, dlno);
        }
    }

   const grdRomaaList_onRowSelected = async(e) => {

        let dlno = e.data.wrcdlno;
        let wrcvnt1 = e.data.wrcvnt1;
        

        if (dlno != null || dlno != "") 
        {
            //납품서 상세현황 조회
            onSearch3(wrcvnt1, dlno);
        }

   }
  
    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //T1업체
            {
                "formatter": "select", "name": "kVnt1", "labelName": "T1업체", "type": "text", "defaultValue": idata.kVnt1,  
                "codeMode": true,
                "selectAll": "",
                "codes": null,
                "comms": idata.vnt1List,
                "align": "left", 
            }, 
            //회사구분
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": true,
                "selectAll": "",
                "codes": CODES["comp"],
                "comms": null,
                "align": "left", 
            }, 
            {"formatter": "blank", "align": "right" },
            //차량번호
            {
                "formatter": "select", "name": "kCano", "labelName": "차량번호", "type": "text", "defaultValue": idata.kCano,  
                "codeMode": false,
                "selectAll": "none",
                "codes": null,
                "comms": idata.canoList,
                "align": "right", 
                "labelType": "insert", 
            }, 
            //담당자
            {
                "formatter": "input", "name": "kName", "labelName": "담당자", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kName,  
                "align": "right", 
                "labelType": "insert", 
            }, 
        ],
        1: [
            //공장
            {
                "formatter": "select", "name": "kPlnt", "labelName": "공장", "type": "text", "defaultValue": idata.kPlnt,  
                "codeMode": false,
                "selectAll": "none",
                "codes": null,
                "comms": idata.plntList,
            }, 
            //하치장
            {
                "formatter": "select", "name": "kGate", "labelName": "하치장", "type": "text", "defaultValue": idata.kGate,  
                "codeMode": false,
                "selectAll": true,
                "codes": null,
                "comms": idata.gateList,
            }, 
            {"formatter": "blank", "align": "right" },
            //출발시간 날짜 & 시간
            {
                "formatter": "dateHour", "name": "kSttm", "name2": "kSthh", "labelName": "출발시간"
                , "defaultValue": idata.kSttm
                , "defaultValue2": idata.kSthh  
                , "align": "right" // (우측 정렬)
                , "labelType": "insert" 
            },
            //연락처
            {
                "formatter": "input", "name": "kTelno", "labelName": "연락처", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kTelno,  
                "align": "right", 
                "labelType": "insert", 
            },  
        ],
        2: [
            //납기일자 + 만조회
            {
                "formatter": "dateCheck", "name": "kNidt", "labelName": "납기일자", "type": "date", "desc": "", "readOnly" : false,
                "defaultValue": idata.kNidt,  
                "align": "left", 
                "labelType": "none", 

                "name2": "kGubn", "labelName2": " ",
                "options2": [{ "name": "kGubn", "labelName": "만 조회", "defaultValue": idata.kGubn }] 
            },

            //품번
            {
                "formatter": "input", "name": "kPtno", "labelName": "품번", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kPtno,  
                "align": "left", 
                "labelType": "none", 
            }, 
            
            {"formatter": "blank", "align": "right" },
            //도착시간 날짜 & 시간
            {
                "formatter": "dateHour", "name": "kEdtm", "name2": "kEdhh", "labelName": "도착시간",
                "defaultValue": idata.kEdtm,
                "defaultValue2": idata.kEdhh,  
                "labelType": "insert", 
                "align": "right", // (우측 정렬)
            },
            //주민번호
            {
                "formatter": "input", "name": "kJmno", "labelName": "주민번호", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kJmno,  
                "align": "left", 
                "labelType": "insert", 
            }, 

            
        ]
    }
  
  
  
    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {
        
        //T1업체선택 Check
        if (idata.kVnt1 == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "1차업체를 선택하십시요 !"});
            document.getElementById("kVnt1").focus();                         
            return ;
        }

        //회사구분선택 Check
        if (idata.kComp == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "회사구분을 선택하십시요 !"});
            document.getElementById("kComp").focus();                         
            return ;
        }

        //공장선택 Check
        if (idata.kPlnt == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "공장을 선택하십시요 !"});
            document.getElementById("kPlnt").focus();                         
            return ;
        }

        //하치장선택 Check
        // if (idata.kGate == "")
        // {
        //     setMessage({ openAlert : true, 
        //                  severity : "info",
        //                  msg : "하치장을 선택하십시요 !"});
        //     document.getElementById("kGate").focus();                                                  
        //     return ;
        // }


        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);


        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                     "kGate":"${idata.kGate}","kPtno":"${idata.kPtno}","kNidt":"${idata.kNidt}",
                     "kGubn":"${idata.kGubn}" }`;
        
        let param = encodeURIComponent(para);

        const query = SERVER_URL + "/romaa/search?query=" + param;
        setSearchApi(query); // Search API

        onSearch2();//그리드2(납품서발행현황) 재조회
    }

  
  
    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
    }

  
      
    /**
     * 입력 (발행)
     * @param {*} e 
     */
    const onInsert = async (e) => {

        //T1업체선택 Check
        if (idata.kVnt1 == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "1차업체를 선택하십시요 !"});
            document.getElementById("kVnt1").focus();                         
            return ;
        }

        //회사구분선택 Check
        if (idata.kComp == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "회사구분을 선택하십시요 !"});
            document.getElementById("kComp").focus();                         
            return ;
        }

        //공장선택 Check
        if (idata.kPlnt == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "공장을 선택하십시요 !"});
            document.getElementById("kPlnt").focus();                         
            return ;
        }

        //하치장선택 Check
        if (idata.kGate == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "하치장을 선택하십시요 !"});
            document.getElementById("kGate").focus();                         
            return ;
        }

        //차량번호 선택 Check
        if (idata.kCano === "") {

            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "차량번호를 선택해 주세요."});
            document.getElementById("kCano").focus();
            return;
        }

        // //발행시 Linc 업체 체크


        //출발시간, 도착시간 Check
        if (idata.kSttm.replace(/-/g, '') > idata.kEdtm.replace(/-/g, '') || (idata.kSttm.replace(/-/g, '') === idata.kEdtm.replace(/-/g, '') && idata.kSthh > idata.kEdhh)) {

            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "출발시간이 도착시간보다 클 수 없습니다."});
            if (idata.kSttm.replace(/-/g, '') >idata.kEdtm.replace(/-/g, '')) {
                document.getElementById("kSttm").focus();
            } else {
                if (idata.kSthh > idata.kEdhh) {
                    document.getElementById("kSthh").focus();
                }
            }
            return;
        }

        //select nodes
        let selectedData = grdRomaaMain.current.getSelectedNodes();

        //  // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "발행할 자료가 없습니다."});
            return false;
        }

        for(var i=0;i<selectedData.length;i++){
            if (selectedData[i].data.gate !== idata.kGate) {
                setMessage({ openAlert : true, 
                    severity : "info",
                    msg : "하치장을 다시 선택해주세요"});
                    e.currentTarget.disabled = false;
                    return false;
            }
        }

        //Insert Parameter
        let newInserts = inserts;
        newInserts["vnt1"] = idata.kVnt1;  //  1차업체
        newInserts["comp"] = idata.kComp;  // 회사구분

        newInserts["cano"] = idata.kCano;  // 차량번호
        newInserts["plnt"] = idata.kPlnt;  // 공장
        newInserts["gate"] = idata.kGate;  // 하치장

        newInserts["npdt"] = idata.kSttm.replace(/-/g, '') + "" + idata.kSthh; // 출발일시 (YYYYMMDDHH)
        newInserts["dcdt"] = idata.kEdtm.replace(/-/g, '') + "" + idata.kEdhh; // 도착일시 (YYYYMMDDHH)

        setInsert({ ...newInserts });

        const query = SERVER_URL + "/romaa/insert";
        setInsertApi(query); // Insert API
        //onSearch2();
    }
  
  
  
    /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {
    }
  
    /**
     * 삭제
     * @param {*} e 
     */
    const onDelete = async (e) => {
    }



    /**
     * 납품서 발행현황 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch2= async (e) => {
        
        setClearGrid3(true); // grid 3 초기화

        //T1업체선택 Check
        if (idata.kVnt1 == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "1차업체를 선택하십시요 !"});
            document.getElementById("kVnt1").focus();                         
            return ;
        }

        //회사구분선택 Check
        if (idata.kComp == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "회사구분을 선택하십시요 !"});
            document.getElementById("kComp").focus();                         
            return ;
        }

        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/romaa/search2?query=" + param;
        setSearchApi2(query); // Search API
    }


    /**
     * 남품서 발행현황 취소
     * @param {*} e 
     * @returns 
     */
    const onDelete2 = async (e) => {
        
        //select nodes
        let selectedData = grdRomaaList.current.getSelectedNodes();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "선택한 데이터가 없습니다."});
            return false;
        }

        //삭제 확인 메세지 
        setDlgMessage({openDlg : true,  
                       dlgTrans : "D2", 
                       dlgMsg : "선택한 데이터를 취소 하시겠습니까?" });
        
    }


    
    /**
     * 납품서 발행현황 인쇄 
     * @param {*} e 
     * @returns 
     */
    const onPrint = async (e) => {
        
        // 담당자 입력 Check
        if (idata.kName == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "담당자를 입력하십시요."});
            document.getElementById("kName").focus();                         
            return ;
        }

        // 연락처 입력 Check
        if (idata.kTelno == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "연락처를 입력하십시요."});
            document.getElementById("kTelno").focus();                         
            return ;
        }

        // 주민번호 입력 Check
        if (idata.kJmno == "")
        {
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "주민번호를 입력하십시요."});
            document.getElementById("kJmno").focus();                         
            return ;
        }
        
        // Cookie 값 셋팅
        Common.setCookie("ck_ename",idata.kName,1);
        Common.setCookie("ck_telno",idata.kTelno,1);
        Common.setCookie("ck_jmno",idata.kJmno,1);

        //select nodes
        let selectedData = grdRomaaList.current.getSelectedData();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "선택한 데이터가 없습니다."});
            return false;
        }

        const fetchData = async () => {
            setLoading(true); 

            let selectDlnoList = [];
            selectedData.map(function (data, i) {

                // selectedData2.forEach(function (data, i) {
                if (data.wrcdlno) {
                    selectDlnoList.push(data.wrcdlno);
                }
            });

            // 납품서발행(ROM3I) , 출하정보전송(ROM4Q), 납품서그룹발행(ROMAA) - "/rom3i/chulha" 모두 여기 사용 !!!!
            const query = SERVER_URL + "/rom3i/chulha"; 
            let param = JSON.stringify({
                vnt1: idata.kVnt1,
                comp: idata.kComp,
                ename : idata.kName,
                telno : idata.kTelno,
                jmno : idata.kJmno,
                func: "CH", // CH : 출하전송 
                rprint: 'N',
                dlnolist: selectDlnoList,
                gubn:"B" //그룹발행구분
            });

            let printList = {};
            let ptlist = [];
            let posList = {};
            await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })
                .then((resp) => resp.json())
                .then((data) => {
                    
                    if (data.success) {
                        setPrintData(data.data.list);
                        

                        // 성공 메시지 처리 필요
                        setMessage({ openAlert : true, 
                                     severity : "success",
                                     msg : "인쇄 조회 성공 하였습니다."});
                        //print 
                        for(let i = 0; i<data.data.list.length;i++){
                            let detailPrintInfo = [];
                            let headPrintInfo2 = {};
                            let detailPrintInfo2 = {};
                            //헤더
                            headPrintInfo2={
                                vandcd:data.data.list[i].head.vnt1,
                                vandnm:data.data.list[i].head.vdnm,
                                companyNm:data.data.list[i].head.conm,
                                plnm:data.data.list[i].head.plnm,
                                gate:data.data.list[i].head.gate,
                                deliveryDate:data.data.list[i].head.prif+"**",
                                issueNo:data.data.list[i].head.dlno,
                                cano:data.data.list[i].head.cano,
                                gubn:"Y",
                                posx: parseInt(data.data.list[i].head.posx),
                                posy: parseInt(data.data.list[i].head.posy),
                            }
                            for(let z = 0; z<data.data.list[i].detail.length;z++){
                                //디테일
                                detailPrintInfo2={
                                    vandcd:data.data.list[i].detail[z].VNT1,
                                    vandnm:data.data.list[i].detail[z].VDNM,
                                    companyNm:data.data.list[i].detail[z].CONM,
                                    plnm:data.data.list[i].detail[z].PLNM,
                                    gate: data.data.list[i].detail[z].GATE,
                                    deliveryDate:data.data.list[i].detail[z].PRIF,
                                    issueNo: data.data.list[i].detail[z].DLNO,
                                    cano: data.data.list[i].detail[z].CANO,
                                    dseq: data.data.list[i].detail[z].DSEQ,
                                    nidt1:data.data.list[i].detail[z].NIDT1,
                                    case1:"",//그룹발행은 caseno 없음
                                    casec:data.data.list[i].detail[z].CASEC,
                                    dlno: data.data.list[i].detail[z].DLNO,
                                    itemNm: data.data.list[i].detail[z].PTNM,
                                    itemNo: data.data.list[i].detail[z].PTNO,
                                    npqt: data.data.list[i].detail[z].NPQT,
                                    oknm: data.data.list[i].detail[z].OKNM,
                                    bccc: "",//그룹발행은 발주번호 없음
                                    gubn: "Y",
                                    plqt: data.data.list[i].detail[z].PLQT,
                                    jaqt: data.data.list[i].detail[z].JAQT,
                                    pmgb: data.data.list[i].detail[z].PMGB===undefined?"":data.data.list[i].detail[z].PMGB,
                                    frod: data.data.list[i].detail[z].FROD,
                                    tood: data.data.list[i].detail[z].TOOD
                                }
                                detailPrintInfo.push(detailPrintInfo2);
                            
                                headPrintInfo2.vandcd=data.data.list[i].detail[z].VNT1;
                                headPrintInfo2.vandnm=data.data.list[i].detail[z].VDNM;
                                headPrintInfo2.companyNm=data.data.list[i].detail[z].CONM;
                                headPrintInfo2.plnm=data.data.list[i].detail[z].PLNM;
                                headPrintInfo2.gate= data.data.list[i].detail[z].GATE;
                                headPrintInfo2.deliveryDate=data.data.list[i].detail[z].PRIF;
                                headPrintInfo2.issueNo= data.data.list[i].detail[z].DLNO;
                                headPrintInfo2.cano= data.data.list[i].detail[z].CANO;
                            }
                            printList = {
                                headPrintInfo:headPrintInfo2,   
                                detailPrintInfo:detailPrintInfo
                            }
                            ptlist.push(printList);
                            posList={
                                posx: parseInt(data.data.list[i].head.posx),
                                posy: parseInt(data.data.list[i].head.posy)
                            }
                        }
                        setPos(posList);
                        if(data.data.list[0].head.bigi==="Y"){
                            // onPrint2(ptlist);
                            let newBigiPrintList = {};
							newBigiPrintList['printItemList'] = ptlist;
							onPrint2(newBigiPrintList);
                        }else{
                            printBtnRef.current.dispatchEvent(new Event("click", { bubbles: true }));
                        }
                        
                        setTimeout(() => onSearch2(), 300);
                    }

                    if (data.error) {
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : data.data.message==""||data.data.message ==undefined?"조회 실패":data.data.message});
                        setTimeout(() => onSearch2(), 300);
                    }

                    if(data.success===false){
                        setMessage({ openAlert : true, 
                            severity : "error",
                            msg : data.data.message!=""||data.data.message !=undefined?data.data.message:"조회 실패"});
                        setTimeout(() => onSearch2(), 300);
                    }
                })
                .catch((error) => {
                    // 에러 응답 처리
                    if (error) {
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                    }
                });
                setLoading(false); 
        };

        fetchData();
    }


    const onPrint2 = async (printData) => {

        
        let ptdata = JSON.stringify(printData);
        let body = {
            "userCode":userId,
            "type":"ckdcard",
            "json":ptdata.replaceAll('"','\''),
            "state":"REQ"
        };
        let data = JSON.stringify(body);

        // await fetch('http://rpa.bigibot.com:9400/system/print', { method: "POST", headers: {"X-AppKey" : "to1adn6pq_sw","Content-Type":"application/json"}, body: data })
        const query = SERVER_URL + "/bigi/print"; 
        await fetch(query, { method: "POST", headers: {"Content-Type":"application/json"}, body: data })
        .then((resp) => resp.json())
        .then(data => {
            let str = data.data;
            if(str.includes('bigi://ckdcard') === true){
                // window.location.href = data.data;
                window.location.href = data.data.replaceAll("\"","");
                setMessage({ openAlert : true, 
                    severity : "success",
                    msg : "BIGI를 확인해주세요."});
            }else{
                setMessage({ openAlert : true, 
                    severity : "error",
                    msg : "BIGI 프로그램을 다시 시도해주세요."});
            }
        })
        .catch(error => {
            if (error) {
                setMessage({ openAlert : true, 
                                severity : "error",
                                msg : "BIGI 프로그램을 재시작 후 다시 시도해주세요."});
            }
        });
        setTimeout(() => onSearch2(), 300);
    }

    /**
     * 납품서 상세현황 조회 
     * @param {*} wrcvnt1 
     * @param {*} dlno 
     */
    const onSearch3= async (wrcvnt1, dlno) => {

        let para = `{"wrcvnt1":"${wrcvnt1}","wrcdlno":"${dlno}"}`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/romaa/search3?query=" + param;
        setSearchApi3(query); // Search API
    }

    
    
    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        setDlgMessage({ ...dlgMessage, openDlg : false});

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans) 
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D2" :
                //const queryD2  = SERVER_URL + "/romaa/delete";
                const fetchData = async () => {
                    setLoading(true); 
                    let selectedData = grdRomaaList.current.getSelectedData();
                    let selectDlnoList = [];

                    for(var i=0;i<selectedData.length;i++){
                        if(selectedData.length === 1){
                            if (selectedData[i].wrcchul === "Y") {
                                setMessage({ openAlert : true, 
                                    severity : "error",
                                    msg : "출하전송된 납품서입니다. 회수 후 삭제해주세요"});
                                    return false;
                            }
                        }else{
                            if (selectedData[i].wrcchul === "Y") {
                                setMessage({ openAlert : true, 
                                    severity : "error",
                                    msg : "출하전송된 납품서가 포함되어 있습니다.확인 후 다시 인쇄해 주세요"});
                                    return false;
                            }
                        }
                    }
                    
                    selectedData.map(function (data, i) {
                        if (data.wrcdlno) {
                            selectDlnoList.push(data.wrcdlno);
                        }
                    });
    
                    const queryD2  = SERVER_URL + "/romaa/delete";
    
                    let param = JSON.stringify({
                        vnt1: idata.kVnt1,
                        comp: idata.kComp,
                        ename : idata.kName,
                        telno : idata.kTelno,
                        jmno : idata.kJmno,
                        func: "CL",
                        rprint: 'N',
                        dlnolist: selectDlnoList,
                        gubn:"B",//그룹발행구분
                        selectedData:selectedData
                    });
    
                    await fetch(queryD2, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })                        
                        .then((resp) => resp.json())
                        .then((data) => {
    
                            if (data.success) {
                                setMessage({ openAlert : true, 
                                            severity : "info",
                                            msg : data.data[0].message==""||data.data[0].message ==undefined?"삭제에 성공하였습니다.":data.data[0].message});
                                setTimeout(() => onSearch2(), 300);
                            }
                            if (data.success===false) {
                                setMessage({ openAlert : true, 
                                             severity : "error",
                                             msg : data.errors.message==""||data.errors.message ==undefined?"삭제에 실패하였습니다.":data.errors.message});
                                setTimeout(() => onSearch2(), 300);
                            }
                            if (data.error) {
                                setMessage({ openAlert : true, 
                                             severity : "error",
                                             msg : data.errors.message==""||data.errors.message ==undefined?"삭제에 실패하였습니다.":data.errors.message});
                                setTimeout(() => onSearch2(), 300);
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {
                                // 서버가 꺼져있거나 응답하지 않습니다.
                                setMessage({ openAlert : true, 
                                    severity : "error",
                                    msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                    setLoading(false); 
                };
                fetchData();
                setDeleteApi2(false); // Update API
                break;
        }
    }
      
    

    /**
     * Grid Bind Data
     * @returns 
    */
    const onGridBindData = (para) => {
    }
 
 

    /**
     * 엑셀
     * @param {*} e 
     */
     const onExcel = async (e) => {
        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                    "kGate":"${idata.kGate}","kPtno":"${idata.kPtno}","kNidt":"${idata.kNidt}",
                    "kGubn":"${idata.kGubn}" }`;

        let param = encodeURIComponent(para);
        const query = SERVER_URL + "/romaa/excel?query=" + param;

        setLoading(true);
        
        await fetch(query, {headers: {Authorization: token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'romaa.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })

        setLoading(false);
        setMessage({ openAlert : true, 
                        severity : "success",
                        msg : "엑셀파일이 생성되었습니다."});
    }
    

    /**
     *Grid Column 정의 
    */
    //Main Grid Column
    const columnDefs = [
        { headerName: "하치장", field: "gate", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
        { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
        { headerName: "납기일자", field: "nidt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false}, 
        { headerName: "P/D no", field: "pdno", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "판매문서", field: "seld", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "판매문서(F)", field: "frod", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "판매문서(T)", field: "tood", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "소요수량", field: "plqt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false },
        { headerName: "납품수량", field: "npqt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: params=> (params.data.bccc !== 'C'), headerClass: "editCell", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false }, 
        { headerName: "D2잔량", field: "jaqt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false}, 
        { headerName: "랩도스잔량", field: "rjaqt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false}, 
        { headerName: "인증여부", field: "bccc1", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "CASE NO", field: "caseno", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        // hidden 컬럼
        { headerName: "jqty", field: "jqty", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: true}, 
        { headerName: "rjqty", field: "rjqty", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: true}, 
    ];

    //납품서 발행 Grid Column
    const columnDefs1 = [
        { headerName: "공장", field: "wrcplnt", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.wrcchul == "N"){return {color : "red"};} return null;}},   
        { headerName: "하치장", field: "wrcgate", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.wrcchul == "N"){return {color : "red"};} return null;}},   
        { headerName: "납품서번호", field: "wrcdlno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.wrcchul == "N"){return {color : "red"};} return null;}},   
        { headerName: "납기일자", field: "wrcnidt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.wrcchul == "N"){return {color : "red"};} return null;}},   
        { headerName: "납품일시", field: "wrcnpdt1", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.wrcchul == "N"){return {color : "red"};} return null;}},   
        { headerName: "건수", field: "wrcpcnt", sortable: true, filter: true, flex: 1, minWidth: 30, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.wrcchul == "N"){return {color : "red"};} return null;}},   
        { headerName: "출하전송", field: "wrcchul", sortable: true, filter: true, flex: 1, minWidth: 30, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.wrcchul == "N"){return {color : "red"};} return null;}},   
        { headerName: "메시지", field: "msg", sortable: true, filter: true, flex: 1, minWidth: 250, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.wrcchul == "N"){return {color : "red"};} return null;}},   
        // hidden 컬럼
        { headerName: "wrcvand", field: "wrcvand", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "wrcvnt1", field: "wrcvnt1", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "wrccomp", field: "wrccomp", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "wrcnpdt", field: "wrcnpdt", sortable: true, filter: true, flex: 1, minWidth: 200, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true },
        { headerName: "wrcpldt", field: "wrcpldt", sortable: true, filter: true, flex: 1, minWidth: 200, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true },
    ];

    //납품서 상세현황 Grid Column
    const columnDefs2 = [
        { headerName: "순번", field: "wrcdseq", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "품번", field: "wrcptno", sortable: true, filter: true, flex: 1, minWidth: 230, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
        { headerName: "납품수량", field: "wrcnpqt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", valueFormatter: Utils.numberFormatter, singleClickEdit: true, hide: false }, 
        { headerName: "Case No", field: "wrccase", sortable: true, filter: true, flex: 1, minWidth: 200, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
    ];
  
    const onClick = async (e) => {
        setPanded(!panded);


        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 146px - 55px)"
              });
        }

    }
    
      
    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={5000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:""})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

            {/* Loading */}
            <Loading loading={loading} />

            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onSearch={onSearch} onSearchName={null}     //조회
                onInsert={onInsert} onInsertName={"발행"}   //발행
                onExcel={onExcel} onExcelName ={null}   //Excel
            >
                {"납입지시 > ERP_CKD 출하관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardMedium, NanumGothic, sans-serif" }}>납품서그룹발행</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange} 
                onClick = {onClick}/>


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:146px */}
                <div className={"grid_type01"} style={{height:panded===true?"100%":"200%"}} > {/* 상단 FRAME */}
                    <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                        <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                            <Grid
                                ref={grdRomaaMain}
                                gridId={"ROMAA"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                pagination={false}                  //  쪽수 매기기 여부
                                paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                                rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={true}      // cell selection drag mode
                                isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                insertApi={insertApi}               // 등록
                                insertParam={inserts}               // 등록 및 수정시 Parmeter
                                insertRefresh={()=> {onSearch(); onSearch2();} } // 등록이후 
                                onClearApi={onClearApi}             // 초기화
                                clearGrid={clearGrid}
                                onClearGridFlag={setClearGrid}
                                onCellValueChangedEvent={grdRomaaMain_onCellValueChanged}
                                onRowClickEvent={grdRomaaMain_onRowClicked}
                                onCellClickEvent={grdRomaaMain_onCellClicked}
                            />
                        </div>
                    </div>
                </div>

                <div className="grid_type02">{/* 하단 FRAME */}
                    <div className="grid_wrap02" style={{ width:"70%", height:"100%"}}> {/* 하단 wrap 좌측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <GridRibbon title={"납품서 발행현황"}
                                onDelete={onDelete2} onDeleteName={"취소"}
                                onPrint={onPrint} onPrintName={"인쇄"} onPrintVisible = {tVnt1 !== "NRAP"}
                            >
                            </GridRibbon>
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* 납품서 발행현황 Grid */}
                                <Grid
                                    ref={grdRomaaList}
                                    gridId={"ROMAA2"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs1}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={false}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    searchApi={searchApi2}              // 조회
                                    deleteApi={deleteApi2}              // 삭제
                                    deleteRefresh={onSearch2}           // 삭제이후 조회
                                    onClearApi={onClearApi2}            // Clear
                                    onRowClickEvent={grdRomaaList_onRowClicked}     
                                    onCellValueChangedEvent={grdRomaaList_onCellValueChanged}
                                    onRowSelected={grdRomaaList_onRowSelected}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid_wrap03"> {/* 하단 wrap 우측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                        <GridRibbon title={"납품서 상세현황"} />
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* 납품서 발행현황 Grid */}
                                <Grid
                                    ref={grdRomaaDetail}
                                    gridId={"ROMAA3"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs2}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}                 // No 컴럼 Display
                                    searchApi={searchApi3}              // 조회
                                    onClearApi={onClearApi3}            // 초기화
                                    updateApi={updateApi3}              // 수정
                                    deleteApi={deleteApi3}              // 삭제
                                    onCellValueChangedEvent={grdRomaaDetail_onCellValueChanged}
                                    deleteRefresh={onSearch2}           // 삭제이후 
                                    clearGrid={clearGrid3}
                                    onClearGridFlag={setClearGrid3}                                
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Content>

            <div className="printArea">
                <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef} style={{ display: "none" }} />} pageStyle={pageStyle} content={() => printRef.current} />
                <CkdCardPrint ref={printRef} printData={printdata} pos = {pos}/>
            </div>
        </>
    )
  }
  export default ROMAA;