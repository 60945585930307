import React from 'react';

import '../../css/main.css';

import { TextField } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

function CustomInput({
    onChange, id, name, type, minDate, maxDate, readOnly, clearButton,
    defaultValue,maxLength }) {

    let wd = ""
    if(id==="kPtno2"){//검수현황 조회품번일 경우(입고일자크기에 맞춰서 품번 칸을 늘렸음)
        wd="220px"
    }else{
        wd="188px"
    }

    const handleChange = (e) => { // 검색조건 변경시 이벤트 처리 - 검색조건
        const { name, value } = e.target;
        
        let event = {
            target: {}
        };
        event.target["name"] = name;
        event.target["value"] = value;

        if (onChange) {
            onChange(event);
        }
    };

    const handleClear = (e) => { 
        
        let event = {
            target: {}
        };
        event.target["name"] = name;
        event.target["value"] = "";

        if (onChange) {
            onChange(event);
        }
    };


    return (
        <>
            <div className={"h_Label1L"} style={id==="kPtno2"?{width:"247px"}:{width:"200px"}}>
                <TextField
                    id={id}
                    name={name}
                    className={"k_input"}
                    type={type}
                    InputLabelProps={{
                        shrink: true,
                    }}
                      inputProps={ type==="date" ? {
                                                    min: minDate ? minDate : "",
                                                    max:maxDate ? maxDate : "9999-12-31",
                                                    style:{width:wd}
                                                } : {
                                                        max:"9999-12-31",
                                                        style:{width:wd}
                                                    } }
                    variant="outlined"
                    onChange={handleChange}
                    defaultValue={defaultValue}
                    value={defaultValue}
                    InputProps={{
                      endAdornment: clearButton && defaultValue && (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClear(this)}
                          title={"내용 삭제"}
                        ><ClearIcon/></IconButton>
                      )
                    }}
                />
            </div>
        </>
    );
}

export default CustomInput;