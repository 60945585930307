/**
 * @Page ROM12
 * @Description [ROM12]납입지시 > 기초관리 > 차량번호관리
 **/
import React, { useState, useEffect, useRef, useCallback  } from "react";
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
import CommonUtil from '@/utils/CommonUtil'; 
 
 
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
const Utils = new CommonUtil();

function ROM12() {
    const token = Common.getToken();
    const userId = Common.getToken("id");
    const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const grdRom12Main = useRef();   //Grid Main


    //Data
    const [idata, setData] = useState({
    }); 


    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;


    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      // Dialog Transaction(I,U,D)
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;


    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    let [insertApi, setInsertApi] = useState(false); 
    let [updateApi, setUpdateApi] = useState(false); 
    let [deleteApi, setDeleteApi] = useState(false); // deleteApi 항목 setting
    
    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
        setInsertApi(false);
        setUpdateApi(false);
        setDeleteApi(false);
    }, []);
    
    

    /**
     * 공통코드 조회
     */
    const onSearchCommon = (para) => {
    }



    /**
     *MOUNT 
    */
    useEffect( () => {
    }, []);



    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value } = e.target;
    }



    /**
     * Grid Event 
     * @param {*} e 
     */
    const grdRom12Main_onCellValueChanged = async (e) => {
    }

    const grdRom12Main_onRowClicked = async (e) => {
    }



    /**
     * 검색조건
     */
    const optionsX = {
        0: [
        ]
    }



    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {
        
        let para = `{"vand":"${tVnt1}"}`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/rom12/search?query=" + param;
        setSearchApi(query); // Search API
    }



    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
        let newItems = {cano : '', newrow : 'Y'};
        grdRom12Main.current.addRow(newItems, 0);
    }


    
    /**
     * 입력
     * @param {*} e 
     */
    const onInsert = async (e) => {

        //input check
        let input = await Promise.all ([inputCheck('I')]);
        if ( !input[0]) return;

        const query = SERVER_URL + "/rom12/insert";
        setInsertApi(query); // Insert API
    }



    /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {

        //input check
        let input = await Promise.all ([inputCheck('U')]);
        if ( !input[0]) return;
        
        const query = SERVER_URL + "/rom12/update";
        setUpdateApi(query); // Update API
    }



    /**
     * 삭제
     * @param {*} e 
     */
    const onDelete = async (e) => {
        
        //input check
        let input = await Promise.all ([inputCheck('D')]);
        if ( !input[0]) return;

        //삭제 확인 메세지 
        setDlgMessage({openDlg : true,  
                       dlgTrans : "D", 
                       dlgMsg : "선택한 데이터를 삭제하시겠습니까?" });
    }



    // input check
    const inputCheck = (gbn) => { 

     let inputFlag = true;
     //select nodes
     let selectedData = grdRom12Main.current.getSelectedNodes();


     // 선택 값 체크
     if (selectedData.length === 0) {
         //Alert 창 OPEN
         setMessage({ openAlert : true, 
                      severity : "info",
                      msg : "선택한 데이터가 없습니다."});
         return false;
     }

     
    //필수입력항목 Check
    selectedData.forEach((node) => {
        if (node.data.cano == null || node.data.cano == "" || node.data.cano === undefined) {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "일련번호를 입력하여 주십시오."});

            grdRom12Main.current.SetFocus(node.rowIndex, "cano");                             
            inputFlag = false
            return false;
        }
        else if (node.data.cano.length  !== 4 ) {
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "일련번호는 4자리 숫자만  입력하여 주십시오"});
            grdRom12Main.current.SetFocus(node.rowIndex, "cano");     
            inputFlag = false
            return;
        }
    });
     
     return inputFlag;
    }



    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans) 
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D" :
                const query = SERVER_URL + "/rom12/delete";
                setDeleteApi(query); // Update API
                break;
        }
    }
    
    


    /**
     *Grid Column 정의 
    */
    const columnDefs = [
        { headerName: "", field: "newrow", sortable: true, filter: true, flex: 1, minWidth: 600, editable: false, hide: true },  
        { headerName: "지역", field: "catu", sortable: true, filter: true, flex: 1, minWidth: 100, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },  
        { headerName: "구분(*)", field: "cata", sortable: true, filter: true, flex: 1, minWidth: 100, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },  
        { headerName: "기능(*)", field: "cats", sortable: true, filter: true, flex: 1, minWidth: 100, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },  
        { headerName: "일련번호(*)", field: "cano", sortable: true, filter: true, flex: 1, minWidth: 300, editable:  (params) => {return (params.data.newrow === 'Y'); }, headerClass: "editCell", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },  
        { headerName: "", field: "", sortable: true, filter: true, flex: 1, minWidth: 600, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },  
    ];

    
    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

                    
            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onInfoMsg={"※ 예제 : 대전-99-가-4326"}
                onSearch={onSearch} onSearchName={null}     //조회
                onAddRow={onAddRow} onAddRowName={"신규"}   //Row 추가
                onInsert={onInsert} onInsertName={null}     //등록
                onUpdate={onUpdate} onUpdateName={null}     //수정
                onDelete={onDelete} onDeleteName={null}     //삭제
            >
                {"납입지시 > 기초관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>차량번호관리</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건*/ } 
            {/* <ContentXearch
                optionsX={optionsX}
                onChange={onChange} /> */}


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={{ height: "calc(100vh - 60px - 50px - 40px )" }}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px */}
                <div className={"grid_type01"} > {/* 상단 FRAME */}
                    <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                        <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                            <Grid
                                ref={grdRom12Main}
                                gridId={"ROM12"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                pagination={true}                   //  쪽수 매기기 여부
                                paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={true}      // cell selection drag mode
                                isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                insertApi={insertApi}               // 등록
                                updateApi={updateApi}               // 수정
                                deleteApi={deleteApi}               // 삭제
                                updateRefresh={onSearch}          // 수정이후 
                                deleteRefresh={onSearch}            // 삭제이후 
                                onClearApi={onClearApi}
                                //onClearApi={()=> {setSearchApi(false); setUpdateApi(false);}}
                                onCellValueChangedEvent={grdRom12Main_onCellValueChanged}
                                onRowClickEvent={grdRom12Main_onRowClicked}
                                
                            />
                        </div>
                    </div>
                </div>
            </Content>
        </>
    )
}
 export default ROM12;