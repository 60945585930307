//납입지시 - ERP_OEM 출하관리
import { Route, Switch } from 'react-router-dom';
import ROM3Z from "@/pages/A05/ROM3Z";
import ROM3Y from "@/pages/A05/ROM3Y";
import ROM3W from "@/pages/A05/ROM3W";
import ROM90 from "@/pages/A05/ROM90";
import ROM91 from "@/pages/A05/ROM91";
import ROM92 from "@/pages/A05/ROM92";


function A05() {
    return ( 
        <Switch>
            <Route path='/A05/ROM3Z' component={ROM3Z} />
            <Route path='/A05/ROM3Y' component={ROM3Y} />
            <Route path='/A05/ROM3W' component={ROM3W} />
            <Route path='/A05/ROM90' component={ROM90} />
            <Route path='/A05/ROM91' component={ROM91} />
            <Route path='/A05/ROM92' component={ROM92} />
        </Switch>
    );
}

export default A05;
