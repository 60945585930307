import React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import "../../css/main.css";

function SnackAlert(props) {
  //  return <MuiAlert elevation={6} variant="filled" {...props} />;
  return (
    <Alert elevation={6} {...props}>
      <AlertTitle>{props.severity}</AlertTitle>
      {props.children}
    </Alert>
  );
}

function CustomAlert({ open, severity, onClose, message, duration, anchorOrigin }) {

  return (

    <Snackbar open={open} autoHideDuration={duration ? duration : 6000} onClose={onClose} anchorOrigin={anchorOrigin ? anchorOrigin : { vertical: 'bottom', horizontal: 'center' }}>
      <SnackAlert onClose={onClose} severity={severity?severity:"info"}>
        {message}
      </SnackAlert>
    </Snackbar>
  );
}
export default CustomAlert;
