import React from 'react';
// import { Link, withRouter } from 'react-router-dom';
// import { Link } from 'react-router-dom';

// import '../../css/common.css';
// import '../../css/style.css';
// import '../../css/main_style.css';
// import '../../css/maindash.css';
// import '../../css/table.css';

// import sty from '../css/main.css';


function Section({ children }) {
    // if (location.pathname === '/login') {
    //     return null;
    // }

    return (
        <>
            {/* <div className={"cont_section"}> */}

            <div className={"content_section"}>


                {/* <div className="tit_section">
                    <div className="container">
                        <div className="position_box">
                            <div className="position_list">
                                <span>Position :</span> 납입지시 &gt; 기초관리 &gt;  <b>ERP_품목관리</b>
                                    <img className="btn_help" src="/images/top_help.png" />
                                <span><img className="btn_help" src="/images/button/btn_question.png" alt="Question Botton" /></span>
                            </div>
                            <div id="popup_layer">
                                <p className="p_help"> 수기로 입력한 데이터는 납품서 발행(출하정보전송)이 불가합니다.<br />수신자료관리 – 기초관리 – 자료생성(수동)에서 데이터생성 먼저 진행해 주시기 바랍니다. </p>
                            </div>
                            <div id="BTs">
                                <button onclick="if(this.className!='btn')return; retrieve('','1')" className="btn" alt='조회'><img src="/images/button/btn_search.png" alt="아이콘" />조회</button>
                                <button onclick="if(this.className!='btn')return;check_vand('IS')" className="btn" alt='입력' src=""><img src="/images/button/btn_insert.png" alt="아이콘" />입력</button>
                                <button onclick="if(this.className!='btn')return;check_vand('UP')" className="btn" alt="수정" src=""><img src="/images/button/btn_update.png" alt="아이콘" />수정</button>
                                <button onclick="if(this.className!='btn')return;check_vand('DL');" className="btn" alt='삭제' src=""><img src="/images/button/btn_delete.png" alt="아이콘" />삭제</button>
                                <button onclick="if(this.className!='btn')return; changePLNT1();callExcel();" className="btn" alt='엑셀' src=""><img src="/images/button/btn_excel.png" alt="아이콘" />엑셀</button>&nbsp;
                            </div>
                        </div>


                    </div>
                </div> */}
                {children}

            </div>

        </>
    );
}

export default Section;
// export default React.memo(Section);