/**
 * @Page WrapCkdLabelPrint_A_NEW
 * @Description [ROM4K / ROM4R / ROM4X]납입지시 - ERP_CKD출하관리 - 부품식별표(중포장/용기/중포장2D) 인쇄  ::  A 4매
 **/
import React from "react";
import CustomDataMatrix from '@/components/atoms/CustomDataMatrix';

import * as Common from "../../utils/Common";
import styled from 'styled-components';



class WrapCkdLabelPrint_A_NEW extends React.Component {

     constructor(props) {
             super(props);
             this.state = {
                 pathNm: null
         };
     }
 
     componentDidMount() {

         if(window.location.pathname.split('/')[2] === "ROM4X") { // ROM4X 부품식별표(중포장 2D) check
             this.setState({pathNm:window.location.pathname.split('/')[2]});
         }
       }


// '바코드 = 업체코드(4) + 품번(15) + 수량(5) + LOTNO(3) + 일련번호(4)
//  tmp(0) '회사구분 / tmp(1) '지역 / tmp(2) '공장 / tmp(3) '차종 / tmp(4) '납품장소(하치장)
//  tmp(5) '품번 / tmp(6) '품명 / tmp(7) '납품수량 / tmp(8) '바코드 / tmp(9) '납품일자
//  tmp(10) '업체명 / tmp(11) "" '공정(공백) / tmp(12) '업체코드 / tmp(13) "" ' LOT NO / tmp(14) "" 'HPC(공백)
//  tmp(15) 'CASE(CKD) / tmp(16) "" '발행번호(바코드만들어서 던짐) / tmp(17) "" '용기번호 / tmp(18) '부착매수 / tmp(19) 'LH/RH
//  tmp(20) "" '사양 / tmp(21)  '발주번호(공백) / tmp(22)  'FROM-ORDER(CKD , OEM은 공정) / tmp(23)  'CKD는 공백, OEM은 HPC / tmp(24)  '용기당수
//  tmp(25) = '출력1 / tmp(26) = 'x좌표1 / tmp(27) = 'y좌표1 / tmp(28) = '색상1 / tmp(29) = 'FONT/S1
//  tmp(30) = '출력2 / tmp(31) = 'x좌표2 / tmp(32) = 'y좌표2 / tmp(33) = '색상2 / tmp(34) = 'FONT/S2
//  tmp(35) = '한글품명 / tmp(36) = 'RHD


    render() {

    const PrintStyle = styled.div`
    @media all {
        body { background-color: ;
            margin: 0px;
            padding: 0px;
            font-family: "HY견고딕";
        }
        .NanumheadM11 {
            font-family: "NanumGothic";
            font-weight:bold;
            font-size:11pt;
        }
        .HYheadM11 {
            font-family: "NanumGothic";
            font-weight:bold;
            font-size:11pt;
        }
        .HYheadM12 {
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:12pt;
            word-break:break-all;
        }
        .HYheadM14 {
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:14pt;
            word-break:break-all;
        }
        .HYheadM16 {
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:16pt;
            word-break:break-all;
        }
        .HYheadM17 {
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:17pt;
            word-break:break-all;
            vertical-align: middle;
        }
        .HYheadM18 {
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:18pt;
            word-break:break-all;
        }
        .HYheadM20 {
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:20pt;
            word-break:break-all;
        }
        
        .HYheadM22 {
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:22pt;
            word-break:break-all;
        }
        .HYheadM9 {
            font-family: "HY견고딕";
            font-size:9pt;
            word-break:break-all;
        }  
        .HYheadM8 {
            font-family: "HY견고딕";
            font-size:8pt;
            word-break:break-all;
            font-weight:bold;
        }
        .HYheadM24 {
            font-family: "HY견고딕";
            font-size:24pt;
            word-break:break-all;
        }             
        .HYheadM44 {
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:44pt;
            word-break:break-all;
            line-height : 13mm;
            z-index:10
        }
        .HYheadM27 {
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:27pt;
            word-break:break-all;
        } 

        .cell { display : flex; white-space: nowrap; z-index: 99; }

        .cell_hori{ display : flex; justify-content: center; } // 수평 중앙정렬 → 

        .cell_veri{ display : flex; align-items: center; } // 수직 중앙정렬 ↓

        .aligR { flex-direction: row-reverse; }
        
        .row2  { display: flex; flex-flow: row nowrap; }

        .row2>.cell:nth-child(1)  { width: 1mm;  }  // background : red ;
        .row2>.cell:nth-child(2)  { width: 15mm; }  // background : blue ;
        .row2>.cell:nth-child(3)  { width: 3mm;  }  // background : orange ;
        .row2>.cell:nth-child(4)  { width: 3mm;  }  // background : purple ;
        .row2>.cell:nth-child(5)  { width: 14mm; }  // background : beige ;
        .row2>.cell:nth-child(6)  { width: 13mm; }  // background : pink ;
        .row2>.cell:nth-child(7)  { width: 14mm; }  // background : orange ;
        .row2>.cell:nth-child(8)  { width: 3mm;  }  // background : green ;
        .row2>.cell:nth-child(9)  { width: 24mm; }  // background : yellow ;
        .row2>.cell:nth-child(10) { width: 10mm; }  // background : red ;
        .row2>.cell:nth-child(11) { width: 8mm;  }  // background : navy ;
        .row2>.cell:nth-child(12) { width: 3mm;  }  // background : green ;
        .row2>.cell:nth-child(13) { width: 27mm; }  // background : pink ;
        .row2>.cell:nth-child(14) { width: 1mm;  }  // background : red ;
        .row2>.cell:nth-child(15) { width: 1mm;  }  // background : blue ;
        
    }

    @media print { margin : 0  !important} 
    
    `;

    const { printData } = this.props;

    let { kStart, chkMetal, kCkdOem, chkNpdt, chkLtno, kNpdt } = this.props.idata;
    
    const mmToPx =(mm) => { let px = mm * 3.7795275591; return px; }

    const listSize = printData.length; // data length
    
    let idx = -1;
    let str = parseInt(kStart)-1;   

    idx =idx + str; // 시작 위치    

    let vseq = 0;

    let remainChk = 0;

    let mainSearchedDivArr = []; // 전체 반복 이후 새로운 배열에 전체로 담아서 진행한다.

    const cardInfo_S =
         printData ? printData.map((data, index) => {
        
        if(index === 0){
            vseq = parseInt(data.SEQ)===0?0: parseInt(data.SEQ)-1; // 바코드 표출 vseq용
        }

        // remain 표출 체크용 - remainChk -- 신규 품번의 vseq 담기
        remainChk = vseq;  // 새 품번의 remain 여부 체크하기
        
        let npqt = parseInt(data.NPQT);// parseInt(data.npqt);//parseInt(data.npqt) === NaN ? 0 : parseInt(data.npqt); // 납품수량
        let qypc = parseInt(data.QYPC); // 용기당 수
        let stck = parseInt(data.STCK); // 부착면 수 


        // 추가 표시 컬러 체크 -- START
        let tmp25 = data.TMP25; // TMP25    WRCLBL1	이중인쇄1 내용
        let tmp28 = data.TMP28; // TMP28    WRCCOL1	이중인쇄1 컬러

        let tmp30 = data.TMP30; // TMP30    WRCLBL2	이중인쇄2 내용
        let tmp33 = data.TMP33; // TMP33    WRCCOL2	이중인쇄2 컬러

        let color1 = "";
        let color2 = "";

        let dualPrint1 = "F"; 
        let dualPrint2 = "F"; 

        if(tmp25!==""){

            dualPrint1 = "T";

            if(tmp28==="B"){ color1 = "blue";
            }else if(tmp28==="G"){ color1 = "green";
            }else if(tmp28==="R"){
                color1 = "red";
            }else if(tmp28==="O"){ color1 = "#ff6600";
            }else if(tmp28==="X"){ color1 = "rgba(0, 0, 0, 0.7)"; }
            
        }
        if(tmp30!==""){

            dualPrint2 = "T";

            if(tmp33==="B"){ color2 = "blue";
            }else if(tmp33==="G"){ color2 = "green";
            }else if(tmp33==="R"){ color2 = "red";
            }else if(tmp33==="O"){ color2 = "#ff6600"; 
            }else if(tmp33==="X"){ color2 = "rgba(0, 0, 0, 0.7)"; }

        }


        let result = Math.floor(npqt / qypc); // 몫
        let resultIdx = Math.floor(npqt / qypc); // 목 + 나머지의 카운트
        let remain = npqt % qypc; // 나머지

        if(remain > 0){ resultIdx = result + 1; }

        const newSearched = [];
        let n = 0;

        Array.from(new Array(resultIdx), (i) =>{  // Main
 
            Array.from(new Array(stck), (w, j) =>{  // 부착면수 만큼 반복
            
                idx = idx+1;
            
                if(j===0){ // 부착면수 1일때
                    vseq = vseq + 1;    // 새 vseq 마다 + 1 증가 
                }
                
                let iCnt = i+1;
                const newArray = {};
                let jCnt = j+1;

                // 새 배열의 n 번째 구하기
                if(i===0 && j===0){ n=0; }else{ n=n+1; }

                Object.keys(data).forEach(key => { // 기존값 배열에 추가
                    newArray[key] = data[key];
                });
                
                newArray["idx"] = idx;
                newArray["vseq"] = vseq;
                
                //납품수량
                let newQty = 0;
                newArray["qty"] = qypc; 
                newQty = qypc; 

                newArray["endpage"] = "F"; 

                
                newArray["color1"] = color1; 
                newArray["color2"] = color2; 

                newArray["dualPrint1"] = dualPrint1; 
                newArray["dualPrint2"] = dualPrint2;
                
                if(resultIdx === (vseq-remainChk)){
                        if(remain > 0){
                            newArray["qty"] = remain; 
                            newQty = remain; 
                        }
                    }else{
                    if(index===0 && resultIdx === 1){
                        if(remain > 0){
                            newArray["qty"] = remain; 
                            newQty = remain; 
                        }
                    }
                }

                // END Page 구하기
                if(listSize === (index+1)){   
                    if(resultIdx === iCnt){
                        if(stck === jCnt){
                            newArray["endpage"] = "T"; 
                        }
                    }
                }

                const lotNo = data.TMP13===""?" ":Common.getLOT(data.TMP13); // LOT NO 날짜 가져오기  data.tmp13

                //일련번호(4)
                const prno = (data.TMP0===undefined?"":data.TMP0) + "" + ("0000" + parseInt(vseq, 10)).slice(-4);
                            
                // DataMatrix - code128 바코드 값 만들기
                //    'barcode2 = 기존바코드(data8) + 납품일자(tmp(9))의 년(2)+월(2)+일(2)+부품식별표발행순번(tem(14)의 4자리)
                //    'barcode2 = data8 & Mid(data(9), 3, 2) & Mid(data(9), 6, 2) & Mid(data(9), 9, 2) & Right("0000" & Format(data(14), "####"), 4)
                //    '20190924 이동주 이사의 의견으로 CKD 부품식별표의 SEQ를 납품일자가 아닌 현재 발행 일자로 수정

                // DataMatrix - code128 바코드 값 만들기
                //const barcodeValu = data.tmp12 +""+ (data.tmp5).padEnd(15," ") +""+ ("00000" + newQty).slice(-5) + "" + ("   " + lot).slice(-3) + ""  + ("0000" + prno).slice(-4) + "" ;
                let barcodeValu = "";

                if(kCkdOem === "CKD"){ // 중포장일때
                    // 수량 7 -> 6 으로 변경 셋팅 2022-06-30
                    // 바코드 = 업체코드(4)              + 품번(13)                       + 수량(6)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4) 

                    // 자동차 측 요구로 바코드 자릿수 변경 2023-08-21
                    // 바코드 = 오더번호(10)              + 품번(14)                       + 수량(8)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4) 
                    barcodeValu = data.TMP21 +""+ (data.TMP5.slice(0,14)).padEnd(14," ") +""+ ("00000000" + newQty).slice(-8) + "" + ((kNpdt).replace(/-/g, '').slice(-6)) + ""  + prno ;

                }else{ // 용기일때
                    // 수량 7 -> 6 으로 변경 셋팅 2022-06-30
                    // 바코드 = 업체코드(4)              + 품번(13)                       + 수량(6)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4) 

                    // 자동차 측 요구로 바코드 자릿수 변경 2023-08-21
                    // 바코드 = 오더번호(10)              + 품번(14)                       + 수량(8)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4) 
                    barcodeValu = data.TMP22 +""+ (data.TMP5.slice(0,14)).padEnd(14," ") +""+ ("00000000" + newQty).slice(-8) + "" + ((kNpdt).replace(/-/g, '').slice(-6)) + ""  + prno ;

                }
                const ptnm = (data.TMP6).slice(0,18);
                newArray["ptnm"] = ptnm; 

                // DataMatrix Barcode 만들기 - code128            
                const dataMatrixBarcode128 = <CustomDataMatrix val={barcodeValu} height={14} bcid={"code128"} scale={1.8} width={"390px"}/>;
                newArray["barcode"] = dataMatrixBarcode128; 

                const dataMatrixBarcode = (this.state.pathNm !== null) && (this.state.pathNm === "ROM4X") ? <CustomDataMatrix val={barcodeValu} height={18} scale={1} /> : "";
                newArray["barcodeMatrix"] = dataMatrixBarcode; 

                newArray["lotno"] = lotNo;
                newArray["bno"] = barcodeValu;

                // 자기 위치 셋팅 (출력매수)
                const posi = idx%4;                    
                newArray["posi"] = posi;

                newSearched[n] = newArray;
 
           })
        })
             
        mainSearchedDivArr.push(...newSearched)
 
    })
    :"";


    //빈값 배열이 들어간다...왜? > 오래걸리니 일단 빈값은 삭제하고 진행하자..
    let mainSearchedDivArr2 = mainSearchedDivArr.filter(Boolean);

    let cardInfo = 
        <div className="forfor" style={{ display : "flex",flexWrap : "wrap", overflow : "hidden", width : mmToPx(280), marginLeft :mmToPx(10 + parseInt( ( Common.getToken("bplx") ? Common.getToken("bplx") : 0 ),10)) }}>
        {/* Start Point */}
        {Array.from(new Array(kStart-1), (v, i) =>{ 
            return  <div className="start_from_test"  
                            style={{ marginTop : i == 0 || i == 1 ? mmToPx( 8 +  parseInt( ( Common.getToken("bply") ? Common.getToken("bply") : 0 ),10)) : "0"
                                    , height: mmToPx(96.5)
                                    , flexBasis: mmToPx(139.5)}}></div>
                                    }
                    )
        }
        {mainSearchedDivArr2.map((sData, dataIdx) => (
            <div className="start_from" key={"WrapCkdLabelPrint_A_NEW"+dataIdx}
                        style={{ marginTop :   sData.posi == 0 ||sData.posi == 1 ? mmToPx( 8 +  parseInt( ( Common.getToken("bply") ? Common.getToken("bply") : 0 ),10)) : "0"
                                , height :mmToPx(96.5)
                                , flexBasis: mmToPx(139.5) }}>
                <div className="dep_div" style={{ overflow:"visible", zIndex:"200", position : "relative" , height : "100%", width : "100%" }}>
                        { sData.dualPrint1 === "T" ?
                            <div style={{ position:"absolute"
                                        , fontFamily: "HY견고딕" 
                                        , top:parseInt(sData.TMP27,10) + "mm"  
                                        , left:parseInt(sData.TMP26,10) + "mm" 
                                        , color:sData.color1
                                        , fontSize:sData.TMP29+"pt" 
                                        , fontWeight:"bold"
                                        , whiteSpace:"nowrap"
                                        , zIndex:"13"} }> {sData.TMP25} </div>
                        :"" }

                        { sData.dualPrint2 === "T" ?
                            <div style={{ position:"absolute" 
                                        , fontFamily: "HY견고딕" 
                                        , top:parseInt(sData.TMP32,10) + "mm" 
                                        , left:parseInt(sData.TMP31,10) + "mm" 
                                        , color:sData.color2 
                                        , fontSize:sData.TMP34+"pt" 
                                        , fontWeight:"bold" 
                                        , whiteSpace:"nowrap"
                                        , zIndex:"13"}}>{sData.TMP30}</div>
                        : "" }

                        <div className="row2" style={{height:mmToPx(8)}}>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>    
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell HYheadM14 cell_veri cell_hori" style={{flex : "1 1 27mm"}}>{sData.TMP1}</div>{/* 지역 */}         
                            <div className="cell NanumheadM11 cell_veri cell_hori" style={{flex : "1 1 48mm"}}>
                                {sData.TMP0==="MPMC"?"현대파워텍(주)용ⓡ" : sData.TMP4==="MAMC"? "글로비스(주)용ⓡ" : "현대자동차(주)/기아(주)ⓡ"}
                            </div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div>

                        <div className="row2"style={{height:mmToPx(8)}} >
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell HYheadM20 cell_veri" style={{flex : "1 1 30mm"}}>{sData.TMP2}</div>{/* 공장 */}
                            <div className="cell" style={{flex : "1 1 14mm"}}></div> 
                            <div className="cell HYheadM16 cell_hori cell_veri" style={{flex : "1 1 27mm"}}>{sData.TMP3}</div>{/* 차종 */}
                            <div className="cell" style={{flex : "1 1 18mm"}}></div>                      
                            <div className="cell HYheadM16 cell_veri cell_hori" style={{flex : "1 1 30mm"}}>{sData.TMP4}</div>{/* 납품장소 */}
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 

                        <div className="row2" style={{height:mmToPx(12)}}>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell HYheadM18 colspan6" style={{fontSize:(sData.TMP5).trim().length>13 ? "20pt" :(sData.TMP5).trim().length>11?"23pt":"26pt", flex : "1 1 81mm"}}>
                                {sData.TMP5.substr(0, 5) + "-" + sData.TMP5.substr(5)}{/* 품번 */}
                            </div>
                            <div className="cell" style={{flex : "1 1 8mm"}} ></div>
                            <div className="cell HYheadM27 cell_veri cell_hori" style={{flex : "1 1 30mm"}}>{sData.qty}</div>{/* 수량 */}         
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 

                        <div className="row2" style={{height:mmToPx(14)}}>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell HYheadM22 cell_veri" style={{flex : "1 1 104mm"}}>{sData.ptnm}</div>{/* 품명 */}         
                            <div className="cell HYheadM44" style={{flexDirection : "row-reverse", fontSize:"44pt", color:(sData.TMP19==="좌" || sData.TMP19==="L") ?"red":"blue", textAlign:"right", zIndex:"1"}}>
                                {sData.TMP19}{/* LH/RH */}
                            </div>
                        </div> 

                        <div className="row2" style={{height:mmToPx(1)}}> <div className="cell"></div> </div>

                        {/* 상단 1mm를 아래로 합침. */}
                        <div className="row2" style={{height:mmToPx(4.5)}}>
                            <div className="cell"></div>
                            <div className="cell HYheadM17 " style={{ flex : "1 1 41mm", overflow:"visible"}}>G/W: {sData.TMP35}kg</div>
                            <div className="cell HYheadM17" style={{ flex : "1 1 54mm", overflow:"visible"}}>N/W: {sData.TMP20}kg</div>
                            <div className="cell" style={{flex : "0", zIndex : "0"}}>
                                { chkMetal==="Y" ?
                                    <div style={{position : "absolute", marginLeft : "-20mm", marginTop : "-9mm"}} >
                                        <img src ="/images/heavy_metal_confirm.gif" style={{width:"18mm"}} alt="heavy_metal_confirm" />
                                    </div>
                                :"" }
                            </div>
                        </div> 

                        <div className="row2" style={{height:mmToPx(4.5)}}>
                            <div className="cell HYheadM18 " style={{fontSize:"13pt"}}>
                                { (sData.TMP35!=="") && (Common.getTextLength(sData.TMP35) > 7) ? sData.TMP35.substr(11) : "" }
                            </div>
                            <div className="cell"></div>
                        </div> 
                        
                        <div className="row2" style={{height:mmToPx(1)}}>{/*style={{height:"2mm"}}*/}
                            <div className="cell"></div>
                        </div>
                        
                        <div className="row2" style={{height:mmToPx(11)}}>
                            <div className="cell"></div>
                            <div className="cell" style={{flex : "1 1 25mm"}}></div>
                            <div className="cell HYheadM12" style={{/* zIndex:"15", */ textAlign : "center", flex : "1 1 119mm"}}>
                                {sData.barcode}
                            </div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 

                        <div className="row2" style={{height: mmToPx(1)}}>
                            <div className="cell"></div>
                        </div>
                        

                        <div className="row2" style={{height:mmToPx(8)}}>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell" style={{flex : "1 1 2mm"}}></div>
                            <div className="cell HYheadM12 cell_veri" style={{flex : "1 1 28mm"}} >{chkNpdt&&chkNpdt==="Y"?sData.TMP9:""}</div>{/* 납품일자 chkNpdt sData.TMP9=납기일자(원복) 2022-06-27 */}
                            <div className="cell" style={{flex : "1 1 14mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 3mm"}} ></div>
                            <div className="cell HYheadM12 cell_veri" style={{flex : "1 1 15mm"}} >{sData.TMP12}</div>{/* 업체코드 */}
                            <div className="cell HYheadM12 cell_veri" style={{flex : "1 1 57mm"}} >{sData.TMP10}</div>{/* 업체명 */}        
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 
                        
                        <div className="row2" style={{height:mmToPx(8)}}>
                            <div className="cell"></div>
                            <div className="cell HYheadM12 cell_veri aligR" style={{fontSize:"11pt", fontWeight:"normal", flex : "1 1 17mm"}}>
                                {kCkdOem==="CKD"?"Order":kCkdOem==="CKD2"?"From":""}
                            </div>
                            <div className="cell" style={{flex : "1 1 3mm"}} ></div>
                            <div className="cell HYheadM12 cell_veri" style={{verticalAlign:"middle", flex : "1 1 28mm" }}>{sData.TMP22}</div>{/* order */}
                            <div className="cell" style={{flex : "1 1 14mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 3mm"}} ></div>
                            <div className="cell HYheadM27 cell_veri" style={{flex : "1 1 45mm"}}>{sData.TMP36==="Y"?"RHD":""}</div>{/* RHD */}
                            <div className="cell HYheadM12 cell_veri cell_hori" style={{flex : "1 1 27mm"}}>{/*sData.TMP21*/}</div>{/* 발주번호(공백) */}
                            <div className="cell" style={{
                                                            position : "absolute", left : mmToPx(66.5), 
                                                            fontFamily: "HY견고딕",
                                                            fontSize:"44pt", 
                                                            fontWeight:"bold",zIndex:"15"}}>{sData.TMP15}{/* TMP15 */}</div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{ position : "absolute"
                                                            , fontSize:"20pt"
                                                            , overflow:"visible"
                                                            , zIndex:"15"
                                                            , flex : "0"
                                                            , left : "127mm"
                                                            , flexDirection : "row-reverse"
                                                            , marginTop : "1.5mm" }}>
                                {sData.barcodeMatrix}
                            </div>{/* 중포장 2D 바코드 */} {/* BARCODE DataMatrix - DataMatrix 추가 표출  */}
                        </div> 
                        
                        <div className="row2" style={{height: mmToPx(8)}}>
                            <div className="cell"></div>
                            <div className="cell HYheadM11 cell_veri aligR" style={{/* fontWeight:"normal",  */flex : "1 1 17mm"}}>
                                {kCkdOem==="CKD"?"":kCkdOem==="CKD2"?"To":""}
                            </div>
                            <div className="cell" style={{flex : "1 1 3mm"}}></div>
                            <div className="cell HYheadM12 cell_veri" style={{flex : "1 1 28mm"}}>{sData.TMP23}</div>{/* order */}       
                            <div className="cell HYheadM12 cell_veri cell_hori" style={{flex : "1 1 14mm"}}>{chkLtno&&chkLtno==="Y"?sData.lotno:""}</div>{/* getLOT() chkLtno  */}         
                            <div className="cell" style={{flex : "1 1 75mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 

                        <div className="row2" style={{ height : mmToPx(0.5) }}> <div className="cell"></div> </div>
                        
                        <div className="row2" style={{height:mmToPx(6)}}>
                            <div className="cell"></div>    
                            <div className="cell" style={{flex : "1 1 19mm"}}></div>
                                <div className="cell HYheadM8 cell_veri" style={{fontWeight:"bold",  whiteSpace: "pre", flex : "1 1 71mm"}}>
                                    {sData.bno}
                                </div>{/* bno */}
                                <div className="cell" style={{flex : "1 1 20mm"}}></div>
                                <div className="cell HYheadM16 colspan3" style={{flex : "1 1 27mm"}}>{sData.TMP17}</div>{/* 용기 Mo */}
                                <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                                <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 
                        <div className="row2" style={{ height : mmToPx(1)}}> <div className="cell"></div> </div>
                    </div>
                        
                    { sData.posi===3 && sData.endpage==="F" ? <div className="page-break_5"></div>  : "" }
                    
                </div>
            ),"")}
        </div>
 
         return <PrintStyle key={"WrapCkdLabelPrint_A_NEW"}>{cardInfo}</PrintStyle>;
     }
 }
 export default WrapCkdLabelPrint_A_NEW;
 