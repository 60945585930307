import React, { createContext, useState, useContext  } from 'react';
//import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';
// import { Link, withRouter } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import '../../css/main.css';
// import sty from '../css/main.css';



import '../../css/common.css';
import '../../css/style.css';
import '../../css/main_style.css';
import '../../css/maindash.css';
import '../../css/table.css';

import CustomForm from '../../components/molecules/CustomForm';

import CustomInput from '../../components/atoms/CustomInput';
import CustomSelect from '../../components/atoms/CustomSelect';
import CustomSelect2 from '../atoms/CustomSelect2';
import CustomSelect3 from '../atoms/CustomSelect3';

import CustomCheckbox from '../../components/atoms/CustomCheckbox';
import CustomRadiobox from '../../components/atoms/CustomRadiobox';
import CustomInputDateHour from '../../components/atoms/CustomInputDateHour';
import CustomInputDateCheck from '../../components/atoms/CustomInputDateCheck';
import CustomInputSelectCheck from '../../components/atoms/CustomInputSelectCheck';
import CustomInputDateCombo from '../../components/atoms/CustomInputDateCombo';

import CustomAutoInput from '../atoms/CustomAutoInput';


import CustomInputDate from '../../components/atoms/CustomInputDate';
import CustomInputDateFromTo from '../../components/atoms/CustomInputDateFromTo';


// import CustomInputSelect from '../atoms/CustomInputSelect';
import CustomCheckbox3 from './../atoms/CustomCheckbox3';


import { display } from '@mui/system';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CopyrightSharp } from '@material-ui/icons';


interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({  
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));
function ContentXearch({optionsX, onChange, onInit, errors, onClick }) {
        // const [visible, setVisible] = useState(false)
        const [expanded, setExpanded] = React.useState(true);


    const xearchs = Object.keys(optionsX);

    let newXearchList = [];

    xearchs.forEach((_, i) => {

        let xearchGroup = optionsX[i] ? optionsX[i].map(function (XearchCol, i) {
            // optionsX[i].map(function (XearchCol, i) {

            let data = null;

            if (XearchCol.formatter === "input") {
                data =
                    <CustomForm key={"CustomForm_input" + XearchCol.name + i}
                        page={XearchCol.page}
                        forId={XearchCol.name}
                        name={XearchCol.labelName}
                        align={XearchCol.align}
                        type={XearchCol.labelType}
                        labelAlign={XearchCol.labelAlign} >

                        <CustomInput
                            page={XearchCol.page}

                            id={XearchCol.name}
                            name={XearchCol.name}
                            type={XearchCol.type}
                            minDate={XearchCol.minDate}
                            maxDate={XearchCol.maxDate}
                            readOnly={XearchCol.readOnly}
                            desc={XearchCol.desc}
                            ref={XearchCol.ref}
                            maxLength={XearchCol.maxLength}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}

                            clearButton={XearchCol.clearButton} // inputBox 내용 삭제 clear 기능
                        // onSearch={onSearch}
                        />

                    </CustomForm>;
            }
            else if (XearchCol.formatter === "autoInput") {
                data =
                    <CustomForm key={"CustomForm_autoInput" + XearchCol.name + i}
                    page={XearchCol.page}
                    forId={XearchCol.name}
                    name={XearchCol.labelName}
                    align={XearchCol.align}
                    type={XearchCol.labelType}
                    labelAlign={XearchCol.labelAlign} >

                        <CustomAutoInput
                           page={XearchCol.page}
                           // options={XearchCol}
                           id={XearchCol.name}
                           name={XearchCol.name}
                           type={XearchCol.type}
                           desc={XearchCol.desc}
                           sortAsc={XearchCol.sortAsc}
                           ref={XearchCol.ref}

                           defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */

                           codeMode={XearchCol.codeMode}  /* codeMode :: option text를 code + text 로 표출 */
                           selectAll={XearchCol.selectAll}  /* selectAll :: 전체 option 표출 여부 */

                           codes={XearchCol.codes}  /* Case 1 - codes :: Code List */
                           comms={XearchCol.comms}  /* Case 2 - comms :: Search List */
                           items={XearchCol.items}  /* Case 3 - items :: Hard Coding List */

                           error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                           onChange={onChange}
                           onInit={onInit}
                        />

                    </CustomForm>;
            }
            else if (XearchCol.formatter === "select") {
                data =
                    <CustomForm key={"CustomForm_select" + XearchCol.name + i}
                        page={XearchCol.page}
                        forId={XearchCol.name}
                        name={XearchCol.labelName}
                        align={XearchCol.align}
                        type={XearchCol.labelType}
                        labelAlign={XearchCol.labelAlign} >

                        <CustomSelect
                            page={XearchCol.page}
                            // options={XearchCol}
                            id={XearchCol.name}
                            name={XearchCol.name}
                            type={XearchCol.type}
                            desc={XearchCol.desc}
                            sortAsc={XearchCol.sortAsc}
                            ref={XearchCol.ref}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */

                            codeMode={XearchCol.codeMode}  /* codeMode :: option text를 code + text 로 표출 */
                            selectAll={XearchCol.selectAll}  /* selectAll :: 전체 option 표출 여부 */

                            codes={XearchCol.codes}  /* Case 1 - codes :: Code List */
                            comms={XearchCol.comms}  /* Case 2 - comms :: Search List */
                            items={XearchCol.items}  /* Case 3 - items :: Hard Coding List */

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}
                            onInit={onInit}

                        // onSearch={onSearch}
                        />
                    </CustomForm>;

            }
            else if (XearchCol.formatter === "check") {
                data =
                    <CustomForm key={"CustomForm_check" + XearchCol.name + i}
                        forId={XearchCol.name}
                        name={XearchCol.labelName}
                        align={XearchCol.align}
                        type={XearchCol.labelType} >

                        <CustomCheckbox
                            // options={XearchCol}
                            id={XearchCol.name}
                            name={XearchCol.name}
                            type={XearchCol.type}
                            desc={XearchCol.desc}
                            sortAsc={XearchCol.sortAsc}
                            ref={XearchCol.ref}

                            labelName={XearchCol.labelName}

                            options={XearchCol.options}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}
                        // onSearch={onSearch}
                            alignType={"R"}
                        />
                    </CustomForm>;

            }
            else if (XearchCol.formatter === "radio") {
                data =
                    <CustomForm key={"CustomForm_radio" + XearchCol.name + i}
                        page={XearchCol.page}
                        forId={XearchCol.name}
                        name={XearchCol.labelName}
                        align={XearchCol.align}
                        type={XearchCol.labelType} >

                        <CustomRadiobox
                            page={XearchCol.page}
                            // options={XearchCol}
                            id={XearchCol.name}
                            name={XearchCol.name}
                            type={XearchCol.type}
                            desc={XearchCol.desc}
                            sortAsc={XearchCol.sortAsc}
                            ref={XearchCol.ref}

                            labelName={XearchCol.labelName}

                            options={XearchCol.options}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}
                        // onSearch={onSearch}
                        />
                    </CustomForm>;

            }
            else if (XearchCol.formatter === "dateHour") {
                data =
                    <CustomForm key={"CustomForm_dateHour" + XearchCol.name + i}
                        forId={XearchCol.name}
                        name={XearchCol.labelName}
                        align={XearchCol.align}
                        type={XearchCol.labelType} >


                        <CustomInputDateHour
                            id={XearchCol.name}
                            name={XearchCol.name}

                            id2={XearchCol.name2}
                            name2={XearchCol.name2}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */
                            defaultValue2={XearchCol.defaultValue2}  /* defaultValue :: selectBox 최초셋팅값 */

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}
                        // onSearch={onSearch}
                        />
                    </CustomForm>;
            } 
            else if (XearchCol.formatter === "dateCheck") {
                data =
                    <CustomForm key={"CustomForm_dateCheck" + XearchCol.name + i}
                        forId={XearchCol.name}
                        name={XearchCol.labelName}
                        align={XearchCol.align}
                        type={XearchCol.labelType} >


                        <CustomInputDateCheck
                            id={XearchCol.name}
                            name={XearchCol.name}

                            id2={XearchCol.name2}
                            name2={XearchCol.name2}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */
                            defaultValue2={XearchCol.defaultValue2}  /* defaultValue :: selectBox 최초셋팅값 */

                            labelOptions2={XearchCol.options2}

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}
                        />
                    </CustomForm>;
            } 
            else if (XearchCol.formatter === "date") {
                data =
                    <CustomForm key={"CustomForm_date" + XearchCol.name + i}
                        forId={XearchCol.name}
                        name={XearchCol.labelName}
                        align={XearchCol.align}
                        type={XearchCol.labelType} >


                        <CustomInputDate
                            id={XearchCol.name}
                            name={XearchCol.name}

                            id2={XearchCol.name2}
                            name2={XearchCol.name2}

                            defaultValue={XearchCol.defaultValue} 
                            defaultValue2={XearchCol.defaultValue2} 

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}
                        />
                    </CustomForm>;

            } else if (XearchCol.formatter === "date2") {
                data =
                    <CustomForm key={"CustomForm_input" + XearchCol.name + i}
                        page={XearchCol.page}
                        forId={XearchCol.name}
                        name={XearchCol.labelName}
                        align={XearchCol.align}
                        type={XearchCol.labelType}
                        labelAlign={XearchCol.labelAlign} >

                        <CustomInputDateFromTo
                            page={XearchCol.page}

                            id={XearchCol.name}
                            name={XearchCol.name}

                            id2={XearchCol.name2}
                            name2={XearchCol.name2}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */
                            defaultValue2={XearchCol.defaultValue2}  /* defaultValue :: selectBox 최초셋팅값 */
                            
                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}
                        />

                    </CustomForm>;
            }
             else if (XearchCol.formatter === "dateCombo") {
                data =
                    <CustomForm key={"CustomForm_dateHour" + XearchCol.name + i}
                        forId={XearchCol.name}
                        name={XearchCol.labelName}
                        align={XearchCol.align}
                        type={XearchCol.labelType} >


                        <CustomInputDateCombo
                            id={XearchCol.name}
                            name={XearchCol.name}

                            id2={XearchCol.name2}
                            name2={XearchCol.name2}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */
                            defaultValue2={XearchCol.defaultValue2}  /* defaultValue :: selectBox 최초셋팅값 */

                            codeMode2={XearchCol.codeMode2}  /* codeMode :: option text를 code + text 로 표출 */
                            codes2={XearchCol.codes2}  /* Case 1 - codes :: Code List */
                            comms2={XearchCol.comms2}  /* Case 2 - comms :: Search List */
                            items2={XearchCol.items2}  /* Case 3 - items :: Hard Coding List */

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}
                        />
                    </CustomForm>;
                    
            }else if (XearchCol.formatter === "selectCheck") {
                data =
                    <CustomForm key={"CustomForm_dateCheck" + XearchCol.name + i}
                        forId={XearchCol.name}
                        name={XearchCol.labelName}
                        align={XearchCol.align}
                        type={XearchCol.labelType} >


                        <CustomInputSelectCheck
                            id={XearchCol.name}
                            name={XearchCol.name}

                            id2={XearchCol.name2}
                            name2={XearchCol.name2}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */
                            defaultValue2={XearchCol.defaultValue2}  /* defaultValue :: selectBox 최초셋팅값 */

                            codeMode={XearchCol.codeMode}  /* codeMode :: option text를 code + text 로 표출 */
                            selectAll={XearchCol.selectAll}  /* selectAll :: 전체 option 표출 여부 */

                            codes={XearchCol.codes}  /* Case 1 - codes :: Code List */
                            comms={XearchCol.comms}  /* Case 2 - comms :: Search List */
                            items={XearchCol.items}  /* Case 3 - items :: Hard Coding List */


                            labelOptions2={XearchCol.options2}

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}
                        />
                    </CustomForm>;
            } else if (XearchCol.formatter === "blank") { //틀 있는 빈 칸
                data =
                    <CustomForm key={"CustomForm_blank" + XearchCol.name + i}
                        forId={XearchCol.name} 
                        align={XearchCol.align}
                        type={XearchCol.labelType}
                    >
                        <div className={"h_Label1L"}></div>
                    </CustomForm>;
            } else if (XearchCol.formatter === "checkInput") { // label checkBox + InputBox
                    let formBoxStyle = {
                        marginRight: "auto"
                    }
                
                    if (XearchCol.align) {
                        if (XearchCol.align === "left") {
                            formBoxStyle = {
                                marginRight: "auto"
                            }
                        } else if (XearchCol.align === "right") {
                            formBoxStyle = {
                                marginLeft: "auto"
                            }
                        }
                    }

                data =
                    <div key={"CustomForm_CustomCheckbox" + XearchCol.name + i} className={"form_box"} style={{formBoxStyle}}>

                        <CustomCheckbox
                            id={XearchCol.name}
                            name={XearchCol.name}
                            type={XearchCol.type}
                            desc={XearchCol.desc}
                            sortAsc={XearchCol.sortAsc}
                            ref={XearchCol.ref}
                            tooltip={XearchCol.tooltip}
                            labelName={XearchCol.labelName}
                            labelType={XearchCol.labelType}

                            options={XearchCol.labelOptions}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}
                            alignType={"R"}
                        />                        

                        <CustomInput
                            id={XearchCol.name}
                            name={XearchCol.name}
                            type={XearchCol.type}
                            readOnly={XearchCol.readOnly}
                            desc={XearchCol.desc}
                            ref={XearchCol.ref}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}
                        />
                        
                    </div>;
            } else if (XearchCol.formatter === "checkSelect") { // label checkBox + SelectBox
                let formBoxStyle = {
                    marginRight: "auto"
                }
            
                if (XearchCol.align) {
                    if (XearchCol.align === "left") {
                        formBoxStyle = {
                            marginRight: "auto"
                        }
                    } else if (XearchCol.align === "right") {
                        formBoxStyle = {
                            marginLeft: "auto"
                        }
                    }
                }

                data =
                    <div key={"CustomForm_CustomCheckbox2" + XearchCol.name + i} className={"form_box"} style={{formBoxStyle}}>

                        <CustomCheckbox
                            id={XearchCol.name}
                            name={XearchCol.name}
                            type={XearchCol.type}
                            desc={XearchCol.desc}
                            sortAsc={XearchCol.sortAsc}
                            ref={XearchCol.ref}

                            labelName={XearchCol.labelName}
                            labelType={XearchCol.labelType}

                            options={XearchCol.labelOptions}

                            defaultValue={XearchCol.defaultValue} 

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}
                            alignType={"R"}
                        />                        

                        <CustomSelect
                            id={XearchCol.name}
                            name={XearchCol.name}
                            type={XearchCol.type}
                            desc={XearchCol.desc}
                            sortAsc={XearchCol.sortAsc}
                            ref={XearchCol.ref}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */

                            codeMode={XearchCol.codeMode}  /* codeMode :: option text를 code + text 로 표출 */
                            defaultValueMode={XearchCol.defaultValueMode}  /* defaultValueMode :: 중복 옵션값 중 선택한 옵션값 받아오기 위함 */
                            selectAll={XearchCol.selectAll}  /* selectAll :: 전체 option 표출 여부 */

                            codes={XearchCol.codes}  /* Case 1 - codes :: Code List */
                            comms={XearchCol.comms}  /* Case 2 - comms :: Search List */
                            items={XearchCol.items}  /* Case 3 - items :: Hard Coding List */

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}

                        />
                        
                    </div>;
            } else if (XearchCol.formatter === "checkSpan") { // 2 col colspan
                data =
                    <div key={"CustomForm_CustomCheckbox3" + XearchCol.name + i} className={"form_box"} style={{marginLeft: "auto"}}>

                        <CustomCheckbox
                            id={XearchCol.name}
                            name={XearchCol.name}
                            type={XearchCol.type}
                            desc={XearchCol.desc}
                            sortAsc={XearchCol.sortAsc}
                            ref={XearchCol.ref}

                            labelName={XearchCol.labelName}
                            labelType={XearchCol.labelType}

                            options={XearchCol.options}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}
                            paddingLeft={"2px"}
                            alignType={"R"}
                        />
                        
                    </div>;
            }
            else if (XearchCol.formatter === "select2") {
                data =
                    <CustomForm key={"CustomForm_select3" + XearchCol.name + i}
                        forId={XearchCol.name}
                        name={XearchCol.labelName}
                        align={XearchCol.align}
                        type={XearchCol.labelType}
                        labelAlign={XearchCol.labelAlign} >

                        <CustomSelect2
                            id={XearchCol.name}
                            name={XearchCol.name}

                            id2={XearchCol.name2}  
                            name2={XearchCol.name2}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */
                            defaultValue2={XearchCol.defaultValue2}  /* defaultValue :: selectBox 최초셋팅값 */

                            codeMode={XearchCol.codeMode}  /* codeMode :: option text를 code + text 로 표출 */
                            codes={XearchCol.codes}  /* Case 1 - codes :: Code List */
                            comms={XearchCol.comms}  /* Case 2 - comms :: Search List */
                            items={XearchCol.items}  /* Case 3 - items :: Hard Coding List */
                            selectAll={XearchCol.selectAll}

                            codeMode2={XearchCol.codeMode2}  /* codeMode :: option text를 code + text 로 표출 */
                            codes2={XearchCol.codes2}  /* Case 1 - codes :: Code List */
                            comms2={XearchCol.comms2}  /* Case 2 - comms :: Search List */
                            items2={XearchCol.items2}  /* Case 3 - items :: Hard Coding List */
                            selectAll2={XearchCol.selectAll2}

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}
                        />

                    </CustomForm>;

            }
            else if (XearchCol.formatter === "select3") {
                data =
                    <CustomForm key={"CustomForm_select3" + XearchCol.name + i}
                        forId={XearchCol.name}
                        name={XearchCol.labelName}
                        align={XearchCol.align}
                        type={XearchCol.labelType}
                        labelAlign={XearchCol.labelAlign} >

                        <CustomSelect3
                            id={XearchCol.name}
                            name={XearchCol.name}

                            id2={XearchCol.name2}  
                            name2={XearchCol.name2}

                            id3={XearchCol.name3}
                            name3={XearchCol.name3}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */
                            defaultValue2={XearchCol.defaultValue2}  /* defaultValue :: selectBox 최초셋팅값 */
                            defaultValue3={XearchCol.defaultValue3}  /* defaultValue :: selectBox 최초셋팅값 */

                            codeMode={XearchCol.codeMode}  /* codeMode :: option text를 code + text 로 표출 */
                            codes={XearchCol.codes}  /* Case 1 - codes :: Code List */
                            comms={XearchCol.comms}  /* Case 2 - comms :: Search List */
                            items={XearchCol.items}  /* Case 3 - items :: Hard Coding List */

                            codeMode2={XearchCol.codeMode2}  /* codeMode :: option text를 code + text 로 표출 */
                            codes2={XearchCol.codes2}  /* Case 1 - codes :: Code List */
                            comms2={XearchCol.comms2}  /* Case 2 - comms :: Search List */
                            items2={XearchCol.items2}  /* Case 3 - items :: Hard Coding List */

                            codeMode3={XearchCol.codeMode3}  /* codeMode :: option text를 code + text 로 표출 */
                            codes3={XearchCol.codes3}  /* Case 1 - codes :: Code List */
                            comms3={XearchCol.comms3}  /* Case 2 - comms :: Search List */
                            items3={XearchCol.items3}  /* Case 3 - items :: Hard Coding List */

                            readonly3={XearchCol.readonly3}

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}

                        />

                    </CustomForm>;

            }
            else if (XearchCol.formatter === "check3") {
                data =
                    <CustomForm key={"CustomForm_check" + XearchCol.name + i}
                        forId={XearchCol.name}
                        name={XearchCol.labelName}
                        align={XearchCol.align}
                        type={XearchCol.labelType} >

                        <CustomCheckbox3
                            id={XearchCol.name}
                            name={XearchCol.name}
                            type={XearchCol.type}
                            desc={XearchCol.desc}
                            sortAsc={XearchCol.sortAsc}
                            ref={XearchCol.ref}

                            labelName={XearchCol.labelName}

                            options={XearchCol.options}

                            defaultValue={XearchCol.defaultValue}  /* defaultValue :: selectBox 최초셋팅값 */

                            error={errors && errors.hasOwnProperty(XearchCol.name) ? errors[XearchCol.name] : null}
                            onChange={onChange}
                            alignType={"R"}
                        />
                    </CustomForm>;

            }
            else {  // 아예 빈 칸
                data = "";
            }


            return data;

        }, this) : "";

        let xearchPadding = "10px 10px 10px 10px";
        let xearchBorder = "0px solid #FFF";
        if (i === 0 && xearchs.length === 1) {
            xearchPadding = "10px 10px 10px 10px";
            xearchBorder = "1px solid #BFBDCD";
        } else if (i === 0 && xearchs.length > 1) {
            xearchPadding = "10px 10px 5px 10px";
        } else if (i > 0 && i < (xearchs.length - 1)) {
            xearchPadding = "5px 10px 5px 10px";
        } else if (i > 0 && i === (xearchs.length - 1)) {
            xearchPadding = "5px 10px 10px 10px";
            xearchBorder = "1px solid #BFBDCD";
        } else { xearchPadding = "10px 10px 10px 10px"; }

        let xearchGroupP =
            <div key={"search_section_xearchGroupP" + i} className={"search_section"} style={{ padding: xearchPadding, borderBottom: xearchBorder }}>
                <div className={"container"}>
                    {xearchGroup}
                </div>
            </div>;

        newXearchList.push(xearchGroupP);

    });
    

    const handleOnClick = (e)=>{
        setExpanded(!expanded);
        
        if (onClick) {
            onClick(expanded);
        }
    }

    return (
        <>      
                <CardActions disableSpacing style={{height:'5px'}}>
                    <ExpandMore
                    expand={expanded}
                    onClick={handleOnClick}
                    aria-expanded={expanded}
                    style={{height:'5px'}}
                    onChange={handleOnClick}
                    >
                    <ExpandMoreIcon />
                    </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit >
                    <CardContent style={{padding:'0px'}}>
                    {optionsX ?
                            newXearchList
                            : ""}
                    </CardContent>
                </Collapse>
           
        </>
    );
}
// )

export default ContentXearch;
// export default React.memo(ContentXearch);