import React from 'react';

import '../../css/common.css';
import '../../css/style.css';
import '../../css/main_style.css';
import '../../css/maindash.css';
import '../../css/table.css';

function Content({ children, style }) {

    return (
        <>
            <div className={"cont_section"} style={style ? style : null}>
                <div className={"container container02"}>
                    {children}
                </div>
            </div>
        </>
    );
}
export default Content;