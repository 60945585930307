import React, { useState, useEffect  } from "react";
import {useHistory } from "react-router-dom";
import { Navigation } from 'react-minimal-side-navigation';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import Popup from '@/pages/Popup/Popup';     
import Link from '@material-ui/core/Link';
import * as Common from '@/utils/Common'; 


let navGubnName = "";   //대구분 메뉴명
const navArray = ["납입지시", "수신자료관리", "시스템관리","고객지원"];  // 대메뉴 이름 Array 셋팅
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const drawerWidth = 220;
const bigi = Common.getToken("bigi");

//Styles
const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: bigi!="Y"?'#222339':'#1c1e25',
        color: '#fff',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: - drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    iconButton: {
        color: '#fff',
    },
    drawerDivider: {
        backgroundColor: "#fff",
    },

    topNavArea: {
        display: 'block',
        fontWeight: 'bold',
        textAlign: 'center',
        /* margin: '28px auto;', */
        margin: '25px 28px 28px 28px',
        fontSize: '16px',
        fontFamily: "'GmarketSansMedium', 'NanumGothic', 'sans - serif'",
    },
    topNavArea2: {
        display: 'block',
        height:'36px',
        width:'187px',
        fontWeight: 'bold',
        textAlign: 'center',
        /* margin: '28px auto;', */
        margin: '8px 15px 11px 15px',
        paddingTop:'7px',
        fontSize: '15pt',
        fontFamily: "'PretendardSemiBold'",
        borderRadius: '100px',
        background: '#0057f4'
    }
}));


function AppMenu(props) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [typeOpen, setTypeOpen] = React.useState(false);


    const [inputs, setInputs] = useState({
        newMenuData: null,  
        newNavi : null,     
    }); 
    // const { newMenuData, newNavi } = inputs;
    //props.navPath = history.location.pathname;
    //대 메뉴명 

    if (props.navGubn === "A") navGubnName = navArray[0];
    else if (props.navGubn === "B") navGubnName = navArray[1];
    else if (props.navGubn === "C") navGubnName = navArray[2]; 
    else if (props.navGubn === "D") navGubnName = navArray[3]; 
    


    //MOUNT
    useEffect(() => {
        //메뉴조회
        const onSearchMenu = async () => {

            // Token Setting , Default Setting
            const token = Common.getToken();
            
            

            if (token) {
                const master = Common.getToken("masterKey");
                const tVnt1 = Common.getToken("vand");
                
                let para = `{"vand":"${tVnt1}","master":"${master}"}`;
        
                let param = encodeURI(para);        

                const query = SERVER_URL + "/menu/sub?query="+param;

                await fetch(query, {method: "POST", headers: { Authorization: token, "Content-Type": "application/json"  }, body: param  }) 
                    .then((resp) => resp.json())
                    .then((data) => {
                        if (data.success) {
                            
                            setInputs({
                                ...inputs,
                                newMenuData: data.data
                            });
                        }
                        else  if (data.error) {
                            if (data.status === 500) {
                            }
                        }
                    })
                    .catch(error => { // 에러 응답 처리
                        if (error) {
                            alert("서버 상태를 확인해 주세요. 관리자에게 문의해주세요.");
                        }
                    });
            }
        }
        onSearchMenu();
    }, []);


    //메뉴 Data 변경시 
    useEffect(() => {
        if (inputs.newMenuData) {
            let newTemp = inputs.newMenuData;
            let newNavi = {};

            newTemp.forEach(function (top) {
                if (top.meupId === " ") {
                    newNavi[top.menuId] = [];
                }
            }, this);

            //navi middle Group 추출                        
            Object.keys(newNavi).forEach(function (top) { // top A, B, C
                newTemp.forEach(function (mid) {
                    let middleGroup = {}; // 객체 생성
                    if (mid.meupId === top) {
                        middleGroup["title"] = mid.menuName;
                        middleGroup["meupId"] = mid.meupId;
                        middleGroup["menuId"] = mid.menuId;
                        middleGroup["itemId"] = "/" + mid.menuId;
                        middleGroup["subNav"] = [];

                        return newNavi[top].push(middleGroup);
                    }
                }, this)
            }, this);

            // navi sub Group 추출
            Object.keys(newNavi).forEach(function (top) { // top A, B, C
                newNavi[top].forEach(function (group) {
                    newTemp.forEach(function (navi, i) {
                        if (group.meupId === top) {
                            if ((navi.meupId !== props.navGubn) && (group.menuId === navi.meupId)) {
                                let subGroup = {};
                                subGroup["title"] = navi.menuName;
                                subGroup["meupId"] = navi.meupId;
                                subGroup["menuId"] = navi.menuId;
                                subGroup["itemId"] = "/" + navi.meupId + "/" + navi.menuId;
                                group["subNav"].push(subGroup);
                            }
                        }
                    }, this)
                }, this)
            }, this);

            //메뉴 데이터 Setting
            setInputs({
                ...inputs,
                newNavi: newNavi
            });
        }
    }, [inputs.newMenuData]);

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={props.open}
            classes={{
                paper: classes.drawerPaper,
            }}>

            <div className={classes.drawerHeader} style={{paddingRight:"3px"}}>
                <a href="/" style={{paddingRight:"0px"}}><img src="/images/logo_w.png" alt="Rapdos LOGO" /></a>
                
                <IconButton className={classes.iconButton} onClick={props.onClose}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>


            <div className={classes.topNavArea2}>
                {navGubnName}
            </div>

            <Navigation
                activeItemId= {props.navPath}//{props.navPath} // 현재 경로
                onSelect={({ itemId }) => {//선택
                    let itemArrayCnt = itemId.split("/").length;
                    if (itemArrayCnt > 2) {
                        props.funcTop(); // Top Notice 조회 onSearchTopNotice Function
                        history.push(itemId);
                        if (props.setMenuId) {
                        }
                    }
                    if(itemId =='/D01/MyInf'){
                        setTypeOpen(true);
                    }else if(itemId =='/D02/RSY90'){
                        window.open("http://helpu.kr/itnj/", '_blank');
                
                    }else if(itemId =='/D02/RSY91'){
                    
                        window.open("https://pf.kakao.com/_xadUMxb/chat", '_blank');
                    }else if(itemId =='/D02/RSY92'){
                    
                        window.open("http://njmro.co.kr/", '_blank');
                    }else if(itemId =='/D02/RSY93'){
                    
                        window.open("https://rapdos.co.kr/file/BigiSetup.exe", '_blank');
                    }
                    

                }}
                items={inputs.newNavi && props.navGubn ? inputs.newNavi[props.navGubn] : ""} // db 조회 후 Nav 값 셋팅 중메뉴 오픈
            />

            <div style={{height:"20px"}}></div>
            
                <div className="banner_area2">
                <ListItem button component={Link} href="https://rapdos.co.kr/file/BigiSetup.exe" target="_blank">  
                    <img src="/images/rapdos_bigi.png" style={{marginLeft: "4px"}} alt="BIGI 다운로드" title="BIGI 다운로드"/>
                </ListItem>
                <ListItem button component={Link} href="http://njmro.co.kr/" target="_blank">  
                    <img src="/images/njmro_b.png" style={{marginLeft: "4px"}} alt="MRO 바로가기" title="MRO 바로가기"/>
                </ListItem>
            </div>
            
            <Popup type="MyInf" open={typeOpen} onClose={()=> {setTypeOpen(false)}}/>
        </Drawer>
    )
}

export default AppMenu;