/**
 * @Page ROM3Z
 * @Description [ROM3Z]납입지시 - 현황관리 - 납품서 발행현황
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import GridRibbon from "@/components/organisms/GridRibbon";
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import * as GridUtil from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; // Common.js
 import CommonUtil from '@/utils/CommonUtil'; // Util.js
 import Loading from "src/components/atoms/CustomLoading2";
 
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();
 
 function ROM3Z() {
 
     const token = Common.getToken();
     const userId = Common.getToken("id");
     const tVnt1 = Common.getToken("vand");
     const tComp = Common.getToken("comp");
     const bigi = Common.getToken("bigi");
     const grdRom3zMain = useRef();   //Grid Main
     const grdRom3zBelow = useRef();   // 밑 Grid
 
     const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
     let [loading, setLoading] = useState(false);

     //Data
     const [idata, setData] = useState({
         //조회조건
         kGubnn: "T1",
         kShowGubn: "1",
         kOrder: "A",
         kDlno: "",
         kFndt: Common.getToday(),
         kTndt: Common.getToday(),
         // kVnt1 : tVnt1,      //T1업체
         kVnt1 : ckVnt1,        //T1업체     
         kVnt2 : "",      //T2업체
         kComp: tComp,       //회사구분
         kPlnt: "",          //공장
         kGate: "",          //하치장
         kPldt: Common.getToday(),  //소요일자
         kPtno : "",         //품번
         kCano : "",         //차량번호
         kName: "",          //담당자
         kCompNm : tComp==="EHMC"?"현대자동차(주)":"남양시작차",        //회사명
 
         //코드 데이터
         vnt1List : [],  //T1업체
         plntList : [],  //공장
         gateList : [],  //하치장
         canoList : [],  //차량번호
         compList : [],  //회사
     });
 
 
      
     const [message, setMessage] = React.useState( {
         openAlert : false,      
         severity : "success",   
         msg : ""                
     });
     const { openAlert, severity, msg } = message;
 
 
     // Dialog
     const [dlgMessage, setDlgMessage] = React.useState( {
         openDlg : false,    
         dlgTrans : "",      // Dialog Transaction(I,U,D)
         dlgMsg : ""         
     });
     const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
 
     // 테이블 1,2 검색 및 프린트
     let [searchApi, setSearchApi] = useState(false); 
     let [searchApi2, setSearchApi2] = useState(false); 
     let [clearGrid, setClearGrid] = useState(false); // 
     let [clearGrid2, setClearGrid2] = useState(false); // 
     const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 146px  - 50px)"});
 
     const onClearApi = useCallback(async (e) => {
         setSearchApi(false);
             }, []);
 
     const onClearApi2 = useCallback(async (e) => {
         setSearchApi2(false);
     }, []);

    
 
     /**
      * 공통코드 조회 (조회조건)
      */
     const onSearchCommon = async (para, name) => {
         let commonData = idata;
 
         const fetchData = async (para, name) => {
             let param = encodeURI(para);
             const query = SERVER_URL + "/comcd/search?query=" + param; 
             await fetch(query, {headers: {Authorization: token}})
                 .then((resp) => resp.json())
                 .then((data) => {
                     if (data.success) {
                         commonData[name] = data.data
                         setData({...commonData});
                     }
                 })
         }
         await fetchData(para, name);
     }
 
     const onSearchCommon1 = (para, name) => {
         let commonData = idata;
 
         const fetchData1 = async (para, name) => {
 
             let param = encodeURIComponent(para);
             const query = SERVER_URL + "/comcd/search1?query=" + param; 
             await fetch(query, { headers: { Authorization: token } })
                 .then((resp) => resp.json())
                 .then((data) => {
                     if (data.success) 
                     {
                         if (name === "typeList") {
                             //commonData["kType"] = data.data[0].comCode
                             //commonData["kTypeNm"] = data.data[0].text
                         }
                         commonData[name] = data.data
                         setData({...commonData});
                     }
                 })
         }
         fetchData1(para, name);
     }
     /**
      *MOUNT
      */
     useEffect( () => {
         //공통코드 조회 (T1업체)
         onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
         //공통코드 조회 (공장)
         onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "plntList");
         //공통코드 조회 (차량번호)
         onSearchCommon(`{"pgmid":"CARN"}`, "canoList");
         //공통코드 조회 (회사)
         onSearchCommon1(`{"pgmid":"NETP_COMP","kGocd":"COMP","kVnt1":"****"}`, "compList");

         // 화면 open 시 자동 조회 
         setTimeout(() => onSearch(), 500);
     }, []);
 
 
 
     /**
      * Control 변경 Event
      * @param {*} e
      */
     const onChange = async (e) => {
         const {name, value, desc} = e.target;
 
         let newSearchs = idata;
         
 
         // T1, T2 업체
         if (name === "kGubnn") {
             
             newSearchs["vnt1List"] = [];
             
             if(value ==="T1"){
                newSearchs["kVnt2"] = "";
             }
             newSearchs[name+"Nm"] = desc;
             // 데이터 받아올 때 까지 대기. async-await 없을 시 state 갱신안됨.
             onSearchCommon(value === "T1" ? `{"pgmid":"VNT1"}`:`{"pgmid":"VNT2"}`, "vnt1List")
             // T2 선택 시 전체 데이터 표시
             //if (value === "T2") idata.vnt1List = [{comCode: "all", text: "전체"}].concat(idata.vnt1List)
             //newSearchs["kVnt1"] = "all"
             setData({ ...newSearchs });
         }
 
         if(name === "kVnt1"){
            if(idata.kGubnn ==="T1"){
                newSearchs["kVnt1"] = value;
                newSearchs[name+"Nm"] = desc;
            }else{
                newSearchs["kVnt2"] = value;
            }
            newSearchs[name+"Nm"] = desc;
            setData({ ...newSearchs });
         }
         // 납품서번호, 품번 조회
         if (name === "kShowGubn") {
             switch (value) {
                 case "1":
                     await onSearch()
                     setData({ ...newSearchs, kShowGubn: "1"});
                     break;
                 case "2":
                     await onPdnoSearch()
                     setData({ ...newSearchs, kShowGubn: "2"});
                     break;
             }
         }
         if(name !== "kVnt1"){
            newSearchs[name] = value;
            newSearchs[name+"Nm"] = desc;
            setData({ ...newSearchs });
         }
     }
     
     const grdRom3zMain_onRowClicked = async (e) => {
         let data = e.data;
 
         let para = `{
                         "cano": "${data.cano}", "chul": "${data.chul}", "comp": "${data.comp}",
                         "dlno": "${data.dlno}", "gate": "${data.gate}", "isur": "${data.isur}", "npdh": "${data.npdh}",
                         "npdt": "${data.npdt}", "pldt": "${data.pldt}", "plnt": "${data.plnt}",
                         "prgb": "${data.prgb}", "prtm": "${data.prtm}", "vand": "${data.vand}", "vnt1": "${data.vnt1}",
                         "gubn": "${idata.kGubnn}", "vnt2": "${idata.kVnt2}"
                      }`;

         let param = encodeURI(para);
         const query = SERVER_URL + "/rom3z/searchD?query=" + param;
         setSearchApi2(query); // Search API
     }
 
     /**
      * 검색조건
      */
     const optionsX = {
         0: [
             // T1업체, T2업체 구분
             {
                 formatter: "radio", name: "kGubnn", labelName: "", type: "text", desc: "", readOnly: false,
                 options: [{name: "kGubn1", setValue: "T1", labelName: "T1업체"}, {name: "kGubn2", setValue: "T2", labelName: "T2업체"}],
                 defaultValue: idata.kGubnn,  
                 align: "left", 
                 labelType: "none", 
                 page:"ROM3Z",
             },
             //T1업체
             {
                 formatter: "select", name: "kVnt1", labelName: "", type: "text", defaultValue: idata.kGubnn === "T1" ? idata.kVnt1 : idata.kVnt2,  
                 codeMode: true,  // 코드만 나오는 요소 있어 codeMode True로 설정. codeMode :: option text를 code + text 로 표출
                 selectAll: idata.kGubnn === "T1" ? false :"all", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: null,
                 comms: idata.vnt1List, 
                 align: "left", 
                 page:"ROM3Z",
             },
             //회사구분
             {
                 formatter: "select", name: "kComp", labelName: "회사구분", type: "text", defaultValue: idata.kComp,  
                 codeMode: true,
                 selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: idata.compList,
                 comms: null,
                 align: "left", 
                 page:"ROM3Z",
             },
             //차량번호
             {
                 formatter: "select", name: "kCano", labelName: "차량번호", type: "text", defaultValue: idata.kCano,  
                 codeMode: false,
                 selectAll: "all", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: null,
                 comms: idata.canoList,
                 align: "left", 
                 page:"ROM3Z",
             },
             
             {"formatter": "blank", "align": "right" }
         ],
         1: [
             // 발행일자 시작
             {
                 formatter: "date2", name: "kFndt", type: "date", labelName: "발행일자"
                 , name2: "kTndt",defaultValue2: idata.kTndt
                 , defaultValue: idata.kFndt
                 , align: "left" // (우측 정렬)
                 , labelType: "none" 
                 , page:"ROM3Z"
             },
             // 발행일자 종료
            //  {
            //      formatter: "input", name: "kTndt", type: "date", labelName: "~"
            //      , defaultValue: idata.kTndt
            //      , align: "left" // (우측 정렬)
            //      , labelType: "none" 
            //  },
             // 공장
             {
                 formatter: "select", name: "kPlnt", labelName: "공장", type: "text", defaultValue: idata.kPlnt,  
                 codeMode: false,
                 selectAll: "all", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: idata.plntList,
                 comms: [{comCode: "OEM", text: "OEM"}, {comCode: "CKD", text: "CKD"}],
                 align: "left", 
                 page:"ROM3Z",
             },
             // 정렬순서
             {
                 formatter: "select", name: "kOrder", labelName: "정렬순서", type: "text", defaultValue: idata.kOrder,  
                 codeMode: false,
                 selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: [{"comCode":"A","text":"공장"}, {"comCode":"B","text":"발행일자"}, {"comCode":"C","text":"차량번호"}],
                 // comms: idata.vnt1List,
                 align: "left", 
                 page:"ROM3Z",
             },
             
             {"formatter": "blank", "align": "right" }
         ],
         2: [
             // 조회구분
             {
                 formatter: "radio", name: "kShowGubn", labelName: "조회구분", type: "text", desc: "", readOnly: false,
                 options: [{name: "kShowGubn1", setValue: "1", labelName: "납품서번호"}, {name: "kShowGubn2", setValue: "2", labelName: "품번"}],
                 defaultValue: idata.kShowGubn,  
                 align: "left", 
                 labelType: "none", 
                 page:"ROM3Z",
             },
             // 납품서번호
             {
                 formatter: "input", name: "kDlno", labelName: "납품서번호", type: "text", desc: "", readOnly: false,
                 defaultValue: idata.kDlno,  
                 align: "left", 
                 labelType: "none", 
                 page:"ROM3Z",
             },
             // 품번
             {
                 formatter: "input", name: "kPtno", labelName: "품번", type: "text", desc: "", readOnly: false,
                 defaultValue: idata.kPtno,  
                 align: "left", 
                 labelType: "none", 
                 page:"ROM3Z",
             },
             {formatter: "blank", align: "left"},
             {"formatter": "blank", "align": "right" }
         ]
     }
 
 
 
     /**
      * 조회
      * @param {*} e
      * @returns
      */
     const onSearch = async (e) => {

        setClearGrid2(true);
 
         if(Common.getDateCa(idata.kFndt,1,'m')<idata.kTndt){
             setMessage({ openAlert : true, 
                 severity : "info",
                 msg : "발행일자는 1개월 이내로 설정바랍니다"});          
             return ;
         }
 
         if (idata.kShowGubn === "2") {
             await onPdnoSearch()
             return
         }
 
         if(idata.kGubnn==="T1"){
            Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);
         }
         
 
         let para = `{
                         "vnt1": "${idata.kVnt1}", "gubn": "${idata.kGubnn}", "vnt2": "${idata.kVnt2}",
                         "comp": "${idata.kComp}", "plnt": "${idata.kPlnt}",
                         "fndt": "${idata.kFndt.replaceAll('-','')}", "tndt": "${idata.kTndt.replaceAll('-','')}", "cano": "${idata.kCano}",
                         "order": "${idata.kOrder}", "dlno": "${idata.kDlno}", "ptno": "${idata.kPtno}"
                      }`;
         
         let param = encodeURI(para);
 
         const query = SERVER_URL + "/rom3z/search?query=" + param;
         setSearchApi(query); // Search API
         
     }
 
 
 
     /**
      * 신규 Row추가
      * @param {*} e
      */
     const onAddRow = async (e) => {
     }
 
 
 
     /**
      * 입력 (발행)
      * @param {*} e
      */
     const onInsert = async (e) => {
 
     }
 
     /**
      * 수정
      * @param {*} e
      * @returns
      */
     const onUpdate = async (e) => {
     }
 
     /**
      * 삭제
      * @param {*} e
      */
     const onDelete = async (e) => {
     }
 
     /**
      * 품번 조회
      * @param {*} e
      */
     const onPdnoSearch = async (e) => {
         let para = `{
                         "vnt1": "${idata.kVnt1}", "gubn": "${idata.kGubnn}", "vnt2": "${idata.kVnt2}",
                         "comp": "${idata.kComp}", "plnt": "${idata.kPlnt}", "vand": "${idata.kVnt1}",
                         "fndt": "${idata.kFndt.replaceAll('-','')}",
                         "tndt": "${idata.kTndt.replaceAll('-','')}", "cano": "${idata.kCano}",
                         "order": "${idata.kOrder}", "dlno": "${idata.kDlno}", "ptno": "${idata.kPtno}"
                      }`;
         let param = encodeURI(para);
 
         const query = SERVER_URL + "/rom3z/searchExcel?query=" + param;
         setSearchApi(query); // Search API
     }


      /**
     * 엑셀
     * @param {*} e 
     */
     const onExcel = async(e) => {
         let para = `{
             "vnt1": "${idata.kVnt1}", "gubn": "${idata.kGubnn}", "vnt2": "${idata.kVnt2}",
             "comp": "${idata.kComp}", "plnt": "${idata.kPlnt}", "vand": "${idata.kVnt1}",
             "fndt": "${idata.kFndt.replaceAll('-','')}",
             "tndt": "${idata.kTndt.replaceAll('-','')}", "cano": "${idata.kCano}",
             "order": "${idata.kOrder}", "dlno": "${idata.kDlno}", "ptno": "${idata.kPtno}",
             "compNm":"${idata.kCompNm}",
             "plntNm":"${idata.kPlnt===""?"전체":idata.kPlntNm}"
          }`;
         let param = encodeURI(para);
         const query = SERVER_URL + "/rom3z/excel?query="+param;

        //엑셀파일 조회 시 로딩오픈
        setLoading(true);
        // setLoading(false);

         await fetch(query, {headers: {Authorization: token} })
             .then((resp) =>{
                 return resp.blob();
             }).then((blob)=>{
                 const href = window.URL.createObjectURL(blob);
                 const link = document.createElement('a');
                 link.href = href;
                 link.setAttribute('download', 'rom3z.xls');
                 document.body.appendChild(link);
                 link.click();
                 document.body.removeChild(link);
             }).catch((err) => {
                 return Promise.reject({ Error: 'error', err });
             })
             
        //엑셀파일 조회 시 로딩 닫기
        // setLoading(true);
        setLoading(false);

        // 엑셀파일 생성 메세지
        setMessage({ openAlert : true, 
                     severity : "success",
                     msg : "엑셀파일이 생성되었습니다."});
     }
 
 
     //Transaction AlertDialogResult
     const AlertDialogResult = async (e) => {
         //AlertDialog 초기화
         setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
 
         //DialogResult
         if (e.result == "N") return;
 
         //Transaction 구분
         switch (e.Trans)
         {
             //등록
             case "I" :
                 break;
             //수정
             case "U" :
                 break;
             //삭제
             case "D2" :
                 break;
 
             case "D3" :
                 break;
         }
     }
 
 
 
     /**
      * Grid Bind Data
      * @returns
      */
     const onGridBindData = (para) => {
         let param = encodeURI(para);
         let items = [];
 
         const query = SERVER_URL + "/comcd/search1?query=" + param; 
 
         fetch(query, { headers: { Authorization: token } })
             .then((resp) => resp.json())
             .then((data) => {
                 if (data.success) {
                     data.data.forEach((element) => {
                         items.push(element.comCode);
                     });
                 }
             })
         return items;
     }
 
 
 
     /**
      *Grid Column 정의
      */
         //Main Grid Column
     const ColumnDefs = [
         { headerName: "공장", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
         { headerName: "하치장", field: "gate", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
         { headerName: "발행일자", field: "npdt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter,hide: false },
         { headerName: "발행시간", field: "npdh", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
         { headerName: "도착일자", field: "dcdt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter,hide: idata.kShowGubn === '1'?true:false},
         { headerName: "도착시간", field: "dcdh", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "차량번호", field: "cano", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
         { headerName: "납품서번호", field: "dlno", sortable: true, filter: true, flex: 1, minWidth: 250, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
         { headerName: "seq", field: "dseq", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "품명", field: "ptnm", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "구매번호", field: "dono", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "구매문서번호", field: "dopt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "발주수량", field: "orqt", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "납품수량", field: "npqt", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "잔량", field: "jaqt", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "납기일자", field: "nidt", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter:Utils.dateFormatter,hide: idata.kShowGubn === '1'?true:false},
         { headerName: "피더", field: "fedr", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "판매문서번호", field: "seld", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "판매문서F", field: "frod", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "판매문서T", field: "tood", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "포장배치", field: "chpk", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "P/D NO", field: "pdno", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "Case", field: "case1", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "실적구분", field: "sjgb", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "발주형태", field: "card", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kShowGubn === '1'?true:false},
         { headerName: "인쇄여부", field: "prgb", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
         { headerName: "출하전송", field: "chul", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
         { headerName: "발행자", field: "isur", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
 
     ];
 
     //아래 Grid Column
     const belowColumnDefs1 = [
         { headerName: "Seq", field: "dseq", sortable: true, filter: true, flex: 1, minWidth: 50,maxWidth:80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
         { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
         { headerName: "구매번호", field: "dono", sortable: true, filter: true, flex: 1, minWidth: 250, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
         { headerName: "납품수량", field: "npqt", sortable: true, filter: true, flex: 1, minWidth: 100,maxWidth:150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "납기일자", field: "nidt", sortable: true, filter: true, flex: 1, minWidth: 140,maxWidth:150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,valueFormatter:Utils.dateFormatter, hide: false },
         { headerName: "판매문서번호", field: "seld", sortable: true, filter: true, flex: 1, minWidth: 280, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
         { headerName: "P/D No", field: "pdno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
     ];
 
 
    const onClick = async (e) => {
        setPanded(!panded);


        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 100px - 10px)"
                });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 146px  - 50px)"
                });
        }
    }

     return(
         <>
             {/* Dialog FRAME */}
             <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />
 
             {/* Alert FRAME */}
             <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg}
                          onClose={
                              ()=> {
                                  setMessage({...message, openAlert: false});
                              }
                          }
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} />

             {/* Loading */}
             <Loading loading={loading} /> 

             {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
             <Ribbon
                 onSearch={onSearch} onSearchName={null}     //조회
                 onExcel={onExcel} onExcelName={null} //Excel
                 //onPrint={onInsert} onPrintName={null} //print
             >
                 {"납입지시 > 현황관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>납품서 발행현황 [ ERP ]</b>
             </Ribbon>
 
 
             {/* ContentXearch Frame : 검색조건 */}
             <ContentXearch
                 optionsX={optionsX}
                 onChange={onChange}
                 onClick = {onClick}/>
 
 
             {/* Content FRAME (Data / Grid Frame) START -------------- */}
             <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:146px */}
                 <div className={"grid_type01"}> {/* 상단 FRAME */}
                     <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                         <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                             <Grid
                                 ref={grdRom3zMain}
                                 gridId={"ROM3Z"}                    //  (필수) Grid Id 정의
                                 columnDefs={ColumnDefs}             //  (필수) 컬럼 정의
                                 pagination={false}                  //  쪽수 매기기 여부
                                 paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                 paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                 rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                 suppressRowClickSelection={false}   //  클릭으로 선택 하는것을 방지
                                 rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                 enableRangeSelection={false}        //
                                 enableCellTextSelection={true}      // cell selection drag mode
                                 isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                 displayNo = {false}
                                 searchApi={searchApi}               // 조회
                                 // insertApi={insertApi}               // 등록
                                 // insertParam={inserts}               // 등록 및 수정시 Parmeter
                                 // insertRefresh={()=> {onSearch(); onSearch2();} } // 등록이후
                                 onClearApi={onClearApi}             // 초기화
                                 clearGrid={clearGrid}
                                 // onCellValueChangedEvent={grdRom3zMain_onCellValueChanged}
                                 onRowClickEvent={grdRom3zMain_onRowClicked}
                                 //updateApi={updateApi}               // 수정
                                 //deleteApi={deleteApi}               // 삭제
                                 //updateRefresh={onSearch}            // 수정이후
                                 //deleteRefresh={onSearch}            // 삭제이후
                             />
 
                         </div>
                     </div>
                 </div>
 
                 <div className={"grid_type01"} style={{display: idata.kShowGubn === "1" ? "block" : "none"}}> {/* 하단 FRAME */}
                     <div className={"grid_wrap01"} > {/* 하단 wrap FRAME */}
                         <div className="grid_cont_box" style={{ height: "calc(100% - 10px)", marginTop: "10px" }}>
                             <Grid
                                 ref={grdRom3zBelow}
                                 gridId={"ROM3ZB"}                    //  (필수) Grid Id 정의
                                 columnDefs={belowColumnDefs1}             //  (필수) 컬럼 정의
                                 pagination={false}                  //  쪽수 매기기 여부
                                 paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                 paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                 rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                 suppressRowClickSelection={false}   //  클릭으로 선택 하는것을 방지
                                 rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                 enableRangeSelection={false}        //
                                 enableCellTextSelection={true}      // cell selection drag mode
                                 isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                 displayNo = {false}
                                 searchApi={searchApi2}               // 조회
                                 // insertApi={insertApi}               // 등록
                                 // insertParam={inserts}               // 등록 및 수정시 Parmeter
                                 // insertRefresh={()=> {onSearch(); onSearch2();} } // 등록이후
                                 onClearApi={onClearApi2}             // 초기화
                                 clearGrid={clearGrid2}
                                 onClearGridFlag={setClearGrid2}  
                                 // onCellValueChangedEvent={grdRom3zMain_onCellValueChanged}
                                 // onRowClickEvent={grdRom3zMain_onRowClicked}
                                 //updateApi={updateApi}               // 수정
                                 //deleteApi={deleteApi}               // 삭제
                                 //updateRefresh={onSearch}            // 수정이후
                                 //deleteRefresh={onSearch}            // 삭제이후
                             />
                         </div>
                     </div>
                 </div>
             </Content>
         </>
     )
 }
 export default ROM3Z;