/**
 * @Page RSY42
 * @Description [RSY42]시스템 관리 : 출하전송 현황
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
import Popup from '@/pages/Popup/Popup';    

import { length } from "file-loader";
import { FormatPaint, InfoOutlined } from "@material-ui/icons";
import { setMaxListeners } from "@ckeditor/ckeditor5-dev-utils/lib/translations/multiplelanguagetranslationservice";
 
 
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
 const Utils = new CommonUtil();
 
 function RSY42() {
     const token = Common.getToken();
     const userId = Common.getToken("id");
     const userName = Common.getToken("name");
     const tVnt1 = Common.getToken("vand");
     const tComp = Common.getToken("comp");
     const grdRsy42 = useRef();   //Grid Main
 
     let now = new Date();

     //Data
     const [idata, setData] = useState({
         //조회조건
         kLogDate : Common.getToday(),    //로그일자
         kLogDlno : "",   //납품서번호
         kVnt1 : tVnt1,      //T1업체
     }); 
 
 
      
     const [message, setMessage] = React.useState( {
         openAlert : false,      
         severity : "success",   
         msg : ""                
     });
     const { openAlert, severity, msg } = message;
 
 
     //Dialog
     const [dlgMessage, setDlgMessage] = React.useState( {
         openDlg : false,    
         dlgTrans : "",      
         dlgMsg : ""         
     });
     const { openDlg, dlgTrans, dlgMsg } = dlgMessage;

     // 상세로그 popup
    const [typeOpen, setTypeOpen] = React.useState(false);
    const [data, setDatas] = useState([]);
 
 
     //Grid Search, Insert, Update, Delete Api
     let [searchApi, setSearchApi] = useState(false); 
     let [insertApi, setInsertApi] = useState(false); 
     let [updateApi, setUpdateApi] = useState(false); 
     let [deleteApi, setDeleteApi] = useState(false); // deleteApi 항목 setting
     const [panded, setPanded] = React.useState(true);
     const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 50px)"});

     const onClearApi = useCallback(async (e) => {
         setSearchApi(false);
         setInsertApi(false);
         setUpdateApi(false);
         setDeleteApi(false);
     }, []);
     
    let exceldata=[];
     
 
     /**
      * 공통코드 조회
      */
     const onSearchCommon = (para,name) => {

        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
     }
 
 
 
     /**
      *MOUNT 
      */
     useEffect( () => {
         
        //공통코드 조회
        onSearchCommon(`{"pgmid":"CODE", "vand":"****", "gocd" : "00"}`);

        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");

         //조회
        //  setTimeout(() => onSearch(), 300);

     }, []);
 
 
 
     /**
      * Control 변경 Event
      * @param {*} e 
      */
     const onChange = async (e) => {
         const { name, value } = e.target;

         let newSearchs = idata;

         if (name === "kLogDlno")
            newSearchs[name] = value.toUpperCase().replace(/\s/g, "");
        else
            newSearchs[name] = value
 
         setData({ ...newSearchs });
 
         //조회 Call    
        //  onSearch(); 
 
         // //구분
         // if (name === "kWrcVand")  idata.kWrcVand = value;
         // //그룹코드    
         // else if (name === "kWrcGocd")  idata.kWrcGocd = value;
         // //코드1    
         // else if (name === "kWrcCod1")  idata.kWrcCod1 = value;
     }

    const editCellClicked =  (params) =>{
        if (params.value === null || params.value === undefined || params.value === "")
            return;

        let data =  [];

        data["context"] = params.value;

        setDatas({ ...data });

        setDatas(data);
        setTypeOpen(true);
    }

     /**
      * Grid Event 
      * @param {*} e 
      */

     
      const onCellValueChanged = async (e) => {
     }


      
       

     const onRowClicked = async (e) => {
     }
 
 
     /**
      * 검색조건
      */
      const optionsX = {
         0: [
            //T1업체
            {
                "formatter": "select", "name": "kVnt1", "labelName": "T1업체", "type": "text", "defaultValue": idata.kVnt1,  
                "codeMode": true,
                "selectAll": "",
                "codes": null,
                "comms": idata.vnt1List,
                "align": "left", 
            }, 
            // 로그 일자
            {
                "formatter": "input", "name": "kLogDate", "labelName": "로그일자", "type": "date", "desc": ""
                , "defaultValue": idata.kLogDate  
                , "align": "left" 
                , "labelType": "none" 
            }, 
            {
                "formatter": "input", "name": "kLogDlno", "labelName": "납품서번호", "type": "text", "desc": "", "clearButton": true
                , "defaultValue": idata.kLogDlno  
                , "align": "left" 
                , "labelType": "none" 
            }, 
             //업체명
             {"formatter": "blank", "align": "left" },
             {"formatter": "blank", "align": "left" },
             {"formatter": "blank", "align": "left" }
         ]
     }
 
 
 
     /**
      * 조회 
      * @param {*} e 
      * @returns 
      */
     const onSearch = async (e) => {
         
        if (idata.kLogDate === ""){
            setMessage({ openAlert : true, 
                severity : "info",
                msg : "로그일자 항목을 입력해 주세요."});
            return;
        }
 
         let para = `{"kVnt1":"${idata.kVnt1}","kLogDate":"${idata.kLogDate.replace(/-/g, "")}",
                      "kLogDlno":"${idata.kLogDlno}"}`;
         let param = encodeURI(para);
 
         const query = SERVER_URL + "/rsy42/search?query=" + param;
 
         setSearchApi(query); // Search API
     }
 
 
 
     /**
      * 신규 Row추가
      * @param {*} e 
      */
     const onAddRow = async (e) => {
     }
 
 
     
     /**
      * 입력
      * @param {*} e 
      */
     const onInsert = async (e) => {
     }
 
 
 
     /**
      * 수정 
      * @param {*} e 
      * @returns 
      */
     const onUpdate = async (e) => {
     }
 
 
 
     /**
      * 삭제
      * @param {*} e 
      */
     const onDelete = async (e) => {
     }
 
    const onExcel = async (e) => {
        let para = `{"kLogDate":"${idata.kLogDate.replace(/-/g, "")}","kLogDlno":"${idata.kLogDlno}"}`;

        para=Common.addDescAttribute(exceldata,para);

        let param = encodeURI(para);

        const query = SERVER_URL + "/rsy42/excel?query=" + param;
        
        await fetch(query, {headers: {Authorization: token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'rsy42.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })
    }

     //Transaction AlertDialogResult
     const AlertDialogResult = async (e) => {
 
         //AlertDialog 초기화
         setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
 
         //DialogResult
         if (e.result == "N") return;
 
         //Transaction 구분
         switch (e.Trans) 
         {
             //등록
             case "I" :
                 break;
             //수정
             case "U" :
                 break;
             //삭제
             case "D" :
                 const query = SERVER_URL + "/rsy42/delete";
                 setDeleteApi(query); // Update API
                 break; 
         }
     }

     /**
      *Grid Column 정의 
      */
      const columnDefs = [
        { headerName: "로그일자", field: "ldate",   sortable: true, filter: true, minWidth: 200, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, valueFormatter : Utils.dateFormatter},
        { headerName: "납품서번호", field: "dlno",  sortable: true, filter: true, minWidth: 250, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},                 
        { headerName: "입력일자", field: "ltime",   sortable: true, filter: true, minWidth: 180, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},         
        { headerName: "로그메세지", field: "msg",   sortable: true, filter: true, minWidth: 350, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false}, 
        { headerName: "삭제구분", field: "delgubn", sortable: true, filter: true, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
        { headerName: "상세로그", field: "lst",     sortable: true, filter: true, minWidth: 250, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false, onCellClicked: editCellClicked
            ,cellStyle: params => {//cell배경색 변경
                {return {color: "#022dff",cursor:"pointer"};}} },
        { headerName: "상세로그2", field: "lst2",   sortable: true, filter: true, minWidth: 250, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false, onCellClicked: editCellClicked
            ,cellStyle: params => {//cell배경색 변경
                {return {color: "#022dff",cursor:"pointer"};}} },
    ];
    
    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 55px  - 50px)"
              });
        }
    }
    
     return(
         <>
             {/* Dialog FRAME */}
             <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />
 
             {/* Alert FRAME */}
             <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
 
            <Popup type="ChullogPopup" open={typeOpen} onClose={()=> {setTypeOpen(false)}} data = {data} />
                     
             {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
             <Ribbon
                //  onInfoMsg={""}
                 onInfoManual={false}                        
                 onSearch={onSearch} onSearchName={null} //조회
                 onExcel={onExcel} onExcelName={null} //Excel
             >
                 {"시스템관리 > 운영관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>출하전송관리 (관리자전용)</b>
             </Ribbon>
 
 
             {/* ContentXearch Frame : 검색조건 */}
             <ContentXearch
                 optionsX={optionsX}
                 onChange={onChange} 
                 onClick={onClick}/>
 
 
             {/* Content FRAME (Data / Grid Frame) START -------------- */}
             <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:55px */}
                 <div className={"grid_type01"} > {/* 상단 FRAME */}
                     <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                         <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                             <Grid
                                 ref={grdRsy42}
                                 gridId={"RSY42"}                    //  (필수) Grid Id 정의
                                 columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                 pagination={true}                  //  쪽수 매기기 여부
                                 paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                 paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                 rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                 suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                 rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                 enableRangeSelection={false}        //  
                                 enableCellTextSelection={true}      // cell selection drag mode
                                 isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                 displayNo = {false}
                                 searchApi={searchApi}               // 조회
                                 insertApi={insertApi}               // 등록
                                 updateApi={updateApi}               // 수정
                                 deleteApi={deleteApi}               // 삭제
                                 updateRefresh={onSearch}          // 수정이후 
                                 deleteRefresh={onSearch}            // 삭제이후 
                                 onClearApi={onClearApi}
                                 //onClearApi={()=> {setSearchApi(false); setUpdateApi(false);}}
                                 onCellValueChangedEvent={onCellValueChanged}
                                 onRowClickEvent={onRowClicked}
                                 
                             />
                         </div>
                     </div>
                 </div>
             </Content>
         </>
     )
 }
 export default RSY42;