/**
 * @Page REPD1
 * @Description [REPD1]수신자료관리 - ERP_자재정보조회 - 일검수통보(D1)
 **/
import React, {useState, useEffect, useRef, useCallback, forwardRef} from "react";
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; // Common.js
import CommonUtil from '@/utils/CommonUtil'; // Util.js
import {getToday} from "@/utils/Common";
import UploadModal from "@/components/organisms/UploadModal";
import Loading from "src/components/atoms/CustomLoading2";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const Utils = new CommonUtil();


function REPD1() {
    const userId = Common.getToken("id");
    const token = Common.getToken();
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const bigi = Common.getToken("bigi");
    const grdRepd1Main = useRef();   // Grid Main

    const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
    let [loading, setLoading] = useState(false);
    //Data
    const [idata, setData] = useState({
        kFpdt: getToday(),
        kTpdt: getToday(),
        kDocd: "",
        kVnt1 : ckVnt1,        //T1업체     
        kComp: tComp,       //회사구분
        kVnt1Nm : "",        //T1업체명
        kCompNm : tComp==="EHMC"?"현대자동차(주)":"기아자동차(주)",        //회사명
        kPageNum :1,//하단 페이지
        kPageTotal :0,//total 페이지
        kTotcnt : 0,//total 갯수

        //코드 데이터
        vnt1List : [],  //T1업체
        compList : [],  //회사구분
        docdList : [],  //문서코드
    });


    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;
    const [uploadInfo, setUploadInfo] = useState({ vnt1: tVnt1, subDir: "DATA" });

    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;

    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    const [openUpload, setOpenUpload] = React.useState(false); // Modal 항목 setting

    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 80px)"});
    const [gridApi, setGridApi] = useState(null);

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
    }, []);

    const onGridReady  = (params) => {
        setGridApi(params);
    };
    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommonAsync = async (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success)
                    {
                        commonData[name] = data.data;
                        setData({...commonData});
                    }
                })
        }
        await fetchData(para, name);
    }

    const onSearchCommon1 = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                    commonData[name] = data.data[0].text;
                    setData({...commonData});
                    }
                })
        }
        fetchData(para, name);   
    }


    /**
     *MOUNT
     */
    useEffect( () => {
        //공통코드 조회 (T1업체)
        onSearchCommonAsync(`{"pgmid":"VNT1"}`, "vnt1List");
        //공통코드 조회 (회사)
        onSearchCommonAsync(`{"pgmid":"COMP"}`, "compList");
        //공통코드 조회 (문서코드)
        onSearchCommonAsync(`{"pgmid":"DOC_ERPD1","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "docdList");

        onSearchCommon1(`{"pgmid":"WRCVAND","kVnt1":"${idata.kVnt1}"}`, "kVnt1Nm");

    }, []);



    /**
     * Control 변경 Event
     * @param {*} e
     */
    const onChange = async (e) => {
        const {name, value, desc} = e.target;

        let newSearchs = idata;
        let newUploadInfo = uploadInfo;
        newSearchs[name] = value
        setData({ ...newSearchs });

         //T1업체, 회사구분 변경시 
         if (name === "kVnt1")  {
            newSearchs["kDocd"] = "";
            newSearchs["kPageNum"] = 1;
            newUploadInfo["vnt1"] = value;
            newSearchs[name+"Nm"] = desc;
            setData({ ...newSearchs });
            setUploadInfo({ ...newUploadInfo });

            onSearchCommonAsync(`{"pgmid":"DOC_ERPD1","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "docdList");
         
        }
        if( name === "kComp"){
            newSearchs[name+"Nm"] = desc;
            newSearchs["kDocd"] = "";
            setData({ ...newSearchs });
            
            onSearchCommonAsync(`{"pgmid":"DOC_ERPD1","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "docdList");

        }
        if( name === "kDocd"){
            newSearchs["kPageNum"] = 1;
            setData({ ...newSearchs });
        }
    }


    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            // T1업체
            {
                formatter: "select", name: "kVnt1", labelName: "1차업체", type: "text", defaultValue: idata.kVnt1,  
                codeMode: true,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: idata.vnt1List, 
                align: "left", 
            },
            // 회사구분
            {
                formatter: "select", name: "kComp", labelName: "회사구분", type: "text", defaultValue: idata.kComp,  
                codeMode: false,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: CODES["comp"],
                comms: null,
                align: "left", 
            },
            // 문서코드
            {
                formatter: "select", name: "kDocd", labelName: "문서코드", type: "text", defaultValue: idata.kDocd,  
                codeMode: false,
                selectAll: false, // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: [{comCode: "", text: "문서 전체"}],
                comms: idata.docdList,
                align: "left", 
            },
            // 전체건수
            {
                formatter: "input", name: "KTotcnt", labelName: "전체건수", type: "text", defaultValue: idata.kTotcnt,  
                codeMode: false,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null, // 2015~2022년까지 :: Case 1 - codes :: Code List
                comms: null,
                align: "left", 
            },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
        ],
    }

    //조회건수
    const onSetCount = (e) => {
        let cnt;
        if(gridApi.api.rowRenderer.gridApi.rowModel.rowsToDisplay.length > 0){
            cnt = gridApi.api.rowRenderer.gridApi.rowModel.rowsToDisplay[0].data.cnt;
        }else{
            cnt = 0;
        }
        let newSearchs = idata; 

        if(idata.kPageNum === 1){
            newSearchs["kPageTotal"] = Math.ceil(parseInt(cnt)/500);
            newSearchs["kTotcnt"] = Common.getNumberFormatter(cnt);
            setData({ ...newSearchs });
        }   
    }

    /**
     * 조회
     * @param {*} e
     * @returns
     */
    const onSearch = async (e) => {
 
        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);
        
        const page = "ERPD1"
        let para = `{
                        "kGubn":"${page}",
                        "kVnt1": "${idata.kVnt1}", "kDocd": "${idata.kDocd}", "kComp": "${idata.kComp}","kPtno":"${idata.kPtno}",
                        "kPageNum":"${idata.kPageNum}"
                    }`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/repd/search?query=" + param;
        setSearchApi(query); // Search API

    }

   /**
     * 파일생성
     * @param {*} e 
     */
    const onUpload = async (e) => {
        let newSearchs = idata; 

        newSearchs["vnt1"] = idata.kWrcVand;
        setData({ ...newSearchs });

        setOpenUpload(true);
   }
   
   const handleUploadClickOpen = () => {
       setOpenUpload(true);
   };

   const handleUploadClose = () => {
       setOpenUpload(false);
   };

    /**
     * 신규 Row추가
     * @param {*} e
     */
    const onAddRow = async (e) => {
    }



    /**
     * 입력 (발행)
     * @param {*} e
     */
    const onInsert = async (e) => {

    }



    /**
     * 수정
     * @param {*} e
     * @returns
     */
    const onUpdate = async (e) => {
    }

    /**
     * 삭제
     * @param {*} e
     */
    const onDelete = async (e) => {
    }

    /**
     * 엑셀
     * @param {*} e
     */
     const onExcel = async (e) => {
        const page = "ERPD1"
        let para = `{"kDocument":"D1 검수합격통보서", "kGubn":"${page}","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kDocd":"${idata.kDocd}",
                    "vnt1Nm":"${idata.kVnt1Nm}","compNm":"${idata.kCompNm}","docdNm":"${idata.kDocd===""?"전체":idata.kDocd}","kTotcnt": "${idata.kTotcnt}"}`;

        let param = encodeURIComponent(para);
        const query = SERVER_URL + "/repd/excel?query=" + param;
        //엑셀파일 조회 시 반응
        setLoading(true);
        await fetch(query, {headers: {Authorization: token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'ERPD1.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })
        setLoading(false);
        
        // 엑셀파일 생성 메세지
        setMessage({ openAlert : true, 
                     severity : "success",
                     msg : "엑셀파일이 생성되었습니다."});
    }

    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});



        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans)
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D2" :
                break;
            case "D3" :
                break;
        }
    }
    
    const onPrevnClickedEvent = (params) =>{

        let newSearchs = idata; 

        if(idata.kPageNum != 1){
            newSearchs["kPageNum"] = idata.kPageNum-1;
        }else{
            newSearchs["kPageNum"] = 1;
        }
        
        setData({ ...newSearchs });

        if(idata.kPageNum >= 1){
            onSearch();
        }
    }

    const onNextClickedEvent = (params) =>{
        let newSearchs = idata; 

        newSearchs["kPageNum"] = idata.kPageNum+1;
        setData({ ...newSearchs });

        if(idata.kPageTotal >=  idata.kPageNum ){
            onSearch();
        }
    }
    /**
     *Grid Column 정의
     */
        // 월별 컬럼
    const mainColumnDefs = [
           { headerName: "Document Code", field: "COL01", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "공장", field: "COL03", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "품번", field: "COL04", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true,valueGetter: Utils.partnoGetter, hide: false },
            { headerName: "입하일자", field: "COL05", sortable: true, filter: true, flex: 1, minWidth: 110, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,valueFormatter: Utils.dateFormatter, hide: false },
            { headerName: "외부납품서번호", field: "COL06", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "외부 납품서의 품목 번호", field: "COL07", sortable: true, filter: true, flex: 1, minWidth: 180, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "입고 Source", field: "COL08", sortable: true, filter: true, flex: 1, minWidth: 110, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "입고구분", field: "COL09", sortable: true, filter: true, flex: 1, minWidth: 110, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "입하수량", field: "COL10", sortable: true, filter: true, flex: 1, minWidth: 110, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "입고수량", field: "COL11", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "입고금액", field: "COL12", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "입고불량 Code", field: "COL13", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "입고단가", field: "COL14", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "입고일자", field: "COL15", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,valueFormatter: Utils.dateFormatter, hide: false },
            { headerName: "구매 문서 번호", field: "COL16", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "구매 품목 번호", field: "COL17", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "L/C 차수", field: "COL18", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "해당 L/C 차수 개설수량", field: "COL19", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "해당 L/C 입고누계", field: "COL20", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "L/C Number", field: "COL21", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "포장단가", field: "COL22", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "포장수량", field: "COL23", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "Shop Code", field: "COL24", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "자재문서번호", field: "COL25", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "자재 전표 품목", field: "COL26", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "자재 전표 연도", field: "COL27", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "통화구분", field: "COL28", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "Filter", field: "COL29", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        ];

        const onClick = async (e) => {
            setPanded(!panded);
    
            if(panded===true){
                setStyle({       
                    height :"calc(100vh - 60px - 50px - 80px)"
                  });
            }else{
                setStyle({
                    height :"calc(100vh - 60px - 50px - 55px  - 80px)"
                  });
            }
        }

    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({...message, openAlert:false})}} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} />

            {/* Upload FRAME */}
            <UploadModal
                open={openUpload}
                onClose={handleUploadClose} 
                onClickOpen={handleUploadClickOpen}
                title={"CSV 업로드"}                
                fileId={"ERPD1"}
                filePath={"C:\\HKC\\VAN\\ERPD1.CSV"}
                uploadInfo={uploadInfo}
                accp=".csv"
                maxFiles={1}
                validatorName={"csv"}
                uploadApi={SERVER_URL + "/erp/upload1"}
                data={idata.vnt1List}
            />
            {/* Loading */}
            <Loading loading={loading} /> 

            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onSearch={onSearch} onSearchName={null}     //조회
                onUpload={onUpload} onUploadName={"생성"}//생성  
                onExcel={onExcel} onExcelName ={null}   //Excel
            >
                {"수신자료관리 > 자재정보자료조회 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>검수합격통보서 [ D1 ]</b>
            </Ribbon>

            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick={onClick}/>

            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:100px */}
                <div className={"grid_type01"}> {/* 상단 FRAME */}
                    <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                        <Grid
                            ref={grdRepd1Main}
                            gridId={"REPD1"}                    //  (필수) Grid Id 정의
                            columnDefs={mainColumnDefs}             //  (필수) 컬럼 정의
                            pagination={false}                  //  쪽수 매기기 여부
                            paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                            onGridReadyEvent={onGridReady}
                            paginationPageSize={"500"}         //  쪽수 1Page 당 Row수
                            rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                            suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                            rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                            enableRangeSelection={false}        //
                            enableCellTextSelection={true}      // cell selection drag mode
                            isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                            displayNo = {false}
                            searchApi={searchApi}               // 조회
                            onClearApi={()=> {onSetCount();onClearApi()}}
                            onRowClickEvent={null}
                            paginationCustom = {true}
                            onPrevnClickedEvent={onPrevnClickedEvent}
                            onNextClickedEvent={onNextClickedEvent}
                        />
                    </div>
                </div>
            </Content>
        </>
    )
}
export default REPD1;