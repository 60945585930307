/**
 * @Page RSY44
 * @Description [RSY44]시스템관리 > 시스템관리 > 팝업관리
 **/
 import React, { useState, useEffect, useRef, useCallback } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
 import Popup from '@/pages/Popup/Popup';     
 import { useParams } from 'react-router-dom';


 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
 const Utils = new CommonUtil();
 
 
 function RSY44() {
     const token = Common.getToken();
     const userId = Common.getToken("id");
     const userName = Common.getToken("name");
     const tVnt1 = Common.getToken("vand");
     const tComp = Common.getToken("comp");
     const grdQnaMain = useRef();   //Grid Main
     const {id} = useParams();
 
 
     //Data
     const [idata, setData] = useState({
         //조회조건
         kSear : "1",      
         kSval : "",
         vdnm:"",
         numb:"",
         wrcseq:0,         
         userName:userName,
     }); 
 
     
 

     const [message, setMessage] = React.useState( {
         openAlert : false,      
         severity : "success",   
         msg : ""                
     });
     const { openAlert, severity, msg } = message;
 
 
     //Dialog
     const [dlgMessage, setDlgMessage] = React.useState( {
         openDlg : false,    
         dlgTrans : "",      
         dlgMsg : ""         
     });
     const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
     const [typeOpen, setTypeOpen] = React.useState(false);
     const [data, setDatas] = useState([]);
 
     //Grid Search, Insert, Update, Delete Api
     let [searchApi, setSearchApi] = useState(false); 
     let [insertApi, setInsertApi] = useState(false); 
     let [updateApi, setUpdateApi] = useState(false); 
     let [deleteApi, setDeleteApi] = useState(false); // deleteApi 항목 setting
     const [panded, setPanded] = React.useState(true);
     const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 50px)"});

     const onClearApi = useCallback(async (e) => {
         setSearchApi(false);
         setInsertApi(false);
         setUpdateApi(false);
         setDeleteApi(false);
     }, []);
     
     /**
      *MOUNT 
      */
     useEffect( () => {
         onSearch();              
         if (id) {
             let data=[];
             data["gubn"] = "search";
             data["numb"]=id;
             setDatas({ ...data });
             setTypeOpen(true);
             
         }

     }, []);

     
   
     /**
      * Control 변경 Event
      * @param {*} e 
      */
     const onChange = async (e) => {
         const { name, value } = e.target;
 
         let newSearchs = idata;
         newSearchs[name] = value
 
         setData({ ...newSearchs });
 
         //조회 Call    
         //onSearch(); 
 
     }
 
 
 
     /**
      * Grid Event 
      * @param {*} e 
      */
     const onRowClicked = async (e) => {
 
         let data =  e.data;

         data["gubn"] = "search";
         data["updateCheck"] = "1";
         
         setDatas({ ...data });
         setTypeOpen(true);
     }
 
 
 
     /**
      * 검색조건
      */
      const optionsX = {
         0: [
             //구분
             {
                 formatter: "radio", name: "kSear", labelName: "", type: "text", desc: "", readOnly: false,
                 options: [{name: "kSear1", setValue: "1", labelName: "제목"},  {name: "kSear3", setValue: "3", labelName: "날짜"}],
                 defaultValue: idata.kSear,  
                 align: "left", 
                 labelType: "none", 
             },//그룹코드
             {
                 formatter: "input", name: "kSval", labelName: "", type: "text", desc: "",
                 defaultValue: idata.kSval,  
                 align: "left", 
                 labelType: "none" 
             },  
             {"formatter": "blank", "align": "left" },
             {"formatter": "blank", "align": "left" },
             {"formatter": "blank", "align": "left" },
             {"formatter": "blank", "align": "left" }
         ]
     }
 
 
 
     /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {

        const fetchData = async (e) => {//조회시 전체건수구해서 넣어주기   
            let inputData = idata; 

            let para = `{"kSear":"${idata.kSear}","kSval":"${idata.kSval}"}`;
            let param = encodeURI(para);
            const query = SERVER_URL + "/wrtpopup/search?query=" + param;

            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())  
                .then((data) => {
                    if (data.success) {
                        setSearchApi(query);                                                          
                    }
                    if (data.error) {
                        alert("서버 상태를 확인해 주세요. 관리자에게 문의해주세요.");
                    }
                    })
                    .catch((error) => {
                    if (error) {
                        setMessage({ openAlert : true, 
                            severity : "error",
                            msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                    }
                    });
                    };
            fetchData(e);

    }
    /**
      * 신규 Row추가
      * @param {*} e 
      */
     const onAddRow = async (e) => {
     }
 
 
     
     /**
      * 입력
      * @param {*} e 
      */
     const onInsert = async (e) => {
 
         let data =  [];
 
         data["gubn"] = "write";//아이디
         // data["vdnm"] = idata.userName;//이름
         data["chk"] = "0";//최초 입력인지 확인         
         // setDatas({ ...data });
            
         setDatas({ ...data });            
         setTypeOpen(true);
     }
 
 
 
     /**
      * 수정 
      * @param {*} e 
      * @returns 
      */
     const onUpdate = async (e) => {
 
          //input check
          let input = await Promise.all ([inputCheck('U')]);
          if ( !input[0]) return;
         
         const query = SERVER_URL + "/rsy10/update";
         setUpdateApi(query); // Update API
     }
 
 
 
     /**
      * 삭제
      * @param {*} e 
      */
     const onDelete = async (e) => {
          
         //input check
         let input = await Promise.all ([inputCheck('D')]);
         if ( !input[0]) return;
 
         //삭제 확인 메세지 
         setDlgMessage({openDlg : true,  
                        dlgTrans : "D", 
                        dlgMsg : "선택한 데이터를 삭제하시겠습니까?" });
     }
 
 
 
     // input check
     const inputCheck = (gbn) => { 
     }

 
 
 
     //Transaction AlertDialogResult
     const AlertDialogResult = async (e) => {
 
         //AlertDialog 초기화
         setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
 
         //DialogResult
         if (e.result == "N") return;
 
         //Transaction 구분
         switch (e.Trans) 
         {
             //등록
             case "I" :
                 break;
             //수정
             case "U" :
                 break;
             //삭제
             case "D" :
                 const query = SERVER_URL + "/rsy10/delete";
                 setDeleteApi(query); // Update API
                 break;
         }
     }
     
     const locFormatter = (params) => {
        if(params.value == "L"){
            return "로그인"
        }
        else if(params.value == "M"){
            return "메인"
        }        
        else{
            return ""
        }        
      };

      const YnFormatter = (params) => {
        if(params.value == "Y"){
            return "사용"
        }
        else if(params.value == "N"){
            return "미사용"
        }        
        else{
            return ""
        }        
      };

      const TypeFormatter = (params) => {
        if(params.value == "I"){
            return "이미지"
        }
        else if(params.value == "T"){
            return "텍스트"
        }        
        else{
            return ""
        }        
      };
    
     
 
 
     /**
      *Grid Column 정의 
      */
     const columnDefs = [
         { headerName: "번호", field: "wrcseqn", sortable: true,    filter: true, flex: 1, minWidth: 100, editable: false, hide: false, cellClass: ["nocheckbox", "alignC"]},
         { headerName: "제목", field: "wrctitl", sortable: true, filter: true, flex: 3, minWidth: 200, editable: false, hide: false, cellClass: ["nocheckbox", "alignL"] 
         ,cellStyle: params => {//cell글자색 변경
             if (params.data.numb === " ") {return {fontWeight: "900"};}
             return null;
         }}, 
         { headerName: "파일명", field: "fileName", sortable: true, filter: true, flex: 3, minWidth: 70, hide: false, cellClass: ["nocheckbox", "alignC"]}, 
         { headerName: "링크", field: "wrclink", sortable: true, filter: true, flex: 3, minWidth: 70, hide: false, cellClass: ["nocheckbox", "alignC"]}, 
         { headerName: "적용 페이지", field: "wrclocation", sortable: true, filter: true, flex: 1, minWidth: 100, hide: false, cellClass: ["nocheckbox", "alignC"], valueFormatter : locFormatter}, 
         { headerName: "시작일", field: "wrcfrom", sortable: true, filter: true, flex: 2, minWidth: 100, editable: false,  cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,valueFormatter: Utils.dateTiemFormatter  }, 
         { headerName: "종료일", field: "wrcto", sortable: true, filter: true, flex: 2, minWidth: 100, editable: false,  cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,valueFormatter: Utils.dateTiemFormatter  }, 
         { headerName: "작성일", field: "wrcidat", sortable: true, filter: true, flex: 2, maxWidth: 90, editable: false,  cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,valueFormatter: Utils.dateTiemFormatter  }, 
         { headerName: "사용여부", field: "useYn", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, cellClass: ["nocheckbox", "alignC"], valueFormatter : YnFormatter,  singleClickEdit: true, hide: false }, 
         { headerName: "내용", field: "wrccont", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true }, 
         { headerName: "타입", field: "wrctype", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, cellClass: ["nocheckbox", "alignC"],valueFormatter : TypeFormatter, singleClickEdit: true, hide:false }, 
         { headerName: "wrcfile", field: "wrcfile", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true },
         { headerName: "fileId", field: "fileId", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true },
         { headerName: "wrcperiod", field: "wrcperiod", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true },
         { headerName: "wrccontsize", field: "wrccontsize", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true },
    ];
 
     const onClick = async (e) => {
            setPanded(!panded);
    
            if(panded===true){
                setStyle({       
                    height :"calc(100vh - 60px - 50px - 50px)"
                  });
            }else{
                setStyle({
                    height :"calc(100vh - 60px - 50px - 55px  - 50px)"
                  });
            }
        }

     return(
         <>
             {/* Dialog FRAME */}
             <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />
 
             {/* Alert FRAME */}
             <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
              
             <Popup type="PopupMngDetail" open={typeOpen} onClose={()=> {setTypeOpen(false);onSearch();}} data ={data} style={{ height:"700px" }}/>
             {/* <Popup type="PopupMngPopup" open={typeOpen} onClose={()=> {setTypeOpen(false);}} data ={data} style={{ height:"700px" }}/> */}
             {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
             <Ribbon
                 onSearch={onSearch} onSearchName={null} //조회
                 onInsert={onInsert} onInsertVisible = {tVnt1 === "M000"}     //등록
                 //onUpdate={onUpdate} onUpdateName={null} onUpdateVisible = {tVnt1 === "M000"}   //수정
                 //onDelete={onDelete} onDeleteName={null} onDeleteVisible = {tVnt1 === "M000"}    //삭제
             >
                 <b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>팝업관리</b>
             </Ribbon>
 
 
             {/* ContentXearch Frame : 검색조건 */}
             <ContentXearch
                 optionsX={optionsX}
                 onChange={onChange} 
                 onClick={onClick}/>
 
 
             {/* Content FRAME (Data / Grid Frame) START -------------- */}
             <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:55px */}
                 <div className={"grid_type01"} > {/* 상단 FRAME */}
                     <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                         <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                             <Grid
                                 ref={grdQnaMain}
                                 gridId={"QNA"}                    //  (필수) Grid Id 정의
                                 columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                 pagination={false}                  //  쪽수 매기기 여부
                                 paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                 paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                 rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                 suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                 rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                 enableRangeSelection={false}        //  
                                 enableCellTextSelection={false}      // cell selection drag mode
                                 isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                 displayNo = {false}
                                 searchApi={searchApi}               // 조회
                                 insertApi={insertApi}               // 등록
                                 updateApi={updateApi}               // 수정
                                 deleteApi={deleteApi}               // 삭제
                                 //updateRefresh={onSearch}          // 수정이후 
                                 deleteRefresh={onSearch}            // 삭제이후 
                                 onClearApi={onClearApi}
                                 //onClearApi={()=> {setSearchApi(false); setUpdateApi(false);}}
                                 // onCellValueChangedEvent={onCellValueChanged}
                                 onRowClickEvent={onRowClicked}
                                 
                             />
                         </div>
                     </div>
                 </div>
             </Content>
         </>
     )
 }
 export default RSY44;
 
 
 
 
 