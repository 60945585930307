import React from "react";
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popup from '@/pages/Popup/Popup';    
// import QNA from '@/pages/NOTICE/QNA';    

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import DraftsIcon from '@material-ui/icons/Drafts';
// import SendIcon from '@material-ui/icons/Send';

import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import CHAT from '@material-ui/icons/Chat';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';


import "../../css/main.css";
import PageTemplates from './../templates/PageTemplates';

import * as Common from '@/utils/Common'; 


// function CustomMenu({ open, severity, onClose, message, duration, anchorOrigin }) {
function CustomMenu({ onLogoutEvent } ) {
  const token = Common.getToken();  
  const tVnt1 = Common.getToken("vand");


  // const options = [
  //   '공지사항',
  //   'Q&A',
  //   '설정',
  //   '로그아웃'
  // ];

  // const ITEM_HEIGHT = 48;

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  // '&:focus': {
  //   backgroundColor: theme.palette.primary.main,
  //   '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
  //     color: theme.palette.common.white,
  //   },
  // },
  


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [typeOpen, setTypeOpen] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    if (onLogoutEvent) {
      setAnchorEl(null); // Menu창 닫기
      onLogoutEvent();
    }
  };
  const handleMyinfo = () => {
    setTypeOpen(true);
    setAnchorEl(null);
  };
  const handleQna = () =>{
    setAnchorEl(null);
  };
  const handleGNGS = () =>{
    setAnchorEl(null);  
  };
  const handleNote = () =>{
    setAnchorEl(null);
  };
  const handleNoteTop = () =>{
    setAnchorEl(null);
  };

  return (
    <div style={{ float: "right", width: "30px", paddingLeft: "5px" }}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      {/* <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}

        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} selected={option === 'Case1'} onClick={() => onClick}>
            {option}
          </MenuItem>
        ))}
      </Menu> */}
      <Popup type="MyInf" open={typeOpen} onClose={()=> {setTypeOpen(false)}}/>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        // keepMounted
        open={open}
        // open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        
      {tVnt1==="M000"?
        <StyledMenuItem onClick={handleNoteTop}>
        <ListItem component={Link} to="/C02/RSY43" style={{paddingTop:"0px",paddingBottom:"0px"}}>
          <ListItemIcon>
            <NotificationImportantIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="상단공지" />
          </ListItem>
        </StyledMenuItem>
        :null}

        <StyledMenuItem onClick={handleNote}>
        <ListItem component={Link} to="/D01/RSY50" style={{paddingTop:"0px",paddingBottom:"0px"}}>
          <ListItemIcon>
            <AnnouncementIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="공지사항" />
          </ListItem>
        </StyledMenuItem>

        <StyledMenuItem onClick={handleQna}>
          <ListItem component={Link} to="/D01/RSY51" style={{paddingTop:"0px",paddingBottom:"0px"}}>{/*Link사용을 위해 추가*/}
            <ListItemIcon>
              <QuestionAnswerIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Q＆A"/>
          </ListItem>
        </StyledMenuItem>

        {/* <StyledMenuItem onClick={handleGNGS}>
          <ListItem component={Link} to="/GNGS" style={{paddingTop:"0px",paddingBottom:"0px"}}>
            <ListItemIcon>
              <CHAT fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="기능개선"/>
          </ListItem>
        </StyledMenuItem> */}


        <StyledMenuItem onClick={handleMyinfo}>
        <ListItem style={{paddingTop:"0px",paddingBottom:"0px"}}>
          <ListItemIcon>
            <SettingsIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="MyInfo" />
          </ListItem>
        </StyledMenuItem>

        <StyledMenuItem onClick={handleLogout}>
        <ListItem style={{paddingTop:"0px",paddingBottom:"0px"}}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="로그아웃" />
          </ListItem>
        </StyledMenuItem>

      </StyledMenu>

    </div>
  );
}
export default CustomMenu;
