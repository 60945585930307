/**
 * @Page WrapCkdLabelPrintPercentB
 * @Description [ROM4K / ROM4R / ROM4X]납입지시 - ERP_CKD출하관리 - 부품식별표(중포장/용기/중포장2D) 인쇄  ::  B 6매
 **/
import React from 'react';
import CustomDataMatrix from '@/components/atoms/CustomDataMatrix';
import * as Common from '../../utils/Common';
import styled from 'styled-components';

class WrapCkdLabelPrintPercentB extends React.Component {
	// constructor 생성자
	constructor(props) {
		super(props);
		this.state = {
			bplx: '0',
			bply: '0',
			pathNm: null,
		};
	}

	// componentDidMount 화면 최초 실행 시,  token 에서 x, y 좌표값 가져오기
	componentDidMount() {
		const info_bplx = Common.getToken('bplx') === null ? 0 : parseInt(Common.getToken('bplx'), 10) * 2; // 로그인한 사용자의 부품식별표 의 x좌표값 받아오기
		const info_bply = Common.getToken('bply') === null ? 0 : parseInt(Common.getToken('bply'), 10) * 2; // 로그인한 사용자의 부품식별표 의 y좌표값 받아오기

		this.setState({ bplx: info_bplx });
		this.setState({ bply: info_bply });
		if (window.location.pathname.split('/')[2] === 'ROM4X') {
			// ROM4X 부품식별표(중포장 2D) check
			this.setState({ pathNm: window.location.pathname.split('/')[2] });
		}
	}

	// '바코드 = 업체코드(4) + 품번(15) + 수량(5) + LOTNO(3) + 일련번호(4)
	//        tmp(0) '회사구분
	//        tmp(1) '지역
	//        tmp(2) '공장
	//        tmp(3) '차종
	//        tmp(4) '납품장소(하치장)
	//        tmp(5) '품번
	//        tmp(6) '품명
	//        tmp(7) '납품수량
	//        tmp(8) '바코드
	//        tmp(9) '납품일자
	//        tmp(10) '업체명
	//        tmp(11) "" '공정(공백)
	//        tmp(12) '업체코드
	//        tmp(13) "" ' LOT NO
	//        tmp(14) "" 'HPC(공백)
	//        tmp(15) 'CASE(CKD)
	//        tmp(16) "" '발행번호(바코드만들어서 던짐)
	//        tmp(17) "" '용기번호
	//        tmp(18) '부착매수
	//        tmp(19) 'LH/RH
	//        tmp(20) "" '사양
	//        tmp(21)  '발주번호(공백)
	//        tmp(22)  'FROM-ORDER(CKD , OEM은 공정)
	//        tmp(23)  'CKD는 공백, OEM은 HPC
	//        tmp(24)  '용기당수
	//        tmp(25) = '출력1
	//        tmp(26) = 'x좌표1
	//        tmp(27) = 'y좌표1
	//        tmp(28) = '색상1
	//        tmp(29) = 'FONT/S1
	//        tmp(30) = '출력2
	//        tmp(31) = 'x좌표2
	//        tmp(32) = 'y좌표2
	//        tmp(33) = '색상2
	//        tmp(34) = 'FONT/S2
	//        tmp(35) = '한글품명
	//        tmp(36) = 'RHD

	render() {
		// Print Style CSS Setting
		const PrintStyleB = styled.div`
			@media all {
				body {
					background-color: ;
					margin: 0px;
					padding: 0px;
					font-family: 'HY견고딕';
				}
				div {
					background-color: ;
					margin: 0px;
					padding: 0px;
					box-sizing: border-box;
				}
				table {
					border-collapse: collapse;
					border-style: none;
					margin: 0px;
					padding: 0px;
					box-sizing: border-box;
				}
				tr {
					width: 100%;
					margin: 0px;
					padding: 0px;
				}
				td {
					margin: 0px;
					padding: 0px;
					background-color: white;
					font-family: 'HY견고딕';
					border: 0px solid green;
					box-sizing: border-box;
				}
				.HYheadM11 {
					font-family: 'NanumGothic';
					font-weight: 700;
					font-size: 11pt;
				}
				.HYheadM12 {
					font-family: 'HY견고딕';
					font-weight: bold;
					font-size: 12pt;
					word-break: break-all;
				}
				.HYheadM13 {
					font-family: 'HY견고딕';
					font-weight: bold;
					font-size: 13pt;
					word-break: break-all;
				}
				.HYheadM14 {
					font-family: 'HY견고딕';
					font-weight: bold;
					font-size: 14pt;
					word-break: break-all;
				}
				.HYheadM16 {
					font-family: 'HY견고딕';
					font-weight: bold;
					font-size: 16pt;
					word-break: break-all;
				}
				.HYheadM17 {
					font-family: 'HY견고딕';
					font-weight: bold;
					font-size: 17pt;
					word-break: break-all;
				}
				.HYheadM18 {
					font-family: 'HY견고딕';
					font-weight: bold;
					font-size: 18pt;
					word-break: break-all;
				}
				.HYheadM9 {
					font-family: 'HY견고딕';
					font-size: 9pt;
					word-break: break-all;
				}
				.HYheadM24 {
					font-family: 'HY견고딕';
					font-size: 24pt;
					word-break: break-all;
				}

				.table {
					display: table;
					border: 1px solid blue;
				}
				.row {
					// display: table-row;
					display: flex;
					flex-flow: wrap;
				}
				.cell {
					display: table-cell;
					// border-bottom:1px solid blue;
					// border-right:1px solid blue;
					border-width: thin;
					white-space: nowrap;
					z-index: 99;
				}

				.colspan2 {
					flex: 2;
				}
				.colspan3 {
					flex: 3;
				}
				.colspan4 {
					flex: 4;
				}
				.colspan5 {
					flex: 5;
				}
				.colspan6 {
					flex: 6;
				}
				.colspan7 {
					flex: 7;
				}
				.colspan8 {
					flex: 8;
				}
				.colspan9 {
					flex: 9;
				}
				.colspan10 {
					flex: 10;
				}

				.row > .cell:nth-child(1) {
					width: 8.5%;
				}
				.row > .cell:nth-child(2) {
					width: 1.5%;
				}
				.row > .cell:nth-child(3) {
					width: 2.5%;
				}
				.row > .cell:nth-child(4) {
					width: 0.5%;
				}
				.row > .cell:nth-child(5) {
					width: 2%;
				}
				.row > .cell:nth-child(6) {
					width: 15%;
				}
				.row > .cell:nth-child(7) {
					width: 11%;
				}
				.row > .cell:nth-child(8) {
					width: 9%;
				}
				.row > .cell:nth-child(9) {
					width: 13%;
				}
				.row > .cell:nth-child(10) {
					width: 11%;
				}

				.row > .cell:nth-child(11) {
					width: 12%;
				}
				.row > .cell:nth-child(12) {
					width: 14%;
				}
			}
			@media print {
				@page {
					size: A4 landscape;
					margin-top: 0mm;
					margin-left: 0mm;
					margin-right: 4mm;
					margin-bottom: 4mm;
				}

				.table {
					display: table;
					border: 1px solid none;
				}
				.row {
					// display: table-row;
					display: flex;
					flex-flow: wrap;
				}
				.cell {
					display: table-cell;
					border-bottom: 1px solid none;
					border-right: 1px solid none;
					border-width: thin;
					white-space: nowrap;
					z-index: 99;
				}

				div.pagebreak {
					break-after: always !important;
					page-break-after: always !important;
					page-break-inside: avoid !important;
				}
			}

			@media screen {
				.table {
					display: table;
					border: 1px solid blue;
				}
				.row {
					// display: table-row;
					display: flex;
					flex-flow: wrap;
				}
				.cell {
					display: table-cell;
					border-bottom: 1px solid blue;
					border-right: 1px solid blue;
					border-width: thin;
					white-space: nowrap;
					z-index: 99;
					color: blue;
				}
			}
		`;

		const { printData } = this.props;

		// 검색조건 파라메터 가져오기
		let { kStart, chkMetal, kCkdOem, chkNpdt, chkLtno, kNpdt } = this.props.idata;
		const listSize = printData.length; // data length

		let idx = -1; // -1 부터 start
		let str = parseInt(kStart) - 1;

		idx = idx + str; // 시작 위치 (like 배열)

		let vseq = 0;

		let remainChk = 0;

		const cardInfo = printData
			? printData.map((data, index) => {
					if (index === 0) {
						vseq = parseInt(data.SEQ) === 0 ? 0 : parseInt(data.SEQ) - 1; // 바코드 표출 vseq용
					}

					remainChk = vseq; // 새 품번의 remain 여부 체크하기

					let npqt = parseInt(data.NPQT); // 납품수량
					let qypc = parseInt(data.QYPC); // 용기당 수
					let stck = parseInt(data.STCK); // 부착면 수

					// 추가 표시 컬러 체크 -- START
					let tmp25 = data.TMP25; // WRCLBL1	이중인쇄1 내용
					let tmp28 = data.TMP28; // WRCCOL1	이중인쇄1 컬러

					let tmp30 = data.TMP30; // WRCLBL2	이중인쇄2 내용
					let tmp33 = data.TMP33; // WRCCOL2	이중인쇄2 컬러

					let color1 = '';
					let color2 = '';

					let dualPrint1 = 'F';
					let dualPrint2 = 'F';

					let dualPrint1css = ''; // dualPrint1 css 추가 표기 view 1
					let dualPrint2css = ''; // dualPrint2 css 추가 표기 view 2

					if (tmp25 !== '') {
						dualPrint1 = 'T';
						if (tmp28 === 'B') {
							color1 = 'blue';
						} else if (tmp28 === 'G') {
							color1 = 'green';
						} else if (tmp28 === 'R') {
							color1 = 'red';
						} else if (tmp28 === 'O') {
							color1 = '#ff6600';
						} else if (tmp28 === 'X') {
							color1 = 'rgba(0, 0, 0, 0.7)';
						}
					}
					if (tmp30 !== '') {
						dualPrint2 = 'T';
						if (tmp33 === 'B') {
							color2 = 'blue';
						} else if (tmp33 === 'G') {
							color2 = 'green';
						} else if (tmp33 === 'R') {
							color2 = 'red';
						} else if (tmp33 === 'O') {
							color2 = '#ff6600';
						} else if (tmp33 === 'X') {
							color2 = 'rgba(0, 0, 0, 0.7)';
						}
					}

					let result = Math.floor(npqt / qypc); // 몫
					let resultIdx = Math.floor(npqt / qypc); // 목 + 나머지의 카운트
					let remain = npqt % qypc; // 나머지

					if (remain > 0) {
						resultIdx = result + 1;
					}

					// 새 프린트용 배열
					const newSearched = [];

					// 증가 체크 resultIdx
					let n = 0;

					Array.from(new Array(resultIdx), (i) => {
						// 메인 출력물

						Array.from(new Array(stck), (w, j) => {
							// 부착면수 만큼 선 반복..

							// 시작시 idx 셋팅
							idx = idx + 1;
							if (j === 0) {
								// 부착면수 1일때 고려...
								vseq = vseq + 1; // 새 vseq 마다 + 1 증가
							}
							let iCnt = i + 1;

							const newArray = {}; // new Array 선언

							let jCnt = j + 1;

							// 새 배열의 n 번째 구하기
							if (i === 0 && j === 0) {
								n = 0;
							} else {
								n = n + 1;
							}

							newArray['idx'] = idx;
							newArray['vseq'] = vseq;

							//납품수량
							let newQty = 0;
							newArray['qty'] = qypc;
							newQty = qypc;

							newArray['stck'] = stck;

							newArray['firstpage'] = 'F';
							newArray['endpage'] = 'F';

							newArray['color1'] = color1;
							newArray['color2'] = color2;

							newArray['dualPrint1'] = dualPrint1;
							newArray['dualPrint2'] = dualPrint2;

							// 첫번째 Page 구하기
							if (i === 0 && j === 0 && index === 0) {
								newArray['firstpage'] = 'T';
							}

							if (resultIdx === vseq - remainChk) {
								if (remain > 0) {
									newArray['qty'] = remain;
									newQty = remain;
								}
							} else {
								if (index === 0 && resultIdx === 1) {
									if (remain > 0) {
										newArray['qty'] = remain;
										newQty = remain;
									}
								}
							}

							// END Page 구하기
							if (listSize === index + 1) {
								if (resultIdx === iCnt) {
									if (stck === jCnt) {
										newArray['endpage'] = 'T';
									}
								}
							}

							//LOTNO(3)
							const lotNo = data.TMP13 === '' ? ' ' : Common.getLOT(data.TMP13); // LOT NO 날짜 가져오기  data.TMP13

							//일련번호(4)
							// const prno = data.TMP0 + "" + ("0000" + parseInt(data.SEQ, 16)).slice(-4);
							const prno = (data.TMP0 === undefined ? '' : data.TMP0) + '' + ('0000' + parseInt(vseq, 10)).slice(-4);

							// DataMatrix - code128 바코드 값 만들기
							//const barcodeValu = data.TMP12 +""+ (data.TMP5).padEnd(15," ") +""+ ("00000" + newQty).slice(-5) + "" + ("   " + lot).slice(-3) + ""  + ("0000" + prno).slice(-4) + "" ;
							let barcodeValu = '';

							if (kCkdOem === 'CKD') {
								// 중포장일때
								// 수량 7 -> 6 으로 변경 셋팅 2022-06-30
								// 바코드 = 업체코드(4)              + 품번(13)                       + 수량(6)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4)

								// 자동차측 요구로 바코드 자릿수 변경 2023-08-21
								// 바코드 = 오더번호(10)              + 품번(14)                       + 수량(8)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4)
								barcodeValu = data.TMP21 + '' + data.TMP5.slice(0, 14).padEnd(14, ' ') + '' + ('00000000' + newQty).slice(-8) + '' + kNpdt.replace(/-/g, '').slice(-6) + '' + prno;
							} else {
								//용기일때
								// 수량 7 -> 6 으로 변경 셋팅 2022-06-30
								// 바코드 = 업체코드(4)              + 품번(13)                       + 수량(6)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4)

								// 자동차측 요구로 바코드 자릿수 변경 2023-08-21
								// 바코드 = 오더번호(10)              + 품번(14)                       + 수량(8)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4)
								barcodeValu = data.TMP22 + '' + data.TMP5.slice(0, 14).padEnd(14, ' ') + '' + ('00000000' + newQty).slice(-8) + '' + kNpdt.replace(/-/g, '').slice(-6) + '' + prno;
							}
							const dataMatrixBarcode128 = <CustomDataMatrix val={barcodeValu} height={12} bcid={'code128'} scale={1.9} width={320} />;
							newArray['barcode'] = dataMatrixBarcode128;

							// DataMatrix Barcode 만들기  - dataMatrix
							const dataMatrixBarcode = this.state.pathNm !== null && this.state.pathNm === 'ROM4X' ? <CustomDataMatrix val={barcodeValu} height={18} scale={1} /> : '';
							//  const dataMatrixBarcode = <CustomDataMatrix val={barcodeValu} height={18} scale={1} />;
							newArray['barcodeMatrix'] = dataMatrixBarcode;

							const ptnm = data.TMP6.slice(0, 18);
							newArray['ptnm'] = ptnm;

							// const lotNo = data.TMP12 +""+ data.TMP5; // vand + 품번
							// const bNo =  ("00000" + vseq).slice(-5) +""+ "";  // 수량 + 공정 + ? + ?

							// const lotNo = data.VAND +""+ ("000000" + data.BSEQ).slice(-6) +""+ data.TMP11.replaceAll('-','');
							// const lotNo = data.VAND +""+ ("000000" + vseq).slice(-6) +""+ data.TMP11.replaceAll('-','');
							// const bNo = data.VAND +" "+ ("000000000000000" + data.TMP5).slice(-15) +""+ ("00000" + newQty).slice(-5);

							// newArray["lotno"] = lotNo;
							// newArray["bno"] = bNo;

							newArray['lotno'] = lotNo;
							newArray['bno'] = barcodeValu;

							// 자기 위치 셋팅 (출력매수)
							const posi = idx % 6;
							newArray['posi'] = posi;

							let divCss = '';
							let divMetal = '';

							// page별  (출력매수)1장 이상일경우 100% 씩 추가 ... 다음장 노출을 위함.
							let pagebreaky = Math.floor(idx / 6) * 100;
							let pagebreakyCnt = Math.floor(idx / 6);
							/******************************************/
							let totalLeft = -1;
							let totalTop = 1.9;
							let cWidth = 140;
							let cHeight = 31.3; //1개높이
							let cHeight2 = 62.6; //2개높이 Y:2
							let cPageHeight = 202.73; //총높이
							let cPageWidth = 276; //총넓이
							let marginX = 9.2; //margin left
							let marginY = 4.6; //margin top

							let Heightmmy = (100 * this.state.bply) / cPageHeight;
							let Heightmmx = (100 * this.state.bplx) / cPageWidth;
							let HeightTmp26 = (100 * parseInt(data.TMP26, 10)) / cPageWidth;
							let HeightTmp27 = (100 * parseInt(data.TMP27, 10)) / cPageHeight;
							let cLeft = 49.5 * (posi % 2) + totalLeft + HeightTmp26 + Heightmmx + '%';
							let cTop = pagebreaky + (posi < 2 ? 0 : posi < 4 ? cHeight : cHeight2) + totalTop + HeightTmp27 + Heightmmy + '%';

							dualPrint1css = {
								position: 'absolute',
								fontFamily: 'HY견고딕',
								top: cTop,
								left: cLeft,
								color: color1,
								fontSize: parseInt(data.TMP29, 10) + 'pt',
								fontWeight: 'bold',
								zIndex: '13',
								whiteSpace: 'nowrap',
							};

							let totalLeft2 = -1.5;
							let totalTop2 = 3.3;
							let HeightTmp31 = (100 * parseInt(data.TMP31, 10)) / cPageWidth;
							let HeightTmp32 = (100 * parseInt(data.TMP32, 10)) / cPageHeight;

							let cLeft2 = 49.5 * (posi % 2) + totalLeft2 + HeightTmp31 + Heightmmx + '%';
							let cTop2 = pagebreaky + (posi < 2 ? 0 : posi < 4 ? cHeight : cHeight2) + totalTop2 + HeightTmp32 + Heightmmy + '%';

							dualPrint2css = {
								position: 'absolute',
								fontFamily: 'HY견고딕',
								top: cTop2,
								left: cLeft2,
								color: color2,
								fontSize: parseInt(data.TMP34, 10) + 'pt',
								fontWeight: 'bold',
								zIndex: '13',
								whiteSpace: 'nowrap',
							};

							let size = { width: '49%', height: '32%' };

							if (posi === 0) {
								// divCss = {position:"absolute", top:"0.0%", left:"0mm", paddingTop:"2mm", paddingLeft:"2mm",  border:"0px solid red"};
								divCss = { position: 'absolute', top: pagebreaky + 2.8 + Heightmmy + '%', left: 0.5 + Heightmmx + '%', paddingTop: '0mm', paddingLeft: '0mm', border: '0px solid red' };
								divMetal = { position: 'absolute', top: pagebreaky + 18 + Heightmmy + '%', left: 42 + Heightmmx + '%', zIndex: '9' };
							} else if (posi === 1) {
								// divCss = {position:"absolute", top:"0.0%", left:"141mm", paddingTop:"2mm", paddingLeft:"2mm",  border:"0px solid red"};
								divCss = { position: 'absolute', top: pagebreaky + 3 + Heightmmy + '%', left: 49.4 + Heightmmx + '%', paddingTop: '0mm', paddingLeft: '0mm', border: '0px solid red' };

								divMetal = { position: 'absolute', top: pagebreaky + 18 + Heightmmy + '%', left: 91.5 + Heightmmx + '%', zIndex: '9' };
							} else if (posi === 2) {
								// divCss = {position:"absolute", top:"32.8%", left:"0mm", paddingTop:"2mm", paddingLeft:"2mm",  border:"0px solid red"};
								divCss = { position: 'absolute', top: pagebreaky + 34.3 + Heightmmy + '%', left: 0.5 + Heightmmx + '%', paddingTop: '0mm', paddingLeft: '0mm', border: '0px solid red' };

								divMetal = { position: 'absolute', top: pagebreaky + 49.5 + Heightmmy + '%', left: 42 + Heightmmx + '%', zIndex: '9' };
							} else if (posi === 3) {
								// divCss = {position:"absolute", top:"32.8%", left:"141mm", paddingTop:"2mm", paddingLeft:"2mm",  border:"0px solid red"};
								divCss = { position: 'absolute', top: pagebreaky + 34.5 + Heightmmy + '%', left: 49.4 + Heightmmx + '%', paddingTop: '0mm', paddingLeft: '0mm', border: '0px solid red' };

								divMetal = { position: 'absolute', top: pagebreaky + 49.5 + Heightmmy + '%', left: 91.5 + Heightmmx + '%', zIndex: '9' };
							} else if (posi === 4) {
								// divCss = {position:"absolute", top:"65.8%", left:"0mm", paddingTop:"2mm", paddingLeft:"2mm",  border:"0px solid red"};
								divCss = { position: 'absolute', top: pagebreaky + 65.5 + Heightmmy + '%', left: 0.5 + Heightmmx + '%', paddingTop: '0mm', paddingLeft: '0mm', border: '0px solid red' };

								divMetal = { position: 'absolute', top: pagebreaky + 81 + Heightmmy + '%', left: 42 + Heightmmx + '%', zIndex: '9' };
							} else if (posi === 5) {
								// divCss = {position:"absolute", top:"65.8%", left:"141mm", paddingTop:"2mm", paddingLeft:"2mm",  border:"0px solid red"};
								divCss = { position: 'absolute', top: pagebreaky + 65.7 + Heightmmy + '%', left: 49.4 + Heightmmx + '%', paddingTop: '0mm', paddingLeft: '0mm', border: '0px solid red' };

								divMetal = { position: 'absolute', top: pagebreaky + 81 + Heightmmy + '%', left: 91.5 + Heightmmx + '%', zIndex: '9' };
							}
							newArray['divCss'] = divCss;
							newArray['divMetal'] = divMetal;
							newArray['dualPrint1css'] = dualPrint1css;
							newArray['dualPrint2css'] = dualPrint2css;
							newArray['size'] = size;

							// 위에서 계산 해서 본인 위치에 셋팅 !
							// newSearched 새배열에 값 몽땅 넣기
							newSearched[n] = newArray;
						});
					});

					return (
						<>
							{newSearched.map(
								(sData, dataIdx) => (
									<form key={'WrapCkdLabelPrintPercentB' + dataIdx}>
										<div style={{ ...sData.divCss, ...sData.size, overflow: 'hidden', zIndex: '200' }}>
											{' '}
											{/* 패치 전 border 삭제 , border:"1px solid red" */}
											<div className="row" style={{ height: '2.5%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell colspan2"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan2"></div>
												<div className="cell"></div>

												<div className="cell HYheadM12 colspan4"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												{/* <div className="cell"></div> */}

												{/* <div className="cell"></div> */}
											</div>
											<div className="row" style={{ height: '6.5%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell colspan2"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan2" style={{ letterSpacing: '0px', textAlign: 'right', paddingLeft: '3mm' }}>
													{data.TMP1}
												</div>
												{/* 지역 */}
												<div className="cell"></div>

												<div className="cell HYheadM12 colspan4" style={{ fontSize: '10pt', fontFamily: 'NanumGothic', letterSpacing: '0.02px', fontWeight: 'bold' }}>
													{data.TMP0 === 'MPMC' ? '현대파워텍(주)용ⓡ' : data.TMP4 === 'MAMC' ? '글로비스(주)용ⓡ' : '현대자동차(주)/기아(주)ⓡ'}
												</div>
												<div className="cell"></div>
												<div className="cell"></div>
												{/* <div className="cell"></div> */}

												{/* <div className="cell"></div> */}
											</div>
											<div className="row" style={{ height: '8%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell HYheadM16 colspan2">{data.TMP2}</div>
												{/* 공장 */}
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM13 colspan2" style={{ paddingTop: '2px' }}>
													{data.TMP3}
												</div>
												{/* 차종 */}
												<div className="cell"></div>

												<div className="cell"></div>
												<div className="cell HYheadM13 colspan3" style={{ paddingTop: '2px' }}>
													{data.TMP4}
												</div>
												{/* 납품장소 */}
												<div className="cell"></div>
												{/* <div className="cell"></div> */}
											</div>
											<div className="row" style={{ height: '12%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												{/* <div className="cell"></div> */}
												<div className="cell HYheadM18 colspan6" style={{ fontSize: data.TMP5.trim().length > 11 ? '22pt' : '23pt' }}>
													{data.TMP5.substr(0, 5) + '-' + data.TMP5.substr(5)}
													{/* 품번 */}
												</div>
												{/* <div className="cell"></div>
         <div className="cell"></div>         
         <div className="cell"></div>
         <div className="cell"></div>
         <div className="cell"></div> */}

												<div className="cell"></div>
												<div className="cell HYheadM16 colspan2" style={{ fontSize: '24pt', paddingLeft: '1mm' }}>
													{sData.qty}
												</div>
												{/* 수량 */}
											</div>
											<div className="row" style={{ height: '15.3%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												{/* <div className="cell"></div> */}
												<div className="cell HYheadM18 colspan9" style={{ fontSize: '22pt' }}>
													{sData.ptnm}
												</div>
												{/* 품명  paddingTop:"7px" */}
												<div className="cell"></div>
												<div
													className="cell HYheadM18 colspan2"
													style={{ fontSize: '28pt', color: data.TMP19 === '좌' || data.TMP19 === 'L' ? 'red' : 'blue', textAlign: 'right', zIndex: '10' }}
												>
													{data.TMP19}
													{/* LH/RH */}
												</div>
												{/* <div className="cell"></div>
         <div className="cell"></div>
         <div className="cell"></div>
         <div className="cell"></div>
         <div className="cell"></div>
         <div className="cell"></div> */}

												{/* <div className="cell"></div>
         <div className="cell"></div>
         <div className="cell"></div>
         <div className="cell"></div>
         <div className="cell"></div> */}
											</div>
											{/* <div className="row" style={{height:"5.5mm"}}>
         <div className="cell HYheadM18 colspan7">
         </div>
         <div className="cell HYheadM18 colspan7">
         </div>
     </div>  */}
											<div className="row" style={{ height: '11%' }}>
												<div className="cell HYheadM18 colspan7" style={{ overflow: 'visible', paddingLeft: '36px', fontSize: '14pt' }}>
													G/W: {data.TMP35}kg
												</div>
												<div className="cell colspan2"></div>
												<div className="cell HYheadM18 colspan7" style={{ overflow: 'visible', paddingLeft: '15px', fontSize: '14pt' }}>
													N/W: {data.TMP20}kg
												</div>
											</div>
											{/* <div className="row" style={{height:"5mm"}}>
         <div className="cell HYheadM18 colspan9" style={{fontSize:"12pt"}}>
             { (data.TMP35!=="") && (Common.getTextLength(data.TMP35) > 7) ? data.TMP35.substr(11) : "" }
         </div>
         <div className="cell colspan5"></div>
     </div>  */}
											<div className="row" style={{ height: '13%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan5" style={{ zIndex: '15', marginLeft: '10px' }}>
													{sData.barcode}
												</div>
												{/* BARCODE DataMatrix - code128 바코드 표출  */}

												{/* <div className="cell"></div>
         <div className="cell"></div>
         <div className="cell"></div>
         <div className="cell"></div> */}
												{/* <div className="cell"></div> */}

												<div className="cell"></div>
											</div>
											<div className="row" style={{ height: '6.5%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												{/* <div className="cell"></div> */}

												<div className="cell HYheadM12 colspan2" style={{ fontSize: '11pt', paddingTop: '1px', paddingLeft: '1mm' }}>
													{chkNpdt && chkNpdt === 'Y' ? data.TMP9 : ''}
												</div>
												{/* 납품일자 chkNpdt data.TMP9=납기일자(원복) 2022-06-27 */}
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell HYheadM12" style={{ fontSize: '11pt', paddingTop: '1px' }}>
													{data.TMP12}
												</div>
												{/* 업체코드 */}
												<div className="cell HYheadM12 colspan2" style={{ fontSize: '11pt', paddingTop: '1px' }}>
													{data.TMP10}
												</div>
												{/* 업체명 */}
												{/* <div className="cell"></div> */}
												<div className="cell"></div>

												<div className="cell"></div>
											</div>
											<div className="row" style={{ height: '7.5%' }}>
												<div className="cell HYheadM12" style={{ fontSize: '10pt', verticalAlign: 'middle', fontWeight: 'normal', paddingTop: '1mm', paddingLeft: '4mm' }}>
													{kCkdOem === 'CKD' ? 'Order' : kCkdOem === 'CKD2' ? 'From' : ''}
													{/* , paddingTop:"2mm" */}
												</div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan3" style={{ fontSize: '11pt', verticalAlign: 'middle', paddingTop: '1mm', paddingLeft: '2mm' }}>
													{data.TMP22}
												</div>
												{/* 공정NO  , paddingTop:"2mm"*/}
												{/* <div className="cell"></div> */}
												{/* <div className="cell"></div> */}
												<div className="cell HYheadM12"></div>
												<div className="cell" style={{ fontFamily: 'HY견고딕', fontSize: '30pt', fontWeight: 'bold' }}>
													{data.TMP15}
													{/* TMP15 */}
												</div>{' '}
												{/* data.TMP15 조립번호 - 이중인쇄 처럼 추가표출이지만 표안에 넣어보기  */}
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan4">{/*data.TMP21*/}</div>
												{/* 발주번호(공백) */}
												<div className="cell" style={{ fontSize: '20pt', overflow: 'visible', paddingTop: '1mm', zIndex: '15' }}>
													{sData.barcodeMatrix}
												</div>
												{/* 중포장 2D 바코드 */} {/* BARCODE DataMatrix - DataMatrix 추가 표출  */}
											</div>
											<div className="row" style={{ height: '8%' }}>
												<div className="cell HYheadM12" style={{ fontSize: '10pt', fontWeight: 'normal', paddingBottom: '1mm', paddingLeft: '5mm' }}>
													{kCkdOem === 'CKD' ? '' : kCkdOem === 'CKD2' ? 'To' : ''}
												</div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12" style={{ fontSize: '11pt', paddingTop: '1mm', paddingLeft: '2mm' }}>
													{data.TMP23}
												</div>
												{/* order   , paddingTop:"1mm"*/}

												<div className="cell"></div>
												<div className="cell HYheadM12" style={{ textAlign: 'right', paddingLeft: '6mm', paddingTop: '1mm' }}>
													{chkLtno && chkLtno === 'Y' ? sData.lotno : ''}
												</div>
												{/* getLOT() chkLtno  */}
												<div className="cell"></div>

												<div className="cell"></div>
												<div className="cell colspan2"></div>

												{/* <div className="cell"></div> */}
											</div>
											<div className="row" style={{ height: '7%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM9 colspan6" style={{ fontSize: '6pt', fontWeight: 'bold', paddingLeft: '1mm', paddingTop: '0.5mm' }}>
													{/*,paddingTop:"1.5mm"*/}
													{sData.bno}
												</div>
												{/* bno */}
												{/* <div className="cell"></div> */}

												{/* <div className="cell"></div>
         <div className="cell"></div>
         <div className="cell"></div> */}
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan3" style={{ fontSize: '14pt', paddingLeft: '6mm' }}>
													{data.TMP17}
												</div>
												{/* 용기 Mo */}
												{/*, paddingTop:"1mm"*/}

												{/* <div className="cell"></div> */}
											</div>
										</div>

										{/* 중금속 확인필 */}
										{chkMetal === 'Y' ? (
											// <div style={{position:"absolute",  top:"19mm",left:"123mm",zIndex:"12"}} >
											// <div style={{position:"relative",  top:"-55mm",left:"123mm",zIndex:"12"}} >
											<div style={{ ...sData.divMetal }}>
												<img src="/images/heavy_metal_confirm.gif" style={{ width: '13mm' }} alt="heavy_metal_confirm" />
											</div>
										) : (
											''
										)}

										{/* RHD 추가표출 data.TMP36 , fontSize:"25pt" RHD  >>> 표안에 입력으로 수정   */}
										{/* {(data.TMP36!=="" && data.TMP36==="Y")?
                            <div style={{position:"absolute", fontFamily: "HY견고딕", top:"110px", left:"177px", fontSize:"25pt", fontWeight:"bold",zIndex:"15"}}>RHD</div>
                        :""} */}

										{/* 조립코드 추가표출 data.TMP15 , fontSize:"30pt" BOM  >>> 표안에 입력으로 수정   */}
										{/* {sData.TMP15!==""?
                            //<div style={{position:"relative", fontFamily: "HY견고딕", top:"-120px", left:"290px", fontSize:"30pt", fontWeight:"bold",zIndex:"15"}}>{data.TMP15}</div>
                            <div style={{...sData.divTmp15}}>{data.TMP15}</div>
                        :""} */}

										{/* 이중출력부분 */}
										{/* XXXXXX */}
										{sData.dualPrint1 === 'T' ? (
											// <div style={{position:"absolute", fontFamily: "HY견고딕", top:{...sData.TMP27}+"px", left: {...sData.TMP26}+"px",zIndex:"10", color:{...sData.color1}, fontSize:{...sData.tmp29===""?"0":sData.TMP29+"pt"} }}>{sData.TMP25}{sData.TMP27}{sData.color1}</div>
											//<div style={{...sData.dualPrint1css,position:"relative",width:"500px", height:"57px"}}>{data.TMP25}</div>
											<div style={{ ...sData.dualPrint1css }}>{data.TMP25}</div>
										) : (
											''
										)}
										{sData.dualPrint2 === 'T' ? (
											// <div style={{position:"absolute", fontFamily: "HY견고딕", top:{...sData.TMP32}+"px", left: {...sData.TMP31}+"px",zIndex:"10", color:{...sData.color2}, fontSize:{...sData.tmp34===""?"0":sData.TMP34+"pt"} }}>{sData.TMP30}{sData.TMP32}{sData.color2}</div>
											//<div style={sData.dualPrint2css}>{data.TMP30}</div>
											//<div style={{...sData.dualPrint2css,position:"relative"}}>{data.TMP30}</div>
											<div style={{ ...sData.dualPrint2css }}>{data.TMP30}</div>
										) : (
											''
										)}
										{/* XXXXXX */}

										{/* 이중출력부분 - 품명 이중출력 위에 추가표출  */}
										{/* 품명 data.TMP6 */}
										{sData.TMP6 !== ''
											? '' // <div style={{position:"absolute", fontFamily: "HY견고딕", top:"80px", left:"66px", fontSize:"22pt", fontWeight:"bold", whiteSpace:"pre",zIndex:"15"}}>{data.TMP6.substr(0, 19)}</div>
											: ''}

										{/* BARCODE DataMatrix - code128 바코드 추가 표출  */}
										{/* 바코드 sData.barcode */}
										{/* {sData.TMP6!==""?                        
                            <div style={{position:"absolute", fontFamily: "HY견고딕", top:"132px", left:"62px", fontSize:"16pt", fontWeight:"bold", whiteSpace:"pre",zIndex:"15"}}>{sData.barcode}</div>
                        :""} */}

										{/* BARCODE DataMatrix - DataMatrix 추가 표출  */}
										{/* 바코드 sData.barcode */}
										{/* {(sData.TMP6!=="") && (this.state.pathNm !== null)?                        
                            <div style={{position:"absolute", fontFamily: "HY견고딕", top:"200px", left:"122mm", fontSize:"20pt", fontWeight:"bold", whiteSpace:"pre",zIndex:"15"}}>{sData.barcodeMatrix}</div>
                        :""} */}

										{/* 업체명 업체코드 추가 표출  */}
										{/* 업체명 업체코드 data.TMP12+" "+data.TMP10 */}
										{/* {sData.TMP6!==""? 
                            <div style={{position:"absolute", fontFamily: "HY견고딕", top:"186px", left:"249px", fontSize:"11pt", fontWeight:"bold", whiteSpace:"pre",zIndex:"15"}}>{data.TMP12+"  "+data.TMP10}</div>
                        :""} */}

										{/* </div> */}

										{sData.posi === 5 && sData.endpage === 'F' ? (
											// (출력매수-1)
											<div key={'pagebreak' + sData.idx} style={{ background: 'red', breakAfter: 'page', pageBreakAfter: 'always', pageBreakInside: 'avoid' }}></div>
										) : (
											''
										)}
									</form>
								),
								'',
							)}
						</>
					);

					// 최초 1 close - 품번 별 row  -- END
			  })
			: '';

		return <PrintStyleB key={'WrapCkdLabelPrintPercentB'}>{cardInfo}</PrintStyleB>;
	}
}
export default WrapCkdLabelPrintPercentB;
