/**
 * @Page ROM78
 * @Description [ROM78]납입지시 - 재고관리 - 협력사재고입력/전송
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; // Common.js
 import CommonUtil from '@/utils/CommonUtil'; // Util.js
 
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();
 
 function ROM78() {
 
     const token = Common.getToken();
     const userId = Common.getToken("id");
     // const userName = Common.getToken("name");
     const tVnt1 = Common.getToken("vand");
     const tComp = Common.getToken("comp");
     const bigi = Common.getToken("bigi");
     const grdRom78Main = useRef();   //Grid Main
     const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
 
 
     //Data
     const [idata, setData] = useState({
         //조회조건
         // kVnt1 : tVnt1,      //T1업체
         kVnt1 : ckVnt1,        //T1업체     
         kPldt: Common.getToday(),  //전송일자
         kComp: tComp,       //회사구분
         kPlnt: "",          //공장
         kPtno : "",         //품번
         kInv0 : "",         //전송차수
 
 
         //코드 데이터
         vnt1List : [],  //T1업체
         plntList : [],  //공장
         compList : [],  //회사
         inv0List : [],  //전송차수
     });
 
 
      
     const [message, setMessage] = React.useState( {
         openAlert : false,      
         severity : "success",   
         msg : ""                
     });
     const { openAlert, severity, msg } = message;
 
 
     //Dialog
     const [dlgMessage, setDlgMessage] = React.useState( {
         openDlg : false,    
         dlgTrans : "",      // Dialog Transaction(I,U,D)
         dlgMsg : ""         
     });
     const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
 
     //Grid Search, Insert, Update, Delete Api
     let [searchApi, setSearchApi] = useState(false); 
     let [insertApi, setInsertApi] = useState(false); 
 
     const [panded, setPanded] = React.useState(true);
     const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 100px  - 50px)"});

     const onClearApi = useCallback(async (e) => {
         setSearchApi(false);
         setInsertApi(false);
     }, []);

    
     /**
      * 공통코드 조회 (조회조건)
      */
     const onSearchCommon = (para, name) => {
         let commonData = idata;
 
         const fetchData = async (para, name) => {
             let param = encodeURI(para);
             const query = SERVER_URL + "/comcd/search?query=" + param; 
             await fetch(query, { headers: { Authorization: token } })
                 .then((resp) => resp.json())
                 .then((data) => {
                     if (data.success)
                     {
                         commonData[name] = data.data
                         setData({...commonData});
                     }
                 })
         }
         fetchData(para, name);
     }
 
     const onSearchCommon1 = (para, name) => {
         let commonData = idata;
 
         const fetchData1 = async (para, name) => {
 
             let param = encodeURIComponent(para);
             const query = SERVER_URL + "/comcd/search1?query=" + param; 
             await fetch(query, { headers: { Authorization: token } })
                 .then((resp) => resp.json())
                 .then((data) => {
                     if (data.success) 
                     {
                         commonData[name] = data.data
                         setData({...commonData});
                     }
                 })
         }
         fetchData1(para, name);
     }
 
 
 
     /**
      *MOUNT
      */
     useEffect( () => {
         //공통코드 조회 (T1업체)
         onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
         //공통코드 조회 (공장)
         onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":""}`, "plntList");
         //공통코드 조회 (회사)
         onSearchCommon(`{"pgmid":"COMP"}`, "compList");
         //공통코드 조회 (회사)
         onSearchCommon1(`{"pgmid":"CODE", "vand":"****", "gocd" : "INV0"}`, "inv0List");
     }, []);
 
 
 
     /**
      * Control 변경 Event
      * @param {*} e
      */
     const onChange = async (e) => {
         const {name, value, desc} = e.target;
 
         let newSearchs = idata;
         newSearchs[name] = value;
         newSearchs[name+"Nm"] = desc;
         setData({ ...newSearchs });
 
         // 회사 변경 시
         if (name === "kComp") {
             //newSearchs["gateList"] = [];
             newSearchs["kPlnt"] = "";
             newSearchs["kPlntNm"] = "";
             newSearchs["kGate"] = "";
             setData({ ...newSearchs });
 
             
 
             if(value == "EHMC") {
                 //공통코드 조회 (공장)
                 onSearchCommon1(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");
             }else if(value == "EKMC"){
                 onSearchCommon1(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");
             }
         }
     }
 
 
     /**
      * 검색조건
      */
     const optionsX = {
         0: [
             //T1업체
             {
                 formatter: "select", name: "kVnt1", labelName: "T1업체", type: "text", defaultValue: idata.kVnt1,  
                 codeMode: true,
                 selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: null,
                 comms: idata.vnt1List, 
                 align: "left", 
             },
             //회사구분
            {
                formatter: "select", name: "kComp", labelName: "회사구분", type: "text", defaultValue: idata.kComp,  
                codeMode: true,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: CODES["comp"],
                comms: null,
                align: "left", 
            },
            {"formatter": "blank", "align": "right" },
             {"formatter": "blank", "align": "right" },
             {"formatter": "blank", "align": "right" }
         ],
         1: [
            
             // 공장
             {
                 formatter: "select", name: "kPlnt", labelName: "공장", type: "text", defaultValue: idata.kPlnt,  
                 codeMode: false,
                 selectAll: "all", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: null,
                 comms: idata.plntList,
                 align: "left", 
             },
             // 품번
             {
                 formatter: "input", name: "kPtno", labelName: "품번", type: "text", defaultValue: idata.kPtno,  
                 align: "left", 
             },
             {"formatter": "blank", "align": "right" },
             {"formatter": "blank", "align": "right" },
             {"formatter": "blank", "align": "right" }
         ]
     }
 
 
 
     /**
      * 조회
      * @param {*} e
      * @returns
      */
     const onSearch = async (e) => {
        
         // Cookie 값 셋팅
         Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);
 
         let para = `{"kVnt1": "${idata.kVnt1}", "kPtno": "${idata.kPtno}", "kComp": "${idata.kComp}","kPlnt": "${idata.kPlnt}"}`;
 
         let param = encodeURI(para);
 
         const query = SERVER_URL + "/inven/send/select?query=" + param;
         setSearchApi(query); // Search API
     }

     /**
      * 조회2
      * @param {*} e
      * @returns
      */
      const onSearch2 = async (e) => {
        
        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);

        let para = `{"kVnt1": "${idata.kVnt1}", "kPtno": "${idata.kPtno}", "kComp": "${idata.kComp}","kPlnt": "${idata.kPlnt}"}`;

        let param = encodeURI(para);

        const query = SERVER_URL + "/inven/send/select2?query=" + param;
        setSearchApi(query); // Search API
    }
 
 
 
     /**
      * 신규 Row추가
      * @param {*} e
      */
     const onAddRow = async (e) => {
     }
 
 
 
     /**
      * 입력 (발행)
      * @param {*} e
      */
     const onInsert = async (e) => {
        //input check
        let input = await Promise.all ([inputCheck('I')]);
        if ( !input[0]) return;

        const query = SERVER_URL + "/inven/send/update";
        setInsertApi(query); // Insert API
     }
 
     // input check
     const inputCheck = (gbn) => { 

        let inputFlag = true;
        //select nodes
        let selectedData = grdRom78Main.current.getSelectedNodes();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "선택한 데이터가 없습니다."});
            return false;
        }
    
        //필수입력항목 Check
        selectedData.forEach((node) => {
            
            if (node.data.zcmodl == null || node.data.zcmodl == "" ) {
                //grdRom78Main.current.setData(0,"invenend") 

                setMessage({ openAlert : true, 
                    severity : "error",
                    msg : "협력사재고관리 > 대상품목입력 화면에서 차종을 입력해주세요."});

                grdRom78Main.current.SetFocus(node.rowIndex, "zcmodl");                             
                inputFlag = false
                return false;
                
            }
            if (node.data.invenend == null || node.data.invenend == "" ) {
                //grdRom78Main.current.setData(0,"invenend") 

                node.setDataValue('invenend', 0);    
            }
            if (node.data.invenendus == null || node.data.invenendus == "" ) {

                node.setDataValue('invenendus', 0);    
            }
            if (node.data.invenendsy == null || node.data.invenendsy == "" ) {
                
                node.setDataValue('invenendsy', 0);    
            }
        });
        
        return inputFlag;
    }
 
 
     /**
      * 수정
      * @param {*} e
      * @returns
      */
     const onUpdate = async (e) => {
     }
 
     /**
      * 삭제
      * @param {*} e
      */
     const onDelete = async (e) => {
     }


     /**
     * Grid Event 
     * @param {*} e 
     */
    const grdRom78Main_onCellValueChanged = async (e) => {        
        let result;

        if (e.column.colId === "invenend" || e.column.colId === "invenendus" || e.column.colId === "invenendsy") {
            if(e.data.total === "0" || e.data.total === ''){
                e.node.setDataValue('total', 0);
                //e.node.setSelected(false);
            }else{
                result = Math.ceil(parseInt(e.data.invenend) + parseInt(e.data.invenendus) + parseInt(e.data.invenendsy));
                e.node.setDataValue('total', result);
            }
            
        }


        
    }

    const grdRom78Main_onRowClicked = async (e) => {

    }
 
     /**
      * 엑셀
      * @param {*} e
      */
     const onExcel = async (e) => {
         //공장선택 Check
         if (idata.kPlnt == "")
         { 
             setMessage({ openAlert : true, 
                          severity : "info",
                          msg : "공장을 선택하십시요 !"});
             document.getElementById("kPlnt").focus();                         
             return ;
         }      
 
         // Cookie 값 셋팅
         Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);
         let para = `{
                         "vnt1": "${idata.kVnt1}", "gubn": "${idata.kGubn}", "gate": "${idata.kGate}",
                         "comp": "${idata.kComp}", "plnt": "${idata.kPlnt}", "ptno": "${idata.kPtno}",
                         "nidt_f": "${idata.kFidt_f.replaceAll('-','')}",
                         "nidt_t": "${idata.kFidt_t.replaceAll('-','')}",
                         "sort": "${idata.kSort}", "usyn": "${idata.kUsyn}", "info_vand": "${idata.kInfo_vand}"
                      }`;
         let param = encodeURI(para);
 
         const query = SERVER_URL + "/Rom78/excel?query=" + param;
         
         await fetch(query, {headers: {Authorization: token } })
             .then((resp) =>{
                 return resp.blob();
             }).then((blob)=>{
                 const href = window.URL.createObjectURL(blob);
                 const link = document.createElement('a');
                 link.href = href;
                 link.setAttribute('download', 'ROM78.xls');
                 document.body.appendChild(link);
                 link.click();
                 document.body.removeChild(link);
             }).catch((err) => {
                 return Promise.reject({ Error: 'error', err });
             })
     }

 
     //Transaction AlertDialogResult
     const AlertDialogResult = async (e) => {
 
         //AlertDialog 초기화
         setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
 
 
 
         //DialogResult
         if (e.result == "N") return;
 
         //Transaction 구분
         switch (e.Trans)
         {
             //등록
             case "I" :
                 break;
             //수정
             case "U" :
                 break;
             //삭제
             case "D2" :
                 break;
             case "D3" :
                 break;
         }
     }

     const editCellClicked = (params) => {
        // default Cell Click 이벤트 

        let gridApi = params.api;

        let rowIdx = params.rowIndex;

        let data = params.data;


        if ((data.npqt === "0" || data.npqt === "")) {

            gridApi.stopEditing(true); // Grid EditMode stop

            var rowNode = gridApi.getRowNode(rowIdx);
            var rowNode2 = gridApi.getDisplayedRowAtIndex(rowIdx); //정렬, 필터링... 하면 변경된 index가져옴

            rowNode2.setDataValue("total", data.total); // 발주수량 값 받아와서 납품수량npqt 셋팅 

            gridApi.startEditingCell({
                rowIndex: rowIdx,
                colKey: 'npqt' // 납품수량npqt edit Mode
            }); // Grid EditMode start
        }

    };

 
 
     /**
      *Grid Column 정의
      */
         //Main Grid Column
     const mainColumnDefs = [
        {
            headerName: '',
            children: [
                { headerName: "회사(h)", field: "comp", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },
                { headerName: "공장", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
                { headerName: "품명", field: "ptnm", sortable: true, filter: true, flex: 1, minWidth: 180, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                { headerName: "차종", field: "zcmodl", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            ]
        },            
            {
                headerName: '재고량',
                children: [
                    { headerName: "자사", field: "invenend", sortable: true, filter: true, flex: 1, minWidth: 200,onCellClicked: editCellClicked, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor",singleClickEdit: true,valueFormatter: Utils.numberFormatter, hide: false },
                    { headerName: "운송중", field: "invenendus", sortable: true, filter: true, flex: 1, minWidth: 200,onCellClicked: editCellClicked, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: true,valueFormatter: Utils.numberFormatter, hide: false },
                    { headerName: "서열장/사외창고", field: "invenendsy", sortable: true, filter: true, flex: 1, minWidth: 200,onCellClicked: editCellClicked, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignR"],cellEditor:"numericEditor", singleClickEdit: true,valueFormatter: Utils.numberFormatter, hide: false },
                    { headerName: "합계", field: "total", sortable: true, filter: true, flex: 1, minWidth: 200, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],cellEditor:"numericEditor", singleClickEdit: true,valueFormatter: Utils.numberFormatter, hide: false },
                ]
            },
            {
                headerName: '',
                children: [
                    { headerName: "적정재고량", field: "ctlt", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true,valueFormatter: Utils.numberFormatter, hide: false },
                ]
            },
 
         ];
 
        const onClick = async (e) => {
            setPanded(!panded);
    
            if(panded===true){
                setStyle({       
                    height :"calc(100vh - 60px - 50px - 50px)"
                    });
            }else{
                setStyle({
                    height :"calc(100vh - 60px - 50px - 100px  - 50px)"
                    });
            }
        }
     return(
         <>
             {/* Dialog FRAME */}
             <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />
 
             {/* Alert FRAME */}
             <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg}
                          onClose={()=> {setMessage({...message, openAlert: false});}}
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} />
 
             {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
             <Ribbon
                 onSearch={onSearch} onSearchName={null}     //조회
                 onInsert={onInsert} onInsertName={"등록"}     //등록
                 onSearch2={onSearch2} onSearchName2={"초기화"}     //초기화
             >
                 {"납입지시 > 재고관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>협력사재고 입력/전송</b>
             </Ribbon>
 
 
             {/* ContentXearch Frame : 검색조건 */}
             <ContentXearch
                 optionsX={optionsX}
                 onChange={onChange}
                 onClick={onClick}/>
 
 
             {/* Content FRAME (Data / Grid Frame) START -------------- */}
             <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:100px */}
                 <div className={"grid_type01"}> {/* 상단 FRAME */}
                     <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                         <Grid
                             ref={grdRom78Main}
                             gridId={"ROM78"}                    //  (필수) Grid Id 정의
                             columnDefs={mainColumnDefs}             //  (필수) 컬럼 정의
                             pagination={true}                  //  쪽수 매기기 여부
                             paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                             paginationPageSize={"500"}         //  쪽수 1Page 당 Row수
                             rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                             suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                             rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                             enableRangeSelection={false}        //
                             enableCellTextSelection={true}      // cell selection drag mode
                             isFirst={true}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                             displayNo = {false}
                             searchApi={searchApi}               // 조회
                             insertApi={insertApi}               // 등록
                             onClearApi={onClearApi}             // 초기화
                             onCellValueChangedEvent={grdRom78Main_onCellValueChanged}
                             onRowClickEvent={grdRom78Main_onRowClicked}
                         />
                     </div>
                 </div>
             </Content>
         </>
     )
 }
 export default ROM78;
 