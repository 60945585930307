/**
 * @Page ROM79
 * @Description [ROM79]납입지시 - 협력사재고관리 - 협력사재고전송현황
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';///
 import * as Common from '@/utils/Common'; // Common.js
 import CommonUtil from '@/utils/CommonUtil'; // Util.js
 
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();
 
 function ROM79() {
 
     const token = Common.getToken();
     const userId = Common.getToken("id");
     // const userName = Common.getToken("name");
     const tVnt1 = Common.getToken("vand");
     const tComp = Common.getToken("comp");
     const bigi = Common.getToken("bigi");
     const grdRom79Main = useRef();   //Grid Main
     const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
 
 
     //Data
     const [idata, setData] = useState({
         //조회조건
         // kVnt1 : tVnt1,      //T1업체
         kVnt1 : ckVnt1,        //T1업체     
         kPldt: Common.getToday(),  //전송일자
         kComp: tComp,       //회사구분
         kPlnt: "",          //공장
         kPtno : "",         //품번
         kInv0 : "1",         //전송차수
 
 
         //코드 데이터
         vnt1List : [],  //T1업체
         plntList : [],  //공장
         compList : [],  //회사
         inv0List : [],  //전송차수
     });
 
 
      
     const [message, setMessage] = React.useState( {
         openAlert : false,      
         severity : "success",   
         msg : ""                
     });
     const { openAlert, severity, msg } = message;
 
 
     //Dialog
     const [dlgMessage, setDlgMessage] = React.useState( {
         openDlg : false,    
         dlgTrans : "",      // Dialog Transaction(I,U,D)
         dlgMsg : ""         
     });
     const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
 
     //Grid Search, Insert, Update, Delete Api
     let [searchApi, setSearchApi] = useState(false); 
    
     const [panded, setPanded] = React.useState(true);
     const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 100px  - 50px)"});

     const onClearApi = useCallback(async (e) => {
         setSearchApi(false);
     }, []);

  
 
     /**
      * 공통코드 조회 (조회조건)
      */
     const onSearchCommon = (para, name) => {
         let commonData = idata;
 
         const fetchData = async (para, name) => {
             let param = encodeURI(para);
             const query = SERVER_URL + "/comcd/search?query=" + param; 
             await fetch(query, { headers: { Authorization: token } })
                 .then((resp) => resp.json())
                 .then((data) => {
                     if (data.success)
                     {
                         commonData[name] = data.data
                         setData({...commonData});
                     }
                 })
         }
         fetchData(para, name);
     }
 
     const onSearchCommon1 = (para, name) => {
         let commonData = idata;
 
         const fetchData1 = async (para, name) => {
 
             let param = encodeURIComponent(para);
             const query = SERVER_URL + "/comcd/search1?query=" + param; 
             await fetch(query, { headers: { Authorization: token } })
                 .then((resp) => resp.json())
                 .then((data) => {
                     if (data.success) 
                     {
                         if (name === "inv0List") {
                             //commonData["kType"] = data.data[0].comCode
                             //commonData["kTypeNm"] = data.data[0].text
                         }
                         commonData[name] = data.data
                         setData({...commonData});
                     }
                 })
         }
         fetchData1(para, name);
     }
 
 
 
     /**
      *MOUNT
      */
     useEffect( () => {
         //공통코드 조회 (T1업체)
         onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
         //공통코드 조회 (공장)
         onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":""}`, "plntList");
         //공통코드 조회 (회사)
         onSearchCommon(`{"pgmid":"COMP"}`, "compList");
         //공통코드 조회 (회사)
         onSearchCommon1(`{"pgmid":"CODE", "vand":"****", "gocd" : "INV0"}`, "inv0List");
     }, []);
 
 
 
     /**
      * Control 변경 Event
      * @param {*} e
      */
     const onChange = async (e) => {
         const {name, value, desc} = e.target;
 
         let newSearchs = idata;
         newSearchs[name] = value;
         newSearchs[name+"Nm"] = desc;
         setData({ ...newSearchs });
 
         // 회사 변경 시
         if (name === "kComp") {
             //newSearchs["gateList"] = [];
             newSearchs["kPlnt"] = "";
             newSearchs["kPlntNm"] = "";
             newSearchs["kGate"] = "";
             setData({ ...newSearchs });
 
             
 
             if(value == "EHMC") {
                 //공통코드 조회 (공장)
                 onSearchCommon1(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");
             }else if(value == "EKMC"){
                 onSearchCommon1(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");
             }
         }
     }
 
 
     /**
      * 검색조건
      */
     const optionsX = {
         0: [
             //T1업체
             {
                 formatter: "select", name: "kVnt1", labelName: "T1업체", type: "text", defaultValue: idata.kVnt1,  
                 codeMode: true,
                 selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: null,
                 comms: idata.vnt1List, 
                 align: "left", 
             },
             //전송일자
            {
                formatter: "input", name: "kPldt", labelName: "전송일자", type: "date", defaultValue: idata.kPldt,  
                align: "left", 
            },
            //전송차수
            {
                formatter: "select", name: "kInv0", labelName: "전송차수", type: "text", defaultValue: idata.kInv0,  
                codeMode: false,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: idata.inv0List,
                align: "left", 
            },
            {"formatter": "blank", "align": "right" },
             {"formatter": "blank", "align": "right" },
             {"formatter": "blank", "align": "right" }
         ],
         1: [
            
            //회사구분
            {
                formatter: "select", name: "kComp", labelName: "회사구분", type: "text", defaultValue: idata.kComp,  
                codeMode: true,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: CODES["comp"],
                comms: null,
                align: "left", 
            },

             // 공장
             {
                 formatter: "select", name: "kPlnt", labelName: "공장", type: "text", defaultValue: idata.kPlnt,  
                 codeMode: false,
                 selectAll: "all", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: null,
                 comms: idata.plntList,
                 align: "left", 
             },
             // 품번
             {
                 formatter: "input", name: "kPtno", labelName: "품번", type: "text", defaultValue: idata.kPtno,  
                 align: "left", 
             },
             {"formatter": "blank", "align": "right" },
             {"formatter": "blank", "align": "right" },
             {"formatter": "blank", "align": "right" }
         ]
     }
 
 
 
     /**
      * 조회
      * @param {*} e
      * @returns
      */
     const onSearch = async (e) => {
        
         // Cookie 값 셋팅
         Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);
 
         let para = `{"kVnt1": "${idata.kVnt1}", "kInv0": "${idata.kInv0}", 
                      "kPtno": "${idata.kPtno}", "kComp": "${idata.kComp}",
                      "kPlnt": "${idata.kPlnt}","kPldt": "${idata.kPldt}"}`;
 
         let param = encodeURI(para);
 
         const query = SERVER_URL + "/inven/all/select?query=" + param;
         setSearchApi(query); // Search API
     }
 
 
 
     /**
      * 신규 Row추가
      * @param {*} e
      */
     const onAddRow = async (e) => {
     }
 
 
 
     /**
      * 입력 (발행)
      * @param {*} e
      */
     const onInsert = async (e) => {
    }
 
 
     /**
      * 수정
      * @param {*} e
      * @returns
      */
     const onUpdate = async (e) => {
     }
 
     /**
      * 삭제
      * @param {*} e
      */
     const onDelete = async (e) => {
     }
 
     /**
      * 엑셀
      * @param {*} e
      */
     const onExcel = async (e) => {
        let para = `{"kVnt1": "${idata.kVnt1}", "kInv0": "${idata.kInv0}", 
                      "kPtno": "${idata.kPtno}", "kComp": "${idata.kComp}",
                      "kPlnt": "${idata.kPlnt}","kPldt": "${idata.kPldt}"}`;
        let param = encodeURI(para);
 
        const query = SERVER_URL + "/inven/excel?query=" + param;
         
        await fetch(query, {headers: {Authorization: token } })
             .then((resp) =>{
                 return resp.blob();
             }).then((blob)=>{
                 const href = window.URL.createObjectURL(blob);
                 const link = document.createElement('a');
                 link.href = href;
                 link.setAttribute('download', 'inven현황.xls');
                 document.body.appendChild(link);
                 link.click();
                 document.body.removeChild(link);
             }).catch((err) => {
                 return Promise.reject({ Error: 'error', err });
             })
     }

 
     //Transaction AlertDialogResult
     const AlertDialogResult = async (e) => {
 
         //AlertDialog 초기화
         setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
 
 
 
         //DialogResult
         if (e.result == "N") return;
 
         //Transaction 구분
         switch (e.Trans)
         {
             //등록
             case "I" :
                 break;
             //수정
             case "U" :
                 break;
             //삭제
             case "D2" :
                 break;
             case "D3" :
                 break;
         }
     }

 

 
 
     /**
      *Grid Column 정의
      */
         //Main Grid Column
     const mainColumnDefs = [
        { headerName: "기준일자", field: "refdate", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, valueFormatter : Utils.dateFormatter },
        { headerName: "전송차수", field: "zsifno", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "법인구분", field: "comcd", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "공장", field: "werks", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "차종/기종", field: "zcmodl", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "업체코드", field: "vendcd", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "END 품번_현대기아", field: "endpno", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
        { headerName: "END 품명_현대기아", field: "endpname", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
        { headerName: "END 품번_협력사자체", field: "endpnov", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false },
        { headerName: "END 품명_협력사자체", field: "endpnamev", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false },
        { headerName: "END 사양 구분", field: "endpspec", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "END USAGE 수량", field: "endus", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "END 소싱구분", field: "endscgb", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "END 근거리/원거리", field: "enddt", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "END [대물/서열] [일반] 구분]", field: "endtyp", sortable: true, filter: true, flex: 1, minWidth: 225, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "END 서열(직서열/통합서열/비서열)", field: "endrankgb", sortable: true, filter: true, flex: 1, minWidth: 250, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "END 재고수량 (협력사 內)", field: "invenend", sortable: true, filter: true, flex: 1, minWidth: 190, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "END 재고수량 (운송중)", field: "invenendus", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "END 재고수량 (서열장/사외창고)", field: "invenendsy", sortable: true, filter: true, flex: 1, minWidth: 240, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+0 공급계획 (수량)", field: "ENDZQDAYD0", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+1 공급계획 (수량)", field: "ENDZQDAYD1", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+2 공급계획 (수량)", field: "ENDZQDAYD2", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+3 공급계획 (수량)", field: "ENDZQDAYD3", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+4 공급계획 (수량)", field: "ENDZQDAYD4", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+5 공급계획 (수량)", field: "ENDZQDAYD5", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+6 공급계획 (수량)", field: "ENDZQDAYD6", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+7 공급계획 (수량)", field: "ENDZQDAYD7", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+8 공급계획 (수량)", field: "ENDZQDAYD8", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+9 공급계획 (수량)", field: "ENDZQDAYD9", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+10 공급계획 (수량)", field: "ENDZQDAYD10", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+11 공급계획 (수량)", field: "ENDZQDAYD11", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+12 공급계획 (수량)", field: "ENDZQDAYD12", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+13 공급계획 (수량)", field: "ENDZQDAYD13", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+14 공급계획 (수량)", field: "ENDZQDAYD14", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+15 공급계획 (수량)", field: "ENDZQDAYD15", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+16 공급계획 (수량)", field: "ENDZQDAYD16", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+17 공급계획 (수량)", field: "ENDZQDAYD17", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+18 공급계획 (수량)", field: "ENDZQDAYD18", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+19 공급계획 (수량)", field: "ENDZQDAYD19", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+20 공급계획 (수량)", field: "ENDZQDAYD20", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+21 공급계획 (수량)", field: "ENDZQDAYD21", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+22 공급계획 (수량)", field: "ENDZQDAYD22", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+23 공급계획 (수량)", field: "ENDZQDAYD23", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+24 공급계획 (수량)", field: "ENDZQDAYD24", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+25 공급계획 (수량)", field: "ENDZQDAYD25", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+26 공급계획 (수량)", field: "ENDZQDAYD26", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+27 공급계획 (수량)", field: "ENDZQDAYD27", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+28 공급계획 (수량)", field: "ENDZQDAYD28", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+29 공급계획 (수량)", field: "ENDZQDAYD29", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "D+30 공급계획 (수량)", field: "ENDZQDAYD30", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "SUB 품번_현대기아", field: "subpno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false },
        { headerName: "SUB 품명_현대기아", field: "subpname", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false },
        { headerName: "SUB 사양 구분", field: "subpspec", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "SUB USAGE 수량", field: "subus", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "SUB 소싱구분", field: "subscgb", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "SUB 대물/일반", field: "subtyp", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "SUB 업체명", field: "subvendnm", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false },
        { headerName: "SUB 업체 사업자등록번호", field: "subregistnum", sortable: true, filter: true, flex: 1, minWidth: 185, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "SUB 업체코드", field: "subvendcd", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "SUB 업체국가", field: "subvendco", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false },
        { headerName: "SUB 업체주소", field: "subvendaddr", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false },
        { headerName: "SUB 재고수량(1차사 內)", field: "invensub", sortable: true, filter: true, flex: 1, minWidth: 180, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "SUB 재고수량(운송중)", field: "invensubus", sortable: true, filter: true, flex: 1, minWidth: 175, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "SUB 재고수량(공정투입대기재고)", field: "invensubpsb", sortable: true, filter: true, flex: 1, minWidth: 240, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "SUB 재고수량(공정內재고)", field: "invensubpin", sortable: true, filter: true, flex: 1, minWidth: 190, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"],singleClickEdit: true, hide: false },
        { headerName: "협력사(1차사)시스템 담당자명/직급", field: "endchnm", sortable: true, filter: true, flex: 1, minWidth: 260, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "협력사(1차사)시스템 담당자연락처", field: "endchtel", sortable: true, filter: true, flex: 1, minWidth: 260, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "생산계획 TEMP 1", field: "TEMP1", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "생산계획 TEMP 2", field: "TEMP2", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "생산계획 TEMP 3", field: "TEMP3", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "생산계획 TEMP 4", field: "TEMP4", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "생산계획 TEMP 5", field: "TEMP5", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "생산계획 TEMP 6", field: "TEMP6", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "입력일자", field: "CREATEDATE", sortable: true, filter: true, flex: 1, minWidth: 165, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "STATUS", field: "STATUS", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false },
        { headerName: "수정일자", field: "UPDATEDATE", sortable: true, filter: true, flex: 1, minWidth: 165, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false }
    ];
 
    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
                });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 100px - 50px)"
                });
        }
    }

    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg}
                        onClose={
                            ()=> {
                                setMessage({...message, openAlert: false});
                            }
                        }
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} />

            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onSearch={onSearch} onSearchName={null}     //조회
                onExcel={onExcel} onExcelName ={null}   //Excel
            >
                {"납입지시 > 협력사재고관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>협력사재고전송현황</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick={onClick} />


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:100px */}
                <div className={"grid_type01"}> {/* 상단 FRAME */}
                    <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                        <Grid
                            ref={grdRom79Main}
                            gridId={"ROM79"}                    //  (필수) Grid Id 정의
                            columnDefs={mainColumnDefs}             //  (필수) 컬럼 정의
                            pagination={true}                  //  쪽수 매기기 여부
                            paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                            paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                            rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                            suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                            rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                            enableRangeSelection={false}        //
                            enableCellTextSelection={true}      // cell selection drag mode
                            isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                            displayNo = {false}
                            searchApi={searchApi}               // 조회
                            onClearApi={onClearApi}             // 초기화
                            onRowClickEvent={null}
                        />
                    </div>
                </div>
            </Content>
        </>
     )
 }
 export default ROM79;
 