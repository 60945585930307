import React from 'react';
// import { Link, withRouter } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import '../../css/main.css';
import '@/css/main.css';
// import sty from '../css/main.css';


function Layout({ children }) {
    // if (location.pathname === '/login') {
    //     return null;
    // }

    return (
        <>
            <div id="Layout" className={"Layout"}>
                {children}
            </div>
        </>
    );
}
// export default withRouter(Header);
export default Layout;