/**
 * @Page RSY11
 * @Description [RSY11]시스템 관리 : 사용자관리
 **/
import React, { useState, useEffect, useRef, useCallback  } from "react";
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import * as GridUtil from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
import CommonUtil from '@/utils/CommonUtil'; 
 
 
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const useYnList = [{ "comCode" : "*",  "text": "전체" }, 
            { "comCode" : "Y",  "text": "사용" }, 
            { "comCode" : "N",  "text": "미사용" }, ];  //조회조건 사용구분 
const Utils = new CommonUtil();
 


function RSY11() {

    const token = Common.getToken();
    const userId = Common.getToken("id");
    const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const grdRsy11Main = useRef();   //Grid Main


    //Data
    const [idata, setData] = useState({
        //조회조건
        kWrcUser : "",    //사용자ID
        kWrcName : "",    //사용자명
        kWrcVand : (tVnt1 === "M000" ? "" : tVnt1),    //업체코드
        kWrcVandReadOnly : (tVnt1 !== "M000"),   //업체코드 로그인시 입력 불가능
        kUseYn : "*",       //사용구분
    }); 

           
    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;
 
 
    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
 
  
    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    let [insertApi, setInsertApi] = useState(false); 
    let [updateApi, setUpdateApi] = useState(false); 
    let [deleteApi, setDeleteApi] = useState(false); // deleteApi 항목 setting
    let [loading, setLoading] = useState(false); 
    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 50px)"});

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
        setInsertApi(false);
        setUpdateApi(false);
        setDeleteApi(false);
    }, []);
     
    
    
    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para) => {
        
    }
    
    

    /**
     *MOUNT 
    */
    useEffect( () => {
        
        //공통코드 조회
        //onSearchCommon();        

         //조회
         setTimeout(() => onSearch(), 300);

    }, []);
 
 
 
    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value } = e.target;

        let newSearchs = idata;
        newSearchs[name] = value

        setData({ ...newSearchs });

        onSearch(); //조회 Call
    }

 
 
    /**
     * Grid Event 
     * @param {*} e 
     */
    const onCellValueChanged = async (e) => {
    }

    const onRowClicked = async (e) => {
    }
 
 
 
     /**
      * 검색조건
      */
    const optionsX = {
        0: [
            //사용자ID
            {
                "formatter": "input", "name": "kWrcUser", "labelName": "사용자ID", "type": "text", "desc": ""
                , "defaultValue": idata.kWrcUser  
                , "align": "left" 
                , "labelType": "none" 
            }, 
            //사용자명
            {
                "formatter": "input", "name": "kWrcName", "labelName": "사용자명", "type": "text", "desc": ""
                , "defaultValue": idata.kWrcName  
                , "align": "left" 
                , "labelType": "none" 
            }, 
            //업체코드
            {
                "formatter": "input", "name": "kWrcVand", "labelName": "업체코드", "type": "text", "desc": "", "readOnly" : idata.kWrcVandReadOnly
                , "defaultValue": idata.kWrcVand  
                , "align": "left" 
                , "labelType": "none" 
            }, 
            //사용구분
            {
                "formatter": "select", "name": "kUseYn", "labelName": "사용구분", "type": "text", "defaultValue": idata.kUseYn,  
                "codeMode": false,
                "selectAll": "",
                "codes": useYnList,
                "comms": null,
                // , "items": [{ "comCode": "Y", "text": "사용" }, { "comCode": "N", "text": "미사용" }] // Case 3 - items :: Hard Coding List
            }, 
            {"formatter": "blank", "align": "right"},
            {"formatter": "blank", "align": "right"}
        ]
    }
 
 
 
    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {
        let para = `{"kWrcUser":"${idata.kWrcUser}","kWrcName":"${idata.kWrcName}","kWrcVand":"${idata.kWrcVand}", "kUseYn":"${idata.kUseYn}"}`;
        let param = encodeURI(para);
        const query = SERVER_URL + "/rsy11/search?query=" + param;
        setSearchApi(query); // Search API
    }
 
 
 
    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
        //let newItems = {  vand: idata.kWrcVand, gocd : idata.kWrcGocd};
        // //let newItems = [{cod1: 'A'}, {cod1: 'B'}];

        let newItems = {roll01 : "Y", roll02 : "Y", roll03 : "Y",roll04: "N",roll05: "Y"};
        grdRsy11Main.current.addRow(newItems, 0);
    }
 
 
     
    /**
     * 입력
     * @param {*} e 
     */
    const onInsert = async (e) => {

        //input check
        let input = await Promise.all ([inputCheck('I')]);
        if ( !input[0]) return;

        const query = SERVER_URL + "/rsy11/insert";
        setInsertApi(query); // Insert API
    }
 
 
 
    /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {

        //input check
        let input = await Promise.all ([inputCheck('U')]);
        if ( !input[0]) return;
        const query = SERVER_URL + "/rsy11/update";
        setUpdateApi(query); // Update API
    }
 
 
 
    /**
     * 삭제
     * @param {*} e 
     */
    const onDelete = async (e) => {
        
        //input check
        let input = await Promise.all ([inputCheck('D')]);
        if ( !input[0]) return;

        //삭제 확인 메세지 
        setDlgMessage({openDlg : true,  
                       dlgTrans : "D", 
                       dlgMsg : "선택한 데이터를 삭제하시겠습니까?" });
    }

 

    //deleteBlank
    function deleteBlank(argval){
        if (argval === null || argval === undefined) return "";

        for(var i=argval.length-1 ;i >= 0; i--){
            
            if(argval.charCodeAt(i) !== 12288 && argval.charCodeAt(i) !== 32 ) //charCodeAt(i)  
               return(argval.substring(0,i+1));
        }
        return(argval.length>0?argval.substring(0,0):"")
    }



    // input check
    const inputCheck = (gbn) => { 

        let inputFlag = true;

        //select nodes
        let selectedData = grdRsy11Main.current.getSelectedNodes();
        
        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "선택한 데이터가 없습니다."});
            return false;
        }


        //필수입력항목 Check
        selectedData.forEach((node) => {
            //담당자만 수정 
            if (node.data.usid !== userId && tVnt1 !== "M000") {
                setMessage({ openAlert : true, 
                            severity : "info",
                            msg : "담당자만 수정 가능합니다."});
                inputFlag = false
                grdRsy11Main.current.SetFocus(node.rowIndex, "usid");                             
                return ;
            }
            //사용자 ID
            if (node.data.usid === null || node.data.usid === "" || node.data.usid === undefined) {
                setMessage({ openAlert : true, 
                             severity : "info",
                             msg : "사용자ID 데이터를 입력하세요."});
                inputFlag = false                             
                grdRsy11Main.current.SetFocus(node.rowIndex, "usid");                             
                return ;
            }
            else if (node.data.usid.length !== 7) {
                setMessage({ openAlert : true, 
                             severity : "info",
                             msg : "사용자ID는 7자리로 입력하세요."});
                inputFlag = false                             
                grdRsy11Main.current.SetFocus(node.rowIndex, "usid");                             
                return ;
            }


            //삭제가 아닌경우 
            if (gbn !== 'D') {

                //업체코드와 사용자 ID와의 관계 Check
                if (node.data.usid !== null || node.data.usid !== "") {
                    if (node.data.vand  >  " ")   
                    {
                        if (node.data.usid.substring(0, 4) !== node.data.vand) {
                            setMessage({ openAlert : true, 
                                         severity : "info",
                                         msg : "업체코드를 포함한 사용자ID를 입력하여 주십시오."});
                            inputFlag = false    
                            grdRsy11Main.current.SetFocus(node.rowIndex, "usid");                             
                            return;
                        }
                    }
                }


                //발주사용기간(01/W)
                let od01 = deleteBlank(!isNaN(node.data.od01) ? node.data.od01.toString() : node.data.od01);
                if (od01 === "") {
                }
                else if  (parseInt(od01) > 15) {
                    inputFlag = false
                    setMessage({ openAlert : true, 
                                 severity : "info",
                                 msg : "발주사용기간(01/W)은 15이상일수 없습니다."});
                    grdRsy11Main.current.SetFocus(node.rowIndex, "od01");                             
                    return;
                }

                //발주사용기간(02/Q)
                //let od02 = deleteBlank(node.data.od02.toString());
                let od02 = deleteBlank(!isNaN(node.data.od02) ? node.data.od02.toString() : node.data.od02);
                if (od02 === "") {
                }
                else if  (parseInt(od02) > 60) {
                    setMessage({ openAlert : true, 
                                 severity : "info",
                                 msg : "발주사용기간(02/Q)은 60이상일수 없습니다."});
                    inputFlag = false                                 
                    grdRsy11Main.current.SetFocus(node.rowIndex, "od02");                             
                    return;
                }

                //납품구분
                let dlgb = deleteBlank(node.data.dlgb);
                if ( dlgb === "") {
                    setMessage({ openAlert : true, 
                                 severity : "info",
                                 msg : "납품구분을 입력하세요."});
                    inputFlag = false                                 
                    grdRsy11Main.current.SetFocus(node.rowIndex, "dlgb");                             
                    return;
                }
                if ( dlgb.length !== 2) {
                    setMessage({ openAlert : true, 
                                 severity : "info",
                                 msg : "납품구분을 2자리로  입력하세요."});
                    inputFlag = false                                 
                    grdRsy11Main.current.SetFocus(node.rowIndex, "dlgb");                             
                    return;
                }

                if ( !dlgb.startsWith('V') && !dlgb.startsWith('W')) {
                    setMessage({ openAlert : true, 
                                 severity : "info",
                                 msg : "납품구분의 첫번째 자리 입력은 'V' or 'W' 입니다."});
                    inputFlag = false                                 
                    grdRsy11Main.current.SetFocus(node.rowIndex, "dlgb");                             
                    return;
                }

                var arg1 = "1|2|3|4|5|6|7|8|9|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z|";
                var pt = arg1.indexOf(dlgb.substr(1,2)+"|");
                if(pt < 0){ 
                    setMessage({ openAlert : true, 
                                 severity : "info",
                                 msg : "납품구분의 두번째 자리 입력은 [1..9] or [A..Z] 입니다."});
                    inputFlag = false
                    grdRsy11Main.current.SetFocus(node.rowIndex, "dlgb");                             
                    return;
                }
            }

            //deleteBlank
            let pswd=deleteBlank(node.data.pswd);

            //등록
            if (gbn === 'I') {
                //비밀번호 
                if( pswd !== "" && pswd.length < 5 ){
                    setMessage({ openAlert : true, 
                                 severity : "info",
                                 msg : "비밀번호를 5자리 이상 입력하세요."});
                    inputFlag = false;
                    grdRsy11Main.current.SetFocus(node.rowIndex, "pswd");                             
                    return;
                }	
                node.data.roll = node.data.roll01+node.data.roll02+node.data.roll03+node.data.roll04+node.data.roll05;//1~5를 합쳐서 입력함
            }
            //수정
            else if (gbn === 'U') {
                
                //비밀번호
                if( pswd === "" || pswd === null ){
                    setMessage({ openAlert : true, 
                                 severity : "info",
                                 msg : "비밀번호를 입력하세요."});
                    inputFlag = false;    
                    grdRsy11Main.current.SetFocus(node.rowIndex, "pswd");      
                    return;   

                }else if( pswd.length < 5 ){
                    setMessage({ openAlert : true, 
                                    severity : "info",
                                    msg : "비밀번호를 5자리 이상 입력하세요."});
                    inputFlag = false;    
                    grdRsy11Main.current.SetFocus(node.rowIndex, "pswd");    
                    return;
                }
                node.data.roll = node.data.roll01+node.data.roll02+node.data.roll03+node.data.roll04+node.data.roll05;//1~5를 합쳐서 입력함
            }
        });

        return inputFlag;
    }
 
    /**
     * 엑셀
     * @param {*} e 
     */
     const onExcel = async (e) => {
        let para = `{"kWrcUser":"${idata.kWrcUser}","kWrcName":"${idata.kWrcName}","kWrcVand":"${idata.kWrcVand}", "kUseYn":"${idata.kUseYn}"}`;
        let param = encodeURIComponent(para);
        const query = SERVER_URL + "/rsy11/excel?query=" + param;

        setLoading(true);
        
        await fetch(query, {headers: {Authorization: token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'rsy10.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })

        setLoading(false);
        setMessage({ openAlert : true, 
                        severity : "success",
                        msg : "엑셀파일이 생성되었습니다."});
    }

 
    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {
 
        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
 
        //DialogResult
        if (e.result === "N") return;
 
        //Transaction 구분
        switch (e.Trans) 
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D" :
                const query = SERVER_URL + "/rsy11/delete";
                setDeleteApi(query); // Update API
                break;
        }
    }
     
     
     
    /**
     * Grid Bind Data
     * @returns 
    */
    const onGridBindData = (para) => {
        let param = encodeURI(para);
        let items = [];

        const query = SERVER_URL + "/comcd/search1?query=" + param; 

        fetch(query, { headers: { Authorization: token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) { 
                    data.data.forEach((element) => {
                        items.push(element.comCode);    
                    });
                }
            })
        return items;
    }

    
 
 
    /**
     *Grid Column 정의 
    */
    const columnDefs = [
        {
            headerName: '사용자정보',//NPQTY1
            children: [
                { headerName: "User ID", field: "usid", sortable: true, filter: true, flex: 1, minWidth: 120,maxWidth: 120, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false,pinned: 'left' },  //valueFormatter : Utils.numberFormatter
                { headerName: "이름", field: "name", sortable: true, filter: true, flex: 1, minWidth: 120,maxWidth: 120, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false,pinned: 'left'  },
                { headerName: "비밀번호", field: "pswd", sortable: true, filter: true, flex: 1, minWidth: 120,maxWidth: 120, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, cellRendererFramework:GridUtil.AgGridPassword/* Password */, hide: false,pinned: 'left'  },
                { headerName: "회사구분", field: "hkmc", sortable: true, filter: true, flex: 1, minWidth: 110,maxWidth: 110, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], 
          cellEditor:'agSelectCellEditor', cellEditorParams: {values: onGridBindData(`{"pgmid":"CODE", "vand":"****", "gocd" : "COMP"}`) },
          singleClickEdit: true, hide: false,pinned: 'left'  },  //valueFormatter : Utils.numberFormatter
            ]
        },
        
        {
            headerName: '권한',//NPQTY1
            children: [
                { headerName: "카드", field: "roll01", sortable: true, filter: true, flex: 1, minWidth: 80,maxWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, cellRendererFramework: GridUtil.AgGridCheckbox /*check box */, hide: false,pinned: 'left'}, 
                { headerName: "식별표", field: "roll02", sortable: true, filter: true, flex: 1, minWidth: 100,maxWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, cellRendererFramework: GridUtil.AgGridCheckbox /*check box */, hide: false,pinned: 'left'}, 
                { headerName: "Netp", field: "roll03", sortable: true, filter: true, flex: 1, minWidth: 90,maxWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, cellRendererFramework: GridUtil.AgGridCheckbox /*check box */, hide: false,pinned: 'left'}, 
                { headerName: "라벨", field: "roll04", sortable: true, filter: true, flex: 1, minWidth: 80,maxWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, cellRendererFramework: GridUtil.AgGridCheckbox/*check box */, hide: false,pinned: 'left'}, 
                { headerName: "제한\n(사용여부)", field: "roll05", sortable: true, filter: true, flex: 1, minWidth: 80,maxWidth: 100, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,pinned: 'left'},  
                { headerName: "BIGI\n사용여부", field: "bigi", sortable: true, filter: true, flex: 1, minWidth: 80,maxWidth: 100, editable: userId==="M000001"?true:false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: userId==="M000001"?false:true,pinned: 'left'},  
            ]
        },
        {
            headerName: '',//NPQTY1
            children: [
                { headerName: "납품구분", field: "dlgb", sortable: true, filter: true, flex: 1, minWidth: 110, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false }, 
            ]
        },
        {
            headerName: '발주사용기간',//NPQTY1
            children: [
                { headerName: "01/W", field: "od01", sortable: true, filter: true, flex: 1, minWidth: 100,maxWidth: 100, maxLength:5, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", valueFormatter: Utils.numberFormatter, singleClickEdit: true, hide: false }, 
                { headerName: "02/Q", field: "od02", sortable: true, filter: true, flex: 1, minWidth: 100,maxWidth: 100, maxLength:5, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", valueFormatter: Utils.numberFormatter, singleClickEdit: true, hide: false }, 
            ]
        },
        {
            headerName: '납입카드',//NPQTY1
            children: [
                { headerName: "X", field: "nplx", sortable: true, filter: true, flex: 1, minWidth: 100,maxWidth: 100, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], cellEditor:"numericEditor", valueFormatter: Utils.numberFormatter, singleClickEdit: true, hide: false }, 
                { headerName: "Y", field: "nply", sortable: true, filter: true, flex: 1, minWidth: 100,maxWidth: 100, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], cellEditor:"numericEditor", valueFormatter: Utils.numberFormatter, singleClickEdit: true, hide: false }, 
            ]
        },
        {
            headerName: '납품서',//NPQTY1
            children: [
                { headerName: "X", field: "cplx", sortable: true, filter: true, flex: 1, minWidth: 100,maxWidth: 100, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], cellEditor:"numericEditor", valueFormatter: Utils.numberFormatter, singleClickEdit: true, hide: false },   
                { headerName: "Y", field: "cply", sortable: true, filter: true, flex: 1, minWidth: 100,maxWidth: 100, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], cellEditor:"numericEditor", valueFormatter: Utils.numberFormatter, singleClickEdit: true, hide: false },   
            ]
        },
        {
            headerName: '부품식별표',//NPQTY1
            children: [
                { headerName: "X", field: "bplx", sortable: true, filter: true, flex: 1, minWidth: 100,maxWidth: 100, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], cellEditor:"numericEditor", valueFormatter: Utils.numberFormatter, singleClickEdit: true, hide: false },   
                { headerName: "Y", field: "bply", sortable: true, filter: true, flex: 1, minWidth: 100,maxWidth: 100, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], cellEditor:"numericEditor", valueFormatter: Utils.numberFormatter, singleClickEdit: true, hide: false },   
            ]
        },
        {
            headerName: '',//NPQTY1
            children: [
                { headerName: "자동전송", field: "auto", sortable: true, filter: true, flex: 1, minWidth: 110, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false }, 
            ]
        },
        {
            headerName: '',//NPQTY1
            children: [
                { headerName: "업체코드", field: "vand", sortable: true, filter: true, flex: 1, minWidth: 110, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },   
            ]
        },
        {
            headerName: '',//NPQTY1
            children: [
                { headerName: "업체명", field: "vanm", sortable: true, filter: true, flex: 1, minWidth: 130,maxWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },   
            ]
        },
    ];
 
    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 55px  - 50px)"
              });
        }
    }
    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

                    
            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                onSearch={onSearch} onSearchName={null} //조회
                onAddRow={onAddRow} onAddRowName={"신규"} onAddRowVisible = {tVnt1 === "M000"}  //Row 추가
                onInsert={onInsert} onInsertName={null} onInsertVisible = {tVnt1 === "M000"}    //등록
                onUpdate={onUpdate} onUpdateName={null} onUpdateVisible = {tVnt1 === "M000"}    //수정
                onDelete={onDelete} onDeleteName={null} onDeleteVisible = {tVnt1 === "M000"}    //삭제
                onExcel={onExcel} onExcelName={null} onExcelVisible = {tVnt1 === "M000"}    //삭제
                //onPrint={onInsert} onPrintName={null} //print
            >
                {"시스템관리 > 시스템관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>사용자관리</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick={onClick} />


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:55px */}
                <div className={"grid_type01"} > {/* 상단 FRAME */}
                    <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                        <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                            <Grid
                                ref={grdRsy11Main}
                                gridId={"RSY10"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                pagination={true}                  //  쪽수 매기기 여부
                                paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={true}      // cell selection drag mode
                                isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                insertApi={insertApi}               // 등록
                                updateApi={updateApi}               // 수정
                                deleteApi={deleteApi}               // 삭제
                                insertRefresh={onSearch}            // 등록이후 
                                //updateRefresh={onSearch}          // 수정이후 
                                deleteRefresh={onSearch}            // 삭제이후 
                                onClearApi={onClearApi}
                                //onClearApi={()=> {setSearchApi(false); setUpdateApi(false);}}
                                onCellValueChangedEvent={onCellValueChanged}
                                onRowClickEvent={onRowClicked}
                                
                            />
                        </div>
                    </div>
                </div>
            </Content>
        </>
    )
 }
 export default RSY11;