/**
 * @Page ExUpload
 * @Description [REX3H/REX3I]납입지시 - ERP_OEM출하관리 - 납품서일괄발행(VMI) :: 엑셀업로드 Popup
**/
import React, { Component } from 'react';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Ribbon from '@/components/organisms/Ribbon';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
import UploadModal from "@/components/organisms/UploadModal";
 
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 



class ExUpload extends Component {

    constructor(props)  {
        super(props);
        this.grdCarTypeMain = React.createRef();

        this.state = {

            //Common
            token:Common.getToken(),
            userId :Common.getToken("id"),
            userName : Common.getToken("name"),
            tVnt1 : Common.getToken("vand"),
            tComp : Common.getToken("comp"),
            // filePath : `C:\HKC\VAN\ERPC3.CSV`,
    
            //Alert 창 ( Alert 창 OPEN,  Alert 창 구분, Alert 창 메세지)
            openAlert : false, severity : "success", msg : "",
    
            //Dialog 창 (Dialog 창 OPEN, Dialog Transaction(I,U,D),  Dialog 메세지)
            openDlg : false, dlgTrans : "", dlgMsg : "", 
    
            //Common 코드 데이터
            compList : {},
    
            //Api (조회, 등록, 수정, 삭제)
            searchApi : false, insertApi : false, updateApi : false, deleteApi : false,
            openUpload : false,setOpenUpload : false,
            gridApi:null,setGridApi:null,
            loadingFlag : false
        };
        
    
    } 


    
    /**
     * WillMount
     */
    async componentWillMount() {

        //조회조건 Setting
        this.setState({kWrcVand: (this.state.tVnt1 == "M000" ? "" : this.state.tVnt1)}); 

        //공통코도 조회 (회사구분)
        let commonCode = await Promise.all([this.onSearchComp(`{"pgmid":"CODE", "vand":"****", "gocd" : "COMP"}`), ]);

        //회사구분
        this.setState({compList: commonCode[0], loadingFlag : true}); 
    }



   

    /**
     * 공통코드 조회
    */
     onSearchCommon = (para, name) => {
    }


    /**
     * 회사구분 코드 조회
    */
      onSearchComp = async (para) => {
    }

     /**
      * 엑셀
      * @param {*} e
      */
    onBtExport = async(e) => {
        
        let para = JSON.stringify({}); 
        let param = encodeURIComponent(para);

        const query = SERVER_URL + "/rex3h/create?query=" + param;
        
        await fetch(query, {headers: {Authorization: this.state.token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'VMI2_excel_upload.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })
        
    };


    render() {

        if (this.state.loadingFlag === false) {
            return null
        }
        else {
           
            //State
            const {token, userId, userName, tVnt1, tComp, kWrcVand,  openAlert, severity, msg,
                   openDlg, dlgTrans, dlgMsg, compList, searchApi, insertApi, updateApi, deleteApi,openUpload, setOpenUpload,
                   gridApi,setGridApi} = this.state;

            //Grid Clear Api               
            const onClearApi = async (e) => {
                this.setState({ searchApi : false, insertApi : false, updateApi : false, deleteApi : false})
            }
            

            /**
             * Control 변경 Event
             * @param {*} e 
             */
            const onChange = async (e) => {
            
            }

        
            /**
             * Grid Event 
             * @param {*} e 
            */
            const onCellValueChanged = async (e) => {
            }

            const onRowClicked = async (e) => {
            }


            /**
             * 검색조건
            */
            const optionsX = {
                0: [
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                ]
            }

        
        
            /**
             * 조회
             * @param {*} e 
             */
            const onSearch = async (e) => { 
                
            }



            /**
             * 신규 Row추가
             * @param {*} e 
             */
            const onAddRow = async (e) => {

            }



            /**
             * 입력
             * @param {*} e 
             */
            const onInsert = async (e) => {
            }



            /**
             * 수정 
             * @param {*} e 
             * @returns 
             */
            const onUpdate = async (e) => {
            }



            /**
             * 삭제
             * @param {*} e 
             */
            const onDelete = async (e) => {
               
            }



            //deleteBlank
            function deleteBlank(argval){
            }



            // input check
            const inputCheck = (gbn) => {    
            }
            

            //AlertDialogResult
            const AlertDialogResult = async (e) => {

            }


            const actionDown =  async (e) => {
               
              };

            //Grid DropDownList Value
            const extractValues = (mappings) => {
                return  Object.keys(mappings);
            };

            const onUpload = () => {
                this.setState({
                    openUpload:true,
                    setOpenUpload:true
                })
            };

            const handleUploadClickOpen = async (e) => {
                this.setState({
                    openUpload:true,
                    setOpenUpload:true
                })
                //SetOpenUpload(true);
            };


            const handleUploadClose = async (e) => {
                if(e === "T"){
                    this.props.onCloseCust();
                }
                this.setState({
                    openUpload:false,
                    setOpenUpload:false
                })
            };
            
            return (
                <>
                    {/* Dialog FRAME */}
                    <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={() => {this.props.onClose()}} />

                    {/* Alert FRAME */}
                    <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg}  anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

                    <button onClick={(e) => { console.log( "###" + e); this.props.onClose()}} style = {{border:"none", float:"right", paddingRight:"0px"}}> <img src="/images/button/close_button.png" alt="close" /></button> 
                    
                    {/* Ribbon Frame : 프로그램 Path 및 버튼 */}                
                    <Ribbon
                        newTabUnused={true}                         // newTabUnused : newTab 표출 버튼 true : 사용안함 // 선언안할경우 default = newTab 사용
                        onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                    >
                    <b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>파일생성</b>
                    </Ribbon>
                    
                    <UploadModal
                        open={openUpload}
                        onClose={handleUploadClose} 
                        onClickOpen={handleUploadClickOpen}
                        closeAuto={true} // 미선언 및 값이 없을경우 - FALSE - 자동 닫히지 않는다. / TRUE일경우 자동 닫힌다.
                        title={"파일 업로드"}
                        fileId={"VMI2"}
                        filePath={"VMI2.xls"}
                        uploadInfo={{ vnt1 : tVnt1, subDir: "DATA" }} // UPLOAD INFO에 구분값도 함꼐 전달.
                        accp=".xls"                        
                        maxFiles={1}
                        validatorName={"xls"}                        
                        uploadApi={SERVER_URL + "/vmi/uploadexcel"}
                    /> 

                    <div className={"grid_type06"} style = {{width: "100%"}}>{/* 우측 FRAME */}
                    <div className="grid_wrap06" style = {{height: "34%",width: "98%"}}> {/* 우측 wrap FRAME */}
                        <div className="grid_area" style={{ border: "0px solid #babfc7" }}> {/* Grid area */}
                            <div style={{height:"17px"}}></div>
                            <table  style={{ width: "100%" }}>
                                <colgroup>
                                    <col width="100PX" />
                                    <col width="500PX" />
                                    <col width="*" />
                                </colgroup>
                                <tbody>
                                <tr style={{ height:"32px"}}>
                                    <td className="k_upload" style = {{border: "0px",textAlign:"right", whiteSpace:"nowrap"}} >문서코드</td>            
                                    <td style = {{border: "0px"}}> 
                                        <input name="DOCU_CODE" style={{width:"130px",height:"26px",marginLeft:"5px",border:"1px solid #b8b8b8",padding:"0px 0px 0px 5px",fontWeight: "700",fontSize: "14px"}} readOnly="readonly"value = "VMI2"/>
                                    </td>
                                </tr>     
                                 <tr style={{ height:"32px"}}>   
                                    <td className="k_upload"style={{verticalAlign: "middle",border :"0px",textAlign:"right", whiteSpace:"nowrap" }}>문서명</td>
                                    <td style = {{border: "0px"}}> 
                                        <div style={{float:"left"}}> <input type="text" name="DOCU_NAME" style={{width:"248px",height:"26px",verticalAlign:"middle",marginLeft:"5px",border:"1px solid #b8b8b8",padding:"0px 0px 0px 5px",fontWeight: "700",fontSize: "14px"}} maxLength="50" readOnly="readonly" value = "VMI2_excel_upload"/></div>
                                        <button onClick={() => this.onBtExport()} style={{color:"#0076a3",fontWeight:"bold",cursor:"pointer",padding:"7px",float:"left", height:"26px",borderRadius:"0"}} >샘플 파일 다운</button>
                                    </td>
                                </tr>
                                <tr style={{ height:"5px"}}>
                                    <td colSpan={2} style = {{border:"0px"}}>
                                        <div style={{color:"rgb(0, 118, 163)",fontSize: "1.1em", paddingLeft:"95px"}}>※ 샘플 파일 다운로드 후 <b>다른이름으로 저장</b>(<b>VMI2.xls</b>)하여 진행해주세요</div>
                                    </td>
                                </tr>
                                
                                <tr style={{ height:"10px"}}><td colSpan={2} style = {{border:"0px"}}> </td></tr>

                                <tr style={{ height:"32px"}}>   
                                    <td style={{paddingright:"5px",color:"#595959", fontWeight:"bold", border :"0px",textAlign:"right", whiteSpace:"nowrap" }}>적용파일</td>  
                                    <td style = {{border: "0px"}}> 
                                        <input type="text" name="FILE_NAME" style={{float:"left",marginLeft:"5px",border:"1px solid #b8b8b8",height:"26px",padding:"5px 0px 0px 5px",width:"248px"}} readOnly="readonly"/>
                                        <div id = "Ribbon" onClick={onUpload} style={{position:"relative",verticalAlign:"middle",cursor:"pointer",width:"350px"}} >
                                            <input /* onClick={onUpload} */ style={{zIndex:"2",opacity:"0",cursor:"pointer",width:"102px",position:"absolute",height: "22px",filter:"alpha(opacity=0)"}} name="DOCU_FILE"/>
                                            <input className="button" style={{zIndex:"1",position:"relative",width:"87px",height:"26px",left:"10px",border:"1px solid #b8b8b8"}} type="button" value="파일첨부"/>
                                        </div>    
                                    </td> 
                                </tr>
                                <tr style={{ height:"17px"}}></tr>
                                <tr style={{ height:"10px"}}>
                                    <td colSpan={2} style = {{border:"0px"}}>
                                        <div style={{color:"rgb(255 0 12)",fontSize: "1.4em", paddingLeft:"20px"}}>※ 실패건수가 있다면 실제 납품서 발행은 진행되지 않습니다 </div>
                                    </td>
                                </tr>
                                <tr style={{ height:"5px"}}>
                                    <td colSpan={2} style = {{border:"0px"}}></td>
                                </tr>
                                </tbody>
                            </table>
                            
                        </div>
                    </div>
                </div>
                </>
            );
        }
        
    }

}


export default ExUpload;