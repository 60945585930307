/**
 * @Page Kia2DLabelPrint
 * @Description [ROM4V]납입지시 - ERP_OEM출하관리 - 부품식별표(KIA 2D) :: KIA 2D 부품식별표 인쇄(A 4매)
 **/
import React from 'react';
import CustomDataMatrix from '@/components/atoms/CustomDataMatrix';
import * as Common from '@/utils/Common';
import styled from 'styled-components';
import '@/css/common.css';

class Kia2DLabelPrint extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bplx2: '0',
			bply2: '0',
		};
	}

	// componentDidMount 화면 최초 실행 시,  token 에서 x, y 좌표값 가져오기
	componentDidMount() {
		const info_bplx2 = Common.getToken('bplx2') === null ? 0 : 10 + parseInt(Common.getToken('bplx2'), 10) * 2; // 로그인한 사용자의 부품식별표 2D 의 x좌표값 받아오기
		const info_bply2 = Common.getToken('bply2') === null ? 0 : 5 + parseInt(Common.getToken('bply2'), 10) * 2; // 로그인한 사용자의 부품식별표 2D 의 y좌표값 받아오기
		this.setState({ bplx2: info_bplx2 });
		this.setState({ bply2: info_bply2 });
	}

	render() {
		// Print Style CSS Setting
		const PrintStyleKiaA = styled.div`
    @media all {
        body {
            background-color: ;
            margin: 0px;
            padding: 0px;
            font-family: "HY헤드라인M";
        }
        div {
            background-color:;
            margin: 0px;
            padding: 0px;
            box-sizing:border-box;    
        }
        table {
            border-collapse:collapse;
            border-style:none;
            margin: 0px;
            padding: 0px;
            box-sizing:border-box;
        }
        tr{
            width:100%;
            margin: 0px;
            padding: 0px;
        }
        td{
            margin: 0px;
            padding: 0px;
            background-color: white;
            font-family: "HY헤드라인M";
            font-weight: bold;
            border:0px solid green;
            box-sizing:border-box;
        }
        .HYheadM12
        {
        font-family: "HY헤드라인M";
        font-size:12pt;
        word-break:break-all;
        }
        .HYheadM14
        {
        font-family: "HY헤드라인M";
        font-size:14pt;
        word-break:break-all;
        }
        .HYheadM16
        {
        font-family: "HY헤드라인M";
        font-size:16pt;
        word-break:break-all;
        }
        .HYheadM18
        {
        font-family: "HY헤드라인M";
        font-size:18pt;
        word-break:break-all;
        }
        .HYheadM9
        {
        font-family: "HY헤드라인M";
        font-size:9pt;
        word-break:break-all;
        }  
        .HYheadM24
        {
        font-family: "HY헤드라인M";
        font-size:24pt;
        word-break:break-all;
        }  
        .table {
            display:table;
            border:1px solid blue;  
        }                 
        .row {            
            // display: table-row;
            display: flex;
            flex-flow: wrap;
        }
        .cell {
            display:table-cell;
            // border-bottom:1px solid blue;
            // border-right:1px solid blue;     
            border-width: thin;  
            white-space: nowrap;
            z-index: 99;
        }

        .colspan2 { flex: 2; }
        .colspan3 { flex: 3; }
        .colspan4 { flex: 4; }
        .colspan5 { flex: 5; }
        .colspan6 { flex: 6; }
        .colspan7 { flex: 7; }            
        .colspan8 { flex: 8; }
        .colspan9 { flex: 9; }
        .colspan10 { flex: 10; }

        .row>.cell:nth-child(1) { width: 16mm; }
        .row>.cell:nth-child(2) { width: 18mm; }
        .row>.cell:nth-child(3) { width: 19mm; }
        .row>.cell:nth-child(4) { width: 19mm; }
        .row>.cell:nth-child(5) { width: 14mm; }
        .row>.cell:nth-child(6) { width: 17mm; }
        .row>.cell:nth-child(7) { width: 18mm; }
        .row>.cell:nth-child(8) { width: 15mm; }
}
@media print {
    
    @page{size: A4 landscape; margin-top:${this.state.bply2}mm; margin-left:${this.state.bplx2}mm; margin-right:4mm;margin-bottom:4mm; } 

    .table {
        display:table;
        border:1px solid none;  
    }                 
    .row {            
        // display: table-row;
        display: flex;
        flex-flow: wrap;
    }
    .cell {
        display:table-cell;
        border-bottom:1px solid none;
        border-right:1px solid none;     
        border-width: thin;  
        white-space: nowrap;
        z-index: 99;
    }

    div.pagebreak {
      break-after: always !important;
      page-break-after: always !important;
      page-break-inside: avoid !important;
    }

    }
  }


  @media screen {

    .table {
        display:table;
        border:1px solid blue;  
    }                 
    .row {            
        // display: table-row;
        display: flex;
        flex-flow: wrap;
    }
    .cell {
        display:table-cell;
        border-bottom:1px solid blue;
        border-right:1px solid blue;     
        border-width: thin;  
        white-space: nowrap;
        z-index: 99;
        color : blue;
    }

    div.pagebreak {
      break-after: always !important;
      page-break-after: always !important;
      page-break-inside: avoid !important;
    }
    
  }
`;

		const { printData } = this.props;

		// 검색조건 파라메터 가져오기
		// const { kNpdt, kStart, kVnt1, kTelNo, chkMetal } = this.props.idata;
		let { kStart, kTelNo, chkMetal, kCarc, chkNpdt, chkCarc, chkLtno, kCarcNm } = this.props.idata;

		const listSize = printData.length; // data length

		let idx = -1; // -1 부터 start
		let str = parseInt(kStart) - 1;

		idx = idx + str; // 시작 위치 (like 배열)

		// 바코드 표출용 - vseq
		let vseq = 0;

		// remain 표출 체크용 - remainChk -- 신규 품번의 vseq 담기
		let remainChk = 0;

		const cardInfo = printData
			? printData.map((data, index) => {
					// 최초 1 open - 품번 별 row  -- START

					if (index === 0) {
						vseq = parseInt(data.BSEQ) - 1; // 바코드 표출 vseq용
					}

					// remain 표출 체크용 - remainChk -- 신규 품번의 vseq 담기
					remainChk = vseq; // 새 품번의 remain 여부 체크하기

					// 배열 만들어서 map 돌리쟈 !

					let npqt = parseInt(data.NPQT); // 납품수량
					let qypc = parseInt(data.QYPC); // 용기당 수
					let stck = parseInt(data.STCK); // 부착면 수

					// 추가 표시 컬러 체크 -- START
					let tmp25 = data.TMP25; // TMP25    WRCLBL1	이중인쇄1 내용
					let tmp28 = data.TMP28; // TMP28    WRCCOL1	이중인쇄1 컬러

					let tmp30 = data.TMP30; // TMP30    WRCLBL2	이중인쇄2 내용
					let tmp33 = data.TMP33; // TMP33    WRCCOL2	이중인쇄2 컬러

					let color1 = '';
					let color2 = '';

					let dualPrint1 = 'F';
					let dualPrint2 = 'F';

					let dualPrint1css = ''; // dualPrint1 css 추가 표기 view 1
					let dualPrint2css = ''; // dualPrint2 css 추가 표기 view 2

					if (tmp25 !== '') {
						dualPrint1 = 'T';
						if (tmp33 === 'B') {
							color1 = 'blue';
						} else if (tmp33 === 'G') {
							color1 = 'green';
						} else if (tmp33 === 'R') {
							color1 = 'red';
						} else if (tmp33 === 'O') {
							color1 = '#ff6600';
						} else if (tmp33 === 'X') {
							color1 = 'rgba(0, 0, 0, 0.7)';
						}
					}
					if (tmp30 !== '') {
						dualPrint2 = 'T';
						if (tmp28 === 'B') {
							color2 = 'blue';
						} else if (tmp28 === 'G') {
							color2 = 'green';
						} else if (tmp28 === 'R') {
							color2 = 'red';
						} else if (tmp28 === 'O') {
							color2 = '#ff6600';
						} else if (tmp28 === 'X') {
							color2 = 'rgba(0, 0, 0, 0.7)';
						}
					}

					let result = Math.floor(npqt / qypc); // 몫
					let resultIdx = Math.floor(npqt / qypc); // 목 + 나머지의 카운트
					let remain = npqt % qypc; // 나머지

					if (remain > 0) {
						resultIdx = result + 1;
					}

					// 새 프린트용 배열
					const newSearched = [];

					let n = 0;

					Array.from(new Array(resultIdx), (v, i) => {
						// 메인 출력물

						Array.from(new Array(stck), (W, j) => {
							// 부착면수 만큼 선 반복..

							// 시작시 idx 셋팅
							idx = idx + 1;
							if (j === 0) {
								// 부착면수 1일때 고려...
								vseq = vseq + 1; // 새 vseq 마다 + 1 증가
							}
							let iCnt = i + 1;

							const newArray = {}; // new Array 선언

							let jCnt = j + 1;

							// 새 배열의 n 번째 구하기
							if (i === 0 && j === 0) {
								n = 0;
							} else {
								n = n + 1;
							}

							newArray['idx'] = idx;
							newArray['vseq'] = vseq;

							//납품수량
							let newQty = 0;
							newArray['qty'] = qypc;
							newQty = qypc;

							newArray['stck'] = stck;

							newArray['firstpage'] = 'F';
							newArray['endpage'] = 'F';

							newArray['color1'] = color1;
							newArray['color2'] = color2;

							newArray['dualPrint1'] = dualPrint1;
							newArray['dualPrint2'] = dualPrint2;

							// 첫번째 Page 구하기
							if (i === 0 && j === 0 && index === 0) {
								newArray['firstpage'] = 'T';
							}

							if (resultIdx === vseq - remainChk) {
								if (remain > 0) {
									newArray['qty'] = remain;
									newQty = remain;
								}
							}

							// END Page 구하기
							if (listSize === index + 1) {
								if (resultIdx === iCnt) {
									if (stck === jCnt) {
										newArray['endpage'] = 'T';
									}
								}
							}

							// DataMatrix 바코드 값 만들기
							//const barcodeValu = data.VAND +""+ ("000000000000000" + data.TMP5).slice(-15) +""+ ("00000" + newQty).slice(-5) +""+ data.VAND +""+ ("000000" + vseq).slice(-6) +""+ (data.TMP11?data.TMP11.replaceAll('-',''):"");
							const barcodeValu =
								data.VAND +
								Common.getLpad(data.TMP5, ' ', 15) +
								Common.getLpad(newQty + '', '0', 5) +
								data.VAND +
								Common.getLpad(vseq + '', '0', 6) +
								(data.TMP11 ? data.TMP11.replaceAll('-', '') : '');
							//vo.vand +StringHelper.lpad(vo.tmp5," ",15)+StringHelper.lpad(qty+"", "0", 5)+vo.vand+StringHelper.lpad(vseq+"", "0", 6)+ vo.tmp11;

							// DataMatrix Barcode 만들기
							// const dataMatrixBarcode = <CustomDataMatrix val={barcodeValu} sty={{width:"20mm"}} />;
							const dataMatrixBarcode = <CustomDataMatrix val={barcodeValu} scale={'2'} height={'18'} />;
							newArray['barcode'] = dataMatrixBarcode;

							// const lotNo = data.VAND +""+ ("000000" + data.BSEQ).slice(-6) +""+ data.TMP11.replaceAll('-','');
							const lotNo = data.VAND + '' + ('000000' + vseq).slice(-6) + '' + (data.TMP11 ? data.TMP11.replaceAll('-', '') : '');
							//const bNo = data.VAND +" "+ ("000000000000000" + data.TMP5).slice(-15) +""+ ("00000" + newQty).slice(-5);

							const bNo = data.VAND + ' ' + Common.getLpad(data.TMP5, ' ', 15) + ' ' + Common.getLpad(newQty + '', '0', 5);
							newArray['lotno'] = lotNo;
							newArray['bno'] = bNo;

							// 자기 위치 셋팅 (출력매수)
							const posi = idx % 4;
							newArray['posi'] = posi;

							let divCss = '';
							let divMetal = '';

							// page별 (출력매수)1장 이상일경우 100% 씩 추가 ... 다음장 노출을 위함.
							let pagebreaky = Math.floor(idx / 4) * 100;

							let pagebreakyCnt = Math.floor(idx / 4);
							//let pagebreakyTop = 194;//쪼오끔 맞음
							let pagebreakyTop = 194;
							let pagebreakyBot = 272;

							if (posi === 0) {
								divCss = { position: 'absolute', top: pagebreaky + '%', left: '0mm', padding: '1mm 2mm 2mm 3mm', border: '0px solid red' };
								divMetal = { position: 'absolute', top: pagebreaky + 14.6 + '%', left: '121mm', zIndex: '12' };
								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY헤드라인M',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 2.1 + '%' : parseInt(data.TMP27, 10) / 2 + pagebreaky - 2 + '%',
									left: parseInt(data.TMP26, 10) - 2 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									zIndex: '13',
									whiteSpace: 'nowrap',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY헤드라인M',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 2.9 + '%' : parseInt(data.TMP32, 10) / 2 + pagebreaky - 2 + '%',
									left: parseInt(data.TMP31, 10) - 1 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									zIndex: '13',
									whiteSpace: 'nowrap',
								};
							} else if (posi === 1) {
								divCss = { position: 'absolute', top: pagebreaky + '%', left: '140mm', padding: '1mm 2mm 2mm 2mm', border: '0px solid red' };
								divMetal = { position: 'absolute', top: pagebreaky + 14.6 + '%', left: '261mm', zIndex: '12' };

								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY헤드라인M',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 2.1 + '%' : parseInt(data.TMP27, 10) / 2 + pagebreaky - 2 + '%',
									left: parseInt(data.TMP26, 10) + 139 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									zIndex: '13',
									whiteSpace: 'nowrap',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY헤드라인M',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 2.9 + '%' : parseInt(data.TMP32, 10) / 2 + pagebreaky - 2 + '%',
									left: parseInt(data.TMP31, 10) + 140.1 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									zIndex: '13',
									whiteSpace: 'nowrap',
								};
							} else if (posi === 2) {
								divCss = { position: 'absolute', top: pagebreaky + 49.4 + '%', left: '0mm', padding: '1mm 2mm 2mm 3mm', border: '0px solid red' };
								divMetal = { position: 'absolute', top: pagebreaky + 64 + '%', left: '121mm', zIndex: '12' };

								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY헤드라인M',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 49.5 + '%' : parseInt(data.TMP27, 10) / 2 + (pagebreaky + 47.5) + '%',
									left: parseInt(data.TMP26, 10) - 2 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									zIndex: '13',
									whiteSpace: 'nowrap',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY헤드라인M',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 50.3 + '%' : parseInt(data.TMP32, 10) / 2 + (pagebreaky + 48.3) + '%',
									left: parseInt(data.TMP31, 10) - 1 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									zIndex: '13',
									whiteSpace: 'nowrap',
								};
							} else if (posi === 3) {
								divCss = { position: 'absolute', top: pagebreaky + 49.4 + '%', left: '140mm', padding: '1mm 2mm 2mm 2mm', border: '0px solid red' };
								divMetal = { position: 'absolute', top: pagebreaky + 64 + '%', left: '261mm', zIndex: '12' };

								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY헤드라인M',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 49.5 + '%' : parseInt(data.TMP27, 10) / 2 + (pagebreaky + 47.5) + '%',
									left: parseInt(data.TMP26, 10) + 139 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									zIndex: '13',
									whiteSpace: 'nowrap',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY헤드라인M',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 50.3 + '%' : parseInt(data.TMP32, 10) / 2 + (pagebreaky + 48.3) + '%',
									left: parseInt(data.TMP31, 10) + 140.1 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									zIndex: '13',
									whiteSpace: 'nowrap',
								};
							}
							newArray['divCss'] = divCss;
							newArray['divMetal'] = divMetal;

							newArray['dualPrint1css'] = dualPrint1css;
							newArray['dualPrint2css'] = dualPrint2css;

							newSearched[n] = newArray;
						});
					});

					return (
						<>
							{newSearched.map(
								(sData, dataIdx) => (
									<form key={'Kia2DLabelPrint' + dataIdx}>
										{/* div로 테이블 그리기 */}
										<div className="table" style={{ ...sData.divCss, width: '138mm', height: '93mm', overflow: 'scroll' }}>
											<div className="row" style={{ height: '4mm' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
											</div>

											<div className="row" style={{ height: '4mm' }}>
												<div className="cell"></div>
												<div className="cell HYheadM14" style={{ paddingTop: '1px', paddingLeft: '10px' }}>
													{data.TMP1}
												</div>
												{/* 공장 */}
												<div className="cell"></div>
												<div className="cell HYheadM14" style={{ paddingLeft: '5px', whiteSpace: 'nowrap', verticalAlign: 'middle' }}>
													{chkCarc && chkCarc === 'Y' ? kCarcNm : ''}
												</div>
												{/*차종 chkCarc kCarc kCarcNm data.TMP3 - ERP의 경우 차종명을 가져오도록 수정  */}
												<div className="cell"></div>
												<div className="cell HYheadM14 colspan3" style={{ textAlign: 'center', whiteSpace: 'nowrap', verticalAlign: 'middle' }}>
													{data.TMP4}
												</div>
												{/* 지역 */}
											</div>

											<div className="row" style={{ height: '5mm' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
											</div>

											<div className="row" style={{ height: '9mm' }}>
												<div className="cell"></div>
												<div className="cell HYheadM18" style={{ textAlign: 'left', whiteSpace: 'nowrap', verticalAlign: 'middle' }}>
													{data.TMP5.substr(0, 5) + '-' + data.TMP5.substr(5)}
												</div>
												{/* 품번 */}
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell"></div>
												<div className="cell HYheadM12" style={{ fontSize: '12pt', verticalAlign: 'middle', paddingLeft: '1mm', paddingTop: '1mm' }}>
													{data.VAND}
												</div>
												{/* 업체코드 */}
												<div className="cell HYheadM12 colspan2" style={{ fontSize: '12pt', verticalAlign: 'middle', paddingTop: '1mm' }}>
													{data.TMP0}
												</div>
												{/* 업체명 */}
											</div>

											<div className="row" style={{ height: '13mm' }}>
												<div className="cell"></div>
												<div className="cell HYheadM18 colspan6" style={{ textAlign: 'left', verticalAlign: 'middle', padding: '2mm 1mm 0mm 0mm' }}>
													{data.TMP6}
												</div>
												{/* 품명 */}

												<div className="cell HYheadM24" style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '24pt' }}>
													{data.TMP10}
												</div>
												{/*  */}
											</div>

											<div className="row" style={{ height: '10mm' }}>
												<div className="cell"></div>
												<div className="cell HYheadM14" style={{ textAlign: 'center', verticalAlign: 'middle', paddingTop: '2mm' }}>
													{data.TMP7}
												</div>
												{/* 납품장소 */}
												<div className="cell"></div>
												<div className="cell HYheadM12" style={{ verticalAlign: 'middle', fontSize: '12pt', paddingTop: '2mm' }}>
													{data.TMP8}
												</div>
												{/* 용기 */}
												<div className="cell"></div>
												<div className="cell HYheadM18 colspan4" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
													{sData.qty}
												</div>
												{/* 수납수 */}
											</div>

											<div className="row" style={{ height: '8mm' }}>
												<div className="cell" style={{ height: '8mm' }}></div>
												<div className="cell" style={{ height: '8mm' }}></div>
												<div className="cell HYheadM12 colspan5" style={{ paddingLeft: '2mm', fontSize: '12pt', whiteSpace: 'pre', verticalAlign: 'middle' }}>
													Lot No : {chkLtno && chkLtno === 'Y' ? sData.lotno : ''}
												</div>
												{/* Lot No */}
												<div className="cell HYheadM12 colspan4" style={{ height: '8mm', fontSize: '12pt', whiteSpace: 'pre', verticalAlign: 'right', textAlign: 'end' }}>
													{data.TMP1 === 'KV21' || data.TMP1 === 'KV22' || data.TMP1 === 'KV23'
														? data.WTUN === '' || data.WTUN === '0' || data.WTUN === undefined
															? ''
															: `총중량 :${(sData.qty * data.WTUN).toFixed(1) + 'kg'}`
														: ''}
												</div>
											</div>

											<div className="row" style={{ height: '11mm' }}>
												<div className="cell HYheadM12 colspan2" style={{ zIndex: '15', paddingLeft: '6mm', paddingTop: '1mm' }}>
													{sData.barcode}
												</div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM14 colspan4" style={{ verticalAlign: 'middle', whiteSpace: 'nowrap', fontSize: '13pt' }}>
													{kTelNo}
												</div>
												{/* 연락처 data.TELNO  */}
											</div>

											<div className="row" style={{ height: '10mm' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell HYheadM12 colspan2" style={{ verticalAlign: 'middle', fontSize: '12pt' }}>
													{chkNpdt && chkNpdt === 'Y' ? data.TMP2 : ''}
												</div>
												{/* 생산일자 chkNpdt */}
												<div className="cell colspan2" style={{ fontSize: '24pt', verticalAlign: 'middle' }}>
													{data.TMP14}
												</div>
												{/*  */}
											</div>

											<div className="row" style={{ height: '8.5mm' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell colspan2" style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '12pt' }}>
													{data.TMP13}
												</div>
												{/* 공정No */}
												<div className="cell"></div>
												<div className="cell"></div>
											</div>

											<div className="row" style={{ height: '3mm' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
											</div>

											<div className="row" style={{ height: '5mm' }}>
												<div className="cell"></div>

												<div
													className="cell HYheadM9 colspan7"
													style={{ paddingLeft: '4.5mm', fontSize: '9pt', verticalAlign: 'middle', display: 'flex', flexDirection: 'column-reverse' }}
												>
													{sData.bno}
												</div>
												{/* bno 발행번호 */}
											</div>
										</div>

										{/* 중금속 확인필 */}
										<div style={{ ...sData.divMetal }}>
											{chkMetal === 'Y' ? (
												<img src="/images/heavy_metal_confirm.gif" style={{ width: '18mm' }} alt="heavy_metal_confirm" />
											) : (
												<img src="/images/content.png" style={{ width: '13mm' }} alt=" " />
											)}
										</div>

										{/* 이중출력부분 */}
										{/* XXXXXX */}
										{sData.dualPrint1 === 'T' ? <div style={{ ...sData.dualPrint1css }}>{data.TMP25}</div> : ''}
										{sData.dualPrint2 === 'T' ? <div style={{ ...sData.dualPrint2css }}>{data.TMP30}</div> : ''}
										{/* XXXXXX */}

										{sData.posi === 3 && sData.endpage === 'F' ? (
											<div key={'pagebreak' + sData.idx} style={{ background: 'red', breakAfter: 'page', pageBreakAfter: 'always', pageBreakInside: 'avoid' }}></div>
										) : (
											''
										)}
									</form>
								),
								'',
							)}
						</>
					);

					// 최초 1 close - 품번 별 row  -- END
			  })
			: '';
		return <PrintStyleKiaA key={'Kia2DLabelPrint'}>{cardInfo}</PrintStyleKiaA>;
	}
}
export default Kia2DLabelPrint;
