/**
 * @Page NOTE//RSY50
 * @Description [NOTE]공지사항
 **/
import React, { useState, useEffect, useRef, useCallback } from "react";
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
import CommonUtil from '@/utils/CommonUtil'; 
import Popup from '@/pages/Popup/Popup';     
import { useParams } from 'react-router-dom';
import {useHistory } from "react-router-dom";

const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
const Utils = new CommonUtil();


function RSY50() {
    const token = Common.getToken();
    const userId = Common.getToken("id");
    const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
const bigi = Common.getToken("bigi");
    const grdQnaMain = useRef();   //Grid Main
    const history = useHistory();
    let {id} = useParams();
    id = history.location.pathname.substring(11);
    


    //Data
    const [idata, setData] = useState({
        //조회조건
        kSear : "1",      
        kSval : "",
        vdnm:"",
        numb:"",
        wrcseq:0,
        userName:userName,
    }); 

   
   
    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;


    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
    const [typeOpen, setTypeOpen] = React.useState(false);
    const [data, setDatas] = useState([]);
    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 50px)"});

    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    let [insertApi, setInsertApi] = useState(false); 
    let [updateApi, setUpdateApi] = useState(false); 
    let [deleteApi, setDeleteApi] = useState(false); // deleteApi 항목 setting
    
    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
        setInsertApi(false);
        setUpdateApi(false);
        setDeleteApi(false);
    }, []);
    
    

    /**
     * 공통코드 조회
     */
    const onSearchCommon = (para) => {

        let commonData = idata;

        //그룹코드 조회
        let param = encodeURI(para);
        const query = SERVER_URL + "/comcd/search1?query=" + param; 
        fetch(query, { headers: { Authorization: token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) { 
                    commonData["kWrcGocdList"] = data.data
                    setData({...commonData});
                }
            })
    }

    const onSearchCommon1 = async (para, name) => {

        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/note/searchqna?query="; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success)
                    {
                        if(tVnt1==="M000"){
                                if(data.data[0].wrcseq> 0){
                                    alert("Q&A에 답변 달아 주세요~ ^*^");    
                                }
                        }
                    }
                })
        }
        await fetchData(para, name);
    }


    /**
     *MOUNT 
     */
    useEffect( () => {
        //공통코드 조회
        onSearchCommon(`{"pgmid":"CODE", "vand":"****", "gocd" : "00"}`);
        onSearchCommon1();

        onSearch();

        if (id) {
            let data=[];
            data["gubn"] = "search";
            data["numb"]=id;
            setDatas({ ...data });
            setTypeOpen(true);
        }
    }, []);



    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value } = e.target;

        let newSearchs = idata;
        newSearchs[name] = value

        setData({ ...newSearchs });

    }

    const onRowClicked = async (e) => {

        let data =  e.data;

        data["gubn"] = "search";
        
        setDatas({ ...data });

        setTypeOpen(true);
    }



    /**
     * 검색조건
     */
     const optionsX = {
        0: [
            //구분
            {
                formatter: "radio", name: "kSear", labelName: "", type: "text", desc: "", readOnly: false,
                options: [{name: "kSear1", setValue: "1", labelName: "제목"}, {name: "kSear2", setValue: "2", labelName: "글쓴이"}, {name: "kSear3", setValue: "3", labelName: "날짜"}],
                defaultValue: idata.kSear,  
                align: "left", 
                labelType: "none", 
            },//그룹코드
            {
                formatter: "input", name: "kSval", labelName: "", type: "text", desc: "",
                defaultValue: idata.kSval,  
                align: "left", 
                labelType: "none" 
            },  
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" }
        ]
    }



    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {

        const fetchData = async (e) => {//조회시 전체건수구해서 넣어주기
            let inputData = idata; 

            let para = `{"kSear":"${idata.kSear}","kSval":"${idata.kSval}"}`;
            let param = encodeURI(para);
            //let param = encodeURIComponent(para);
            const query = SERVER_URL + "/note/search?query=" + param;

            await fetch(query, { headers: { Authorization: token } })
                     .then((resp) => resp.json())  
                     .then((data) => {
                         if (data.success) {
                            setSearchApi(query);
                            
                            
                        }
                        if (data.error) {
                            alert("서버 상태를 확인해 주세요. 관리자에게 문의해주세요.");
                        }
                     })
                     .catch((error) => {
                        // 에러 응답 처리
                        if (error) {
                            setMessage({ openAlert : true, 
                                         severity : "error",
                                         msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                        }
                    });
                    };
            fetchData(e);

    }

    



    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
    }


    
    /**
     * 입력
     * @param {*} e 
     */
    const onInsert = async (e) => {

        let data =  [];

        data["gubn"] = "write";//아이디
        // data["vdnm"] = idata.userName;//이름
        // data["chk"] = 0;//최초 입력인지 확인

        // setDatas({ ...data });

        setDatas(data);
        setTypeOpen(true);
    }



    /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {

         //input check
         let input = await Promise.all ([inputCheck('U')]);
         if ( !input[0]) return;
        
        const query = SERVER_URL + "/rsy10/update";
        setUpdateApi(query); // Update API
    }



    /**
     * 삭제
     * @param {*} e 
     */
    const onDelete = async (e) => {
         
        //input check
        let input = await Promise.all ([inputCheck('D')]);
        if ( !input[0]) return;

        //삭제 확인 메세지 
        setDlgMessage({openDlg : true,  
                       dlgTrans : "D", 
                       dlgMsg : "선택한 데이터를 삭제하시겠습니까?" });
    }



    // input check
    const inputCheck = (gbn) => { 
    }



    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans) 
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D" :
                const query = SERVER_URL + "/rsy10/delete";
                setDeleteApi(query); // Update API
                break;
        }
    }
    
    


    /**
     *Grid Column 정의 
     */
    const columnDefs = [
        { headerName: "num", field: "num", sortable: true, filter: true, flex: 1, minWidth: 100, hide: true, cellClass: ["nocheckbox", "alignC"]}, 
        { headerName: "gubn2", field: "gubn2", sortable: true, filter: true, flex: 1, minWidth: 100, hide: true, cellClass: ["nocheckbox", "alignC"]},//상단체크여부
        { headerName: "wrcseq", field: "wrcseq", sortable: true, filter: true, flex: 1, minWidth: 100, hide: true, cellClass: ["nocheckbox", "alignC"]},//Q&A 답변 여부
        { headerName: "No", field: "numb", sortable: true, filter: true, flex: 1, minWidth: 100, hide: false, cellClass: ["nocheckbox", "alignC"]}, 
        { headerName: "제 목", field: "titl", sortable: true, filter: true, flex: 1, minWidth: 850, hide: false, cellClass: ["nocheckbox", "alignL"] 
        ,cellStyle: params => {//cell글자색 변경
            if (params.data.numb === " ") {return {fontWeight: "900"};}
            return null;
        }}, 
        { headerName: "글쓴이", field: "usnm", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
        { headerName: "날짜", field: "idat", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false,  cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,valueFormatter: Utils.dateFormatter  }, 
        { headerName: "조회수", field: "rcnt", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false }, 
    ];

    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 55px  - 50px)"
              });
        }
    }
    
    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

            <Popup type="NotePopup" open={typeOpen} onClose={()=> {setTypeOpen(false);onSearch();}} data ={data} />
                    
            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                onSearch={onSearch} onSearchName={null} //조회
                onInsert={onInsert} onInsertVisible = {tVnt1 === "M000"}     //등록
                //onUpdate={onUpdate} onUpdateName={null} onUpdateVisible = {tVnt1 === "M000"}   //수정
                //onDelete={onDelete} onDeleteName={null} onDeleteVisible = {tVnt1 === "M000"}    //삭제
            >
                <b style={{ color: "#cd5c5c", fontFamily: "PretendardMedium, NanumGothic, sans-serif" }}>공지사항</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick={onClick} />


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:55px */}
                <div className={"grid_type01"} > {/* 상단 FRAME */}
                    <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                        <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                            <Grid
                                ref={grdQnaMain}
                                gridId={"QNA"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                pagination={false}                  //  쪽수 매기기 여부
                                paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={false}      // cell selection drag mode
                                isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                insertApi={insertApi}               // 등록
                                updateApi={updateApi}               // 수정
                                deleteApi={deleteApi}               // 삭제
                                //updateRefresh={onSearch}          // 수정이후 
                                deleteRefresh={onSearch}            // 삭제이후 
                                onClearApi={onClearApi}
                                //onClearApi={()=> {setSearchApi(false); setUpdateApi(false);}}
                                // onCellValueChangedEvent={onCellValueChanged}
                                onRowClickEvent={onRowClicked}
                                
                            />
                        </div>
                    </div>
                </div>
            </Content>
        </>
    )
}
export default RSY50;