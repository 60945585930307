// import React from 'react';
import React, { useEffect } from 'react';

import '../../css/main.css';

import { TextField } from '@material-ui/core';

import CustomCheckbox from '../../components/atoms/CustomCheckbox';

let codesList;
let commsList; 
let itemsList; // Case 3 - items :: Hard Coding List


function CustomInputSelectCheck({ //children, ref,
    id, name, defaultValue,    
    codes, comms, items,
    codeMode, selectAll,

    id2, name2, defaultValue2,
    labelName2,labelOptions2,
    onChange }) {

        // Case 1 - "Code List" - codes :: codesList
        if (codes) { codesList = codes; } else { codesList = []; }

        // Case 2 - "Search List" - comms :: commsList    
        if (comms) { commsList = comms; } else { commsList = []; }
    
        // Case 3 - "Hard Coding List" - items :: itemsList
        if (items) { itemsList = items; } else { itemsList = []; }


    useEffect(() => {

        if (defaultValue) {
            let temp = document.getElementById(id);
            temp.value = defaultValue;
        }
        if (defaultValue2) {
            let temp2 = document.getElementById(id2);
            temp2.value = defaultValue2;
        }

    }, [id, id2, defaultValue, defaultValue2]);


    const handleChange = (e) => { // 검색조건 변경시 이벤트 처리 - 검색조건
        const { name, value } = e.target;
        let event = {
            target: {}
        };
        event.target["name"] = name;
        event.target["value"] = value;

        if (onChange) {
            onChange(event);
        }
    };

    return (
        <>
            <div className={"h_Label1L"} style={{width:"130px"}}>


                <TextField
                    id={id}
                    name={name}
                    className={"k_combo"}
                    select
                    onChange={handleChange}
                    SelectProps={{
                        native: true,
                    }}
                    variant="outlined"
                    defaultValue={defaultValue}

                    style={{width:"130px"}}
                >


                    {selectAll && selectAll === "none" ?
                        <option key={"selectOne" + id} value={""}>{""}</option>
                        : ""}

                    {selectAll && selectAll === "select" ?
                        <option key={"selectOne" + id} value={""}>{"선택"}</option>
                        : ""}

                    {(selectAll && selectAll === "all") || selectAll === true ?
                        <option key={"selectOne" + id} value={""}>{"전체"}</option>
                        : ""}

                    {/* Case 1 - "Code List" - codes :: codesList */}
                    {codesList ? codesList.map((option, index) => (
                        < option key={"codesList" + id + index} value={option.comCode}>
                            {codeMode ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""}

                    {/* Case 2 - "Search List" - comms :: commsList */}
                    {commsList ? commsList.map((option, index) => (
                        < option key={"commsList" + id + index} value={option.comCode}>
                            {codeMode ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""}

                    {/* Case 3 - "Hard Coding List" - items :: itemsList */}
                    {itemsList ? itemsList.map((option, index) => (
                        < option key={"itemsList" + id + index} value={option.comCode}>
                            {codeMode ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""}

                </TextField>

            </div>
                
            <div className={"h_Label1L"} style={{width:"70px",marginTop:"-5px",paddingLeft:"10px"}}>

                <CustomCheckbox                
                            id={id2}
                            name={name2}

                            options={labelOptions2}

                            onChange={handleChange}
                            paddingLeft={"2px"}
                        />

            </div>
        </>
    );
}

export default CustomInputSelectCheck;