import React, { Component , useState} from 'react';
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
import CommonUtil from '@/utils/CommonUtil'; 
import { stringToArray } from 'ag-grid-community';
import Loading from "src/components/atoms/CustomLoading2";
 
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');

const delgbn = [{ "comCode" : "",  "text": "발행내역" },{ "comCode" : "DEL",  "text": "발행삭제내역" }];    //구분
const prgb = [{ "comCode" : "Y",  "text": "인쇄" },{ "comCode" : "N",  "text": "미인쇄" }]; //인쇄여부
const chul = [{ "comCode" : "Y",  "text": "전송" },{ "comCode" : "N",  "text": "미전송" }]; //전송여부

const Utils = new CommonUtil();

class Redeli extends Component {
    

    constructor(props)  {
        super(props);
        this.grdCarTypeMain = React.createRef();

        this.state = {
            //Common
            token:Common.getToken(),
            userId :Common.getToken("id"),
            userName : Common.getToken("name"),
            tVnt1 : Common.getToken("vand"),
            tComp : Common.getToken("comp"),
    
            //구분
            kDelGubn : "",
            //납품서번호
            kDlNo : "",
            //인쇄여부
            kPrgb : "",
            //전송여부
            kChul : "",
            //전송메세지
            kMsg : "",
    
            //Alert 창 ( Alert 창 OPEN,  Alert 창 구분, Alert 창 메세지)
            openAlert : false, severity : "success", msg : "",
    
            //Dialog 창 (Dialog 창 OPEN, Dialog Transaction(I,U,D),  Dialog 메세지)
            openDlg : false, dlgTrans : "", dlgMsg : "", 
    
            //Api (조회, 등록, 수정, 삭제)
            searchApi : false, insertApi : false, updateApi : false, deleteApi : false,

            loadingFlag : false,
            loading : false
        }
       
    }
    
    /**
     * WillMount
     */
    async componentWillMount() {
        //Loading Flag
        this.setState({loadingFlag : true}); 
    }


    /**
     * DidMount
     */
    componentDidMount() {
    }


    // /**
    //  * WillReceiveProps
    //  */
    // componentWillReceiveProps () {
    // }

    // /**
    //  * WillUpdate
    //  * @param {*} nextProps 
    //  * @param {*} nextState 
    //  */
    // componentWillUpdate(nextProps, nextState) {
    // }

    // /**
    //  * DidUpdate
    //  * @param {*} prevProps 
    //  * @param {*} prevState 
    //  */
    // componentDidUpdate(prevProps, prevState) {
    // }

    // /**
    //  * WillUnmount
    //  */
    // componentWillUnmount() {
    // }


    /**
     * 공통코드 조회
    */
     onSearchCommon = (para, name) => {
    }


    render() {

        if (this.state.loadingFlag === false) {
            return null
        }
        else {
        
            //State
            const {token, userId, userName, tVnt1, tComp, kDelGubn, kDlNo, kPrgb, kChul, kMsg,
                   openAlert, severity, msg, loading,
                   openDlg, dlgTrans, dlgMsg, compList, searchApi, insertApi, updateApi, deleteApi } = this.state;

                        
            /**
             * Loading 선언
            */
             const setLoading = (gubn) => {
                this.setState({loading : gubn}); 
            }


            /**
             * Control 변경 Event
             * @param {*} e 
             */
            const onChange = async (e) => {
                const { name, value } = e.target;

                let newSearchs = this.state;
                newSearchs[name] = value
                this.setState({ ...newSearchs });
            }

        
            /**
             * 검색조건
            */
            const optionsX = {
                0: [
                    //구분
                    {
                        "formatter": "select", "name": "kDelGubn", "labelName": "구분", "type": "text", "defaultValue": kDelGubn,  
                        "codeMode": true,
                        "selectAll": "",
                        "codes": delgbn,
                        "comms": null,
                        "align": "left", 
                    }, 
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                ],
                1: [
                    //납품서번호 
                    {
                        "formatter": "input", "name": "kDlNo", "labelName": "납품서번호", "type": "text", "desc": "", "readOnly" : false,
                        "defaultValue": kDlNo,  
                        "align": "left", 
                        "labelType": "none" 
                    }, 
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                ],
                2: [
                    //인쇄여부
                    {
                        "formatter": "select", "name": "kPrgb", "labelName": "인쇄여부", "type": "text", "defaultValue": kPrgb,  
                        "codeMode": false,
                        "selectAll": "none",
                        "codes": prgb,
                        "comms": null,
                        "align": "left", 
                    }, 
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                ],
                3: [
                    //전송여부
                    {
                        "formatter": "select", "name": "kChul", "labelName": "전송여부", "type": "text", "defaultValue": kChul,  
                        "codeMode": false,
                        "selectAll": "none",
                        "codes": chul,
                        "comms": null,
                        "align": "left", 
                    }, 
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                ],
                4: [
                    //전송msg 
                    {
                        "formatter": "input", "name": "kDlNo", "labelName": "전송msg", "type": "text", "desc": "", "readOnly" : true,
                        "defaultValue": kMsg,  
                        "align": "left", 
                        "labelType": "none" 
                    }, 
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                ],
                5: [
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                ],
            }


        
            /**
             * 조회
             * @param {*} e 
             */
            const onSearch = async (e) => { 
                
                //input check
                let input = await Promise.all ([inputCheck()]);
                if ( !input[0]) return;
                
                let table = (kDelGubn === "DEL" ? "wrthead_del" : "wrthead");
                let para_trim = kDlNo.trim(); // 입력한 납품서번호 trim 처리
                let para = `{"table":"${table}","kDlno":"${para_trim}"}`;
                let param = encodeURIComponent(para);

                const query = SERVER_URL + "/popup/redeli_search?query=" + param;

                const fetchSearchData = async (query) => {

                    await fetch(query,  { headers: { Authorization: token } })
                        .then((resp) => resp.json())
                        .then((data) => {

                            if (data.success) {
                                if (data.data.length == 0) {
                                    this.setState({ openAlert : true, 
                                                    severity : "error",
                                                    msg : "해당 납품서 내역이 존재하지 않습니다."});
                                }
                                else {
                                    this.setState({ openAlert : true, 
                                                    severity : "info",
                                                    msg : "정상적으로 조회되었습니다."});

                                    //인쇄여부, 전송여부, 전송msg  Setting    
                                    this.setState({kPrgb : data.data[0].prgb, kChul:data.data[0].chul, kMsg : data.data[0].cmsg}); 
                                }

                                
                            }
                            if (data.error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "조회 실패"});
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                };
                fetchSearchData(query);
            }



            /**
             * 신규 Row추가
             * @param {*} e 
             */
            const onAddRow = async (e) => {
            }



            /**
             * 입력
             * @param {*} e 
             */
            const onInsert = async (e) => {

                //input check
                let input = await Promise.all ([inputCheck()]);
                if ( !input[0]) return;

                const fetchInsertData = async () => {

                    const query = SERVER_URL + "/popup/redeli_insert"; 
                    let param = JSON.stringify({
                        dlno: kDlNo,
                    });
                    
                    //납품서 복구 시 로딩오픈
                    setLoading(true);
                    await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })                        
                        .then((resp) => resp.json())
                        .then((data) => {

                            if (data.success) {
                                this.setState({ openAlert : true, 
                                                severity : "info",
                                                msg : data.data.message});
                            }

                            else if (data.errors) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.errors.message});
                            }
                            else if (data.error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.message});
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                        setLoading(false);
                };
                fetchInsertData();
            }



            /**
             * 수정 
             * @param {*} e 
             * @returns 
             */
            const onUpdate = async (e) => {

                //인쇄여부 입력 Check
                if (kPrgb === "") {
                    
                    this.setState({ openAlert : true, 
                                    severity : "info",
                                    msg : "인쇄여부를 입력해 주세요."});
                    document.getElementById("kPrgb").focus();
                    return false;
                }

                //출하전송여부 입력 Check
                if (kChul === "") {
                    
                    this.setState({ openAlert : true, 
                                    severity : "info",
                                    msg : "출하전송 여부를 입력해 주세요."});
                    document.getElementById("kChul").focus();
                    return false;
                }


                const fetchUpdateData = async () => {

                    const query = SERVER_URL + "/popup/redeli_update"; 
                    let param = JSON.stringify({
                        prgb: kPrgb,
                        chul: kChul,
                        dlno: kDlNo,
                    });
                    
                    

                    await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })                        
                        .then((resp) => resp.json())
                        .then((data) => {

                            if (data.success) {
                                this.setState({ openAlert : true, 
                                                severity : "info",
                                                msg : data.count + "건 작업을 수행하였습니다. "});
                            }
                            else if (data.errors) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.errors.message});

                            }
                            else if (data.error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.message});
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        })
                };
                fetchUpdateData();
            }



            /**
             * 로그삭제
             * @param {*} e 
             */
            const onDelete = async (e) => {

                //input check
                let input = await Promise.all ([inputCheck()]);
                if ( !input[0]) return;
                

                //삭제 확인 메세지 

                this.setState({openDlg : true,  
                              dlgTrans : "D", 
                                dlgMsg : "로그 및 내역이 DB에서 완전삭제됩니다.삭제 하시겠습니까 ?" });
            }



            //deleteBlank
            function deleteBlank(argval){

                // if (argval === null || argval === undefined) return "";

                // for(var i=argval.length-1 ;i >= 0; i--){
                    
                //     if(argval.charCodeAt(i) !== 12288 && argval.charCodeAt(i) !== 32 ) //charCodeAt(i)  
                //     return(argval.substring(0,i+1));
                // }
                // return(argval.length>0?argval.substring(0,0):"")
            }



            // input check
            const inputCheck = () => {
                
                //납품서번호 입력 Check
                if (kDlNo === "") {
                    
                    this.setState({ openAlert : true, 
                                    severity : "info",
                                    msg : "납품서 번호를 입력해 주세요."});
                    document.getElementById("kDlNo").focus();
                    return false;
                }           
                return true;
            }
            

            //AlertDialogResult
            const AlertDialogResult = async (e) => {

                //AlertDialog 초기화
                this.setState({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        
                //DialogResult
                if (e.result == "N") return;
        
                //Transaction 구분
                switch (e.Trans) 
                {
                    //등록
                    case "I" :
                        break;
                    //수정
                    case "U" :
                        break;
                    //삭제
                    case "D" :
                        const fetchData = async () => {

                            const query = SERVER_URL + "/popup/redeli_delete"; 
                            let param = JSON.stringify({
                                dlno: kDlNo,
                            });
        
                            await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })                        
                                .then((resp) => resp.json())
                                .then((data) => {
        
                                    if (data.success) {
                                        this.setState({ openAlert : true, 
                                                        severity : "info",
                                                        msg : data.count + "건 작업을 수행하였습니다. "});
                                    }
                                    else if (data.errors) {
                                        this.setState({ openAlert : true, 
                                                        severity : "error",
                                                        msg : data.errors.message});
        
                                    }
                                    else if (data.error) {
                                        this.setState({ openAlert : true, 
                                                        severity : "error",
                                                        msg : data.message});
                                    }
                                })
                                .catch((error) => {
                                    // 에러 응답 처리
                                    if (error) {
                                        this.setState({ openAlert : true, 
                                                        severity : "error",
                                                        msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                                    }
                                });
                        };
                
                        fetchData();

                        break;
                }
            }

        

            //Grid DropDownList Value
            const extractValues = (mappings) => {
                return  Object.keys(mappings);
            }

            //Grid DropDownList Display
            const lookupValue = (mappings, key) => {
                return mappings[key];
            }        

        
            /**
             *Grid Column 정의 
            */
            const columnDefs = [
            ];

        
            return (
                <>
                    {/* Dialog FRAME */}
                    <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

                    {/* Alert FRAME */}
                    <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {this.setState({openAlert:false, severity:"", msg:""})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

                    <button onClick={() => {this.props.onClose()}} style = {{border:"none", float:"right"}}> <img src="/images/button/close_button.png" alt="logout" /></button> 
                   
                    {/* Loading */}
                    <Loading loading={loading} /> 

                    {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
                    <Ribbon
                        newTabUnused={true}                         // newTabUnused : newTab 표출 버튼 true : 사용안함 // 선언안할경우 default = newTab 사용
                        onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                        onSearch={onSearch} onSearchName={"조회"}
                        onUpdate={onUpdate} onUpdateName={"수정"} onUpdateVisible={kDelGubn === ""}
                        onInsert={onInsert} onInsertName={"복구"} onInsertVisible = {kDelGubn === "DEL"}     
                        onDelete={onDelete} onDeleteName={"로그삭제"} onDeleteVisible = {kDelGubn === "DEL"}   
                    >
    
                    <b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>납품내역 복구 및 관리</b>
                    </Ribbon>
        
                    {/* ContentXearch Frame : 검색조건 */}
                    <ContentXearch
                        optionsX={optionsX}
                        onChange={onChange} />
                </>
            );
        }
        
    }

}


export default Redeli;