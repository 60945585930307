/**
 * @Page WrapErpLabelPrintC
 * @Description [ROM4H / ROM4N]납입지시 - ERP_OEM출하관리 - 부품식별표(VMI / PO) :: [OEM 내수] 부품식별표(VMI / PO) 인쇄  ::  C 12매
 **/
import React from 'react';
import CustomDataMatrix from '@/components/atoms/CustomDataMatrix';
import * as Common from '@/utils/Common';
import styled from 'styled-components';

class WrapErpLabelPrintC extends React.Component {
	// constructor 생성자
	constructor(props) {
		super(props);
		this.state = {
			bplx: '0',
			bply: '0',
		};
	}

	componentDidMount() {
		const info_bplx = Common.getToken('bplx') === null ? 0 : 11 + parseInt(Common.getToken('bplx'), 10) * 2; // 로그인한 사용자의 부품식별표 의 x좌표값 받아오기
		const info_bply = Common.getToken('bply') === null ? 0 : 0 + parseInt(Common.getToken('bply'), 10) * 2; // 로그인한 사용자의 부품식별표 의 y좌표값 받아오기
		this.setState({ bplx: info_bplx });
		this.setState({ bply: info_bply });
	}

	// '바코드 = 업체코드(4) + 품번(15) + 수량(5) + LOTNO(3) + 일련번호(4)
	//        tmp(0) '회사구분
	//        tmp(1) '지역
	//        tmp(2) '공장
	//        tmp(3) '차종
	//        tmp(4) '납품장소(하치장)
	//        tmp(5) '품번
	//        tmp(6) '품명
	//        tmp(7) '납품수량
	//        tmp(8) '바코드
	//        tmp(9) '납품일자
	//        tmp(10) '업체명
	//        tmp(11) "" '공정(공백)
	//        tmp(12) '업체코드
	//        tmp(13) "" ' LOT NO
	//        tmp(14) "" 'HPC(공백)
	//        tmp(15) 'CASE(CKD)
	//        tmp(16) "" '발행번호(바코드만들어서 던짐)
	//        tmp(17) "" '용기번호
	//        tmp(18) '부착매수
	//        tmp(19) 'LH/RH
	//        tmp(20) "" '사양
	//        tmp(21)  '발주번호(공백)
	//        tmp(22)  'FROM-ORDER(CKD , OEM은 공정)
	//        tmp(23)  'CKD는 공백, OEM은 HPC
	//        tmp(24)  '용기당수
	//        TMP(25) = '출력1
	//        TMP(26) = 'x좌표1
	//        TMP(27) = 'y좌표1
	//        TMP(28) = '색상1
	//        TMP(29) = 'FONT/S1
	//        TMP(30) = '출력2
	//        TMP(31) = 'x좌표2
	//        TMP(32) = 'y좌표2
	//        TMP(33) = '색상2
	//        TMP(34) = 'FONT/S2
	//        TMP(35) = '한글품명
	//        TMP(36) = 'RHD

	render() {
		// Print Style CSS Setting
		const PrintStyleC = styled.div`
			@media all {
				body {
					background-color: ;
					margin: 0px;
					padding: 0px;
					font-family: 'HY견고딕';
				}
				div {
					background-color: ;
					margin: 0px;
					padding: 0px;
					box-sizing: border-box;
				}
				table {
					border-collapse: collapse;
					border-style: none;
					margin: 0px;
					padding: 0px;
					box-sizing: border-box;
				}
				tr {
					width: 100%;
					margin: 0px;
					padding: 0px;
				}
				td {
					margin: 0px;
					padding: 0px;
					background-color: white;
					font-family: 'HY견고딕';
					border: 0px solid green;
					box-sizing: border-box;
				}

				.HYheadM11 {
					font-family: 'NanumGothic';
					font-weight: 700;
					font-size: 11pt;
				}
				.HYheadM12 {
					font-family: 'HY견고딕';
					font-weight: bold;
					font-size: 12pt;
					word-break: break-all;
				}
				.HYheadM14 {
					font-family: 'HY견고딕';
					font-weight: bold;
					font-size: 14pt;
					word-break: break-all;
				}
				.HYheadM16 {
					font-family: 'HY견고딕';
					font-weight: bold;
					font-size: 16pt;
					word-break: break-all;
				}
				.HYheadM18 {
					font-family: 'HY견고딕';
					font-weight: bold;
					font-size: 18pt;
					word-break: break-all;
				}
				.HYheadM9 {
					font-family: 'HY견고딕';
					font-size: 9pt;
					word-break: break-all;
				}
				.HYheadM24 {
					font-family: 'HY견고딕';
					font-size: 24pt;
					word-break: break-all;
				}

				.table {
					display: table;
					border: 1px solid blue;
				}
				.row {
					// display: table-row;
					display: flex;
					flex-flow: wrap;
				}
				.cell {
					display: table-cell;
					// border-bottom:1px solid blue;
					// border-right:1px solid blue;
					border-width: thin;
					white-space: nowrap;
					z-index: 99;
				}

				.colspan2 {
					flex: 2;
				}
				.colspan3 {
					flex: 3;
				}
				.colspan4 {
					flex: 4;
				}
				.colspan5 {
					flex: 5;
				}
				.colspan6 {
					flex: 6;
				}
				.colspan7 {
					flex: 7;
				}
				.colspan8 {
					flex: 8;
				}
				.colspan9 {
					flex: 9;
				}
				.colspan10 {
					flex: 10;
				}

				.row > .cell:nth-child(1) {
					width: 7.5mm;
				}
				.row > .cell:nth-child(2) {
					width: 1mm;
				}
				.row > .cell:nth-child(3) {
					width: 0.5mm;
				}
				.row > .cell:nth-child(4) {
					width: 3mm;
				}
				.row > .cell:nth-child(5) {
					width: 2mm;
				}
				.row > .cell:nth-child(6) {
					width: 12mm;
				}
				.row > .cell:nth-child(7) {
					width: 11mm;
				}
				.row > .cell:nth-child(8) {
					width: 7mm;
				}
				.row > .cell:nth-child(9) {
					width: 6.5mm;
				}
				.row > .cell:nth-child(10) {
					width: 10mm;
				}
				.row > .cell:nth-child(11) {
					width: 3mm;
				}
				.row > .cell:nth-child(12) {
					width: 13mm;
				}
			}
			@media print {
				@page {
					size: A4 landscape;
					margin-top: ${this.state.bply}mm;
					margin-left: ${this.state.bplx}mm;
					margin-right: 3mm;
					margin-bottom: 4mm;
				}

				.table {
					display: table;
					border: 1px solid pink;
				}
				.row {
					// display: table-row;
					display: flex;
					flex-flow: wrap;
				}
				.cell {
					display: table-cell;
					border-bottom: 1px solid none;
					border-right: 1px solid none;
					border-width: thin;
					white-space: nowrap;
					z-index: 99;
				}

				div.pagebreak {
					break-after: always !important;
					page-break-after: always !important;
					page-break-inside: avoid !important;
				}
			}

			@media screen {
				.table {
					display: table;
					border: 1px solid blue;
				}
				.row {
					// display: table-row;
					display: flex;
					flex-flow: wrap;
				}
				.cell {
					display: table-cell;
					border-bottom: 1px solid blue;
					border-right: 1px solid blue;
					border-width: thin;
					white-space: nowrap;
					z-index: 99;

					//color : blue;
				}
			}
		`;

		const { printData } = this.props;

		// 검색조건 파라메터 가져오기
		const { kStart, chkMetal, kCkdOem, chkNpdt, chkCarc, chkLtno, kCarc, kCarcNm } = this.props.idata;

		const listSize = printData.length; // data length

		let idx = -1; // -1 부터 start
		let str = parseInt(kStart) - 1;

		idx = idx + str; // 시작 위치 (like 배열)

		// 바코드 표출용 - vseq
		let vseq = 0;

		// remain 표출 체크용 - remainChk -- 신규 품번의 vseq 담기
		let remainChk = 0;

		const cardInfo = printData
			? printData.map((data, index) => {
					// 최초 1 open - 품번 별 row  -- START

					if (index === 0) {
						vseq = parseInt(data.SEQ) - 1; // 바코드 표출 vseq용
					}

					remainChk = vseq; // 새 품번의 remain 여부 체크하기

					let npqt = parseInt(data.NPQT); // 납품수량
					let qypc = parseInt(data.QYPC); // 용기당 수
					let stck = parseInt(data.STCK); // 부착면 수

					// 추가 표시 컬러 체크 -- START
					let tmp25 = data.TMP25; // WRCLBL1	이중인쇄1 내용
					let tmp28 = data.TMP28; // WRCCOL1	이중인쇄1 컬러

					let tmp30 = data.TMP30; // WRCLBL2	이중인쇄2 내용
					let tmp33 = data.TMP33; // WRCCOL2	이중인쇄2 컬러

					let color1 = '';
					let color2 = '';

					let dualPrint1 = 'F';
					let dualPrint2 = 'F';

					let dualPrint1css = ''; // dualPrint1 css 추가 표기 view 1
					let dualPrint1cssR = ''; // dualPrint1cssR css 추가 표기 view 1-2

					let dualPrint2css = ''; // dualPrint2 css 추가 표기 view 2

					if (tmp25 !== '') {
						dualPrint1 = 'T';
						if (tmp28 === 'B') {
							color1 = 'blue';
						} else if (tmp28 === 'G') {
							color1 = 'green';
						} else if (tmp28 === 'R') {
							color1 = 'red';
						} else if (tmp28 === 'O') {
							color1 = '#ff6600';
						} else if (tmp28 === 'X') {
							color1 = 'rgba(0, 0, 0, 0.7)';
						}
					}

					if (tmp30 !== '') {
						dualPrint2 = 'T';
						if (tmp33 === 'B') {
							color2 = 'blue';
						} else if (tmp33 === 'G') {
							color2 = 'green';
						} else if (tmp33 === 'R') {
							color2 = 'red';
						} else if (tmp33 === 'O') {
							color2 = '#ff6600';
						} else if (tmp33 === 'X') {
							color2 = 'rgba(0, 0, 0, 0.7)';
						}
					}

					let result = Math.floor(npqt / qypc); // 몫
					let resultIdx = Math.floor(npqt / qypc); // 목 + 나머지의 카운트
					let remain = npqt % qypc; // 나머지

					if (remain > 0) {
						resultIdx = result + 1;
					}

					// 새 프린트용 배열
					const newSearched = [];

					// 증가 체크 resultIdx
					let n = 0;

					Array.from(new Array(resultIdx), (v, i) => {
						// 메인 출력물

						Array.from(new Array(stck), (w, j) => {
							// 부착면수 만큼 선 반복..

							// 시작시 idx 셋팅
							idx = idx + 1;

							if (j === 0) {
								// 부착면수 1일때 고려...
								vseq = vseq + 1; // 새 vseq 마다 + 1 증가
							}
							let iCnt = i + 1;

							const newArray = {}; // new Array 선언

							let jCnt = j + 1;

							// 새 배열의 n 번째 구하기
							if (i === 0 && j === 0) {
								n = 0;
							} else {
								n = n + 1;
							}
							newArray['idx'] = idx;
							newArray['vseq'] = vseq;

							//납품수량
							let newQty = 0;
							newArray['qty'] = qypc;
							newQty = qypc;

							newArray['stck'] = stck;
							newArray['firstpage'] = 'F';
							newArray['endpage'] = 'F';

							newArray['color1'] = color1;
							newArray['color2'] = color2;

							newArray['dualPrint1'] = dualPrint1;
							newArray['dualPrint2'] = dualPrint2;

							// 첫번째 Page 구하기
							if (i === 0 && j === 0 && index === 0) {
								newArray['firstpage'] = 'T';
							}

							if (resultIdx === vseq - remainChk) {
								if (remain > 0) {
									newArray['qty'] = remain;
									newQty = remain;
								}
							}

							// END Page 구하기
							if (listSize === index + 1) {
								if (resultIdx === iCnt) {
									if (stck === jCnt) {
										newArray['endpage'] = 'T';
									}
								}
							}

							//LOTNO(3)
							const lotNo = data.TMP13 === '' || data.TMP13 === 'N' ? ' ' : Common.getLOT(data.TMP13); // LOT NO 날짜 가져오기  data.TMP13

							//일련번호(4)
							const prno = (data.TMP0 === undefined ? '' : data.TMP0) + '' + ('0000' + vseq.toString(16).toUpperCase()).slice(-4);

							// DataMatrix - code128 바코드 값 만들기
							const barcodeValu = data.TMP12 + '' + data.TMP5.padEnd(15) + '' + ('00000' + newQty).slice(-5) + '' + ('   ' + lotNo).slice(-3) + '' + prno;
							// 바코드 = 업체코드(4)   + 품번(15)                       + 수량(5)                           + LOTNO(3)                      + 일련번호(4)

							// DataMatrix Barcode 만들기
							// const dataMatrixBarcode = <CustomDataMatrix val={barcodeValu} height={5} bcid={"code128"} scale={1} />;
							const dataMatrixBarcode = <CustomDataMatrix val={barcodeValu} height={12} bcid={'code128'} scale={0.5} width={'100%'} />;
							newArray['barcode'] = dataMatrixBarcode;

							newArray['lotno'] = lotNo;
							newArray['bno'] = barcodeValu;

							// 자기 위치 셋팅 (출력매수)
							const posi = idx % 12;
							newArray['posi'] = posi;

							let divCss = '';
							let divTmp15 = '';
							let divMetal = '';

							// page별  (출력매수)1장 이상일경우 100% 씩 추가 ... 다음장 노출을 위함.
							let pagebreaky = Math.floor(idx / 12) * 120.5;
							let flag = false;
							if (Math.floor(idx / 12) > 0) {
								pagebreaky = pagebreaky + 0.2;
							}

							//5장째 부터 밀린다.
							let fontSizeCus = !data.TMP15 === false && data.TMP15.toString().replace(/\s/gi, '').length > 4 ? '16px' : '20pt';

							if (posi === 0) {
								divCss = { position: 'absolute', top: pagebreaky + 11.38 + '%', left: '3mm', paddingTop: '0mm', paddingLeft: '3mm', border: '0px solid red' };

								divMetal = { position: 'absolute', top: pagebreaky + 33.5 + '%', left: '76mm', zIndex: '12' }; //  28.2 + 1.58 = 29.78
								divTmp15 = { position: 'absolute', fontFamily: 'HY견고딕', top: pagebreaky + 28 + '%', left: '45mm', fontSize: fontSizeCus, fontWeight: 'bold', zIndex: '15' };

								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 8 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 8) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 8 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint1cssR = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 8 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 8) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 8 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 10 + '%' : parseInt(data.TMP32, 10) + (pagebreaky + 10) - 15 + '%',
									left: parseInt(data.TMP31, 10) + 10 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									whiteSpace: 'nowrap',
									zIndex: '12',
								};
							} else if (posi === 1) {
								divCss = { position: 'absolute', top: pagebreaky + 11.38 + '%', left: '88mm', paddingTop: '0mm', paddingLeft: '3mm', border: '0px solid red' };

								divMetal = { position: 'absolute', top: pagebreaky + 33.5 + '%', left: '160mm', zIndex: '12' };
								divTmp15 = { position: 'absolute', fontFamily: 'HY견고딕', top: pagebreaky + 28 + '%', left: '126mm', fontSize: fontSizeCus, fontWeight: 'bold', zIndex: '15' };

								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 8 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 8) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 85 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint1cssR = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 8 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 8) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 85 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 10 + '%' : parseInt(data.TMP32, 10) + (pagebreaky + 10) - 15 + '%',
									left: parseInt(data.TMP31, 10) + 87 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									whiteSpace: 'nowrap',
									zIndex: '12',
								};
							} else if (posi === 2) {
								divCss = { position: 'absolute', top: pagebreaky + 11.38 + '%', left: '173mm', paddingTop: '0mm', paddingLeft: '2.5mm', border: '0px solid red' };

								divMetal = { position: 'absolute', top: pagebreaky + 33.5 + '%', left: '245mm', zIndex: '12' };
								divTmp15 = { position: 'absolute', fontFamily: 'HY견고딕', top: pagebreaky + 28 + '%', left: '210mm', fontSize: fontSizeCus, fontWeight: 'bold', zIndex: '15' };

								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 8 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 8) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 162 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint1cssR = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 8 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 8) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 162 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 10 + '%' : parseInt(data.TMP32, 10) + (pagebreaky + 10) - 15 + '%',
									left: parseInt(data.TMP31, 10) + 164 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									whiteSpace: 'nowrap',
									zIndex: '12',
								};
							} else if (posi === 3) {
								divCss = { position: 'absolute', top: pagebreaky + 11.38 + '%', left: '258mm', paddingTop: '0mm', paddingLeft: '2mm', border: '0px solid red' };

								divMetal = { position: 'absolute', top: pagebreaky + 33.5 + '%', left: '330mm', zIndex: '12' };
								divTmp15 = { position: 'absolute', fontFamily: 'HY견고딕', top: pagebreaky + 28 + '%', left: '295mm', fontSize: fontSizeCus, fontWeight: 'bold', zIndex: '15' };

								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 8 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 8) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 239 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint1cssR = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 8 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 8) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 239 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 10 + '%' : parseInt(data.TMP32, 10) + (pagebreaky + 10) - 15 + '%',
									left: parseInt(data.TMP31, 10) + 241 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									whiteSpace: 'nowrap',
									zIndex: '12',
								};
							} else if (posi === 4) {
								divCss = { position: 'absolute', top: pagebreaky + 46.5 + '%', left: '3mm', paddingTop: '0mm', paddingLeft: '3mm', border: '0px solid red' }; // 47.14 + 8.04  = 55.18

								divMetal = { position: 'absolute', top: pagebreaky + 68.5 + '%', left: '76mm', zIndex: '12' };
								divTmp15 = { position: 'absolute', fontFamily: 'HY견고딕', top: pagebreaky + 56.8 + '%', left: '45mm', fontSize: fontSizeCus, fontWeight: 'bold', zIndex: '15' };

								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 37 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 37) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 8 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint1cssR = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 37 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 37) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 8 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 39 + '%' : parseInt(data.TMP32, 10) + (pagebreaky + 39) - 15 + '%',
									left: parseInt(data.TMP31, 10) + 10 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									whiteSpace: 'nowrap',
									zIndex: '12',
								};
							} else if (posi === 5) {
								divCss = { position: 'absolute', top: pagebreaky + 46.5 + '%', left: '88mm', paddingTop: '0mm', paddingLeft: '3mm', border: '0px solid red' };

								divMetal = { position: 'absolute', top: pagebreaky + 68.5 + '%', left: '160mm', zIndex: '12' };
								divTmp15 = { position: 'absolute', fontFamily: 'HY견고딕', top: pagebreaky + 56.8 + '%', left: '126mm', fontSize: fontSizeCus, fontWeight: 'bold', zIndex: '15' };

								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 37 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 37) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 85 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint1cssR = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 37 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 37) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 85 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 39 + '%' : parseInt(data.TMP32, 10) + (pagebreaky + 39) - 15 + '%',
									left: parseInt(data.TMP31, 10) + 87 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									whiteSpace: 'nowrap',
									zIndex: '12',
								};
							} else if (posi === 6) {
								divCss = { position: 'absolute', top: pagebreaky + 46.5 + '%', left: '173mm', paddingTop: '0mm', paddingLeft: '2.5mm', border: '0px solid red' };

								divMetal = { position: 'absolute', top: pagebreaky + 68.5 + '%', left: '245mm', zIndex: '12' };
								divTmp15 = { position: 'absolute', fontFamily: 'HY견고딕', top: pagebreaky + 56.8 + '%', left: '210mm', fontSize: fontSizeCus, fontWeight: 'bold', zIndex: '15' };

								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 37 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 37) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 162 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint1cssR = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 37 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 37) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 162 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 39 + '%' : parseInt(data.TMP32, 10) + (pagebreaky + 39) - 15 + '%',
									left: parseInt(data.TMP31, 10) + 164 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									whiteSpace: 'nowrap',
									zIndex: '12',
								};
							} else if (posi === 7) {
								divCss = { position: 'absolute', top: pagebreaky + 46.5 + '%', left: '258mm', paddingTop: '0mm', paddingLeft: '2mm', border: '0px solid red' };

								divMetal = { position: 'absolute', top: pagebreaky + 68.5 + '%', left: '330mm', zIndex: '12' };
								divTmp15 = { position: 'absolute', fontFamily: 'HY견고딕', top: pagebreaky + 56.8 + '%', left: '295mm', fontSize: fontSizeCus, fontWeight: 'bold', zIndex: '15' };

								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 37 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 37) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 239 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint1cssR = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 37 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 37) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 239 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 39 + '%' : parseInt(data.TMP32, 10) + (pagebreaky + 39) - 15 + '%',
									left: parseInt(data.TMP31, 10) + 241 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									whiteSpace: 'nowrap',
									zIndex: '12',
								};
							} else if (posi === 8) {
								divCss = { position: 'absolute', top: pagebreaky + 82 + '%', left: '3mm', paddingTop: '0mm', paddingLeft: '3mm', border: '0px solid red' }; // 68.2 + 14  = 82.2

								divMetal = { position: 'absolute', top: pagebreaky + 104 + '%', left: '76mm', zIndex: '12' };
								divTmp15 = { position: 'absolute', fontFamily: 'HY견고딕', top: pagebreaky + 85.8 + '%', left: '45mm', fontSize: fontSizeCus, fontWeight: 'bold', zIndex: '15' };

								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 66 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 66) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 8 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint1cssR = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 66 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 66) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 8 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 68 + '%' : parseInt(data.TMP32, 10) + (pagebreaky + 66) - 15 + '%',
									left: parseInt(data.TMP31, 10) + 10 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									whiteSpace: 'nowrap',
									zIndex: '12',
								};
							} else if (posi === 9) {
								divCss = { position: 'absolute', top: pagebreaky + 82 + '%', left: '88mm', paddingTop: '0mm', paddingLeft: '3mm', border: '0px solid red' };

								divMetal = { position: 'absolute', top: pagebreaky + 104 + '%', left: '160mm', zIndex: '12' };
								divTmp15 = { position: 'absolute', fontFamily: 'HY견고딕', top: pagebreaky + 85.8 + '%', left: '126mm', fontSize: fontSizeCus, fontWeight: 'bold', zIndex: '15' };

								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 66 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 66) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 85 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint1cssR = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 66 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 66) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 85 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 68 + '%' : parseInt(data.TMP32, 10) + (pagebreaky + 66) - 15 + '%',
									left: parseInt(data.TMP31, 10) + 87 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									whiteSpace: 'nowrap',
									zIndex: '12',
								};
							} else if (posi === 10) {
								divCss = { position: 'absolute', top: pagebreaky + 82 + '%', left: '173mm', paddingTop: '0mm', paddingLeft: '2.5mm', border: '0px solid red' };

								divMetal = { position: 'absolute', top: pagebreaky + 104 + '%', left: '245mm', zIndex: '12' };
								divTmp15 = { position: 'absolute', fontFamily: 'HY견고딕', top: pagebreaky + 85.8 + '%', left: '210mm', fontSize: fontSizeCus, fontWeight: 'bold', zIndex: '15' };

								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 66 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 66) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 162 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint1cssR = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 66 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 66) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 162 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 68 + '%' : parseInt(data.TMP32, 10) + (pagebreaky + 66) - 15 + '%',
									left: parseInt(data.TMP31, 10) + 164 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									whiteSpace: 'nowrap',
									zIndex: '12',
								};
							} else if (posi === 11) {
								divCss = { position: 'absolute', top: pagebreaky + 82 + '%', left: '258mm', paddingTop: '0mm', paddingLeft: '2mm', border: '0px solid red' };

								divMetal = { position: 'absolute', top: pagebreaky + 104 + '%', left: '330mm', zIndex: '12' };
								divTmp15 = { position: 'absolute', fontFamily: 'HY견고딕', top: pagebreaky + 85.8 + '%', left: '295mm', fontSize: fontSizeCus, fontWeight: 'bold', zIndex: '15' };

								dualPrint1css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 66 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 66) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 239 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint1cssR = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP27, 10) === 0 ? pagebreaky + 66 + '%' : parseInt(data.TMP27, 10) + (pagebreaky + 66) - 15 + '%',
									left: parseInt(data.TMP26, 10) + 239 + 'mm',
									color: color1,
									fontSize: data.TMP29 + 'pt',
									fontWeight: 'bold',
									width: '293px',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									zIndex: '13',
								};
								dualPrint2css = {
									position: 'absolute',
									fontFamily: 'HY견고딕',
									top: parseInt(data.TMP32, 10) === 0 ? pagebreaky + 68 + '%' : parseInt(data.TMP32, 10) + (pagebreaky + 66) - 15 + '%',
									left: parseInt(data.TMP31, 10) + 241 + 'mm',
									color: color2,
									fontSize: data.TMP34 + 'pt',
									fontWeight: 'bold',
									whiteSpace: 'nowrap',
									zIndex: '12',
								};
							}
							newArray['divCss'] = divCss;
							newArray['divTmp15'] = divTmp15;
							newArray['divMetal'] = divMetal;

							newArray['dualPrint1css'] = dualPrint1css;
							newArray['dualPrint1cssR'] = dualPrint1cssR;
							newArray['dualPrint2css'] = dualPrint2css;

							// 위에서 계산 해서 본인 위치에 셋팅 !
							// newSearched 새배열에 값 몽땅 넣기
							newSearched[n] = newArray;
						});
					});

					return (
						<>
							{newSearched.map(
								(sData, dataIdx) => (
									<form key={'WrapErpLabelPrintC' + dataIdx} style={{ border: '0px solid blue' }}>
										{/* div로 테이블 그리기 */}
										<div className="table" style={{ ...sData.divCss, width: '85mm', height: '61mm', overflow: 'hidden', zIndex: '200', border: '0px solid purple' }}>
											{' '}
											{/* 패치 전 border 삭제 , border:"1px solid red" */}
											<div className="row" style={{ height: '2mm' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell"></div>
												<div className="cell colspan2"></div>
											</div>
											<div className="row" style={{ height: '3mm' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12" style={{ fontSize: '6pt' }}>
													{data.TMP1}
												</div>
												{/* 지역 */}
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan2" style={{ fontSize: '6pt', fontFamily: 'NanumGothic', fontWeight: 'bold' }}>
													{data.TMP0 === 'MPMC' ? '현대파워텍(주)용ⓡ' : '현대자동차(주)/기아(주)ⓡ'}
												</div>
												<div className="cell"></div>

												<div className="cell"></div>
												<div className="cell colspan2"></div>
											</div>
											<div className="row" style={{ height: '5mm' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM16" style={{ fontSize: '11pt' }}>
													{data.TMP2}
												</div>
												{/* 공장 */}

												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12" style={{ fontSize: '6pt', paddingTop: '4px' }}>
													{chkCarc && chkCarc === 'Y' ? kCarcNm : ''}
												</div>
												{/* 차종 chkCarc kCarc kCarcNm data.TMP3 - ERP의 경우 차종명을 가져오도록 수정 */}
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan2" style={{ fontSize: '8pt', paddingTop: '3px', paddingLeft: '0mm' }}>
													{data.TMP4}
												</div>
												{/* 납품장소 */}
											</div>
											<div className="row" style={{ height: '6.5mm' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM18" style={{ fontSize: data.TMP5.trim().length > 12 ? '14pt' : '14pt', paddingTop: '3px' }}>
													{data.TMP0 === 'MHMC' ? (data.TMP5.trim().length > 12 ? data.TMP5.substr(0, 11) : data.TMP5.substr(0, 12)) : data.TMP5.substr(0, 5) + '-' + data.TMP5.substr(5)}
													{/* 품번 */}
												</div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell HYheadM16" style={{ fontSize: '14pt', paddingTop: '3px', paddingLeft: '0mm' }}>
													{sData.qty}
												</div>
												{/* 수량 */}
												<div className="cell colspan2"></div>
											</div>
											<div className="row" style={{ height: '2mm' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell"></div>
												<div className="cell colspan2"></div>
											</div>
											<div className="row" style={{ height: '5mm' }}>
												<div className="cell"></div>

												<div className="cell HYheadM18 colspan6" style={{ fontSize: '11pt', paddingTop: '5px', paddingLeft: '5px' }}>
													{data.TMP6}
												</div>
												{/* 품명 */}

												<div
													className="cell HYheadM18 colspan2"
													style={{ lineHeight: '6mm', fontSize: '18pt', color: data.TMP19 === '좌' || data.TMP19 === 'L' ? 'red' : 'blue', textAlign: 'right', zIndex: '10' }}
												>
													{data.TMP19}
													{/* LH/RH */}
												</div>
												<div className="cell"></div>
											</div>
											<div className="row" style={{ height: '5mm' }}>
												<div className="cell HYheadM18 colspan3" style={{ fontSize: '10pt' }}>
													{data.TMP35 !== '' && Common.getTextLength(data.TMP35) > 7 ? data.TMP35.substr(0, 7) : ''}
												</div>
												{/* 한글품명 */}

												<div className="cell HYheadM18 colspan5" style={{ fontSize: '9pt', paddingTop: '2px', whiteSpace: 'nowrap', textAlign: 'center' }}>
													{data.TMP2 === 'HV31' || data.TMP2 === 'HE31' || data.TMP2 === 'HM31' || data.TMP2 === 'HK31' ? '적입중량:' : ''}
													{data.TMP2 === 'HV31' || data.TMP2 === 'HE31' || data.TMP2 === 'HM31' || data.TMP2 === 'HK31'
														? data.WTUN === '' || data.WTUN === '0' || data.WTUN === undefined
															? '　　　' + ' kg'
															: (sData.qty * data.WTUN).toFixed(3) + 'kg'
														: ''}
												</div>
												{/* 전주공장일땐 적입중량 표시 */}
												<div className="cell HYheadM18 colspan4" style={{ fontSize: '10pt', paddingTop: '4px', whiteSpace: 'nowrap', textAlign: 'center' }}>
													{data.TMP20 !== undefined ? ('          ' + data.TMP20).slice(-10) : ''}
												</div>
												{/* 사양 */}
											</div>
											<div className="row" style={{ height: '4mm' }}>
												<div className="cell HYheadM18 colspan4" style={{ fontSize: '12pt' }}>
													{data.TMP35 !== '' && Common.getTextLength(data.TMP35) > 7 ? data.TMP35.substr(11) : ''}
												</div>
												{/* 한글품명 */}
												<div className="cell colspan5"></div>
											</div>
											<div className="row" style={{ height: '8mm' }}>
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan5" style={{ zIndex: '15' }}>
													{sData.barcode}
												</div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
											</div>
											<div className="row" style={{ height: '1mm' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell colspan2"></div>
											</div>
											<div className="row" style={{ height: '3mm' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12" style={{ fontSize: '6pt' }}>
													{chkNpdt && chkNpdt === 'Y' ? data.TMP9 : ''}
												</div>
												{/* 납품일자 chkNpdt */}
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12" style={{ fontSize: '6pt' }}>
													{data.TMP12}
												</div>
												{/* 업체코드 */}
												{/* 업체명 */}
												<div className="cell HYheadM12" style={{ fontSize: '6pt' }}>
													{data.TMP10}
												</div>
												{/* 업체명 */}
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell colspan2"></div>
											</div>
											<div className="row" style={{ height: '4mm' }}>
												<div className="cell HYheadM12" style={{ fontSize: '6pt', verticalAlign: 'middle', fontWeight: 'normal', paddingTop: '1.5mm', paddingBottom: '1mm' }}>
													{kCkdOem === 'CKD'
														? 'Order'
														: data.TMP11 === '' || data.TMP2 === 'S1' || data.TMP2 === 'S2' || data.TMP2 === 'S3' || data.TMP2 === 'SP' || data.TMP2 === '6'
														? data.TMP0 === 'MAMC'
															? '보관장'
															: data.TMP0 === 'MKMC'
															? '공정번호'
															: '공정No'
														: '공정No'}
												</div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan2" style={{ fontSize: '9pt', verticalAlign: 'middle', paddingTop: '1.5mm' }}>
													{data.TMP22}
												</div>
												{/* 공정NO */}
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan2" style={{ fontSize: '25pt' }}>
													{data.TMP36 === 'Y' ? 'RHD' : ''}
												</div>
												{/* RHD */}
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan3" style={{ fontSize: '10pt' }}>
													{data.TMP21}
												</div>
												{/* 발주번호 */}
												<div className="cell"></div>
											</div>
											<div className="row" style={{ height: '5mm' }}>
												<div className="cell HYheadM12" style={{ fontSize: '6pt', fontWeight: 'normal', paddingTop: '2mm', paddingBottom: '1mm', paddingLeft: '1mm' }}>
													{kCkdOem === 'CKD' ? '' : 'HPC'}
												</div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12" style={{ fontSize: '9pt', paddingTop: '2mm' }}>
													{data.TMP23}
												</div>
												{/* HPC */}
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12" style={{ fontSize: '9pt', paddingTop: '2mm' }}>
													{chkLtno && chkLtno === 'Y' ? sData.lotno : ''}
												</div>
												{/* getLOT() chkLtno  */}
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell colspan2"></div>
											</div>
											<div className="row" style={{ height: '1mm' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell"></div>
												<div className="cell colspan2"></div>
											</div>
											<div className="row" style={{ height: '3.5mm' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM9 colspan5" style={{ fontSize: '5pt', fontWeight: 'bold', paddingTop: '1mm', paddingLeft: '1mm' }}>
													{sData.bno}
												</div>
												{/* bno */}
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12" style={{ fontSize: '6pt', paddingTop: '1mm' }}>
													{data.TMP17}
												</div>
												{/* 용기 Mo */}

												<div className="cell colspan2"></div>
											</div>
										</div>

										{/* 중금속 확인필 */}
										{chkMetal === 'Y' ? (
											<div style={{ ...sData.divMetal }}>
												<img src="/images/heavy_metal_confirm.gif" style={{ width: '9mm' }} alt="heavy_metal_confirm" />
											</div>
										) : (
											''
										)}

										{/* 조립코드 추가표출 data.TMP15 , fontSize:"20pt" BOM */}
										{sData.TMP15 !== '' ? <div style={{ ...sData.divTmp15 }}>{data.TMP15}</div> : ''}

										{/* 이중출력부분 */}
										{/* XXXXXX */}
										{sData.dualPrint1 === 'T' ? (
											sData.posi !== 3 && sData.posi !== 7 && sData.posi !== 11 ? (
												<div style={{ ...sData.dualPrint1css }}>{data.TMP25}</div>
											) : (
												<div style={{ ...sData.dualPrint1cssR }}>{data.TMP25}</div>
											)
										) : (
											''
										)}

										{sData.dualPrint2 === 'T' ? sData.posi !== 3 && sData.posi !== 7 && sData.posi !== 11 ? <div style={{ ...sData.dualPrint2css }}>{data.TMP30}</div> : '' : ''}
										{/* XXXXXX */}

										{/* 이중출력부분 - 품명 이중출력 위에 추가표출  */}
										{/* 품명 data.TMP6 */}
										{sData.TMP6 !== '' ? '' : ''}

										{/* BARCODE DataMatrix - code128 바코드 추가 표출  */}
										{/* 바코드 sData.barcode */}
										{sData.TMP6 !== '' ? '' : ''}

										{/* 업체명 업체코드 추가 표출  */}
										{/* 업체명 업체코드 data.TMP12+" "+data.TMP10 */}
										{sData.TMP6 !== '' ? '' : ''}

										{sData.posi === 11 && sData.endpage === 'F' ? (
											<>
												<div
													key={'pagebreak' + sData.idx}
													style={{ background: 'red', breakAfter: 'page', pageBreakAfter: 'always', pageBreakInside: 'avoid', border: '2px solid white' }}
												></div>
											</>
										) : (
											''
										)}
									</form>
								),
								'',
							)}
						</>
					);
			  })
			: '';

		return <PrintStyleC key={'WrapErpLabelPrintC'}>{cardInfo}</PrintStyleC>;
	}
}
export default WrapErpLabelPrintC;
