//시스템관리 - 시스템관리
import { Route, Switch } from 'react-router-dom';
import RSY31 from '@/pages/C02/RSY31';  //프로그램 등록화면
import RSY32 from '@/pages/C02/RSY32';  //사용현황
import RSY40 from '@/pages/C02/RSY40';  //납품서 이력현황
import RSY41 from '@/pages/C02/RSY41';  //코드관리
import RSY42 from '@/pages/C02/RSY42';  //출하전송 현황
import RSY43 from '@/pages/C02/RSY43';  //상단공지
import RSY44 from '@/pages/C02/RSY44';  //팝업관리


function C02() {
    return ( 
        <Switch>
            <Route path='/C02/RSY31' component={RSY31} />
            <Route path='/C02/RSY32' component={RSY32} />
            <Route path='/C02/RSY40' component={RSY40} />
            <Route path='/C02/RSY41' component={RSY41} />
            <Route path='/C02/RSY42' component={RSY42} />
            <Route path='/C02/RSY43' component={RSY43} />
            <Route path='/C02/RSY44' component={RSY44} />    
        </Switch>
    );
}

export default C02;
