import React, { Component } from 'react';
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
import * as GridUtil from '@/components/grid/agGrid';
import CommonUtil from '@/utils/CommonUtil'; 
import { stringToArray } from 'ag-grid-community';
 
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
const Utils = new CommonUtil();


class T2Code extends Component {

    constructor(props)  {
        super(props);
        this.grdT2CodeMain = React.createRef();

        this.state = {
            //Common
            token:Common.getToken(),
            userId :Common.getToken("id"),
            userName : Common.getToken("name"),
            tVnt1 : Common.getToken("vand"),
            tComp : Common.getToken("comp"),
    
            //조회조건
            kWrcVand : "",          
            kVnt2 : "",//t2업체
    
            //Alert 창 ( Alert 창 OPEN,  Alert 창 구분, Alert 창 메세지)
            openAlert : false, severity : "success", msg : "",
    
            //Dialog 창 (Dialog 창 OPEN, Dialog Transaction(I,U,D),  Dialog 메세지)
            openDlg : false, dlgTrans : "", dlgMsg : "", 
    
            //Common 코드 데이터
            compList : {},
    
            //Api (조회, 등록, 수정, 삭제)
            searchApi : false, insertApi : false, updateApi : false, deleteApi : false,

            loadingFlag : false
        }
    }

    
    /**
     * WillMount
     */
    async componentWillMount() {

        //조회조건 Setting
        this.setState({kWrcVand: (this.state.tVnt1 == "M000" ? "M000" : this.state.tVnt1)}); 

        //공통코도 조회 (회사구분)
        let commonCode = await Promise.all([this.onSearchComp(`{"pgmid":"CODE", "vand":"****", "gocd" : "COMP"}`), ]);

        //회사구분
        this.setState({compList: commonCode[0], loadingFlag : true}); 
    }


    /**
     * DidMount
     */
     componentDidMount() {


        let para = `{"kGocd":"MODL"}`;
        let param = encodeURIComponent(para);

        const query = SERVER_URL + "/popup/t2code_search?query=" + param;

        const fetchSearchDataStart = async (query) => {
        let inputData = this.state; 
            await fetch(query,  { headers: { Authorization: this.state.token  } })
                .then((resp) => resp.json())
                .then((data) => {

                    if (data.success) {
                        
                        this.setState({ searchApi : query})
                    }
                    if (data.error) {
                        this.setState({ openAlert : true, 
                                        severity : "error",
                                        msg : "조회 실패"});
                    }
                })
                .catch((error) => {
                    // 에러 응답 처리
                    if (error) {
                        this.setState({ openAlert : true, 
                                        severity : "error",
                                        msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                    }
                });
        };
        fetchSearchDataStart(query);
    }


    /**
     * 공통코드 조회
    */
     onSearchCommon = (para, name) => {
    }


    /**
     * 회사구분 코드 조회
    */
      onSearchComp = async (para) => {

        let param = encodeURI(para);
        let elements = {}; // 객체 생성

        const query = SERVER_URL + "/comcd/search1?query=" + param; 

        await fetch(query, { headers: { Authorization: this.state.token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) { 
                    data.data.forEach(function (element) {
                        elements[element.comCode] = element.text;
                    }, this)
                }
            })
        return elements;
    }



    render() {

        if (this.state.loadingFlag === false) {
            return null
        }
        else {
        
            //State
            const {token, userId, userName, tVnt1, tComp, kWrcVand,  openAlert, severity, msg,
                   openDlg, dlgTrans, dlgMsg, compList, searchApi, insertApi, updateApi, deleteApi } = this.state;


            //Grid Clear Api               
            const onClearApi = async (e) => {
                this.setState({ searchApi : false, insertApi : false, updateApi : false, deleteApi : false})
            }


            /**
             * Control 변경 Event
             * @param {*} e 
             */
            const onChange = async (e) => {
                const { name, value } = e.target;

                //  let newSearchs = idata;
                //  newSearchs[name] = value
                //  setData({ ...newSearchs });
                //  //조회 Call    
                //  onSearch(); 
            }

        
            /**
             * Grid Event 
             * @param {*} e 
            */
            const onCellValueChanged = async (e) => {
            }

            const onRowClicked = async (e) => {
            }


            /**
             * 검색조건
            */
            const optionsX = {
                0: [
                    //업체 
                    {
                        "formatter": "input", "name": "kVnt2", "labelName": "T2 코드", "type": "text", "desc": "",
                        "align": "left", 
                        "labelType": "none" 
                    }, 
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                ]
            }

        
        
            /**
             * 조회
             * @param {*} e 
             */
            const onSearch = async (e) => { 
                let para = `{"kGocd":"MODL"}`;
                let param = encodeURI(para);

                const query = SERVER_URL + "/popup/t2code_search?query=" + param;
                this.setState({ searchApi : query})
            }



            /**
             * 신규 Row추가
             * @param {*} e 
             */
            const onAddRow = async (e) => {
                //업체코드 입력 Check
                if (kWrcVand === "") {
                    
                    this.setState({ openAlert : true, 
                                    severity : "info",
                                    msg : "업체 코드를 입력해 주세요."});
                    document.getElementById("kWrcVand").focus();
                    return;
                }

                let newItems = { vand: kWrcVand, gocd : "MODL"};
                this.grdT2CodeMain.current.addRow(newItems, 0);
            }



            /**
             * 입력
             * @param {*} e 
             */
            const onInsert = async (e) => {

                //input check
                let input = await Promise.all ([inputCheck('I')]);
                if ( !input[0]) return;
            //    let selectedData = grdT2CodeMain.current.getSelectedNodes();

            //     //  // 선택 값 체크
            //     if (selectedData.length === 0) {
            //         //Alert 창 OPEN
            //         setMessage({ openAlert : true, 
            //                      severity : "info",
            //                      msg : "입력할 자료가 없습니다."});
            //         return false;
            //     }
               
                
                const query = SERVER_URL + "/popup/t2code_insert";
                this.setState({ insertApi : query})

            }



            /**
             * 수정 
             * @param {*} e 
             * @returns 
             */
            const onUpdate = async (e) => {

                //input check
                let input = await Promise.all ([inputCheck('U')]);
                if ( !input[0]) return;
            
                const query = SERVER_URL + "/popup/t2code_update";
                this.setState({ updateApi : query}) // Update API
            }



            /**
             * 삭제
             * @param {*} e 
             */
            const onDelete = async (e) => {
                
                //input check
                let input = await Promise.all ([inputCheck('D')]);
                if ( !input[0]) return;

                //삭제 확인 메세지 
                this.setState({openDlg : true,  
                               dlgTrans : "D", 
                               dlgMsg : "선택한 데이터를 삭제하시겠습니까?" });
            }



            //deleteBlank
            function deleteBlank(argval){

                if (argval === null || argval === undefined) return "";

                for(var i=argval.length-1 ;i >= 0; i--){
                    
                    if(argval.charCodeAt(i) !== 12288 && argval.charCodeAt(i) !== 32 ) //charCodeAt(i)  
                    return(argval.substring(0,i+1));
                }
                return(argval.length>0?argval.substring(0,0):"")
            }



            // input check
            const inputCheck = (gbn) => { 

                let inputFlag = true;

                //select nodes
                let selectedData = this.grdT2CodeMain.current.getSelectedNodes();

                // 선택 값 체크
                if (selectedData.length === 0) {
                    //Alert 창 OPEN
                    this.setState({ openAlert : true, 
                                    severity : "info",
                                    msg : "선택한 데이터가 없습니다."});
                    return false;
                }


                //필수입력항목 Check
                selectedData.forEach((node) => {

                    //t2업체
                    let cod1 = deleteBlank(node.data.vdt2); 
                    if ( cod1 === "") {
                        this.setState({ openAlert : true, 
                                        severity : "info",
                                        msg : "T2업체를 입력하여 주십시오."});
                        inputFlag = false                                 
                        this.grdT2CodeMain.current.SetFocus(node.rowIndex, "cod1");                             
                        return;
                    }

                    //납품구분
                    let cod2 = deleteBlank(node.data.dlgb); 
                    let arg1 = "1|2|3|4|5|6|7|8|9|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z|";
			        let arg2 = "V|W|";
                    let pt1 = arg1.indexOf(cod2.substr(1,2)+"|");
                    let pt2 = arg2.indexOf(cod2.substr(0,1) + "|");
                    

                    if ( cod2 === "") {
                        this.setState({ openAlert : true, 
                                        severity : "info",
                                        msg : "납품구분을 입력하여 주십시오."});
                        inputFlag = false                                 
                        this.grdT2CodeMain.current.SetFocus(node.rowIndex, "cod2");                             
                        return;
                    }
                    if ( cod2.length != 2) {
                        this.setState({ openAlert : true, 
                                        severity : "info",
                                        msg : "납품구분을 2자리로  입력하세요."});
                        inputFlag = false                                 
                        this.grdT2CodeMain.current.SetFocus(node.rowIndex, "cod2");                             
                        return;
                    }
                    if ( pt2<0 ) {
                        this.setState({ openAlert : true, 
                                        severity : "info",
                                        msg : "납품구분의 첫번째 자리 입력은 'V' or 'W' 입니다."});
                        inputFlag = false                                 
                        this.grdT2CodeMain.current.SetFocus(node.rowIndex, "cod2");                             
                        return;
                    }
                    if( pt1<0 ){
                        this.setState({ openAlert : true, 
                            severity : "info",
                            msg : "납품구분의 두번째 자리 입력은 [1..9] or [A..Z] 입니다."});
                        inputFlag = false                                 
                        this.grdT2CodeMain.current.SetFocus(node.rowIndex, "cod2");                             
                        return;
                    }
                });
                
                return inputFlag;
                
            }
            

            //AlertDialogResult
            const AlertDialogResult = async (e) => {

                //AlertDialog 초기화
                this.setState({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        
                //DialogResult
                if (e.result == "N") return;
        
                //Transaction 구분
                switch (e.Trans) 
                {
                    //등록
                    case "I" :
                        break;
                    //수정
                    case "U" :
                        break;
                    //삭제
                    case "D" :
                        const query = SERVER_URL + "/popup/t2code_delete";
                        this.setState({ deleteApi : query}) // Delete API
                        break;
                }
            }

        

            //Grid DropDownList Value
            const extractValues = (mappings) => {
                return  Object.keys(mappings);
            }

            //Grid DropDownList Display
            const lookupValue = (mappings, key) => {
                return mappings[key];
            }        

        
            /**
             *Grid Column 정의 
            */
            const columnDefs = [
                { headerName: "", field: "ord", sortable: true, filter: true, flex: 1, minWidth: 0, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true },  
                { headerName: "T2 업체", field: "vdt2", sortable: true, filter: true, flex: 1, minWidth: 110, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },  
                { headerName: "T2 업체명", field: "vdnm", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },  
                { headerName: "납품구분", field: "dlgb", sortable: true, filter: true, flex: 1, minWidth: 80, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false }, 
                { headerName: "확인", field: "cnfm", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },  
                { headerName: "DO", field: "ord0", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, cellRendererFramework: GridUtil.AgGridCheckbox, hide: false },  
                { headerName: "D1", field: "ord1", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, cellRendererFramework: GridUtil.AgGridCheckbox, hide: false },  
                { headerName: "D2", field: "ord2", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, cellRendererFramework: GridUtil.AgGridCheckbox, hide: false },  
                { headerName: "D6", field: "ord6", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, cellRendererFramework: GridUtil.AgGridCheckbox, hide: true },  
                { headerName: "D8", field: "ord8", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, cellRendererFramework: GridUtil.AgGridCheckbox, hide: true },  
                { headerName: "DH", field: "ordh", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, cellRendererFramework: GridUtil.AgGridCheckbox, hide: false },  
                { headerName: "긴급", field: "orgg", sortable: true, filter: true, flex: 1, minWidth: 70, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, cellRendererFramework: GridUtil.AgGridCheckbox, hide: false }, 
            ];


        
            return (
                <>
                    {/* Dialog FRAME */}
                    <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

                    {/* Alert FRAME */}
                    <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {this.setState({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
                    <button onClick={() => {this.props.onClose()}} style = {{border:"none", float:"right", paddingRight:"0px"}}> <img src="/images/button/close_button.png" alt="close" /></button> 

                    {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
                    <Ribbon
                        newTabUnused={true}                         // newTabUnused : newTab 표출 버튼 true : 사용안함 // 선언안할경우 default = newTab 사용
                        onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                        onSearch={onSearch} onSearchName={null}     //조회
                        onAddRow={onAddRow} onAddRowName={"신규"}   //Row 추가
                        onInsert={onInsert} onInsertName={null}     //등록
                        onUpdate={onUpdate} onUpdateName={null} //수정
                        onDelete={onDelete} onDeleteName={null} //삭제
                    >
                    <b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>T2업체등록</b>
                    </Ribbon>
        
        
                    {/* ContentXearch Frame : 검색조건 */}
                    <ContentXearch
                        optionsX={optionsX}
                        onChange={onChange} />
        
                    <div className={"grid_type01"} style={{ marginTop: "10px"}} > {/* 상단 FRAME */}
                        <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                            <div className="grid_cont_box" style={{ height: "380px", width : "100%" }}>
                                <Grid
                                    ref={this.grdT2CodeMain}
                                    gridId={"T2CDOE"}                  //  (필수) Grid Id 정의
                                    columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    searchApi={searchApi}               // 조회
                                    insertApi={insertApi}               // 등록
                                    onClearApi={onClearApi}
                                    insertRefresh={onSearch}            // 등록이후 
                                    updateApi={updateApi}               // 수정
                                    updateRefresh={onSearch}            // 수정이후 
                                    deleteApi={deleteApi}               // 삭제
                                    deleteRefresh={onSearch}            // 삭제이후 
                                    
                                    //onClearApi={()=> {setSearchApi(false); setUpdateApi(false);}}
                                    onCellValueChangedEvent={onCellValueChanged}
                                    onRowClickEvent={onRowClicked}
                                />
                            </div>
                                <div style={{border:"1",left:"5px",color:"red",marginBottom:"4px"}}>※ 납품구분 입력 방식이 바뀌었습니다. (기존 1자리 {'->'} 2자리)</div>
                                <div style={{border:"1",color:"red",marginBottom:"4px"}}>입력방식{') '}첫번째자리 : 'V' or 'W'</div>
                                <div style={{border:"1",color:"red",marginLeft:"50px"}}>두번째자리 : '1'~'9' or 'A'~'Z'</div>
                        </div>
                    </div>
                </>
            );
        }
        
    }

}


export default T2Code;