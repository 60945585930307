/**
 * @Page FAQ//RSY52
 * @Description [FAQ]FAQ 화면
 **/
import React, { useState, useEffect, useRef, useCallback } from "react";
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
import CommonUtil from '@/utils/CommonUtil'; 
import Popup from '@/pages/Popup/Popup';     
import { useParams } from 'react-router-dom';
import {useHistory } from "react-router-dom";

const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
const Utils = new CommonUtil();


function RSY52() {
    const token = Common.getToken();
    const userId = Common.getToken("id");
    const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
const bigi = Common.getToken("bigi");
    const grdFaqMain = useRef();   //Grid Main
    const history = useHistory();
    let {id} = useParams();
    id = history.location.pathname.substring(11);


    //Data
    const [idata, setData] = useState({
        //조회조건
        kSear : "1",      
        kSval : "",
        vdnm:"",
        numb:"",
        kTitl:"",//제목
        kGubn:"",//구분
        wrcseq:0,
        userName:userName,

        gubnList : [],  //공장
    }); 

    
   
    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;


    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
    const [typeOpen, setTypeOpen] = React.useState(false);
    const [data, setDatas] = useState([]);

    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    let [insertApi, setInsertApi] = useState(false); 
    let [updateApi, setUpdateApi] = useState(false); 
    let [deleteApi, setDeleteApi] = useState(false); // deleteApi 항목 setting
    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 50px)"});

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
        setInsertApi(false);
        setUpdateApi(false);
        setDeleteApi(false);
    }, []);
    
    

    /**
     * 공통코드 조회
     */
    const onSearchCommon = (para,name) => {

        let commonData = idata;

        //그룹코드 조회
        let param = encodeURI(para);
        const query = SERVER_URL + "/comcd/search1?query=" + param; 
        fetch(query, { headers: { Authorization: token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) { 
                    commonData[name] = data.data
                    setData({...commonData});
                }
            })
    }

    /**
     *MOUNT 
     */
    useEffect( () => {
        //공통코드 조회
        //onSearchCommon(`{"pgmid":"CODE", "vand":"****", "gocd" : "00"}`);
        onSearchCommon(`{"pgmid":"CODE", "vand":"****", "gocd" : "FAQ"}`, "gubnList" )

        onSearch();

        if (id) {
            let data=[];
            data["gubn"] = "search";
            data["numb"]=id;
            setDatas({ ...data });
            setTypeOpen(true);
        }
    }, []);



    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value } = e.target;
        let val = "";
        let newSearchs = idata; 

        newSearchs[name] = value;
        setData({ ...newSearchs })

        if (name === "kGubn" )  {
            newSearchs["kGubn"] = value;
            setData({ ...newSearchs });
        }

        onSearch(); //조회 Call

    }



    /**
     * Grid Event 
     * @param {*} e 
     */

    const onRowClicked = async (e) => {

        let data =  e.data;

        data["gubn"] = "search";
        
        setDatas({ ...data });

        setTypeOpen(true);
    }



    /**
     * 검색조건
     */
     const optionsX = {
        0: [
           
            {
                "formatter": "select", "name": "kGubn", "labelName": "조회구분", "type": "text", "defaultValue": idata.kGubn,  
                "codeMode": false,
                "selectAll": true,
                "codes": null,
                "comms": idata.gubnList,
            }, // kGubn
            {
                "formatter": "input", "name": "kTitl", "labelName": "제목", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kTitl,  
                "align": "left", 
                "labelType": "none", 
            }, 
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" }
        ]
    }



    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {

        const fetchData = async (e) => {//조회시 전체건수구해서 넣어주기
            let inputData = idata; 

            let para = `{"kTitl":"${idata.kTitl}","kGubn":"${idata.kGubn}"}`;
            let param = encodeURI(para);
            const query = SERVER_URL + "/faq/search?query=" + param;

            await fetch(query, { headers: { Authorization: token } })
                     .then((resp) => resp.json())  
                     .then((data) => {
                         if (data.success) {
                            setSearchApi(query);
                        }
                        if (data.error) {
                            alert("서버 상태를 확인해 주세요. 관리자에게 문의해주세요.");
                        }
                     })
                     .catch((error) => {
                        // 에러 응답 처리
                        if (error) {
                            setMessage({ openAlert : true, 
                                         severity : "error",
                                         msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                        }
                    });
                    };
            fetchData(e);

    }

    



    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
    }


    
    /**
     * 입력
     * @param {*} e 
     */
    const onInsert = async (e) => {

        let data =  [];

        data["gubn"] = "write";//아이디
        // data["vdnm"] = idata.userName;//이름
        // data["chk"] = 0;//최초 입력인지 확인

        // setDatas({ ...data });

        setDatas(data);
        setTypeOpen(true);
    }



    /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {
    }



    /**
     * 삭제
     * @param {*} e 
     */
    const onDelete = async (e) => {
    }



    // input check
    const inputCheck = (gbn) => { 
    }



    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans) 
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D" :
                break;
        }
    }
    
    


    /**
     *Grid Column 정의 
     */
    const columnDefs = [
        { headerName: "No", field: "numb", sortable: true, filter: true, flex: 1, minWidth: 30, hide: false, cellClass: ["nocheckbox", "alignC"]}, 
        { headerName: "조회구분", field: "gubn", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
        { headerName: "제 목", field: "titl", sortable: true, filter: true, flex: 1, minWidth: 1300, hide: false, cellClass: ["nocheckbox", "alignL"]}, 
    ];
    
    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 55px  - 50px)"
              });
        }
    }

    
    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

            <Popup type="FaqWrite" open={typeOpen} onClose={()=> {setTypeOpen(false);onSearch();}} data ={data} />
                    
            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                onSearch={onSearch} onSearchName={null} //조회
                onInsert={onInsert} onInsertVisible = {tVnt1 === "M000"}     //등록
            >
                <b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>자주 묻는 질문 (FAQ)</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick={onClick} />


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:55px */}
                <div className={"grid_type01"} > {/* 상단 FRAME */}
                    <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                        <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                            <Grid
                                ref={grdFaqMain}
                                gridId={"FAQ"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                pagination={false}                  //  쪽수 매기기 여부
                                paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={false}      // cell selection drag mode
                                isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                insertApi={insertApi}               // 등록
                                updateApi={updateApi}               // 수정
                                deleteApi={deleteApi}               // 삭제
                                //updateRefresh={onSearch}          // 수정이후 
                                deleteRefresh={onSearch}            // 삭제이후 
                                onClearApi={onClearApi}
                                //onClearApi={()=> {setSearchApi(false); setUpdateApi(false);}}
                                // onCellValueChangedEvent={onCellValueChanged}
                                onRowClickEvent={onRowClicked}
                                
                            />
                        </div>
                    </div>
                </div>
            </Content>
        </>
    )
}
export default RSY52;