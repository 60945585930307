//수신자료관리 - ERP_자재정보조회
import { Route, Switch } from 'react-router-dom';
import REPD0 from '@/pages/B02/REPD0';
import REPD1 from "@/pages/B02/REPD1";
import REPD2 from "@/pages/B02/REPD2";
import REPD3 from "@/pages/B02/REPD3";
import REPD9 from "@/pages/B02/REPD9";
import REPDH from '@/pages/B02/REPDH';
import RKDD0 from '@/pages/B02/RKDD0';
import RKDD1 from '@/pages/B02/RKDD1';
import RKDD2 from '@/pages/B02/RKDD2';
import RKDD9 from '@/pages/B02/RKDD9';

function B02() {
    return ( 
        <Switch>
            <Route path='/B02/REPD0' component={REPD0} />
            <Route path='/B02/REPD1' component={REPD1} />
            <Route path='/B02/REPD2' component={REPD2} />
            <Route path='/B02/REPD3' component={REPD3} />
            <Route path='/B02/REPD9' component={REPD9} />
            <Route path='/B02/REPDH' component={REPDH} />
            <Route path='/B02/RKDD0' component={RKDD0} />
            <Route path='/B02/RKDD1' component={RKDD1} />
            <Route path='/B02/RKDD2' component={RKDD2} />
            <Route path='/B02/RKDD9' component={RKDD9} />
        </Switch>
    );
}

export default B02;
