import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import "../../css/main.css";
import CustomDropzone from "./../molecules/CustomDropzone";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: "30px",
        fontSize: "1.25rem",
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function UploadModal({ open, onClose, onCloseYes, onCloseNo, closeAuto, title, fileId, uploadApi, uploadInfo, accp, maxFiles, validatorName,filePath, data }) {
 
    const onCloseFunc = (e) =>{
        if(closeAuto){
            onClose(e);
        }
    }

    return (
        <>
            <div className={"Modal"}>
                <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={onClose}>
                        {/* Modal title */}
                        {title}
                    </DialogTitle>
                    <DialogContent dividers>
                        <div style={{ width: "400px", height: "200px" }}>
                            <CustomDropzone 
                                fileId={fileId} 
                                maxFiles={maxFiles} 
                                uploadInfo={uploadInfo} 
                                accp={accp} 
                                validatorName={validatorName} 
                                uploadApi={uploadApi} 
                                filePath={filePath} 
                                data={data} 
                                onClose={(e) => onCloseFunc(e)} />
                        </div>
                    </DialogContent>
                    {onCloseYes || onCloseNo ? (
                        <DialogActions>
                            {onCloseYes ? (
                                <Button autoFocus onClick={onCloseYes} color="primary">
                                    예
                                </Button>
                            ) : (
                                ""
                            )}
                            {onCloseNo ? (
                                <Button onClick={onCloseNo} color="primary" autoFocus>
                                    아니오
                                </Button>
                            ) : (
                                ""
                            )}
                        </DialogActions>
                    ) : (
                        ""
                    )}
                </Dialog>
            </div>
        </>
    );
}
export default UploadModal;
