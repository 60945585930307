import React from 'react';

import '../../css/main.css';
// import '@/css/main.css';

import bwipjs from "bwip-js";
// const DEFAULT = "0123456789";


function CustomDataMatrix({ //children, ref,
    // const CustomDataMatrix = React.memo(function CustomDataMatrix({ //children, 
    // val, height, sty }) {
      val, bcid, scale, height, width, backgroundcolor }) {

    // const [input, setInput] = React.useState(val?val:"");
    const [src, setImageSrc] = React.useState("false");
  
    // Similar to componentDidMount and componentDidUpdate:
    React.useEffect(() => {
        let canvas = document.createElement("canvas");
        bwipjs.toCanvas(canvas, {
          bcid: bcid?bcid:"datamatrix", // (필수) Barcode type - datamatrix , code128 , code39
          text: val, // (필수) Text to encode
          scale: scale?scale:2, // 3x scaling factor
          height: height?height:18, // 12 Bar height, in millimeters
          includetext: false, // Show human-readable text          
          // padding : , // Shorthand for setting paddingtop, paddingleft, paddingright, and paddingbottom.
          // paddingwidth : 0, // Shorthand for setting paddingleft and paddingright.
          // paddingheight : 10, // Shorthand for setting paddingtop and paddingbottom.
          // paddingtop : , // Sets the height of the padding area, in points, on the top of the barcode image. Rotates and scales with the image.
          // paddingleft : , //  Sets the width of the padding area, in points, on the left side of the barcode image. Rotates and scales with the image.
          // paddingright : 10, //  Sets the width of the padding area, in points, on the right side of the barcode image. Rotates and scales with the image.
          // paddingbottom : , //  Sets the height of the padding area, in points, on the bottom of the barcode image. Rotates and scales with the image.
          backgroundcolor : backgroundcolor?backgroundcolor:"", //  This is actually a BWIPP option but is better handled by the bwip-js drawing code. Takes either a hex RRGGBB or hex (ex, "FFFFFF")  
          textxalign: "center" // Always good to set this
        });
        setImageSrc(canvas.toDataURL("image/png"));
    // }, [input]);
  }, [val, bcid, scale, height, width, backgroundcolor]);


  
    // function onChange(e) {
    //     if (e.target.value.length > 1) {
    //     setInput(e.target.value);
    //     } else {
    //     //   setInput(DEFAULT);
    //     setInput("");
    //     }
    // }


    return (
        <>
          {/* <div className="App"> */}
            {/* <h1>React Data Matrix</h1>
            <h2>Start editing to see some magic happen!</h2>
            <input
                type="text"
                style={{ textAlign: "center" }}
                value={input}
                onChange={onChange}
            />
            <br /> */}
            {/* <img src={src} alt={`data matrix from ${input}`} style={sty!==""?sty:null} /> */}
            <img src={src} alt={`data matrix from ${val}`} style={{width:width!==""?width:""}}/>
          {/* </div> */}
        </>
    );
}

export default CustomDataMatrix;
// export default React.memo(CustomDataMatrix);