/**
 * @Page WrapCkdLabelPrintPercent
 * @Description [ROM4K / ROM4R / ROM4X]납입지시 - ERP_CKD출하관리 - 부품식별표(중포장/용기/중포장2D) QR  ::  A 4매
 **/
import React from 'react';
import CustomDataMatrix from '@/components/atoms/CustomDataMatrix';
import * as Common from '../../utils/Common'; // Common.js
import styled from 'styled-components';
import QRCode from 'react-qr-code';

class WrapCkdLabelPrintPercentQR extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bplx: '0',
			bply: '0',
			pathNm: null,
		};
	}

	componentDidMount() {
		const info_bplx = Common.getToken('bplx') === null ? 0 : parseInt(Common.getToken('bplx'), 10) * 2; // 로그인한 사용자의 부품식별표 의 x좌표값 받아오기
		const info_bply = Common.getToken('bply') === null ? 0 : parseInt(Common.getToken('bply'), 10) * 2; // 로그인한 사용자의 부품식별표 의 y좌표값 받아오기

		this.setState({ bplx: info_bplx });
		this.setState({ bply: info_bply });

		if (window.location.pathname.split('/')[2] === 'ROM4X') {
			// ROM4X 부품식별표(중포장 2D) check
			this.setState({ pathNm: window.location.pathname.split('/')[2] });
		}
	}

	// '바코드 = 업체코드(4) + 품번(15) + 수량(5) + LOTNO(3) + 일련번호(4)
	//        tmp(0) '회사구분
	//        tmp(1) '지역
	//        tmp(2) '공장
	//        tmp(3) '차종
	//        tmp(4) '납품장소(하치장)
	//        tmp(5) '품번
	//        tmp(6) '품명
	//        tmp(7) '납품수량
	//        tmp(8) '바코드
	//        tmp(9) '납품일자
	//        tmp(10) '업체명
	//        tmp(11) "" '공정(공백)
	//        tmp(12) '업체코드
	//        tmp(13) "" ' LOT NO
	//        tmp(14) "" 'HPC(공백)
	//        tmp(15) 'CASE(CKD)
	//        tmp(16) "" '발행번호(바코드만들어서 던짐)
	//        tmp(17) "" '용기번호
	//        tmp(18) '부착매수
	//        tmp(19) 'LH/RH
	//        tmp(20) "" '사양
	//        tmp(21)  '발주번호(공백)
	//        tmp(22)  'FROM-ORDER(CKD , OEM은 공정)
	//        tmp(23)  'CKD는 공백, OEM은 HPC
	//        tmp(24)  '용기당수
	//        tmp(25) = '출력1
	//        tmp(26) = 'x좌표1
	//        tmp(27) = 'y좌표1
	//        tmp(28) = '색상1
	//        tmp(29) = 'FONT/S1
	//        tmp(30) = '출력2
	//        tmp(31) = 'x좌표2
	//        tmp(32) = 'y좌표2
	//        tmp(33) = '색상2
	//        tmp(34) = 'FONT/S2
	//        tmp(35) = '한글품명
	//        tmp(36) = 'RHD

	render() {
		// Print Style CSS Setting
		const PrintStyle = styled.div`
			@media all {
				body {
					background-color: ;
					margin: 0px;
					padding: 0px;
					font-family: 'HY견고딕';
				}
				div {
					background-color: ;
					margin: 0px;
					padding: 0px;
					box-sizing: border-box;
				}
				table {
					border-collapse: collapse;
					border-style: none;
					margin: 0px;
					padding: 0px;
					box-sizing: border-box;
				}
				tr {
					width: 100%;
					margin: 0px;
					padding: 0px;
				}
				td {
					margin: 0px;
					padding: 0px;
					background-color: white;
					font-family: 'HY견고딕';
					border: 0px solid green;
					box-sizing: border-box;
				}
				.ChosunGu11 {
					font-family: 'NanumGothic';
					font-weight: bold;
					font-size: 11pt;
				}
				.ChosunGu14 {
					font-family: 'NanumGothic';
					font-weight: bold;
					font-size: 14pt;
				}
				.ChosunBg {
					font-family: 'ChosunBg';
				}
				.table {
					display: table;
					border: 1px solid blue;
				}
				.row {
					// display: table-row;
					display: flex;
					flex-flow: wrap;
				}
				.cell {
					display: table-cell;
					// border-bottom:1px solid blue;
					// border-right:1px solid blue;
					border-width: thin;
					white-space: nowrap;
					z-index: 99;
				}

				.colspan2 {
					flex: 2;
				}
				.colspan3 {
					flex: 3;
				}
				.colspan4 {
					flex: 4;
				}
				.colspan5 {
					flex: 5;
				}
				.colspan6 {
					flex: 6;
				}
				.colspan7 {
					flex: 7;
				}
				.colspan8 {
					flex: 8;
				}
				.colspan9 {
					flex: 9;
				}
				.colspan10 {
					flex: 10;
				}

				.row > .cell:nth-child(1) {
					width: 12%;
				}
				.row > .cell:nth-child(2) {
					width: 3%;
				}
				.row > .cell:nth-child(3) {
					width: 4%;
				}
				.row > .cell:nth-child(4) {
					width: 9%;
				}
				.row > .cell:nth-child(5) {
					width: 9%;
				}
				.row > .cell:nth-child(6) {
					width: 10%;
				}
				.row > .cell:nth-child(7) {
					width: 9%;
				}
				.row > .cell:nth-child(8) {
					width: 9%;
				}
				.row > .cell:nth-child(9) {
					width: 7%;
				}
				.row > .cell:nth-child(10) {
					width: 9%;
				}

				.row > .cell:nth-child(11) {
					width: 9%;
				}
				.row > .cell:nth-child(12) {
					width: 10%;
				}
			}
			@media print {
				@page {
					size: A4 landscape;
					margin-top: 0mm;
					margin-left: 0mm;
					margin-right: 4mm;
					margin-bottom: 4mm;
				}

				.table {
					display: table;
					border: 1px solid none;
				}
				.row {
					// display: table-row;
					display: flex;
					flex-flow: wrap;
				}
				.cell {
					display: table-cell;
					border-bottom: 1px solid none;
					border-right: 1px solid none;
					border-width: thin;
					white-space: nowrap;
					z-index: 99;
				}

				div.pagebreak {
					break-after: always !important;
					page-break-after: always !important;
					page-break-inside: avoid !important;
				}
			}

			@media screen {
				.table {
					display: table;
					border: 1px solid red;
				}
				.row {
					// display: table-row;
					display: flex;
					flex-flow: wrap;
				}
				.cell {
					display: table-cell;
					border-bottom: 1px solid red;
					border-right: 1px solid red;
					border-width: thin;
					white-space: nowrap;
					z-index: 99;

					color: blue;
				}
			}
		`;

		const { printData } = this.props;

		// 검색조건 파라메터 가져오기
		let { kStart, chkMetal, kCkdOem, chkNpdt, chkLtno, kNpdt } = this.props.idata;

		const listSize = printData.length; // data length
		let idx = -1; // -1 부터 start
		let str = parseInt(kStart) - 1;

		idx = idx + str; // 시작 위치 (like 배열)

		// 바코드 표출용 - vseq
		let vseq = 0;

		// remain 표출 체크용 - remainChk -- 신규 품번의 vseq 담기
		let remainChk = 0;

		const mmToPx = (mm) => {
			let px = mm * 3.7795275591;
			return px;
		};

		const cardInfo = printData
			? printData.map((data, index) => {
					// 최초 1 open - 품번 별 row  -- START

					if (index === 0) {
						//vseq = parseInt(data.SEQ) -1; // 바코드 표출 vseq용
						vseq = parseInt(data.SEQ) === 0 ? 0 : parseInt(data.SEQ) - 1; // 바코드 표출 vseq용
					}

					// remain 표출 체크용 - remainChk -- 신규 품번의 vseq 담기
					remainChk = vseq; // 새 품번의 remain 여부 체크하기

					// 배열 만들어서 map 돌리쟈 !
					let npqt = parseInt(data.NPQT); // parseInt(data.npqt);//parseInt(data.npqt) === NaN ? 0 : parseInt(data.npqt); // 납품수량
					let qypc = parseInt(data.QYPC); // 용기당 수
					let stck = parseInt(data.STCK); // 부착면 수

					// 추가 표시 컬러 체크 -- START
					let tmp25 = data.TMP25; // TMP25    WRCLBL1	이중인쇄1 내용
					let tmp28 = data.TMP28; // TMP28    WRCCOL1	이중인쇄1 컬러

					let tmp30 = data.TMP30; // TMP30    WRCLBL2	이중인쇄2 내용
					let tmp33 = data.TMP33; // TMP33    WRCCOL2	이중인쇄2 컬러

					let color1 = '';
					let color2 = '';

					let dualPrint1 = 'F';
					let dualPrint2 = 'F';

					let dualPrint1css = ''; // dualPrint1 css 추가 표기 view 1
					let dualPrint2css = ''; // dualPrint2 css 추가 표기 view 2

					if (tmp25 !== '') {
						dualPrint1 = 'T';
						if (tmp28 === 'B') {
							color1 = 'blue';
						} else if (tmp28 === 'G') {
							color1 = 'green';
						} else if (tmp28 === 'R') {
							color1 = 'red';
						} else if (tmp28 === 'O') {
							color1 = '#ff6600';
						} else if (tmp28 === 'X') {
							color1 = 'rgba(0, 0, 0, 0.7)';
						}
					}
					if (tmp30 !== '') {
						dualPrint2 = 'T';
						if (tmp33 === 'B') {
							color2 = 'blue';
						} else if (tmp33 === 'G') {
							color2 = 'green';
						} else if (tmp33 === 'R') {
							color2 = 'red';
						} else if (tmp33 === 'O') {
							color2 = '#ff6600';
						} else if (tmp33 === 'X') {
							color2 = 'rgba(0, 0, 0, 0.7)';
						}
					}
					// 추가 표시 컬러 체크 -- END

					let result = Math.floor(npqt / qypc); // 몫
					let resultIdx = Math.floor(npqt / qypc); // 목 + 나머지의 카운트
					let remain = npqt % qypc; // 나머지

					if (remain > 0) {
						resultIdx = result + 1;
					}

					// 새 프린트용 배열
					const newSearched = [];

					// 증가 체크 resultIdx
					let n = 0;

					Array.from(new Array(resultIdx), (i) => {
						// 메인 출력물

						Array.from(new Array(stck), (w, j) => {
							// 부착면수 만큼 선 반복..
							// 시작시 idx 셋팅
							idx = idx + 1;

							// v seq count 셋팅
							//if((idx-str)%stck===0){
							if (j === 0) {
								// 부착면수 1일때 고려...
								vseq = vseq + 1; // 새 vseq 마다 + 1 증가
							}

							let iCnt = i + 1;
							const newArray = {}; // new Array 선언
							let jCnt = j + 1;

							// 새 배열의 n 번째 구하기
							if (i === 0 && j === 0) {
								n = 0;
							} else {
								n = n + 1;
							}

							newArray['idx'] = idx;
							newArray['vseq'] = vseq;

							//납품수량
							let newQty = 0;
							newArray['qty'] = qypc;
							newQty = qypc;

							newArray['stck'] = stck;

							newArray['firstpage'] = 'F';
							newArray['endpage'] = 'F';

							newArray['color1'] = color1;
							newArray['color2'] = color2;

							newArray['dualPrint1'] = dualPrint1;
							newArray['dualPrint2'] = dualPrint2;

							// 첫번째 Page 구하기
							if (i === 0 && j === 0 && index === 0) {
								newArray['firstpage'] = 'T';
							}

							// 나머지 표출하기  - 수정
							if (resultIdx === vseq - remainChk) {
								if (remain > 0) {
									newArray['qty'] = remain;
									newQty = remain;
								}
							} else {
								if (index === 0 && resultIdx === 1) {
									if (remain > 0) {
										newArray['qty'] = remain;
										newQty = remain;
									}
								}
							}

							// END Page 구하기
							if (listSize === index + 1) {
								if (resultIdx === iCnt) {
									if (stck === jCnt) {
										newArray['endpage'] = 'T';
									}
								}
							}

							const lotNo = data.TMP13 === '' ? ' ' : Common.getLOT(data.TMP13); // LOT NO 날짜 가져오기  data.tmp13

							//일련번호(4)
							// const prno = data.tmp0 + "" + ("0000" + parseInt(data.SEQ, 16)).slice(-4);
							const prno = (data.TMP0 === undefined ? '' : data.TMP0) + '' + ('0000' + parseInt(vseq, 10)).slice(-4);

							// DataMatrix - code128 바코드 값 만들기
							//    'barcode2 = 기존바코드(data8) + 납품일자(tmp(9))의 년(2)+월(2)+일(2)+부품식별표발행순번(tem(14)의 4자리)
							//    'barcode2 = data8 & Mid(data(9), 3, 2) & Mid(data(9), 6, 2) & Mid(data(9), 9, 2) & Right("0000" & Format(data(14), "####"), 4)
							//    '20190924 이동주 이사의 의견으로 CKD 부품식별표의 SEQ를 납품일자가 아닌 현재 발행 일자로 수정

							// DataMatrix - code128 바코드 값 만들기
							let barcodeValu = '';

							if (kCkdOem === 'CKD') {
								// 중포장일때
								// 바코드 = 업체코드(4)   + 품번(13)                       + 수량(6)                           + 납품일자(yymmdd 8)                      + 일련번호(4)
								// barcodeValu = data.TMP21 +""+ (data.TMP5).padEnd(13," ") +""+ ("000000" + newQty).slice(-6) + "" + ((data.TMP13).slice(-8)).replace(/-/g, '') + ""  + prno ;

								// 수량 6자리에서 7자리로 변경 2022-06-22
								// 바코드 = 업체코드(4)              + 품번(13)                       + 수량(7)                                         + 납품일자(yymmdd 8)                      + 일련번호(4)
								//barcodeValu = data.TMP21 +""+ (data.TMP5.slice(0,13)).padEnd(13," ") +""+ ("0000000" + newQty).slice(-7) + "" + ((data.TMP13).slice(-8)).replace(/-/g, '') + ""  + prno ;

								//검색조건에서 받아온 kNpdt=납품일자로 변경 2022-06-23
								// 바코드 = 업체코드(4)              + 품번(13)                       + 수량(7)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4)
								//  barcodeValu = data.TMP21 +""+ (data.TMP5.slice(0,13)).padEnd(13," ") +""+ ("0000000" + newQty).slice(-7) + "" + ((kNpdt).replace(/-/g, '').slice(-6)) + ""  + prno ;

								// 수량 7 -> 6 으로 변경 셋팅 2022-06-30
								// 바코드 = 업체코드(4)              + 품번(13)                       + 수량(6)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4)
								barcodeValu = data.TMP21 + '' + data.TMP5.slice(0, 14).padEnd(14, ' ') + '' + ('00000000' + newQty).slice(-8) + '' + kNpdt.replace(/-/g, '').slice(-6) + '' + prno;
							} else {
								//용기일때
								// 바코드 = 업체코드(4)   + 품번(13)                       + 수량(6)                           + 납품일자(yymmdd 8)                      + 일련번호(4)
								// barcodeValu = data.TMP22 +""+ (data.TMP5).padEnd(13," ") +""+ ("000000" + newQty).slice(-6) + "" + ((data.TMP13).slice(-8)).replace(/-/g, '') + ""  + prno ;

								// 수량 6자리에서 7자리로 변경 2022-06-22
								// 바코드 = 업체코드(4)              + 품번(13)                       + 수량(7)                                         + 납품일자(yymmdd 8)                      + 일련번호(4)
								//  barcodeValu = data.TMP22 +""+ (data.TMP5.slice(0,13)).padEnd(13," ") +""+ ("0000000" + newQty).slice(-7) + "" + ((data.TMP13).slice(-8)).replace(/-/g, '') + ""  + prno ;

								//검색조건에서 받아온 kNpdt=납품일자로 변경 2022-06-23
								// 바코드 = 업체코드(4)              + 품번(13)                       + 수량(7)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4)
								//  barcodeValu = data.TMP22 +""+ (data.TMP5.slice(0,13)).padEnd(13," ") +""+ ("0000000" + newQty).slice(-7) + "" + ((kNpdt).replace(/-/g, '').slice(-6)) + ""  + prno ;

								// 수량 7 -> 6 으로 변경 셋팅 2022-06-30
								// 바코드 = 업체코드(4)              + 품번(13)                       + 수량(6)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4)
								barcodeValu = data.TMP22 + '' + data.TMP5.slice(0, 14).padEnd(14, ' ') + '' + ('00000000' + newQty).slice(-8) + '' + kNpdt.replace(/-/g, '').slice(-6) + '' + prno;
							}

							let serialNo = kNpdt.replace(/-/g, '').slice(-6) + '' + prno;

							const ptnm = data.TMP6.slice(0, 18);
							newArray['ptnm'] = ptnm;

							// DataMatrix Barcode 만들기 - code128
							const dataMatrixBarcode128 = <CustomDataMatrix val={barcodeValu} height={14} bcid={'code128'} scale={1.8} width={'390px'} />;
							newArray['barcode'] = dataMatrixBarcode128;

							const dataMatrixBarcodeQR = <QRCode style={{ height: mmToPx(25), maxWidth: mmToPx(25), width: mmToPx(25) }} value={barcodeValu} level={'H'} />;
							newArray['qrcode'] = dataMatrixBarcodeQR;

							// DataMatrix Barcode 만들기  - dataMatrix
							const dataMatrixBarcode = this.state.pathNm !== null && this.state.pathNm === 'ROM4X' ? <CustomDataMatrix val={barcodeValu} height={18} scale={1} /> : '';
							newArray['barcodeMatrix'] = dataMatrixBarcode;

							newArray['lotno'] = lotNo;
							newArray['bno'] = barcodeValu;
							newArray['serialNo'] = serialNo;

							// 자기 위치 셋팅 (출력매수)
							const posi = idx % 4;
							newArray['posi'] = posi;

							let divCss = '';
							let divMetal = '';
							let divTmp15 = '';

							// page별  (출력매수)1장 이상일경우 100% 씩 추가 ... 다음장 노출을 위함.
							let pagebreaky = Math.floor(idx / 4) * 100;
							let pagebreakyCnt = Math.floor(idx / 4);

							/******************************************/
							let totalLeft = -1;
							let totalTop = 0.5;
							let cWidth = 143;
							let cHeight = 46.8;
							let cPageHeight = 183.5; //총높이
							let cPageWidth = 280; //총넓이
							let marginX = 9.2; //margin left
							let marginY = 5; //margin top

							let Heightmmy = (100 * this.state.bply) / cPageHeight;
							let Heightmmx = (100 * this.state.bplx) / cPageWidth;

							let HeightTmp26 = (100 * parseInt(data.TMP26, 10)) / cPageWidth;
							let HeightTmp27 = (100 * parseInt(data.TMP27, 10)) / cPageHeight;

							let cLeft = 49.4 * (posi % 2) + totalLeft + HeightTmp26 + Heightmmx + '%';
							let cTop = pagebreaky + (posi < 2 ? 0 : cHeight) + totalTop + HeightTmp27 + Heightmmy + '%';

							dualPrint1css = {
								position: 'absolute',
								fontFamily: 'HY견고딕',
								top: cTop,
								left: cLeft,
								color: color1,
								fontSize: parseInt(data.TMP29, 10) + 'pt',
								fontWeight: 'bold',
								zIndex: '13',
								whiteSpace: 'nowrap',
							};

							let totalLeft2 = -1.2;
							let totalTop2 = 0.5;
							let HeightTmp31 = (100 * parseInt(data.TMP31, 10)) / cPageWidth;
							let HeightTmp32 = (100 * parseInt(data.TMP32, 10)) / cPageHeight;

							let cLeft2 = 49.4 * (posi % 2) + totalLeft2 + HeightTmp31 + Heightmmx + '%';
							let cTop2 = pagebreaky + (posi < 2 ? 0 : cHeight) + totalTop2 + HeightTmp32 + Heightmmy + '%';
							let size = { width: '48%', height: '48%' };

							dualPrint2css = {
								position: 'absolute',
								fontFamily: 'HY견고딕',
								top: cTop2,
								left: cLeft2,
								color: color2,
								fontSize: parseInt(data.TMP34, 10) + 'pt',
								fontWeight: 'bold',
								zIndex: '13',
								whiteSpace: 'nowrap',
							};
							/******************************************/
							if (posi === 0) {
								divCss = {
									position: 'absolute',
									top: pagebreaky + 3.8 + Heightmmy + '%',
									left: 0.3 + Heightmmx + '%',
									paddingTop: '2mm',
									paddingLeft: '0mm',
									border: '0px solid red',
									zIndex: '20',
								};

								divMetal = { position: 'absolute', top: pagebreaky + 29.6 + Heightmmy + '%', left: 42.5 + Heightmmx + '%', zIndex: '12' };
							} else if (posi === 1) {
								divCss = {
									position: 'absolute',
									top: pagebreaky + 3.8 + Heightmmy + '%',
									left: 49.7 + Heightmmx + '%',
									paddingTop: '2mm',
									paddingLeft: '0mm',
									border: '0px solid red',
									zIndex: '16',
								}; //,marginTop: "137px"

								divMetal = { position: 'absolute', top: pagebreaky + 29.6 + Heightmmy + '%', left: 92 + Heightmmx + '%', zIndex: '12' };
							} else if (posi === 2) {
								divCss = {
									position: 'absolute',
									top: pagebreaky + 50.9 + Heightmmy + '%',
									left: 0.3 + Heightmmx + '%',
									paddingTop: '1.5mm',
									paddingLeft: '0mm',
									border: '0px solid red',
									zIndex: '20',
								};

								divMetal = { position: 'absolute', top: pagebreaky + 76.6 + Heightmmy + '%', left: 42.5 + Heightmmx + '%', zIndex: '12' };
							} else if (posi === 3) {
								divCss = {
									position: 'absolute',
									top: pagebreaky + 50.9 + Heightmmy + '%',
									left: 49.6 + Heightmmx + '%',
									paddingTop: '1.5mm',
									paddingLeft: '0mm',
									border: '0px solid red',
									zIndex: '16',
								};

								divMetal = { position: 'absolute', top: pagebreaky + 76.6 + Heightmmy + '%', left: 92 + Heightmmx + '%', zIndex: '12' };
							}
							newArray['divCss'] = divCss;
							newArray['divMetal'] = divMetal;
							newArray['dualPrint1css'] = dualPrint1css;
							newArray['dualPrint2css'] = dualPrint2css;

							newArray['divTmp15'] = divTmp15;
							newArray['size'] = size;

							// 위에서 계산 해서 본인 위치에 셋팅 !
							// newSearched 새배열에 값 몽땅 넣기
							newSearched[n] = newArray;
						});
					});

					return (
						<>
							{newSearched.map(
								(sData, dataIdx) => (
									<form key={'WrapCkdLabelPrintPercentQR' + dataIdx}>
										{/* div로 테이블 그리기 */}

										<div style={{ ...sData.divCss, ...sData.size, overflow: 'hidden', zIndex: '200', width: mmToPx(140), height: mmToPx(97), padding: mmToPx(1) }}>
											{/* top:"0", left:"0", */} {/* 패치 전 border 삭제 , border:"1px solid red" */}
											<div className="row" style={{ height: mmToPx(8) }}>
												<div className="" style={{ width: mmToPx(48) }}></div>
												<div className="" style={{ width: mmToPx(16) }}></div>
												<div className="ChosunBg" style={{ width: mmToPx(27), fontSize: data.TMP1.length > 5 ? '12pt' : data.TMP1.length > 7 ? '9pt' : '16pt', textAlign: 'center' }}>
													{data.TMP1}
												</div>
												{/* 지역 */}
												<div className="ChosunBg" style={{ width: mmToPx(45), fontSize: '10pt', letterSpacing: '-1px', textAlign: 'center', paddingTop: '4px' }}>
													{data.TMP0 === 'MPMC' ? '현대파워텍(주)용ⓡ' : data.TMP4 === 'MAMC' ? '글로비스(주)용ⓡ' : '현대자동차(주)/기아(주)ⓡ'}
												</div>
											</div>
											<div className="row" style={{ height: mmToPx(8) }}>
												<div className="" style={{ width: mmToPx(14) }}></div>
												<div className="ChosunBg" style={{ width: mmToPx(34), fontSize: '18pt', textAlign: 'center' }}>
													{data.TMP2}
												</div>
												{/* 공장 */}
												<div className="" style={{ width: mmToPx(17) }}></div>
												<div className="ChosunBg" style={{ width: mmToPx(27), fontSize: '18pt', textAlign: 'center' }}>
													{data.TMP4}
												</div>
												{/* 납품장소 */}

												<div style={{ width: mmToPx(10) }}></div>
												<div style={{ width: mmToPx(26), textAlign: 'center', paddinTop: '4px' }}>{sData.qrcode}</div>
												<div style={{ width: mmToPx(9) }}></div>
											</div>
											<div className="row" style={{ height: mmToPx(8) }}>
												<div className="" style={{ width: mmToPx(14) }}></div>
												<div
													className="ChosunBg"
													style={{
														width: mmToPx(34),
														letterSpacing: '0px',
														textAlign: 'center',
														fontSize: !data.TMP3 === false && data.TMP3.toString().replace(/\s/gi, '').length > 9 ? '13pt' : '14pt',
														marginTop: '2px',
													}}
												>
													{data.TMP3}
												</div>
												{/* 차종 */}
												<div className="" style={{ width: mmToPx(17) }}></div>
												<div className="ChosunBg" style={{ width: mmToPx(27), fontSize: sData.qty.length > 7 ? '15pt' : '18pt', textAlign: 'center' }}>
													{sData.qty}
												</div>
											</div>
											<div className="row" style={{ height: mmToPx(12) }}>
												<div className="" style={{ width: mmToPx(15) }}></div>
												<div className="ChosunBg" style={{ width: mmToPx(83), textAlign: 'left', fontSize: '20pt', paddingTop: '6px' }}>
													{data.TMP5.substr(0, 5) + '-' + data.TMP5.substr(5)}
													{/* 품번 */}
												</div>
											</div>
											<div className="row" style={{ height: mmToPx(14) }}>
												<div className="" style={{ width: mmToPx(15) }}></div>
												<div className="ChosunBg" style={{ width: mmToPx(98), textAlign: 'left', fontSize: data.TMP6.length > 18 ? '16pt' : '20pt', paddingTop: '7px' }}>
													{data.TMP6}
												</div>
												<div
													className="ChosunBg"
													style={{
														width: mmToPx(25),
														fontSize: '44pt',
														color: data.TMP19 === '좌' || data.TMP19 === 'L' ? 'red' : 'blue',
														textAlign: 'right',
														zIndex: '10',
														marginTop: '-4px',
													}}
												>
													{data.TMP19}
												</div>
												{/* LH/RH */}
											</div>
											<div className="row" style={{ height: mmToPx(15) }}>
												<div className="ChosunBg" style={{ width: mmToPx(45), fontSize: '16pt', letterSpacing: '-0.5px', paddingTop: '10px' }}>
													G/W: {data.TMP35}kg
												</div>
												<div className="ChosunBg" style={{ width: mmToPx(44), fontSize: '16pt', letterSpacing: '-0.5px', paddingTop: '10px' }}>
													N/W: {data.TMP20}kg
												</div>
												<div className="ChosunBg" style={{ width: mmToPx(41), fontSize: '14pt', paddingTop: '10px' }}>
													{sData.serialNo}
												</div>
											</div>
											<div className="row" style={{ height: mmToPx(8) }}>
												<div className="" style={{ width: mmToPx(19) }}></div>
												<div className="ChosunBg" style={{ width: mmToPx(30), fontSize: '12pt', textAlign: 'center' }}>
													{chkNpdt && chkNpdt === 'Y' ? data.TMP9 : ''}
												</div>
												{/* 납품일자 chkNpdt data.TMP9=납기일자(원복) 2022-06-27 */}
												<div className="" style={{ width: mmToPx(17) }}></div>
												<div className="ChosunBg" style={{ width: mmToPx(18), fontSize: '12pt' }}>
													{data.TMP12}
												</div>
												<div className="ChosunBg" style={{ width: mmToPx(54), fontSize: data.TMP10.length > 10 ? '10pt' : '12pt' }}>
													{data.TMP10}
												</div>
											</div>
											<div className="row" style={{ height: mmToPx(8) }}>
												<div className="ChosunBg" style={{ width: mmToPx(19), fontSize: '11pt', textAlign: 'center' }}>
													{kCkdOem === 'CKD' ? 'Order' : kCkdOem === 'CKD2' ? 'From' : ''}
												</div>
												<div className="ChosunBg" style={{ width: mmToPx(30), fontSize: '12pt' }}>
													{data.TMP22}
												</div>
												{/* 공정NO*/}
												<div className="ChosunBg" style={{ width: mmToPx(17), fontSize: '27pt' }}>
													{data.TMP36 === 'Y' ? 'RHD' : ''}
												</div>
												{/* RHD */}
												<div className="ChosunBg" style={{ width: mmToPx(50), fontSize: '40pt' }}>
													{data.TMP15}
												</div>
												<div className="ChosunBg" style={{ width: mmToPx(10), fontSize: '20pt', overflow: 'visible', paddingTop: '2mm', zIndex: '15' }}></div>
											</div>
											<div className="row" style={{ height: mmToPx(8) }}>
												<div className="ChosunBg" style={{ width: mmToPx(19), fontSize: '11pt', textAlign: 'center' }}>
													{kCkdOem === 'CKD' ? '' : kCkdOem === 'CKD2' ? 'To' : ''}
												</div>
												<div className="ChosunBg" style={{ width: mmToPx(30), fontSize: '12pt' }}>
													{data.TMP23}
												</div>
												{/* order*/}
												<div className="ChosunBg" style={{ width: mmToPx(17), fontSize: '12pt', textAlign: 'center' }}>
													{chkLtno && chkLtno === 'Y' ? sData.lotno : ''}
												</div>
												{/* getLOT() chkLtno  */}
												<div className="" style={{ width: mmToPx(27), fontSize: '18pt' }}></div>
											</div>
											<div className="row" style={{ height: mmToPx(6) }}>
												<div className="" style={{ width: mmToPx(18) }}></div>
												<div className="ChosunBg" style={{ width: mmToPx(76), fontSize: '9pt', whiteSpace: 'nowrap' }}>
													{sData.bno}
												</div>
												{/* bno */}
												<div className="" style={{ width: mmToPx(12) }}></div>
												<div className="ChosunBg" style={{ width: mmToPx(30), fontSize: '12pt', marginTop: '-1px' }}>
													{data.TMP17}
												</div>
												{/* 용기 No */}
											</div>
										</div>

										{/* 중금속 확인필 */}

										{chkMetal === 'Y' ? (
											<div style={{ ...sData.divMetal }}>
												{/* <img src ="/images/heavymetal.jpg" style={{width:"18mm"}} /> */}
												<img src="/images/heavy_metal_confirm.gif" style={{ width: '15mm' }} alt="heavy_metal_confirm" />
											</div>
										) : (
											''
										)}

										{/* 이중출력부분 */}
										{/* XXXXXX */}
										{sData.dualPrint1 === 'T' ? <div style={{ ...sData.dualPrint1css }}>{data.TMP25}</div> : ''}
										{sData.dualPrint2 === 'T' ? <div style={{ ...sData.dualPrint2css }}>{data.TMP30}</div> : ''}
										{/* XXXXXX */}

										{sData.posi === 3 && sData.endpage === 'F' ? (
											// (출력매수-1)
											<div key={'pagebreak' + sData.idx} style={{ background: 'red', breakAfter: 'page', pageBreakAfter: 'always', pageBreakInside: 'avoid' }}></div>
										) : (
											''
										)}
									</form>
								),
								'',
							)}
						</>
					);

					// 최초 1 close - 품번 별 row  -- END
			  })
			: '';

		return <PrintStyle key={'WrapCkdLabelPrintPercentQR'}>{cardInfo}</PrintStyle>;
	}
}
export default WrapCkdLabelPrintPercentQR;
