/**
 * @Page ROM91
 * @Description [ROM91]납입지시 - 현황관리 - 검수현황 
 **/
import React, {useState, useEffect, useRef, useCallback, forwardRef} from "react";
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; // Common.js
import CommonUtil from '@/utils/CommonUtil'; // Util.js
import {getToday} from "@/utils/Common";
import Loading from "src/components/atoms/CustomLoading2";

const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const Utils = new CommonUtil();


function ROM91() {
    const token = Common.getToken();
    const userId = Common.getToken("id");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const bigi = Common.getToken("bigi");
    const grdRom91Main = useRef();   // Grid Main
    
    const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
    let [loading, setLoading] = useState(false);

    //Data
    const [idata, setData] = useState({
        kFpdt: getToday(),
        kTpdt: getToday(),
        kOrdr: "1", // 조회결과
        kIpgo: "", // 조회항목
        // kVnt1 : tVnt1,      //T1업체
        kVnt1 : ckVnt1,        //T1업체     
        kComp: tComp,       //회사구분
        kPlnt: "",          //공장
        kPtno2 : "",         //품번
        gubn:"",
        kVnt1Nm : "",        //T1업체명
        kCompNm : tComp==="EHMC"?"현대자동차(주)":"기아자동차(주)",        //회사명

        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장
        gubnList : [],  //하치장
        compList : [],  //회사
        ipgoList : [],
        headerList:[],//헤더
    });


    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;


    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      // Dialog Transaction(I,U,D)
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;

    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    let [clearGrid, setClearGrid] = useState(false); 

    const [panded, setPanded] = React.useState(true);
     const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 100px  - 50px)"});
     
    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
    }, []);


    const onSearchCommon = async (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/rom91/total?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data;
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }


    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommonAsync = async (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success)
                    {
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        await fetchData(para, name);
    }

    const onSearchCommon1 = (para, name) => {
        let commonData = idata;

        const fetchData1 = async (para, name) => {

            let param = encodeURIComponent(para);
            const query = SERVER_URL + "/comcd/search1?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    {
                        if (name === "typeList") {
                            //commonData["kType"] = data.data[0].comCode
                            //commonData["kTypeNm"] = data.data[0].text
                        }
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData1(para, name);
    }

    const onSearchCommon2 = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                    commonData[name] = data.data[0].text;
                    setData({...commonData});
                    }
                })
        }
        fetchData(para, name);   
    }

    /**
     *MOUNT
     */
    useEffect( () => {
        onSearchCommon(`{"vnt1": "${idata.kVnt1}", "fpdt": "${idata.kFpdt.replaceAll('-', '')}", "tpdt": "${idata.kTpdt.replaceAll('-', '')}",
                        "comp": "${idata.kComp}", "plnt": "${idata.kPlnt}", "ptno": "${idata.kPtno2}", "ipgo": "${idata.kIpgo}"}`, "headerList");
        //공통코드 조회 (회사)
        onSearchCommon1(`{"pgmid":"CODE","vand":"****","gocd":"COMP"}`, "compList");
        //공통코드 조회 (T1업체)
        onSearchCommonAsync(`{"pgmid":"VNT1"}`, "vnt1List");
        //공통코드 조회 (공장)
        //onSearchCommonAsync(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");
        onSearchCommonAsync(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":""}`, "plntList");

        // 검수구분 가져오기
        onSearchCommon1(`{"pgmid":"IPGO","kGocd":"IPGO","kVnt1":"****"}`, "ipgoList");

        onSearchCommon2(`{"pgmid":"WRCVAND","kVnt1":"${idata.kVnt1}"}`, "kVnt1Nm");
    }, []);



    /**
     * Control 변경 Event
     * @param {*} e
     */
    const onChange = async (e) => {
        const {name, value, desc} = e.target;

        let newSearchs = idata;
        newSearchs[name] = value;
        newSearchs[name+"Nm"] = desc;
        setData({ ...newSearchs });


        if(name === "kComp"){

            setClearGrid(true); // 그리드 clear 하기 

            newSearchs["kPlnt"] = "";
            newSearchs["kPlntNm"] = "";
            newSearchs["kGate"] = "";
            setData({ ...newSearchs });

            //공통코드 조회 (공장)
        onSearchCommonAsync(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":""}`, "plntList");
        }

        if (name === "kFpdt"||name === "kTpdt"||name==="kPlnt"||name === "kOrdr"||name === "kIpgo"||name==="kVnt1"||name==="kPtno2") { // "조회 항목", "조회 구분" 변경 시 하단 그리드 1, 2 clear            
            idata.gubn = "";
            
            // onSearchCommon(`{"vnt1": "${idata.kVnt1}", "fpdt": "${idata.kFpdt.replaceAll('-', '')}", "tpdt": "${idata.kTpdt.replaceAll('-', '')}",
            //                  "comp": "${idata.kComp}", "plnt": "${idata.kPlnt}", "ptno": "${idata.kPtno2}", "ipgo": "${idata.kIpgo}"}`, "headerList");
        }

//setClearGrid(true); // 그리드 clear 하기 

        
    }


    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //T1업체
            {
                formatter: "select", name: "kVnt1", labelName: "T1업체", type: "text", defaultValue: idata.kVnt1,  
                codeMode: true,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: idata.vnt1List, 
                align: "left", 
            },
            // 공장
            {
                formatter: "select", name: "kPlnt", labelName: "공장", type: "text", defaultValue: idata.kPlnt,  
                codeMode: false,
                selectAll: "all", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: idata.plntList,
                align: "left", 
            },
            // 입고일자
            {
                formatter: "date2", name: "kFpdt",name2: "kTpdt", labelName: "입고일자", type: "date",
                defaultValue: idata.kFpdt, defaultValue2: idata.kTpdt,
                codeMode: false,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: null,
                align: "left", 
            },
            // 입고일자 끝
            // {
            //     formatter: "input", name: "kTpdt", labelName: "~", type: "date", defaultValue: idata.kTpdt,  
            //     codeMode: false,
            //     selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
            //     codes: null, // 2015~2022년까지 :: Case 1 - codes :: Code List
            //     comms: null,
            //     align: "left", 
            // },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
        ],
        1: [
            //회사구분
            {
                formatter: "select", name: "kComp", labelName: "회사구분", type: "text", defaultValue: idata.kComp,  
                codeMode: true,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: idata.compList,
                comms: null,
                align: "left", 
            },
            // 검수구분
            {
                formatter: "select", name: "kIpgo", labelName: "검수구분", type: "text", defaultValue: idata.kIpgo,  
                codeMode: true,
                selectAll: "all", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: idata.ipgoList,
                align: "left", 
            },
            // 조회품번
            {
                formatter: "input", name: "kPtno2", labelName: "조회품번", type: "text", defaultValue: idata.kPtno2,  
                codeMode: false,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: null,
                align: "left", 
            },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
        ]
    }



    /**
     * 조회
     * @param {*} e
     * @returns
     */
    const onSearch = async (e) => {
        setClearGrid(true); // 그리드 clear 하기 
        
        //조회누를때 합계조회
        onSearchCommon(`{"vnt1": "${idata.kVnt1}", "fpdt": "${idata.kFpdt.replaceAll('-', '')}", "tpdt": "${idata.kTpdt.replaceAll('-', '')}",
        "comp": "${idata.kComp}", "plnt": "${idata.kPlnt}", "ptno": "${idata.kPtno2}", "ipgo": "${idata.kIpgo}"}`, "headerList");
        
        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);
        
        let para = `{
                        "vnt1": "${idata.kVnt1}", "fpdt": "${idata.kFpdt.replaceAll('-', '')}", "tpdt": "${idata.kTpdt.replaceAll('-', '')}",
                        "comp": "${idata.kComp}", "plnt": "${idata.kPlnt}", "ptno": "${idata.kPtno2}", "ipgo": "${idata.kIpgo}"
                     }`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/rom91/search?query=" + param;
        setSearchApi(query); // Search API

        idata.gubn = "S";
    }



    /**
     * 신규 Row추가
     * @param {*} e
     */
    const onAddRow = async (e) => {
    }



    /**
     * 입력 (발행)
     * @param {*} e
     */
    const onInsert = async (e) => {

    }



    /**
     * 수정
     * @param {*} e
     * @returns
     */
    const onUpdate = async (e) => {
    }

    /**
     * 삭제
     * @param {*} e
     */
    const onDelete = async (e) => {
    }

    /**
     * 엑셀
     * @param {*} e
     */
    const onExcel = async (e) => {
        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);
        
        let para = `{
                        "vnt1": "${idata.kVnt1}", "fpdt": "${idata.kFpdt.replaceAll('-', '')}", "tpdt": "${idata.kTpdt.replaceAll('-', '')}",
                        "comp": "${idata.kComp}", "plnt": "${idata.kPlnt}", "ptno": "${idata.kPtno2}", "ipgo": "${idata.kIpgo}",
                        "vnt1Nm":"${idata.kVnt1Nm}","compNm":"${idata.kCompNm}","plntNm":"${idata.kPlnt===""?"전체":idata.kPlntNm}","ipgoNm":"${idata.kIpgo===""?"전체":idata.kIpgo}"
                     }`;

        let param = encodeURI(para);

        const query = SERVER_URL + "/rom91/excel?query=" + param;
        //엑셀파일 조회 시 반응
        setLoading(true);
        await fetch(query, {headers: {Authorization: token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'rom91.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })
        setLoading(false);

        // 엑셀파일 생성 메세지
        setMessage({ openAlert : true, 
                     severity : "success",
                     msg : "엑셀파일이 생성되었습니다."});
    }

    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});



        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans)
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D2" :
                break;
            case "D3" :
                break;
        }
    }

    /**
     *Grid Column 정의
     */
        // 월별 컬럼
    const mainColumnDefs = [ 
        {
        headerName: '검수일',//NPQTY1
        children: [
            { headerName: "",field: "CRDT", sortable: false,resizable: true,filter: false, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,valueFormatter: Utils.dateFormatter,pinned: 'left', hide: false 
            ,headerClass: 'header-green'},
        ]
    },
    {
        headerName: '공장',//NPQTY1
        children: [
            { headerName: "", field: "PLNT", sortable: false, resizable:true,filter: false, flex: 1, Width: 50, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, pinned:'left',
            headerClass: 'header-green'},
        ]
    },
    {
        headerName: '품번',//NPQTY1
        children: [
            { headerName: "", field: "PTNO", sortable: false, resizable:true,filter: false, flex: 1, minWidth: 100, editable: false, cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false, pinned:'left',
            headerClass: 'header-green',valueFormatter:Utils.partnoFormatter}
        ]
    },
    {
        headerName: '품명',//NPQTY1
        children: [
            { headerName: "합계", field: "PTNM", sortable: false, resizable:true,filter: false, flex: 1, minWidth: 250, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false, pinned:'left',
            headerClass: 'header-green'},
        ]
    },

    {
        headerName: '소스',//NPQTY1
        children: [
            { headerName: "", field: "IGSR", sortable: false,resizable:true, filter: false, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,
            headerClass: 'header-green'},
        ]
    },
    {
        headerName: '구분',//NPQTY1
        children: [
            { headerName: "", field: "IGGB", sortable: false, filter: false, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,
            headerClass: 'header-green'},
        ]
    },
    {
        headerName: '검수금액',//NPQTY1  
        children: [
            { headerName: idata.gubn===""?"":idata.headerList[0].SUMISAM, field: "ISAM", sortable: false, filter: false, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,
              valueFormatter:Utils.numberFormatter},
        ]
    },
    {
        headerName: '포장금액',//NPQTY1  
        children: [
            { headerName: idata.gubn===""?"":idata.headerList[0].SUMPJAM, field: "PJAM", sortable: false, filter: false, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,},
        ]
    },
    {
        headerName: '입고일자',//NPQTY1
        children: [
            { headerName: "", field: "IGDT", sortable: false, filter: false, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,
            headerClass: 'header-green',valueFormatter:Utils.dateFormatter},
        ]
    },
    {
        headerName: '입고량',//NPQTY1  
        children: [
            { headerName: idata.gubn===""?"":idata.headerList[0].SUMIGQT, field: "IGQT", sortable: false, filter: false, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter},
        ]
    },
    {
        headerName: '입고단가',//NPQTY1
        children: [
            { headerName: "", field: "IGPR", sortable: false, resizable:true,filter: false, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,
            headerClass: 'header-green',valueFormatter:Utils.numberFormatter},
        ]
    },
    {
        headerName: '입하일자',//NPQTY1
        children: [
            { headerName: "", field: "DLDT", sortable: false, filter: false, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, 
            headerClass: 'header-green',valueFormatter:Utils.dateFormatter},
        ]
    },
    {
        headerName: '입하량',//NPQTY1  
        children: [
            { headerName: idata.gubn===""?"":idata.headerList[0].SUMIHQT, field: "IHQT", sortable: false, filter: false, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
        ]
    },
    {
        headerName: '검수코드내역',//NPQTY1
        children: [
            { headerName: "", field: "CDNM", sortable: false,resizable:true, filter: false, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false, 
            headerClass: 'header-green'},
        ]
    },
    {
        headerName: '납품서번호',//NPQTY1
        children: [
            { headerName: "", field: "DLNO", sortable: false,resizable:true, filter: false, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, 
            headerClass: 'header-green'},
        ]
    },
    {
        headerName: '판매문서(from)',//NPQTY1
        children: [
            { headerName: "", field: "FROD", sortable: false,resizable:true, filter: false, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, 
            headerClass: 'header-green'},
        ]
    },
    {
        headerName: ' 판매문서(to)',//NPQTY1
        children: [
            { headerName: "", field: "TOOD", sortable: false,resizable:true, filter: false, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, 
            headerClass: 'header-green'},
        ]
    },
    {
        headerName: '납품서 순번',//NPQTY1
        children: [
            { headerName: "", field: "DSEQ", sortable: false, filter: false, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,
            headerClass: 'header-green'},
        ]
    },
        ];

        const onClick = async (e) => {
            setPanded(!panded);
    
    
            if(panded===true){
                setStyle({       
                    height :"calc(100vh - 60px - 100px - 10px)"
                    });
            }else{
                setStyle({
                    height :"calc(100vh - 60px - 50px - 100px  - 50px)"
                    });
            }
        }

    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg}
                         onClose={
                             ()=> {
                                 setMessage({...message, openAlert: false});
                             }
                         }
                         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} />

            {/* Loading */}
            <Loading loading={loading} /> 

            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onSearch={onSearch} onSearchName={null}     //조회
                onExcel={onExcel} onExcelName ={null}   //Excel
            >
                {"납입지시 > 현황관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>검수현황</b>
            </Ribbon>

            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick={onClick}/>

            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:100px */}
                <div className={"grid_type01"}> {/* 상단 FRAME */}
                    <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                        <Grid
                            ref={grdRom91Main}
                            gridId={"ROM91"}                    //  (필수) Grid Id 정의
                            columnDefs={mainColumnDefs}             //  (필수) 컬럼 정의
                            pagination={true}                  //  쪽수 매기기 여부
                            paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                            paginationPageSize={"500"}         //  쪽수 1Page 당 Row수
                            rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                            suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                            rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                            enableRangeSelection={false}        //
                            enableCellTextSelection={true}      // cell selection drag mode
                            isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                            displayNo = {false}
                            searchApi={searchApi}               // 조회
                            // insertApi={insertApi}               // 등록
                            // insertParam={inserts}               // 등록 및 수정시 Parmeter
                            // insertRefresh={()=> {onSearch(); onSearch2();} } // 등록이후
                            onClearApi={onClearApi}             // 초기화
                            clearGrid={clearGrid}
                            onClearGridFlag={setClearGrid}
                            // onCellValueChangedEvent={grdRom3YMain_onCellValueChanged}
                            onRowClickEvent={null}
                            //updateApi={updateApi}               // 수정
                            //deleteApi={deleteApi}               // 삭제
                            //updateRefresh={onSearch}            // 수정이후
                            //deleteRefresh={onSearch}            // 삭제이후
                        />
                    </div>
                </div>
            </Content>
        </>
    )
}
export default ROM91;