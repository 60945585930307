/**
 * @Page CkdCardPrint
 * @Description CkdCardPrint 납입카드 인쇄
 **/
import React from 'react';
import * as Common from '@/utils/Common'; // Common.js
import styled from 'styled-components';
import QRCode from 'react-qr-code';


const underDiv = { width: '190mm', borderWidth: '0px', borderStyle: 'solid', /* marginBottom: "8mm", */ height: '27.5mm' };
const underTable = { border: '0px', borderCollapse: 'collapse', borderStyle: 'none', padding: '0px', width: '100%' };

class CkdCardPrint extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//  nplx: "0",
			//  nply: "0",
			pathNm: null,
		};
	}

	componentDidMount() {

		if (window.location.pathname.split('/')[2] === 'ROM3K' || window.location.pathname.split('/')[2] === 'ROMAB') {
			// ROM3K 납품서재인쇄, ROMAB 그룹발행재인쇄 check
			this.setState({ pathNm: window.location.pathname.split('/')[2] });
		}
	}

	render() {
		const posx = this.props.pos && this.props.pos.hasOwnProperty('posx') && this.props.pos.posx === null ? 0 : 11 + parseInt(this.props.pos.posx, 10) * 2;
		const posy = this.props.pos && this.props.pos.hasOwnProperty('posy') && this.props.pos.posy === null ? 0 : 22.67 + parseInt(this.props.pos.posy * 3.7795275591, 10) * 2;

		const PrintStyle = styled.div`
			@media print {
				@page {
					size: A4 landscape;
					margin-top: ${posy}px;
					margin-left: ${posx}mm;
					margin-right: 10mm;
					margin-bottom: 4mm;
				}

				body {
					background-color: ;
					margin: 0px;
					padding: 0px;
					font-family: 'ChosunGu';
				}
				div {
					background-color: ;
					margin: 0px;
					padding: 0px;
					box-sizing: border-box;
				}
				table {
					border-collapse: collapse;
					border-style: none;
					margin: 0px;
					padding: 0px;
					box-sizing: border-box;
				}
				tr {
					width: 100%;
					margin: 0px;
					padding: 0px;
				}
				td {
					margin: 0px;
					padding: 0px;
					background-color: white;
					font-family: 'ChosunGu';
					border: 0px solid black;
					box-sizing: border-box;
				}

				.wrapDiv {
					width: 190mm;
					height: 132mm;
					box-sizing: border-box;
					border-style: dotted;
					border-width: 0px;
					margin-bottom: 0px;
				}
				.headInfoStyle {
					height: 14px;
					margin-bottom: 2px;
					font-size: 10pt;
					font-family: 'ChosunGu';
					letter-spacing: 0px;
				}
				.seqNumberDiv {
					position: absolute;
					background: white;
					text-align: center;
					z-index: 1;
					border-radius: 100%;
					font-size: 10pt;
				}
				.seqNumberStyle {
					text-align: center;
					border: 0px;
					padding: 0;
					margin: 0;
					width: 36px;
					font-size: 9pt;
					height: 0mm;
				}
				.seqTdStyle {
					border: 0px;
					padding: 0;
					margin: 0;
					width: 10mm;
					font-size: 15pt;
					vertical-align: top;
					font-family: '3of9Barcode';
				}
				.barcodeTableStyle {
					border-collapse: collapse;
					border-spacing: 0;
					border: 0px;
					width: 100%;
				}
				.barcodeTdStyle {
					border: 0px;
					padding: 0;
					margin: 0;
					font-size: 18.5pt;
					vertical-align: top;
					font-family: '3of9Barcode';
					padding-left: 8px;
				}

				.seqTableStyle {
					border-collapse: collapse;
					border-spacing: 0;
					border: 0px;
					width: 726px;
					height: 11mm;
				}
				.detailTableStyle {
					border-collapse: collapse;
					border-spacing: 0;
					border: 0px;
					width: 718.11px;
					table-layout: fixed;
					height: 70mm;
				}
				.ckdpartNoTh {
					border: 1px solid #2e2e2e;
					text-align: center;
					font-size: 9pt;
					font-family: 'ChosunGu';
					vertical-align: middle;
					height: 4.5mm;
				}
				.noTd2 {
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-color: black;
					text-align: center;
					font-size: 10pt;
					vertical-align: middle;
					font-weight: bold;
				}
				.partNoTD {
					text-align: left;
					font-size: 11pt;
					vertical-align: middle;
					font-family: 'ChosunBg';
				}
				.bcccTd {
					text-align: center;
					font-size: 9pt;
					vertical-align: middle;
					font-family: 'ChosunGu';
					padding-left: 17px;
				}
				.tdptnm {
					text-align: left;
					font-size: 7pt;
					vertical-align: middle;
					border-bottom-width: 0.5px;
					border-bottom-style: solid;
					border-color: black;
					font-family: 'ChosunGu';
					white-space: nowrap;
				}

				.gateTd {
					text-align: left;
					font-size: 9pt;
					vertical-align: middle;
					border-color: black;
					padding-left: 10px;
					font-family: 'ChosunBg';
					letter-spacing: -1px;
				}
				.caseQty {
					text-align: center;
					font-size: 10pt;
					vertical-align: middle;
					font-family: 'ChosunGu';
					padding-right: 7px;
				}
				.pmgbTd {
					text-align: right;
					font-size: 12pt;
					vertical-align: middle;
					font-weight: bold;
					font-family: 'ChosunBg';
					padding-right: 7px;
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-color: black;
				}
				.npqtTd {
					text-align: center;
					vertical-align: middle;
					font-size: 11pt;
					font-family: 'ChosunBg';
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-color: black;
				}
				.oknmTd {
					text-align: center;
					vertical-align: middle;
					font-size: 10pt;
					font-family: 'ChosunBg';
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-color: black;
				}
				.frodTd {
					text-align: center;
					font-size: 9pt;
					vertical-align: middle;
					font-family: 'ChosunGu';
					padding-right: 7px;
				}
				.toodTd {
					text-align: center;
					font-size: 8pt;
					vertical-align: middle;
					font-family: 'NanumGothic';
					padding-right: 7px;
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-color: black;
					background-color: transparent;
				}
				.qtyTd {
					text-align: right;
					vertical-align: middle;
					font-size: 9pt;
					font-family: 'NanumGothic';
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-color: black;
					padding-right: 5px;
				}
				.nidtTd {
					text-align: left;
					vertical-align: middle;
					font-size: 9pt;
					font-family: 'NanumGothic';
					padding-left: 10px;
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-color: black;
				}

				div.pagebreak {
					break-after: always !important;
					page-break-after: always !important;
					page-break-inside: avoid !important;
				}
			}

			@media screen {
				div {
					background-color: ;
					margin: 0px;
					padding: 0px;
					box-sizing: border-box;
				}
				table {
					border-collapse: collapse;
					margin: 0px;
					padding: 0px;
					box-sizing: border-box;
				}
				tr {
					width: 100%;
					margin: 0px;
					padding: 0px;
				}
				td {
					margin: 0px;
					padding: 0px;
					background-color: white;
					font-family: 'ChosunBg';
					border: 1px solid black;
					box-sizing: border-box;
				}

				.wrapDiv {
					width: 190mm;
					height: 132mm;
					box-sizing: border-box;
					border-style: dotted;
					border-width: 0px;
					margin-bottom: 0px;
				}
				.headInfoStyle {
					height: 14px;
					margin-bottom: 2px;
					font-size: 10pt;
					font-family: 'ChosunGu';
					letter-spacing: 0px;
				}
				.seqNumberStyle {
					text-align: center;
					border: 0px;
					padding: 0;
					margin: 0;
					width: 36px;
					font-size: 9pt;
					height: 0mm;
				}
				.seqTdStyle {
					border: 0px;
					padding: 0;
					margin: 0;
					width: 10mm;
					font-size: 15pt;
					vertical-align: top;
					font-family: '3of9Barcode';
				}
				.barcodeTableStyle {
					border-collapse: collapse;
					border-spacing: 0;
					border: 0px;
					width: 100%;
				}
				.barcodeTdStyle {
					border: 0px;
					padding: 0;
					margin: 0;
					font-size: 18.5pt;
					vertical-align: top;
					font-family: '3of9Barcode';
					padding-left: 8px;
				}

				.seqTableStyle {
					border-collapse: collapse;
					border-spacing: 0;
					border: 0px;
					width: 726px;
					height: 11mm;
				}
				.detailTableStyle {
					border-collapse: collapse;
					border-spacing: 0;
					border: 0px;
					width: 718.11px;
					table-layout: fixed;
					height: 70mm;
				}
				.ckdpartNoTh {
					border: 0.5px solid #2e2e2e;
					text-align: center;
					font-size: 10pt;
					font-family: 'ChosunGu';
					vertical-align: middle;
					height: 4mm;
				}
				.noTd2 {
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-color: black;
					text-align: center;
					font-size: 10pt;
					font-family: 'ChosunGu';
					vertical-align: middle;
				}
				.partNoTD {
					text-align: left;
					font-size: 11pt;
					vertical-align: middle;
					font-family: 'ChosunBg';
				}
				.bcccTd {
					text-align: center;
					font-size: 9pt;
					vertical-align: middle;
					font-family: 'ChosunGu';
					padding-left: 17px;
				}
				.tdptnm {
					text-align: left;
					font-size: 7pt;
					vertical-align: middle;
					border-bottom-width: 0.5px;
					border-bottom-style: solid;
					border-color: black;
					font-family: 'ChosunGu';
					white-space: nowrap;
				}

				.gateTd {
					text-align: left;
					font-size: 9pt;
					vertical-align: middle;
					border-color: black;
					padding-left: 10px;
					font-family: 'ChosunBg';
					letter-spacing: -1px;
				}
				.caseQty {
					text-align: center;
					font-size: 10pt;
					vertical-align: middle;
					font-family: 'ChosunGu';
					padding-right: 7px;
				}
				.pmgbTd {
					text-align: right;
					font-size: 12pt;
					vertical-align: middle;
					font-family: 'ChosunBg';
					padding-right: 7px;
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-color: black;
				}
				.npqtTd {
					text-align: center;
					vertical-align: middle;
					font-size: 11pt;
					font-family: 'ChosunBg';
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-color: black;
				}
				.oknmTd {
					text-align: center;
					vertical-align: middle;
					font-size: 10pt;
					font-family: 'ChosunBg';
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-color: black;
				}
				.frodTd {
					text-align: center;
					font-size: 9pt;
					vertical-align: middle;
					font-weight: bold;
					font-family: 'ChosunGu';
					padding-right: 7px;
				}
				.toodTd {
					text-align: center;
					font-size: 8pt;
					vertical-align: middle;
					font-family: 'NanumGothic';
					padding-right: 7px;
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-color: black;
					background-color: transparent;
				}
				.qtyTd {
					text-align: right;
					vertical-align: middle;
					font-size: 9pt;
					font-family: 'NanumGothic';
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-color: black;
					padding-right: 5px;
				}
				.nidtTd {
					text-align: left;
					vertical-align: middle;
					font-size: 9pt;
					font-family: 'NanumGothic';
					padding-left: 10px;
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-color: black;
				}
				div.pagebreak {
					break-after: always !important;
					page-break-after: always !important;
					page-break-inside: avoid !important;
				}
			}
		`;

		const { printData } = this.props;

		const mmToPx = (mm) => {
			let px = mm * 3.7795275591;
			return px;
		};

		const cardInfo = printData
			? printData.map((data, index) => {
					const seqBarcodeList = data.detail.map((item, index) => {
						const blankTdArray = [];
						if (index === data.detail.length - 1) {
							let blankTdCount = 8 - data.detail.length;

							for (let i = 0; i < blankTdCount; i++) {
								blankTdArray.push(
									<>
										<td
											style={
												index === 1
													? { width: '125px' }
													: index === 2
													? { width: '124px' }
													: index === 3
													? { width: '122px' }
													: index === 4
													? { width: '116.5px' }
													: index === 5
													? { width: '110px' }
													: { width: '80px' }
											}
										></td>
									</>,
								);
							}
						}
						return (
							<>
								{item.GUBN != 'B' ? (
									<td className="seqTdStyle" style={index === 0 ? { width: '10.25mm' } : { width: '10mm' }}>
										*{item.DSEQ}*
									</td>
								) : (
									<td className="seqTdStyle"></td>
								)}
								<td style={index === 7 ? { width: '0mm' } : { width: 58 + 'px' }}></td>
								{blankTdArray}
							</>
						);
					});

					const seqBarcodeList2 = data.detail.map((item, index) => {
						const blankTdArray = [];
						if (index === data.detail.length - 1) {
							let blankTdCount = 8 - data.detail.length;

							for (let i = 0; i < blankTdCount; i++) {
								blankTdArray.push(
									<>
										<td
											style={
												index === 1
													? { width: '125px' }
													: index === 2
													? { width: '124px' }
													: index === 3
													? { width: '122px' }
													: index === 4
													? { width: '116.5px' }
													: index === 5
													? { width: '110px' }
													: { width: '80px' }
											}
										></td>
									</>,
								);
							}
						}
						return (
							<>
								{item.GUBN != 'B' ? (
									<td
										className="seqTdStyle"
										style={
											index === 0
												? { width: '10.25mm', position: 'relative', top: '-4px', backgroundColor: 'transparent' }
												: { width: '10mm', position: 'relative', top: '-4px', backgroundColor: 'transparent' }
										}
									>
										*{item.DSEQ}*
									</td>
								) : (
									<td className="seqTdStyle"></td>
								)}
								<td style={index === 7 ? { width: '0mm' } : { width: 58 + 'px' }}></td>
								{blankTdArray}
							</>
						);
					});

					const seqList = data.detail.map((item) => {
						return (
							<>
								{item.GUBN != 'B' ? (
									<td className="seqNumberStyle" style={{ position: 'relative', top: '-4px', textAlign: 'left', backgroundColor: 'transparent', paddingLeft: '16px' }}>
										{item.DSEQ}
									</td>
								) : (
									<td className="seqNumberStyle" style={{ width: '10mm' }}></td>
								)}
								<td style={{ display: 'block' }}></td>
							</>
						);
					});
					const simpleArray = [1, 2];
					const chek = data.detail[0].GUBN;
					const barcodeValu = data.head.dlno;

					const gate = data.detail.map((item, index) => {
						return <>{index === 0 ? item.GATE : ''}</>;
					});

					const plnm = data.detail.map((item, index) => {
						return <>{index === 0 ? item.PLNM : ''}</>;
					});

					const prif = data.detail.map((item, index) => {
						return <>{index === 0 ? item.PRIF : ''}</>;
					});

					const cano = data.detail.map((item, index) => {
						return <>{index === 0 ? item.CANO : ''}</>;
					});

					return (
						<>
							{simpleArray.map((n, simpleIndex) => (
								<div key={data.head.dlno + simpleIndex} className="wrapDiv">
									<div style={{ width: '100%', height: mmToPx(22) }}>
										<div style={{ float: 'left', width: mmToPx(76) , textAlign: 'left' }}>
											<div className="headInfoStyle">
												공급자 : {data.head.vnt1} {data.head.vdnm}
											</div>
											<div className="headInfoStyle">발주자 : {data.head.conm}</div>
											<div className="headInfoStyle">
												납품처 : {plnm}&nbsp;&nbsp;{gate}
											</div>
											<div className="headInfoStyle">
												납품일 : {prif}
												{this.state.pathNm === 'ROM3K' || this.state.pathNm === 'ROMAB' ? ' **' : ''}
											</div>
											<div className="headInfoStyle">납품서 No : {data.head.dlno}</div>
										</div>

										<div style={{ float: 'left', width: mmToPx(30), position: 'relative', backgroundColor: 'transparent', paddingTop: mmToPx(7), height: mmToPx(10) }}>
											<div style={{ float: 'left', fontSize: '15pt', width: '50mm', fontFamily: 'ChosunGu', position: 'relative', top: '-10px', backgroundColor: 'transparent' }}>
												납입CARD({n === 1 ? '갑' : '을'})
											</div>
											<div
												style={{
													float: 'left',
													fontSize: '12pt',
													width: '35mm',
													fontFamily: 'ChosunGu',
													position: 'relative',
													top: '-5px',
													backgroundColor: 'transparent',
													textAlign: 'center',
												}}
											>
												[ 현대 ]
											</div>
										</div>

										<div style={{ float: 'left', width: mmToPx(46), position: 'relative', backgroundColor: 'transparent', paddingTop: mmToPx(5), height: mmToPx(10) }}></div>

										<div style={{ float: 'left', width: mmToPx(16), paddingTop: mmToPx(4), height: mmToPx(10) /* paddingLeft:mmToPx(5) */ }}>
											<div style={{ width: mmToPx(14), height: mmToPx(16), float: 'left' }}>
												<QRCode style={{ height: mmToPx(16), maxWidth: mmToPx(16), width: mmToPx(16) }} value={data.head.dlno} level={'M'} />
											</div>
										</div>
										<div
											style={{
												fontFamily: 'ChosunGu',
												fontSize: '10pt',
												textAlign: 'end',
												float: 'right',
												width: mmToPx(22),
												paddingTop: mmToPx(4),
												height: mmToPx(10),
												paddingTop: mmToPx(17.75),
											}}
										>
											ⓡ
										</div>
									</div>
									<div style={{ clear: 'both' }}></div>
									<table className="detailTableStyle">
										<col width="8mm" />
										<col width="59mm" />
										<col width="23mm" />
										<col width="12mm" />
										<col width="19mm" />
										<col width="25mm" />
										<col width="23mm" />
										<col width="9mm" />
										<col width="8mm" />
										<col width="36mm" />
										<tbody>
											<tr style={{ height: '2mm' }}></tr>
											{/* 추가 ROW */}
											<tr style={{ height: mmToPx(4.2) }}>
												<td className="ckdpartNoTh" style={{ borderBottomStyle: 'none' }}>
													순
												</td>
												<td className="ckdpartNoTh">품&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;번</td>
												<td className="ckdpartNoTh" style={{ borderBottomStyle: 'none' }}>
													납&nbsp;&nbsp;&nbsp;입
												</td>
												<td className="ckdpartNoTh" style={{ borderBottomStyle: 'none' }}>
													검수
												</td>
												<td className="ckdpartNoTh" style={{ letterSpacing: '0px' }}>
													납입장소
												</td>
												{chek != 'B' ? ( //B = 그룹발행
													<td className="ckdpartNoTh">발주번호</td>
												) : (
													<td className="ckdpartNoTh" style={{ borderBottomStyle: 'none' }}>
														발&nbsp;&nbsp;&nbsp;주
													</td>
												)}
												{chek != 'B' ? (
													<td className="ckdpartNoTh" style={{ fontSize: '8pt', letterSpacing: '-1px' }}>
														Case No
													</td>
												) : (
													<td className="ckdpartNoTh" style={{ borderBottomStyle: 'none' }}>
														납&nbsp;&nbsp;&nbsp;입
													</td>
												)}
												<td></td>
												<td className="ckdpartNoTh" style={{ borderBottomStyle: 'none', fontSize: '9pt', letterSpacing: '1px' }}>
													포
												</td>
												<td className="ckdpartNoTh" style={{ fontSize: '9pt', letterSpacing: '-1px' }}>
													FROM ORDER
												</td>
											</tr>
											<tr style={{ height: mmToPx(4.2) }}>
												<td className="ckdpartNoTh" style={{ borderTopStyle: 'none' }}>
													위
												</td>
												<td className="ckdpartNoTh">품&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;명</td>
												<td className="ckdpartNoTh" style={{ borderTopStyle: 'none' }}>
													수&nbsp;&nbsp;&nbsp;량
												</td>
												<td className="ckdpartNoTh" style={{ borderTopStyle: 'none' }}>
													구분
												</td>
												<td className="ckdpartNoTh" style={{ borderTopStyle: 'none', letterSpacing: '1px' }}>
													납입일
												</td>
												{chek != 'B' ? (
													<td className="ckdpartNoTh" style={{ borderTopStyle: 'none' }}>
														발주수량
													</td>
												) : (
													<td className="ckdpartNoTh" style={{ borderTopStyle: 'none' }}>
														수&nbsp;&nbsp;&nbsp;량
													</td>
												)}
												{chek != 'B' ? (
													<td className="ckdpartNoTh">납입잔량</td>
												) : (
													<td className="ckdpartNoTh" style={{ borderTopStyle: 'none' }}>
														잔&nbsp;&nbsp;&nbsp;량
													</td>
												)}
												<td></td>
												<td className="ckdpartNoTh" style={{ borderTopStyle: 'none', fontSize: '9pt' }}>
													장
												</td>
												<td className="ckdpartNoTh" style={{ fontSize: '9pt', letterSpacing: '-1px' }}>
													TO ORDER
												</td>
											</tr>
											<tr>
												<td style={{ height: '3px', border: '0px' }}></td>
											</tr>
											{data.detail.map((item, dindex) => {
												const blankTrArray = [];
												if (dindex === data.detail.length - 1) {
													let blankTdCount = 8 - data.detail.length;

													for (let i = 0; i < blankTdCount; i++) {
														blankTrArray.push(
															<>
																<tr>
																	<td style={{ height: '4mm' }}></td>
																	<td style={{ height: '4mm' }}></td>
																	<td style={{ height: '4mm' }}></td>
																	<td style={{ height: '4mm' }}></td>
																	<td style={{ height: '4mm' }}></td>
																	<td style={{ height: '4mm' }}></td>
																	<td style={{ height: '4mm' }}></td>
																	<td style={{ height: '4mm' }}></td>
																	<td style={{ height: '4mm' }}></td>
																	<td style={{ height: '4mm' }}></td>
																</tr>
																<tr>
																	<td style={{ height: '4mm', borderBottom: '0.1mm solid white' }}></td>
																	<td style={{ height: '4mm', borderBottom: '0.1mm solid white' }}></td>
																	<td style={{ height: '4mm', borderBottom: '0.1mm solid white' }}></td>
																	<td style={{ height: '4mm', borderBottom: '0.1mm solid white' }}></td>
																	<td style={{ height: '4mm', borderBottom: '0.1mm solid white' }}></td>
																	<td style={{ height: '4mm', borderBottom: '0.1mm solid white' }}></td>
																	<td style={{ height: '4mm', borderBottom: '0.1mm solid white' }}></td>
																	<td style={{ height: '4mm', borderBottom: '0.1mm solid white' }}></td>
																	<td style={{ height: '4mm', borderBottom: '0.1mm solid white' }}></td>
																	<td style={{ height: '4mm', borderBottom: '0.1mm solid white' }}></td>
																</tr>
															</>,
														);
													}
												}
												var prcd = '';
												if (item.PRCD && item.PRCD.trim() !== '') {
													if (n === 1) {
														if (item.PLNT === 'HV12' || item.PLNT === 'HV14' || item.PLNT === 'HV15') {
															prcd = `[${item.PRCD}]`;
														}
													} else {
														prcd = `[${item.PRCD}]`;
													}
												}
												return (
													<>
														<tr key={item.PTNO + 'R1' + index + '' + n} style={{ height: '4mm' }}>
															<td className="noTd2" rowSpan="2">
																{item.DSEQ}
																{/*순위 */}
															</td>
															<td className="partNoTD" style={{ position: 'relative', bottom: '-2px', paddingLeft: '4px', lineHeight: '15px' }}>
																{' '}
																{item.PTNO1}
															</td>
															{/*품번 */}
															<td className="npqtTd" rowSpan="2">
																{item.NPQT}
																{/*납입수량*/}
															</td>
															<td className="oknmTd" rowSpan="2" style={{ textAlign: 'left', paddingLeft: '2px' }}>
																{' '}
																{item.OKNM}
															</td>
															{/*검수구분 */}
															<td className="gateTd" style={{ position: 'relative', bottom: '-2px', fontWeight: 'bold' }}>
																{item.GATE}
															</td>
															{/*납입장소 */}
															{
																chek != 'B' ? (
																	<td className="bcccTd" style={{ position: 'relative', bottom: '-2px', textAlign: dindex === 0 ? 'center' : 'left' }}>
																		{dindex === 0 ? item.BCCC : item.BCCC === '정상발행' ? 'A' : 'B'}
																	</td> //발주번호
																) : (
																	<td
																		className="npqtTd"
																		rowSpan="2"
																		style={{ textAlign: 'right', fontWeight: '100', verticalAlign: 'middle', paddingRight: '17px', fontSize: '10pt' }}
																	>
																		{item.PLQT}
																	</td>
																) //그룹발행 => 발주수량
															}
															{
																chek != 'B' ? (
																	<td className="caseQty" style={{ position: 'relative', bottom: '-2px' }}>
																		{item.CASE1}
																	</td> //case no
																) : (
																	<td
																		className="npqtTd"
																		rowSpan="2"
																		style={{ textAlign: 'right', fontWeight: '100', verticalAlign: 'middle', paddingRight: '17px', fontSize: '10pt' }}
																	>
																		{item.JAQT}
																	</td>
																) //그룹발행=>납입잔량
															}
															<td></td>
															<td className="pmgbTd" rowSpan="2" style={dindex === 7 ? { borderBottom: '0px' } : { borderBottom: '1px solid' }}>
																{item.PMGB} {/* 포장 */}
															</td>
															<td className="frodTd" style={{ position: 'relative', textAlign: 'left', paddingLeft: '25px' }}>
																{item.FROD} {/* FROM ORDER */}
															</td>
														</tr>
														<tr key={item.PTNO + 'R2' + index + '' + n} style={{ height: '4mm' }}>
															<td className="tdptnm" style={{ paddingLeft: '4px', whiteSpace: 'nowrap', letterSpacing: '-1px' }}>
																{item.PTNM}
																{/*품명 */}
																{prcd}
															</td>
															<td className="nidtTd" style={{ verticalAlign: 'bottom' }}>
																{item.NIDT1}
															</td>
															{/*납입일 */}
															{chek != 'B' ? (
																<td className="qtyTd">{item.PLQT}</td> // 발주수량 
															) : (
																''
															)}
															{chek != 'B' ? (
																<td className="qtyTd">{item.JAQT}</td> // 납입잔량 
															) : (
																''
															)}
															<td></td>
															<td className="toodTd" style={{ textAlign: 'left', paddingLeft: '25px', borderBottom: dindex === 7 ? '0px' : '1px solid' }}>
																{item.TOOD} {/* TO ORDER */}
															</td>
														</tr>
														{blankTrArray}
													</>
												);
											})}
										</tbody>
									</table>
									<div style={underDiv}>
										<div style={{ height: '3mm' }}></div>
										{/* 추가 row */}
										<div style={{ float: 'left', width: '144mm' }}>
											{n === 1 ? (
												<>
													<div style={{ height: '4mm', fontSize: '9pt', fontFamily: 'NanumGothic', letterSpacing: '1.2px' }}></div>
													<div style={{ height: '8mm' }}></div>
												</>
											) : (
												<>
													<div style={{ height: '4mm', fontSize: '9pt', fontFamily: 'ChosunGu', letterSpacing: '1.4px' }}>
														※ 본 부품은 회사의 중요한 자산임으로 무단도용, 훼손시 민,형사상의 처벌을 받을 수 있습니다.
													</div>
													<div>
														<div style={{ float: 'left', fontFamily: '휴먼매직체', fontSize: '11pt', textDecoration: 'underline' }}>MEMO</div>
														<div style={{ float: 'right', fontFamily: 'ChosunGu', fontSize: '10pt' }}>[{cano}]</div>
													</div>
												</>
											)}
											<div style={{ height: '12.5mm' }}></div>
										</div>
										<div style={{ float: 'right', width: '38mm' }}>
											<table style={underTable}>
												<tr style={{ height: '4mm' }}>
													<td style={{ fontFamily: 'ChosunGu', fontSize: '9pt', borderWidth: '1px', borderStyle: 'solid', textAlign: 'center', verticalAlign: 'middle' }}>검수</td>
													<td style={{ borderWidth: '1px', borderStyle: 'solid', textAlign: 'center', verticalAlign: 'middle' }}>확인</td>
												</tr>
												<tr>
													<td style={{ fontFamily: 'ChosunGu', fontSize: '9pt', borderWidth: '1px', borderStyle: 'solid', height: '8mm' }}>&nbsp;</td>
													<td style={{ borderWidth: '1px', borderStyle: 'solid', height: '8mm' }}>&nbsp;</td>
												</tr>
											</table>
										</div>
										<div style={{ clear: 'both' }}></div>
									</div>
								</div>
							))}
							{index === printData.length - 1 ? '' : <div className={'pagebreak'}></div>}
						</>
					);
			  })
			: '';
		return <PrintStyle key={'CkdCardPrint'}>{cardInfo}</PrintStyle>;
	}
}
export default CkdCardPrint;
