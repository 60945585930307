import React from 'react';

import '../../css/main.css';

import {Checkbox, FormControlLabel, FormGroup, Tooltip} from '@material-ui/core';


function CustomCheckbox({
    id, name, labelName, defaultValue, options,
    codes, comms, items,
    codeMode, selectAll, onChange, 
    paddingLeft, alignType, labelType,tooltip }) {

        if(id === "kCarc"){
            paddingLeft = "40px";
        }
        if(id === "dupCnt"){
            paddingLeft = "19px";
        }


    const handleChange = (e) => { 
        const { name, checked } = e.target;
        let event = {
            target: {}
        };
        event.target["name"] = name;
        if (checked) {
            event.target["value"] = "Y";
        } else { event.target["value"] = "N"; }

        if (onChange) {
            onChange(event);
        }
    };


    return (
        <>
            <div className={"h_Label1"+(alignType?alignType:'')+" checks" + (labelType ? " h_Label1"+(alignType?alignType:'')+"_" + labelType : "")} style={{ whiteSpace:"pre", paddingTop: "9px", paddingLeft:paddingLeft }}>

                {options ?
                    options.map((option, i) =>
                        <React.Fragment key={"checkbox" + option.name + option.defaultValue + i}>

                            <FormGroup>
                                <div title = {tooltip!=""?tooltip:""} >
                                    <FormControlLabel control=
                                        {<Checkbox 
                                            id={option.name}
                                            checked={option.defaultValue === "N" ? false  : true}
                                            name={option.name}
                                            onChange={handleChange} />} 
                                            label={option.labelName} 
                                    />
                                </div>
                            </FormGroup>

                        </React.Fragment>
                    )

                    : ""}

            </div>
        </>
    );
}

export default CustomCheckbox;