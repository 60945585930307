import React from 'react';

export const FunctionalCustomTooltip = (props) => {
    props.reactContainer.classList.add('custom-tooltip');

    /*Dash Board Tooltip */

    const tooltip_data = props.value;
    const tooltip_result = tooltip_data.replace(/\B(?=(\d{3})+(?!\d))/g, ",").split(', ').filter(i=>i.length !== 0);
    var default_data = '';
    
    const tooltip_check = props.colDef.tooltipField.slice(0,-1);    /*생성현황 그리드에 사용하는 툴팁 체크 */

    /* 기존 툴팁 출력을 위함 */

    if(props.colDef.tooltipField === 'mesg' || props.colDef.tooltipField === 'msg')
        default_data = props.value;
       
    /* 맨 아래 툴팁 출력시  맨 위 툴팁 출력시 밑으로*/
    const x = 0;
    var y = 0;

    
    // if(props.rowIndex >= 5){
    //     y = -100;                
    // }        
    // else{
    //     y = -10;
    // }
        

    const styles = {
        position: "static",
        transform : `translate(${x},${y}%)`,                
        border: default_data == null || default_data ==  "" || default_data == " " ? "none" : "1px solid gray",
        backgroundColor: default_data == null || default_data ==  "" || default_data == " " ? "none" : 'white',        
    }   

    

    return (
        <div className="custom-tooltip" style={styles}>            
            <p><span>{default_data}</span></p>                        
            { tooltip_result.map((x,i) => tooltip_check === 'tooltip' ?
                <p key={i}><span>{x}</span></p> : '') 
            }
        </div>
    );
};
