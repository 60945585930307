// import { Color } from "ag-grid-community";
import React from "react";
// import { Link, withRouter } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import "../../css/main.css";
// import sty from '../css/main.css';


Buttonwrapper.defaultProps = {
  onSearchVisible : true, onSearch2Visible : true,
  onAddRowVisible : true, onInsertVisible : true, 
  onUpdateVisible : true, onDeleteVisible : true, 
  onExcelVisible : true, onPrintVisible : true, 
  onUploadVisible : true, onFunctionVisible : true,
  onFunction2Visible : true
};

function Buttonwrapper({
  children,
  onSearch,
  onSearchName,
  onSearchVisible,
  onSearch2,
  onSearchName2,
  onSearch2Visible,
  onAddRow,
  onAddRowName,
  onAddRowVisible,
  onInsert,
  onInsertName,
  onInsertVisible,
  onUpdate,
  onUpdateName,
  onUpdateVisible,
  onDelete,
  onDeleteName,
  onDeleteVisible,
  onExcel,
  onExcelName,
  onExcelVisible,
  onPrint,
  onPrintName,
  onPrintVisible,
  onUpload,
  onUploadName,
  onUploadVisible,
  onFunction,
  onFunctionName,
  onFunctionType,
  onFunctionVisible,
  onFunction2,
  onFunctionName2,
  onFunctionType2,
  onFunction2Visible,
  tooltip
}) {
  // if (location.pathname === '/login') {
  //     return null;
  // }

  return (
    <>
      <div id={"BTs"}>
        {/* <button onclick="if(this.className!='btn')return; retrieve('','1')" className="btn" alt='조회'><img src="/images/button/btn_search.png" alt="아이콘" />조회</button>
                <button onclick="if(this.className!='btn')return;check_vand('IS')" className="btn" alt='입력' src=""><img src="/images/button/btn_insert.png" alt="아이콘" />입력</button>
                <button onclick="if(this.className!='btn')return;check_vand('UP')" className="btn" alt="수정" src=""><img src="/images/button/btn_update.png" alt="아이콘" />수정</button>
                <button onclick="if(this.className!='btn')return;check_vand('DL');" className="btn" alt='삭제' src=""><img src="/images/button/btn_delete.png" alt="아이콘" />삭제</button>
                <button onclick="if(this.className!='btn')return; changePLNT1();callExcel();" className="btn" alt='엑셀' src=""><img src="/images/button/btn_excel.png" alt="아이콘" />엑셀</button>&nbsp; */}

        {/* 조회 */}
        {onSearch && onSearchVisible ? (
          <button href="#" title="Search" onClick={onSearch}>
            <img src="/images/button/btn_search.png" alt="Search" />
            <span>{onSearchName ? onSearchName : "조회"}</span>
          </button>
        ) : (
          ""
        )}

         {/* Row추가 */}
         {onAddRow && onAddRowVisible ? (
             <button href="#" title="Add" onClick={onAddRow}>
             <img src="/images/button/btn_add.png" alt="Add"
              style= {{width : '19px', height :'19px'}} />
             <span>{onAddRowName ? onAddRowName : "신규"}</span>
           </button>
        ) : (
          ""
        )}


        {/* 입력 저장 */}
        {onInsert && onInsertVisible ? (
          <button href="#" title="Insert" onClick={onInsert} >
            <img src="/images/button/btn_insert.png" alt="Insert"/>
            <span>{onInsertName ? onInsertName : "입력"}</span>
          </button>
        ) : (
          ""
        )}

        {/* 수정 저장 */}
        {onUpdate && onUpdateVisible ? (
          <button href="#" title="Update" onClick={onUpdate}>
            <img src="/images/button/btn_update.png" alt="Update" />
            <span>{onUpdateName ? onUpdateName : "수정"}</span>
          </button>
        ) : (
          ""
        )}

        {/* 삭제 */}
        {onDelete && onDeleteVisible ? (
          <button href="#" title="Delete" onClick={onDelete}>
            <img src="/images/button/btn_delete.png" alt="Delete" />
            <span>{onDeleteName ? onDeleteName : "삭제"}</span>
          </button>
        ) : (
          ""
        )}

        

        {/* 출력 */}
        {onPrint && onPrintVisible ? (
          <button id ="printid" href="#" title={tooltip?tooltip:"Print"} onClick={onPrint} >
            <img src="/images/button/btn_print.png" alt="Print" />
            <span>{onPrintName ? onPrintName : "출력"}</span>
          </button>
        ) : (
          ""
        )}

        {/* 업로드 */}
        {onUpload && onUploadVisible ? (
          <button href="#" title="Upload" onClick={onUpload}>
            <img src="/images/button/btn_upload.png" alt="Upload"/>
            <span>{onUploadName ? onUploadName : "자료생성"}</span>
          </button>
        ) : (
          ""
        )}



        {/* 조회2 (초기화)*/}
        {onSearch2 && onSearch2Visible ? (
          <button href="#" title="Search2" onClick={onSearch2}>
            <img src="/images/button/btn_search2.png" alt="search2"
             style= {{width : '19px', height :'19px'}}/>
            <span>{onSearchName2 ? onSearchName2 : "초기화"}</span>
          </button>
        ) : (
          ""
        )}

        {/* FUNCTION - 동적 */}
        {onFunction &&  onFunctionVisible? (
          <button href="#" title={onFunctionType} onClick={onFunction}>
            <img src={"/images/button/btn_" + onFunctionType + ".png"} alt={onFunctionType} />
            <span>{onFunctionName ? onFunctionName : ""}</span>
          </button>
        ) : (
          ""
        )}

         {/* FUNCTION - 동적 */}
         {onFunction2 &&  onFunction2Visible? (
          <button href="#" title={onFunctionType2} onClick={onFunction2}>
            <img src={"/images/button/btn_" + onFunctionType2 + ".png"} alt={onFunctionType2} />
            <span>{onFunctionName2 ? onFunctionName2 : ""}</span>
          </button>
        ) : (
          ""
        )}

        {/* 엑셀 내보내기*/}
        {onExcel && onExcelVisible ? (
          <button href="#" title="Excel" onClick={onExcel}>
            <img src="/images/button/btn_excel.png" alt="Excel" />
            <span>{onExcelName ? onExcelName : "엑셀"}</span>
          </button>
        ) : (
          ""
        )}

        {children}

      </div>



   

      {/* {children} */}

      {/* <button onclick="if(this.className!='btn')return; retrieve('','1')" className="btn" alt="조회"><img src="/images/button/btn_search.png" alt="아이콘" />조회</button> */}
      {/* <img src="/rapdos/images/button_ins.gif" alt="등록" className="button" onclick="if(this.className!='button')return;sendForm('MT');"> */}

      {/* <button href="#">
                    <img src="/images/button/btn_search.png" alt="Search" /><span>입력</span>
                </button>
                <button href="#">
                    <img src="/images/button/btn_insert.png" alt="Insert" /><span>입력</span>
                </button>
                <button href="#">
                    <img src="/images/button/btn_update.png" alt="Update" /><span>수정</span>
                </button>
                <button href="#">
                    <img src="/images/button/btn_delete.png" alt="Delete" /><span>삭제</span>
                </button>
                <button href="#">
                    <img src="/images/button/btn_excel.png" alt="Excel" /><span>엑셀</span>
                </button> */}

      {/* <button href="#">
                    <span className={"btn_search"}></span><span>조회</span>
                </button>
                <button href="#">
                    <span className={"btn_insert"}></span><span>입력</span>
                </button>
                <button href="#">
                    <span className={"btn_update"}></span><span>수정</span>
                </button>
                <button href="#">
                    <span className={"btn_delete"}></span><span>삭제</span>
                </button>
                <button href="#">
                    <span className={"btn_excel"}></span><span>엑셀</span>
                </button> */}

      {/* <button id="btn-search" href="#">
                    <img src="./images/button/btn_search.png"  style={{width:"20px",height:"20px"}} alt="Search Botton"/>
                    <span style={{display:"inline-block",paddingTop:"2px",paddingLeft:"4px"}}>조회</span>
                </button>
                <button id="btn-insert" href="#">
                    <img src="./images/button/btn_insert.png"  style={{width:"20px",height:"20px"}} alt="Insert Botton"/>
                    <span style={{display:"inline-block",paddingTop:"2px",paddingLeft:"4px"}}>입력</span>
                </button>
                <button id="btn-update" href="#">
                    <img src="./images/button/btn_update.png"  style={{width:"20px",height:"20px"}} alt="Update Botton"/>
                    <span style={{display:"inline-block",paddingTop:"2px",paddingLeft:"4px"}}>수정</span>            
                </button>
                <button id="btn-delete" href="#">
                    <img src="./images/button/btn_delete.png"  style={{width:"20px",height:"20px"}} alt="Delte Botton"/>
                    <span style={{display:"inline-block",paddingTop:"2px",paddingLeft:"4px"}}>삭제</span>                
                </button>
                <button id="btn-excel" href="#">
                    <img src="./images/button/btn_excel.png"  style={{width:"20px",height:"20px"}} alt="Excel Botton"/>
                    <span style={{display:"inline-block",paddingTop:"2px",paddingLeft:"4px"}}>엑셀</span>                
                </button> */}
    </>
  );
}
// export default withRouter(Header);
export default Buttonwrapper;
