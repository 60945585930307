//수신자료관리 - 기초관리
import { Route, Switch } from 'react-router-dom';
import REP01 from '@/pages/B01/REP01';  //자료생성(수동)
import RNT30 from '@/pages/B01/RNT30';  //Body seq 219 코드등록

function B01() {
    return ( 
        <Switch>
            <Route path='/B01/REP01' component={REP01} />
            <Route path='/B01/RNT30' component={RNT30} />
        </Switch>
    );
}

export default B01;

