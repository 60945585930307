//납입지시 - ERP_OEM 출하관리
import { Route, Switch } from 'react-router-dom';
import ROM3H from '@/pages/A02/ROM3H';  //납품서발행(VMI)
import ROM3L from '@/pages/A02/ROM3L';  //납품서발행(PO)
import ROM3J from '@/pages/A02/ROM3J';  //발행취소/재인쇄
import ROM4H from '@/pages/A02/ROM4H';  //부품식별표(VMI)
import ROM4N from '@/pages/A02/ROM4N';  //부품식별표(PO)
import ROM4P from '@/pages/A02/ROM4P';  //출하정보전송
import ROM4V from '@/pages/A02/ROM4V';  //부품식별표(KIA 2D)
import ROM3T from '@/pages/A02/ROM3T';  //납품서삭제내역
import ROM3V from '@/pages/A02/ROM3V';  //백지카드 발행
//import REX3H from '@/pages/A02/REX3H';  //납품서 일괄발행(VMI)
import REX3I from '@/pages/A02/REX3I';  //납품서 일괄발행(VMI)


function A02() {
    return ( 
        <Switch>
            <Route path='/A02/ROM3H' component={ROM3H} />
            <Route path='/A02/ROM3L' component={ROM3L} />
            <Route path='/A02/ROM3J' component={ROM3J} />
            <Route path='/A02/ROM4H' component={ROM4H} />
            <Route path='/A02/ROM4N' component={ROM4N} />
            <Route path='/A02/ROM4V' component={ROM4V} />
            <Route path='/A02/ROM4P' component={ROM4P} />
            <Route path='/A02/ROM3T' component={ROM3T} />
            <Route path='/A02/ROM3V' component={ROM3V} />
            <Route path='/A02/REX3I' component={REX3I} />
        </Switch>
    );
}

export default A02;
