/**
 * @Page RSY10
 * @Description [RSY10]시스템 관리 : 코드관리  
 **/
import React, { useState, useEffect, useRef, useCallback  } from "react";
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
import CommonUtil from '@/utils/CommonUtil'; 


const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
const Utils = new CommonUtil();

function RSY10() {
    const token = Common.getToken();
    const userId = Common.getToken("id");
    const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const grdRsy10Main = useRef();   //Grid Main


    //Data
    const [idata, setData] = useState({
        //조회조건
        kWrcVand: "****",    //구분
        kWrcGocd : "00",    //그룹코드    
        kWrcVndList : (tVnt1 === "M000" ? [ { "comCode" : tVnt1,  "text": "업체" }] 
                                       : []),   //구분 데이터 (관리자일 경우 조회조건 구분항목에 추가한다.)
        kWrcGocdList : [],  //그룹 데이터  
        kWrcCod1 : "",       //코드1
        kWrcCod2 : "",       //코드2
        kWrcCod3 : "",       //코드3
    }); 

   
    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;


    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;


    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    let [insertApi, setInsertApi] = useState(false); 
    let [updateApi, setUpdateApi] = useState(false); 
    let [deleteApi, setDeleteApi] = useState(false); // deleteApi 항목 setting
    let [loading, setLoading] = useState(false); 
    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 50px)"});

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
        setInsertApi(false);
        setUpdateApi(false);
        setDeleteApi(false);
    }, []);
    
    

    /**
     * 공통코드 조회
     */
    const onSearchCommon = (para) => {

        let commonData = idata;

        //그룹코드 조회
        let param = encodeURI(para);
        const query = SERVER_URL + "/comcd/search1?query=" + param; 
        fetch(query, { headers: { Authorization: token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) { 
                    commonData["kWrcGocdList"] = data.data
                    setData({...commonData});
                }
            })
    }



    /**
     *MOUNT 
     */
    useEffect( () => {
        
        //공통코드 조회
        onSearchCommon(`{"pgmid":"CODE", "vand":"****", "gocd" : "00"}`);

        //조회
        setTimeout(() => onSearch(), 300);
    }, []);

    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value } = e.target;

        let newSearchs = idata;
        newSearchs[name] = value        

        
        setData({ ...newSearchs });
        //조회 Call    
        onSearch(); 

        // //구분
        // if (name === "kWrcVand")  idata.kWrcVand = value;
        // //그룹코드    
        // else if (name === "kWrcGocd")  idata.kWrcGocd = value;
        // //코드1    
        // else if (name === "kWrcCod1")  idata.kWrcCod1 = value;
    }



    /**
     * Grid Event 
     * @param {*} e 
     */
    const onCellValueChanged = async (e) => {
    }

    const onRowClicked = async (e) => {
    }



    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //구분
            {
                "formatter": "autoInput", 
                "name": "kWrcVand", 
                "labelName": "구분", 
                "type": "text", 
                "defaultValue": idata.kWrcVand,  
                "codeMode": false,
                "selectAll": "",
                "codes": gbn,
                "comms": idata.kWrcVndList,
                // , "items": [{ "comCode": "Y", "text": "사용" }, { "comCode": "N", "text": "미사용" }] // Case 3 - items :: Hard Coding List
            }, 
            //그룹코드
            {
                "formatter": "autoInput",
                "name": "kWrcGocd", 
                "labelName": "그룹코드",
                "type": "text",
                "defaultValue": idata.kWrcGocd,  
                "codeMode": false,
                "selectAll": "",
                "codes": [{ "comCode" : "00",  "text": "그룹" }],
                "comms": idata.kWrcGocdList,     
                
                // , "items": [{ "comCode": "Y", "text": "사용" }, { "comCode": "N", "text": "미사용" }] // Case 3 - items :: Hard Coding List
            }, 
            //코드 1
            {
                "formatter": "input", "name": "kWrcCod1", "labelName": "코드1", "type": "text", "desc": ""
                , "defaultValue": idata.kWrcCod1  
                , "align": "left" 
                , "labelType": "none" 
            }, 
            //코드 2
            {
                "formatter": "input", "name": "kWrcCod2", "labelName": "코드2", "type": "text", "desc": ""
                , "defaultValue": idata.kWrcCod2  
                , "align": "left" 
                , "labelType": "none" 
            }, 
            //코드 3
            {
                "formatter": "input", "name": "kWrcCod3", "labelName": "코드3", "type": "text", "desc": ""
                , "defaultValue": idata.kWrcCod3  
                , "align": "left" 
                , "labelType": "none" 
            }, 
            {"formatter": "blank", "align": "left" }
        ]
    }



    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {        

        // autoinput 데이터 전처리 ex) {"comCode":"BDCD"} => BDCD
        //구분 
        let h_kWrcVand = ""
        document.getElementById("h_kWrcVand").textContent == "" ? h_kWrcVand = "****" : h_kWrcVand = (document.getElementById("h_kWrcVand").textContent).replace('{"comCode":"', '').replace('"}', '');        
        idata.kWrcVand = h_kWrcVand;

        // autoinput 데이터 전처리 ex) {"comCode":"BDCD"} => BDCD 
        //그룹코드
        let h_kWrcGocd = ""
        document.getElementById("h_kWrcGocd").textContent == "" ? h_kWrcGocd = "00" : h_kWrcGocd = (document.getElementById("h_kWrcGocd").textContent).replace('{"comCode":"', '').replace('"}', '');        
        idata.kWrcGocd = h_kWrcGocd

      

        //구분코드 선택 Check
        if (idata.kWrcVand === "") {
            
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "구분 코드를 선택해 주세요."});
            document.getElementById("kWrcVand").focus();
            return;
        }

        
        //그룹코드 선택 Check
        if (idata.kWrcGocd === "") {
            
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "그룹 코드를 선택해 주세요."});
            document.getElementById("kWrcGocd").focus();
            return;
        }
        
            
       
        let para = `{"kWrcVand":"${idata.kWrcVand}","kWrcGocd":"${idata.kWrcGocd == "" ? "00" : idata.kWrcGocd}","kWrcCod1":"${idata.kWrcCod1}","kWrcCod2":"${idata.kWrcCod2}","kWrcCod3":"${idata.kWrcCod3}"}`;        
        let param = encodeURI(para);
        
        const query = SERVER_URL + "/rsy10/search?query=" + param;

        setSearchApi(query); // Search API
        
    }

    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
        let newItems = {  vand: idata.kWrcVand, gocd : idata.kWrcGocd};
        //let newItems = [{cod1: 'A'}, {cod1: 'B'}];

        grdRsy10Main.current.addRow(newItems, 0);
    }

    
    /**
     * 입력
     * @param {*} e 
     */
    const onInsert = async (e) => {

        //input check
        let input = await Promise.all ([inputCheck('I')]);
        if ( !input[0]) return;

        const query = SERVER_URL + "/rsy10/insert";
        setInsertApi(query); // Insert API
    }



    /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {

         //input check
         let input = await Promise.all ([inputCheck('U')]);
         if ( !input[0]) return;
        
        const query = SERVER_URL + "/rsy10/update";
        setUpdateApi(query); // Update API
    }



    /**
     * 삭제
     * @param {*} e 
     */
    const onDelete = async (e) => {
         
        //input check
        let input = await Promise.all ([inputCheck('D')]);
        if ( !input[0]) return;

        //삭제 확인 메세지 
        setDlgMessage({openDlg : true,  
                       dlgTrans : "D", 
                       dlgMsg : "선택한 데이터를 삭제하시겠습니까?" });
    }



    // input check
    const inputCheck = (gbn) => { 

        let inputFlag = true;
        //select nodes
        let selectedData = grdRsy10Main.current.getSelectedNodes();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "선택한 데이터가 없습니다."});
            return false;
        }

        if (gbn != 'D') {
            //필수입력항목 Check
            selectedData.forEach((node) => {
                if (node.data.cod1 == null || node.data.cod1 == "" || node.data.cod1 === undefined) {
                    setMessage({ openAlert : true, 
                                 severity : "info",
                                 msg : "코드1 데이터를 입력하세요."});

                    grdRsy10Main.current.SetFocus(node.rowIndex, "cod1");                             
                    inputFlag = false
                    return false;
                }
                else if (node.data.des1 == null || node.data.des1 == "" || node.data.des1 === undefined) {
                    setMessage({ openAlert : true, 
                                 severity : "info",
                                 msg : "코드명1 데이터를 입력하세요."});
                    grdRsy10Main.current.SetFocus(node.rowIndex, "des1");     

                    inputFlag = false
                    return;
                }
            });
        }
        return inputFlag;
    }


    /**
     * 엑셀
     * @param {*} e 
     */
     const onExcel = async (e) => {
        let para = `{"kWrcVand":"${idata.kWrcVand}","kWrcGocd":"${idata.kWrcGocd == "" ? "00" : idata.kWrcGocd}","kWrcCod1":"${idata.kWrcCod1}",
                     "kWrcCod2":"${idata.kWrcCod2}","kWrcCod3":"${idata.kWrcCod3}"}`;        
        let param = encodeURIComponent(para);
        const query = SERVER_URL + "/rsy10/excel?query=" + param;

        setLoading(true);
        
        await fetch(query, {headers: {Authorization: token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'rsy10.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })

        setLoading(false);
        setMessage({ openAlert : true, 
                        severity : "success",
                        msg : "엑셀파일이 생성되었습니다."});
    }


    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans) 
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D" :
                const query = SERVER_URL + "/rsy10/delete";
                setDeleteApi(query); // Update API
                break;
        }
    }
    
    
    /**
     *Grid Column 정의 
     */
    const columnDefs = [
        { headerName: "구분", field: "vand", sortable: true, filter: true, flex: 1, minWidth: 0, hide: true }, 
        { headerName: "그룹코드", field: "gocd", sortable: true, filter: true, flex: 1, minWidth: 0, hide: true }, 
        { headerName: "코드1(*)", field: "cod1", sortable: true, filter: true, flex: 1, minWidth: 150, editable: true, headerClass: "", cellEditor:"maxLengt", cellEditorParams:{maxLength:4}, cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },  //valueFormatter : Utils.numberFormatter
        { headerName: "코드2(*)", field: "cod2", sortable: true, filter: true, flex: 1, minWidth: 170, editable: true, headerClass: "", cellEditor:"maxLengt", cellEditorParams:{maxLength:4}, cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }, 
        { headerName: "코드3(*)", field: "cod3", sortable: true, filter: true, flex: 1, minWidth: 170, editable: true, headerClass: "", cellEditor:"maxLengt", cellEditorParams:{maxLength:4}, cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }, 
        { headerName: "코드명1", field: "des1", sortable: true, filter: true, flex: 1, minWidth: 360, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }, 
        { headerName: "코드명2", field: "des2", sortable: true, filter: true, flex: 1, minWidth: 360, maxLength:5, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }, 
        { headerName: "코드명3", field: "des3", sortable: true, filter: true, flex: 1, minWidth: 360, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }, 
        { headerName: "정렬", field: "ordr", sortable: true, filter: true, flex: 1, minWidth: 80, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false }   
    ];
    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 55px  - 50px)"
              });
        }
    }
    
    return(
        <>
           
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
            
            <Ribbon
                onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                onSearch={onSearch} onSearchName={null} 
                onAddRow={onAddRow} onAddRowName={"신규"} onAddRowVisible = {tVnt1 === "M000"}  //Row 추가
                onInsert={onInsert} onInsertName={null} onInsertVisible = {tVnt1 === "M000"}    //등록
                onUpdate={onUpdate} onUpdateName={null} onUpdateVisible = {tVnt1 === "M000"}    //수정
                onDelete={onDelete} onDeleteName={null} onDeleteVisible = {tVnt1 === "M000"}    //삭제
                onExcel={onExcel} onExcelName={null} onExcelVisible = {tVnt1 === "M000"}    //삭제
                //onPrint={onInsert} onPrintName={null} //print
            >
                {"시스템관리 > 시스템관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>코드관리</b>
            </Ribbon>
           

            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick={onClick} />


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:55px */}
                <div className={"grid_type01"} > {/* 상단 FRAME */}
                    <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                        <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                            <Grid
                                ref={grdRsy10Main}
                                gridId={"RSY10"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                pagination={true}                  //  쪽수 매기기 여부
                                paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"28"}         //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={true}      // cell selection drag mode
                                isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                insertApi={insertApi}               // 등록
                                updateApi={updateApi}               // 수정
                                deleteApi={deleteApi}               // 삭제
                                //updateRefresh={onSearch}          // 수정이후 
                                deleteRefresh={onSearch}            // 삭제이후 
                                onClearApi={onClearApi}
                                //onClearApi={()=> {setSearchApi(false); setUpdateApi(false);}}
                                onCellValueChangedEvent={onCellValueChanged}
                                onRowClickEvent={onRowClicked}
                                
                            />
                        </div>
                    </div>
                </div>
            </Content>
        </>
    )
}
export default RSY10;