/**
 * @Page RSY40
 * @Description [RSY40]시스템 관리 : 운영관리 (납품서 이력현황)
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import GridRibbon from "@/components/organisms/GridRibbon";
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil';  
 
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 `
 const Utils = new CommonUtil();
 
 function RSY40() {
    const token = Common.getToken();
    const userId = Common.getToken("id");
    const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const grdRSY40List = useRef();   //Grid1
    const grdRSY40List2 = useRef();   //Grid2
    const master = Common.getToken("masterKey");
    
    //Data
    const [idata, setData] = useState({
       //조회조건
       kVnt1 : tVnt1,      //T1업체
       kComp: tComp,       //회사구분             
       kPlnt: "",          //공장
       kPtno: "",
       kPdno: "",
       kSeld: "",
       kDlno : "",         //납품서번호        
       //코드 데이터
       vnt1List : [],  //T1업체
       plntList : [],  //공장
       kMaster : master, //마스터 계정 여부
    }); 
    
    
    const [message, setMessage] = React.useState( {
       openAlert : false,      
       severity : "success",   
       msg : ""                
    });
    const { openAlert, severity, msg } = message;


    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;


    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false);      
    let [searchApi2, setSearchApi2] = useState(false); 
    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 80px)",padding: "0px 10px 35px 10px"});
    let [clearGrid, setClearGrid] = useState(false);  

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
        setSearchApi2(false);         
     }, []);


      /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {
        
    }
 
     /**
      * 공통코드 조회
      */
      const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }
 
     /**
      *MOUNT 
      */
     useEffect( () => {
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");

        //공통코드 조회 (공장)
        onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "plntList");

        //조회조건 우상단 납품서번호 disalbed 처리
        document.getElementById("kDlno").setAttribute("disabled" , true);        

     }, []);
 
     /**
      * Control 변경 Event
      * @param {*} e 
      */
     const onChange = async (e) => {
         const { name, value } = e.target;

         let newSearchs = idata;
         newSearchs[name] = value
         setData({ ...newSearchs });                 
             
        //T1업체, 회사구분 변경시 
        if (name === "kVnt1" || name === "kComp"){
            newSearchs["plntList"] = [];
            newSearchs["kPlnt"] = "";
            newSearchs[name] = value;
            setData({ ...newSearchs });
            onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "plntList");                   
        }

    }
 
     /**
      * Grid Event 
      * @param {*} e 
      */

      //grdRSY40List Grid Row Click
    const grdRSY40List_onRowClicked = async (e) => {
        var selectedDlno = e.data.dlno           
        document.getElementById("kDlno").setAttribute("value" , selectedDlno);        
        document.getElementById("kDlno").value = selectedDlno;   

        navigator.clipboard.writeText(selectedDlno) // 클립보드에 납품서번호 저장
    }

    const onCellValueChanged = async (e) => {
    }
 
    const onRowClicked = async (e) => {
    }

    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //T1업체
            {
                "formatter": "select", "name": "kVnt1", "labelName": "T1업체", "type": "text", "defaultValue": idata.kVnt1,  
                "codeMode": true,
                "selectAll": "",
                "codes": null,
                "comms": idata.vnt1List,
                "align": "left", 
            }, 
            //회사구분
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": true,
                "selectAll": "",
                "codes": CODES["comp"],
                "comms": null,
                "align": "left", 
            }, 
            //공장
            { 
                "formatter": "select", "name": "kPlnt", "labelName": "공장", "type": "text", "defaultValue": idata.kPlnt,  
                "codeMode": false,
                "selectAll": "all",
                "codes": null,
                "comms": idata.plntList
            }, 
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" }            
       ],
       1: [
            //품번
            {
                "formatter": "input", "name": "kPtno", "labelName": "품번", "type": "text", "desc": "", "readOnly" : false, "clearButton": true,
                "defaultValue": idata.kPtno,  
                "align": "left", 
                "labelType": "none", 
            }, 
            //PD번호
            {
                "formatter": "input", "name": "kPdno", "labelName": "PD번호", "type": "text", "desc": "", "readOnly" : false, "clearButton": true,
                "defaultValue": idata.kPdno,  
                "align": "left", 
                "labelType": "none", 
            }, 
            //발주번호
            {
                "formatter": "input", "name": "kSeld", "labelName": "발주번호", "type": "text", "desc": "", "readOnly" : false, "clearButton": true,
                "defaultValue": idata.kSeld,  
                "align": "left", 
                "labelType": "none", 
            }, 
            {"formatter": "blank", "align": "left" },
             //납품서번호
            {
                "formatter": "input", "name": "kDlno", "labelName": "납품서번호", "type": "text", "desc": "", "readOnly" : "false", "clearButton": true,
                "defaultValue": idata.kDlno,  
                "align": "left", 
                "labelType": "none", 
            },              
        ]         
     }
 

     /**
      * 조회 
      * @param {*} e 
      * @returns 
      */
    const onSearch = async (e) => {      
        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);        

        //PD번호, 발주번호 데이터 전처리(공백/하이픈 제거 등)        
        const fromattedkSeld = (idata.kSeld.replace("-","")).replaceAll(" ", "");
        const formattedkPdno = idata.kPdno == null || idata.kPdno == undefined || idata.kPdno == "" ? "" :  "('" + ((idata.kPdno).replaceAll(" ", "" )).replaceAll(",", "','" ) + "')";         

        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                     "kPtno":"${idata.kPtno.replaceAll('-','')}" ,"kPdno":"${formattedkPdno}","kSeld":"${fromattedkSeld}" }`;        

        let param = encodeURIComponent(para);                
        
        const query = SERVER_URL + "/rsy40/search?query=" + param; 
        const query2 = SERVER_URL + "/rsy40/search2?query=" + param;

        setSearchApi(query); // Search API           
        setSearchApi2(query2); // Search2 API    
    }
    
     //Transaction AlertDialogResult
     const AlertDialogResult = async (e) => {
 
         //AlertDialog 초기화
         setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        
         //DialogResult
         if (e.result == "N") return;
 
         //Transaction 구분
         switch (e.Trans) 
         {
             //등록
             case "I" :
                 break;
             //수정
             case "U" :
                 break;
             //삭제
             case "D" :
                 break;
         }
    } 

    /**
     *Grid Column 정의 
    */

    // 납품서 상세현황 Grid Column
    const columnDefs1 = [
        { headerName: "업체코드", field: "vand", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false},
        { headerName: "T1업체", field: "vnt1", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false},
        { headerName: "발행일자", field: "npdt", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false, valueFormatter: Utils.dateFormatter},
        { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false, valueGetter: Utils.partnoGetter},
        { headerName: "납품수량", field: "npqt", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: false, hide: false, valueFormatter: Utils.numberFormatter},
        { headerName: "납품서번호", field: "dlno", sortable: true, filter: true, flex: 1, minWidth: 220, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: false, hide: false},
        { headerName: "PD no.", field: "pdno", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false},
        { headerName: "발주번호(OEM)", field: "odno", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true},
        { headerName: "발주번호", field: "seld", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false, valueGetter:function(params){ return params.data.odno+" "+params.data.seld}},
        { headerName: "From Order", field: "frod", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false},
        { headerName: "To Order", field: "tood", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false},
        { headerName: "CASE NO", field: "caseno", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false},
        { headerName: "차량번호", field: "cano", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false},        
    ];

    // 납품서 상세현황(삭제) Grid Column
    const columnDefs2 = [        
        { headerName: "업체코드", field: "vand", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false},
        { headerName: "T1업체", field: "vnt1", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false},
        { headerName: "발행일자", field: "npdt", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false, valueFormatter: Utils.dateFormatter},
        { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false, valueGetter: Utils.partnoGetter},
        { headerName: "납품수량", field: "npqt", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: false, hide: false, valueFormatter: Utils.numberFormatter},
        { headerName: "납품서번호", field: "dlno", sortable: true, filter: true, flex: 1, minWidth: 220, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: false, hide: false},
        { headerName: "PD no.", field: "pdno", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false},
        { headerName: "발주번호(OEM)", field: "odno", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true},
        { headerName: "발주번호", field: "seld", sortable: true, filter: true, flex: 1, minWidth: 170, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false, valueGetter:function(params){ return params.data.odno+" "+params.data.seld}},
        { headerName: "From Order", field: "frod", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false},
        { headerName: "To Order", field: "tood", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false},
        { headerName: "CASE NO", field: "caseno", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false},
        { headerName: "차량번호", field: "cano", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false},
    ];

    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)",
                padding: "0px 10px 35px 10px"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 55px  - 80px)",
                padding: "0px 10px 35px 10px"
              });
        }
    }

     return(
         <>
             {/* Dialog FRAME */}
             <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult}/>          
 
             {/* Alert FRAME */}
             <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} /> 
                     
             {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
             <Ribbon
                onInfoMsg={"납품서 클릭시 납품서번호가 자동으로 복사됩니다!"}
                onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                onSearch={onSearch} onSearchName={null}     //조회                
                >
                {"시스템관리 > 운영관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>납품서 이력현황</b>
             </Ribbon>
  
             {/* ContentXearch Frame : 검색조건 */}
             <ContentXearch
                 optionsX={optionsX}
                 onChange={onChange}    
                 onClick={onClick}
                 />
             
             {/* Content FRAME (Data / Grid Frame) START -------------- */}
             <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:55px */}
             <div className="grid_type01" style={{margin : "0px"}}>{/* 상단 FRAME */}
                <div className="grid_wrap01"> {/* 상단 wrap 좌측 FRAME */}
                    <div className="grid_area"> {/* Grid area */}
                        <GridRibbon 
                            title={"납품서 상세현황"}>
                        </GridRibbon>
                        <div className="grid_cont_box" style={{ height: "calc(87%)" }}>
                            {/* Grid1 */}
                                <Grid
                                    ref={grdRSY40List}
                                    gridId={"RSY40"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs1}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={false}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={false}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    useMessage = {true}                 // 메세지 창 설정
                                    onClearApi={onClearApi}            // 초기화
                                    searchApi={searchApi}              // 조회
                                    onRowClickEvent={grdRSY40List_onRowClicked}
                                />
                        </div>
                    </div>
                </div>                
            </div>

                <div className="grid_type01">{/* 상단 FRAME */}
                    <div className="grid_wrap01"> {/* 상단 wrap 좌측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <GridRibbon 
                                title={"납품서 상세현황(삭제)"}
                            >
                            </GridRibbon>
                            <div className="grid_cont_box" style={{ height: "calc(89%)" }}>
                                {/* Grid2 */}
                                <Grid
                                    ref={grdRSY40List2}
                                    gridId={"RSY402"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs2}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={false}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    useMessage = {true}                 // 메시지창 설정                 
                                    onClearApi={onClearApi}            // 초기화
                                    searchApi={searchApi2}              // 조회
                                    onRowClickEvent={grdRSY40List_onRowClicked}                                                       
                                />
                            </div>
                        </div>
                    </div>                                                    
                </div>
             </Content>
         </>
     )
 }
 export default RSY40;