// Example locale file for English, give this to your locale team to translate

const AG_GRID_LOCALE_KR = {
    // Set Filter
    selectAll: "(전부 선택)",
    selectAllSearchResults: "(Select All Search Results)",
    searchOoo: "조회...",
    blanks: "(빈칸)",
    noMatches: "맞는결과없음",

    // Number Filter & Text Filter
    filterOoo: "Filter...",
    equals: "와 같다",
    notEqual: "와 같지 않다",
    empty: "Choose One",

    // Number Filter
    lessThan: "보다 적다",
    greaterThan: "보다 크다",
    lessThanOrEqual: "보다 적거나 같다",
    greaterThanOrEqual: "보다 크거나 같다",
    inRange: "In range",
    inRangeStart: "from",
    inRangeEnd: "to",

    // Text Filter
    contains: "검색어를 포함한다",
    notContains: "포함하지 않는다",
    startsWith: "~로 시작한다",
    endsWith: "~로 끝난다",

    // Date Filter
    dateFormatOoo: "yyyy-mm-dd",

    // Filter Conditions
    andCondition: "그리고",
    orCondition: "또는",

    // Filter Buttons
    applyFilter: "적용",
    resetFilter: "초기화",
    clearFilter: "지우기",
    cancelFilter: "취소",

    // Filter Titles
    textFilter: "Text Filter",
    numberFilter: "Number Filter",
    dateFilter: "Date Filter",
    setFilter: "Set Filter",

    // Side Bar
    columns: "Columns",
    filters: "Filters",

    // columns tool panel
    pivotMode: "Pivot Mode",
    groups: "Row Groups",
    rowGroupColumnsEmptyMessage: "Drag here to set row groups",
    values: "Values",
    valueColumnsEmptyMessage: "Drag here to aggregate",
    pivots: "Column Labels",
    pivotColumnsEmptyMessage: "Drag here to set column labels",

    // Header of the Default Group Column
    group: "Group",

    // Other
    loadingOoo: "Loading...",
    noRowsToShow: "No Rows To Show",
    enabled: "Enabled",

    // Menu
    pinColumn: "Pin Column",
    pinLeft: "Pin Left",
    pinRight: "Pin Right",
    noPin: "No Pin",
    valueAggregation: "Value Aggregation",
    autosizeThiscolumn: "Autosize This Column",
    autosizeAllColumns: "Autosize All Columns",
    groupBy: "Group by",
    ungroupBy: "Un-Group by",
    resetColumns: "Reset Columns",
    expandAll: "Expand All",
    collapseAll: "Close All",
    copy: "Copy",
    ctrlC: "Ctrl+C",
    copyWithHeaders: "Copy With Headers",
    paste: "Paste",
    ctrlV: "Ctrl+V",
    export: "Export",
    csvExport: "CSV Export",
    excelExport: "Excel Export",

    // Enterprise Menu Aggregation and Status Bar
    sum: "Sum",
    min: "Min",
    max: "Max",
    none: "None",
    count: "Count",
    avg: "Average",
    filteredRows: "Filtered",
    selectedRows: "Selected",
    totalRows: "Total Rows",
    totalAndFilteredRows: "Rows",
    more: "More",
    to: "to",
    of: "of",
    page: "Page",
    nextPage: "Next Page",
    lastPage: "Last Page",
    firstPage: "First Page",
    previousPage: "Previous Page",

    // Pivoting
    pivotColumnGroupTotals: "Total",

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: "Pivot Chart & Pivot Mode",
    pivotChart: "Pivot Chart",
    chartRange: "Chart Range",

    columnChart: "Column",
    groupedColumn: "Grouped",
    stackedColumn: "Stacked",
    normalizedColumn: "100% Stacked",

    barChart: "Bar",
    groupedBar: "Grouped",
    stackedBar: "Stacked",
    normalizedBar: "100% Stacked",

    pieChart: "Pie",
    pie: "Pie",
    doughnut: "Doughnut",

    line: "Line",

    xyChart: "X Y (Scatter)",
    scatter: "Scatter",
    bubble: "Bubble",

    areaChart: "Area",
    area: "Area",
    stackedArea: "Stacked",
    normalizedArea: "100% Stacked",

    histogramChart: "Histogram",

    // Charts
    pivotChartTitle: "Pivot Chart",
    rangeChartTitle: "Range Chart",
    settings: "Settings",
    data: "Data",
    format: "Format",
    categories: "Categories",
    defaultCategory: "(None)",
    series: "Series",
    xyValues: "X Y Values",
    paired: "Paired Mode",
    axis: "Axis",
    navigator: "Navigator",
    color: "Color",
    thickness: "Thickness",
    xType: "X Type",
    automatic: "Automatic",
    category: "Category",
    number: "Number",
    time: "Time",
    xRotation: "X Rotation",
    yRotation: "Y Rotation",
    ticks: "Ticks",
    width: "Width",
    height: "Height",
    length: "Length",
    padding: "Padding",
    spacing: "Spacing",
    chart: "Chart",
    title: "Title",
    titlePlaceholder: "Chart title - double click to edit",
    background: "Background",
    font: "Font",
    top: "Top",
    right: "Right",
    bottom: "Bottom",
    left: "Left",
    labels: "Labels",
    size: "Size",
    minSize: "Minimum Size",
    maxSize: "Maximum Size",
    legend: "Legend",
    position: "Position",
    markerSize: "Marker Size",
    markerStroke: "Marker Stroke",
    markerPadding: "Marker Padding",
    itemSpacing: "Item Spacing",
    itemPaddingX: "Item Padding X",
    itemPaddingY: "Item Padding Y",
    layoutHorizontalSpacing: "Horizontal Spacing",
    layoutVerticalSpacing: "Vertical Spacing",
    strokeWidth: "Stroke Width",
    offset: "Offset",
    offsets: "Offsets",
    tooltips: "Tooltips",
    callout: "Callout",
    markers: "Markers",
    shadow: "Shadow",
    blur: "Blur",
    xOffset: "X Offset",
    yOffset: "Y Offset",
    lineWidth: "Line Width",
    normal: "Normal",
    bold: "Bold",
    italic: "Italic",
    boldItalic: "Bold Italic",
    predefined: "Predefined",
    fillOpacity: "Fill Opacity",
    strokeOpacity: "Line Opacity",
    histogramBinCount: "Bin count",
    columnGroup: "Column",
    barGroup: "Bar",
    pieGroup: "Pie",
    lineGroup: "Line",
    scatterGroup: "X Y (Scatter)",
    areaGroup: "Area",
    histogramGroup: "Histogram",
    groupedColumnTooltip: "Grouped",
    stackedColumnTooltip: "Stacked",
    normalizedColumnTooltip: "100% Stacked",
    groupedBarTooltip: "Grouped",
    stackedBarTooltip: "Stacked",
    normalizedBarTooltip: "100% Stacked",
    pieTooltip: "Pie",
    doughnutTooltip: "Doughnut",
    lineTooltip: "Line",
    groupedAreaTooltip: "Area",
    stackedAreaTooltip: "Stacked",
    normalizedAreaTooltip: "100% Stacked",
    scatterTooltip: "Scatter",
    bubbleTooltip: "Bubble",
    histogramTooltip: "Histogram",
    noDataToChart: "No data available to be charted.",
    pivotChartRequiresPivotMode: "Pivot Chart requires Pivot Mode enabled.",
    chartSettingsToolbarTooltip: "Menu",
    chartLinkToolbarTooltip: "Linked to Grid",
    chartUnlinkToolbarTooltip: "Unlinked from Grid",
    chartDownloadToolbarTooltip: "Download Chart",

    // ARIA
    ariaHidden: "hidden",
    ariaVisible: "visible",
    ariaChecked: "checked",
    ariaUnchecked: "unchecked",
    ariaIndeterminate: "indeterminate",
    ariaColumnSelectAll: "Toggle Select All Columns",
    ariaInputEditor: "Input Editor",
    ariaDateFilterInput: "Date Filter Input",
    ariaFilterInput: "Filter Input",
    ariaFilterColumnsInput: "Filter Columns Input",
    ariaFilterValue: "Filter Value",
    ariaFilterFromValue: "Filter from value",
    ariaFilterToValue: "Filter to value",
    ariaFilteringOperator: "Filtering Operator",
    ariaColumnToggleVisibility: "column toggle visibility",
    ariaColumnGroupToggleVisibility: "column group toggle visibility",
    ariaRowSelect: "Press SPACE to select this row",
    ariaRowDeselect: "Press SPACE to deselect this row",
    ariaRowToggleSelection: "Press Space to toggle row selection",
    ariaRowSelectAll: "Press Space to toggle all rows selection",
    ariaSearch: "Search",
    ariaSearchFilterValues: "Search filter values",
};
export default AG_GRID_LOCALE_KR;
