/**
 * @Page ROM81
 * @Description [ROM81]납입지시 - 시작차 출하관리 - 납품서발행
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import GridRibbon from "@/components/organisms/GridRibbon";
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import * as GridUtil from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import Popup from '@/pages/Popup/Popup';    
 import CommonUtil from '@/utils/CommonUtil'; 
 import ReactToPrint from "react-to-print";
 import WrapStartCardPrint from "@/components/molecules/WrapStartCardPrint";
 
  
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();

 
 // Print Style CSS Setting
const pageStyle = `
@media all {
    .pagebreak {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .pagebreak {
      margin-top:1rem;
      display: block;
      page-break-after: always;
    }
  }
  
  @page {
    size: A4 portrait !important;
    margin:7mm 10mm;
  }
  body, div, table, td{
      font-family:굴림체,Gulim ;
  }

`;




 function ROM81() {
 
    const token = Common.getToken();
    const userId = Common.getToken("id");
    // const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    // const tComp = Common.getToken("comp");
    const bigi = Common.getToken("bigi");
    const grdRom81Main = useRef();   //Grid Main
    const grdRom81List = useRef();   //납품서 발행현황 Grid
    const grdRom81Detail= useRef();  //납품서 상세현황 Grid
    const printRef = useRef();
    const printBtnRef = useRef();

    const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 

    
    //Data
    const [idata, setData] = useState({
        //조회조건
        // kVnt1 : tVnt1,      //T1업체
        kVnt1 : ckVnt1,        //T1업체     
        kComp: 'EHKR',       //회사구분
        kPlnt: "",          //공장
        kGate: "",          //하치장
        kPldt: Common.getToday(),  //소요일자
        kNidt: Common.getToday(),   //납기일자
        kPtno : "",         //품번
        kCano : "",         //차량번호          
        kGubn: "N",       //미소요량 품번포함
        kName: "",          //담당자
        kSttm: Common.getToday(),   //출발일자
        kSthh: Common.getHour(),    //출발시간
        kTelno: "",         //연락처
        kEdtm: Common.getToday(),   //도착일자
        kEdhh: Common.getHour() === "24" ? "00" : parseInt("0"+Common.getHour().slice(-2))+1,    //도착시간 
        kNidtGubn:"B",
        kProj:"",//프로젝트

        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장
        gateList : [],  //하치장
        canoList : [],  //차량번호
        projList : [],  //프로젝트
    }); 
    
            
    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;
  
  
    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      // Dialog Transaction(I,U,D)
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
   
    // Params (Insert, Update Parameter)
    let [inserts, setInsert] = useState({}); 

    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    let [insertApi, setInsertApi] = useState(false); 
    let [updateApi, setUpdateApi] = useState(false); 
    let [deleteApi, setDeleteApi] = useState(false); // deleteApi 항목 setting
    let [searchApi2, setSearchApi2] = useState(false); 
    let [deleteApi2, setDeleteApi2] = useState(false); 
    let [searchApi3, setSearchApi3] = useState(false); 
    let [updateApi3, setUpdateApi3] = useState(false); 
    let [deleteApi3, setDeleteApi3] = useState(false); 
    let [printdata, setPrintData] = useState([]);   
    let [clearGrid3, setClearGrid3] = useState(false); 

    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 146px  - 50px)"});

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
        setInsertApi(false);
        setUpdateApi(false);
        setDeleteApi(false);
    }, []);

    const onClearApi2 = useCallback(async (e) => {
        setSearchApi2(false);
        setDeleteApi2(false);
    }, []);

    const onClearApi3 = useCallback(async (e) => {
        setSearchApi3(false);
        setUpdateApi3(false);
        setDeleteApi3(false);
    }, []);




    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }
     

    
    /**
     *MOUNT 
    */
    useEffect( () => {
        
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
        //공통코드 조회 (공장)
        onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");
        //공통코드 조회 (차량번호)
        onSearchCommon(`{"pgmid":"CARN"}`, "canoList");
        //공통코드 조회 (프로젝트)
        // onSearchCommon(`{"pgmid":"PROJ"}`, "projList");
        onSearchCommon(`{"pgmid":"PROJ","kVand":"${idata.kVnt1}"}`, "projList");
        //납품서 발행현황조회
        onSearch2();
    }, []);
  
  
  
    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value} = e.target;
        let val = "";
        let newSearchs = idata; 
        newSearchs[name] = value
        setData({ ...newSearchs });

        //공장 변경시 하치장 조회
        if (name === "kPlnt") {
            val = Common.changeEDTM(idata.kSttm,idata.kSthh);

            newSearchs["kGate"] = "";
            newSearchs["kEdhh"] = val.endHour;

            setData({ ...newSearchs });
            
            onSearchCommon(`{"pgmid":"VGATE","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}"}`, "gateList")
        }
        //T1업체, 회사구분 변경시 
        else if (name === "kVnt1" || name === "kComp")  {
            
            newSearchs["plntList"] = [];
            newSearchs["gateList"] = [];
            newSearchs["kPlnt"] = "";
            newSearchs["kGate"] = "";
            setData({ ...newSearchs });

            //공통코드 조회 (공장)
            onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");

            //납품서 발행 조회
            onSearch2();
            //onSearch3(null);
        }
        else if(name === "kSttm"){//출발일자가 선택되면 도착일자도 변경
            newSearchs["kEdtm"] = value;
            
            setData({ ...newSearchs });
        }
        else if(name === "kSthh"){//출발시간이 선택되면 도착시간 = 출발시간 +1
         
           val = Common.changeEDTM(idata.kSttm,value);

           newSearchs["kEdtm"] = val.endDate;
           newSearchs["kEdhh"] = val.endHour;

            setData({ ...newSearchs });
            
        }

        //하치장, 미소요량 Check 시 조회 Call
        else if  (name === "kGate" || name === "kGubn")  {
            onSearch(); //조회 Call
        }
    }
 
  
  
    /**
     * Grid Event 
     * @param {*} e 
     */
    const grdRom81Main_onCellValueChanged = async (e) => {

        let data = e.data;
        if (data.npqt === "0" || data.npqt === "") {
            e.node.setSelected(false);
        }


        if (e.column.colId === "npqt") {

            let npqt = !isNaN(parseInt(data.npqt, 10)) ? parseInt(data.npqt, 10) : 0;
            let jaqt = !isNaN(parseInt(data.jaqt, 10)) ? parseInt(data.jaqt, 10) : 0;
            // let rjqty = !isNaN(parseInt(data.rjqty)) ? parseInt(data.rjqty) : 0;

            if (npqt  > jaqt) {
                setMessage({ openAlert : true, 
                             severity : "info",
                             msg : "발주 잔량보다 더 많이 납품할 수 없습니다."});                       
                return;
            }
        }
    }
    const grdRom81Main_onRowClicked = async (e) => {
    }

    //납품서 발행현황 CellValueChanged
    const grdRom81List_onCellValueChanged = async (e) => {

    }
    //납품서 상세현황 CellValueChanged
    const grdRom81Detail_onCellValueChanged = async (e) => {
    }

    //남품서 발행현황 Row Click
    const grdRom81List_onRowClicked = async (e) => {

        let dlno = e.data.wrcdlno;
        if (dlno != null || dlno != "") 
        {
            onSearch3(dlno);
        }
    }

  
  
    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //T1업체
            {
                "formatter": "select", "name": "kVnt1", "labelName": "T1업체", "type": "text", "defaultValue": idata.kVnt1,  
                "codeMode": true,
                "selectAll": "",
                "codes": null,
                "comms": idata.vnt1List,
                "align": "left", 
            }, 
            //프로젝트
            {
                "formatter": "select", "name": "kProj", "labelName": "프로젝트", "type": "text", "defaultValue": idata.kProj,  
                "codeMode": true,
                "selectAll": true, // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                "codes": null,
                "comms": idata.projList,
                "align": "left", 
            }, 
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            //차량번호
            {
                "formatter": "select", "name": "kCano", "labelName": "차량번호", "type": "text", "defaultValue": idata.kCano,  
                "codeMode": false,
                "selectAll": "none",
                "codes": null,
                "comms": idata.canoList,
                "align": "right", 
                "labelType": "insert" 
            }, 
        ],
        1: [
            //회사구분
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": true,
                "selectAll": "",
                //"codes": CODES["comp"],
                "comms": null,
                "align": "left", 
                "items": [{ "comCode": "EHKR", "text": "남양시작차" }]
            }, 
           //납기일자
           {
            "formatter": "date", "name": "kNidt", "name2": "kNidtGubn", "labelName": "납기일자"
            , "defaultValue": idata.kNidt
            , "defaultValue2": idata.kNidtGubn  
            , "align": "right" // (우측 정렬)
            // , "labelType": "insert" 
//                ,"items": [{ "comCode": "Y", "text": "인쇄" }, { "comCode": "N", "text": "미인쇄" }]
        },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            //출발시간 날짜 & 시간
            {
                "formatter": "dateHour", "name": "kSttm", "name2": "kSthh", "labelName": "출발시간"
                , "defaultValue": idata.kSttm
                , "defaultValue2": idata.kSthh  
                , "align": "right" // (우측 정렬)
                , "labelType": "insert" 
            },
        ],
        2: [
            //공장
            {
                "formatter": "select", "name": "kPlnt", "labelName": "공장", "type": "text", "defaultValue": idata.kPlnt,  
                "codeMode": false,
                "selectAll": "",
                "codes": null,
                "comms": idata.plntList,
            }, 
            //품번
            {
                "formatter": "input", "name": "kPtno", "labelName": "품번", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kPtno,  
                "align": "left", 
                "labelType": "none", 
            }, 
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            //도착시간 날짜 & 시간
            {
                "formatter": "dateHour", "name": "kEdtm", "name2": "kEdhh", "labelName": "도착시간",
                "defaultValue": idata.kEdtm,
                "defaultValue2": idata.kEdhh,  
                "labelType": "insert", 
                "align": "right", // (우측 정렬)
            },
        ]
    }
  
  
  
    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {
        
        //T1업체선택 Check
        if (idata.kVnt1 == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "1차업체를 선택하십시요 !"});
            document.getElementById("kVnt1").focus();                         
            return ;
        }

        //회사구분선택 Check
        if (idata.kComp == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "회사구분을 선택하십시요 !"});
            document.getElementById("kComp").focus();                         
            return ;
        }


        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);
      

        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kProj":"${idata.kProj}","kNidt":"${idata.kNidt}"
                    ,"kPlnt":"${idata.kPlnt}","kPtno":"${idata.kPtno}","kNidtGubn":"${idata.kNidtGubn}"}`;
        
        let param = encodeURI(para);

        const query = SERVER_URL + "/rom81/search?query=" + param;
        setSearchApi(query); // Search API
    }

  
  
    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
    }

  
      
    /**
     * 입력 (발행)
     * @param {*} e 
     */
    const onInsert = async (e) => {

        //T1업체선택 Check
        if (idata.kVnt1 == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "1차업체를 선택하십시요 !"});
            document.getElementById("kVnt1").focus();                         
            return ;
        }

        //회사구분선택 Check
        if (idata.kComp == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "회사구분을 선택하십시요 !"});
            document.getElementById("kComp").focus();                         
            return ;
        }

        //차량번호 선택 Check
        if (idata.kCano === "") {

            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "차량번호를 선택해 주세요."});
            document.getElementById("kCano").focus();
            return;
        }


        //출발시간, 도착시간 Check
        if (idata.kSttm.replace(/-/g, '') > idata.kEdtm.replace(/-/g, '') || (idata.kSttm.replace(/-/g, '') === idata.kEdtm.replace(/-/g, '') && idata.kSthh > idata.kEdhh)) {

            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "출발시간이 도착시간보다 클 수 없습니다."});
            if (idata.kSttm.replace(/-/g, '') >idata.kEdtm.replace(/-/g, '')) {
                document.getElementById("kSttm").focus();
            } else {
                if (idata.kSthh > idata.kEdhh) {
                    document.getElementById("kSthh").focus();
                }
            }
            return;
        }

        //select nodes
        let selectedData = grdRom81Main.current.getSelectedNodes();
        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "선택한 데이터가 없습니다."});
            return;
        }
        let result = '';
        selectedData.forEach((node) => {
            
            if (node.data.npqt === "" || node.data.npqt=== "0") {
                setMessage({ openAlert : true, 
                             severity : "info",
                             msg : "납품량을 입력하여 주십시오."});
                grdRom81Main.current.SetFocus(node.rowIndex, "npqt");                             
                result = 'f';
                return ;
            }
            if (node.data.npqt >node.data.jaqt) {
                setMessage({ openAlert : true, 
                             severity : "info",
                             msg : "발주 잔량보다 더 많이 납품할 수 없습니다."});
                grdRom81Main.current.SetFocus(node.rowIndex, "npqt");                             
                result = 'f';
                return ;
            }
            return ;
        })
        
        if(result!='f'){
            if(idata.kPlnt === ''){
                idata.kPlnt = idata.plntList[0].comCode;
            }
            //Insert Parameter
            let newInserts = inserts;
            newInserts["vnt1"] = idata.kVnt1;  //  1차업체
            newInserts["comp"] = idata.kComp;  // 회사구분
    
            newInserts["cano"] = idata.kCano;  // 차량번호
            newInserts["plnt"] = idata.kPlnt;  // 공장
    
            newInserts["pldt"] = idata.kPldt.replace(/-/g, '');    // 소요일자
            newInserts["npdt"] = idata.kSttm.replace(/-/g, '') + "" + idata.kSthh; // 출발일시 (YYYYMMDDHH)
            newInserts["dcdt"] = idata.kEdtm.replace(/-/g, '') + "" + idata.kEdhh; // 도착일시 (YYYYMMDDHH)
    
            setInsert({ ...newInserts });
    
            const query = SERVER_URL + "/rom81/insert";
            setInsertApi(query); // Insert API
        }
    }
  

  

    /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {
    }
  
    /**
     * 삭제
     * @param {*} e 
     */
    const onDelete = async (e) => {
    }



    /**
     * 납품서 발행현황 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch2= async (e) => {
        
        setClearGrid3(true); // grid 3 초기화

        //T1업체선택 Check
        if (idata.kVnt1 == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "1차업체를 선택하십시요 !"});
            document.getElementById("kVnt1").focus();                         
            return ;
        }

        //회사구분선택 Check
        if (idata.kComp == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "회사구분을 선택하십시요 !"});
            document.getElementById("kComp").focus();                         
            return ;
        }

        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/rom81/search2?query=" + param;
        setSearchApi2(query); // Search API
    }


    /**
     * 남품서 발행현황 취소
     * @param {*} e 
     * @returns 
     */
    const onDelete2 = async (e) => {
        
        //select nodes
        let selectedData = grdRom81List.current.getSelectedNodes();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "선택한 데이터가 없습니다."});
            return false;
        }

        //삭제 확인 메세지 
        setDlgMessage({openDlg : true,  
                    dlgTrans : "D2", 
                    dlgMsg : "선택한 데이터를 취소 하시겠습니까?" });

    }


    
    /**
     * 납품서 발행현황 인쇄 
     * @param {*} e 
     * @returns 
     */
    const onPrint2 = async (e) => {

        //select nodes
        let selectedData = grdRom81List.current.getSelectedData();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "선택한 데이터가 없습니다."});
            return false;
        }

        const fetchData = async () => {

            let selectDlnoList = [];
            selectedData.map(function (data, i) {

                // selectedData2.forEach(function (data, i) {
                if (data.wrcdlno) {
                    selectDlnoList.push(data.wrcdlno);
                }
            });

            const query = SERVER_URL + "/rom81/chulha"; 
            let param = JSON.stringify({
                vnt1: idata.kVnt1,
                comp: idata.kComp,
                ename : idata.kName,
                telno : idata.kTelno,
                func: "CH", // CH : 출하전송 
                rprint: 'N',
                dlnolist: selectDlnoList,
            });

            await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) {
                        setPrintData(data.data.list);

                        setMessage({ openAlert : true, 
                                     severity : "success",
                                     msg : "인쇄 조회 성공 하였습니다."});
                        printBtnRef.current.dispatchEvent(new Event("click", { bubbles: true }));
                        setTimeout(() => onSearch2(), 300);
                    }

                    if (data.error) {
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : "조회 실패"});
                    }
                })
                .catch((error) => {
                    if (error) {
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                    }
                });
        };

        fetchData();
    }


    /**
     * 납품서 상세현황 조회 
     * @param {*} dlno 
     */
    const onSearch3= async (dlno) => {

        let para = `{"vnt1":"${idata.kVnt1}","dlno":"${dlno}"}`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/rom81/search3?query=" + param;
        setSearchApi3(query); // Search API
    }

    /**
     * 납품서 상세현황 수정 
     * @param {*} e 
     */
    const onUpdate3 = async (e) => {
        
        //select nodes
        let selectedData = grdRom81Detail.current.getSelectedData();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "수정할 데이터를 선택해 주세요."});
            return false;
        }

        const query = SERVER_URL + "/rom81/update";
        setUpdateApi3(query); // Update API
    }


    /**
     * 납품서 상세현황 납품품목삭제
     * @param {*} e 
     */
    const onDelete3 = async (e) => {

         //select nodes
         let selectedData = grdRom81Detail.current.getSelectedNodes();

         // 선택 값 체크
         if (selectedData.length === 0) {
             //Alert 창 OPEN
             setMessage({ openAlert : true, 
                          severity : "info",
                          msg : "선택한 데이터가 없습니다."});
             return false;
         }
 
         //삭제 확인 메세지 
         setDlgMessage({openDlg : true,  
                        dlgTrans : "D3", 
                        dlgMsg : "선택한 데이터를 삭제하시겠습니까?" });

    }

    
    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});

        

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans) 
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D2" :
                const queryD2  = SERVER_URL + "/rom81/delete";
                setDeleteApi2(queryD2); // Update API
                break;

            case "D3" :
                const queryD3 = SERVER_URL + "/rom81/delete";
                setDeleteApi3(queryD3); // Delete API
                break;
        }
    }
      
    

    /**
     * Grid Bind Data
     * @returns 
    */
    const onGridBindData = (para) => {
    }
 
     
    const editCellClicked = (params) => {
        // default Cell Click 이벤트 

        let gridApi = params.api;

        let rowIdx = params.rowIndex;

        let data = params.data;


        if ((data.npqt === 0 || data.npqt === "")) {

            gridApi.stopEditing(true); // Grid EditMode stop

            //var rowNode = gridApi.getRowNode(rowIdx);
            var rowNode = gridApi.getDisplayedRowAtIndex(rowIdx); //정렬, 필터링... 하면 변경된 index가져옴
            rowNode.setDataValue("npqt", data.jaqt); // 잔량jaqt 값 받아와서 납품수량npqt 셋팅 


            // params.api.startEditingCell({
            gridApi.startEditingCell({
                // rowIndex: params.rowIndex,
                rowIndex: rowIdx,
                colKey: 'npqt' // 납품수량npqt edit Mode
            }); // Grid EditMode start


        }

    };

    /**
     *Grid Column 정의 
    */
    //Main Grid Column
    const columnDefs = [
        { headerName: "card(H)", field: "card", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true },  
        { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], valueGetter: Utils.partnoGetter, singleClickEdit: true, hide: false }, 
        { headerName: "납기일자", field: "nidt", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false },
        { headerName: "발주번호", field: "odno", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "발주량", field: "odqt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false}, 
        { headerName: "납품량", field: "npqt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: true,onCellClicked: editCellClicked, headerClass: "editCell", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false}, 
        { headerName: "잔량", field: "jaqt", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "담당", field: "crdx", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false}, 
        { headerName: "입고량", field: "igqt", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "발주일자", field: "ordt", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,valueFormatter: Utils.dateFormatter, hide: false}, 
    ];
    //납품서 발행 Grid Column
    const columnDefs1 = [
        { headerName: "vnt1(H)", field: "vnt1", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "공장", field: "wrcplnt", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "담당", field: "wrccrdx", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "납품서번호", field: "wrcdlno", sortable: true, filter: true, flex: 1, minWidth: 240, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "발행일시", field: "wrcnpdt", sortable: true, filter: true, flex: 1, minWidth: 200, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "건수", field: "wrcpcnt", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
    ];
    //납품서 상세현황 Grid Column
    const columnDefs2 = [
        { headerName: "dlno(H)", field: "wrcdlno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "vnt1(H)", field: "vnt1", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "순번", field: "wrcdseq", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "품번", field: "wrcptno", sortable: true, filter: true, flex: 1, minWidth: 250, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
        { headerName: "납기일자", field: "wrcnidt", sortable: true, filter: true, flex: 1, minWidth: 250, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
        { headerName: "발주번호", field: "wrcodno", sortable: true, filter: true, flex: 1, minWidth: 120, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false }, 
        { headerName: "납품량", field: "wrcnpqt", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false}, 
    ];
  
    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 146px  - 50px)"
              });
        }
    }

      
    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={5000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />


            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onSearch={onSearch} onSearchName={null}     //조회
                onInsert={onInsert} onInsertName={"발행"}   //발행
            >
                {"납입지시 > 시작차 출하관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>납품서 발행</b>
            </Ribbon>

            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick={onClick} />


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:146px */}
                <div className={"grid_type01"} style={{height:panded===true?"100%":"200%"}}> {/* 상단 FRAME */}
                    <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                        <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                            <Grid
                                ref={grdRom81Main}
                                gridId={"ROM81"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                pagination={false}                  //  쪽수 매기기 여부
                                paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                                rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={true}      // cell selection drag mode
                                isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                insertApi={insertApi}               // 등록
                                insertParam={inserts}               // 등록 및 수정시 Parmeter
                                insertRefresh={()=> {onSearch(); onSearch2();} } // 등록이후 
                                onClearApi={onClearApi}             // 초기화
                                onCellValueChangedEvent={grdRom81Main_onCellValueChanged}
                                onRowClickEvent={grdRom81Main_onRowClicked}
                            />
                        </div>
                    </div>
                </div>

                <div className="grid_type02">{/* 하단 FRAME */}
                    <div className="grid_wrap02"> {/* 하단 wrap 좌측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <GridRibbon title={"납품서 발행현황"}
                                onDelete={onDelete2} onDeleteName={"취소"}
                                onPrint={onPrint2} onPrintName={"인쇄"}
                            >
                            </GridRibbon>
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* 납품서 발행현황 Grid */}
                                <Grid
                                    ref={grdRom81List}
                                    gridId={"ROM812"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs1}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={false}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    searchApi={searchApi2}              // 조회
                                    deleteApi={deleteApi2}              // 삭제
                                    deleteRefresh={onSearch2}           // 삭제이후 조회
                                    onClearApi={onClearApi2}            // Clear
                                    onRowClickEvent={grdRom81List_onRowClicked}     
                                    onCellValueChangedEvent={grdRom81List_onCellValueChanged}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid_wrap03"> {/* 하단 wrap 우측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <GridRibbon title={"납품서 상세현황"}
                                onDelete={onDelete3} onDeleteName={"납품목록 삭제"}
                            >
                            </GridRibbon>
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* 납품서 발행현황 Grid */}
                                <Grid
                                    ref={grdRom81Detail}
                                    gridId={"ROM813"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs2}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}                 // No 컴럼 Display
                                    searchApi={searchApi3}              // 조회
                                    onClearApi={onClearApi3}            // 초기화
                                    updateApi={updateApi3}              // 수정
                                    deleteApi={deleteApi3}              // 삭제
                                    onCellValueChangedEvent={grdRom81Detail_onCellValueChanged}
                                    deleteRefresh={onSearch2}           // 삭제이후 
                                    clearGrid={clearGrid3}  
                                    onClearGridFlag={setClearGrid3}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Content>

            <div className="printArea">
                <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef} style={{ display: "none" }} />} pageStyle={pageStyle} content={() => printRef.current} />
                <WrapStartCardPrint ref={printRef} printData={printdata} />
            </div>
        </>
    )
  }
  export default ROM81;  