/**
 * @Page ROM4H
 * @Description [ROM4H]납입지시 - ERP_OEM출하관리 - 부품식별표(VMI)
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import GridRibbon from "@/components/organisms/GridRibbon";
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import * as GridUtil from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import Popup from '@/pages/Popup/Popup';    
  import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
 import ReactToPrint from "react-to-print";

 import WrapErpLabelPrintPercent from "@/components/molecules/WrapErpLabelPrintPercent"; 
 import WrapErpLabelPrintPercentB from "@/components/molecules/WrapErpLabelPrintPercentB"
 import WrapErpLabelPrintC from "@/components/molecules/WrapErpLabelPrintC";

 import WrapErpLabelPrint_A_NEW from "@/components/molecules/WrapErpLabelPrint_A_NEW"; 
 import WrapErpLabelPrint_B_NEW from "@/components/molecules/WrapErpLabelPrint_B_NEW"
 import WrapErpLabelPrint_C_NEW from "@/components/molecules/WrapErpLabelPrint_C_NEW";
  
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();

 
 function ROM4H() {
 
    const token = Common.getToken();
    const userId = Common.getToken("id");
    const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const bigi3 = Common.getToken("bigi");
    //let bigiGubn = "";
    let bigi2 ="";
    const grdRom4hMain = useRef();   //Grid Main
    const printRef = useRef();
    const printBtnRef = useRef();
    const printRef2 = useRef();
    const printBtnRef2 = useRef();
    const printRef3 = useRef();
    const printBtnRef3 = useRef();
    
    // Print Style CSS Setting
    const pageStyle = `
    @media all {
        .pagebreak {
        display: none;
        }
    }
    
    @media print {
        html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
        }
    }
    
    @media print {
        .pagebreak {
        margin-top:1rem;
        display: block;
        page-break-after: always;
        }
    }
    
    @page {
        size: A4 landscape !important;    
        margin:7mm 10mm;
    }
    body, div, table, td{
        font-family:굴림체,Gulim ;
    }

    `;
    const pageStyle2 = `

        @page{size: A4 landscape;  margin: 0;  }  // 20231020오후 최종

        body, div, table, td{
            font-family:굴림체,Gulim ;

        }
        @media print {

            html, body {
                height: initial !important;
                overflow: initial !important;
                //-webkit-print-color-adjust: exact; // 프린트 컬러 옵션
                }

        .page-break_5 { page-break-inside:avoid; page-break-after:always }
        .page-break { page-break-inside:avoid; page-break-after:always }
        }
   `;
   
    
    

    
    //Data
    const [idata, setData] = useState({
        //조회조건
        kVnt1 : tVnt1,      //T1업체
        kComp: tComp,       //회사구분
        kPlnt: "",          //공장
        kGate: "",          //하치장
        chkNpdt : "Y",      //납품일자 CheckBox
        kNpdt: Common.getToday(),  //납품일자
        chkLtno : "Y",      //Lot No CheckBox
        kLtno : Common.getToday(),  //Lot No
        chkCarc : "Y",
        kCarc : "",         //차종
        kCarcNm : "",       //차종명
        kPtno : "",         //품번 - 791502P000 샘플
        kType : "",         //식별표타입
        kTypeNm : "",       //식별표타입
        chkMetal : "N",     //중금속확인필
        kStart : "1",        //START 출력 시작 위치
        kCkdOem : "OEM",     // CKD OEM 구분  출력용 
        bigiGubn : "",
        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장
        gateList : [],  //하치장
        carcList : [],  //차종
        typeList : [],  //식별표타입
    }); 
    
            
    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;
  
  
    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
  

    //차종 Popup
    const [typeOpen, setTypeOpen] = React.useState(false);

    //차종 Popup
    const [sampleOpen, setSampleOpen] = React.useState(false);
   
    let [inserts, setInsert] = useState({}); 

    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    let [clearGrid, setClearGrid] = useState(false);  

    let [bigi, setBigi] = React.useState("");
    let [printdata, setPrintData] = useState([]);     
    let [printdata2, setPrintData2] = useState([]);   
    let [printdata3, setPrintData3] = useState([]);   
    let [loading, setLoading] = useState(false); 
    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 146px  - 50px)"});


    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
    }, []);

    



    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }

    /**
     * 식별표 타입 조회
     * @param {*} para 
     * @param {*} name 
     */
    const onSearchCommon1 = (para, name) => {
        let commonData = idata;

        const fetchData1 = async (para, name) => {

            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search1?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData["kType"] = data.data[0].comCode
                        commonData["kTypeNm"] = data.data[0].text
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData1(para, name);
    }


    /**
     * bigi 조회
     * @param {*} para 
     * @param {*} name 
     */
     const onSearchCommon2 = (para, name) => {
        let commonData = idata;

        const fetchData1 = async (para, name) => {

            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search1?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        if(data.data[0].text==="Y"){
                            setBigi(data.data[0].text);
                            bigi2 = data.data[0].text;
                            commonData[name] = data.data[0].text;
                        }else{
                            setBigi(data.data[0].text);
                            bigi2 = data.data[0].text;                            
                            commonData[name] = data.data[0].text;
                        }
                        setData({...commonData});
                    }

                })
        }
        fetchData1(para, name);
        
    }
    
    /**
     *MOUNT 
    */
    useEffect( () => {
        
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
        //공통코드 조회 (공장)
        onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");
        //공통코드 조회 (차종)
        onSearchCommon(`{"pgmid":"MODL", "kVnt1":"${tVnt1}","kComp":"${idata.kComp}"}`, "carcList");
        //공통코드 조회 (식별표타입)
        onSearchCommon1(`{"pgmid":"CODE", "vand":"****", "gocd":"PTCD"}`, "typeList");
        //공통코드 조회 (BIGI)
        onSearchCommon2(`{"pgmid":"BIGI", "vand":"****", "gocd":"${userId}"}`, "bigiGubn");

    }, []);
  
  
  
    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value, desc } = e.target;

        let newSearchs = idata; 
        newSearchs[name] = value;     
        if(name === "kCarc"){   // 차종 코드 변경 시 차종 명 값 셋팅하기
            newSearchs[name+"Nm"] = desc;
        }

        setData({ ...newSearchs });

        //공장 변경시 하치장 조회
        if (name === "kPlnt") {
            onSearchCommon(`{"pgmid":"VGATE","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}"}`, "gateList");
        }
        //T1업체, 회사구분 변경시 
        else if (name === "kVnt1" || name === "kComp")  {
            
            newSearchs["plntList"] = [];
            newSearchs["gateList"] = [];
            newSearchs["carcList"] = [];
            newSearchs["kPlnt"] = "";
            newSearchs["kGate"] = "";
            newSearchs["kCarc"] = "";
            newSearchs["kCarcNm"] = "";
            setData({ ...newSearchs });

            //공통코드 조회 (공장)
            onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");

            //공통코드 조회 (차종)
            onSearchCommon(`{"pgmid":"MODL", "kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "carcList");
        }
        //하치장, 미소요량 Check 시 조회 Call
        else if  (name === "kGate" )  {
            if(value!=""){
                onSearch(); //조회 Call
            }
        }else if(name === "kGubn"){
            onSearch(); //조회 Call
        }
    }
 
  
  
    /**
     * Grid Event 
     * @param {*} e 
    */
    const grdRom4hMain_onCellValueChanged = async (e) => {
        
        let result;

        //납품수량과 용기당수 계산을 하여 용기수 Setting
        if (e.column.colId === "npqt" || e.column.colId === "qypc") {
            if(e.data.npqt === "0" || e.data.npqt === 0 || e.data.npqt === ''){
                e.node.setDataValue('caseqty', '');
                e.node.setSelected(false);
            }
            else {
                if(e.data.npqt !== "" || e.data.npqt !==null ) {
                    result = Math.ceil(parseInt(e.data.npqt) / parseInt(e.data.qypc));
                    if(result === Infinity){//용기당 수가 0일때 용기수 빈 값
                        result = '';    
                    }else{
                        result = result;
                    }
                }else {
                result = '';
                }
                e.node.setDataValue('caseqty', result);
            }   
        }

    }
    
    const grdRom4hMain_onRowClicked = async (e) => {
    }

    //Cell Key Down
    const grdRom4hMain_onCellKeyDown = async (e) => {
    }
    
  
  
    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //T1업체
            {
                "formatter": "select", "name": "kVnt1", "labelName": "T1업체", "type": "text", "defaultValue": idata.kVnt1,  
                "codeMode": true,
                "selectAll": "",
                "codes": null,
                "comms": idata.vnt1List,
                "align": "left", 
            }, 
            //회사구분
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": true,
                "selectAll": "",
                "codes": CODES["comp"],
                "comms": null,
                "align": "left", 
            }, 
                
            
            {"formatter": "blank", "align": "right" },
            {
                "formatter": "checkInput", 
                "labelOptions": [{ "name": "chkNpdt", "labelName": "납품일자", "defaultValue": idata.chkNpdt }], 
                "name": "kNpdt", "type": "date", "desc": "", "readOnly" : false,
                "defaultValue": idata.kNpdt,  
                "align": "left", 
                "labelType": "insert1" 
            }, 
            {
                "formatter": "checkSelect",
                "labelOptions": [{ "name": "chkCarc", "labelName": "차종", "defaultValue": idata.chkCarc }], 
                "name": "kCarc", "labelName": "", "type": "text", "defaultValue": idata.kCarc,  
                "codeMode": false,
                "selectAll": "none",
                "codes": null,
                "comms": idata.carcList,
                "defaultValueMode": true, 
                "align": "right", 
                
                "labelType": "insert1" 
            }, 
            
        ],
        1: [
            //공장
            {
                "formatter": "select", "name": "kPlnt", "labelName": "공장", "type": "text", "defaultValue": idata.kPlnt,  
                "codeMode": false,
                "selectAll": "none",
                "codes": null,
                "comms": idata.plntList,
            }, 
            //하치장
            {
                "formatter": "select", "name": "kGate", "labelName": "하치장", "type": "text", "defaultValue": idata.kGate,  
                "codeMode": false,
                "selectAll": "none",
                "codes": null,
                "comms": idata.gateList,
            }, 
            {"formatter": "blank", "align": "right" },
            //LOT NO
            {
                "formatter": "checkInput", 
                "labelOptions": [{ "name": "chkLtno", "labelName": "LOT NO", "defaultValue": idata.chkLtno }], 
                
                "name": "kLtno", "type": "date", "desc": "", "readOnly" : false,
                "defaultValue": idata.kLtno,  
                "align": "left", 
                
                "labelType": "insert1" 
            }, 

            //Start
            {
                "formatter": "select", "name": "kStart", "labelName": "START", "type": "text", "defaultValue": idata.kStart,  
                "codeMode": false,
                "selectAll": "",
                "codes": idata.kType && idata.kType==="B"? CODES["startB"] : (idata.kType && idata.kType==="C"? CODES["startC"] : CODES["start"]),
                "comms": null,
                "align": "right", 
                
                "labelType": "insert1" 
            },
        ],
        2: [
             //품번
             {
                "formatter": "input", "name": "kPtno", "labelName": "품번", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kPtno,  
                "align": "left", 
                "labelType": "none", 
            }, 
            //식별표타입
            {
                "formatter": "select", "name": "kType", "labelName": "식별표타입", "type": "text", "defaultValue": idata.kType,  
                "codeMode": true,
                "selectAll": "",
                "codes": null,
                "comms": idata.typeList,
            }, 
            {"formatter": "blank", "align": "right" },
            {
                "formatter": "checkSpan", "name": "chkMetal", "labelName": " ",
                "options": [{ "name": "chkMetal", "labelName": "중금속 확인필", "defaultValue": idata.chkMetal }], 
                
                "labelType": "insert1" 
            },
            {"formatter": "blank", "align": "right" }
        ]
    }
  
    /**
     * 차종관리 닫기 후 차종콤보 재 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearchCarType = () => {
        //공통코드 조회 (차종)
        onSearchCommon(`{"pgmid":"MODL", "kVnt1":"${tVnt1}","kComp":"${idata.kComp}"}`, "carcList");
    }
  
    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {
        
        setClearGrid(true); // Detail Grid 초기화

        //T1업체선택 Check
        if (idata.kVnt1 === "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "1차업체를 선택하십시요 !"});
            document.getElementById("kVnt1").focus();                         
            return ;
        }

        //회사구분선택 Check
        if (idata.kComp === "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "회사구분을 선택하십시요 !"});
            document.getElementById("kComp").focus();                         
            return ;
        }

        //공장선택 Check
        if (idata.kPlnt === "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "공장을 선택하십시요 !"});
            document.getElementById("kPlnt").focus();                         
            return ;
        }

        //하치장선택 Check
        if (idata.kGate === "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "하치장을 선택하십시요 !"});
            document.getElementById("kGate").focus();                                                  
            return ;
        }

        //식별표타입 선택 Check
        if (idata.kType === "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "식별표타입을 선택하십시요 !"});
            document.getElementById("kType").focus();                                                  
            return ;
        }

        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                     "kGate":"${idata.kGate}","kType":"${idata.kType}","kPtno":"${idata.kPtno}"}`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/rom4h/search?query=" + param;
        setSearchApi(query); // Search API
    }


    //deleteBlank
    function deleteBlank(argval){
        if (argval === null || argval === undefined) return "";

        for(var i=argval.length-1 ;i >= 0; i--){
        
            if(argval.charCodeAt(i) !== 12288 && argval.charCodeAt(i) !== 32 ) //charCodeAt(i)  
                return(argval.substring(0,i+1));
        }

        return(argval.length>0?argval.substring(0,0):"")
    }



    /**
     * 인쇄 
     * @param {*} e 
     * @returns 
     */
     const onPrint = async (e) => {
        
        let inputFlag = true;

        // 납품일자 선택 Check
        if (idata.chkNpdt === "Y") {
            if (idata.kNpdt === "") {
                setMessage({ openAlert : true, 
                            severity : "info",
                            msg : "납품일자를 선택하세요."});
                document.getElementById("kNpdt").focus();                         
                return ;
            }
        }

        // 차종 선택 Check
        if (idata.chkCarc === "Y") {
            if (idata.kCarc === "") {
                setMessage({ openAlert : true, 
                            severity : "info",
                            msg : "차종을 선택하세요."});
                document.getElementById("kCarc").focus();                         
                return ;
            }
        }
        
        // LOT NO 선택 Check
        if (idata.chkLtno === "Y") {
            if (idata.kLtno === "") {
                setMessage({ openAlert : true, 
                            severity : "info",
                            msg : "LOT NO를 선택하세요."});
                document.getElementById("kLtno").focus();                         
                return ;
            }
        }

        //select nodes
        let selectedData = grdRom4hMain.current.getSelectedNodes();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "선택한 데이터가 없습니다."});
            return;
        }

        //필수입력항목 Check
        selectedData.forEach((node) => {

            if (deleteBlank(node.data.npqt) === "" || deleteBlank(node.data.npqt) === "0") {
                setMessage({ openAlert : true, 
                             severity : "info",
                             msg : "납품수량을 입력하여 주십시오."});
                inputFlag = false;    
                grdRom4hMain.current.SetFocus(node.rowIndex, "npqt");                             
                return;
            }
            else if (deleteBlank(node.data.stck) === "" || deleteBlank(node.data.stck) === "0") {
                setMessage({ openAlert : true, 
                             severity : "info",
                             msg : "부착면수를 입력하세요."});
                inputFlag = false;                                 
                grdRom4hMain.current.SetFocus(node.rowIndex, "stck");     
                return;
            }
            else if (deleteBlank(node.data.qypc) === "" || deleteBlank(node.data.qypc) === "0") {
                setMessage({ openAlert : true, 
                             severity : "info",
                             msg : "용기당수를 입력하세요."});
                inputFlag = false;                                 
                grdRom4hMain.current.SetFocus(node.rowIndex, "qypc");     
                return;
            }
        });

        if (!inputFlag) return;


        if (idata.kType === "A"){
            //출력 확인 메세지 
            setDlgMessage({openDlg : true,  
                        dlgTrans : "P", 
                        dlgMsg : "선택하신 부품 식별표는 [ "+ idata.kType + " 4매 ] 입니다. 부품식별지 용지를 확인 하십시오. 인쇄 하시겠습니까?" });
        }
        if (idata.kType === "B"){
            //출력 확인 메세지 
            setDlgMessage({openDlg : true,  
                           dlgTrans : "P", 
                           dlgMsg : "선택하신 부품 식별표는 [ "+ idata.kType + " 6매 ] 입니다. 부품식별지 용지를 확인 하십시오. 인쇄 하시겠습니까?" });
        }
        if (idata.kType === "C"){
            //출력 확인 메세지 
            setDlgMessage({openDlg : true,  
                           dlgTrans : "P", 
                           dlgMsg : "선택하신 부품 식별표는 [ "+ idata.kType + " 12매 ] 입니다. 부품식별지 용지를 확인 하십시오. 인쇄 하시겠습니까?" });
        }
        
    }
  
  

    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
    }

  
      
    /**
     * 차종등록
     * @param {*} e 
     */
    const onInsert = async (e) => {
        //차종Popup Open
        setTypeOpen(true);
    }
  
  
  
    /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {
    }
  


    /**
     * 삭제
     * @param {*} e 
     */
    const onDelete = async (e) => {
    }

    const onPrint2 = async (printData) => {

        let ptdata = JSON.stringify(printData);
        let body = {
            "userCode":userId,
            "type":"vmi",
            "json":ptdata.replaceAll('"','\''),
            "state":"REQ"
        };
        let data = JSON.stringify(body);
        
        const query = SERVER_URL + "/bigi/print"; 
        await fetch(query, { method: "POST", headers: {"Content-Type":"application/json"}, body: data })
        .then((resp) => resp.json())
        .then(data => {
            let str = data.data;
            if(str.includes('bigi://vmi') === true){
                window.location.href = data.data.replaceAll("\"","");
                setMessage({ openAlert : true, 
                    severity : "success",
                    msg : "BIGI를 확인해주세요."});
            }else{
                setMessage({ openAlert : true, 
                    severity : "error",
                    msg : "BIGI 프로그램을 다시 시도해주세요."});
            }            
        })
        .catch(error => {
            if (error) {
                setMessage({ openAlert : true, 
                                severity : "error",
                                msg : "BIGI 프로그램을 재시작 후 다시 시도해주세요."});
            }
        });
     
        
    }

    
    const AlertDialogResult = async (e) => {

        setDlgMessage({ ...dlgMessage, openDlg : false});

        if (e.result == "N") return;

        switch (e.Trans) 
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //출력
            case "P" :
                let selectedData = grdRom4hMain.current.getSelectedData();

                const fetchData = async () => {
                    let selectedDataItems = [];
                    let selectDataList = {};
                    let selectPtnoList = [];
                   
                    let printList = {};
                    selectedData.forEach((data) => {
                        selectPtnoList.push(data.ptno.replace(/\//gi,'／'));
                        selectDataList = {vnt1 :idata.kVnt1, comp : idata.kComp, plnt : idata.kPlnt, ltno : idata.kLtno, gate : idata.kGate===""?"N":idata.kGate,
                                          ptno:data.ptno.replace(/\//gi,'／'), stck:data.stck, qypc:data.qypc, npqt:data.npqt, caseqty : data.caseqty, 
                                          vnyn:data.vnyn==="" || data.vnyn==="N"?"N":"Y"} 
                        selectedDataItems.push(selectDataList)
                    });
                    
                    
                    const query = SERVER_URL + "/rom4h/print"; 
                    let param = JSON.stringify({
                        parmData : {vnt1 : idata.kVnt1,
                                    comp : idata.kComp,
                                    plnt : idata.kPlnt,
                                    ltno : idata.chkLtno==="Y"?idata.kLtno:"N", // LOT NO 데이터에 빈값 던질 경우, backend 에러가... 안쓰이지만 "N" 값으로 대체
                                    gate : idata.kGate===""?"N":idata.kGate,
                                    npdt : idata.chkNpdt==="Y"?idata.kNpdt:"N", // 납품일자 데이터에 빈값 던질 경우, backend 에러가... 안쓰이지만 "N" 값으로 대체
                                    chkNpdt:idata.chkNpdt, 
                                    chkLtno : idata.chkLtno,
                                    chkCarc : idata.chkCarc,
                                    carc : idata.chkCarc==="Y"?((idata.kCarc.replace(/,/gi,'，')).replace(/\//gi,'／')).replace(/ /gi,'　'):"N", // 차종 데이터에 빈값 던질 경우, backend 에러가... 안쓰이지만 "N" 값으로 대체
                                    ptnolist : selectPtnoList,
                                    selectedData:selectedDataItems
                                    },
                    });
                    
                    //인쇄데이터
                    let detailPrintInfo = [];
                    let detailPrintInfo2 = {};
                    let printcnt = 0;  
                    let resultQypc; 
                    let realPrintCnt; 

                    await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })
                        .then((resp) => resp.json())
                        .then((data) => {
                            if (data.success) {
                                setMessage({ openAlert : true, 
                                             severity : "success",
                                             msg : "인쇄 조회 성공 하였습니다."});
                                if(idata.kVnt1 ==="M000"){
                                    setMessage({ openAlert : true, 
                                        severity : "success",
                                        msg : "BIGI RPA호출중입니다.."});
                                }else{
                                    setMessage({ openAlert : true, 
                                        severity : "success",
                                        msg : "인쇄 조회 성공 하였습니다."});
                                }

                                if (idata.kType === "A"){
                                    setPrintData(data.data);
                                    
                                    for(let i=0; i<data.data.length;i++){
                                        resultQypc = Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC); //용기수
                                        realPrintCnt = resultQypc*data.data[i].STCK;//용기수*부착면수 -> 실제 부품식별표 갯수
                                        printcnt = printcnt+ parseInt(realPrintCnt);
                                        detailPrintInfo2={
                                            area:data.data[i].TMP1,//지역
                                            itemNo:data.data[i].TMP5,//품번
                                            itemNm:data.data[i].TMP6,//품명
                                            quantity:parseInt(selectedData[i].npqt),//수량
                                            companyNm:data.data[i].TMP10,//회사명
                                            companyCd:data.data[i].TMP12,//회사코드
                                            containerNo:data.data[i].TMP17,//용기번호
                                            attached:data.data[i].STCK,//부착면수
                                            pos:data.data[i].TMP19,//L/R 구분
                                            hpc:data.data[i].TMP23,//hpc
                                            container:data.data[i].QYPC,//용기당수
                                            contents1:data.data[i].TMP25,//이중인쇄1
                                            xPos1:1.000*data.data[i].TMP26,//이중인쇄 x1
                                            yPos1:1.000*data.data[i].TMP27,//이중인쇄 y2
                                            color1:data.data[i].TMP28,//이중인쇄컬러1
                                            fontSize1:1.000*data.data[i].TMP29,//이중인쇄폰트사이즈1
                                            contents2:data.data[i].TMP30,//이중인쇄2
                                            xPos2:1.000*data.data[i].TMP31,//이중인쇄 x2
                                            yPos2:1.000*data.data[i].TMP32,//이중인쇄 y2
                                            color2:data.data[i].TMP33,//이중인쇄컬러2
                                            fontSize2:1.000*data.data[i].TMP34,//이중인쇄폰트사이즈2
                                            itemNmKR:data.data[i].TMP35,//한글품명    
                                            orderNo:data.data[i].TMP22,//공정
                                            isRHD:data.data[i].TMP36,//RHD
                                            spec:data.data[i].TMP20,// 사양
                                            prcd:data.data[i].TMP15,// tmp15 조립코드
                                            poWidget:data.data[i].WTUN,// 사양
                                            issueNo:data.data[i].TMP12+(data.data[i].TMP5).padEnd(15),
                                            resultQypc : Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC), //용기수
                                            realPrintCnt : resultQypc*data.data[i].STCK,//용기수*부착면수 -> 실제 부품식별표 갯수
                                            location : data.data[i].TMP4
                                        };
                                        detailPrintInfo.push(detailPrintInfo2);
                                    }
                                    printList={
                                        type : 4,
                                        seq : parseInt(data.data[0].SEQ)-1,
                                        factoryCode : data.data[0].TMP2,//공장
                                        vehicleType : idata.chkCarc==="Y"?idata.kCarcNm:" ",//erp는 차종명 
                                        location : data.data[0].TMP4,//하치장
                                        isMetal : (idata.chkMetal ==="Y")?true:false,//중금속확인필
                                        lotNo : (idata.chkLtno==="Y")?Common.getLOT(data.data[0].TMP13):"",// LOT NO 날짜 가져오기  data.TMP13:"N";
                                        deliveryDate : idata.chkNpdt==="Y"?idata.kNpdt:" ",//납품일자
                                        start:parseInt(idata.kStart)-1,
                                        printcnt:printcnt,
                                        posx: data.data[0].POSX,
                                        posy: data.data[0].POSY,
                                        detailPrintInfo:detailPrintInfo
                                    }
                                    if(data.data[0].BIGI==="Y"){
                                        setBigi("Y");
                                        bigi2 = "Y";
                                    }else{
                                        setBigi("N");
                                        bigi2 = "N";
                                    }

                                    if(bigi2==="Y"){
                                        onPrint2(printList);
                                    }else{
                                        printBtnRef.current.dispatchEvent(new Event("click", { bubbles: true }));
                                    }
                                    
                                }
                                if (idata.kType === "B"){
                                    setPrintData2(data.data);

                                    for(let i=0; i<data.data.length;i++){
                                        resultQypc = Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC); //용기수
                                        realPrintCnt = resultQypc*data.data[i].STCK;//용기수*부착면수 -> 실제 부품식별표 갯수
                                        printcnt = printcnt+ parseInt(realPrintCnt);
                                        detailPrintInfo2={
                                            area:data.data[i].TMP1,//지역
                                            itemNo:data.data[i].TMP5,//품번
                                            itemNm:data.data[i].TMP6,//품명
                                            quantity:parseInt(selectedData[i].npqt),//수량
                                            companyNm:data.data[i].TMP10,//회사명
                                            companyCd:data.data[i].TMP12,//회사코드
                                            containerNo:data.data[i].TMP17,//용기번호
                                            attached:data.data[i].STCK,//부착면수
                                            pos:data.data[i].TMP19,//L/R 구분
                                            hpc:data.data[i].TMP23,//hpc
                                            container:data.data[i].QYPC,//용기당수
                                            contents1:data.data[i].TMP25,//이중인쇄1
                                            xPos1:1.000*data.data[i].TMP26,//이중인쇄 x1
                                            yPos1:1.000*data.data[i].TMP27,//이중인쇄 y2
                                            color1:data.data[i].TMP28,//이중인쇄컬러1
                                            fontSize1:1.000*data.data[i].TMP29,//이중인쇄폰트사이즈1
                                            contents2:data.data[i].TMP30,//이중인쇄2
                                            xPos2:1.000*data.data[i].TMP31,//이중인쇄 x2
                                            yPos2:1.000*data.data[i].TMP32,//이중인쇄 y2
                                            color2:data.data[i].TMP33,//이중인쇄컬러2
                                            fontSize2:1.000*data.data[i].TMP34,//이중인쇄폰트사이즈2
                                            itemNmKR:data.data[i].TMP35,//한글품명    
                                            orderNo:data.data[i].TMP22,//공정
                                            isRHD:data.data[i].TMP36,//RHD
                                            spec:data.data[i].TMP20,// 사양
                                            prcd:data.data[i].TMP15,// tmp15 조립코드
                                            poWidget:data.data[i].WTUN,// 사양
                                            issueNo:data.data[i].TMP12+(data.data[i].TMP5).padEnd(15),
                                            resultQypc : Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC), //용기수
                                            realPrintCnt : resultQypc*data.data[i].STCK,//용기수*부착면수 -> 실제 부품식별표 갯수
                                            location : data.data[i].TMP4
                                            
                                        };
                                        detailPrintInfo.push(detailPrintInfo2);
                                    }
                                    printList={
                                        type : 6,
                                        seq : parseInt(data.data[0].SEQ)-1,
                                        factoryCode : data.data[0].TMP2,//공장
                                        vehicleType : idata.chkCarc==="Y"?idata.kCarcNm:" ",//차종
                                        location : data.data[0].TMP4,//하치장
                                        isMetal : (idata.chkMetal ==="Y")?true:false,//중금속확인필
                                        lotNo : (idata.chkLtno==="Y")?Common.getLOT(data.data[0].TMP13):"",// LOT NO 날짜 가져오기  data.TMP13:"N";
                                        deliveryDate : idata.chkNpdt==="Y"?idata.kNpdt:" ",//납품일자
                                        start:parseInt(idata.kStart)-1,
                                        printcnt:printcnt,
                                        posx: data.data[0].POSX,
                                        posy: data.data[0].POSY,
                                        detailPrintInfo:detailPrintInfo
                                    }
                                    if(data.data[0].BIGI==="Y"){
                                        setBigi("Y");
                                        bigi2 = "Y";
                                    }else{
                                        setBigi("N");
                                        bigi2 = "N";
                                    }

                                    if(bigi2==="Y"){
                                        onPrint2(printList);
                                    }else{
                                        printBtnRef2.current.dispatchEvent(new Event("click", { bubbles: true }));
                                    }
                                }
                                if (idata.kType === "C"){ 
                                    setPrintData3(data.data);
                                    
                                    for(let i=0; i<data.data.length;i++){
                                        resultQypc = Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC); //용기수
                                        realPrintCnt = resultQypc*data.data[i].STCK;//용기수*부착면수 -> 실제 부품식별표 갯수
                                        printcnt = printcnt+ parseInt(realPrintCnt);
                                        detailPrintInfo2={
                                            area:data.data[i].TMP1,//지역
                                            itemNo:data.data[i].TMP5,//품번
                                            itemNm:data.data[i].TMP6,//품명
                                            quantity:parseInt(selectedData[i].npqt),//수량
                                            companyNm:data.data[i].TMP10,//회사명
                                            companyCd:data.data[i].TMP12,//회사코드
                                            containerNo:data.data[i].TMP17,//용기번호
                                            attached:data.data[i].STCK,//부착면수
                                            pos:data.data[i].TMP19,//L/R 구분
                                            hpc:data.data[i].TMP23,//hpc
                                            container:data.data[i].QYPC,//용기당수
                                            contents1:data.data[i].TMP25,//이중인쇄1
                                            xPos1:data.data[i].TMP26,//이중인쇄 x1
                                            yPos1:data.data[i].TMP27,//이중인쇄 y2
                                            color1:data.data[i].TMP28,//이중인쇄컬러1
                                            fontSize1:data.data[i].TMP29,//이중인쇄폰트사이즈1
                                            contents2:data.data[i].TMP30,//이중인쇄2
                                            xPos2:data.data[i].TMP31,//이중인쇄 x2
                                            yPos2:data.data[i].TMP32,//이중인쇄 y2
                                            color2:data.data[i].TMP33,//이중인쇄컬러2
                                            fontSize2:data.data[i].TMP34,//이중인쇄폰트사이즈2
                                            itemNmKR:data.data[i].TMP35,//한글품명    
                                            orderNo:data.data[i].TMP22,//공정
                                            isRHD:data.data[i].TMP36,//RHD
                                            spec:data.data[i].TMP20,// 사양
                                            prcd:data.data[i].TMP15,// tmp15 조립코드
                                            poWidget:data.data[i].WTUN,// 사양
                                            issueNo:data.data[i].TMP12+(data.data[i].TMP5).padEnd(15),
                                            resultQypc : Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC), //용기수
                                            realPrintCnt : resultQypc*data.data[i].STCK,//용기수*부착면수 -> 실제 부품식별표 갯수
                                            location : data.data[i].TMP4
                                            
                                        };
                                        detailPrintInfo.push(detailPrintInfo2);
                                    }
                                    printList={
                                        type : 12,
                                        seq : parseInt(data.data[0].SEQ)-1,
                                        factoryCode : data.data[0].TMP2,//공장
                                        vehicleType : idata.chkCarc==="Y"?idata.kCarcNm:" ",//차종
                                        location : data.data[0].TMP4,//하치장
                                        isMetal : (idata.chkMetal ==="Y")?true:false,//중금속확인필
                                        lotNo : (idata.chkLtno==="Y")?Common.getLOT(data.data[0].TMP13):"",// LOT NO 날짜 가져오기  data.TMP13:"N";
                                        deliveryDate : idata.chkNpdt==="Y"?idata.kNpdt:" ",//납품일자
                                        start:parseInt(idata.kStart)-1,
                                        printcnt:printcnt,
                                        posx: data.data[0].POSX,
                                        posy: data.data[0].POSY,
                                        detailPrintInfo:detailPrintInfo
                                    }
                                    if(data.data[0].BIGI==="Y"){
                                        setBigi("Y");
                                        bigi2 = "Y";
                                    }else{
                                        setBigi("N");
                                        bigi2 = "N";
                                    }

                                    if(bigi2==="Y"){
                                        onPrint2(printList);
                                    }else{
                                        printBtnRef3.current.dispatchEvent(new Event("click", { bubbles: true }));
                                    }
                                }
                                
                                // 인쇄 출력후 재조회 
                                setTimeout(() => onSearch(), 300);
                            }
        
                            if (data.error) {
                                setMessage({ openAlert : true, 
                                             severity : "error",
                                             msg : "조회 실패"});
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {
                                setMessage({ openAlert : true, 
                                             severity : "error",
                                             msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                };
        
                fetchData();

                

                break;

                
        }
        
    }
      
    

    /**
     * 엑셀
     * @param {*} e 
     */
     const onExcel = async (e) => {
        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                     "kGate":"${idata.kGate}","kType":"${idata.kType}","kPtno":"${idata.kPtno}"}`;

        let param = encodeURIComponent(para);
        const query = SERVER_URL + "/rom4h/excel?query=" + param;

        setLoading(true);
        
        await fetch(query, {headers: {Authorization: token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'rom4h.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })

        setLoading(false);
        setMessage({ openAlert : true, 
                        severity : "success",
                        msg : "엑셀파일이 생성되었습니다."});
    }
 
     
  
    /**
     *Grid Column 정의 
    */
    const columnDefs = [
        { headerName: "vnyn(h)", field: "vnyn", sortable: true, filter: true, flex: 1, minWidth: 280, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true }, //valueGetter: Utils.partnoGetter,
        { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 280, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }, //valueGetter: Utils.partnoGetter,
        { headerName: "납품수량", field: "npqt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: true,cellEditor:"numericEditor", cellEditorParams:{maxLength:13}, headerClass: "editCell", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false }, 
        { headerName: "용기당수", field: "qypc", sortable: true, filter: true, flex: 1, minWidth: 120, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", cellEditorParams:{maxLength:13}, singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false
        ,cellStyle: params => {if (params.data.vnyn === "Y") {return {color: "rgb(243 0 0)",fontWeight: "bold"};}return null;} }, 
        { headerName: "용기수", field: "caseqty", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "부착면수", field: "stck", sortable: true, filter: true, flex: 1, minWidth: 120, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", cellEditorParams:{maxLength:13}, singleClickEdit: true, hide: false}, 
        { headerName: "용기No", field: "csno", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false}, 
        { headerName: "L/R", field: "ckgb", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false}, 
        { headerName: "특기", field: "optn", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false}, 
        { headerName: "지역", field: "spec", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false}, 
        { headerName: "공정", field: "shop", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false}, 
        { headerName: "HPC", field: "hpcc", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false}, 
    ];

      
    const onClick = async (e) => {
        setPanded(!panded);


        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 146px  - 50px)"
              });
        }


    }
    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({...message, openAlert:false})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

            <Popup type="CarType" open={typeOpen} onClose={()=> {setTypeOpen(false);onSearchCarType();}} para={idata.kComp}/>

            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onInfoMsg={"그리드의 용기당수량의 빨강색표시는 <br/> 납입지시 > 기초관리 > ERP_품목관리화면의 사용자지정 용기당수량항목의 사용유무로 표출됩니다."}
                onSearch={onSearch} onSearchName={null}             //조회
                onPrint={onPrint} onPrintName={"인쇄"}
                onFunction={onInsert} onFunctionName={"차종등록"}   //차종등록
                onFunctionType={"insert"}                           //차종등록 버튼이미지명 호출
                onExcel={onExcel} onExcelName ={null}   //Excel
            >
                {"납입지시 > ERP_OEM 출하관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardMedium, NanumGothic, sans-serif" }}>부품식별표(VMI)</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick = {onClick} />


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:146px */}
                <div className={"grid_type01"} > {/* 상단 FRAME */}
                    <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                        <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                            <Grid
                                ref={grdRom4hMain}
                                gridId={"ROM4H"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                pagination={true}                   //  쪽수 매기기 여부
                                paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                                rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={true}      // cell selection drag mode
                                isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                onClearApi={onClearApi}             // 초기화
                                clearGrid={clearGrid}
                                onClearGridFlag={setClearGrid}
                                onCellValueChangedEvent={grdRom4hMain_onCellValueChanged}
                                onRowClickEvent={grdRom4hMain_onRowClicked}
                            />
                        </div>
                    </div>
                </div>

                
            </Content>



            {Common.getToken('propt') === 'Y'? // 신규 프린트 사용하는
                <>
                <div className="printArea">
                    {bigi !== "Y"
                    ?<>
                        <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef} style={{ display: "none" }} />} pageStyle={pageStyle2} content={() => printRef.current} />
                        <WrapErpLabelPrint_A_NEW ref={printRef} printData={printdata} idata={idata} />
                    </>
                    :<></>
                    }
                </div>

                <div className="printArea">
                    {bigi !== "Y"
                    ?<>
                        <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef2} style={{ display: "none" }} />} pageStyle={pageStyle2} content={() => printRef2.current} />
                        <WrapErpLabelPrint_B_NEW ref={printRef2} printData={printdata2} idata={idata} />
                    </>
                    :<></>
                    }
                </div>
                
                <div className="printArea">
                    {bigi !== "Y"
                    ?<>
                        <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef3} style={{ display: "none" }} />} pageStyle={pageStyle2} content={() => printRef3.current} />
                        <WrapErpLabelPrint_C_NEW ref={printRef3} printData={printdata3} idata={idata} />
                    </>
                    :<></>
                    }
                </div>
            </>
            : 
            <>
            <div className="printArea">
                {bigi !== "Y"
                ?<>
                    <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef} style={{ display: "none" }} />} pageStyle={pageStyle} content={() => printRef.current} />
                    <WrapErpLabelPrintPercent ref={printRef} printData={printdata} idata={idata} />
                </>
                :<></>
                }
            </div>

            <div className="printArea">
                {bigi !== "Y"
                ?<>
                    <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef2} style={{ display: "none" }} />} pageStyle={pageStyle} content={() => printRef2.current} />
                    <WrapErpLabelPrintPercentB ref={printRef2} printData={printdata2} idata={idata} />
                </>
                :<></>
                }
            </div>
            
            <div className="printArea">
                {bigi !== "Y"
                ?<>
                    <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef3} style={{ display: "none" }} />} pageStyle={pageStyle} content={() => printRef3.current} />
                    <WrapErpLabelPrintC ref={printRef3} printData={printdata3} idata={idata} />
                </>
                :<></>
                }
            </div>
        </>
        }
        </>
    )
  }
  export default ROM4H;