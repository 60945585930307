import React, { useEffect } from 'react';
// import React, {useEffect,useState} from 'react';


import '../../css/main.css';
// import '@/css/main.css';

// import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
// import MenuItem from '@material-ui/core/MenuItem';


// const common = require('../../config/commonCode.json');

let codesList;
let commsList; 
let itemsList; // Case 3 - items :: Hard Coding List

function CustomSelect({ //children, ref,
    // const CustomSelect = React.memo(function CustomSelect({ //children, 
    id, name, defaultValue, defaultValueMode,
    codes, comms, items,page,
    codeMode, selectAll, onChange , onInit}) {

    // Case 1 - "Code List" - codes :: codesList
    if (codes) { codesList = codes; } else { codesList = []; }


    // Case 2 - "Search List" - comms :: commsList    
    if (comms) { commsList = comms;
         commsList = commsList.filter(Boolean) // 빈값제거
        } else { commsList = []; }


    // Case 3 - "Hard Coding List" - items :: itemsList
    if (items) { itemsList = items; } else { itemsList = []; }

    useEffect(() => {
        let temp = document.getElementById(id);
        if (temp.options && temp.options[temp.selectedIndex] && temp.options[temp.selectedIndex].text !== '') {
            let e = {
                target: {}
            };
            e.target["name"] = id;
            e.target["value"] = temp && temp.options && temp.options[temp.selectedIndex] ? temp.options[temp.selectedIndex].value : "";
            e.target["desc"] = temp.options[temp.selectedIndex].text;
            if (onInit) {
                onInit(e);
            }
        }else{
            
        }
    }, [commsList,codesList]);

        const handleChange = (e) => { // 검색조건 변경시 이벤트 처리 - 검색조건
            const { name, value,desc } = e.target;
            let event = {
                target: {}
            };
            event.target["name"] = name;
            event.target["value"] = value;
            event.target["desc"] = desc?desc:e.target[e.target.selectedIndex].text;
    
            if (onChange) {
                onChange(event);
            }
        };


    return (
        <>
            <div className={"h_Label1L"}>

            
                <TextField
                    //id={"outlined-select-currency-native11" + id}
                    id={id}
                    name={name}
                    className={page === "ROM3Z"?id==="kVnt1"?"k_combo4":"k_combo":id==="kSort"?"k_combo4":"k_combo"}//page === "ROM3Z"?id==="kVnt1"?"k_combo4":"k_combo":
                    select
                    // label="Native select"
                    // value={currency}
                    // onChange={onChange}
                    onChange={handleChange}
                    SelectProps={{
                        native: true,
                    }}
                    // helperText="Please select your currency"
                    variant="outlined"
                    
                    defaultValue={defaultValueMode?defaultValue:null}
                    /* defaultValueMode :: 중복 옵션값 중 선택한 옵션값 받아오기 위함 - ROM4H / ROM4N / ROM4V */

                    // value={defaultValue}
                    value={defaultValueMode?null:defaultValue}

                // inputRef={ref ? ref : null}
                // defaultValue={{
                //     value: commonList[0]?.value,
                //     label: (commonList || []).filter(x => (x.value.includes(commonList[0]?.value)))[0]?.label
                // }}
                //style={page==="ROM3Z"?{width: "234px"}:{width: "200px"}}
                >

                    {/* selectAll :: 전체 option 표출 여부 */}
                    {/* {selectAll ? <option key={"selectAll" + id} value={""}>{"전체"}</option> : ""} */}
                    {selectAll && selectAll === "none" ?
                        <option key={"selectOne" + id} value={""}>{""}</option>
                        : ""}

                    {selectAll && selectAll === "select" ?
                        <option key={"selectOne" + id} value={""}>{"선택"}</option>
                        : ""}

                    {(selectAll && selectAll === "all") || selectAll === true ?
                        <option key={"selectOne" + id} value={""}>{"전체"}</option>
                        : ""}

                    {/* Case 0 - "ALL List" - ALL :: commonList */}
                    {/* {commonList ? commonList.map((option, index) => (
                        < option key={"codesList" + id + index} value={option.comCode}>
                            {codeMode ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""} */}


                    {/* Case 1 - "Code List" - codes :: codesList   selected={defaultValue === option.comCode ? true : false}*/}
                    {codesList ? codesList.map((option, index) => (
                        < option key={"codesList" + id + index} value={option.comCode}  > 
                            {codeMode ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""}

                    {/* Case 2 - "Search List" - comms :: commsList */}
                    {commsList ? commsList.map((option, index) => (
                        < option key={"commsList" + id + index} value={option.comCode}  >
                            {codeMode ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""}

                    {/* Case 3 - "Hard Coding List" - items :: itemsList */}
                    {itemsList ? itemsList.map((option, index) => (
                        < option key={"itemsList" + id + index} value={option.comCode}  >
                            {codeMode ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""}

                </TextField>
            </div>
        </>
    );
}

export default CustomSelect;
// export default React.memo(CustomSelect);