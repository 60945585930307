/**
 * @Page Kia2DLabelPrint_B_NEW
 * @Description [ROM4V]납입지시 - ERP_OEM출하관리 - 부품식별표(KIA 2D) :: KIA 2D 부품식별표 인쇄(B 6매)
 **/
import React from "react";
import CustomDataMatrix from '@/components/atoms/CustomDataMatrix';
import * as Common from "../../utils/Common";
import styled from 'styled-components';
import '@/css/common.css';

class Kia2DLabelPrint_B_NEW extends React.Component {


    constructor(props) {
        super(props);
        this.state = { };
    }

    componentDidMount() {  }
        
    render() {

    const PrintStyleKiaB = styled.div`
    @media all {

        body {
            background-color: ;
            margin: 0px;
            padding: 0px;
            font-family: "HY헤드라인M";
        }
        div {
            background-color:;
            margin: 0px;
            padding: 0px;
            box-sizing:border-box;
        }
        .HYheadM12 {
            font-family: "HY헤드라인M";
            font-size:12pt;
            word-break:break-all;
        }
        .HYheadM13 {
            font-family: "HY헤드라인M";
            font-size:13pt;
            word-break:break-all;
        }
        }
        .HYheadM14 {
            font-family: "HY헤드라인M";
            font-size:14pt;
            word-break:break-all;
        }
        .HYheadM16 {
            font-family: "HY헤드라인M";
            font-size:16pt;
            word-break:break-all;
        }
        .HYheadM18 {
            font-family: "HY헤드라인M";
            font-size:18pt;
            word-break:break-all;
        }
        .HYheadM9 {
            font-family: "HY헤드라인M";
            font-size:9pt;
            word-break:break-all;
        }  
        .HYheadM24 {
            font-family: "HY헤드라인M";
            font-size:24pt;
            word-break:break-all;
        }
        .row {             
            display: flex;
            flex-flow: wrap;
        }
        .cell { display : flex; white-space: nowrap; z-index: 99; }

        .cell_hori{ display : flex; justify-content: center; } // 수평 중앙정렬 → 

        .cell_veri{ display : flex; align-items: center; } // 수직 중앙정렬 ↓

        .aligR { flex-direction: row-reverse; }
        
        .row2  { display: flex; flex-flow: row nowrap; }

        .row2>.cell:nth-child(1)  { width: 2mm;  }     // background : red ;    
        .row2>.cell:nth-child(2)  { width: 15mm; }     // background : blue ;   
        .row2>.cell:nth-child(3)  { width: 3mm;  }     // background : orange ; 
        .row2>.cell:nth-child(4)  { width: 19mm; }     // background : purple ; 
        .row2>.cell:nth-child(5)  { width: 15mm; }     // background : beige ;  
        .row2>.cell:nth-child(6)  { width: 19mm; }     // background : pink ;   
        .row2>.cell:nth-child(7)  { width: 3mm;  }     // background : orange ; 
        .row2>.cell:nth-child(8)  { width: 12mm; }     // background : green ;  
        .row2>.cell:nth-child(9)  { width: 13mm; }     // background : yellow ; 
        .row2>.cell:nth-child(10) { width: 17mm; }     // background : red ;    
        .row2>.cell:nth-child(11) { width: 3mm;  }     // background : navy ;   
        .row2>.cell:nth-child(12) { width: 3mm;  }     // background : green ;  
        .row2>.cell:nth-child(13) { width: 14mm; }     // background : pink ;   
        .row2>.cell:nth-child(14) { width: 2mm;  }     // background : red ;    
        
    }
  }

`;


    const { printData } = this.props;

    let { kStart, kTelNo, chkMetal, kCarc, chkNpdt, chkCarc, chkLtno, kCarcNm  } = this.props.idata;

    const mmToPx =(mm) => { let px = mm * 3.7795275591; return px; }

    const listSize = printData.length;
    
    let idx = -1; 
    let str = parseInt(kStart)-1;    

    idx =idx + str; // 시작 위치
    
    let vseq = 0;

    let remainChk = 0;

    let mainSearchedDivArr = []; // 전체 반복 이후 새로운 배열에 전체로 담아서 진행한다.
    
    const cardInfo_S = 
        printData ? printData.map((data, index) => {

        if(index === 0){
            vseq = parseInt(data.BSEQ) -1; // 바코드 표출 vseq용
        }

        remainChk = vseq;  // 새 품번의 remain 여부 체크하기
        
        let npqt = parseInt(data.NPQT); // 납품수량
        let qypc = parseInt(data.QYPC); // 용기당 수
        let stck = parseInt(data.STCK); // 부착면 수 

        // 추가 표시 컬러 체크 -- START
        let tmp25 = data.TMP25; // TMP25    WRCLBL1	이중인쇄1 내용
        let tmp28 = data.TMP28; // TMP28    WRCCOL1	이중인쇄1 컬러

        let tmp30 = data.TMP30; // TMP30    WRCLBL2	이중인쇄2 내용
        let tmp33 = data.TMP33; // TMP33    WRCCOL2	이중인쇄2 컬러

        let color1 = "";
        let color2 = "";

        let dualPrint1 = "F"; 
        let dualPrint2 = "F"; 

        if(tmp25!==""){

            dualPrint1 = "T";

            if(tmp33==="B"){ color1 = "blue";
            }else if(tmp33==="G"){ color1 = "green";
            }else if(tmp33==="R"){ color1 = "red";
            }else if(tmp33==="O"){ color1 = "#ff6600";
            }else if(tmp33==="X"){ color1 = "rgba(0, 0, 0, 0.7)"; }
            
        }
        if(tmp30!==""){

            dualPrint2 = "T";

            if(tmp28==="B"){ color2 = "blue";
            }else if(tmp28==="G"){ color2 = "green";
            }else if(tmp28==="R"){ color2 = "red";
            }else if(tmp28==="O"){ color2 = "#ff6600"; 
            }else if(tmp28==="X"){ color2 = "rgba(0, 0, 0, 0.7)"; }
        }

        let result = Math.floor(npqt / qypc); // 몫
        let resultIdx = Math.floor(npqt / qypc); // 목 + 나머지의 카운트
        let remain = npqt % qypc; // 나머지

        if(remain > 0){ resultIdx = result + 1; }

        const newSearched = [];
        let n = 0;
        
        Array.from(new Array(resultIdx), (v,i) =>{  // Main

            Array.from(new Array(stck), (W,j) =>{  // 부착면수 만큼 반복
                
                idx = idx+1;
                
                if(j===0){ // 부착면수 1일때
                    vseq = vseq + 1;    // 새 vseq 마다 + 1 증가 
                }
                
                let iCnt = i+1;
                const newArray = {};
                let jCnt = j+1;

                if(i===0 && j===0){ n=0;}else{ n=n+1; }

                Object.keys(data).forEach(key => { // 기존값 배열에 추가
                    newArray[key] = data[key];
                });
                    
                newArray["idx"] = idx;
                newArray["vseq"] = vseq;
                
                //납품수량
                let newQty = 0;
                newArray["qty"] = qypc; 
                newQty = qypc; 

                newArray["endpage"] = "F"; 

                newArray["color1"] = color1; 
                newArray["color2"] = color2; 

                newArray["dualPrint1"] = dualPrint1; 
                newArray["dualPrint2"] = dualPrint2;

                if(resultIdx === (vseq-remainChk)){
                    if(remain > 0){
                        newArray["qty"] = remain; 
                        newQty = remain; 
                    }
                }

                // END Page 구하기
                if(listSize === (index+1)){   
                    if(resultIdx === iCnt){
                        if(stck === jCnt){
                            newArray["endpage"] = "T"; 
                        }
                    }
                }
                    
                // DataMatrix 바코드 값 만들기
                const barcodeValu = data.VAND +Common.getLpad(data.TMP5," ",15)+Common.getLpad(newQty+"", "0", 5)+data.VAND+Common.getLpad(vseq+"", "0", 6)+ (data.TMP11?data.TMP11.replaceAll('-',''):"");

                const dataMatrixBarcode = <CustomDataMatrix val={barcodeValu} scale={"1.9"} height={"18"} />;
                newArray["barcode"] = dataMatrixBarcode; 
                const lotNo = data.VAND +""+ ("000000" + vseq).slice(-6) +""+ (data.TMP11?data.TMP11.replaceAll('-',''):""); 
                const bNo =data.VAND +" "+Common.getLpad(data.TMP5," ",15)+" "+Common.getLpad(newQty+"", "0", 5) 
                newArray["lotno"] = lotNo;
                newArray["bno"] = bNo;

                // 자기 위치 셋팅 (출력매수)
                const posi = idx%6;                    
                newArray["posi"] = posi;

                // dualPrint1css = {position:"absolute", fontFamily: "HY헤드라인M", top:parseInt(data.TMP27,10)===0?((pagebreaky+2  ))+"%":((parseInt(data.TMP27,10)/2)+((pagebreaky+1  )))+"%", left:(parseInt(data.TMP26,10)-2  )+"mm", color:color1, fontSize:data.TMP29+"pt", fontWeight:"bold",zIndex:"13", whiteSpace:"nowrap"};
                // dualPrint2css = {position:"absolute", fontFamily: "HY헤드라인M", top:parseInt(data.TMP32,10)===0?((pagebreaky+2.8))+"%":((parseInt(data.TMP32,10)/2)+((pagebreaky+1.8)))+"%", left:(parseInt(data.TMP31,10)-1.5)+"mm", color:color2, fontSize:data.TMP34+"pt", fontWeight:"bold",zIndex:"13", whiteSpace:"nowrap"};

                newSearched[n] = newArray;

            })
        })

        mainSearchedDivArr.push(...newSearched)

        })
        :"";

    let cardInfo = 
        <div className="forfor" style={{ display : "flex",flexWrap : "wrap", overflow : "hidden", width : mmToPx(280), marginLeft :mmToPx(10.7 + parseInt( ( Common.getToken("bplx") ? Common.getToken("bplx") : 0 ),10)) }}>
            {/* Start Point */}
            {Array.from(new Array(kStart-1), (v, i) =>{ 
            return  <div className="start_from_test"  
                            style={{  marginTop :  i == 0 || i == 1 ? mmToPx(8 +  parseInt( ( Common.getToken("bply") ? Common.getToken("bply") : 0 ),10)) : "0"
                                    , height: mmToPx(64)
                                    , flexBasis : mmToPx(140) }}></div>
                                    }
                        )
            }
        {mainSearchedDivArr.map((sData, dataIdx) => (
            <div className="start_from" key={"Kia2DLabelPrint_B_NEW"+dataIdx}
                            style={{ marginTop :   sData.posi == 0 ||sData.posi == 1 ? mmToPx( 8 +  parseInt( ( Common.getToken("bply") ? Common.getToken("bply") : 0 ),10)) : "0"
                            , height :mmToPx(64)
                            , flexBasis: mmToPx(140) }}>

                {/* div로 테이블 그리기 */} 
                <div className="dep_div" style={{ overflow:"visible", zIndex:"200", position : "relative" , height : "100%", width : "100%"/* , opacity : "0" */ }}>

                        { sData.dualPrint1 === "T" ?
                            <div style={{ position:"absolute"
                                        , fontFamily: "HY헤드라인M" 
                                        , top:parseInt(sData.TMP27,10) + "mm"  
                                        , left:parseInt(sData.TMP26,10) + "mm" 
                                        , color:sData.color1
                                        , fontSize:sData.TMP29+"pt" 
                                        , fontWeight:"bold"
                                        , whiteSpace:"nowrap"
                                        , visibility : parseInt(sData.TMP27,10)  > 96 ? "hidden" : "visible"
                                        , zIndex:"13"} }> {sData.TMP25} </div>
                        :"" }

                        { sData.dualPrint2 === "T" ?
                            <div style={{ position:"absolute" 
                                        , fontFamily: "HY헤드라인M" 
                                        , top:parseInt(sData.TMP32,10) + "mm" 
                                        , left:parseInt(sData.TMP31,10) + "mm" 
                                        , color:sData.color2 
                                        , fontSize:sData.TMP34+"pt" 
                                        , fontWeight:"bold" 
                                        , whiteSpace:"nowrap"
                                        , visibility : parseInt(sData.TMP32,10)  > 96 ? "hidden" : "visible"
                                        , zIndex:"13"}}>{sData.TMP30}</div>
                        : "" }

                         <div className="row2" style={{height:"2mm"/* , background : "black" */}}>
                            <div className="cell"></div>
                        </div>

                        <div className="row2" style={{height:"7mm"}}>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell HYheadM14 cell_hori cell_veri">{sData.TMP1}</div>{/* 공장 */}
                            <div className="cell"></div>
                            <div className="cell HYheadM14 cell_hori cell_veri" style={{ whiteSpace:"nowrap"}}>{chkCarc&&chkCarc==="Y"?kCarcNm:""}</div>{/*차종 chkCarc kCarc kCarcNm sData.TMP3 - ERP의 경우 차종명을 가져오도록 수정  */}
                            <div className="cell" style={{ flex : "1 1 15mm"}}></div>
                            <div className="cell HYheadM14 cell_hori cell_veri " style={{ flex : "1 1 50mm", whiteSpace:"nowrap" }}>{sData.TMP4}</div>{/* 지역 */}
                            <div className="cell" style={{ flex : "1 1 2mm"}}></div>
                        </div>

                        <div className="row2" style={{height:"7mm"}}>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell HYheadM16 cell_veri" style={{flex : "1 1 53mm"}}>{sData.TMP5.substr(0, 5) + "-" + sData.TMP5.substr(5)}</div>{/* 품번 */}
                            <div className="cell" style={{ flex : "1 1 15mm"}}></div>
                            <div className="cell HYheadM12 cell_hori cell_veri" style={{flex : "1 1 13mm"}}>{sData.VAND}</div>{/* 업체코드 */}
                            <div className="cell HYheadM12 cell_veri" style={{flex : "1 1 37mm"}}>{sData.TMP0}</div>{/* 업체명 */}
                            <div className="cell" style={{ flex : "1 1 2mm"}}></div>
                        </div> 


                        <div className="row2" style={{height:"9mm"}}>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell HYheadM16 cell_veri" style={{verticalAlign:"middle", flex : "1 1 101mm" }}>{sData.TMP6}</div>{/* 품명 */}
                            <div className="cell" style={{flex : "1 1 3mm" }}></div>
                                <div className="cell HYheadM24 cell_veri"  style={{ flex : "1 1 14mm" }}>{sData.TMP10}</div>{/*  */}       
                                <div className="cell" style={{ flex : "1 1 2mm"}}></div>
                        </div> 

                        <div className="row2" style={{height:"7mm"}}>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell HYheadM14 cell_hori cell_veri">{sData.TMP7}</div>{/* 납품장소 */}
                            <div className="cell"></div>
                            <div className="cell HYheadM12 cell_hori cell_veri">{sData.TMP8}</div>{/* 용기 */}
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell HYheadM16 cell_hori cell_veri"  style={{flex : "1 1 30mm" }}>{sData.qty}</div>{/* 수납수 */}
                            <div className="cell" style={{ flex : "1 1 6mm"}}></div>
                            <div className="cell" style={{ flex : "0", position : "absolute", left : "120mm", marginTop : "-22px", zIndex : "0" }}>
                                {chkMetal==="Y" ?
                                    <img src ="/images/heavy_metal_confirm.gif" style={{width:"18mm"}} alt="heavy_metal_confirm" />                                
                                :
                                    <img src ="/images/content.png" style={{width:"13mm"}} alt=" " />
                                }
                            </div>
                            <div className="cell" style={{ flex : "1 1 14mm"}}></div>
                            <div className="cell" style={{ flex : "1 1 2mm"}}></div>
                        </div> 

                        <div className="row2" style={{height:"5mm" /* , border : "1px solid red" */}}>
                            <div className="cell" style={{/* border : "1px solid blue" */}}></div>
                            <div className="cell" style={{/* border : "1px solid purple" */}}></div>
                            <div className="cell" style={{/* border : "1px solid red" */}}></div>
                            <div className="cell" style={{/* border : "1px solid pink" */}}></div>
                            <div className="cell HYheadM12 cell_veri" style={{ flex : "1 1 62mm", whiteSpace: "pre",  /* border : "2px solid yellow" */}}>Lot No : {chkLtno&&chkLtno==="Y"?sData.lotno:""}</div>{/* Lot No */}
                            <div className="cell HYheadM12 cell_veri" style={{ flex : "1 1 37mm", /* border : "1px solid purple",  */ whiteSpace: "pre", }}>
                                {/* KV41은 방드시 예외처리 삭제필요하다. */}
                                {sData.TMP1==='KV21'||sData.TMP1==='KV22'||sData.TMP1==='KV23'/*  || sData.TMP1==='KV41' */?
                                    ( sData.WTUN === ""||sData.WTUN === "0"||sData.WTUN === undefined) ? ""  : `총중량 :${(sData.qty*sData.WTUN).toFixed(1)+"kg"}`
                                    :""
                                }
                            </div>
                            <div className="cell" style={{ flex : "1 1 2mm"}}></div>
                        </div> 

                        <div className="row2" style={{height:"7mm"}}>
                            {/* <div className="cell HYheadM12" style={{zIndex:"15",paddingLeft:"24px", paddingTop:"17px"}}>
                                {sData.barcode}
                            </div>   */}
                            <div className="cell"></div>
                            <div className="cell"></div>  
                            <div className="cell"></div>  
                            <div className="cell"></div>  
                            <div className="cell"></div>  
                            {/* <div className="cell" style={{ flex : "1 1 34mm"}}></div>   */}
                            <div className="cell HYheadM13 cell_veri"  style={{flex : "1 1 84mm", whiteSpace:"nowrap"}}>{kTelNo}</div>{/* 연락처 sData.TELNO  */}
                            {/* <div className="cell" style={{ flex : "1 1 37mm"}}></div>   */}
                            <div className="cell" style={{ flex : "1 1 2mm"}}></div>  
                        </div> 

                        <div className="row2" style={{height:"7mm"}}>
                            <div className="cell"></div>{/* 2 */}
                            <div className="cell" style={{ flex : "1 1 6mm"}} ></div>
                            <div style={{ position:"absolute" , flex : "0", height : "25mm",marginTop : "-5.5mm", left : "8mm", zIndex : "100" }}>
                                    {sData.barcode}
                            </div>
                            <div className="cell" style={{ flex : "1 1 46mm"}}></div>
                            <div className="cell HYheadM12 cell_hori cell_veri" style={{ flex : "1 1 47mm" }}>{chkNpdt&&chkNpdt==="Y"?sData.TMP2:""}</div>{/* 생산일자 chkNpdt */}
                            <div className="cell cell_hori cell_veri" style={{ flex : "1 1 37mm", marginTop : "5mm", fontSize:"24pt"}}>{sData.TMP14}</div>{/*  */}
                            <div className="cell" style={{ flex : "1 1 2mm"}}></div>  
                        </div> 


                        <div className="row2" style={{height:"7mm"}}>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div> 
                            <div className="cell"></div> 
                            <div className="cell cell_hori cell_veri"  style={{ flex : "1 1 47mm", fontSize:"12pt"}}>{sData.TMP13}</div>{/* 공정No */}
                            <div className="cell" style={{ flex : "1 1 37mm"}}></div>  
                            <div className="cell" style={{ flex : "1 1 2mm"}}></div>
                        </div> 

                        <div className="row2" style={{height:"5mm"}}>
                            <div className="cell" style={{ flex : "1 1 22mm"}}></div>  
                            <div className="cell HYheadM9 cell_veri" style={{ flex : "1 1 116mm"}}>{sData.bno}</div>{/* bno 발행번호 */}
                            <div className="cell" style={{ flex : "1 1 2mm"}}></div>
                        </div> 

                        <div className="row2" style={{height:"1mm"}}> <div className="cell"></div> </div>

                    </div>

                    {/*페이지 넘김 처리 */}
                    { sData.posi===5 && sData.endpage==="F" ? <div className="page-break"></div> : "" }
                
                </div>
            ),"")}
        </div>

    return <PrintStyleKiaB key={"Kia2DLabelPrint_B_NEW"}>{cardInfo}</PrintStyleKiaB>;
    }
}
export default Kia2DLabelPrint_B_NEW;

