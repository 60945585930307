//납입지시 - 기초관리
import { Route, Switch } from 'react-router-dom';
import REP02 from '@/pages/A01/REP02';  //자료생성(수동)
import ROM4L from '@/pages/A01/ROM4L';  //ERP_품목관리
import ROM13 from '@/pages/A01/ROM13';  //T2업체 품목관리
import ROM12 from '@/pages/A01/ROM12';  //차량번호관리
import ROM72 from '@/pages/A01/ROM72';  //단가변동현황


function A01() {
    return ( 
        <Switch>
            <Route exact path='/A01/REP02' component={REP02} /> 
            <Route exact path='/A01/ROM4L' component={ROM4L} /> 
            <Route exact path='/A01/ROM13' component={ROM13} />
            <Route exact path='/A01/ROM12' component={ROM12} />
            <Route exact path='/A01/ROM72' component={ROM72} />
        </Switch>
    );
}

export default A01;
