//수신자료관리 - 모듈단가자료조회
import { Route, Switch } from 'react-router-dom';
import RNTM1 from '@/pages/B04/RNTM1';  //모듈BOM
import RNTC1 from '@/pages/B04/RNTC1';  //모듈 단가현황
import RNTC2 from '@/pages/B04/RNTC2';  //모듈 단가상세내역
import RNTC3 from '@/pages/B04/RNTC3';  //모듈 단가상세내역
import RNTC4 from '@/pages/B04/RNTC4';  //사양차이 정산 상세내역
import RNTC5 from '@/pages/B04/RNTC5';  //단가차이 정산 상세내역
import RNTC6 from '@/pages/B04/RNTC6';  //ZERO단가 조건 정산 상세내역
import RNTC7 from '@/pages/B04/RNTC7';  //정단가 조건 정산 상세내역

function B04() {
    return ( 
        <Switch>
            <Route path='/B04/RNTM1' component={RNTM1} />
            <Route path='/B04/RNTC1' component={RNTC1} />
            <Route path='/B04/RNTC2' component={RNTC2} />
            <Route path='/B04/RNTC3' component={RNTC3} />
            <Route path='/B04/RNTC4' component={RNTC4} />
            <Route path='/B04/RNTC5' component={RNTC5} />
            <Route path='/B04/RNTC6' component={RNTC6} />
            <Route path='/B04/RNTC7' component={RNTC7} />
            
        </Switch>
    );
}

export default B04;
