/**
 * @Page WrapCkdLabelPrint_B_NEW
 * @Description [ROM4K / ROM4R / ROM4X]납입지시 - ERP_CKD출하관리 - 부품식별표(중포장/용기/중포장2D) 인쇄  ::  B 6매
 **/
import React from "react";
import CustomDataMatrix from '@/components/atoms/CustomDataMatrix';
import * as Common from "../../utils/Common"; 
import styled from 'styled-components';

class WrapCkdLabelPrint_B_NEW extends React.Component {

   constructor(props) {
           super(props);
           this.state = { pathNm: null };
   }

   componentDidMount() {
       if(window.location.pathname.split('/')[2] === "ROM4X") { // ROM4X 부품식별표(중포장 2D) check
           this.setState({pathNm:window.location.pathname.split('/')[2]});
       }
   }


// '바코드 = 업체코드(4) + 품번(15) + 수량(5) + LOTNO(3) + 일련번호(4)
//  tmp(0) '회사구분 / tmp(1) '지역 / tmp(2) '공장 / tmp(3) '차종 / tmp(4) '납품장소(하치장)
//  tmp(5) '품번 / tmp(6) '품명 / tmp(7) '납품수량 / tmp(8) '바코드 / tmp(9) '납품일자
//  tmp(10) '업체명 / tmp(11) "" '공정(공백) / tmp(12) '업체코드 / tmp(13) "" ' LOT NO / tmp(14) "" 'HPC(공백)
//  tmp(15) 'CASE(CKD) / tmp(16) "" '발행번호(바코드만들어서 던짐) / tmp(17) "" '용기번호 / tmp(18) '부착매수 / tmp(19) 'LH/RH
//  tmp(20) "" '사양 / tmp(21)  '발주번호(공백) / tmp(22)  'FROM-ORDER(CKD , OEM은 공정) / tmp(23)  'CKD는 공백, OEM은 HPC / tmp(24)  '용기당수
//  tmp(25) = '출력1 / tmp(26) = 'x좌표1 / tmp(27) = 'y좌표1 / tmp(28) = '색상1 / tmp(29) = 'FONT/S1
//  tmp(30) = '출력2 / tmp(31) = 'x좌표2 / tmp(32) = 'y좌표2 / tmp(33) = '색상2 / tmp(34) = 'FONT/S2
//  tmp(35) = '한글품명 / tmp(36) = 'RHD


   render() {
   
   const PrintStyleB = styled.div`
   @media all {
       body {
           background-color: ;
           margin: 0px;
           padding: 0px;
           font-family: "HY견고딕";
       }
       .NmheadM10 { // 추가 (현대자동차)
           font-family: "NanumGothic";
           font-weight:700;
           font-size:10pt;
       }
       .HYheadM10 {
           font-family: "HY견고딕";
           font-weight:bold;
           font-size:10pt;
           word-break:break-all;
       }
       .HYheadM11 {
           font-family: "HY견고딕";
           font-weight:bold;
           font-size:11pt;
           word-break:break-all;
       }
       .HYheadM12 {
           font-family: "HY견고딕";
           font-weight:bold;
           font-size:12pt;
           word-break:break-all;
       }
       .HYheadM13 {
           font-family: "HY견고딕";
           font-weight:bold;
           font-size:13pt;
           word-break:break-all;
       }
       .HYheadM14 {
           font-family: "HY견고딕";
           font-weight:bold;
           font-size:14pt;
           word-break:break-all;
       }
       .HYheadM16 {
           font-family: "HY견고딕";
           font-weight:bold;
           font-size:16pt;
           word-break:break-all;
       }
       .HYheadM17 {
           font-family: "HY견고딕";
           font-weight:bold;
           font-size:17pt;
           word-break:break-all;
       }
       .HYheadM18 {
           font-family: "HY견고딕";
           font-weight:bold;
           font-size:18pt;
           word-break:break-all;
       }
       .HYheadM9 {
           font-family: "HY견고딕";
           font-size:9pt;
           word-break:break-all;
       }  
       .HYheadM22 {
           font-family: "HY견고딕";
           font-weight:bold;
           font-size:22pt;
           word-break:break-all;
       }
       .HYheadM24 {
           font-family: "HY견고딕";
           font-weight:bold;
           font-size:24pt;
           word-break:break-all;
       }
       .HYheadM28 {
           font-family: "HY견고딕";
           font-weight:bold;
           font-size:28pt;
           word-break:break-all;
       }

       .cell { display : flex; white-space: nowrap; z-index: 99;}

       .cell_hori{ display : flex; justify-content: center; } // 수평 중앙정렬 → 

       .cell_veri{ display : flex; align-items: center; } // 수직 중앙정렬 ↓

       .aligR { flex-direction: row-reverse; }
       
       .row2  { display: flex; flex-flow: row nowrap; }
       
       .row2>.cell:nth-child(1)  { width: 1mm;  }
       .row2>.cell:nth-child(2)  { width: 15mm; }
       .row2>.cell:nth-child(3)  { width: 3mm;  }
       .row2>.cell:nth-child(4)  { width: 3mm;  }
       .row2>.cell:nth-child(5)  { width: 14mm; }
       .row2>.cell:nth-child(6)  { width: 13mm; }
       .row2>.cell:nth-child(7)  { width: 14mm; }
       .row2>.cell:nth-child(8)  { width: 3mm;  }
       .row2>.cell:nth-child(9)  { width: 24mm; }
       .row2>.cell:nth-child(10) { width: 10mm; }
       .row2>.cell:nth-child(11) { width: 8mm;  }
       .row2>.cell:nth-child(12) { width: 3mm;  }
       .row2>.cell:nth-child(13) { width: 27mm; }
       .row2>.cell:nth-child(14) { width: 1mm;  }
       .row2>.cell:nth-child(15) { width: 1mm;  }

    }

   @media print { margin : 0  !important} 

       `;

   const { printData } = this.props;

   let { kStart, chkMetal, kCkdOem, chkNpdt, chkLtno, kNpdt } = this.props.idata;

   const mmToPx =(mm) => { let px = mm * 3.7795275591; return px; }

   const listSize = printData.length; // data length

   let idx = -1;
   let str = parseInt(kStart)-1;        

   idx =idx + str; // 시작 위치
   
   let vseq = 0;

   let remainChk = 0;

   let mainSearchedDivArr = []; // 전체 반복 이후 새로운 배열에 전체로 담아서 진행한다.

   const cardInfo_S = 
       printData ? printData.map((data, index) => {
       
        // 바코드 표출 vseq용
       if(index === 0){ vseq = parseInt(data.SEQ)===0?0: parseInt(data.SEQ)-1; }

       remainChk = vseq;  // 새 품번의 remain 여부 체크하기
       
       let npqt = parseInt(data.NPQT); // 납품수량
       let qypc = parseInt(data.QYPC); // 용기당 수
       let stck = parseInt(data.STCK); // 부착면 수 

       // 추가 표시 컬러 체크 -- START
       let tmp25 = data.TMP25; // WRCLBL1	이중인쇄1 내용
       let tmp28 = data.TMP28; // WRCCOL1	이중인쇄1 컬러

       let tmp30 = data.TMP30; // WRCLBL2	이중인쇄2 내용
       let tmp33 = data.TMP33; // WRCCOL2	이중인쇄2 컬러

       let color1 = "";
       let color2 = "";

       let dualPrint1 = "F"; 
       let dualPrint2 = "F"; 
       
       if(tmp25!==""){

           dualPrint1 = "T";

           if(tmp28==="B"){ color1 = "blue";
           }else if(tmp28==="G"){ color1 = "green";
           }else if(tmp28==="R"){ color1 = "red";
           }else if(tmp28==="O"){ color1 = "#ff6600";
           }else if(tmp28==="X"){ color1 = "rgba(0, 0, 0, 0.7)"; }
       }
       if(tmp30!==""){

           dualPrint2 = "T";

           if(tmp33==="B"){ color2 = "blue";    
           }else if(tmp33==="G"){ color2 = "green";
           }else if(tmp33==="R"){ color2 = "red";
           }else if(tmp33==="O"){ color2 = "#ff6600"; 
           }else if(tmp33==="X"){ color2 = "rgba(0, 0, 0, 0.7)"; }
       }

       let result = Math.floor(npqt / qypc); // 몫
       let resultIdx = Math.floor(npqt / qypc); // 목 + 나머지의 카운트
       let remain = npqt % qypc; // 나머지

       if(remain > 0){ resultIdx = result + 1; }

       const newSearched = [];
       let n = 0;

       Array.from(new Array(resultIdx), (i) =>{  // Main

           Array.from(new Array(stck), (w, j) =>{  // 부착면수 만큼 선 반복..
               
               idx = idx+1;
               if(j===0){ // 부착면수 1일때
                   vseq = vseq + 1;    // 새 vseq 마다 + 1 증가 
               }

               let iCnt = i+1;
               const newArray = {};
               let jCnt = j+1;

               // 새 배열의 n 번째 구하기
               if(i===0 && j===0){ n=0; }else{ n=n+1; }

               Object.keys(data).forEach(key => { // 기존값 배열에 추가
                   newArray[key] = data[key];
               });
               
               newArray["idx"] = idx;
               newArray["vseq"] = vseq;
               
               //납품수량
               let newQty = 0;
               newArray["qty"] = qypc; 
               newQty = qypc; 

               newArray["endpage"] = "F"; 
               
               newArray["color1"] = color1; 
               newArray["color2"] = color2; 

               newArray["dualPrint1"] = dualPrint1; 
               newArray["dualPrint2"] = dualPrint2;
               

               if(resultIdx === (vseq-remainChk)){
                   if(remain > 0){
                       newArray["qty"] = remain; 
                       newQty = remain; 
                   }
               }else{
                   if(index===0 && resultIdx === 1){
                       if(remain > 0){
                           newArray["qty"] = remain; 
                           newQty = remain; 
                       }
                   }
               }

               if(listSize === (index+1)){   
                   if(resultIdx === iCnt){
                       if(stck === jCnt){
                           newArray["endpage"] = "T"; 
                       }
                   }
               }

               //LOTNO(3)
               const lotNo = data.TMP13===""?" ":Common.getLOT(data.TMP13); // LOT NO 날짜 가져오기  data.TMP13

               //일련번호(4)
               const prno = (data.TMP0===undefined?"":data.TMP0) + "" + ("0000" + parseInt(vseq, 10)).slice(-4);
                           
               // DataMatrix - code128 바코드 값 만들기
               let barcodeValu = "";

               if(kCkdOem === "CKD"){// 중포장일때
                   // 수량 7 -> 6 으로 변경 셋팅 2022-06-30
                   // 바코드 = 업체코드(4)              + 품번(13)                       + 수량(6)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4) 

                   // 자동차측 요구로 바코드 자릿수 변경 2023-08-21
                   // 바코드 = 오더번호(10)              + 품번(14)                       + 수량(8)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4) 
                   barcodeValu = data.TMP21 +""+ (data.TMP5.slice(0,14)).padEnd(14," ") +""+ ("00000000" + newQty).slice(-8) + "" + ((kNpdt).replace(/-/g, '').slice(-6)) + ""  + prno ;
                   
               }else{//용기일때
                   // 수량 7 -> 6 으로 변경 셋팅 2022-06-30
                   // 바코드 = 업체코드(4)              + 품번(13)                       + 수량(6)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4) 

                   // 자동차측 요구로 바코드 자릿수 변경 2023-08-21
                   // 바코드 = 오더번호(10)              + 품번(14)                       + 수량(8)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4) 
                   barcodeValu = data.TMP22 +""+ (data.TMP5.slice(0,14)).padEnd(14," ") +""+ ("00000000" + newQty).slice(-8) + "" + ((kNpdt).replace(/-/g, '').slice(-6)) + ""  + prno ;

               }

               const dataMatrixBarcode128 = <CustomDataMatrix val={barcodeValu} height={12} bcid={"code128"} scale={1.9} width={320}/>;
               newArray["barcode"] = dataMatrixBarcode128; 

               const dataMatrixBarcode = (this.state.pathNm !== null) && (this.state.pathNm === "ROM4X") ? <CustomDataMatrix val={barcodeValu} height={18} scale={1} /> : "";
               //  const dataMatrixBarcode = <CustomDataMatrix val={barcodeValu} height={18} scale={1} />;
               newArray["barcodeMatrix"] = dataMatrixBarcode; 

               const ptnm = (data.TMP6).slice(0,18);
               newArray["ptnm"] = ptnm; 

               newArray["lotno"] = lotNo;
               newArray["bno"] = barcodeValu;

               // 자기 위치 셋팅 (출력매수)
               const posi = idx%6;                    
               newArray["posi"] = posi;
               newSearched[n] = newArray;
           })
       })

       mainSearchedDivArr.push(...newSearched)

       })
   :"";

   //빈값 배열이 들어간다...왜? > 오래걸리니 일단 빈값은 삭제하고 진행하자..
   let mainSearchedDivArr2 = mainSearchedDivArr.filter(Boolean);

   let cardInfo = 
       <div className="forfor" style={{ display : "flex",flexWrap : "wrap", overflow : "hidden", width : mmToPx(280), marginLeft :mmToPx(9 + parseInt( ( Common.getToken("bplx") ? Common.getToken("bplx") : 0 ),10)) }}>
       {/* Start Point */}
       {Array.from(new Array(kStart-1), (v, i) =>{ 
           return  <div className="start_from_test"  
                           style={{  marginTop :  i == 0 || i == 1 ? mmToPx(7 +  parseInt( ( Common.getToken("bply") ? Common.getToken("bply") : 0 ),10)) : "0"
                                   , height: mmToPx(64.5)
                                   , flexBasis : mmToPx(139.5) }}></div>
                                   }
                       )
       }
       {mainSearchedDivArr2.map((sData, dataIdx) => (
           <div className="start_from" key={"WrapCkdLabelPrint_B_NEW"+dataIdx}
                       style={{ marginTop :   sData.posi == 0 ||sData.posi == 1 ? mmToPx( 7 +  parseInt( ( Common.getToken("bply") ? Common.getToken("bply") : 0 ),10)) : "0"
                               , height : mmToPx(64.5)
                               , flexBasis: mmToPx(139.5) }}>
               <div className="dep_div" style={{ overflow:"visible", zIndex:"200", position : "relative" , height : "100%", width : "100%"/* , opacity : "0" */ }}>
                       { sData.dualPrint1 === "T" ?
                           <div style={{ position:"absolute"
                                       , fontFamily: "HY견고딕" 
                                       , top:parseInt(sData.TMP27,10) + "mm"  
                                       , left:parseInt(sData.TMP26,10) + "mm" 
                                       , color:sData.color1
                                       , fontSize:sData.TMP29+"pt" 
                                       , fontWeight:"bold"
                                       , whiteSpace:"nowrap"
                                       , zIndex:"13"} }> {sData.TMP25} </div>
                       :"" }

                       { sData.dualPrint2 === "T" ?
                           <div style={{ position:"absolute" 
                                       , fontFamily: "HY견고딕" 
                                       , top:parseInt(sData.TMP32,10) + "mm" 
                                       , left:parseInt(sData.TMP31,10) + "mm" 
                                       , color:sData.color2 
                                       , fontSize:sData.TMP34+"pt" 
                                       , fontWeight:"bold" 
                                       , whiteSpace:"nowrap"
                                       , zIndex:"13"}}>{sData.TMP30}</div>
                       : "" }

                       <div className="row2" style={{ height : mmToPx(1)}}>
                           <div className="cell"></div>
                       </div>
                   
                       <div className="row2" style={{height:mmToPx(5)}}>
                           <div className="cell" ></div>
                           <div className="cell" ></div>
                           <div className="cell" ></div>
                           <div className="cell" ></div>
                           <div className="cell" ></div>    
                           <div className="cell" ></div>
                           <div className="cell" ></div>
                           <div className="cell HYheadM12 cell_veri cell_hori" style={{flex : "1 1 27mm", letterSpacing: "0px"}}>{sData.TMP1}</div>{/* 지역 */}         
                           <div className="cell NmheadM10 cell_veri cell_hori" style={{letterSpacing:"0.02px", flex : "1 1 48mm"}}>
                               {sData.TMP0==="MPMC"?"현대파워텍(주)용ⓡ" : sData.TMP4==="MAMC"? "글로비스(주)용ⓡ" : "현대자동차(주)/기아(주)ⓡ"}
                           </div>
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                       </div>

                       <div className="row2" style={{height:mmToPx(5)}}>
                           <div className="cell"></div>
                           <div className="cell"></div>
                           <div className="cell"></div>
                           <div className="cell HYheadM16 cell_veri" style={{flex : "1 1 30mm"}}>{sData.TMP2}</div>{/* 공장 */}
                           <div className="cell" style={{flex : "1 1 14mm"}}></div>    
                           <div className="cell HYheadM13 cell_hori cell_veri" style={{flex : "1 1 27mm"}}>{sData.TMP3}</div>{/* 차종 */}
                           <div className="cell" style={{flex : "1 1 18mm"}}></div>                     
                           <div className="cell HYheadM13 cell_veri cell_hori" style={{flex : "1 1 30mm"}}>{sData.TMP4}</div>{/* 납품장소 */}
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>    
                       </div> 

                       <div className="row2" style={{height:mmToPx(8)}}>
                           <div className="cell"></div>
                           <div className="cell"></div>
                           <div className="cell"></div>
                           <div className="cell HYheadM18" style={{fontSize:(sData.TMP5).trim().length>11?"22pt":"23pt", flex : "1 1 81mm"}}>
                               {sData.TMP5.substr(0, 5) + "-" + sData.TMP5.substr(5)}{/* 품번 */}
                           </div>
                           <div className="cell" style={{flex : "1 1 8mm"}} ></div>
                           <div className="cell HYheadM24 cell_veri cell_hori" style={{flex : "1 1 30mm"}}>{sData.qty}</div>{/* 수량 */}         
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                       </div> 

                       <div className="row2" style={{height:mmToPx(8)}}>
                           <div className="cell"></div>
                           <div className="cell"></div>
                           <div className="cell"></div>
                           <div className="cell HYheadM22 cell_veri" style={{flex : "1 1 104mm", whiteSpace:"nowrap"}}>{sData.ptnm}</div>{/* 품명  paddingTop:"7px" */}         
                           <div className="cell HYheadM28" style={{ color:(sData.TMP19==="좌" || sData.TMP19==="L") ?"red":"blue", zIndex:"10", flexDirection : "row-reverse", alignItems : "center"}}>
                               {sData.TMP19}{/* LH/RH */}
                           </div>
                           <div className="cell" style={{flex : "0", zIndex : "1"}}>
                               {chkMetal==="Y" ?
                                       <div style={{position : "absolute", marginLeft : "-13mm"}}  >
                                           <img src ="/images/heavy_metal_confirm.gif" style={{width:"13mm"}} alt="heavy_metal_confirm" />
                                       </div>
                               :""}
                           </div>
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                       </div>

                       <div className="row2" style={{ height : mmToPx(0.7)}}>
                           <div className="cell"></div>
                       </div>

                       <div className="row2" style={{height:mmToPx(8)}}>
                           <div className="cell HYheadM18 cell_hori" style={{ flex : "1 1 41mm", overflow:"visible" }}>G/W: {sData.TMP35}kg</div>
                           <div className="cell HYheadM18 cell_hori" style={{ flex : "1 1 54mm", overflow:"visible" }}>N/W: {sData.TMP20}kg</div>
                       </div> 

                       <div className="row2" style={{ height : mmToPx(0.6)}}>
                           <div className="cell"></div>
                       </div>

                       <div className="row2" style={{height : mmToPx(8)}}>
                           <div className="cell"></div>
                           <div className="cell" style={{flex : "1 1 25mm"}}></div>
                           <div className="cell HYheadM12" style={{zIndex:"15", textAlign : "center", width : "100%", height : "100%", objectFit : "cover", flex : "1 1 119mm"}}>
                               {sData.barcode}
                           </div>{/* BARCODE DataMatrix - code128 바코드 표출  */}
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                       </div> 

                       <div className="row2" style={{ height : mmToPx(0.7)}}>
                           <div className="cell"></div>
                       </div>

                       <div className="row2" style={{height:mmToPx(5)}}>
                           <div className="cell"></div>
                           <div className="cell"></div>        
                           <div className="cell"></div>
                           <div className="cell"></div>
                           <div className="cell HYheadM11 cell_veri" style={{flex : "1 1 27mm"}}>{chkNpdt&&chkNpdt==="Y"?sData.TMP9:""}</div>{/* 납품일자 chkNpdt sData.TMP9=납기일자(원복) 2022-06-27 */}
                           <div className="cell" style={{flex : "1 1 14mm"}} ></div>
                           <div className="cell" style={{flex : "1 1 3mm"}} ></div>
                           <div className="cell HYheadM11 cell_veri" style={{flex : "1 1 15mm"}}>{sData.TMP12}</div>{/* 업체코드 */}
                           <div className="cell HYheadM11 cell_veri" style={{flex : "1 1 57mm"}}>{sData.TMP10}</div>{/* 업체명 */}        
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                       </div> 
                   
                       <div className="row2" style={{height:mmToPx(5)}}>
                           <div className="cell"></div>
                           <div className="cell HYheadM10 cell_veri aligR" style={{flex : "1 1 18mm"}}>
                               {kCkdOem==="CKD"?"Order":kCkdOem==="CKD2"?"From":""}{/* , paddingTop:"2mm" */}
                           </div>
                           <div className="cell" style={{flex : "1 1 3mm"}} ></div>
                           <div className="cell HYheadM11 cell_veri" style={{flex : "1 1 27mm",}}>{sData.TMP22}</div>{/* 공정NO */}
                           <div className="cell" style={{flex : "1 1 14mm"}} ></div>
                           <div className="cell" style={{flex : "1 1 3mm"}} ></div>
                           <div className="cell HYheadM27 cell_veri" style={{flex : "1 1 45mm"}}></div>{/* 빈값 */}
                           <div className="cell HYheadM12 cell_veri cell_hori" style={{flex : "1 1 27mm"}}>{/*sData.TMP21*/}</div>{/* 발주번호(공백) */}
                           {/* <div className="cell" style={{fontFamily: "HY견고딕", fontSize:"30pt", fontWeight:"bold"}}>{sData.TMP15}</div> sData.TMP15 조립번호 - 이중인쇄 처럼 추가표출이지만 표안에 넣어보기           */}
                           <div className="cell" style={{  position : "absolute"
                                                           , left : mmToPx(66.5)
                                                           , marginTop : "-1.5mm"
                                                           , fontFamily: "HY견고딕"
                                                           , fontSize: !(sData.TMP15) === false && (sData.TMP15).toString().replace(/\s/ig, "").length > 4 ?"24px" : "30pt"
                                                           , fontWeight:"bold"}}>{sData.TMP15}</div>
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                           <div className="cell" style={{ position : "absolute", fontSize:"20pt", overflow:"visible", zIndex:"15", flex : "0", left : "127mm"}}>
                               {sData.barcodeMatrix}
                           </div>{/* 중포장 2D 바코드 */} {/* BARCODE DataMatrix - DataMatrix 추가 표출  */}
                       </div> 
                           
                       <div className="row2" style={{height:mmToPx(5)}}>
                           <div className="cell"></div>
                           <div className="cell HYheadM10 aligR" style={{ fontWeight:"normal", flex : "1 1 18mm"}}>
                               {kCkdOem==="CKD"?"":kCkdOem==="CKD2"?"To":""}
                           </div>
                           <div className="cell" style={{flex : "1 1 3mm"}}></div>
                           <div className="cell HYheadM11 cell_veri" style={{flex : "1 1 27mm"}}>{sData.TMP23}</div>{/* order"*/}                                                  
                           <div className="cell HYheadM12 cell_veri cell_hori" style={{ flex : "1 1 14mm" }}>{chkLtno&&chkLtno==="Y"?sData.lotno:""}</div>{/* getLOT() chkLtno  */}         
                           <div className="cell" style={{flex : "1 1 75mm"}} ></div>
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                       </div> 
                           
                       <div className="row2" style={{height:mmToPx(4)}}>
                           <div className="cell"></div>
                           <div className="cell" style={{flex : "1 1 25mm"}}></div>
                           <div className="cell HYheadM9 cell_veri" style={{fontSize:"6pt",fontWeight:"bold",flex : "1 1 60mm"}}>
                               {sData.bno}
                           </div>{/* bno */}
                           <div className="cell" style={{flex : "1 1 23mm"}}></div>
                           <div className="cell HYheadM14 cell_veri cell_hori" style={{flex : "1 1 30mm"}}>{sData.TMP17}</div>{/* 용기 Mo */}
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                           <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                       </div> 
                   </div>

                   {/*페이지 넘김 처리 */}
                   { sData.posi===5 && sData.endpage==="F" ? <div className="page-break"></div> : "" }

               </div>
           ),"")}
       </div>

       return <PrintStyleB key={"WrapCkdLabelPrint_B_NEW"}>{cardInfo}</PrintStyleB>;
   }
}
export default WrapCkdLabelPrint_B_NEW;
