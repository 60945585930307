import React, { Component } from 'react';
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import * as Common from '@/utils/Common'; 
import * as GridUtil from '@/components/grid/agGrid';
import CommonUtil from '@/utils/CommonUtil'; 
import Loading from "src/components/atoms/CustomLoading2"; 


const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
const Utils = new CommonUtil();



class ChullogMsgPopup extends Component {

    constructor(props)  {
        super(props);
        this.grdchullogMain = React.createRef();

        this.state = {
            //Common
            token:Common.getToken(),
            userId :Common.getToken("id"),
            userName : Common.getToken("name"),
            tVnt1 : Common.getToken("vand"),
            tComp : Common.getToken("comp"),

            dlno : props.data.dlno, // 출하정보전송에서 클릭한 납품서번호
            npdt : props.data.npdt, // 출하정보전송에서 클릭한 납기일자

            //Api (조회)
            searchApi : false,

            loadingFlag : false,
            loading : false
        }
    }

    
    /**
     * WillMount
     */
    async componentWillMount() {

        this.setState({loadingFlag : true}); 

    }

    Loading2 = (gubn) => {
        this.setState({loading : gubn}); 
    }



    /**
     * DidMount
     */
    
    componentDidMount() {        
        //Loading2(true);
        
        document.getElementById("dlno").setAttribute("disabled" , true);   //납품서번호 disabled 처리
        
        // 팝업 띄울때 클릭한 납품서 번호로 조회
        let para = `{"kVnt1":"${this.state.tVnt1}", "kDlno":"${this.state.dlno}","kNpdt":"${this.state.npdt}"}`; 
        let param = encodeURI(para);
        const query = SERVER_URL + "/rom4q/PopupSearch?query=" + param;
        this.setState({ searchApi : query});        
        
        //setLoading(false);
        
    }


    
    componentWillUnmount() {
        
        this.setState({searchApi : true}); 
    }


    /**
     * 공통코드 조회
    */
     onSearchCommon = (para, name) => {
    }


    /**
     * 회사구분 코드 조회
    */
      onSearchComp = async (para) => {
    }



    render() {

        if (this.state.loadingFlag === false) {
            return null
        }
        else {
        
            //State
            const {token, userId, userName, tVnt1, tComp, kWrcVand, kComp,  openAlert, severity, msg,setTypeOpen,dlno,npdt,
                   searchApi,loading } = this.state;

            //Grid Clear Api               
            const onClearApi = async (e) => {
                this.setState({ searchApi : false})
            }


            /**
             * Control 변경 Event
             * @param {*} e 
             */
            const onChange = async (e) => {
            }

            const setLoading = (gubn) => {
                this.setState({loading : gubn}); 
            }

        
            /**
             * Grid Event 
             * @param {*} e 
            */
            const onCellValueChanged = async (e) => {
            }

            const onRowClicked = async (e) => {
            }


            /**
             * 검색조건
            */
            const optionsX = {
                0: [
                    {
                        "formatter": "input", "name": "dlno", "labelName": "납품서번호", "type": "text", "desc": "", "defaultValue" : dlno,
                        "align": "left", 
                        "labelType": "none" 
                        
                    }, 
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                ]
            }

        
        
            /**
             * 조회
             * @param {*} e 
             */
            const onSearch = async (e) => {   
                setLoading(true);
                let para = `{"kVnt1":"${this.state.tVnt1}", "kDlno":"${this.state.dlno}","kNpdt":"${this.state.npdt}"}`; 
                let param = encodeURI(para);
                const query = SERVER_URL + "/rom4q/PopupSearch?query=" + param;
                this.setState({ searchApi : query});     
                setLoading(false);
            }



            /**
             * 신규 Row추가
             * @param {*} e 
             */
            const onAddRow = async (e) => {
            
            }



            /**
             * 입력
             * @param {*} e 
             */
            const onInsert = async (e) => {

            }



            /**
             * 수정 
             * @param {*} e 
             * @returns 
             */
            const onUpdate = async (e) => {

            }



            /**
             * 삭제
             * @param {*} e 
             */
            const onDelete = async (e) => {
                
                
            }



            //deleteBlank
            function deleteBlank(argval){
            }

            //AlertDialogResult
            const AlertDialogResult = async (e) => {

            }

        
            /*
            Grid Column 정의 
            */
            const columnDefs = [
                { headerName: "일자", field: "ltime", sortable: true, filter: true, flex: 1, minWidth: 170, maxWidth: 170, editable: false,  cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },  
                { headerName: "전송 Message", field: "mesg", sortable: true, filter: true, flex: 1, minWidth: 405, maxWidth: 405, editable: false, cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }  
            ];


            return (
                <>  
                    {/* Loading */}
                    <Loading loading={loading} />

                    <button onClick={() => {this.props.onClose()}} style = {{border:"none", float:"right", paddingRight:"0px"}}> <img src="/images/button/close_button.png" alt="close" /></button> 
        
                    {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
                    <Ribbon
                        newTabUnused={true}                         // newTabUnused : newTab 표출 버튼 true : 사용안함 // 선언안할경우 default = newTab 사용
                        onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                        onSearch={onSearch} onSearchName={null}     //조회
                    >
                    <b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>출하전송 이력</b>
                    </Ribbon>
                    
                    {/* ContentXearch Frame : 검색조건 */}
                    <ContentXearch
                        optionsX={optionsX}
                        onChange={onChange}/>

                    <div className={"grid_type01"} style={{ marginTop: "10px"}} > {/* 상단 FRAME */}
                        <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                            <div className="grid_cont_box" style={{ height: "430px", width : "100%" }}>
                                <Grid
                                    ref = {this.grdchullogMain}
                                    gridId = {"CHULLOG"}                  //  (필수) Grid Id 정의
                                    columnDefs = {columnDefs}             //  (필수) 컬럼 정의 
                                    pagination = {true}                  //  쪽수 매기기 여부
                                    paginationCsv = {false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize = {"100"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick = {true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection = {true}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection = {false}        //  
                                    enableCellTextSelection = {true}      // cell selection drag mode
                                    isFirst = {false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    searchApi = {searchApi}               // 조회                                    
                                    onClearApi = {onClearApi}                                                                                                            
                                    useMessage = {false}                 // 메세지 창 설정
                                />
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        
    }

}


export default ChullogMsgPopup;