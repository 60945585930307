/**
 * @Page RNT30
 * @Description [RNT30]수신자료관리 : 기초관리 : body seq 219 코드등록
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import GridRibbon from "@/components/organisms/GridRibbon";
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
 
 
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
 const Utils = new CommonUtil();
 
 function RNT30() {
     const token = Common.getToken();
     const userId = Common.getToken("id");
     const userName = Common.getToken("name");
     const tVnt1 = Common.getToken("vand");
     const tComp = Common.getToken("comp");
     const bigi = Common.getToken("bigi");
     const grdRnt30List = useRef();   //Grid1
     const grdRnt30Detail = useRef();  //Grid2
 
 
     //Data
     const [idata, setData] = useState({
        //조회조건
        kVnt1 : tVnt1,      //T1업체
        kComp: tComp,       //회사구분
        kWrcVand: "****",
        kGubn : "*",    //그룹코드
        kTelno: "",         //연락처
        kCano: "",         //차종

        //코드 데이터
        vnt1List : [],  //T1업체
        compList : [],  //회사
        plntList : [],  //회사구분
        gateList : [],  //선택구분
        canoList : [],  //차종
     }); 
 
 
      
     const [message, setMessage] = React.useState( {
         openAlert : false,      
         severity : "success",   
         msg : ""                
     });
     const { openAlert, severity, msg } = message;
 
 
     //Dialog
     const [dlgMessage, setDlgMessage] = React.useState( {
         openDlg : false,    
         dlgTrans : "",      // Dialog Transaction(I,U,D)
         dlgMsg : ""         
     });
     const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
 
 
     //Grid Search, Insert, Update, Delete Api
     let [searchApi, setSearchApi] = useState(false); 
     let [searchApi2, setSearchApi2] = useState(false); 
     let [insertApi, setInsertApi] = useState(false); 
     let [insertApi2, setInsertApi2] = useState(false); 
     let [updateApi, setUpdateApi] = useState(false); 
     let [updateApi2, setUpdateApi2] = useState(false); 
     let [deleteApi, setDeleteApi] = useState(false); // deleteApi 항목 setting
     let [deleteApi2, setDeleteApi2] = useState(false); // deleteApi 항목 setting
     let [clearGrid2, setClearGrid2] = useState(false); 

     const [panded, setPanded] = React.useState(true);
     const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 50px)"});

     const onClearApi = useCallback(async (e) => {
         setSearchApi(false);
         setDeleteApi(false);
     }, []);

     const onClearApi2 = useCallback(async (e) => {
        setSearchApi2(false);
        setInsertApi2(false);
        setUpdateApi2(false);
        setDeleteApi2(false);
    }, []);
     
 
     /**
      * 공통코드 조회
      */
      const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }
 
 /**
     * 회사구분
     * @param {*} para 
     * @param {*} name 
     */
  const onSearchCommon1 = (para, name) => {
    let commonData = idata;

    const fetchData1 = async (para, name) => {

        let param = encodeURI(para);
        const query = SERVER_URL + "/comcd/search1?query=" + param; 
        await fetch(query, { headers: { Authorization: token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) 
                {
                    if (name === "compList") {
                        //commonData["kType"] = data.data[0].comCode
                        //commonData["kTypeNm"] = data.data[0].text
                    }
                    commonData[name] = data.data
                    setData({...commonData});
                }
            })
    }
    fetchData1(para, name);
}
 
     /**
      *MOUNT 
      */
     useEffect( () => {
         
         //공통코드 조회
         onSearchCommon(`{"pgmid":"WRCCARC","kComp":"${idata.kComp}"}`, "canoList");//차종
         onSearchCommon1(`{"pgmid":"CODE", "vand":"****", "gocd":"COMP","order":"COMP"}`, "compList");//회사

     }, []);
 
 
 
     /**
      * Control 변경 Event
      * @param {*} e 
      */
     const onChange = async (e) => {
         const { name, value } = e.target;
 
         let newSearchs = idata;
         newSearchs[name] = value
 
         setData({ ...newSearchs });
 
         
         if (name === "kComp") {
            onSearchCommon(`{"pgmid":"WRCCARC","kComp":"${idata.kComp}"}`, "canoList");//차종
        }else if(name === "kGubn"){
            idata.kGubn = value;
        }

         //조회 Call    
         //onSearch(); 
     }
 
 
 
     /**
      * Grid Event 
      * @param {*} e 
      */
     const onCellValueChanged = async (e) => {
     }
 
     const onRowClicked = async (e) => {
     }
 
 
 
     /**
      * 검색조건
      */
      const optionsX = {
         0: [
             //회사구분
             {
                 "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                 "codeMode": false,
                 "selectAll": false,
                 "comms": idata.compList,
             }, 
             //차종
             {
                 "formatter": "select", "name": "kCano", "labelName": "차종", "type": "text", "defaultValue": idata.kCano,  
                 "codeMode": false,
                 "selectAll": false,
                 "comms": idata.canoList,
             }, 
             //선택구분
             {
                "formatter": "select", "name": "kGubn", "labelName": "선택구분", "type": "text",  
                "codeMode": false,
                "selectAll": "",
                "codes": [{ "comCode" : "*",  "text": "전체" }],
                "comms": idata.kGubnList,
                "items": [{ "comCode": "Y", "text": "Y" }, { "comCode": "N", "text": "N" }] // Case 3 - items :: Hard Coding List
            },  
             {"formatter": "blank", "align": "left" },
             {"formatter": "blank", "align": "left" },
             {"formatter": "blank", "align": "left" },
         ]
     }
 
 
 
     /**
      * 조회 
      * @param {*} e 
      * @returns 
      */
     const onSearch = async (e) => {
        if(idata.kCano === ''){
            idata.kCano=idata.canoList[0].comCode;
        }
        let para = `{"kComp":"${idata.kComp}","kCarc":"${idata.kCano}","kGubn":"${idata.kGubn}","kVnt1":"${idata.kVnt1}"}`;
        let param = encodeURI(para);
 
        const query = SERVER_URL + "/rnt30/search?query=" + param;
        
        setSearchApi(query); // Search API

     }
 
 
 
     /**
      * 신규 Row추가
      * @param {*} e 
      */
     const onAddRow = async (e) => {
        let newItems = {comp:idata.kComp,vand:"****",car1:idata.kCano};
 
        grdRnt30Detail.current.addRow(newItems, 0);
     }
 
 
     
     /**
      * 입력
      * @param {*} e 
      */
     const onInsert2 = async (e) => {
 
        let selectedData = grdRnt30Detail.current.getSelectedData();
         
        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "입력할 데이터를 선택해 주세요."});
            return false;
        }
 
        const query = SERVER_URL + "/rnt30/insert";
        setInsertApi2(query); // Insert API
     }
 
 
 
     /**
      * 수정 
      * @param {*} e 
      * @returns 
      */
     const onUpdate2 = async (e) => {
 
        //input check
        let selectedData = grdRnt30Detail.current.getSelectedData();
         
           // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "수정할 데이터를 선택해 주세요."});
            return false;
        }
         const query = SERVER_URL + "/rnt30/update";
         setUpdateApi2(query); // Update API
     }
 
 
 
     /**
      * 선택/취소   삭제
      * @param {*} e 
      */
     const onDelete = async (e) => {
        let selectedData = grdRnt30List.current.getSelectedData();
        
        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "삭제할 데이터를 선택해 주세요."});
            return false;
        }

        const query = SERVER_URL + "/rnt30/selcancel";
        setDeleteApi(query); 

     }

     const onDelete2 = async (e) => {
         //input check
        let selectedData = grdRnt30Detail.current.getSelectedData();
         
        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "삭제할 데이터를 선택해 주세요."});
            return false;
        }
          
        const query = SERVER_URL + "/rnt30/delete";
        setDeleteApi2(query); 
    }
 
 
 
     // input check
     const inputCheck = (gbn) => { 
 
         let inputFlag = true;
         //select nodes
         let selectedData = grdRnt30List.current.getSelectedNodes();
 
         // 선택 값 체크
         if (selectedData.length === 0) {
             //Alert 창 OPEN
             setMessage({ openAlert : true, 
                          severity : "info",
                          msg : "선택한 데이터가 없습니다."});
             return false;
         }
 
         return inputFlag;
     }

     //그리드 Row Click
     const grdRnt30List_onRowClicked = async (e) => {

        let comp = e.data.comp;
        let car1 = e.data.car1;
        let optn = e.data.optn;

        onSearch2(comp,car1,optn);
    }
 
    const onSearch2 = async (comp,car1,optn) => {
        let selectedData = grdRnt30List.current.getSelectedData();
        let aa = "";
        let para = "";
        if(selectedData.length === 0){
            para = `{"comp":"${idata.kComp}","vand":"****","car1":"${idata.kCano}","optn":"${optn}"}`;
        }else{
            aa = selectedData[0].optn
            para = `{"comp":"${idata.kComp}","vand":"****","car1":"${idata.kCano}","optn":"${aa}"}`;
        }
        let param = encodeURI(para);
        
        const query = SERVER_URL + "/rnt30/search2?query=" + param;
        setSearchApi2(query); // Search API
     }


 
     //Transaction AlertDialogResult
     const AlertDialogResult = async (e) => {
 
         //AlertDialog 초기화
         setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
 
         //DialogResult
         if (e.result == "N") return;
 
         //Transaction 구분
         switch (e.Trans) 
         {
             //등록
             case "I" :
                 break;
             //수정
             case "U" :
                 break;
             //삭제
             case "D" :
                 const query = SERVER_URL + "/rnt30/delete";
                 setDeleteApi(query); // Update API
                 break;
         }
     }

     const grdRnt30Detail_onCellValueChanged = async (e) => {

        let data = e.data;
        if (data.comp === " " || data.comp === "") {
            e.node.setSelected(false);
        }
    }
     
     
 
 
     /**
      *Grid Column 정의 
      */
     const columnDefs = [
        { headerName: "id(h)", field: "id", sortable: true, filter: true, flex: 1, minWidth: 0, cellClass: ["nocheckbox", "alignC"], hide: true },
        { headerName: "comp(h)", field: "comp", sortable: true, filter: true, flex: 1, minWidth: 0, cellClass: ["nocheckbox", "alignC"], hide: true }, 
        { headerName: "car1(h)", field: "car1", sortable: true, filter: true, flex: 1, minWidth: 0, cellClass: ["nocheckbox", "alignC"], hide: true }, 
        //{ headerName: "", field: "", sortable: true, filter: true, flex: 1, minWidth: 0, cellClass: ["checkbox", "alignL"], hide: false }, 
        { headerName: "Option", field: "optn", sortable: true, filter: true, flex: 1, minWidth: 50, cellClass: ["nocheckbox", "alignL"], hide: false }, 
        { headerName: "Option명", field: "otnm", sortable: true, filter: true, flex: 1, minWidth: 200, cellClass: ["nocheckbox", "alignL"], hide: false }, 
        { headerName: "선택구분", field: "stgb", sortable: true, filter: true, flex: 1, minWidth: 140, cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },  //valueFormatter : Utils.numberFormatter
     ];
     const columnDefs1 = [
        { headerName: "comp", field: "comp", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true},
        { headerName: "carc", field: "car1", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true},
        { headerName: "vand", field: "vand", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true },
        { headerName: "Option", field: "optn", sortable: true, filter: true, flex: 1, minWidth: 50, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
        { headerName: "Option구분", field: "otgb", sortable: true, filter: true, flex: 1, minWidth: 140, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "Option구분명", field: "ognm", sortable: true, filter: true, flex: 1, minWidth: 240, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
    ];

    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 55px  - 50px)"
              });
        }
    }
     
     return(
         <>
             {/* Dialog FRAME */}
             <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />
 
             {/* Alert FRAME */}
             <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
 
                     
             {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
             <Ribbon
                 
                 //onAddRow={onAddRow} onAddRowName={"신규"} onAddRowVisible = {tVnt1 === "M000"}  //Row 추가
                 //onExcel={onInsert} onExcelName={null} //Excel
                 //onPrint={onInsert} onPrintName={null} //print
             >
                 {"수신자료관리 > 기초관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>Body Seq 219 코드</b>
             </Ribbon>
 
 
             {/* ContentXearch Frame : 검색조건 */}
             <ContentXearch
                 optionsX={optionsX}
                 onChange={onChange} 
                 onClick={onClick}/>
 
 
             {/* Content FRAME (Data / Grid Frame) START -------------- */}
             <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:55px */}
             <div className="grid_type02">{/* 하단 FRAME */}
                    <div className="grid_wrap02"> {/* 하단 wrap 좌측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <GridRibbon 
                                onSearch={onSearch} onSearchName={null}   //조회
                                onDelete={onDelete} onDeleteName={'선택/취소'}   //삭제
                            >
                            </GridRibbon>
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* Grid1 */}
                                <Grid
                                    ref={grdRnt30List}
                                    gridId={"RNT30"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={false}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    onClearApi={onClearApi}            // 초기화
                                    searchApi={searchApi}              // 조회
                                    deleteApi={deleteApi}              // 삭제
                                    deleteRefresh={()=>{onSearch();}}    // 삭제이후 조회
                                    onRowClickEvent={grdRnt30List_onRowClicked}     
                                    //onCellValueChangedEvent={grdRnt30List_onCellValueChanged}
                                    // onCellValueChangedEvent={onCellValueChanged}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid_wrap03"> {/* 하단 wrap 우측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <GridRibbon 
                                onAddRow={onAddRow} onAddRowName={"신규"} onAddRowVisible = {tVnt1 === "M000"}  //Row 추가
                                onInsert={onInsert2} onInsertName={null}  onInsertVisible = {tVnt1 === "M000"}    //등록
                                onUpdate={onUpdate2} onUpdateName={null}  onUpdateVisible = {tVnt1 === "M000"}  //수정
                                onDelete={onDelete2} onDeleteName={null}  onDeleteVisible = {tVnt1 === "M000"}  //삭제
                            >
                            </GridRibbon>
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* Grid2 */}
                                <Grid
                                    ref={grdRnt30Detail}
                                    gridId={"RNT302"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs1}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={false}   //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}                 // No 컴럼 Display
                                    searchApi={searchApi2}              // 조회
                                    onClearApi={onClearApi2}            // 초기화
                                    insertApi={insertApi2}              // 등록
                                    updateApi={updateApi2}              // 수정
                                    deleteApi={deleteApi2}              // 삭제
                                    insertRefresh={()=>{onSearch2();}}  // 등록이후 
                                    updateRefresh={()=>{onSearch2();}}  // 수정이후 
                                    deleteRefresh={()=>{onSearch2();}}   // 삭제이후
                                    onCellValueChangedEvent={grdRnt30Detail_onCellValueChanged}
                                    clearGrid={clearGrid2}
                                    onClearGridFlag={setClearGrid2}                                     
                                    //onClearApi={()=> {setSearchApi2(false);}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
             </Content>
         </>
     )
 }
 export default RNT30;