import React from "react";

import "../../css/common.css";
import "../../css/style.css";
import "../../css/main_style.css";
import "../../css/maindash.css";
import "../../css/table.css";

import Breadcrumb from "../../components/organisms/Breadcrumb";
import Buttonwrapper from "../../components/organisms/Buttonwrapper";

const navi = require("../../config/navigation.json");
const navArray = ["납입지시", "수신자료관리", "시스템관리"]; // 대메뉴 이름 Array 셋팅

function Ribbon({
  children, newTabUnused,
  onInfoMsg, onInfoManual,
  onSearch,
  onSearchName,
  onSearchVisible,
  onSearch2,
  onSearchName2,
  onSearch2Visible,
  onAddRow,
  onAddRowName,
  onAddRowVisible,
  onInsert,
  onInsertName,
  onInsertVisible,
  onUpdate,
  onUpdateName,
  onUpdateVisible,
  onDelete,
  onDeleteName,
  onDeleteVisible,
  onExcel,
  onExcelName,
  onExcelVisible,
  onUpload,
  onUploadName,
  onUploadVisible,
  onPrint,
  onPrintName,
  onPrintVisible,
  onFunction,
  onFunctionName,
  onFunctionType,
  onFunctionVisible,
  onFunction2,
  onFunctionName2,
  onFunctionType2,
  onFunction2Visible
}) {

  const currPath = window.location.pathname; // page path

  let pathArray = currPath.split("/"); // page path split
  let gubn = currPath.substring(1, 2); // 대메뉴 id
  let middlePath = "/" + pathArray[1].substring(0); // 중메뉴 id

  // 대메뉴 화면 리스트 추출
  let middleArray = navi[gubn];

  // 중메뉴 화면 리스트 추출
  let currArray = middleArray
    ? middleArray
      .filter((middle) => middle.itemId === middlePath)
      .map((filterMiddle) => {
        return filterMiddle;
      })
    : "";

  let middleName = ""; // 중메뉴 명
  middleName = currArray[0] && currArray[0].title ? currArray[0].title : "";

  // 소메뉴 화면 리스트 추출
  let subArray =
    currArray[0] && currArray[0].subNav
      ? currArray[0].subNav
        .filter((sub) => sub.itemId === currPath)
        .map((filterSub) => {
          return filterSub;
        })
      : "";
  let subName = ""; // 소메뉴 화면명
  subName = subArray[0] && subArray[0].title ? subArray[0].title : "";

  let navGubnName = "";

  if (gubn === "A") {
    navGubnName = navArray[0];
  } else if (gubn === "B") {
    navGubnName = navArray[1];
  } else if (gubn === "C") {
    navGubnName = navArray[2];
  } else if (gubn === "D") {
    subName = navArray[3];
  }else if (gubn === "M") {
    subName = "[MAIN] 메인페이지";
  } else {
    navGubnName = "";
  }

  return (
    <>
      <div id="Ribbon" className={"tit_section"}>
        <div className={"container"}>
          <div className={"position_box"}>
            <Breadcrumb onInfoMsg={onInfoMsg} newTabUnused={newTabUnused} onInfoManual={onInfoManual}>
              {children ? children : ""}
              {!!!children && navGubnName ? navGubnName : ""} {!!!children && middleName ? " > " + middleName + " > " : ""} <b style={{ fontFamily: "'PretendardMedium', 'NanumGothic', sans-serif", color: "#cd5c5c" }}>{!!!children && subName ? subName : ""}</b>
            </Breadcrumb>

            <Buttonwrapper
              onSearch={onSearch}
              onSearchName={onSearchName}
              onSearchVisible = {onSearchVisible}
              onAddRow={onAddRow}
              onAddRowName={onAddRowName}
              onAddRowVisible={onAddRowVisible}
              onSearch2={onSearch2}
              onSearchName2={onSearchName2}
              onSearch2Visible = {onSearch2Visible}
              onInsert={onInsert}
              onInsertName={onInsertName}
              onInsertVisible={onInsertVisible}
              onUpdate={onUpdate}
              onUpdateName={onUpdateName}
              onUpdateVisible = {onUpdateVisible}
              onDelete={onDelete}
              onDeleteName={onDeleteName}
              onDeleteVisible={onDeleteVisible}
              onExcel={onExcel}
              onExcelName={onExcelName}
              onExcelVisible={onExcelVisible}
              onPrint={onPrint}
              onPrintName={onPrintName}
              onPrintVisible={onPrintVisible}
              onUpload={onUpload}
              onUploadName={onUploadName}
              onUploadVisible={onUploadVisible}
              onFunction={onFunction}
              onFunctionName={onFunctionName}
              onFunctionType={onFunctionType}
              onFunctionVisible={onFunctionVisible}
              onFunction2={onFunction2}
              onFunctionName2={onFunctionName2}
              onFunctionType2={onFunctionType2}
              onFunction2Visible={onFunction2Visible}
            >
            </Buttonwrapper>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ribbon;
