

import { useEffect, useRef } from 'react';

function useThrottle(callback, time) {
  const isWaiting = useRef(false); // 렌더링과 관련없는 값은 useRef로 저장

  return (...args) => {
    if (isWaiting.current) return;

    callback.apply(this, args);
    isWaiting.current = true;

    setTimeout(() => {
      isWaiting.current = false;
    }, time);
  };
}

export default useThrottle;