/**
 * @Page WrapStartCardPrint
 * @Description  [ROM81/ROM82]납입지시 - 시작차 출하관리 - 납품서발행/재인쇄 
 **/
import React from "react";

// import * as Common from "../../utils/Common"; // Common.js
import * as Common from "@/utils/Common"; // Common.js

import styled from 'styled-components';
import TableEditing from "@ckeditor/ckeditor5-table/src/tableediting";


const underDiv = { width: "190mm", marginTop: "4mm", borderWidth: "0px", borderStyle: "solid", marginBottom: "8mm" };
const underTable = { border: "0px", borderCollapse: "collapse", borderStyle: "none", padding: "0px", width: "100%" };

class WrapStartCardPrint extends React.Component {

    // constructor 생성자
    constructor(props) {
            super(props);
            this.state = {
                splx: "0",
                sply: "0"
        };
    }

    // componentDidMount 화면 최초 실행 시,  token 에서 x, y 좌표값 가져오기 
    componentDidMount() {
        const info_nplx = Common.getToken("nplx")===null?0: 20 + ( parseInt(Common.getToken("splx"),10) * 2 ) ;    // 로그인한 사용자의 시작차식별표 의 x좌표값 받아오기(left)
        const info_nply = Common.getToken("nply")===null?0: 19 + ( parseInt(Common.getToken("sply"),10) * 2 ) ;    // 로그인한 사용자의 시작차식별표 의 y좌표값 받아오기 (top)
        this.setState({nplx:info_nplx});
        this.setState({nply:info_nply});        
      }

    render() {

        // Print Style CSS Setting
        const PrintStyleStartCard = styled.div`
        @media print {

            @page{size: A4 landscape; margin-top:${this.state.nply}mm; margin-left:${this.state.nplx}mm; margin-right:16mm;margin-bottom:4mm; } 
            
            body {
                margin: 0px;
                padding: 0px;
                font-family: "굴림체";
                font-size:10pt;
                font-weight:100;
            }
            div {
                margin: 0px;
                padding: 0px;
                box-sizing:border-box;
            }
            table {
                border-collapse:collapse;
                border-style:none;
                margin: 0px;
                padding: 0px;
                box-sizing:border-box;
                table-layut:fixed;
            }
            tr{
                width:100%;
                margin: 0px;
                padding: 0px;
            }
            td{
                margin: 0px;
                padding: 0px;
                background-color: white;
                font-family: "둥근고딕,굴림체";
                border:0px solid green;
                box-sizing:border-box;
            }
            .headTable{
                border-collapse: collapse;
                 table-layut:fixed;
            }
            .headTitle{
                font-family:HY견고딕;
                font-size:22pt;
                font-weight:bold; 
                text-align:center;
            }
            .headTdL{
                font-family:굴림체; 
                font-size: 11pt;
                text-align:left;
                letter-spacing:-1px;
            }
            .headTdR{
                font-family:굴림체; 
                font-size: 14pt;
                text-align:right;
            }
            .signA{
                 border:1px solid black;
                 text-align:center;
                 
            }
            .signB{
                border:1px solid black;
                width:100%;
            }
            
            .dataTdC{
                text-align: center;
                font-size:10pt; 
                font-weight:300;
                font-family:"굴림체";
                vertical-align:bottom;
                letter-spacing:-1px;
            }
            .dataTdL{
                text-align: left;
                font-size:10pt; 
                font-weight:300;
                font-family:"굴림체";
                vertical-align:bottom;
            }
            .dataTdR{
                text-align: right; 
                font-size:10pt;
                font-family:"굴림체";
                vertical-align:bottom;
                letter-spacing:-1px;
            }
            .odnoTd{
                text-align:center;
                font-size:10pt;
                color:#666;
                font-family:"굴림체";
                vertical-align:top;
            }
            .BarcodeTd{
                font-family:"3of9Barcode";
                font-size:33pt;
                text-align:center;
                border:0px;
            }
            .JYNOTd{
                   white-sapce:pre;
                   font-size:5pt;
                   border-bottom:1px solid black;
            }
            .TdCenter{
                text-align:center;
                font-weight:100;
                font-size:11pt;
            }

            div.pagebreak {
                break-after: always !important;
                page-break-after: always !important;
                page-break-inside: avoid !important;
            }
            table.bodyTitle{
                border-collapse: collapse;
                font-family:굴림체;
                width:99%;
                margin-left:5px;
            }
            table.bodyTitle tr{
                height:21px;
            }
            table.bodyTitle td{
                background-color: white;	
                border:1px solid black;
            }
            table.bodyData{
                width:100%;
                border-collapse: collapse;
                border-bottom:1px solid black;
                table-layout:fiexed;
            }
            
            table.bodyData tr{
                height:21px;
            }
        }


        @media screen {
            body {
                margin: 0px;
                padding: 0px;
                font-family: "굴림체";
                font-size:10pt;
                font-weight:100;
            }
            div {
                margin: 0px;
                padding: 0px;
                box-sizing:border-box;
            }
            table {
                border-collapse:collapse;
                border-style:none;
                margin: 0px;
                padding: 0px;
                box-sizing:border-box;
                table-layut:fixed;
            }
            tr{
                width:100%;
                margin: 0px;
                padding: 0px;
            }
            td{
                margin: 0px;
                padding: 0px;
                background-color: white;
                font-family: "둥근고딕,굴림체";
                border:0px solid green;
                box-sizing:border-box;
            }
            .headTable{
                border-collapse: collapse;
                 table-layut:fixed;
            }
            .headTitle{
                font-family:HY견고딕;
                font-size:22pt;
                font-weight:bold; 
                text-align:center;
            }
            .headTdL{
                font-family:굴림체; 
                font-size: 11pt;
                text-align:left;
                letter-spacing:-1px;
            }
            .headTdR{
                font-family:굴림체; 
                font-size: 14pt;
                text-align:right;
            }
            .signA{
                 border:1px solid black;
                 text-align:center;
                 
            }
            .signB{
                border:1px solid black;
                width:100%;
            }
            
            .dataTdC{
                text-align: center;
                font-size:10pt; 
                font-weight:300;
                font-family:"굴림체";
                vertical-align:bottom;
                letter-spacing:-1px;
            }
            .dataTdL{
                text-align: left;
                font-size:10pt; 
                font-weight:300;
                font-family:"굴림체";
                vertical-align:bottom;
            }
            .dataTdR{
                text-align: right; 
                font-size:10pt;
                font-family:"굴림체";
                vertical-align:bottom;
                letter-spacing:-1px;
            }
            .odnoTd{
                text-align:center;
                font-size:10pt;
                color:#666;
                font-family:"굴림체";
                vertical-align:top;
            }
            .BarcodeTd{
                font-family:"3of9Barcode";
                font-size:30pt;
                text-align:center;
            }
            .JYNOTd{
                white-sapce:pre;
                font-size:5pt;
                border-bottom:1px solid black;
            }
            .TdCenter{
                font-size:11pt;
                text-align:center;
                font-weight:100;
            }

            div.pagebreak {
                break-after: always !important;
                page-break-after: always !important;
                page-break-inside: avoid !important;
            }
            table.bodyTitle{
                border-collapse: collapse;
                font-family:굴림체;
                width:100%;
                margin-left:5px;
            }
            table.bodyTitle tr{
                height:21px;
            }
            table.bodyTitle td{
                background-color: white;	
                border:1px solid black;
            }
            table.bodyData{
                width:100%;
                border-collapse: collapse;
                border-bottom:1px solid black;
                table-layout:fiexed;
            }
            
            table.bodyData tr{
                height:21px;
            }

        }
        `;
        
        



        const { printData } = this.props;

        const cardInfo = printData
        ? printData.map((data, index) => {
            const BarcodeList = data.detail.map((item, index) => {
                const blankTdArray = [];
                if (index === data.detail.length - 1) {
                    let blankTdCount = 8 - data.detail.length;
                    const value = "*"+item.ODNO+item.WRCODSQ+item.WRCNPQT+"*"
                        blankTdArray.push(
                            <>
                                <td  className="BarCodeTD" style={{border:"0px",textAlign:"right",fontSize:"20pt"}}>{value}</td> 
                            </>
                        );
                }
                return (
                    <>
                        
                        {blankTdArray}
                    </>
                );
            });
            const seqList = data.detail.map((item) => {
                
                return (
                    <>
                        <td >{item.DSEQ}</td>
                        <td style={{ width: "16mm" }}></td>
                    </>
                );
            });
            const simpleArray = [1, 2];
            const chek = data.head.chek;

            return (
                <>
                    {simpleArray.map((n, simpleIndex) => (
                        <div key={data.head.dlno + simpleIndex} >{/*className="wrapDiv"*/}
                            <div style={{ width: "100%", height: "21mm" }}>
                                <tabel>
                                    <col width="3%"/><col width="45%"/><col width="1%"/><col width="30%"/>
                                    <tbody>
                                        <tr>
                                            <td style={{paddingLeft:"1px"}}>
                                                <div style={{ float: "left", width: "60mm", textAlign: "left" ,marginTop: "2px",marginLeft: "4px"}}>
                                                    <div className="headTdL">공 급 자 :{data.head.vnt1} {data.head.uname}</div>
                                                    <div className="headTdL">납품일자 :{data.head.prif}</div>
                                                    <div className="headTdL">발 주 자 :현대/기아자동차</div>
                                                    <div className="headTdL">하 역 장 :{data.head.plnt} {data.head.plnm}</div>
                                                </div>
                                            </td>
                                            <td style={{verticalAlign:"top",paddingTop:"10px"}}>
                                                <div style={{textAlign:"left"}}>
                                                    <div className="headTitle" style={{ float: "left", fontWeight:"bold"}}>납 입 카 드 ({n === 1 ? "갑" : "을"})</div>
                                                    <div style={{ float: "left",position: "relative",bottom: "-18px",fontSize:"7pt"}}>ⓡ</div>
                                                </div>
                                            </td>
                                            <td></td>
                                            <td style={{verticalAlign:"top",width: "200px",paddingTop:"3px"}}>
                                                <table style={{border:"1px solid red"}}>
                                                <col width="10%"/><col width="40%"/><col width="10%"/><col width="40%"/>
                                                <tbody>
                                                <tr style={{height:"72px"}}>
                                                    <td className="signA" style={{width:"19px",verticalAlign:"middle"}}>
                                                        담<br></br>당 
                                                    </td>
                                                    <td  className="signB" style={{width:"49px"}}></td>
                                                    <td className="signA" style={{width:"19px",verticalAlign:"middle"}}>확<br></br>인</td>
                                                    <td className="signB" style={{width:"49px"}}></td>
                                                </tr>
                                                </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </tabel>
                            </div>
                            
                            <table className="bodyTitle">
                                <col width="3%"/>
                                <col width="42%"/>
                                <col width="25%"/>
                                <col width="15%"/>
                                <col width="14%"/>
                                <col width="6%"/>
                                <col width="9%"/>
                                <col width="6%"/>
                                <tbody>
                                    <tr style={{ height: "30px"}}>
                                        <td className="TdCenter"style={{ borderBottomStyle: "none",verticalAlign:"bottom" }}>순</td>
                                        <td className="TdCenter">품&nbsp;&nbsp;번</td>
                                        <td className="TdCenter" >프로젝트명</td>
                                        <td className="TdCenter" >지시일자</td>
                                        <td className="TdCenter"  style={{letterSpacing:"1px" }}>발주번호</td>
                                        <td className="TdCenter"  style={{ borderBottomStyle: "none",verticalAlign:"bottom" }}>
                                            {n === 1 ? "기" : "분납"}
                                        </td>
                                        <td className="TdCenter"  rowSpan="2"style={{ lineHeight:"17px"}}>금번<br></br>납품수<br></br>량</td>
                                        <td className="TdCenter"  style={{ borderBottomStyle: "none",verticalAlign:"bottom" }}>잔여</td>
                                    </tr>
                                    <tr style={{ height: "28px"}}>
                                        <td className="TdCenter"  style={{ borderTopStyle: "none",verticalAlign:"top" }}>위</td>
                                        <td className="TdCenter" >품&nbsp;&nbsp;명</td>
                                        <td className="TdCenter"  style={{ borderTopStyle: "none" }}>차종&nbsp;&nbsp;&nbsp;설명</td>
                                        <td className="TdCenter"  style={{ borderTopStyle: "none" }}>담&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;당</td>
                                        <td className="TdCenter"  style={{ borderTopStyle: "none",letterSpacing:"1px",verticalAlign:"top" }}>
                                        {n === 1 ? "총 발주수량" : "기 납품 수량"}
                                            </td>
                                        <td className="TdCenter" style={{  borderTopStyle: "none"}}>
                                        {n === 1 ? "납입" : "SEQ"}
                                            </td>
                                        <td className="TdCenter" style={{ borderTopStyle: "none",borderRight:"1px solid black",verticalAlign:"top"}}>수량</td>
                                    </tr>

                                    {data.detail.map((item, dindex) => {
                                        
                                        return (
                                            <>
                                            <tr>
                                                <td rowspan="3" className="dataTdC" style={{border:"none",verticalAlign:"middle"}}>{item.DSEQ}{/*순위 */}</td>
                                                <td className="dataTdL" style={{border:"none"}}>{item.PTNO1}</td>{/*품번*/}
                                                <td className="dataTdL" style={{border:"none",paddingLeft:"14px"}}>{item.PROJ}</td>{/*프로젝트명*/}
                                                <td className="dataTdL" style={{border:"none",position:"relative",left:"-10px",background:"none"}}>{item.ORDT}</td>{/*지시일자*/}
                                                <td className="dataTdL" style={{border:"none",fontWeight:"bold",fontFamily:"굴림체",position:"relative",left:"-10px",background:"none",letterSpacing:"-2px"}}>{item.ODNO}</td>{/*발주번호*/}
                                                {n===1?
                                                 <td rowspan="2" className="dataTdR" style={{border:"none",paddingRight:"8px",verticalAlign:"middle"}}>{item.PRQT}</td>//기납입(갑)
                                                :<td rowspan="2" className="dataTdR" style={{border:"none",paddingRight:"8px",verticalAlign:"middle"}}>{item.ODSQ}</td>//분납seq(을)
                                                }
                                                <td rowspan="2" className="dataTdR" style={{fontSize:"16",border:"none",paddingRight:"8px",verticalAlign:"middle"}}>{item.NPQT}</td>{/*금번납품수량 */}
                                                <td rowspan="2" className="dataTdR" style={{border:"none",paddingRight:"8px",verticalAlign:"middle"}}>{item.JAQT}</td>{/*잔여수량 */}
                                            </tr>
                                            <tr>
                                                <td className="dataTdL" style={{border:"none"}}>{item.PTNM}</td>{/*품명 */}
                                                <td className="dataTdC" style={{border:"none"}}>{item.CDSC}</td>{/*차종설명 */}
                                                <td className="dataTdL" style={{border:"none"}}>{item.CRDX}</td>{/*담당 */}
                                                {n===1?
                                                <td className="dataTdR" style={{border:"none",paddingRight:"8px"}}>{item.ODQT}</td>//총발주수량(갑)
                                                :<td className="dataTdR" style={{border:"none",paddingRight:"8px"}}>{item.PRQT}</td>//기납품수량(을)
                                                }
                                            </tr>
                                            <tr style={{borderBottom:"1px solid black"}}>
                                                <td colspan = "2" className="odnoTd" style={{border:"none",letterSpacing:"-1px"}}>
                                                    *{item.ODNO}{item.WRCODSQ}{item.WRCNPQT}*</td>
                                                <td colspan="5" className="BarCodeTD" style={{border:"none",fontFamily:"3of9Barcode",fontSize:"30pt"}}>{BarcodeList}</td> 
                                            </tr>
                                            <tr style={{height:"82px"}}>
                                                <td className="dataTdC" style={{writingMode:"tb-rl",verticalAlign:"middle",borderLeft:"none",borderRight:"none",letterSpacing:"-5px"}}>적용호차</td>
                                                <td colspan="7" className="JYNOTd"  style={{textAlign:"left",borderLeft:"none",borderRight:"none",fontSize:"5pt"}}>{item.JYNO}</td>
                                            </tr>
                                        {n===1?
                                            <tr style={{height:"80px",border:"none"}}>
                                                <td style={{height:"80px",border:"none"}}>
                                                  &nbsp;
                                                </td>
                                            </tr>
                                        :""}
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ))}
                    {index === printData.length - 1 ? "" : <div className={"pagebreak"}></div>}
                </>
            );
        })
        :"";


        return <PrintStyleStartCard key={"WrapStartCardPrint"}>{cardInfo}</PrintStyleStartCard>;

    }
}
export default WrapStartCardPrint;
