/**
 * @Page ROM3K
 * @Description [ROM3K]납입지시 - ERP_CKD출하관리 - 발행취소/재인쇄 (E_CKD)
 **/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from '@/components/molecules/CustomAlert';
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common';
import CommonUtil from '@/utils/CommonUtil';
import ReactToPrint from 'react-to-print';

import CkdCardPrintQR from '@/components/molecules/CkdCardPrintQR';

const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER;
const CODES = require('@/config/commonCode.json');
const Utils = new CommonUtil();

const pageStyle = `
 @media all {
     .pagebreak {
         display: none;
     }
 }

 @media print {
     html, body {
         height: initial !important;
         overflow: initial !important;
         -webkit-print-color-adjust: exact;
     }
 }

@media print {
     .pagebreak {
         margin-top:0rem;
         display: block;
         page-break-after: always;
     }
}

@page {
    size: A4 portrait !important;    
    margin:7mm 10mm;
 }
body, div, table, td{
     font-family:둥근고딕,굴림체,Gulim ;
}

`;

function ROM3K() {
	const token = Common.getToken();
	const userId = Common.getToken('id');
	const tVnt1 = Common.getToken('vand');
	const tComp = Common.getToken('comp');
	const grdRom3kMain = useRef();   // Grid Main
	const grdRom3kDetail = useRef(); // Grid Detail
	const printRefQR = useRef();
	const printBtnRefQR = useRef();

	const ckVnt1 =
		Common.getCookie('ck_vnt1' + userId) === null || Common.getCookie('ck_vnt1' + userId) === '' || Common.getCookie('ck_vnt1' + userId) === 'undefined'
			? tVnt1
			: Common.getCookie('ck_vnt1' + userId);

	const [idata, setData] = useState({
		kVnt1: ckVnt1, // T1업체(쿠키에서 값 가져오기)
		kComp: tComp,  // 회사구분
		kNpdt: Common.getToday(),
		kPlnt: '',     // 공장
		kDlno: '',     // 납품서번호
		kGubn: '2',    // 조회구분(발행취소/재인쇄) :: Default 값 셋팅
		vnt1List: [],  // T1업체
		plntList: [],  // 공장
	});

	const [message, setMessage] = React.useState({
		openAlert: false,
		severity: 'success',
		msg: '',
	});
	const { openAlert, severity, msg } = message;

	const [panded, setPanded] = React.useState(true);
	const [style, setStyle] = useState({ height: 'calc(100vh - 60px - 50px - 100px  - 50px)' });

	//Dialog
	const [dlgMessage, setDlgMessage] = React.useState({
		openDlg: false,
		dlgTrans: '',
		dlgMsg: '',
	});
	const { openDlg, dlgTrans, dlgMsg } = dlgMessage;

	//Grid Search, Insert, Update, Delete Api
	let [searchApi, setSearchApi] = useState(false);
	let [deleteApi, setDeleteApi] = useState(false);
	let [searchApi2, setSearchApi2] = useState(false);
	let [printdata, setPrintData] = useState([]);
	let [pos, setPos] = useState([]);
	let [clearGrid2, setClearGrid2] = useState(false);

	const onClearApi = useCallback(async (e) => {
		setSearchApi(false);
		setDeleteApi(false);
	}, []);

	const onClearApi2 = useCallback(async (e) => {
		setSearchApi2(false);
	}, []);

	/**
	 * 공통코드 조회 (조회조건)
	 */
	const onSearchCommon = (para, name) => {
		let commonData = idata;

		const fetchData = async (para, name) => {
			let param = encodeURI(para);
			const query = SERVER_URL + '/comcd/search?query=' + param;
			await fetch(query, { headers: { Authorization: token } })
				.then((resp) => resp.json())
				.then((data) => {
					if (data.success) {
						commonData[name] = data.data;
						setData({ ...commonData });
					}
				});
		};
		fetchData(para, name);
	};

	/**
	 *MOUNT
	 */
	useEffect(() => {
		//공통코드 조회 (T1업체)
		onSearchCommon(`{"pgmid":"VNT1"}`, 'vnt1List');
		//공통코드 조회 (공장)
		onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"CKD"}`, 'plntList');

		//조회
		onSearch();
	}, []);

	/**
	 * Control 변경 Event
	 * @param {*} e
	 */
	const onChange = async (e) => {
		const { name, value } = e.target;

		let newSearchs = idata;
		newSearchs[name] = value;
		setData({ ...newSearchs });

		//T1업체, 회사구분 변경시
		if (name === 'kVnt1' || name === 'kComp') {
			newSearchs['plntList'] = [];
			newSearchs['gateList'] = [];
			newSearchs['kPlnt'] = '';
			newSearchs['kGate'] = '';
			setData({ ...newSearchs });

			//공통코드 조회 (공장)
			onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"CKD"}`, 'plntList');
		}

		//납품서 발행 조회
		onSearch();
	};

	const onClick = async (e) => {
		setPanded(!panded);

		if (panded === true) {
			setStyle({
				height: 'calc(100vh - 60px - 50px - 50px)',
			});
		} else {
			setStyle({
				height: 'calc(100vh - 60px - 50px - 100px  - 50px)',
			});
		}
	};

	/**
	 * Grid Event
	 * @param {*} e
	 */
	//Main Grid CellValueChanged
	const grdRom3kMain_onCellValueChanged = async (e) => {};

	//Main Grid Row Click
	const grdRom3kMain_onRowClicked = async (e) => {
		let data = e.data;

		//Detail Grid 조회
		onSearch2(data.dlno, data.gate, data.prgb);
	};

	//Detail Grid CellValueChanged
	const grdRom3kDetail_onCellValueChanged = async (e) => {};

	//Detail Grid Row Click
	const grdRom3kDetail_onRowClicked = async (e) => {};

	/**
	 * 검색조건
	 */
	const optionsX = {
		0: [
			//T1업체
			{
				formatter: 'select',
				name: 'kVnt1',
				labelName: 'T1업체',
				type: 'text',
				defaultValue: idata.kVnt1,
				codeMode: true,
				selectAll: '',
				codes: null,
				comms: idata.vnt1List,
				align: 'left',
			},
			//회사구분
			{
				formatter: 'select',
				name: 'kComp',
				labelName: '회사구분',
				type: 'text',
				defaultValue: idata.kComp,
				codeMode: true,
				selectAll: '',
				codes: CODES['comp'],
				comms: null,
				align: 'left',
			},
			//공장
			{
				formatter: 'select',
				name: 'kPlnt',
				labelName: '공장',
				type: 'text',
				defaultValue: idata.kPlnt,
				codeMode: false,
				selectAll: 'all',
				codes: null,
				comms: idata.plntList,
			},
			{ formatter: 'blank', align: 'left' },
			{ formatter: 'blank', align: 'left' },
		],
		1: [
			//발행일자
			{
				formatter: 'input',
				name: 'kNpdt',
				labelName: '납품일자',
				type: 'date',
				desc: '',
				readOnly: false,
				defaultValue: idata.kNpdt,
				align: 'left',
				labelType: 'none',
			},
			//납품서번호
			{
				formatter: 'input',
				name: 'kDlno',
				labelName: '납품서번호',
				type: 'text',
				desc: '',
				readOnly: false,
				defaultValue: idata.kDlno,
				align: 'left',
				labelType: 'none',
			},
			//조회구분 ( 발행취소, 재인쇄 )
			{
				formatter: 'radio',
				name: 'kGubn',
				labelName: '조회구분',
				defaultValue: idata.kGubn,
				options: [
					{ name: 'kGubn1', labelName: '발행취소', setValue: '1' },
					{ name: 'kGubn2', labelName: '재인쇄', setValue: '2' },
				],
			},
			{ formatter: 'blank', align: 'left' },
			{ formatter: 'blank', align: 'left' },
		],
	};

	/**
	 * 조회
	 * @param {*} e
	 * @returns
	 */
	const onSearch = async (e) => {
		setClearGrid2(true); // Detail Grid 초기화

		//T1업체선택 Check
		if (idata.kVnt1 == '') {
			setMessage({ openAlert: true, severity: 'info', msg: '1차업체를 선택하십시요 !' });
			document.getElementById('kVnt1').focus();
			return;
		}

		//회사구분선택 Check
		if (idata.kComp == '') {
			setMessage({ openAlert: true, severity: 'info', msg: '회사구분을 선택하십시요 !' });
			document.getElementById('kComp').focus();
			return;
		}

		//공장선택 Check
		if (idata.kPlnt == null) {
			setMessage({ openAlert: true, severity: 'info', msg: '공장을 선택하십시요 !' });
			document.getElementById('kPlnt').focus();
			return;
		}

		// Cookie 값 셋팅
		Common.setCookie('ck_vnt1' + userId, idata.kVnt1, 1);

		let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                     "kNpdt":"${idata.kNpdt}","kDlno":"${idata.kDlno}", "kGubn":"${idata.kGubn}" }`;
		let param = encodeURI(para);

		const query = SERVER_URL + '/rom3k/search?query=' + param;
		setSearchApi(query);
	};

	/**
	 * 발행취소
	 * @param {*} e
	 */
	const onDelete = async (e) => {
		//select nodes
		let selectedData = grdRom3kMain.current.getSelectedData();

		// 선택 값 체크
		if (selectedData.length === 0) {
			//Alert 창 OPEN
			setMessage({ openAlert: true, severity: 'info', msg: '발행취소할 자료가 없습니다.' });
			return false;
		}

		for (var i = 0; i < selectedData.length; i++) {
			if (selectedData.length === 1) {
				if (selectedData[i].chul === 'Y' && selectedData[i].gubn === '1') {
					setMessage({ openAlert: true, severity: 'info', msg: '출하전송된 납품서입니다. 회수 후 삭제해주세요.' });
					e.currentTarget.disabled = false;
					return false;
				}
			} else {
				if (selectedData[i].chul === 'Y' && selectedData[i].gubn === '1') {
					setMessage({ openAlert: true, severity: 'info', msg: '출하전송된 납품서가 포함되어있습니다.확인 후 다시 발행취소해 주세요.' });
					e.currentTarget.disabled = false;
					return false;
				}
			}
		}

		//발행취소 확인 메세지
		setDlgMessage({ openDlg: true, dlgTrans: 'D', dlgMsg: '선택한 데이터를 발행취소 하시겠습니까?' });
	};

	/**
	 * 납품서 발행 재인쇄
	 * @param {*} e
	 * @returns
	 */
	const onPrint = async () => {
		//select nodes
		let selectedData = grdRom3kMain.current.getSelectedData();

		// 선택 값 체크
		if (selectedData.length === 0) {
			//Alert 창 OPEN
			setMessage({ openAlert: true, severity: 'info', msg: '선택한 데이터가 없습니다.' });
			return false;
		}

		const fetchData = async () => {
			let selectDlnoList = [];
			selectedData.map(function (data, i) {
				if (data.dlno) {
					selectDlnoList.push(data.dlno);
				}
			});

			const query = SERVER_URL + '/rom3i/print';
			let param = JSON.stringify({
				rprint: 'Y',
				dlnolist: selectDlnoList,
				gubn: '', //그룹발행구분
			});

			let printList = {};
			let ptlist = [];
			let posList = {};

			await fetch(query, { method: 'POST', headers: { Authorization: token, 'Content-Type': 'application/json' }, body: param })
				.then((resp) => resp.json())
				.then((data) => {
					if (data.success) {
						setPrintData(data.data);

						// 성공 메시지 처리 필요
						setMessage({ openAlert: true, severity: 'success', msg: '인쇄 조회 성공 하였습니다.' });

						for (let i = 0; i < data.data.length; i++) {
							let detailPrintInfo = [];
							let headPrintInfo2 = {};
							let detailPrintInfo2 = {};
							//헤더
							headPrintInfo2 = {
								vandcd: data.data[i].head.vnt1,
								vandnm: data.data[i].head.vdnm,
								companyNm: data.data[i].head.conm,
								plnm: data.data[i].head.plnm,
								gate: data.data[i].head.gate,
								deliveryDate: data.data[i].head.prif + '**',
								issueNo: data.data[i].head.dlno,
								cano: data.data[i].head.cano,
								gubn: 'N',
								posx: parseInt(data.data[i].head.posx),
								posy: parseInt(data.data[i].head.posy),
							};
							for (let z = 0; z < data.data[i].detail.length; z++) {
								//디테일
								detailPrintInfo2 = {
									vandcd: data.data[i].detail[z].VNT1,
									vandnm: data.data[i].detail[z].VDNM,
									companyNm: data.data[i].detail[z].CONM,
									plnm: data.data[i].detail[z].PLNM,
									gate: data.data[i].detail[z].GATE,
									deliveryDate: data.data[i].detail[z].PRIF,
									issueNo: data.data[i].detail[z].DLNO,
									cano: data.data[i].detail[z].CANO,
									dseq: data.data[i].detail[z].DSEQ,
									nidt1: data.data[i].detail[z].NIDT1,
									case1: data.data[i].detail[z].CASE1,
									casec: data.data[i].detail[z].CASEC,
									dlno: data.data[i].detail[z].DLNO,
									itemNm: data.data[i].detail[z].PTNM,
									itemNo: data.data[i].detail[z].PTNO,
									npqt: data.data[i].detail[z].NPQT,
									oknm: data.data[i].detail[z].OKNM,
									bccc: data.data[i].detail[z].BCCC,
									gubn: 'N',
									plqt: data.data[i].detail[z].PLQT,
									jaqt: data.data[i].detail[z].JAQT,
									pmgb: data.data[i].detail[z].PMGB === undefined ? '' : data.data[i].detail[z].PMGB,
									frod: data.data[i].detail[z].FROD,
									tood: data.data[i].detail[z].TOOD,
								};
								detailPrintInfo.push(detailPrintInfo2);
								//헤더정보를 다음 데이터의 헤더 정보를 불러옴(긴급건으로 임시 대응)
								headPrintInfo2.vandcd = data.data[i].detail[z].VNT1;
								headPrintInfo2.vandnm = data.data[i].detail[z].VDNM;
								headPrintInfo2.companyNm = data.data[i].detail[z].CONM;
								headPrintInfo2.plnm = data.data[i].detail[z].PLNM;
								headPrintInfo2.gate = data.data[i].detail[z].GATE;
								headPrintInfo2.deliveryDate = data.data[i].detail[z].PRIF + '**';
								headPrintInfo2.issueNo = data.data[i].detail[z].DLNO;
								headPrintInfo2.cano = data.data[i].detail[z].CANO;
							}
							printList = {
								headPrintInfo: headPrintInfo2,
								detailPrintInfo: detailPrintInfo,
							};
							ptlist.push(printList);

							posList = {
								posx: parseInt(data.data[i].head.posx),
								posy: parseInt(data.data[i].head.posy),
							};
						}
						setPos(posList);

						if (data.data[0].head.bigi === 'Y') {
							let newBigiPrintList = {};
							newBigiPrintList['printItemList'] = ptlist;
							onPrint2(newBigiPrintList);
						} else {
							printBtnRefQR.current.dispatchEvent(new Event('click', { bubbles: true }));
						}
					}

					if (data.error) {
						setMessage({ openAlert: true, severity: 'error', msg: '조회 실패' });
					}
				})
				.catch((error) => {
					if (error) {
						setMessage({ openAlert: true, severity: 'error', msg: '서버 상태를 확인해 주세요. 관리자에게 문의해주세요.' });
					}
				});
		};
		fetchData();
	};

	/**
	 * 납품서 발행 재인쇄
	 * @param {*} e
	 * @returns
	 */
	const onPrintChk = async (e) => {

		let selectedData = grdRom3kMain.current.getSelectedData();

		// 선택 값 체크
		if (selectedData.length === 0) {
			setMessage({ openAlert: true, severity: 'info', msg: '선택한 데이터가 없습니다.' });
			return false;
		}
		onPrint();
	};

	const onPrint2 = async (printData) => {
		let ptdata = JSON.stringify(printData);
		let body = {
			userCode: userId,
			type: 'ckdcard',
			json: ptdata.replaceAll('"', "'"),
			state: 'REQ',
		};
		let data = JSON.stringify(body);

		//await fetch('http://rpa.bigibot.com:9400/system/print', { method: 'POST', headers: { 'X-AppKey': 'to1adn6pq_sw', 'Content-Type': 'application/json' }, body: data })
		const query = SERVER_URL + "/bigi/print"; 
        await fetch(query, { method: "POST", headers: {"Content-Type":"application/json"}, body: data })
			.then((resp) => resp.json())
			.then((data) => {
				let str = data.data;
				if (str.includes('bigi://ckdcard') === true) {
					// window.location.href = data.data;
					window.location.href = data.data.replaceAll("\"","");
					setMessage({ openAlert: true, severity: 'success', msg: 'BIGI를 확인해주세요.' });
				} else {
					setMessage({ openAlert: true, severity: 'error', msg: 'BIGI 프로그램을 다시 시도해주세요.' });
				}
			})
			.catch((error) => {
				if (error) {
					setMessage({ openAlert: true, severity: 'error', msg: 'BIGI 프로그램을 재시작 후 다시 시도해주세요.' });
				}
			});
	};

	/**
	 *
	 * @param {*} dlno 납품서번호
	 * @param {*} gate 하치장
	 * @param {*} prgb 인쇄여부
	 */
	const onSearch2 = async (dlno, gate, prgb) => {
		let para = `{"kDlno":"${dlno}","kGate":"${gate}"}`;
		let param = encodeURI(para);

		const query = SERVER_URL + '/rom3k/search2?query=' + param;
		setSearchApi2(query); // Search API
	};

	const AlertDialogResult = async (e) => {
		//AlertDialog 초기화
		setDlgMessage({ ...dlgMessage, openDlg: false });

		//DialogResult
		if (e.result == 'N') return;

		//Transaction 구분
		switch (e.Trans) {
			//등록
			case 'I':
				break;
			//수정
			case 'U':
				break;
			//삭제
			case 'D':
				const fetchData = async () => {
					let selectedData = grdRom3kMain.current.getSelectedData();
					let selectDlnoList = [];

					selectedData.map(function (data, i) {
						if (data.dlno) {
							selectDlnoList.push(data.dlno);
						}
					});

					const queryD = SERVER_URL + '/rom3k/delete';

					let param = JSON.stringify({
						vnt1: idata.kVnt1,
						comp: idata.kComp,
						ename: idata.kName,
						telno: idata.kTelno,
						jmno: idata.kJmno,
						func: 'CL',
						rprint: 'N',
						dlnolist: selectDlnoList,
						gubn: '', //그룹발행구분
						selectedData: selectedData,
					});

					await fetch(queryD, { method: 'POST', headers: { Authorization: token, 'Content-Type': 'application/json' }, body: param })
						.then((resp) => resp.json())
						.then((data) => {

							if (data.success) {
								setMessage({ openAlert: true, severity: 'success', msg: data.data[0].message == '' || data.data[0].message == undefined ? '삭제에 성공하였습니다.' : data.data[0].message });
								setTimeout(() => onSearch(), 300);
							}
							if (data.success === false) {
								setMessage({ openAlert: true, severity: 'error', msg: data.errors.message == '' || data.errors.message == undefined ? '삭제에 실패하였습니다.' : data.errors.message });
								setTimeout(() => onSearch(), 300);
							}
							if (data.error) {
								setMessage({ openAlert: true, severity: 'error', msg: data.errors.message == '' || data.errors.message == undefined ? '삭제에 실패하였습니다.' : data.errors.message });
								setTimeout(() => onSearch(), 300);
							}
						})
						.catch((error) => {
							if (error) {
								setMessage({ openAlert: true, severity: 'error', msg: '서버 상태를 확인해 주세요. 관리자에게 문의해주세요.' });
							}
						});
				};
				fetchData();
				setDeleteApi(false);
				break;
		}
	};

	/**
	 *Grid Column 정의
	 */
	//Main Grid Column
	const columnDefs = [
		{ headerName: '업체코드', field: 'vand', sortable: true, filter: true, flex: 1, minWidth: 0, hide: true }, // (HIDDEN)
		{ headerName: '회사구분', field: 'comp', sortable: true, filter: true, flex: 1, minWidth: 0, hide: true }, // (HIDDEN)
		{ headerName: 'npdt', field: 'npdt', sortable: true, filter: true, flex: 1, minWidth: 0, hide: true },    // (HIDDEN) (발행일시)
		{ headerName: 'npdh', field: 'npdh', sortable: true, filter: true, flex: 1, minWidth: 0, hide: true },    // (HIDDEN) (발행시간)
		{ headerName: 'prtm', field: 'prtm', sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, hide: true },
		{ headerName: 'vnt1', field: 'T1업체', sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, hide: true },
		{ headerName: 'check1', field: 'check1', sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, hide: true },
		{
			headerName: '공장',
			field: 'plnt',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 140,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			checkboxSelection: (params) => (params.data.chul === 'Y' && '1' === params.data.gubn ? false : true),
			hide: false,
		},
		{
			headerName: '하치장',
			field: 'gate',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 140,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '납품서번호',
			field: 'dlno',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 380,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '납기일자',
			field: 'pldt',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 200,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			valueFormatter: Utils.dateFormatter,
			hide: false,
		},
		{
			headerName: '납품일시',
			field: 'npdt1',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 200,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '품목건수',
			field: 'pcnt',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 130,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '인쇄',
			field: 'prgb',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 130,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '전송확인',
			field: 'chul',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 130,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
	];
	//Detail Grid Column
	const columnDefs1 = [
		{ headerName: 'vnt1(H)', field: 'vnt1', sortable: true, filter: true, flex: 1, minWidth: 0, hide: true }, // (HIDDEN)
		{ headerName: 'comp(H)', field: 'comp', sortable: true, filter: true, flex: 1, minWidth: 0, hide: true }, // (HIDDEN)
		{ headerName: 'plnt', field: 'plnt', sortable: true, filter: true, flex: 1, minWidth: 0, hide: true },    // (HIDDEN)
		{ headerName: 'dlno(H)', field: 'dlno', sortable: true, filter: true, flex: 1, minWidth: 0, hide: true }, // (HIDDEN)
		{ headerName: '하치장(H)', field: 'gate', sortable: true, filter: true, flex: 1, minWidth: 0, hide: true }, // (HIDDEN)
		{ headerName: 'prgb', field: 'prgb', sortable: true, filter: true, flex: 1, minWidth: 0, hide: true },    // (HIDDEN)
		{ headerName: 'pldt', field: 'pldt', sortable: true, filter: true, flex: 1, minWidth: 0, hide: true },    // (HIDDEN)
		{
			headerName: '순번',
			field: 'dseq',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '공장',
			field: 'plnt',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '품번',
			field: 'ptno',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 200,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			valueGetter: Utils.partnoGetter,
			hide: false,
		},
		{
			headerName: '납품수량',
			field: 'npqt',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: 'PD no.',
			field: 'pdno',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 140,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '판매문서',
			field: 'seld',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: 'From Order',
			field: 'frod',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: 'To Order',
			field: 'tood',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: 'CASE NO',
			field: 'case1',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 140,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '차량번호',
			field: 'cano',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 140,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
	];

	return (
		<>
			{/* Dialog FRAME */}
			<AlertDialog open={openDlg} trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

			{/* Alert FRAME */}
			<CustomAlert
				open={openAlert}
				severity={severity}
				duration={5000}
				message={msg}
				onClose={() => {
					setMessage({ ...message, openAlert: false });
				}}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			/>

			{/* Ribbon Frame : 프로그램 Path 및 버튼 */}
			<Ribbon
				onInfoMsg={"납품서 발행 취소 방법<br/><br/>1. 먼저 '출하정보전송' 메뉴에서 삭제할 납품서 선택 후 회수처리<br/>2. '발행취소/재인쇄' 메뉴에서 삭제할 납품서 체크 후 발행취소"}
				onSearch={onSearch}
				onSearchName={null} //조회
				onDelete={onDelete}
				onDeleteName={'발행취소'}
				onDeleteVisible={idata.kGubn === '1'} // 발행취소일 때만 사용
				onPrint={(e) => onPrintChk(e)}
				onPrintName={'재인쇄'}
				onPrintVisible={idata.kGubn === '2'} // 재인쇄일 때만 사용
			>
				{'납입지시 > ERP_CKD출하관리 > '}
				<b style={{ color: '#cd5c5c', fontFamily: 'PretendardMedium, NanumGothic, sans-serif' }}>발행취소/재인쇄 (E_CKD)</b>
			</Ribbon>

			{/* ContentXearch Frame : 검색조건  expanded = {expanded}  */}
			<ContentXearch optionsX={optionsX} onChange={onChange} onClick={onClick} />

			<Content style={style}>
				{' '}
				{/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:100px */}
				<div className={'grid_type01'} style={{ height: '100%' }}>
					{' '}
					{/* 상단 FRAME */}
					<div className={'grid_wrap01'}>
						{' '}
						{/* 상단 wrap FRAME */}
						<div className="grid_cont_box" style={{ height: '100%' }}>
							<Grid
								ref={grdRom3kMain}
								gridId={'ROM3K'}                  // (필수) Grid Id 정의
								columnDefs={columnDefs}           // (필수) 컬럼 정의
								pagination={false}                // 쪽수 매기기 여부
								paginationCsv={true}              // CSV 사용구분 - pagination과 동시에 사용 필요
								paginationPageSize={'1000'}       // 쪽수 1Page 당 Row수
								rowMultiSelectWithClick={true}    // 멀티 클릭 가능 여부
								suppressRowClickSelection={false} // 클릭으로 선택 하는것을 방지
								rowSelection={'multiple'}         // 여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
								enableRangeSelection={false}
								enableCellTextSelection={true}    // cell selection drag mode
								isFirst={false}                   // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
								displayNo={false}
								searchApi={searchApi}             // 조회
								deleteApi={deleteApi}             // 삭제
								deleteRefresh={onSearch}          // 삭제이후
								onClearApi={onClearApi}           // 초기화
								onCellValueChangedEvent={grdRom3kMain_onCellValueChanged}
								onRowClickEvent={grdRom3kMain_onRowClicked}
							/>
						</div>
					</div>
				</div>
				<div className="grid_type04" style={{ height: '50%' }}>
					{/* 하단 FRAME */}
					<div className="grid_wrap04">
						{' '}
						{/* 하단 wrap 좌측 FRAME */}
						<div className="grid_area">
							{' '}
							{/* Grid area */}
							<div className="grid_cont_box" style={{ height: '100%' }}>
								{/* 납품서 발행현황 Grid */}
								<Grid
									ref={grdRom3kDetail}
									gridId={'ROM3K2'}
									columnDefs={columnDefs1}
									pagination={false}
									paginationCsv={true}
									paginationPageSize={'1000'}
									rowMultiSelectWithClick={true}
									suppressRowClickSelection={true}
									rowSelection={'single'}
									enableRangeSelection={false}
									enableCellTextSelection={true}
									isFirst={false}
									displayNo={false}
									searchApi={searchApi2}
									onClearApi={onClearApi2}
									clearGrid={clearGrid2}
									onClearGridFlag={setClearGrid2}
									onRowClickEvent={grdRom3kDetail_onRowClicked}
									onCellValueChangedEvent={grdRom3kDetail_onCellValueChanged}
								/>
							</div>
						</div>
					</div>
				</div>
				{/* 테스트용 ( 웹에서 확인용 삭제하지 말 것.) */}
				{/* <div>
                    <CkdCardPrintQR ref={printRef} printData={printdata} pos = {pos} />
                </div> */}
			</Content>

			<div className="printArea">
				<ReactToPrint trigger={() => <button className="btn" ref={printBtnRefQR} style={{ display: 'none' }} />} pageStyle={pageStyle} content={() => printRefQR.current} />
				<CkdCardPrintQR ref={printRefQR} printData={printdata} pos={pos} />
			</div>
		</>
	);
}
export default ROM3K;
