/**
 * @Page ROM13
 * @Description [ROM13]납입지시 - 기초관리 - T2업체 품목관리
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import GridRibbon from "@/components/organisms/GridRibbon";
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import Popup from '@/pages/Popup/Popup';    
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
  
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();

 function ROM13() {
 
    const token = Common.getToken();
    const userId = Common.getToken("id");
    const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const tVnt2 = Common.getToken("vand2");
    const tComp = Common.getToken("comp");
    const grdRom13Main = useRef();   //Grid Main


    //Data
    const [idata, setData] = useState({
        //조회조건 
        kVnt1 : tVnt1,      //T1업체
        kVnt2 : "",      //T2업체
        kComp: tComp,       //회사구분
        kPlnt: "HC11",          //공장
        kDate: Common.getToday(),   //일자
        kPtno : "",         //품번
        rate:"",//발주비율
        vnt2:tVnt2,//발주비율

        //코드 데이터
        compList : [],  //회사
        vnt2List : [],  //T2업체
        plntList : [],  //공장
      
    }); 
    
         
    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;
  
  
    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      // Dialog Transaction(I,U,D)
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
  

    //Popup
    const [typeOpen, setTypeOpen] = React.useState(false);
    const [typeOpen2, setTypeOpen2] = React.useState(false);
   
    // Params (Insert, Update Parameter)
    let [inserts, setInsert] = useState({}); 
    let [deletes, setDelete] = useState({}); 

    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    let [clearGrid, setClearGrid] = useState(false);  


    let [insertApi, setInsertApi] = useState(false); 
    let [updateApi, setUpdateApi] = useState(false); 
    let [deleteApi, setDeleteApi] = useState(false); // deleteApi 항목 setting

    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 100px  - 50px)"});

    const onClearApi = useCallback(async (e) => {
         setSearchApi(false);
         setInsertApi(false);
         setDeleteApi(false);
    }, []);

    



    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }

/**
     * 회사구분
     * @param {*} para 
     * @param {*} name 
     */
 const onSearchCommon1 = (para, name) => {
    let commonData = idata;

    const fetchData1 = async (para, name) => {

        let param = encodeURI(para);
        const query = SERVER_URL + "/comcd/search1?query=" + param; 
        await fetch(query, { headers: { Authorization: token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) 
                {
                    if (name === "compList") {
                      
                    }
                    commonData[name] = data.data
                    setData({...commonData});
                }
            })
    }
    fetchData1(para, name);
}
    
    /**
     *MOUNT 
    */
    useEffect( () => {
        
        //공통코드 조회 (T2업체)
        onSearchCommon(`{"pgmid":"VNT2NM",key0:"T2"}`, "vnt2List");
        //공통코드 조회 (공장)
        onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "plntList");
        //공통코드 조회 (회사구분)
        onSearchCommon1(`{"pgmid":"CODE", "vand":"****", "gocd":"COMP","order":"COMP"}`, "compList");

        //onSearch2();
        
    }, []);
  
  
  
    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value} = e.target;

        let newSearchs = idata; 
        newSearchs[name] = value
        setData({ ...newSearchs });

        //공장 변경시 하치장 조회
        // if (name === "kPlnt") {
        //     onSearchCommon(`{"pgmid":"VGATE","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}"}`, "gateList")
        // }
        //T1업체, 회사구분 변경시 
        if (name === "kVnt1" || name === "kComp")  {
            
            newSearchs["plntList"] = [];
            newSearchs["gateList"] = [];
            newSearchs["kPlnt"] = "";
            //newSearchs["kGate"] = "";
            setData({ ...newSearchs });

            //공통코드 조회 (공장)
            onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "plntList");
        }
    }
 
  
  
    /**
     * Grid Event 
     * @param {*} e 
     */
    const grdRom13Main_onCellValueChanged = async (e) => {

        
    }
    const grdRom13Main_onRowClicked = async (e) => {
    }

    //Cell Key Down
    const grdRom13Main_onCellKeyDown = async (e) => {
    }
    
  
  
    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //회사구분
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": true,
                "selectAll": "",
                "codes": null,
                "comms": idata.compList,
                "align": "left", 
            },
            //공장  
            {
                "formatter": "select", "name": "kPlnt", "labelName": "공장", "type": "text", "defaultValue": idata.kPlnt,  
                "codeMode": false,
                "selectAll": "",
                "codes": null,
                "comms": idata.plntList,
            },
             //T2업체
            {
                "formatter": "select", "name": "kVnt2", "labelName": "T2업체", "type": "text","defaultValue": idata.kVnt2,  
                "codeMode": true,
                "selectAll": true,
                "codes": null,
                "comms": idata.vnt2List,
                "align": "left", 
            },
            {
                "formatter": "select", "name": "vnt2", "labelName": "T2업체", "type": "text",  
                "codeMode": true,
                "selectAll": "",
                "codes": null,
                "comms": idata.vnt2List,
                "align": "left", 
                "labelType":"insert" // 검색조건 입력 모드 table.css className // .h_Label1R_insert
            }, //T2업체(입력)
            {
                "formatter": "input", "name": "rate", "labelName": "발주비율", "type": "text", "desc": "", "readOnly" : false,
                "align": "left", 
                "labelType":"insert" // 검색조건 입력 모드 table.css className // .h_Label1R_insert
            }, //발주비율(입력)
           
        ],
        1: [
            //품번
            {
                "formatter": "input", "name": "kPtno", "labelName": "품번", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kPtno,  
                "align": "left", 
                "labelType": "none", 
            }, 
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
        ]

    }
  
  
  
    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {
        
        setClearGrid(true); // Detail Grid 초기화

        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                     "kPtno":"${idata.kPtno}","kVnt2":"${idata.kVnt2}"}`;
        let param = encodeURI(para);
        const query = SERVER_URL + "/rom13/search?query=" + param;
        setSearchApi(query); // Search API
    }

    const onSearch2 = async (e) => {
        
        setTypeOpen(true);
    }


    /**
     * t2업체 닫기 후 t2콤보 재 조회 
     * @param {*} e 
     * @returns 
     */
     const onSearchT2 = () => {
        //공통코드 조회 (T2)
        onSearchCommon(`{"pgmid":"VNT2NM",key0:"T2"}`, "vnt2List");
    }


    //deleteBlank
    function deleteBlank(argval){
        if (argval === null || argval === undefined) return "";

        for(var i=argval.length-1 ;i >= 0; i--){
            
            if(argval.charCodeAt(i) !== 12288 && argval.charCodeAt(i) !== 32 ) //charCodeAt(i)  
               return(argval.substring(0,i+1));
        }
        return(argval.length>0?argval.substring(0,0):"")
    }



    /**
     * 인쇄 
     * @param {*} e 
     * @returns 
     */
     const onPrint = async (e) => {
    }
  
  

    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
    }

    const onInsert = async (e) => {
        //등록

         //발주비율 Check
        // if (idata.rate == "")
        // {
        //     setMessage({ openAlert : true, 
        //                  severity : "info",
        //                  msg : "발주비율을 입력하십시요."});
        //     document.getElementById("rate").focus();                         
        //     return ;
        // }

        //발주비율 입력안해도 되도록.. Check 
        if (idata.rate !== "" && idata.rate > 100)
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "발주비율은 100% 이상 입력 할 수없습니다!"});
            document.getElementById("rate").focus();                         
            return ;
        }
        let selectedData = grdRom13Main.current.getSelectedNodes();

        for(var i=0; i<selectedData.length; i++) {
			if(selectedData[i].data.vcnt > 5){
                setMessage({ openAlert : true, 
                    severity : "info",
                    msg : "5!"});
				return;				
			}
        }

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "선택한 자료가 없습니다."});
            return false;
        }

        if(idata.vnt2 === '' || idata.vnt2 === undefined){
            idata.vnt2 = idata.vnt2List[0].comCode;
        };

        //Insert Parameter
        let newInserts = inserts;
        newInserts["comp"] = idata.kComp;  // 회사구분
        newInserts["plnt"] = idata.kPlnt;  // 공장

        newInserts["vnt2"] = idata.vnt2;  //  2차업체
        newInserts["rate"] = idata.rate;  // 발주비율

        newInserts["date"] = idata.kDate.replace(/-/g, '');    // 일자
        
        setInsert({ ...newInserts });

        const query = SERVER_URL + "/rom13/insert";
        setInsertApi(query); // Insert API
    }

    // input check
    const inputCheck = (gbn) => { 
 
    }
      
    /**
     * t2업체등록
     * @param {*} e 
     */
    const onInsert2 = async (e) => {
        //t2업체등록Popup Open
        setTypeOpen2(true);
    }
  
  
  
    /**
     * t1업체확인
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {
        //t1업체확인Popup Open
        setTypeOpen(true);
    }
  


    /**
     * 삭제
     * @param {*} e 
     */
    const onDelete = async (e) => {
        let selectedData = grdRom13Main.current.getSelectedNodes();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "선택한 데이터가 없습니다."});
            return false;
        }
        
        if(idata.vnt2 === '' || idata.vnt2 === undefined){
            idata.vnt2 = idata.vnt2List[0].comCode;
        };
            
            for(var i=0; i<selectedData.length; i++) {
                selectedData[i].data["vnt2"] = idata.vnt2;  //  2차업체
            }
                
            setDelete({ ...selectedData });
        //삭제 확인 메세지 
        setDlgMessage({openDlg : true,  
                     dlgTrans : "D", 
                     dlgMsg : "선택한 데이터를 삭제 하시겠습니까?" });

    }



    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans) 
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            
            //삭제
            case "D" :
               
                const query  = SERVER_URL + "/rom13/delete";
                setDeleteApi(query); 

                break;

        }
    }
      
    

    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 45px - 10px)",
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 100px  - 50px)",
              });
        }
    }
 
     
  
    /**
     *Grid Column 정의 
    */
    //Main Grid Column
    const columnDefs = [
        { headerName: "vand(h)", field: "vand", sortable: true, filter: false, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },
        { headerName: "comp(h)", field: "comp", sortable: true, filter: false, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },
        { headerName: "plnt(h)", field: "plnt", sortable: true, filter: false, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },
        { headerName: "vcnt(h)", field: "vcnt", sortable: true, filter: false, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },
        { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 200, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
        { headerName: "품명", field: "ptnm", sortable: true, filter: true, flex: 1, minWidth: 260, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }, 
        { headerName: "", field: "vnt21", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false}, 
        { headerName: "", field: "rate1", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "", field: "vnt22", sortable: false, filter: false, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false}, 
        { headerName: "", field: "rate2", sortable: false, filter: false, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "", field: "vnt23", sortable: false, filter: false, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false}, 
        { headerName: "", field: "rate3", sortable: false, filter: false, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "", field: "vnt24", sortable: false, filter: false, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false}, 
        { headerName: "", field: "rate4", sortable: false, filter: false, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "", field: "vnt25", sortable: false, filter: false, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false}, 
        { headerName: "", field: "rate5", sortable: false, filter: false, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
    ];

      
    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

            <Popup type="T1Comp" open={typeOpen} onClose={()=> {setTypeOpen(false)}}/>
            <Popup type="T2Code" open={typeOpen2} onClose={()=> {setTypeOpen2(false);onSearchT2();}} />{/*para={idata.kComp}*/}

            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onInfoMsg={"T1, T2 업체 연계 방법 <br/><br/> 1. T1(1차)업체 : 'T2업체등록' 팝업에서 업체코드와 납품구분 값을 입력하여 등록을 한다. <br/> 2. T2(2차)업체 : 'T1업체확인' 팝업에서 등록되어 있는 T1업체 선택 후 확인을 한다. "}
                onSearch={onSearch} onSearchName={"조회"}             //조회
                onInsert={onInsert} onInsertName={"등록"} //입력
                onDelete={onDelete} onDeleteName={"삭제"} //삭제
                onFunction={onInsert2} onFunctionName={"T2업체등록"}   //T2업체등록
                onFunctionType={"insert"}                             //T2업체등록 버튼이미지명 호출
                onFunction2={onUpdate} onFunctionName2={"T1업체확인"}  //T1업체확인
                onFunctionType2={"search"}                            //T1업체확인 버튼이미지명 호출
            >
                {"납입지시 > 기초관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>T2업체 품목관리</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick = {onClick} />


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:101px */}
                <div className={"grid_type01"} > {/* 상단 FRAME */}
                    <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                        <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                            <Grid
                                ref={grdRom13Main}
                                gridId={"ROM13"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                pagination={true}                  //  쪽수 매기기 여부
                                paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={false}   //  클릭으로 선택 하는것을 방지
                                rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={true}      // cell selection drag mode
                                isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                onClearApi={onClearApi}             // 초기화
                                clearGrid={clearGrid}
                                onClearGridFlag={setClearGrid}
                                onCellValueChangedEvent={grdRom13Main_onCellValueChanged}
                                onRowClickEvent={grdRom13Main_onRowClicked}
                                //onCellKeyDownEvent ={grdRom4nMain_onCellKeyDown}
                                insertApi={insertApi}               // 등록
                                insertParam={inserts}               // 등록 및 수정시 Parmeter
                                insertRefresh={()=> {onSearch();} } // 등록이후 
                                updateApi={updateApi}               // 수정
                                deleteApi={deleteApi}               // 삭제
                                updateRefresh={onSearch}            // 수정이후 
                                deleteRefresh={onSearch}            // 삭제이후 
                            />
                        </div>
                    </div>
                </div>
                
            </Content>
        </>
    )
  }
  export default ROM13;