/**
 * @Page RNTC11
 * @Description [RNTC11]수신자료관리 - ERP_모듈정보조회 - 사양차이 정산 상세내역
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
 import UploadModal from "@/components/organisms/UploadModal";
 import Loading from "src/components/atoms/CustomLoading2";

  
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();

 
  function RNTC11() {
 
    const token = Common.getToken();
    const userId = Common.getToken("id");
    // const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const grdRntc11Main = useRef();   //Grid Main  

    const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
    let [loading, setLoading] = useState(false);
    
    //Data
    const [idata, setData] = useState({
        //조회조건
        // kVnt1 : tVnt1,      //T1업체
        kVnt1 : ckVnt1,        //T1업체     
        kComp : tComp,      //회사구분
        kDocd : "",         //문서코드 
        kTotcnt : "",       //전체건수
        kVnt1Nm : "",        //T1업체명
        kCompNm : tComp==="EHMC"?"현대자동차(주)":"기아자동차(주)",        //회사명

        //코드 데이터
        vnt1List : [],  //T1업체
        docdList : [],  //문서코드
    }); 
    
            
    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;
    const [uploadInfo, setUploadInfo] = useState({ vnt1: tVnt1, subDir: "DATA" });

    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 50px)"});
    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
  
   
    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    const [openUpload, setOpenUpload] = React.useState(false); // Modal 항목 setting

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);

    }, []);



    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }

    const onSearchCommon1 = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                    commonData[name] = data.data[0].text;
                    setData({...commonData});
                    }
                })
        }
        fetchData(para, name);   
    }
     


    /**
     *MOUNT 
    */
    useEffect( () => {
        
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");

        //공통코드 조회 (문서코드)
        onSearchCommon(`{"pgmid":"DOC_ERPC4","Key1":"*", "kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "docdList");

        onSearchCommon1(`{"pgmid":"WRCVAND","kVnt1":"${idata.kVnt1}"}`, "kVnt1Nm");

    }, []);
  
  
  
    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value, desc} = e.target;

        let newSearchs = idata; 
        let newUploadInfo = uploadInfo;
        newSearchs[name] = value;
        newSearchs[name+"Nm"] = desc;
        
        setData({ ...newSearchs });

         //T1업체, 회사구분 변경시 
         if (name === "kVnt1")  {

            newSearchs["kDocd"] = "";
            newUploadInfo["vnt1"] = value;

            setData({ ...newSearchs });
            setUploadInfo({ ...newUploadInfo });

            onSearchCommon(`{"pgmid":"DOC_ERPC4","Key1":"*", "kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "docdList");
        }
        if(name==="kComp"){
            newSearchs["kCompNm"] = value==="EHMC"?"현대자동차(주)":"기아자동차(주)";
            newSearchs["kDocd"] = "";
            setData({ ...newSearchs });

            //공통코드 조회 (문서코드)
            onSearchCommon(`{"pgmid":"DOC_ERPC4","Key1":"*", "kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "docdList");
        }


        onSearch()
    }
 
  
  
    /**
     * Grid Event 
     * @param {*} e 
     */
    const grdRntc11Main_onCellValueChanged = async (e) => {
    }
    const grdRntc11Main_onRowClicked = async (e) => {
    }


  
  
    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //T1업체
            {
                "formatter": "select", "name": "kVnt1", "labelName": "1차업체", "type": "text", "defaultValue": idata.kVnt1,  
                "codeMode": true,
                "selectAll": "",
                "codes": null,
                "comms": idata.vnt1List,
                "align": "left", 
            }, 
            //회사구분,
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": false,
                "selectAll": "",
                "codes": CODES["comp"],
                "comms": null,
                "align": "left", 
            }, 
            //문서코드
            {
                "formatter": "select", "name": "kDocd", "labelName": "문서코드", "type": "text", "defaultValue": idata.kDocd,  
                "codeMode": false,
                "selectAll": false,
                "codes": [{comCode: "", text: "문서 전체"}],
                "comms": idata.docdList,
                "align": "right", 
            }, 
             //전체건수
             {
                "formatter": "input", "name": "kTotcnt", "labelName": "전체건수", "type": "text", "defaultValue": idata.kTotcnt, "readOnly" : true,
                "codeMode": false,
                "selectAll": "none",
                "codes": null,
                "comms": "",
                "align": "right", 
            }, 
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
            
        ],
        
    }
  
  
  
    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {
        
        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);

        
        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kDocd":"${idata.kDocd}" }`;
        
        let param = encodeURI(para);

        const query = SERVER_URL + "/rntc11/search?query=" + param;
        setSearchApi(query); // Search API
    }

    /**
    * 파일생성
    * @param {*} e 
    */
   const onUpload = async (e) => {
        let newSearchs = idata; 

        newSearchs["vnt1"] = idata.kWrcVand;
        setData({ ...newSearchs });

        setOpenUpload(true);
    }

    const handleUploadClickOpen = () => {

    setOpenUpload(true);
    };

    const handleUploadClose = () => {
    setOpenUpload(false);
    //onSearchDocd();
    };
  
    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
    }

  
      
    /**
     * 입력 (발행)
     * @param {*} e 
     */
    const onInsert = async (e) => {
    }
  
  
  
    /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {
    }


  
    /**
     * 삭제
     * @param {*} e 
     */
    const onDelete = async (e) => {
    }



    /**
     * 엑셀
     * @param {*} e 
     */
     const onExcel = async (e) => {
        const page = "ERPC4"
        let para = `{"kDocument":"C4 사양차이 정산 상세내역",
                     "kGubn":"${page}","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kDocd":"${idata.kDocd}",
                     "vnt1Nm":"${idata.kVnt1Nm}","compNm":"${idata.kCompNm}","docdNm":"${idata.kDocd===""?"전체":idata.kDocd}","kTotcnt": "${idata.kTotcnt}"}`;
        
        let param = encodeURIComponent(para);
        const query = SERVER_URL + "/rntc/excel?query=" + param;
        //엑셀파일 조회 시 반응
        setLoading(true);
        await fetch(query, {headers: {Authorization: token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'ERPC4.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })
        setLoading(false);

        // 엑셀파일 생성 메세지
        setMessage({ openAlert : true, 
                     severity : "success",
                     msg : "엑셀파일이 생성되었습니다."});
    }


    
    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});


        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans) 
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D" :
                break;

            case "D3" :
                break;
        }
    }
    
    

        //조회건수
        const onSetCount = () => {
            //전체건수 Setting
            let newSearchs = idata; 
            newSearchs["kTotcnt"] = (grdRntc11Main.current.getRowCount()).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            setData({ ...newSearchs });
        }
        

    /**
     * Grid Bind Data
     * @returns 
    */
    const onGridBindData = (para) => {
    }
 
     
  
    /**
     *Grid Column 정의 
    */
    //Main Grid Column
    // ag-grid header :: Table Header with Multiple Rows  기능 추가
    const columnDefs = [  
        { headerName: "분석기간(월)", field: "col04", sortable: true, filter: true, flex: 1, minWidth: 110, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.monthFormatter, hide: false },
        { headerName: "생산모듈코드", field: "col05", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "하위품목업체", field: "col06", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "자재 번호", field: "col07", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false  },
        { headerName: "사급\n구분", field: "col08",  sortable: true, filter: true, flex: 1, minWidth: 50, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "단가 기준\n(내수 입고량)", field: "col09", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false},
        { headerName: "구성 기준\n(내수 입고량)", field: "col10", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false}, 
        { headerName: "단가 기준\n(수출 입고량)", field: "col11", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false},
        { headerName: "구성 기준\n(수출 입고량)", field: "col12", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false}, 
        { headerName: "내수\n차이수량", field: "col13", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false },
        { headerName: "수출\n차이수량", field: "col14", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatterFixed3, hide: false },
        { headerName: "SUB\n품목단가", field: "col15", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatterFixed2, hide: false },
        { headerName: "내수\n차이금액", field: "col16", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatterFixed2, hide: false },
        { headerName: "수출\n차이금액", field: "col17", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatterFixed2, hide: false }
    ];
   
    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 55px  - 50px)"
              });
        }
    }
    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} />
            
            {/* Upload FRAME */}
            <UploadModal
                open={openUpload}
                onClose={handleUploadClose} 
                onClickOpen={handleUploadClickOpen}
                title={"CSV 업로드"}
                fileId={"ERPC4"}
                filePath={"C:\\HKC\\VAN\\ERPC4.CSV"}
                uploadInfo={uploadInfo}
                accp=".csv"
                maxFiles={1}
                validatorName={"csv"}
                uploadApi={SERVER_URL + "/erp/upload1"}
                data={idata.vnt1List}
            />
            {/* Loading */}
            <Loading loading={loading} />

            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onSearch={onSearch} onSearchName={null} //조회
                //onExcel={onExcel} onExcelName ={null}   //Excel
                onUpload={onUpload} onUploadName={"생성"}//생성  
                onExcel={onExcel} onExcelName ={null}   //Excel
            >
                {"수신자료관리 > ERP_모듈정보조회 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>사양차이 정산 상세내역 [ C4 ]</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick={onClick} />


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:55px */}
                <div className={"grid_type01"} > {/* 상단 FRAME */}
                    <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                        <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                            <Grid
                                ref={grdRntc11Main}
                                gridId={"RNTC11"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                pagination={true}                  //  쪽수 매기기 여부
                                paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"500"}         //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={true}      // cell selection drag mode
                                isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                onClearApi={()=> {onSetCount();onClearApi()}}
                                onCellValueChangedEvent={grdRntc11Main_onCellValueChanged}
                                onRowClickEvent={grdRntc11Main_onRowClicked}
                            />
                        </div>
                    </div>
                </div>
            </Content>
        </>
    )
  }
  export default RNTC11;