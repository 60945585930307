/**
 * @Page ROM3W
 * @Description [ROM3W]납입지시 - 현황관리 - CKD 미납현황조회 
 **/
import AlertDialog from "@/components/material-UI/AlertDialog";
import CustomAlert from "@/components/molecules/CustomAlert";
import Ribbon from "@/components/organisms/Ribbon";
import ContentXearch from "@/components/organisms/ContentXearch";
import Content from "@/components/organisms/Content";
import Grid from "@/components/grid/agGrid";
import React, {useCallback, useEffect, useRef, useState} from "react";
import * as Common from "@/utils/Common";
import CODES from "@/config/commonCode.json";
import CommonUtil from "@/utils/CommonUtil";
import Loading from "src/components/atoms/CustomLoading2";


const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const Utils = new CommonUtil();

function ROM3W() {
    const token = Common.getToken();
    const userId = Common.getToken("id");
    // const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const grdRom3YMain = useRef();   //Grid Main

    const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
    let [loading, setLoading] = useState(false);

    //Data
    const [idata, setData] = useState({
        //조회조건
        kGubn: `(+)`,
        kOrder: "A",
        kSort: "PTNO",
        kDlno: "",
        kFndt: "",
        kTndt: "",
        // kVnt1 : tVnt1,      //T1업체
        kVnt1 : ckVnt1,        //T1업체     
        kComp: tComp,       //회사구분
        kPlnt: "",          //공장
        kGate: "",          //하치장
        kPldt: Common.getToday(),  //소요일자
        kPtno : "",         //품번
        kFidt_f: Common.getToday(),   //시작일
        kTelno: "",         //연락처
        kFidt_t: Common.getToday(),   //종료일
        kUsyn: "*",
        kInfo_vand: "",
        kVnt1Nm : "",        //T1업체명
        kCompNm : tComp==="EHMC"?"현대자동차(주)":"기아자동차(주)",        //회사명

        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장
        gateList : [],  //하치장
        canoList : [],  //차량번호
        compList : [],  //회사
    });


    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;

    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      // Dialog Transaction(I,U,D)
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;

    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 

    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 100px  - 50px)"});

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
    }, []);


    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success)
                    {
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }

    const onSearchCommonAsync = async (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        if(data.data.length>0){
                            commonData[name] = data.data[0].text;
                        }
                        
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name); 
    }



    /**
     *MOUNT
     */
    useEffect( () => {
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
        //공통코드 조회 (공장)
        onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"CKD"}`, "plntList");
        //공통코드 조회 (회사)
        onSearchCommon(`{"pgmid":"COMP"}`, "compList");
        // 하치장 조회
        onSearchCommonAsync(`{"pgmid":"WRCVAND","kVnt1":"${idata.kVnt1}"}`, "kVnt1Nm");
    }, []);



    /**
     * Control 변경 Event
     * @param {*} e
     */
    const onChange = async (e) => {
        const {name, value, desc} = e.target;

        let newSearchs = idata;
        newSearchs[name] = value;
        newSearchs[name+"Nm"] = desc;
        setData({ ...newSearchs });

        //회사 변경 시
        if(name === "kComp"){

            newSearchs["kPlnt"] = "";
            newSearchs["kPlntNm"] = "";
            newSearchs["kGate"] = "";
            setData({ ...newSearchs });
            
            onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"CKD"}`, "plntList");
        }
        // 공장 변경 시
        else if (name === "kPlnt") {
            newSearchs["kGate"] = "";
            setData({ ...newSearchs });

            onSearchCommon(`{"pgmid":"KGATE","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", "kFidt_f":"${idata.kFidt_f}","kFidt_t":"${idata.kFidt_t}"}`, "gateList")
        }        

    }

    const grdRom3YMain_onRowClicked = async (e) => {
    }

    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //T1업체
            {
                formatter: "select", name: "kVnt1", labelName: "T1업체", type: "text", defaultValue: idata.kVnt1,  
                codeMode: true,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: idata.vnt1List, 
                align: "left", 
            },
            //회사구분
            {
                formatter: "select", name: "kComp", labelName: "회사구분", type: "text", defaultValue: idata.kComp,  
                codeMode: true,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: CODES["comp"],
                comms: null,
                align: "left", 
            },
            // 품번
            {
                formatter: "input", name: "kPtno", type: "text", labelName: "품번"
                , defaultValue: idata.kPtno
                // , defaultValue2: idata.kSthh  
                , align: "left" // (우측 정렬)
                , labelType: "none" 
            },
            // 납기일자 시작
            {
                formatter: "input", name: "kFidt_f", type: "date", labelName: "납기일자"
                , defaultValue: idata.kFidt_f
                // , defaultValue2: idata.kSthh  
                , align: "left" // (우측 정렬)
                , labelType: "none" 
            },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
        ],
        1: [
            // 공장
            {
                formatter: "select", name: "kPlnt", labelName: "공장", type: "text", defaultValue: idata.kPlnt,  
                codeMode: false,
                selectAll: "none", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: idata.plntList,
                align: "left", 
            },
            // 하치장
            {
                formatter: "select", name: "kGate", labelName: "하치장", type: "text", defaultValue: idata.kGate,  
                codeMode: false,
                selectAll: "all", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: idata.gateList,
                align: "left", 
            },
            // 정렬순서
            {
                formatter: "radio", name: "kSort", labelName: "정렬순서", 
                options: [{name: "kSort", setValue: "PTNO", labelName: "품번"}, {name: "kSort1", setValue: "NIDT", labelName: "납기일"}],
                defaultValue: idata.kSort,  
                align: "left", 
               labelType: "none", 
            },                
            // 납기일자 종료
            {
                formatter: "input", name: "kFidt_t", type: "date", labelName: "~"
                , defaultValue: idata.kFidt_t
                // , defaultValue2: idata.kSthh  
                , align: "left", // (우측 정렬)
                
                "labelType": "insert1" 
            },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
        ]
    }



    /**
     * 조회
     * @param {*} e
     * @returns
     */
    const onSearch = async (e) => {
        
        //공장선택 Check
        if (idata.kPlnt == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "공장을 선택하십시요 !"});
            document.getElementById("kPlnt").focus();                         
            return ;
        }

        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);

        let para = `{
                        "vnt1": "${idata.kVnt1}", "gubn": "${idata.kGubn}", "gate": "${idata.kGate}",
                        "comp": "${idata.kComp}", "plnt": "${idata.kPlnt}", "ptno": "${idata.kPtno}",
                        "nidt_f": "${idata.kFidt_f.replaceAll('-','')}",
                        "nidt_t": "${idata.kFidt_t.replaceAll('-','')}",
                        "sort": "${idata.kSort}", "usyn": "${idata.kUsyn}", "info_vand": "${idata.kInfo_vand}"
                     }`;

        let param = encodeURI(para);

        const query = SERVER_URL + "/rom3w/search?query=" + param;
        setSearchApi(query); // Search API
    }



    /**
     * 신규 Row추가
     * @param {*} e
     */
    const onAddRow = async (e) => {
    }



    /**
     * 입력 (발행)
     * @param {*} e
     */
    const onInsert = async (e) => {

    }



    /**
     * 수정
     * @param {*} e
     * @returns
     */
    const onUpdate = async (e) => {
    }

    /**
     * 삭제
     * @param {*} e
     */
    const onDelete = async (e) => {
    }

    /**
     * 엑셀
     * @param {*} e
     */
    const onExcel = async (e) => {
        //공장선택 Check
        if (idata.kPlnt == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "공장을 선택하십시요 !"});
            document.getElementById("kPlnt").focus();                         
            return ;
        }

        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);

        let para = `{
                        "vnt1": "${idata.kVnt1}", "gubn": "${idata.kGubn}", "gate": "${idata.kGate}",
                        "comp": "${idata.kComp}", "plnt": "${idata.kPlnt}", "ptno": "${idata.kPtno}",
                        "nidt_f": "${idata.kFidt_f.replaceAll('-','')}",
                        "nidt_t": "${idata.kFidt_t.replaceAll('-','')}",
                        "sort": "${idata.kSort}", "usyn": "${idata.kUsyn}", "info_vand": "${idata.kInfo_vand}",
                        "vnt1Nm":"${idata.kVnt1Nm}","compNm":"${idata.kCompNm}","plntNm":"${idata.kPlntNm}","gateNm":"${idata.kGate===""?"전체":idata.kGate}"
                     }`;

        let param = encodeURI(para);

        const query = SERVER_URL + "/rom3w/excel?query=" + param;

        //엑셀파일 조회 시 로딩오픈
        setLoading(true);
        // setLoading(false);

        await fetch(query, {headers: {Authorization: token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'rom3w.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })

        //엑셀파일 조회 시 로딩 닫기
        // setLoading(true);
        setLoading(false);

        // 엑셀파일 생성 메세지
        setMessage({ openAlert : true, 
                     severity : "success",
                     msg : "엑셀파일이 생성되었습니다."});
    }


    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans)
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D2" :
                break;

            case "D3" :
                break;
        }
    }


    /**
     *Grid Column 정의
     */
        //Main Grid Column
    const mainColumnDefs = [
            { headerName: "하치장", field: "ctlt", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "납기일자", field: "nidt", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,valueFormatter: Utils.dateFormatter },
            { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
            { headerName: "P/D No", field: "pdno", sortable: true, filter: true, flex: 1, minWidth: 200, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "오더번호", field: "seld", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "오더수량", field: "plqt", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true,valueFormatter: Utils.numberFormatter, hide: false },
            { headerName: "잔량", field: "jaqt", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true,valueFormatter: Utils.numberFormatter, hide: false },
            { headerName: "포장배치", field: "chpk", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "F-Order", field: "frod", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "T_Order", field: "tood", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "Case", field: "caseno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
    ];

    const onClick = async (e) => {
        setPanded(!panded);


        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 100px - 10px)"
                });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 100px  - 50px)"
                });
        }
    }
    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} />

            {/* Loading */}
            <Loading loading={loading} /> 

            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onSearch={onSearch} onSearchName={null}     //조회
                onExcel={onExcel} onExcelName={null} //Excel
            >
                {"납입지시 > 현황관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>CKD 미납현황조회</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick={onClick} />


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:100px */}
                <div className={"grid_type01"}> {/* 상단 FRAME */}
                    <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                        <Grid
                            ref={grdRom3YMain}
                            gridId={"ROM3W"}                    //  (필수) Grid Id 정의
                            columnDefs={mainColumnDefs}             //  (필수) 컬럼 정의
                            pagination={true}                  //  쪽수 매기기 여부
                            paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                            paginationPageSize={"500"}         //  쪽수 1Page 당 Row수
                            rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                            suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                            rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                            enableRangeSelection={false}        //
                            enableCellTextSelection={true}      // cell selection drag mode
                            isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                            displayNo = {false}
                            searchApi={searchApi}               // 조회
                            // insertApi={insertApi}               // 등록
                            // insertParam={inserts}               // 등록 및 수정시 Parmeter
                            // insertRefresh={()=> {onSearch(); onSearch2();} } // 등록이후
                            onClearApi={onClearApi}             // 초기화
                            // onCellValueChangedEvent={grdRom3YMain_onCellValueChanged}
                            onRowClickEvent={grdRom3YMain_onRowClicked}
                            //updateApi={updateApi}               // 수정
                            //deleteApi={deleteApi}               // 삭제
                            //updateRefresh={onSearch}            // 수정이후
                            //deleteRefresh={onSearch}            // 삭제이후
                        />
                    </div>
                </div>
            </Content>

        </>
    )
}
export default ROM3W;