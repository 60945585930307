/**
 * @Page REX3H / REX3I
 * @Description [REX3H/REX3I]납입지시 - ERP_OEM출하관리 - 납품서일괄발행(VMI)
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import GridRibbon from "@/components/organisms/GridRibbon";
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import * as GridUtil from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import Popup from '@/pages/Popup/Popup';    
 import CommonUtil from '@/utils/CommonUtil'; 
 import ReactToPrint from "react-to-print";
 import Loading from "src/components/atoms/CustomLoading2"; 
 import VmiCardPrint from "@/components/molecules/VmiCardPrint";
 
  
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();

 
 // Print Style CSS Setting
const pageStyle = `
@media all {
    .pagebreak {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .pagebreak {
      margin-top:1rem;
      display: block;
      page-break-after: always;
    }
  }
  
  @page {
    size: A4 portrait !important;
    margin:7mm 10mm;
  }
  body, div, table, td{
      font-family:굴림체,Gulim ;
  }

`;




 function REX3I() {
 
    const token = Common.getToken();
    const userId = Common.getToken("id");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const bigi = Common.getToken("bigi");
    const grdRex3hMain = useRef();   //Grid Main
    const grdRex3hList = useRef();   //납품서 발행현황 Grid
    const grdRex3hDetail= useRef();  //납품서 상세현황 Grid
    const printRef = useRef();
    const printBtnRef = useRef();

    const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
    const ckName = Common.getCookie("ck_ename")===null?"":Common.getCookie("ck_ename"); 
    const ckTelno = Common.getCookie("ck_telno")===null?"":Common.getCookie("ck_telno"); 

    let [loading, setLoading] = useState(false); 
    
    //Data
    const [idata, setData] = useState({
        //조회조건
        // kVnt1 : tVnt1,      //T1업체
        kVnt1 : ckVnt1,        //T1업체     
        kComp: tComp,       //회사구분
        kName: ckName,          //담당자    
        kTelno: ckTelno,         //연락처   
        kChk:"",       //FAIL 체크
        kChk2:"",       //FAIL 체크

        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장
        gateList : [],  //하치장
        canoList : [],  //차량번호
    }); 
          
    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;
  
  
    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      // Dialog Transaction(I,U,D)
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
    const [typeOpen, setTypeOpen] = React.useState(false);
   
    // Params (Insert, Update Parameter)
    let [inserts, setInsert] = useState({}); 
    let [datainserts, setDataInsert] = useState({datainserts:[]}); 

    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    let [insertApi, setInsertApi] = useState(false); 
    let [updateApi, setUpdateApi] = useState(false); 
    let [deleteApi, setDeleteApi] = useState(false); // deleteApi 항목 setting
    let [searchApi2, setSearchApi2] = useState(false); 
    let [deleteApi2, setDeleteApi2] = useState(false); 
    let [searchApi3, setSearchApi3] = useState(false); 
    let [updateApi3, setUpdateApi3] = useState(false); 
    let [deleteApi3, setDeleteApi3] = useState(false); 
    let [printdata, setPrintData] = useState([]);   
    let [pos, setPos] = useState([]); //납품서 좌표 

    let [clearGrid3, setClearGrid3] = useState(false); 

    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 101px  - 50px)"});

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
        setInsertApi(false);
        setUpdateApi(false);
        setDeleteApi(false);
    }, []);

    const onClearApi2 = useCallback(async (e) => {
        setSearchApi2(false);
        setDeleteApi2(false);
    }, []);

    const onClearApi3 = useCallback(async (e) => {
        setSearchApi3(false);
        setUpdateApi3(false);
        setDeleteApi3(false);
    }, []);




    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }
     

    
    /**
     *MOUNT 
    */
    useEffect( () => {
        
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");

        //납품서 발행현황조회
        onSearch2();
    }, []);
  
  
  
    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value} = e.target;

        let newSearchs = idata; 
        newSearchs[name] = value
        setData({ ...newSearchs })
    }
 
  
  
    /**
     * Grid Event 
     * @param {*} e 
     */
    const grdRex3hMain_onCellValueChanged = async (e) => {

        let data = e.data;
        if (data.npqt === "0" || data.npqt === "") {
            e.node.setSelected(false);
        }
    }
    const grdRex3hMain_onRowClicked = async (e) => {        
        
    }

    //납품서 발행현황 CellValueChanged
    const grdRex3hList_onCellValueChanged = async (e) => {

    }
    //납품서 상세현황 CellValueChanged
    const grdRex3hDetail_onCellValueChanged = async (e) => {
    }

    //남품서 발행현황 Row Click
    const grdRex3hList_onRowClicked = async (e) => {

        let dlno = e.data.dlno;
        if (dlno != null || dlno != "") 
        {
            //납품서 상세현황 조회
            onSearch3(dlno);
        }
    }

  
  
    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //T1업체
            {
                "formatter": "select", "name": "kVnt1", "labelName": "T1업체", "type": "text", "defaultValue": idata.kVnt1,  
                "codeMode": true,
                "selectAll": "",
                "codes": null,
                "comms": idata.vnt1List,
                "align": "left", 
            }, 
            //회사구분
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": true,
                "selectAll": "",
                "codes": CODES["comp"],
                "comms": null,
                "align": "left", 
            }, 
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            //담당자
            {
                "formatter": "input", "name": "kName", "labelName": "담당자", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kName,  
                "align": "right", 
                "labelType": "insert", 
            }, 
        ],
        1: [
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            //연락처
            {
                "formatter": "input", "name": "kTelno", "labelName": "연락처", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kTelno,  
                "align": "right", 
                "labelType": "insert", 
            },  
        ]
    }
  
  
  
    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {
        
        //T1업체선택 Check
        if (idata.kVnt1 == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "1차업체를 선택하십시요 !"});
            document.getElementById("kVnt1").focus();                         
            return ;
        }

        //회사구분선택 Check
        if (idata.kComp == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "회사구분을 선택하십시요 !"});
            document.getElementById("kComp").focus();                         
            return ;
        }

        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);


        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`;
        
        let param = encodeURI(para);
        const query = SERVER_URL + "/rex3h/search?query=" + param;

        const fetchData = async (query) => {
            

            await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) {

                        if (data.data.length === 0) {
                            setMessage("조회된 결과가 없습니다."); 
                        } else {
                            setMessage("정상적으로 조회되었습니다."); 
                        }

                        setSearchApi(query);
                        onRowColor(data.data);
                    
                    }
                    
                    if (data.error) {
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : "조회 실패"});
                    }
                })
                .catch((error) => {
                    if (error) {
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                    }
                });
        };
        fetchData(query);

        onSearch2();
    }

    /**
     * row 컬러 
     * @param {*} e 
     */
    const onRowColor = async (e) => {

        idata.kChk = "";
        idata.kChk2 = "";
        
        datainserts.datainserts =[];
        datainserts.length = 0;
        setDataInsert({ ...datainserts });


        if(e.length != 0){
            for(var i=0;i<e.length;i++){
                if(e[i].rst1 == "FAIL"){
                    idata.kChk = "N"
                    idata.kChk2 = "N"
                }else{
                    idata.kChk = ""
                }
            };

            for(var i=0;i<e.length;i++){
                datainserts.datainserts[i] = e[i];   
                datainserts.length = e.length;
            };

            //조회된 데이터 넣어주기(발행하기위해서)
            setDataInsert({ ...datainserts });
        }

    }
  
    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
   
    }

  
      
    /**
     * 입력 (발행)
     * @param {*} e 
     */ 
    const onInsert = async (e) => {
        if (idata.kVnt1 == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "1차업체를 선택하십시요 !"});
            document.getElementById("kVnt1").focus();                         
            return ;
        }

        //회사구분선택 Check
        if (idata.kComp == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "회사구분을 선택하십시요 !"});
            document.getElementById("kComp").focus();                         
            return ;
        }

        //datainsert =datainserts; 
       
        if(idata.kChk2 == "N"){
            setMessage({ openAlert : true, 
                severity : "info",
                msg : "생성 작업 중 실패건수가 있습니다. 자료 재생성 후 다시 발행해주십시오."});
            return ;
        }

        const fetchData = async () => {
            
            let newMap;
            newMap = datainserts.datainserts.map(function (data, i) {

                data.comp = idata.kComp;//회사
                data.pldt = (datainserts.datainserts[i].sttm).substring(0,8);//소요일자
                data.npdt = datainserts.datainserts[i].sttm;//출발시간
                data.dcdt = datainserts.datainserts[i].edtm;//도착시간

                let temp = data;

                Object.entries(inserts).forEach(function (disp, i) { // XearchBox Data

                    temp[disp[0]] = disp[1];
                });

                return { ...temp };

            }, this);


           if(datainserts.datainserts.length === 0){
                setMessage({ openAlert : true, 
                    severity : "error",
                    msg : "발행할 데이터가 없습니다."});
                return ;
           }

           const query = SERVER_URL + "/rex3h/insert"; 
            
            await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: JSON.stringify(newMap), })
                .then((resp) => resp.json())
                .then((data) => {

                    if (data.success) {
                        if (data.count === 0) { 
                            setMessage("등록된 데이터가 없습니다. "); 
                        } 
                        else {
                            setMessage({ openAlert : true, 
                                severity : "success",
                                msg : "정상적으로 등록되었습니다."});
        
                        }
                        setTimeout(() => onSearch2(), 300);
                    }
                    else if (data.errors) {
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : data.errors.message});
                    }
                    else if (data.error) {
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : data.message});
                    }
                })
                .catch((error) => {
                    // 에러 응답 처리
                    if (error) {
                        
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                    }
                });
        };
        fetchData(e);

    
    }
  
    const onInsert2 = async (e) => {
        //t2업체등록Popup Open
        setTypeOpen(true);
    }
  

    /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {
    }
  
    /**
     * 삭제
     * @param {*} e 
     */
    const onDelete = async (e) => {
    }



    /**
     * 납품서 발행현황 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch2= async (e) => {
        
        setClearGrid3(true); // grid 3 초기화

        //T1업체선택 Check
        if (idata.kVnt1 == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "1차업체를 선택하십시요 !"});
            document.getElementById("kVnt1").focus();                         
            return ;
        }

        //회사구분선택 Check
        if (idata.kComp == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "회사구분을 선택하십시요 !"});
            document.getElementById("kComp").focus();                         
            return ;
        }

        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/rex3h/search2?query=" + param;
        setSearchApi2(query); // Search API
    }


    /**
     * 남품서 발행현황 취소
     * @param {*} e 
     * @returns 
     */
    const onDelete2 = async (e) => {
        
        //select nodes
        let selectedData = grdRex3hList.current.getSelectedNodes();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "선택한 데이터가 없습니다."});
            return false;
        }

        //삭제 확인 메세지 
        setDlgMessage({openDlg : true,  
                       dlgTrans : "D2", 
                       dlgMsg : "선택한 데이터를 취소 하시겠습니까?" });
        
    }


    
    /**
     * 납품서 발행현황 인쇄 
     * @param {*} e 
     * @returns 
     */
    const onPrint = async (e) => {
        
        // 담당자 입력 Check
        if (idata.kName == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "담당자를 입력하십시요."});
            document.getElementById("kName").focus();                         
            return ;
        }

        // 연락처 입력 Check
        if (idata.kTelno == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "연락처를 입력하십시요."});
            document.getElementById("kTelno").focus();                         
            return ;
        }

        // Cookie 값 셋팅
        Common.setCookie("ck_ename",idata.kName,1);
        Common.setCookie("ck_telno",idata.kTelno,1);

        //select nodes
        let selectedData = grdRex3hList.current.getSelectedData();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "선택한 데이터가 없습니다."});
            return false;
        }

        const fetchData = async () => {
            setLoading(true); 

            let selectDlnoList = [];
            selectedData.map(function (data, i) {

                // selectedData2.forEach(function (data, i) {
                if (data.dlno) {
                    selectDlnoList.push(data.dlno);
                }
            });

            // 납품서발행(VMI ROM3H) , 납품서발행(PO ROM3L), 출하정보전송(ROM4P), 납품서일괄발행(VMI REX3H) - "/rom3h/chulha" 모두 여기 사용 !!!!
            const query = SERVER_URL + "/rom3h/chulha"; 
            let param = JSON.stringify({
                vnt1: idata.kVnt1,
                comp: idata.kComp,
                ename : idata.kName,
                telno : idata.kTelno,
                func: "CH", // CH : 출하전송 
                // dlnolist: ["D136RHV12200903009", "UO9FRHV15200701005"],
                rprint: 'N',
                dlnolist: selectDlnoList,
            });
            //  await fetch(query, { headers: { Authorization: token } })
            await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })
                .then((resp) => resp.json())
                .then((data) => {
                    let printList = {};
                    let ptlist = [];
                    let posList = {};
                    
                    if (data.success) {
                        setPrintData(data.data.list);

                        setMessage({ openAlert : true, 
                                     severity : "success",
                                     msg : "인쇄 조회 성공 하였습니다."});
                        //print 
                        for(let i=0; i<data.data.list.length;i++){
                            let detailPrintInfo = [];
                            let headPrintInfo2 = {};
                            let detailPrintInfo2 = {};
                            //헤더
                            headPrintInfo2={
                                cano:data.data.list[i].head.cano,
                                companyNm:data.data.list[i].head.conm,
                                issueNo:data.data.list[i].head.dlno,
                                vandcd:data.data.list[i].head.vnt1,
                                vandnm:data.data.list[i].head.vdnm,
                                plnm:data.data.list[i].head.plnm,
                                gate:data.data.list[i].head.gate,
                                deliveryDate:data.data.list[i].head.prif,
                                chek:data.data.list[i].head.chek,
                                posx:parseInt(data.data.list[i].head.posx),
                                posy:parseInt(data.data.list[i].head.posy),
                            }
                            for(let z = 0; z<data.data.list[i].detail.length;z++){
                                //디테일
                                detailPrintInfo2={
                                    cano: data.data.list[i].detail[z].CANO,
                                    companyNm:data.data.list[i].detail[z].CONM,
                                    issueNo: data.data.list[i].detail[z].DLNO,
                                    vandcd:data.data.list[i].detail[z].VNT1,
                                    vandnm:data.data.list[i].detail[z].VDNM,
                                    plnm:data.data.list[i].detail[z].PLNM,
                                    gate: data.data.list[i].detail[z].GATE,
                                    plnt: data.data.list[i].detail[z].PLNT,
                                    deliveryDate:data.data.list[i].detail[z].PRIF,
                                    case1:data.data.list[i].detail[z].CASE1,
                                    casec:data.data.list[i].detail[z].CASEC,
                                    dlno: data.data.list[i].detail[z].DLNO,
                                    dopt: data.data.list[i].detail[z].DOPT,
                                    itemNm: data.data.list[i].detail[z].PTNM,
                                    itemNo: data.data.list[i].detail[z].PTNO,
                                    prgb: data.data.list[i].detail[z].PRGB,
                                    crdx: data.data.list[i].detail[z].CRDX,
                                    odno: data.data.list[i].detail[z].ODNO,
                                    dono: data.data.list[i].detail[z].DONO,
                                    prcd: data.data.list[i].detail[z].PRCD!=" "?"["+data.data.list[i].detail[z].PRCD+"]":" ",
                                    qypc: data.data.list[i].detail[z].QYPC,
                                    seq: data.data.list[i].detail[z].DSEQ,
                                    npqt: data.data.list[i].detail[z].NPQT,
                                    sjgb: data.data.list[i].detail[z].SJGB,
                                    feed: data.data.list[i].detail[z].FEED,
                                    loct: data.data.list[i].detail[z].LOCT
                                }
                                detailPrintInfo.push(detailPrintInfo2)
                                //헤더정보를 다음 데이터의 헤더 정보를 불러옴(긴급건으로 임시 대응)
                                headPrintInfo2.cano=data.data.list[i].detail[z].CANO;
                                headPrintInfo2.companyNm=data.data.list[i].detail[z].CONM;
                                headPrintInfo2.issueNo=data.data.list[i].detail[z].DLNO;
                                headPrintInfo2.vandcd=data.data.list[i].detail[z].VNT1;
                                headPrintInfo2.vandnm=data.data.list[i].detail[z].VDNM;
                                headPrintInfo2.plnm=data.data.list[i].detail[z].PLNM;
                                headPrintInfo2.gate=data.data.list[i].detail[z].GATE;
                                headPrintInfo2.deliveryDate=data.data.list[i].detail[z].PRIF;
                            }
                            printList = {
                                headPrintInfo:headPrintInfo2,   
                                detailPrintInfo:detailPrintInfo
                            }
                            ptlist.push(printList);

                            posList={
                                posx: parseInt(data.data.list[i].head.posx),
                                posy: parseInt(data.data.list[i].head.posy)
                            }
                        }

                        setPos(posList);

                        if(data.data.list[0].head.bigi === "Y"){
                            onPrint2(ptlist);
                        }else{
                            printBtnRef.current.dispatchEvent(new Event("click", { bubbles: true }));
                        }

                        setTimeout(() => onSearch2(), 300);
                    }

                    if (data.error) {
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : data.data.message!=""||data.data.message !=undefined?data.data.message:"조회 실패"});
                        setTimeout(() => onSearch2(), 300);
                    }

                    if(data.success===false){
                        setMessage({ openAlert : true, 
                            severity : "error",
                            msg : data.data.message!=""||data.data.message !=undefined?data.data.message:"조회 실패"});
                        setTimeout(() => onSearch2(), 300);
                    }
                })
                .catch((error) => {
                    // 에러 응답 처리
                    if (error) {
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                    }
                });
                setLoading(false); 
        };

        fetchData();
    }



    const onPrint2 = async (printData) => {

        let ptdata = JSON.stringify(printData);
        let body = {
            "userCode":userId,
            "type":"vmicard",
            "json":ptdata.replaceAll('"','\''),
            "state":"REQ"
        };
        let data = JSON.stringify(body);

        // await fetch('http://rpa.bigibot.com:9400/system/print', { method: "POST", headers: {"X-AppKey" : "to1adn6pq_sw","Content-Type":"application/json"}, body: data })
        const query = SERVER_URL + "/bigi/print"; 
        await fetch(query, { method: "POST", headers: {"Content-Type":"application/json"}, body: data })
        .then((resp) => resp.json())
        .then(data => {
            let str = data.data;
            if(str.includes('bigi://vmicard') === true){
                window.location.href = data.data.replaceAll("\"","");
                // window.location.href = data.data;
                setMessage({ openAlert : true, 
                    severity : "success",
                    msg : "BIGI를 확인해주세요."});
            }else{
                setMessage({ openAlert : true, 
                    severity : "error",
                    msg : "BIGI 프로그램을 다시 시도해주세요."});
            }  
        })
        .catch(error => {
            if (error) {
                setMessage({ openAlert : true, 
                                severity : "error",
                                msg : "BIGI 프로그램을 재시작 후 다시 시도해주세요."});
            }
        });
        setTimeout(() => onSearch2(), 300);   
    }

    /**
     * 납품서 상세현황 조회 
     * @param {*} dlno 
     */
    const onSearch3= async (dlno) => {

        let para = `{"vnt1":"${idata.kVnt1}","dlno":"${dlno}"}`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/rex3h/search3?query=" + param;
        setSearchApi3(query); // Search API
    }

    /**
     * 납품서 상세현황 수정 
     * @param {*} e 
     */
    const onUpdate3 = async (e) => {
        let selectedData = grdRex3hDetail.current.getSelectedData();


        if (selectedData.length === 0) {

            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "수정할 데이터를 선택해 주세요."});
            return false;
        }

        const query = SERVER_URL + "/rex3h/update";
        setUpdateApi3(query); // Update API
    }


    /**
     * 납품서 상세현황 납품품목삭제
     * @param {*} e 
     */
    const onDelete3 = async (e) => {

         //select nodes
         let selectedData = grdRex3hDetail.current.getSelectedNodes();

         // 선택 값 체크
         if (selectedData.length === 0) {
             //Alert 창 OPEN
             setMessage({ openAlert : true, 
                          severity : "info",
                          msg : "선택한 데이터가 없습니다."});
             return false;
         }
 
         //삭제 확인 메세지 
         setDlgMessage({openDlg : true,  
                        dlgTrans : "D3", 
                        dlgMsg : "선택한 데이터를 삭제하시겠습니까?" });

    }

    
    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});

        

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans) 
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D2" :
                //const queryD2  = SERVER_URL + "/rex3h/delete";
                const fetchData = async () => {
                    let selectedData = grdRex3hList.current.getSelectedData();
                    let selectDlnoList = [];
    
                    selectedData.map(function (data, i) {
    
                        // selectedData2.forEach(function (data, i) {
                        if (data.dlno) {
                            selectDlnoList.push(data.dlno);
                        }

                    });
    
                    const queryD2  = SERVER_URL + "/rex3h/delete";
    
                    let param = JSON.stringify({
                        vnt1: idata.kVnt1,
                        comp: idata.kComp,
                        ename : idata.kName,
                        telno : idata.kTelno,
                        func: "CL", // CH : 출하전송 0
                        // dlnolist: ["D136RHV12200903009", "UO9FRHV15200701005"],
                        rprint: 'N',
                        dlnolist: selectDlnoList,
                        selectedData:selectedData
                    });
    
                    await fetch(queryD2, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })                        
                        .then((resp) => resp.json())
                        .then((data) => {
                            if (data.success) {
                                setMessage({ openAlert : true, 
                                            severity : "info",
                                            msg : data.data.message==""||data.data.message ==undefined?"삭제에 성공하였습니다.":data.data.message});
                                setTimeout(() => onSearch2(), 300);
                            }
                            if (data.success===false) {
                                setMessage({ openAlert : true, 
                                             severity : "error",
                                             msg : data.errors.message==""||data.errors.message ==undefined?"삭제에 실패하였습니다.":data.errors.message});
                                setTimeout(() => onSearch2(), 300);
                            }
                            if (data.error) {
                                setMessage({ openAlert : true, 
                                             severity : "error",
                                             msg : data.errors.message==""||data.errors.message ==undefined?"삭제에 실패하였습니다.":data.errors.message});
                                setTimeout(() => onSearch2(), 300);
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {
                                setMessage({ openAlert : true, 
                                    severity : "error",
                                    msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                };
                fetchData();
                setDeleteApi2(false); // Update API
                break;

            case "D3" :
                const queryD3 = SERVER_URL + "/rex3h/detail_delete";
                setDeleteApi3(queryD3); // Delete API
                break;
        }
    }
      
    /**
     *Grid Column 정의 
    */
    //Main Grid Column
    const columnDefs = [
        { headerName: "card(H)", field: "card", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true },  
       
        { headerName: "공장", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false
         ,cellStyle: params => {//cell글자색 변경
            if (params.data.rst1 === "FAIL") {return {color: "red"};}
            return null;
         }},
        { headerName: "하치장", field: "gate", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false
        ,cellStyle: params => {
            if (params.data.rst1 === "FAIL") {return {color: "red"};}
            return null;
         }},
        { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true,  valueGetter: Utils.partnoGetter, hide: false
        ,cellStyle: params => {
            if (params.data.rst1 === "FAIL") {return {color: "red"};}
            return null;
         }}, 
        { headerName: "수량", field: "npqt", sortable: true, filter: true, flex: 1, minWidth: 20, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false,cellStyle: params => {
            if (params.data.rst1 === "FAIL") {return {color: "red"};}
            return null;
         }}, 
        { headerName: "출발시간", field: "sttm", sortable: true, filter: true, flex: 1, minWidth: 50, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,cellStyle: params => {
            if (params.data.rst1 === "FAIL") {return {color: "red"};}
            return null;
         }}, 
        { headerName: "도착시간", field: "edtm", sortable: true, filter: true, flex: 1, minWidth: 50, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,cellStyle: params => {
            if (params.data.rst1 === "FAIL") {return {color: "red"};}
            return null;
         }},  
        { headerName: "차량번호", field: "cano", sortable: true, filter: true, flex: 1, minWidth: 50, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,cellStyle: params => {
            if (params.data.rst1 === "FAIL") {return {color: "red"};}
            return null;
         }},  
        { headerName: "발행가능여부", field: "rst1", sortable: true, filter: true, flex: 1, minWidth: 50, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,cellStyle: params => {
            if (params.data.rst1 === "FAIL") {return {color: "red"};}
            return null;
         }},  
        { headerName: "메세지", field: "rst2", sortable: true, filter: true, flex: 1, minWidth: 400, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false,cellStyle: params => {
            if (params.data.rst1 === "FAIL") {return {color: "red"};}
            return null;
         }},  
         { headerName: "개별여부", field: "pergubn", sortable: true, filter: true, flex: 1, minWidth: 50, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false
         , valueFormatter : params => {return params.data.pergubn === "1" ? "개별 (1)" :"묶음 (0)"}
         , cellStyle: params => {//cell글자색 변경
             if (params.data.rst1 === "FAIL") {return {color: "red"};}
             if (params.data.pergubn === "1") {return {color: "#0057f4"};} /* rgb(237, 93, 59) */
             return null;
          }
        }
        
    ];

     //납품서 발행 Grid Column
     const columnDefs1 = [
        { headerName: "vnt1(H)", field: "vnt1", sortable: true, filter: true, flex: 1, minWidth: 10, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "comp(H)", field: "comp", sortable: true, filter: true, flex: 1, minWidth: 10, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "공장", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.msg.chul == "N"){return {color : "red"};} return null;}},      
        { headerName: "하치장", field: "gate", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.msg.chul == "N"){return {color : "red"};} return null;}},      
        { headerName: "납품서번호", field: "dlno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.msg.chul == "N"){return {color : "red"};} return null;}},      
        { headerName: "납품일시", field: "npdt", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.msg.chul == "N"){return {color : "red"};} return null;}},      
        { headerName: "건수", field: "pcnt", sortable: true, filter: true, flex: 1, minWidth: 30, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.msg.chul == "N"){return {color : "red"};} return null;}},      
        { headerName: "출하전송", field: "chul", sortable: true, filter: true, flex: 1, minWidth: 30, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.msg.chul == "N"){return {color : "red"};} return null;}},    
        { headerName: "메시지", field: "msg", sortable: true, filter: true, flex: 1, minWidth: 340, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.msg.chul == "N"){return {color : "red"};} return null;}},      
    ];
    //납품서 상세현황 Grid Column
    const columnDefs2 = [
        { headerName: "vnt1(H)", field: "wrcvnt1", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "dlno(H)", field: "wrcdlno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "comp(H)", field: "wrccomp", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "plnt(H)", field: "wrcplnt", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "pldt(H)", field: "wrcpldt", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "순번", field: "wrcdseq", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "품번", field: "wrcptno", sortable: true, filter: true, flex: 1, minWidth: 190, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true,valueGetter: Utils.partnoGetter, hide: false },
        { headerName: "계약번호", field: "wrcdono", sortable: true, filter: true, flex: 1, minWidth: 190, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
        { headerName: "납품수량", field: "wrcnpqt", sortable: true, filter: true, flex: 1, minWidth: 110, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", valueFormatter: Utils.numberFormatter, singleClickEdit: true, hide: false }, 
        { headerName: "용기수", field: "wrcccnt", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false}, 
    ];
  
    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 101px  - 50px)"
              });
        }
    }
      
    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={5000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

            <Popup type="ExUpload" open={typeOpen} 
                                   onClose={ (e)=> {if( !e ||e === "T" ){ setTypeOpen(false);onSearch();}} }
            />

            {/* Loading */}
            <Loading loading={loading} />

            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onSearch={onSearch} onSearchName={null}     //조회
                onInsert={onInsert} onInsertName={"발행"}   //발행
                onFunction={onInsert2} onFunctionName={"파일생성 (EXCEL)"}   //파일생성
                onFunctionType={"upload"}                           //파일생성 버튼이미지명 호출
            >
                {"납입지시 > ERP_OEM 출하관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardMedium, NanumGothic, sans-serif" }}>납품서 일괄발행(VMI)</b>
            </Ribbon>

            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick = {onClick} />


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:146px */}
                <div className={"grid_type01"} style={{height:panded===true?"100%":"200%"}} > {/* 상단 FRAME */}
                    <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                        <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                            <Grid
                                ref={grdRex3hMain}
                                gridId={"REX3H"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                pagination={false}                  //  쪽수 매기기 여부
                                paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                                rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={true}      // cell selection drag mode
                                isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                insertApi={insertApi}               // 등록
                                insertParam={inserts}               // 등록 및 수정시 Parmeter
                                onClearApi={onClearApi}             // 초기화
                                onCellValueChangedEvent={grdRex3hMain_onCellValueChanged}
                                onRowClickEvent={grdRex3hMain_onRowClicked}
                            />
                        </div>
                    </div>
                </div>

                <div className="grid_type02">{/* 하단 FRAME */}
                    <div className="grid_wrap02" style={{ width:"70%", height:"100%"}}> {/* 하단 wrap 좌측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <GridRibbon title={"납품서 발행현황"}
                                onDelete={onDelete2} onDeleteName={"취소"}
                                onPrint={onPrint} onPrintName={"인쇄"} onPrintVisible = {tVnt1 !== "NRAP"}
                            >
                            </GridRibbon>
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                    {/* 납품서 발행현황 Grid */}
                                <Grid
                                    ref={grdRex3hList}
                                    gridId={"REX3H2"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs1}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={false}   //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    searchApi={searchApi2}              // 조회
                                    deleteApi={deleteApi2}              // 삭제
                                    deleteRefresh={onSearch2}           // 삭제이후 조회
                                    onClearApi={onClearApi2}            // Clear
                                    onRowClickEvent={grdRex3hList_onRowClicked}     
                                    onCellValueChangedEvent={grdRex3hList_onCellValueChanged}
                                    alarmAlert={'F'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid_wrap03"> {/* 하단 wrap 우측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <GridRibbon title={"납품서 상세현황"}
                                // onSearch={onSearch}
                                onUpdate={onUpdate3} onUpdateName={"수정"}
                                onDelete={onDelete3} onDeleteName={"납품목록 삭제"}
                            >
                            </GridRibbon>
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* 납품서 발행현황 Grid */}
                                <Grid
                                    ref={grdRex3hDetail}
                                    gridId={"REX3H3"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs2}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}                 // No 컴럼 Display
                                    searchApi={searchApi3}              // 조회
                                    onClearApi={onClearApi3}            // 초기화
                                    updateApi={updateApi3}              // 수정
                                    deleteApi={deleteApi3}              // 삭제
                                    onCellValueChangedEvent={grdRex3hDetail_onCellValueChanged}
                                    deleteRefresh={onSearch2}           // 삭제이후 
                                    clearGrid={clearGrid3}
                                    onClearGridFlag={setClearGrid3}
                                    
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Content>

            <div className="printArea">
                <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef} style={{ display: "none" }} />} pageStyle={pageStyle} content={() => printRef.current} />
                <VmiCardPrint ref={printRef} printData={printdata} pos = {pos} />
            </div>
        </>
    )
  }
  export default REX3I;