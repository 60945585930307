import React from 'react';
// import { Link, withRouter } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import '../../css/main.css';
// import sty from '../css/main.css';




function ButtonSet({ children,
    onSearch, onSearchName,
    onInsert, onInsertName,
    onUpdate, onUpdateName,
    onDelete, onDeleteName,
    onExcel, onExcelName,
    onPrint, onPrintName }) {
    // if (location.pathname === '/login') {
    //     return null;
    // }

    return (
        <>
            <div style={{
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "flex-end",
                alignItems: "center",
                marginRight: "15px"
            }}>
                {/* <button onclick="if(this.className!='btn')return; retrieve('','1')" className="btn" alt='조회'><img src="/images/button/btn_search.png" alt="아이콘" />조회</button>
                <button onclick="if(this.className!='btn')return;check_vand('IS')" className="btn" alt='입력' src=""><img src="/images/button/btn_insert.png" alt="아이콘" />입력</button>
                <button onclick="if(this.className!='btn')return;check_vand('UP')" className="btn" alt="수정" src=""><img src="/images/button/btn_update.png" alt="아이콘" />수정</button>
                <button onclick="if(this.className!='btn')return;check_vand('DL');" className="btn" alt='삭제' src=""><img src="/images/button/btn_delete.png" alt="아이콘" />삭제</button>
                <button onclick="if(this.className!='btn')return; changePLNT1();callExcel();" className="btn" alt='엑셀' src=""><img src="/images/button/btn_excel.png" alt="아이콘" />엑셀</button>&nbsp; */}

                {/* 조회 */}
                {onSearch ?
                    <button href="#" title="Search" onClick={onSearch}>
                        <img src="/images/button/btn_search.png" alt="Search" /><span>{onSearchName ? onSearchName : "조회"}</span>
                    </button>
                    : ""}

                {/* 입력 저장 */}
                {onInsert ?
                    <button href="#" title="Insert" onClick={onInsert}>
                        <img src="/images/button/btn_insert.png" alt="Insert" /><span>{onInsertName ? onInsertName : "입력"}</span>
                    </button>
                    : ""}

                {/* 수정 저장 */}
                {onUpdate ?
                    <button href="#" title="Update" onClick={onUpdate}>
                        <img src="/images/button/btn_update.png" alt="Update" /><span>{onUpdateName ? onUpdateName : "수정"}</span>
                    </button>
                    : ""}

                {/* 삭제 */}
                {onDelete ?
                    <button href="#" title="Delete" onClick={onDelete}>
                        <img src="/images/button/btn_delete.png" alt="Delete" /><span>{onDeleteName ? onDeleteName : "삭제"}</span>
                    </button>
                    : ""}

                {/* 엑셀 내보내기*/}
                {onExcel ?
                    <button href="#" title="Excel" onClick={onExcel}>
                        <img src="/images/button/btn_excel.png" alt="Excel" /><span>{onExcelName ? onExcelName : "엑셀"}</span>
                    </button>
                    : ""}

                {/* 출력 */}
                {onPrint ?
                    <button href="#" title="Print" onClick={onPrint}>
                        <img src="/images/button/btn_excel.png" alt="Excel" /><span>{onPrintName ? onPrintName : "출력"}</span>
                    </button>
                    : ""}
                {children}
            </div>






            {/* <button onclick="if(this.className!='btn')return; retrieve('','1')" className="btn" alt="조회"><img src="/images/button/btn_search.png" alt="아이콘" />조회</button> */}
            {/* <img src="/rapdos/images/button_ins.gif" alt="등록" className="button" onclick="if(this.className!='button')return;sendForm('MT');"> */}

            {/* <button href="#">
                    <img src="/images/button/btn_search.png" alt="Search" /><span>입력</span>
                </button>
                <button href="#">
                    <img src="/images/button/btn_insert.png" alt="Insert" /><span>입력</span>
                </button>
                <button href="#">
                    <img src="/images/button/btn_update.png" alt="Update" /><span>수정</span>
                </button>
                <button href="#">
                    <img src="/images/button/btn_delete.png" alt="Delete" /><span>삭제</span>
                </button>
                <button href="#">
                    <img src="/images/button/btn_excel.png" alt="Excel" /><span>엑셀</span>
                </button> */}

            {/* <button href="#">
                    <span className={"btn_search"}></span><span>조회</span>
                </button>
                <button href="#">
                    <span className={"btn_insert"}></span><span>입력</span>
                </button>
                <button href="#">
                    <span className={"btn_update"}></span><span>수정</span>
                </button>
                <button href="#">
                    <span className={"btn_delete"}></span><span>삭제</span>
                </button>
                <button href="#">
                    <span className={"btn_excel"}></span><span>엑셀</span>
                </button> */}


            {/* <button id="btn-search" href="#">
                    <img src="./images/button/btn_search.png"  style={{width:"20px",height:"20px"}} alt="Search Botton"/>
                    <span style={{display:"inline-block",paddingTop:"2px",paddingLeft:"4px"}}>조회</span>
                </button>
                <button id="btn-insert" href="#">
                    <img src="./images/button/btn_insert.png"  style={{width:"20px",height:"20px"}} alt="Insert Botton"/>
                    <span style={{display:"inline-block",paddingTop:"2px",paddingLeft:"4px"}}>입력</span>
                </button>
                <button id="btn-update" href="#">
                    <img src="./images/button/btn_update.png"  style={{width:"20px",height:"20px"}} alt="Update Botton"/>
                    <span style={{display:"inline-block",paddingTop:"2px",paddingLeft:"4px"}}>수정</span>            
                </button>
                <button id="btn-delete" href="#">
                    <img src="./images/button/btn_delete.png"  style={{width:"20px",height:"20px"}} alt="Delte Botton"/>
                    <span style={{display:"inline-block",paddingTop:"2px",paddingLeft:"4px"}}>삭제</span>                
                </button>
                <button id="btn-excel" href="#">
                    <img src="./images/button/btn_excel.png"  style={{width:"20px",height:"20px"}} alt="Excel Botton"/>
                    <span style={{display:"inline-block",paddingTop:"2px",paddingLeft:"4px"}}>엑셀</span>                
                </button> */}
        </>
    );
}
// export default withRouter(Header);
export default ButtonSet;