//시스템관리 - 시스템관리
import { Route, Switch } from 'react-router-dom';
import RSY50 from '@/pages/D01/RSY50';  //FAQ
import RSY51 from '@/pages/D01/RSY51';  //QNA
import RSY52 from '@/pages/D01/RSY52';  //FAQ
import GNGS from '@/pages/D01/GNGS';
function D01() {
    return ( 
        <Switch>
            <Route path='/D01/RSY50' component={RSY50} />
            <Route path='/D01/RSY51' component={RSY51} />
            <Route path='/D01/RSY52' component={RSY52} />
            <Route path='/D01/GNGS' component={GNGS} />
        </Switch>
    );
}

export default D01;