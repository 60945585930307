//납입지시 - 협력사재고관리
import { Route, Switch } from 'react-router-dom';
import ROM77 from "@/pages/A07/ROM77";
import ROM78 from "@/pages/A07/ROM78";
import ROM79 from "@/pages/A07/ROM79";
//import ROM3W from "@/pages/A05/ROM3W";



function A07() {
    return ( 
        <Switch>
            <Route path='/A07/ROM77' component={ROM77} />
            <Route path='/A07/ROM78' component={ROM78} />
            <Route path='/A07/ROM79' component={ROM79} />
        </Switch>
    );
}

export default A07;
