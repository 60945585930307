import React from "react";
import { Button, Dialog , DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import { red } from "@mui/material/colors";

const styles = theme => ({
    styledHeader: {
        background: 'black',
        '& h2': {
          color: 'white',
        }
    }
})


function AlertDialogCustom({open, trans, message, onClose, button, title, width, height,backColor, contentImage }) 
{
    const handleClose = (action) => {
        let e = {Trans:trans, result:action, key:action}
        onClose(e);
    };
      
    return(
        <Dialog
            open={open}
            onClose={()=> {handleClose("N")}}
            // fullScreen={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: {
                  maxWidth : "1000px",
                  backgroundColor : backColor ? backColor : "#F0F0F0",
                  width : width? width + "px": "1004px",
                  height : height ? height + "px" : "624px"
                },
              }}
            >
            {trans && trans!=="S"  ?
                <>
                    <DialogTitle id="alert-dialog-title" classes={{ root : "dialog_title_custom" }}>
                        {title?title:"시스템 팝업"}
                        <button 
                            style={{ float : "right", border: "0", backgroundColor: "transparent" }} 
                            onClick={()=> {handleClose("N")}}>
                                <img style={{margin: "auto", display: "block"}}  src="/images/button/close_btn_2.png"></img>
                        </button>                        
                    </DialogTitle>
                </>
                :
                <></>     
            }
            <DialogContent style={{padding:trans==="S"?"13px":""}}>
                {trans && trans!=="S"?
                <>
                    <div style={{backgroundColor : "white", marginTop : "23px", width :"940px", height :"460px", padding : "30px"}}>
                        <DialogContentText id="alert-dialog-description">
                            <p style={{fontSize : "22px", fontFamily :"PretendardMedium", color: "#010101", textAlign : "center" }} 
                                dangerouslySetInnerHTML={{__html: message?message:""}} ></p>
                            <br />
                            {contentImage && contentImage.imageInfo && contentImage.imageInfo.map((info,i)=>{
                                let style=info.style
                                //return <img style={{ style}}  src={info.path}></img>
                                return <img style={{ "margin" : "auto", "display": "block"}}  src={info.path}></img>
                            })}
                        </DialogContentText>
                    </div>
                </>
                :
                <>
                    <div style={{ width : "294px", height :"22px"}}>
                        <DialogContentText id="alert-dialog-description">
                            <p style={{fontSize : "17px", fontFamily :"PretendardMedium", color: "#010101", textAlign : "center" }} >
                                <img src="/images/caution.png"/> {message} </p>
                        </DialogContentText>
                    </div>
                </>
                }
            </DialogContent>


            {button && button.buttonMessage ? 
                <p style={{textAlign: "center" }} ><img src="/images/caution.png"></img> 
                    <span style={{fontSize: '19px', fontFamily: 'PretendardMedium' }}>
                    {button.buttonMessage}
                    </span>
                </p>
            :""}

            <DialogActions classes={{ root : "dialogAction_algc" }} style={{padding:trans==="S"?"0px":""}}>
                {trans !=="S"?
                <div style={{justifyContent : "center", margin: "15px"}}>
                    
                    <div>
                    {button && button.buttonInfo && button.buttonInfo.map((info,i)=>{
                        
                        return <Button style={{width : info.width ? info.width + "px" : ""}} className={info.className} variant="outlined" /* color="secondary" */ onClick={()=> {  handleClose(info.key) }} >
                            <span style={{fontSize : "18px", fontFamily :"PretendardMedium" }} >{info.name}</span>
                        </Button>
                      })}
                      </div>
                    &nbsp;
                </div>
                :""
                }
            </DialogActions>
        </Dialog>
    );   
}

export default AlertDialogCustom;