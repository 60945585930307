//수신자료관리 - ERP_생산자료조회
import { Route, Switch } from 'react-router-dom';
import REPPA from '@/pages/B03/REPPA';// BODY_SEQ_219
import REPP0 from '@/pages/B03/REPP0';// BODY투입계획_상세
import REPP2 from '@/pages/B03/REPP2';// BODY투입계획_종합
import REPP4 from '@/pages/B03/REPP4';// TRIM투입계획
import REPP6 from '@/pages/B03/REPP6';// 서열부품집계_상세
import REPP7 from '@/pages/B03/REPP7';// 서열부품집계_종합
import REPP8 from '@/pages/B03/REPP8';// 모듈부품집계_상세
import REPP9 from '@/pages/B03/REPP9';// 모듈부품집계_종합
import REPPC from '@/pages/B03/REPPC';// ALC_Ⅱ계획현황_상세
import REPPD from '@/pages/B03/REPPD';// ALC_Ⅱ계획현황_종합
import REPPI from '@/pages/B03/REPPI';// WIRE서열계_상세
import REPPJ from '@/pages/B03/REPPJ';// WIRE서열계_종합
import REPPS from '@/pages/B03/REPPS';// 

function B03() {
    return ( 
        <Switch>
            <Route path='/B03/REPPA' component={REPPA} />
            <Route path='/B03/REPP0' component={REPP0} />
            <Route path='/B03/REPP2' component={REPP2} />
            <Route path='/B03/REPP4' component={REPP4} />
            <Route path='/B03/REPP6' component={REPP6} />
            <Route path='/B03/REPP7' component={REPP7} />
            <Route path='/B03/REPP8' component={REPP8} />
            <Route path='/B03/REPP9' component={REPP9} />
            <Route path='/B03/REPPC' component={REPPC} />
            <Route path='/B03/REPPD' component={REPPD} />
            <Route path='/B03/REPPI' component={REPPI} />
            <Route path='/B03/REPPJ' component={REPPJ} />
            <Route path='/B03/REPPS' component={REPPS} />
        </Switch>
    );
}

export default B03;
