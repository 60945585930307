import React,{useEffect} from 'react';
import '../../css/main.css';
import { TextField } from '@material-ui/core';

function CustomSelect3({ //children, ref,
    id, name, id2, name2, id3, name3, 
    defaultValue, defaultValue2, defaultValue3,
    codes, comms, items, codeMode, 
    codes2, comms2, items2, codeMode2,
    codes3, comms3, items3, codeMode3, readonly3,
    selectAll, onChange }) {


    useEffect(() => {
        let temp = document.getElementById(id);
        if (temp.options && temp.options[temp.selectedIndex] && temp.options[temp.selectedIndex].text !== '') {
            let e = {
                target: {}
            };
            e.target["name"] = id;
            e.target["value"] = temp && temp.options && temp.options[temp.selectedIndex] ? temp.options[temp.selectedIndex].value : "";
            e.target["desc"] = temp.options[temp.selectedIndex].text;
            if (onChange) {
                onChange(e);
            }
        }
    }, [id,defaultValue]);
    
    const handleChange = (e) => { // 검색조건 변경시 이벤트 처리 - 검색조건
        const { name, value } = e.target;
        let event = {
            target: {}
        };
        event.target["name"] = name;
        event.target["value"] = value;
        event.target["desc"] = e.target[e.target.selectedIndex].text;

        if (onChange) {
            onChange(event);
        }
    };


    return (
        <>
            <div className={"h_LabelL"} style={{ width: "200px"}}>

                <TextField
                    //select1
                    id={id}
                    name={name}
                    className={"k_combo3"}
                    select

                    onChange={handleChange}
                    SelectProps={{
                        native: true,
                    }}
                    variant="outlined"
                    value={defaultValue}
                    style={{ float: "left", width: "55px",marginRight: "3px" }}
                >

                    {selectAll && selectAll === "none" ?
                        <option key={"selectOne" + id} value={""}>{""}</option>
                        : ""}

                    {selectAll && selectAll === "select" ?
                        <option key={"selectOne" + id} value={""}>{"선택"}</option>
                        : ""}

                    {(selectAll && selectAll === "all") || selectAll === true ?
                        <option key={"selectOne" + id} value={""}>{"전체"}</option>
                        : ""}

                    {/* Case 1 - "Code List" - codes :: codesList */}
                    {codes ? codes.map((option, index) => (
                        < option key={"codesList" + id + index} value={option.comCode}>
                            {codeMode ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""}

                    {/* Case 2 - comms :: Search Lis */}
                    {comms ? comms.map((option, index) => (
                        < option key={"commsList" + id + index} value={option.comCode}>
                            {codeMode ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""}
                    
                    {/* Case 3 - items :: Hard Coding List */}
                    {items ? items.map((option, index) => (
                        < option key={"itemsList" + id + index} value={option.comCode}>
                            {codeMode ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""}

                </TextField>

                <TextField
                    //select2
                    id={id2}
                    name={name2}
                    className={"k_combo3"}
                    select
                  
                    onChange={handleChange}
                    SelectProps={{
                        native: true,
                    }}
                    
                    variant="outlined"
                    value={defaultValue2}
                    style={{ float: "left", width: "80px",marginRight: "3px" }}
                >

                    {selectAll && selectAll === "none" ?
                        <option key={"selectOne" + id} value={""}>{""}</option>
                        : ""}

                    {selectAll && selectAll === "select" ?
                        <option key={"selectOne" + id} value={""}>{"선택"}</option>
                        : ""}

                    {(selectAll && selectAll === "all") || selectAll === true ?
                        <option key={"selectOne" + id} value={""}>{"전체"}</option>
                        : ""}

                    {/* Case 1 - "Code List" - codes :: codesList */}
                    {codes2 ? codes2.map((option, index) => (
                        < option key={"codes2List" + id + index} value={option.comCode}>
                            {codeMode2 ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""}

                    {/* Case 2 - comms :: Search Lis */}
                    {comms2 ? comms2.map((option, index) => (
                        < option key={"comms2List" + id + index} value={option.comCode}>
                            {codeMode2 ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""}
                    
                    {/* Case 3 - items :: Hard Coding List */}
                    {items2 ? items2.map((option, index) => (
                        < option key={"items2List" + id + index} value={option.comCode}>
                            {codeMode2 ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""}

                </TextField>

                <TextField
                    //select3
                    id={id3}
                    name={name3}
                    className={"k_combo3"+ (readonly3? " readonly":"")}
                    select
                    
                    onChange={handleChange}
                    SelectProps={{
                        native: true,
                    }}
                    
                    variant="outlined"
                    value={defaultValue3}
                    style={{ float: "left", width: "59px" }}
                    
                >

                    {selectAll && selectAll === "none" ?
                        <option key={"selectOne" + id} value={""}>{""}</option>
                        : ""}

                    {selectAll && selectAll === "select" ?
                        <option key={"selectOne" + id} value={""}>{"선택"}</option>
                        : ""}

                    {(selectAll && selectAll === "all") || selectAll === true ?
                        <option key={"selectOne" + id} value={""}>{"전체"}</option>
                        : ""}

                    {/* Case 1 - "Code List" - codes :: codesList */}
                    {codes3 ? codes3.map((option, index) => (
                        < option key={"codes3List" + id + index} value={option.comCode}>
                            {codeMode3 ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""}

                    {/* Case 2 - comms :: Search Lis */}
                    {comms3 ? comms3.map((option, index) => (
                        < option key={"comms3List" + id + index} value={option.comCode}>
                            {codeMode3 ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""}
                    
                    {/* Case 3 - items :: Hard Coding List */}
                    {items3 ? items3.map((option, index) => (
                        < option key={"items3List" + id + index} value={option.comCode}>
                            {codeMode3 ? option.comCode + " " : ""}{option.text}
                        </option>
                    )) : ""}

                </TextField>
            </div>
        </>
    );
}

export default CustomSelect3;