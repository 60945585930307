import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";

import '../css/login.css';
// require('../css/login.css');

import { Snackbar } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

// import axios from 'axios';
import * as common from '../utils/Common'; 

import AlertDialog from '@/components/material-UI/AlertDialog';
import { LocalDiningOutlined } from "@material-ui/icons";

import * as Common from '@/utils/Common'; 
import Popup from '@/pages/Popup/Popup';    

function SnackAlert(props) {
    //  return <MuiAlert elevation={6} variant="filled" {...props} />;
    return (
        <Alert elevation={6} {...props}>
            <AlertTitle>{props.severity}</AlertTitle>
            {props.children}
        </Alert>
    );
}

function formattedDate(date){
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();
    var hh = date.getHours();
  
    return [date.getFullYear(),
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd,
            (hh>9 ? '' : '0') + hh
           ].join('');
  }

function LoginPage() {

    // token 초기화 
    // sessionStorage.clear(); // ALL

    const sendLogout = () => { //로그아웃
        const token = Common.getToken();
        const url = process.env.REACT_APP_BACKEND_SERVER;
        const path= "/user/logout";
        const query = url + path;

        return fetch(query, {
            method: 'GET',
            headers: { Authorization: token  }
        })
    };

    const history = useHistory();

    const [search, setSearchs] = useState({
        user_id: "",
        user_password: ""

    });   //검색조건 setInputs 입력기능 및 최초입력값 셋팅 선언
    const { user_id, user_password } = search;   //검색조건 변수 선언

    const inputRef = React.useRef(null); // 아이디로 focus 이동을 위한 reference 선언
    const inputRef2 = React.useRef(null); // 패스워드로 focus 이동을 위한 reference 선언

    const [typeOpen2, setTypeOpen2] = React.useState(true);

    const onChange = (e) => { // InputBox 변경시 이벤트 처리 - 검색조건

        
        let str = e.target.value;
        let con = '';

        const pattern2 =  /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크
        const pattern3 = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자

        const { name, value } = e.target;

        //한글 + 특수문자 입력 방지
        if(pattern2.test(str)){
        // if(pattern2.test(str)){
            
            setOpenAlert(true);
            setSeverity("info");
            setMessage("한글은 입력되지 않습니다.");
            
            str = str.replace(pattern2,"");
            return;
        }
        if(name === "user_id"){
            if(pattern3.test(str)){
                setOpenAlert(true);
                setSeverity("info");
                setMessage("특수문자는 입력되지 않습니다.");
                
                str = str.replace(pattern2,"");
                return;
            }
        }
   

        //대문자 변환
  
        con =   str.split(' ').map(function (word, index) {
            // First character upper case else lower case
            return word
            }).join(' ');

        
        setSearchs({ ...search, [name]: con });

        if(name === "user_id"){
            if(value.length === 7){
                document.getElementById("user_password").focus();    
            }
            con =   str.split(' ').map(function (word, index) {
                // First character upper case else lower case
                return word.toUpperCase();
                }).join(' ');

            setSearchs({ ...search, [name]: con });
        }
    }

    // const [errors, setErrors] = useState('');

    const [openAlert, setOpenAlert] = React.useState(false);  
    const [message, setMessage] = React.useState("");     
    const [severity, setSeverity] = React.useState("success");         
    const handleCloseAlert = () => {  // Alert 창 CLOSE
        setOpenAlert(false);
    };

    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;


    //const onChangeError = (e) => { // 이슈 발생 시 이벤트 처리 - ERROR
    //     const { name, value } = e.target;
    //     setInputs({ ...errors, value });
    // }

    // const onChangeMessage = (msg) => { // 이슈 발생 시 이벤트 처리 - ERROR
    //     // const { name, value } = e.target;

    //     setErrors(msg);
    // }


    const onKeyPress = (e) => { // Enter 키보드 이벤트 처리
        if (e.key === 'Enter') {

            if (user_id !== "" & user_password !== "") {
                inputRef.current.focus(); // 아이디로 focus 이동
            }

            if (user_id === "" & user_password !== "") {
                inputRef.current.focus(); // 아이디로 focus 이동
            }

            if (user_id !== "" & user_password === "") {
                inputRef2.current.focus(); // 패스워드로 focus 이동
            }

            if (user_id !== "" & user_password !== "") {
                onLogin(); // 로그인 Function
            }
        }
    };

    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false, dlgTrans : dlgTrans, dlgMsg : dlgMsg});

        //DialogResult
        if (e.result == "N") return;

        onLogin("force");
    }

    // const onReset = () => { // 변수 입력값 초기화
    //     setInputs({
    //         user_id: '',
    //         user_password: ''
    //     })
    // };

    const onLogin = (e) => { // 로그인 Function

        // 입력 값 필수 체크
        if (search["user_id"] === "" & search["user_password"] === "") {
            // alert("아이디와 패스워드를 입력해주세요.");            
            setOpenAlert(true);         
            setSeverity("info");                
            setMessage("아이디와 패스워드를 입력해주세요.");      

            inputRef.current.focus(); // 아이디로 focus 이동
            return;
        }
        else if (search["user_id"] === "") {
            // alert("아이디를 입력해주세요.");
            setOpenAlert(true);         
            setSeverity("info");               
            setMessage("아이디를 입력해주세요.");      

            inputRef.current.focus(); // 아이디로 focus 이동
            return;
        }else if (search["user_password"] === "") {
            // alert("패스워드를 입력해주세요.");
            setOpenAlert(true);         
            setSeverity("info");                
            setMessage("패스워드를 입력해주세요.");      

            inputRef2.current.focus(); // 패스워드로 focus 이동         
            return;
        }
        else if (search["user_id"].length !== 7) {
            // alert("아이디 입력길이가 틀림");
            setOpenAlert(true);         
            setSeverity("error");               
            setMessage("ID 및 PW를 확인해주세요");      

            inputRef.current.focus(); // 아이디로 focus 이동
            return;
        }
        

        //사용자 밀어내기 적용
        const url = process.env.REACT_APP_BACKEND_SERVER;  
        const path=e==="force"? "/user/login":"/user/check";
        const query = url+path; 

        let param = common.JSONtoString(search);  // 검색조건 Parameter String Setting

        fetch(query, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Cache-Control' : 'no-cache'
            },
            body: param
        })
            .then((resp) => resp.json())
            //.then((data) => updateData(data)); // 그리드 데이터 업데이트 시 사용됨 (sample)
            .then((data) => {
                if (data.success) {
                    if(data.code===373){
                        //삭제 확인 메세지 
                        setDlgMessage({openDlg : true,  
                            dlgTrans : "로그인", 
                            dlgMsg : `동일한 아이디로 이미 로그인 되어 있습니다.
                              로그인을 계속하면 기존 사용자는 자동 로그아웃 처리 됩니다. 
                              계속하시겠습니까?` });
                    }else if(data.code === 403){
                        setOpenAlert(true);         
                        setSeverity("error");            
                        // setMessage("비밀번호가 맞지 않습니다.");      
                        setMessage(`사용권한이 존재하지 않습니다.관리자에게 문의해주세요.`);      
                    }else{
                        sessionStorage.setItem('token', data.data); // Token Insert
                        //history.push('/');
                        //로그인에서 메인화면으로 갈때 라우터 사용하지않고 화면 새로고침 유도-local cache회피용
                        window.location.href="./";
                    }
                }
                if (data.error) {
                    sessionStorage.setItem('token', null); // Token Init

                    // 실패 메시지 처리 필요
                    if (data.error === "Unauthorized") {
                        // 접근 권한이 없습니다.                        
                        // alert("비밀번호가 맞지 않습니다.");
                        setSearchs({ ...search, user_password: "" });

                        setOpenAlert(true);         
                        setSeverity("error");            
                        // setMessage("비밀번호가 맞지 않습니다.");      
                        setMessage("ID 및 PW가 틀립니다, 맞지 않습니다.");      

                        inputRef2.current.focus(); // 패스워드로 focus 이동     

                    } else if (data.error === "Internal Server Error") {
                        // 아이디가 존재하지 않습니다.
                        // alert(data.message);

                        setOpenAlert(true);         
                        setSeverity("error");            
                        setMessage("ID 및 PW가 틀립니다, 맞지 않습니다.");      

                        inputRef.current.focus(); // 아이디로 focus 이동

                    } else {
                        // alert(data.message);                        
                        setOpenAlert(true);         
                        setSeverity("error");            
                        setMessage(data.message);      
                    }

                }
            })
            .catch(error => { // 에러 응답 처리
                if(search["user_id"]==="LF24001"){
                    alert(error);
                }
                if (error) {
                    if(error && error.message && error.message.includes('Failed')){
                        setOpenAlert(true);         
                        setSeverity("error");            
                        setMessage("서버 상태를 확인해 주세요. 관리자에게 문의해주세요.");      
                    }else{
                        setOpenAlert(true);         
                        setSeverity("error");            
                        setMessage("아이디 패스워드를 확인해주세요.");      
                    }
                }
            });
    };

    //  //Data for Popup    
    //  const [data, setDatas] = useState([]);    

    //  const onSearchPopup = () => {                                        

    //     const today = new Date();
    //     var formatToday = formattedDate(today); // 오늘 날짜 포맷 : ex)2022102812 : yyyymmdd24hh

    //     const allKeys = Object.keys(localStorage);
    //     var keysLength = allKeys.length;
        
    //     for(var i = 0; i < keysLength; i++){ // localstorage에서 오늘보다 이전 날짜 제거
    //         if(localStorage.getItem(allKeys[i]) <= formatToday){             
    //             localStorage.removeItem(allKeys[i]);
    //         }
    //     }        
    //     if(localStorage.getItem("100") == null) {
    //         let data =  []     
    //         data["wrcseqn"] = "100" //팝업관리 번호로 체크
    //         data["fileId"] = "6d11ab94-d871-4cda-8eb9-a441309c57e8"; // /file/popup/ + fileId
    //         data["wrctype"] = "T"; // 팝업출력 타입 I: 이미지 , T: 텍스트 
    //         data["wrclink"] = "https://www.naver.com";  // 팝업 클릭시 이동할 페이지주소 
    //         data["wrcperiod"] = "W" // D: 하루/ W:일주일 동안 띄우지 않음
    //         // wrctype 이 "T"일때 출력될 문장            
    //         data["wrccont"] =   
    //         `
    //         랩도스 시스템 서버 점검 안내
    //         안녕하십니까.
            
    //         [1차] 일정: HMG 이슈로 VPN 장비 교체 (일정연기)
            
    //         [2차] 일정: 10/14(금) 22:00~23:00 약 1시간
    //         작업내용: IDC 내부 보안 시스템 업그레이드
            
    //         작업 진행동안 네트워크 및 서버가 임시 중단될 예정으로
    //         진행중인 업무는 해당 작업 전 발행/인쇄해 주시도록
    //         사전 참고 부탁드리겠습니다.
            
    //         더욱 편리하고 안정적인 서비스 제공을 위해
    //         최선을 다하겠습니다.
    //         감사합니다.
            
    //         (주)아이티엔제이`
    //         setDatas(data);                    
    //         setTypeOpen2(true);           
    //     }
    //     else{
    //         setTypeOpen2(false);
    //     }
    // }
        
    

    // useEffect(onSearchPopup, [])

    /**
      *MOUNT 
      */
      useEffect( () => {
        //로그아웃정보 서버로 전송
        sendLogout().then(()=>{sessionStorage.removeItem('token');});        
        // onSearchPopup();
        // 팝업알림 - 1차 : VPN 장비 교체 및 시스템 최적화,  2차 : IDC 내부 보안 시스템 업그레이드
        //setTimeout(() => onSearchPopup(), 300)

    }, []);

    
//     const onSearchPopup = () => { // 팝업알림 // 랩도스 시스템 서버 점검 안내 - 하드코딩..
        
//         alert(`랩도스 시스템 서버 점검 안내
// 안녕하십니까.

// [1차] 일정: HMG 이슈로 VPN 장비 교체 (일정연기)

// [2차] 일정: 10/14(금) 22:00~23:00 약 1시간
// 작업내용: IDC 내부 보안 시스템 업그레이드

// 작업 진행동안 네트워크 및 서버가 임시 중단될 예정으로
// 진행중인 업무는 해당 작업 전 발행/인쇄해 주시도록
// 사전 참고 부탁드리겠습니다.

// 더욱 편리하고 안정적인 서비스 제공을 위해
// 최선을 다하겠습니다.
// 감사합니다.

// (주)아이티엔제이`)
//     }


    return (
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            <div id="wrap" className={"wrap"}>

                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <SnackAlert onClose={handleCloseAlert} severity={severity}>
                        {message}
                    </SnackAlert>
                </Snackbar>

                <div id="Head">
                    {/* <meta name="viewport" content="width=devcie-width, initial-scale=1, user-scalable=no"/> */}
                    <link rel="shortcut icon" href="images/Rapdos_favicon.png" />
                    <title>Refresh Automobile Parts Delivery Order System on th Web</title>
                </div>
                {/* <!-- Login Box PART --> */}
                <section className={"login_section"}>
                    <img src="images/login/bg.png" style={{ height: "100%" ,width:"100%"}} alt="로그인화면" />
                    <div className={"login_box"}>

                        <div className={"login_left"}> {/* <!-- Login_left Img PART --> */}
                            <div style={{width: "100%",height: "280px",border: "0px", cursor:"default"}}>
                                <div style={{color: "#fff",width: "110px",height: "4%",borderBottom:"2px solid"}}></div>
                                <div className="logoFont">Rapdos</div>
                                <div className="midFont">자동차 부품산업에 최적화 된 <br />NO1. 납입대응 솔루션 랩도스</div>
                                <div className="engFont">
                                    <span className={"hd_p"}>R</span>efresh <span className={"hd_p"}>A</span>utomobile <span className={"hd_p"}>P</span>arts <span className={"hd_p"}>D</span>elivery <span className={"hd_p"}>O</span>rder <span className={"hd_p"}>S</span>ystem on the Web
                                </div>
                            </div>

                            <div style={{height:"20px"}}></div>

                            <div style={{width: "450px",height: "296px",border: "0px"}}>
                                <div className={"left"}>
                                    <a href="http://itnj.co.kr" target="_blank" >
                                        <div className="leftRadius">
                                            <img src="images/login/icon01.png" alt="로그인아이콘1"/>
                                        </div>
                                        <div className="rightRadius">
                                            <div className="linkFont">ITNJ 홈페이지</div>
                                        </div>
                                    </a>
                                </div>

                                <div className={"f_right"}  >
                                    <a href="http://helpu.kr/itnj/" target="_blank" >
                                        <div className="leftRadius">
                                            <img src="images/login/icon03.png" alt="로그인아이콘2"/>
                                        </div>
                                        <div className="rightRadius">
                                            <div className="linkFont">원격지원 요청</div>
                                        </div>
                                    </a>
                                </div>

                                <div style={{height:"75px"}}></div>

                                <div className={"left"}>
                                    <a href="http://njmro.co.kr/" target="_blank">
                                        <div className="leftRadius">
                                            <img src="images/login/icon02.png" alt="로그인아이콘3"/>
                                        </div>
                                        <div className="rightRadius">
                                            <div className="linkFont">소모품 주문</div>
                                        </div>
                                    </a>
                                </div>

                                <div className={"f_right"}>
                                    <a href="https://pf.kakao.com/_xadUMxb/chat" target="_blank">
                                    <div className="leftRadius">                                    
                                        <img src="images/login/icon04.png" alt="로그인아이콘4"/>
                                    </div>
                                    <div className="rightRadius">
                                        <div className="linkFont">카카오톡 상담하기</div>
                                    </div>
                                    </a>
                                </div>

                                <div style={{height:"75px"}}></div>

                                <div className={"left"} style={{ cursor: "pointer" }}>
                                    <a onClick = {() => { window.open("http://itnj.co.kr/rapdos.html",'랩도스가 처음이신가요?','toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=1300px, height=1200px,left=500px, top=500px','_blank')}} >
                                        <div className="leftRadius">                                    
                                            <img src="images/login/icon05.png" alt="로그인아이콘5"/>
                                        </div>
                                        <div className="rightRadius2" >
                                            <div className="linkFont">랩도스가 처음이신가요? &nbsp; <span style = {{fontSize : "15px", fontWeight : "600"}} >랩도스 소개/가입/데모신청</span></div>
                                        </div>
                                    </a>
                                </div>

                            </div>     
                            <div style={{color:"#b6b6b6", cursor:"default"}}>(주)아이티엔제이 울산광역시 남구 왕생로 99 5F</div>
                            <div style={{color:"#b6b6b6", cursor:"default"}}>TEL. 1688-0720  FAX. 052-298-4117 MAIL. itnj.inc@itnj.co.kr</div>
                        </div>

                        <div className={"login_form"} > {/* <!-- Login Form PART --> */}
                            <form id="loginForm">
                                <div className={"form_position"}>
                                    <p className={"form_txt"} style={{cursor:"default"}}>LOGIN</p>
                                    <div className={"form_align"}>
                                        {/* <p>
                                            <img src="images/login/ico_id.png" alt="아이디 아이콘" />
                                            {/* <img src={require('./images/login/ico_id.png')} alt="아이디 아이콘"/>
                                        </p> */}
                                        <input id="user_id" name="user_id" type="text" placeholder="아이디" ref={inputRef} onChange={onChange} value={user_id} onKeyPress={onKeyPress} autoFocus />
                                    </div>
                                    <div className={"form_align"}>
                                        {/* <p>
                                            <img src="images/login/ico_pw.png" style={{ width: "15px", height: "24px" }} alt="페스워드 아이콘" />
                                        </p> */}
                                        <input id="user_password" name="user_password" type="password" placeholder="패스워드 (대소문자를 구분해주세요)" ref={inputRef2} onChange={onChange} value={user_password} onKeyPress={onKeyPress} autoComplete="off" />
                                    </div>
                                    {/* <button type="button" name="login_button" onClick={onLoginTest}> */}
                                    {/* <Link href="/main">로그인</Link> */}
                                    <a href={"#!"} onClick={onLogin} >로그인</a>
                                    {/* </button> */}

                                </div>
                            </form>
                            
                        </div>
                        
                        
                    </div>
                    <div style={{color: "rgb(182, 182, 182)",position: "absolute",top: "92%",left: "67%", cursor:"default"}}>Copyright ⓒ 2022 ITNJ inc. ALL Rights Reserved</div> 
                </section>

                {/* <!-- Login Incorrect PART --> */}
                <div className={"white_content modalPop modal_small"} hidden id="open_search_pass01">
                    <div className={"white_content_wrap"}>
                        <div className={"top_header"}>
                            <p className={"header_l"}>로그인 실패</p>
                            <button className={"header_r"} type="button" id="close_alert">닫기</button>
                        </div>
                        <div className={"incorrect_message"}>
                            <p className={"inco01"}><img src="images/login/login_alert.png" alt="경고이미지" /></p>
                            <p className="inco02">에러메시지</p>
                        </div>
                    </div>
                </div>

            </div>
            {/* <Popup type="PopupMngPopup" open={typeOpen2} onClose={()=> {setTypeOpen2(false)}} data = {data}/>  */}
        </>
    )
}
export default LoginPage;