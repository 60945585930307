/**
 * @Page ROM3Y
 * @Description [ROM3Y]납입지시 - 현황관리 - PO 미납현황조회
 **/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from '@/components/molecules/CustomAlert';
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common';
import CommonUtil from '@/utils/CommonUtil';
import Loading from 'src/components/atoms/CustomLoading2';

const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER;
const CODES = require('@/config/commonCode.json');
const Utils = new CommonUtil();

function ROM3Y() {
	const token = Common.getToken();
	const userId = Common.getToken('id');
	const tVnt1 = Common.getToken('vand');
	const tComp = Common.getToken('comp');
	const grdRom3YMain = useRef();
	const bigi = Common.getToken('bigi');
	const master = Common.getToken('masterKey');
	const ckVnt1 =
		Common.getCookie('ck_vnt1' + userId) === null || Common.getCookie('ck_vnt1' + userId) === '' || Common.getCookie('ck_vnt1' + userId) === 'undefined'
			? tVnt1
			: Common.getCookie('ck_vnt1' + userId);

	let [loading, setLoading] = useState(false);

	const [idata, setData] = useState({
		//조회조건
		kGubn: `(+)`,
		kOrder: 'A',
		kSort: 'PTNO',
		kDlno: '',
		kFndt: '',
		kTndt: '',
		kVnt1: ckVnt1,              // T1업체
		kComp: tComp,               // 회사구분
		kPlnt: '',                  // 공장
		kGate: '',                  // 하치장
		kPldt: Common.getToday(),   // 소요일자
		kPtno: '',                  // 품번
		kFidt_f: Common.getToday(), // 시작일
		kTelno: '',                 // 연락처
		kFidt_t: Common.getToday(), // 종료일
		kUsyn: 'N',
		kInfo_vand: '',
		kVnt1Nm: '',
		kCompNm: tComp === 'EHMC' ? '현대자동차(주)' : '기아자동차(주)', //회사명

		//코드 데이터
		vnt1List: [],               // T1업체
		plntList: [],               // 공장
		gateList: [],               // 하치장
		canoList: [],               // 차량번호
		compList: [],               // 회사
		kMaster: master,
	});

	const [message, setMessage] = React.useState({
		openAlert: false,
		severity: 'success',
		msg: '',
	});
	const { openAlert, severity, msg } = message;

	//Dialog
	const [dlgMessage, setDlgMessage] = React.useState({
		openDlg: false,
		dlgTrans: '',
		dlgMsg: '',
	});
	const { openDlg, dlgTrans, dlgMsg } = dlgMessage;

	//Grid Search, Insert, Update, Delete Api
	let [searchApi, setSearchApi] = useState(false);

	const [panded, setPanded] = React.useState(true);
	const [style, setStyle] = useState({ height: 'calc(100vh - 60px - 50px - 100px  - 50px)' });

	const onClearApi = useCallback(async (e) => {
		setSearchApi(false);
	}, []);

	/**
	 * 공통코드 조회 (조회조건)
	 */
	const onSearchCommon = (para, name) => {
		let commonData = idata;

		const fetchData = async (para, name) => {
			let param = encodeURI(para);
			const query = SERVER_URL + '/comcd/search?query=' + param;
			await fetch(query, { headers: { Authorization: token } })
				.then((resp) => resp.json())
				.then((data) => {
					if (data.success) {
						commonData[name] = data.data;
						setData({ ...commonData });
					}
				});
		};
		fetchData(para, name);
	};

	const onSearchCommon1 = (para, name) => {
		let commonData = idata;

		const fetchData1 = async (para, name) => {
			let param = encodeURIComponent(para);
			const query = SERVER_URL + '/comcd/search1?query=' + param;
			await fetch(query, { headers: { Authorization: token } })
				.then((resp) => resp.json())
				.then((data) => {
					if (data.success) {
						commonData[name] = data.data;
						setData({ ...commonData });
					}
				});
		};
		fetchData1(para, name);
	};

	const onSearchCommon2 = (para, name) => {
		let commonData = idata;

		const fetchData = async (para, name) => {
			let param = encodeURI(para);
			const query = SERVER_URL + '/comcd/search?query=' + param;
			await fetch(query, { headers: { Authorization: token } })
				.then((resp) => resp.json())
				.then((data) => {
					if (data.success) {
						commonData[name] = data.data[0].text;
						setData({ ...commonData });
					}
				});
		};
		fetchData(para, name);
	};

	/**
	 *MOUNT
	 */
	useEffect(() => {
		//공통코드 조회 (T1업체)
		onSearchCommon(`{"pgmid":"VNT1"}`, 'vnt1List');
		//공통코드 조회 (공장)
		onSearchCommon1(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"PO"}`, 'plntList');
		//공통코드 조회 (차량번호)
		onSearchCommon(`{"pgmid":"CARN"}`, 'canoList');
		//공통코드 조회 (회사)
		onSearchCommon(`{"pgmid":"COMP"}`, 'compList');
		onSearchCommon2(`{"pgmid":"WRCVAND","kVnt1":"${idata.kVnt1}"}`, 'kVnt1Nm');
	}, []);

	/**
	 * Control 변경 Event
	 * @param {*} e
	 */
	const onChange = async (e) => {
		const { name, value, desc } = e.target;

		let newSearchs = idata;
		newSearchs[name] = value;
		newSearchs[name + 'Nm'] = desc;
		setData({ ...newSearchs });

		// 회사 변경 시
		if (name === 'kComp') {
			newSearchs['kPlnt'] = '';
			newSearchs['kPlntNm'] = '';
			newSearchs['kGate'] = '';
			setData({ ...newSearchs });

			if (value == 'EHMC') {
				//공통코드 조회 (공장)
				onSearchCommon1(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, 'plntList');
			} else if (value == 'EKMC') {
				onSearchCommon1(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, 'plntList');
			}
		}
		// 공장 변경 시
		else if (name === 'kPlnt') {
			newSearchs['kGate'] = '';
			setData({ ...newSearchs });

			onSearchCommon(`{"pgmid":"POGATE","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", "kFidt_f":"${idata.kFidt_f}","kFidt_t":"${idata.kFidt_t}"}`, 'gateList');
		} else if (name === 'kSort') {
			onSearch();
		}
	};

	/**
	 * 검색조건
	 */
	const optionsX = {
		0: [
			//T1업체
			{
				formatter: 'select',
				name: 'kVnt1',
				labelName: 'T1업체',
				type: 'text',
				defaultValue: idata.kVnt1,
				codeMode: true,
				selectAll: '',
				codes: null,
				comms: idata.vnt1List,
				align: 'left',
			},
			//회사구분
			{
				formatter: 'select',
				name: 'kComp',
				labelName: '회사구분',
				type: 'text',
				defaultValue: idata.kComp,
				codeMode: true,
				selectAll: '',
				codes: CODES['comp'],
				comms: null,
				align: 'left',
			},
			// 납기일자 시작
			{
				formatter: 'input',
				name: 'kFidt_f',
				type: 'date',
				labelName: '납기일자',
				defaultValue: idata.kFidt_f,
				align: 'left', // (우측 정렬)
				labelType: 'none',
			},
			// 정렬순서
			{
				formatter: 'radio',
				name: 'kSort',
				labelName: '정렬순서',
				type: 'text',
				desc: '',
				readOnly: false,
				options: [
					{ name: 'kSort', setValue: 'PTNO', labelName: '품번' },
					{ name: 'kSort1', setValue: 'NIDT', labelName: '납기일' },
				],
				defaultValue: idata.kSort,
				align: 'left',
				labelType: 'none',
			},
			{ formatter: 'blank', align: 'right' },
			{ formatter: 'blank', align: 'right' },
		],
		1: [
			// 공장
			{
				formatter: 'select',
				name: 'kPlnt',
				labelName: '공장',
				type: 'text',
				defaultValue: idata.kPlnt,
				codeMode: false,
				selectAll: 'none',
				codes: null,
				comms: idata.plntList,
				align: 'left',
			},
			// 하치장
			{
				formatter: 'select',
				name: 'kGate',
				labelName: '하치장',
				type: 'text',
				defaultValue: idata.kGate,
				codeMode: false,
				selectAll: 'all',
				codes: null,
				comms: idata.gateList,
				align: 'left',
			},
			// 납기일자 종료
			{
				formatter: 'input',
				name: 'kFidt_t',
				type: 'date',
				labelName: '~',
				defaultValue: idata.kFidt_t,
				align: 'left', // (우측 정렬)

				labelType: 'insert1',
			},
			// 사용여부->발주취소여부
			{
				formatter: 'select',
				name: 'kUsyn',
				labelName: '발주 취소여부',
				type: 'text',
				defaultValue: idata.kUsyn,
				codeMode: false,
				selectAll: 'all',
				codes: [
					{ comCode: 'Y', text: '취소포함' },
					{ comCode: 'N', text: '취소미포함' },
				], // -> 쿼리에서 취소포함 = 'X' /취소미포함 = ' '
				comms: null,
				align: 'left',
			},
			{ formatter: 'blank', align: 'right' },
			{ formatter: 'blank', align: 'right' },
		],
	};

	/**
	 * 조회
	 * @param {*} e
	 * @returns
	 */
	const onSearch = async (e) => {
		//공장선택 Check
		if (idata.kPlnt == '') {
			setMessage({ openAlert: true, severity: 'info', msg: '공장을 선택하십시요 !' });
			document.getElementById('kPlnt').focus();
			return;
		}

		// Cookie 값 셋팅
		Common.setCookie('ck_vnt1' + userId, idata.kVnt1, 1);

		let para = `{
                         "vnt1": "${idata.kVnt1}", "gubn": "${idata.kGubn}", "gate": "${idata.kGate}",
                         "comp": "${idata.kComp}", "plnt": "${idata.kPlnt}", "ptno": "${idata.kPtno}",
                         "nidt_f": "${idata.kFidt_f.replaceAll('-', '')}",
                         "nidt_t": "${idata.kFidt_t.replaceAll('-', '')}",
                         "sort": "${idata.kSort}", "usyn": "${idata.kUsyn}", "info_vand": "${idata.kInfo_vand}"
                      }`;

		let param = encodeURI(para);

		const query = SERVER_URL + '/rom3y/search?query=' + param;
		setSearchApi(query); // Search API
	};

	/**
	 * 신규 Row추가
	 * @param {*} e
	 */
	const onAddRow = async (e) => {};

	/**
	 * 입력 (발행)
	 * @param {*} e
	 */
	const onInsert = async (e) => {};

	/**
	 * 수정
	 * @param {*} e
	 * @returns
	 */
	const onUpdate = async (e) => {};

	/**
	 * 삭제
	 * @param {*} e
	 */
	const onDelete = async (e) => {};

	/**
	 * 엑셀
	 * @param {*} e
	 */
	const onExcel = async (e) => {
		//공장선택 Check
		if (idata.kPlnt == '') {
			setMessage({ openAlert: true, severity: 'info', msg: '공장을 선택하십시요 !' });
			document.getElementById('kPlnt').focus();
			return;
		}

		// Cookie 값 셋팅
		Common.setCookie('ck_vnt1' + userId, idata.kVnt1, 1);
		let para = `{
                         "vnt1": "${idata.kVnt1}", "gubn": "${idata.kGubn}", "gate": "${idata.kGate}",
                         "comp": "${idata.kComp}", "plnt": "${idata.kPlnt}", "ptno": "${idata.kPtno}",
                         "nidt_f": "${idata.kFidt_f.replaceAll('-', '')}",
                         "nidt_t": "${idata.kFidt_t.replaceAll('-', '')}",
                         "sort": "${idata.kSort}", "usyn": "${idata.kUsyn}", "info_vand": "${idata.kInfo_vand}",
                         "compNm":"${idata.kCompNm}","vnt1Nm":"${idata.kVnt1Nm}","plntNm":"${idata.kPlntNm}","gateNm":"${idata.kGate === '' ? '전체' : idata.kGate}"
                      }`;
		let param = encodeURI(para);

		const query = SERVER_URL + '/rom3y/excel?query=' + param;

		//엑셀파일 조회 시 로딩오픈
		setLoading(true);

		await fetch(query, { headers: { Authorization: token } })
			.then((resp) => {
				return resp.blob();
			})
			.then((blob) => {
				const href = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = href;
				link.setAttribute('download', 'rom3y.xls');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((err) => {
				return Promise.reject({ Error: 'error', err });
			});

		//엑셀파일 조회 시 로딩 닫기
		setLoading(false);

		// 엑셀파일 생성 메세지
		setMessage({ openAlert: true, severity: 'success', msg: '엑셀파일이 생성되었습니다.' });
	};

	//Transaction AlertDialogResult
	const AlertDialogResult = async (e) => {
		//AlertDialog 초기화
		setDlgMessage({ openDlg: false, dlgTrans: '', dlgMsg: '' });

		//DialogResult
		if (e.result == 'N') return;

		//Transaction 구분
		switch (e.Trans) {
			//등록
			case 'I':
				break;
			//수정
			case 'U':
				break;
			//삭제
			case 'D2':
				break;
			case 'D3':
				break;
		}
	};

	/**
	 *Grid Column 정의
	 */
	//Main Grid Column
	const mainColumnDefs = [
		{
			headerName: '하치장',
			field: 'gate',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 130,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '납기일자',
			field: 'NIDT',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 130,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
			valueFormatter: Utils.dateFormatter,
		},
		{
			headerName: '품번',
			field: 'ptno',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			valueGetter: Utils.partnoGetter,
			hide: false,
		},
		{
			headerName: '발주번호',
			field: 'ODNO',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '품목번호',
			field: 'PMNO',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '발주수량',
			field: 'DRQT',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '잔량',
			field: 'JAQT',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '담당',
			field: 'crdx',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '피더',
			field: 'fedr',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '실적',
			field: 'sjgb',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '검사',
			field: 'okgb',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '발주 취소여부',
			field: 'USYN',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		//인터페이스 일자
	];

	if (idata.kMaster == 'Y') {
		mainColumnDefs.splice(12, 0, {
			headerName: '수신일시',
			field: 'IFDATE',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		});
	}

	const onClick = async (e) => {
		setPanded(!panded);

		if (panded === true) {
			setStyle({
				height: 'calc(100vh - 60px - 100px - 10px)',
			});
		} else {
			setStyle({
				height: 'calc(100vh - 60px - 50px - 100px  - 50px)',
			});
		}
	};

	return (
		<>
			{/* Dialog FRAME */}
			<AlertDialog open={openDlg} trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

			{/* Alert FRAME */}
			<CustomAlert
				open={openAlert}
				severity={severity}
				duration={3000}
				message={msg}
				onClose={() => {
					setMessage({ ...message, openAlert: false });
				}}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			/>

			{/* Loading */}
			<Loading loading={loading} />

			{/* Ribbon Frame : 프로그램 Path 및 버튼 */}
			<Ribbon
				onSearch={onSearch}
				onSearchName={null} //조회
				onExcel={onExcel}
				onExcelName={null} //Excel
			>
				{'납입지시 > 현황관리 > '}
				<b style={{ color: '#cd5c5c', fontFamily: 'PretendardLight, NanumGothic, sans-serif' }}>PO 미납현황조회</b>
			</Ribbon>

			{/* ContentXearch Frame : 검색조건 */}
			<ContentXearch optionsX={optionsX} onChange={onChange} onClick={onClick} />

			{/* Content FRAME (Data / Grid Frame) START -------------- */}
			<Content style={style}>
				{' '}
				<div className={'grid_type01'}>
					{' '}
					{/* 상단 FRAME */}
					<div className="grid_cont_box" style={{ height: 'calc(100%)' }}>
						<Grid
							ref={grdRom3YMain}
							gridId={'ROM3Y'}                  // (필수) Grid Id 정의
							columnDefs={mainColumnDefs}       // (필수) 컬럼 정의
							pagination={true}                 // 쪽수 매기기 여부
							paginationCsv={false}             // CSV 사용구분 - pagination과 동시에 사용 필요
							paginationPageSize={'500'}        // 쪽수 1Page 당 Row수
							rowMultiSelectWithClick={true}    // 멀티 클릭 가능 여부
							suppressRowClickSelection={true}  // 클릭으로 선택 하는것을 방지
							rowSelection={'multiple'}         // 여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
							enableRangeSelection={false}
							enableCellTextSelection={true}    // cell selection drag mode
							isFirst={false}                   // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
							displayNo={false}
							searchApi={searchApi}             // 조회
							onClearApi={onClearApi}           // 초기화
							onRowClickEvent={null}
						/>
					</div>
				</div>
			</Content>
		</>
	);
}
export default ROM3Y;
