// import React from 'react';
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CustomMenu from '../../components/molecules/CustomMenu';
import Popup from '@/pages/Popup/Popup';    

import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';


// import '../../css/main.css';
// import * as Common from '../../utils/Common'; 
import '@/css/main.css';
import * as Common from '@/utils/Common'; 


// const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const currPath = window.location.pathname;


export default function AppHeader({ open, onOpen, drawerWidth, func, navGubn, funcTop, topNotice }) {

    // const token = Common.getToken();
    //const alrm = Common.getToken("alrm");
    const userId = Common.getToken("id");
    const userName = Common.getToken("name");
    const bigi = Common.getToken("bigi");
    const history = useHistory();
    const [typeOpen, setTypeOpen] = React.useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    //Data Top Notice
    const [alrm, setDataAlrm] = useState(null);
    const [gubn, setDataGubn] = useState(null);
    
    const useStyles = makeStyles((theme) => ({
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            minHeight: 60,
            backgroundColor: '#fff',
            boxShadow: "none",
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxShadow: "none",
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
    }));

    const classes = useStyles();

    
    const onLogout = (e) => {
        history.push('/login')
    }


    const onHeaderClick = (val) => {
        navGubn = val;
        onOpen();
        func(val);
    }

    
    const onSearchTopNotice = () => { // Top Notice 조회 onSearchTopNotice Function
        if(topNotice){
            setDataAlrm( topNotice.titl );
            setDataGubn( topNotice.gubn );
        }else{
            setDataAlrm( null );
            setDataGubn( null );
        }
    }
    useEffect(onSearchTopNotice, [topNotice]); // Top Notice 값이 변경되면 재조회 할 수 있도록 !! 


    const init = () => {


        
        let currGubn = currPath.substring(1, 2);

        if (currPath === "/" || currPath === "/login") {
            currGubn = "A"
        }

        if (navGubn === "") {
            if (currGubn === "A" || currGubn === "B" || currGubn === "C"|| currGubn === "D") {

                navGubn = currGubn;
                onHeaderClick(currGubn);
            } else {
                navGubn = "A";
            }
        }

        funcTop(); // Top Notice

    }
    useEffect(init, []); // 최초실행 - navGubn 대메뉴 호출

    const myinfo = () =>{//my info 팝업열기
        setTypeOpen(true);
    }
    const [isClicked, setIsClicked] = useState(false);

    const handleLinkClick = () => {
        setIsClicked(true);
    
        setTimeout(() => {
          return navGubn === "B" ? "selected" : "";
        }, 1000); // 1초 후에 실행하도록 설정
      };
    
    const closeModal = () => {
        setTypeOpen(false);
      };

    return (
        <AppBar
            position="fixed"
            color={"default"}
            // color={"transparent"}
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}>

            <Toolbar style={{ width: "100%" ,background: "#f5f5f5",borderBottom:"1px solid #bfbdcd",paddingLeft:"0px"}}>
                <div className={"header_menu_area"}>
                <img src="/images/button/btn_right.png" className={clsx(classes.menuButton, open && classes.hide)} alt="버튼" title="버튼" style={{paddingLeft:"0px",cursor: "pointer"}} onClick={onOpen} />
                </div>

                {/* <Typography className={clsx(classes.title)} variant="h6" noWrap>
                    New Rapdos System
                </Typography> */}

                <div className={"header_area2"}>
                    <Link to={"#"} className={navGubn === "A" ? "selected" : ""} id="headerA" onClick={() => onHeaderClick("A")}> 납입지시 </Link>
                    <Link to={"#"} className={navGubn === "B" ? "selected" : ""} id="headerB" onClick={() =>{onHeaderClick("B")}}> 수신자료관리 </Link>
                    <Link to={"#"} className={navGubn === "C" ? "selected" : ""} id="headerC" onClick={() => onHeaderClick("C")}> 시스템관리 </Link>
                    <Link to={"#"} className={navGubn === "D" ? "selected" : ""} id="headerD" onClick={() => onHeaderClick("D")}> 고객지원 </Link>
                </div>

                {/* <Button color="inherit">Login</Button>
                <CustomMenu
                    onLogoutEvent={onLogout}
                /> */}


                <div className={"header_info_area"}>
                        {alrm && alrm.trim()===""?null:<><img src="/images/notice_bell.png" alt="공지" title="공지" style={{height: "17px",marginTop: "2px"}} />&nbsp;</>}                    
                        {gubn==="Y"?<div style={{fontSize:"11pt",fontFamily: "'PretendardMedium'",marginRight:"1px",color:"#ff0000",minWidth:"57px",whiteSpace:"nowrap",letterSpacing:"-1px"}}>[긴급공지]</div>:<div style={{fontSize:"11pt",fontFamily: "'PretendardMedium'",marginRight:"1px",minWidth:"30px",whiteSpace:"nowrap",letterSpacing:"-1px"}}>[공지]</div>}
                        <div className={"header_info_area_text"} title={alrm} style={{fontSize:"11pt",fontFamily: "'PretendardMedium', 'NanumGothic', 'sans-serif'",paddingRight:"5px",paddingTop:"0px",marginRight:"10px",letterSpacing:"-1px"}}>{alrm}</div>
                        <div key={refreshKey}>
                        <Popup type="MyInf" open={typeOpen} onClose={()=> {setTypeOpen(false);}} />
                        </div>
                        <img src="/images/button/profile.png" alt="MyInfo 바로가기" title="MyInfo 바로가기" style={{ float: "right",cursor:"pointer",width:"28px",cursor:"pointer"}} onClick={myinfo}/>

                        <p style={{ float: "right", paddingLeft: "4px", paddingRight: "4px", fontSize:"11pt", cursor:"default", fontFamily: "'PretendardMedium'", whiteSpace:"nowrap",color:"#0057f4",cursor:"pointer" }}onClick={myinfo}>{userId}</p>
                        <p style={{ float: "right", fontSize:"11pt",     marginRight: "15px",cursor:"default", fontFamily: "'PretendardMedium'", whiteSpace:"nowrap",color:"#222339",cursor:"pointer"}}onClick={myinfo}>{userName}</p>

                        {/* </span> */}
                        <div style={{cursor:"pointer",paddingRight:"5px",paddingTop:"0px",marginRight:"-13px", background: "#ed5d3b",borderRadius: "104px",width: "93px",height:"28px"}} onClick={onLogout}>
                            <img src="/images/button/logout_icon.png" alt="로그아웃" title="로그아웃" style={{ cursor:"pointer",width:"16px",marginLeft: "4px",marginTop: "6px"}} />
                            <div style={{float: "right",fontSize:"11pt",fontFamily: "'PretendardMedium'",color:"#ffffff",marginTop: "6px",marginRight:"4px"}}>로그아웃</div>
                        </div>
                </div>
            </Toolbar>
        </AppBar>
    );
}