import React, { useState } from "react";

import AppHeader from './AppHeader';
import AppMenu from './AppMenu';
import AppMain from './AppMain';
import '@/css/main.css';

import * as Common from '@/utils/Common'; 
import {useHistory } from "react-router-dom";
import TabPage from './TabList';
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 

var navPath = "";

function MainLayout({ children, setMenuId, onCreateTab}) {
    const token = Common.getToken();
    // const classes = useStyles();
    const drawerWidth = 220;
    const history = useHistory();
    
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [inputs, setInputs] = useState({
        gubn: ""
    });  //검색조건 setInputs 입력기능 및 최초입력값 셋팅 선언
    const { gubn } = inputs;

    
    const [navGubn, setNavGubn] = React.useState("");
    
    const onChangeGubn = (val) => {
        setNavGubn(val);
        navPath = window.location.pathname;
        setInputs({ ...inputs, [gubn]: val });
    };

    
    navPath = history.location.pathname;
    //setNavGubn(navPath.substring(1,2));

    const [topNotice, setTopNotice] = React.useState({});    
    const onSearchTopNotice = () => { // Top Notice 조회 onSearchTopNotice Function

      

        const query = SERVER_URL + "/main/notice/top"; // + param; 

        const fetchSearch = async (query) => {

            await fetch(query,  { headers: { Authorization: token  } })
                .then((resp) => resp.json())
                .then((data) => {

                    if (data.success) {

                        setTopNotice(data.data[0]);
                    }
                    if (data.error) {
                        setTopNotice(null);
                        // setDataAlrm( null );
                        // setDataGubn( null );
                    }
                 }
                )
                .catch((error) => {// 에러 응답 처리
                    if (error) {
                        setTopNotice(null);    
                    }
                });
        };
        fetchSearch(query);
        //setTimeout(() => fetchSearch(query), 1000);
    }

    
    return (
        <>
        
            <main className="cr-app bg-light">
                
                <AppHeader
                    navGubn={navGubn} navPath={navPath} func={onChangeGubn} funcTop={onSearchTopNotice} topNotice={topNotice}
                    open={open} onOpen={handleDrawerOpen} drawerWidth={drawerWidth} />

                <AppMenu
                    navGubn={navGubn} navPath={navPath} funcTop={onSearchTopNotice}
                    open={open} onClose={handleDrawerClose} drawerWidth={drawerWidth}
                    setMenuId={setMenuId} onCreateTab={onCreateTab} />

                <AppMain open={open} drawerWidth={drawerWidth}>
                    {children}
                </AppMain>
                
            </main>
        </>
    );
}

export default MainLayout;