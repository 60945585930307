import React, { Component } from 'react';
import { Modal, Box } from "@material-ui/core";
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
import CommonUtil from '@/utils/CommonUtil'; 
import { stringToArray } from 'ag-grid-community';
import Popup from '@/pages/Popup/Popup';    

 
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
const Utils = new CommonUtil();


class CarType extends Component {

    constructor(props)  {
        super(props);
        this.grdCarTypeMain = React.createRef();

        this.state = {
            //Common
            token:Common.getToken(),
            userId :Common.getToken("id"),
            userName : Common.getToken("name"),
            tVnt1 : Common.getToken("vand"),
            tComp : Common.getToken("comp"),
    
            //조회조건 - 업체
            kWrcVand : "",          
            //조회조건 - 회사구분
            kComp: "",
    
            //Alert 창 ( Alert 창 OPEN,  Alert 창 구분, Alert 창 메세지)
            openAlert : false, severity : "success", msg : "",
    
            //Dialog 창 (Dialog 창 OPEN, Dialog Transaction(I,U,D),  Dialog 메세지)
            openDlg : false, dlgTrans : "", dlgMsg : "", 
    
            //Common 코드 데이터
            compList : {},
    
            //Api (조회, 등록, 수정, 삭제)
            searchApi : false, insertApi : false, updateApi : false, deleteApi : false,

            loadingFlag : false,setTypeOpen:false
        }
        this.closeModal = this.closeModal.bind(this);
    }

    
    /**
     * WillMount
     */
    async componentWillMount() {

        //조회조건 Setting
        this.setState({kWrcVand: (this.state.tVnt1 == "M000" ? "" : this.state.tVnt1)}); 

        //공통코도 조회 (회사구분)
        let commonCode = await Promise.all([this.onSearchComp(`{"pgmid":"CODE", "vand":"****", "gocd" : "COMP"}`), ]);

        //회사구분
        this.setState({compList: commonCode[0], loadingFlag : true}); 

        this.setState({kComp: this.props.para}); 
    }


    /**
     * DidMount
     */
    componentDidMount() {
        this.setState({kComp: this.props.para}); 

        let para = `{"kGocd":"MODL", "kCod1":"${this.props.para?this.props.para:this.state.kComp}"}`;
        let param = encodeURIComponent(para);

        const query = SERVER_URL + "/popup/carType_search?query=" + param;

        const fetchSearchData = async (query) => {
        let inputData = this.state; 
            await fetch(query,  { headers: { Authorization: this.state.token  } })
                .then((resp) => resp.json())
                .then((data) => {

                    if (data.success) {
                        this.setState({ searchApi : query})
                        //this.setState({ ...inputData });
                        
                    }
                    if (data.error) {
                        this.setState({ openAlert : true, 
                                        severity : "error",
                                        msg : "조회 실패"});
                    }
                })
                .catch((error) => {
                    // 에러 응답 처리
                    if (error) {
                        this.setState({ openAlert : true, 
                                        severity : "error",
                                        msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                    }
                });
        };
        //fetchSearchData(query);
        
        setTimeout(() => fetchSearchData(query), 300);
    }


    // /**
    //  * WillReceiveProps
    //  */
    // componentWillReceiveProps () {
    // }

    // /**
    //  * WillUpdate
    //  * @param {*} nextProps 
    //  * @param {*} nextState 
    //  */
    // componentWillUpdate(nextProps, nextState) {
    // }

    // /**
    //  * DidUpdate
    //  * @param {*} prevProps 
    //  * @param {*} prevState 
    //  */
    // componentDidUpdate(prevProps, prevState) {
    // }

    // /**
    //  * WillUnmount
    //  */
    // componentWillUnmount() {
    // }


    /**
     * 공통코드 조회
    */
     onSearchCommon = (para, name) => {
    }


    /**
     * 회사구분 코드 조회
    */
      onSearchComp = async (para) => {

        let param = encodeURI(para);
        let elements = {}; // 객체 생성

        const query = SERVER_URL + "/comcd/search1?query=" + param; 

        await fetch(query, { headers: { Authorization: this.state.token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) { 
                    data.data.forEach(function (element) {
                        elements[element.comCode] = element.text;
                    }, this)
                }
            })
        return elements;
    }

    closeModal = () => {
        return <Popup type="CarType" onClose={()=> {this.state.setTypeOpen(false)}}></Popup>
      };


    render() {

        if (this.state.loadingFlag === false) {
            return null
        }
        else {
        
            //State
            const {token, userId, userName, tVnt1, tComp, kWrcVand, kComp,  openAlert, severity, msg,setTypeOpen,
                   openDlg, dlgTrans, dlgMsg, compList, searchApi, insertApi, updateApi, deleteApi } = this.state;


            //Grid Clear Api               
            const onClearApi = async (e) => {
                this.setState({ searchApi : false, insertApi : false, updateApi : false, deleteApi : false})
            }


            /**
             * Control 변경 Event
             * @param {*} e 
             */
            const onChange = async (e) => {
                const { name, value } = e.target;

                if(name==="kComp"){
                    this.setState({kComp: value});
                }
                
            }

        
            /**
             * Grid Event 
             * @param {*} e 
            */
            const onCellValueChanged = async (e) => {
            }

            const onRowClicked = async (e) => {
            }


            /**
             * 검색조건
            */
            const optionsX = {
                0: [
                    //업체 
                    {
                        "formatter": "input", "name": "kWrcVand", "labelName": "업체", "type": "text", "desc": "", "readOnly" : (tVnt1 == "M000" ? false : true),
                        "defaultValue": kWrcVand,  
                        "align": "left", 
                        "labelType": "none" 
                    }, 
                    //회사구분
                    {
                        "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": kComp,  
                        "codeMode": false,
                        "selectAll": true,
                        // "codes": CODES["comp"],
                        "comms": null,
                        "align": "left", 
                        "items": [{ "comCode": "EHMC", "text": "EHMC 현대자동차(주)" },{ "comCode": "EKMC", "text": "EKMC 기아자동차(주)" },
                                  //{ "comCode": "MHMC", "text": "MHMC 현대자동차" }, // .txt 사용 전용 회사(old 버전용)
                                  { "comCode": "EHKR", "text": "EHKR 남양시작차" }]
                    }, 
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" }
                ]
            }

        
        
            /**
             * 조회
             * @param {*} e 
             */
            const onSearch = async (e) => { 
                
                //업체코드 입력 Check
                if (kWrcVand === "") {
                    
                    this.setState({ openAlert : true, 
                                    severity : "info",
                                    msg : "업체 코드를 입력해 주세요."});
                    document.getElementById("kWrcVand").focus();
                    return;
                }

                // let para = `{"kGocd":"MODL"}`;
                let para = `{"kGocd":"MODL", "kCod1":"${kComp}"}`;
                let param = encodeURI(para);

                const query = SERVER_URL + "/popup/carType_search?query=" + param;
                this.setState({ searchApi : query})
            }



            /**
             * 신규 Row추가
             * @param {*} e 
             */
            const onAddRow = async (e) => {
                //업체코드 입력 Check
                if (kWrcVand === "") {
                    
                    this.setState({ openAlert : true, 
                                    severity : "info",
                                    msg : "업체 코드를 입력해 주세요."});
                    document.getElementById("kWrcVand").focus();
                    return;
                }

                let newItems = { vand: kWrcVand, gocd : "MODL"};
                this.grdCarTypeMain.current.addRow(newItems, 0);
            }



            /**
             * 입력
             * @param {*} e 
             */
            const onInsert = async (e) => {

                //input check
                let input = await Promise.all ([inputCheck('I')]);
                if ( !input[0]) return;

                const query = SERVER_URL + "/popup/carType_insert";
                this.setState({ insertApi : query})

            }



            /**
             * 수정 
             * @param {*} e 
             * @returns 
             */
            const onUpdate = async (e) => {

                //input check
                let input = await Promise.all ([inputCheck('U')]);
                if ( !input[0]) return;
            
                const query = SERVER_URL + "/popup/carType_update";
                this.setState({ updateApi : query}) // Update API

            }



            /**
             * 삭제
             * @param {*} e 
             */
            const onDelete = async (e) => {
                
                //input check
                let input = await Promise.all ([inputCheck('D')]);
                if ( !input[0]) return;

                //삭제 확인 메세지 
                this.setState({openDlg : true,  
                               dlgTrans : "D", 
                               dlgMsg : "선택한 데이터를 삭제하시겠습니까?" });
            }



            //deleteBlank
            function deleteBlank(argval){

                if (argval === null || argval === undefined) return "";

                for(var i=argval.length-1 ;i >= 0; i--){
                    
                    if(argval.charCodeAt(i) !== 12288 && argval.charCodeAt(i) !== 32 ) //charCodeAt(i)  
                    return(argval.substring(0,i+1));
                }
                return(argval.length>0?argval.substring(0,0):"")
            }



            // input check
            const inputCheck = (gbn) => { 

                let inputFlag = true;

                //select nodes
                let selectedData = this.grdCarTypeMain.current.getSelectedNodes();

                // 선택 값 체크
                if (selectedData.length === 0) {
                    //Alert 창 OPEN
                    this.setState({ openAlert : true, 
                                    severity : "info",
                                    msg : "선택한 데이터가 없습니다."});
                    return false;
                }


                //필수입력항목 Check
                selectedData.forEach((node) => {

                    //업체코드 입력 Check (신규시 자동으로 입력됨)
                    if (node.data.vand === null || node.data.vand === "" || node.data.vand === undefined) {
                        this.setState({ openAlert : true, 
                                        severity : "info",
                                        msg : "업체코드가 입력되지 않았습니다. 다시 조회 후 작업하세요"});
                        inputFlag = false
                        this.grdCarTypeMain.current.SetFocus(node.rowIndex, "usid");                             
                        return ;
                    }

                    //회사구분
                    let cod1 = deleteBlank(node.data.cod1); 
                    if ( cod1 === "") {
                        this.setState({ openAlert : true, 
                                        severity : "info",
                                        msg : "회사구분을 입력하여 주십시오."});
                        inputFlag = false                                 
                        this.grdCarTypeMain.current.SetFocus(node.rowIndex, "cod1");                             
                        return;
                    }

                    //공장코드
                    let cod2 = deleteBlank(node.data.cod2); 
                    if ( cod2 === "") {
                        this.setState({ openAlert : true, 
                                        severity : "info",
                                        msg : "공장을 입력하여 주십시오."});
                        inputFlag = false                                 
                        this.grdCarTypeMain.current.SetFocus(node.rowIndex, "cod2");                             
                        return;
                    }

                    //차종코드 
                    let cod3 = deleteBlank(node.data.cod3); 
                    if ( cod3 === "") {
                        this.setState({ openAlert : true, 
                                        severity : "info",
                                        msg : "차종코드를 입력하여 주십시오"});
                        inputFlag = false                                 
                        this.grdCarTypeMain.current.SetFocus(node.rowIndex, "cod3");                             
                        return;
                    }
                });

                return inputFlag;
                
            }
            

            //AlertDialogResult
            const AlertDialogResult = async (e) => {

                //AlertDialog 초기화
                this.setState({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        
                //DialogResult
                if (e.result == "N") return;
        
                //Transaction 구분
                switch (e.Trans) 
                {
                    //등록
                    case "I" :
                        break;
                    //수정
                    case "U" :
                        break;
                    //삭제
                    case "D" :
                        const query = SERVER_URL + "/popup/carType_delete";
                        this.setState({ deleteApi : query}) // Delete API
                        break;
                }
            }

        

            //Grid DropDownList Value
            const extractValues = (mappings) => {
                return  Object.keys(mappings);
            }

            //Grid DropDownList Display
            const lookupValue = (mappings, key) => {
                return mappings[key];
            }        

        
            /**
             *Grid Column 정의 
            */
            const columnDefs = [
                { headerName: "vand", field: "vand", sortable: true, filter: true, flex: 1, minWidth: 150, hide: true }, // hidden 컬럼
                { headerName: "gocd", field: "gocd", sortable: true, filter: true, flex: 1, minWidth: 150, hide: true }, // hidden 컬럼
                { headerName: "회사구분", field: "cod1", sortable: true, filter: true, flex: 1, minWidth: 170, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignL"],    
                  cellEditor:'agSelectCellEditor', cellEditorParams: {values: extractValues(compList)},  valueFormatter: function (params) { return lookupValue(compList, params.value)},   
                  singleClickEdit: true, hide: false },  
                { headerName: "공장[**]", field: "cod2", sortable: true, filter: true, flex: 1, minWidth: 60, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },  
                { headerName: "차종", field: "cod3", sortable: true, filter: true, flex: 1, minWidth: 150, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },  
                { headerName: "차종명", field: "des1", sortable: true, filter: true, flex: 1, minWidth: 170, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }, 
            ];


            const onClick = () =>{
                this.state.setTypeOpen(false)
            }
        
            return (
                <>
                    {/* Dialog FRAME */}
                    <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />
                    
                    <button onClick={() => {this.props.onClose()}} style = {{border:"none", float:"right", paddingRight:"0px"}}> <img src="/images/button/close_button.png" alt="close" /></button> 

                    {/* Alert FRAME */}
                    <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {this.setState({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
        

                    {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
                    <Ribbon
                        newTabUnused={true}                         // newTabUnused : newTab 표출 버튼 true : 사용안함 // 선언안할경우 default = newTab 사용
                        onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                        onSearch={onSearch} onSearchName={null}     //조회
                        onAddRow={onAddRow} onAddRowName={"신규"}   //Row 추가
                        onInsert={onInsert} onInsertName={null}     //등록
                        onUpdate={onUpdate} onUpdateName={null}     //수정
                        onDelete={onDelete} onDeleteName={null}     //삭제
                    >
                    <b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>차종 등록</b>
                    </Ribbon>
        
        
                    {/* ContentXearch Frame : 검색조건 */}
                    <ContentXearch
                        optionsX={optionsX}
                        onChange={onChange}/>
        
                    <div className={"grid_type01"} style={{ marginTop: "10px"}} > {/* 상단 FRAME */}
                        <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                            <div className="grid_cont_box" style={{ height: "420px", width : "100%" }}>
                                <Grid
                                    ref={this.grdCarTypeMain}
                                    gridId={"CARTYPE"}                  //  (필수) Grid Id 정의
                                    columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                    pagination={true}                  //  쪽수 매기기 여부
                                    paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    searchApi={searchApi}               // 조회
                                    insertApi={insertApi}               // 등록
                                    onClearApi={onClearApi}
                                    insertRefresh={onSearch}            // 등록이후 
                                    updateApi={updateApi}               // 수정
                                    updateRefresh={onSearch}            // 수정이후 
                                    deleteApi={deleteApi}               // 삭제
                                    deleteRefresh={onSearch}            // 삭제이후 
                                    
                                    //onClearApi={()=> {setSearchApi(false); setUpdateApi(false);}}
                                    onCellValueChangedEvent={onCellValueChanged}
                                    onRowClickEvent={onRowClicked}
                                    
                                />
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        
    }

}


export default CarType;