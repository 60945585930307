import React, { Component } from 'react';
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
import * as GridUtil from '@/components/grid/agGrid';
import CommonUtil from '@/utils/CommonUtil'; 
import { stringToArray } from 'ag-grid-community';
 
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
const Utils = new CommonUtil();


class ChullogPopup extends Component {

    constructor(props)  {
        super(props);
        

        this.state = {
            //Common
            token:Common.getToken(),
            userId :Common.getToken("id"),
            userName : Common.getToken("name"),
            tVnt1 : Common.getToken("vand"),
            tComp : Common.getToken("comp"),

            context : props.data.context,
    
            //Common 코드 데이터
            compList : {},
    
            //Api (조회, 등록, 수정, 삭제)
            searchApi : false, insertApi : false, updateApi : false, deleteApi : false,

            loadingFlag : false
        }
    }

    
    /**
     * WillMount
     */
    async componentWillMount() {

        this.setState({loadingFlag : true}); 

    }


    /**
     * DidMount
     */
    componentDidMount() {
        let para = `{"kVnt1":"${this.state.tVnt1}","kPlnt":"${this.state.kPlnt}","kComp":"${this.state.kComp}","odno":"${this.state.odno}"}`;
        let param = encodeURIComponent(para);

        const query = SERVER_URL + "/popup/jyno_search?query=" + param;

        const fetchSearchData = async (query) => {
        let inputData = this.state; 
            await fetch(query,  { headers: { Authorization: this.state.token  } })
                .then((resp) => resp.json())
                .then((data) => {

                    if (data.success) {
                        this.state.jyno = data.data[0].jyno;
                        this.setState({ ...inputData });
                        
                    }
                    if (data.error) {
                        this.setState({ openAlert : true, 
                                        severity : "error",
                                        msg : "조회 실패"});
                    }
                })
                .catch((error) => {
                    // 에러 응답 처리
                    if (error) {
                        this.setState({ openAlert : true, 
                                        severity : "error",
                                        msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                    }
                });
        };
        fetchSearchData(query);
    }


    // /**
    //  * WillReceiveProps
    //  */
    // componentWillReceiveProps () {
    // }

    // /**
    //  * WillUpdate
    //  * @param {*} nextProps 
    //  * @param {*} nextState 
    //  */
    // componentWillUpdate(nextProps, nextState) {
    // }

    // /**
    //  * DidUpdate
    //  * @param {*} prevProps 
    //  * @param {*} prevState 
    //  */
    // componentDidUpdate(prevProps, prevState) {
    // }

    // /**
    //  * WillUnmount
    //  */
    componentWillUnmount() {
        this.setState({searchApi : true}); 
    }


    /**
     * 공통코드 조회
    */
     onSearchCommon = (para, name) => {
    }


    /**
     * 회사구분 코드 조회
    */
      onSearchComp = async (para) => {

        let param = encodeURI(para);
        let elements = {}; // 객체 생성

        const query = SERVER_URL + "/comcd/search1?query=" + param; 

        await fetch(query, { headers: { Authorization: this.state.token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) { 
                    data.data.forEach(function (element) {
                        elements[element.comCode] = element.text;
                    }, this)
                }
            })
        return elements;
    }



    render() {

        if (this.state.loadingFlag === false) {
            return null
        }
        else {
        
            //Grid Clear Api               
            // const onClearApi = async (e) => {
            //     this.setState({ searchApi : false, insertApi : false, updateApi : false, deleteApi : false})
            // }


            /**
             * Control 변경 Event
             * @param {*} e 
             */
            const onChange = async (e) => {
                const { name, value } = e.target;

                //  let newSearchs = idata;
                //  newSearchs[name] = value
                //  setData({ ...newSearchs });
                //  //조회 Call    
                //  onSearch(); 
            }

        
            /**
             * Grid Event 
             * @param {*} e 
            */
            const onCellValueChanged = async (e) => {
            }

            const onRowClicked = async (e) => {
            }


            /**
             * 검색조건
            */
            const optionsX = {
                // 0: [
                    
                //     // {
                //     //     "formatter": "input", "name": "kVnt2", "labelName": "T2 코드", "type": "text", "desc": "",
                //     //     "align": "left", 
                //     //     "labelType": "none" 
                //     // }, 
                //     // {"formatter": "blank", "align": "left" },
                //     // {"formatter": "blank", "align": "left" },
                //     // {"formatter": "blank", "align": "left" },
                //     // {"formatter": "blank", "align": "left" },
                //     // {"formatter": "blank", "align": "left" },
                // ]
            }

        
        
            /**
             * 조회
             * @param {*} e 
             */
            const onSearch = async (e) => { 
            }



            /**
             * 신규 Row추가
             * @param {*} e 
             */
            const onAddRow = async (e) => {
            
            }



            /**
             * 입력
             * @param {*} e 
             */
            const onInsert = async (e) => {

            }



            /**
             * 수정 
             * @param {*} e 
             * @returns 
             */
            const onUpdate = async (e) => {

            }



            /**
             * 삭제
             * @param {*} e 
             */
            const onDelete = async (e) => {
                
                
            }



            //deleteBlank
            function deleteBlank(argval){
            }

            //AlertDialogResult
            const AlertDialogResult = async (e) => {

                //AlertDialog 초기화
                this.setState({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        
                //DialogResult
                if (e.result == "N") return;
        
                //Transaction 구분
                switch (e.Trans) 
                {
                    //등록
                    case "I" :
                        break;
                    //수정
                    case "U" :
                        break;
                    //삭제
                    case "D" :
                        break;
                }
            }

        

            //Grid DropDownList Value
            const extractValues = (mappings) => {
                return  Object.keys(mappings);
            }

            //Grid DropDownList Display
            const lookupValue = (mappings, key) => {
                return mappings[key];
            }        

        
            /**
             *Grid Column 정의 
            */
            const columnDefs = [
            ];

            // Xml Formatting (input: str, output: str)
            const prettifyXml = function(sourceXml) {
                var xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
                var xsltDoc = new DOMParser().parseFromString([
                    // describes how we want to modify the XML - indent everything
                    '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
                    '  <xsl:strip-space elements="*"/>',
                    '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
                    '    <xsl:value-of select="normalize-space(.)"/>',
                    '  </xsl:template>',
                    '  <xsl:template match="node()|@*">',
                    '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
                    '  </xsl:template>',
                    '  <xsl:output indent="yes"/>',
                    '</xsl:stylesheet>',
                ].join('\n'), 'application/xml');

                var xsltProcessor = new XSLTProcessor();    
                xsltProcessor.importStylesheet(xsltDoc);
                var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
                
                // xml 형식이 아닐때
                if (resultDoc.getElementsByTagName("html")[0])
                    return sourceXml;
                var resultXml = new XMLSerializer().serializeToString(resultDoc);
                return resultXml;
            };

            return (
                <>
                    <button onClick={() => {this.props.onClose()}} style = {{border:"none", float:"right", paddingRight:"0px"}}> <img src="/images/button/close_button.png" alt="close" /></button> 

                    <div className="title" style={{height: "23px"}}>
                        <b style={{ fontSize: "12pt", color: "#000000", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>상세로그 팝업 (XML)</b>
                    </div>
        
                    <div className={"grid_type01"} style={{ marginTop: "10px"}} > {/* 상단 FRAME */}
                        <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                            <div className="grid_cont_box" style={{ height: "100%", width : "100%" }}>
                            <table  style={{ width: "100%" }}>
                                    <colgroup>
                                        <col width="100%" />
                                    </colgroup>
                                    <tbody>
                                    <tr style={{ height:"100%",border:"0px"}}>
                                        <td style={{border:"0px"}}>
                                            <div style={{wordBreak:"break-all"}}>
                                            <textarea wrap = "off" name="context" style={{width:"100%",height:"700px",background:"#F1F3F7",verticalAlign: "top",fontSize: "10pt",fontWeight: "bold",resize: "none"}} value={prettifyXml(this.state.context)} readOnly />
                                            </div>
                                        </td>
                                    </tr>     
                                    </tbody>
                                </table>
                               
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        
    }

}


export default ChullogPopup;