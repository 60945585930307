/**
 * @Page ROM92
 * @Description [ROM92]납입지시 - 현황관리 - 납품대비검수현황
 **/
 import React, {useState, useEffect, useRef, useCallback, forwardRef} from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; // Common.js
 import CommonUtil from '@/utils/CommonUtil'; // Util.js
 import {getToday} from "@/utils/Common";
 import Loading from "src/components/atoms/CustomLoading2";
 
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();
 
 
 function ROM92() {

     const currentYear = new Date().getFullYear().toString();
     const currentMonth = (new Date().getMonth()+1).toString(); // 현재 달 (현재달 : 달 조회시 +1)

     const token = Common.getToken();
     const userId = Common.getToken("id");
     const tVnt1 = Common.getToken("vand");
     const tComp = Common.getToken("comp");
     const bigi = Common.getToken("bigi");
     const grdRom92Main = useRef();   // Grid Main
     const grdRom92Detail = useRef();   // Grid Main
     const printRef = useRef();
     const printBtnRef = useRef();
     // const rGubn3Ref = useRef(null);
    
    const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
    let [loading, setLoading] = useState(false);
 
     //Data
     const [idata, setData] = useState({
        kFpdt: getToday(),
        kTpdt: getToday(),
        // kYear: getToday().substring(0,4),
        
        kIpgo: "all", // 조회항목
        // kVnt1 : tVnt1,      //T1업체
        kVnt1 : ckVnt1,        //T1업체     
        kComp: "EHMC",       //회사구분
        kPlnt: "",          //공장
        kPtno : "",         //품번
        kVnt1Nm : "",        //T1업체명
        kCompNm : tComp==="EHMC"?"현대자동차(주)":"기아자동차(주)",        //회사명

        kOpt : "plnt",//조회항목
        kGubn1 : "M",
        kGubn2 : currentYear, // 연도
        kGubn3 : currentMonth, // 월
 

        
        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장
        compList : [],  //회사
        yearList : [],  //년도
        montList : [],  //월
     });
 
 
      
     const [message, setMessage] = React.useState( {
         openAlert : false,      
         severity : "success",   
         msg : ""                
     });
     const { openAlert, severity, msg } = message;
 
 
     //Dialog
     const [dlgMessage, setDlgMessage] = React.useState( {
         openDlg : false,    
         dlgTrans : "",      // Dialog Transaction(I,U,D)
         dlgMsg : ""         
     });
     const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
 
     //Grid Search, Insert, Update, Delete Api
     let [searchApi, setSearchApi] = useState(false); 
     let [searchApi2, setSearchApi2] = useState(false); 

     let [printdata, setPrintData] = useState([]);   //Print
 
     const [panded, setPanded] = React.useState(true);
     const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 100px  - 50px)"});

     const onClearApi = useCallback(async (e) => {
         setSearchApi(false);
         setSearchApi2(false);
     }, []);
     const onClearApi2 = useCallback(async (e) => {
        setSearchApi2(false);
    }, []);



    let [clearGrid, setClearGrid] = useState(false); 
    let [clearGrid2, setClearGrid2] = useState(false); 
 
     /**
      * 공통코드 조회 (조회조건)
      */
     const onSearchCommonAsync = async (para, name) => {
         let commonData = idata;
 
         const fetchData = async (para, name) => {
             let param = encodeURI(para);
             const query = SERVER_URL + "/comcd/search?query=" + param; 
             await fetch(query, { headers: { Authorization: token } })
                 .then((resp) => resp.json())
                 .then((data) => {
                     if (data.success)
                     {
                         commonData[name] = data.data
                         setData({...commonData});
                     }
                 })
         }
         await fetchData(para, name);
     }
 
     /**
     * 회사구분
     * @param {*} para 
     * @param {*} name 
     */
  const onSearchCommon1 = (para, name) => {
    let commonData = idata;

    const fetchData1 = async (para, name) => {

        let param = encodeURI(para);
        const query = SERVER_URL + "/comcd/search1?query=" + param; 
        await fetch(query, { headers: { Authorization: token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) 
                {
                    if (name === "compList") {
                        //commonData["kType"] = data.data[0].comCode
                        //commonData["kTypeNm"] = data.data[0].text
                    }
                    commonData[name] = data.data
                    setData({...commonData});
                }
            })
    }
    fetchData1(para, "compList");
}

     const onSearchCommon2 = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                    commonData[name] = data.data[0].text;
                    setData({...commonData});
                    }
                })
        }
        fetchData(para, name);   
    }
 
 
     /**
      *MOUNT
      */
     useEffect( () => {
        //공통코드 조회 (T1업체)
        onSearchCommonAsync(`{"pgmid":"VNT1"}`, "vnt1List");

        //공통코드 조회 (공장)
        onSearchCommonAsync(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");

        //공통코드 조회 (회사)
        onSearchCommon1(`{"pgmid":"CODE", "vand":"****", "gocd":"COMP"}`, "compList");         

        for(var i = idata.kGubn2-6; i <= parseInt(idata.kGubn2)+1 ; i++){
            idata.yearList.push({
                comCode: i,
                text: i+"년"
            })
        }
        for(var i = 1; i <= 12 ; i++){
            idata.montList.push({
                comCode: i<10?"0"+i:i,
                text: i+"월"
            })
        }
        onSearchCommon2(`{"pgmid":"WRCVAND","kVnt1":"${idata.kVnt1}"}`, "kVnt1Nm");
     }, []);
 
 
 
     /**
      * Control 변경 Event
      * @param {*} e
      */
     const onChange = async (e) => {
         const {name, value, desc} = e.target;
         
         let newSearchs = idata;

        if (name === "kOpt"||name === "kGubn1"||name === "kGubn2"||name === "kGubn3") { // "조회 항목", "조회 구분" 변경 시 하단 그리드 1, 2 clear            
            setClearGrid(true); // 그리드 clear 하기 
            setClearGrid2(true); // 그리드2 clear 하기 
        }

        if (name === "kGubn1") { // 조회 구분 월/일 변경 시 오늘의 월 값 셋팅.
            if(value === "D" ){
                newSearchs["kGubn3"] = currentMonth<10?"0"+currentMonth:currentMonth;
            }
        } 
         
         newSearchs[name] = value;
         newSearchs[name+"Nm"] = desc;
         setData({ ...newSearchs });
 
     }
 
 
     /**
      * 검색조건
      */
     const optionsX = {
         0: [
             //T1업체
             {
                 formatter: "select", name: "kVnt1", labelName: "T1업체", type: "text", defaultValue: idata.kVnt1,  
                 codeMode: true,
                 selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: null,
                 comms: idata.vnt1List, 
                 align: "left", 
             },
             //조회항목
             {
                formatter: "select", name: "kOpt", labelName: "조회항목", type: "text", defaultValue: idata.kOpt,  
                codeMode: false,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: null,
                items: [{ "comCode": "plnt", "text": "공장별 품목" }, { "comCode": "part", "text": "품번별 공장" }],
                align: "left", 
            },
             // 공장
             {
                 formatter: "select", name: "kPlnt", labelName: "공장", type: "text", defaultValue: idata.kPlnt,  
                 codeMode: false,
                 selectAll: "all", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: null,
                 comms: idata.plntList,
                 align: "left", 
             },
             {"formatter": "blank", "align": "right" },
             {"formatter": "blank", "align": "right" }
         ],
         1: [
             //회사구분
             {
                formatter: "select", name: "kComp", labelName: "회사구분", type: "text", defaultValue: idata.kComp,  
                codeMode: true,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms:  idata.compList,
                align: "left", 
             },
             // 조회구분
             {
                formatter: "select3", name: "kGubn1",name2: "kGubn2",name3: "kGubn3", labelName: "조회구분"
                ,defaultValue: idata.kGubn1,  defaultValue2: idata.kGubn2,  defaultValue3: idata.kGubn3,
                codeMode: false,
                codes: null,
                comms: null,
                items: [{ "comCode": "M", "text": "월 단위" }, { "comCode": "D", "text": "일 단위" }],

                codeMode2: false,
                codes2: null,
                comms2: idata.yearList,

                codeMode3: false,
                codes3:null,
                comms3:idata.kGubn1=="M"?"":idata.montList,
                //selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                readonly3:idata.kGubn1=="M"?"T":"", // 월단위(M) 조회구분 일 경우, css 변경
                align: "left", 
            },
             // 조회품번
             {
                 formatter: "input", name: "kPtno", labelName: "품번", type: "text", defaultValue: idata.kPtno,  
                 codeMode: false,
                 selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: null,
                 comms: null,
                 align: "left", 
             },
             {"formatter": "blank", "align": "right" },
             {"formatter": "blank", "align": "right" }
         ]
     }
 
 
 
     /**
      * 조회
      * @param {*} e
      * @returns
      */
     const onSearch = async (e) => {
        
        setClearGrid2(true); // grid 2 초기화

        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);
        
    

         const fetchData = async (e) => {//조회시 전체건수구해서 넣어주기
            let inputData = idata; 

            let para = `{"vnt1":"${idata.kVnt1}","comp":"${idata.kComp}","kOpt": "${idata.kOpt}",
                         "sday":  "${idata.kGubn2}${idata.kGubn1 === "D" ? idata.kGubn3 :''}","ptno": "${idata.kPtno.replaceAll('-','')}",
                         "plnt": "${idata.kPlnt}","gubn": "${idata.kGubn1}"}`;
            //let param = encodeURI(para);
            let param = encodeURIComponent(para);
            const query = SERVER_URL + "/rom92/search?query=" + param;

            await fetch(query, { headers: { Authorization: token } })
                     .then((resp) => resp.json())  
                     .then((data) => {
                         if (data.success) {
                            idata.kTotc = data.count;
                            setSearchApi(query);
                        }
                        if (data.error) {
                            alert("서버 상태를 확인해 주세요. 관리자에게 문의해주세요.");
                        }
                     })
                     .catch((error) => {
                        // 에러 응답 처리
                        if (error) {
                            setMessage({ openAlert : true, 
                                         severity : "error",
                                         msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                        }
                    });
                    };
            fetchData(e);
     }
 
     
     
    /**
     * Grid Event 
     * @param {*} e 
     */
    // const grdRom92Main_onCellValueChanged = async (e) => {        
    // }
    const grdRom92Main_onRowClicked = async (e) => {

        let data = e.data;

        if(data.title1 !=="합계" && data.title1 !=="합계_2"){
            onSearch2(data.title1);    // h_val 좌측그리드 첫번째 컬럼
        }else{
        }

    }

    
    /**
     * 
     * @param {*} h_val 좌측그리드 첫번째 컬럼
     */
     const onSearch2= async (h_val) => {
        
        setClearGrid2(true); // grid 2 초기화
        
        let para = `{"vnt1":"${idata.kVnt1}","comp":"${idata.kComp}","kOpt": "${idata.kOpt}","gubn":"${idata.kGubn1}" ,"sday": "${idata.kGubn2}${idata.kGubn1 === "D" ? idata.kGubn3 :''}","ptno": "${idata.kPtno.replaceAll('-','')}","plnt": "${idata.kPlnt}","h_val": "${h_val}"}`;
        let param = encodeURIComponent(para);

        const query = SERVER_URL + "/rom92/search2?query=" + param;
        setSearchApi2(query); // Search API
    }
 
 
     /**
      * 신규 Row추가
      * @param {*} e
      */
     const onAddRow = async (e) => {
     }
 
 
 
     /**
      * 입력 (발행)
      * @param {*} e
      */
     const onInsert = async (e) => {
 
     }
 
 
 
     /**
      * 수정
      * @param {*} e
      * @returns
      */
     const onUpdate = async (e) => {
     }
 
     /**
      * 삭제
      * @param {*} e
      */
     const onDelete = async (e) => {
     }
 
     /**
      * 엑셀
      * @param {*} e
      */
     const onExcel = async (e) => {
        let para = `{"vnt1":"${idata.kVnt1}","comp":"${idata.kComp}","kOpt": "${idata.kOpt}","gubn":"${idata.kGubn1}" ,
                     "sday": "${idata.kGubn2}${idata.kGubn1 === "D" ? idata.kGubn3 :''}","gubn3": "${idata.kGubn3}",
                     "ptno": "${idata.kPtno.replaceAll('-','')}","plnt": "${idata.kPlnt}","h_val": "${e}",
                     "vnt1Nm":"${idata.kVnt1Nm}","compNm":"${idata.kCompNm}","plntNm":"${idata.kPlnt===""?"전체":idata.kPlntNm}","gubn1Nm":"${idata.kGubn1Nm}",
                     "optNm":"${idata.kOpt==="plnt"?"공장별품목":idata.kOptNm}"
                    }`;

        let param = encodeURIComponent(para);

        const query = SERVER_URL + "/rom92/excel?query=" + param;
        //엑셀파일 조회 시 반응
        setLoading(true);
        await fetch(query, {headers: {Authorization: token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'rom92.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })
        setLoading(false);
        
        // 엑셀파일 생성 메세지
        setMessage({ openAlert : true, 
                     severity : "success",
                     msg : "엑셀파일이 생성되었습니다."});
     }



    //  const rowSpan = async (e) => {
    //     let res = "";
    //     let str = e.data.title1;
        
    //     res = (e.data.title1).substring((str.lastIndexOf("_")+0))

    //     if(res === "_2"){
    //         //e.data.splice(6)
    //         //e.data.filter(user => user.id !== id)
    //         delete e.data.title1;
    //         return 2;
    //     }else{
    //         return 2;
    //     }
    // }
 
     //Transaction AlertDialogResult
     const AlertDialogResult = async (e) => {
 
         //AlertDialog 초기화
         setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
 
 
 
         //DialogResult
         if (e.result == "N") return;
 
         //Transaction 구분
         switch (e.Trans)
         {
             //등록
             case "I" :
                 break;
             //수정
             case "U" :
                 break;
             //삭제
             case "D2" :
                 break;
             case "D3" :
                 break;
         }
     }
 
     
     /**
      *Grid Column 정의
      */
         // 월별 컬럼
     const columnDefs1 = [
            {
            headerName: '',
            children: [
                { headerName: idata.kOpt==="plnt"?"공장":"품번", field: "title1", 
                  rowSpan: function(params) {
                    let str = params.data.title1;

                    if(params.data.rn%2 === 1){
                        return 2;
                    }else{
                        return 0;
                    }
                  },
                  cellClass: function(params) { 
                      return (params.data.rn%2 === 1?["nocheckbox", "alignL", "my-class-rowspan"]:["nocheckbox", "alignL", "my-class-rowspan2"]);
                  },
                  valueGetter: Utils.partnoGetter,
                  valueFormatter: function(params) { 
                    return (params.data.rn%2 === 1?params.value:"");
                },
                  sortable: false, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", 
                  singleClickEdit: true, hide: false },
                { headerName: "검수율", field: "aperc",
                  rowSpan: function(params) {
                    let str = params.data.title1;
            
                    if(params.data.rn%2 === 1){
                        //e.data.splice(6)
                        //e.data.filter(user => user.id !== id)
                        //delete params.data.title1;

                        return 2;
                    }else{
                        return 0;
                    }
                  },
                  cellClass: function(params) { 
                      return (params.data.rn%2 === 1?["nocheckbox", "alignR", "my-class-rowspan"]:["nocheckbox", "alignR", "my-class-rowspan2"]);
                  },
                  valueFormatter: function(params) { 
                    return (params.data.rn%2 === 1?params.value:"");
                    }, sortable: false, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", singleClickEdit: true, hide: false}, 
   
            ]
        },  // header 1
        {
            headerName: '납품량',//NPQTY1
            children: [
                { headerName: "검수량", field: "npqty1", sortable: false, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: true, hide: false, valueFormatter: Utils.numberFormatter }, 
            ]
        },
         ];

    const columnDefs2 = [
            {
            headerName: '',
            children: [
                { headerName: idata.kOpt==="plnt"?"품번":"공장", field: "TITLE1", 
                rowSpan: function(params) {
                  let str = params.data.TITLE1;
                  if(parseInt(params.data.RN,10) % 2 === 1){
                      return 2;
                  }else{
                      return 0;
                  }
                },
                cellClass: function(params) { 
                    return (parseInt(params.data.RN,10) % 2 === 1?["nocheckbox alignL my-class-rowspan"]:["nocheckbox alignL my-class-rowspan2"]);
                },
                valueGetter: Utils.partnoGetter,
                valueFormatter: function(params) { 
                  return (parseInt(params.data.RN,10) % 2 === 1?params.value:"");
              }
                , sortable: false, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", singleClickEdit: false, hide: false, pinned: 'left' }, //valueGetter: Utils.partnoGetter,
   
            ]
        },  // header 1
        {
            headerName: '납품누계',
            children: [
                { headerName: "검수누계", field: "NPQTY1", sortable: false, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: false, valueFormatter: Utils.numberFormatter, hide: false, pinned: 'left'}, 
            ]
        },
        {
            headerName: '월별',
            children: [
                { headerName: "1월", field: "NPQT1", sortable: false, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: false, valueFormatter: Utils.numberFormatter, hide: false}, 
                { headerName: "2월", field: "NPQT2", sortable: false, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: false, valueFormatter: Utils.numberFormatter, hide: false}, 
                { headerName: "3월", field: "NPQT3", sortable: false, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: false, valueFormatter: Utils.numberFormatter, hide: false}, 
                { headerName: "4월", field: "NPQT4", sortable: false, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: false, valueFormatter: Utils.numberFormatter, hide: false}, 
                { headerName: "5월", field: "NPQT5", sortable: false, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: false, valueFormatter: Utils.numberFormatter, hide: false}, 
                { headerName: "6월", field: "NPQT6", sortable: false, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: false, valueFormatter: Utils.numberFormatter, hide: false}, 
                { headerName: "7월", field: "NPQT7", sortable: false, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: false, valueFormatter: Utils.numberFormatter, hide: false}, 
                { headerName: "8월", field: "NPQT8", sortable: false, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: false, valueFormatter: Utils.numberFormatter, hide: false}, 
                { headerName: "9월", field: "NPQT9", sortable: false, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: false, valueFormatter: Utils.numberFormatter, hide: false}, 
                { headerName: "10월", field: "NPQT10", sortable: false, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: false, valueFormatter: Utils.numberFormatter, hide: false}, 
                { headerName: "11월", field: "NPQT11", sortable: false, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: false, valueFormatter: Utils.numberFormatter, hide: false}, 
                { headerName: "12월", field: "NPQT12", sortable: false, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: false, valueFormatter: Utils.numberFormatter, hide: false}, 
            ]
        },
         ];


    const columnDefs3 = [
            {
            headerName: '',
            children: [
                { headerName: idata.kOpt==="plnt"?"품번":"공장", field: "TITLE1", 
                rowSpan: function(params) {
                  let str = params.data.TITLE1;
          
                  if(parseInt(params.data.RN,10) % 2 === 1){
                      return 2;
                  }else{
                      return 0;
                  }
                },
                cellClass: function(params) {
                    return (parseInt(params.data.RN,10) % 2 === 1?["nocheckbox", "alignL", "my-class-rowspan"]:["nocheckbox", "alignL", "my-class-rowspan2"]);
                },
                valueGetter: Utils.partnoGetter,
                valueFormatter: function(params) { 
                  return (parseInt(params.data.RN,10) % 2 === 1?params.value:"");
              }
                , sortable: false, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", singleClickEdit: false, hide: false, pinned: 'left' }, //valueGetter: Utils.partnoGetter,
   
            ]
        },  // header 1
        {
            headerName: '납품누계',
            children: [
                { headerName: "검수누계", field: "NPQTY1", sortable: false, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: false, valueFormatter: Utils.numberFormatter, hide: false, pinned: 'left'}, 
            ]
        },
        {
            headerName: '일별',
            children: [
            ].concat(
                Array(Common.getMonthDays(idata.kGubn2+idata.kGubn3)).fill(0).map((_, i) => {
                    return {
                        headerName: (i+1).toString()+"일", field: "NPQT"+(i+1), sortable: false, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: false,valueFormatter: Utils.numberFormatter, hide: false
                    }
            })
            )
        },
         ];

         const onClick = async (e) => {
            setPanded(!panded);
    
    
            if(panded===true){
                setStyle({       
                    height :"calc(100vh - 60px - 100px - 10px)"
                    });
            }else{
                setStyle({
                    height :"calc(100vh - 60px - 50px - 100px  - 50px)"
                    });
            }
        }
 
     return(
         <>
             {/* Dialog FRAME */}
             <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />
 
             {/* Alert FRAME */}
             <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg}
                          onClose={
                              ()=> {
                                  setMessage({...message, openAlert: false});
                              }
                          }
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} />
 
             {/* Loading */}
            <Loading loading={loading} /> 

             {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
             <Ribbon
                 onSearch={onSearch} onSearchName={null}     //조회
                 onExcel={onExcel} onExcelName={null} //Excel
             >
                 {"납입지시 > 현황관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>납품대비검수현황</b>
             </Ribbon>
 
             <ContentXearch
                 optionsX={optionsX}
                 onChange={onChange}
                 onClick={onClick}/>
 
             {/* Content FRAME (Data / Grid Frame) START -------------- */}
             <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:100px */}
             <div className="grid_type02">{/* 하단 FRAME */}
                    <div className="grid_wrap02"style={{ width: "25%" }}> {/* 하단 wrap 좌측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* 납품서 발행현황 Grid */}
                                <Grid
                                    ref={grdRom92Main}
                                    gridId={"ROM92"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs1}            //  (필수) 컬럼 정의 
                                    pagination={true}                  //  쪽수 매기기 여부
                                    suppressRowTransform={false}         //  rowspan 사용여부
                                    paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={false}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={false}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}

                                    searchApi={searchApi}              // 조회
                                    // deleteApi={deleteApi2}              // 삭제
                                    // deleteRefresh={()=>{onSearch();onSearch2();}}    // 삭제이후 조회
                                    // onClearApi={onClearApi2}            // Clear
                                    onRowClickEvent={grdRom92Main_onRowClicked}     
                                    // onCellValueChangedEvent={grdRom92Main_onCellValueChanged}
                                    // insertApi={insertApi}               // 등록
                                    // updateApi={updateApi}               // 수정
                                    // insertRefresh={onSearch}            // 등록이후 
                                    // //updateRefresh={onSearch}          // 수정이후 
                                    // onClearApi={()=> {setSearchApi(false);}}                                    
                                    onClearApi={onClearApi}            // 초기화
                                    clearGrid={clearGrid}
                                    onClearGridFlag={setClearGrid}
                                    // onCellValueChangedEvent={onCellValueChanged}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid_wrap03" style={{ width: "75%" }}> {/* 하단 wrap 우측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* 납품서 상세현황 Grid */}
                                <Grid
                                    ref={grdRom92Detail}
                                    gridId={"ROM922"}                   //  (필수) Grid Id 정의
                                    columnDefs={()=> idata.kGubn1==="M"?columnDefs2:columnDefs3}            //  (필수) 컬럼 정의 
                                    pagination={true}                  //  쪽수 매기기 여부
                                    paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"38"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={false}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={false}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}

                                    searchApi={searchApi2}              // 조회

                                    onClearApi={onClearApi2}            // 초기화
                                    clearGrid={clearGrid2}
                                    onClearGridFlag={setClearGrid2}
                                    // updateApi={updateApi3}              // 수정
                                    // deleteApi={deleteApi3}              // 삭제
                                    // onCellValueChangedEvent={grdRom3lDetail_onCellValueChanged}
                                    // deleteRefresh={()=>{onSearch();onSearch2();}}   // 삭제이후
                                    // clearGrid={clearGrid3}
                                    // onClearGridFlag={setClearGrid3}
                                    // // insertApi={insertApi}               // 등록
                                    // // insertRefresh={onSearch}            // 등록이후 
                                    // updateRefresh={onSearch}          // 수정이후 
                                    // //onClearApi={()=> {setSearchApi(false); setUpdateApi(false);}}
                                    // onCellValueChangedEvent={onCellValueChanged}
                                    // onRowClickEvent={onRowClicked}
                                />
                            </div>
                        </div>
                    </div>
                </div>
             </Content>

         </>
     )
 }
 export default ROM92;