import React, { Component } from 'react';
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
import CommonUtil from '@/utils/CommonUtil'; 
import { stringToArray } from 'ag-grid-community';
 
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
const Utils = new CommonUtil();


class Chul extends Component {

    constructor(props)  {
        super(props);
        this.grdChulMain = React.createRef();

        this.state = {
            //Common
            token:Common.getToken(),
            userId :Common.getToken("id"),
            userName : Common.getToken("name"),
            tVnt1 : Common.getToken("vand"),
            tComp : Common.getToken("comp"),
    
            //조회조건
            kWrcVand : "",          
    
            //Alert 창 ( Alert 창 OPEN,  Alert 창 구분, Alert 창 메세지)
            openAlert : false, severity : "success", msg : "",
    
            //Dialog 창 (Dialog 창 OPEN, Dialog Transaction(I,U,D),  Dialog 메세지)
            openDlg : false, dlgTrans : "", dlgMsg : "", 
    
            //Common 코드 데이터
            compList : [],
            vandList : [],
            kVnt1:"",

    
            //Api (조회, 등록, 수정, 삭제)
            searchApi : false, insertApi : false, updateApi : false, deleteApi : false,

            loadingFlag : false
        }
    }

    
    /**
     * WillMount
     */
    async componentWillMount() {

        //조회조건 Setting
        this.setState({kWrcVand: (this.state.tVnt1 == "M000" ? "" : this.state.tVnt1)}); 

        //공통코도 조회 (회사구분)
        //let commonCode = await Promise.all([this.onSearchComp(`{"pgmid":"CODE", "vand":"****", "gocd" : "COMP"}`), ]);
        let commonCode = await Promise.all([this.onSearchComp(`{"pgmid":"WRCVAND"}`), "vandList"]);

        //회사구분
        this.setState({vandList: commonCode[0].vandList, loadingFlag : true});     }


    /**
     * DidMount
     */
    componentDidMount() {
    }


    // /**
    //  * WillReceiveProps
    //  */
    // componentWillReceiveProps () {
    // }

    // /**
    //  * WillUpdate
    //  * @param {*} nextProps 
    //  * @param {*} nextState 
    //  */
    // componentWillUpdate(nextProps, nextState) {
    // }

    // /**
    //  * DidUpdate
    //  * @param {*} prevProps 
    //  * @param {*} prevState 
    //  */
    // componentDidUpdate(prevProps, prevState) {
    // }

    // /**
    //  * WillUnmount
    //  */
    // componentWillUnmount() {
    // }


   


    /**
     * 공통코드 조회
    */
     onSearchComp = async (para) => {

        let commonData = this.state;
        let param = encodeURIComponent(para);

        const query = SERVER_URL + "/comcd/search1?query=" + param; 
        await fetch(query, { headers: { Authorization: this.state.token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) 
                {
                    commonData["vandList"] = data.data
                    this.setState({...commonData});
                }
            })
        return commonData;
    }



    render() {

        if (this.state.loadingFlag === false) {
            return null
        }
        else {
        
            //State
            const {token, userId, userName, tVnt1, tComp, kWrcVand,  openAlert, severity, msg,
                   openDlg, dlgTrans, dlgMsg, compList, vandList,kVnt1,
                   searchApi, insertApi, updateApi, deleteApi } = this.state;


            //Grid Clear Api               
            const onClearApi = async (e) => {
                this.setState({ searchApi : false, insertApi : false, updateApi : false, deleteApi : false})
            }


            /**
             * Control 변경 Event
             * @param {*} e 
             */
            const onChange = async (e) => {
                const { name, value } = e.target;

                let newSearchs = this.state;
                newSearchs[name] = value
                this.setState({ ...newSearchs });
            }

        
            /**
             * Grid Event 
             * @param {*} e 
            */
            const onCellValueChanged = async (e) => {
            }

            const onRowClicked = async (e) => {
            }


            /**s
             * 검색조건
            */
            const optionsX = {
                0: [
                    //업체 
                    {
                     "formatter": "select", "name": "kVnt1", "labelName": "업체", "type": "text",  
                     "codeMode": true,
                     "selectAll": "none",
                     "codes": null,
                     "comms": this.state.vandList,
                     "align": "left", 
                    }, 
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                ]
            }

        
        
            /**
             * 조회
             * @param {*} e 
             */
            const onSearch = async (e) => { 
                
                //업체코드 입력 Check
                if (this.state.kVnt1 === "") {
                    
                    this.setState({ openAlert : true, 
                                    severity : "info",
                                    msg : "업체 코드를 입력해 주세요."});
                    return;
                }

                let para = `{"kVnt1":"${this.state.kVnt1}"}`;
                let param = encodeURI(para);

                const query = SERVER_URL + "/popup/chul_search?query=" + param;
                this.setState({ searchApi : query})
            }



            /**
             * 신규 Row추가
             * @param {*} e 
             */
            const onAddRow = async (e) => {
             
            }



            /**
             * 입력
             * @param {*} e 
             */
            const onInsert = async (e) => {

            }



            /**
             * 수정 
             * @param {*} e 
             * @returns 
             */
            const onUpdate = async (e) => {

              
            }



            /**
             * 삭제
             * @param {*} e 
             */
            const onDelete = async (e) => {
                
            }



            //deleteBlank
            function deleteBlank(argval){

                if (argval === null || argval === undefined) return "";

                for(var i=argval.length-1 ;i >= 0; i--){
                    
                    if(argval.charCodeAt(i) !== 12288 && argval.charCodeAt(i) !== 32 ) //charCodeAt(i)  
                    return(argval.substring(0,i+1));
                }
                return(argval.length>0?argval.substring(0,0):"")
            }



            // input check
            const inputCheck = (gbn) => { 

                let inputFlag = true;

                //select nodes
                let selectedData = this.grdChulMain.current.getSelectedNodes();

                // 선택 값 체크
                if (selectedData.length === 0) {
                    //Alert 창 OPEN
                    this.setState({ openAlert : true, 
                                    severity : "info",
                                    msg : "선택한 데이터가 없습니다."});
                    return false;
                }


                return inputFlag;
                
            }
            

            //AlertDialogResult
            const AlertDialogResult = async (e) => {

                //AlertDialog 초기화
                this.setState({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        
                //DialogResult
                if (e.result == "N") return;
        
                //Transaction 구분
                switch (e.Trans) 
                {
                    //등록
                    case "I" :
                        break;
                    //수정
                    case "U" :
                        break;
                    //삭제
                    case "D" :
                        break;
                }
            }

        

            //Grid DropDownList Value
            const extractValues = (mappings) => {
                return  Object.keys(mappings);
            }

            //Grid DropDownList Display
            const lookupValue = (mappings, key) => {
                return mappings[key];
            }        

        
            /**
             *Grid Column 정의 
            */
            const columnDefs = [
                { headerName: "납품일시", field: "npdt1", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, cellClass: ["nocheckbox", "alignC"],  singleClickEdit: true, hide: false },  
                { headerName: "납품서번호", field: "wrcdlno", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },  
                { headerName: "SEQ", field: "wrcdseq", sortable: true, filter: true, flex: 1, minWidth: 50, editable: false, cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },  
                { headerName: "발주번호", field: "wrcodno", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }, 
                { headerName: "품번", field: "wrcptno", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }, 
                { headerName: "수량", field: "wrcnpqt", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false }, 
                { headerName: "Message", field: "mesg", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }, 
            ];


        
            return (
                <>
                    {/* Dialog FRAME */}
                    <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

                    {/* Alert FRAME */}
                    <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {this.setState({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
        
                    <button onClick={() => {this.props.onClose()}} style = {{border:"none", float:"right"}}> <img src="/images/button/close_button.png" alt="logout" /></button> 

                    {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
                    <Ribbon
                        newTabUnused={true}                         // newTabUnused : newTab 표출 버튼 true : 사용안함 // 선언안할경우 default = newTab 사용
                        onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                        onSearch={onSearch} onSearchName={null}     //조회
                    >
                    <b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>출하미전송조회</b>
                    </Ribbon>
                    <b style={{ color: "#FF0000", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>※ 다음은 출하정보전송 오류 및 미전송 내역입니다.
                        오류조치 후 반드시 출하정보 전송을 하셔야합니다</b>
        
        
                    {/* ContentXearch Frame : 검색조건 */}
                    <ContentXearch
                        optionsX={optionsX}
                        onChange={onChange} />
        
                    <div className={"grid_type01"} style={{ marginTop: "10px"}} > {/* 상단 FRAME */}
                        <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                            <div className="grid_cont_box" style={{ height: "417px", width : "885px" }}>
                                <Grid
                                    ref={this.grdChulMain}
                                    gridId={"CHUL"}                  //  (필수) Grid Id 정의
                                    columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={false}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    searchApi={searchApi}               // 조회
                                    //insertApi={insertApi}               // 등록
                                    onClearApi={onClearApi}
                                    //insertRefresh={onSearch}            // 등록이후 
                                    //updateApi={updateApi}               // 수정
                                    //updateRefresh={onSearch}            // 수정이후 
                                    //deleteApi={deleteApi}               // 삭제
                                    //deleteRefresh={onSearch}            // 삭제이후 
                                    
                                    //onClearApi={()=> {setSearchApi(false); setUpdateApi(false);}}
                                    onCellValueChangedEvent={onCellValueChanged}
                                    onRowClickEvent={onRowClicked}
                                    
                                />
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        
    }

}


export default Chul;