//납입지시 - ERP_CKD 출하관리
import { Route, Switch } from 'react-router-dom';
import ROM3I from '@/pages/A03/ROM3I';  //납품서발행
import ROM3K from '@/pages/A03/ROM3K';  //발행취소/재인쇄
import ROM4K from '@/pages/A03/ROM4K';  //부품식별표(중포장)
import ROM4R from '@/pages/A03/ROM4R';  //부품식별표(용기)
import ROM4X from '@/pages/A03/ROM4X';  //부품식별표(중포장2D)
import ROM4Q from '@/pages/A03/ROM4Q';  //출하정보전송
import ROM4T from '@/pages/A03/ROM4T';  //납품서 삭제내역
import ROMAA from '@/pages/A03/ROMAA';  //납품서 그룹발행
import ROMAB from '@/pages/A03/ROMAB';  //납품서 발행취소/재인쇄


function A03() {
    return ( 
        <Switch>
            <Route path='/A03/ROM3I' component={ROM3I} />
            <Route path='/A03/ROM3K' component={ROM3K} />
            <Route path='/A03/ROM4K' component={ROM4K} />
            <Route path='/A03/ROM4R' component={ROM4R} />
            <Route path='/A03/ROM4X' component={ROM4X} />
            <Route path='/A03/ROM4Q' component={ROM4Q} />
            <Route path='/A03/ROM4T' component={ROM4T} />
            <Route path='/A03/ROMAA' component={ROMAA} />
            <Route path='/A03/ROMAB' component={ROMAB} />
        </Switch>
    );
}

export default A03;
