/**
 * @Page RNTM1
 * @Description [RNTM1]수신자료관리 - 모듈단가자료조회 - 모듈BOM
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
 import UploadModal from "@/components/organisms/UploadModal"
  
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 //분류 구분
 const gubn = [ { "comCode": "CN", "text": "CN 모듈적용조건" },
                { "comCode": "CP", "text": "CP 모듈CODE NO(CLR) 대 P/NO(CLR)" },
                { "comCode": "GL", "text": "GL 모듈CODE NO 대 UPGVC" },
                { "comCode": "IM", "text": "IM ITEM ID" },
                { "comCode": "MI", "text": "MI MI" }, 
                { "comCode": "MM", "text": "MM MAIN 모듈 CODE NO 대 모듈 CODE NO 현황" },
                { "comCode": "ST", "text": "ST STR" }, 
                { "comCode": "WO", "text": "WO W/O별 MAIN 모듈 CODE NO 현황" }]  

 const Utils = new CommonUtil();

 
  function RNTM1() {
 
    const token = Common.getToken();
    const userId = Common.getToken("id");
    // const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const grdRntm1Main = useRef();   //Grid Main  

   const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 

    
    //Data
    const [idata, setData] = useState({
        //조회조건
        kComp : tComp,      //회사구분
        kVnt1 : ckVnt1,
        kDocd : "",         //문서코드 
        kTotcnt : "",       //전체건수
        kGubn : "CN",       //분류
        //코드 데이터
        docdList : [],  //문서코드
        compList : [],  //회사코드
    }); 
    
            
    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;
    // const [uploadInfo, setUploadInfo] = useState({ vnt1: tVnt1, subDir: "DATA" });
    const [uploadInfo, setUploadInfo] = useState({ vnt1: ckVnt1, subDir: "DATA" });

  
    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
  
   
    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    const [openUpload, setOpenUpload] = React.useState(false); // Modal 항목 setting
    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 50px)"});
    
    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);

    }, []);

    

    /**
     * 공통코드 조회 (조회조건)
     */
     const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData1 = async (para, name) => {

            let param = encodeURIComponent(para);
            const query = SERVER_URL + "/comcd/search1?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    {
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData1(para, name);
    }
     

    const onSearchCommonAsync = async (para, name) => {
        let commonData = idata;
    
        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success)
                    {
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        await fetchData(para, name);
    }
    


    /**
     *MOUNT 
    */
    useEffect( () => {
        
        //공통코드 조회 (회사구분)
        onSearchCommon(`{"pgmid":"CODE", "vand":"****", "gocd":"COMP"}`, "compList");

        //공통코드 조회 (문서코드)
        onSearchCommonAsync(`{"pgmid":"NETP_DOCCODE", "Key0" : "M1", "Key1":"*", "kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "docdList");

    }, []);
  
  
  
    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value} = e.target;

        let newSearchs = idata; 
        newSearchs[name] = value
        setData({ ...newSearchs });


         if (name === "kComp")  {

            newSearchs["kDocd"] = "";
            setData({ ...newSearchs });

            //공통코드 조회 (문서코드)
            onSearchCommonAsync(`{"pgmid":"NETP_DOCCODE", "Key0" : "M1", "Key1":"*", "kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "docdList");
        }

        //onSearch()
    }
 
  
  
    /**
     * Grid Event 
     * @param {*} e 
     */
    const grdRntm1Main_onCellValueChanged = async (e) => {
    }
    const grdRntm1Main_onRowClicked = async (e) => {
    }


  
  
    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //회사구분
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": false,
                "selectAll": "",
                "codes": null,
                "comms": idata.compList,
                "align": "left", 
            }, 
            //문서코드
            {
                "formatter": "select", "name": "kDocd", "labelName": "문서코드", "type": "text", "defaultValue": idata.kDocd,  
                "codeMode": false,
                "selectAll": "all",
                "codes": null,
                "comms": idata.docdList,
                "align": "right", 
            }, 
             //분류
             {
                "formatter": "select", "name": "kGubn", "labelName": "분류", "type": "text", "defaultValue": null,  
                "codeMode": false,
                "selectAll": "",
                "codes": gubn,
                "comms": null,
                "align": "right", 
            //  "items": [{ "comCode": "CN", "text": "CN 모듈적용조건" }, { "comCode": "CP", "text": "CP 모듈CODE NO(CLR) 대 P/NO(CLR)" }, { "comCode": "GL", "text": "GL 모듈CODE NO 대 UPGVC" }
            //                 , { "comCode": "IM", "text": "IM ITEM ID" }, { "comCode": "MI", "text": "MI MI" }, { "comCode": "MM", "text": "MM MAIN 모듈 CODE NO 대 모듈 CODE NO 현황" }
            //                 , { "comCode": "ST", "text": "ST STR" }, { "comCode": "WO", "text": "WO W/O별 MAIN 모듈 CODE NO 현황" }]
            }, 
             //전체건수
             {
                "formatter": "input", "name": "kTotcnt", "labelName": "전체건수", "type": "text", "defaultValue": idata.kTotcnt, "readOnly" : true,
                "codeMode": false,
                "selectAll": "none",
                "codes": null,
                "comms": "",
                "align": "right", 
            }, 
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
            
        ],
        
    }
  
  
  
    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {
      
        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kDocd":"${idata.kDocd}","kGubn":"${idata.kGubn}" }`;
        
        let param = encodeURI(para);

        const query = SERVER_URL + "/rntm1/search?query=" + param;
        setSearchApi(query); // Search API
    }

    /**
    * 파일생성
    * @param {*} e 
    */
   const onUpload = async (e) => {

    setOpenUpload(true);
    }

    const handleUploadClickOpen = () => {

    setOpenUpload(true);
    };

    const handleUploadClose = () => {
    setOpenUpload(false);
    //onSearchDocd();
    };
  
  
    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
    }

  
      
    /**
     * 입력 (발행)
     * @param {*} e 
     */
    const onInsert = async (e) => {
    }
  
  
  
    /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {
    }


  
    /**
     * 삭제
     * @param {*} e 
     */
    const onDelete = async (e) => {
    }



    /**
     * 엑셀
     * @param {*} e 
     */
    const onExcel = async (e) => {
    }


    
    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});


        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans) 
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D" :
                break;

            case "D3" :
                break;
        }
    }
    
    

        //조회건수
        const onSetCount = () => {
            //전체건수 Setting
            let newSearchs = idata; 
            newSearchs["kTotcnt"] = (grdRntm1Main.current.getRowCount()).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            
            setData({ ...newSearchs });
        }
        

    /**
     * Grid Bind Data
     * @returns 
    */
    const onGridBindData = (para) => {
    }
 
     
  
    /**
     *Grid Column 정의 
    */
    //Main Grid Column 
    let columnDefs = [];
    
    //CN 모듈적용조건
    const columnDefsCN = [  
        { headerName: "순번", field: "rn", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "차종", field: "col04", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "Main 모듈코드", field: "col05", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "모듈코드", field: "col06", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false  },
        { headerName: "모듈명", field: "col08",  sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "Color", field: "col09",  sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "EFF-IN", field: "col10", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
        { headerName: "EFF-OUT", field: "col11", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
        { headerName: "최초등록일", field: "col12", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false},
        { headerName: "최신수정일", field: "col13", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false}    
    ];
    
    //CP 모듈CODE NO(CLR) 대 P/NO(CLR)
    const columnDefsCP = [  
        { headerName: "순번", field: "rn", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "모듈코드", field: "col04", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false  },
        { headerName: "PART NO", field: "col05", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false  },
        { headerName: "USAGE", field: "col06", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "구분", field: "col07",  sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "EFF-IN", field: "col08",  sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "EFF-OUT", field: "col09", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
        { headerName: "최초등록일", field: "col10", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false},
        { headerName: "최신수정일", field: "col11", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false} 
    ];

    //GL 모듈CODE NO 대 UPGVC
    const columnDefsGL = [  
        { headerName: "순번", field: "rn", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "모듈코드", field: "col04", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false  },
        { headerName: "UPGVC", field: "col05", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false  },
        { headerName: "SEQ", field: "col06", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "구분", field: "col07",  sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "EFF-IN", field: "col08",  sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "EFF-OUT", field: "col09", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
        { headerName: "최초등록일", field: "col10", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false},
        { headerName: "최신수정일", field: "col11", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false}   
    ];
    
    //IM ITEM ID
    const columnDefsIM = [  
        { headerName: "순번", field: "rn", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "차종", field: "col04", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "모듈코드", field: "col05", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "Part No", field: "col06", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false  },
        { headerName: "Part Name", field: "col07",  sortable: true, filter: true, flex: 1, minWidth: 180, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false  },
        { headerName: "Color", field: "col08",  sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
        { headerName: "CCN", field: "col09", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
        { headerName: "구분", field: "col10", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
        { headerName: "최초등록일", field: "col11", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false},
        { headerName: "최신수정일", field: "col12", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false}
    ];

    //MI MI
    const columnDefsMI = [  
            { headerName: "순번", field: "rn", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "SPEC", field: "col04", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false  },
            { headerName: "MI", field: "col06", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false  },
        ]; 

    //MM MAIN 모듈 CODE NO 대 모듈 CODE NO 현황
    const columnDefsMM  = [  
         { headerName: "순번", field: "rn", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
         { headerName: "Main 모듈코드", field: "col04", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false  },
         { headerName: "모듈코드", field: "col05", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false  },
         { headerName: "구분", field: "col06", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
         { headerName: "EFF-IN", field: "col07",  sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
         { headerName: "EFF-OUT", field: "col08",  sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
         { headerName: "최초등록일", field: "col09", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false},
         { headerName: "최신수정일", field: "col10", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false}
     ];
    
    //ST STR
    const columnDefsST  = [  
         { headerName: "순번", field: "rn", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
         { headerName: "차종", field: "col04", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
         { headerName: "모듈코드", field: "col05", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false  },
         { headerName: "PART NO", field: "col06", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true,valueGetter: Utils.partnoGetter,hide: false  },
         { headerName: "P/FIX", field: "col07",  sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
         { headerName: "PART NO", field: "col08",  sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true,valueGetter: Utils.partnoGetter, hide: false  },
         { headerName: "S/FIX", field: "col09", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,hide: false},
         { headerName: "수량", field: "col10", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true,valueFormatter: Utils.numberFormatter, hide: false},
         { headerName: "EITEM", field: "col11", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,  hide: false},
         { headerName: "EFF-IN", field: "col12", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
         { headerName: "EFF-OUT", field: "col13", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
         { headerName: "최초등록일", field: "col14", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false},
         { headerName: "최신수정일", field: "col15", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false}
     ];
    
    //WO W/O별 MAIN 모듈 CODE NO 현황
    const columnDefsWO = [  
         { headerName: "순번", field: "rn", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
         { headerName: "W/O", field: "col04", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false  },
         { headerName: "SPEC", field: "col05", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false  },
         { headerName: "외장", field: "col06", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
         { headerName: "내장", field: "col07",  sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"],singleClickEdit: true, hide: false  },
         { headerName: "모듈코드", field: "col08",  sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"],singleClickEdit: true, hide: false  },
         { headerName: "구분", field: "col09", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false},
         { headerName: "EFF-IN", field: "col10", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,hide: false},
         { headerName: "EFF-OUT", field: "col11", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
         { headerName: "최초등록일", field: "col12", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false},
         { headerName: "최신수정일", field: "col13", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false}
     ];
     

    if(idata.kGubn === ""){
        columnDefs = columnDefsCN;
    }
    else if(idata.kGubn === "CN"){
        columnDefs = columnDefsCN;
    }
    else if(idata.kGubn === "CP"){
        columnDefs = columnDefsCP;
    }
    else if(idata.kGubn  === "GL"){
        columnDefs = columnDefsGL;
    }
    else if(idata.kGubn  === "IM"){
        columnDefs = columnDefsIM;
    }
    else if(idata.kGubn  === "MI"){
        columnDefs = columnDefsMI;
    }
    else if(idata.kGubn  === "MM"){
        columnDefs = columnDefsMM;
    }
    else if(idata.kGubn  === "ST"){
        columnDefs = columnDefsST;
    }
    else if(idata.kGubn  === "WO"){
        columnDefs = columnDefsWO;
    }

    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 55px  - 50px)"
              });
        }
    }

      
    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

            {/* Upload FRAME */}
            <UploadModal
                open={openUpload}
                onClose={handleUploadClose} 
                onClickOpen={handleUploadClickOpen}
                title={"파일 업로드"}
                fileId={"HKCM1"}
                filePath={"C:\\HKC\\VAN\\HKCM1.TXT"}
                uploadInfo={uploadInfo}
                accp=".txt"
                maxFiles={1}
                validatorName={"txt"}
                uploadApi={SERVER_URL + "/hkc/upload"}
            />

            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onSearch={onSearch} onSearchName={null} //조회
                onUpload={onUpload} onUploadName={"생성"}//생성 
            >
                {"수신자료관리 > 모듈단가자료조회 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>모듈BOM [ M1 ]</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick={onClick} />


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={{ height: "calc(100vh - 60px - 50px - 55px  - 50px)" }}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:55px */}
                <div className={"grid_type01"} > {/* 상단 FRAME */}
                    <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                        <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                            <Grid
                                ref={grdRntm1Main}
                                gridId={"RNTM1"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                pagination={true}                   //  쪽수 매기기 여부
                                paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"100"}          //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={true}      // cell selection drag mode
                                isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                onClearApi={()=> {onSetCount();onClearApi()}}
                                onCellValueChangedEvent={grdRntm1Main_onCellValueChanged}
                                onRowClickEvent={grdRntm1Main_onRowClicked}
                            />
                        </div>
                    </div>
                </div>
            </Content>
        </>
    )
  }
  export default RNTM1;
