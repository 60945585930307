/**
 * @Page REP02
 * @Description [REP02] 납입지시 - 기초관리 - 자료생성(수동)
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
 import UploadModal from "@/components/organisms/UploadModal";
 
 
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
 const Utils = new CommonUtil();
 
 function REP02() {
     const token = Common.getToken();
     const userId = Common.getToken("id");
     const tVnt1 = Common.getToken("vand");
     const grdRep02Main = useRef();   //Grid Main

     const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
     const [panded, setPanded] = React.useState(true);
     const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 100px - 30px)"});
 
     //Data
     const [idata, setData] = useState({
         //조회조건
         kWrcVand : (ckVnt1 === "M000" ? "M000" : ckVnt1),    //업체코드
         kWrcVandReadOnly : (ckVnt1 !== "M000"),   //업체코드 로그인시 입력 불가능
         kVnt1 : tVnt1,   //로그인시 업체코드
         kWrcVdnm : "",    //업체명
         kComp : "EHMC", //회사코드
         kCheck : "",
         kPage : "REP02",
         //코드 데이터
         vnt1List : [],  //T1업체
     }); 
      
     const [message, setMessage] = React.useState( {
         openAlert : false,      
         severity : "success",   
         msg : ""                
     });
     const { openAlert, severity, msg } = message;
     const [uploadInfo, setUploadInfo] = useState({ vnt1: tVnt1, subDir: "DATA" });
 
     //Dialog
     const [dlgMessage, setDlgMessage] = React.useState( {
         openDlg : false,    
         dlgTrans : "",      // Dialog Transaction(I,U,D)
         dlgMsg : ""         
     });
     const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
 
     const [typeOpen, setTypeOpen] = React.useState(false);
 
     //Grid Search, Insert, Update, Delete Api
     let [searchApi, setSearchApi] = useState(false); 
     let [check, setCheckApi] = useState(false); 
     let [insertApi, setInsertApi] = useState(false); 
     let [updateApi, setUpdateApi] = useState(false); 
     let [deleteApi, setDeleteApi] = useState(false); // deleteApi 항목 setting
     let [filedata, setFileData] = useState([]);   //file 
     const [openUpload, setOpenUpload] = React.useState(false); // Modal 항목 setting
     
     const onClearApi = useCallback(async (e) => {
         setSearchApi(false);
         setCheckApi(false)
         setInsertApi(false);
         setUpdateApi(false);
         setDeleteApi(false);
     }, []);
     
     

    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURIComponent(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data;
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }

 
 
     /**
      *MOUNT 
      */
     useEffect( () => {
         
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
         
         //공통코드 조회
         onSearchCommon(`{"pgmid":"CODE", "vand":"****", "gocd" : "00"}`); // ???

         //조회
         onSearch();
     }, []);
 
 
 
     /**
      * Control 변경 Event
      * @param {*} e 
      */
     const onChange = async (e) => {
         const { name, value } = e.target;
 
         let newSearchs = idata;
         let newUploadInfo = uploadInfo;
         newSearchs[name] = value
 
         setData({ ...newSearchs });
         


         if(name ==="kWrcVand"){
            newUploadInfo["vnt1"] = value;
            setUploadInfo({ ...newUploadInfo });
         }
 
         //조회 Call    
         onSearch(); 
 
     }
 
 
 
     /**
      * Grid Event 
      * @param {*} e 
      */
     const onCellValueChanged = async (e) => {
     }
 
     const onRowClicked = async (e) => {
     }
 
 
 
     /**
      * 검색조건
      */
      const optionsX = {
         0: [
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": false,
                "selectAll": "",
                //"codes": CODES["comp"],
                "comms": null,
                "align": "left", 
                "items": [{ "comCode": "EHMC", "text": "EHMC 현대자동차(주)ERP" },{ "comCode": "EKMC", "text": "EKMC 기아자동차(주)ERP" },
                          //{ "comCode": "MHMC", "text": "MHMC 현대자동차" }, // .txt 사용 전용 회사(old 버전용)
                          { "comCode": "EHKR", "text": "EHKR 남양시작차" }]
            }, //회사구분
            {
                "formatter": "select", "name": "kWrcVand", "labelName": "T1 업체", "type": "text", "defaultValue": idata.kWrcVand,  
                "codeMode": true,
                "selectAll": "",
                "comms": idata.vnt1List,                
                "align": "left", 
            }, //t1업체
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" }
         ]
     }
 
 
 
     /**
      * 조회 
      * @param {*} e 
      * @returns 
      */
     const onSearch = async (e) => {

         let para = `{"kWrcVand":"${idata.kWrcVand}","kWrcVdnm":"${idata.kWrcVdnm}","kComp":"${idata.kComp}","kPage":"${idata.kPage}","kVnt1":"${idata.kVnt1}"}`;
         let param = encodeURI(para);
 
         const query = SERVER_URL + "/rep02/search?query=" + param;
 
         setSearchApi(query); // Search API
     }
 
 
 
     /**
      * 신규 Row추가
      * @param {*} e 
      */
     const onAddRow = async (e) => {
         
     }
     const onUpload = () => {

        let selectedData = grdRep02Main.current.getSelectedNodes();
        let newSearchs = idata; 
        
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "데이터를 선택해주세요."});
            return false;
        }
        newSearchs["kCheck"] = selectedData[0].data.wrcdes2;
        newSearchs["vnt1"] = idata.kWrcVand;
        setData({ ...newSearchs });
        
        
        setOpenUpload(true);
    };
     const handleUploadClickOpen = () => {
        
        setOpenUpload(true);
    };
 
    const handleUploadClose = () => {
        setOpenUpload(false);
    };
   
     /**
      * 입력
      * @param {*} e 
      */
     const onInsert = async (e) => {
 
         //input check
         let input = await Promise.all ([inputCheck('I')]);
         if ( !input[0]) return;

         //팝업
         setTypeOpen(true);
     }
 
 
 
     /**
      * 수정 
      * @param {*} e 
      * @returns 
      */
     const onUpdate = async (e) => {
 
         
     }
 
 
 
     /**
      * 삭제
      * @param {*} e 
      */
     const onDelete = async (e) => {
         
     }
 
 
 
     const inputCheck = (gbn) => { 
 
         let inputFlag = true;
         let selectedData = grdRep02Main.current.getSelectedNodes();
 
         if (selectedData.length === 0) {
             setMessage({ openAlert : true, 
                          severity : "info",
                          msg : "선택한 데이터가 없습니다."});
             return false;
         }

         return inputFlag;
     }
 
 
 
     //Transaction AlertDialogResult
     const AlertDialogResult = async (e) => {
 
         //AlertDialog 초기화
         setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
 
         //DialogResult
         if (e.result == "N") return;
 
         //Transaction 구분
         switch (e.Trans) 
         {
             //등록
             case "I" :
                 break;
             //수정
             case "U" :
                 break;
         }
     }
     
     
 
 
     /**
      *Grid Column 정의 
      */
     const columnDefs = [
         { headerName: "WRCCOD1", field: "WRCCOD1", sortable: true, filter: true, flex: 1, minWidth: 30, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true}, 
         { headerName: "생성자료", field: "WRCDES1", sortable: true, filter: true, flex: 1, minWidth: 30, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, 
          checkboxSelection : params=> (params.data.gubn === 'N'&& idata.kVnt1!=idata.kWrcVand) ? false : true, hide: false}, //구분이 'N'이고 선택한 업체와 로그인한 업체가 다르면 체크박스 X
         { headerName: "생성파일명", field: "WRCDES2", sortable: true, filter: true, flex: 1, minWidth: 180, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false}, 
         { headerName: "총건수", field: "COL1", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },  //valueFormatter : Utils.numberFormatter
         { headerName: "생성건수", field: "COL2", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false }, 
         { headerName: "중복건수", field: "COL3", sortable: true, filter: true, flex: 1, minWidth: 50, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false }, 
     ];

     const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 45px - 10px)",
                padingTop:"5px"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 100px - 30px)",
                padingTop:"5px"
              });
        }
    }
    
     return(
         <>
             {/* Dialog FRAME */}
             <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />
 
             {/* Alert FRAME */}
             <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
 
            {/* Upload FRAME */}
            <UploadModal
                open={openUpload}
                onClose={handleUploadClose}
                onClickOpen={handleUploadClickOpen}
                title={"CSV 업로드"}
                fileId={(idata.kCheck).substr(11,5)}
                filePath={idata.kCheck}
                uploadInfo={uploadInfo}
                accp=".csv"
                maxFiles={1}
                validatorName={"csv"}
                uploadApi={SERVER_URL + "/erp/upload1"}
                data={idata.vnt1List}
            />
            
             {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
             <Ribbon
                onSearch={onSearch} onSearchName={null} //조회
                onUpload={onUpload}   //자료생성
             >
                {"납입지시 > 기초관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>자료생성(수동)</b>
             </Ribbon>
 
 
             {/* ContentXearch Frame : 검색조건 */}
             <ContentXearch
                 optionsX={optionsX}
                 onChange={onChange}
                 onClick = {onClick} />
 
 
             {/* Content FRAME (Data / Grid Frame) START -------------- */}
             <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:55px */}
                 <div className={"grid_type01"} > {/* 상단 FRAME */}
                     <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                         <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                             <Grid
                                 ref={grdRep02Main}
                                 gridId={"REP02"}                    //  (필수) Grid Id 정의
                                 columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                 pagination={false}                  //  쪽수 매기기 여부
                                 paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                 paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                 rowMultiSelectWithClick={false}     //  멀티 클릭 가능 여부
                                 suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                 rowSelection={"single"}             //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                 enableRangeSelection={false}        
                                 enableCellTextSelection={true}      // cell selection drag mode
                                 isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                 displayNo = {false}
                                 checkboxSelection={check} 
                                 searchApi={searchApi}               // 조회
                                 insertApi={insertApi}               // 등록
                                 updateApi={updateApi}               // 수정
                                 deleteApi={deleteApi}               // 삭제
                                 deleteRefresh={onSearch}            // 삭제이후 
                                 onClearApi={onClearApi}
                                 onCellValueChangedEvent={onCellValueChanged}
                                 onRowClickEvent={onRowClicked}
                                 
                             />
                         </div>
                     </div>
                 </div>
             </Content>
         </>
     )
 }
 export default REP02;
