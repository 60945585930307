/**
 * @Page WrapErpLabelPrint_C_NEW
 * @Description [ROM4H / ROM4N]납입지시 - ERP_OEM출하관리 - 부품식별표(VMI / PO) :: [OEM 내수] 부품식별표(VMI / PO) 인쇄  ::  C 12매
 **/
import React from "react";
import CustomDataMatrix from '@/components/atoms/CustomDataMatrix';
import * as Common from '@/utils/Common';
import styled from 'styled-components';

class WrapErpLabelPrint_C_NEW extends React.Component {

   constructor(props) {
       
       super(props);
       this.state = { };
}

// '바코드 = 업체코드(4) + 품번(15) + 수량(5) + LOTNO(3) + 일련번호(4)
// tmp(0) '회사구분 / tmp(1) '지역 / tmp(2) '공장 / tmp(3) '차종 / tmp(4) '납품장소(하치장) 
// tmp(5) '품번 / tmp(6) '품명 / tmp(7) '납품수량 / tmp(8) '바코드 / tmp(9) '납품일자
// tmp(10) '업체명 / tmp(11) "" '공정(공백) / tmp(12) '업체코드 / tmp(13) "" ' LOT NO / tmp(14) "" 'HPC(공백)
// tmp(15) 'CASE(CKD) / tmp(16) "" '발행번호(바코드만들어서 던짐) / tmp(17) "" '용기번호 / tmp(18) '부착매수 / tmp(19) 'LH/RH
// tmp(20) "" '사양 / tmp(21)  '발주번호(공백) / tmp(22)  'FROM-ORDER(CKD , OEM은 공정) / tmp(23)  'CKD는 공백, OEM은 HPC / tmp(24)  '용기당수
// TMP(25) = '출력1 / TMP(26) = 'x좌표1 / TMP(27) = 'y좌표1 / TMP(28) = '색상1 / TMP(29) = 'FONT/S1
// TMP(30) = '출력2 / TMP(31) = 'x좌표2 / TMP(32) = 'y좌표2 / TMP(33) = '색상2 / TMP(34) = 'FONT/S2 / TMP(35) = '한글품명 / TMP(36) = 'RHD


   render() {

    const PrintStyleC = styled.div`
    @media all {
    
        body {
            margin: 0px;
            padding: 0px;
            font-family: "HY견고딕";
        }
        .HYheadM5 {
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:5pt;
        }
        .HYheadM6{
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:6pt;
        }
        .NmheadM6{
            font-family: "NanumGothic";
            font-weight:bold;
            font-size:6pt;
        }
        .HYheadM8{
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:8pt;
            word-break:break-all;
        }
        .HYheadM10{
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:10pt;
            word-break:break-all;
        }
        .HYheadM11{
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:11pt; 
        }
        .HYheadM12{
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:12pt;
            word-break:break-all;
        }
        .HYheadM14 {
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:14pt;
            word-break:break-all;
        }
        .HYheadM16 {
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:16pt;
            word-break:break-all;
        }
        .HYheadM18 {
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:18pt;
            word-break:break-all; 
        }
        .HYheadM9 {
            font-family: "HY견고딕";
            font-size:9pt;
            word-break:break-all;
            font-weight:bold;
        }  
        .HYheadM24 {
            font-family: "HY견고딕";
            font-size:24pt;
            word-break:break-all;
        }
        .HYheadM25 {
            font-family: "HY견고딕";
            font-weight:bold;
            font-size:25pt;
            word-break:break-all;
        }
                    
        .cell { display : flex; white-space: nowrap; z-index: 99;}

        .cell_hori { display : flex; justify-content: center; } // 수평 중앙정렬 → 

        .cell_veri { display : flex; align-items: center; } // 수직 중앙정렬 ↓

        .aligR { flex-direction: row-reverse; }
        
        .row2  { display: flex; flex-flow: row nowrap; }

        .row2>.cell:nth-child(1)  { width: 3.779527559px ;  } // background : red ; 
        .row2>.cell:nth-child(2)  { width: 26.45669291px ;  } // background : blue ; 
        .row2>.cell:nth-child(3)  { width: 5.669291339px ;  } // background : orange ; 
        .row2>.cell:nth-child(4)  { width: 5.669291339px ;  } // background : purple ; 
        .row2>.cell:nth-child(5)  { width: 5.669291339px ;  } // background : beige ; 
        .row2>.cell:nth-child(6)  { width: 45.35433071px ;  } // background : pink ; 
        .row2>.cell:nth-child(7)  { width: 28.34645669px ;  } // background : orange ; 
        .row2>.cell:nth-child(8)  { width: 5.669291339px ;  } // background : green ; 
        .row2>.cell:nth-child(9)  { width: 39.68503937px ;  } // background : yellow ; 
        .row2>.cell:nth-child(10) { width: 18.8976378px  ;  } // background : red ; 
        .row2>.cell:nth-child(11) { width: 15.11811024px ;  } // background : navy ; 
        .row2>.cell:nth-child(12) { width: 5.669291339px ;  } // background : green ; 
        .row2>.cell:nth-child(13) { width: 51.02362205px ;  } // background : pink ; 
        .row2>.cell:nth-child(14) { width: 3.779527559px ;  } // background : red ; 
        .row2>.cell:nth-child(15) { width: 3.779527559px ;  } // background : blue ; 
    }
    `;

    const { printData } = this.props;
    
    const { kStart, chkMetal, kCkdOem, chkNpdt, chkCarc, chkLtno, kCarc, kCarcNm } = this.props.idata;

    const mmToPx =(mm) => { let px = mm * 3.7795275591; return px; }
    
    const listSize = printData.length;
    
    let idx = -1;
    let str = parseInt(kStart)-1;       

    idx =idx + str; // 시작 위치

    let vseq = 0; // 바코드 표출용 - vseq

    let remainChk = 0; // remain 표출 체크용 - remainChk -- 신규 품번의 vseq 담기
    
    let mainSearchedDivArr = []; // 전체 반복 이후 새로운 배열에 전체로 담아서 진행한다.

    const cardInfo_S = 
        printData ? printData.map((data, index) => {
        
        if(index === 0){
            vseq = parseInt(data.SEQ) -1; // 바코드 표출 vseq용
        }

        // remain 표출 체크용 - remainChk -- 신규 품번의 vseq 담기
        remainChk = vseq;  // 새 품번의 remain 여부 체크하기
        
        let npqt = parseInt(data.NPQT); // 납품수량
        let qypc = parseInt(data.QYPC); // 용기당 수
        let stck = parseInt(data.STCK); // 부착면 수 

        // 추가 표시 컬러 체크 -- START
        let tmp25 = data.TMP25; // WRCLBL1	이중인쇄1 내용
        let tmp28 = data.TMP28; // WRCCOL1	이중인쇄1 컬러

        let tmp30 = data.TMP30; // WRCLBL2	이중인쇄2 내용
        let tmp33 = data.TMP33; // WRCCOL2	이중인쇄2 컬러

        let color1 = "";
        let color2 = "";

        let dualPrint1 = "F"; 
        let dualPrint2 = "F"; 

        if(tmp25!==""){

            dualPrint1 = "T";

            if(tmp28==="B"){ color1 = "blue";
            }else if(tmp28==="G"){ color1 = "green";
            }else if(tmp28==="R"){ color1 = "red";
            }else if(tmp28==="O"){ color1 = "#ff6600"; 
            }else if(tmp28==="X"){ color1 = "rgba(0, 0, 0, 0.7)"; }
        }
        if(tmp30!==""){
        
            dualPrint2 = "T";

            if(tmp33==="B"){ color2 = "blue";
            }else if(tmp33==="G"){ color2 = "green";
            }else if(tmp33==="R"){ color2 = "red";  
            }else if(tmp33==="O"){ color2 = "#ff6600"; 
            }else if(tmp33==="X"){ color2 = "rgba(0, 0, 0, 0.7)"; }
        }


        let result = Math.floor(npqt / qypc); // 몫
        let resultIdx = Math.floor(npqt / qypc); // 목 + 나머지의 카운트
        let remain = npqt % qypc; // 나머지

        if(remain > 0){ resultIdx = result + 1; }

        const newSearched = [];
        let n = 0;
        
        Array.from(new Array(resultIdx), (v, i) =>{  // Main

            Array.from(new Array(stck), (w, j) =>{  // 부착면수 만큼 반복
                
                idx = idx+1;
                if(j===0){ // 부착면수 1일때
                    vseq = vseq + 1;    // 새 vseq 마다 + 1 증가 
                }

                let iCnt = i+1;
                const newArray = {};
                let jCnt = j+1;

                // 새 배열의 n 번째 구하기
                if(i===0 && j===0){  n=0; }else{ n=n+1; }
                
                Object.keys(data).forEach(key => { // 기존값 배열에 추가
                    newArray[key] = data[key];
                });

                newArray["idx"] = idx;
                newArray["vseq"] = vseq;
                
                //납품수량
                let newQty = 0;
                newArray["qty"] = qypc; 
                newQty = qypc; 

                newArray["endpage"] = "F"; 

                newArray["color1"] = color1; 
                newArray["color2"] = color2; 

                newArray["dualPrint1"] = dualPrint1; 
                newArray["dualPrint2"] = dualPrint2;

                if(resultIdx === (vseq-remainChk)){                    
                    if(remain > 0){
                        newArray["qty"] = remain; 
                        newQty = remain; 
                    }
                }

                if(listSize === (index+1)){   
                    if(resultIdx === iCnt){
                        if(stck === jCnt){
                            newArray["endpage"] = "T"; 
                        }
                    }
                }

                //LOTNO(3)
                const lotNo = (data.TMP13===""||data.TMP13==="N")?" ":Common.getLOT(data.TMP13); // LOT NO 날짜 가져오기  data.TMP13

                //일련번호(4)
                const prno = (data.TMP0===undefined?"":data.TMP0) + "" + ("0000" + vseq.toString(16).toUpperCase()).slice(-4);

                // DataMatrix - code128 바코드 값 만들기 (바코드 = 업체코드(4)   + 품번(15)                       + 수량(5)                           + LOTNO(3)                      + 일련번호(4) )
                const barcodeValu = data.TMP12 +""+ (data.TMP5).padEnd(15) +""+ ("00000" + newQty).slice(-5) + "" + ("   " + lotNo).slice(-3) + ""  + prno ;

                // DataMatrix Barcode 만들기
                const dataMatrixBarcode = <CustomDataMatrix val={barcodeValu} height={12} bcid={"code128"} scale={0.5} width={"100%"} />;
                newArray["barcode"] = dataMatrixBarcode; 
                newArray["lotno"] = lotNo;
                newArray["bno"] = barcodeValu;

                // 자기 위치 셋팅 (출력매수)
                const posi = idx%12;                    
                newArray["posi"] = posi;
                
                newSearched[n] = newArray;
            })    
        })
        
        mainSearchedDivArr.push(...newSearched)

    })
    :"";


    let cardInfo = 
        <div className="forfor"  style={{  display : "flex",flexWrap : "wrap", overflow : "hidden", width : mmToPx(280) ,marginLeft : ( 12.6 + parseInt( ( Common.getToken("bplx") ? Common.getToken("bplx") : 0 ),10)) + "mm" }}>
        {/* Start Point */}
        {Array.from(new Array(kStart-1), (v, i) => { 
            return  <div  className="start_from_test"  
                            style={{ marginTop : i == 0 || i == 1 ||i == 2 ||i == 3  ? mmToPx(20+ parseInt( ( Common.getToken("bply") ? Common.getToken("bply") : 0 ),10)) : "0"
                                    , height : mmToPx(50) 
                                    , marginBottom : mmToPx(10)
                                    , flexBasis : mmToPx(70) }}></div> 
                                    } 
                    )
        }

        { mainSearchedDivArr.map( (sData, dataIdx) => (
            <div className="start_from"   key={"WrapErpLabelPrint_C_NEW"+dataIdx} 
                        style={{ marginTop : sData.posi == 0 ||sData.posi == 1 ||sData.posi == 2 ||sData.posi == 3? mmToPx(20+ parseInt( ( Common.getToken("bply") ? Common.getToken("bply") : 0 ),10)) : "0"
                                , height:"50mm"
                                , marginBottom : "10mm"
                                , flexBasis : "70mm" 
                                }} >
                <div className="dep_div" style={{ overflow:"visible", zIndex:"200" , position : "relative", height : "100%", width: "100%" }}> 
                        { sData.dualPrint1 === "T" ?
                            <div style={{ position:"absolute"
                                        , fontFamily: "HY견고딕" 
                                        , top:parseInt(sData.TMP27,10) + "mm"  
                                        , left:parseInt(sData.TMP26,10) + "mm" 
                                        , color:sData.color1
                                        , fontSize:sData.TMP29+"pt" 
                                        , fontWeight:"bold"
                                        , whiteSpace:"nowrap"
                                        , zIndex:"13"
                                        , visibility : parseInt(sData.TMP27,10)  > 60 ? "hidden" : "visible"
                                    }}> {sData.TMP25} </div>
                        :"" }

                        { sData.dualPrint2 === "T" ?
                            <div style={{ position:"absolute" 
                                        , fontFamily: "HY견고딕" 
                                        , top:parseInt(sData.TMP32,10) + "mm" 
                                        , left:parseInt(sData.TMP31,10) + "mm" 
                                        , color:sData.color2 
                                        , fontSize:sData.TMP34+"pt" 
                                        , fontWeight:"bold" 
                                        , whiteSpace:"nowrap"
                                        , zIndex:"12"
                                        , visibility : parseInt(sData.TMP32,10)  > 60 ? "hidden" : "visible"
                                    }}>{sData.TMP30}</div>
                        : "" }

                        <div className="row2" style={{height: mmToPx(1)}}></div>

                        <div className="row2" style={{height:"3.5mm"}}> 
                        {/* <div className="row2" style={{height:mmToPx(3.5)}}> */}
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>    
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell HYheadM6 cell_hori" style={{ flex : "1 1 12mm", display : "flex", ali : "flex-end"}}>{sData.TMP1}</div>{/* 지역 */}
                            <div className="cell NmheadM6" style={{  flex : "1 1 24mm"}}>
                                {sData.TMP0==="MPMC"?"현대파워텍(주)용ⓡ" : "현대자동차(주)/기아(주)ⓡ"}
                            </div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div>

                        <div className="row2" style={{height:"4mm"}}>
                        {/* <div className="row2" style={{height:mmToPx(4)}}> */}
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell HYheadM11 " style={{flex : "1 1 15mm"}}>{sData.TMP2}</div>{/* 공장 */}
                            <div className="cell" style={{flex : "1 1 7.5mm"}}></div>                 
                            <div className="cell HYheadM6 cell_hori" style={{flex : "1 1 12mm"}}>{chkCarc&&chkCarc==="Y"?kCarcNm:""}</div>{/* 차종 chkCarc kCarc kCarcNm sData.TMP3 - ERP의 경우 차종명을 가져오도록 수정 */}  
                            <div className="cell" style={{flex : "1 1 9mm"}}></div>                 
                            <div className="cell HYheadM8 cell_hori" style={{flex : "1 1 15mm"}}>{sData.TMP4}</div>{/* 납품장소 */}
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 

                        <div className="row2" style={{height: "6.5mm"}}>
                            {/* <div className="row2" style={{height: mmTopx(6.5)}}> */}
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            {/* 품번 길이에 따라서 폰트 사이즈 다르게 주어야 하는지? */}
                            <div className="cell HYheadM14" style={{flex : "1 1 39.5mm"}}>
                                    { sData.TMP0==="MHMC"? ((sData.TMP5).trim().length>12? sData.TMP5.substr(0, 11) : sData.TMP5.substr(0, 12) )
                                                    : sData.TMP5.substr(0, 5) + "-" + sData.TMP5.substr(5) }
                            </div> {/* 품번 */}
                            <div className="cell" style={{flex : "1 1 4mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1.5mm"}} ></div>
                            <div className="cell HYheadM14 cell_hori" style={{flex : "1 1 13.5mm"}}>{sData.qty}</div>{/* 수량 */}         
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div>      
                        
                        <div className="row2" style={{height:"6.5mm"  /* mmToPx(6.5) */}}>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell HYheadM11" style={{flex : "1 1 43.5mm"}}>{sData.TMP6}</div>{/* 품명 */}
                            <div className="cell" style={{flex : "1 1 1.5mm"}} ></div>
                            <div className="cell HYheadM18 aligR" style={{ lineHeight:"6mm" , color:(sData.TMP19==="좌" || sData.TMP19==="L") ?"red":"blue", textAlign:"right", zIndex:"10"}}>
                                {sData.TMP19}{/* LH/RH */}
                            </div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 

                        <div className="row2" style={{height: "5mm" /* mmToPx(6) */}}>
                            <div className="cell" style={{width:mmToPx(1)}}></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell HYheadM10" style={{ flex : "1 1 19mm"}}> { (sData.TMP35!=="") && (Common.getTextLength(sData.TMP35) > 7) ? sData.TMP35.substr(0,7) : "" } </div>{/* 한글품명 */} 
                            <div className="cell HYheadM9" style={{ textAlign:"center", flex : "1 1 39mm" }}>
                                {sData.TMP2==='HV31'||sData.TMP2==='HE31'||sData.TMP2==='HM31'||sData.TMP2==='HK31'?"적입중량:":""}
                                {sData.TMP2==='HV31'||sData.TMP2==='HE31'||sData.TMP2==='HM31'||sData.TMP2==='HK31'?
                                ( sData.WTUN === ""||sData.WTUN === "0"||sData.WTUN === undefined) ? "　　　"+" kg": (sData.qty*sData.WTUN).toFixed(3)+"kg"
                                :""}
                            </div>{/* 전주공장일땐 적입중량 표시 */}   
                            <div className="cell HYheadM10" style={{ flex : "1 1 14mm"}}>
                                {sData.TMP20!==undefined?("          " + sData.TMP20).slice(-10):""}
                            </div>{/* 사양 */}
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 

                        {/* <div className="row2" style={{height:"8%"}}>
                            <div className="cell HYheadM18 colspan4" style={{fontSize:"12pt"}}>
                                { (sData.TMP35!=="") && (Common.getTextLength(sData.TMP35) > 7) ? sData.TMP35.substr(11) : "" }
                            </div>   // 한글품명 
                            <div className="cell colspan5"></div>
                        </div>  */}
                    
                        <div className="row2" style={{height:"6mm" /* mmToPx(6) */}}>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell HYheadM12" style={{zIndex:"15", flex : "1 1 60mm"}}> {sData.barcode} </div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 

                        <div className="row2" style={{height: "1mm" /* mmToPx(1) */}}>
                            <div className="cell"></div>
                        </div>
                    
                        <div className="row2" style={{height:"4mm" /* mmToPx(4) */}}>
                            <div className="cell"></div>
                            <div className="cell" style={{flex : "1 1 8.5mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1.5mm"}} ></div>
                            <div className="cell HYheadM6 cell_veri " style={{flex : "1 1 13.5mm"}}>{chkNpdt&&chkNpdt==="Y"?sData.TMP9:""}</div>{/* 납품일자 chkNpdt */}        
                            <div className="cell" style={{flex : "1 1 7.5mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1.5mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1.5mm"}} ></div>
                            <div className="cell HYheadM6 cell_veri" style={{flex : "1 1 9mm"}}>{sData.TMP12}</div>{/* 업체코드 */}
                            <div className="cell HYheadM6 cell_veri" style={{flex : "1 1 24mm"}}>{sData.TMP10}</div>{/* 업체명 */}
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 
    
                        <div className="row2" style={{height: "4mm" /* mmToPx(4) */}}>
                            <div className="cell"></div>
                            <div className="cell HYheadM6 aligR cell_veri" style={{ verticalAlign:"middle", /* fontWeight:"normal", */flex : "1 1 8.5mm" }}>
                                    { kCkdOem==="CKD" ? "Order": (sData.TMP11==="")||((sData.TMP2==="S1")||(sData.TMP2==="S2")||(sData.TMP2==="S3")||(sData.TMP2==="SP")||(sData.TMP2==="6")) ? sData.TMP0==="MAMC"? "보관장"
                                                    : sData.TMP0==="MKMC"? "공정번호" : "공정No"
                                                : "공정No" }
                            </div>
                            <div className="cell" style={{flex : "1 1 1.5mm"}} ></div>
                            <div className="cell HYheadM9" style={{ verticalAlign:"middle", flex : "1 1 13.5mm" }}>{sData.TMP22}</div>{/* 공정NO */}
                            <div className="cell" style={{flex : "1 1 7.5mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1.5mm"}} ></div>
                            <div className="cell HYheadM25" style={{flex : "1 1 34.5mm"}}>{sData.TMP36==="Y"?"RHD":""}</div>{/* RHD */}
                            {/* 2023.10.28 백엔드 조회 쿼리 '' 공백으로 조회 후 표출하므로 주석처리 진행 */}
                            {/* <div className="cell HYheadM10" style={{flex : "1 1 10.5mm"}} >{sData.TMP21}</div> */} {/* 발주번호 */}
                            <div className="cell" style={{flex : "0"}}>
                                { sData.TMP15!==""?
                                                <div style={{ position:"absolute"
                                                            , fontFamily: "HY견고딕"
                                                            , fontSize : !(sData.TMP15) === false &&  (sData.TMP15).toString().replace(/\s/ig, "").length > 4 ?"16px" : "20pt"
                                                            , fontWeight:"bold"
                                                            , zIndex:"15"
                                                            , marginLeft : "-113px" }}>{sData.TMP15}</div>
                                :"" }
                                {/* 중금속 확인필 */}
                                { chkMetal==="Y" ? <div style={{position : "absolute", marginLeft : "-30px" }}> <img src ="/images/heavy_metal_confirm.gif" style={{width:"8mm"}} alt="heavy_metal_confirm" /> </div> :""}
                            </div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 
    
                        <div className="row2" style={{height: "4mm" /* mmToPx(4) */}}>
                            <div className="cell"></div>
                            <div className="cell HYheadM6 aligR cell_veri" style={{/* fontWeight:"normal",  */flex : "1 1 8.5mm"}}> {kCkdOem==="CKD"?"":"HPC"} </div>
                            <div className="cell" style={{flex : "1 1 1.5mm"}} ></div>
                            <div className="cell HYheadM9 cell_veri" style={{ flex : "1 1 13.5mm"}}>{sData.TMP23}</div>{/* HPC */}
                            <div className="cell HYheadM9 aligR" style={{flex : "1 1 7.5mm"}}>{chkLtno&&chkLtno==="Y"?sData.lotno:""}</div>{/* getLOT() chkLtno  */}
                            <div className="cell" style={{flex : "1 1 36mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 

                        <div className="row2" style={{height:"3.5mm" /* mmToPx(3.5) */}}>
                            <div className="cell"></div>
                            <div className="cell" style={{flex : "1 1 10.5mm"}} ></div>
                            <div className="cell HYheadM5 cell_veri" style={{flex : "1 1 29.5mm"}}> {sData.bno} </div>{/* bno */}
                            <div className="cell"></div>
                            <div className="cell HYheadM6 cell_veri aligR" style={{flex : "1 1 8mm"}}>{sData.TMP17}</div>{/* 용기 Mo */}
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 

                        <div className="row2" style={{height: "1mm"}}><div className="cell"></div></div>

                </div>

                {/*페이지 넘김 처리 */}
                { sData.posi===11 && sData.endpage==="F" ? <div className="page-break"></div>  : "" } 
 
            </div>
        ),"")}
    </div>
    
    return <PrintStyleC key={"WrapErpLabelPrint_C_NEW"}>{cardInfo}</PrintStyleC>;

   }
}
export default WrapErpLabelPrint_C_NEW;