/**
 * @Page ROM4L
 * @Description [ROM4L]납입지시 - 기초관리 - ERP_품목관리
 **/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import ContentTwo from '../../components/organisms/ContentTwo';
import Grid from '@/components/grid/agGrid';
import CustomAlert from '@/components/molecules/CustomAlert';
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common';
import CommonUtil from '@/utils/CommonUtil';
import Loading from 'src/components/atoms/CustomLoading2';

const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER;
const Utils = new CommonUtil();

// Print Style CSS Setting
const pageStyle = `
@media all {
    .pagebreak {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .pagebreak {
      margin-top:1rem;
      display: block;
      page-break-after: always;
    }
  }
  
  @page {
    size: A4;
    margin:7mm 10mm;
  }
  body, div, table, td{
      font-family:굴림체,Gulim ;
  }

`;

function ROM4L() {
	const token = Common.getToken();
	const userId = Common.getToken('id');
	const tVnt1 = Common.getToken('vand');
	const tComp = Common.getToken('comp');
	const grdRom4lMain = useRef(); //Grid Main
	let [loading, setLoading] = useState(false);
	const ckVnt1 =
		Common.getCookie('ck_vnt1' + userId) === null || Common.getCookie('ck_vnt1' + userId) === '' || Common.getCookie('ck_vnt1' + userId) === 'undefined'
			? tVnt1
			: Common.getCookie('ck_vnt1' + userId);

	const pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크
	const pattern_num = /[0-9]/; // 숫자
	const pattern_spc = /[~!.'/@#$%^&*()_+|<>?\\:={}-]/; // 특수문자
	const pattern_eng = /[a-zA-Z]/; // 문자
	const [panded, setPanded] = React.useState(true);
	const [style, setStyle] = useState({ height: 'calc(100vh - 60px - 50px - 100px - 50px)', paddingTop: '10px' });
	//Data
	const [idata, setData] = useState({
		//조회조건
		kVnt1: ckVnt1, // T1업체
		kComp: tComp, // 회사구분
		kPlnt: '', // 공장
		kPtno: '', // 품번
		kEmgubn: '1',
		kVnt1Nm: '', // T1업체명
		kCompNm: tComp === 'EHMC' ? '현대자동차(주)' : '기아자동차(주)', // 회사명

		//코드 데이터
		vnt1List: [], // T1업체
		plntList: [], // 공장
		compList: [], // 회사구분
		gateList: [], // 하치장
		typeList: [], // 식별표타입
		wdcspecList: [], // 지역
		wdcckgbList: [], // L/R

		//입력데이터
		wdcplnt: '', // 공장
		wdcptno: '', // 품번
		wdcptnm: '', // 품명
		wdcgate: '', // 대표하치장
		wdcsjgb: '', // 실적구분
		wdcnpgb: '', // 납품서구분
		wdccase: '', // 용기코드
		wdcqypc: '', // 용기당수량
		wdciggb: '', // 입고방식
		wdcfrdt: '', // 효력시작일
		wdctodt: '', // 효력종료일
		wdcokgb: '', // 검수유무
		wdcdono: '', // 구매문서
		wdcdopt: '', // 품목번호
		wdccrdx: '', // 담당자
		//하치장1 ~ 10
		wdcgate1: '',
		wdcgate2: '',
		wdcgate3: '',
		wdcgate4: '',
		wdcgate5: '',
		wdcgate6: '',
		wdcgate7: '',
		wdcgate8: '',
		wdcgate9: '',
		wdcgate10: '',
		wdckorn: '', // 한글품명
		wdctrst: '', // 공급자
		//공정No
		wdcshop1: '',
		wdcline1: '',
		wdcaspr1: '',
		wdcrack1: '',
		//HPC
		wdccarc: '',
		wdcalct: '',
		wdcdlgt: '',
		wdcgjno: '', // 공정
		wdcprcd: '', // 조립코드
		wdcalcc: '', // ALC
		wdcnppr: '', // 납품단가
		wdclane: '', // LANE
		wdcoptn: '', // 특기
		wdcptgb: '', // 식별표 Type
		wdcstck: '', // 부착면수
		wdcspec: '', // 지역
		wdcckgb: '', // L/R
		wdcrthd: 'Y', // RHD적용
		wdcflag: 'Y', // 사용여부
		wdclbl1: '', // 내용
		wdccol1: '', // 색상
		wdcsiz1: '', // 폰트사이즈
		wdcprx1: '', // X좌표
		wdcpry1: '', // Y좌표
		wdclbl2: '', // 이중인쇄 내용
		wdccol2: '', // 색상
		wdcsiz2: '', // 폰트사이즈
		wdcprx2: '', // X좌표
		wdcpry2: '', // Y좌표
		wdcwtun: '', // 적입중량
		wrcvnqt: '', // 업체별 용기당수량
		wrcvnyn: '', // 업체별 용기당수량 사용여부
	});

	const [message, setMessage] = React.useState({
		openAlert: false,
		severity: 'success',
		msg: '',
	});
	const { openAlert, severity, msg } = message;

	//Dialog
	const [dlgMessage, setDlgMessage] = React.useState({
		openDlg: false,
		dlgTrans: '',
		dlgMsg: '',
	});
	const { openDlg, dlgTrans, dlgMsg } = dlgMessage;

	let [searchApi, setSearchApi] = useState(false);
	let [deleteApi, setDeleteApi] = useState(false);

	let [clearGrid, setClearGrid] = useState(false);

	const onClearApi = useCallback(async (e) => {
		setSearchApi(false);
		setDeleteApi(false);
	}, []);

	const onClick = async (e) => {
		setPanded(!panded);

		if (panded === true) {
			setStyle({
				height: 'calc(100vh - 60px - 50px - 50px)',
				padingTop: '10px',
			});
		} else {
			setStyle({
				height: 'calc(100vh - 60px - 50px - 100px - 50px)',
				paddingTop: '10px',
			});
		}
	};

	/**
	 * 공통코드 조회 (조회조건)
	 */
	const onSearchCommon = (para, name) => {
		let commonData = idata;

		const fetchData = async (para, name) => {
			let param = encodeURIComponent(para);
			const query = SERVER_URL + '/comcd/search?query=' + param;
			await fetch(query, { headers: { Authorization: token } })
				.then((resp) => resp.json())
				.then((data) => {
					if (data.success) {
						if (name === 'plntList') {
							if (data.data.length > 0) {
								commonData['kPlnt'] = data.data[0].comCode;
								commonData['kPlntNm'] = data.data[0].text;
							}
						}
						commonData[name] = data.data;
						setData({ ...commonData });
					}
				});
		};
		fetchData(para, name);
	};

	/**
	 * (식별표 타입, 지역) 조회
	 * @param {*} para
	 * @param {*} name
	 */
	const onSearchCommon1 = (para, name) => {
		let commonData = idata;

		const fetchData1 = async (para, name) => {
			let param = encodeURIComponent(para);
			const query = SERVER_URL + '/comcd/search1?query=' + param;
			await fetch(query, { headers: { Authorization: token } })
				.then((resp) => resp.json())
				.then((data) => {
					if (data.success) {
						if (name === 'typeList') {
						}
						commonData[name] = data.data;
						setData({ ...commonData });
					}
				});
		};
		fetchData1(para, name);
	};

	const onSearchCommon2 = (para, name) => {
		let commonData = idata;

		const fetchData = async (para, name) => {
			let param = encodeURI(para);
			const query = SERVER_URL + '/comcd/search?query=' + param;
			await fetch(query, { headers: { Authorization: token } })
				.then((resp) => resp.json())
				.then((data) => {
					if (data.success) {
						commonData[name] = data.data[0].text;
						setData({ ...commonData });
					}
				});
		};
		fetchData(para, name);
	};

	/**
	 *MOUNT
	 */
	useEffect(() => {
		//공통코드 조회 (T1업체)
		onSearchCommon(`{"pgmid":"VNT1"}`, 'vnt1List');
		//공통코드 조회 (공장)
		onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":""}`, 'plntList');

		//공통코드 조회 (회사구분)
		onSearchCommon1(`{"pgmid":"CODE", "vand":"****", "gocd":"COMP"}`, 'compList');

		//공통코드 조회 (식별표타입)
		onSearchCommon1(`{"pgmid":"CODE", "vand":"****", "gocd":"PTCD"}`, 'typeList');

		//공통코드 조회 (지역)
		onSearchCommon1(`{"pgmid":"CODE", "vand":"****", "gocd":"SPEC"}`, 'wdcspecList');

		//공통코드 조회 (L/R))
		onSearchCommon1(`{"pgmid":"CODE", "vand":"****", "gocd":"CKGB"}`, 'wdcckgbList');
		onSearchCommon2(`{"pgmid":"WRCVAND","kVnt1":"${idata.kVnt1}"}`, 'kVnt1Nm');
	}, []);

	/**
	 * Control 변경 Event
	 * @param {*} e
	 */
	const onChange = async (e) => {
		const { name, value, desc } = e.target;

		let newSearchs = idata;

		newSearchs[name] = value;
		newSearchs[name + 'Nm'] = desc;
		setData({ ...newSearchs });

		//T1업체, 회사구분 변경시
		if (name === 'kVnt1' || name === 'kComp') {
			newSearchs['plntList'] = [];
			newSearchs['gateList'] = [];
			newSearchs['kPlnt'] = '';
			newSearchs[name] = value;
			setData({ ...newSearchs });

			//공통코드 조회 (공장)
			onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":""}`, 'plntList');
		}

		//입력항목 (품번)
		else {
			if (name === 'wdcptno') {
				//하이픈(-) 입력 Check
				if (value.includes('-')) {
					setMessage({ openAlert: true, severity: 'info', msg: '하이픈(-)은 입력 할 수 없습니다.' });
					return;
				}
			}

			//숫자만 입력 Check (용기당수량, 부착면수, 폰트사이즈, x좌표, Y좌표, 납품단가)
			if (
				name === 'wdcqypc' ||
				name === 'wdcstck' ||
				name === 'wdcsiz1' ||
				name === 'wdcprx1' ||
				name === 'wdcpry1' ||
				name === 'wdcsiz2' ||
				name === 'wdcprx2' ||
				name === 'wdcpry2' ||
				name === 'wdcnppr'
			) {
				if (pattern_spc.test(value) || pattern_kor.test(value) || pattern_eng.test(value)) return;
			} else if (name !== 'wdckorn') {
				if (name !== 'wdclbl1' && name !== 'wdclbl2') {
					// 이중 출력 1,2 "내용" 한글입력 되도록 처리

					if (pattern_kor.test(value)) return; // 한글입력 안되게처리
				}
			}

			newSearchs[name] = value;
			newSearchs[name + 'Nm'] = desc;
			setData({ ...newSearchs });
		}

		//조회조건 변경시 Clear
		if (name === 'kVnt1' || name === 'kComp' || name === 'kPlnt' || name === 'kPtno' || name === 'kEmgubn') {
			setClearGrid(true);
			initInputData();
		}
	};

	/**
	 * Grid Event
	 * @param {*} e
	 */
	const grdRom4lMain_onCellValueChanged = async (e) => {};
	const grdRom4lMain_onRowClicked = async (e) => {
		let data = e.data;

		//품목관리 상세 조회
		onSearch2(data);
	};

	/**
	 * 검색조건
	 */
	const optionsX = {
		0: [
			//T1업체
			{
				formatter: 'select',
				name: 'kVnt1',
				labelName: 'T1업체',
				type: 'text',
				defaultValue: idata.kVnt1,
				codeMode: true,
				selectAll: '',
				codes: null,
				comms: idata.vnt1List,
				align: 'left',
			},
			//공장
			{
				formatter: 'select',
				name: 'kPlnt',
				labelName: '공장',
				type: 'text',
				defaultValue: idata.kPlnt,
				codeMode: false,
				selectAll: '',
				codes: null,
				comms: idata.plntList,
			},
			//사용여부
			{
				formatter: 'select',
				name: 'kEmgubn',
				labelName: '사용여부',
				type: 'text',
				defaultValue: idata.kEmgubn,
				codeMode: false,
				selectAll: '',
				codes: [
					{ text: '전체', comCode: '1' },
					{ text: '사용', comCode: '2' },
					{ text: '미사용', comCode: '3' },
				],
				comms: null,
				align: 'left',
			},
			{ formatter: 'blank', align: 'right' },
			{ formatter: 'blank', align: 'right' },
			{ formatter: 'blank', align: 'right' },
		],
		1: [
			//회사구분
			{
				formatter: 'select',
				name: 'kComp',
				labelName: '회사구분',
				type: 'text',
				defaultValue: idata.kComp,
				codeMode: true,
				selectAll: '',
				comms: idata.compList,
				align: 'left',
			},
			//품번
			{
				formatter: 'input',
				name: 'kPtno',
				labelName: '품번',
				type: 'text',
				desc: '',
				readOnly: false,
				defaultValue: idata.kPtno,
				align: 'left',
				labelType: 'none',
			},
			{ formatter: 'blank', align: 'right' },
			{ formatter: 'blank', align: 'right' },
			{ formatter: 'blank', align: 'right' },
			{ formatter: 'blank', align: 'right' },
		],
	};

	/**
	 * 조회
	 * @param {*} e
	 * @returns
	 */
	const onSearch = async (e) => {
		//T1업체선택 Check
		if (idata.kVnt1 == '') {
			setMessage({ openAlert: true, severity: 'info', msg: '1차업체를 선택하십시요 !' });
			document.getElementById('kVnt1').focus();
			return;
		}

		//회사구분선택 Check
		if (idata.kComp == '') {
			setMessage({ openAlert: true, severity: 'info', msg: '회사구분을 선택하십시요 !' });
			document.getElementById('kComp').focus();
			return;
		}

		//공장선택 Check
		if (idata.kPlnt == '') {
			setMessage({ openAlert: true, severity: 'info', msg: '공장을 선택하십시요 !' });
			document.getElementById('kPlnt').focus();
			return;
		}

		// Cookie 값 셋팅
		Common.setCookie('ck_vnt1' + userId, idata.kVnt1, 1);

		let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                     "kPtno":"${idata.kPtno}","kEmgubn":"${idata.kEmgubn}"}`;
		let param = encodeURIComponent(para);

		const query = SERVER_URL + '/rom4l/search?query=' + param;
		setSearchApi(query);

		initInputData();
	};

	/**
	 * 입력
	 * @param {*} e
	 */
	const onInsert = async (e) => {
		//input check
		let input = await Promise.all([inputCheck('I')]);
		if (!input[0]) return;

		//품번 입력 Check
		if (idata.wdcptno === '' || idata.wdcptno === null) {
			setMessage({ openAlert: true, severity: 'info', msg: '품번은 필수입력입니다.' });
			return;
		}
		//품명 입력 Check
		if (idata.wdcptnm === '' || idata.wdcptnm === null) {
			setMessage({ openAlert: true, severity: 'info', msg: '품명은 필수입력입니다.' });
			return;
		}

		//품번 하이픈(-) 입력 Check
		if (idata.wdcptno.includes('-')) {
			setMessage({ openAlert: true, severity: 'info', msg: '하이픈(-)은 입력 할 수 없습니다.' });
			return;
		}
		//품번 작은따움표 체크
		if (idata.wdcptno.includes("'")) {
			setMessage({ openAlert: true, severity: 'info', msg: '하이픈(-)은 입력 할 수 없습니다.' });
			return;
		}

		//품명 작은따움표 체크
		if (idata.wdcptnm.includes("'")) {
			setMessage({ openAlert: true, severity: 'info', msg: '하이픈(-)은 입력 할 수 없습니다.' });
			return;
		}

		//공장선택 Check
		if (idata.kPlnt == '') {
			setMessage({ openAlert: true, severity: 'info', msg: '공장을 선택하십시요 !' });
			document.getElementById('kPlnt').focus();
			return;
		}

		//입력 확인 메세지
		setDlgMessage({ openDlg: true, dlgTrans: 'I', dlgMsg: '입력된 품목정보를 등록 하시겠습니까?' });
	};

	/**
	 * 수정
	 * @param {*} e
	 * @returns
	 */
	const onUpdate = async (e) => {
		//check
		let input = await Promise.all([inputCheck('U')]);
		if (!input[0]) return;

		//수정 확인 메세지
		setDlgMessage({ openDlg: true, dlgTrans: 'U', dlgMsg: '데이터를 수정 하시겠습니까?' });
	};

	/**
	 * 삭제
	 * @param {*} e
	 */
	const onDelete = async (e) => {
		//check
		let input = await Promise.all([inputCheck('D')]);
		if (!input[0]) return;

		//삭제 확인 메세지
		setDlgMessage({ openDlg: true, dlgTrans: 'D', dlgMsg: '선택한 데이터를 삭제하시겠습니까?' });
	};

	// input Data init
	const initInputData = async (e) => {
		let inputData = idata;
		inputData['wdcplnt'] = '';
		inputData['wdcptno'] = '';
		inputData['wdcptnm'] = '';
		inputData['wdcgate'] = '';
		inputData['wdcsjgb'] = '';
		inputData['wdcnpgb'] = '';
		inputData['wdccase'] = '';
		inputData['wdcqypc'] = '';
		inputData['wdciggb'] = '';
		inputData['wdcfrdt'] = '';
		inputData['wdctodt'] = '';
		inputData['wdcokgb'] = '';
		inputData['wdcdono'] = '';
		inputData['wdcdopt'] = '';
		inputData['wdccrdx'] = '';
		inputData['wdcgate1'] = '';
		inputData['wdcgate2'] = '';
		inputData['wdcgate3'] = '';
		inputData['wdcgate4'] = '';
		inputData['wdcgate5'] = '';
		inputData['wdcgate6'] = '';
		inputData['wdcgate7'] = '';
		inputData['wdcgate8'] = '';
		inputData['wdcgate9'] = '';
		inputData['wdcgate10'] = '';
		inputData['wdckorn'] = '';
		inputData['wdctrst'] = '';
		inputData['wdcshop1'] = '';
		inputData['wdcline1'] = '';
		inputData['wdcaspr1'] = '';
		inputData['wdcrack1'] = '';
		inputData['wdccarc'] = '';
		inputData['wdcalct'] = '';
		inputData['wdcdlgt'] = '';
		inputData['wdcgjno'] = '';
		inputData['wdcprcd'] = '';
		inputData['wdcalcc'] = '';
		inputData['wdcnppr'] = '';
		inputData['wdclane'] = '';
		inputData['wdcoptn'] = '';
		inputData['wdcptgb'] = '';
		inputData['wdcstck'] = '';
		inputData['wdcspec'] = '';
		inputData['wdcckgb'] = '';
		inputData['wdcrthd'] = '';
		inputData['wdcflag'] = '';
		inputData['wdclbl1'] = '';
		inputData['wdccol1'] = '';
		inputData['wdcsiz1'] = '';
		inputData['wdcprx1'] = '';
		inputData['wdcpry1'] = '';
		inputData['wdclbl2'] = '';
		inputData['wdccol2'] = '';
		inputData['wdcsiz2'] = '';
		inputData['wdcprx2'] = '';
		inputData['wdcpry2'] = '';
		inputData['wdcwtun'] = '';
		inputData['wrcvnqt'] = '';
		inputData['wrcvnyn'] = '';
		setData({ ...inputData });
	};

	/**
	 * 품목관리 상세조회
	 * @param {*} e
	 * @returns
	 */
	const onSearch2 = async (e) => {
		initInputData();

		const fetchData = async (e) => {
			let inputData = idata;
			let para = `{"wrcvand":"${e.wrcvand}","wrccomp":"${e.wrccomp}","wdcplnt":"${e.wdcplnt}", 
                         "wdcptno":"${e.wdcptno}"}`;

			
			let param = encodeURIComponent(para);
			const query = SERVER_URL + '/rom4l/search1?query=' + param;

			await fetch(query, { headers: { Authorization: token } })
				.then((resp) => resp.json())
				.then((data) => {
					inputData['wdcplnt'] = data.data[0].wdcplnt; // 공장
					inputData['wdcptno'] = data.data[0].wdcptno; // 품번
					inputData['wdcptnm'] = data.data[0].wdcptnm; // 품명
					inputData['wdcgate'] = data.data[0].wdcgate; // 대표하치장
					inputData['wdcsjgb'] = data.data[0].wdcsjgb; // 실적구분
					inputData['wdcnpgb'] = data.data[0].wdcnpgb; // 납품서구분
					inputData['wdccase'] = data.data[0].wdccase; // 용기코드
					inputData['wdcqypc'] = data.data[0].wdcqypc; // 용기당수량
					inputData['wdciggb'] = data.data[0].wdciggb; // 입고방식
					inputData['wdcfrdt'] = data.data[0].wdcfrdt; // 효력시작일
					inputData['wdctodt'] = data.data[0].wdctodt; // 효력종료일
					inputData['wdcokgb'] = data.data[0].wdcokgb; // 검수유무
					inputData['wdcdono'] = data.data[0].wdcdono; // 구매문서
					inputData['wdcdopt'] = data.data[0].wdcdopt; // 품목번호
					inputData['wdccrdx'] = data.data[0].wdccrdx; // 담당자
					inputData['wdcgate1'] = data.data[0].wdcgate1; // 하치장1
					inputData['wdcgate2'] = data.data[0].wdcgate2; // 하치장2
					inputData['wdcgate3'] = data.data[0].wdcgate3; // 하치장3
					inputData['wdcgate4'] = data.data[0].wdcgate4; // 하치장4
					inputData['wdcgate5'] = data.data[0].wdcgate5; // 하치장5
					inputData['wdcgate6'] = data.data[0].wdcgate6; // 하치장6
					inputData['wdcgate7'] = data.data[0].wdcgate7; // 하치장7
					inputData['wdcgate8'] = data.data[0].wdcgate8; // 하치장8
					inputData['wdcgate9'] = data.data[0].wdcgate9; // 하치장9
					inputData['wdcgate10'] = data.data[0].wdcgate10; // 하치장10
					inputData['wdckorn'] = data.data[0].wdckorn; // 한글품명
					inputData['wdctrst'] = data.data[0].wdctrst; // 공급자
					inputData['wdcshop1'] = data.data[0].wdcshop1; // 공정No1
					inputData['wdcline1'] = data.data[0].wdcline1; // 공정No2
					inputData['wdcaspr1'] = data.data[0].wdcaspr1; // 공정No3
					inputData['wdcrack1'] = data.data[0].wdcrack1; // 공정No4
					inputData['wdccarc'] = data.data[0].wdccarc; // HPC1
					inputData['wdcalct'] = data.data[0].wdcalct; // HPC2
					inputData['wdcdlgt'] = data.data[0].wdcdlgt; // HPC3
					inputData['wdcgjno'] = data.data[0].wdcgjno; // 공정
					inputData['wdcprcd'] = data.data[0].wdcprcd; // 조립코드
					inputData['wdcalcc'] = data.data[0].wdcalcc; // ALC
					inputData['wdcnppr'] = data.data[0].wdcnppr; // 납품단가
					inputData['wdclane'] = data.data[0].wdclane; // LANE
					inputData['wdcoptn'] = data.data[0].wdcoptn; // 특기
					inputData['wdcptgb'] = data.data[0].wdcptgb; // 식별표 Type
					inputData['wdcstck'] = data.data[0].wdcstck; // 부착면수
					inputData['wdcspec'] = data.data[0].wdcspec; // 지역
					inputData['wdcckgb'] = data.data[0].wdcckgb; // L/R
					inputData['wdcrthd'] = data.data[0].wdcrthd === '' ? 'N' : data.data[0].wdcrthd; // RHD적용
					inputData['wdcflag'] = data.data[0].wdcflag; //사용여부
					inputData['wdclbl1'] = data.data[0].wdclbl1; //내용
					inputData['wdccol1'] = data.data[0].wdccol1 === '' ? 'B' : data.data[0].wdccol1; // 색상
					inputData['wdcsiz1'] = data.data[0].wdcsiz1; // 폰트사이즈
					inputData['wdcprx1'] = data.data[0].wdcprx1; // X좌표
					inputData['wdcpry1'] = data.data[0].wdcpry1; // Y좌표
					inputData['wdclbl2'] = data.data[0].wdclbl2; // 이중인쇄 내용
					inputData['wdccol2'] = data.data[0].wdccol2 === '' ? 'B' : data.data[0].wdccol2; //색상
					inputData['wdcsiz2'] = data.data[0].wdcsiz2; // 폰트사이즈
					inputData['wdcprx2'] = data.data[0].wdcprx2; // X좌표
					inputData['wdcpry2'] = data.data[0].wdcpry2; // Y좌표
					inputData['wdcwtun'] = data.data[0].wdcwtun; // 적입중량
					inputData['wrcvnqt'] = data.data[0].wrcvnqt; // 업체별 용기당 수량
					inputData['wrcvnyn'] = data.data[0].wrcvnyn; // 업체별 용기당 수량 사용여부
					setData({ ...inputData });
				})
				.catch((error) => {
					let message = error.data.message;

					// 에러 응답 처리
					if (error) {
						setMessage({ openAlert: true, severity: 'error', msg: '서버 상태를 확인해 주세요. 관리자에게 문의해주세요.' });
					}
				});
		};

		fetchData(e);
	};

	/**
	 * 엑셀
	 * @param {*} e
	 */
	const onExcel = async (e) => {
		let para = `{"wrcvand":"${idata.kVnt1}","wrccomp":"${idata.kComp}","wdcplnt":"${idata.kPlnt}",
                        "vnt1Nm":"${idata.kVnt1Nm}",
                        "compNm":"${idata.kCompNm}",
                        "plntNm":"${idata.kPlntNm}"
                    }`;

		let param = encodeURIComponent(para);
		const query = SERVER_URL + '/rom4l/excel?query=' + param;

		setLoading(true);

		await fetch(query, { headers: { Authorization: token } })
			.then((resp) => {
				return resp.blob();
			})
			.then((blob) => {
				const href = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = href;
				link.setAttribute('download', 'rom4l.xls');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((err) => {
				return Promise.reject({ Error: 'error', err });
			});

		setLoading(false);

		setMessage({ openAlert: true, severity: 'success', msg: '엑셀파일이 생성되었습니다.' });
	};

	//BackendCall
	const BackendCall = async (e) => {
		const fetchData = async (e) => {
			let url;

			if (e === 'I') {
				url = '/rom4l/insert';
			}
			if (e === 'U') {
				//품번 작은따움표 체크
				if (idata.wdcptno.includes("'")) {
					setMessage({ openAlert: true, severity: 'info', msg: '품번 : 작은 따움표는 입력할 수 없습니다.' });
					return;
				}

				//품명 작은따움표 체크
				if (idata.wdcptnm.includes("'")) {
					setMessage({ openAlert: true, severity: 'info', msg: '품명 : 작은 따움표는 입력할 수 없습니다.' });
					return;
				}

				url = '/rom4l/update';
			}

			const query = SERVER_URL + url;

			let param = JSON.stringify({
				kVnt1: idata.kVnt1,
				kComp: idata.kComp,
				wdcplnt: idata.wdcplnt, // 공장
				wdcptno: idata.wdcptno.toUpperCase(), // 품번
				wdcptnm: idata.wdcptnm.toUpperCase(), // 품명
				wdckorn: idata.wdckorn.toUpperCase(), // 한글품명
				kPlnt: idata.kPlnt, // 공장
				wdcnpgb: idata.wdcnpgb.toUpperCase(), // 납품서구분
				wdcsjgb: idata.wdcsjgb.toUpperCase(), // 실적구분
				wdcgate: idata.wdcgate.toUpperCase(), // 대표하치장
				wdccase: idata.wdccase.toUpperCase(), // 용기코드
				wdcqypc: idata.wdcqypc, // 용기당수량
				wdcokgb: idata.wdcokgb.toUpperCase(), // 검수유무
				wdccrdx: idata.wdccrdx.toUpperCase(), // 담당자
				wdciggb: idata.wdciggb.toUpperCase(), // 입고방식
				wdctrst: idata.wdctrst.toUpperCase(), // 공급자
				wdcshop1: idata.wdcshop1.toUpperCase(), // 공정No1
				wdcline1: idata.wdcline1.toUpperCase(), // 공정No2
				wdcaspr1: idata.wdcaspr1.toUpperCase(), // 공정No3
				wdcrack1: idata.wdcrack1.toUpperCase(), // 공정No4
				wdccarc: idata.wdccarc.toUpperCase(), // HPC1
				wdcalct: idata.wdcalct.toUpperCase(), // HPC2
				wdcdlgt: idata.wdcdlgt.toUpperCase(), // HPC3
				wdcprcd: idata.wdcprcd.toUpperCase(), // 조립코드
				wdcnppr: idata.wdcnppr.toUpperCase(), // 납품단가
				wdcoptn: idata.wdcoptn.toUpperCase(), // 특기
				wdcptgb: idata.wdcptgb, // 식별표 Type
				wdcstck: idata.wdcstck, // 부착면수
				wdcspec: idata.wdcspec, // 지역
				wdcckgb: idata.wdcckgb, // L/R
				wdcrthd: idata.wdcrthd, // RHD적용
				wdcflag: idata.wdcflag, // 사용여부
				wdclbl1: idata.wdclbl1.toUpperCase(), // 내용
				wdccol1: idata.wdccol1, // 색상
				wdcprx1: idata.wdcprx1, // X좌표
				wdcpry1: idata.wdcpry1, // Y좌표
				wdcsiz1: idata.wdcsiz1, // 폰트사이즈
				wdclbl2: idata.wdclbl2.toUpperCase(), // 이중인쇄 내용
				wdccol2: idata.wdccol2, // 색상
				wdcprx2: idata.wdcprx2, // X좌표
				wdcpry2: idata.wdcpry2, // Y좌표
				wdcwtun: idata.wdcwtun, // 적입중량 20220630 추가
				wrcvnqt: idata.wrcvnqt, // 업체당 용기당수량 20220811 추가
				wrcvnyn: idata.wrcvnyn, // 업체당 용기당수량 사용여부20220811 추가
				wdcsiz2: idata.wdcsiz2, // 폰트사이즈
				wdcgjno: idata.wdcgjno.toUpperCase(), // 공정
				wdcalcc: idata.wdcalcc.toUpperCase(), // ALC
				wdclane: idata.wdclane.toUpperCase(), // LANE
				wdcfrdt: idata.wdcfrdt, // 효력시작일
				wdctodt: idata.wdctodt, // 효력종료일
				wdcgate1: idata.wdcgate1, // 하치장1
				wdcgate2: idata.wdcgate2, // 하치장2
				wdcgate3: idata.wdcgate3, // 하치장3
				wdcgate4: idata.wdcgate4, // 하치장4
				wdcgate5: idata.wdcgate5, // 하치장5
				wdcgate6: idata.wdcgate6, // 하치장6
				wdcgate7: idata.wdcgate7, // 하치장7
				wdcgate8: idata.wdcgate8, // 하치장8
				wdcgate9: idata.wdcgate9, // 하치장9
				wdcgate10: idata.wdcgate10, // 하치장10
			});

			await fetch(query, { method: 'POST', headers: { Authorization: token, 'Content-Type': 'application/json' }, body: param })
				.then((resp) => resp.json())
				.then((data) => {
					if (data.success) {
						setMessage({ openAlert: true, severity: 'success', msg: '정상적으로 등록되었습니다.' });
					} else if (data.errors) {
						setMessage({ openAlert: true, severity: 'error', msg: data.errors.message });
					} else if (data.error) {
						let message = data.message;

						if (message.search('ORA-00001')) {
							message = '이미 등록된 코드입니다.';
						}

						setMessage({ openAlert: true, severity: 'error', msg: message });
					}
				})
				.catch((error) => {
					// 에러 응답 처리
					if (error) {
						setMessage({ openAlert: true, severity: 'error', msg: '서버 상태를 확인해 주세요. 관리자에게 문의해주세요.' });
					}
				});
		};
		fetchData(e);
	};

	// input check
	const inputCheck = (gbn) => {
		if (tVnt1 != idata.kVnt1) {
			if (gbn != 'U') {
				setMessage({ openAlert: true, severity: 'info', msg: 'T2 업체는 입력 및 삭제가 불가능 합니다.' });
				return false;
			}
		}

		if (gbn == 'D') {
			
			let selectedData = grdRom4lMain.current.getSelectedNodes();

			// 선택 값 체크
			if (selectedData.length === 0) {
				//Alert 창 OPEN
				setMessage({ openAlert: true, severity: 'info', msg: '선택한 데이터가 없습니다.' });
				return false;
			}
		} else {
			//품명 입력 Check - 입력/수정
			if (idata.wdcptnm === '' || idata.wdcptnm === null) {
				setMessage({ openAlert: true, severity: 'info', msg: '품명은 필수입력입니다.' });
				return;
			}
		}
		return true;
	};

	
	const AlertDialogResult = async (e) => {
		//AlertDialog 초기화
		setDlgMessage({ openDlg: false, dlgTrans: '', dlgMsg: '' });

		//DialogResult
		if (e.result == 'N') return;

		//Transaction 구분
		switch (e.Trans) {
			//등록
			case 'I':
				BackendCall(e.Trans);
				break;
			//수정
			case 'U':
				BackendCall(e.Trans);
				break;
			//삭제
			case 'D':
				const queryD = SERVER_URL + '/rom4l/delete';
				setDeleteApi(queryD); // Update API
				break;
		}
	};

	/**
	 * Grid Bind Data
	 * @returns
	 */
	const onGridBindData = (para) => {};

	/**
	 *Grid Column 정의
	 */
	//Main Grid Column
	const columnDefs = [
		{
			headerName: 'wrcvand',
			field: 'wrcvand',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: true,
		},
		{
			headerName: 'wrccomp',
			field: 'wrccomp',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: true,
		},
		{
			headerName: '공장',
			field: 'wdcplnt',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '품번',
			field: 'wdcptno',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 140,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			valueGetter: Utils.partnoGetter,
			hide: false,
		},
		{
			headerName: '품명',
			field: 'wdcptnm',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 260,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '사용',
			field: 'wdcflag',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
	];

	/**
	 * Input 항목 Forcus Select
	 * @param {*} event
	 */
	const handleFocus = (event) => {
		event.target.select();
	};

	const checkNum = (str) => {
		let val = str;

		if (/\D/gi.test(str)) {
			val = val.replaceAll(/\D/gi, '');
		}
		return val;
	};

	return (
		<>
			{/* Dialog FRAME */}
			<AlertDialog open={openDlg} trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

			{/* Alert FRAME */}
			<CustomAlert
				open={openAlert}
				severity={severity}
				duration={3000}
				message={msg}
				onClose={() => {
					setMessage({ openAlert: false, severity: '', msg: '조회 실패' });
				}}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			/>

			{/* Loading */}
			<Loading loading={loading} />

			{/* Ribbon Frame : 프로그램 Path 및 버튼 */}
			<Ribbon
				onInfoMsg={'수기로 입력한 데이터는 납품서 발행(출하정보전송)이 불가합니다. <br/> 수신자료관리 – 기초관리 – 자료생성(수동)에서 데이터생성 먼저 진행해 주시기 바랍니다.'}
				onSearch={onSearch}
				onSearchName={null} //조회
				onInsert={onInsert}
				onInsertName={null} //입력
				onUpdate={onUpdate}
				onUpdateName={null} //수정
				onDelete={onDelete}
				onDeleteName={null} //삭제
				onExcel={onExcel}
				onExcelName={null} //Excel
			>
				{'납입지시 > 기초관리 > '}
				<b style={{ color: '#cd5c5c', fontFamily: 'PretendardLight, NanumGothic, sans-serif' }}>ERP_품목관리</b>
			</Ribbon>

			{/* ContentXearch Frame : 검색조건 */}
			<ContentXearch optionsX={optionsX} onChange={onChange} onClick={onClick} />

			{/* Content FRAME (Data / Grid Frame) START -------------- */}
			<ContentTwo style={style}>
				{' '}
				{/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:100px */}
				<div className={'grid_type05'}>
					{/* 좌측 FRAME */}
					<div className={'grid_wrap05'}>
						{' '}
						{/* 좌측 wrap FRAME */}
						<div className="grid_area">
							{' '}
							{/* Grid area */}
							<div className="grid_cont_box" style={{ height: 'calc(100%)' }}>
								<Grid
									ref={grdRom4lMain}
									gridId={'ROM4L'} // (필수) Grid Id 정의
									columnDefs={columnDefs} // (필수) 컬럼 정의
									pagination={true} // 쪽수 매기기 여부
									paginationCsv={false} // CSV 사용구분 - pagination과 동시에 사용 필요
									paginationPageSize={'1000'} // 쪽수 1Page 당 Row수
									rowMultiSelectWithClick={true} // 멀티 클릭 가능 여부
									suppressRowClickSelection={false} // 클릭으로 선택 하는것을 방지
									rowSelection={'multiple'} // 여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
									enableRangeSelection={false}
									enableCellTextSelection={true} // cell selection drag mode
									isFirst={true} // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
									displayNo={false}
									searchApi={searchApi} // 조회
									deleteApi={deleteApi} // 삭제
									deleteRefresh={onSearch} // 삭제이후
									updateRefresh={onSearch2} // 수정이후
									onClearApi={onClearApi} // 초기화
									clearGrid={clearGrid}
									onClearGridFlag={setClearGrid}
									onCellValueChangedEvent={grdRom4lMain_onCellValueChanged}
									onRowClickEvent={grdRom4lMain_onRowClicked}
									expanded={panded}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={'grid_type06'}>
					{/* 우측 FRAME */}
					<div className="grid_wrap06">
						{' '}
						{/* 우측 wrap FRAME */}
						<div className="grid_area" style={{ height: '100%', border: '1px solid #babfc7' }}>
							{' '}
							{/* Grid area */}
							<table className="input_box input_box01" style={{ width: '100%' }}>
								<colgroup>
									<col width="9%" />
									{/*<!-- <col width="0.1px"/> -->*/}
									<col width="12%" />
									<col width="16%" />
									<col width="15%" />
									<col width="17%" />
									<col width="13%" />
									<col width="17%" />
								</colgroup>
								<tbody>
									<tr className="standard_tr uno_tr">
										<td className="t_head t_head_do" rowSpan="9">
											D0
										</td>
										<td className="k_label13R">
											<b>
												품 번(<span className="no_point">*</span>)
											</b>
										</td>
										<td className="k_label18L">
											{/* 품번입력 */}
											<input className="k_inputChkL_M" value={idata.wdcptno} onChange={onChange} maxLength="18" name="wdcptno" onFocus={handleFocus} />
										</td>
										<td className="k_label13R">
											<b>
												품 명(<span className="no_point">*</span>)
											</b>
										</td>
										<td className="k_label18L" colSpan="3">
											{/* 품명입력 */}
											<input className="k_inputChkL_M" value={idata.wdcptnm} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="30" name="wdcptnm" onFocus={handleFocus} />
										</td>
									</tr>
									<tr className={panded === true ? 'standard_tr uno_tr' : 'standard_tr2 uno_tr'}>
										<td className="k_label13R">대표하치장&nbsp;</td>
										<td className="k_label18L">
											{/* 대표하치장입력 */}
											<input className="k_inputChkL_M" value={idata.wdcgate} onChange={onChange} maxLength="4" name="wdcgate" onFocus={handleFocus} />
										</td>
										<td className="k_label13R">실적구분&nbsp;</td>
										<td className="k_label18L">
											{/* 실적구분입력 */}
											<input className="k_inputChkC_M" value={idata.wdcsjgb} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="1" name="wdcsjgb" onFocus={handleFocus} />
										</td>
										<td className="k_label13R">납품서구분&nbsp;</td>
										<td className="k_label18L">
											{/* 납품서구분입력 */}
											<input className="k_inputChkC_M" value={idata.wdcnpgb} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="1" name="wdcnpgb" onFocus={handleFocus} />
										</td>
									</tr>
									<tr className={panded === true ? 'standard_tr uno_tr' : 'standard_tr2 uno_tr'}>
										<td className="k_label13R">용기코드&nbsp;</td>
										<td className="k_label18L">
											{/* 용기코드 입력 */}
											<input className="k_inputChkL_M" value={idata.wdccase} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="7" name="wdccase" onFocus={handleFocus} />
										</td>
										<td className="k_label13R">용기당수량&nbsp;</td>
										<td className="k_label18L">
											{/* 용기당수량 입력 */}
											<input className="k_inputChkNumR" value={idata.wdcqypc} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="13" name="wdcqypc" onFocus={handleFocus} />
										</td>
										<td className="k_label13R">입고방식&nbsp;</td>
										<td className="k_label18L">
											{/* 입고방식 입력 */}
											<input className="k_inputChkL_M" value={idata.wdciggb} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="1" name="wdciggb" onFocus={handleFocus} />
										</td>
									</tr>
									<tr className={panded === true ? 'standard_tr uno_tr' : 'standard_tr2 uno_tr'}>
										<td className="k_label13R">효력시작일&nbsp;</td>
										<td className="k_label18L">
											{/* 효력시작일 입력 */}
											<input className="k_inputChkL" value={idata.wdcfrdt} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="2" name="wdcfrdt" onFocus={handleFocus} />
										</td>
										<td className="k_label13R">효력종료일&nbsp;</td>
										<td className="k_label18L">
											{/* 효력종료일 입력 */}
											<input className="k_inputChkL" value={idata.wdctodt} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="2" name="wdctodt" onFocus={handleFocus} />
										</td>
										<td className="k_label13R">검수유무&nbsp;</td>
										<td className="k_label18L">
											{/* 검수유무 입력 */}
											<input className="k_inputChkL_M" value={idata.wdcokgb} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="1" name="wdcokgb" onFocus={handleFocus} />
										</td>
									</tr>
									<tr className={panded === true ? 'standard_tr uno_tr' : 'standard_tr2 uno_tr'}>
										<td className="k_label13R">구매문서&nbsp;</td>
										<td className="k_label18L">
											{/* 구매문서 입력 ReadyOnly */}
											<input className="k_inputChkL" value={idata.wdcdono} style={{ WIDTH: '100%' }} readOnly maxLength="1" name="wdcdono" />
										</td>
										<td className="k_label13R">품목번호&nbsp;</td>
										<td className="k_label18L">
											{/* 품목번호 입력 ReadyOnly */}
											<input className="k_inputChkL_M" value={idata.wdcdopt} style={{ WIDTH: '100%' }} readOnly maxLength="1" name="wdcdopt" />
										</td>
										<td className="k_label13R">담당자&nbsp;</td>
										<td className="k_label18L">
											{/* 담당자 입력 */}
											<input className="k_inputChkL_M" value={idata.wdccrdx} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="3" name="wdccrdx" onFocus={handleFocus} />
										</td>
									</tr>
								</tbody>
							</table>{' '}
							{/*<!-- DO 테이블 END --> */}
							<table className="input_box input_box01" style={{ width: '100%' }}>
								<colgroup>
									<col width="9%" />
									<col width="12%" />
									<col width="7.7%" />
									<col width="7.7%" />
									<col width="7.7%" />
									<col width="7.7%" />
									<col width="7.7%" />
									<col width="7.7%" />
									<col width="7.7%" />
									<col width="7.7%" />
									<col width="7.7%" />
									<col width="7.7%" />
								</colgroup>
								<tbody>
									<tr className={panded === true ? 'standard_tr dos_tr' : 'standard_tr2 dos_tr'}>
										<td className="t_head t_head_do" rowSpan="12">
											DH
										</td>
										<td className="k_label13R">하치장</td>
										<td className="k_label18L">
											{/* 하치장1 입력 */}
											<input className="k_inputChkL_M" value={idata.wdcgate1} onChange={onChange} maxLength="4" name="wdcgate1" onFocus={handleFocus} />
										</td>
										<td className="k_label18L">
											{/* 하치장2 입력 */}
											<input className="k_inputChkL_M" value={idata.wdcgate2} onChange={onChange} maxLength="4" name="wdcgate2" onFocus={handleFocus} />
										</td>
										<td className="k_label18L">
											{/* 하치장3 입력 */}
											<input className="k_inputChkL_M" value={idata.wdcgate3} onChange={onChange} maxLength="4" name="wdcgate3" onFocus={handleFocus} />
										</td>
										<td className="k_label18L">
											{/* 하치장4 입력 */}
											<input className="k_inputChkL_M" value={idata.wdcgate4} onChange={onChange} maxLength="4" name="wdcgate4" onFocus={handleFocus} />
										</td>
										<td className="k_label18L">
											{/* 하치장5 입력 */}
											<input className="k_inputChkL_M" value={idata.wdcgate5} onChange={onChange} maxLength="4" name="wdcgate5" onFocus={handleFocus} />
										</td>
										<td className="k_label18L">
											{/* 하치장6 입력 */}
											<input className="k_inputChkL_M" value={idata.wdcgate6} onChange={onChange} maxLength="4" name="wdcgate6" onFocus={handleFocus} />
										</td>
										<td className="k_label18L">
											{/* 하치장7 입력 */}
											<input className="k_inputChkL_M" value={idata.wdcgate7} onChange={onChange} maxLength="4" name="wdcgate7" onFocus={handleFocus} />
										</td>
										<td className="k_label18L">
											{/* 하치장8 입력 */}
											<input className="k_inputChkL_M" value={idata.wdcgate8} onChange={onChange} maxLength="4" name="wdcgate8" onFocus={handleFocus} />
										</td>
										<td className="k_label18L">
											{/* 하치장9 입력 */}
											<input className="k_inputChkL_M" value={idata.wdcgate9} onChange={onChange} maxLength="4" name="wdcgate9" onFocus={handleFocus} />
										</td>
										<td className="k_label18L">
											{/* 하치장10 입력 */}
											<input className="k_inputChkL_M" value={idata.wdcgate10} onChange={onChange} maxLength="4" name="wdcgate10" onFocus={handleFocus} />
										</td>
									</tr>
									<tr className={panded === true ? 'standard_tr dos_tr' : 'standard_tr2 dos_tr'}>
										<td className="k_label13R">한글품명</td>
										<td className="k_label18L" colSpan="6">
											{/* 한글품명 입력 */}
											<input className="k_inputChkL" value={idata.wdckorn} onChange={onChange} maxLength="30" name="wdckorn" onFocus={handleFocus} />
										</td>
										<td className="k_label13R">공급자</td>
										<td className="k_label18L" colSpan="3">
											{/* 공급자 입력 */}
											<input className="k_inputChkL_M" value={idata.wdctrst} onChange={onChange} maxLength="2" name="wdctrst" onFocus={handleFocus} />
										</td>
									</tr>
									<tr className={panded === true ? 'standard_tr dos_tr' : 'standard_tr2 dos_tr'}>
										<td className="k_label13R">공정No</td>
										<td className="k_label18L">
											{/* 공정No 1 */}
											<input className="k_inputChkL_M" value={idata.wdcshop1} onChange={onChange} maxLength="1" name="wdcshop1" onFocus={handleFocus} />
										</td>
										<td className="k_label18L">
											{/* 공정No 2 */}
											<input className="k_inputChkL_M" value={idata.wdcline1} onChange={onChange} maxLength="6" name="wdcline1" onFocus={handleFocus} />
										</td>
										<td className="k_label18L" colSpan="2">
											{/* 공정No 3 */}
											<input className="k_inputChkL_M" value={idata.wdcaspr1} onChange={onChange} maxLength="10" name="wdcaspr1" onFocus={handleFocus} />
										</td>
										<td className="k_label18L" colSpan="2">
											{/* 공정No 4 */}
											<input className="k_inputChkL_M" value={idata.wdcrack1} onChange={onChange} maxLength="3" name="wdcrack1" onFocus={handleFocus} />
										</td>
										<td className="k_label13R">HPC</td>
										<td className="k_label18L">
											{/* HPC1 */}
											<input className="k_inputChkL_M" value={idata.wdccarc} onChange={onChange} maxLength="2" name="wdccarc" onFocus={handleFocus} />
										</td>
										<td className="k_label18L">
											{/* HPC2 */}
											<input className="k_inputChkL_M" value={idata.wdcalct} onChange={onChange} maxLength="1" name="wdcalct" onFocus={handleFocus} />
										</td>
										<td className="k_label18L">
											{/* HPC3 */}
											<input className="k_inputChkL_M" value={idata.wdcdlgt} onChange={onChange} maxLength="3" name="wdcdlgt" onFocus={handleFocus} />
										</td>
									</tr>
									<tr className={panded === true ? 'standard_tr dos_tr' : 'standard_tr2 dos_tr'}>
										<td className="k_label13R">공정&nbsp;</td>
										<td className="k_label18L" colSpan="6">
											{/* 공정 입력 */}
											<input className="k_inputChkL_M" value={idata.wdcgjno} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="5" name="wdcgjno" onFocus={handleFocus} />
										</td>
										<td className="k_label13R">조립코드&nbsp;</td>
										<td className="k_label18L" colSpan="3">
											{/* 조립코드 입력 */}
											<input className="k_inputChkL_M" value={idata.wdcprcd} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="5" name="wdcprcd" onFocus={handleFocus} />
										</td>
									</tr>
									<tr className={panded === true ? 'standard_tr dos_tr' : 'standard_tr2 dos_tr'}>
										<td className="k_label13R">ALC&nbsp;</td>
										<td className="k_label18L" colSpan="6">
											{/* ALC 입력 */}
											<input className="k_inputChkL_M" value={idata.wdcalcc} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="3" name="wdcalcc" onFocus={handleFocus} />
										</td>
										<td className="k_label13R">납품단가&nbsp;</td>
										<td className="k_label18L" colSpan="3">
											{/* 납품단가 입력 */}
											<input
												type="text"
												className="k_inputChkL_M"
												value={checkNum(idata.wdcnppr)}
												onChange={onChange}
												style={{ WIDTH: '100%' }}
												maxLength="11"
												name="wdcnppr"
												onFocus={handleFocus}
											/>
										</td>
									</tr>
									<tr className={panded === true ? 'standard_tr dos_tr' : 'standard_tr2 dos_tr'}>
										<td className="k_label13R">LANE&nbsp;</td>
										<td className="k_label18L" colSpan="6">
											{/* LANE 입력 */}
											<input className="k_inputChkL" value={idata.wdclane} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="30" name="wdclane" onFocus={handleFocus} />
										</td>
										<td className="k_label13R">특기&nbsp;</td>
										<td className="k_label18L" colSpan="3">
											{/* 특기 입력 */}
											<input className="k_inputChkL_M" value={idata.wdcoptn} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="10" name="wdcoptn" onFocus={handleFocus} />
										</td>
									</tr>
								</tbody>
							</table>{' '}
							{/*<!-- DH 테이블 END -->*/}
							<table className="input_box input_box01" style={{ width: '100%' }}>
								<colgroup>
									<col width="9%" />
									{/*<!-- <col width="0.1px"/> -->*/}
									<col width="12%" />
									<col width="16%" />
									<col width="15%" />
									<col width="17%" />
									<col width="13%" />
									<col width="17%" />
								</colgroup>
								<tbody>
									<tr className={panded === true ? 'standard_tr tres_tr' : 'standard_tr2 tres_tr'}>
										<td className="t_head t_head_do" rowSpan="7">
											사<br />용<br />자<br />정<br />의
										</td>
										<td className="k_label13R">식별표Type</td>
										<td className="k_label18L">
											<select className="k_combo" name="wdcptgb" value={idata.wdcptgb} onChange={onChange}>
												<option selected value=" "></option>
												{idata.typeList.map((element) =>
													element.comCode == idata.wdcptgb ? (
														<option key={'wdcptgb' + element.comCode} value={element.comCode} selected>
															{element.comCode + ' ' + element.text}
														</option>
													) : (
														<option key={'wdcptgb' + element.comCode} value={element.comCode}>
															{element.comCode + ' ' + element.text}
														</option>
													),
												)}
											</select>
										</td>
										<td className="k_label13R">부착면수</td>
										<td className="k_label18L">
											{/* 부착면수 입력 */}
											<input
												className="k_inputChkNumR"
												value={idata.wdcstck}
												onChange={onChange}
												maxLength="20"
												name="wdcstck"
												onFocus={handleFocus}
												readOnly={idata.wdcplnt != 'HK11' && idata.wdcplnt != 'HK21' && idata.wdcplnt != 'HK31' ? false : true}
											/>
										</td>

										<td className="k_label13R">지역</td>
										<td className="k_label18L">
											{/* 지역 Select */}
											<select className="k_combo" name="wdcspec" value={idata.wdcspec} onChange={onChange}>
												<option selected value=" "></option>
												{idata.wdcspecList.map((element) => (
													<option key={'wdcspec' + element.comCode} value={element.comCode}>
														{element.text}
													</option>
												))}
											</select>
										</td>
									</tr>
									<tr className={panded === true ? 'standard_tr tres_tr' : 'standard_tr2 tres_tr'}>
										<td className="k_label13R">L/R 구분</td>
										<td className="k_label18L">
											<select className="k_combo" name="wdcckgb" value={idata.wdcckgb} onChange={onChange}>
												{/* L/R Select */}
												<option selected value=" "></option>
												{idata.wdcckgbList.map((element) => (
													<option key={'wdcckgb' + element.comCode} value={element.comCode}>
														{element.text}
													</option>
												))}
											</select>
										</td>
										<td className="k_label13R">RHD적용</td>
										<td className="k_label18L_f radio_box">
											{/* RHD Radio */}
											<label className="k_label_radio">
												<input checked={idata.wdcrthd === 'Y'} type="radio" value="Y" name="wdcrthd" onChange={onChange} />
												&nbsp; <span className="radio_color">Yes</span>
											</label>
											<label className="k_label_radio">
												<input checked={idata.wdcrthd === 'N'} type="radio" value="N" name="wdcrthd" onChange={onChange} />
												&nbsp; <span className="radio_color">No</span>
											</label>
										</td>
										<td className="k_label13R">사용여부</td>
										<td className="k_label18L_f radio_box">
											{/* 사용여부 Radio */}
											<label>
												<input className="k_label_radio" checked={idata.wdcflag === 'Y'} type="radio" value="Y" name="wdcflag" onChange={onChange} />
												&nbsp; <span className="radio_color">Yes</span>
											</label>
											<label>
												<input className="k_label_radio" checked={idata.wdcflag === 'N'} type="radio" value="N" name="wdcflag" onChange={onChange} />
												&nbsp; <span className="radio_color">No</span>
											</label>
											<label>
												<input className="k_label_radio" checked={idata.wdcflag === 'S'} type="radio" value="S" name="wdcflag" onChange={onChange} />
												&nbsp; <span className="radio_color">T2</span>
											</label>
										</td>
									</tr>
									<tr className={panded === true ? 'standard_tr tres_tr' : 'standard_tr2 tres_tr'}>
										<td className="k_label13R_f" colSpan="2">
											[이중인쇄 출력1설정]
										</td>
										<td className="k_label13R">내용&nbsp;</td>
										<td className="k_label18L">
											{/* 내용입력 */}
											<input className="k_inputChkL2" value={idata.wdclbl1} onChange={onChange} maxLength="25" name="wdclbl1" onFocus={handleFocus} />
										</td>
										<td className="k_label13R">색상&nbsp;</td>
										<td className="k_label18L_f radio_box">
											{/* 색상 select */}
											<label title="Red">
												<input type="radio" value="R" name="wdccol1" checked={idata.wdccol1 === 'R'} onChange={onChange} />
												&nbsp; <span className="radio_color radio_color01">R</span>
											</label>
											<label title="Green">
												<input type="radio" value="G" name="wdccol1" checked={idata.wdccol1 === 'G'} onChange={onChange} />
												&nbsp; <span className="radio_color radio_color02">G</span>
											</label>
											<label title="Blue">
												<input type="radio" value="B" name="wdccol1" checked={idata.wdccol1 === 'B'} onChange={onChange} />
												&nbsp; <span className="radio_color radio_color03">B</span>
											</label>
											<label title="Orange">
												<input type="radio" value="O" name="wdccol1" checked={idata.wdccol1 === 'O'} onChange={onChange} />
												&nbsp; <span className="radio_color radio_color04">O</span>
											</label>
											<label title="Black">
												<input type="radio" value="X" name="wdccol1" checked={idata.wdccol1 === 'X'} onChange={onChange} />
												&nbsp; <span className="radio_color radio_color05">X</span>
											</label>
										</td>
									</tr>
									<tr className={panded === true ? 'standard_tr tres_tr' : 'standard_tr2 tres_tr'}>
										<td className="k_label13R">폰트사이즈</td>
										<td className="k_label18L">
											{/* 폰트사이즈 입력 */}
											<input className="k_inputChkNumR" value={checkNum(idata.wdcsiz1)} onChange={onChange} maxLength="3" name="wdcsiz1" onFocus={handleFocus} />
										</td>
										<td className="k_label13R" title="[이중인쇄 출력1설정] X좌표 : 절취선부터 mm 측정 (글자 폭에 따라 오차범위 존재 : ± 5mm)" style={{ cursor: 'pointer' }}>
											<b>X좌표</b>
										</td>
										<td className="k_label18L">
											{/* X좌표 입력 */}
											<input
												className="k_inputChkNumR"
												value={checkNum(idata.wdcprx1)}
												onChange={onChange}
												style={{ WIDTH: '85%', paddingRight: '5px' }}
												maxLength="3"
												name="wdcprx1"
												onFocus={handleFocus}
											/>
											<b>㎜</b>
										</td>
										<td className="k_label13R" title="[이중인쇄 출력1설정] Y좌표 : 절취선부터 mm 측정" style={{ cursor: 'pointer' }}>
											<b>Y좌표</b>
										</td>
										<td className="k_label18L">
											{/* Y좌표 입력 */}
											<input
												className="k_inputChkNumR"
												value={checkNum(idata.wdcpry1)}
												onChange={onChange}
												style={{ WIDTH: '85%', paddingRight: '5px' }}
												maxLength="3"
												name="wdcpry1"
												onFocus={handleFocus}
											/>
											<b>㎜</b>
										</td>
									</tr>
									<tr className={panded === true ? 'standard_tr tres_tr' : 'standard_tr2 tres_tr'}>
										<td className="k_label13R_f" colSpan="2">
											[이중인쇄 출력2설정]
										</td>
										<td className="k_label13R">내용</td>
										<td className="k_label18L">
											{/* 내용 입력 */}
											<input className="k_inputChkL2" value={idata.wdclbl2} onChange={onChange} maxLength="25" name="wdclbl2" onFocus={handleFocus} />
										</td>
										<td className="k_label13R">색상</td>
										<td className="k_label18L_f radio_box">
											{/* 색상 */}
											<label title="Red">
												<input type="radio" value="R" name="wdccol2" checked={idata.wdccol2 === 'R'} onChange={onChange} />
												&nbsp; <span className="radio_color radio_color01">R</span>
											</label>
											<label title="Green">
												<input type="radio" value="G" name="wdccol2" checked={idata.wdccol2 === 'G'} onChange={onChange} />
												&nbsp; <span className="radio_color radio_color02">G</span>
											</label>
											<label title="Blue">
												<input type="radio" value="B" name="wdccol2" checked={idata.wdccol2 === 'B'} onChange={onChange} />
												&nbsp; <span className="radio_color radio_color03">B</span>
											</label>
											<label title="Orange">
												<input type="radio" value="O" name="wdccol2" checked={idata.wdccol2 === 'O'} onChange={onChange} />
												&nbsp; <span className="radio_color radio_color04">O</span>
											</label>
											<label title="Black">
												<input type="radio" value="X" name="wdccol2" checked={idata.wdccol2 === 'X'} onChange={onChange} />
												&nbsp; <span className="radio_color radio_color05">X</span>
											</label>
										</td>
									</tr>
									<tr className={panded === true ? 'standard_tr tres_tr' : 'standard_tr2 tres_tr'}>
										<td className="k_label13R">폰트사이즈&nbsp;</td>
										<td className="k_label18L">
											{/* 폰트사이즈 입력 */}
											<input className="k_inputChkNumR" value={checkNum(idata.wdcsiz2)} onChange={onChange} style={{ WIDTH: '100%' }} maxLength="3" name="wdcsiz2" onFocus={handleFocus} />
										</td>
										<td className="k_label13R" title="[이중인쇄 출력2설정] X좌표 : 절취선부터 mm 측정 (글자 폭에 따라 오차범위 존재 : ± 5mm)" style={{ cursor: 'pointer' }}>
											<b>X좌표</b>
										</td>
										<td className="k_label18L">
											{/* X좌표 입력 */}
											<input
												className="k_inputChkNumR"
												value={checkNum(idata.wdcprx2)}
												onChange={onChange}
												style={{ WIDTH: '85%', paddingRight: '5px' }}
												maxLength="3"
												name="wdcprx2"
												onFocus={handleFocus}
											/>
											<b>㎜</b>
										</td>
										<td className="k_label13R" title="[이중인쇄 출력2설정] Y좌표 : 절취선부터 mm 측정" style={{ cursor: 'pointer' }}>
											<b>Y좌표</b>
										</td>
										<td className="k_label18L">
											{/* Y좌표 입력 */}
											<input
												className="k_inputChkNumR"
												value={checkNum(idata.wdcpry2)}
												onChange={onChange}
												style={{ WIDTH: '85%', paddingRight: '5px' }}
												maxLength="3"
												name="wdcpry2"
												onFocus={handleFocus}
											/>
											<b>㎜</b>
										</td>
									</tr>

									<tr className={panded === true ? 'standard_tr tres_tr' : 'standard_tr2 tres_tr'}>
										<td className="k_label13R" title="부품식별표VMI/PO 적입중량 표시 추가(전주공장 중대재해 점검결과 개선지적 사항)" style={{ cursor: 'pointer' }}>
											<b>적입중량(부품당)</b>
										</td>
										<td className="k_label18L">
											{/* 적입중량 입력  20220630 추가 */}
											<input
												className="k_inputChkNumR"
												value={idata.wdcwtun}
												onChange={onChange}
												style={{ WIDTH: '100%', paddingRight: '5px' }}
												maxLength="10"
												name="wdcwtun"
												onFocus={handleFocus}
											/>
											<b>kg</b>
										</td>
										<td className="k_label13R" style={{ verticalAlign: 'top' }}>
											<b>
												사용자 지정 <br></br>용기당수량
											</b>
										</td>
										<td className="">
											{/* 업체 용기당수량 입력  20220708 추가 */}
											<input
												className="k_inputChkNumR"
												value={idata.wrcvnqt}
												onChange={onChange}
												readOnly={idata.wrcvnyn === 'Y' ? false : true}
												style={
													idata.wrcvnyn === 'Y'
														? { width: '65%', height: '36px', paddingRight: '5px', border: '1px solid #CECDD9', marginRight: '5px', color: 'red', fontWeight: 'bold' }
														: { width: '65%', height: '36px', paddingRight: '5px', border: '1px solid #CECDD9', marginRight: '5px', backgroundColor: '#e1e0eb', opacity: '0.5' }
												}
												maxLength="13"
												name="wrcvnqt"
												onFocus={handleFocus}
											/>
											<select
												className="k_combo"
												name="wrcvnyn"
												value={idata.wrcvnyn}
												onChange={onChange}
												readOnly={idata.wrcvnyn === 'Y' ? false : true}
												style={{ width: '30%', paddingRight: '5px', paddingLeft: '5px', border: '1px solid #CECDD9', height: '36px' }}
											>
												{/* 사용여부 Select */}
												<option selected value=" "></option>
												<option selected value="Y">
													Y
												</option>
												<option selected value="N">
													N
												</option>
											</select>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</ContentTwo>
		</>
	);
}
export default ROM4L;
