/*  Dashboard 생성현황등록 Popup*/
import React, { Component } from 'react';
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
import CommonUtil from '@/utils/CommonUtil'; 

 
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
const Utils = new CommonUtil();


class DashboardPopup extends Component {

    constructor(props)  {
        super(props);
        this.grdCarTypeMain = React.createRef();

        this.state = {
            //Common
            token:Common.getToken(),
            userId :Common.getToken("id"),
            userName : Common.getToken("name"),
            tVnt1 : Common.getToken("vand"),
            tComp : Common.getToken("comp"),
    
            //조회조건
            kGubn : "*",          
    
            //Alert 창 ( Alert 창 OPEN,  Alert 창 구분, Alert 창 메세지)
            openAlert : false, severity : "success", msg : "",
    
            //Dialog 창 (Dialog 창 OPEN, Dialog Transaction(I,U,D),  Dialog 메세지)
            openDlg : false, dlgTrans : "", dlgMsg : "", 

    
            //Api (조회, 등록, 수정, 삭제)
            searchApi : false, insertApi : false, updateApi : false, deleteApi : false,

            loadingFlag : false,setTypeOpen:false
        }
        this.closeModal = this.closeModal.bind(this);
    }

    
    /**
     * WillMount
     */
    async componentWillMount() {

        //조회조건 Setting
        this.setState({loadingFlag : true}); 

    }


    /**
     * DidMount
     */
    componentDidMount() {
        let para = `{"vand":"${this.state.tVnt1}","kGubn":"${this.state.kGubn}"}`;
        let param = encodeURIComponent(para);

        const query = SERVER_URL + "/popup/dashboardPopup_search?query=" + param;


        const fetchSearchData = async (query) => {
        let inputData = this.state; 
            await fetch(query,  { headers: { Authorization: this.state.token  } })
                .then((resp) => resp.json())
                .then((data) => {

                    if (data.success) {
                        this.setState({ searchApi : query})
                        //this.setState({ ...inputData });
                        
                    }
                    if (data.error) {
                        this.setState({ openAlert : true, 
                                        severity : "error",
                                        msg : "조회 실패"});
                    }
                })
                .catch((error) => {
                    // 에러 응답 처리
                    if (error) {
                        this.setState({ openAlert : true, 
                                        severity : "error",
                                        msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                    }
                });
        };
        setTimeout(() => fetchSearchData(query), 300);
    }


    // /**
    //  * WillReceiveProps
    //  */
    // componentWillReceiveProps () {
    // }

    // /**
    //  * WillUpdate
    //  * @param {*} nextProps 
    //  * @param {*} nextState 
    //  */
    // componentWillUpdate(nextProps, nextState) {
    // }

    // /**
    //  * DidUpdate
    //  * @param {*} prevProps 
    //  * @param {*} prevState 
    //  */
    // componentDidUpdate(prevProps, prevState) {
    // }

    // /**
    //  * WillUnmount
    //  */
    // componentWillUnmount() {
    // }


    /**
     * 공통코드 조회
    */
     onSearchCommon = (para, name) => {
    }


    /**
     * 회사구분 코드 조회
    */
      onSearchComp = async (para) => {

        let param = encodeURI(para);
        let elements = {}; // 객체 생성

        const query = SERVER_URL + "/comcd/search1?query=" + param; 

        await fetch(query, { headers: { Authorization: this.state.token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) { 
                    data.data.forEach(function (element) {
                        elements[element.comCode] = element.text;
                    }, this)
                }
            })
        return elements;
    }

    closeModal = () => {
     //   return <Popup type="CarType" onClose={()=> {this.state.setTypeOpen(false)}}></Popup>
      };


    render() {

        if (this.state.loadingFlag === false) {
            return null
        }
        else {
        
            //State
            const {token, userId, userName, tVnt1, tComp, kGubn,  openAlert, severity, msg,
                   openDlg, dlgTrans, dlgMsg,  searchApi, insertApi, updateApi, deleteApi } = this.state;


            //Grid Clear Api               
            const onClearApi = async (e) => {
                this.setState({ searchApi : false, insertApi : false, updateApi : false, deleteApi : false})
            }


            /**
             * Control 변경 Event
             * @param {*} e 
             */
            const onChange = async (e) => {
                const { name, value } = e.target;

                let newSearchs = this.state;
                newSearchs[name] = value
                this.setState({ ...newSearchs });
            }

        
            /**
             * Grid Event 
             * @param {*} e 
            */
            const onCellValueChanged = async (e) => {
            }

            const onRowClicked = async (e) => {
            }


            /**
             * 검색조건
            */
            const optionsX = {
                0: [
                    //업체 
                    {
                        "formatter": "select", "name": "kGubn", "labelName": "등록현황", "type": "text", "desc": "",
                        "defaultValue": kGubn,  
                        "align": "left", 
                        "labelType": "none", 
                        "items": [{ "comCode": "*", "text": "전체" },{ "comCode": "Y", "text": "등록" }, { "comCode": "N", "text": "미등록" }]
                    }   
                ]
            }

        
        
            /**
             * 조회
             * @param {*} e 
             */
            const onSearch = async (e) => { 

                let para = `{"vand":"${this.state.tVnt1}","kGubn":"${this.state.kGubn}"}`;
                let param = encodeURI(para);

                const query = SERVER_URL + "/popup/dashboardPopup_search?query=" + param;
                this.setState({ searchApi : query})
            }



            /**
             * 신규 Row추가
             * @param {*} e 
             */
            const onAddRow = async (e) => {
            }



            /**
             * 입력
             * @param {*} e 
             */
            const onInsert = async (e) => {

                //input check
                let input = await Promise.all ([inputCheck('I')]);
                if ( !input[0]) return;

                const query = SERVER_URL + "/popup/dashboardPopup_insert";
                this.setState({ insertApi : query})

            }



            /**
             * 수정 
             * @param {*} e 
             * @returns 
             */
            const onUpdate = async (e) => {

                //input check
                let input = await Promise.all ([inputCheck('U')]);
                if ( !input[0]) return;
            
                const query = SERVER_URL + "/popup/carType_update";
                this.setState({ updateApi : query}) // Update API

            }



            /**
             * 삭제
             * @param {*} e 
             */
            const onDelete = async (e) => {
            }



            //deleteBlank
            function deleteBlank(argval){

                if (argval === null || argval === undefined) return "";

                for(var i=argval.length-1 ;i >= 0; i--){
                    
                    if(argval.charCodeAt(i) !== 12288 && argval.charCodeAt(i) !== 32 ) //charCodeAt(i)  
                    return(argval.substring(0,i+1));
                }
                return(argval.length>0?argval.substring(0,0):"")
            }



            // input check
            const inputCheck = (gbn) => { 

                let inputFlag = true;

                return inputFlag;
                
            }
            

            //AlertDialogResult
            const AlertDialogResult = async (e) => {

                //AlertDialog 초기화
                this.setState({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        
                //DialogResult
                if (e.result == "N") return;
        
                //Transaction 구분
                switch (e.Trans) 
                {
                    //등록
                    case "I" :
                        break;
                    //수정
                    case "U" :
                        break;
                    //삭제
                    case "D" :
                        break;
                }
            }

        

            //Grid DropDownList Value
            const extractValues = (mappings) => {
                return  Object.keys(mappings);
            }

            //Grid DropDownList Display
            const lookupValue = (mappings, key) => {
                return mappings[key];
            }        

            const editCellClicked = (params) => {
                let gridApi = params.api;
                let rowIdx = params.rowIndex;
                let dyorn = params.data.dyorn;
        
                var rowNode = gridApi.getRowNode(rowIdx);

                if(dyorn === "Y"){
                    rowNode.setDataValue("dyorn", "N");
                }else{
                    rowNode.setDataValue("dyorn", "Y");
                }
        
            }
        
            /**
             *Grid Column 정의 
            */
            const columnDefs = [
                { headerName: "DCOMP", field: "dcomp", sortable: true, filter: true, flex: 1, minWidth: 0, hide: true }, // hidden 컬럼
                { headerName: "자료유형", field: "dtype", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },  
                { headerName: "자료명", field: "dname", sortable: true, filter: true, flex: 1, minWidth: 200, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },  
                { headerName: "등록여부", field: "dyorn", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, onCellClicked: editCellClicked, headerClass: "editCell", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },  
                { headerName: "정렬", field: "dordr", sortable: true, filter: true, flex: 1, minWidth: 90, editable: true,cellEditor:"numericEditor", headerClass: "editCell", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,hide: false }, //cellEditor: 'agLargeTextCellEditor',cellEditorPopup: true,cellEditorParams:{maxLength:3}
            ];


            const onClick = () =>{
                this.state.setTypeOpen(false)
            }
        
            return (
                <>
                    {/* Dialog FRAME */}
                    <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />
                    
                    <button onClick={() => {this.props.onClose()}} style = {{border:"none", float:"right", paddingRight:"0px"}}> <img src="/images/button/close_button.png" alt="close" /></button> 

                    {/* Alert FRAME */}
                    <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {this.setState({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
        

                    {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
                    <Ribbon
                        newTabUnused={true}                         // newTabUnused : newTab 표출 버튼 true : 사용안함 // 선언안할경우 default = newTab 사용
                        onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                        onSearch={onSearch} onSearchName={null}     //조회
                        onInsert={onInsert} onInsertName={"입력"}     //등록
                    >
                    <b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>생성현황등록</b>
                    </Ribbon>
        
        
                    {/* ContentXearch Frame : 검색조건 */}
                    <ContentXearch
                        optionsX={optionsX}
                        onChange={onChange}/>
        
                    <div className={"grid_type01"} style={{ marginTop: "10px"}} > {/* 상단 FRAME */}
                        <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                            <div className="grid_cont_box" style={{ height: "342px", width : "100%" }}>
                                <Grid
                                    ref={this.grdPopupMain}
                                    gridId={"POPUP"}                  //  (필수) Grid Id 정의
                                    columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                    pagination={true}                  //  쪽수 매기기 여부
                                    paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"10"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    searchApi={searchApi}               // 조회
                                    insertApi={insertApi}               // 등록
                                    onClearApi={onClearApi}
                                    insertRefresh={onSearch}            // 등록이후 
                                    updateApi={updateApi}               // 수정
                                    updateRefresh={onSearch}            // 수정이후 
                                    deleteApi={deleteApi}               // 삭제
                                    deleteRefresh={onSearch}            // 삭제이후 
                                    
                                    //onClearApi={()=> {setSearchApi(false); setUpdateApi(false);}}
                                    onCellValueChangedEvent={onCellValueChanged}
                                    onRowClickEvent={onRowClicked}
                                    
                                />
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        
    }

}


export default DashboardPopup;