//수신자료관리 - ERP_모듈정보조회
import { Route, Switch } from 'react-router-dom';
import RNTC8 from '@/pages/B06/RNTC8';  //모듈단가 조회
import RNTC9 from '@/pages/B06/RNTC9';  //모듈단가 상세내역
import RNTC10 from '@/pages/B06/RNTC10';  //모듈별 정산현황
import RNTC11 from '@/pages/B06/RNTC11';  //사양차이 정산 상세내역
import RNTC12 from '@/pages/B06/RNTC12';  // 단가차이 정산 상세내역
import RNTC13 from '@/pages/B06/RNTC13';  // ZERO 단가 조건 정산 상세내역
import RNTC14 from '@/pages/B06/RNTC14';  // 정단가 조건 정산 상세내역


function B06() {
    return ( 
        <Switch>
            <Route path='/B06/RNTC8' component={RNTC8} />
            <Route path='/B06/RNTC9' component={RNTC9} />
            <Route path='/B06/RNTC10' component={RNTC10} />
            <Route path='/B06/RNTC11' component={RNTC11} />
            <Route path='/B06/RNTC12' component={RNTC12} />
            <Route path='/B06/RNTC13' component={RNTC13} />
            <Route path='/B06/RNTC14' component={RNTC14} />
        </Switch>
    );
}

export default B06;
