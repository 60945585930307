import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import * as Common from '@/utils/Common'; 
// import '../../css/main.css';
import '@/css/main.css';

import TabPage from './TabList';

export default function AppMain({ open, drawerWidth, children }) {
    const history = useHistory();
    const location = history.location.pathname;
    const bigi = Common.getToken("bigi");

    const useStyles = makeStyles((theme) => ({
        content: {
            flexGrow: 1,
            // padding: theme.spacing(3),
            // transition: theme.transitions.create('margin', {
            //     easing: theme.transitions.easing.sharp,
            //     duration: theme.transitions.duration.leavingScreen,
            // }),
            // marginLeft: -drawerWidth,
            marginLeft: 0,
            marginTop: "60px",
            padding: "0px",

            // display: "flex",
            // flexFlow: "column nowrap",
            // width: "100%",
            // height: "100%",
            // minWidth: "1000px",
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 220,
        },
    }));

    const classes = useStyles();

    return (
        <main
            className={clsx(classes.content, {
                [classes.contentShift]: open,
            }) //+ " content_section"
            }
        >
        {location==="/"||location==="/unauthorized"||location==="/multiIp"||location==="/NotFoundPage"?children:<TabPage location = {location} panel={children}/>}
        </main>
    );
}