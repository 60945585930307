import React from 'react';
import $ from "jquery";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useHistory } from "react-router-dom";
import '../../css/common.css';
import '../../css/style.css';
import '../../css/main_style.css';
import '../../css/maindash.css';
import '../../css/table.css';
import * as Common from '@/utils/Common'; 

function Breadcrumb({ children, onInfoMsg, newTabUnused, onInfoManual }) {
    const history = useHistory();
    const bigi = Common.getToken("bigi");
    const onClickInfo = (e) => {
        $(".p_help").html(onInfoMsg);
        // info Toggle
        if ($("#popup_layer").is(':visible')) {
            $("#popup_layer").hide();
        } else {
            $("#popup_layer").show();
        }
    };

    const onClickManual = (e) => {
        const pagePath = window.location.pathname.split('/')[2];

        const url = "https://rapdos.co.kr/help/"+pagePath+".html";
        window.open(url, 'RapdosManual', '_blank');
    };

    const onClickNewTab = (e) => { // 브라우저 새탭에서 보기 기능

        let url = window.location.href;
        window.open(url, '_blank');

        // alert(window.location.href); // - 현재 페이지의 href (URL) 반환  http://192.168.123.97:3000/pd/test

        // alert(window.location.hostname); // - 웹 호스트의 도메인 네임 반환  192.168.123.97
        // alert(window.location.pathname); // - 현재 페이지의 경로와 파일 이름 반환    /pd/test
        // alert(window.location.protocol); // - 사용하는 웹 프로토콜 반환 (http:// 혹은 https://)     http:

        // alert(window.location.assign); // - 새 document 로드    function assign() { [native code] }
        // window.location.assign(url); // - 새 document 로드    
    };

    return (
        <>
            <div className="position_list" >

                <span style={{ fontFamily: "'PretendardMedium', 'NanumGothic', sans-serif", cursor: "default",float: "left" }} > 
                    {history.location.pathname==="/"?<span title="경로" style={{float: "left",fontFamily: "'PretendardMedium', 'NanumGothic', sans-serif",fontSize: "17px"}}>{children}</span>  :
                    <span title="경로" style={{float: "left",fontFamily: "'PretendardMedium', 'NanumGothic', sans-serif",fontSize: "13px"}}>Position : {children}</span>  }

                    {newTabUnused ? ""
                    :<span title="[New Browser] Open" onClick={onClickNewTab} style={{float: "left",marginLeft: "0px"}}><div style={{background: "#ed5d3b",fontSize: "10pt",fontFamily: "'PretendardMedium'",cursor: "pointer",color: "#ffffff",borderRadius: "92px",width: "50px",textAlign: "center",marginLeft:"5px",paddingTop:"2px",height: "20px"}}>새창</div> </span>}

                    {onInfoManual===false ? ""                        
                    :<span style={{float: "left", marginLeft: "5px", marginRight: "0px" }}> <img className="btn_manual" src="/images/button/btn_manual.png" alt="Manual Botton" title="New Browser [ Manual ] Open" onClick={onClickManual} style={{ marginTop: "1px", cursor: "pointer" }} /></span>}

                    {onInfoMsg ?
                        <span style={{ float: "right",marginLeft: "5px", marginRight: "5px" }}> <img className="btn_help" src="/images/button/btn_question.png" alt="Question Botton" title="[show Tip] Open" onClick={onClickInfo} style={{ marginTop: "1px", cursor: "pointer" }} /></span>
                    : ""}
                </span>
                
            </div>
            <div id="popup_layer">
                {/* <p className="p_help"> {onInfoMsg} </p> */}
                <p className="p_help"></p>
            </div>
        </>
    );
}

export default Breadcrumb;
// export default React.memo(Breadcrumb);