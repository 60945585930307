/**
 * @Page RSY41
 * @Description [RSY41]납품서 현황(OEM) : 시스템관리 
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import GridRibbon from "@/components/organisms/GridRibbon";
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil';  
 import Popup from '@/pages/Popup/Popup';    
 
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
 const Utils = new CommonUtil();
 
 function RSY41() {
     const token = Common.getToken();
     const userId = Common.getToken("id");
     const userName = Common.getToken("name");
     const tVnt1 = Common.getToken("vand");
     const tComp = Common.getToken("comp");
     const grdRsy41List = useRef();   //Grid1
     const grdRsy41Detail = useRef();  //Grid2
     const grdRsy41List2 = useRef();   //Grid3
     const grdRsy41Detail2 = useRef();  //Grid4
     const master = Common.getToken("masterKey");
     
     //Data
     const [idata, setData] = useState({
        //조회조건
        kVnt1 : tVnt1,      //T1업체
        kComp: tComp,       //회사구분
        kNpdt:  Common.getToday(), // 발행일자 :: Default 값 셋팅       
        kPlnt: "",          //공장
        kDlno : "",         //납품서번호
        kGubn: "1",         // 조회구분(OEM) :: Default 값 셋팅       
        kGubn2: "",        // 조회구분(CKD) :: Default 값 셋팅  

        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장
        kMaster : master, //마스터 계정 여부
     }); 


      //납품내역 복구 Popup
    const [redeilOpen, setRedeilOpen] = React.useState(false);
 
      
     const [message, setMessage] = React.useState( {
         openAlert : false,      
         severity : "success",   
         msg : ""                
     });
     const { openAlert, severity, msg } = message;
 
 
     //Dialog
     const [dlgMessage, setDlgMessage] = React.useState( {
         openDlg : false,    
         dlgTrans : "",      
         dlgMsg : ""         
     });

     const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
 
 
     //Grid Search, Insert, Update, Delete Api
     let [searchApi, setSearchApi] = useState(false); 
     let [searchApi2, setSearchApi2] = useState(false); 
     let [searchApi3, setSearchApi3] = useState(false); 
     let [searchApi4, setSearchApi4] = useState(false); 
     const [panded, setPanded] = React.useState(true);
     const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 80px)",padding: "0px 10px 35px 10px" });
     let [clearGrid, setClearGrid] = useState(false);  

     const onClearApi = useCallback(async (e) => {
         setSearchApi(false);
         setSearchApi2(false);
         setSearchApi3(false);
         setSearchApi4(false);
     }, []);



      /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {
        //삭제내역 복구 Popup Open
        setRedeilOpen(true);
    }
 
     /**
      * 공통코드 조회
      */
      const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }
 
     /**
      *MOUNT 
      */
     useEffect( () => {
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");

        //공통코드 조회 (공장)
        onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");

     }, []);
 
     /**
      * Control 변경 Event
      * @param {*} e 
      */
     const onChange = async (e) => {
         const { name, value } = e.target;

         let newSearchs = idata;
         newSearchs[name] = value
         setData({ ...newSearchs });

         
        //T1업체, 회사구분 변경시 
        if (name === "kVnt1" || name === "kComp" || name === "kGubn")  {
            newSearchs["plntList"] = [];
            newSearchs["kPlnt"] = "";
            newSearchs[name] = value;
            setData({ ...newSearchs });
            onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");   
            //공통코드 조회 (공장)
            if(idata.kGubn == 1){
                onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList1");   
            }
            else{
                onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"CKD"}`, "plntList2");   
            }
        }

        
        if (name === "kGubn") onSearch();

        /*
        if (name === "kGubn" && idata.kNpdt != "" && idata.kDlno == "" ) {
            switch (value) {
                case "1":
                    await onSearch()
                    await onSearch2()
                    await onSearch3()
                    setData({ ...newSearchs });
                    break;
                case "2":
                    await onSearch()    
                    await onSearch2()
                    await onSearch3()
                    setData({ ...newSearchs});
                    break;
            }
        }
        else if ((name === "kGubn" && idata.kNpdt == "" && idata.kDlno != "") || (name === "kGubn" && idata.kNpdt != "" && idata.kDlno != "")){
            switch (value) {
                case "1":
                    await onSearch(idata.kDlno, idata.kGubn)
                    setData({ ...newSearchs });
                    break;
                case "2":
                    await onSearch(idata.kDlno, idata.kGubn)
                    setData({ ...newSearchs});
                    break;
            }
        }
        */
     }
 
     /**
      * Grid Event 
      * @param {*} e 
      */

      //grdRsy41List Grid Row Click
    const grdRsy41List_onRowClicked = async (e) => {
        let data = e.data;
        //Detail Grid 조회
        onSearch2(data.dlno, data.gate);
    }

    //grdRsy41List Grid Row Click
    const grdRsy41List2_onRowClicked = async (e) => {
        let data = e.data;
        //Detail Grid 조회
        onSearch3(data.dlno, data.gate);
    }

     const onCellValueChanged = async (e) => {
     }
 
     const onRowClicked = async (e) => {
     }

     /**
      * 검색조건
      */
      const optionsX = {
        0: [
            //T1업체
            {
                "formatter": "select", "name": "kVnt1", "labelName": "T1업체", "type": "text", "defaultValue": idata.kVnt1,  
                "codeMode": true,
                "selectAll": "",
                "codes": null,
                "comms": idata.vnt1List,
                "align": "left", 
            }, 
            //회사구분
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": true,
                "selectAll": "",
                "codes": CODES["comp"],
                "comms": null,
                "align": "left", 
            }, 
            //공장
            { 
                "formatter": "select", "name": "kPlnt", "labelName": "공장", "type": "text", "defaultValue": idata.kGubn === "1" ? idata.kPlnt : idata.kPlnt,  
                "codeMode": false,
                "selectAll": "all",
                "codes": null,
                "comms": idata.kGubn === "1" ? idata.plntList : idata.plntList2,
            }, 
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" }
            
        ],
        1: [
            //발행일자
            {
                "formatter": "input", "name": "kNpdt", "labelName": "납품일자", "type": "date", "desc": "", "readOnly" : false, "clearButton": true,
                "defaultValue": idata.kNpdt,  
                "align": "left", 
                "labelType": "none" 
            }, 
             //납품서번호
            {
                "formatter": "input", "name": "kDlno", "labelName": "납품서번호", "type": "text", "desc": "", "readOnly" : false, "clearButton": true,
                "defaultValue": idata.kDlno,  
                "align": "left",
                "labelType": "none", 
            }, 
            //조회구분 ( OEM:1, CKD:2 )     
            {
                "formatter": "radio", "name": "kGubn", "labelName": "조회구분", 
                "defaultValue": idata.kGubn,
                "options": [{ "name": "kGubn1", "labelName": "OEM", "setValue": "1" }, { "name": "kGubn2", "labelName": "CKD", "setValue": "2" }] 
            }, 
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" }
        ]
     }
 

     /**
      * 조회 
      * @param {*} e 
      * @returns 
      */
    const onSearch = async (e) => {

        //발행일자, 납품서 번호 입력 CHECK
        if(idata.kNpdt == "" && idata.kDlno == ""){
            setMessage({ openAlert : true, 
                severity : "info",
                msg : "발행일자 혹은 납품서 번호를 입력하십시오 !"});
            document.getElementById("kNpdt").focus();                         
            return ;
        }
        
        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);
        let trimed_dlno = idata.kDlno.trim();

        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                     "kNpdt":"${idata.kNpdt}","kDlno":"${trimed_dlno}","kGubn":"${idata.kGubn}"  }`;

        let param = encodeURI(para);

        if(idata.kNpdt != "" && idata.kDlno == ""){
            const query = SERVER_URL + "/rsy41/search?query=" + param;  
            const query3 = SERVER_URL + "/rsy41/search3?query=" + param;

            setSearchApi(query); // Search API            
            setSearchApi3(query3); // Search3 API
            setClearGrid(true);
            
        }
        else if((idata.kNpdt == "" && idata.kDlno != "") || (idata.kNpdt != "" && idata.kDlno != "")){
            const query = SERVER_URL + "/rsy41/search?query=" + param;
            const query2 = SERVER_URL + "/rsy41/search2?query=" + param;
            const query3 = SERVER_URL + "/rsy41/search3?query=" + param;
            const query4 = SERVER_URL + "/rsy41/search4?query=" + param;    
            
            setSearchApi(query); // Search API
            setSearchApi2(query2); // Search2 API
            setSearchApi3(query3); // Search3 API
            setSearchApi4(query4); // Search4 API
        }
    }

    const onSearch2= async (dlno, gate) => {        
        let para = `{"kDlno":"${dlno}","kGate":"${gate}"}`;
        let param = encodeURI(para);
        
        const query2 = SERVER_URL + "/rsy41/search2?query=" + param;
        setSearchApi2(query2); // Search2 API
    }

    const onSearch3 = async (dlno, gate) => {
        let para = `{"kDlno":"${dlno}","kGate":"${gate}"}`;
        let param = encodeURI(para);
        
        const query4 = SERVER_URL + "/rsy41/search4?query=" + param;    
        setSearchApi4(query4); // Search4 API
    }

     //Transaction AlertDialogResult
     const AlertDialogResult = async (e) => {
 
         //AlertDialog 초기화
         setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        
         //DialogResult
         if (e.result == "N") return;
 
         //Transaction 구분
         switch (e.Trans) 
         {
             //등록
             case "I" :
                 break;
             //수정
             case "U" :
                 break;
             //삭제
             case "D" :
                 break;
         }
    } 

        /**
         *Grid Column 정의 
        */
    //납품서 발행 Grid Column
    const columnDefs1 = [
        { headerName: "업체코드", field: "vand", sortable: true, filter: true, flex: 1, minWidth: 0, hide: true },     //hidden 컬럼
        { headerName: "회사구분", field: "comp", sortable: true, filter: true, flex: 1, minWidth: 0, hide: true },     //hidden 컬럼
        { headerName: "npdt", field: "npdt", sortable: true, filter: true, flex: 1, minWidth: 0, hide: true },       //hidden 컬럼 (발행일시)
        { headerName: "발행일시", field: "isdt", sortable: true, filter: true, flex: 1, minWidth: 120,cellClass: ["nocheckbox", "alignC"], valueFormatter: Utils.dateFormatter,singleClickEdit: false, hide: false }, // (실발행시간)
        { headerName: "공장", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 45, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false},
        { headerName: "하치장", field: "gate", sortable: true, filter: true, flex: 1, minWidth: 45, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false },
        { headerName: "납품서번호", field: "dlno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: false, hide: false},
        { headerName: "납기일자", field: "pldt", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: idata.kGubn === '1'?true:false},
        { headerName: "납품일시", field: "npdt1", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false },
        // { headerName: "품목건수", field: "pcnt", sortable: true, filter: true, flex: 1, minWidth: 45, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: false, valueFormatter: Utils.numberFormatter, hide: false },
        { headerName: "인쇄", field: "prgb", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false },
        { headerName: "전송", field: "chul", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: false, hide: false }
    ];


    //납품서 상세현황 Grid Column
    const columnDefs2 = [            
            { headerName: "공장", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 0,hide: true },
            { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
            { headerName: "구매번호/PO", field: "odno1", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: idata.kGubn === '2'?true:false },
            { headerName: "납품수량", field: "npqt",sortable: true, filter: true, flex: 1, minWidth: 70, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false },
            { headerName: "PD no", field: "pdno",sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: idata.kGubn === '1'?true:false},
            { headerName: "From Order", field: "frod", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kGubn === '1'?true:false  },
            { headerName: "To Order", field: "tood", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kGubn === '1'?true:false  },
            { headerName: "CASE NO", field: "case1", sortable: true, filter: true, flex: 1, minWidth: 70, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kGubn === '1'?true:false },
            { headerName: "납품일자", field: "npdt", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false },
            { headerName: "시간", field: "nptm1", sortable: true, filter: true, flex: 1, minWidth: 70, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,  hide: idata.kGubn === '2'?true:false  },
            { headerName: "발행일시", field:"isdt", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,valueFormatter: Utils.dateTiemFormatter2, hide: false }
    ];


        //납품서 발행 삭제현황 Grid Column
    const columnDefs3 = [        
        { headerName: "발행일자", field: "isdt", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "업체코드", field: "vand", sortable: true, filter: true, flex: 1, minWidth: 0, hide: true },     //hidden 컬럼
        { headerName: "회사구분", field: "comp", sortable: true, filter: true, flex: 1, minWidth: 0, hide: true },     //hidden 컬럼
        { headerName: "npdt", field: "npdt", sortable: true, filter: true, flex: 1, minWidth: 0, hide: true },       //hidden 컬럼 (발행일시)
        { headerName: "npdh", field: "npdh", sortable: true, filter: true, flex: 1, minWidth: 0, hide: true },        //hidden 컬럼 (발행시간)        
        { headerName: "공장", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 45, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
        { headerName: "하치장", field: "gate", sortable: true, filter: true, flex: 1, minWidth: 45, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "납품서번호", field: "dlno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
        { headerName: "납품일자", field: "npdt1", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "품목건수", field: "pcnt", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false },
        { headerName: "인쇄", field: "prgb", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "전송확인", field: "chul", sortable: true, filter: true, flex: 1, minWidth: 40, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
    ];
    

        //납품서 상세 삭제현황 Grid Column
    const columnDefs4 = [
        { headerName: "순번", field: "dseq", sortable: true, filter: true, flex: 1, minWidth:60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "납기일자", field: "nidt", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter,hide: idata.kGubn === '1'?true:false  },
        { headerName: "납품일자", field: "npdt", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false, hide: idata.kGubn === '2'?true:false },
        { headerName: "시간", field: "nptm", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false, hide: idata.kGubn === '2'?true:false },
        { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
        { headerName: "납품수량", field: "npqt", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false },
        { headerName: "PD No.", field: "pdno", sortable: true, filter: true, flex: 1, minWidth: 110, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,  hide: idata.kGubn === '1'?true:false },
        { headerName: "판매문서", field: "seld", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,  hide: idata.kGubn === '1'?true:false   },
        { headerName: "From Order", field: "frod", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kGubn === '1'?true:false  },
        { headerName: "To Order", field: "tood", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: idata.kGubn === '1'?true:false  },
        { headerName: "CASE NO", field: "case1", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: idata.kGubn === '1'?true:false  },
        { headerName: "삭제일시", field: "deldate", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,valueFormatter: Utils.dateTiemFormatter2, hide: false },    
        { headerName: "발행일자", field: "isdt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,valueFormatter: Utils.dateTiemFormatter2, hide: false }

    ];
        
    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 30px)",
                padding: "0px 10px 35px 10px" 
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 55px  - 80px)",
                padding: "0px 10px 35px 10px" 
              });
        }
    }

     return(
         <>
             {/* Dialog FRAME */}
             <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult}/>

              {/* 삭제내역 복구  */}
            <div id ="11">
            <Popup type="Redeli" open={redeilOpen} onClose={()=> {setRedeilOpen(false)}}/>
            </div>
 
             {/* Alert FRAME */}
             <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
 
                     
             {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
             <Ribbon
                onInfoMsg={"OEM / CKD 납품서 모두 조회가능합니다. <br/>납품서 상세현황 항목은 OEM기준으로 표출되어있습니다"}
                onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                onSearch={onSearch} onSearchName={null}     //조회
                onInsert={onUpdate} onInsertName={"납품내역 복구 및 관리"} onInsertVisible = {tVnt1 == "M000" || idata.kMaster == 'Y'}
                >
                 {"시스템관리 > 운영관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>납품서현황 (관리자전용)</b>
             </Ribbon>
 
 
             {/* ContentXearch Frame : 검색조건 */}
             <ContentXearch
                 optionsX={optionsX}
                 onChange={onChange}
                 onClick={onClick} />
 
 
             {/* Content FRAME (Data / Grid Frame) START -------------- */}
             <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:55px */}
             <div className="grid_type02" style={{margin : "0px"}}>{/* 상단 FRAME */}
                    <div className="grid_wrap02" style={{width:"46%" }}> {/* 상단 wrap 좌측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <GridRibbon 
                                title={"납품서 발행현황"}>
                            </GridRibbon>
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* Grid1 */}
                                <Grid
                                    ref={grdRsy41List}
                                    gridId={"RSY41"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs1}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={false}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={false}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    useMessage = {true}                 // 메세지 창 설정
                                    onClearApi={onClearApi}            // 초기화
                                    searchApi={searchApi}              // 조회
                                    onRowClickEvent={grdRsy41List_onRowClicked}
                                />
                            </div>
                        </div>
                    </div>
                

                    <div className="grid_wrap03" style={{width:"54%" }}> {/* 하단 wrap 우측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <GridRibbon 
                                title={"납품서 상세현황"}>
                            </GridRibbon>
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* Grid2 */}
                                <Grid
                                    ref={grdRsy41Detail}
                                    gridId={"RSY412"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs2}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={false}   //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}                 // No 컴럼 Display
                                    searchApi={searchApi2}              // 조회
                                    onClearApi={onClearApi}            // 초기화
                                    clearGrid={clearGrid}
                                    onClearGridFlag={setClearGrid}
                                    useMessage = {false}                 // 메시지창 설정
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid_type02">{/* 상단 FRAME */}
                <div className="grid_wrap02" style={{width:"46%" }}> {/* 상단 wrap 좌측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <GridRibbon 
                                title={"납품서 발행 삭제현황"}
                            >
                            </GridRibbon>
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* Grid3 */}
                                <Grid
                                    ref={grdRsy41List2}
                                    gridId={"RSY413"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs3}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={false}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    onClearApi={onClearApi}            // 초기화
                                    searchApi={searchApi3}              // 조회
                                    useMessage = {true}                 // 메시지창 설정
                                    onRowClickEvent={grdRsy41List2_onRowClicked}
                                />
                            </div>
                        </div>
                    </div>
                
                    <div className="grid_wrap03" style={{width:"54%" }}> {/* 하단 wrap 우측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <GridRibbon
                                title={"납품서 상세 삭제현황"}
                            >
                            </GridRibbon>
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* Grid4 */}
                                <Grid
                                    ref={grdRsy41Detail2}
                                    gridId={"RSY414"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs4}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={false}   //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {true}                 // No 컴럼 Display
                                    searchApi={searchApi4}              // 조회
                                    onClearApi={onClearApi}            // 초기화
                                    clearGrid={clearGrid}
                                    onClearGridFlag={setClearGrid}
                                    useMessage = {false}                 // 메시지창 설정
                                />
                            </div>
                        </div>
                    </div>
                    
                </div>
             </Content>
         </>
     )
 }
 export default RSY41;