import React, { Component } from 'react';
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
import CommonUtil from '@/utils/CommonUtil'; 
import { stringToArray } from 'ag-grid-community';
import { common } from '@material-ui/core/colors';
import { Search } from '@material-ui/icons';
 
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');


const Utils = new CommonUtil();


class MyInf extends Component {

    constructor(props)  {
        super(props);

        this.state = {
            //Common
            token:Common.getToken(),
    
            userId :Common.getToken("id"),
            userName : Common.getToken("name"),
            tVnt1 : Common.getToken("vand"),
            tComp : Common.getToken("comp"),
            isdt: Common.getToday(), // 오늘날짜 :: Default 값 셋팅
            
            user : "",  //사용자id
            name : "",  //사용자이름
            pswd : "",  //비밀번호
            hkmc : "",  //회사구분
            // excl : "",  //엑셀출력타입
            pbar : "",  //수신자료관리
            cplx : "",  //납품서x
            cply : "",  //납품서y
            bplx : "",  //부품식별표x
            bply : "",  //부품식별표y
            bplx2 : "", //부품식별표 2d x
            bply2 : "", //부품식별표 2d y
            splx : "",  //시작차식별표 x
            sply : "",  //시작차식별표 y
            dlgb : "",  //납품구분
            bigi : "",  //bigi구분
            propt : "",  //propt 신규 업체 사용구분
            
            user_id:"",
            user_password:"",
            //Common 코드 데이터
            compList : [],comp : "",
            exclList : [],
            pbarList : [],
            cprtList : [],
            cplhList : [],

            //Alert 창 ( Alert 창 OPEN,  Alert 창 구분, Alert 창 메세지)
            openAlert : false, severity : "success", msg : "",
    
            //Dialog 창 (Dialog 창 OPEN, Dialog Transaction(I,U,D),  Dialog 메세지)
            openDlg : false, dlgTrans : "", dlgMsg : "", 
    
            //Api (조회, 등록, 수정, 삭제)
            searchApi : false, insertApi : false, updateApi : false, deleteApi : false,

            loadingFlag : false
        }
    }


    
    /**
     * WillMount
     */
    async componentWillMount() {
        //Loading Flag
        this.setState({loadingFlag : true}); 

        //공통코도 조회 (회사구분)
        let commonCode = await Promise.all([this.onSearchComp(`{"pgmid":"CODE", "vand":"****", "gocd" : "COMP"}`, "compList") ]);
        //공통코드 조회 (엑셀출력타입)
        // let commonCode1 = await Promise.all([this.onSearchComp(`{"pgmid":"CODE", "vand":"****", "gocd" : "EXCL"}`, "exclList" ) ]);
        //공통코드 조회 (수신자료관리)
        let commonCode2 = await Promise.all([this.onSearchComp(`{"pgmid":"CODE", "vand":"****", "gocd" : "PBAR"}`, "pbarList" ) ]);
        //공통코드 조회 (수신자료관리)
        let commonCode3 = await Promise.all([this.onSearchComp(`{"pgmid":"CODE", "vand":"****", "gocd" : "CPLH","cod1" : "TO_NUMBER(WRCCOD1)"}`, "cplhList" ) ]);

        //회사구분
        this.setState({compList: commonCode[0].compList, loadingFlag : true}); 
        //엑셀출력타입
        // this.setState({exclList: commonCode1[0].exclList, loadingFlag : true}); 
        //수신자료관리
        this.setState({pbarList: commonCode2[0].pbarList, loadingFlag : true}); 
        //수신자료관리
        this.setState({cplhList: commonCode3[0].cplhList, loadingFlag : true}); 
        //onSearch;
    }


    /**
     * DidMount
     */
    componentDidMount() {
        let para = `{"kVnt1":"${this.state.tVnt1}","userId":"${this.state.userId}"}`;
        let param = encodeURIComponent(para);

        const query = SERVER_URL + "/popup/myinfo_search?query=" + param;

        const fetchSearchDataStart = async (query) => {
        let inputData = this.state; 
            await fetch(query,  { headers: { Authorization: this.state.token  } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) {
                    //    if (data.data.length == 0) {
                        this.state.user = data.data[0].usid; //이름
                        this.state.name = data.data[0].name; //이름
                        this.state.pswd = data.data[0].pswd; //비밀번호
                        this.state.hkmc = data.data[0].hkmc; //회사구분
                        // this.state.excl = data.data[0].excl; //엑셀출력타입
                        this.state.pbar = data.data[0].pbar; //수신자료관리
                        this.state.cplx = data.data[0].cplx; //납품서x
                        this.state.cply = data.data[0].cply; //납품서y
                        this.state.bplx = data.data[0].bplx; //부품식별표x
                        this.state.bply = data.data[0].bply; //부품식별표y
                        this.state.bplx2 = data.data[0].bplx2; //부품식별표2d x
                        this.state.bply2 = data.data[0].bply2; //부품식별표2d y
                        this.state.splx = data.data[0].splx; //시작차식별표x
                        this.state.sply = data.data[0].sply; //시작차식별표y
                        this.state.dlgb = data.data[0].dlgb; //납품구분
                        this.state.bigi = data.data[0].bigi; //bigi인쇄
                        this.state.propt = data.data[0].propt; //propt
                        this.state.user_id = data.data[0].usid;
                        this.state.user_password = data.data[0].pswd;
                        this.setState({ ...inputData });
                    }
                    if (data.error) {
                        this.setState({ openAlert : true, 
                                        severity : "error",
                                        msg : "조회 실패"});
                    }
                })
                .catch((error) => {
                    // 에러 응답 처리
                    if (error) {
                        this.setState({ openAlert : true, 
                                        severity : "error",
                                        msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                    }
                });
        };
        fetchSearchDataStart(query);
        //setTimeout(() => fetchSearchDataStart(query), 1000);
    }



    componentWillUnmount() {
        this.setState({searchApi : true}); 
        
    }


    /**
     * 공통코드 조회
    */
     onSearchCommon = (para, name) => {
    }

    /**
     * 회사구분 코드 조회
    */
     onSearchComp = async (para,name) => {
        let commonData = this.state;
        let param = encodeURIComponent(para);

        const query = SERVER_URL + "/comcd/search1?query=" + param; 
        await fetch(query, { headers: { Authorization: this.state.token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) 
                {
                    commonData[name] = data.data
                    this.setState({...commonData});
                }
            })
        return commonData;
    }


    render() {
        
        if (this.state.loadingFlag === false) {
            return null
        }
        else {
            
            //State
            const {token, userId, userName, tVnt1, tComp,user_id,user_password,
                   openAlert, severity, msg
                   ,user,name,pswd,hkmc,excl,pbar,cplx,cply,bplx,bply,bplx2,bply2,splx,sply,dlgb,isdt,bigi,propt
                   ,exclList,pbarList,cprtList,cplhList
                   ,openDlg, dlgTrans, dlgMsg, compList, searchApi, insertApi, updateApi, deleteApi } = this.state;


            /**
             * Control 변경 Event
             * @param {*} e 
             */
            const onChange = async (e) => {
                const { name, value} = e.target;

                let newSearchs = this.state; //comp
        
                newSearchs[name] = value;
                this.setState({ ...newSearchs });
            }

        


        

            const onToken = async (e) =>{
                const url = process.env.REACT_APP_BACKEND_SERVER;  
                const path="/user/recheck";//->/user/check 이거 사용하는듯
                const querytoken = url + path; 

                //let search =  `{"user_id":"${user_id}","user_password":"${user_password}"}`;
                let search = 
                {
                    user_id: this.state.user_id,
                    user_password: this.state.user_password
            
                }
                let param = Common.JSONtoString(search);  // 검색조건 Parameter String Setting//code: 200

                fetch(querytoken, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },body: param})
                    .then((resp) => resp.json())
                    .then((data) => {
                        if (data.success) {
                            sessionStorage.removeItem('token')
                            sessionStorage.setItem('token', data.data); // Token Insert
                            onSearch();
                        }
                        if (data.error) {
                            this.setState({ openAlert : true, 
                                severity : "error",
                                msg : "오류가 발생하였습니다. 재로그인해주세요."});
                        }
                    })
                    .catch(error => {
                        if (error) {
                            this.setState({ openAlert : true, 
                                severity : "error",
                                msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                        }
                    });
            }
            /**
             * 조회
             * @param {*} e 
             */
            const onSearch = async (e) => {                             
               let para = `{"kVnt1":"${tVnt1}","userId":"${userId}"}`;
               let param = encodeURIComponent(para);
               
               const query = SERVER_URL + "/popup/myinfo_search?query=" + param;

               const fetchSearchData = async (query) => {
                let inputData = this.state; 
//                sessionStorage.setItem('token', null); // Token Init
                   await fetch(query,  { headers: { Authorization: token } })
                       .then((resp) => resp.json())
                       .then((data) => {
                           if (data.success) {
                                inputData["user"] = data.data[0].usid; //이름(아이디)
                                inputData["name"] = data.data[0].name; //이름
                                inputData["pswd"] = data.data[0].pswd; //비밀번호
                                inputData["hkmc"] = data.data[0].hkmc; //회사구분
                                // inputData["excl"] = data.data[0].excl; //엑셀출력타입
                                inputData["pbar"] = data.data[0].pbar; //수신자료관리
                                inputData["cplx"] = data.data[0].cplx; //납품서x
                                inputData["cply"] = data.data[0].cply; //납품서y
                                inputData["bplx"] = data.data[0].bplx; //부품식별표x
                                inputData["bply"] = data.data[0].bply; //부품식별표y
                                inputData["bplx2"] = data.data[0].bplx2; //부품식별표2d x
                                inputData["bply2"] = data.data[0].bply2; //부품식별표2d y
                                inputData["splx"] = data.data[0].splx; //시작차식별표x
                                inputData["sply"] = data.data[0].sply; //시작차식별표y
                                inputData["dlgb"] = data.data[0].dlgb; //납품구분
                                inputData["bigi"] = data.data[0].bigi; //bigi구분
                                this.setState({ ...inputData });
                                this.setState({ openAlert : true, 
                                            severity : "info",
                                            msg : "조회되었습니다."});
                                //sessionStorage.setItem('token', data.data); // Token Insert
                           }
                           if (data.error) {
                               this.setState({ openAlert : true, 
                                               severity : "error",
                                               msg : "조회 실패"});
                           }
                       })
                       .catch((error) => {
                           if (error) {
                               this.setState({ openAlert : true, 
                                               severity : "error",
                                               msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                           }
                       });
               };
               fetchSearchData(query);
            }



            /**
             * 신규 Row추가
             * @param {*} e 
             */
            const onAddRow = async (e) => {
            }



            /**
             * 입력
             * @param {*} e 
             */
            const onInsert = async (e) => {

                
            }



            /**
             * 수정 
             * @param {*} e 
             * @returns 
             */
            const onUpdate = async (e) => {
                
                //비밀번호 체크
                if (this.state.pswd === "") {
                    this.setState({ openAlert : true, 
                                    severity : "info",
                                    msg : "비밀번호를 입력하세요."});
                    document.getElementById("pswd").focus();
                    return false;
                }
                if(this.state.pswd.length<5){
                    this.setState({ openAlert : true, 
                        severity : "info",
                        msg : "비밀번호를 5자리 이상 입력하세요."});

                    return false;
                }
                //납품구분 체크
                let arg1 = "1|2|3|4|5|6|7|8|9|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z|";
                let arg2 = "V|W|";
                let pt2 = arg2.indexOf(dlgb.substring(0,1) + "|");
                let pt = arg1.indexOf(dlgb.substring(1,2)+"|");
                
                if(dlgb == ""){
                    this.setState({ openAlert : true, 
                        severity : "info",
                        msg : "납품구분을 입력하세요."});

                    return false;
                }
                
                if(dlgb.length != 2){
                    this.setState({ openAlert : true, 
                        severity : "info",
                        msg : "납품구분을 2자리로 입력하세요."});

                    return false;
                }
                        
                
                if(pt2<0){
                    this.setState({ openAlert : true, 
                        severity : "info",
                        msg : "납품구분의 첫번째 자리 입력은 'V' or 'W' 입니다."});

                    return false;
                }

                
                if(pt<0){
                    this.setState({ openAlert : true, 
                        severity : "info",
                        msg : "납품구분의 두번째 자리 입력은 [1..9] or [A..Z] 입니다."});

                    return false;
                }

                
                const fetchUpdateData = async () => {

                    const query = SERVER_URL + "/popup/myinfo_update"; 
                    let param = JSON.stringify({
                        isdt : isdt,
                        usid : user,
                        name : name,
                        pswd : pswd,
                        hkmc : hkmc,
                        excl : excl,
                        pbar : pbar,
                        cplx : cplx,
                        cply : cply,
                        bplx : bplx,
                        bply : bply,
                        bplx2 : bplx2,
                        bply2 : bply2,
                        splx : splx,
                        sply : sply,
                        dlgb : dlgb,
                        bigi : bigi,
                        propt : propt
                    });

                    await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })                        
                        .then((resp) => resp.json())
                        .then((data) => {
                            if (data.success) {
                                setTimeout(() => onSearch(), 300);
                            }
                            else if (data.errors) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.errors.message});

                            }
                            else if (data.error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",  
                                                msg : data.message});
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                };
                fetchUpdateData();


                //sessionStorage.setItem('token', null); // Token Init

                //onSearch();
            }



            /**
             * 로그삭제
             * @param {*} e 
             */
            const onDelete = async (e) => {

                
            }



            //deleteBlank
            function deleteBlank(argval){

            }



            // input check
            const inputCheck = () => {
                
              
            }
            

            //AlertDialogResult
            const AlertDialogResult = async (e) => {

                //AlertDialog 초기화
                this.setState({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        
                //DialogResult
                if (e.result == "N") return;
        
                //Transaction 구분
                switch (e.Trans) 
                {
                    //등록
                    case "I" :
                        break;
                    //수정
                    case "U" :
                        break;
                    //삭제
                    case "D" :
                        break;
                }
            }

        

            //Grid DropDownList Value
            const extractValues = (mappings) => {
                return  Object.keys(mappings);
            }

            //Grid DropDownList Display
            const lookupValue = (mappings, key) => {
                return mappings[key];
            }        

        
            /**
             *Grid Column 정의 
            */
            const columnDefs = [
            ];

        
            return (
                <>
                    {/* Dialog FRAME */}
                    <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

                    {/* Alert FRAME */}
                    <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {this.setState({openAlert:false, severity:"", msg:""})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
        
                    <button onClick={() => {this.props.onClose()}} style = {{border:"none", float:"right", paddingRight:"0px"}}> <img src="/images/button/close_button.png" alt="close" /></button> 
                    
                    {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
                    <Ribbon
                        newTabUnused={true}                         // newTabUnused : newTab 표출 버튼 true : 사용안함 // 선언안할경우 default = newTab 사용
                        onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                        onSearch={onSearch} onSearchName={"조회"}//onToken
                        onUpdate={onUpdate} onUpdateName={"수정"}
                    >
    
                    <b style={{ color: "#cd5c5c", fontFamily: "PretendardMedium, NanumGothic, sans-serif" }}>사용자관리</b>
                    </Ribbon>
                    {/* <div className={"grid_type06"} style = {{height: "93%",width: "100%"}}>우측 FRAME */}
                    {/* <div className="grid_wrap06" style = {{height: "34%",width: "96%"}}> 우측 wrap FRAME */}
                        <div className="grid_area" style={{ width: "100%", border: "0px solid #babfc7" }}> {/* Grid area */}
                            <div style={{height:"10px"}}></div>
                            <div style={{height:"17px", color: "rgb(255, 0, 12)"}}>※ 사용자관리 내용 수정후엔 반드시 재로그인을 해야 합니다</div>
                            <div style={{height:"5px"}}></div>
                            <table  style={{ width: "100%" }}>
                                <colgroup>
                                    <col width="80px" />
                                    <col width="30px" />
                                    <col width="30px" />
                                    <col width="*" />
                                </colgroup>
                                <tbody>
                                <tr style={{ height:"32px"}}>
                                    <td className="info_tr" >사용자 ID</td>
                                    <td style = {{border: "0px"}} colSpan="2" > 
                                        <input name="user" value = {user} style={{width:"100px",height:"26px",marginLeft:"16px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}} readOnly="readonly"/>
                                        <input name="name" value={name} style={{width:"130px",height:"26px",marginLeft:"17px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}} readOnly="readonly"/>
                                    </td>
                                </tr>     
                                <tr style={{ height:"32px"}}>
                                    <td className="info_tr" >비밀번호</td>
                                    <td style = {{border: "0px"}} colSpan="2"> 
                                        <input name="pswd" value={pswd} type="password" onChange={onChange} style={{width:"248px",height:"26px",marginLeft:"16px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px",fontFamily: "cursive"}} />
                                    </td>
                                </tr>
                                <tr style={{ height:"32px"}}>
                                    <td className="info_tr" >회사구분</td>
                                    <td style = {{border: "0px"}} colSpan="2"> 
                                        <select name="hkmc" value={hkmc} onChange={onChange} style={{width:"248px",height:"26px",marginLeft:"16px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}}>
                                            <option value={""}></option>
                                            {compList.map(element => ( <option key={element.comCode} value={element.comCode}>
                                                                           {element.text} 
                                                                     </option>
                                                                ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr style={{ height:"32px"}}>
                                    <td className="info_tr" >수신자료관리</td>
                                    <td style = {{border: "0px"}} colSpan="2"> 
                                        <select name="pbar" value={pbar} onChange={onChange} style={{width:"248px",height:"26px",marginLeft:"16px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}}>

                                        <option value={""}></option>

                                            {pbarList.map(element => ( <option key={element.comCode} value={element.comCode}>
                                                                            {element.text} 
                                                                       </option>
                                                                ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr style={{ height:"5px"}}></tr>
                                {/*<tr style={{ height:"32px"}}>
                                    <td className="info_tr" >납입카드</td>
                                    <td style = {{border: "0px"}} > 
                                    &nbsp;&nbsp;&nbsp;&nbsp;X&nbsp;&nbsp;:&nbsp;&nbsp;
                                    <select name="nplx" value={nplx} onChange={onChange} style={{width:"80px",height:"26px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}}>
                                        <option value={""}></option>

                                        {cplhList.map(element => ( <option key={element.comCode} value={element.comCode}>
                                                                                {element.text} 
                                                                        </option>
                                                                    ))}
                                    </select>
                                    </td>
                                    <td style = {{border: "0px"}}> 
                                    Y&nbsp;&nbsp;:&nbsp;
                                    <select name="nply" value={nply} onChange={onChange} style={{width:"80px",height:"26px",marginLeft:"5px",border:"1px solid #b8b8b8",padding:"0px 5px 0px px"}}>
                                        <option value={""}></option>

                                        {cplhList.map(element => ( <option key={element.comCode} value={element.comCode}>
                                                                                    {element.text} 
                                                                            </option>
                                                                        ))}
                                    </select>
                                    </td>
                                </tr>*/}
                                <tr style={{ height:"33px"}}>
                                    <td className="info_tr" style={{verticalAlign: "middle"}} >납품서<br></br>( 납품CARD )</td>
                                    <td style = {{border: "0px",verticalAlign: "middle"}}> 
                                    &nbsp;&nbsp;&nbsp;&nbsp;X&nbsp;&nbsp;:&nbsp;&nbsp;
                                    <select name="cplx" value={cplx} onChange={onChange} style={{width:"80px",height:"26px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}}>
                                        <option value={""}></option>

                                    {cplhList.map(element => ( <option key={element.comCode} value={element.comCode}>
                                                                                    {element.text} 
                                                                            </option>
                                                                        ))}
                                    </select>
                                    </td>
                                    <td style = {{border: "0px",verticalAlign: "middle"}}> 
                                    Y&nbsp;&nbsp;:&nbsp;
                                    <select name="cply" value={cply} onChange={onChange} style={{width:"80px",height:"26px",marginLeft:"5px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}}>
                                        <option value={""}></option>
                                    {cplhList.map(element => ( <option key={element.comCode} value={element.comCode}>
                                                                                    {element.text} 
                                                                            </option>
                                                                        ))}
                                    </select>
                                    </td>
                                </tr>
                                <tr style={{ height:"34px"}}>
                                    <td className="info_tr"style={{verticalAlign: "middle"}} >부품식별표</td>
                                    <td style = {{border: "0px",verticalAlign: "middle"}} > 
                                    &nbsp;&nbsp;&nbsp;&nbsp;X&nbsp;&nbsp;:&nbsp;&nbsp;
                                    <select name="bplx" value={bplx} onChange={onChange} style={{width:"80px",height:"26px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}}>
                                        <option value={""}></option>
                                    {cplhList.map(element => ( <option key={element.comCode} value={element.comCode}>
                                                                                    {element.text} 
                                                                            </option>
                                                                        ))}
                                    </select>
                                    </td>
                                    <td style = {{border: "0px",verticalAlign: "middle"}}> 
                                    Y&nbsp;&nbsp;:&nbsp;
                                    <select name="bply" value={bply} onChange={onChange} style={{width:"80px",height:"26px",marginLeft:"5px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}}>
                                        <option value={""}></option>
                                    {cplhList.map(element => ( <option key={element.comCode} value={element.comCode}>
                                                                                    {element.text} 
                                                                            </option>
                                                                        ))}
                                    </select>
                                    </td>
                                </tr>
                                <tr style={{ height:"33px"}}>
                                    <td className="info_tr" style={{verticalAlign: "middle"}}>부품식별표2D<br></br>( KIA 2D )</td>
                                    <td style = {{border: "0px",verticalAlign: "middle"}} > 
                                    &nbsp;&nbsp;&nbsp;&nbsp;X&nbsp;&nbsp;:&nbsp;&nbsp;
                                    <select name="bplx2" value={bplx2} onChange={onChange} style={{width:"80px",height:"26px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}}>
                                        <option value={""}></option>
                                    {cplhList.filter(element => element.comCode > -8).map(element =>( <option key={element.comCode} value={element.comCode}>
                                                                                    {element.text} 
                                                                            </option>
                                                                        ))}
                                    </select>
                                    </td>
                                    <td style = {{border: "0px",verticalAlign: "middle"}}> 
                                    Y&nbsp;&nbsp;:&nbsp;
                                    <select name="bply2" value={bply2} onChange={onChange} style={{width:"80px",height:"26px",marginLeft:"5px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}}>
                                        <option value={""}></option>  
                                        {cplhList.filter(element => element.comCode > -8).map(element =>( <option key={element.comCode} value={element.comCode}>
                                                                                    {element.text} 
                                                                            </option>
                                                                        ))}
                                    </select>
                                    </td>
                                </tr>
                                <tr style={{ height:"34px"}}>
                                    <td className="info_tr" style={{verticalAlign: "middle"}}>시작차식별표</td>
                                    <td style = {{border: "0px",verticalAlign: "middle"}} > 
                                    &nbsp;&nbsp;&nbsp;&nbsp;X&nbsp;&nbsp;:&nbsp;&nbsp;
                                    <select name="splx" value={splx} onChange={onChange} style={{width:"80px",height:"26px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}}>
                                        <option value={""}></option>
                                    {cplhList.map(element => ( <option key={element.comCode} value={element.comCode}>
                                                                                    {element.text} 
                                                                            </option>
                                                                        ))}
                                    </select>
                                    </td>
                                    <td style = {{border: "0px",verticalAlign: "middle"}}> 
                                    Y&nbsp;&nbsp;:&nbsp;
                                    <select name="sply" value={sply} onChange={onChange} style={{width:"80px",height:"26px",marginLeft:"5px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}}>
                                        <option value={""}></option>
                                    {cplhList.map(element => ( <option key={element.comCode} value={element.comCode}>
                                                                                    {element.text} 
                                                                            </option>
                                                                        ))}
                                    </select>
                                    </td>
                                </tr>
                                <tr style={{ height:"5px"}}></tr>
                                <tr style={{ height:"32px"}}>
                                    <td className="info_tr"style={{verticalAlign: "middle"}} >납품구분</td>
                                    <td style = {{border: "0px",verticalAlign: "middle"}} colSpan="2"> 
                                        <input name="dlgb" value={dlgb} onChange={onChange} style={{width:"108px",height:"26px",marginLeft:"16px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}} />
                                        &nbsp;&nbsp;(구분 :[V,W][1..9]or[A..Z]) 
                                    </td>
                                </tr> 
                                <tr style={{ height:"5px"}}></tr>
                                <tr style={{ height:"32px"}}>
                                    <td className="info_tr"style={{verticalAlign: "middle"}} >BIGI인쇄</td>
                                    <td style = {{border: "0px",verticalAlign: "middle"}} colSpan="2"> 
                                        <select name="bigi" value={bigi} onChange={onChange} style={{width:"248px",height:"26px",marginLeft:"16px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}}>
                                            <option value={""}></option>
                                            <option value={"Y"}>Y</option>
                                            <option value={"N"}>N</option>    
                                        </select>
                                    </td>

                                </tr> 
                                {/* {Common.getToken("masterKey") === 'Y'? */}
                                <>
                                    <tr style={{ height:"5px"}}></tr>
                                    <tr style={{ height:"32px"}}>
                                        <td className="info_tr"style={{verticalAlign: "middle"}} >인쇄버전 변경</td>
                                        <td style = {{border: "0px",verticalAlign: "middle"}} colSpan="2"> 
                                            <select name="propt" value={propt} onChange={onChange} style={{width:"248px",height:"26px",marginLeft:"16px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}}>
                                                <option value={""}></option>
                                                <option value={"Y"}>Y</option>
                                                <option value={"N"}>N</option>    
                                            </select>
                                        </td>
                                    </tr> 
                                </>
                                {/* :""} */}
                                </tbody>
                            </table>
                            
                        </div>
                </>
            );
        }
        
    }

}


export default MyInf;
