  /**
 * @Page ROM90
 * @Description [ROM90]납입지시 - 현황관리 - 매출현황
 **/
 import React, {useState, useEffect, useRef, useCallback, forwardRef,useMemo} from "react";
 import { render } from 'react-dom';
 import { AgGridReact } from 'ag-grid-react';
 import Ribbon from '@/components/organisms/Ribbon';
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; // Common.js
 import CommonUtil from '@/utils/CommonUtil'; // Util.js
 import Loading from "src/components/atoms/CustomLoading2";
 
 


 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();
 
 
 function ROM90() {
 
    function createData(count, prefix){
        var result = [];
        if(count != 1){
            if(idata.kGubn1 === "M"){
                if(idata.kOrdr === "1"){//수량
                    result.push({  
                        TITL2:"합계",
                        ASQTY:count.ASQTY,
                        SQTY1 : count.SQTY1, SQTY2: count.SQTY2,  SQTY3: count.SQTY3,
                        SQTY4 : count.SQTY4, SQTY5: count.SQTY5, SQTY6: count.SQTY6,
                        SQTY7 : count.SQTY7, SQTY8: count.SQTY8,  SQTY9: count.SQTY9,
                        SQTY10 : count.SQTY10,SQTY11 : count.SQTY11,SQTY12 : count.SQTY12
                    })
                }else{//금액
                    result.push({  
                        TITL2:"합계",
                        ASMON:count.ASMON,
                        SMON1 : count.SMON1,  SMON2 : count.SMON2,  SMON3 : count.SMON3,
                        SMON4 : count.SMON4,  SMON5 : count.SMON5,  SMON6 : count.SMON6,
                        SMON7 : count.SMON7,  SMON8 : count.SMON8,  SMON9 : count.SMON9,
                        SMON10 : count.SMON10,SMON11 : count.SMON11,SMON12 : count.SMON12,
                    })
                }
            }else{
                if(idata.kOrdr === "1"){//수량
                    result.push({  
                        TITL2:"합계",
                        ASQTY:count.ASQTY,
                        SQTY1 : count.SQTY1, SQTY2: count.SQTY2,  SQTY3: count.SQTY3,
                        SQTY4 : count.SQTY4, SQTY5: count.SQTY5, SQTY6: count.SQTY6,
                        SQTY7 : count.SQTY7, SQTY8: count.SQTY8,  SQTY9: count.SQTY9,
                        SQTY10 : count.SQTY10,SQTY11 : count.SQTY11,SQTY12 : count.SQTY12,SQTY13 : count.SQTY13,
                        SQTY14 : count.SQTY14,SQTY15 : count.SQTY15,SQTY16 : count.SQTY16,SQTY17 : count.SQTY17,
                        SQTY18 : count.SQTY18,SQTY19 : count.SQTY19,SQTY20 : count.SQTY20,SQTY21 : count.SQTY21,
                        SQTY22 : count.SQTY22,SQTY23 : count.SQTY23,SQTY24 : count.SQTY24,SQTY25 : count.SQTY25,
                        SQTY26 : count.SQTY26,SQTY27 : count.SQTY27,SQTY28 : count.SQTY28,SQTY29 : count.SQTY29,
                        SQTY30 : count.SQTY30,SQTY31 : count.SQTY31
                    })
                }else{//금액
                    result.push({  
                        TITL2:"합계",
                        ASMON:count.ASMON,
                        SMON1 : count.SMON1,  SMON2 : count.SMON2,  SMON3 : count.SMON3,
                        SMON4 : count.SMON4,  SMON5 : count.SMON5,  SMON6 : count.SMON6,
                        SMON7 : count.SMON7,  SMON8 : count.SMON8,  SMON9 : count.SMON9,
                        SMON10 : count.SMON10,SMON11 : count.SMON11,SMON12 : count.SMON12,SMON13 : count.SMON13,
                        SMON14 : count.SMON14,SMON15 : count.SMON15,SMON16 : count.SMON16,SMON17 : count.SMON17,
                        SMON18 : count.SMON18,SMON19 : count.SMON19,SMON20 : count.SMON20,SMON21 : count.SMON21,
                        SMON22 : count.SMON22,SMON23 : count.SMON23,SMON24 : count.SMON24,SMON25 : count.SMON25,
                        SMON26 : count.SMON26,SMON27 : count.SMON27,SMON28 : count.SMON28,SMON29 : count.SMON29,
                        SMON30 : count.SMON30,SMON31 : count.SMON31
                    })
                }
            }

    
    
        }else{
       result.push({
             TITL1:"합계"
    
           })
        }
        
        return result;
      }

     const currentYear = new Date().getFullYear().toString();
     const currentMonth = (new Date().getMonth()+1).toString(); // 현재 달 (현재달 : 달 조회시 +1)
 
     const token = Common.getToken();
     const userId = Common.getToken("id");
     const tVnt1 = Common.getToken("vand");
     const tComp = Common.getToken("comp");
     const bigi = Common.getToken("bigi");
     const grdRom90Main = useRef();   // Grid Main
 
     const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
     let [loading, setLoading] = useState(false);
     
     //Data
     const [idata, setData] = useState({
         kGubn1: "M", // 월단위 일단위
         kGubn2: currentYear, // 연도
         kGubn3: currentMonth, // 월
         kOrdr: "1", // 조회결과
         //kOrdrNm: "수량", // 조회결과
         kOpt: "all", // 조회항목
         // kVnt1 : tVnt1,      //T1업체
         kVnt1 : ckVnt1,        //T1업체     
         kComp: tComp,       //회사구분
         kPlnt: "",          //공장
         kPldt: Common.getToday(),  //소요일자
         kPtno : "",         //품번
         gubn:"",
         kVnt1Nm : "",        //T1업체명
         kCompNm : tComp==="EHMC"?"현대자동차(주)":"기아자동차(주)",        //회사명
 
 
         //코드 데이터
         vnt1List : [],  //T1업체
         plntList : [],  //공장
         gateList : [],  //하치장
         compList : [],  //회사
         yearList : [],  //년도
         montList : [],  //월
         headerList:[],//헤더
         list : [],
         apilist : [],
     });
 
 
      
     const [message, setMessage] = React.useState( {
         openAlert : false,      
         severity : "success",   
         msg : ""                
     });
     const { openAlert, severity, msg } = message;
 
 
     //Dialog
     const [dlgMessage, setDlgMessage] = React.useState( {
         openDlg : false,    
         dlgTrans : "",      // Dialog Transaction(I,U,D)
         dlgMsg : ""         
     });
     const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
 
     //Grid Search, Insert, Update, Delete Api
     let [searchApi, setSearchApi] = useState(false); 
     let [clearGrid, setClearGrid] = useState(false); 
     
     const [panded, setPanded] = React.useState(true);
     const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 100px  - 50px)"});
     
     const onClearApi = useCallback(async (e) => {
         setSearchApi(false);
     }, []);
  
  
    
 
     /**
      * 공통코드 조회 (조회조건)
      */
     const onSearchCommonAsync = async (para, name) => {
         let commonData = idata;
 
         const fetchData = async (para, name) => {
             let param = encodeURI(para);
             const query = SERVER_URL + "/comcd/search?query=" + param; 
             await fetch(query, { headers: { Authorization: token } })
                 .then((resp) => resp.json())
                 .then((data) => {
                     if (data.success)
                     {
                         commonData[name] = data.data
                         setData({...commonData});
                     }
                 })
         }
         await fetchData(para, name);
     }
 
     const onSearchCommon1 = (para, name) => {
         let commonData = idata;
 
         const fetchData1 = async (para, name) => {
 
             let param = encodeURIComponent(para);
             const query = SERVER_URL + "/comcd/search1?query=" + param; 
             await fetch(query, { headers: { Authorization: token } })
                 .then((resp) => resp.json())
                 .then((data) => {
                     if (data.success) 
                     {
                         if (name === "typeList") {
                             //commonData["kType"] = data.data[0].comCode
                             //commonData["kTypeNm"] = data.data[0].text
                         }
                         commonData[name] = data.data
                         setData({...commonData});
                     }
                 })
         }
         fetchData1(para, name);
     }
 
     const onSearchCommon = async (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                    commonData[name] = data.data[0].text;
                    setData({...commonData});
                    }
                })
        }
        fetchData(para, name);  
    }
 
 
 
     /**
      *MOUNT
      */
     useEffect( () => {
         //공통코드 조회 (회사)
         onSearchCommon1(`{"pgmid":"CODE","vand":"****","gocd":"COMP"}`, "compList");
 
         //공통코드 조회 (T1업체)
         onSearchCommonAsync(`{"pgmid":"VNT1"}`, "vnt1List");
         //공통코드 조회 (공장)
         onSearchCommonAsync(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":""}`, "plntList");
 
         
         onSearchCommon(`{"pgmid":"WRCVAND","kVnt1":"${idata.kVnt1}"}`, "kVnt1Nm");
 
         for(var i = idata.kGubn2-6; i <= parseInt(idata.kGubn2)+1 ; i++){
             idata.yearList.push({
                 comCode: i,
                 text: i+"년"
             })
         }
         for(var i = 1; i <= 12 ; i++){
             idata.montList.push({
                 comCode: i,
                 text: i+"월"
             })
         }
 
     }, []);
 
 
 
     /**
      * Control 변경 Event
      * @param {*} e
      */
     const onChange = async (e) => {
         const {name, value, desc} = e.target;
         idata.gubn = "";
 
         let newSearchs = idata;
         newSearchs[name] = value;
         newSearchs[name+"Nm"] = desc;
         setData({ ...newSearchs });
 
 
         if(name === "kComp"){
 
            newSearchs["kPlnt"] = "";
            newSearchs["kPlntNm"] = "";
            newSearchs["kGate"] = "";
            setData({ ...newSearchs });
 
            //공통코드 조회 (공장)
            onSearchCommonAsync(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":""}`, "plntList");
         }
 
 
         if (name === "kOpt"||name === "kGubn1"||name === "kGubn2"||name === "kGubn3"||name==="kOrdr") { // "조회 항목", "조회 구분" 변경 시 하단 그리드 1, 2 clear            
             
             setClearGrid(true); // 그리드 clear 하기 


             if(idata.apilist.length != 0){
                let grid = idata.apilist;
                             
                let row = createData(1, 'Top');
                grid.setPinnedTopRowData(row);
             }
             



         }
         
         //onSearch();
     }
 
     
 
 
     /**
      * 검색조건
      */
     const optionsX = {
         0: [
             //T1업체
             {
                 formatter: "select", name: "kVnt1", labelName: "T1업체", type: "text", defaultValue: idata.kVnt1,  
                 codeMode: true,
                 selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: null,
                 comms: idata.vnt1List, 
                 align: "left", 
             },
             // 공장
             {
                 formatter: "select", name: "kPlnt", labelName: "공장", type: "text", defaultValue: idata.kPlnt,  
                 codeMode: false,
                 selectAll: "all", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: null,
                 comms: idata.plntList,
                 align: "left", 
             },
             // 조회구분            
             {
                 formatter: "select3", name: "kGubn1",name2: "kGubn2",name3: "kGubn3", labelName: "조회구분"
                 ,defaultValue: idata.kGubn1,  defaultValue2: idata.kGubn2,  defaultValue3: idata.kGubn3,
                 codeMode: false,
                 codes: null,
                 comms: null,
                 items: [{ "comCode": "M", "text": "년 단위" }, { "comCode": "D", "text": "월 단위" }],
 
                 codeMode2: false,
                 codes2: null,
                 comms2: idata.yearList,
 
                 codeMode3: false,
                 codes3:null,
                 comms3:idata.kGubn1=="M"?"":idata.montList,
                 //selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 readonly3:idata.kGubn1=="M"?"T":"", // 월단위(M) 조회구분 일 경우, css 변경
                 align: "left", 
             },
             {"formatter": "blank", "align": "right" },
             {"formatter": "blank", "align": "right" }
         ],
         1: [
             //회사구분
             {
                 formatter: "select", name: "kComp", labelName: "회사구분", type: "text", defaultValue: idata.kComp,  
                 codeMode: true,
                 selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: idata.compList,
                 comms: null,
                 align: "left", 
             },
             // 조회항목
             {
                 formatter: "select", name: "kOpt", labelName: "조회항목", type: "text", defaultValue: idata.kOpt,  
                 codeMode: false,
                 selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: [{"comCode":"all","text":"공장+품번"}, {"comCode":"plnt","text":"공장"}, {"comCode":"part","text":"품번"}],
                 comms: null,
                 align: "left", 
             },
             // 조회결과
             {
                 formatter: "select", name: "kOrdr", labelName: "조회결과", type: "text", defaultValue: idata.kOrdr,  
                 codeMode: false,
                 selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: [{"comCode":"1","text":"수량"}, {"comCode":"2","text":"금액"}, {"comCode":"3","text":"수량+금액"}],
                 comms: null,
                 align: "left", 
             },
             {"formatter": "blank", "align": "right" },
             {"formatter": "blank", "align": "right" }
         ]
     }
 
 
 
     /**
      * 조회
      * @param {*} e
      * @returns
      */
     const onSearch = async (e) => { 
         
         e.preventDefault(); 
         
         Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);
         
         let para = `{
                         "vnt1": "${idata.kVnt1}", "gubn": "${idata.kGubn1}",
                         "comp": "${idata.kComp}", "plnt": "${idata.kPlnt}", "ptno": "${idata.kPtno}",
                         "opt": "${idata.kOpt}", "sday": "${idata.kGubn2}${idata.kGubn1 === "D"? idata.kGubn3<10 ? 0+idata.kGubn3 :idata.kGubn3: ''}"
                      }`;
 
         let param = encodeURI(para);
 
         if(idata.kOrdr === "3"){
             setMessage({ openAlert : true, 
                 severity : "info",
                 msg : "조회결과가 수량 + 금액일 경우는 엑셀 전환만 가능합니다."});
         }else{
             const query = SERVER_URL + "/rom90/search?query=" + param;
             setLoading(true);
             const fetchData = async (query) => {
             
 
                 await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })
                     .then((resp) => resp.json())
                     .then((data) => {
                         if (data.success) {
                             if (data.data.length === 0) {
                                 setMessage("조회된 결과가 없습니다."); 
                             } else {
                                 setMessage("정상적으로 조회되었습니다."); 
                                 
                             }
                             
                             let grid = idata.apilist;

                             var row  =  createData(data.data[0], 'Top'); 
                             
                             grid.setPinnedTopRowData(row);

                            let deletedRow = data.data[0];
                            grid.updateRowData({remove:[deletedRow]});
                             
 
                             
                              setSearchApi(query);
                             setLoading(false);
                         }
                         
                         if (data.error) {
                             setMessage({ openAlert : true, 
                                          severity : "error",
                                          msg : "조회 실패"});
                         }
                     })
                     .catch((error) => {
                         // 에러 응답 처리
                         if (error) {
                             setMessage({ openAlert : true, 
                                          severity : "error",
                                          msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                         }
                     });
             };
             fetchData(query);
         }
     }
 
 
     /**
      * 신규 Row추가
      * @param {*} e
      */
     const onAddRow = async (e) => {
     }
 
 
 
     /**
      * 입력 (발행)
      * @param {*} e
      */
     const onInsert = async (e) => {
 
     }
 
 
 
     /**
      * 수정
      * @param {*} e
      * @returns
      */
     const onUpdate = async (e) => {
     }
 
     /**
      * 삭제
      * @param {*} e
      */
     const onDelete = async (e) => {
     }
 
     /**
      * 엑셀
      * @param {*} e
      */
     const onExcel = async (e) => {
         // Cookie 값 셋팅
         Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);
         
         let para = `{
                         "vnt1": "${idata.kVnt1}", "gubn": "${idata.kGubn1}",
                         "comp": "${idata.kComp}", "plnt": "${idata.kPlnt}", "ptno": "${idata.kPtno}",
                         "opt": "${idata.kOpt}", "sday":"${idata.kGubn2}${idata.kGubn1 === "D"? idata.kGubn3<10 ? 0+idata.kGubn3 :idata.kGubn3: ''}",
                         "ordr": "${idata.kOrdr}","vnt1Nm":"${idata.kVnt1Nm}","compNm":"${idata.kCompNm}","plntNm":"${idata.kPlnt===""?"전체":idata.kPlntNm}","gubn1Nm":"${idata.kGubn1Nm}",
                         "optNm":"${idata.kOpt==="all"?"공장+품번":idata.kOptNm}","ordrNm":"${idata.kOrdr==="1"?"수량":idata.kOrdrNm}"
                      }`;
 
         let param = encodeURI(para);
 
         const query = SERVER_URL + "/rom90/excel?query=" + param;
         //엑셀파일 조회 시 반응
         setLoading(true);
         await fetch(query, {headers: {Authorization: token } })
             .then((resp) =>{
                 return resp.blob();
             }).then((blob)=>{
                 const href = window.URL.createObjectURL(blob);
                 const link = document.createElement('a');
                 link.href = href;
                 link.setAttribute('download', 'rom90.xls');
                 document.body.appendChild(link);
                 link.click();
                 document.body.removeChild(link);
             }).catch((err) => {
                 return Promise.reject({ Error: 'error', err });
             })
         setLoading(false);
     
         // 엑셀파일 생성 메세지
         setMessage({ openAlert : true, 
                      severity : "success",
                      msg : "엑셀파일이 생성되었습니다."});   
     }
 
     //Transaction AlertDialogResult
     const AlertDialogResult = async (e) => {
 
         //AlertDialog 초기화
         setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
 
 
 
         //DialogResult
         if (e.result == "N") return;
 
         //Transaction 구분
         switch (e.Trans)
         {
             //등록
             case "I" :
                 break;
             //수정
             case "U" :
                 break;
             //삭제
             case "D2" :
                 break;
             case "D3" :
                 break;
         }
     }
 
 
 
 
     

 
     const rowPinned = (params) => {
         // default Cell Click 이벤트 
 
         let gridApi = params.api;
 
         idata.apilist = gridApi;

     };
 
/**
 *Grid Column 정의
 */
     
     const mainColumnDefs =
 
     [
        { headerName: "RN", field: "RN", sortable: false, filter: false, flex: 1, maxWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true,  },
         { headerName: idata.kOpt==="part"?"품번":"공장", field: "TITL1", sortable: false, filter: false, flex: 1, minWidth: 100, maxWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, pinned:'left',hide:false,
         cellRendererSelector:rowPinned},
         //{ headerName: "품번", field: "TITL2", sortable: false, filter: false, flex: 1, maxWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false, pinned: 'left'},
         { headerName:idata.kOpt==="plnt"?"공장명":idata.kOpt==="part"?"품명":"품번", field: "TITL2", sortable: false, filter: false, flex: 1, maxWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide:false, pinned: 'left'},
         { headerName: "누계", field:  idata.kOrdr==1 ? "ASQTY" : "ASMON", sortable: true, filter: true, flex: 1, maxWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false, pinned: 'left'},
         //{ headerName: idata.gubn===""?"":((idata.kOrdr==="1") ? idata.headerList[0].ASQTY:idata.headerList[0].ASMON), field: idata.kOrdr==1 ? "SQTY" : "SMON", sortable: false, filter: false, flex: 1, minWidth: 125, maxWidth: 125, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true,valueFormatter: Utils.numberFormatter, hide: false, pinned: 'left' },
         
     ].concat(
                 // 월별이면 12컬럼, 아니면 31컬럼으로
                 Array(idata.kGubn1 === "M" ? 12 : 31).fill(0).map((_, i) => {
                     return {
                         
                             // headerName: (i+1).toString()+(idata.kGubn1 === "M" ? "월" : "일"),
                             // children:
                              
                                headerName: (i+1).toString()+(idata.kGubn1 === "M" ? "월" : "일"), field: idata.kOrdr === "1" ? "SQTY"+(i+1) : "SMON"+(i+1), sortable: false, filter: false, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false//,valueFormatter: Utils.numberFormatter
                                
                         // 수량 혹은 금액 구분. 수량+금액은 엑셀만 출력하므로 제외
                         //(idata.kGubn1 === "M" ?  idata.headerList[0].SMQTY+i : idata.headerList[0].SQTY+i)                    
                     }
                 })
            );
      
 
           
    const pinnedTopRowData = useMemo(() => {

        
    let gridApi = grdRom90Main;
    let gridApi2 = grdRom90Main.current;

        return createData(1, 'Top');
    }, []);


    const onClick = async (e) => {
        setPanded(!panded);


        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 100px - 10px)"
                });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 100px  - 50px)"
                });
        }
    }

          
 
     return(
         <>
             {/* Dialog FRAME */}
             <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />
 
             {/* Alert FRAME */}
             <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg}
                          onClose={
                              ()=> {
                                  setMessage({...message, openAlert: false});
                              }
                          }
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} />
 
             {/* Loading */}
             <Loading loading={loading} /> 
 
             {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
             <Ribbon
                 onSearch={onSearch} onSearchName={null}     //조회
                 onExcel={onExcel} onExcelName={null} //Excel
             >
                 {"납입지시 > 현황관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>매출현황</b>
             </Ribbon>
 
             <ContentXearch
                 optionsX={optionsX}
                 onChange={onChange}
                 onClick={onClick}
                  />
 
             {/* Content FRAME (Data / Grid Frame) START -------------- */}
             <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:100px */}
                 <div className={"grid_type01"}> {/* 상단 FRAME */}
                     <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                         <Grid
                             ref={grdRom90Main}
                             gridId={"ROM90"}                    //  (필수) Grid Id 정의
                             columnDefs={mainColumnDefs}             //  (필수) 컬럼 정의
                             pagination={true}                  //  쪽수 매기기 여부
                             paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                             paginationPageSize={"26"}         //  쪽수 1Page 당 Row수
                             rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                             suppressRowClickSelection={false}   //  클릭으로 선택 하는것을 방지
                             rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                             enableRangeSelection={false}        //
                             enableCellTextSelection={true}      // cell selection drag mode
                             isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                             displayNo = {false}
                             searchApi={searchApi}               // 조회
                             // insertApi={insertApi}               // 등록
                             // insertParam={inserts}               // 등록 및 수정시 Parmeter
                             // insertRefresh={()=> {onSearch(); onSearch2();} } // 등록이후
                             onClearApi={onClearApi}             // 초기화
                             clearGrid={clearGrid}
                             onClearGridFlag={setClearGrid}
                             //pinnedTopRowData={createData(1, 'Top')}
                             pinnedTopRowData={pinnedTopRowData}
                             // onCellValueChangedEvent={grdRom90Main_onCellValueChanged}
                             onRowClickEvent={null}
                             //updateApi={updateApi}               // 수정
                             //deleteApi={deleteApi}               // 삭제
                             //updateRefresh={onSearch}            // 수정이후
                             //deleteRefresh={onSearch}            // 삭제이후
                         />
                     </div>
                 </div>
             </Content>
         </>
     )
 }
 export default ROM90;