/**
 * @Page RNTW0
 * @Description [RNTW0]수신자료관리 - CLAIM정보자료조회 - 클레임 [W1]
 **/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from '@/components/molecules/CustomAlert';
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common';
import CommonUtil from '@/utils/CommonUtil';
import UploadModal from '@/components/organisms/UploadModal';
import Loading from 'src/components/atoms/CustomLoading2';

const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER;
const Utils = new CommonUtil();

function RNTW0() {
	const token = Common.getToken();
	const tVnt1 = Common.getToken('vand');
	const grdRntw0Main = useRef();
	let [loading, setLoading] = useState(false);

	const currentYear = new Date().getFullYear().toString();
	const currentMonth = (new Date().getMonth() + 1).toString();

	const [idata, setData] = useState({
		kVnt1: tVnt1, // T1업체
		kComp: 'MHMC', // 회사구분
		kNpdt: Common.getToday(),
		kPlnt: '',
		kGubn: '',
		kDocd: '',
		Key0: 'W1',
		CNT: '',
		kGubn1: currentYear,
		kGubn2: currentMonth,
		vnt1List: [],
		docdList: [],
		yearList: [],
		montList: [],
	});

	const [message, setMessage] = React.useState({
		openAlert: false,
		severity: 'success',
		msg: '',
	});
	const { openAlert, severity, msg } = message;

	//Dialog
	const [dlgMessage, setDlgMessage] = React.useState({
		openDlg: false,
		dlgTrans: '',
		dlgMsg: '',
	});
	const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
	const [uploadInfo, setUploadInfo] = useState({ vnt1: tVnt1, subDir: 'DATA' });

	let [searchApi, setSearchApi] = useState(false);
	let [insertApi, setInsertApi] = useState(false);
	const [openUpload, setOpenUpload] = React.useState(false); // Modal 항목 setting
	const [panded, setPanded] = React.useState(true);
	const [style, setStyle] = useState({ height: 'calc(100vh - 60px - 50px - 100px  - 50px)' });

	const onClearApi = useCallback(async (e) => {
		setSearchApi(false);
		setInsertApi(false);
	}, []);

	/**
	 *MOUNT
	 */
	useEffect(() => {
		onSearchCommon1(`{"pgmid":"NETP_DOCCODE","Key0":"W1","Key1":"X","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, 'docdList');

		for (var i = idata.kGubn1 - 6; i <= parseInt(idata.kGubn1) + 1; i++) {
			idata.yearList.push({
				comCode: i,
				text: i + '년',
			});
		}
		for (var i = 1; i <= 12; i++) {
			idata.montList.push({
				comCode: i,
				text: i + '월',
			});
		}
	}, []);

	const onSearchCommon1 = (para, name) => {
		let commonData = idata;

		const fetchData1 = async (para, name) => {
			let param = encodeURI(para);
			const query = SERVER_URL + '/comcd/search1?query=' + param;
			await fetch(query, { headers: { Authorization: token } })
				.then((resp) => resp.json())
				.then((data) => {
					if (data.success) {
						if (name === 'docdList') {
						}
						commonData[name] = data.data;
						setData({ ...commonData });
					}
				});
		};
		fetchData1(para, name);
	};

	/**
	 * Grid Event
	 * @param {*} e
	 */
	const grdRntw0Main_onCellValueChanged = async (e) => {};

	/**
	 * 검색조건
	 */
	const optionsX = {
		0: [
			//회사구분
			{
				formatter: idata.kGubn == 'WC' ? 'blank' : 'select',
				name: 'kComp',
				labelName: '회사구분',
				type: 'text',
				defaultValue: idata.kComp,
				codeMode: false,
				selectAll: '',
				comms: null,
				align: 'left',
				items: [
					{ comCode: 'MHMC', text: '(주)현대자동차' },
					{ comCode: 'MKMC', text: '(주)기아자동차' },
				],
			},
			{
				formatter: 'select',
				name: 'kDocd',
				labelName: '문서코드',
				type: 'text',
				defaultValue: idata.kDocd,
				codeMode: false,
				selectAll: '',
				comms: idata.docdList,
				align: 'left',
			},

			{ formatter: 'blank', align: 'left' },
			{ formatter: 'blank', align: 'left' },
			{ formatter: 'blank', align: 'left' },
		],
		1: [
			{
				formatter: 'select2', // WRCISMD
				name: 'kGubn1',
				name2: 'kGubn2',
				labelName: '통보서 년월',
				defaultValue: idata.kGubn1,
				defaultValue2: idata.kGubn2,
				codeMode: false,
				codes: null,
				comms: idata.yearList,

				codeMode2: false,
				codes2: null,
				comms2: idata.montList,

				//selectAll : "all",
				selectAll2: 'all',
				align: 'left',
			},

			{
				formatter: 'select',
				name: 'kGubn',
				labelName: '국내외구분',
				type: 'text',
				defaultValue: idata.kGubn,
				codeMode: false,
				selectAll: 'all',
				//"comms": idata.kGubnList,
				align: 'left',
				items: [
					{ comCode: 'D', text: '국내(D)' },
					{ comCode: 'E', text: '해외(E)' },
				],
			},

			{ formatter: 'blank', align: 'left' },
			{ formatter: 'blank', align: 'left' },
			{ formatter: 'blank', align: 'left' },
		],
	};

	/**
	 * Control 변경 Event
	 * @param {*} e
	 */
	const onChange = async (e) => {
		const { name, value } = e.target;

		let newSearchs = idata;
		newSearchs[name] = value;
		setData({ ...newSearchs });

		//회사, 회차구분 변경시
		if (name === 'kComp') {
			newSearchs['docdList'] = [];
			setData({ ...newSearchs });
			onSearchCommon1(`{"pgmid":"NETP_DOCCODE","Key0":"W1","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}",`, 'docdList');
		}
	};

	/**
	 * 조회
	 * @param {*} e
	 * @returns
	 */
	const onSearch = async (e) => {
		
		if (!idata.kDocd) {
			if (idata.docdList.length > 0) {
				idata.kDocd = idata.docdList[0].comCode;
			}
		}

		//회사구분선택 Check
		if (idata.kDocd == '') {
			setMessage({ openAlert: true, severity: 'info', msg: '문서코드를 선택하십시요 !' });
			document.getElementById('kDocd').focus();
			return;
		}

		const fetchData = async (e) => {
			//조회시 전체건수구해서 넣어주기
			let inputData = idata;

			let para = `{"kVnt1":"${idata.kVnt1}",
                         "kComp":"${idata.kComp}",
                         "kGubn":"${idata.kGubn}",
                         "kDocd":"${idata.kDocd}",
                         "kGubn1":"${idata.kGubn1}${idata.kGubn2 > 0 ? (idata.kGubn2 < 10 ? 0 + idata.kGubn2 : idata.kGubn2) : ''} ",
                         "kGubn2":"${idata.kGubn2 < 10 ? 0 + idata.kGubn2 : idata.kGubn2}" 
                        }`;
			let param = encodeURIComponent(para);
			const query = SERVER_URL + '/rntw0/search?query=' + param;

			await fetch(query, { headers: { Authorization: token } })
				.then((resp) => resp.json())
				.then((data) => {
					if (data.success) {
						setSearchApi(query);
					}
					if (data.error) {
						alert('서버 상태를 확인해 주세요. 관리자에게 문의해주세요.');
					}
				})
				.catch((error) => {
					// 에러 응답 처리
					if (error) {
						setMessage({ openAlert: true, severity: 'error', msg: '서버 상태를 확인해 주세요. 관리자에게 문의해주세요.' });
					}
				});
		};
		fetchData(e);
	};

	const onUpload = () => {
		setOpenUpload(true);
	};

	const handleUploadClickOpen = () => {
		setOpenUpload(true);
	};

	const handleUploadClose = () => {
		setOpenUpload(false);
		//onSearchDocd();
	};

	//Transaction AlertDialogResult
	const AlertDialogResult = async (e) => {
		//AlertDialog 초기화
		setDlgMessage({ openDlg: false, dlgTrans: '', dlgMsg: '' });

		//DialogResult
		if (e.result == 'N') return;

		//Transaction 구분
		switch (e.Trans) {
			//등록
			case 'I':
				break;
			//수정
			case 'U':
				break;
			//삭제
			case 'D':
				break;
		}
	};

	/**
	 * 엑셀
	 * @param {*} e
	 */
	const onExcel = async (e) => {
		let para = `{"kVnt1":"${idata.kVnt1}",
                    "kComp":"${idata.kComp}",
                    "compNm":"${idata.kComp === 'EHMC' ? '현대자동차(주)' : '기아자동차(주)'}",
                    "kGubn":"${idata.kGubn}",
                    "ocgbNm":"${idata.kGubn === 'D' ? '국내' : idata.kGubn === 'E' ? '해외' : '전체'}",
                    "kDocd":"${idata.kDocd}",
                    "kGubn1":"${idata.kGubn1}${idata.kGubn2 > 0 ? (idata.kGubn2 < 10 ? 0 + idata.kGubn2 : idata.kGubn2) : ''} ",
                    "kGubn2":"${idata.kGubn2 < 10 ? 0 + idata.kGubn2 : idata.kGubn2}" 
                }`;

		let param = encodeURIComponent(para);
		const query = SERVER_URL + '/rntw0/excel?query=' + param;
		setLoading(true);
		await fetch(query, { headers: { Authorization: token } })
			.then((resp) => {
				return resp.blob();
			})
			.then((blob) => {
				const href = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = href;
				link.setAttribute('download', 'rntw0.xls');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((err) => {
				return Promise.reject({ Error: 'error', err });
			});
		setLoading(false);
		setMessage({ openAlert: true, severity: 'success', msg: '엑셀파일이 생성되었습니다.' });
	};

	/**
	 *Grid Column 정의
	 */
	const columnDefs = [
		{
			headerName: '문서분류',
			field: 'wrcdocd',
			sortable: true,
			filter: true,
			flex: 1,
			maxWidth: 120,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			pinned: 'left',
			hide: false,
		},
		{
			headerName: 'SEQ',
			field: 'wrclseq',
			sortable: true,
			filter: true,
			flex: 1,
			maxWidth: 58,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			pinned: 'left',
			hide: false,
		},
		{
			headerName: '법인',
			field: 'wrccopr',
			sortable: true,
			filter: true,
			flex: 1,
			maxWidth: 60,
			maxWidth: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			pinned: 'left',
			hide: false,
		},
		{
			headerName: '발생구분',
			field: 'wrcocgb',
			sortable: true,
			filter: true,
			flex: 1,
			maxWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			pinned: 'left',
			hide: false,
		},
		{
			headerName: '업체번호',
			field: 'wrcvand',
			sortable: true,
			filter: true,
			flex: 1,
			maxWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: true,
		},
		{
			headerName: '변제통보서 년월',
			field: 'wrcismd',
			sortable: true,
			filter: true,
			flex: 1,
			maxWidth: 120,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			valueFormatter: Utils.dateFormatter2,
			pinned: 'left',
			hide: false,
		},
		{
			headerName: '변제통보서 구분코드',
			field: 'wrcwnti',
			sortable: true,
			filter: true,
			flex: 1,
			maxWidth: 145,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			pinned: 'left',
			hide: false,
		},
		{
			headerName: '통보서 순위',
			field: 'wrcinfm',
			sortable: true,
			filter: true,
			flex: 1,
			maxWidth: 105,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			pinned: 'left',
			hide: false,
		},
		{
			headerName: '가변제/진변제 구분',
			field: 'wrcsngb',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '사업소명',
			field: 'wrcconm',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '클레임 타입',
			field: 'wrcclty',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '차종',
			field: 'wrcopcd',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 60,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '차종명',
			field: 'wrccarm',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '원인부번',
			field: 'wrccsno',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 120,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignL'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '원인부품명',
			field: 'wrcprnm',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 130,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: 'opcode',
			field: 'wrcwkcd',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: 'RONO',
			field: 'wrcrono',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: 'VIN',
			field: 'wrcvino',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '주행거리',
			field: 'wrcodmt',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '사용개월',
			field: 'wrcvsbm',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '생산일',
			field: 'wrcpdmd',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			valueFormatter: Utils.dateFormatter,
			hide: false,
		},
		{
			headerName: '판매일',
			field: 'wrcsamd',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			valueFormatter: Utils.dateFormatter,
			hide: false,
		},
		{
			headerName: '작업일',
			field: 'wrcprmd',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			valueFormatter: Utils.dateFormatter,
			hide: false,
		},
		{
			headerName: '납입율',
			field: 'wrcnyul',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '분담율',
			field: 'wrcbyul',
			sortable: true,
			filter: true,
			flex: 1,
			minwidth: 60,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatterFixed2,
			hide: false,
		},
		{
			headerName: '적용율',
			field: 'wrcbrrp',
			sortable: true,
			filter: true,
			flex: 1,
			minwidth: 60,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatterFixed2,
			hide: false,
		},
		{
			headerName: '원인',
			field: 'wrccaus',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 60,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '현상',
			field: 'wrcpsit',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 60,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '엔진번호',
			field: 'wrcegno',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 120,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '변속기번호',
			field: 'wrctmno',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '1차 불량 진단코드',
			field: 'wrcbdc1',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '2차 불량 진단코드',
			field: 'wrcbdc2',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '3차 불량 진단코드',
			field: 'wrcbdc3',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '환율',
			field: 'wrcexrt',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '변제대상부품비',
			field: 'wrcbptm',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 120,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '변제대상공임비',
			field: 'wrcbrpm',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 120,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '변제대상외주비',
			field: 'wrcbsbm',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 120,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '변제부품비',
			field: 'wrcvptm',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 95,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '변제공임비',
			field: 'wrcvrpm',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 95,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '변제외주비',
			field: 'wrcexfm',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 95,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '이전주행거리',
			field: 'wrcbfod',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 110,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '이전수리일',
			field: 'wrcprdt',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 95,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '이전RO',
			field: 'wrcbrro',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 90,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '이전업체',
			field: 'wrcovnd',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '이전변제통보서년월',
			field: 'wrcbsym',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 140,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			valueFormatter: Utils.dateFormatter2,
			hide: false,
		},
		{
			headerName: '이전변제통서구분코드',
			field: 'wrcbsgb',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 155,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '이전순위',
			field: 'wrcosrl',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 90,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: 'HEADHDETAILD',
			field: 'wrchdgb',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 125,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: 'DETAL 번호',
			field: 'wrcpano',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '수량',
			field: 'wrcquty',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 70,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '교환부품번호',
			field: 'wrccgno',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 110,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '교환부품명',
			field: 'wrccgnm',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 180,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: 'MAKUP',
			field: 'wrcmkup',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '작업수량',
			field: 'wrcwrqt',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: 'M/H',
			field: 'wrcwkmh',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 70,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatterFixed2,
			hide: false,
		},
		{
			headerName: '변제대상부품비',
			field: 'wrcbptm2',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '변제대상공임비',
			field: 'wrcbrpm2',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '변제대상외주비',
			field: 'wrcbsbm2',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignR'],
			singleClickEdit: true,
			valueFormatter: Utils.numberFormatter,
			hide: false,
		},
		{
			headerName: '생성일자',
			field: 'wrcsnmd',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			valueFormatter: Utils.dateFormatter,
			hide: false,
		},
		{
			headerName: '캠페인이슈번호',
			field: 'wrcocno',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 120,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '외장칼라',
			field: 'wrcxrcl',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 80,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		},
		{
			headerName: '여유내용',
			field: 'wrcrsbc',
			sortable: true,
			filter: true,
			flex: 1,
			minWidth: 150,
			editable: false,
			headerClass: '',
			cellClass: ['nocheckbox', 'alignC'],
			singleClickEdit: true,
			hide: false,
		}
	];

	const onClick = async (e) => {
		setPanded(!panded);

		if (panded === true) {
			setStyle({
				height: 'calc(100vh - 60px - 50px - 50px)',
			});
		} else {
			setStyle({
				height: 'calc(100vh - 60px - 50px - 100px  - 50px)',
			});
		}
	};

	return (
		<>
			{/* Dialog FRAME */}
			<AlertDialog open={openDlg} trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

			{/* Alert FRAME */}
			<CustomAlert
				open={openAlert}
				severity={severity}
				duration={3000}
				message={msg}
				onClose={() => {
					setMessage({ openAlert: false, severity: '', msg: '조회 실패' });
				}}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			/>

			<UploadModal
				open={openUpload}
				onClose={handleUploadClose}
				onClickOpen={handleUploadClickOpen}
				title={'CSV 업로드'}
				fileId={'HKCW1'}
				filePath={'C:\\HKC\\VAN\\HKCW1.CSV'}
				uploadInfo={uploadInfo}
				accp=".csv"
				maxFiles={1}
				validatorName={'csv'}
				uploadApi={SERVER_URL + '/hkc/upload'}
			/>

			{/* Loading */}
			<Loading loading={loading} />

			{/* Ribbon Frame : 프로그램 Path 및 버튼 */}
			<Ribbon onSearch={onSearch} onSearchName={null} onUpload={onUpload} onExcel={onExcel} onExcelName={null}>
				{'수신자료관리 > CLAIM정보자료조회 > '}
				<b style={{ color: '#cd5c5c', fontFamily: 'PretendardLight, NanumGothic, sans-serif' }}>클레임 [ W1 ]</b>
			</Ribbon>

			{/* ContentXearch Frame : 검색조건 */}
			<ContentXearch optionsX={optionsX} onChange={onChange} onClick={onClick} />

			{/* Content FRAME (Data / Grid Frame) START -------------- */}
			<Content style={style}>
				{' '}
				<div className={'grid_type01'} style={{ height: '100%' }}>
					{' '}
					{/* 상단 FRAME */}
					<div className={'grid_wrap01'}>
						{' '}
						{/* 상단 wrap FRAME */}
						<div className="grid_cont_box" style={{ height: '100%' }}>
							<Grid
								ref={grdRntw0Main}
								gridId={'RNTW0'}
								columnDefs={columnDefs}
								pagination={false}
								paginationCsv={true}              //  CSV 사용구분 - pagination과 동시에 사용 필요
								paginationPageSize={'1000'}       //  쪽수 1Page 당 Row수
								rowMultiSelectWithClick={false}   //  멀티 클릭 가능 여부
								suppressRowClickSelection={false} //  클릭으로 선택 하는것을 방지
								rowSelection={'single'}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
								enableRangeSelection={false}      //
								enableCellTextSelection={true}    // cell selection drag mode
								isFirst={false}                   // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
								displayNo={false}                     
								searchApi={searchApi}                     
								deleteRefresh={onSearch}          // 삭제이후 조회
								updateRefresh={onSearch}          // 삭제이후
								onClearApi={onClearApi}           // 초기화
								onCellValueChangedEvent={grdRntw0Main_onCellValueChanged}                     
								insertApi={insertApi}             // 등록
								insertRefresh={() => {
									onSearch();
								}}
							/>
						</div>
					</div>
				</div>
			</Content>
		</>
	);
}
export default RNTW0;
