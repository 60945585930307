import React, { useEffect , useState} from "react";
// import { useHistory } from 'react-router-dom';
// import Ribbon from '../components/organisms/Ribbon';
import Section from '../components/organisms/Section';
import CustomAlert from "@/components/molecules/CustomAlert";
import MainTemplates from '@/components/templates/MainTemplates';

// import * as common from '../../utils/Common'; 
// import axios from 'axios';


// const navi = require('../config/navigation.json');
// const common = require('../config/commonCode.json');


function RefusePage(props) {
    const [message, setMessage] = useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;
    const [seconds, setSeconds] = useState(5);
    // const history = useHistory();


    useEffect(() => {
        const countdown = setInterval(() => {
            if (parseInt(seconds) > 1) {
              setSeconds(parseInt(seconds) - 1);
            }
            if (parseInt(seconds) === 1) {
                window.location.href="/login";
            }
          }, 1000);
          return () => clearInterval(countdown);
        // setMessage({
        //     openAlert: true,
        //     severity: "error",
        //     msg: "다른 ip에서 접속이 감지되었습니다."
        // });

    //     setTimeout(() => {
    //        window.location.href="/login";
    //    }, 5000);

    }, [seconds]);
    
    const logout = (e) => {
        //sessionStorage.removeItem('token'); // token
        window.location.href = "/login"; // <link to=... 사용시에는 .href 미사용
    }

    /* 메시지 받아올때 -> {props.message}*/
    return (
        <>
            <MainTemplates>
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={() => { setMessage({ openAlert: false, severity: "", msg: "조회 실패" }) }} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
                <Section>
                    <br />
                    <div className="fof_notfound">
                        <div style={{  width: "100%", display:"inline-block", verticalAlign: "middle",textAlign: "center"}} >
                            <div style={{width:"190px",height:"910px",float: "left"}}></div>

                            <div style={{width:"618px",height:"910px",float: "left"}}>
                                <div style={{height:"130px"}}> </div>
                                <img src="/images/oops_top.png" alt="ip_top" title="ip_top" style={{ position: "relative",left: "-39%"}}/>
                                <div style={{fontFamily:"'GmarketSansBold'",color:"#2E2E2E",fontSize: "31pt",textAlign: "left",letterSpacing:"-3px"}}> Invalid Authentication</div>
                                <div style={{fontFamily:"'GmarketSansLight'",color:"#000",fontSize: "29pt",textAlign: "left"}}> {props.message}</div>
                                <div style={{height:"60px"}}> </div>
                                {/* <div className="notfound_mid"> 이용에 불편을 드려 죄송합니다.</div>*/}
                                <div className="notfound_mid"> {seconds}초 후 로그인 화면으로 이동합니다.</div>
                                <div style={{height:"50px"}}> </div>
                                <div> 
                                    <a href="/login" onClick={logout}><div className="notfound_btn" style = {{marginLeft: "120px"}}>로그인 화면 이동</div></a>
                                </div>
                            </div>

                            <div style={{overflowX: "hidden"}}>{/*width:"700px",,float:"left"*/}
                                <div style={{height:"240px"}}> </div>
                                <img src="/images/ip_bg.png" alt="ip_img" title="ip_img" style={{ paddingRight: "34px",verticalAlign: "middle",width:"810px",display: "flex"}}/>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                </Section> {/* Section FRAME (WorkFrame) -------------- END */}
            </MainTemplates>
        </>
    );
}
export default RefusePage;