import React, { useState, useEffect, useCallback, forwardRef, useRef, useImperativeHandle } from "react";
import { AgGridReact } from "ag-grid-react";
import CustomAlert from "@/components/molecules/CustomAlert";
import CustomLoadingOverlay from "@/components/atoms/CustomGridLoading";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "@/css/agcustomize.css";
import * as Common from "@/utils/Common"; // Common.js
import AG_GRID_LOCALE_KR from "@/utils/locale.kr";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { FunctionalCustomTooltip } from "./customTooltip.jsx";
import "@/css/tooltip_style.css";

const noCol = { headerName: "No", field: "no",  cellClass: ["nocheckbox", "alignR"], valueGetter: node => String(node.node.rowIndex + 1), minWidth: 100}	

const agGrid = React.forwardRef(({ 
    gridId,
    columnDefs,
    defaultColDef,
    pagination,
    paginationCsv,
    paginationPageSize,
    rowMultiSelectWithClick,
    suppressRowClickSelection,
    rowSelection,
    enableRangeSelection,
    enableCellTextSelection,
    isFirst,
    displayNo,
    searchApi,
    insertApi,
    updateApi,
    deleteApi,
    insertRefresh,
    updateRefresh,
    deleteRefresh,
    onClearApi,
    onCellValueChangedEvent,
    onRowClickEvent,
    onCellClickEvent,

    pinnedTopRowData,
    
    onClickedRow, onRowApi, onClearApi2,
    insertParam, insertParamChange, insertParamList,
    onRowId, onChangeRowId, onSetSelectedData, onRowSelectedEvent,
    reSelection, refresh,
    clearGrid, onClearGridFlag,
    moveScrolledToEnd,useMessage,

    headerHeight, rowHeight,

    onRowSelected,onCellKeyDownEvent,
    onPaginationChanged,paginationCustomm,onPrevnClickedEvent,onNextClickedEvent,paginationCustom,onGridReadyEvent
    ,alarmAlert
}, ref) => 
{
    const token = Common.getToken();

    const [gridApi, setGridApi] = useState(null);
    const [csvOffsets, setCsvOffsets] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [openAlert, setOpenAlert] = React.useState(false); 
    const [message, setMessage] = React.useState(""); 
    const [severity, setSeverity] = React.useState("success"); 
    const [newColumnDefs, setNewColumnDefs] = React.useState([]); //Column 재정의 (No 컬럼 자동추가)


    function isFirstColumn(params) {
        var displayedColumns = params.columnApi.getAllDisplayedColumns();
        var thisIsFirstColumn = displayedColumns[displayNo ? 1 : 0] === params.column;
        return thisIsFirstColumn;
    }


    const handleCloseAlert = () => {
        // Alert 창 CLOSE
        setOpenAlert(false);
    };

    const onGridReady = (params) => {
        setGridApi(params.api);

        if(onGridReadyEvent != undefined){
            onGridReadyEvent(params);
        }
    };
    
    
    useEffect(() => {
        if (displayNo) columnDefs.unshift(noCol);
        setNewColumnDefs(columnDefs)
    }, []);


    useEffect(() => {
        setNewColumnDefs(columnDefs)
    }, [columnDefs])

    
    useEffect(() => {

        if (gridApi) {
            gridApi.setRowData(rowData ? rowData : []);
            gridApi.hideOverlay();
        }
        setCsvOffsets({
            top: document.querySelector("#" + gridId + " .ag-paging-row-summary-panel").offsetTop + document.getElementById(gridId).offsetTop,
            left: document.getElementById(gridId).offsetLeft + 20,
        });
    }, [gridApi, rowData, gridId]); // 최초 실행


    const onCsvButtonClicked = () => {
        if (gridApi) {
            gridApi.exportDataAsCsv({ fileName: gridId });
        }
    };

    const onPrevnClicked = () => {
        
        onPrevnClickedEvent(gridApi);
    };


      
    const onNextClicked = () => {
        onNextClickedEvent(gridApi);
    };


    const onGridSizeChanged = (e) => {
        setCsvOffsets({
            top: document.querySelector("#" + gridId + " .ag-paging-row-summary-panel").offsetTop + document.getElementById(gridId).offsetTop,
            left: document.getElementById(gridId).offsetLeft + 20,
        });
    };

    //조회
    const onSearchGrid = useCallback(

        

        // 조회 onSearch Function
        async (s) => {
            if (s) {
                await fetch(s, { headers: { Authorization: token } })
                    .then((resp) => resp.json())
                    .then((data) => {
                        if (data.success) {
                            setRowData(data.data);
                            if(useMessage === undefined || useMessage === true){

                                if(alarmAlert !== "F"){

                                    setOpenAlert(true); 
                                    setSeverity("success"); 
                                    
                                    console.log(alarmAlert)
    
                                    if (data.data.length === 0) {
                                         setMessage("조회된 결과가 없습니다."); 
                                    } else {
                                         setMessage("정상적으로 조회되었습니다."); 
                                        if(gridId === "ROM90"){
                                            let deletedRow = data.data[0];
                                            gridApi.updateRowData({remove:[deletedRow]});
                                        }                                    
                                    }
                                }
                                
    
                            }
                            
                           
                            onClearApi();

                            if (moveScrolledToEnd) {
                                const index = data.data.length - 1;
                                gridApi.ensureIndexVisible(index, 'bottom');
                            }
                        }
                        if (data.error) {
                            setOpenAlert(true); 
                            setSeverity("error"); 
                            setMessage("조회 실패"); 
                            onClearApi();
                        }
                    })
                    .catch((error) => {
                        // 에러 응답 처리
                        if (error) {
                            setOpenAlert(true); 
                            setSeverity("error"); 
                            setMessage("서버 상태를 확인해 주세요. 관리자에게 문의해주세요."); 
                            onClearApi();
                        }
                    });
            }

            if(gridApi){
                gridApi.hideOverlay();
            }

        },
        [searchApi]
    );


    useEffect(() => {

        if (gridApi && searchApi) {
            gridApi.showLoadingOverlay();
            let s = searchApi;

            onSearchGrid(s);
        }
    }, [searchApi, gridApi]); // funcFlag 로 onSearch() 호출


    //등록
    const onInsertGrid = useCallback(
        // 입력 onInsert Functions
        async (e) => {
            gridApi.stopEditing(false); // Grid EditMode stop

            let selectedData = gridApi.getSelectedRows();

            if (insertApi) {

                // 선택 값 체크
                if (selectedData.length === 0) {
                    setOpenAlert(true); 
                    setSeverity("error"); 
                    setMessage("선택한 데이터가 없습니다."); 
                    gridApi.hideOverlay();
                    onClearApi();
                    return;
                }
                
                let newMap;
                if (insertParam) {
                    newMap = selectedData.map(function (data, i) {

                        let temp = data;
                        Object.entries(insertParam).forEach(function (disp, i) { // XearchBox Data
                            temp[disp[0]] = disp[1];
                        });

                        return { ...temp };

                    }, this);
                } else {
                    newMap = selectedData;
                }
                
                await fetch(insertApi, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', Authorization: token },
                    body: JSON.stringify(newMap),
                })
                    .then((resp) => resp.json())
                    .then((data) => {

                        setOpenAlert(true); 
                        if (data.success) {
                            if (data.count === 0) { 
                                setSeverity("error"); 
                                setMessage("등록된 데이터가 없습니다. "); 
                            } 
                            else {
                                setSeverity("success"); 

                                if (data.errors !== null) {
                                    setSeverity("error"); 
                                    setMessage(data.errors.message)
                                }
                                else {
                                    if(data.data === 1){
                                        setMessage("정상적으로 등록되었습니다."); 
                                    }else if(data.data[0].message !== null || data.data[0].message !== undefined){
                                        setMessage(data.data[0].message);     
                                    }else{
                                        setMessage("정상적으로 등록되었습니다."); 
                                    }
                                }
                            }
                            if (insertRefresh) setTimeout(() => insertRefresh(), 1000); //refresh onSearch 호출
                        }
                        else if (data.errors) {
                            setSeverity("error"); 
                            setMessage(data.errors.message); 

                            if (insertRefresh) setTimeout(() => insertRefresh(), 2000); //refresh onSearch 호출
                        }
                        else if (data.error) {

                            let message = data.message;

                            if(message.search('ORA-00001') > -1){ // ORA-00001 무결성 제약 조건
                                message = "이미 등록된 코드입니다.";
                            }else if(message.search('ORA-12899') > -1){ // ORA-12899 열에 대한 값이 큽니다
                                let custMsg = message;
                                custMsg = custMsg.substring(custMsg.indexOf("ORA-12899") , custMsg.indexOf("최대값:") + 8);
                                message = custMsg;
                            }else{
                                message = "잘못된 등록입니다.";
                            }

                            setSeverity("error"); 
                            setMessage(message); 
                        }
                        onClearApi();
                    })
                    .catch((error) => {
                        // 에러 응답 처리
                        if (error) {
                            setOpenAlert(true); 
                            setSeverity("error"); 
                            setMessage("서버 상태를 확인해 주세요. 관리자에게 문의해주세요."); 
                            onClearApi();
                        }
                    });
            }
            if(gridApi){
                gridApi.hideOverlay();
            }
        },
        [insertApi, gridApi, onClearApi]
    );
    useEffect(() => {
        if (insertApi) {
            if (gridApi.showLoadingOverlay) {
                gridApi.showLoadingOverlay();
                onInsertGrid();
            }
        }
    }, [insertApi, gridApi]); // funcFlag 로 onInsert() 호출


    //수정
    const onUpdateGrid = useCallback(
        // 수정 onUpdate Functions
        async (e) => {
            gridApi.stopEditing(false); // Grid EditMode stop

            let selectedData = gridApi.getSelectedRows();
            if (updateApi) {
                // 선택 값 체크
                if (selectedData.length === 0) {
                    setOpenAlert(true); 
                    setSeverity("error"); 
                    setMessage("선택한 데이터가 없습니다."); 
                    gridApi.hideOverlay();
                    onClearApi();
                    return;
                }

                let newMap;
                if (insertParam) {
                    // select Row Data
                    newMap = selectedData.map(function (data, i) {

                        let temp = data;
                        Object.entries(insertParam).forEach(function (disp, i) { // XearchBox Data
                            temp[disp[0]] = disp[1];
                        });

                        return { ...temp };

                    }, this);
                }
                else {
                    newMap = selectedData;
                }

                await fetch(updateApi, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', Authorization: token },
                    body: JSON.stringify(newMap),
                })
                    .then((resp) => resp.json())
                    .then((data) => {

                        setOpenAlert(true); 
                        if (data.success) {
                            if (data.count === 0) { 
                                setSeverity("error"); 
                                setMessage("수정된 데이터가 없습니다. "); 
                            }else {
                                setSeverity("success"); 

                                if (data.errors !== null) {
                                    setMessage(data.errors.message)
                                }
                                else {
                                    setMessage("정상적으로 수정되었습니다."); 
                                }
                            }
                            if (updateRefresh) setTimeout(() => updateRefresh(), 1000); //refresh onSearch 호출
                        }
                        else if (data.errors) {
                            setSeverity("error"); 
                            setMessage(data.errors.message); 
                        }
                        else if (data.error) {
                            let message = data.message;
                            setSeverity("error"); 
                            if(message.search('ORA-12899') > -1){ // ORA-12899 열에 대한 값이 큽니다
                                let custMsg = message;
                                custMsg = custMsg.substring(custMsg.indexOf("ORA-12899") , custMsg.indexOf("최대값:") + 8);
                                message = custMsg;
                            }

                            setMessage(message); 
                        }
                        onClearApi();
                    })
                    .catch((error) => {
                        // 에러 응답 처리
                        if (error) {
                            setOpenAlert(true); 
                            setSeverity("error"); 
                            setMessage("서버 상태를 확인해 주세요. 관리자에게 문의해주세요."); 
                            onClearApi();
                        }
                    });
            }
            gridApi.hideOverlay();
        },
        [updateApi]
    );
    useEffect(() => {
        if (updateApi) {
            if (gridApi.showLoadingOverlay) {
                gridApi.showLoadingOverlay();
                onUpdateGrid();
            }
        }
    }, [updateApi, gridApi]); // funcFlag 로 onInsert() 호출


    //삭제
    const onDeleteGrid = useCallback(
        // 삭제 onDelete Functions
        async (e) => {
            gridApi.stopEditing(false); // Grid EditMode stop

            let selectedData = gridApi.getSelectedRows();

            if (deleteApi) {

                // 선택 값 체크
                if (selectedData.length === 0) {
                    setOpenAlert(true); 
                    setSeverity("error"); 
                    setMessage("선택한 데이터가 없습니다."); 

                    gridApi.hideOverlay();
                    onClearApi();
                    return;
                }

                await fetch(deleteApi, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', Authorization: token },
                    body: JSON.stringify(selectedData),
                })
                    .then((resp) => resp.json())
                    .then((data) => {

                        setOpenAlert(true); 
                        if (data.success) {
                            if (data.count === 0) { 
                                setSeverity("error"); 
                                setMessage("삭제된 데이터가 없습니다. "); 
                            } 
                            else {
                                setSeverity("success"); 
                                setMessage("정상적으로 삭제되었습니다."); 
                                
                            }
                            if (deleteRefresh) setTimeout(() => deleteRefresh(), 1000); //refresh onSearch 호출
                        }
                        else if (data.errors) {
                            setSeverity("error"); 
                            setMessage(data.errors.message); 
                        }
                        onClearApi();

                    })
                    .catch((error) => {
                        if (error) {
                            setOpenAlert(true); 
                            setSeverity("error"); 
                            setMessage("서버 상태를 확인해 주세요. 관리자에게 문의해주세요."); 
                            onClearApi();
                        }
                    });
            }

            gridApi.hideOverlay();

        },
        [deleteApi, gridApi, onClearApi]
    );
    useEffect(() => {
        if (deleteApi) {
            if (gridApi.showLoadingOverlay) {
                gridApi.showLoadingOverlay();
                // gridApi.showNoRowsOverlay();
                onDeleteGrid();
            }
        }
    }, [deleteApi, gridApi]); // funcFlag 로 onInsert() 호출

    useEffect(() => {
        if (clearGrid) {
            setRowData(null);
            onClearGridFlag();
        }
    }, [gridApi, clearGrid]); // clearGrid true 일때 실행


    const Delayed = ({ children, waitBeforeShow = 100 }) => {
        const [isShown, setIsShown] = useState(false);

        useEffect(() => {
            setTimeout(() => {
                setIsShown(true);
            }, waitBeforeShow);
        }, [waitBeforeShow]);

        return isShown ? children : null;
    };






    //CellValueChanged
    const onCellValueChanged = async (e) => {
        if (!e.node.selected) {
           e.node.setSelected(true);
        }
        onCellValueChangedEvent(e);
    }


    /*
    const onCellClickEvented = async (e) => {
        if (!e.node.selected) {
           e.node.setSelected(true);
        }
        onCellValueChangedEvent(e);
    }
    */


    const suppressKeyboardEvent = async(e) => {
        if (e.key === "Enter"||e.key =="NumpadEnter") {
            return true;
          }
          return false;
    }
     const nextColumnq = (currentColumn) => {
        const columnDefs = gridApi.columnController.displayedColumns();
        
        const currentColumnIndex = columnDefs.findIndex((col) => col.field === currentColumn);
    
        if (currentColumnIndex >= 0 && currentColumnIndex < columnDefs.length - 1) {
          return columnDefs[currentColumnIndex + 1].field;
        }
    
        return null; // 다음 컬럼을 찾을 수 없는 경우 null 반환
      };

    //onCellKeyDown
    const onCellKeyDown = async (e) => {
        let cnt = e.api.getDisplayedRowCount()-1;

        if(e.event.code == 'ArrowUp'){
            if(e.node.rowIndex !== 0 ){
                ref.current.SetFocus(e.node.rowIndex - 1, e.column.colId);
            }else{
                ref.current.SetFocus(e.node.rowIndex, e.column.colId);
            }
            
        }else if(e.event.code == 'ArrowDown'){
            if(e.node.rowIndex < cnt ){
                var rowNode2 = gridApi.getDisplayedRowAtIndex(e.node.rowIndex+1)
                ref.current.SetFocus(e.node.rowIndex+ 1,  e.column.colId);

                var focusedCell = gridApi.getFocusedCell();
                var rowIndex = focusedCell.rowIndex;
                var colId = focusedCell.column.getId();
                var rowData = gridApi.getRowNode(rowIndex).data;
                var cellData = rowData[colId];

                if(rowNode2.data.jaqt===undefined){
                    if(gridId =="ROM4K"||gridId =="ROM4R"||gridId =="ROM4X"||gridId =="ROM4N"){
                        onCellKeyDownEvent(e);
                    }
                    
                    
                }else{
                    gridApi.stopEditing(true);
                    rowNode2.setDataValue(e.column.colId, rowNode2.data.jaqt)//납품서
                    gridApi.startEditingCell({
                        rowIndex: e.node.rowIndex+1,
                        colKey: 'npqt' // 납품수량
                    });
                }

                
            }else{
                ref.current.SetFocus(e.node.rowIndex, e.column.colId);
            }
        }else if(e.event.code =="ArrowRight"){
            //ref.current.SetFocus(e.node.rowIndex, e.column.colId+1);
            gridApi.tabToNextCell();
           
        }else if(e.event.code =="ArrowLeft"){
            gridApi.tabToPreviousCell();
        }else if(e.event.code =="Enter"||e.event.code =="NumpadEnter"){
           
            if(gridId =="ROM3I"||gridId =="ROM3L"||gridId =="ROMAA"){
                var rowNode2 = gridApi.getDisplayedRowAtIndex(e.node.rowIndex+1)
                ref.current.SetFocus(e.node.rowIndex+ 1,  e.column.colId);
    
                var focusedCell = gridApi.getFocusedCell();
                var rowIndex = focusedCell.rowIndex;
                var colId = focusedCell.column.getId();
                var rowData = gridApi.getRowNode(rowIndex).data;
                var cellData = rowData[colId];
                gridApi.stopEditing(true);
                rowNode2.setDataValue(e.column.colId, rowNode2.data.jaqt)//납품서
                gridApi.startEditingCell({
                    rowIndex: e.node.rowIndex+1,
                    colKey: 'npqt' // 납품수량
                });
            }else{
                if(gridId =="ROM4K"||gridId =="ROM4R"||gridId =="ROM4X"||gridId =="ROM4N"){
                    onCellKeyDownEvent(e);
                }else{
                    gridApi.tabToNextCell();
                }
                
            }
            
        }
        

     
    }

    //onCellKeyPress
    // const onCellKeyPress = async (e) => { 
    // }
    
    //onCellEditingStarted
    // const onCellEditingStarted = async (e) => { 
    // }

    //onCellEditingStopped
    // const onCellEditingStopped = async (e) => { 
    // }



    React.useImperativeHandle(ref, () => ({

        //Grid Row 추가
        addRow(items, rowIndex){
            Array.isArray(items) ? items.forEach(element =>  gridApi.updateRowData({add: [element], addIndex:rowIndex}))
                                 : gridApi.updateRowData({add: [items], addIndex:rowIndex});  
            // //gridApi.updateRowData({add: [items], addIndex:rowIndex});
            // //gridApi.applyTransaction({ add: [{ cod1: 'D' }], addIndex:0});
            //gridApi.getRowNode(rowIndex).selectThisNode(true);
            gridApi.getDisplayedRowAtIndex(rowIndex).setSelected(true);
            gridApi.ensureIndexVisible(rowIndex, 'middle'); //신규 Row로 이동
        },

        //Grid Get SelectedRows
        getSelectedNodes() {
            //gridApi.getSelectedRows();
            //gridApi.getSelectedNodes()

            return gridApi.getSelectedNodes()
        },

        getSelectedData() {
            let selectNodes = this.getSelectedNodes();
            let selectedData = selectNodes.map(node => node.data);
            
            // const selectedNodes = [];
            // // Need to call this instead of getSelectedNodes to preserve sorted order
            // gridApi.forEachNodeAfterFilterAndSort(function mapSortedNode ({ data, selected }) {
            //     if (selected) {
            //         data.rowIndex
            //         selectedNodes.push(data);
            //     }
            // });
            // return null;
        
             return selectedData
        },

        getRowCount(){ 
            return gridApi.getDisplayedRowCount()
        },

        //Set Focus
        SetFocus(row, col) {
            gridApi.setFocusedCell(row, col, null);

            gridApi.startEditingCell({
                rowIndex: row,
                colKey: col,
                rowPinned: null
            });
        },

        ShowFilterCheckBox(value) {
            var columnDefs = gridApi.getColumnDefs();
            columnDefs.forEach(function (colDef, index) {

                if (value) {
                    colDef.headerCheckboxSelection = isFirstColumn;
                    colDef.checkboxSelection = isFirstColumn;
                } 
                else {
                    colDef.headerCheckboxSelection = null;
                    colDef.checkboxSelection = null;
                }
            });
            gridApi.setColumnDefs(columnDefs);
        }
    }))

  

    return (
        <>
            {/* {children} */}
            <div style={{ width: "100%", height: "100%" }}>
                {/* Alert FRAME */}
                <CustomAlert open={openAlert} severity={severity} message={message} duration={5000} onClose={handleCloseAlert} />

                <div className={"example-wrapper"} style={{ height: "100%", width: "100%" }}>
                    {/* <div style={{ marginBottom: "5px" }}>
                                    <button onClick={() => onSelectedRows()}>Get selected rows</button>
                                </div> */}
                    <div id={gridId} /* (필수) Grid Id 정의 */ style={{ height: "100%", width: "100%" }} className={"ag-theme-alpine"}>
                        <AgGridReact
                            headerHeight={typeof headerHeight !== "undefined"?headerHeight:30}
                            rowHeight={typeof rowHeight !== "undefined"?rowHeight:26}
                            defaultColDef={
                                typeof defaultColDef !== "undefined"
                                    ? defaultColDef
                                    : {
                                        flex: 1,
                                        minWidth: 200,
                                        resizable: true,
                                        lockPosition: true,
                                        tooltipComponent: "customTooltip",
                                        cellClass: ["nocheckbox", "alignL"], // default cellClass
                                        headerCheckboxSelection: isFirst ? isFirstColumn : null,
                                        checkboxSelection: isFirst ? isFirstColumn : null,
                                    }
                            } // 필요 시 재 정의
                            //onRowClicked={typeof onClickedRow !== "undefined" ? onClickedRow : onGridRowClicked} // 필요 시 재 정의
                            //onRowClicked={typeof onClickedRow !== "undefined" ? onGridRowClicked : false} // 필요 시 재 정의
                            onRowClicked={onRowClickEvent} // 필요 시 재 정의
                            //onCellClicked={onCellClickEvented} // 필요 시 재 정의
                            onCellClicked={onCellClickEvent} // 필요 시 재 정의
                            pinnedTopRowData={pinnedTopRowData}
                            onRowSelected={onRowSelected}
                            // onSelectionChanged={onSelectionChanged}

                            pagination={typeof pagination !== "undefined" ? pagination : true} //  "true" // 쪽수 매기기 여부
                            paginationPageSize={typeof paginationPageSize !== "undefined" ? paginationPageSize : "1000"} //  "1000" // 쪽수 1Page 당 Row수
                            columnDefs={newColumnDefs} //  (필수) 컬럼 정의
                            //columnDefs={columnDefs} //  (필수) 컬럼 정의
                            //columnDefs={newColumnDefs} //  (필수) 컬럼 정의
                            rowMultiSelectWithClick={typeof rowMultiSelectWithClick !== "undefined" ? rowMultiSelectWithClick : true} // true // 멀티 클릭 가능 여부
                            suppressRowClickSelection={typeof suppressRowClickSelection !== "undefined" ? suppressRowClickSelection : false} // false // 클릭으로 선택 하는것을 방지
                            rowSelection={rowSelection ? rowSelection : "multiple"} // 여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                            //navigateToNextCell={navigateToNextCell} // 다음 셀을 키보드로 선택할 때 SELECTION에 대한 콜백함수 선언
                            onGridReady={onGridReady}
                            animateRows={true} // 로우 생길때 효과
                            frameworkComponents={{
                                customLoadingOverlay: CustomLoadingOverlay,
                                customTooltip: FunctionalCustomTooltip,
                                numericEditor: NumericEditor,
                                maxLengt: MaxEditor,
                                
                            }}
                            loadingOverlayComponent={"customLoadingOverlay"}
                            loadingOverlayComponentParams={{
                                loading: true,
                                type: false,
                                color: false,
                                loadingMessage: "조회 중 입니다.",
                            }}
                            // noRowsOverlayComponent={'customNoRowsOverlay'}
                            // noRowsOverlayComponentParams={{ Message: 'Sorry - no rows! at: ' }}
                            noRowsOverlayComponentParams={{
                                // noRowsMessageFunc: () => 'Sorry - no rows! at: ' + new Date(),
                                loading: true,
                                type: false,
                                color: false,
                                loadingMessage: "noRowsMessageFunc...",
                            }}
                            enableRangeSelection={typeof enableRangeSelection !== "undefined" ? enableRangeSelection : false} // false
                            enableCellTextSelection={typeof enableCellTextSelection !== "undefined" ? enableCellTextSelection : true} // true  // cell selection drag mode
                            // rowData={rowData} // data 받아와서 render 전 자동진행
                            // rowData={data ? data : []} // data 받아와서 render 전 자동진행
                            onGridSizeChanged={onGridSizeChanged}
                            localeText={AG_GRID_LOCALE_KR}
                            stopEditingWhenCellsLoseFocus={true}
                            onCellValueChanged = {onCellValueChanged}
                            onCellKeyDown = {onCellKeyDown}
                            suppressKeyboardEvent = {suppressKeyboardEvent}
                            //onCellKeyPress = {onCellKeyPress}
                            //onCellEditingStarted = {onCellEditingStarted}
                            //onCellEditingStopped = {onCellEditingStopped}
                            onPaginationChanged={onPaginationChanged}
                            tooltipShowDelay={500}
                            tooltipHideDelay={2000}
                            paginationCustom = {typeof paginationCustom !== "undefined" ? paginationCustom : false}
                            onPrevnClicked={onPrevnClicked}
                            onNextClicked={onNextClicked}
                        ></AgGridReact>

                        {/* custom csv Button */}
                        {pagination && paginationCsv ? (
                            <Delayed>
                                <div id={"csvButton"} className={"csvButton"} style={{ top: csvOffsets ? csvOffsets.top : "0", left: csvOffsets ? csvOffsets.left : "200" }}
                                    title={"csv 엑셀 다운로드"}
                                    onClick={onCsvButtonClicked}>
                                    {/* csv */}
                                    <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                                </div>
                            </Delayed>
                        ) : (
                            ""
                        )}


                        {/* custom pagination Button */}
                        { paginationCustom ? (
                            
                        <div style={{position: "absolute",top: "98.5%",left:"0.6%",width: "98.8%",height: "4%",border:"1px solid",borderColor: "var(--ag-border-color, #babfc7)",borderTop:"none"}}>
                            <div id={"prev"} className={"prevButton"} style={{ top: "14%", left: "93%"}}
                                title={"prev"}
                                onClick={onPrevnClicked}>
                                <NavigateBeforeIcon/>
                            </div>

                            <div id={"next"} className={"nextButton"} style={{ top: "14%", left: "97%"}}
                                title={"next"}
                                onClick={onNextClicked}>
                                <NavigateNextIcon/>
                            </div>
                        </div>
                            
                        ) : (
                            ""
                        )}

                    </div>
                </div>
            </div>
        </>
    );
});
// )

// export default Grid;
export default React.memo(agGrid);





/**
 * Grid Util
 * @param {*} props 
 * @returns 
 */

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;
const KEY_ENTER = 13;
const KEY_TAB = 9;

const MaxEditor = forwardRef((props, ref) => {
    let maxLength = props.maxLength ? props.maxLength : 200;

    const createInitialState = () => {
        let startValue;
        let highlightAllOnFocus = true;

        if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
            // if backspace or delete pressed, we clear the cell
            startValue = '';
        } else if (props.charPress) {
            // if a letter was pressed, we start with the letter
            startValue = props.charPress;
            highlightAllOnFocus = false;
        } else {
            // otherwise we start with the current value
            startValue = props.value;
            if (props.keyPress === KEY_F2) {
                highlightAllOnFocus = false;
            }
        }

        return {
            value: startValue,
            highlightAllOnFocus
        };
    };

    const initialState = createInitialState();

    const [value, setValue] = useState(initialState.value);
    const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(initialState.highlightAllOnFocus);
    const refInput = useRef(null);

    const cancelBeforeStart = props.charPress && ('1234567890'.indexOf(props.charPress) < 0);

    const isLeftOrRight = event => {
        return [37, 39].indexOf(event.keyCode) > -1;
    };

    const deleteOrBackspace = event => {
        return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;
    };

    const onKeyDown = event => {
        if (isLeftOrRight(event) || deleteOrBackspace(event)) {
            event.stopPropagation();
            return;
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [onKeyDown]);

    useImperativeHandle(ref, () => {
        return {
            afterGuiAttached() {
                // get ref from React component
                const eInput = refInput.current;
                eInput.focus();
                if (highlightAllOnFocus) {
                    eInput.select();

                    setHighlightAllOnFocus(false);
                } else {
                    // when we started editing, we want the carot at the end, not the start.
                    // comes into play in two scenarios: a) when user hits F2 and b)
                    // when user hits a printable character, then on IE (and only IE) the carot
                    // was placed after the first character, thus 'apply' would end up as 'pplea'
                    const length = eInput.value ? eInput.value.length : 0;
                    if (length > 0) {
                        eInput.setSelectionRange(length, length);
                    }
                }
            },

            getValue() {
                return value;
            },

            isCancelBeforeStart() {
                return cancelBeforeStart;
            },

            // will reject the number if it greater than 1,000,000
            // not very practical, but demonstrates the method.
            isCancelAfterEnd() {
                //return value > 1000000;
            }
        };
    });

    return (
        <input ref={refInput}
               value={value}
               maxLength={maxLength}
               onChange={event => setValue(event.target.value) }
               style={{width: "100%"}}
               className={"ag-text-al"}
        />
    );
});

const NumericEditor = forwardRef((props, ref) => {
    let maxLength = props.maxLength ? props.maxLength : 200;
    const createInitialState = () => {
        let startValue;
        let highlightAllOnFocus = true;

        if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
            // if backspace or delete pressed, we clear the cell
            startValue = '';
        } else if (props.charPress) {
            // if a letter was pressed, we start with the letter
            startValue = props.charPress;
            highlightAllOnFocus = false;
        } else {
            // otherwise we start with the current value
            startValue = props.value;
            if (props.keyPress === KEY_F2) {
                highlightAllOnFocus = false;
            }
        }

        return {
            value: startValue,
            highlightAllOnFocus
        };
    };

    const initialState = createInitialState();

    const [value, setValue] = useState(initialState.value);
    const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(initialState.highlightAllOnFocus);
    const refInput = useRef(null);

    const cancelBeforeStart = props.charPress && ('1234567890'.indexOf(props.charPress) < 0);

    const isLeftOrRight = event => {
        return [37, 39].indexOf(event.keyCode) > -1;
    };

    const getCharCodeFromEvent = event => {
        event = event || window.event;
        return (typeof event.which === "undefined") ? event.keyCode : event.which;
    };

    const isCharNumeric = charStr => {
        return !!/\d/.test(charStr);
    };

    const isKeyPressedNumeric = event => {
        const charCode = getCharCodeFromEvent(event);
        const charStr = event.key ? event.key : String.fromCharCode(charCode);
        return isCharNumeric(charStr);
    };

    const deleteOrBackspace = event => {
        return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;
    };

    const finishedEditingPressed = event => {
        const charCode = getCharCodeFromEvent(event);
        return charCode === KEY_ENTER || charCode === KEY_TAB;
    };
    const onKeyDown = event => {
        if (isLeftOrRight(event) || deleteOrBackspace(event)) {
            event.stopPropagation();
            return;
        }

        if (!finishedEditingPressed(event) && !isKeyPressedNumeric(event)) {
            if (event.preventDefault) event.preventDefault();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [onKeyDown]);

    useImperativeHandle(ref, () => {
        return {
            afterGuiAttached() {
                // get ref from React component
                const eInput = refInput.current;
                eInput.focus();
                if (highlightAllOnFocus) {
                    eInput.select();

                    setHighlightAllOnFocus(false);
                } else {
                    // when we started editing, we want the carot at the end, not the start.
                    // comes into play in two scenarios: a) when user hits F2 and b)
                    // when user hits a printable character, then on IE (and only IE) the carot
                    // was placed after the first character, thus 'apply' would end up as 'pplea'
                    const length = eInput.value ? eInput.value.length : 0;
                    if (length > 0) {
                        eInput.setSelectionRange(length, length);
                    }
                }
            },

            getValue() {
                return value;
            },

            isCancelBeforeStart() {
                return cancelBeforeStart;
            },

            // will reject the number if it greater than 1,000,000
            // not very practical, but demonstrates the method.
            isCancelAfterEnd() {
                //return value > 1000000;
            }
        };
    });

    return (
        <input ref={refInput}
            //    value={!value === false ?value.substring(0, maxLength) : value  }
               value={value}
               maxLength={maxLength}
               onChange={event => setValue(event.target.value) }
               style={{width: "100%"}}
               className={"ag-text-ar"}
        />
    );
});


//Check Box
export const AgGridCheckbox = (props) => {
    const boolValue = props.value && props.value.toString() === 'Y';
    const [isChecked, setIsChecked] = useState(boolValue);
    const onChanged = () => {
        props.setValue(isChecked ? 'N' : 'Y');
        setIsChecked(!isChecked);
    };
    return (
      <div>
        <input type="checkbox" checked={isChecked} onChange={onChanged} />
      </div>
    );
 };

 //Password Box
export const AgGridPassword = (props) => {
    const value = props.value;
    
    return (
      <div>
        <input type="password" onChange={event => props.setValue(event.target.value) } value={value}/>
      </div>
    );
 };
 

 // QnA Re: 답글 표출 기능
 export const AgGridReply = (props) => {
     const value = props.value;
     let gubn = "";
     let checkLen = value.indexOf("Re:");
     if(checkLen > -1){
        gubn = "Y";
        // gubn = true;
     }else{
        gubn = "N";
        // gubn = false;
     }
     
     return (
       <div style={{whiteSpace: "pre"}}>
           {gubn==="Y"?
                <>
                {value.substr(0,checkLen)}
                <img src="/images/rew.gif" alt="답글" title="답글" style={{paddingTop:"5px", paddingRight:"5px"}} />
                {value.substr(checkLen)}
                </>
            :value}
       </div>
     );
  };

