/**
 * @Page ROM82
 * @Description [ROM82]납입지시 - 시작차 출하관리 - 발행취소/재인쇄 (시작차)
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import GridRibbon from "@/components/organisms/GridRibbon";
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import * as GridUtil from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
 import ReactToPrint from "react-to-print";
 import WrapStartCardPrint from "@/components/molecules/WrapStartCardPrint";
  
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();
 
 // Print Style CSS Setting
 const pageStyle = `
    @media all {
        .pagebreak {
            display: none;
        }
    }
   
    @media print {
        html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
        }
    }
   
   @media print {
        .pagebreak {
            margin-top:1rem;
            display: block;
            page-break-after: always;
        }
   }
   
   @page {
        size: A4 portrait !important;
        margin:7mm 10mm;
   }
   body, div, table, td{
        font-family:굴림체,Gulim ;
   }
   
 `;

 
 

 function ROM82() {
 
    const token = Common.getToken();
    const userId = Common.getToken("id");
    // const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const bigi = Common.getToken("bigi");
    const grdRom82Main = useRef();      //Grid Main
    const grdRom82Detail = useRef();    //Grid Detail
    const printRef = useRef();
    const printBtnRef = useRef();

    const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 

    
    //Data
    const [idata, setData] = useState({
        //조회조건
        // kVnt1 : tVnt1,      //T1업체
        kVnt1 : ckVnt1,        //T1업체     
        kComp: 'EHKR',       //회사구분
        kNpdt: Common.getToday(), // 발행일자 :: Default 값 셋팅       
        kPlnt: "",          //공장
        kDlno : "",         //납품서번호
        kGubn: "2",         // 조회구분(발행취소/재인쇄) :: Default 값 셋팅       
        kGubn2: "N",        // 조회구분(백지카드) :: Default 값 셋팅                    

        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장

    }); 
    
            
    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;
  
  
    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      // Dialog Transaction(I,U,D)
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
  
    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false);    
    let [deleteApi, setDeleteApi] = useState(false);    // deleteApi 항목 setting
    let [searchApi2, setSearchApi2] = useState(false);  
    let [printdata, setPrintData] = useState([]);       
    let [clearGrid2, setClearGrid2] = useState(false);  

    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 100px  - 50px)"});

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
        setDeleteApi(false);
    }, []);

    const onClearApi2 = useCallback(async (e) => {
        setSearchApi2(false);
    }, []);



    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }
     

    
    /**
     *MOUNT 
    */
    useEffect( () => {
        
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
        //공통코드 조회 (공장)
        onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");

        //조회
        onSearch();
    }, []);
  
  
  
    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value} = e.target;

        let newSearchs = idata; 
        newSearchs[name] = value
        setData({ ...newSearchs });


        //T1업체, 회사구분 변경시 
        if (name === "kVnt1" || name === "kComp")  {
            
            newSearchs["plntList"] = [];
            newSearchs["gateList"] = [];
            newSearchs["kPlnt"] = "";
            newSearchs["kGate"] = "";
            setData({ ...newSearchs });

            //공통코드 조회 (공장)
            onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");

        }

        //납품서 발행 조회
        onSearch();
    }
 
  
  
    /**
     * Grid Event 
     * @param {*} e 
    */
    //Main Grid CellValueChanged
    const grdRom82Main_onCellValueChanged = async (e) => {
    }


    //Main Grid Row Click
    const grdRom82Main_onRowClicked = async (e) => {

        let data = e.data;

        //Detail Grid 조회
        onSearch2(data.dlno, data.prgb);
    }

    //Detail Grid CellValueChanged
    const grdRom82Detail_onCellValueChanged = async (e) => {
    }

    //Detail Grid Row Click
    const grdRom82Detail_onRowClicked = async (e) => {

    }

  
  
    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //T1업체
            {
                "formatter": "select", "name": "kVnt1", "labelName": "T1업체", "type": "text", "defaultValue": idata.kVnt1,  
                "codeMode": true,
                "selectAll": "",
                "codes": null,
                "comms": idata.vnt1List,
                "align": "left", 
            }, 
            //회사구분
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": true,
                "selectAll": "",
                //"codes": CODES["comp"],
                "comms": null,
                "align": "left", 
                "items": [{ "comCode": "EHKR", "text": "남양시작차" }]
            }, 
            //공장
            {
                "formatter": "select", "name": "kPlnt", "labelName": "공장", "type": "text",  
                "codeMode": false,
                "selectAll": "all", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                "codes": null,
                "comms": idata.plntList,
                "align": "left", 
            },
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" }
            
        ],
        1: [
            //발행일자
            {
                "formatter": "input", "name": "kNpdt", "labelName": "발행일자", "type": "date", "desc": "", "readOnly" : false,
                "defaultValue": idata.kNpdt,  
                "align": "left", 
                "labelType": "none" 
            }, 
             //납품서번호
             {
                "formatter": "input", "name": "kDlno", "labelName": "납품서번호", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kDlno,  
                "align": "left", 
                "labelType": "none", 
            }, 
            //조회구분 ( 발행취소, 재인쇄 )     
            {
                "formatter": "radio", "name": "kGubn", "labelName": "조회구분", "defaultValue": idata.kGubn
                , "options": [{ "name": "kGubn1", "labelName": "발행취소", "setValue": "1" }, { "name": "kGubn2", "labelName": "재인쇄", "setValue": "2" }] 
                // , "defaultValue": kPldt  
            }, 
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" }
        ],
    }
  
  
  
    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {
        
        setClearGrid2(true); // Detail Grid 초기화
        
        //T1업체선택 Check
        if (idata.kVnt1 == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "1차업체를 선택하십시요 !"});
            document.getElementById("kVnt1").focus();                         
            return ;
        }

        //회사구분선택 Check
        if (idata.kComp == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "회사구분을 선택하십시요 !"});
            document.getElementById("kComp").focus();                         
            return ;
        }

        //공장선택 Check
        if (idata.kPlnt == null)
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "공장을 선택하십시요 !"});
            document.getElementById("kPlnt").focus();                         
            return ;
        }


        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);

        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                     "kNpdt":"${idata.kNpdt}","kDlno":"${idata.kDlno}", "kGubn":"${idata.kGubn}",
                     "kGubn2":"${idata.kGubn2}" }`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/rom82/search?query=" + param;
        setSearchApi(query); // Search API
    }

  
  
    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
    }

  
      
    /**
     * 입력
     * @param {*} e 
     */
    const onInsert = async (e) => {
    }
  
  
  
    /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {
    }


  
    /**
     * 발행취소
     * @param {*} e 
     */
    const onDelete = async (e) => {
        //select nodes
        let selectedData = grdRom82Main.current.getSelectedData();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "발행취소할 자료가 없습니다."});
            return false;
        }

        //발행취소 확인 메세지 
        setDlgMessage({openDlg : true,  
                       dlgTrans : "D", 
                       dlgMsg : "선택한 데이터를 발행취소 하시겠습니까?" });

    }


    /**
     * 납품서 발행 재인쇄
     * @param {*} e 
     * @returns 
     */
     const onPrint = async (e) => {
        
        //select nodes
        let selectedData = grdRom82Main.current.getSelectedData();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "선택한 데이터가 없습니다."});
            return false;
        }

        const fetchData = async () => {

            let selectDlnoList = [];
            selectedData.map(function (data, i) {

                // selectedData2.forEach(function (data, i) {
                if (data.dlno) {
                    selectDlnoList.push(data.dlno);
                }
            });

            const query = SERVER_URL + "/rom81/print"; 
            let param = JSON.stringify({
                rprint: 'Y',
                dlnolist: selectDlnoList
            });
            await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) {

                        setPrintData(data.data);

                        // 성공 메시지 처리 필요
                        setMessage({ openAlert : true, 
                                     severity : "success",
                                     msg : "인쇄 조회 성공 하였습니다."});
                        //print 
                        printBtnRef.current.dispatchEvent(new Event("click", { bubbles: true }));
                    }

                    if (data.error) {
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : "조회 실패"});
                    }
                })
                .catch((error) => {
                    // 에러 응답 처리
                    if (error) {
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                    }
                });
        };
        fetchData();
    }



    /**
     * 
     * @param {*} dlno 납품서번호
     * @param {*} prgb 인쇄여부
     */
    const onSearch2= async (dlno, prgb) => {
        
        let para = `{"kDlno":"${dlno}"}`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/rom82/search2?query=" + param;
        setSearchApi2(query); // Search API
    }


    
    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans) 
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D" :
                const queryD  = SERVER_URL + "/rom82/delete";
                setDeleteApi(queryD); // Update API
                break;
        }
    }
      
    

    /**
     * Grid Bind Data
     * @returns 
    */
    const onGridBindData = (para) => {
        
    }
 
     
  
    /**
     *Grid Column 정의 
    */
    //Main Grid Column
    const columnDefs = [
        { headerName: "업체코드", field: "vand", sortable: true, filter: true, flex: 1, minWidth: 140, hide: true },     //hidden 컬럼
        { headerName: "회사구분", field: "comp", sortable: true, filter: true, flex: 1, minWidth: 140, hide: true },     //hidden 컬럼
        { headerName: "vnt1", field: "vnt1", sortable: true, filter: true, flex: 1, minWidth: 140, hide: true },       //hidden 컬럼 (발행일시)
        { headerName: "prtm", field: "prtm", sortable: true, filter: true, flex: 1, minWidth: 140, hide: true },        //hidden 컬럼 (발행시간)
        { headerName: "소요일자", field: "pldt", sortable: true, filter: true, flex: 1, minWidth: 380, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true },
        { headerName: "공장", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, 
          checkboxSelection : params=> (params.data.chul === 'Y' && '1' === params.data.gubn) ? false : true, hide: false},
        { headerName: "납품서번호", field: "dlno", sortable: true, filter: true, flex: 1, minWidth: 380, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
        { headerName: "발행일시", field: "npdt", sortable: true, filter: true, flex: 1, minWidth: 380, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "담당", field: "crdx", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "차량번호", field: "cano", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
        { headerName: "품목건수", field: "pcnt", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
        { headerName: "인쇄", field: "prgb", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
    ];
    //Detail Grid Column
    const columnDefs1 = [
        { headerName: "vnt1", field: "vnt1", sortable: true, filter: true, flex: 1, minWidth: 380, hide: true },    // hidden 컬럼
        { headerName: "comp", field: "comp", sortable: true, filter: true, flex: 1, minWidth: 380, hide: true },    // hidden 컬럼
        { headerName: "plnt", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 140, hide: true },    // hidden 컬럼
        { headerName: "dlno", field: "dlno", sortable: true, filter: true, flex: 1, minWidth: 140, hide: true },  // hidden 컬럼
        { headerName: "prgb", field: "prgb", sortable: true, filter: true, flex: 1, minWidth: 140, hide: true },   // hidden 컬럼

        { headerName: "순번", field: "dseq", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 380, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
        { headerName: "발주번호", field: "odno", sortable: true, filter: true, flex: 1, minWidth: 380, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "발주SEQ", field: "odsq", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "납기일자", field: "nidt", sortable: true, filter: true, flex: 1, minWidth: 200, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,valueFormatter: Utils.dateFormatter, hide: false },
        { headerName: "납품량", field: "npqt", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
        { headerName: "잔량", field: "jaqt", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false }
    ];
   
  
    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 100px  - 50px)"
              });
        }
    }

    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={5000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onSearch={onSearch} onSearchName={null}         //조회
                onDelete={onDelete} onDeleteName={"발행취소"}   onDeleteVisible = {idata.kGubn === "1"} //발행취소일 때만 사용
                onPrint={onPrint} onPrintName={"재인쇄"}        onPrintVisible = {idata.kGubn === "2"}  //재인쇄일 때만 사용
            >
                {"납입지시 > 시작차 출하관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>발행취소/재인쇄</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick={onClick} />


            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:146px */}
                <div className={"grid_type01"} style={{ height: "100%" }}> {/* 상단 FRAME */}
                    <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                        <div className="grid_cont_box" style={{ height: "100%" }}>
                            <Grid
                                ref={grdRom82Main}
                                gridId={"ROM82"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                pagination={false}                  //  쪽수 매기기 여부
                                paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={true}      // cell selection drag mode
                                isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                deleteApi={deleteApi}               // 삭제
                                deleteRefresh={onSearch}            // 삭제이후 
                                onClearApi={onClearApi}             // 초기화
                                onCellValueChangedEvent={grdRom82Main_onCellValueChanged}
                                onRowClickEvent={grdRom82Main_onRowClicked}  
                            />
                        </div>
                    </div>
                </div>

                <div className="grid_type04" style={{ height: "50%" }} >{/* 하단 FRAME */}
                    <div className="grid_wrap04"> {/* 하단 wrap 좌측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <div className="grid_cont_box" style={{ height: "100%" }}>

                                {/* 납품서 발행현황 Grid */}
                                <Grid
                                    ref={grdRom82Detail}
                                    gridId={"ROM822"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs1}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"single"}             //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    searchApi={searchApi2}              // 조회
                                    onClearApi={onClearApi2}            // Clear
                                    clearGrid={clearGrid2}
                                    onClearGridFlag={setClearGrid2}
                                    onRowClickEvent={grdRom82Detail_onRowClicked}     
                                    onCellValueChangedEvent={grdRom82Detail_onCellValueChanged}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <WrapStartCardPrint ref={printRef} printData={printdata} />    
                </div>
            </Content>

            <div className="printArea">
                <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef} style={{ display: "none" }} />} pageStyle={pageStyle} content={() => printRef.current} />
                <WrapStartCardPrint ref={printRef} printData={printdata} />
            </div>
        </>
    )
  }
  export default ROM82;