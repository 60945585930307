/**
 * @Page ROM4X
 * @Description [ROM4X]납입지시 - ERP_CKD출하관리 - 부품식별표(중포장 2D)
 **/
import React, { useState, useEffect, useRef, useCallback  } from "react";
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import AlertDialogCustom from '@/components/material-UI/AlertDialogCustom';
import * as Common from '@/utils/Common'; 
import CommonUtil from '@/utils/CommonUtil'; 
import ReactToPrint from "react-to-print";

import WrapCkdLabelPrintPercent from "@/components/molecules/WrapCkdLabelPrintPercent";
import WrapCkdLabelPrintPercentB from "@/components/molecules/WrapCkdLabelPrintPercentB";
import WrapCkdLabelPrintC from "@/components/molecules/WrapCkdLabelPrintC";

import WrapCkdLabelPrintPercentQR from "@/components/molecules/WrapCkdLabelPrintPercentQR"; 
import WrapCkdLabelPrintPercentQRB from "@/components/molecules/WrapCkdLabelPrintPercentQRB"; 

// 신규 인쇄소스
import WrapCkdLabelPrint_A_NEW from "@/components/molecules/WrapCkdLabelPrint_A_NEW"; 
import WrapCkdLabelPrint_B_NEW from "@/components/molecules/WrapCkdLabelPrint_B_NEW"
import WrapCkdLabelPrint_C_NEW from "@/components/molecules/WrapCkdLabelPrint_C_NEW"; 

import WrapCkdLabelPrint_QR_A_NEW from "@/components/molecules/WrapCkdLabelPrint_QR_A_NEW"; 
import WrapCkdLabelPrint_QR_B_NEW from "@/components/molecules/WrapCkdLabelPrint_QR_B_NEW"; 
 
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const Utils = new CommonUtil();


// Print Style CSS Setting
const pageStyle = `
@media all {
   .pagebreak {
     display: none;
   }
 }
 
 @media print {
   html, body {
     height: initial !important;
     overflow: initial !important;
     -webkit-print-color-adjust: exact;
   }
 }
 
 @media print {
   .pagebreak {
     margin-top:1rem;
     display: block;
     page-break-after: always;
   }
 }
 
 @page {
   size: A4;
   margin:7mm 10mm;
 }
 body, div, table, td{
     font-family:굴림체,Gulim ;
 }

`;

const pageStyle2 = `

@page{size: A4 landscape;  margin: 0;  }  // 20231020오후 최종

body, div, table, td{
    font-family:굴림체,Gulim ;

}
@media print {

    html, body {
         height: initial !important;
         overflow: initial !important;
        //-webkit-print-color-adjust: exact; // 프린트 컬러 옵션
        }

.page-break_5 { page-break-inside:avoid; page-break-after:always }
.page-break { page-break-inside:avoid; page-break-after:always }
}
`;

function ROM4X() {

   const token = Common.getToken();
   const userId = Common.getToken("id");
   // const userName = Common.getToken("name");
   const tVnt1 = Common.getToken("vand");
   const tComp = Common.getToken("comp");
   const bigi3 = Common.getToken("bigi");
   let bigi2 ="";
   const grdRom4xMain = useRef();   //Grid Main
   const printRef = useRef();
   const printBtnRef = useRef();
   const printRef2 = useRef();
   const printBtnRef2 = useRef();
   const printRef3 = useRef();
   const printBtnRef3 = useRef();
   
   const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 

   
   //Data
    const [idata, setData] = useState({
        //조회조건 
        // kVnt1 : tVnt1,      //T1업체
        kVnt1 : ckVnt1,        //T1업체     
        kComp: tComp,       //회사구분
        kPlnt: "",          //공장
        kGate: "",          //하치장
        kNidt: Common.getToday(),   //납기일자
        chkNpdt : "Y",      //납품일자 CheckBox
        kNpdt: Common.getToday(),  //납품일자
        chkLtno : "Y",      //Lot No CheckBox
        kLtno : Common.getToday(),  //Lot No
        kCarc : "",         //차종
        kSeld:"",
        kPtno : "",         //품번
        kType : "",         //식별표타입
        kTypeNm : "",       //식별표타입
        kGubn : "N",         //식별표인쇄
        kDlgb:"N",          //삭제분
        chkMetal : "N",     //중금속확인필
        kNidtGubn:"B",
        kStart : "1",        //START 출력 시작 위치
        kCkdOem:"CKD",
        bigiGubn : "",
        kBarcode:"",
        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장
        gateList : [],  //하치장
        typeList : [],  //식별표타입
        kStck : "1",
      //  gubnList : [{ "comCode": "Y", "text": "인쇄" }, { "comCode": "N", "text": "미인쇄" }],  //식별표인쇄
    }); 

   
        
   
   const [message, setMessage] = React.useState( {
       openAlert : false,      
       severity : "success",   
       msg : ""                
   });
   const { openAlert, severity, msg } = message;
 
 
   //Dialog
   const [dlgMessage, setDlgMessage] = React.useState( {
       openDlg : false,    
       dlgTrans : "",      
       dlgMsg : ""         
   });
   const { openDlg, dlgTrans, dlgMsg } = dlgMessage;

   //Dialog Custom
   const [dlgMessage2, setDlgMessage2] = React.useState( {
    openDlg2 : false, 
    dlgTrans2 : "",  
    dlgMsg2 : ""  
    });

    const [dlgMessage3, setDlgMessage3] = React.useState( {
        openDlg3 : false, 
        dlgTrans3 : "",  
        dlgMsg3 : ""  
    });
    const { openDlg2, dlgTrans2, dlgMsg2 } = dlgMessage2;
    const { openDlg3, dlgTrans3, dlgMsg3 } = dlgMessage3;

   //Grid Search, Insert, Update, Delete Api
   let [bigi, setBigi] = React.useState("");
   let [searchApi, setSearchApi] = useState(false); 
   let [clearGrid, setClearGrid] = useState(false);  

   let [printdata, setPrintData] = useState([]);   
   let [printdata2, setPrintData2] = useState([]);   
   let [printdata3, setPrintData3] = useState([]);   
   const [gridApi, setGridApi] = useState(null);
  
   const onClearApi = useCallback(async (e) => {
       setSearchApi(false);
   }, []);

   let [barcode, setBarcode] = React.useState("");
//    let [stckCol, setStckColum] = React.useState(false);
   let [loading, setLoading] = useState(false); 
   const [panded, setPanded] = React.useState(true);
   const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 146px  - 50px)"});


   /**
    * 공통코드 조회 (조회조건)
    */
   const onSearchCommon = (para, name) => {
       let commonData = idata;

       const fetchData = async (para, name) => {
           let param = encodeURI(para);
           const query = SERVER_URL + "/comcd/search?query=" + param; 
           await fetch(query, { headers: { Authorization: token } })
               .then((resp) => resp.json())
               .then((data) => {
                   if (data.success) 
                   { 
                       commonData[name] = data.data
                       setData({...commonData});
                   }
               })
       }
       fetchData(para, name);
   }

   /**
    * 식별표 타입 조회
    * @param {*} para 
    * @param {*} name 
    */
   const onSearchCommon1 = (para, name) => {
       let commonData = idata;

       const fetchData1 = async (para, name) => {

           let param = encodeURI(para);
           const query = SERVER_URL + "/comcd/search1?query=" + param; 
           await fetch(query, { headers: { Authorization: token } })
               .then((resp) => resp.json())
               .then((data) => {
                   if (data.success) 
                   { 
                       // commonData["kType"] = data.data[0].comCode
                       // commonData["kTypeNm"] = data.data[0].text
                       commonData["kType"] = "";
                       commonData["kTypeNm"] = "";
                       commonData[name] = data.data;
                       setData({...commonData});
                   }
               })
       }
       fetchData1(para, name);
   }

   /**
    * bigi 조회
    * @param {*} para 
    * @param {*} name 
    */
    const onSearchCommon2 = (para, name) => {
       let commonData = idata;

       const fetchData1 = async (para, name) => {

           let param = encodeURI(para);
           const query = SERVER_URL + "/comcd/search1?query=" + param; 
           await fetch(query, { headers: { Authorization: token } })
               .then((resp) => resp.json())
               .then((data) => {
                   if (data.success) 
                   { 
                       //commonData[name] = data.data[0].text;
                       if(data.data[0].text==="Y"){
                           setBigi(data.data[0].text);
                           bigi2 = data.data[0].text;
                           commonData[name] = data.data[0].text;
                       }else{
                           setBigi(data.data[0].text);
                           bigi2 = data.data[0].text;                            
                           commonData[name] = data.data[0].text;
                       }
                       setData({...commonData});
                      //setBigi(data.data[0].text);
                   }

               })
       }
       fetchData1(para, name);
       
   }

   
   /**
    *MOUNT 
   */
   useEffect( () => {
       
       //공통코드 조회 (T1업체)
       onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
       //공통코드 조회 (공장)
       onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"CKD"}`, "plntList");
       //공통코드 조회 (식별표타입)
       onSearchCommon1(`{"pgmid":"CODE", "vand":"****", "gocd":"PTCD"}`, "typeList");
       //공통코드 조회 (BIGI)
       onSearchCommon2(`{"pgmid":"BIGI", "vand":"****", "gocd":"${userId}"}`, "bigiGubn");

       setTimeout(() =>alert(`   소포장 품목 출력 시 인쇄매수를 선택하여 인쇄할 수 있도록 \n   '인쇄매수' 탭이 추가되었습다.\n   이용에 참조바랍니다.\n\n   (부착면수 → '인쇄매수' 탭 변경)`) , 500);       
       
   }, []);
 
 
 
   /**
    * Control 변경 Event
    * @param {*} e 
    */
   const onChange = async (e) => {
       const { name, value} = e.target;

       let newSearchs = idata; 
       newSearchs[name] = value
       setData({ ...newSearchs });

       //T1업체, 회사구분 변경시 
       if (name === "kVnt1" || name === "kComp")  {
           
           newSearchs["plntList"] = [];
           newSearchs["gateList"] = [];
           newSearchs["kPlnt"] = "";
           //newSearchs["kGate"] = "";
           setData({ ...newSearchs });

           //공통코드 조회 (공장)
           onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"CKD"}`, "plntList");
       }
       else if  (name === "kGate" || name === "kGubn")  {
           onSearch(); //조회 Call
       }else if(name === "kBarcode"){
        if(idata.kBarcode === "Barcode"){
            setBarcode("Barcode");
        }else{
            setBarcode("QR");
        }
    }else if(name === "kPlnt"){
        let newData = idata; 
        newData["kStck"] = "1"; // 공장 선택시 디폴트 값 다시 셋팅진행한다.
        setData({ ...newData });
    }
    /* else if(name === "kPlnt" && value === "HK31"){
        setStckColum(true);
        alert(`   ※ 전주 CKD 공지사항\n\n   소포장 품목 출력 시 1장 or 2장 선택하여 인쇄할 수 있도록 \n   '인쇄매수' 탭이 추가되었습다.\n   이용에 참조바랍니다.\n\n   (부착면수 → '인쇄매수' 탭 변경)`);
    }else if(name === "kPlnt" && value !== "HK31"){
        setStckColum(false);
        let newData = idata; 
        newData["kStck"] = "1";
        setData({ ...newData });
        alert(`   ※ 울산/아산CKD 공지사항\n\n   현대자동차 CKD식별표 바코드 양식 변경에 따른  \n   병행기간 내 인쇄 매수가 지정되어 출력 됩니다. \n   (부착면수 수정 불가)\n   ① 1차원 바코드 → 2면 부착\n   ② QR바코드 → 1면 부착`);
    } */
   }

 
 
   /**
    * Grid Event 
    * @param {*} e 
    */
   const grdRom4xMain_onCellValueChanged = async (e) => {
       let result;

       /* if(e.column.colId === "stck"){
            if(parseInt(e.data.stck) > 1){
                setDlgMessage3({
                    openDlg3 : true,  
                    dlgTrans3 : "S", 
                    dlgMsg3 : 
                    "HMC 부착면수 2면 → 1면으로 변경"
                })
                setTimeout(() => setDlgMessage3({ openDlg3 : false}), 2000);
            } 
        } */

       //납품수량과 용기당수 계산을 하여 용기수 Setting
       if (e.column.colId === "npqt" || e.column.colId === "qypc") {
           if(e.data.npqt === "0" || e.data.npqt ===''){
               e.node.setDataValue('caseqty', 0);
               e.node.setSelected(false);
           }else{
               if (e.data.npqt !== "" || e.data.npqt !==null ) {
                   result = Math.ceil(parseInt(e.data.npqt) / parseInt(e.data.qypc));
                   if(result === Infinity){//용기당 수가 0일때 용기수 빈 값
                       result = '';    
                   }else{
                       result = result;
                   }
               }
               else {
                   result = 0;
               }
               e.node.setDataValue('caseqty', result);
           }
       }
       
   }
   const grdRom4xMain_onRowClicked = async (e) => {
   }

   //Cell Key Down
   const grdRom4xMain_onCellKeyDown = async (e) => {
       var focusedCell;
       var rowIndex;
       var colId;
       var rowData;
       var cellData;
       var rowNode2;
       if(e.event.code =="ArrowDown"){
           grdRom4xMain.current.SetFocus(e.node.rowIndex+ 1,  e.column.colId);
           focusedCell = gridApi.api.getFocusedCell();
           rowIndex = focusedCell.rowIndex;
           colId = focusedCell.column.getId();
           rowData = gridApi.api.getRowNode(rowIndex).data;
           cellData = rowData[colId];
           rowNode2=gridApi.api.getDisplayedRowAtIndex(e.node.rowIndex+1);
           gridApi.api.stopEditing(true);

           if(colId==="npqt"){
               cellData = rowData["plqt"];//오더수량
           }

           rowNode2.setDataValue(colId, cellData)

           gridApi.api.startEditingCell({
               rowIndex: e.node.rowIndex+1,
               colKey: colId // 납품수량
           });
       }else if (e.event.code =="NumpadEnter"||e.event.code =="Enter"){//NumpadEnter
           gridApi.api.tabToNextCell();
           focusedCell = gridApi.api.getFocusedCell();
           rowIndex = focusedCell.rowIndex;
           colId = focusedCell.column.getId();
           rowData = gridApi.api.getRowNode(rowIndex).data;
           cellData = rowData[colId];
           rowNode2=gridApi.api.getDisplayedRowAtIndex(e.node.rowIndex+1);
            var rowNode2;

                if(colId==="npqt"){
                    rowNode2 = gridApi.api.getDisplayedRowAtIndex(e.node.rowIndex+1)
                }else{
                    rowNode2 = gridApi.api.getDisplayedRowAtIndex(e.node.rowIndex)
                }
                
                if(colId==="npqt"){
                   gridApi.api.stopEditing(true);
                   rowNode2.setDataValue("npqt", rowNode2.data.plqt)//납품수량 
                   gridApi.api.startEditingCell({
                       rowIndex: e.node.rowIndex+1,
                       colKey: 'npqt' // 납품수량
                   });
               }else{
                   rowNode2.setDataValue(colId, cellData)
               }
               
      }
   }
   
 
 
   /**
    * 검색조건
    */
   const optionsX = {
       0: [
           //T1업체
           {
               "formatter": "select", "name": "kVnt1", "labelName": "T1업체", "type": "text", "defaultValue": idata.kVnt1,  
               "codeMode": true,
               "selectAll": "",
               "codes": null,
               "comms": idata.vnt1List,
               "align": "left", 
           }, 
           //회사구분
           {
               "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
               "codeMode": true,
               "selectAll": "",
               "codes": CODES["comp"],
               "comms": null,
               "align": "left", 
           }, 
           //오더번호
           {
               "formatter": "input", "name": "kSeld", "labelName": "오더번호", "type": "text", "desc": "", "readOnly" : false,
               "defaultValue": idata.kSeld,  
               "align": "left", 
               "labelType": "none", 
           }, 
           //납품일자chkNpdt
           {
               "formatter": "checkInput", //"name": "chkNpdt", "labelName": " ",
               "labelOptions": [{ "name": "chkNpdt", "labelName": "납품일자", "defaultValue": idata.chkNpdt }], 
               
               "name": "kNpdt", "type": "date", "desc": "", "readOnly" : false,
               "defaultValue": idata.kNpdt,  
               "align": "left", 
               
               "labelType": "insert1" 
           }, 
           {"formatter": "blank", "align": "right" },
       ],
       1: [
           //공장
           {
               "formatter": "select", "name": "kPlnt", "labelName": "공장", "type": "text", "defaultValue": idata.kPlnt,  
               "codeMode": false,
               "selectAll": "none",
               "codes": null,
               "comms": idata.plntList,
           }, 
           //납기일자nidt
           {
               "formatter": "date", "name": "kNidt", "name2": "kNidtGubn", "labelName": "납기일자"
               , "defaultValue": idata.kNidt 
               , "defaultValue2": idata.kNidtGubn  
               , "align": "right" // (우측 정렬)
               , "labelType": "none" 
//                ,"items": [{ "comCode": "Y", "text": "인쇄" }, { "comCode": "N", "text": "미인쇄" }]
           },
           //바코드 타입
          {
              "formatter": bigi3 !="Y"?"select":"blank", "name": "kBarcode", "labelName": "바코드 형식", "type": "text", "defaultValue": idata.kBarcode,//idata.kType,  
              "codeMode": false,
              "selectAll": "",
              "codes": null,
              "items": [{"comCode": "QR","text": "QR코드"},{"comCode": "Barcode","text": "바코드"}],   // Case 3 - items :: Hard Coding List
              "labelType": "insert1" 
          },
       
           //LOT NOchkLtno
           {
               "formatter": "checkInput", 
               "labelOptions": [{ "name": "chkLtno", "labelName": "LOT NO", "defaultValue": idata.chkLtno }], 
               
               "name": "kLtno", "type": "date", "desc": "", "readOnly" : false,
               "defaultValue": idata.kLtno,  
               "align": "left", 
               
               "labelType": "insert1" 
           }, 
           //Start
           {
               "formatter": "select", "name": "kStart", "labelName": "START", "type": "text", "defaultValue": idata.kStart,  
               "codeMode": false,
               "selectAll": "",
               
               "codes": idata.kType && idata.kType==="B"? CODES["startB"] : (idata.kType && idata.kType==="C"? CODES["startC"] : CODES["start"]),
               "comms": null,
               "align": "right", 
               
               "labelType": "insert1" 
           },
       ],
       2: [
            //품번
            {
               "formatter": "input", "name": "kPtno", "labelName": "품번", "type": "text", "desc": "", "readOnly" : false,
               "defaultValue": idata.kPtno,  
               "align": "left", 
               "labelType": "none", 
           }, 

           //식별표타입 + 삭제분        gubn      dlgb
           {
               "formatter": "selectCheck", "name": "kGubn", "labelName": "식별표인쇄", "type": "text", "defaultValue": idata.kGubn,  
               "codeMode": false,
               "selectAll": "", 
               // "codes": null,
               // "comms": idata.typeList,
               "items": [{"text": "전체","comCode": "*"},{"text": "인쇄","comCode": "Y"},{"text": "미인쇄","comCode": "N"}],   // Case 3 - items :: Hard Coding List
               
               "name2": "kDlgb", "labelName2": " ",
               "options2": [{ "name": "kDlgb", "labelName": "삭제분", "defaultValue": idata.kDlgb }] 
           },
           {
            "formatter":  "select", "name": "kStck", "labelName": "인쇄 매수", "type": "text", "defaultValue": idata.kStck,  
            "codeMode": false,  
            "selectAll": "",  
            "items": [
                {"text": "1장","comCode": "1"},{"text": "2장","comCode": "2"},{"text": "3장","comCode": "3"},
                {"text": "4장","comCode": "4"},{"text": "5장","comCode": "5"},{"text": "6장","comCode": "6"},
                {"text": "7장","comCode": "7"},{"text": "8장","comCode": "8"},{"text": "9장","comCode": "9"},
                {"text": "10장","comCode": "10"}
            ]
            },
           //중금속확인필  chkMetal
           {
               "formatter": "checkSpan", "name": "chkMetal", "labelName": " ",
               "options": [{ "name": "chkMetal", "labelName": "중금속 확인필", "defaultValue": idata.chkMetal }], 
               
               "labelType": "insert1" 
           },
           {
               "formatter": "select", "name": "kType", "labelName": "식별표타입", "type": "text", "defaultValue": idata.kType,  
               "codeMode": true,
               "selectAll": "none",
               "codes": null,
               "comms": idata.typeList,
               
               "labelType": "insert1" 
           }//식별표타입  type
       ],

   }
 
 
 
   /**
    * 조회 
    * @param {*} e 
    * @returns 
    */
   const onSearch = async (e) => {
       
       setClearGrid(true); // Detail Grid 초기화

       //T1업체선택 Check
       if (idata.kVnt1 === "")
       {
           setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "1차업체를 선택하십시요 !"});
           document.getElementById("kVnt1").focus();                         
           return ;
       }

       //회사구분선택 Check
       if (idata.kComp === "")
       {
           setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "회사구분을 선택하십시요 !"});
           document.getElementById("kComp").focus();                         
           return ;
       }

       //공장선택 Check
       if (idata.kPlnt === "")
       {
           setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "공장을 선택하십시요 !"});
           document.getElementById("kPlnt").focus();                         
           return ;
       }

       // Cookie 값 셋팅
       Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);

       let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                    "kType":"${idata.kType}","kPtno":"${idata.kPtno}","kNidt":"${idata.kNidt}","kNidtGubn":"${idata.kNidtGubn}","kSeld":"${idata.kSeld}",
                    "kGubn":"${idata.kGubn}","kDlgb":"${idata.kDlgb}"}`;
       let param = encodeURI(para);
       const query = SERVER_URL + "/rom4x/search?query=" + param;
       setSearchApi(query); // Search API
   }


   //deleteBlank
   function deleteBlank(argval){
       if (argval === null || argval === undefined) return "";

       for(var i=argval.length-1 ;i >= 0; i--){
           
           if(argval.charCodeAt(i) !== 12288 && argval.charCodeAt(i) !== 32 ) //charCodeAt(i)  
              return(argval.substring(0,i+1));
       }
       return(argval.length>0?argval.substring(0,0):"")
   }



   /**
    * 인쇄 
    * @param {*} e 
    * @returns 
    */
    const onPrint = async (e) => {
       
       let inputFlag = true;

       // 납품일자 선택 Check
       if (idata.chkNpdt === "Y") {
           if (idata.kNpdt === "") {
               setMessage({ openAlert : true, 
                           severity : "info",
                           msg : "납품일자를 선택하세요."});
               document.getElementById("kNpdt").focus();                         
               return ;
           }
       }
       
       // LOT NO 선택 Check
       if (idata.chkLtno === "Y") {
           if (idata.kLtno === "") {
               setMessage({ openAlert : true, 
                           severity : "info",
                           msg : "LOT NO를 선택하세요."});
               document.getElementById("kLtno").focus();                         
               return ;
           }
       }

      //식별표타입 선택 Check
      if (idata.kType === "")
      {
          setMessage({ openAlert : true, 
                       severity : "info",
                       msg : "부품식별표 TYPE을 선택하세요."});
          document.getElementById("kType").focus();                                                  
          return ;
      }

       //select nodes
       let selectedData = grdRom4xMain.current.getSelectedNodes();

       // 선택 값 체크
       if (selectedData.length === 0) {
           //Alert 창 OPEN
           setMessage({ openAlert : true, 
                       severity : "info",
                       msg : "선택한 데이터가 없습니다."});
           return;
       }

       //필수입력항목 Check
       selectedData.forEach((node) => {

           if (node.data.npqt === "" || node.data.npqt=== "0") {
               setMessage({ openAlert : true, 
                            severity : "info",
                            msg : "납품수량을 입력하여 주십시오."});
               inputFlag = false;    
               grdRom4xMain.current.SetFocus(node.rowIndex, "npqt");                             
               return ;
           }
           else if(parseInt(node.data.npqt) > parseInt(node.data.plqt))
           {
               setMessage({ openAlert : true, 
                   severity : "info",
                   msg : "납품수량은 오더수량보다 클 수 없습니다."});
               inputFlag = false;  
               grdRom4xMain.current.SetFocus(node.rowIndex, "npqt");     
               return;
           }
           else if (node.data.stck === "" || node.data.stck === "0") {
               setMessage({ openAlert : true, 
                            severity : "info",
                            msg : "부착면수를 입력하세요."});
               inputFlag = false;                                 
               grdRom4xMain.current.SetFocus(node.rowIndex, "stck");     
               return;
           }
           else if (node.data.qypc === "" || node.data.qypc === "0") {
               setMessage({ openAlert : true, 
                            severity : "info",
                            msg : "용기당수를 입력하세요."});
               inputFlag = false;                                 
               grdRom4xMain.current.SetFocus(node.rowIndex, "qypc");     
               return;
           }
       });

       if (!inputFlag) return;

       if (idata.kType === "A"){
           //출력 확인 메세지 
           setDlgMessage({openDlg : true,  
                       dlgTrans : "P", 
                       dlgMsg : "선택하신 부품 식별표는 [ "+ idata.kType + " 4매 ] 입니다. 부품식별지 용지를 확인 하십시오. 인쇄 하시겠습니까?" });
       }
       if (idata.kType === "B"){
           //출력 확인 메세지 
           setDlgMessage({openDlg : true,  
                          dlgTrans : "P", 
                          dlgMsg : "선택하신 부품 식별표는 [ "+ idata.kType + " 6매 ] 입니다. 부품식별지 용지를 확인 하십시오. 인쇄 하시겠습니까?" });
       }
       if (idata.kType === "C"){
           //출력 확인 메세지 
           setDlgMessage({openDlg : true,  
                          dlgTrans : "P", 
                          dlgMsg : "선택하신 부품 식별표는 [ "+ idata.kType + " 12매 ] 입니다. 부품식별지 용지를 확인 하십시오. 인쇄 하시겠습니까?" });
       }

      
   }
 
   const onPrint2 = async (printData) => {

       
       let ptdata = JSON.stringify(printData);
       let body = {
           "userCode":userId,
           "type":"ckd",
           "json":ptdata.replaceAll('"','\''),
           "state":"REQ"
       };
       let data = JSON.stringify(body);

       //await fetch('http://rpa.bigibot.com:9400/system/print', { method: "POST", headers: {"X-AppKey" : "to1adn6pq_sw","Content-Type":"application/json"}, body: data })
       const query = SERVER_URL + "/bigi/print"; 
       await fetch(query, { method: "POST", headers: {"Content-Type":"application/json"}, body: data })
       .then((resp) => resp.json())
       .then(data => {
        //    window.location.href = data.data;
        window.location.href = data.data.replaceAll("\"","");
                   setMessage({ openAlert : true, 
                       severity : "success",
                       msg : "BIGI를 확인해주세요."});
       })
       .catch(error => {
           if (error) {
               setMessage({ openAlert : true, 
                               severity : "error",
                               msg : "BIGI 프로그램을 재시작 후 다시 시도해주세요."});
           }
       });
              
   }


   /**
    * 신규 Row추가
    * @param {*} e 
    */
   const onAddRow = async (e) => {
   }

 
     
   /**
    * 입력
    * @param {*} e 
    */
   const onInsert = async (e) => {

   }
 
 
 
   /**
    * 수정 
    * @param {*} e 
    * @returns 
    */
   const onUpdate = async (e) => {
   }
 


   /**
    * 삭제
    * @param {*} e 
    */
   const onDelete = async (e) => {
   }

   const fetchDataCall = (gubn) => {
    let selectedData = grdRom4xMain.current.getSelectedData();

               const fetchData = async () => {
                   let selectDataList = {};
                   let selectPtnoList = [];
                   let selectPdnoList = [];
                   let selectedDataItems = [];
                   let printList = {};

                   selectedData.map(function (data, i) {
       
                       selectPtnoList.push(data.ptno);
                       selectPdnoList.push(data.pdno);
                       selectDataList = {vnt1 :idata.kVnt1, comp : idata.kComp, plnt : idata.kPlnt,pdno:data.pdno,
                           ptno:data.ptno, 
                           //stck:data.stck, 
                           stck: idata.kStck , // 부착면수는 상태값을 가지고만 처리한다.
                           qypc:data.qypc, npqt:data.npqt, caseqty : data.caseqty,odno:data.seld,
                           vnyn:data.vnyn==="" || data.vnyn==="N"?"N":"Y"} 
                       selectedDataItems.push(selectDataList)
                       
                   });

                   
                   const query = SERVER_URL + "/rom4x/print"; 
                    let param = JSON.stringify({
                        parmData : {vnt1 : idata.kVnt1,
                       comp : idata.kComp,
                       plnt : idata.kPlnt,
                       // ltno : idata.kLtno,
                       ltno : idata.chkLtno==="Y"?idata.kLtno:"N", // LOT NO 데이터에 빈값 던질 경우, backend 에러가... 안쓰이지만 "N" 값으로 대체
                       npdt : idata.chkNpdt==="Y"?idata.kNpdt:"N", // 납품일자 데이터에 빈값 던질 경우, backend 에러가... 안쓰이지만 "N" 값으로 대체
                       chkNpdt:idata.chkNpdt,
                       chkLtno : idata.chkLtno,
                       ptnoList: selectPtnoList,
                       pdnoList: selectPdnoList,
                       kCkdOem:"CKD",
                       selectedData: selectedDataItems,
                                    },
                    });

                   //인쇄데이터
                   let detailPrintInfo = [];
                   let detailPrintInfo2 = {};
                   let printcnt = 0;  
                   let resultQypc; 
                   let realPrintCnt; 
                   
                   //  await fetch(query, { headers: { Authorization: token } })
                   await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })
                       .then((resp) => resp.json())
                       .then((data) => {
                           if (data.success) {
       
                               // 성공 메시지 처리 필요
                               setMessage({ openAlert : true, 
                                            severity : "success",
                                            msg : "인쇄 조회 성공 하였습니다."});

                               if (idata.kType === "A"){
                                   setPrintData(data.data);
                               
                                   for(let i=0; i<data.data.length;i++){
                                       resultQypc = Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC); //용기수
                                       realPrintCnt = resultQypc*data.data[i].STCK;//용기수*부착면수 -> 실제 부품식별표 갯수
                                       printcnt = printcnt+ parseInt(realPrintCnt);
                                       detailPrintInfo2={
                                           area:data.data[i].TMP1,//지역
                                           vehicleType : data.data[i].TMP3,//차종
                                           itemNo:data.data[i].TMP5,//품번
                                           itemNm:data.data[i].TMP6,//품명
                                           quantity:parseInt(selectedData[i].npqt),//수량
                                           companyNm:data.data[i].TMP10,//회사명
                                           companyCd:data.data[i].TMP12,//회사코드
                                           containerNo:data.data[i].TMP17,//용기번호
                                           attached:data.data[i].STCK,//부착면수
                                           container:data.data[i].QYPC,//용기당수
                                           contents1:data.data[i].TMP25,//이중인쇄1
                                           xPos1:1.000*data.data[i].TMP26,//이중인쇄 x1
                                           yPos1:1.000*data.data[i].TMP27,//이중인쇄 y2
                                           color1:data.data[i].TMP28,//이중인쇄컬러1
                                           fontSize1:1.000*data.data[i].TMP29,//이중인쇄폰트사이즈1
                                           contents2:data.data[i].TMP30,//이중인쇄2
                                           xPos2:1.000*data.data[i].TMP31,//이중인쇄 x2
                                           yPos2:1.000*data.data[i].TMP32,//이중인쇄 y2
                                           color2:data.data[i].TMP33,//이중인쇄컬러2
                                           fontSize2:1.000*data.data[i].TMP34,//이중인쇄폰트사이즈2
                                           gw:1.000*data.data[i].TMP35,//G/W
                                           nw:1.000*data.data[i].TMP20,//G/W
                                           orderNo:data.data[i].TMP21,//Order
                                           fromOrderNo:data.data[i].TMP22,//fromOrder
                                           toOrderNo:data.data[i].TMP23,//toOrder
                                           isRHD:data.data[i].TMP36,//RHD
                                           prcd:data.data[i].TMP15,// tmp15 조립코드
                                           issueNo:" ",
                                           resultQypc : Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC), //용기수
                                           realPrintCnt : resultQypc*data.data[i].STCK,//용기수*부착면수 -> 실제 부품식별표 갯수
                                           nidt :idata.chkNpdt==="Y"?data.data[i].TMP9:" ",
                                           pdno : data.data[i].PDNO,
                                           location : data.data[i].TMP4
                                       };
                                       detailPrintInfo.push(detailPrintInfo2);
                                   }
                                   printList={
                                       type : 4,
                                       ckdGubn : "CKD",
                                       gubn : "Y",
                                       seq : data.data[0].SEQ-1,
                                       factoryCode : data.data[0].TMP2,//공장
                                       location : data.data[0].TMP4,//하치장
                                       isMetal : (idata.chkMetal ==="Y")?true:false,//중금속확인필
                                       lotNo : (idata.chkLtno==="Y")?Common.getLOT(data.data[0].TMP13):"",// LOT NO 날짜 가져오기  data.TMP13:"N";
                                       deliveryDate :idata.kNpdt,//납품일자
                                       start:parseInt(idata.kStart)-1,
                                       printcnt:printcnt,
                                       posx: data.data[0].POSX,
                                       posy: data.data[0].POSY,
                                       mode: (gubn === "QR" ? "QR" : "BAR"),
                                       ckdDetailPrintInfo:detailPrintInfo
                                   }
                                   if(data.data[0].BIGI==="Y"){
                                       setBigi("Y");
                                       bigi2 = "Y";
                                   }else{
                                       setBigi("N");
                                       bigi2 = "N";
                                   }

                                   if(bigi2==="Y"){
                                       onPrint2(printList);
                                   }else{
                                        if(gubn === "QR"){
                                            setBarcode("QR");
                                        }else if(gubn === "Barcode"){
                                            setBarcode("Barcode");
                                        }
                                       printBtnRef.current.dispatchEvent(new Event("click", { bubbles: true }));
                                   }
                               }
                               if (idata.kType === "B"){
                                   setPrintData2(data.data);

                                   for(let i=0; i<data.data.length;i++){
                                       resultQypc = Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC); //용기수
                                       realPrintCnt = resultQypc*data.data[i].STCK;//용기수*부착면수 -> 실제 부품식별표 갯수
                                       printcnt = printcnt+ parseInt(realPrintCnt);
                                       detailPrintInfo2={
                                           area:data.data[i].TMP1,//지역
                                           vehicleType : data.data[i].TMP3,//차종
                                           itemNo:data.data[i].TMP5,//품번
                                           itemNm:data.data[i].TMP6,//품명
                                           quantity:parseInt(selectedData[i].npqt),//수량
                                           companyNm:data.data[i].TMP10,//회사명
                                           companyCd:data.data[i].TMP12,//회사코드
                                           containerNo:data.data[i].TMP17,//용기번호
                                           attached:data.data[i].STCK,//부착면수
                                           container:data.data[i].QYPC,//용기당수
                                           contents1:data.data[i].TMP25,//이중인쇄1
                                           xPos1:1.000*data.data[i].TMP26,//이중인쇄 x1
                                           yPos1:1.000*data.data[i].TMP27,//이중인쇄 y2
                                           color1:data.data[i].TMP28,//이중인쇄컬러1
                                           fontSize1:1.000*data.data[i].TMP29,//이중인쇄폰트사이즈1
                                           contents2:data.data[i].TMP30,//이중인쇄2
                                           xPos2:1.000*data.data[i].TMP31,//이중인쇄 x2
                                           yPos2:1.000*data.data[i].TMP32,//이중인쇄 y2
                                           color2:data.data[i].TMP33,//이중인쇄컬러2
                                           fontSize2:1.000*data.data[i].TMP34,//이중인쇄폰트사이즈2
                                           gw:1.000*data.data[i].TMP35,//G/W
                                           nw:1.000*data.data[i].TMP20,//G/W
                                           orderNo:data.data[i].TMP21,//Order
                                           fromOrderNo:data.data[i].TMP22,//fromOrder
                                           toOrderNo:data.data[i].TMP23,//toOrder
                                           isRHD:data.data[i].TMP36,//RHD
                                           prcd:data.data[i].TMP15,// tmp15 조립코드
                                           issueNo:" ",
                                           resultQypc : Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC), //용기수
                                           realPrintCnt : resultQypc*data.data[i].STCK,//용기수*부착면수 -> 실제 부품식별표 갯수
                                           nidt :idata.chkNpdt==="Y"?data.data[i].TMP9:" ",
                                           pdno : data.data[i].PDNO,
                                           location : data.data[i].TMP4
                                       };
                                       detailPrintInfo.push(detailPrintInfo2);
                                   }
                                   printList={
                                       type : 6,
                                       ckdGubn : "CKD",
                                       gubn : "Y",
                                       seq : data.data[0].SEQ-1,
                                       factoryCode : data.data[0].TMP2,//공장
                                       location : data.data[0].TMP4,//하치장
                                       isMetal : (idata.chkMetal ==="Y")?true:false,//중금속확인필
                                       lotNo : (idata.chkLtno==="Y")?Common.getLOT(data.data[0].TMP13):"",// LOT NO 날짜 가져오기  data.TMP13:"N";
                                       deliveryDate : idata.kNpdt,//납품일자
                                       start:parseInt(idata.kStart)-1,
                                       printcnt:printcnt,
                                       posx: data.data[0].POSX,
                                       posy: data.data[0].POSY,
                                       mode: (gubn === "QR" ? "QR" : "BAR"),
                                       ckdDetailPrintInfo:detailPrintInfo
                                   }
                                   if(data.data[0].BIGI==="Y"){
                                       setBigi("Y");
                                       bigi2 = "Y";
                                   }else{
                                       setBigi("N");
                                       bigi2 = "N";
                                   }

                                   if(bigi2==="Y"){
                                       onPrint2(printList);
                                   }else{
                                        if(gubn === "QR"){
                                            setBarcode("QR");
                                        }else if(gubn === "Barcode"){
                                            setBarcode("Barcode");
                                        }
                                       printBtnRef2.current.dispatchEvent(new Event("click", { bubbles: true }));
                                   }
                               }                                
                               if (idata.kType === "C"){
                                   setPrintData3(data.data);

                                   for(let i=0; i<data.data.length;i++){
                                       resultQypc = Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC); //용기수
                                       realPrintCnt = resultQypc*data.data[i].STCK;//용기수*부착면수 -> 실제 부품식별표 갯수
                                       printcnt = printcnt+ parseInt(realPrintCnt);
                                       detailPrintInfo2={
                                           area:data.data[i].TMP1,//지역
                                           vehicleType : data.data[i].TMP3,//차종
                                           itemNo:data.data[i].TMP5,//품번
                                           itemNm:data.data[i].TMP6,//품명
                                           quantity:parseInt(selectedData[i].npqt),//수량
                                           companyNm:data.data[i].TMP10,//회사명
                                           companyCd:data.data[i].TMP12,//회사코드
                                           containerNo:data.data[i].TMP17,//용기번호
                                           attached:data.data[i].STCK,//부착면수
                                           container:data.data[i].QYPC,//용기당수
                                           contents1:data.data[i].TMP25,//이중인쇄1
                                           xPos1:1.000*data.data[i].TMP26,//이중인쇄 x1
                                           yPos1:1.000*data.data[i].TMP27,//이중인쇄 y2
                                           color1:data.data[i].TMP28,//이중인쇄컬러1
                                           fontSize1:1.000*data.data[i].TMP29,//이중인쇄폰트사이즈1
                                           contents2:data.data[i].TMP30,//이중인쇄2
                                           xPos2:1.000*data.data[i].TMP31,//이중인쇄 x2
                                           yPos2:1.000*data.data[i].TMP32,//이중인쇄 y2
                                           color2:data.data[i].TMP33,//이중인쇄컬러2
                                           fontSize2:1.000*data.data[i].TMP34,//이중인쇄폰트사이즈2
                                           gw:1.000*data.data[i].TMP35,//G/W
                                           nw:1.000*data.data[i].TMP20,//G/W
                                           orderNo:data.data[i].TMP21,//Order
                                           fromOrderNo:data.data[i].TMP22,//fromOrder
                                           toOrderNo:data.data[i].TMP23,//toOrder
                                           isRHD:data.data[i].TMP36,//RHD
                                           prcd:data.data[i].TMP15,// tmp15 조립코드
                                           issueNo:" ",
                                           resultQypc : Math.ceil(parseInt(selectedData[i].npqt) / data.data[i].QYPC), //용기수
                                           realPrintCnt : resultQypc*data.data[i].STCK,//용기수*부착면수 -> 실제 부품식별표 갯수
                                           nidt :idata.chkNpdt==="Y"?data.data[i].TMP9:" ",
                                           pdno : data.data[i].PDNO,
                                           location : data.data[i].TMP4
                                       };
                                       detailPrintInfo.push(detailPrintInfo2);
                                   }
                                   printList={
                                       type : 12,
                                       ckdGubn : "CKD",
                                       gubn : "Y",
                                       seq : data.data[0].SEQ-1,
                                       factoryCode : data.data[0].TMP2,//공장
                                       location : data.data[0].TMP4,//하치장
                                       isMetal : (idata.chkMetal ==="Y")?true:false,//중금속확인필
                                       lotNo : (idata.chkLtno==="Y")?Common.getLOT(data.data[0].TMP13):"",// LOT NO 날짜 가져오기  data.TMP13:"N";
                                       deliveryDate :idata.kNpdt,//납품일자
                                       start:parseInt(idata.kStart)-1,
                                       printcnt:printcnt,
                                       posx: data.data[0].POSX,
                                       posy: data.data[0].POSY,
                                       mode: (gubn === "QR" ? "QR" : "BAR"),
                                       ckdDetailPrintInfo:detailPrintInfo
                                   }
                                   if(data.data[0].BIGI==="Y"){
                                       setBigi("Y");
                                       bigi2 = "Y";
                                   }else{
                                       setBigi("N");
                                       bigi2 = "N";
                                   }

                                   if(bigi2==="Y"){
                                       onPrint2(printList);
                                   }else{
                                       printBtnRef3.current.dispatchEvent(new Event("click", { bubbles: true }));
                                   }
                               }
                               setTimeout(() => onSearch(), 300);
                           }
       
                           if (data.error) {
                               setMessage({ openAlert : true, 
                                            severity : "error",
                                            msg : "조회 실패"});
                           }
                       })
                       .catch((error) => {
                           // 에러 응답 처리
                           if (error) {
                               setMessage({ openAlert : true, 
                                            severity : "error",
                                            msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                           }
                       });
               };
       
               fetchData();

   }



   
   //Transaction AlertDialogResult
   const AlertDialogResult = async (e) => {

       //AlertDialog 초기화
       setDlgMessage({ ...dlgMessage, openDlg : false});
       setDlgMessage2({ ...dlgMessage2, openDlg2 : false});

       //DialogResult
       if (e.result == "N") return;

       //Transaction 구분
       switch (e.Trans) 
       {
           //등록
           case "I" :
               break;
           //수정
           case "U" :
               break;
           //출력
           case "P" :

           if(idata && !(idata.kType) === false && idata.kType === "C" ){ /* 식별표 타입이 C 12매 일경우 무조건 바코드 형식이므로, 팜업 표출 X */
            
            let newDataB = idata; 
            newDataB["kStck"] = idata["kStck"] ? idata["kStck"] :  "1";
            setData({ ...newDataB });

            fetchDataCall("Barcode");
             break;
            }

           setDlgMessage2({
            openDlg2 : true,  
            dlgTrans2 : "A", 
            dlgMsg2 : 
            "2023년 9월 1일부터 QR 코드 출력을 권장하며 <br /> 2023년 12월 1일부터 기존 바코드 출력이 불가합니다. <br />참조하시어 신규 양식지를 미리 구비해 놓으시길 바랍니다.<br /> <a  target='_blank' href='http://njmro.co.kr/' > <div style='display: flex;flex-direction: row;justify-content: center;' > <button  class='mui_b' style='width : 280px'> 신규 용지 구매하러 가기<img src='/images/arrow.png'></img>     </button> <div> </a> "
            });
           break;

          case "A" : /* 프린트 선택 여부에 따라 변경 */
          switch (e.key) 
              {
                  case "QR" :
                    let newDataQ = idata; 
                    newDataQ["kStck"] = idata["kStck"];
                    setData({ ...newDataQ });
                    fetchDataCall(e.key);

                  break;
                  case "Barcode" :
                    let newDataB = idata; 
                    newDataB["kStck"] = idata["kStck"];
                    setData({ ...newDataB });
                    fetchDataCall(e.key);
                  break;
              }
          break;

       }
   }
     
   
/**
    * 엑셀
    * @param {*} e 
    */
const onExcel = async (e) => {
   let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
               "kDlgb":"${idata.chkDlgb}","kNidtGubn":"${idata.kNidtGubn}","kNidt":"${idata.kNidt}",
               "kPtno":"${idata.kPtno}","kGubn":"${idata.kGubn}","kType":"${idata.kType}"}`;

   let param = encodeURIComponent(para);
   const query = SERVER_URL + "/rom4x/excel?query=" + param;

   setLoading(true);
   
   await fetch(query, {headers: {Authorization: token } })
       .then((resp) =>{
           return resp.blob();
       }).then((blob)=>{
           const href = window.URL.createObjectURL(blob);
           const link = document.createElement('a');
           link.href = href;
           link.setAttribute('download', 'rom4x.xls');
           document.body.appendChild(link);
           link.click();
           document.body.removeChild(link);
       }).catch((err) => {
           return Promise.reject({ Error: 'error', err });
       })

   setLoading(false);
   setMessage({ openAlert : true, 
                   severity : "success",
                   msg : "엑셀파일이 생성되었습니다."});
}

   /**
    * Grid Bind Data
    * @returns 
   */
   const onGridBindData = (para) => {
       // let param = encodeURI(para);
       // let items = [];

       // const query = SERVER_URL + "/comcd/search1?query=" + param; 

       // fetch(query, { headers: { Authorization: token } })
       //     .then((resp) => resp.json())
       //     .then((data) => {
       //         if (data.success) { 
       //             data.data.forEach((element) => {
       //                 items.push(element.comCode);    
       //             });
       //         }
       //     })
       // return items;
   }

   const editCellClicked = (params) => {
       // default Cell Click 이벤트 

       let gridApi = params.api;

       let rowIdx = params.rowIndex;

       let data = params.data;
       setGridApi(params);

       if(params.column.colId ==="npqt"){
           if ((data.npqt === "0" || data.npqt === "")) {

               gridApi.stopEditing(true); // Grid EditMode stop
   
               var rowNode = gridApi.getRowNode(rowIdx);
               var rowNode2 = gridApi.getDisplayedRowAtIndex(rowIdx); //정렬, 필터링... 하면 변경된 index가져옴
   
               //rowNode.setDataValue("npqt", data.plqt); // 발주수량 값 받아와서 납품수량npqt 셋팅 
               rowNode2.setDataValue("npqt", data.plqt); // 발주수량 값 받아와서 납품수량npqt 셋팅 
   
   
               // params.api.startEditingCell({
               gridApi.startEditingCell({
                   // rowIndex: params.rowIndex,
                   rowIndex: rowIdx,
                   colKey: 'npqt' // 납품수량npqt edit Mode
               }); // Grid EditMode start
   
   
           }
       }
       

   };
    
 
   /**
    *Grid Column 정의 
   */
   //Main Grid Column
   const columnDefs = [
       { headerName: "vnt1", field: "vnt1", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },
       { headerName: "comp", field: "comp", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },
       { headerName: "plnt", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },
       { headerName: "prtm", field: "prtm", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },
       { headerName: "pldt", field: "pldt", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },
       { headerName: "spgb", field: "spgb", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },
       { headerName: "vnyn", field: "vnyn", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },
       { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 200, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false
       ,cellStyle: params => {//cell배경색 변경
           if (params.data.spgb === "Y") {return {background: "rgb(255 207 255 / 52%)"};}return null;}},
       { headerName: "납기일자", field: "nidt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true,valueGetter: Utils.dateGetter , hide: false 
       ,cellStyle: params => {//cell글자색 변경
           if (params.data.spgb === "Y") {return {background: "rgb(255 207 255 / 52%)"};}return null;}},
       { headerName: "P/D No", field: "pdno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], cellEditor:"numericEditor", singleClickEdit: true, hide: false 
       ,cellStyle: params => {//cell글자색 변경
           if (params.data.spgb === "Y") {return {background: "rgb(255 207 255 / 52%)"};}return null;}}, 
       { headerName: "오더No", field: "seld", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], cellEditor:"numericEditor", singleClickEdit: true, hide: false,cellStyle: params => {
           if (params.data.spgb === "Y") {return {background: "rgb(255 207 255 / 52%)"};}return null;}}, 
       { headerName: "오더수량", field: "plqt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,cellStyle: params => {
           if (params.data.spgb === "Y") {return {background: "rgb(255 207 255 / 52%)"};}return null;}}, 
       { headerName: "납품수량", field: "npqt", sortable: true, filter: true, flex: 1, minWidth: 120, editable: true, onCellClicked: editCellClicked, headerClass: "editCell", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,cellStyle: params => {
           if (params.data.spgb === "Y") {return {background: "rgb(255 207 255 / 52%)"};}return null;}}, 
       { headerName: "용기당수", field: "qypc", sortable: true, filter: true, flex: 1, minWidth: 120, editable: true, onCellClicked: editCellClicked, headerClass: "editCell", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,cellStyle: params => {
           if(params.data.spgb === "Y" && params.data.vnyn === "Y"){return{background: "rgb(255 207 255 / 52%)",color:"rgb(243 0 0)",fontWeight:"bold"}}
           else if(params.data.spgb === "Y" && params.data.vnyn === "N"||params.data.spgb === "Y" && params.data.vnyn === ""){return{background: "rgb(255 207 255 / 52%)"}}
           else if(params.data.vnyn === "Y"){return{color:"rgb(243 0 0)",fontWeight:"bold"}}return null;}}, 
       { headerName: "용기수", field: "caseqty", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,cellStyle: params => {
           if (params.data.spgb === "Y") {return {background: "rgb(255 207 255 / 52%)"};}return null;}}, 
       { headerName: "부착면수", field: "stck", sortable: true, filter: true, flex: 1, minWidth: 120, editable: true, onCellClicked: editCellClicked, headerClass: "editCell", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: true,cellStyle: params => {
           if (params.data.spgb === "Y") {return {background: "rgb(255 207 255 / 52%)"};}return null;}}, 
       { headerName: "하치장", field: "ctlt", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,cellStyle: params => {
           if (params.data.spgb === "Y") {return {background: "rgb(255 207 255 / 52%)"};}return null;}}, 
       { headerName: "국가", field: "nanm", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false,cellStyle: params => {
           if (params.data.spgb === "Y") {return {background: "rgb(255 207 255 / 52%)"};}return null;}}, 
       { headerName: "CASE", field: "csno", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,cellStyle: params => {
           if (params.data.spgb === "Y") {return {background: "rgb(255 207 255 / 52%)"};}return null;}}, 
       { headerName: "용기등급", field: "okgb", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,cellStyle: params => {
           if (params.data.spgb === "Y") {return {background: "rgb(255 207 255 / 52%)"};}return null;}}, 
   ];

   const onClick = async (e) => {
       setPanded(!panded);


       if(panded===true){
           setStyle({       
               height :"calc(100vh - 60px - 50px - 50px)"
             });
       }else{
           setStyle({
               height :"calc(100vh - 60px - 50px - 146px  - 50px)"
             });
       }


   }
     
   return(
       <>
           {/* Dialog FRAME */}
           <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

           {/* Dialog Custom */}
           <AlertDialogCustom 
                open={openDlg2}  
                trans={dlgTrans2} 
                message={dlgMsg2} 
                onClose={AlertDialogResult} 
                width={1004} 
                height={695}
                contentImage={{ "imageInfo" : [{"path" : "/images/popup_info2.png"}]}}
                backColor={"#F0F0F0"}
                title={"인쇄방식 선택"}
                button={{ "buttonInfo" : [
                                    {"key" : "Barcode", "name" : `바코드형 인쇄(${idata.kStck}장)`, "className" : "mui_r", "width" : "197"},
                                    {"key" : "QR", "name" : `QR코드형 인쇄(${idata.kStck}장)` , "className" : "mui_b", "width" : "197"},
                                ],
                            "buttonMessage" : "인쇄용지를 확인하시고 인쇄방식을 선택해 주세요"
                        }}
                />

            <AlertDialogCustom 
                open={openDlg3}  
                trans={dlgTrans3} 
                message={dlgMsg3} 
                onClose={()=>setDlgMessage3({ ...dlgMessage3, openDlg3 : false})} 
                width={328} 
                height={55}
                backColor={"#F0F0F0"}
                title={""}
            />

           {/* Alert FRAME */}
           <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({...message, openAlert:false})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

           {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
           <Ribbon
               onInfoMsg={"그리드의 용기당수량의 빨강색표시는 <br/> 납입지시 > 기초관리 > ERP_품목관리화면의 사용자지정 용기당수량항목의 사용유무로 표출됩니다."}
               onSearch={onSearch} onSearchName={null}             //조회
               onPrint={onPrint} onPrintName={"인쇄"}
               onExcel={onExcel} onExcelName ={null}   //Excel
           >
               {"납입지시 > ERP_CKD 출하관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardMedium, NanumGothic, sans-serif" }}>부품식별표(중포장 2D)</b>
           </Ribbon>


           {/* ContentXearch Frame : 검색조건 */}
           <ContentXearch
               optionsX={optionsX}
               onChange={onChange}
               onClick = {onClick} />


           {/* Content FRAME (Data / Grid Frame) START -------------- */}
           <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:146px */}
               <div className={"grid_type01"} > {/* 상단 FRAME */}
                   <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                       <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                           <Grid
                               ref={grdRom4xMain}
                               gridId={"ROM4X"}                    //  (필수) Grid Id 정의
                               columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                               pagination={true}                   //  쪽수 매기기 여부
                               paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                               paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                               rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                               suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                               rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                               enableRangeSelection={false}        //  
                               enableCellTextSelection={true}      // cell selection drag mode
                               isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                               displayNo = {false}
                               searchApi={searchApi}               // 조회
                               onClearApi={onClearApi}             // 초기화
                               clearGrid={clearGrid}
                               onClearGridFlag={setClearGrid}
                               onCellValueChangedEvent={grdRom4xMain_onCellValueChanged}
                               onRowClickEvent={grdRom4xMain_onRowClicked}
                               onCellKeyDownEvent ={grdRom4xMain_onCellKeyDown}
                               // insertApi={insertApi}               // 등록
                               // insertParam={inserts}               // 등록 및 수정시 Parmeter
                               // insertRefresh={()=> {onSearch(); onSearch2();} } // 등록이후 
                               //updateApi={updateApi}               // 수정
                               //deleteApi={deleteApi}               // 삭제
                               //updateRefresh={onSearch}            // 수정이후 
                               //deleteRefresh={onSearch}            // 삭제이후 
                           />
                       </div>
                   </div>
               </div>

           </Content>
           

           {/* 프린트 */} 
           {bigi == "Y"  /* 비기사용여부 */
                ? <></>  
                    : Common.getToken('propt') === 'Y' /* 신규 인쇄 사용여부 */
                        ?
                            <>                              
                                {/* ==NEW== "A(4)" TYPE BARCODE / QRCODE */}
                                <div className="printArea">
                                    <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef} style={{ display: "none" }} />} pageStyle={pageStyle2} content={() => printRef.current} />
                                    { barcode==="Barcode" ? 
                                        <WrapCkdLabelPrint_A_NEW ref={printRef} printData={printdata} idata={idata} />
                                        :
                                        <WrapCkdLabelPrint_QR_A_NEW ref={printRef} printData={printdata} idata={idata} /> }
                                </div>

                                {/* ==NEW== "B(6)" TYPE BARCODE / QRCODE */}
                                <div className="printArea">
                                    <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef2} style={{ display: "none" }} />} pageStyle={pageStyle2} content={() => printRef2.current} />
                                    { barcode==="Barcode"?
                                        <WrapCkdLabelPrint_B_NEW ref={printRef2} printData={printdata2} idata={idata} />
                                        :
                                        <WrapCkdLabelPrint_QR_B_NEW ref={printRef2} printData={printdata2} idata={idata} /> }
                                </div>

                                {/* ==NEW== "C(12)" TYPE BARCODE */}
                                <div className="printArea">
                                    <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef3} style={{ display: "none" }} />} pageStyle={pageStyle2} content={() => printRef3.current} />
                                    <WrapCkdLabelPrint_C_NEW ref={printRef3} printData={printdata3} idata={idata} />
                                </div>
                                </>
                        :
                            <>
                                {/* --기존-- "A(4)" TYPE BARCODE / QRCODE */}
                                <div className="printArea">
                                    <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef} style={{ display: "none" }} />} pageStyle={pageStyle} content={() => printRef.current} />
                                    { barcode==="Barcode" ?
                                        <WrapCkdLabelPrintPercent ref={printRef} printData={printdata} idata={idata} />
                                        :
                                        <WrapCkdLabelPrintPercentQR ref={printRef} printData={printdata} idata={idata} /> }
                                </div>

                                {/* --기존-- "B(6)" TYPE BARCODE / QRCODE */}
                                <div className="printArea">
                                    <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef2} style={{ display: "none" }} />} pageStyle={pageStyle} content={() => printRef2.current} />
                                    { barcode==="Barcode"?
                                        <WrapCkdLabelPrintPercentB ref={printRef2} printData={printdata2} idata={idata} />
                                        :
                                        <WrapCkdLabelPrintPercentQRB ref={printRef2} printData={printdata2} idata={idata} />  }
                                </div>

                                {/* --기존-- "C(12)" TYPE BARCODE */}
                                <div className="printArea">
                                    <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef3} style={{ display: "none" }} />} pageStyle={pageStyle} content={() => printRef3.current} />
                                    <WrapCkdLabelPrintC ref={printRef3} printData={printdata3} idata={idata} />
                                </div>
                            </>
            }
       </>
   )
 }
 export default ROM4X;