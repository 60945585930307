/**
 * @Page RSY12
 * @Description [RSY12]시스템 관리 : 업체관리
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
 
 
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
 const Utils = new CommonUtil();
 
 function RSY12() {
     const token = Common.getToken();
     const userId = Common.getToken("id");
     const userName = Common.getToken("name");
     const tVnt1 = Common.getToken("vand");
     const tComp = Common.getToken("comp");
     const grdRsy12Main = useRef();   //Grid Main
 
 
     //Data
     const [idata, setData] = useState({
         //조회조건
         kWrcVand : (tVnt1 === "M000" ? "" : tVnt1),    //업체코드
         kWrcVandReadOnly : (tVnt1 !== "M000"),   //업체코드 로그인시 입력 불가능
         kWrcVdnm : ""    //업체명    
     }); 
 
 
      
     const [message, setMessage] = React.useState( {
         openAlert : false,      
         severity : "success",   
         msg : ""                
     });
     const { openAlert, severity, msg } = message;
 
 
     //Dialog
     const [dlgMessage, setDlgMessage] = React.useState( {
         openDlg : false,    
         dlgTrans : "",      
         dlgMsg : ""         
     });
     const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
 
 
     //Grid Search, Insert, Update, Delete Api
     let [searchApi, setSearchApi] = useState(false); 
     let [insertApi, setInsertApi] = useState(false); 
     let [updateApi, setUpdateApi] = useState(false); 
     let [deleteApi, setDeleteApi] = useState(false); // deleteApi 항목 setting
     let [loading, setLoading] = useState(false); 
     const [panded, setPanded] = React.useState(true);
     const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 50px)"});

     const onClearApi = useCallback(async (e) => {
         setSearchApi(false);
         setInsertApi(false);
         setUpdateApi(false);
         setDeleteApi(false);
     }, []);
     
     
 
     /**
      * 공통코드 조회
      */
     const onSearchCommon = (para) => {
 
         let commonData = idata;
 
         //그룹코드 조회
         let param = encodeURI(para);
         const query = SERVER_URL + "/comcd/search1?query=" + param; 
     }
 
 
 
     /**
      *MOUNT 
      */
     useEffect( () => {
         
         //공통코드 조회
         onSearchCommon(`{"pgmid":"CODE", "vand":"****", "gocd" : "00"}`);

         //조회
         setTimeout(() => onSearch(), 300);

     }, []);
 
 
 
     /**
      * Control 변경 Event
      * @param {*} e 
      */
     const onChange = async (e) => {
         const { name, value } = e.target;
 
         let newSearchs = idata;
         newSearchs[name] = value
 
         setData({ ...newSearchs });
 
         //조회 Call    
         onSearch(); 
 
         // //구분
         // if (name === "kWrcVand")  idata.kWrcVand = value;
         // //그룹코드    
         // else if (name === "kWrcGocd")  idata.kWrcGocd = value;
         // //코드1    
         // else if (name === "kWrcCod1")  idata.kWrcCod1 = value;
     }
 
 
 
     /**
      * Grid Event 
      * @param {*} e 
      */
     const onCellValueChanged = async (e) => {
     }
 
     const onRowClicked = async (e) => {
     }
 
 
 
     /**
      * 검색조건
      */
      const optionsX = {
         0: [
            {
                "formatter": "input", "name": "kWrcVand", "labelName": "업체코드", "type": "text", "desc": "", "readOnly" : idata.kWrcVandReadOnly
                , "defaultValue": idata.kWrcVand  
                , "align": "left" 
                , "labelType": "none" 
            }, //업체코드
            {
                "formatter": "input", "name": "kWrcVdnm", "labelName": "업체명", "type": "text", "desc": ""
                , "defaultValue": idata.kWrcVdnm  
                , "align": "left" 
                , "labelType": "none" 
            }, 
             //업체명
             {"formatter": "blank", "align": "left" },
             {"formatter": "blank", "align": "left" },
             {"formatter": "blank", "align": "left" },
             {"formatter": "blank", "align": "left" }
         ]
     }
 
 
 
     /**
      * 조회 
      * @param {*} e 
      * @returns 
      */
     const onSearch = async (e) => {
         
         let para = `{"kWrcVand":"${idata.kWrcVand}","kWrcVdnm":"${idata.kWrcVdnm}"}`;
         let param = encodeURI(para);
 
         const query = SERVER_URL + "/rsy12/search?query=" + param;
 
         setSearchApi(query);
     }
 
 
 
     /**
      * 신규 Row추가
      * @param {*} e 
      */
     const onAddRow = async (e) => {
         let newItems = {  vand: idata.kWrcVand, gocd : idata.kWrcGocd};
         //let newItems = [{cod1: 'A'}, {cod1: 'B'}];
 
         grdRsy12Main.current.addRow(newItems, 0);
     }
 
 
     
     /**
      * 입력
      * @param {*} e 
      */
     const onInsert = async (e) => {
 
         //input check
         let input = await Promise.all ([inputCheck('I')]);
         if ( !input[0]) return;
 
         const query = SERVER_URL + "/rsy12/insert";
         setInsertApi(query); // Insert API
     }
 
 
 
     /**
      * 수정 
      * @param {*} e 
      * @returns 
      */
     const onUpdate = async (e) => {
 
          //input check
          let input = await Promise.all ([inputCheck('U')]);
          if ( !input[0]) return;
         
         const query = SERVER_URL + "/rsy12/update";
         setUpdateApi(query); // Update API
     }
 
 
 
     /**
      * 삭제
      * @param {*} e 
      */
     const onDelete = async (e) => {
          
         //input check
          let input = await Promise.all ([inputCheck('D')]);
          if ( !input[0]) return;
 
         //삭제 확인 메세지 
         setDlgMessage({openDlg : true,  
                        dlgTrans : "D", 
                        dlgMsg : "선택한 데이터를 삭제하시겠습니까?" });
     }
 
 
 
     // input check
     const inputCheck = (gbn) => { 
 
         let inputFlag = true;
         //select nodes
         let selectedData = grdRsy12Main.current.getSelectedNodes();
 
 
         // 선택 값 체크
         if (selectedData.length === 0) {
             //Alert 창 OPEN
             setMessage({ openAlert : true, 
                          severity : "info",
                          msg : "선택한 데이터가 없습니다."});
             return false;
         }
 
         if (gbn != 'D') {
             //필수입력항목 Check
             selectedData.forEach((node) => {
                 if (node.data.vand == null || node.data.vand == "" || node.data.vand === undefined) {
                     setMessage({ openAlert : true, 
                                  severity : "info",
                                  msg : "업체코드를 입력하세요."});
 
                     grdRsy12Main.current.SetFocus(node.rowIndex, "vand");                             
                     inputFlag = false
                     return false;
                 }
             });
         }
         return inputFlag;
     }
 
 
    /**
         * 엑셀
         * @param {*} e 
         */
    const onExcel = async (e) => {
        let para = `{"kWrcVand":"${idata.kWrcVand}","kWrcVdnm":"${idata.kWrcVdnm}"}`;
        let param = encodeURIComponent(para);
        const query = SERVER_URL + "/rsy12/excel?query=" + param;

        setLoading(true);
        
        await fetch(query, {headers: {Authorization: token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'rsy12.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })

        setLoading(false);
        setMessage({ openAlert : true, 
                        severity : "success",
                        msg : "엑셀파일이 생성되었습니다."});
    }

     //Transaction AlertDialogResult
     const AlertDialogResult = async (e) => {
 
         //AlertDialog 초기화
         setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
 
         //DialogResult
         if (e.result == "N") return;
 
         //Transaction 구분
         switch (e.Trans) 
         {
             //등록
             case "I" :
                 break;
             //수정
             case "U" :
                 break;
             //삭제
             case "D" :
                 const query = SERVER_URL + "/rsy12/delete";
                 setDeleteApi(query); // Update API
                 break;
         }
     }
     
     
 
 
     /**
      *Grid Column 정의 
      */
     const columnDefs = [
         { headerName: "업체코드", field: "vand", sortable: true, filter: true, flex: 1,maxLength:5, minWidth: 30, editable: true, headerClass: "editCell",cellEditor:"maxLengt", cellEditorParams:{maxLength:6}, cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false}, 
         { headerName: "업체명", field: "vdnm", sortable: true, filter: true, flex: 1, minWidth: 180, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false}, 
         { headerName: "사업자번호", field: "bsno", sortable: true, filter: true, flex: 1, minWidth: 120, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },  //valueFormatter : Utils.numberFormatter
         { headerName: "법인번호", field: "cpno", sortable: true, filter: true, flex: 1, minWidth: 100, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false }, 
         { headerName: "대표자", field: "chif", sortable: true, filter: true, flex: 1, minWidth: 50, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false }, 
         { headerName: "주 소", field: "addr", sortable: true, filter: true, flex: 1, minWidth: 200, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }, 
         { headerName: "업종", field: "type1", sortable: true, filter: true, flex: 1, minWidth: 50, maxLength:5, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }, 
         { headerName: "업태", field: "stat", sortable: true, filter: true, flex: 1, minWidth: 50, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }, 
         { headerName: "전화", field: "tele", sortable: true, filter: true, flex: 1, minWidth: 120, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
         { headerName: "FAX", field: "faxn", sortable: true, filter: true, flex: 1, minWidth: 120, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false }   
     ];
    const onClick = async (e) => {
            setPanded(!panded);
    
            if(panded===true){
                setStyle({       
                    height :"calc(100vh - 60px - 50px - 50px)"
                  });
            }else{
                setStyle({
                    height :"calc(100vh - 60px - 50px - 55px  - 50px)"
                  });
            }
        }
     return(
         <>
             {/* Dialog FRAME */}
             <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />
 
             {/* Alert FRAME */}
             <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
 
                     
             {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
             <Ribbon
                 onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                 onSearch={onSearch} onSearchName={null} //조회
                 onAddRow={onAddRow} onAddRowName={"신규"}  onAddRowVisible = {tVnt1 === "M000"}  //Row 추가
                 onInsert={onInsert} onInsertName={null} onInsertVisible = {tVnt1 === "M000"}    //등록
                 onUpdate={onUpdate} onUpdateName={null} //onUpdateVisible = {tVnt1 === "M000"}    //수정
                 onDelete={onDelete} onDeleteName={null} onDeleteVisible = {tVnt1 === "M000"}    //삭제
                 onExcel={onExcel} onExcelName={null} onExcelVisible = {tVnt1 === "M000"}    //삭제
                 //onPrint={onInsert} onPrintName={null} //print
             >
                 {"시스템관리 > 시스템관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>업체관리</b>
             </Ribbon>
 
 
             {/* ContentXearch Frame : 검색조건 */}
             <ContentXearch
                 optionsX={optionsX}
                 onChange={onChange} />
 
 
             {/* Content FRAME (Data / Grid Frame) START -------------- */}
             <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:55px */}
                 <div className={"grid_type01"} > {/* 상단 FRAME */}
                     <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                         <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                             <Grid
                                 ref={grdRsy12Main}
                                 gridId={"RSY12"}                    //  (필수) Grid Id 정의
                                 columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                 pagination={true}                  //  쪽수 매기기 여부
                                 paginationCsv={tVnt1 === "M000"}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                 paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                 rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                 suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                 rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                 enableRangeSelection={false}        //  
                                 enableCellTextSelection={true}      // cell selection drag mode
                                 isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                 displayNo = {false}
                                 searchApi={searchApi}               // 조회
                                 insertApi={insertApi}               // 등록
                                 updateApi={updateApi}               // 수정
                                 deleteApi={deleteApi}               // 삭제
                                 //updateRefresh={onSearch}          // 수정이후 
                                 deleteRefresh={onSearch}            // 삭제이후 
                                 onClearApi={onClearApi}
                                 //onClearApi={()=> {setSearchApi(false); setUpdateApi(false);}}
                                 onCellValueChangedEvent={onCellValueChanged}
                                 onRowClickEvent={onRowClicked}
                                 
                             />
                         </div>
                     </div>
                 </div>
             </Content>
         </>
     )
 }
 export default RSY12;
