import React, { Component,useState,useRef } from 'react';
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 


function formattedDate(date){
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();
    // var hh = date.getHours();
  
    return [date.getFullYear(),
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd,
            // (hh>9 ? '' : '0') + hh
           ].join('');
  }

class PopupMngPopup extends Component {
    constructor(props)  {
        super(props);
        //this.grdCarTypeMain = React.createRef();
        this.state = {
            //Common
            token:Common.getToken(),
            userName : Common.getToken("name"),
            tVnt1 : Common.getToken("vand"),
            readOnly: true,
            mode : "",
            vand : Common.getToken("vand"),          

            selectedFile:null,            
            dayCheck:"",             
                        
            wrclink:props.data.wrclink,
            fileId:props.data.fileId,
            wrccont:props.data.wrccont,     
            wrctype:props.data.wrctype,       
            wrcperiod:props.data.wrcperiod == "" || props.data.wrcperiod == undefined || props.data.wrcperiod == null ? "D" : props.data.wrcperiod,
            previewYN:props.data.previewYN == "" || props.data.previewYN == undefined || props.data.previewYN == null ? "N" : props.data.previewYN,
            wrcseqn:props.data.wrcseqn,
            wrccontsize:props.data.wrccontsize == "" || props.data.wrccontsize == undefined || props.data.wrccontsize == null ? "28" : props.data.wrccontsize,
            //Alert 창 ( Alert 창 OPEN,  Alert 창 구분, Alert 창 메세지)
            openAlert : false, severity : "success", msg : "",
    
            //Dialog 창 (Dialog 창 OPEN, Dialog Transaction(I,U,D),  Dialog 메세지)
            openDlg : false, dlgTrans : "", dlgMsg : "", 
    
            //Api (조회, 등록, 수정, 삭제)
            searchApi : false, insertApi : false, updateApi : false, deleteApi : false,

            loadingFlag : false,

            data : [], setDatas : [],            
            typeOpen : false,setTypeOpen : false, setModalOpen : false,
            loading : false,
            imgBase64:null,
            upload_file:null,
        }
        this.fileRef=React.createRef();
    }


    /**
     * WillMount
     */
    async componentWillMount() {
        //Loading Flag
        this.setState({loadingFlag : true}); 
    }

    /**
     * DidMount (바로조회)
     */
    componentDidMount() {
    }

    handleFileClick = async (e) => {
        e.preventDefault();
        this.fileRef.current.click();
    }
    

    render() {

        if (this.state.loadingFlag === false) {
            return null
        }
        else {
            //State
            const {token,  tVnt1,  kDelGubn, mode,uid,wrcseqn,close,
                   openAlert, severity, msg,idat,wrctitl,vdnm,cont,vand,wrcpath,wrcfile,wrccont,datatest,fileid,setFileid,
                   fileId, wrclink, wrcfrom, wrcto, wrcidat, useYn ,fileName,wrclocation,wrcperiod,wrccontsize,
                   openDlg, dlgTrans, dlgMsg, compList, searchApi, insertApi, updateApi, deleteApi,imgBase64,updateCheck,dayClose,dayCheck,previewYN,wrctype,
                   data,setDatas,typeOpen,setModalOpen,open,setOpen,setTypeOpen,upload_file
                 } = this.state;

        
            /**
             * Control 변경 Event
             * @param {*} e 
             */
            const onChange = async (e) => {
                const { name, value } = e.target;

                let newSearchs = this.state;
                newSearchs[name] = value
                this.setState({ ...newSearchs });
            }

                                
            //AlertDialogResult
            const AlertDialogResult = async (e) => {

                //AlertDialog 초기화
                this.setState({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        
                //DialogResult
                if (e.result == "N") return;
        
                //Transaction 구분
                switch (e.Trans) 
                {
                    //등록
                    case "I" :
                        break;
                    //수정
                    case "U" :
                        break;
                    //삭제
                    case "D" :
                        break;
                }
            }    

            const today = new Date();
            if(wrcperiod == "D"){today.setDate(today.getDate() + 1);} // 하루 동안
            else{today.setDate(today.getDate() + 7);}                 // 일주일 동안
               
            var saveDay = formattedDate(today);                 

            // 하루/일주일 동안 이창을 열지 않음 체크박스 체크여부
            const isChecked = async (e) => { 
                if(e.target.checked === true){                    
                    this.state.dayCheck = "Y"                       
               }else{
                    this.state.dayCheck = "N"          
               }
           }    

           // 체크박스 여부 체크 후 localstorage에 데이터 입력
           const isCheckedClose = async (e) => {
                if(this.state.dayCheck == "Y"){                      
                    localStorage.setItem(wrcseqn,saveDay + "00"); // 당일 자정으로 변환
                }                
            }    
                              
            return (
                <> 
                {/* Dialog FRAME */}
                <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} /> 
                <div>
                    <table style = {{ borderCollapse : "collapse", border:"none", borderRadius:"10px",
                        boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" // 진한 그림자      
                                    , margin : "5px"
                        // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" // 연한 그림자
                        }}>                                        
                            <tr style={{backgroundColor:"#222339"}}>                                
                                <td colSpan={2}>                      
                                    <a href = {wrclink} target = "_blank" onClick={wrclink == "" ? (e)=>{e.preventDefault()} : (e)=>{} }>

                                        {/* 팝업 미리보기*/}
                                        {previewYN == "Y" && wrctype == "I" ? <img style={{maxHeight:"800px", maxWidth:"800px", alt:"File"}} src = {fileId}/> : ""} 

                                         {/* 로그인/메인 페이지*/}
                                        {previewYN == "N" && wrctype == "I" ? 
                                            <img style={{maxHeight:"800px", maxWidth:"800px", alt:"File"}} src = {"/file/popup/" +fileId}/> : ""} 
                                        {wrctype == "T" ? 
                                            <textarea                
                                                style = {{padding:"15px", width:"700px", height:"550px",fontFamily: "'PretendardMedium', sans-serif", fontSize : wrccontsize + "px" , textAlign:"left", resize:"none", outline:"none", border:"none", readOnly:"readOnly", display:"block",
                                                caretColor:"transparent"}} spellCheck="false" 
                                                value = {wrccont} >
                                            </textarea>
                                            : ""
                                        }                                    
                                    </a>
                                </td>
                            </tr>                   
                            <tr style={{height:"20px", backgroundColor:"#000000"}}>   
                                <td style={{textAlign:"left"}}>                                   
                                    <input style={{marginLeft:"5px",marginRight:"5px", cursor:"pointer"}} onChange={isChecked} type="checkbox" id={`periodCheck${wrcseqn}`}></input>
                                    {wrcperiod == "D" ? 
                                        <label for={`periodCheck${wrcseqn}`} style={{fontFamily: "'PretendardMedium', sans-serif", fontSize : "large" , cursor:"pointer", color:"white"}}>오늘 하루 이창을 열지 않음</label>
                                        :
                                        <label for={`periodCheck${wrcseqn}`} style={{fontFamily: "'PretendardMedium', sans-serif", fontSize : "large" , cursor:"pointer", color:"white"}}>일주일 동안 이창을 열지 않음</label>} 
                                </td>            
                                <td style={{textAlign:"right"}}>
                                    <p onClick={() => {this.props.onClose(wrcseqn);isCheckedClose();}} style={{fontFamily: "'PretendardMedium', sans-serif", fontSize : "large" , cursor:"pointer", marginRight:"5px", color:"white"}}>[닫기]</p>
                                </td>
                            </tr>                
                    </table>        
                </div>                                        
            </>
        );
        }
        
    }

}

export default PopupMngPopup;