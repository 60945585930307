/**
 * @Page ROM4P
 * @Description [ROM4P]납입지시 - ERP_OEM출하관리 - 출하정보전송 (E_OEM)
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 
 import Ribbon from '@/components/organisms/Ribbon';
 import GridRibbon from "@/components/organisms/GridRibbon";
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import * as GridUtil from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
 import ReactToPrint from "react-to-print";
 import VmiCardPrint from "@/components/molecules/VmiCardPrint";
 import Popup from '@/pages/Popup/Popup';    
  
 import Loading from "src/components/atoms/CustomLoading2"; 

 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();
 
 // Print Style CSS Setting
 const pageStyle = `
@media all {
    .pagebreak {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .pagebreak {
      margin-top:1rem;
      display: block;
      page-break-after: always;
    }
  }
  
  @page {
    size: A4 portrait !important;
    margin:7mm 10mm;
  }
  body, div, table, td{
      font-family:굴림체,Gulim ;
  }

`;

 function ROM4P() {
 
    const token = Common.getToken();
    const userId = Common.getToken("id");
    // const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const bigi = Common.getToken("bigi");
    const grdRom4pMain = useRef();      //Grid Main
    const grdRom4pDetail = useRef();    //Grid Detail
    const printRef = useRef();
    const printBtnRef = useRef();

    const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
    const ckName = Common.getCookie("ck_ename")===null?"":Common.getCookie("ck_ename"); 
    const ckTelno = Common.getCookie("ck_telno")===null?"":Common.getCookie("ck_telno"); 
    
    
    //Data
    const [idata, setData] = useState({
        //조회조건
        // kVnt1 : tVnt1,              //T1업체
        kVnt1 : ckVnt1,        //T1업체     
        kComp: tComp,               //회사구분
        kNpdt: Common.getToday(),   // 발행일자 :: Default 값 셋팅       
        kCano : "",                 //차량번호    
        kName: ckName,          //담당자    
        kTelno: ckTelno,         //연락처         
        kPlnt: "",                  //공장
        kGubn: "1",                 // 조회구분(미전송/전송) :: Default 값 셋팅       
        kInclt2: "N",               // T2업체포함 :: Default 값 셋팅                    

        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장
        canoList : [],  //차량번호
    }); 
    
            
    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;
    const [typeOpen, setTypeOpen] = React.useState(false);
    const [typeOpen2, setTypeOpen2] = React.useState(false);

    let [loading, setLoading] = useState(false); 
    const [data, setDatas] = useState([]);

    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      // Dialog Transaction(I,U,D)
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
  
    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false);    
    let [searchApi2, setSearchApi2] = useState(false);  
    

    let [deleteApi, setDeleteApi] = useState(false);    // deleteApi 항목 setting
    
    let [printdata, setPrintData] = useState([]);       
    let [pos, setPos] = useState([]); //납품서 좌표
    let [clearGrid2, setClearGrid2] = useState(false);  

    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 146px  - 50px)"});

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
    }, []);

    const onClearApi2 = useCallback(async (e) => {
        setSearchApi2(false);
    }, []);



    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }
     

    
    /**
     *MOUNT 
    */
    useEffect( () => {
        
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
        //공통코드 조회 (공장)
        onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");
        //공통코드 조회 (차량번호)
        onSearchCommon(`{"pgmid":"CARN"}`, "canoList");

        //조회
        onSearch();
    }, []);
  
  
  
    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value} = e.target;

        let newSearchs = idata; 
        newSearchs[name] = value
        setData({ ...newSearchs });


        //T1업체, 회사구분 변경시 
        if (name === "kVnt1" || name === "kComp")  {
            
            newSearchs["plntList"] = [];
            newSearchs["gateList"] = [];
            newSearchs["kPlnt"] = "";
            newSearchs["kGate"] = "";
            setData({ ...newSearchs });

            //공통코드 조회 (공장)
            onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");
        }

        //조회조건 변경시 자동 조회
        if (name === "kVnt1" || name === "kComp" || name === "kCano" ||
            name === "kPlnt" || name === "kGubn" || name === "kInclt2" ) {

            //납품서 발행 조회
            onSearch();
        }
    }
 
  
  
    /**
     * Grid Event 
     * @param {*} e 
    */
    //Main Grid CellValueChanged
    const grdRom4pMain_onCellValueChanged = async (e) => {
    }


    //Main Grid Row Click
    const grdRom4pMain_onRowClicked = async (e) => {

        let data = e.data;

        //Detail Grid 조회
        onSearch2(data.dlno, data.gate, data.prgb);
    }

    //Detail Grid CellValueChanged
    const grdRom4pDetail_onCellValueChanged = async (e) => {
    }

    //Detail Grid Row Click
    const grdRom4pDetail_onRowClicked = async (e) => {

    }

  
  
    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //T1업체
            {
                "formatter": "select", "name": "kVnt1", "labelName": "T1업체", "type": "text", "defaultValue": idata.kVnt1,  
                "codeMode": true,
                "selectAll": "",
                "codes": null,
                "comms": idata.vnt1List,
                "align": "left", 
            }, 
             //납품일자
             {
                "formatter": "input", "name": "kNpdt", "labelName": "납품일자", "type": "date", "desc": "", "readOnly" : false,
                "defaultValue": idata.kNpdt,  
                "align": "left", 
                "labelType": "none" 
            }, 
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" },
             //담당자
             {
                "formatter": "input", "name": "kName", "labelName": "담당자", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kName,  
                "align": "right", 
                "labelType": "insert", 
            }
        ],
        1: [
            //회사구분
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": true,
                "selectAll": "",
                "codes": CODES["comp"],
                "comms": null,
                "align": "left", 
            }, 
            //차량번호
            {
                "formatter": "select", "name": "kCano", "labelName": "차량번호", "type": "text", "defaultValue": idata.kCano,  
                "codeMode": false,
                "selectAll": "all",
                "codes": null,
                "comms": idata.canoList,
                "align": "right", 
            }, 
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" },
            //연락처
            {
                "formatter": "input", "name": "kTelno", "labelName": "연락처", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kTelno,  
                "align": "right", 
                "labelType": "insert", 
            }
        ],
        2: [
            //공장
            {
                "formatter": "select", "name": "kPlnt", "labelName": "공장", "type": "text", "defaultValue": idata.kPlnt,  
                "codeMode": false,
                "selectAll": "all",
                "codes": null,
                "comms": idata.plntList,
            }, 
            //조회구분 ( 미전송, 전송 )     
            {
                "formatter": "radio", "name": "kGubn", "labelName": "조회구분", "defaultValue": idata.kGubn
                , "options": [{ "name": "kGubn1", "labelName": "미전송", "setValue": "1" }, { "name": "kGubn2", "labelName": "재전송", "setValue": "2" }] 
                // , "defaultValue": kPldt  
            }, 

            //T2업체포함 
            // {
            //     "formatter": "check", "name": "kInclt2", "labelName": " "
            //     , "options": [{ "name": "kInclt2", "labelName": "T2업체포함", "defaultValue": idata.kInclt2 }] 
            //     // , "defaultValue": kPldt  
            // }, 
            {
                "formatter": "checkSpan", "name": "kInclt2", "labelName": " ",
                "options": [{ "name": "kInclt2", "labelName": "T2업체포함", "defaultValue": idata.kInclt2 }] 
            },
            
            {"formatter": "blank", "align": "left" },
            {"formatter": "blank", "align": "left" }
        ],
    }
  
  
  
    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {
        
        setClearGrid2(true); // Detail Grid 초기화

        //T1업체선택 Check
        if (idata.kVnt1 == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "1차업체를 선택하십시요 !"});
            document.getElementById("kVnt1").focus();                         
            return ;
        }

        //회사구분선택 Check
        if (idata.kComp == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "회사구분을 선택하십시요 !"});
            document.getElementById("kComp").focus();                         
            return ;
        }

        //공장선택 Check
        if (idata.kPlnt == null)
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "공장을 선택하십시요 !"});
            document.getElementById("kPlnt").focus();                         
            return ;
        }

        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);

        let para = `{"kVnt1":"${idata.kVnt1}", "kNpdt":"${idata.kNpdt}", 
                     "kComp":"${idata.kComp}", "kCano":"${idata.kCano}", 
                     "kPlnt":"${idata.kPlnt}", "kGubn":"${idata.kGubn}", "kInclt2":"${idata.kInclt2}"}`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/rom4p/search?query=" + param;
        setSearchApi(query); // Search API
    }

  
  
    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
    }

  
      
    /**
     * 입력
     * @param {*} e 
     */
    const onInsert = async (e) => {
    }
  
  
  
    /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {
    }


  
    /**
     * 회수처리/취소
     * @param {*} e 
     */
    const onDelete = async (e) => {
    }


    /**
     * 출하자료 전송, 회수처리/취소
     * @param {*} e 
     * @returns 
     */
     const onPrint = async (gbn) => {

        //select nodes
        let selectedData = grdRom4pMain.current.getSelectedData();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "선택한 데이터가 없습니다."});
            return false;
        }

        if (gbn === "CH") {
            // 담당자 입력 Check
            if (idata.kName == "")
            {
                setMessage({ openAlert : true, 
                            severity : "info",
                            msg : "담당자를 입력하십시요."});
                document.getElementById("kName").focus();                         
                return ;
            }

            // 연락처 입력 Check
            if (idata.kTelno == "")
            {
                setMessage({ openAlert : true, 
                            severity : "info",
                            msg : "연락처를 입력하십시요."});
                document.getElementById("kTelno").focus();                         
                return ;
            }
        }

        // Cookie 값 셋팅
        Common.setCookie("ck_ename",idata.kName,1);
        Common.setCookie("ck_telno",idata.kTelno,1);


        const fetchData = async () => {
            setLoading(true); 

            let selectDlnoList = [];
            selectedData.map(function (data, i) {

                // selectedData2.forEach(function (data, i) {
                if (data.dlno) {
                    selectDlnoList.push(data.dlno);
                }
            }); 

            // 납품서발행(VMI ROM3H) , 납품서발행(PO ROM3L), 출하정보전송(ROM4P), 납품서일괄발행(VMI REX3H) - "/rom3h/chulha" 모두 여기 사용 !!!!
            const query = SERVER_URL + "/rom3h/chulha"; 
            let param = JSON.stringify({
                vnt1: idata.kVnt1,
                comp: idata.kComp,
                ename : idata.kName,
                telno : idata.kTelno,
                func: gbn, // CH : 출하전송, CL : 회수처리/취소
                rprint: 'N',
                dlnolist: selectDlnoList,
            });
            let posList = {};
            await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) {
                        
                        if (gbn === "CH") {
                            setPrintData(data.data.list);
                        
                            // 성공 메시지 처리 필요
                            setMessage({ openAlert : true, 
                                        severity : "success",
                                        msg : "인쇄 조회 성공 하였습니다."});
                            //print 
                            for(let i = 0; i<data.data.list.length;i++){
                                posList={
                                    posx: parseInt(data.data.list[0].head.posx),
                                    posy: parseInt(data.data.list[0].head.posy)
                                }
                            }
                            setPos(posList);
                            printBtnRef.current.dispatchEvent(new Event("click", { bubbles: true }));
                        }else{
                            //출하전송 취소 메세지
                            setMessage({ openAlert : true, 
                                severity : "success",
                                msg : data.data.message});
                        }
                        setTimeout(() => onSearch(), 400);
                    }

                    if(data.success===false){
                        setMessage({ openAlert : true, 
                            severity : "error",
                            msg : data.data.message!=""||data.data.message !=undefined?data.data.message:"출하 실패"});
                    }

                    if (data.error) {
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : data.data.message==""||data.data.message ==undefined?"조회 실패":data.data.message});
                    }
                })
                .catch((error) => {
                    // 에러 응답 처리
                    if (error) {
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                    }
                });
                setLoading(false); 
        };

        fetchData();
    }



    /**
     * 
     * @param {*} dlno 납품서번호
     * @param {*} gate 하치장
     * @param {*} prgb 인쇄여부
     */
    const onSearch2= async (dlno, gate, prgb) => {
        
        let para = `{"kDlno":"${dlno}","kGate":"${gate}"}`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/rom4p/search2?query=" + param;
        setSearchApi2(query); // Search API
    }


    const onSearch3 = async (e) => {
        
        //Popup Open
        setTypeOpen(true);
    }

    
    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans) 
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D" :
                // const queryD  = SERVER_URL + "/rom3j/delete";
                // setDeleteApi(queryD); // Update API
                break;
        }
    }
      
    

    /**
     * Grid Bind Data
     * @returns 
    */
    const onGridBindData = (para) => {
    }
    
    const editCellClicked =  (params) =>{
        if (params.value === null || params.value === undefined || params.value === "")
            return;        
        let data =  [];
        data["dlno"] = params.data.dlno;
        data["context"] = params.value;
        data['npdt'] = params.data.npdt;

        setDatas({ ...data });
        setDatas(data);
        setTypeOpen2(true);
        
    }

 
     
  
    /**
     *Grid Column 정의 
    */
    //Main Grid Column
    const columnDefs = [
        { headerName: "공장", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 100,maxWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false},
          //checkboxSelection : params=> (params.data.chul === 'Y' && '1' === params.data.gubn) ? false : true, hide: false},
        { headerName: "하치장", field: "gate", sortable: true, filter: true, flex: 1, minWidth: 120,maxWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "납품서번호", field: "dlno", sortable: true, filter: true, flex: 1.5, minWidth: 300,maxWidth: 300, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
        { headerName: "납품일시", field: "npdt1", sortable: true, filter: true, flex: 1, minWidth: 150,maxWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "품목건수", field: "pcnt", sortable: true, filter: true, flex: 1, minWidth: 120,maxWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false },
        { headerName: "인쇄", field: "prgb", sortable: true, filter: true, flex: 1, minWidth: 100,maxWidth: 100,editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "전송확인", field: "chul", sortable: true, filter: true, flex: 1, minWidth: 110,maxWidth: 110, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "전송 Message", field: "mesg", sortable: true, filter: true, flex: 2, minWidth: 600,maxWidth: 660,editable: false, headerClass: "", tooltipField: 'mesg', cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false,  onCellClicked: editCellClicked
            ,cellStyle: params => {{return {color: "#022dff",cursor:"pointer",textDecoration:"underline",fontWeight:"bold"};}}},

        { headerName: "업체코드", field: "vand", sortable: true, filter: true, flex: 1, minWidth: 140, hide: true },     //hidden 컬럼
        { headerName: "회사구분", field: "comp", sortable: true, filter: true, flex: 1, minWidth: 140, hide: true },     //hidden 컬럼
        { headerName: "npdt", field: "npdt", sortable: true, filter: true, flex: 1, minWidth: 140, hide: true },        //hidden 컬럼 (발행일시)
        { headerName: "npdh", field: "npdh", sortable: true, filter: true, flex: 1, minWidth: 140, hide: true },        //hidden 컬럼 (발행시간)
        { headerName: "prtm", field: "prtm", sortable: true, filter: true, flex: 1, minWidth: 130, hide: true },        //hidden 컬럼
        { headerName: "vnt1", field: "T1업체", sortable: true, filter: true, flex: 1, minWidth: 130,hide: true },       //hidden 컬럼
        { headerName: "소요일자", field: "pldt", sortable: true, filter: true, flex: 1, minWidth: 380, hide: true },    //hidden 컬럼
        { headerName: "mesg", field: "mesg", sortable: true, filter: true, flex: 1, minWidth: 130, hide: true },        //hidden 컬럼
    ];
    //Detail Grid Column
    const columnDefs1 = [
        { headerName: "순번", field: "dseq", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "공장", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 270, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
        { headerName: "구매번호/PO", field: "odno1", sortable: true, filter: true, flex: 1, minWidth: 250, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
        { headerName: "수량", field: "npqt", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false },
        { headerName: "납품일자", field: "npdt", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueFormatter: Utils.dateFormatter, hide: false },
        { headerName: "시간", field: "nptm1", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "전송 Message", field: "mesg", sortable: true, filter: true, flex: 1, minWidth: 500, editable: false, tooltipField: 'mesg',cellStyle: Utils.boldCellStyle, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },

        { headerName: "dlno(H)", field: "dlno", sortable: true, filter: true, flex: 1, minWidth: 150, hide: true },    // hidden 컬럼
        { headerName: "odno(H)", field: "odno", sortable: true, filter: true, flex: 1, minWidth: 380, hide: true },    // hidden 컬럼
        { headerName: "seri(H)", field: "seri", sortable: true, filter: true, flex: 1, minWidth: 380, hide: true },    // hidden 컬럼
        { headerName: "nptm(H)", field: "nptm", sortable: true, filter: true, flex: 1, minWidth: 140, hide: true },    // hidden 컬럼
        { headerName: "cano(H)", field: "cano", sortable: true, filter: true, flex: 1, minWidth: 140, hide: true },    // hidden 컬럼
        { headerName: "gate(H)", field: "gate", sortable: true, filter: true, flex: 1, minWidth: 140, hide: true },    // hidden 컬럼
        { headerName: "dono(H)", field: "dono", sortable: true, filter: true, flex: 1, minWidth: 140, hide: true }     // hidden 컬럼
    ];
   
    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 146px  - 50px)"
              });
        }
    }
  
    return( 
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={5000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

            {/* Loading */}
            <Loading loading={loading} />

            <Popup type="Chul" open={typeOpen} onClose={()=> {setTypeOpen(false)}}/>  
            <Popup type="ChullogMsgPopup" open={typeOpen2} onClose={()=> {setTypeOpen2(false)}} data = {data} />

            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onSearch={onSearch} onSearchName={null} //조회
                onPrint={()=> {onPrint("CH")}}  onPrintName={"출하자료전송"} onPrintVisible = {idata.kGubn === "1" && tVnt1 !== "NRAP"}       //미전송일 때만 사용
                onDelete={()=> {onPrint("CL")}} onDeleteName={"회수처리(출하전송취소)"} onDeleteVisible = {idata.kGubn === "2" && tVnt1 !== "NRAP"}    //재전송일 때만 사용
                //onFunction={onSearch3} onFunctionName={"출하미전송조회"} onFunctionType={"search"}  onFunctionVisible = {tVnt1 === "M000"} //관리자만 접근가능
            >
                {"납입지시 > ERP_OEM 출하관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardMedium, NanumGothic, sans-serif" }}>출하정보전송 (E_OEM)</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick = {onClick} />


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:146px */}
                <div className={"grid_type01"} style={{ height: "100%" }}> {/* 상단 FRAME */}
                    <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                        <div className="grid_cont_box" style={{ height: "100%" }}>
                            <Grid
                                ref={grdRom4pMain}
                                gridId={"ROM4P"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                pagination={false}                  //  쪽수 매기기 여부
                                paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={false}   //  클릭으로 선택 하는것을 방지
                                rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={true}      // cell selection drag mode
                                isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                onClearApi={onClearApi}             // 초기화
                                onCellValueChangedEvent={grdRom4pMain_onCellValueChanged}
                                onRowClickEvent={grdRom4pMain_onRowClicked}
                            />
                        </div>
                    </div>
                </div>

                <div className="grid_type04" style={{ height: "50%" }} >{/* 하단 FRAME */}
                    <div className="grid_wrap04"> {/* 하단 wrap 좌측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <div className="grid_cont_box" style={{ height: "100%" }}>

                                {/* 납품서 발행현황 Grid */}
                                <Grid
                                    ref={grdRom4pDetail}
                                    gridId={"ROM4P2"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs1}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"single"}             //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    searchApi={searchApi2}              // 조회
                                    onClearApi={onClearApi2}            // Clear
                                    clearGrid={clearGrid2}
                                    onClearGridFlag={setClearGrid2}
                                    onRowClickEvent={grdRom4pDetail_onRowClicked}     
                                    onCellValueChangedEvent={grdRom4pDetail_onCellValueChanged}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Content>

            <div className="printArea">
                <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef} style={{ display: "none" }} />} pageStyle={pageStyle} content={() => printRef.current} />
                <VmiCardPrint ref={printRef} printData={printdata} pos = {pos}/>
            </div>
        </>
    )
  }
  export default ROM4P;