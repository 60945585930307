/**
 * @Page WrapErpLabelPrintPercentB
 * @Description [ROM4H / ROM4N]납입지시 - ERP_OEM출하관리 - 부품식별표(VMI / PO) :: [OEM 내수] 부품식별표(VMI / PO) 인쇄  ::  B 6매
 **/
import React from 'react';
import CustomDataMatrix from '@/components/atoms/CustomDataMatrix';
import * as Common from '@/utils/Common';
import styled from 'styled-components';

class WrapErpLabelPrintPercentB extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bplx: '0',
			bply: '0',
		};
	}

	componentDidMount() {
		const info_bplx = Common.getToken('bplx') === null ? 0 : parseInt(Common.getToken('bplx'), 10) * 2; // 로그인한 사용자의 부품식별표 의 x좌표값 받아오기
		const info_bply = Common.getToken('bply') === null ? 0 : parseInt(Common.getToken('bply'), 10) * 2; // 로그인한 사용자의 부품식별표 의 y좌표값 받아오기
		this.setState({ bplx: info_bplx });
		this.setState({ bply: info_bply });
	}

	// '바코드 = 업체코드(4) + 품번(15) + 수량(5) + LOTNO(3) + 일련번호(4)
	//        tmp(0) '회사구분
	//        tmp(1) '지역
	//        tmp(2) '공장
	//        tmp(3) '차종
	//        tmp(4) '납품장소(하치장)
	//        tmp(5) '품번
	//        tmp(6) '품명
	//        tmp(7) '납품수량
	//        tmp(8) '바코드
	//        tmp(9) '납품일자
	//        tmp(10) '업체명
	//        tmp(11) "" '공정(공백)
	//        tmp(12) '업체코드
	//        tmp(13) "" ' LOT NO
	//        tmp(14) "" 'HPC(공백)
	//        tmp(15) 'CASE(CKD)
	//        tmp(16) "" '발행번호(바코드만들어서 던짐)
	//        tmp(17) "" '용기번호
	//        tmp(18) '부착매수
	//        tmp(19) 'LH/RH
	//        tmp(20) "" '사양
	//        tmp(21)  '발주번호(공백)
	//        tmp(22)  'FROM-ORDER(CKD , OEM은 공정)
	//        tmp(23)  'CKD는 공백, OEM은 HPC
	//        tmp(24)  '용기당수
	//        tmp(25) = '출력1
	//        tmp(26) = 'x좌표1
	//        tmp(27) = 'y좌표1
	//        tmp(28) = '색상1
	//        tmp(29) = 'FONT/S1
	//        tmp(30) = '출력2
	//        tmp(31) = 'x좌표2
	//        tmp(32) = 'y좌표2
	//        tmp(33) = '색상2
	//        tmp(34) = 'FONT/S2
	//        tmp(35) = '한글품명
	//        tmp(36) = 'RHD

	render() {
		// Print Style CSS Setting
		const PrintStyleB = styled.div`
			@media all {
				body {
					background-color: ;
					margin: 0px;
					padding: 0px;
					font-family: 'HY견고딕';
				}
				div {
					background-color: ;
					margin: 0px;
					padding: 0px;
					box-sizing: border-box;
				}
				table {
					border-collapse: collapse;
					border-style: none;
					margin: 0px;
					padding: 0px;
					box-sizing: border-box;
				}
				tr {
					width: 100%;
					margin: 0px;
					padding: 0px;
				}
				td {
					margin: 0px;
					padding: 0px;
					background-color: white;
					font-family: 'HY견고딕';
					border: 0px solid green;
					box-sizing: border-box;
				}
				.HYheadM11 {
					font-family: 'NanumGothic';
					font-weight: 700;
					font-size: 11pt;
				}
				.HYheadM12 {
					font-family: 'HY견고딕';
					font-weight: bold;
					font-size: 12pt;
					word-break: break-all;
				}
				.HYheadM13 {
					font-family: 'HY견고딕';
					font-weight: bold;
					font-size: 13pt;
					word-break: break-all;
				}
				.HYheadM14 {
					font-family: 'HY견고딕';
					font-weight: bold;
					font-size: 14pt;
					word-break: break-all;
				}
				.HYheadM16 {
					font-family: 'HY견고딕';
					font-weight: bold;
					font-size: 16pt;
					word-break: break-all;
				}
				.HYheadM18 {
					font-family: 'HY견고딕';
					font-weight: bold;
					font-size: 18pt;
					word-break: break-all;
				}
				.HYheadM9 {
					font-family: 'HY견고딕';
					font-size: 9pt;
					word-break: break-all;
				}
				.HYheadM24 {
					font-family: 'HY견고딕';
					font-size: 24pt;
					word-break: break-all;
				}

				.table {
					display: table;
					border: 1px solid blue;
				}
				.row {
					// display: table-row;
					display: flex;
					flex-flow: wrap;
				}
				.cell {
					display: table-cell;
					// border-bottom:1px solid blue;
					// border-right:1px solid blue;
					border-width: thin;
					white-space: nowrap;
					z-index: 99;
				}

				.colspan2 {
					flex: 2;
				}
				.colspan3 {
					flex: 3;
				}
				.colspan4 {
					flex: 4;
				}
				.colspan5 {
					flex: 5;
				}
				.colspan6 {
					flex: 6;
				}
				.colspan7 {
					flex: 7;
				}
				.colspan8 {
					flex: 8;
				}
				.colspan9 {
					flex: 9;
				}
				.colspan10 {
					flex: 10;
				}

				.row > .cell:nth-child(1) {
					width: 10%;
				}
				.row > .cell:nth-child(2) {
					width: 2.9%;
				}
				.row > .cell:nth-child(3) {
					width: 2%;
				}
				.row > .cell:nth-child(4) {
					width: 5%;
				}
				.row > .cell:nth-child(5) {
					width: 16%;
				}
				.row > .cell:nth-child(6) {
					width: 11%;
				}
				.row > .cell:nth-child(7) {
					width: 10%;
				}
				.row > .cell:nth-child(8) {
					width: 9%;
				}
				.row > .cell:nth-child(9) {
					width: 11%;
				}
				.row > .cell:nth-child(10) {
					width: 10%;
				}
				.row > .cell:nth-child(11) {
					width: 10%;
				}
			}
			@media print {
				@page {
					size: A4 landscape;
					margin-top: 0mm;
					margin-left: 0mm;
					margin-right: 4mm;
					margin-bottom: 4mm;
				}

				.table {
					display: table;
					border: 1px solid none;
				}
				.row {
					// display: table-row;
					display: flex;
					flex-flow: wrap;
				}
				.cell {
					display: table-cell;
					border-bottom: 1px solid none;
					border-right: 1px solid none;
					border-width: thin;
					white-space: nowrap;
					z-index: 99;
				}

				div.pagebreak {
					break-after: always !important;
					page-break-after: always !important;
					page-break-inside: avoid !important;
				}
			}

			@media screen {
				.table {
					display: table;
					border: 1px solid red;
				}
				.row {
					// display: table-row;
					display: flex;
					flex-flow: wrap;
				}
				.cell {
					display: table-cell;
					border-bottom: 1px solid red;
					border-right: 1px solid red;
					border-width: thin;
					white-space: nowrap;
					z-index: 99;
					color: blue;
				}
			}
		`;

		const { printData } = this.props;

		// 검색조건 파라메터 가져오기
		const { kStart, chkMetal, kCkdOem, chkNpdt, chkCarc, chkLtno, kCarc, kCarcNm } = this.props.idata;
		const listSize = printData.length; // data length

		let idx = -1; // -1 부터 start
		let str = parseInt(kStart) - 1;

		idx = idx + str; // 시작 위치 (like 배열)

		// 바코드 표출용 - vseq
		let vseq = 0;

		// remain 표출 체크용 - remainChk -- 신규 품번의 vseq 담기
		let remainChk = 0;

		const cardInfo = printData
			? printData.map((data, index) => {
					if (index === 0) {
						vseq = parseInt(data.SEQ) - 1; // 바코드 표출 vseq용
					}

					remainChk = vseq; // 새 품번의 remain 여부 체크하기

					let npqt = parseInt(data.NPQT); // 납품수량
					let qypc = parseInt(data.QYPC); // 용기당 수
					let stck = parseInt(data.STCK); // 부착면 수

					// 추가 표시 컬러 체크 -- START
					let tmp25 = data.TMP25; // WRCLBL1	이중인쇄1 내용
					let tmp28 = data.TMP28; // WRCCOL1	이중인쇄1 컬러

					let tmp30 = data.TMP30; // WRCLBL2	이중인쇄2 내용
					let tmp33 = data.TMP33; // WRCCOL2	이중인쇄2 컬러

					let color1 = '';
					let color2 = '';

					let dualPrint1 = 'F';
					let dualPrint2 = 'F';

					let dualPrint1css = ''; // dualPrint1 css 추가 표기 view 1
					let dualPrint2css = ''; // dualPrint2 css 추가 표기 view 2

					if (tmp25 !== '') {
						dualPrint1 = 'T';
						if (tmp28 === 'B') {
							color1 = 'blue';
						} else if (tmp28 === 'G') {
							color1 = 'green';
						} else if (tmp28 === 'R') {
							color1 = 'red';
						} else if (tmp28 === 'O') {
							color1 = '#ff6600';
						} else if (tmp28 === 'X') {
							color1 = 'rgba(0, 0, 0, 0.7)';
						}
					}
					if (tmp30 !== '') {
						dualPrint2 = 'T';
						if (tmp33 === 'B') {
							color2 = 'blue';
						} else if (tmp33 === 'G') {
							color2 = 'green';
						} else if (tmp33 === 'R') {
							color2 = 'red';
						} else if (tmp33 === 'O') {
							color2 = '#ff6600';
						} else if (tmp33 === 'X') {
							color2 = 'rgba(0, 0, 0, 0.7)';
						}
					}
					// 추가 표시 컬러 체크 -- END

					let result = Math.floor(npqt / qypc); // 몫
					let resultIdx = Math.floor(npqt / qypc); // 목 + 나머지의 카운트
					let remain = npqt % qypc; // 나머지

					if (remain > 0) {
						resultIdx = result + 1;
					}

					// 새 프린트용 배열
					const newSearched = [];

					// 증가 체크 resultIdx
					let n = 0;

					Array.from(new Array(resultIdx), (i) => {
						// 메인 출력물

						Array.from(new Array(stck), (w, j) => {
							// 부착면수 만큼 선 반복..

							idx = idx + 1;

							if (j === 0) {
								// 부착면수 1일때 고려...
								vseq = vseq + 1; // 새 vseq 마다 + 1 증가
							}

							let iCnt = i + 1;

							const newArray = {};

							let jCnt = j + 1;

							// 새 배열의 n 번째 구하기
							if (i === 0 && j === 0) {
								n = 0;
							} else {
								n = n + 1;
							}

							newArray['idx'] = idx;
							newArray['vseq'] = vseq;

							//납품수량
							let newQty = 0;
							newArray['qty'] = qypc;
							newQty = qypc;
							newArray['stck'] = stck;
							newArray['firstpage'] = 'F';
							newArray['endpage'] = 'F';

							newArray['color1'] = color1;
							newArray['color2'] = color2;

							newArray['dualPrint1'] = dualPrint1;
							newArray['dualPrint2'] = dualPrint2;

							if (i === 0 && j === 0 && index === 0) {
								newArray['firstpage'] = 'T';
							}

							if (resultIdx === vseq - remainChk) {
								if (remain > 0) {
									newArray['qty'] = remain;
									newQty = remain;
								}
							}

							// END Page 구하기
							if (listSize === index + 1) {
								if (resultIdx === iCnt) {
									if (stck === jCnt) {
										newArray['endpage'] = 'T';
									}
								}
							}

							//LOTNO(3)
							const lotNo = data.TMP13 === '' || data.TMP13 === 'N' ? ' ' : Common.getLOT(data.TMP13); // LOT NO 날짜 가져오기  data.TMP13

							//일련번호(4)
							const prno = (data.TMP0 === undefined ? '' : data.TMP0) + '' + ('0000' + vseq.toString(16).toUpperCase()).slice(-4);

							// DataMatrix - code128 바코드 값 만들기
							const barcodeValu = data.TMP12 + '' + data.TMP5.padEnd(15) + '' + ('00000' + newQty).slice(-5) + '' + ('   ' + lotNo).slice(-3) + '' + prno;
							// 바코드 = 업체코드(4)   + 품번(15)                       + 수량(5)                           + LOTNO(3)                      + 일련번호(4)

							// DataMatrix Barcode 만들기
							const dataMatrixBarcode = <CustomDataMatrix val={barcodeValu} height={12} bcid={'code128'} scale={0.5} width={'100%'} />; //backgroundcolor={"FFFFFF"}
							newArray['barcode'] = dataMatrixBarcode;

							newArray['lotno'] = lotNo;
							newArray['bno'] = barcodeValu;

							// 자기 위치 셋팅 (출력매수)
							const posi = idx % 6;
							newArray['posi'] = posi;

							let divCss = '';
							let divMetal = '';
							let divTmp15 = '';

							// page별  (출력매수)1장 이상일경우 100% 씩 추가 ... 다음장 노출을 위함.
							let pagebreaky = Math.floor(idx / 6) * 100;
							let pagebreakyCnt = Math.floor(idx / 6);
							/******************************************/
							let totalLeft = -1;
							let totalTop = 0;
							let cWidth = 140;
							let cHeight = 31.3; //1개높이
							let cHeight2 = 62.6; //2개높이 Y:2
							let cPageHeight = 195; //총높이
							let cPageWidth = 280; //총넓이
							let marginX = 9.2; //margin left
							let marginY = 4.6; //margin top

							let Heightmmy = (100 * this.state.bply) / cPageHeight;
							let Heightmmx = (100 * this.state.bplx) / cPageWidth;
							let HeightTmp26 = (100 * parseInt(data.TMP26, 10)) / cPageWidth;
							let HeightTmp27 = (100 * parseInt(data.TMP27, 10)) / cPageHeight;

							let cLeft = 49.4 * (posi % 2) + totalLeft + HeightTmp26 + Heightmmx + '%';
							let cTop = pagebreaky + (posi < 2 ? 0 : posi < 4 ? cHeight : cHeight2) + totalTop + HeightTmp27 + Heightmmy + '%';

							dualPrint1css = {
								position: 'absolute',
								fontFamily: 'HY견고딕',
								top: cTop,
								left: cLeft,
								color: color1,
								fontSize: parseInt(data.TMP29, 10) + 'pt',
								fontWeight: 'bold',
								zIndex: '13',
								whiteSpace: 'nowrap',
							};

							let totalLeft2 = -1;
							let totalTop2 = 2.3;
							let HeightTmp31 = (100 * parseInt(data.TMP31, 10)) / cPageWidth;
							let HeightTmp32 = (100 * parseInt(data.TMP32, 10)) / cPageHeight; // parseInt(data.TMP32,10)*0.4;

							let cLeft2 = 49.4 * (posi % 2) + totalLeft2 + HeightTmp31 + Heightmmx + '%';
							let cTop2 = pagebreaky + (posi < 2 ? 0 : posi < 4 ? cHeight : cHeight2) + totalTop2 + HeightTmp32 + Heightmmy + '%';

							dualPrint2css = {
								position: 'absolute',
								fontFamily: 'HY견고딕',
								top: cTop2,
								left: cLeft2,
								color: color2,
								fontSize: parseInt(data.TMP34, 10) + 'pt',
								fontWeight: 'bold',
								zIndex: '13',
								whiteSpace: 'nowrap',
							};

							let size = { width: '49%', height: '31.5%' };

							if (posi === 0) {
								divCss = { position: 'absolute', top: pagebreaky + 3.4 + Heightmmy + '%', left: Heightmmx + '%', paddingTop: '0.5mm', paddingLeft: '0mm', border: '0px solid red' };
								divMetal = { position: 'absolute', top: pagebreaky + 18 + Heightmmy + '%', left: 42 + Heightmmx + '%', zIndex: '9' };
							} else if (posi === 1) {
								divCss = { position: 'absolute', top: pagebreaky + 3.4 + Heightmmy + '%', left: 49.5 + Heightmmx + '%', paddingTop: '0.5mm', paddingLeft: '0mm', border: '0px solid red' };
								divMetal = { position: 'absolute', top: pagebreaky + 18 + Heightmmy + '%', left: 91 + Heightmmx + '%', zIndex: '9' };
							} else if (posi === 2) {
								divCss = { position: 'absolute', top: pagebreaky + 34.55 + Heightmmy + '%', left: Heightmmx + '%', paddingTop: '1mm', paddingLeft: '0mm', border: '0px solid blue' };
								divMetal = { position: 'absolute', top: pagebreaky + 49 + Heightmmy + '%', left: 42 + Heightmmx + '%', zIndex: '9' };
							} else if (posi === 3) {
								divCss = { position: 'absolute', top: pagebreaky + 34.65 + Heightmmy + '%', left: 49.5 + Heightmmx + '%', paddingTop: '1mm', paddingLeft: '0mm', border: '0px solid blue' };
								divMetal = { position: 'absolute', top: pagebreaky + 49 + Heightmmy + '%', left: 91 + Heightmmx + '%', zIndex: '9' };
							} else if (posi === 4) {
								divCss = { position: 'absolute', top: pagebreaky + 66.15 + Heightmmy + '%', left: Heightmmx + '%', paddingTop: '0.5mm', paddingLeft: '0mm', border: '0px solid black' };
								divMetal = { position: 'absolute', top: pagebreaky + 81 + Heightmmy + '%', left: 42 + Heightmmx + '%', zIndex: '9' };
							} else if (posi === 5) {
								divCss = { position: 'absolute', top: pagebreaky + 66.25 + Heightmmy + '%', left: 49.5 + Heightmmx + '%', paddingTop: '0.5mm', paddingLeft: '0mm', border: '0px solid black' };
								divMetal = { position: 'absolute', top: pagebreaky + 81 + Heightmmy + '%', left: 91 + Heightmmx + '%', zIndex: '9' };
							}
							newArray['divCss'] = divCss;
							newArray['divMetal'] = divMetal;
							newArray['dualPrint1css'] = dualPrint1css;
							newArray['dualPrint2css'] = dualPrint2css;

							newArray['divTmp15'] = divTmp15;
							newArray['size'] = size;

							// 위에서 계산 해서 본인 위치에 셋팅 !
							// newSearched 새배열에 값 몽땅 넣기
							newSearched[n] = newArray;
						});
					});

					return (
						<>
							{newSearched.map(
								(sData, dataIdx) => (
									<form key={'WrapErpLabelPrintPercentB' + dataIdx} style={{ overflow: 'hidden' }}>
										<div style={{ ...sData.divCss, ...sData.size, overflow: 'hidden', zIndex: '200' }}>
											{' '}
											{/* 패치 전 border 삭제 , border:"1px solid red" */}
											<div className="row" style={{ height: '1.1%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell colspan2"></div>
											</div>
											<div className="row" style={{ height: '7.5%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell"></div>
												<div className="cell HYheadM14 colspan2">{data.TMP1}</div>
												{/* 지역 */}
												<div className="cell HYheadM11 colspan3" style={{ fontFamily: 'NanumGothic', fontWeight: 'bold' }}>
													{data.TMP0 === 'MPMC' ? '현대파워텍(주)용ⓡ' : data.TMP4 === 'MAMC' ? '글로비스(주)용ⓡ' : '현대자동차(주)/기아(주)ⓡ'}
												</div>
											</div>
											<div className="row" style={{ height: '7%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM16" style={{ fontSize: '15pt' }}>
													{data.TMP2}
												</div>
												{/* 공장 */}

												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM16 colspan4" style={{ paddingTop: '0px', fontSize: '13pt' }}>
													{chkCarc && chkCarc === 'Y' ? kCarcNm : ''}
												</div>
												{/* 차종 chkCarc kCarc kCarcNm data.TMP3 - ERP의 경우 차종명을 가져오도록 수정 */}
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM16 colspan2" style={{ paddingTop: '0px', fontSize: '13pt' }}>
													{data.TMP4}
												</div>
												{/* 납품장소 */}

												<div className="cell"></div>
											</div>
											<div className="row" style={{ height: '8%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM18 colspan6" style={{ fontSize: data.TMP5.trim().length > 11 ? '23pt' : '24pt' }}>
													{data.TMP5.substr(0, 5) + '-' + data.TMP5.substr(5)}
													{/* 품번 */}
												</div>
												<div className="cell"></div>
												<div className="cell HYheadM16 colspan2" style={{ fontSize: '26pt' }}>
													{sData.qty}
												</div>
												{/* 수량 */}
											</div>
											<div className="row" style={{ height: '12%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM18 colspan6" style={{ fontSize: '22pt', paddingTop: '13px' }}>
													{data.TMP6 !== undefined ? data.TMP6.substr(0, 19) : ''}
												</div>
												{/* 품명 */}
												<div
													className="cell HYheadM18 colspan2"
													style={{ fontSize: '28pt', color: data.TMP19 === '좌' || data.TMP19 === 'L' ? 'red' : 'blue', textAlign: 'right', zIndex: '10' }}
												>
													{data.TMP19}
													{/* LH/RH */}
												</div>
											</div>
											<div className="row" style={{ height: '9%' }}>
												<div
													className="cell HYheadM18 colspan3"
													style={{ paddingLeft: '2px', paddingTop: '8px', fontSize: data.TMP35 !== '' && Common.getTextLength(data.TMP35) > 7 ? '13pt' : '27pt' }}
												>
													{data.TMP35 !== '' && Common.getTextLength(data.TMP35) > 7 ? data.TMP35.substr(0, 10) : data.TMP35}
												</div>
												{/* 한글품명 */}
												<div className="cell HYheadM18 colspan4" style={{ fontSize: '13pt', paddingTop: '6px', whiteSpace: 'nowrap' }}>
													{data.TMP2 === 'HV31' || data.TMP2 === 'HE31' || data.TMP2 === 'HM31' || data.TMP2 === 'HK31' ? '적입중량:' : ''}
													{data.TMP2 === 'HV31' || data.TMP2 === 'HE31' || data.TMP2 === 'HM31' || data.TMP2 === 'HK31'
														? data.WTUN === '' || data.WTUN === '0' || data.WTUN === undefined
															? '　　　' + ' kg'
															: (sData.qty * data.WTUN).toFixed(3) + 'kg'
														: ''}
												</div>
												{/* 전주공장일땐 적입중량 표시 */}
												<div className="cell HYheadM18 colspan4" style={{ fontSize: '21pt' }}>
													{data.TMP20 !== undefined ? ('          ' + data.TMP20).slice(-10) : ''}
												</div>
												{/* 사양 */}
											</div>
											<div className="row" style={{ height: '9%' }}>
												<div className="cell HYheadM18 colspan4" style={{ paddingLeft: '2px', paddingTop: '5px', fontSize: '12pt' }}>
													{data.TMP35 !== '' && Common.getTextLength(data.TMP35) > 7 ? data.TMP35.substr(10) : ''}
												</div>
												{/* 한글품명 */}
												<div className="cell colspan5"></div>
											</div>
											<div className="row" style={{ height: '15%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan5" style={{ zIndex: '15', paddingTop: '5px' }}>
													{sData.barcode}
												</div>
												<div className="cell"></div>
												<div className="cell"></div>
											</div>
											<div className="row" style={{ height: '8%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12" style={{ paddingTop: '1px' }}>
													{chkNpdt && chkNpdt === 'Y' ? data.TMP9 : ''}
												</div>
												{/* 납품일자 chkNpdt */}
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12" style={{ paddingTop: '1px' }}>
													{data.TMP12}
												</div>
												{/* 업체코드 */}
												<div className="cell HYheadM12 colspan2" style={{ paddingTop: '1px' }}>
													{data.TMP10}
												</div>
												{/* 업체명 */}
												<div className="cell"></div>

												<div className="cell"></div>
											</div>
											<div className="row" style={{ height: '7%' }}>
												<div className="cell HYheadM12" style={{ fontSize: '11pt', verticalAlign: 'middle', fontWeight: 'normal', paddingLeft: '3mm', paddingTop: '1px' }}>
													{kCkdOem === 'CKD' ? 'Order' : '공정NO'}
												</div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan2" style={{ verticalAlign: 'middle' }}>
													{data.TMP22}
												</div>
												{/* 공정NO */}
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan2" style={{ fontSize: '27pt', paddingLeft: '3mm' }}>
													{data.TMP36 === 'Y' ? 'RHD' : ''}
												</div>
												{/* RHD */}
												<div className="cell"></div>
												<div
													className="cell"
													style={{ fontFamily: 'HY견고딕', fontSize: !data.TMP15 === false && data.TMP15.toString().replace(/\s/gi, '').length > 4 ? '24px' : '30pt', fontWeight: 'bold' }}
												>
													{data.TMP15}
												</div>
												<div className="cell"></div>
												<div className="cell HYheadM12">{data.TMP21}</div>
												{/* 발주번호 */}
												<div className="cell"></div>
											</div>
											<div className="row" style={{ height: '6%' }}>
												<div className="cell HYheadM12" style={{ fontSize: '11pt', fontWeight: 'normal', paddingTop: '1mm', paddingBottom: '1mm', paddingLeft: '5mm' }}>
													{kCkdOem === 'CKD' ? '' : 'HPC'}
												</div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell HYheadM12" style={{ paddingTop: '1mm' }}>
													{data.TMP23}
												</div>
												{/* HPC */}
												<div className="cell"></div>
												<div className="cell HYheadM12" style={{ paddingTop: '1mm' }}>
													{chkLtno && chkLtno === 'Y' ? sData.lotno : ''}
												</div>
												{/* getLOT() chkLtno  */}
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell colspan2"></div>
											</div>
											<div className="row" style={{ height: '9%' }}>
												<div className="cell"></div>
												<div className="cell"></div>
												<div className="cell"></div>

												<div className="cell HYheadM9 colspan6" style={{ fontSize: '7pt', fontWeight: 'bold', paddingTop: '1.5mm', paddingLeft: '1.5mm' }}>
													{sData.bno}
												</div>
												{/* bno */}
												<div className="cell"></div>
												<div className="cell HYheadM12 colspan2" style={{ paddingTop: '0.5mm' }}>
													{data.TMP17}
												</div>
												{/* 용기 Mo */}
											</div>
										</div>

										{/* 중금속 확인필 */}
										{chkMetal === 'Y' ? (
											<div style={{ ...sData.divMetal }}>
												{/* <img src ="/images/heavymetal.jpg" style={{width:"18mm"}} /> */}
												<img src="/images/heavy_metal_confirm.gif" style={{ width: '14mm' }} alt="heavy_metal_confirm" />
											</div>
										) : (
											''
										)}

										{/* 이중출력부분 */}
										{/* XXXXXX */}
										{sData.dualPrint1 === 'T' ? <div style={{ ...sData.dualPrint1css }}>{data.TMP25}</div> : ''}

										{sData.dualPrint2 === 'T' ? <div style={{ ...sData.dualPrint2css }}>{data.TMP30}</div> : ''}
										{/* XXXXXX */}
										{sData.posi === 5 && sData.endpage === 'F' ? (
											// (출력매수-1)
											<div key={'pagebreak' + sData.idx} style={{ background: 'red', breakAfter: 'page', pageBreakAfter: 'always', pageBreakInside: 'avoid' }}></div>
										) : (
											''
										)}
									</form>
								),
								'',
							)}
						</>
					);
			  })
			: '';
		return <PrintStyleB key={'WrapErpLabelPrintPercentB'}>{cardInfo}</PrintStyleB>;
	}
}
export default WrapErpLabelPrintPercentB;
