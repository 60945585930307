/**
 * @Page WrapCkdLabelPrint_QR_A_NEW
 * @Description [ROM4K / ROM4R / ROM4X]납입지시 - ERP_CKD출하관리 - 부품식별표(중포장/용기/중포장2D) QR  ::  A 4매
 **/
import React from 'react';
import CustomDataMatrix from '@/components/atoms/CustomDataMatrix';
import * as Common from '../../utils/Common';
import styled from 'styled-components';
import QRCode from 'react-qr-code';

class WrapCkdLabelPrint_QR_A_NEW extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pathNm: null,
		};
	}

	componentDidMount() {
		if (window.location.pathname.split('/')[2] === 'ROM4X') {
			// ROM4X 부품식별표(중포장 2D) check
			this.setState({ pathNm: window.location.pathname.split('/')[2] });
		}
	}

	// '바코드 = 업체코드(4) + 품번(15) + 수량(5) + LOTNO(3) + 일련번호(4)
	// tmp(0) '회사구분 /tmp(1) '지역 /tmp(2) '공장 /tmp(3) '차종 /tmp(4) '납품장소(하치장)
	// tmp(5) '품번/tmp(6) '품명/tmp(7) '납품수량/tmp(8) '바코드/tmp(9) '납품일자
	// tmp(10) '업체명/tmp(11) "" '공정(공백)/tmp(12) '업체코드/tmp(13) "" ' LOT NO/tmp(14) "" 'HPC(공백)
	// tmp(15) 'CASE(CKD)/tmp(16) "" '발행번호(바코드만들어서 던짐)/tmp(17) "" '용기번호/tmp(18) '부착매수/tmp(19) 'LH/RH
	// tmp(20) "" '사양/tmp(21)  '발주번호(공백)/tmp(22)  'FROM-ORDER(CKD , OEM은 공정)/tmp(23)  'CKD는 공백, OEM은 HPC/tmp(24)  '용기당수
	// tmp(25) = '출력1/tmp(26) = 'x좌표1/tmp(27) = 'y좌표1/tmp(28) = '색상1/tmp(29) = 'FONT/S1
	// tmp(30) = '출력2/tmp(31) = 'x좌표2/tmp(32) = 'y좌표2/tmp(33) = '색상2/tmp(34) = 'FONT/S2
	// tmp(35) = '한글품명/tmp(36) = 'RHD

	render() {
		const PrintStyle = styled.div`
			@media all {
				body {
					background-color: ;
					margin: 0px;
					padding: 0px;
					font-family: 'HY견고딕';
				}
				.ChosunGu11 {
					font-family: 'NanumGothic';
					font-weight: bold;
					font-size: 11pt;
				}
				.ChosunGu14 {
					font-family: 'NanumGothic';
					font-weight: bold;
					font-size: 14pt;
				}
				.ChosunBg {
					font-family: 'ChosunBg';
				}

				.row2 > div {
					display: flex;
					white-space: nowrap;
					z-index: 99;
				}

				.cell {
					display: flex;
					white-space: nowrap;
					z-index: 99;
				}

				.cell_hori {
					display: flex;
					justify-content: center;
				} // 수평 중앙정렬 →

				.cell_veri {
					display: flex;
					align-items: center;
				} // 수직 중앙정렬 ↓

				.aligR {
					flex-direction: row-reverse;
				}

				.row2 {
					display: flex;
					flex-flow: row nowrap;
				}

				.row2 > .cell:nth-child(1) {
					width: 1mm;
				} // background : red ;
				.row2 > .cell:nth-child(2) {
					width: 15mm;
				} // background : blue ;
				.row2 > .cell:nth-child(3) {
					width: 4mm;
				} // background : orange ;
				.row2 > .cell:nth-child(4) {
					width: 4mm;
				} // background : purple ;
				.row2 > .cell:nth-child(5) {
					width: 26mm;
				} // background : beige ;
				.row2 > .cell:nth-child(6) {
					width: 17mm;
				} // background : pink ;
				.row2 > .cell:nth-child(7) {
					width: 4mm;
				} // background : orange ;
				.row2 > .cell:nth-child(8) {
					width: 23mm;
				} // background : green ;
				.row2 > .cell:nth-child(9) {
					width: 10mm;
				} // background : yellow ;
				.row2 > .cell:nth-child(10) {
					width: 25mm;
				} // background : red ;
				.row2 > .cell:nth-child(11) {
					width: 9mm;
				} // background : navy ;
				.row2 > .cell:nth-child(12) {
					width: 1mm;
				} // background : green ;
				.row2 > .cell:nth-child(13) {
					width: 1mm;
				} // background : green ;
			}

			@media print {
				margin: 0 !important;
			}
		`;
		const { printData } = this.props;

		let { kStart, chkMetal, kCkdOem, chkNpdt, chkLtno, kNpdt } = this.props.idata;

		const listSize = printData.length;
		let idx = -1;
		let str = parseInt(kStart) - 1;

		idx = idx + str;

		let vseq = 0;
		let remainChk = 0;
		let mainSearchedDivArr = [];

		const mmToPx = (mm) => {
			let px = mm * 3.7795275591;
			return px;
		};

		const cardInfo_S = printData
			? printData.map((data, index) => {
					if (index === 0) {
						vseq = parseInt(data.SEQ) === 0 ? 0 : parseInt(data.SEQ) - 1; // 바코드 표출 vseq용
					}

					remainChk = vseq; // 새 품번의 remain 여부 체크하기

					let npqt = parseInt(data.NPQT); // 납품수량
					let qypc = parseInt(data.QYPC); // 용기당 수
					let stck = parseInt(data.STCK); // 부착면 수

					let tmp25 = data.TMP25; // WRCLBL1	이중인쇄1 내용
					let tmp28 = data.TMP28; // WRCCOL1	이중인쇄1 컬러

					let tmp30 = data.TMP30; // WRCLBL2	이중인쇄2 내용
					let tmp33 = data.TMP33; // WRCCOL2	이중인쇄2 컬러

					let color1 = '';
					let color2 = '';

					let dualPrint1 = 'F';
					let dualPrint2 = 'F';

					if (tmp25 !== '') {
						dualPrint1 = 'T';

						if (tmp28 === 'B') {
							color1 = 'blue';
						} else if (tmp28 === 'G') {
							color1 = 'green';
						} else if (tmp28 === 'R') {
							color1 = 'red';
						} else if (tmp28 === 'O') {
							color1 = '#ff6600';
						}else if (tmp28 === 'X') {
							color1 = 'rgba(0, 0, 0, 0.7)';
						}
					}
					if (tmp30 !== '') {
						dualPrint2 = 'T';

						if (tmp33 === 'B') {
							color2 = 'blue';
						} else if (tmp33 === 'G') {
							color2 = 'green';
						} else if (tmp33 === 'R') {
							color2 = 'red';
						} else if (tmp33 === 'O') {
							color2 = '#ff6600';
						} else if (tmp33 === 'X') {
							color2 = 'rgba(0, 0, 0, 0.7)';
						}
						
						
					}

					let result = Math.floor(npqt / qypc); // 몫
					let resultIdx = Math.floor(npqt / qypc); // 목 + 나머지의 카운트
					let remain = npqt % qypc; // 나머지

					if (remain > 0) {
						resultIdx = result + 1;
					}

					const newSearched = [];
					let n = 0;

					Array.from(new Array(resultIdx), (i) => {
						Array.from(new Array(stck), (w, j) => {
							// 부착면수 반복

							idx = idx + 1;
							if (j === 0) {
								// 부착면수 = 1
								vseq = vseq + 1; // 새 vseq 마다 + 1 증가
							}

							let iCnt = i + 1;
							const newArray = {};
							let jCnt = j + 1;

							// 새 배열의 n 번째 구하기
							if (i === 0 && j === 0) {
								n = 0;
							} else {
								n = n + 1;
							}

							Object.keys(data).forEach((key) => {
								newArray[key] = data[key];
							});

							newArray['idx'] = idx;
							newArray['vseq'] = vseq;

							//납품수량
							let newQty = 0;
							newArray['qty'] = qypc;
							newQty = qypc;

							newArray['stck'] = stck;

							newArray['endpage'] = 'F';

							newArray['color1'] = color1;
							newArray['color2'] = color2;

							newArray['dualPrint1'] = dualPrint1;
							newArray['dualPrint2'] = dualPrint2;

							if (resultIdx === vseq - remainChk) {
								if (remain > 0) {
									newArray['qty'] = remain;
									newQty = remain;
								}
							} else {
								if (index === 0 && resultIdx === 1) {
									if (remain > 0) {
										newArray['qty'] = remain;
										newQty = remain;
									}
								}
							}

							// END Page 구하기
							if (listSize === index + 1) {
								if (resultIdx === iCnt) {
									if (stck === jCnt) {
										newArray['endpage'] = 'T';
									}
								}
							}

							const lotNo = data.TMP13 === '' ? ' ' : Common.getLOT(data.TMP13); // LOT NO 날짜 가져오기  data.tmp13

							const prno = (data.TMP0 === undefined ? '' : data.TMP0) + '' + ('0000' + parseInt(vseq, 10)).slice(-4);

							//     DataMatrix - code128 바코드 값 만들기
							//    'barcode2 = 기존바코드(data8) + 납품일자(tmp(9))의 년(2)+월(2)+일(2)+부품식별표발행순번(tem(14)의 4자리)
							//    'barcode2 = data8 & Mid(data(9), 3, 2) & Mid(data(9), 6, 2) & Mid(data(9), 9, 2) & Right("0000" & Format(data(14), "####"), 4)
							//    '20190924 이동주 이사의 의견으로 CKD 부품식별표의 SEQ를 납품일자가 아닌 현재 발행 일자로 수정

							// DataMatrix - code128 바코드 값 만들기
							let barcodeValu = '';

							if (kCkdOem === 'CKD') {
								// 중포장일때
								// 바코드 = 업체코드(4)   + 품번(13)                       + 수량(6)                           + 납품일자(yymmdd 8)                      + 일련번호(4)
								// barcodeValu = data.TMP21 +""+ (data.TMP5).padEnd(13," ") +""+ ("000000" + newQty).slice(-6) + "" + ((data.TMP13).slice(-8)).replace(/-/g, '') + ""  + prno ;

								// 수량 6자리에서 7자리로 변경 2022-06-22
								// 바코드 = 업체코드(4)              + 품번(13)                       + 수량(7)                                         + 납품일자(yymmdd 8)                      + 일련번호(4)
								//barcodeValu = data.TMP21 +""+ (data.TMP5.slice(0,13)).padEnd(13," ") +""+ ("0000000" + newQty).slice(-7) + "" + ((data.TMP13).slice(-8)).replace(/-/g, '') + ""  + prno ;

								//검색조건에서 받아온 kNpdt=납품일자로 변경 2022-06-23
								// 바코드 = 업체코드(4)              + 품번(13)                       + 수량(7)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4)
								//  barcodeValu = data.TMP21 +""+ (data.TMP5.slice(0,13)).padEnd(13," ") +""+ ("0000000" + newQty).slice(-7) + "" + ((kNpdt).replace(/-/g, '').slice(-6)) + ""  + prno ;

								// 수량 7 -> 6 으로 변경 셋팅 2022-06-30
								// 바코드 = 업체코드(4)              + 품번(13)                       + 수량(6)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4)
								barcodeValu = data.TMP21 + '' + data.TMP5.slice(0, 14).padEnd(14, ' ') + '' + ('00000000' + newQty).slice(-8) + '' + kNpdt.replace(/-/g, '').slice(-6) + '' + prno;
							} else {
								//용기일때
								// 바코드 = 업체코드(4)   + 품번(13)                       + 수량(6)                           + 납품일자(yymmdd 8)                      + 일련번호(4)
								// barcodeValu = data.TMP22 +""+ (data.TMP5).padEnd(13," ") +""+ ("000000" + newQty).slice(-6) + "" + ((data.TMP13).slice(-8)).replace(/-/g, '') + ""  + prno ;

								// 수량 6자리에서 7자리로 변경 2022-06-22
								// 바코드 = 업체코드(4)              + 품번(13)                       + 수량(7)                                         + 납품일자(yymmdd 8)                      + 일련번호(4)
								//  barcodeValu = data.TMP22 +""+ (data.TMP5.slice(0,13)).padEnd(13," ") +""+ ("0000000" + newQty).slice(-7) + "" + ((data.TMP13).slice(-8)).replace(/-/g, '') + ""  + prno ;

								//검색조건에서 받아온 kNpdt=납품일자로 변경 2022-06-23
								// 바코드 = 업체코드(4)              + 품번(13)                       + 수량(7)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4)
								//  barcodeValu = data.TMP22 +""+ (data.TMP5.slice(0,13)).padEnd(13," ") +""+ ("0000000" + newQty).slice(-7) + "" + ((kNpdt).replace(/-/g, '').slice(-6)) + ""  + prno ;

								// 수량 7 -> 6 으로 변경 셋팅 2022-06-30
								// 바코드 = 업체코드(4)              + 품번(13)                       + 수량(6)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4)
								barcodeValu = data.TMP22 + '' + data.TMP5.slice(0, 14).padEnd(14, ' ') + '' + ('00000000' + newQty).slice(-8) + '' + kNpdt.replace(/-/g, '').slice(-6) + '' + prno;
							}

							let serialNo = kNpdt.replace(/-/g, '').slice(-6) + '' + prno;

							const ptnm = data.TMP6.slice(0, 18);
							newArray['ptnm'] = ptnm;

							// DataMatrix Barcode 만들기 - code128
							const dataMatrixBarcode128 = <CustomDataMatrix val={barcodeValu} height={14} bcid={'code128'} scale={1.8} width={'390px'} />;
							newArray['barcode'] = dataMatrixBarcode128;

							const dataMatrixBarcodeQR = <QRCode style={{ height: mmToPx(25), maxWidth: mmToPx(25), width: mmToPx(25) }} value={barcodeValu} level={'H'} />;
							newArray['qrcode'] = dataMatrixBarcodeQR;

							// DataMatrix Barcode 만들기  - dataMatrix
							const dataMatrixBarcode = this.state.pathNm !== null && this.state.pathNm === 'ROM4X' ? <CustomDataMatrix val={barcodeValu} height={18} scale={1} /> : '';
							newArray['barcodeMatrix'] = dataMatrixBarcode;

							newArray['lotno'] = lotNo;
							newArray['bno'] = barcodeValu;
							newArray['serialNo'] = serialNo;

							// 자기 위치 셋팅 (출력매수)
							const posi = idx % 4;
							newArray['posi'] = posi;

							newSearched[n] = newArray;
						});
					});
					mainSearchedDivArr.push(...newSearched);
			  })
			: '';

		let mainSearchedDivArr2 = mainSearchedDivArr.filter(Boolean);

		let cardInfo = (
			<div
				className="forfor"
				style={{ display: 'flex', flexWrap: 'wrap', overflow: 'hidden', width: mmToPx(280), marginLeft: mmToPx(10.5 + parseInt(Common.getToken('bplx') ? Common.getToken('bplx') : 0, 10)) }}
			>
				{/* Start Point */}
				{Array.from(new Array(kStart - 1), (v, i) => {
					return (
						<div
							className="start_from_test"
							style={{ marginTop: i == 0 || i == 1 ? mmToPx(7 + parseInt(Common.getToken('bply') ? Common.getToken('bply') : 0, 10)) : '0', height: mmToPx(97), flexBasis: mmToPx(140) }}
						></div>
					);
				})}
				{mainSearchedDivArr2.map(
					(sData, dataIdx) => (
						<div
							className="start_from"
							key={'WrapCkdLabelPrint_QR_A_NEW' + dataIdx}
							style={{
								marginTop: sData.posi == 0 || sData.posi == 1 ? mmToPx(7 + parseInt(Common.getToken('bply') ? Common.getToken('bply') : 0, 10)) : '0',
								height: mmToPx(97),
								width: mmToPx(140),
							}}
						>
							<div className="dep_div" style={{ overflow: 'visible', zIndex: '200', position: 'relative', height: '100%', width: '100%' /* , opacity : "0"  */ }}>
								{sData.dualPrint1 === 'T' ? (
									<div
										style={{
											position: 'absolute',
											fontFamily: 'HY견고딕',
											top: parseInt(sData.TMP27, 10) + 'mm',
											left: parseInt(sData.TMP26, 10) + 'mm',
											color: sData.color1,
											fontSize: sData.TMP29 + 'pt',
											fontWeight: 'bold',
											whiteSpace: 'nowrap',
											zIndex: '13',
										}}
									>
										{' '}
										{sData.TMP25}{' '}
									</div>
								) : (
									''
								)}

								{sData.dualPrint2 === 'T' ? (
									<div
										style={{
											position: 'absolute',
											fontFamily: 'HY견고딕',
											top: parseInt(sData.TMP32, 10) + 'mm',
											left: parseInt(sData.TMP31, 10) + 'mm',
											color: sData.color2,
											fontSize: sData.TMP34 + 'pt',
											fontWeight: 'bold',
											whiteSpace: 'nowrap',
											zIndex: '12',
										}}
									>
										{sData.TMP30}
									</div>
								) : (
									''
								)}

								<div className="row2" style={{ height: mmToPx(1) }}>
									{' '}
									<div className="cell"></div>{' '}
								</div>

								<div className="row2" style={{ height: mmToPx(8) }}>
									<div className="cell" style={{ width: mmToPx(67) }}></div>
									<div className="cell ChosunBg cell_hori cell_veri" style={{ width: mmToPx(27), fontSize: sData && sData.TMP1.length > 5 ? '12pt' : sData.TMP1.length > 7 ? '9pt' : '16pt' }}>
										{sData.TMP1}
									</div>
									{/* 지역 */}
									<div className="cell ChosunBg cell_hori cell_veri" style={{ width: mmToPx(46), fontSize: '10pt', letterSpacing: '-1px' }}>
										{sData.TMP0 === 'MPMC' ? '현대파워텍(주)용ⓡ' : sData.TMP4 === 'MAMC' ? '글로비스(주)용ⓡ' : '현대자동차(주)/기아(주)ⓡ'}
									</div>
								</div>

								<div className="row2" style={{ height: mmToPx(8) }}>
									<div className="cell" style={{ width: mmToPx(16) }}></div>
									<div className="cell ChosunBg cell_hori cell_veri" style={{ width: mmToPx(34), fontSize: '18pt' }}>
										{sData.TMP2}
									</div>
									{/* 공장 */}
									<div className="cell " style={{ width: mmToPx(17) }}></div>
									<div className="cell ChosunBg cell_hori cell_veri" style={{ width: mmToPx(27), fontSize: '18pt' }}>
										{sData.TMP4}
									</div>
									{/* 납품장소 */}
									<div style={{ width: mmToPx(10) }}>
										{' '}
										<div className="cell"></div>{' '}
									</div>
									<div className="cell" style={{ width: mmToPx(25), marginTop: '1.5mm' }}>
										{sData.qrcode}
									</div>
									<div style={{ width: mmToPx(11) }}>
										{' '}
										<div className="cell"></div>{' '}
									</div>
								</div>

								<div className="row2" style={{ height: mmToPx(8) }}>
									<div style={{ width: mmToPx(16) }}></div>
									<div
										className="ChosunBg cell_hori cell_veri"
										style={{ width: mmToPx(34), letterSpacing: '0px', fontSize: !sData.TMP3 === false && sData.TMP3.toString().replace(/\s/gi, '').length > 9 ? '13pt' : '14pt' }}
									>
										{sData.TMP3}
									</div>
									{/* 차종 */}
									<div style={{ width: mmToPx(13) }}></div>
									<div className="ChosunBg cell_hori cell_veri" style={{ width: mmToPx(31), fontSize: sData.qty.length > 7 ? '15pt' : '18pt', textAlign: 'center' }}>
										{sData.qty}
									</div>
									<div style={{ width: mmToPx(46) }}></div>
								</div>

								<div className="row2" style={{ height: mmToPx(12) }}>
									<div style={{ width: mmToPx(20) }}></div>
									<div className="ChosunBg cell_veri" style={{ width: mmToPx(74), textAlign: 'left', fontSize: '20pt' }}>
										{sData.TMP5.substr(0, 5) + '-' + sData.TMP5.substr(5)}
										{/* 품번 */}
									</div>
									<div style={{ width: mmToPx(46) }}></div>
								</div>

								<div className="row2" style={{ height: mmToPx(14) }}>
									{/* 품명 */}
									<div style={{ width: mmToPx(20) }}></div>
									<div className="ChosunBg cell_veri" style={{ width: mmToPx(94), textAlign: 'left', fontSize: sData.TMP6.length > 18 ? '16pt' : '20pt', paddingTop: '7px' }}>
										{sData.TMP6}
									</div>
									<div
										className="ChosunBg cell_hori cell_veri"
										style={{
											width: mmToPx(25),
											fontSize: '44pt',
											color: sData.TMP19 === '좌' || sData.TMP19 === 'L' ? 'red' : 'blue',
											textAlign: 'right',
											zIndex: '10' /* ,marginTop:"-4px" */,
										}}
									>
										{sData.TMP19}
									</div>
									{/* LH/RH */}
									<div style={{ width: mmToPx(1) }}></div>
								</div>

								<div className="row2" style={{ height: mmToPx(15) }}>
									<div style={{ width: mmToPx(2) }}></div>
									<div className="ChosunBg cell_veri" style={{ width: mmToPx(45), fontSize: '16pt', letterSpacing: '-0.5px' }}>
										G/W: {sData.TMP35}kg
									</div>
									<div className="ChosunBg cell_veri" style={{ width: mmToPx(44), fontSize: '16pt', letterSpacing: '-0.5px' }}>
										N/W: {sData.TMP20}kg
									</div>
									<div className="ChosunBg cell_veri" style={{ width: mmToPx(43), fontSize: '14pt' }}>
										{sData.serialNo}
									</div>
									<div style={{ width: mmToPx(5) }}>
										{chkMetal === 'Y' ? (
											<div style={{ position: 'absolute', marginLeft: '-12mm', zIndex: '0' }}>
												<img src="/images/heavy_metal_confirm.gif" style={{ width: '15mm' }} alt="heavy_metal_confirm" />
											</div>
										) : (
											''
										)}
									</div>
								</div>

								<div className="row2" style={{ height: mmToPx(8) }}>
									<div style={{ width: mmToPx(24) }}></div>
									<div className="ChosunBg cell_veri" style={{ width: mmToPx(26), fontSize: '12pt' }}>
										{chkNpdt && chkNpdt === 'Y' ? sData.TMP9 : ''}
									</div>
									{/* 납품일자 chkNpdt sData.TMP9=납기일자(원복) 2022-06-27 */}
									<div style={{ width: mmToPx(17) }}></div>
									<div style={{ width: mmToPx(4) }}></div>
									<div className="ChosunBg cell_veri" style={{ width: mmToPx(23), fontSize: '12pt' }}>
										{sData.TMP12}
									</div>
									<div className="ChosunBg cell_veri" style={{ width: mmToPx(45), fontSize: sData.TMP10.length > 10 ? '10pt' : '12pt' }}>
										{sData.TMP10}
									</div>
									<div style={{ width: mmToPx(1) }}></div>
								</div>

								<div className="row2" style={{ height: mmToPx(8) }}>
									<div className="ChosunBg aligR cell_veri" style={{ width: mmToPx(19), fontSize: '11pt' }}>
										{kCkdOem === 'CKD' ? 'Order' : kCkdOem === 'CKD2' ? 'From' : ''}
									</div>
									<div style={{ width: mmToPx(5) }}></div>
									<div className="ChosunBg cell_veri" style={{ width: mmToPx(26), fontSize: '12pt' }}>
										{sData.TMP22}
									</div>
									{/* 공정NO*/}
									<div className="ChosunBg cell_veri" style={{ width: mmToPx(17), fontSize: '27pt' }}>
										{sData.TMP36 === 'Y' ? 'RHD' : ''}
									</div>
									{/* RHD > CKD 화면에서는 사용하지 않는다.*/}
									<div style={{ width: mmToPx(4) }}></div>
									<div className="ChosunBg cell_veri" style={{ width: mmToPx(69), fontSize: '40pt', alignItems: 'flex-start' }}>
										{sData.TMP15}
									</div>
								</div>

								<div className="row2" style={{ height: mmToPx(8) }}>
									<div className="ChosunBg aligR cell_veri" style={{ width: mmToPx(19), fontSize: '11pt' }}>
										{kCkdOem === 'CKD' ? '' : kCkdOem === 'CKD2' ? 'To' : ''}
									</div>
									<div style={{ width: mmToPx(5) }}></div>
									<div className="ChosunBg cell_veri" style={{ width: mmToPx(26), fontSize: '12pt' }}>
										{sData.TMP23}
									</div>
									{/* order*/}
									<div className="ChosunBg cell_veri cell_hori" style={{ width: mmToPx(17), fontSize: '12pt' }}>
										{chkLtno && chkLtno === 'Y' ? sData.lotno : ''}
									</div>
									{/* getLOT() chkLtno  */}
									<div style={{ width: mmToPx(73) }}></div>
								</div>

								<div className="row2" style={{ height: mmToPx(6) }}>
									<div style={{ width: mmToPx(21) }}></div>
									<div className="ChosunBg cell_veri" style={{ width: mmToPx(73), fontSize: '9pt' }}>
										{sData.bno}
									</div>
									{/* bno*/}
									<div style={{ width: mmToPx(10) }}></div>
									<div className="ChosunBg cell_veri" style={{ width: mmToPx(36), fontSize: '12pt' }}>
										{sData.TMP17}
									</div>
									{/* 용기 No */}
								</div>
							</div>

							{sData.posi === 3 && sData.endpage === 'F' ? <div className="page-break"></div> : ''}
						</div>
					),
					'',
				)}
			</div>
		);

		return <PrintStyle key={'WrapCkdLabelPrint_QR_A_NEW'}>{cardInfo}</PrintStyle>;
	}
}
export default WrapCkdLabelPrint_QR_A_NEW;
