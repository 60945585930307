//납입지시 - 시작차 출하관리
import { Route, Switch } from 'react-router-dom';
import ROM81 from '@/pages/A04/ROM81';  //납품서발행
import ROM82 from '@/pages/A04/ROM82';  //발행취소/재인쇄
import ROM84 from '@/pages/A04/ROM84';  //부품식별표
import ROM85 from '@/pages/A04/ROM85';  //시작차 입고현황
import ROM86 from '@/pages/A04/ROM86';  //시작차 발주현황


function A04() {
    return ( 
        <Switch>
            <Route path='/A04/ROM81' component={ROM81} />
            <Route path='/A04/ROM82' component={ROM82} />
            <Route path='/A04/ROM84' component={ROM84} />
            <Route path='/A04/ROM85' component={ROM85} />
            <Route path='/A04/ROM86' component={ROM86} />
        </Switch>
    );
}

export default A04;
