import React, { Component } from 'react';
import Ribbon from '@/components/organisms/Ribbon';
// import ContentXearch from '@/components/organisms/ContentXearch';
// import Content from '@/components/organisms/Content';
// import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
import CommonUtil from '@/utils/CommonUtil'; 
// import { stringToArray } from 'ag-grid-community';
// import ContentTwo from '../../components/organisms/ContentTwo';
// import RNTW0 from '@/pages/B05/RNTW0';


import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';
// import { EditorState } from 'draft-js';
import { EditorState, convertToRaw, ContentState } from "draft-js";
// import { EditorState, convertToRaw, getCurrentContent } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { TimerSharp, Visibility } from '@material-ui/icons';
import { getElementError } from '@testing-library/react';
 
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
// const CODES = require('@/config/commonCode.json');
// const Utils = new CommonUtil();

const MyBlockQW = styled.div`
.wrapper-class{
    width: 97%;
    margin: 0 auto;
    margin-bottom: 4rem;
}
.editor-class {
    height: 500px !important;
    border: 1px solid #b8b8b8 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}
.toolbar-class {        
    border: 1px solid #f1f1f1 !important;
}
`;


class GngsWrite extends Component {

    constructor(props)  {
        super(props);
        //this.grdCarTypeMain = React.createRef();
        this.state = {
            // Common
            token:Common.getToken(),
            userId :Common.getToken("id"),
            userName : Common.getToken("name"),
            tVnt1 : Common.getToken("vand"),
            tComp : Common.getToken("comp"),
            uid : props.data.userId +  props.data.userName,
            idat:Common.getToday(),
            titl:props.data.chk === 1 ? props.data.titl:"",
            gngb:props.data.gngb == undefined ? "1" : props.data.gngb,
            vdnm:props.data.vdnm,
            gubn:props.data.gubn,
            loginVdnm:"",
            numb:props.data.numb,
            chk:props.data.chk,
            text:props.data.text,
            cont:props.data.chk ===1 && Common.getToken("vand") === "M000"?`Rapdos 사용과 관심에 감사드립니다. \n\n
            \n
            \n고객지원팀  
            \ntel)1688-0720  \n
            \n=================================================================================  \n
            \n > `+ props.data.text
            :` >
            \n=================================================================================  
            \n >`+props.data.text,
            close:"",
            //Alert 창 ( Alert 창 OPEN,  Alert 창 구분, Alert 창 메세지)
            openAlert : false, severity : "success", msg : "",
    
            //Dialog 창 (Dialog 창 OPEN, Dialog Transaction(I,U,D),  Dialog 메세지)
            openDlg : false, dlgTrans : "", dlgMsg : "", 
    
            //Api (조회, 등록, 수정, 삭제)
            searchApi : false, insertApi : false, updateApi : false, deleteApi : false,
            
            gngbList : [],

            loadingFlag : false,
            editorState: EditorState.createEmpty() // 에디터 용
        }
    }

   
    /**
     * WillMount
     */
    async componentWillMount() {
        this.setState({loadingFlag : true}); 

        // 구분 공통코드
        let commonCode2 = await Promise.all([this.onSearchComp(`{"pgmid":"CODE", "vand":"****", "gocd" : "GNGS"}`, "gngbList" ) ]);
        // 구분 공통코드
        this.setState({gngbList: commonCode2[0].gngbList, loadingFlag : true}); 
    }

    onSearchVdnm = async (para) => {
        let commonData = this.state;
        let param = encodeURI(para);

        const query = SERVER_URL + "/comcd/search1?query=" + param; 

        await fetch(query, { headers: { Authorization: this.state.token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) { 
                        //elements[element.text] = element.comCode;
                        commonData["loginVdnm"] = data.data[0].text; //업체명
                        this.setState({...commonData});
                }
            })
        return commonData;
    }

    /**
     * DidMount
     */
    componentDidMount() {
        this.onSearchVdnm(`{"pgmid":"WRCVAND", "kVnt1":"${this.state.tVnt1}"}`);
        this.onSearchComp(`{"pgmid":"CODE", "vand":"****", "gocd" : "GNGS"}`, "gngbList" );

        if(this.state.chk != 0){
            
            let cont =`Rapdos 사용과 관심에 감사드립니다. <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        고객지원팀  
                        <br>
                        tel)1688-0720  
                        <br>
                        <br>
                        =================================================================================  
                        <br>
                        <br>`;


                        let inputData = this.state; 
                        inputData["cont"] = this.state.cont;
                        inputData["loginVdnm"] = this.state.loginVdnm;
                        

                        //에디터에 값 추가 
                        const blocksFromHtml = htmlToDraft(this.state.cont);
                        // const blocksFromHtml = htmlToDraft(`${data.data[0].cont}`);

                        if (blocksFromHtml) { 
                            const {contentBlocks, entityMap} = blocksFromHtml; 
                            // https://draftjs.org/docs/api-reference-content-state/#createfromblockarray 
                            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap); 
                            // ContentState를 EditorState기반으로 새 개체를 반환. 
                            // https://draftjs.org/docs/api-reference-editor-state/#createwithcontent 
                            const editorState = EditorState.createWithContent(contentState); 

                            // const contentBlock = htmlToDraft(blocksFromHtml);
                            // const editorState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

                            //setEditorState(editorState); // <== 이부분 
                            inputData["editorState"] = editorState;
                        }

                        this.setState({ ...inputData });
        }
        if(this.state.chk === 0){
            
            let cont =`[기능개선 요청/제안]<br/>
E-Rapdos를 이용해 주셔서 감사합니다.<br/>
랩도스 서비스의 기능개선 요청 및 제안하고자 하시는 사항을 등록해 주십시오.<br/>
기능 개선이나 아이디어를 남겨주시면 검토 후 적용하도록 노력하겠습니다.<br/>

<br/>
[제안내용]<br/>`;


                        let inputData = this.state; 
                        // inputData["cont"] = this.state.cont;
                        inputData["cont"] = cont;
                        inputData["loginVdnm"] = this.state.loginVdnm;

                        //에디터에 값 추가 
                        // const blocksFromHtml = htmlToDraft(this.state.cont);
                        const blocksFromHtml = htmlToDraft(cont);
                        // const blocksFromHtml = htmlToDraft(`${data.data[0].cont}`);

                        if (blocksFromHtml) { 
                            const {contentBlocks, entityMap} = blocksFromHtml; 
                            // https://draftjs.org/docs/api-reference-content-state/#createfromblockarray 
                            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap); 
                            // ContentState를 EditorState기반으로 새 개체를 반환. 
                            // https://draftjs.org/docs/api-reference-editor-state/#createwithcontent 
                            const editorState = EditorState.createWithContent(contentState); 

                            // const contentBlock = htmlToDraft(blocksFromHtml);
                            // const editorState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

                            //setEditorState(editorState); // <== 이부분 
                            inputData["editorState"] = editorState;
                        }
                        this.setState({ ...inputData });
        }
    }


    // /**
    //  * WillReceiveProps
    //  */
    // componentWillReceiveProps () {
    // }

    // /**
    //  * WillUpdate
    //  * @param {*} nextProps 
    //  * @param {*} nextState 
    //  */
    // componentWillUpdate(nextProps, nextState) {
    // }

    // /**
    //  * DidUpdate
    //  * @param {*} prevProps 
    //  * @param {*} prevState 
    //  */
    // componentDidUpdate(prevProps, prevState) {
    // }

    // /**
    //  * WillUnmount
        //  */
        // componentWillUnmount() {
    // }


    /**
     * 공통코드 조회
    */
     onSearchCommon = (para, name) => {

        
    }
    
    // 에디터 값 변경 시 
    onEditorStateChange = (editorState) => {
        let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));


        let newSearchs = this.state;
        newSearchs["editorState"] = editorState;
        newSearchs["cont"] = value;
        this.setState({ ...newSearchs });
    }

        /**
     * 회사구분 코드 조회
    */
         onSearchComp = async (para,name) => {
            let commonData = this.state;
            let param = encodeURIComponent(para);
    
            const query = SERVER_URL + "/comcd/search1?query=" + param; 
            await fetch(query, { headers: { Authorization: this.state.token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success)    
                    {
                        commonData[name] = data.data
                        this.setState({...commonData});
                    }
                })
            return commonData;
        }
       
   



    render() {

        if (this.state.loadingFlag === false) {
            return null
        }
        else {
        
            //State
            const {token, userId, userName, tVnt1, tComp, kDelGubn, mode,uid,numb,gubn,close,
                   openAlert, severity, msg,idat,titl,vdnm,cont,loginVdnm,gngb,gngbList,
                   openDlg, dlgTrans, dlgMsg, compList, searchApi, insertApi, updateApi, deleteApi,setTypeOpen } = this.state;


            /**
             * Control 변경 Event
             * @param {*} e 
             */
            const onChange = async (e) => {
                const { name, value } = e.target;

                let newSearchs = this.state;
                newSearchs[name] = value
                this.setState({ ...newSearchs });
            }

        
            /**
             * 검색조건
            */
            const optionsX = {
                0: [
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                    {"formatter": "blank", "align": "left" },
                ],
            }


        
            /**
             * 조회
             * @param {*} e 
             */
            const onSearch = async (e) => { 
              
            }



            /**
             * 신규 Row추가
             * @param {*} e 
             */
            const onAddRow = async (e) => {
            }



            /**
             * 입력
             * @param {*} e 
             */
            const onInsert = async (e) => {                                                       
                if(this.state.gngb == "" || this.state.gngb == null || this.state.gngb == "0"){
                    this.setState({ openAlert : true, 
                        severity : "error",
                        msg : "구분을 선택해주세요."});
                    return
                }
                else if(this.state.titl == "" || this.state.titl == null ){
                    this.setState({ openAlert : true, 
                        severity : "error",
                        msg : "제목을 입력해주세요."});
                    return
                }            
                //input check
                // let input = await Promise.all ([inputCheck()]);
                // if ( !input[0]) return;

                const fetchInsertData = async () => {

                    const query = SERVER_URL + "/gngs/rwrite"; 
                    let param = JSON.stringify({
                        
                        vand : this.state.tVnt1,
                        iusa : this.state.userId,
                        pseq : this.state.chk === 0 ? this.state.chk : this.state.numb,
                        idat : this.state.idat,
                        titl : this.state.chk === 1 ?" Re:" + this.state.titl :this.state.titl,
                        cont : this.state.cont,
                        // gubn : this.state.gubn,
                        gubn : "Y", //업체만 보기 항상 Y 유지
                        gngb: this.state.gngb,
                        numb:this.state.numb,
                    });

                    await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })                        
                        .then((resp) => resp.json())
                        .then((data) => {

                            if (data.success) {
                                this.setState({ openAlert : true, 
                                                severity : "success",
                                                msg : data.data.message});
                            }

                            else if (data.errors) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.errors.message});
                            }
                            else if (data.error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.message});
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                };
                fetchInsertData();

                setTimeout(() => this.props.onClose(), 1000); //refresh onClose 호출
            }



            /**
             * 수정 
             * @param {*} e 
             * @returns 
             */
            const onUpdate = async (e) => {

    
            }



            /**
             * 삭제
             * @param {*} e 
             */
            const onDelete = async (e) => {

            

            }



            //deleteBlank
            function deleteBlank(argval){

                // if (argval === null || argval === undefined) return "";

                // for(var i=argval.length-1 ;i >= 0; i--){
                    
                //     if(argval.charCodeAt(i) !== 12288 && argval.charCodeAt(i) !== 32 ) //charCodeAt(i)  
                //     return(argval.substring(0,i+1));
                // }
                // return(argval.length>0?argval.substring(0,0):"")
            }



            const onCheck2 = async (e) => {
                let temp = "";
                if(e.target.checked === true){
                   //상단
                   this.state.gubn = "Y";
                   temp = "Y";
                   e.target.checked = true;
               }else{
                   this.state.gubn = "N"; 
                   temp = "N";
                   //e.target.checked = false;  
               }
               
               const { name, value } = e.target;

               let newSearchs = this.state;
               newSearchs[name] = temp
               this.setState({ ...newSearchs });
           }

            // input check
            const inputCheck = () => {
                
                //납품서번호 입력 Check
                // if (kDlNo === "") {
                //     
                //     this.setState({ openAlert : true, 
                //                     severity : "info",
                //                     msg : "납품서 번호를 입력해 주세요."});
                //     document.getElementById("kDlNo").focus();
                //     return false;
                // }
                // return true;
            }
            

            //AlertDialogResult
            const AlertDialogResult = async (e) => {

                //AlertDialog 초기화
                this.setState({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        
                //DialogResult
                if (e.result == "N") return;
        
                //Transaction 구분
                switch (e.Trans) 
                {
                    //등록
                    case "I" :
                        break;
                    //수정
                    case "U" :
                        break;
                    //삭제
                    case "D" :
                        break;
                }
            }

        

            //Grid DropDownList Value
            const extractValues = (mappings) => {
                return  Object.keys(mappings);
            }

            //Grid DropDownList Display
            const lookupValue = (mappings, key) => {
                return mappings[key];
            }        
            
           
            /**
             *Grid Column 정의 
            */
            const columnDefs = [
            ];

            return (
                <>
                    {/* Dialog FRAME */}
                    <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

                    {/* Alert FRAME */}
                    <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {this.setState({openAlert:false, severity:"", msg:""})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
                    <button onClick={() => {this.props.onClose()}} style = {{border:"none", float:"right"}}> <img src="/images/button/close_button.png" alt="logout" /></button> 
                    {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
                    <Ribbon
                        newTabUnused={true}                         // newTabUnused : newTab 표출 버튼 true : 사용안함 // 선언안할경우 default = newTab 사용
                        onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                        //onSearch={onSearch} onSearchName={null}     //조회
                        onInsert={onInsert} onInsertName={"입력"}     //등록
                        
                    >
    
                    <b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>기능개선 요청/제안</b>
                    </Ribbon>
        
                    {/* ContentXearch Frame : 검색조건 */}
                    {/* <ContentXearch
                        optionsX={optionsX}
                        onChange={onChange} /> */}
                     <div style={{ height: "150px", paddingTop: "10px", background:" rgb(241, 243, 247)",marginTop: "8px",borderTop: "1px solid #cecdd9",borderBottom: "1px solid #cecdd9" }}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:100px */}
                        
                        <div className={"grid_type06"} >{/* 우측 FRAME */}
                        <div className="grid_wrap06" style={{height: "15%"}} > {/* 우측 wrap FRAME */}
                            <div className="grid_area" style={{ height: "13%", border: "0px solid #babfc7", width:"100%" }}> {/* Grid area */}
    
                                <table  style={{ width: "100%" }}>
                                    <colgroup>
                                        <col width="10%" />
                                        <col width="22.5%" />
                                        <col width="22.5%" />
                                        <col width="22.5%" />
                                        <col width="22.5%" />
                                    </colgroup>
                                    <tbody>
                                    <tr style={{ height:"28px",border:"0px"}}>
                                        <td style={{border:"0px",background: "#F1F3F7",textAlign:"Right",whiteSpace:"nowrap"}}>작성자 : </td>            
                                        <td colSpan = "4" style={{border:"0px",background: "#F1F3F7"}}>
                                            <input name="uid" onChange={onChange} style={{width:"319px",height:"26px",marginLeft:"15px",border:"1px solid #b8b8b8",padding: "5px"}} value={userId+" "+ userName} readOnly/> 
                                            <input style={{width:"400px",height:"26px",marginLeft:"15px",border:"0px",background: "#F1F3F7",color:"red"}} /*value={"* 작성자의 성함과 연락처를 남겨주시면 연락드리도록 하겠습니다 "}*/ readOnly/> 
                                        </td>
                                    </tr>     
                                    <tr style={{ height:"28px",border:"0px",background: "#F1F3F7"}}>   
                                        <td className="k_upload" style={{verticalAlign: "middle",border:"0px",background: "#F1F3F7",textAlign:"Right",whiteSpace:"nowrap"}}>업체명 : </td>
                                        <td style={{border:"0px",background: "#F1F3F7"}}>
                                            <input name="cont" onChange={onChange} style={{width:"319px",height:"26px",marginLeft:"15px",border:"1px solid #b8b8b8",padding: "5px"}} value = {tVnt1 + " "+this.state.loginVdnm} readOnly/> 
                                        </td>
                                        <td colSpan = "2" style={{border:"0px",background: "#F1F3F7", display : "none"}}>
                                        {//userId === "M000001" ?//관리자만 수정모드,상단위치 체크 가능
                                        // this.state.gubnView === "search"?
                                        this.state.gubn === "Y" ? 
                                        <>
                                        {/* <input type="checkbox" id="mode" name="mode" value="1" onChange={onCheck} style={{ marginLeft: "15px" }} />
                                            <label htmlFor="mode"> 수정모드</label> */}
                                        <input type="checkbox" id="gubn" name="gubn"  onChange={onCheck2} style={{ marginLeft: "15px" }} defaultChecked = {this.state.gubn === "Y" ? true:false} />{/*checked ={this.state.gubn2 === "Y" ? true:false}*/}
                                            <label htmlFor="gubn"> <b>업체만 보기</b> </label>        
                                        </>
                                        :
                                        <>
                                            
                                        <input type="checkbox" id="gubn" name="gubn" value="Y" onChange={onCheck2} style={{ marginLeft: "15px" }} />
                                        <label htmlFor="gubn"> <b>업체만 보기</b> </label>        
                                        </>
                                        //:""
                                        }      
                                        {/* {iusa==userId ?//입력자 아이디와 로그인 아이디가 같을때만 수정모드
                                            <>
                                            <input type="checkbox" id="mode" name="mode" value="1" onChange={onCheck} style={{ marginLeft: "15px" }} />
                                                <label htmlFor="mode"> 수정모드</label>
                                            </>
                                        :""} */}
                                        </td>
                                    </tr>
                                    <tr style={{ height:"28px",border:"0px",background: "#F1F3F7"}}>   
                                        <td style={{verticalAlign: "middle",border:"0px",background: "#F1F3F7",textAlign:"Right",whiteSpace:"nowrap"}}>구분 :</td>
                                        <td>
                                        <select name="gngb" value={gngb == "" || gngb == undefined ? "1" : gngb } onChange={onChange} style={{width:"120px",height:"26px",marginLeft:"15px",border:"1px solid #b8b8b8",padding:"0px 0px 0px 0px"}}>
                                        <option value={"0"}>선택</option>
                                            {gngbList.map(element => ( 
                                                <option key={element.comCode} value={element.comCode}>
                                                        {element.text} 
                                                </option>
                                            ))
                                            }
                                        </select>
                                        </td>
                                    </tr>                                                                 
                                    <tr style={{ height:"28px",border:"0px",background: "#F1F3F7"}}>   
                                        <td className="k_upload" style={{verticalAlign: "middle",border:"0px",background: "#F1F3F7",textAlign:"Right",whiteSpace:"nowrap"}}>제목 : </td>
                                        <td colSpan = "4" style={{border:"0px",background: "#F1F3F7"}}>
                                        <input name="titl" onChange={onChange} style={{width:"519px",height:"26px",marginLeft:"15px",border:"1px solid #b8b8b8",padding: "5px"}} value = {this.state.chk === 0 ? titl :titl}/>
                                        </td>
                                    </tr>                                
                                    </tbody>
                                </table>   {/*<!-- DO 테이블 END --> */}
                            </div>
                        </div>
                        <table  style={{ width: "100%",marginTop: "15px" }}>
                                    <colgroup>
                                        <col width="10%" />
                                        <col width="90%" />
                                    </colgroup>
                                    <tbody>
                                    <tr style={{ height:"28px",border:"0px"}}>
                                        <td style={{border:"0px",textAlign:"Right",verticalAlign: "top",paddingTop:"15px",whiteSpace:"nowrap"}}>내용 : </td>            
                                        <td colSpan = "2" style={{border:"0px"}}>
                                            {/* <textarea name="cont" id = "cont" onChange={onChange} readOnly={this.state.readOnly}
                                                        style={{width:"840px",height:"650px",padding:"5px 5px 5px 5px",marginLeft:"15px",border:"0px solid #b8b8b8",fontSize: "13px"}} value = {this.state.cont}/>  */}
                                                             
                                            <MyBlockQW>

                                                <Editor                                                    
                                                    wrapperClassName="wrapper-class"    // 에디터와 툴바 모두에 적용되는 클래스
                                                    editorState={this.state.editorState}// 초기값 설정
                                                    editorClassName="editor-class"      // 에디터 주변에 적용된 클래스
                                                    toolbarClassName="toolbar-class" // 툴바 주위에 적용된 클래스
                                                    
                                                    toolbar={{
                                                        // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
                                                        list: { inDropdown: true },
                                                        textAlign: { inDropdown: true },
                                                        link: { inDropdown: true },
                                                        history: { inDropdown: false },
                                                    }}  // 툴바 설정

                                                    placeholder="내용을 작성해주세요."
                                                                                                        
                                                    localization={{
                                                        locale: 'ko',
                                                    }}  // 한국어 설정

                                                    onEditorStateChange={this.onEditorStateChange}  // 에디터의 값이 변경될 때마다 onEditorStateChange 호출
                                                />

                                                </MyBlockQW>

                                        </td>
                                    </tr>     
                                    </tbody>
                        </table>   {/*<!-- DO 테이블 END --> */}
                    </div>
    
                    
                        </div>
                </>
            );
        }
        
    }

}


export default GngsWrite;