import React from 'react';
import Layout from '../organisms/Layout';

function MainTemplates({ children }) {
    return (
        <>
            <Layout>
                {children}
            </Layout>
        </>
    );
}

export default MainTemplates;