import React from "react";
import ReactLoading from "react-loading";

import "../../css/main.css";

function CustomLoading({ loading, type, color }) {
  const cType = type ? type : "bars";
  const cColor = color ? color : "#8f9cf4";

  return <>{loading ? <ReactLoading id={"loading"} type={cType} color={cColor} /> : null}</>;
}
export default CustomLoading;
