/**
 * @Page ROM3H
 * @Description [ROM3H]납입지시 - ERP_OEM출하관리 - 납품서발행(VMI)
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import GridRibbon from "@/components/organisms/GridRibbon";
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
 import ReactToPrint from "react-to-print";
 import VmiCardPrint from "@/components/molecules/VmiCardPrint";
 import VmiCardPrintHMG from "@/components/molecules/VmiCardPrintHMG";
 import Loading from "src/components/atoms/CustomLoading2"; 
 import $ from 'jquery';

 import useThrottle from '@/utils/useThrottle'; // 일정시간 버튼 이벤트 막기

  
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();

 
 // Print Style CSS Setting
const pageStyle = `
@media all {
    .pagebreak {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .pagebreak {
      margin-top:1rem;
      display: block;
      page-break-after: always;
    }
  }
  
  @page {
    size: A4 portrait !important;
    margin:7mm 10mm;
  }
  body, div, table, td{
      font-family:굴림체,Gulim ;
  }

`;




 function ROM3H() {
 
    const token = Common.getToken();
    const userId = Common.getToken("id");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const bigi = Common.getToken("bigi");
    const grdRom3hMain = useRef();   //Grid Main
    const grdRom3hList = useRef();   //납품서 발행현황 Grid
    const grdRom3hDetail= useRef();  //납품서 상세현황 Grid
    const printRef = useRef();
    const printBtnRef = useRef();

    const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
    const ckName = Common.getCookie("ck_ename")===null?"":Common.getCookie("ck_ename"); 
    const ckTelno = Common.getCookie("ck_telno")===null?"":Common.getCookie("ck_telno"); 

    let [loading, setLoading] = useState(false); 
	let [throttleBtn, setThrottleBtn] = useState("");  // 버튼 막기 이벤트 설정
    
    //Data
    const [idata, setData] = useState({
        //조회조건
        // kVnt1 : tVnt1,      //T1업체
        kVnt1 : ckVnt1,        //T1업체     
        kComp: tComp,       //회사구분
        kPlnt: "",          //공장
        kGate: "",          //하치장
        kPldt: Common.getToday(),  //소요일자
        kPtno : "",         //품번
        kCano : "",         //차량번호          
        kGubn: "N",       //미소요량 품번포함
        kName: ckName,          //담당자    
        kSttm: Common.getToday(),   //출발일자
        kSthh: Common.getHour(),    //출발시간
        kTelno: ckTelno,         //연락처   
        kEdtm: Common.getToday(),   //도착일자
        kEdhh: Common.getHour(),    //도착시간  

        dupCnt: "",    //발행매수
        chkDup:"N",    //중복발행 체크

        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장
        gateList : [],  //하치장
        canoList : [],  //차량번호
        isToggleOn: true
    }); 
    
            
    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;
  
  
    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      // Dialog Transaction(I,U,D)
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
  
   
    // Params (Insert, Update Parameter)
    let [inserts, setInsert] = useState({}); 

    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    let [insertApi, setInsertApi] = useState(false); 
    let [updateApi, setUpdateApi] = useState(false); 
    let [deleteApi, setDeleteApi] = useState(false); // deleteApi 항목 setting
    let [searchApi2, setSearchApi2] = useState(false); 
    let [deleteApi2, setDeleteApi2] = useState(false); 
    let [searchApi3, setSearchApi3] = useState(false); 
    let [updateApi3, setUpdateApi3] = useState(false); // updateApi3 항목 setting
    let [deleteApi3, setDeleteApi3] = useState(false); 
    let [printdata, setPrintData] = useState([]);   
    let [pos, setPos] = useState([]); //납품서 좌표
    let [clearGrid3, setClearGrid3] = useState(false); 

    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 146px - 50px)"});

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
        setInsertApi(false);
        setUpdateApi(false);
        setDeleteApi(false);
    }, []);

    const onClearApi2 = useCallback(async (e) => {
        setSearchApi2(false);
        setDeleteApi2(false);
    }, []);

    const onClearApi3 = useCallback(async (e) => {
        setSearchApi3(false);
        setUpdateApi3(false);
        setDeleteApi3(false);
    }, []);




    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }
     

    
    /**
     *MOUNT 
    */
    useEffect( () => {
        
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
        //공통코드 조회 (공장)
        onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");
        //공통코드 조회 (차량번호)
        onSearchCommon(`{"pgmid":"CARN"}`, "canoList");

        //납품서 발행현황조회
        onSearch2();
    }, []);

    const onPrintCall = () => { requestPrint(); };
	const requestPrint = () => { onPrint(); };
    const debounceHandler = useThrottle(onPrintCall, 2000);

    useEffect(()=>{ 

		if(throttleBtn === "T"){
			$("#"+"printid").attr("disabled", true);
            setTimeout(() => $("#"+"printid").attr("disabled", false), 1500);
			debounceHandler();

		}else if(throttleBtn === "F"){
			$("#"+"printid").attr("disabled", false); }

		},[throttleBtn] 

	  );
    
    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value} = e.target;
        let val = "";
        let newSearchs = idata; 
        newSearchs[name] = value
        setData({ ...newSearchs });

        //공장 변경시 하치장 조회
        if (name === "kPlnt") {
            val = Common.changeEDTM(idata.kSttm,idata.kSthh);

            newSearchs["kGate"] = "";
            newSearchs["kEdhh"] = val.endHour;
            setData({ ...newSearchs });
            
            onSearchCommon(`{"pgmid":"VGATE","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}"}`, "gateList")
        }
        else if(name === "kSttm"){//출발일자가 선택되면 도착일자도 변경
            newSearchs["kEdtm"] = value;
            
            setData({ ...newSearchs });
        }
        else if(name === "kSthh"){//출발시간이 선택되면 도착시간 = 출발시간 +1
         
           val = Common.changeEDTM(idata.kSttm,value);

           newSearchs["kEdtm"] = val.endDate;
           newSearchs["kEdhh"] = val.endHour;

            setData({ ...newSearchs });
            
        }
        //T1업체, 회사구분 변경시 
        else if (name === "kVnt1" || name === "kComp")  {
            
            newSearchs["plntList"] = [];
            newSearchs["gateList"] = [];
            newSearchs["kPlnt"] = "";
            newSearchs["kGate"] = "";
            setData({ ...newSearchs });

            //공통코드 조회 (공장)
            onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");

            //납품서 발행 조회
            onSearch2();
           
        }
        //하치장, 미소요량 Check 시 조회 Call
        else if  (name === "kGate" || name === "kGubn")  {
            onSearch(); //조회 Call
        }
    }
 
  
  
    /**
     * Grid Event 
     * @param {*} e 
     */
    const grdRom3hMain_onCellValueChanged = async (e) => {

        let data = e.data;
        if (data.npqt === "0" || data.npqt === "") {
            e.node.setSelected(false);
        }
    }
    const grdRom3hMain_onRowClicked = async (e) => {
    }

    //납품서 발행현황 CellValueChanged
    const grdRom3hList_onCellValueChanged = async (e) => {

    }
    //납품서 상세현황 CellValueChanged
    const grdRom3hDetail_onCellValueChanged = async (e) => {
    }

    //납품서 발행현황 Row Click
    const grdRom3hList_onRowClicked = async (e) => {

        let dlno = e.data.dlno;
        if (dlno != null || dlno != "") 
        {
            //납품서 상세현황 조회
            onSearch3(dlno);
        }
    }

    //납품서 발행현황 체크박스 Click
    const grdRom3hList_onRowSelected = async(e) =>{

        let dlno = e.data.dlno;
        if (dlno != null || dlno != "") 
        {
            //납품서 상세현황 조회
            onSearch3(dlno);
        }

    }
  
    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //T1업체
            {
                "formatter": "select", "name": "kVnt1", "labelName": "T1업체", "type": "text", "defaultValue": idata.kVnt1,  
                "codeMode": true,
                "selectAll": "",
                "codes": null,
                "comms": idata.vnt1List,
                "align": "left", 
            }, 
            //회사구분
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": true,
                "selectAll": "",
                "codes": CODES["comp"],
                "comms": null,
                "align": "left", 
            }, 
            {"formatter": "blank", "align": "right" },
            
            //차량번호
            {
                "formatter": "select", "name": "kCano", "labelName": "차량번호", "type": "text", "defaultValue": idata.kCano,  
                "codeMode": false,
                "selectAll": "none",
                "codes": null,
                "comms": idata.canoList,
                "align": "right", 
                "labelType": "insert" 
            }, 
            //담당자
            {
                "formatter": "input", "name": "kName", "labelName": "담당자", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kName,  
                "align": "right", 
                "labelType": "insert", 
            }, 
        ],
        1: [
            //공장
            {
                "formatter": "select", "name": "kPlnt", "labelName": "공장", "type": "text", "defaultValue": idata.kPlnt,  
                "codeMode": false,
                "selectAll": "none",
                "codes": null,
                "comms": idata.plntList,
            }, 
            //하치장
            {
                "formatter": "select", "name": "kGate", "labelName": "하치장", "type": "text", "defaultValue": idata.kGate,  
                "codeMode": false,
                "selectAll": true,
                "codes": null,
                "comms": idata.gateList,
            }, 
            {"formatter": "blank", "align": "right" },
            //출발시간 날짜 & 시간
            {
                "formatter": "dateHour", "name": "kSttm", "name2": "kSthh", "labelName": "출발시간"
                , "defaultValue": idata.kSttm
                , "defaultValue2": idata.kSthh  
                , "align": "right" // (우측 정렬)
                , "labelType": "insert" 
            },
            //연락처
            {
                "formatter": "input", "name": "kTelno", "labelName": "연락처", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kTelno,  
                "align": "right", 
                "labelType": "none", 
                "labelType": "insert" 
            },  
        ],
        2: [
            //소요일자
            {
                "formatter": "input", "name": "kPldt", "labelName": "소요일자", "type": "date", "desc": "", "readOnly" : false,
                "defaultValue": idata.kPldt,  
                "align": "left", 
                "labelType": "none" 
            }, 
            //품번
            {
                "formatter": "input", "name": "kPtno", "labelName": "품번", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kPtno,  
                "align": "left", 
                "labelType": "none", 
            }, 
            //미소요량포함
            {
                
                "formatter": "checkSpan", "name": "kGubn", "labelName": " ",
                "options": [{ "name": "kGubn", "labelName": "미소요량 품번포함", "defaultValue": idata.kGubn }] 
            },
            
            //도착시간 날짜 & 시간
            {
                "formatter": "dateHour", "name": "kEdtm", "name2": "kEdhh", "labelName": "도착시간",
                "defaultValue": idata.kEdtm,  
                "defaultValue2": idata.kEdhh,  
                "labelType": "insert", 
                "align": "right", // (우측 정렬)
            },
            {
                "formatter": "checkInput",
                "labelOptions": [{ "name": "chkDup", "labelName": "발행매수", "defaultValue": idata.chkDup }], 
                "name": "dupCnt", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.dupCnt,  
                "align": "right", 
                "tooltip":"발행매수 체크 후 수량 입력하면 입력한 숫자만큼 동일한 납품서가 출력됩니다.", 
                
                "labelType": "insert1"
            },
        ]
    }
  
  
  
    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {
        
        //T1업체선택 Check
        if (idata.kVnt1 == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "1차업체를 선택하십시요 !"});
            document.getElementById("kVnt1").focus();                         
            return ;
        }

        //회사구분선택 Check
        if (idata.kComp == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "회사구분을 선택하십시요 !"});
            document.getElementById("kComp").focus();                         
            return ;
        }

        //공장선택 Check
        if (idata.kPlnt == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "공장을 선택하십시요 !"});
            document.getElementById("kPlnt").focus();                         
            return ;
        }
        
        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);

        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                     "kGate":"${idata.kGate}","kPtno":"${idata.kPtno}","kPldt":"${idata.kPldt}",
                     "kGubn":"${idata.kGubn}" }`;
        
        let param = encodeURI(para);

        const query = SERVER_URL + "/rom3h/search?query=" + param;
        setSearchApi(query); // Search API

        onSearch2();//그리드2(납품서발행현황) 재조회
    }

  
  
    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
    }

  
      
    /**
     * 입력 (발행)
     * @param {*} e 
     */
    const onInsert = async (e) => {

        //T1업체선택 Check
        if (idata.kVnt1 == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "1차업체를 선택하십시요 !"});
            document.getElementById("kVnt1").focus();                         
            return ;
        }

        //회사구분선택 Check
        if (idata.kComp == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "회사구분을 선택하십시요 !"});
            document.getElementById("kComp").focus();                         
            return ;
        }

        //공장선택 Check
        if (idata.kPlnt == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "공장을 선택하십시요 !"});
            document.getElementById("kPlnt").focus();                         
            return ;
        }

        //하치장선택 Check
        if (idata.kGate == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "하치장을 선택하십시요 !"});
            document.getElementById("kGate").focus();                         
            return ;
        }

        //차량번호 선택 Check
        if (idata.kCano === "") {

            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "차량번호를 선택해 주세요."});
            document.getElementById("kCano").focus();
            return;
        }


        //발행시 Linc 업체 체크


        //출발시간, 도착시간 Check
        if (idata.kSttm.replace(/-/g, '') > idata.kEdtm.replace(/-/g, '') || (idata.kSttm.replace(/-/g, '') === idata.kEdtm.replace(/-/g, '') && idata.kSthh > idata.kEdhh)) {

            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "출발시간이 도착시간보다 클 수 없습니다."});
            if (idata.kSttm.replace(/-/g, '') >idata.kEdtm.replace(/-/g, '')) {
                document.getElementById("kSttm").focus();
            } else {
                if (idata.kSthh > idata.kEdhh) {
                    document.getElementById("kSthh").focus();
                }
            }
            return;
        }

        //select nodes
        let selectedData = grdRom3hMain.current.getSelectedNodes();

        //  // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "발행할 자료가 없습니다."});
            return false;
        }

        for(var i=0;i<selectedData.length;i++){
            if (selectedData[i].data.gate !== idata.kGate) {
                setMessage({ openAlert : true, 
                    severity : "info",
                    msg : "하치장을 다시 선택해주세요"});
                    e.currentTarget.disabled = false;
                    return false;
            }
        }


       
       if(idata.chkDup === "Y"){// 8건이하만 발행가능
        if (selectedData.length > 8) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "8건이하만 발행가능합니다. 다시 선택해주세요."});
            return false;
            }
       }
       

        //Insert Parameter
        let newInserts = inserts;
        newInserts["vnt1"] = idata.kVnt1;  //  1차업체
        newInserts["comp"] = idata.kComp;  // 회사구분

        newInserts["cano"] = idata.kCano;  // 차량번호
        newInserts["plnt"] = idata.kPlnt;  // 공장
        newInserts["gate"] = idata.kGate;  // 하치장

        newInserts["pldt"] = idata.kPldt.replace(/-/g, '');    // 소요일자
        newInserts["npdt"] = idata.kSttm.replace(/-/g, '') + "" + idata.kSthh; // 출발일시 (YYYYMMDDHH)
        newInserts["dcdt"] = idata.kEdtm.replace(/-/g, '') + "" + idata.kEdhh; // 도착일시 (YYYYMMDDHH)

        newInserts["chkDup"] = idata.chkDup;  // 중복발행 체크
        newInserts["dupCnt"] = idata.dupCnt;  // 중복발행 개수
        newInserts["cnt"] = selectedData.length;  //하위품목 선택 개수

        setInsert({ ...newInserts });

        const query = SERVER_URL + "/rom3h/insert";
        setInsertApi(query); // Insert API
    }
  
  
    
  
    /**
     * 수정 (개별발행)  -> 여러건 선택 후 클릭 시 1건씩 나눠서 발행됨(2023-04 업체 요청)
     * @param {*} e 
     * @returns 
     */
    const onSingleInsert = async (e) => {

        if(idata.chkDup=="Y"){
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "발행매수에 체크가 되어있으면 개별 발행을 하실 수 없습니다."});
            document.getElementById("chkDup").focus();                         
            return ;
        }
       //T1업체선택 Check
       if (idata.kVnt1 == "")
       {
           setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "1차업체를 선택하십시요 !"});
           document.getElementById("kVnt1").focus();                         
           return ;
       }

       //회사구분선택 Check
       if (idata.kComp == "")
       {
           setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "회사구분을 선택하십시요 !"});
           document.getElementById("kComp").focus();                         
           return ;
       }

       //공장선택 Check
       if (idata.kPlnt == "")
       {
           setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "공장을 선택하십시요 !"});
           document.getElementById("kPlnt").focus();                         
           return ;
       }

       //하치장선택 Check
       if (idata.kGate == "")
       {
           setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "하치장을 선택하십시요 !"});
           document.getElementById("kGate").focus();                         
           return ;
       }

       //차량번호 선택 Check
       if (idata.kCano === "") {

           setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "차량번호를 선택해 주세요."});
           document.getElementById("kCano").focus();
           return;
       }


       //출발시간, 도착시간 Check
       if (idata.kSttm.replace(/-/g, '') > idata.kEdtm.replace(/-/g, '') || (idata.kSttm.replace(/-/g, '') === idata.kEdtm.replace(/-/g, '') && idata.kSthh > idata.kEdhh)) {

           setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "출발시간이 도착시간보다 클 수 없습니다."});
           if (idata.kSttm.replace(/-/g, '') >idata.kEdtm.replace(/-/g, '')) {
               document.getElementById("kSttm").focus();
           } else {
               if (idata.kSthh > idata.kEdhh) {
                   document.getElementById("kSthh").focus();
               }
           }
           return;
       }

       //select nodes
       let selectedData = grdRom3hMain.current.getSelectedNodes();
       let cnt = selectedData.length;
       // 선택 값 체크
       if (selectedData.length === 0) {
           //Alert 창 OPEN
           setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "발행할 자료가 없습니다."});
           return false;
       }
        
       //Insert Parameter
       let newInserts = inserts;
       newInserts["vnt1"] = idata.kVnt1;  //  1차업체
       newInserts["comp"] = idata.kComp;  // 회사구분

       newInserts["cano"] = idata.kCano;  // 차량번호
       newInserts["plnt"] = idata.kPlnt;  // 공장
       newInserts["gate"] = idata.kGate;  // 하치장

       newInserts["pldt"] = idata.kPldt.replace(/-/g, '');    // 소요일자
       newInserts["npdt"] = idata.kSttm.replace(/-/g, '') + "" + idata.kSthh; // 출발일시 (YYYYMMDDHH)
       newInserts["dcdt"] = idata.kEdtm.replace(/-/g, '') + "" + idata.kEdhh; // 도착일시 (YYYYMMDDHH)
      

       setInsert({ ...newInserts });


       //확인 메세지 
       setDlgMessage({openDlg : true,  
                      dlgTrans : "개별발행", 
                      dlgMsg : "선택한 납품목록이 한 건씩 발행됩니다.\n 진행하시겠습니까?" });
    }
  
    /**
     * 삭제
     * @param {*} e 
     */
    const onDelete = async (e) => {
    }



    /**
     * 납품서 발행현황 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch2= async (e) => {
        
        setClearGrid3(true); // grid 3 초기화

        //T1업체선택 Check
        if (idata.kVnt1 == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "1차업체를 선택하십시요 !"});
            document.getElementById("kVnt1").focus();                         
            return ;
        }

        //회사구분선택 Check
        if (idata.kComp == "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "회사구분을 선택하십시요 !"});
            document.getElementById("kComp").focus();                         
            return ;
        }

        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/rom3h/search2?query=" + param;
        setSearchApi2(query); // Search API
    }


    /**
     * 남품서 발행현황 취소
     * @param {*} e 
     * @returns 
     */
    const onDelete2 = async (e) => {
        
        //select nodes
        let selectedData = grdRom3hList.current.getSelectedNodes();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "선택한 데이터가 없습니다."});
            return false;
        }

        //삭제 확인 메세지 
        setDlgMessage({openDlg : true,  
                       dlgTrans : "D2", 
                       dlgMsg : "선택한 데이터를 취소 하시겠습니까?" });
        
    }





    const onPrintChk = (e) => {

         // 담당자 입력 Check
         if (idata.kName == "")
         {
             setMessage({ openAlert : true, 
                          severity : "info",
                          msg : "담당자를 입력하십시요."});
             document.getElementById("kName").focus();                         
             return ;
         }
 
         // 연락처 입력 Check
         if (idata.kTelno == "")
         {
             setMessage({ openAlert : true, 
                          severity : "info",
                          msg : "연락처를 입력하십시요."});
             document.getElementById("kTelno").focus();                         
             return ;
         }
 
 
         // Cookie 값 셋팅
         Common.setCookie("ck_ename",idata.kName,1);
         Common.setCookie("ck_telno",idata.kTelno,1);    

        let selectedData = grdRom3hList.current.getSelectedData();

		if (selectedData.length === 0) {
			setMessage({ openAlert: true, severity: 'info', msg: '선택한 데이터가 없습니다.' });
			e.currentTarget.disabled = false;
			return false;
		}

        setThrottleBtn("T"); // onPrint 호출은 콜백이 돌아올때 실행한다.

        return true;
    }
    
    /**
     * 납품서 발행현황 인쇄 
     * @param {*} e 
     * @returns 
     */
    const onPrint = async (e) => {
        
        //select nodes
        let selectedData = grdRom3hList.current.getSelectedData();

        const fetchData = async () => {
            setLoading(true); 

            let selectDlnoList = [];
            selectedData.map(function (data, i) {

               
                if (data.dlno) {
                    selectDlnoList.push(data.dlno);
                }
            });

            
            // 납품서발행(VMI ROM3H) , 납품서발행(PO ROM3L), 출하정보전송(ROM4P), 납품서일괄발행(VMI REX3H) - "/rom3h/chulha" 모두 여기 사용 !!!!
            const query = SERVER_URL + "/rom3h/chulha"; 
            let param = JSON.stringify({
                vnt1: idata.kVnt1,
                comp: idata.kComp,
                ename : idata.kName,
                telno : idata.kTelno,
                func: "CH", // CH : 출하전송 
                rprint: 'N',
                dlnolist: selectDlnoList,
            });
           
            await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json"}, body: param })
                .then((resp) => resp.json())
                .then((data) => {

                    let printList = {};
                    let ptlist = [];
                    let posList = {};
                    
                    if (data.success) {
                        setPrintData(data.data.list);

                        setMessage({ openAlert : true, 
                                     severity : "success",
                                     msg : "인쇄 조회 성공 하였습니다."});

                       
                        
                        for(let i=0; i<data.data.list.length;i++){
                            let detailPrintInfo = [];
                            let headPrintInfo2 = {};
                            let detailPrintInfo2 = {};
                            //헤더
                            headPrintInfo2={
                                cano:data.data.list[i].head.cano,
                                companyNm:data.data.list[i].head.conm,
                                issueNo:data.data.list[i].head.dlno,
                                vandcd:data.data.list[i].head.vnt1,
                                vandnm:data.data.list[i].head.vdnm,
                                plnm:data.data.list[i].head.plnm,
                                gate:data.data.list[i].head.gate,
                                deliveryDate:data.data.list[i].head.prif,
                                chek:data.data.list[i].head.chek,
                                posx: parseInt(data.data.list[i].head.posx),
                                posy: parseInt(data.data.list[i].head.posy),
                            }
                            for(let z = 0; z<data.data.list[i].detail.length;z++){
                                //디테일
                                detailPrintInfo2={
                                    cano: data.data.list[i].detail[z].CANO,
                                    companyNm:data.data.list[i].detail[z].CONM,
                                    issueNo: data.data.list[i].detail[z].DLNO,
                                    vandcd:data.data.list[i].detail[z].VNT1,
                                    vandnm:data.data.list[i].detail[z].VDNM,
                                    plnm:data.data.list[i].detail[z].PLNM,
                                    gate: data.data.list[i].detail[z].GATE,
                                    plnt: data.data.list[i].detail[z].PLNT,
                                    deliveryDate:data.data.list[i].detail[z].PRIF,
                                    case1:data.data.list[i].detail[z].CASE1,
                                    casec:data.data.list[i].detail[z].CASEC,
                                    dlno: data.data.list[i].detail[z].DLNO,
                                    dopt: data.data.list[i].detail[z].DOPT,
                                    itemNm: data.data.list[i].detail[z].PTNM,
                                    itemNo: data.data.list[i].detail[z].PTNO,
                                    prgb: data.data.list[i].detail[z].PRGB,
                                    crdx: data.data.list[i].detail[z].CRDX,
                                    odno: data.data.list[i].detail[z].ODNO,
                                    dono: data.data.list[i].detail[z].DONO,
                                    prcd: data.data.list[i].detail[z].PRCD!=" "?"["+data.data.list[i].detail[z].PRCD+"]":" ",
                                    qypc: data.data.list[i].detail[z].QYPC,
                                    seq: data.data.list[i].detail[z].DSEQ,
                                    npqt: data.data.list[i].detail[z].NPQT,
                                    sjgb: data.data.list[i].detail[z].SJGB,
                                    feed: data.data.list[i].detail[z].FEED,
                                    loct: data.data.list[i].detail[z].LOCT
                                }
                                detailPrintInfo.push(detailPrintInfo2);
                                //헤더정보를 다음 데이터의 헤더 정보를 불러옴(긴급건으로 임시 대응)
                                headPrintInfo2.cano=data.data.list[i].detail[z].CANO;
                                headPrintInfo2.companyNm=data.data.list[i].detail[z].CONM;
                                headPrintInfo2.issueNo=data.data.list[i].detail[z].DLNO;
                                headPrintInfo2.vandcd=data.data.list[i].detail[z].VNT1;
                                headPrintInfo2.vandnm=data.data.list[i].detail[z].VDNM;
                                headPrintInfo2.plnm=data.data.list[i].detail[z].PLNM;
                                headPrintInfo2.gate=data.data.list[i].detail[z].GATE;
                                headPrintInfo2.deliveryDate=data.data.list[i].detail[z].PRIF;
                            }
                            printList = {
                                headPrintInfo:headPrintInfo2,   
                                detailPrintInfo:detailPrintInfo
                            }
                            ptlist.push(printList);

                            posList={
                                posx: parseInt(data.data.list[i].head.posx),
                                posy: parseInt(data.data.list[i].head.posy)
                            }
                        }
                        
                        setPos(posList);
                        if(data.data.list[0].head.bigi === "Y"){
                            onPrint2(ptlist);
                        }else{
                            printBtnRef.current.dispatchEvent(new Event("click", { bubbles: true }));
                        }
                        setTimeout(() => onSearch2(), 300);
                        setThrottleBtn("F");
                    }

                    if (data.error) {
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : data.data.message==""||data.data.message ==undefined?"조회 실패":data.data.message});
                        setTimeout(() => onSearch2(), 300);
                        setThrottleBtn("F");
                    }

                    if(data.success===false){
                        setMessage({ openAlert : true, 
                            severity : "error",
                            msg : data.data.message!=""||data.data.message !=undefined?data.data.message:"조회 실패"});
                        setTimeout(() => onSearch2(), 300);
                    }
                    setThrottleBtn("F");
                })
                .catch((error) => {
                    // 에러 응답 처리
                    if (error) { 
                        
                        setMessage({ openAlert : true, 
                                     severity : "error",
                                     msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                        setThrottleBtn("F");
                    }
                });
                setLoading(false); 
        };

        fetchData();
        onSearch2();
    }


    const onPrint2 = async (printData) => {

        
        let ptdata = JSON.stringify(printData);
        let body = {
            "userCode":userId,
            "type":"vmicard",
            "json":ptdata.replaceAll('"','\''),
            "state":"REQ"
        };
        let data = JSON.stringify(body);

        const query = SERVER_URL + "/bigi/print"; 
        await fetch(query, { method: "POST", headers: {"Content-Type":"application/json"}, body: data })
        .then((resp) => resp.json())
        .then(data => {
            let str = data.data;
            if(str.includes('bigi://vmicard') === true){
                window.location.href = data.data.replaceAll("\"","");
                setMessage({ openAlert : true, 
                    severity : "success",
                    msg : "BIGI를 확인해주세요."});
            }else{
                setMessage({ openAlert : true, 
                    severity : "error",
                    msg : "BIGI 프로그램을 다시 시도해주세요."});
            }            
        })
        .catch(error => {
            if (error) {
                setMessage({ openAlert : true, 
                                severity : "error",
                                msg : "BIGI 프로그램을 재시작 후 다시 시도해주세요."});
            }
        });
        setTimeout(() => onSearch2(), 300);   
    }


    /**
     * 납품서 상세현황 조회 
     * @param {*} dlno 
     */
    const onSearch3= async (dlno) => {

        let para = `{"vnt1":"${idata.kVnt1}","dlno":"${dlno}"}`;
        let param = encodeURI(para);

        const query = SERVER_URL + "/rom3h/search3?query=" + param;
        setSearchApi3(query); // Search API
    }

    /**
     * 납품서 상세현황 수정 
     * @param {*} e 
     */
    const onUpdate3 = async (e) => {
        
        //select nodes
        let selectedData = grdRom3hDetail.current.getSelectedData();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "수정할 데이터를 선택해 주세요."});
            return false;
        }

        const query = SERVER_URL + "/rom3h/update";
        setUpdateApi3(query); // Update API
    }


    /**
     * 납품서 상세현황 납품품목삭제
     * @param {*} e 
     */
    const onDelete3 = async (e) => {

         //select nodes
         let selectedData = grdRom3hDetail.current.getSelectedNodes();

         // 선택 값 체크
         if (selectedData.length === 0) {
             //Alert 창 OPEN
             setMessage({ openAlert : true, 
                          severity : "info",
                          msg : "선택한 데이터가 없습니다."});
             return false;
         }
 
         //삭제 확인 메세지 
         setDlgMessage({openDlg : true,  
                        dlgTrans : "D3", 
                        dlgMsg : "선택한 납품목록을 삭제하시겠습니까?" });

    }

    
    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});

        

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans) 
        {
            //등록
            case "개별발행" :
                const query = SERVER_URL + "/rom3h/singleInsert";
                setInsertApi(query); // Insert API
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D" ://test
            //setDeleteApi(query); // Delete API
            break;
            case "D2" :
                //const queryD2  = SERVER_URL + "/rom3h/delete";
                const fetchData = async () => {
                    let selectedData = grdRom3hList.current.getSelectedData();
                    let selectDlnoList = [];
        
                    for(var i=0;i<selectedData.length;i++){
                        if(selectedData.length === 1){
                            if (selectedData[i].chul === "Y") {
                                setMessage({ openAlert : true, 
                                    severity : "error",
                                    msg : "출하전송된 납품서입니다. 회수 후 삭제해주세요"});
                                    return false;
                            }
                        }else{
                            if (selectedData[i].chul === "Y") {
                                setMessage({ openAlert : true, 
                                    severity : "info",
                                    msg : "출하전송된 납품서가 포함되어 있습니다.확인 후 다시 인쇄해 주세요"});
                                    return false;
                            }
                        }
                    }

                    selectedData.map(function (data, i) {
                        if (data.dlno) {
                            selectDlnoList.push(data.dlno);
                        }
                    });
    
                    const query2 = SERVER_URL + "/rom3h/delete"; 

                    let param = JSON.stringify({
                        vnt1: idata.kVnt1,
                        comp: idata.kComp,
                        ename : idata.kName,
                        telno : idata.kTelno,
                        func: "CL",
                        rprint: 'N',
                        dlnolist: selectDlnoList,
                        selectedData:selectedData
                    });
    
                    await fetch(query2, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })                        
                        .then((resp) => resp.json())
                        .then((data) => {
    
                            if (data.success) {
                                setMessage({ openAlert : true, 
                                            severity : "info",
                                            msg : data.data.message==""||data.data.message ==undefined?"삭제에 성공하였습니다.":data.data.message});
                                setTimeout(() => onSearch2(),onSearch(), 300);
                            }
                            if (data.success===false) {
                                setMessage({ openAlert : true, 
                                             severity : "error",
                                             msg : data.errors.message==""||data.errors.message ==undefined?"삭제에 실패하였습니다.":data.errors.message});
                                setTimeout(() => onSearch2(), 300);
                            }
                            if (data.error) {
                                setMessage({ openAlert : true, 
                                             severity : "error",
                                             msg : data.errors.message==""||data.errors.message ==undefined?"삭제에 실패하였습니다.":data.errors.message});
                                setTimeout(() => onSearch2(), 300);
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {

                                setMessage({ openAlert : true, 
                                    severity : "error",
                                    msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                };
                fetchData();
                setDeleteApi2(false); // Update API
                break;

            case "D3" :
                const queryD3 = SERVER_URL + "/rom3h/detail_delete";
                setDeleteApi3(queryD3); // Delete API
                break;
        }
    }
      
    
    /**
     * 엑셀
     * @param {*} e 
     */
     const onExcel = async (e) => {
        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                     "kGate":"${idata.kGate}","kPtno":"${idata.kPtno}","kPldt":"${idata.kPldt}",
                     "kGubn":"${idata.kGubn}" }`;

        let param = encodeURIComponent(para);
        const query = SERVER_URL + "/rom3h/excel?query=" + param;

        setLoading(true);
        
        await fetch(query, {headers: {Authorization: token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'rom3h.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })

        setLoading(false);
        setMessage({ openAlert : true, 
                        severity : "success",
                        msg : "엑셀파일이 생성되었습니다."});
    }


    /**
     * Grid Bind Data
     * @returns 
    */
    const onGridBindData = (para) => {
    }
  
    /**
     *Grid Column 정의 
    */
    //Main Grid Column
    const columnDefs = [
        { headerName: "vnyn(h)", field: "vnyn", sortable: true, filter: true, flex: 1, minWidth: 280, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true }, //valueGetter: Utils.partnoGetter,
        { headerName: "card(H)", field: "card", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true },  
        { headerName: "하치장", field: "gate", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
        { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 140, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
        { headerName: "소요량", field: "plqt", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false },
        { headerName: "납품수량", field: "npqt", sortable: true, filter: true, flex: 1, minWidth: 110, editable: true, cellEditor:"numericEditor", cellEditorParams:{maxLength:13}, headerClass: "editCell", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false }, 
        { headerName: "용기당수", field: "qypc", sortable: true, filter: true, flex: 1, minWidth: 110, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false
             ,cellStyle: params => {if (params.data.vnyn === "Y") {return {color: "rgb(243 0 0)",fontWeight: "bold"};}return null;}}, 
        { headerName: "납품누계", field: "nqty", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false}, 
        { headerName: "05:30", field: "pl01", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "08:30", field: "pl02", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "10:30", field: "pl03", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "13:30", field: "pl04", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "15:30", field: "pl05", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "17:30", field: "pl06", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "20:30", field: "pl07", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "22:30", field: "pl08", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "01:30", field: "pl09", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "03:30", field: "pl10", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}
    ];
    //납품서 발행 Grid Column    
    const columnDefs1 = [
        { headerName: "vnt1(H)", field: "vnt1", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true},
        { headerName: "comp(H)", field: "comp", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true},  
        { headerName: "공장", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.chul == "N"){return {color : "red"};} return null;}},
        { headerName: "하치장", field: "gate", sortable: true, filter: true, flex: 1, minWidth: 60, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.chul == "N"){return {color : "red"};} return null;}},
        { headerName: "납품서번호", field: "dlno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.chul == "N"){return {color : "red"};} return null;}},
        { headerName: "납품일시", field: "npdt", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.chul == "N"){return {color : "red"};} return null;}},
        { headerName: "건수", field: "pcnt", sortable: true, filter: true, flex: 1, minWidth: 30, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.chul == "N"){return {color : "red"};} return null;}},                
        { headerName: "출하전송", field: "chul", sortable: true, filter: true, flex: 1, minWidth: 30, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.chul == "N"){return {color : "red"};} return null;}},    
        { headerName: "메시지", field: "msg", sortable: true, filter: true, flex: 1, minWidth: 340, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false, tooltipField :'msg' ,cellStyle : params => {if(params.data.msg.length !== 1 || params.data.chul == "N"){return {color : "red"};} return null;}},            
    ];
    //납품서 상세현황 Grid Column
    const columnDefs2 = [
        { headerName: "vnt1(H)", field: "vnt1", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "dlno(H)", field: "dlno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "pldt(H)", field: "pldt", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "chul(H)", field: "chul", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true },  
        { headerName: "순번", field: "dseq", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
        { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 190, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
        { headerName: "계약번호", field: "dono", sortable: true, filter: true, flex: 1, minWidth: 190, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
        { headerName: "납품수량", field: "npqt", sortable: true, filter: true, flex: 1, minWidth: 110, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", valueFormatter: Utils.numberFormatter, singleClickEdit: true, hide: false }, 
        { headerName: "용기수", field: "ccnt", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, valueFormatter: Utils.numberFormatter, hide: false}, 
    ];
  

    const onClick = async (e) => {
        setPanded(!panded);


        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 45px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 146px - 50px)"
              });
        }

    }
      
    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={5000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

            {/* Loading */}
            <Loading loading={loading} />

            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onInfoMsg={"상단 그리드의 용기당수량의 빨강색표시는 <br/> 납입지시 > 기초관리 > ERP_품목관리화면의 사용자지정 용기당수량항목의 사용유무로 표출됩니다."}
                onSearch={onSearch} onSearchName={null}     //조회
                onInsert={onInsert} onInsertName={"발행"}   //발행
                onUpdate={onSingleInsert} onUpdateName={"개별발행"}   //발행
                onExcel={onExcel} onExcelName ={null}   //Excel
            >
                {"납입지시 > ERP_OEM 출하관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardMedium, NanumGothic, sans-serif" }}>납품서발행(VMI)</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick = {onClick} />


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:146px */}
                <div className={"grid_type01"}  style={{height:panded===true?"100%":"200%"}}> {/* 상단 FRAME */}
                    <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                        <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                            <Grid
                                ref={grdRom3hMain}
                                gridId={"ROM3H"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                pagination={false}                  //  쪽수 매기기 여부
                                paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                                rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={true}      // cell selection drag mode
                                isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                insertApi={insertApi}               // 등록
                                insertParam={inserts}               // 등록 및 수정시 Parmeter
                                insertRefresh={()=> {onSearch(); onSearch2();} } // 등록이후 
                                onClearApi={onClearApi}             // 초기화
                                onCellValueChangedEvent={grdRom3hMain_onCellValueChanged}
                                onRowClickEvent={grdRom3hMain_onRowClicked}
                                useMessage={false}
                            />
                        </div>
                    </div>
                </div>

                <div className="grid_type02">{/* 하단 FRAME */}
                    <div className="grid_wrap02" style={{ width:"70%", height:"100%"}}> {/* 하단 wrap 좌측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <GridRibbon title={"납품서 발행현황"}
                                onDelete={onDelete2} onDeleteName={"취소"}
                                onPrint={(e) => {onPrintChk(e) ;   }} onPrintName={"인쇄"} onPrintVisible = {tVnt1 !== "NRAP"} tooltip={"납품서인쇄:두번 연달아 클릭하면 에러가 납니다"} 
                                
                            >
                            </GridRibbon>
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* 납품서 발행현황 Grid */}
                                <Grid
                                    ref={grdRom3hList}
                                    gridId={"ROM3H2"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs1}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={false}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    searchApi={searchApi2}              // 조회
                                    deleteApi={deleteApi2}              // 삭제
                                    deleteRefresh={onSearch2}           // 삭제이후 조회
                                    onClearApi={onClearApi2}            // Clear
                                    onRowClickEvent={grdRom3hList_onRowClicked}     
                                    onCellValueChangedEvent={grdRom3hList_onCellValueChanged}
                                    useMessage={false}                            
                                    onRowSelected={grdRom3hList_onRowSelected}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid_wrap03"> {/* 하단 wrap 우측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <GridRibbon title={"납품서 상세현황"}
                                // onSearch={onSearch}
                                onUpdate={onUpdate3} onUpdateName={"수정"}
                                onDelete={onDelete3} onDeleteName={"납품목록 삭제"}
                            >
                            </GridRibbon>
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* 납품서 발행현황 Grid */}
                                <Grid
                                    ref={grdRom3hDetail}
                                    gridId={"ROM3H3"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs2}            //  (필수) 컬럼 정의 
                                    pagination={false}                  //  쪽수 매기기 여부
                                    paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}                 // No 컴럼 Display
                                    searchApi={searchApi3}              // 조회
                                    onClearApi={onClearApi3}            // 초기화
                                    updateApi={updateApi3}              // 수정
                                    deleteApi={deleteApi3}              // 삭제
                                    onCellValueChangedEvent={grdRom3hDetail_onCellValueChanged}
                                    deleteRefresh={onSearch2}           // 삭제이후 
                                    clearGrid={clearGrid3}
                                    onClearGridFlag={setClearGrid3}                    
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div>
                    <VmiCardPrintHMG printData={printdata} />
                </div> */}
                {/* <div>
                    {userId==="S015003"?
                        <VmiCardPrintHMG printData={printdata} />
                    :
                        <VmiCardPrint printData={printdata} />
                    }
                </div> */}
                
            </Content>

            

            <div className="printArea">
                <ReactToPrint trigger={() => <button className="btn" ref={printBtnRef} style={{ display: "none" }} />} pageStyle={pageStyle} content={() => printRef.current} />
                {userId==="S015003" || userId==="SFXE001"|| userId==="P655001"||userId==="P655002"||userId==="P655003"||userId==="T131001"?
                    <VmiCardPrintHMG ref={printRef} printData={printdata} />
                :
                    <VmiCardPrint ref={printRef} printData={printdata}  pos = {pos}/>
                }
            </div>
            
            
        </>
    )
  }
  export default ROM3H;