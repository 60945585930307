/**
 * @Page REPD3
 * @Description [REPD3]수신자료관리 - ERP_자재정보조회 - 주간소요량(D2)Line별
 **/
 import React, {useState, useEffect, useRef, useCallback, forwardRef} from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; // Common.js
 import CommonUtil from '@/utils/CommonUtil'; // Util.js
 import {getToday} from "@/utils/Common";
 import UploadModal from "@/components/organisms/UploadModal";
 import Loading from "src/components/atoms/CustomLoading2";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
 
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();
 
 function REPD3() {
     const userId = Common.getToken("id");
     const token = Common.getToken();
     const tVnt1 = Common.getToken("vand");
     const tComp = Common.getToken("comp");
     const grdRepd3Main = useRef();   // Grid Main
 
     const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
     let [loading, setLoading] = useState(false);
     //Data
     const [idata, setData] = useState({
         kFpdt: getToday(),
         kTpdt: getToday(),
         kDocd: "",
         kVnt1 : ckVnt1,        //T1업체     
         kComp: tComp,       //회사구분
        kVnt1Nm : "",        //T1업체명
        kCompNm : tComp==="EHMC"?"현대자동차(주)":"기아자동차(주)",        //회사명
        kPageNum :1,//하단 페이지
        kPageTotal :0,//total 페이지
        kTotcnt : 0,//total 갯수

         //코드 데이터
         vnt1List : [],  //T1업체
         docdList : [],  //문서코드
         compList : [],  //회사
         headerList : [],  //헤더
     });
 
 
      
     const [message, setMessage] = React.useState( {
         openAlert : false,      
         severity : "success",   
         msg : ""                
     });
     const { openAlert, severity, msg } = message;
     const [uploadInfo, setUploadInfo] = useState({ vnt1: tVnt1, subDir: "DATA" });
 
 
     //Dialog
     const [dlgMessage, setDlgMessage] = React.useState( {
         openDlg : false,    
         dlgTrans : "",      
         dlgMsg : ""         
     });
     const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
 
     //Grid Search, Insert, Update, Delete Api
     let [searchApi, setSearchApi] = useState(false);     
     let [clearGrid, setClearGrid] = useState(false); 
     const [openUpload, setOpenUpload] = React.useState(false); // Modal 항목 setting
 
     const [panded, setPanded] = React.useState(true);
     const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 80px)"});
     const [gridApi, setGridApi] = useState(null);
     const onClearApi = useCallback(async (e) => {
         setSearchApi(false);
     }, []);
 
 
     const onGridReady  = (params) => {
        setGridApi(params);
    };

     /**
      * 공통코드 조회 (조회조건)
      */
     const onSearchCommonAsync = async (para, name) => {
         let commonData = idata;
 
         const fetchData = async (para, name) => {
             let param = encodeURI(para);
             const query = SERVER_URL + "/comcd/search?query=" + param; 
             await fetch(query, { headers: { Authorization: token } })
                 .then((resp) => resp.json())
                 .then((data) => {
                     if (data.success)
                     {
                         commonData[name] = data.data;
                         setData({...commonData});
                     }
                 })
         }
         await fetchData(para, name);
     }

     const onSearchCommon1 = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                    commonData[name] = data.data[0].text;
                    setData({...commonData});
                    }
                })
        }
        fetchData(para, name);   
    }
 
     /**
      *MOUNT
      */
     useEffect( () => {
         //공통코드 조회 (T1업체)
         onSearchCommonAsync(`{"pgmid":"VNT1"}`, "vnt1List");
         //공통코드 조회 (회사)
         onSearchCommonAsync(`{"pgmid":"COMP"}`, "compList");
         //공통코드 조회 (문서코드)
         onSearchCommonAsync(`{"pgmid":"DOC_ERPD3","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "docdList");
 
         onSearchCommon1(`{"pgmid":"WRCVAND","kVnt1":"${idata.kVnt1}"}`, "kVnt1Nm");
     }, []);
 
 
 
     /**
      * Control 변경 Event
      * @param {*} e
      */
     const onChange = async (e) => {
         const {name, value, desc} = e.target;
 
         let newSearchs = idata;
         let newUploadInfo = uploadInfo;
         newSearchs[name] = value
         setData({ ...newSearchs });
 
         //T1업체, 회사구분 변경시 
         if (name === "kVnt1")  {
             newSearchs["kDocd"] = "";
            newSearchs["kPageNum"] = 1;
             newUploadInfo["vnt1"] = value;
             newSearchs[name+"Nm"] = desc;

             setData({ ...newSearchs });
             setUploadInfo({ ...newUploadInfo });

             onSearchCommonAsync(`{"pgmid":"DOC_ERPD3","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "docdList");
            // onSearchCommon(`{"pgmid":"CODE","vand":"${idata.kVnt1}","gocd":"COMP","HEAD":"${idata.kComp}"}`);
         }
         if( name === "kComp"){
             newSearchs[name+"Nm"] = desc;
             newSearchs["kDocd"] = "";
             setData({ ...newSearchs });
             
             onSearchCommonAsync(`{"pgmid":"DOC_ERPD3","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "docdList");
 
         }
         if(name === "kDocd"){
            newSearchs["kPageNum"] = 1;
            setData({ ...newSearchs });
             setClearGrid(true); // 그리드 clear 하기 
                onSearchCommonAsync(`{"pgmid":"erpp6_header", "kPage":"ERPD3","kDocd" : "${idata.kDocd}","kExcel":"Y"}`, "headerList");
         }
     }
 
 
     /**
      * 검색조건
      */
     const optionsX = {
         0: [
             // T1업체
             {
                 formatter: "select", name: "kVnt1", labelName: "1차업체", type: "text", defaultValue: idata.kVnt1,  
                 codeMode: true,
                 selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: null,
                 comms: idata.vnt1List, 
                 align: "left", 
             },
             // 회사구분
             {
                 formatter: "select", name: "kComp", labelName: "회사구분", type: "text", defaultValue: idata.kComp,  
                 codeMode: false,
                 selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: CODES["comp"],
                 comms: null,
                 align: "left", 
             },
             // 문서코드
             {
                 formatter: "select", name: "kDocd", labelName: "문서코드", type: "text", defaultValue: idata.kDocd,  
                 codeMode: false,
                 selectAll: false, // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: [{comCode: "", text: "문서 전체"}],
                 comms: idata.docdList,
                 align: "left", 
             },
             // 전체건수
             {
                 formatter: "input", name: "KTotcnt", labelName: "전체건수", type: "text", defaultValue: idata.kTotcnt,  
                 codeMode: false,
                 selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                 codes: null, // 2015~2022년까지 :: Case 1 - codes :: Code List
                 comms: null,
                 align: "left", 
             },
             {"formatter": "blank", "align": "right" },
             {"formatter": "blank", "align": "right" }
         ],
     }
 
      //조회건수
      const onSetCount = () => {
        let cnt;
        if(gridApi.api.rowRenderer.gridApi.rowModel.rowsToDisplay.length > 0){
            cnt = gridApi.api.rowRenderer.gridApi.rowModel.rowsToDisplay[0].data.cnt;
        }else{
            cnt = 0;
        }
        let newSearchs = idata; 

        if(idata.kPageNum === 1){
            newSearchs["kPageTotal"] = Math.ceil(parseInt(cnt)/500);
            newSearchs["kTotcnt"] = Common.getNumberFormatter(cnt);
            setData({ ...newSearchs });
        } 

     }
 
     /**
      * 조회
      * @param {*} e
      * @returns
      */
     const onSearch = async (e) => {
  
         // Cookie 값 셋팅
         Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);
         
         const page = "ERPD3"
         let para = `{
                         "kGubn":"${page}", "exl": "N",
                         "kVnt1": "${idata.kVnt1}", "kDocd": "${idata.kDocd}", "kComp": "${idata.kComp}", "kPageNum":"${idata.kPageNum}"
                      }`;
         let param = encodeURI(para);
 
         const query = SERVER_URL + "/repd/search?query=" + param;
         setSearchApi(query); // Search API
     }
   
     /**
          * 파일생성
          * @param {*} e 
          */
     const onUpload = async (e) => {
         let newSearchs = idata; 
 
         newSearchs["vnt1"] = idata.kWrcVand;
         setData({ ...newSearchs });
 
         setOpenUpload(true);
     }
 
     const handleUploadClickOpen = () => {
     
     setOpenUpload(true);
     };
 
     const handleUploadClose = () => {
     setOpenUpload(false);
     //onSearchDocd();
     };
 
 
     /**
      * 신규 Row추가
      * @param {*} e
      */
     const onAddRow = async (e) => {
     }
 
 
 
     /**
      * 입력 (발행)
      * @param {*} e
      */
     const onInsert = async (e) => {
 
     }
 
 
 
     /**
      * 수정
      * @param {*} e
      * @returns
      */
     const onUpdate = async (e) => {
     }
 
     /**
      * 삭제
      * @param {*} e
      */
     const onDelete = async (e) => {
     }
 
     /**
      * 엑셀
      * @param {*} e
      */
      const onExcel = async (e) => {
         const page = "ERPD3"
         let para = `{"kDocument":"D2 주간소요량(Line별)", 
                      "kGubn":"${page}","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}",
                      "pgmid":"erpp6_header", "kPage":"ERPD3","Key0": "excel","kDocd" : "${idata.kDocd}",
                      "vnt1Nm":"${idata.kVnt1Nm}","compNm":"${idata.kCompNm}","docdNm":"${idata.kDocd===""?"전체":idata.kDocd}","kTotcnt": "${idata.kTotcnt}"}`;
 
        
         let param = encodeURIComponent(para);
         const query = SERVER_URL + "/repd/excel?query=" + param;
         //엑셀파일 조회 시 반응
         setLoading(true);
         await fetch(query, {headers: {Authorization: token } })
             .then((resp) =>{
                 return resp.blob();
             }).then((blob)=>{
                 const href = window.URL.createObjectURL(blob);
                 const link = document.createElement('a');
                 link.href = href;
                 link.setAttribute('download', 'ERPD3.xls');
                 document.body.appendChild(link);
                 link.click();
                 document.body.removeChild(link);
             }).catch((err) => {
                 return Promise.reject({ Error: 'error', err });
             })
        setLoading(false);
        
        // 엑셀파일 생성 메세지
        setMessage({ openAlert : true, 
                     severity : "success",
                     msg : "엑셀파일이 생성되었습니다."});
     }
 
     //Transaction AlertDialogResult
     const AlertDialogResult = async (e) => {
 
         //AlertDialog 초기화
         setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
 
 
 
         //DialogResult
         if (e.result == "N") return;
 
         //Transaction 구분
         switch (e.Trans)
         {
             //등록
             case "I" :
                 break;
             //수정
             case "U" :
                 break;
             //삭제
             case "D2" :
                 break;
             case "D3" :
                 break;
         }
     }
 
    const onPrevnClickedEvent = (params) =>{

        let newSearchs = idata; 

        if(idata.kPageNum != 1){
            newSearchs["kPageNum"] = idata.kPageNum-1;
        }else{
            newSearchs["kPageNum"] = 1;
        }
        
        setData({ ...newSearchs });

        if(idata.kPageNum >= 1){
            onSearch();
        }
    }

    const onNextClickedEvent = (params) =>{
        let newSearchs = idata; 

        newSearchs["kPageNum"] = idata.kPageNum+1;
        setData({ ...newSearchs });

        if(idata.kPageTotal >=  idata.kPageNum ){
            onSearch();
        }
    }

 
     /**
      *Grid Column 정의
      */
         // 월별 컬럼
     const mainColumnDefs = 
      idata.kDocd===""?[ 
         { headerName: "Document Code", field: "COL01", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
         { headerName: "업체코드", field: "COL02", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: true },
         { headerName: "공장", field: "COL03", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
         { headerName: "SHOP구분", field: "COL04", sortable: true, filter: true, flex: 1, minWidth: 95, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
         { headerName: "Line구분", field: "COL05", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
         { headerName: "품번", field: "COL06", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true,valueGetter: Utils.partnoGetter, hide: false },
         { headerName: "품명", field: "COL07", sortable: true, filter: true, flex: 1, minWidth: 160, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
         { headerName: "현재고", field: "COL08", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "실물재고", field: "COL09", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "전일실적", field: "COL10", sortable: true, filter: true, flex: 1, minWidth: 90, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "2T", field: "COL11", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter},
         { headerName: "2T", field: "COL12", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter},
         { headerName: "2T", field: "COL13", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter},
         { headerName: "2T", field: "COL14", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter},
         { headerName: "2T", field: "COL15", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter},
         { headerName: "2T", field: "COL16", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter},
         { headerName: "2T", field: "COL17", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter},
         { headerName: "2T", field: "COL18", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter},
         { headerName: "2T", field: "COL19", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter},
         { headerName: "2T", field: "COL20", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter},
         { headerName: "D일 합계", field: "COL21", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
 
         { headerName: "D+1일 2T", field: "COL22", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+1일 2T", field: "COL23", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+1일 2T", field: "COL24", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+1일 2T", field: "COL25", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
 
         { headerName: "D+1일 2T", field: "COL26", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+1일 2T", field: "COL27", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+1일 2T", field: "COL28", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+1일 2T", field: "COL29", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+1일 2T", field: "COL30", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+1일 2T", field: "COL32", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+1일 합계", field: "COL33", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+2일 2T", field: "COL34", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+2일 2T", field: "COL35", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+2일 2T", field: "COL36", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+2일 2T", field: "COL37", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+2일 2T", field: "COL38", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+2일 2T", field: "COL39", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+2일 2T", field: "COL40", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+2일 2T", field: "COL41", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+2일 2T", field: "COL42", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+2일 2T", field: "COL43", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+2일 합계", field: "COL44", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+3일 2T", field: "COL45", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+3일 2T", field: "COL46", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+3일 2T", field: "COL47", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+3일 2T", field: "COL48", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+3일 2T", field: "COL49", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+3일 2T", field: "COL50", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false,valueFormatter:Utils.numberFormatter },
         { headerName: "D+3일 2T", field: "COL51", sortable: true, filter: true, flex: 1, minWidth: 120, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false ,valueFormatter:Utils.numberFormatter},
         { headerName: "D+3일 2T", field: "COL52", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false ,valueFormatter:Utils.numberFormatter},
         { headerName: "D+3일 2T", field: "COL53", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+3일 2T", field: "COL54", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+3일 합계", field: "COL55", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+4일 2T", field: "COL56", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+4일 2T", field: "COL57", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+4일 2T", field: "COL58", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+4일 2T", field: "COL59", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+4일 2T", field: "COL60", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+4일 2T", field: "COL61", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+4일 2T", field: "COL62", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+4일 2T", field: "COL63", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+4일 2T", field: "COL64", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+4일 2T", field: "COL65", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+4일 2T", field: "COL66", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+5일 수량", field: "COL67", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+6일 수량", field: "COL68", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+7일 수량", field: "COL69", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+8일 수량", field: "COL70", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+9일 수량", field: "COL71", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+10일 수량", field: "COL72", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+11일 수량", field: "COL73", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+12일 수량", field: "COL74", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+13일 수량", field: "COL75", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+14일 수량", field: "COL76", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+15일 수량", field: "COL77", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+16일 수량", field: "COL78", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+17일 수량", field: "COL78", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+18일 수량", field: "COL79", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+19일 수량", field: "COL80", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+20일 수량", field: "COL81", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+21일 수량", field: "COL82", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+22일 수량", field: "COL83", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+23일 수량", field: "COL84", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+24일 수량", field: "COL85", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+25일 수량", field: "COL86", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+26일 수량", field: "COL87", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+27일 수량", field: "COL88", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+28일 수량", field: "COL89", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+29일 수량", field: "COL90", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+30일 수량", field: "COL91", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "D+31~45일", field: "COL92", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "발주잔량", field: "COL93", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
         { headerName: "납입지시유형", field: "COL94", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
     ]
     : Array((idata.headerList).length).fill(0).map((_, i) => {
         return{
             headerName: idata.headerList[i].comCode, field:i< 9?"COL0"+(i+1):"COL"+(i+1),  sortable: true, filter: true, flex: 1, minWidth: i<=5?135:170, editable: false, headerClass: "", cellClass:(i>6 && i<93)? ["nocheckbox", "alignR"]:["nocheckbox", "alignC"],singleClickEdit: true, hide: i===1?true:false,
             valueFormatter:i<=6 ? "" : i<93?Utils.numberFormatter:""
             , valueGetter: i===5?Utils.partnoGetter:""
         }
         
     })

     const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 80px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 55px  - 80px)"
              });
        }
    }
     return(
         <>
             {/* Dialog FRAME */}
             <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />
 
             {/* Alert FRAME */}
             <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({...message, openAlert:false})}} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} />
            
             {/* Upload FRAME */}
             <UploadModal
                 open={openUpload}
                 onClose={handleUploadClose} 
                 onClickOpen={handleUploadClickOpen}
                 title={"CSV 업로드"}
                 fileId={"ERPD3"}
                 filePath={"C:\\HKC\\VAN\\ERPD3.CSV"}
                 uploadInfo={uploadInfo}
                 accp=".csv"
                 maxFiles={1}
                 validatorName={"csv"}
                 uploadApi={SERVER_URL + "/erp/upload1"}
                 data={idata.vnt1List}
             />
             {/* Loading */}
             <Loading loading={loading} /> 

             {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
             <Ribbon
                 onSearch={onSearch} onSearchName={null}     //조회
                 onUpload={onUpload} onUploadName={"생성"}//생성 
                 onExcel={onExcel} onExcelName ={null}   //Excel
             >
                 {"수신자료관리 > 자재정보자료조회 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>주간소요량(Line별) [ D3 ]</b>
             </Ribbon>
 
             <ContentXearch
                 optionsX={optionsX}
                 onChange={onChange}
                 onClick={onClick}/>
 
             {/* Content FRAME (Data / Grid Frame) START -------------- */}
             <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:100px */}
                 <div className={"grid_type01"}> {/* 상단 FRAME */}
                     <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                         <Grid
                             ref={grdRepd3Main}
                             gridId={"REPD3"}                    //  (필수) Grid Id 정의
                             columnDefs={mainColumnDefs}             //  (필수) 컬럼 정의
                             pagination={false}                  //  쪽수 매기기 여부
                             paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                             paginationPageSize={"500"}         //  쪽수 1Page 당 Row수
                             rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                             suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                             rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                             enableRangeSelection={false}        //
                             enableCellTextSelection={true}      // cell selection drag mode
                             isFirst={false}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                             displayNo = {false}
                             searchApi={searchApi}               // 조회
                             onClearApi={()=> {onSetCount();onClearApi()}}
                             clearGrid={clearGrid}
                             onClearGridFlag={setClearGrid}
                             onRowClickEvent={null}
                             onGridReadyEvent={onGridReady}
                             paginationCustom = {true}
                             onPrevnClickedEvent={onPrevnClickedEvent}
                             onNextClickedEvent={onNextClickedEvent}
                         />
                     </div>
                 </div>
             </Content>
         </>
     )
 }
 export default REPD3;