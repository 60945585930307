import React, { Component } from 'react';
import Ribbon from '@/components/organisms/Ribbon';
import ContentXearch from '@/components/organisms/ContentXearch';
import Content from '@/components/organisms/Content';
import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
import * as GridUtil from '@/components/grid/agGrid';
import CommonUtil from '@/utils/CommonUtil'; 
import { stringToArray } from 'ag-grid-community';
 
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
const Utils = new CommonUtil();


class JynoPopup extends Component {

    constructor(props)  {
        super(props);
        

        this.state = {
            //Common
            token:Common.getToken(),
            userId :Common.getToken("id"),
            userName : Common.getToken("name"),
            tVnt1 : Common.getToken("vand"),
            tComp : Common.getToken("comp"),
    
            //조회조건
            kVnt1 : props.data.kVnt1 ,
            kPlnt : props.data.kPlnt ,
            kComp : props.data.kComp , 
            odno : props.data.odno , 

            jyno : "",
    
            //Alert 창 ( Alert 창 OPEN,  Alert 창 구분, Alert 창 메세지)
            openAlert : false, severity : "success", msg : "",
    
            //Dialog 창 (Dialog 창 OPEN, Dialog Transaction(I,U,D),  Dialog 메세지)
            openDlg : false, dlgTrans : "", dlgMsg : "", 
    
            //Common 코드 데이터
            compList : {},
    
            //Api (조회, 등록, 수정, 삭제)
            searchApi : false, insertApi : false, updateApi : false, deleteApi : false,

            loadingFlag : false
        }
    }

    
    /**
     * WillMount
     */
    async componentWillMount() {

        this.setState({loadingFlag : true}); 

    }


    /**
     * DidMount
     */
    componentDidMount() {
        let para = `{"kVnt1":"${this.state.tVnt1}","kPlnt":"${this.state.kPlnt}","kComp":"${this.state.kComp}","odno":"${this.state.odno}"}`;
        let param = encodeURIComponent(para);

        const query = SERVER_URL + "/popup/jyno_search?query=" + param;

        const fetchSearchData = async (query) => {
        let inputData = this.state; 
            await fetch(query,  { headers: { Authorization: this.state.token  } })
                .then((resp) => resp.json())
                .then((data) => {

                    if (data.success) {
                        this.state.jyno = data.data[0].jyno;
                        this.setState({ ...inputData });
                        
                    }
                    if (data.error) {
                        this.setState({ openAlert : true, 
                                        severity : "error",
                                        msg : "조회 실패"});
                    }
                })
                .catch((error) => {
                    // 에러 응답 처리
                    if (error) {
                        this.setState({ openAlert : true, 
                                        severity : "error",
                                        msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                    }
                });
        };
        fetchSearchData(query);
    }


    // /**
    //  * WillReceiveProps
    //  */
    // componentWillReceiveProps () {
    // }

    // /**
    //  * WillUpdate
    //  * @param {*} nextProps 
    //  * @param {*} nextState 
    //  */
    // componentWillUpdate(nextProps, nextState) {
    // }

    // /**
    //  * DidUpdate
    //  * @param {*} prevProps 
    //  * @param {*} prevState 
    //  */
    // componentDidUpdate(prevProps, prevState) {
    // }

    // /**
    //  * WillUnmount
    //  */
    componentWillUnmount() {
        this.setState({searchApi : true}); 
    }


    /**
     * 공통코드 조회
    */
     onSearchCommon = (para, name) => {
    }


    /**
     * 회사구분 코드 조회
    */
      onSearchComp = async (para) => {

        let param = encodeURI(para);
        let elements = {}; // 객체 생성

        const query = SERVER_URL + "/comcd/search1?query=" + param; 

        await fetch(query, { headers: { Authorization: this.state.token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) { 
                    data.data.forEach(function (element) {
                        elements[element.comCode] = element.text;
                    }, this)
                }
            })
        return elements;
    }



    render() {

        if (this.state.loadingFlag === false) {
            return null
        }
        else {
        
            //State
            const {token, userId, userName, tVnt1, tComp, kWrcVand,  openAlert, severity, msg,
                   openDlg, dlgTrans, dlgMsg, searchApi,kVnt1,kPlnt,kComp,jyno } = this.state;


            //Grid Clear Api               
            // const onClearApi = async (e) => {
            //     this.setState({ searchApi : false, insertApi : false, updateApi : false, deleteApi : false})
            // }


            /**
             * Control 변경 Event
             * @param {*} e 
             */
            const onChange = async (e) => {
                const { name, value } = e.target;

                //  let newSearchs = idata;
                //  newSearchs[name] = value
                //  setData({ ...newSearchs });
                //  //조회 Call    
                //  onSearch(); 
            }

        
            /**
             * Grid Event 
             * @param {*} e 
            */
            const onCellValueChanged = async (e) => {
            }

            const onRowClicked = async (e) => {
            }


            /**
             * 검색조건
            */
            const optionsX = {
                // 0: [
                    
                //     // {
                //     //     "formatter": "input", "name": "kVnt2", "labelName": "T2 코드", "type": "text", "desc": "",
                //     //     "align": "left", 
                //     //     "labelType": "none" 
                //     // }, 
                //     // {"formatter": "blank", "align": "left" },
                //     // {"formatter": "blank", "align": "left" },
                //     // {"formatter": "blank", "align": "left" },
                //     // {"formatter": "blank", "align": "left" },
                //     // {"formatter": "blank", "align": "left" },
                // ]
            }

        
        
            /**
             * 조회
             * @param {*} e 
             */
            const onSearch = async (e) => { 
                let para = `{"kVnt1":"${this.state.tVnt1}","kPlnt":"${this.state.kPlnt}","kComp":"${this.state.kComp}","odno":"${this.state.odno}"}`;
                let param = encodeURIComponent(para);
 
                const query = SERVER_URL + "/popup/jyno_search?query=" + param;
 
                const fetchSearchData = async (query) => {
                 let inputData = this.state; 
                    await fetch(query,  { headers: { Authorization: token } })
                        .then((resp) => resp.json())
                        .then((data) => {
 
                            if (data.success) {
                             //    if (data.data.length == 0) {
                                 inputData["jyno"] = data.data[0].jyno; //이름
                                 this.setState({ ...inputData });
                                 //    this.setState({ openAlert : true, 
                                 //                    severity : "info",
                                 //                    msg : "정상적으로 조회되었습니다."});
                             //    }
                             //    else {
                             //         this.setState({ openAlert : true, 
                             //                         severity : "info",
                             //                         msg : "정상적으로 조회되었습니다."});
                             //    }
 
                                
                            }
                            if (data.error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "조회 실패"});
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                };
                fetchSearchData(query);
            }



            /**
             * 신규 Row추가
             * @param {*} e 
             */
            const onAddRow = async (e) => {
            
            }



            /**
             * 입력
             * @param {*} e 
             */
            const onInsert = async (e) => {

            }



            /**
             * 수정 
             * @param {*} e 
             * @returns 
             */
            const onUpdate = async (e) => {

            }



            /**
             * 삭제
             * @param {*} e 
             */
            const onDelete = async (e) => {
                
                
            }



            //deleteBlank
            function deleteBlank(argval){

                if (argval === null || argval === undefined) return "";

                for(var i=argval.length-1 ;i >= 0; i--){
                    
                    if(argval.charCodeAt(i) !== 12288 && argval.charCodeAt(i) !== 32 ) //charCodeAt(i)  
                    return(argval.substring(0,i+1));
                }
                return(argval.length>0?argval.substring(0,0):"")
            }



            // input check
            const inputCheck = (gbn) => { 

                let inputFlag = true;

                //select nodes
                let selectedData = this.grdT2CodeMain.current.getSelectedNodes();

                // 선택 값 체크
                if (selectedData.length === 0) {
                    //Alert 창 OPEN
                    this.setState({ openAlert : true, 
                                    severity : "info",
                                    msg : "선택한 데이터가 없습니다."});
                    return false;
                }


               
                
            }
            

            //AlertDialogResult
            const AlertDialogResult = async (e) => {

                //AlertDialog 초기화
                this.setState({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        
                //DialogResult
                if (e.result == "N") return;
        
                //Transaction 구분
                switch (e.Trans) 
                {
                    //등록
                    case "I" :
                        break;
                    //수정
                    case "U" :
                        break;
                    //삭제
                    case "D" :
                        break;
                }
            }

        

            //Grid DropDownList Value
            const extractValues = (mappings) => {
                return  Object.keys(mappings);
            }

            //Grid DropDownList Display
            const lookupValue = (mappings, key) => {
                return mappings[key];
            }        

        
            /**
             *Grid Column 정의 
            */
            const columnDefs = [
            ];


        
            return (
                <>
                    {/* Dialog FRAME */}
                    <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

                    {/* Alert FRAME */}
                    <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {this.setState({openAlert:false, severity:"", msg:""})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
                    <button onClick={() => {this.props.onClose()}} style = {{border:"none", float:"right", paddingRight:"0px"}}> <img src="/images/button/close_button.png" alt="close" /></button> 

                    {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
                    <Ribbon
                        newTabUnused={true}                         // newTabUnused : newTab 표출 버튼 true : 사용안함 // 선언안할경우 default = newTab 사용
                        onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                        //onSearch={onSearch} onSearchName={"조회"}
                    >
                    <b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>적용호차</b>
                    </Ribbon>
        
                    <div className={"grid_type01"} style={{ marginTop: "10px"}} > {/* 상단 FRAME */}
                        <div className={"grid_wrap01"}> {/* 상단 wrap FRAME */}
                            <div className="grid_cont_box" style={{ height: "355px", width : "398px" }}>
                            <table  style={{ width: "100%" }}>
                                    <colgroup>
                                        <col width="100%" />
                                    </colgroup>
                                    <tbody>
                                    <tr style={{ height:"355px",border:"0px"}}>
                                        <td style={{border:"0px"}}>
                                            <div style={{wordBreak:"break-all"}}>
                                            <textarea name="jyno" style={{width:"398px",height:"355px",background:"#F1F3F7",verticalAlign: "top",fontSize: "10pt",fontWeight: "bold",resize: "none"}} value={jyno} readOnly />
                                            </div>
                                        </td>
                                    </tr>     
                                    </tbody>
                                </table>
                               
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        
    }

}


export default JynoPopup;