import React from "react";
import * as Common from "@/utils/Common"; // Common.js
import styled from 'styled-components';
const underDiv = { width: "195mm", borderWidth: "0px", borderStyle: "solid", marginBottom: "8mm",position:"relative",top:"0px" };//, marginTop: "4mm"
const underTable = { border: "0px", borderCollapse: "collapse", borderStyle: "none", padding: "0px", width: "144px" };

class VmiCardPrintHMG extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nplx: "0",
            nply: "0",
            pathNm: null
        };
    }

    componentDidMount() {
        const info_nplx = Common.getToken("cplx")===null?0: 9 + ( parseInt(Common.getToken("cplx"),10) * 2 ) ;    // 로그인한 사용자의 납입카드의 x좌표값 받아오기
        const info_nply = Common.getToken("cply")===null?0: 5 + ( parseInt(Common.getToken("cply"),10) * 2 );    // 로그인한 사용자의 납입카드의 y좌표값 받아오기
        this.setState({nplx:info_nplx});
        this.setState({nply:info_nply});   
        
        if(window.location.pathname.split('/')[2] === "ROM3J") { // ROM3J 재발행 check
            this.setState({pathNm:window.location.pathname.split('/')[2]});
        }

    }

    

    render() {
        const PrintStyle = styled.div`
        @media print {

            @page{size: A4 landscape; margin-top:${this.state.nply}mm; margin-left:${this.state.nplx}mm; margin-right:10mm;margin-bottom:4mm; } 
            
            body {
            background-color: ;
            margin: 0px;
            padding: 0px;
            font-family: "둥근고딕,굴림체";
            }
            div {
            background-color:;
            margin: 0px;
            padding: 0px;
            box-sizing:border-box;
            }
            table {
            border-collapse:collapse;
            border-style:none;
            margin: 0px;
            padding: 0px;
            box-sizing:border-box;
            }
            tr{
            width:100%;
            margin: 0px;
            padding: 0px;
            }
            td{
            margin: 0px;
            padding: 0px;
            background-color: white;
            font-family: "둥근고딕,굴림체";
            border:0px solid black;
            box-sizing:border-box;
            }

            .wrapDiv {
                // width: 195mm;
                width: 210mm;
                // height: 133mm;
                // height: 138mm;
                height: 142mm;
                box-sizing: border-box;
                border-style: dotted;
                border-width: 0px;
                //margin-bottom:2px;
            }
            .headInfoStyle {
                height: 14px;
                margin-bottom: 2px;
                font-size: 10pt;
                font-family:'NanumSquare';
                font-weight:bold;
                letter-spacing:1px;
            }
            .seqNumberStyle {
                text-align: center;
                border: 0px;
                padding: 0;
                margin: 0;
                width: 36px;
                font-size: 9pt;
                height: 0mm;
            }
            .seqTdStyle {
                border: 0px;
                padding: 0;
                margin: 0;
                // font-size: 16pt;
                font-size: 23.5pt;
                vertical-align: top;
                font-family : "3of9Barcode";
            
            }
            .barcodeTableStyle {
                border-collapse: collapse;
                border-spacing: 0;
                border: 0px;
                width: 361px;
            }
            .barcodeTdStyle {
                border: 0px;
                padding: 0;
                margin: 0;
                // font-size: 18.3pt;
                font-size: 23.5pt;
                vertical-align: top;
                font-family: "3of9Barcode";
            }
            
            .seqTableStyle {
                border-collapse: collapse;
                border-spacing: 0;
                border: 0px;
                // width: 726px;
                height: 11mm;
                //margin-left: 11px;
            }
            .detailTableStyle {
                border-collapse: collapse;
                border-spacing: 0;
                border: 0px;
                width: 737px;
                table-layout: fixed;
                height: 70mm;
            }
            .vmipartNoTh {
               border: 0.5px solid #2e2e2e;
               text-align: center;
               color:black;
               font-size: 10pt;
               font-family:"NanumSquare";
               font-weight:bold;
               vertical-align: middle;
               height: 4.5mm;
            }
            .noTd2 {
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-color: black;
                text-align: center;
                font-size: 10pt;
                vertical-align: middle;
                font-weight:bold;
            }
            .partNoTD {
                text-align: left;
                font-size: 11.5pt;
                vertical-align: middle;
                font-family: "HY견고딕";
                font-weight:bold;
                border-top: 0px;
            }
            .td81 {
                text-align: left;
                font-size: 8pt;
                vertical-align: middle;
                border-color: black;
                padding-left:7px;
            }
            .vmitd82 {
                text-align: left;
                font-size: 8pt;
                vertical-align: middle;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-color: black;
                padding-left:7px;
            }
            .vmitd83 {
                text-align: left;
                font-size: 9pt;
                vertical-align: middle;
                font-family:"굴림체";
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-color: black;
                padding-left: 9px;
            }
            .vmitd84 {
                text-align: left;
                font-size: 9pt;
                vertical-align: middle;
                font-family:"굴림체";
                padding-left: 7px;
            }
            .td91 {
                text-align: center;
                font-size: 9pt;
                vertical-align: middle;
                border-color: black;
            }
            .td92 {
                text-align: center;
                font-size: 9pt;
                vertical-align: middle;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-color: black;
            }
            .vmitd92 {
                text-align: right;
                font-size: 8pt;
                vertical-align: middle;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-color: black;
                padding-right:7px;
                font-family:"굴림체";
            }
            .tdptnm {
                text-align: left;
                font-size: 7pt;
                vertical-align: middle;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-color: black;
                font-family:"굴림";
                whiteSpace:nowrap;
            }
            .gateTd {
                text-align: left;
                font-size: 9pt;
                vertical-align: middle;
                border-color: black;
                padding-left:7px;
                font-family:"굴림체";
            }
            .caseQty {
                text-align: right;
                font-size: 9pt;
                vertical-align: middle;
                font-weight: bold;
                font-family:"굴림체";
                padding-right: 7px;
            }
            .npqtTd {
                text-align: center;
                vertical-align: middle;
                font-size: 11pt;
                font-family: "HY견고딕";
                border-bottom-width: 1px;
                border-bottom-style: solid;
                font-weight: bold;
            }

            div.pagebreak {
            break-after: always !important;
            page-break-after: always !important;
            page-break-inside: avoid !important;
            }
        }


        @media screen {
            div {
            background-color:;
            margin: 0px;
            padding: 0px;
            box-sizing:border-box;
            }
            table {
            border-collapse:collapse;
            margin: 0px;
            padding: 0px;
            box-sizing:border-box;
            }
            tr{
            width:100%;
            margin: 0px;
            padding: 0px;
            }
            td{
            margin: 0px;
            padding: 0px;
            background-color: white;
            font-family: "HY견고딕";
            border:1px solid black;
            box-sizing:border-box;
            }

            // .cont_section > .container.container02{ overflow: auto !important; } // 웹 view 전용

            .wrapDiv {
                // width: 195mm;
                width: 210mm;
                // height: 133mm;
                // height: 138mm;
                height: 142mm;
                box-sizing: border-box;
                border-style: dotted;
                border-width: 1px; // screen모드 테두리 표출
                //margin-bottom:2px;
            }
            .headInfoStyle {
                height: 14px;
                margin-bottom: 2px;
                font-size: 10pt;
                font-family:'NanumSquare';
                font-weight:bold;
                letter-spacing:1px;
            }
            .seqNumberStyle {
                text-align: center;
                border: 0px;
                padding: 0;
                margin: 0;
                width: 36px;
                font-size: 9pt;
                height: 0mm;
            }
            .seqTdStyle {
                border: 0px;
                padding: 0;
                margin: 0;
                // font-size: 16pt;
                font-size: 23.5pt;
                vertical-align: top;
                font-family : "3of9Barcode";
            }
            .barcodeTableStyle {
                border-collapse: collapse;
                border-spacing: 0;
                border: 0px;
                width: 361px;
            }
            .barcodeTdStyle {
                border: 0px;
                padding: 0;
                margin: 0;
                // font-size: 18.5pt;
                font-size: 23.5pt;
                vertical-align: top;
                font-family: "3of9Barcode";
            }
            
            .seqTableStyle {
                border-collapse: collapse;
                border-spacing: 0;
                border: 0px;
                // width: 726px;
                height: 11mm;
                //margin-left: 11px;
            }
            .detailTableStyle {
                border-collapse: collapse;
                border-spacing: 0;
                border: 0px;
                width: 737px;
                table-layout: fixed;
                height: 70mm;
            }
            .vmipartNoTh {
                border: 0.5px solid #2e2e2e;
               text-align: center;
               font-size: 10pt;
               font-family:"NanumSquare";
               font-weight:bold;
               color:black;
               vertical-align: middle;
               height: 4.5mm;
            }
            .noTd2 {
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-color: black;
                text-align: center;
                font-size: 10pt; 
                vertical-align: middle;
                font-weight:bold;
            }
            .partNoTD {
                text-align: left;
                font-size: 11.5pt;
                vertical-align: middle;
                font-family: "HY견고딕";
                font-weight:bold;
                border-top: 0px;
            }
            .td81 {
                text-align: left;
                font-size: 8pt;
                vertical-align: middle;
                border-color: black;
                padding-left:7px;
            }
            .vmitd82 {
                text-align: left;
                font-size: 8pt;
                vertical-align: middle;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-color: black;
                padding-left:7px;
            }
            .vmitd83 {
                text-align: left;
                font-size: 9pt;
                vertical-align: middle;
                font-family:"굴림체";
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-color: black;
                padding-left: 9px;
            }
            .vmitd84 {
                text-align: left;
                font-size: 9pt;
                vertical-align: middle;
                font-family:"굴림체";
                padding-left: 7px;
            }
            .td91 {
                text-align: center;
                font-size: 9pt;
                vertical-align: middle;
                border-color: black;
            }
            .td92 {
                text-align: center;
                font-size: 9pt;
                vertical-align: middle;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-color: black;
            }
            .vmitd92 {
                text-align: right;
                font-size: 8pt;
                vertical-align: middle;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-color: black;
                padding-right:7px;
                font-family:"굴림체";
            }
            .tdptnm {
                text-align: left;
                font-size: 7pt;
                vertical-align: middle;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-color: black;
                font-family:"굴림";
                whiteSpace:nowrap;
            }
            .gateTd {
                text-align: left;
                font-size: 9pt;
                vertical-align: middle;
                border-color: black;
                padding-left:7px;
                font-family:"굴림체";
            }
            .caseQty {
                text-align: right;
                font-size: 9pt;
                vertical-align: middle;
                font-weight: bold;
                font-family:"굴림체";
                padding-right: 7px;
            }
            .npqtTd {
                text-align: center;
                vertical-align: middle;
                font-size: 11pt;
                font-family: "HY견고딕";
                border-bottom-width: 1px;
                border-bottom-style: solid;
                font-weight: bold;
            }

            div.pagebreak {
            break-after: always !important;
            page-break-after: always !important;
            page-break-inside: avoid !important;
            }import { BorderBottom } from '@material-ui/icons';


        }
        `;


        const { printData } = this.props;
        const simpleArray = [1,2];

        const cardInfo = printData
            ? printData.map((data, index) => {
                const seqBarcodeList = data.detail.map((item, index) => {
                    const blankTdArray = [];
                    if (index === data.detail.length - 1) {
                        let blankTdCount = 8 - data.detail.length;

                        for (let i = 0; i < blankTdCount; i++) {
                            blankTdArray.push(
                                <>
                                {/* <td className="seqTdStyle"></td>  */}
                                    {/* <td style={{ width: "87px" }}></td> */}

                                    {/* <td style={index===1?{ width: "125px" }:index===2?{ width: "124px" }:index===3?{ width: "122px" }:index===4?{ width: "116.5px" }:index===5?{ width: "110px" }:{ width: "83px" }}></td> */}
                                    <td></td>

                                    {/* {index != 6?
                                     <td style={index === 2?{width:"79px"}:index === 4?{ width: "74px"}:index === 5?{ width: (63)+"px"}:{width:"60px"}}></td>        
                                       :
                                       <td style={{ width: (40)+"px"}}></td>
                                       } */}
                                </>
                            );
                        }
                    }

                    return (
                        
                        <>
                        
                            <td className={"seqTdStyle"}>
                                *{item.DSEQ}*
                            </td>
                            {data.detail.length > 6 && data.detail.length !== 8
                            ? <td></td>
                            : data.detail.length === 8
                            ? <td></td>
                            :<td></td>
                            }
                            {
                            // data.detail.length > 6 && data.detail.length !== 8
                            // ? <td style={index===0?{ width: "82px" }:index===7?{ width: "30px" }:{width: (51)+(index+29)+"px"}}></td>
                            // : data.detail.length === 8
                            // ? <td style={index===0?{ width: "89px" }:index===7?{ width: "34px" }:{width: (58)+(index+30)+"px"}}></td>
                            // :<td style={index===0?{ width: "82px" }:index===7?{ width: "30px" }:{width: (52)+(index+29)+"px"}}></td>
                            }
                            
                            {blankTdArray}
                        </>
                    );
                });

                const seqBarcodeList2 = data.detail.map((item, index) => {
                    const blankTdArray = [];
                    if (index === data.detail.length - 1) {
                        let blankTdCount = 8 - data.detail.length;

                        for (let i = 0; i < blankTdCount; i++) {
                            blankTdArray.push(
                                <>
                                {/* <td className="seqTdStyle"></td>  */}
                                    {/* <td style={{ width: "87px" }}></td> */}

                                    {/* <td style={index===1?{ width: "125px" }:index===2?{ width: "124px" }:index===3?{ width: "122px" }:index===4?{ width: "116.5px" }:index===5?{ width: "110px" }:{ width: "83px" }}></td> */}
                                    <td></td>

                                    {/* {index != 6?
                                     <td style={index === 2?{width:"79px"}:index === 4?{ width:"74px"}:index === 5?{ width: (63)+"px"}:{width:"60px"}}></td>        
                                       :
                                       <td style={{ width: (40)+"px"}}></td>
                                       } */}
                                </>
                            );
                        }
                    }

                    return (
                        
                        <>
                        
                            <td className="seqTdStyle" style={{position: "relative",top: "-1px",backgroundColor: "transparent" }}>
                                *{item.DSEQ}*
                            </td>
                            {data.detail.length > 6 && data.detail.length !== 8
                            ? <td></td>
                            : data.detail.length === 8
                            ? <td></td>
                            : <td></td>
                            }
                            {
                            // data.detail.length > 6 && data.detail.length !== 8
                            // ? <td style={index===0?{ width: "82px" }:index===7?{ width: "30px" }:{width: (51)+(index+29)+"px"}}></td>
                            // : data.detail.length === 8
                            // ? <td style={index===0?{ width: "89px" }:index===7?{ width: "34px" }:{width: (58)+(index+30)+"px"}}></td>
                            // : <td style={index===0?{ width: "82px" }:index===7?{ width: "30px" }:{width: (52)+(index+29)+"px"}}></td>
                            }
                            
                        </>
                    );
                });

                
                const seqList = data.detail.map((item, index) => {
                    return (
                        <>
                            <td className="seqNumberStyle" style={{position: "relative",top:"-4px",backgroundColor:"transparent",textAlign:"left",paddingLeft:"25px"}}>{item.DSEQ}</td>{/*position: "relative",top:"-7px",left:"-7px"*/}
                            <td style={{display:"block",border:"none"}}></td>
                        </>
                    );
                });
                
                const chek = data.head.chek;
                //const barcodeValu = data.head.dlno;

                
                const gate = data.detail.map((item, index) => {
                    return (
                        <>
                            {index===0?item.GATE:""}
                        </>
                    );
                });

                const plnm = data.detail.map((item, index) => {
                    return (
                        <>
                            {index===0?item.PLNM:""}
                        </>
                    );
                });

                const prif = data.detail.map((item, index) => {
                    return (
                        <>
                            {index===0?item.PRIF:""}
                        </>
                    );
                });

                const cano = data.detail.map((item, index) => {
                    return (
                        <>
                            {index===0?item.CANO:""}
                        </>
                    );
                });
                
            
                return (
                    <>
                        {simpleArray.map((n, simpleIndex) => (
                            <div key={"VmiCardPrintHMG"+ n} className="wrapDiv">
                                <div style={{ width: "737px", height: "23mm" }}>
                                    <div style={{ float: "left", width: "93mm", textAlign: "left" ,marginTop: "3px",marginLeft: "2px",height: "22mm"}}>
                                        <div className="headInfoStyle">
                                            공급자 : {data.head.vnt1} {data.head.vdnm}
                                        </div>
                                        <div className="headInfoStyle">발주자 : {data.head.conm}</div>
                                        <div className="headInfoStyle">납품처 : {plnm}&nbsp;&nbsp;{gate}</div>
                                        <div className="headInfoStyle">납품일 : {prif}{this.state.pathNm === "ROM3J"?" [재발행]":""}</div>
                                        <div className="headInfoStyle">납품서 No : {data.head.dlno}</div>
                                    </div>
                                    <div style={{ float: "right", width: "101mm",paddingTop:"0px" ,paddingLeft:"0px",height:"90px"}}>
                                    {chek==='W'?
                                    <table className={"barcodeTableStyle"}>
                                            <tbody>
                                                <tr>
                                                    <td className="barcodeTdStyle" style={{fontFamily:"굴림체",fontSize:"20pt"}}>
                                                        백지카드
                                                    </td>
                                                </tr>
                                            </tbody>
                                    </table>
                                    :   <table className={"barcodeTableStyle"} style ={{height:"5mm"}} >
                                            <tbody>
                                                <tr>
                                                    {/* 큰바코드 !!!!!!!!!!!!!!!!!!!!!! */}
                                                    <td className="barcodeTdStyle" style={{position: "relative",top:"0px",backgroundColor: "transparent"}}>
                                                        *{data.head.dlno}*
                                                        {/* <CustomDataMatrix val={barcodeValu} sty={{width:"20mm"}} bcid={"code39"} scale={"1"} /> */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="barcodeTdStyle" style={{position: "relative",top:"-1px",backgroundColor: "transparent"}}>
                                                        *{data.head.dlno}*
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                        <div>
                                            <div style={{ float: "left", fontSize: "15pt", width: "57mm",fontFamily:"NanumGothic",fontWeight:"bold" ,position: "relative",top:"7px",backgroundColor: "transparent",paddingLeft:"80px"}}>납입CARD({n === 1 ? "갑" : "을"})</div>
                                            <div style={{ float: "left", fontSize: "14pt", width: "33mm",fontFamily:"NanumGothic",fontWeight:"bold" ,position: "relative",top:"8px",backgroundColor: "transparent"}}>[ 현대 ]</div>
                                            <div style={{ float: "left",position: "relative",top: "7px" }}>ⓡ</div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ clear: "both" }}></div>
                                <div style={{height:"55px",position:"relative",top:"-1.5px",width: "100%"}}>
                                {chek==='W'?
                                    <table className="seqTableStyle" style={{width:"99%",marginTop:"10px"}}>
                                    <tbody>
                                        <tr>
                                            <td style={{fontSize:"14pt",fontFamily:"굴림체",textAlign:"right",letterSpacing:"-1px",position: "relative",left: "-7px"}}>
                                                백지카드 입고 증빙번호 : ____________________</td>
                                        </tr>
                                    </tbody>
                                    </table>
                                :
                                    <table className="seqTableStyle" style={{marginLeft:"46px", tableLayout:"fixed"}}>
                                    <colgroup>
                                        <col width="64px"/><col width="31px"/><col width="63.5px"/><col width="30px"/><col width="63.5px"/><col width="31px"/><col width="64px"/><col width="31px"/>
                                        <col width="64px"/><col width="31px"/><col width="64px"/><col width="31px"/><col width="64px"/><col width="31px"/><col width="64px"/><col width="18.5px"/>
                                    </colgroup>
                                        <tbody>
                                            <tr>{seqBarcodeList}</tr>{/* 작은바코드 !!!!!!!!!!!!!!!!!!!!!! */}
                                            <tr>{seqBarcodeList2}</tr>{/* 작은바코드 !!!!!!!!!!!!!!!!!!!!!! */}
                                            {/* <tr>{seqBarcodeList2}</tr> */}
                                            {/* 작은바코드 !!!!!!!!!!!!!!!!!!!!!! */}
                                            <tr>{seqList}</tr> {/* 숫자 */}
                                        </tbody>
                                    </table>
                                }
                                </div>
                                <table className="detailTableStyle">
                                    <col width="7mm" />
                                    <col width="45mm" />
                                    <col width="7mm" />
                                    <col width="20mm" />
                                    <col width="11mm" />
                                    <col width="16mm" />
                                    <col width="22mm" />
                                    <col width="20mm" />
                                    <col width="7mm" />
                                    <col width="37mm" />
                                    <tbody>
                                        <tr>
                                            <td className = "vmipartNoTh" style={{ borderBottomStyle: "none" }}>순</td>
                                            <td className = "vmipartNoTh" colSpan="2">품&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;번</td>
                                            <td className = "vmipartNoTh" style={{ borderBottomStyle: "none" }}>납&nbsp;&nbsp;&nbsp;입</td>
                                            <td className = "vmipartNoTh">담당</td>
                                            <td className = "vmipartNoTh" style={{  borderBottomStyle: "none",letterSpacing:"1px" }}>납입</td>
                                            <td className = "vmipartNoTh"  rowSpan="2">
                                                발주번호
                                            </td>
                                            <td className = "vmipartNoTh" style={{ borderBottomStyle: "none", fontSize: "8pt",letterSpacing:"1px"}}>용기당수량/수</td>
                                            <td></td>
                                            <td className = "vmipartNoTh"  rowSpan="2">
                                                특기사항
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className = "vmipartNoTh" style={{ borderTopStyle: "none" }}>위</td>
                                            <td className = "vmipartNoTh" colSpan="2">품&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;명</td>
                                            <td className = "vmipartNoTh"  style={{  borderTopStyle: "none" }}>수&nbsp;&nbsp;&nbsp;량</td>
                                            <td className = "vmipartNoTh">구분</td>
                                            <td className = "vmipartNoTh" style={{  borderTopStyle: "none",letterSpacing:"1px" }}>장소</td>
                                            <td className = "vmipartNoTh">용기NO</td>
                                            <td></td>
                                        </tr>
                                        <tr><td style={{height:"3px",border:"0px"}}></td></tr>
                                        {data.detail.map((item, dindex) => {
                                            const blankTrArray = [];
                                            if (dindex === data.detail.length - 1) {
                                                let blankTdCount = 8 - data.detail.length;

                                                for (let i = 0; i < blankTdCount; i++) {
                                                    blankTrArray.push(
                                                        <>
                                                            <tr key={item.PTNO +"blank"+ dindex}>
                                                                <td style={{height : "4.2mm"}} ></td>
                                                                <td style={{height : "4.2mm"}} ></td>
                                                                <td style={{height : "4.2mm"}} ></td>
                                                                <td style={{height : "4.2mm"}}></td>
                                                                <td style={{height : "4.2mm"}}></td>
                                                                <td style={{height : "4.2mm"}}></td>
                                                                <td style={{height : "4.2mm"}}></td> 
                                                                <td style={{height : "4.2mm"}}></td>
                                                                <td style={{height : "4.2mm"}}></td>
                                                                <td style={{height : "4.2mm"}}></td>
                                                            </tr>
                                                            <tr >
                                                                <td style={{height : "4mm",borderBottom:"1px solid white"}} ></td>
                                                                <td style={{height : "4mm",borderBottom:"1px solid white"}} ></td>
                                                                <td style={{height : "4mm",borderBottom:"1px solid white"}} ></td>
                                                                <td style={{height : "4mm",borderBottom:"1px solid white"}}></td>
                                                                <td style={{height : "4mm",borderBottom:"1px solid white"}}></td>
                                                                <td style={{height : "4mm",borderBottom:"1px solid white"}}></td>
                                                                <td style={{height : "4mm",borderBottom:"1px solid white"}}></td>
                                                                <td style={{height : "4mm",borderBottom:"1px solid white"}}></td>
                                                                <td style={{height : "4mm",borderBottom:"1px solid white"}}></td>
                                                                <td style={{height : "4mm",borderBottom:"1px solid white"}}></td>
                                                            </tr>
                                                        </>
                                                    );
                                                }
                                            }
                                            var prcd = "";
                                            if (item.PRCD && item.PRCD.trim() !== "") {
                                                if (n === 1) {
                                                    if (item.PLNT === "HV12" || item.PLNT === "HV14" || item.PLNT === "HV15") {
                                                        prcd = `[${item.PRCD}]`;
                                                    }
                                                } else {
                                                    prcd = `[${item.PRCD}]`;
                                                }
                                            }
                                            return (
                                                <>
                                                    <tr key={item.PTNO +"data"+ dindex} style={{height : "4.2mm",borderTop:"0px"}}>
                                                        <td className="noTd2" rowSpan="2">{item.DSEQ}{/*순위 */}</td>
                                                        <td className="partNoTD"  style={{whiteSpace:"nowrap",position:"relative",borderBottom:"0px",borderTop:"0px",lineHeight:"15px",bottom:"-1px",letterSpacing:"1px"}}>{item.PTNO}</td>{/*품번  */}
                                                        <td className="partNoTD"  style={{borderBottom:"0px",borderTop:"0px",lineHeight:"15px"}}></td>{/**/}
                                                        <td className="npqtTd" rowSpan="2">{item.NPQT} {/*납입수량 */}</td>
                                                        <td className="td81" style={{position:"relative",bottom:"-2px"}}> {item.CRDX}</td>{/*담당  */}
                                                        <td className="gateTd" style={{position:"relative",bottom:"-2px"}}> {item.GATE}</td>{/*납입장소 */}
                                                        <td className="vmitd84" style={{position:"relative",bottom:"-2px"}}> {item.DONO}</td>{/*발주번호 */}
                                                        <td className="caseQty" style={{position:"relative",bottom:"-2px"}}>{/*용기당수량/수   */}
                                                        {item.QYPC}/{item.CASEC}
                                                        </td>
                                                        <td></td>
                                                        <td className="noTd2" rowSpan="2"  style={dindex===7?{borderBottom:"0px"}:{borderBottom:"1px solid"}}></td> {/* */}
                                                    </tr>
                                                    <tr style={{height : "4mm"}}>
                                                        <td className="tdptnm" style={{whiteSpace:"nowrap",letterSpacing:"-1px",borderTop:"0px"}}>
                                                            {item.PTNM}{/*품명 */}
                                                        </td>
                                                        <td className="tdptnm" style={{whiteSpace:"nowrap",letterSpacing:"-1px",borderTop:"0px"}}>
                                                            {prcd}{/*[서열코드]  */}
                                                        </td>
                                                        <td className="vmitd82">{item.SJGB && item.SJGB.trim() === "00" ? "" : item.SJGB}</td>{/*구분 */}
                                                        <td className="vmitd82">{item.FEED}{item.LOCT}</td> {/*{item.LOCT} */}
                                                        <td className="vmitd83">{item.DOPT}</td>{/* {item.ODGB}*/}
                                                        <td className="vmitd92">{item.CASE1}</td>{/*용기수   */}
                                                        <td></td>
                                                    </tr>
                                                    {blankTrArray}
                                                </>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <div style={underDiv}>
                                    <div style={{ float: "left", width: "157mm" }}>
                                        {n === 1 ? ""
                                        : (
                                            <>
                                                <div style={{marginBottom:"12px",fontSize:"9pt",fontFamily:"NanumGothic",letterSpacing:"1.5px"}}>※ 본 부품은 회사의 중요한 자산임으로 무단도용, 훼손시 민,형사상의 처벌을 받을 수 있습니다.</div>
                                                <div>
                                                    <div style={{ float: "left",fontFamily:"휴먼매직체",fontSize:"11pt",textDecoration:"underline"}}>MEMO</div>
                                                    <div style={{ float: "right", paddingRight: "7mm" }}>[{cano}]</div>
                                                </div>
                                            </>
                                        )}
                                        &nbsp;
                                    </div>
                                    <div style={{ float: "left", width: "38mm" }}>
                                        <table style={underTable}>
                                            <tr>
                                                <td style={{ borderWidth: "1px", borderStyle: "solid", textAlign: "center" }}>검수</td>
                                                <td style={{ borderWidth: "1px", borderStyle: "solid", textAlign: "center" }}>확인</td>
                                            </tr>
                                            <tr>
                                                <td style={{ borderWidth: "1px", borderStyle: "solid", height: "8mm" }}>&nbsp;</td>
                                                <td style={{ borderWidth: "1px", borderStyle: "solid", height: "8mm" }}>&nbsp;</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div style={{ clear: "both" }}></div>
                                </div>
                            </div>
                        ))}
                        {index === printData.length - 1 ? "" : <div className={"pagebreak"}></div>}
                    </>
                );
            })
            : "";
        //return <div>{cardInfo}</div>;
        return <PrintStyle key={"VmiCardPrintHMG"}>{cardInfo}</PrintStyle>;
    }
}
export default VmiCardPrintHMG;
