import React from 'react';

import '../../css/main.css';

function CustomRadiobox({ //children, ref,
    id, name, labelName, defaultValue, options,
    codes, comms, items,
    codeMode, selectAll,page, onChange }) {

    const handleChange = (e, gubnValue) => { // 검색조건 변경시 이벤트 처리 - 검색조건
        const { name, value } = e.target;
        let event = {
            target: {}
        };
        event.target["name"] = name;
        event.target["value"] = gubnValue; // checked 가 true 일 때, 선택한 라디오버튼의 ID 값 표시


        if (onChange) {
            onChange(event);
        }
    };


    return (
        <>
            <div className={"h_Label1L radios"} style={ page==="ROM3Z"?{paddingTop: "0px"} :{paddingTop: "0px"}}>

                {options ?
                    options.map((option, i) =>
                        <React.Fragment key={"radiobox" + option.name + option.setValue + i}>

                            <input
                                id={option.name}
                                name={name}
                                type={"radio"}
                                style={{ cursor: "pointer" }}

                                value={defaultValue}
                                checked={defaultValue === option.setValue}

                                onChange={(e) => handleChange(e, option.setValue)}
                            />
                            <label htmlFor={option.name} style={{ paddingRight: "10px", cursor: "pointer", fontSize: "12.5px" }}> {option.labelName}</label>
                        </React.Fragment>
                    )
                    : ""}
            </div>
        </>
    );
}

export default CustomRadiobox;