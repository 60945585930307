//excel Loading.js 
import React from "react";
import ReactLoading from "react-loading"; 

import "../../css/main.css";

function CustomLoading2({ loading, type, color}) {
  const cType = type ? type : "spin";      //type 변경은 react-loading에서 변경가능 
 // type LoadingType = "blank" | "balls" | "bars" | "bubbles" | "cubes" | "cylon" | "spin" | "spinningBubbles" | "spokes";
  const cColor = color ? color : "#8f9cf4";
  return <>{loading ? <div style={{height: "94%",zIndex: "1",width: "100%",position: "absolute",opacity: "0.5",backgroundColor: "aliceblue"}}><ReactLoading id={"loading"} type={cType} color={cColor}/></div> : null}</>;
  
}
export default  CustomLoading2;
