/**
 * @Page CarTypePopup
 * @Description 차종등록 Popup
**/

import React, { useState } from "react";
import { Modal, Box } from "@material-ui/core";
import CarType from "./CarType";    //차종등록
import T2Code from "./T2Code";    //t2업체등록
import T1Comp from "./T1Comp";    //t1업체확인
import ExUpload from "./ExUpload";    //엑셀업로드 
import Redeli from "./Redeli";    //삭제내역 복구
import Chul from "./Chul";    //출하미전송조회
import MyInf from "./MyInf";    //MyInfo
import QnaPopup from "./QnaPopup";    //QnaPopup
import Qnarwrite from "./Qnarwrite";    //Qna 답변
import GngsPopup from "./GngsPopup";    //GngsPopup
import GngsWrite from "./GngsWrite";    //기능개선 요청/제안 답변
import NotePopup from './NotePopup';//공지사항
import NoteTopPopup from './NoteTopPopup';//공지사항
import JynoPopup from './JynoPopup';//적용호차 팝업
import DashboardPopup from './DashboardPopup';//Dashboard팝업
import ChullogPopup from './ChullogPopup';//출하전송현황 상세로그 팝업
import PopupMngDetail from "./PopupMngDetail";
import PopupMngPopup from "./PopupMngPopup";
import ChullogMsgPopup from './ChullogMsgPopup';//출하전송현황 상세로그 팝업
import FaqWrite from "./FaqWrite";    //기능개선 요청/제안 답변
import { useHistory } from "react-router-dom";



import { data } from 'jquery';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    height: 620,
    bgcolor: 'background.paper',
    //border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const CarTypestyle ={
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 730,
    height: 620,
    bgcolor: 'background.paper',
    //border: '1px solid #000',
    boxShadow: 24,
    p: 4
};
const T2codestyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 920,
    height: 620,
    bgcolor: 'background.paper',
    //border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const Redelistyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    height: 400,
    bgcolor: 'background.paper',
    //border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const ExUploadstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    height: 330,
    bgcolor: 'background.paper',
    //border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const MyInfostyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 490,
    //height: 520,
    bgcolor: 'background.paper',
    //border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const Qnastyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 900,
    bgcolor: 'background.paper',
    //border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const Gngsstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 900,
    bgcolor: 'background.paper',
    //border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const Chulstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 950,
    height: 600,
    bgcolor: 'background.paper',
    //border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const Jynostyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 460,
    height: 480,
    bgcolor: 'background.paper',
    //border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const Dashboardstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 538,
    bgcolor: 'background.paper',
    //border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const Chullogstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 800,
    bgcolor: 'background.paper',
    //border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};


const FaqWritestyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 800,
    bgcolor: 'background.paper',
    //border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const PopupMngDetailstyle = {
    position: 'absolute',
    top: '44%',
    left: '60%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    height: 730,
    bgcolor: 'background.paper',
    //border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const MngStyle = {
    position: 'absolute',
    // top: '4%',
    // left: '10%',    
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border : "none",
    display : "flex",
    "justify-content": "flexStart"

      
};


function Popup({type, open, onClose, data, para, closeSeqid}) {
    const history = useHistory();
    const [popup, setPopup] = useState(true);
    
    const handleClose = (e,gubn) => {
        onClose(e,gubn);
    };
   
    return(
    <>
        <Modal
        
            open={ data && closeSeqid && data.length ==  closeSeqid.length ? false :  open}
            onClose={()=>{handleClose('',true);}} >
            <Box style={(type == "PopupMngPopup") ? {paddingTop:"0px",paddingBottom:"0px", border:""}:{paddingTop:"10px",paddingBottom:"10px"}} 
                 sx={(type === "PopupMngDetail") ? PopupMngDetailstyle : (type === "PopupMngPopup") ? MngStyle : (type === "GngsWrite") ? Gngsstyle : (type === "GngsPopup") ? Gngsstyle : (type === "Redeli") ? Redelistyle : (type === "ExUpload") ? ExUploadstyle : (type === "T2Code") ? T2codestyle 
                    : (type === "MyInf") ? MyInfostyle : (type === "QnaPopup") ? Qnastyle: (type === "Qnarwrite") ? Qnastyle
                    : (type === "NotePopup"|| type === "NoteTopPopup") ? Qnastyle : (type === "Chul") ? Chulstyle : (type === "JynoPopup") ? Jynostyle :(type === "DashboardPopup") ? Dashboardstyle 
                    : (type === "CarType") ? CarTypestyle : (type === "ChullogPopup") ? Chullogstyle :(type === "FaqWrite") ? FaqWritestyle : style}>
                    {type === "CarType" && <CarType onClose={handleClose} para={para} /> /*차종등록*/}
                    {type === "T2Code" && <T2Code onClose={handleClose} /> /*t2code*/}
                    {type === "T1Comp" && <T1Comp onClose={handleClose} /> /*t1업체코드*/}
                    {type === "ExUpload" && <ExUpload onClose={handleClose} onCloseCust={onClose} /> /*파일생성*/}
                    {type === "Redeli" && <Redeli onClose={handleClose} /> /*삭제내역복구*/}
                    {type === "Chul" && <Chul onClose={handleClose}/> /*출하미전송조회*/}
                    {type === "MyInf" && <MyInf onClose={handleClose}/> /*MyInfo*/}
                    {type === "QnaPopup" && <QnaPopup data = {data} onClose={handleClose} /> /*QnaPopup*/}
                    {type === "Qnarwrite" && <Qnarwrite data = {data} open={open} onClose={handleClose} /> /*QnarwritePopup*/}
                    {type === "GngsPopup" && <GngsPopup data = {data} onClose={handleClose} /> /*GngsPopup*/}
                    {type === "GngsWrite" && <GngsWrite data = {data} open={open} onClose={handleClose} /> /*GngsWritePopup*/}
                    {type === "NotePopup" && <NotePopup data = {data} onClose={handleClose}/> /*NOTEPopup*/}
                    {type === "NoteTopPopup" && <NoteTopPopup data = {data} onClose={handleClose}/> /*NoteTopPopup*/}
                    {type === "JynoPopup" && <JynoPopup data = {data} onClose={handleClose}/> /*적용호차Popup*/}
                    {type === "DashboardPopup" && <DashboardPopup data = {data} onClose={handleClose}/> /*적용호차Popup*/}
                    {type === "ChullogPopup" && <ChullogPopup data = {data} onClose={handleClose}/> /*상세로그Popup*/}
                    {type === "PopupMngDetail" && <PopupMngDetail data = {data} onClose={handleClose}/> /*팝업관리상세*/}
                    {/* {type === "PopupMngPopup" && <PopupMngPopup data = {data} para={para} onClose={handleClose}/> /*팝업관리팝업 기존소스*/} 
                    {data && data.length > 0  && type === "PopupMngPopup" &&
                                    data.map((row,i)=>{
                                if(!(closeSeqid.some((x)=>{ return row["wrcseqn"] === x   }))){
                                    return <PopupMngPopup data = {row} para={para} onClose={handleClose}/> 
                                }
                                }) /*팝업관리팝업 기존소스 신규_2024.07.10 yikim*/
                     }
                    {type === "ChullogMsgPopup" && <ChullogMsgPopup data = {data} onClose={handleClose}/> /*상세로그Popup*/}
                    {type === "FaqWrite" && <FaqWrite data = {data} onClose={handleClose}/> /*상세로그Popup*/}
            </Box>
        </Modal>
    </>
        
    );   
}

 
export default Popup;
