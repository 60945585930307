/**
 * @Page ROM77
 * @Description [ROM77]납입지시 - 협력사재고관리 - 대상품목입력
 **/
import React, {useCallback, useEffect, useRef, useState} from "react";
import Ribbon from "@/components/organisms/Ribbon";
import ContentXearch from "@/components/organisms/ContentXearch";
import Content from "@/components/organisms/Content";
import Grid from "@/components/grid/agGrid";
import CustomAlert from "@/components/molecules/CustomAlert";
import * as Common from "@/utils/Common";
import CommonUtil from "@/utils/CommonUtil";
import AlertDialog from "@/components/material-UI/AlertDialog";
import CODES from "@/config/commonCode.json";



const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const Utils = new CommonUtil();

function ROM77() {
    const token = Common.getToken();
    const userId = Common.getToken("id");
    // const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const bigi = Common.getToken("bigi");
    const grdRom77Main = useRef();   //Grid Main

    const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 


    //Data
    const [idata, setData] = useState({
        //조회조건

        // kVnt1 : tVnt1,      //T1업체
        kVnt1 : ckVnt1,        //T1업체     
        kComp: tComp,       //회사구분
        kPlnt: "",          //공장
        kPtno : "",         //품번
        kGubn : "",         //구분자
        kUsyn : "Y",         //사용여부
        //kInfo_vand: "",


        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장
        compList : [],  //회사
        usynList : ["Y","N"],  //사용여부
    });


    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;

    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      // Dialog Transaction(I,U,D)
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;

    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    let [insertApi, setInsertApi] = useState(false); 
    let [updateApi, setUpdateApi] = useState(false); 

    let [clearGrid, setClearGrid] = useState(false); 

    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 100px  - 50px)"});

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
        setInsertApi(false);
        setUpdateApi(false);
    }, []);



    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success)
                    {
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }

    /**
     *MOUNT
     */
    useEffect( () => {
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
        //공통코드 조회 (공장)
        onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":""}`, "plntList");
        //공통코드 조회 (회사)
        onSearchCommon(`{"pgmid":"COMP"}`, "compList");
    }, []);



    /**
     * Control 변경 Event
     * @param {*} e
     */
    const onChange = async (e) => {
        const {name, value, desc} = e.target;

        let newSearchs = idata;
        newSearchs[name] = value;
        newSearchs[name+"Nm"] = desc;
        setData({ ...newSearchs });

        //회사 변경 시
        if(name === "kComp"){

            newSearchs["kPlnt"] = "";
            newSearchs["kPlntNm"] = "";
            newSearchs["kGate"] = "";
            setData({ ...newSearchs });
            
            onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"CKD"}`, "plntList");
        }  

    }

    const grdRom77Main_onRowClicked = async (e) => {

        
        if(e.node.data.endtyp === " " || e.node.data.endtyp === null){
            e.node.setDataValue('endtyp', 'L');     //END [대물/서열][일반]구분
        }
        if(e.node.data.endrankgb === " " || e.node.data.endrankgb === null){
            e.node.setDataValue('endrankgb', 'D');  //END 서열9직서열/통합서열/비서열
        }
        if(e.node.data.subscgb === " " || e.node.data.subscgb === null){
            e.node.setDataValue('subscgb', 'CKD');  //SUB 소싱구분
        }
        if(e.node.data.subtyp === " " || e.node.data.subtyp === null){
            e.node.setDataValue('subtyp', 'L');     //SUB [대물/서열][일반]구분
        }
        if(e.node.data.subus === " " || e.node.data.subus === null){
            e.node.setDataValue('subus', 1);        //SUB usage 수량
        }
        if(e.node.data.endus === " " || e.node.data.endus === null){
            e.node.setDataValue('endus', 1);      //END usage 수량
        }
    }


    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //T1업체
            {
                formatter: "select", name: "kVnt1", labelName: "T1업체", type: "text", defaultValue: idata.kVnt1,  
                codeMode: true,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: idata.vnt1List, 
                align: "left", 
            },
            //회사구분
            {
                formatter: "select", name: "kComp", labelName: "회사구분", type: "text", defaultValue: idata.kComp,  
                codeMode: true,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: CODES["comp"],
                comms: null,
                align: "left", 
            },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
        ],
        1: [
            // 공장
            {
                formatter: "select", name: "kPlnt", labelName: "공장", type: "text", defaultValue: idata.kPlnt,  
                codeMode: false,
                selectAll: "all", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: idata.plntList,
                align: "left", 
            },
            // 사용여부
            {
                formatter: "select", name: "kUsyn", labelName: "사용여부", type: "text", defaultValue: idata.kUsyn,  
                codeMode: false,
                selectAll: "all", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: null,
                items: [{ "comCode": "Y", "text": "Y" }, { "comCode": "N", "text": "N" }],
                align: "left", 
            },
            // 품번
            {
                formatter: "input", name: "kPtno", type: "text", labelName: "품번"
                , defaultValue: idata.kPtno
                , align: "left" // (우측 정렬)
                , labelType: "none" 
            },
           
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
        ]
    }



    /**
     * 조회
     * @param {*} e
     * @returns
     */
    const onSearch = async (e) => {
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);

        let para = `{"kVnt1": "${idata.kVnt1}", "kPtno": "${idata.kPtno}","kComp": "${idata.kComp}","kPlnt": "${idata.kPlnt}","kUsyn":"${idata.kUsyn}"}`;

        let param = encodeURI(para);

        const query = SERVER_URL + "/inven/master/select?query=" + param;
        setSearchApi(query); // Search API
    }



    /**
     * 신규 Row추가
     * @param {*} e
     */
    const onAddRow = async (e) => {

    }



    /**
     * 입력 ()
     * @param {*} e
     */
    const onInsert = async (e) => {
    
        

    }


     // input check
     const inputCheck = (gbn) => { 

        let inputFlag = true;
        //select nodes
        let selectedData = grdRom77Main.current.getSelectedNodes();

        // 선택 값 체크
        if (selectedData.length === 0) {
            //Alert 창 OPEN
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "선택한 데이터가 없습니다."});
            return false;
        }
    
        //필수입력항목 Check
        selectedData.forEach((node) => {
            if (node.data.zcmodl === null || node.data.zcmodl === "" ) {
                setMessage({ openAlert : true, 
                                severity : "info",
                                msg : "차종을 입력해주세요"});

                grdRom77Main.current.SetFocus(node.rowIndex, "zcmodl");                             
                inputFlag = false
                return false;
            }
            if (node.data.invenend === null || node.data.invenend === "" ) {
                setMessage({ openAlert : true, 
                                severity : "info",
                                msg : "자사 안전재고일수를 입력해주세요"});

                grdRom77Main.current.SetFocus(node.rowIndex, "invenend");                             
                inputFlag = false
                return false;
            }
            if (node.data.invenendus === null || node.data.invenendus === "" ) {
                setMessage({ openAlert : true, 
                                severity : "info",
                                msg : "운송중 안전재고일수를 입력해주세요"});

                grdRom77Main.current.SetFocus(node.rowIndex, "invenendus");                             
                inputFlag = false
                return false;
            }
            if (node.data.invenendsy === null || node.data.invenendsy === "" ) {
                setMessage({ openAlert : true, 
                                severity : "info",
                                msg : "서열장/사외창고 안전재고일수를 입력해주세요"});

                grdRom77Main.current.SetFocus(node.rowIndex, "invenendsy");                             
                inputFlag = false
                return false;
            }

        });
        
        return inputFlag;
    }


    /**
     * 수정
     * @param {*} e
     * @returns
     */
    const onUpdate = async (e) => {

          //input check
          let input = await Promise.all ([inputCheck('U')]);
          if ( !input[0]) return;
          const query = SERVER_URL + "/inven/master/update";
          setUpdateApi(query); // Update API
    }

    /**
     * 삭제
     * @param {*} e
     */
    const onDelete = async (e) => {
    }

    /**
     * 엑셀
     * @param {*} e
     */
    const onExcel = async (e) => {
        let para = `{"kVnt1": "${idata.kVnt1}", "kPtno": "${idata.kPtno}","kComp": "${idata.kComp}","kPlnt": "${idata.kPlnt}","kUsyn":"${idata.kUsyn}"}`;
        let param = encodeURIComponent(para);
        const query = SERVER_URL + "/inven/master/excel?query=" + param;
        
        await fetch(query, {headers: {Authorization: token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'Inven.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })
    }


    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans)
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //삭제
            case "D2" :
                break;

            case "D3" :
                break;
        }
    }

    /**
     * Grid Bind Data
     * @returns 
    */
     const onGridBindData = (para) => {
        let param = encodeURI(para);
        let items = [];

        const query = SERVER_URL + "/comcd/search1?query=" + param; 

        fetch(query, { headers: { Authorization: token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) { 
                    data.data.forEach((element) => {
                        items.push(element.comCode);    
                    });
                }
            })
        return items;

        //return items;
    }

    const onCellValueChanged = async (e) => {
        e.node.setSelected(true);
        
    }

    const editCellClicked = (params) => {
        // default Cell Click 이벤트 

        let gridApi = params.api;

        let rowIdx = params.rowIndex;

        let data = params.data;

        var rowNode2 = gridApi.getDisplayedRowAtIndex(rowIdx); //정렬, 필터링... 하면 변경된 index가져옴

        gridApi.startEditingCell({
            rowIndex: rowIdx,
        }); // Grid EditMode start

        rowNode2.selected = true;
    };

    

    /**
     *Grid Column 정의
     */
        //Main Grid Column
    const mainColumnDefs = [
        {
            headerName: '',
            children: [
                { headerName: "공장", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 100, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, valueGetter: Utils.partnoGetter, hide: false },
                { headerName: "품명", field: "ptnm", sortable: true, filter: true, flex: 1, minWidth: 180, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                //editCellSub - 필수 입력 컬럼 우측 하이라이트 추가
                { headerName: "차종", field: "zcmodl", sortable: true, filter: true, flex: 1,maxLength:5, minWidth: 100, editable: true, headerClass: "editCellSub", onCellClicked: editCellClicked, cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
                { headerName: "사용여부", field: "useyn", sortable: true, filter: true, flex: 1,maxLength:5, minWidth: 100, editable: true, headerClass: "editCellOnlySub", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false,
                cellEditor:'agSelectCellEditor', cellEditorParams: {values: ["Y","N"] },singleClickEdit: true, hide: false },  //valueFormatter : Utils.numberFormatter
            ]
        },
        {
            headerName: '안전재고일수',
            children: [
                { headerName: "자사", field: "invenend", sortable: true, filter: true, flex: 1, minWidth: 150, editable: true, headerClass: "editCellOnlySub", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor",singleClickEdit: true, hide: false},
                { headerName: "운송중", field: "invenendus", sortable: true, filter: true, flex: 1, minWidth: 150, editable: true, headerClass: "editCellOnlySub", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor",singleClickEdit: true, hide: false },
                { headerName: "서열장/사외창고", field: "invenendsy", sortable: true, filter: true, flex: 1, minWidth: 150, editable: true, headerClass: "editCellOnlySub", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor",singleClickEdit: true, hide: false },
            ]
        },
        {
            headerName: '',
            children: [
                { headerName: "END\n사양구분", field: "endpspec", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                { headerName: "END\nusage 수량", field: "endus", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "editCellOnlySub", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                { headerName: "END\n소싱 구분", field: "endscgb", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "editCellOnlySub", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false,
                cellEditor:'agSelectCellEditor', cellEditorParams: {values: onGridBindData(`{"pgmid":"CODE", "vand":"****", "gocd" : "JJ18"}`) },singleClickEdit: true, hide: false },  //valueFormatter : Utils.numberFormatter
                { headerName: "END\n근거리/원거리", field: "enddt", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false,
                cellEditor:'agSelectCellEditor', cellEditorParams: {values:onGridBindData(`{"pgmid":"CODE", "vand":"****", "gocd" : "JJ19"}`)},singleClickEdit: true, hide: false },  //valueFormatter : Utils.numberFormatter
                { headerName: "END\n[대물/서열][일반]구분", field: "endtyp", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false ,
                cellEditor:'agSelectCellEditor', cellEditorParams: {values: onGridBindData(`{"pgmid":"CODE", "vand":"****", "gocd" : "JJ20"}`) },singleClickEdit: true, hide: false },  //valueFormatter : Utils.numberFormatter
                { headerName: "END\n서열9직서열/통합서열/비서열", field: "endrankgb", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false,
                cellEditor:'agSelectCellEditor', cellEditorParams: {values: onGridBindData(`{"pgmid":"CODE", "vand":"****", "gocd" : "JJ21"}`) },singleClickEdit: true, hide: false },  //valueFormatter : Utils.numberFormatter
                { headerName: "SUB품번_현대기아", field: "subpno", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "editCellOnlySub", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                { headerName: "SUB품명_현대기아", field: "subpname", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "editCellOnlySub", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                { headerName: "SUB 사양구분", field: "subpspec", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                { headerName: "SUB\nusage 수량", field: "subus", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "editCellOnlySub", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                { headerName: "SUB\n소싱구분", field: "subscgb", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "editCellOnlySub", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false,
                cellEditor:'agSelectCellEditor', cellEditorParams: {values: onGridBindData(`{"pgmid":"CODE", "vand":"****", "gocd" : "JJ60"}`) },singleClickEdit: true, hide: false },  //valueFormatter : Utils.numberFormatter
                { headerName: "SUB\n[대물/서열][일반]구분", field: "subtyp", sortable: true, filter: true, flex: 1, editable: true,minWidth: 160, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false,
                cellEditor:'agSelectCellEditor', cellEditorParams: {values: onGridBindData(`{"pgmid":"CODE", "vand":"****", "gocd" : "JJ20"}`) },singleClickEdit: true, hide: false },  //valueFormatter : Utils.numberFormatter
                { headerName: "SUB\n업체명", field: "subvendnm", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "editCellOnlySub", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                { headerName: "SUB\n업체 사업자등록번호", field: "subregistnum", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                { headerName: "SUB\n업체코드", field: "subvendcd", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                { headerName: "SUB\n업체국가", field: "subvendco", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "editCellOnlySub", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                { headerName: "SUB\n업체주소", field: "subvendaddr", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                { headerName: "협력사(1차사)시스템\n담당자명/직급", field: "endchnm", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
                { headerName: "협력사(1차사)시스템\n담당자 연락처", field: "endchtel", sortable: true, filter: true, flex: 1, minWidth: 160, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
            ]
        },
        ];

        const onClick = async (e) => {
            setPanded(!panded);
    
            if(panded===true){
                setStyle({       
                    height :"calc(100vh - 60px - 50px - 50px)"
                    });
            }else{
                setStyle({
                    height :"calc(100vh - 60px - 50px - 100px  - 50px)"
                    });
            }
        }

    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg}
                         onClose={
                             ()=> {
                                 setMessage({...message, openAlert: false});
                             }
                         }
                         anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />


            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onSearch={onSearch} onSearchName={null}     //조회
                onUpdate={onUpdate} onUpdateName={"수정"}     //등록
                onExcel={onExcel} onExcelName={null} //Excel
            >
                {"납입지시 > 협력사재고관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>대상품목입력</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick={onClick}/>


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:100px */}
                <div className={"grid_type01"}> {/* 상단 FRAME */}
                    <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                        <Grid
                            ref={grdRom77Main}
                            gridId={"ROM77"}                    //  (필수) Grid Id 정의
                            columnDefs={mainColumnDefs}             //  (필수) 컬럼 정의
                            pagination={true}                  //  쪽수 매기기 여부
                            paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                            paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                            rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                            suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                            rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                            enableRangeSelection={false}        //
                            enableCellTextSelection={true}      // cell selection drag mode
                            isFirst={true}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                            displayNo = {false}
                            searchApi={searchApi}               // 조회
                            insertApi={insertApi}               // 등록
                            updateApi={updateApi}               // 수정
                            onClearApi={onClearApi}             // 초기화
                            clearGrid={clearGrid}
                            onClearGridFlag={setClearGrid}
                            onRowClickEvent={grdRom77Main_onRowClicked}
                            onCellValueChangedEvent={onCellValueChanged}
                            onCellClicked={onCellValueChanged}
                        />
                    </div>
                </div>
            </Content>

        </>
    )
}
export default ROM77;

