import React from "react";
import { Button, Dialog , DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";


function AlertDialog({open, trans, message, onClose}) 
{
    const handleClose = (action) => {
        let e = {Trans:trans, result:action}
        onClose(e);
    };
      
    return(
        <Dialog
            open={open}
            onClose={()=> {handleClose("N")}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
      >
            <DialogTitle id="alert-dialog-title">
                {trans.substring(0, 1) === "I" ? "등록확인" : trans.substring(0, 1) === "U" ? "수정확인" 
                                                           : trans.substring(0, 1) === "D" ? "삭제확인" 
                                                           : trans.substring(0, 1) === "P" ? "인쇄확인" : trans  }
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message.split("\n").map((line, i) => { return (<span key={i}>{line}<br /></span>);})}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={()=> {handleClose("Y")}}>예</Button>
            <Button onClick={()=> {handleClose("N")}} autoFocus>
                아니요
            </Button>
            </DialogActions>
        </Dialog>
    );   
}

export default AlertDialog;