import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import Ribbon from '../components/organisms/Ribbon';
import Section from '../components/organisms/Section';
import CustomAlert from "@/components/molecules/CustomAlert";
import MainTemplates from '@/components/templates/MainTemplates';
import '@/css/common.css';
import '@/css/pagenotfound.css';



// import * as common from '../../utils/Common'; 
// import axios from 'axios';


// const navi = require('../config/navigation.json');
// const common = require('../config/commonCode.json');

function NotFoundPage() {
    const [message, setMessage] = useState({
        openAlert: false,      
        severity: "success",   
        msg: ""                
    });
    const { openAlert, severity, msg } = message;
    const history = useHistory();

    useEffect(() => {

    }, []);
  


    return (
        <>
            <MainTemplates>
                <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={() => { setMessage({ openAlert: false, severity: "", msg: "조회 실패" }) }} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
                <Section>
                    <div className="notfound_main">
                        <div id="main" className="notfound_div">
                            <div className="fof_notfound">
                                <div style={{width:"190px",height:"910px",float: "left"}}></div>
                                <div style={{width:"600px",height:"910px",float: "left"}}>
                                    <div style={{height:"155px"}}> </div>
                                    <img src="/images/404_top.png" alt="404_top" title="404_top" style={{ position: "relative",left: "-39%"}}/>
                                    <div style={{fontFamily:"'GmarketSansBold'",color:"#2E2E2E",fontSize: "31pt",textAlign: "left",letterSpacing:"-3px"}}> Page Not Found</div>
                                    <div style={{fontFamily:"'GmarketSansLight'",color:"#000",fontSize: "29pt",textAlign: "left"}}> 페이지를 찾을 수 없습니다.</div>
                                    <div style={{height:"60px"}}> </div>
                                    <div className="notfound_mid"> 이용에 불편을 드려 죄송합니다.</div>
                                    <div className="notfound_mid"> 주소를 다시 한 번 확인해주세요</div>
                                    <div style={{height:"80px"}}> </div>
                                    <div> 
                                        <div className="notfound_btn" style={{marginRight:"12px"}} onClick={ () => {history.goBack();} } >이전 페이지</div>
                                        <a href="/"><div className="notfound_btn">홈으로 가기</div></a>
                                    </div>
                                </div>
                                <div style={{overflowX: "hidden"}}>{/*width:"700px",,float:"left"*/}
                                    <img src="/images/404_bg.png" alt="404_img" title="404_img" style={{ paddingRight: "34px",verticalAlign: "middle",paddingTop: "294px",width:"844px",display: "flex"}}/>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </Section> {/* Section FRAME (WorkFrame) -------------- END */}
            </MainTemplates>
        </>
    );
}
export default NotFoundPage;
