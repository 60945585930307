/**
 * @Page WrapCkdLabelPrint_C_NEW
  * @Description [ROM4K / ROM4R / ROM4X]납입지시 - ERP_CKD출하관리 - 부품식별표(중포장/용기/중포장2D) 인쇄  ::  C 12매
 **/
 import React from "react";
 import CustomDataMatrix from '@/components/atoms/CustomDataMatrix';
 import * as Common from "../../utils/Common";
 import styled from 'styled-components';
 
class WrapCkdLabelPrint_C_NEW extends React.Component {

    constructor(props) {
        super(props);
        this.state = { pathNm: null };
}

componentDidMount() {
    if(window.location.pathname.split('/')[2] === "ROM4X") { // ROM4X 부품식별표(중포장 2D) check
        this.setState({pathNm:window.location.pathname.split('/')[2]});
    }       
  }


    // '바코드 = 업체코드(4) + 품번(15) + 수량(5) + LOTNO(3) + 일련번호(4)
    //  tmp(0) '회사구분 / tmp(1) '지역 / tmp(2) '공장 / tmp(3) '차종 / tmp(4) '납품장소(하치장)
    //  tmp(5) '품번 / tmp(6) '품명 / tmp(7) '납품수량 / tmp(8) '바코드 / tmp(9) '납품일자
    //  tmp(10) '업체명 / tmp(11) "" '공정(공백) / tmp(12) '업체코드 / tmp(13) "" ' LOT NO / tmp(14) "" 'HPC(공백)
    //  tmp(15) 'CASE(CKD) / tmp(16) "" '발행번호(바코드만들어서 던짐) / tmp(17) "" '용기번호 / tmp(18) '부착매수 / tmp(19) 'LH/RH
    //  tmp(20) "" '사양 / tmp(21)  '발주번호(공백) / tmp(22)  'FROM-ORDER(CKD , OEM은 공정) / tmp(23)  'CKD는 공백, OEM은 HPC / tmp(24)  '용기당수
    //  tmp(25) = '출력1 / tmp(26) = 'x좌표1 / tmp(27) = 'y좌표1 / tmp(28) = '색상1 / tmp(29) = 'FONT/S1
    //  tmp(30) = '출력2 / tmp(31) = 'x좌표2 / tmp(32) = 'y좌표2 / tmp(33) = '색상2 / tmp(34) = 'FONT/S2
    //  tmp(35) = '한글품명 / tmp(36) = 'RHD


    render() {

        const PrintStyleC = styled.div`
        @media all {
            body {
                margin: 0px;
                padding: 0px;
                font-family: "HY견고딕";
            }
            .HYheadM5 {
                font-family: "HY견고딕";
                font-weight:bold;
                font-size:5pt;
            }
            .HYheadM6{
                font-family: "HY견고딕";
                font-weight:bold;
                font-size:6pt;
            }
            .NmheadM6{
                font-family: "NanumGothic";
                font-weight:bold;
                font-size:6pt;
            }
            .HYheadM7 {
                font-family: "HY견고딕";
                font-weight:bold;
                font-size:7pt;
                word-break:break-all;
            }
            .HYheadM8 {
                font-family: "HY견고딕";
                font-weight:bold;
                font-size:8pt;
                word-break:break-all;
            }
            .HYheadM10 {
                font-family: "HY견고딕";
                font-weight:bold;
                font-size:10pt;
                word-break:break-all;
            }
            .HYheadM11 {
                font-family: "HY견고딕";
                font-weight:bold;
                font-size:11pt;
                word-break:break-all;
            }
            .HYheadM12 {
                font-family: "HY견고딕";
                font-weight:bold;
                font-size:12pt;
                word-break:break-all;
            }
            .HYheadM14 {
                font-family: "HY견고딕";
                font-weight:bold;
                font-size:14pt;
                word-break:break-all;
            }
            .HYheadM16 {
                font-family: "HY견고딕";
                font-weight:bold;
                font-size:16pt;
                word-break:break-all;
            }
            .HYheadM18 {
                font-family: "HY견고딕";
                font-weight:bold;
                font-size:18pt;
                word-break:break-all;
            }
            .HYheadM9 {
                font-family: "HY견고딕";
                font-size:9pt;
                word-break:break-all;
            }  
            .HYheadM24 {
                font-family: "HY견고딕";
                font-size:24pt;
                word-break:break-all;
            }
            .HYheadM27 {
                font-family: "HY견고딕";
                font-weight:bold;
                font-size:27pt;
                word-break:break-all;
            }

            .cell { display : flex; white-space: nowrap; z-index: 99;}

            .cell_hori { display : flex; justify-content: center; } // 수평 중앙정렬 → 

            .cell_veri { display : flex; align-items: center; } // 수직 중앙정렬 ↓

            .aligR { flex-direction: row-reverse; }
            
            .row2  { display: flex; flex-flow: row nowrap; }

            .row2>.cell:nth-child(1)  { width: 3.779527559px ; } // background : red ; 
            .row2>.cell:nth-child(2)  { width: 26.45669291px ; } // background : blue ; 
            .row2>.cell:nth-child(3)  { width: 5.669291339px ; } // background : orange ; 
            .row2>.cell:nth-child(4)  { width: 5.669291339px ; } // background : purple ; 
            .row2>.cell:nth-child(5)  { width: 5.669291339px ; } // background : beige ; 
            .row2>.cell:nth-child(6)  { width: 45.35433071px ; } // background : pink ; 
            .row2>.cell:nth-child(7)  { width: 28.34645669px ; } // background : orange ; 
            .row2>.cell:nth-child(8)  { width: 5.669291339px ; } // background : green ; 
            .row2>.cell:nth-child(9)  { width: 39.68503937px ; } // background : yellow ; 
            .row2>.cell:nth-child(10) { width: 18.8976378px  ; } // background : red ; 
            .row2>.cell:nth-child(11) { width: 15.11811024px ; } // background : navy ; 
            .row2>.cell:nth-child(12) { width: 5.669291339px ; } // background : green ; 
            .row2>.cell:nth-child(13) { width: 51.02362205px ; } // background : pink ; 
            .row2>.cell:nth-child(14) { width: 3.779527559px ; } // background : red ; 
            .row2>.cell:nth-child(15) { width: 3.779527559px ; } // background : blue ; 
        
        }
        
        `;
        

        const { printData } = this.props;
        
        let { kStart, chkMetal, kCkdOem, chkNpdt, chkLtno, kNpdt } = this.props.idata;

        const mmToPx =(mm) => { let px = mm * 3.7795275591; return px; }
        
        const listSize = printData.length; // data length

        let idx = -1;
        let str = parseInt(kStart)-1;
        
        idx =idx + str; // 시작 위치
        
        let vseq = 0;

        let remainChk = 0;        
        
        let mainSearchedDivArr = []; // 전체 반복 이후 새로운 배열에 전체로 담아서 진행한다.

        const cardInfo_S = 
            printData ? printData.map((data, index) => {
            
             if(index === 0){ 
                vseq = parseInt(data.SEQ)===0?0: parseInt(data.SEQ)-1; // 바코드 표출 vseq용
            }

            // remain 표출 체크용 - remainChk -- 신규 품번의 vseq 담기
            remainChk = vseq;  // 새 품번의 remain 여부 체크하기
                
            let npqt = parseInt(data.NPQT); // 납품수량
            let qypc = parseInt(data.QYPC); // 용기당 수
            let stck = parseInt(data.STCK); // 부착면 수 

            // 추가 표시 컬러 체크 -- START
            let tmp25 = data.TMP25; // TMP25    WRCLBL1	이중인쇄1 내용
            let tmp28 = data.TMP28; // TMP28    WRCCOL1	이중인쇄1 컬러

            let tmp30 = data.TMP30; // TMP30    WRCLBL2	이중인쇄2 내용
            let tmp33 = data.TMP33; // TMP33    WRCCOL2	이중인쇄2 컬러

            let color1 = "";
            let color2 = "";

            let dualPrint1 = "F"; 
            let dualPrint2 = "F"; 
            

            if(tmp25!==""){

                dualPrint1 = "T";

                if(tmp28==="B"){ color1 = "blue";
                }else if(tmp28==="G"){ color1 = "green";
                }else if(tmp28==="R"){ color1 = "red";
                }else if(tmp28==="O"){ color1 = "#ff6600"; 
                }else if(tmp28==="X"){ color1 = "rgba(0, 0, 0, 0.7)"; }
                
            }
            if(tmp30!==""){

                dualPrint2 = "T";

                if(tmp33==="B"){ color2 = "blue";
                }else if(tmp33==="G"){ color2 = "green"; 
                }else if(tmp33==="R"){ color2 = "red"; 
                }else if(tmp33==="O"){ color2 = "#ff6600"; 
                }else if(tmp33==="X"){ color2 = "rgba(0, 0, 0, 0.7)"; }
            }
            
            let result = Math.floor(npqt / qypc); // 몫
            let resultIdx = Math.floor(npqt / qypc); // 목 + 나머지의 카운트
            let remain = npqt % qypc; // 나머지

            if(remain > 0){ resultIdx = result + 1; }

            const newSearched = [];
            let n = 0;

            Array.from(new Array(resultIdx), (v, i) =>{  // Main

                Array.from(new Array(stck), (w, j) =>{  // 부착면수 만큼 반복

                    idx = idx+1;
                    if(j===0){ // 부착면수 1일때
                        vseq = vseq + 1;    // 새 vseq 마다 + 1 증가 
                    }
                    
                    let iCnt = i+1;
                    const newArray = {};
                    let jCnt = j+1;

                    // 새 배열의 n 번째 구하기
                    if(i===0 && j===0){ n=0; }else{ n=n+1; }

                    Object.keys(data).forEach(key => { // 기존값 배열에 추가
                        newArray[key] = data[key];
                    });
                    
                    newArray["idx"] = idx;
                    newArray["vseq"] = vseq;

                    //납품수량
                    let newQty = 0;
                    newArray["qty"] = qypc; 
                    newQty = qypc; 

                    newArray["endpage"] = "F"; 

                    newArray["color1"] = color1; 
                    newArray["color2"] = color2; 

                    newArray["dualPrint1"] = dualPrint1; 
                    newArray["dualPrint2"] = dualPrint2;

                    if(resultIdx === (vseq-remainChk)){
                        if(remain > 0){
                            newArray["qty"] = remain; 
                            newQty = remain; 
                        }
                    }else{
                        if(index===0 && resultIdx === 1 && vseq=== 1){
                            if(remain > 0){
                                newArray["qty"] = remain; 
                                newQty = remain; 
                            }
                        }
                     }

                    // END Page 구하기
                    if(listSize === (index+1)){   
                        if(resultIdx === iCnt){
                            if(stck === jCnt){
                                newArray["endpage"] = "T"; 
                            }
                        }
                    }

                    //LOTNO(3)
                    const lotNo = data.TMP13===""?" ":Common.getLOT(data.TMP13); // LOT NO 날짜 가져오기  data.TMP13

                    //일련번호(4) 
                    // const prno = (data.TMP0===undefined?"":data.TMP0) + "" + ("0000" + parseInt(vseq, 10)).slice(-4);
                              
                    let barcodeValu = "";

                    if(kCkdOem === "CKD"){// 중포장일때
                        // 수량 6자리에서 7자리로 변경 2022-06-22
                        // 바코드 = 업체코드(4)   + 품번(13)                            + 수량(7)

                        // 자동차 측 요구로 바코드 자릿수 변경 2023-08-21
                         // 바코드 = 오더번호(10)              + 품번(14)                       + 수량(8) 
                        barcodeValu = data.TMP21 +""+ (data.TMP5).padEnd(14," ") +""+ ("0000000" + newQty).slice(-8)
                        
                    }else{//용기일때
                        // 바코드 = 업체코드(4)   + 품번(13)                       + 수량(6)
                        // barcodeValu = data.TMP22 +""+ (data.TMP5).padEnd(13," ") +""+ ("000000" + newQty).slice(-6)
                         
                        // 수량 6자리에서 7자리로 변경 2022-06-22
                        // 바코드 = 업체코드(4)   + 품번(13)                            + 수량(7)

                        // 자동차 측 요구로 바코드 자릿수 변경 2023-08-21
                         // 바코드 = 오더번호(10)              + 품번(14)                       + 수량(8) 
                        barcodeValu = data.TMP22 +""+ (data.TMP5).padEnd(14," ") +""+ ("0000000" + newQty).slice(-8)
                        
                    }

                    // DataMatrix Barcode 만들기 - code128
                    const dataMatrixBarcode128 = <CustomDataMatrix val={barcodeValu} height={12} bcid={"code128"} scale={0.5} width={"100%"} />;
                    newArray["barcode"] = dataMatrixBarcode128; 

                    // DataMatrix Barcode 만들기  - dataMatrix
                    const dataMatrixBarcode = (this.state.pathNm !== null) && (this.state.pathNm === "ROM4X") || (this.props.idata.kGubn2 === "ROM4X")? <CustomDataMatrix val={barcodeValu} height={18} width={"100%"} scale={0.5} /> : "";
                   //  const dataMatrixBarcode = <CustomDataMatrix val={barcodeValu} height={18} scale={1} />;
                    newArray["barcodeMatrix"] = dataMatrixBarcode; 

                    const ptnm = (data.TMP6).slice(0,16);
                    newArray["ptnm"] = ptnm; 
                    newArray["lotno"] = lotNo;
                    newArray["bno"] = barcodeValu;

                    // 자기 위치 셋팅 (출력매수)
                    const posi = idx%12;                    
                    newArray["posi"] = posi;
                                    
                    newSearched[n] = newArray;
                })
            })

            mainSearchedDivArr.push(...newSearched)
            
        })
        :"";


        let cardInfo = 
        <div className="forfor"  style={{  
                display : "flex",flexWrap : "wrap", overflow : "hidden", 
                width : mmToPx(280) ,marginLeft : ( 11/* 12.6 */ + parseInt( ( Common.getToken("bplx") ? Common.getToken("bplx") : 0 ),10)) + "mm" }}>
            {/* Start Point */}
            {Array.from(new Array(kStart-1), (v, i) => { 
                return  <div  className="start_from_test"  
                            style={{ marginTop : i == 0 || i == 1 ||i == 2 ||i == 3  ? mmToPx(20+ parseInt( ( Common.getToken("bply") ? Common.getToken("bply") : 0 ),10)) : "0"
                                    , height : mmToPx(50) 
                                    , marginBottom : mmToPx(10)
                                    , flexBasis : mmToPx(69.4) }}></div> 
                                    } 
                    )
            }
            {mainSearchedDivArr.map((sData, dataIdx) => (
            <>
                <div className="start_from" key={"WrapCkdLabelPrint_C_NEW"+dataIdx}
                            style={{ marginTop : sData.posi == 0 ||sData.posi == 1 ||sData.posi == 2 ||sData.posi == 3? mmToPx(20+ parseInt( ( Common.getToken("bply") ? Common.getToken("bply") : 0 ),10)) : "0"
                                , height:mmToPx(50) 
                                , marginBottom : mmToPx(10)
                                , flexBasis : mmToPx(69.4) 
                                }} >
                    
                    <div className="dep_div" style={{ overflow:"visible", zIndex:"200" , position : "relative", height : "100%", width: "100%" }}> 

                    { sData.dualPrint1 === "T" ?
                            <div style={{ position:"absolute"
                                        , fontFamily: "HY견고딕" 
                                        , top:parseInt(sData.TMP27,10) + "mm"  
                                        , left:parseInt(sData.TMP26,10) + "mm" 
                                        , color:sData.color1
                                        , fontSize:sData.TMP29+"pt" 
                                        , fontWeight:"bold"
                                        , whiteSpace:"nowrap"
                                        , zIndex:"13"
                                        , visibility : parseInt(sData.TMP27,10)  > 60 ? "hidden" : "visible"
                                    }}> {sData.TMP25} </div>
                        :"" }

                        { sData.dualPrint2 === "T" ?
                            <div style={{ position:"absolute" 
                                        , fontFamily: "HY견고딕" 
                                        , top:parseInt(sData.TMP32,10) + "mm" 
                                        , left:parseInt(sData.TMP31,10) + "mm" 
                                        , color:sData.color2 
                                        , fontSize:sData.TMP34+"pt" 
                                        , fontWeight:"bold" 
                                        , whiteSpace:"nowrap"
                                        , zIndex:"12"
                                        , visibility : parseInt(sData.TMP32,10)  > 60 ? "hidden" : "visible"
                                    }}>{sData.TMP30}</div>
                        : "" }
                        <div className="row2" style={{height: mmToPx(1)}}>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>    
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                        </div>

                        <div className="row2" style={{height:mmToPx(3)}}>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell" ></div>    
                            <div className="cell" ></div>
                            <div className="cell" ></div>
                            <div className="cell HYheadM6 cell_hori cell_veri" style={{ flex : "1 1 12mm", display : "flex", ali : "flex-end" }}>{sData.TMP1}</div>{/* 지역 */}         
                            <div className="cell NmheadM6 cell_veri" style={{ flex : "1 1 24mm" ,letterSpacing:"0.05px"}}>
                                {sData.TMP0==="MPMC"?"현대파워텍(주)용ⓡ" : sData.TMP4==="MAMC"? "글로비스(주)용ⓡ" : "현대자동차(주)/기아(주)ⓡ"}
                            </div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div>
                        <div className="row2" style={{height:"4mm"}}>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell HYheadM11" style={{ flex : "1 1 15mm" }}>{sData.TMP2}</div>{/* 공장 */}
                            <div className="cell" style={{flex : "1 1 7.5mm"}}></div>                 
                            <div className="cell HYheadM8 cell_hori cell_veri" style={{ flex : "1 1 12mm" }}>{sData.TMP3}</div>{/* 차종 */}
                            <div className="cell" style={{flex : "1 1 9mm"}}></div>                 
                            <div className="cell HYheadM8 cell_hori cell_veri" style={{ flex : "1 1 15mm" }}>{sData.TMP4}</div>{/* 납품장소 */}
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 

                        <div className="row2" style={{height:"6.5mm"}}>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell HYheadM14 cell_veri" style={{flex : "1 1 39.5mm"}}>
                                {sData.TMP5.substr(0, 5) + "-" + sData.TMP5.substr(5)}{/* 품번 */}
                            </div>
                            <div className="cell" style={{flex : "1 1 4mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1.5mm"}} ></div>
                            <div className="cell HYheadM14 cell_hori cell_veri" style={{flex : "1 1 13.5mm"}}>{sData.qty}</div>{/* 수량 */}
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 

                        <div className="row2" style={{height:"6.5mm"}}>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell HYheadM11 cell_veri" style={{ flex : "1 1 43.5mm" }}>{sData.TMP6}</div>{/* 품명 */}
                            <div className="cell cell_veri cell_veri" style={{flex : "1 1 1.5mm"}} ></div>
                            <div className="cell HYheadM18 cell_veri aligR" style={{lineHeight:"7.5mm" ,color:(sData.TMP19==="좌" || sData.TMP19==="L") ?"red":"blue", textAlign:"right", zIndex:"10"}}>
                                {sData.TMP19}{/* LH/RH */}
                            </div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 

                        <div className="row2" style={{height: mmToPx(1)}}></div>
                        <div className="row2" style={{height:"5mm"}}>
                            <div className="cell HYheadM10 cell_hori cell_veri" style={{ overflow:"visible", flex : "1 1 32.5mm" }}>G/W : {sData.TMP35} kg</div>
                            <div className="cell HYheadM10 cell_hori cell_veri" style={{ overflow:"visible", flex : "1 1 32.5mm"  }}>N/W : {sData.TMP20} kg</div>
                        </div> 
                    
                        <div className="row2" style={{height:"6mm"}}>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell"></div>
                            <div className="cell HYheadM12" style={{zIndex:"15",flex : "1 1 60mm" }}>
                                {sData.barcode}
                            </div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 
                    
                        <div className="row2" style={{height: "1mm" /* mmToPx(1) */}}>
                            <div className="cell"></div>
                        </div>

                        <div className="row2" style={{height:"4mm"}}>
                            <div className="cell"></div>
                            <div className="cell" style={{flex : "1 1 8.5mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1.5mm"}} ></div>
                            <div className="cell HYheadM6 cell_veri" style={{flex : "1 1 13.5mm"}}>{chkNpdt&&chkNpdt==="Y"?sData.TMP9:""}</div>{/* 납품일자 chkNpdt sData.TMP9=납기일자(원복) 2022-06-27 */}
                            <div className="cell" style={{flex : "1 1 7.5mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1.5mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1.5mm"}} ></div>
                            <div className="cell HYheadM6 cell_veri" style={{flex : "1 1 33mm"}}>{sData.TMP12}　{sData.TMP10}</div>{/* 업체코드 */} {/* 업체명 */}
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            {/* <div className="cell colspan2" style={{fontSize:"20pt", overflow:"visible", paddingTop:"7px", paddingLeft:"2mm", zIndex:"15"}}>  이거 기존 바코드*/}
                            
                        </div> 
                        
                    
                        <div className="row2" style={{height:"4mm"}}>
                            <div className="cell"></div>
                            <div className="cell HYheadM6 aligR cell_veri" style={{fontWeight:"normal", flex : "1 1 8.5mm"}}>
                                {kCkdOem==="CKD"?"Order":kCkdOem==="CKD2"?"From":""}
                            </div>
                            <div className="cell" style={{flex : "1 1 1.5mm"}} ></div>
                            <div className="cell HYheadM6 cell_veri" style={{ flex : "1 1 13.5mm" }}>{sData.TMP22}</div>{/* 공정NO */}
                            <div className="cell" style={{flex : "1 1 7.5mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1.5mm"}} ></div>
                            <div className="cell HYheadM27 cell_veri" style={{ flex : "1 1 34.5mm" }}>{sData.TMP36==="Y"?"RHD":""}</div>{/* RHD > CKD 화면에서는 사용하지 않는다.*/}
                            <div className="cell" style={{flex : "0"}}>
                                { sData.TMP15!==""?
                                                <div style={{ position:"absolute"
                                                            , fontFamily: "HY견고딕"
                                                            , fontSize : !(sData.TMP15) === false &&  (sData.TMP15).toString().replace(/\s/ig, "").length > 4 ?"16px" : "20pt"
                                                            , fontWeight:"bold"
                                                            , zIndex:"15"
                                                            , marginLeft : "-120px" }}>{sData.TMP15}</div>
                                :"" }
                                {/* 중금속 확인필 */}
                                { chkMetal==="Y" ? <div style={{position : "absolute", marginLeft : "-30px" , zIndex : "0"}}> <img src ="/images/heavy_metal_confirm.gif" style={{width:"9mm"}} alt="heavy_metal_confirm" /> </div> :""}
                            </div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{ position : "absolute", fontSize:"20pt", overflow:"visible", zIndex:"15", flex : "0", left : "49mm", width : "34px"}}>
                                {sData.barcodeMatrix}
                            </div>{/* 중포장 2D 바코드 */} {/* BARCODE DataMatrix - DataMatrix 추가 표출  */}
                        </div> 
                    
                        <div className="row2" style={{height:"4mm"}}>
                            <div className="cell"></div>
                            <div className="cell HYheadM6 aligR cell_veri" style={{fontWeight:"normal", flex : "1 1 8.5mm"}}>
                                {kCkdOem==="CKD"?"":kCkdOem==="CKD2"?"To":""}
                            </div>
                            <div className="cell" style={{flex : "1 1 1.5mm"}} ></div>         
                            <div className="cell HYheadM6 cell_veri" style={{ flex : "1 1 16.5mm" }}>{sData.TMP23}</div>{/* To-Order */}
                            <div className="cell HYheadM9 cell_veri " style={{flex : "1 1 7.5mm", fontWeight:"bold"}}>{chkLtno&&chkLtno==="Y"?sData.lotno:""}</div>{/* getLOT() chkLtno  */}
                            <div className="cell" style={{flex : "1 1 33mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                            <div className="cell" style={{flex : "1 1 1mm"}} ></div>
                        </div> 

                        <div className="row2" style={{height:"3.5mm"}}>
                            <div className="cell"></div>
                            <div className="cell" style={{flex : "1 1 11mm"}} ></div>
                            <div className="cell HYheadM5 cell_veri" style={{ flex : "1 1 44mm" }}> {sData.bno} </div>{/* bno */}
                            <div className="cell HYheadM7 cell_veri" style={{flex : "1 1 9mm"}}>{sData.TMP17}</div>{/* 용기 Mo */}
                        </div> 

                        <div className="row2" style={{height: "1mm"}}><div className="cell"></div></div>

                    </div>

                    {/*페이지 넘김 처리 */}
                    { sData.posi===11 && sData.endpage==="F" ? <div className="page-break"></div>  : "" } 

            </div>
            </>
        ),"")}
        </div>

        return <PrintStyleC key={"WrapCkdLabelPrint_C_NEW"}>{cardInfo}</PrintStyleC>;

    }
}
export default WrapCkdLabelPrint_C_NEW;
