import React, { useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Snackbar } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import * as Common from '@/utils/Common'; 

import Loading from "../atoms/CustomLoading";

const baseStyle = {
    flex: 1,
    display: "flex",
    height: "116px",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 0px 20px 10px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor:"pointer"
};

const activeStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};
function SnackAlert(props) {
    return (
        <Alert elevation={6} {...props}>
            <AlertTitle>{props.severity}</AlertTitle>
            {props.children}
        </Alert>
    );
}



function CustomDropzone({ filePath, fileId, maxFiles, uploadApi, uploadInfo, accp, validatorName, data, onClose }) {
    const token = Common.getToken();

    let [loading, setLoading] = useState(false); 
    const [open, setOpen] = React.useState(false);
    const [result, setResult] = React.useState("");
    const [severity, setSeverity] = React.useState("success");
    let [count, setCount] = React.useState("");  //대상건수
    let [acount, setAcount] = React.useState("");//성공건수
    let [scount, setScount] = React.useState("");//실패건수
    let vnt1 = React.useState("");


    const [idata, setData] = useState({
        vnt1 : "",
        vnt1List : [],  //T1업체
    }); 

    // 파일 validation 커스터마이즈
    const csvFileValidator = (file) => {
        
        let fname = file.name.toUpperCase();

        // 대문자 비교
        // 앞에 구문자만                            // 확장자만        // 파일 명 자체 비교에서 파일 이름(앞 5자리) + 확장자명 체크로 수정 2022-08-29
        if ( (fname.substring(0, 5) !== `${fileId}`) || (fname.substring(fname.length - 4) !== `.CSV`) ) { 
            return {
                code: "not-this-file",
                message: ` ${fileId}로 시작하는 .csv확장자인 파일을 선택해주세요`,
            };
        }
    };
    function onDropRejected(fileRejections) {
        if (fileRejections.length > 0) {
            setResult(fileRejections[0].errors[0].message);
        } else {
            setResult("파일 업로드가 실패했습니다. 파일을 다시 확인해주세요");
        }
        setSeverity("error");
        setOpen(true);
        return;
    }

    //  파일 데이터 서버로 보내기
    function onDrop(acceptedFiles, fileRejections) {
        if (acceptedFiles.length === 0) {
            return;
        }
        if (fileRejections.length > 0) {
            setResult(fileRejections[0].errors[0].message);
            return;
        }
        if (maxFiles < acceptedFiles.length) {
            setResult(`파일은 ${maxFiles} 개만 허용됩니다`);
            setSeverity("error");
            setOpen(true);
            return;
        }
        const formData = new FormData();
        setLoading(true);
        // 멀티 파일일 경우 > 현재는 해당케이스 없음.
        if (maxFiles > 1) {
            acceptedFiles.forEach((file) => formData.append("files[]", file));
        } else {
            formData.append("file", acceptedFiles[0]);
        }
        for (const [key, value] of Object.entries(uploadInfo)) {
            formData.append(`${key}`, `${value}`);
        }
        
        const fetchData = async () => {
            const query = uploadApi; 
            let msg = "";

            await fetch(query, { method: "POST", headers: { Authorization: token }, body: formData })
                .then((resp) => resp.json())
                .then((data) => {
                    let ser = "";
                    if (data.success) {
                        ser = "success";
                        msg = data.data.msg;

                        setCount(data.data.count);
                        setAcount(data.data.acount);
                        setScount(data.data.scount);
                        onClose("T");
                    } else {
                        ser = "error";
                        msg = !data.errors.message === false ? data.errors.message : !data.errors.msg === false ? data.errors.msg : "다시 시도하거나 관리자에게 문의해주세요";;
                    }
                    setLoading(false);
                    setSeverity(ser);
                    setResult(msg);
                    setOpen(true);
                    
                })
                .catch((error) => {
                    if (error) {
                        alert("서버 상태를 확인해 주세요. 관리자에게 문의해주세요.");
                        setLoading(false);
                        setSeverity("error");
                        setResult(Object.keys(error).length == 0 ? "" :error);
                        setOpen(true);
                    }
                });
        };
        fetchData();
    }

    const onChange = async (e) => {
        const { value } = e.target;
 
         let newSearchs = vnt1;
         uploadInfo.vnt1 = value;
 
         setData({ ...newSearchs });
    }

    const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop: onDrop,
        onDropRejected: onDropRejected,
        accept: accp ? accp : ["image/*", "text/plain", ".pdf", ".hwp", ".csv", ".pptx", ".xlsx"],
        maxFiles: maxFiles ? maxFiles : 10,
        maxSize: 500000000, // 5mb
        validator: validatorName && validatorName === "csv" ? csvFileValidator : null,
    });
    // 업로드 가능한 파일
    const acceptedFileItems = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));
    // //업로드 실패한 파일 - validatiton  및 설정위반 되는 파일들
    // const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    //     <li key={file.path}>
    //         {file.path} - {file.size} bytes
    //         <ul>
    //             {errors.map((e) => (
    //                 <li key={e.code}>{e.message}</li>
    //             ))}
    //         </ul>
    //     </li>
    // ));
   
    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    return (
        <>
            <section className="container">                
                {data?
                <div style={{width:"100%",height:"35px"}}>
                    <div style={{float: "left",marginRight:"10px",paddingTop:"4.5px"}}> T1 업체</div>
                    <select name="vnt1" value={uploadInfo.vnt1} onChange={onChange} style={{width:"55%",height:"26px",border:"1px solid #b8b8b8",padding:"0px 5px 0px 5px"}}>
                                    {data.map(element =>( <option key={element.comCode} value={element.comCode}>
                                                                {element.comCode} {element.text} 
                                                            </option>
                                                                        ))}
                    </select>
                </div> 
                :""}
                <Loading loading={loading} type={"bubbles"} />
                <div {...getRootProps({ style })}>    
                    <input {...getInputProps()} />
                    <p>파일을 드래그 해주세요!, 여기를 클릭해서 선택하셔도 됩니다</p>
                    <br/>
                    <p>                        
                        { fileId === "" ? "" : `파일명은 ${fileId}로 시작하는 ${filePath.substring(filePath.length - 4)}확장자인 파일을 선택해주세요`}<br/>파일 {maxFiles}개 선택 가능.<br/> {filePath === "" ? "" : `파일경로는 ${filePath.substring(0, 11)}`}
                    </p>            
                </div>
                <aside style={{ marginTop: "10px" }}>
                    {acceptedFileItems.length > 0 && fileId !== "VMI" ? (
                        <>
                            <h4>전송 파일 </h4>
                            <ul>{acceptedFileItems}</ul>
                        </>
                    ) :acceptedFileItems.length > 0 && fileId === "VMI" ? (
                        <>
                            <h4>전송 파일 </h4>
                            <ul style = {{marginBottom:"7px"}}>{acceptedFileItems}</ul>
                            <h5 style ={{float:"left", marginRight:"10px",marginBottom:"4px",fontSize: "13px"}}>대상건수 : </h5><ul style = {{marginBottom:"4px",fontSize: "13px"}}>{count}</ul>
                            <h5 style ={{float:"left", marginRight:"10px",marginBottom:"4px",fontSize: "13px"}}>성공건수 : </h5><ul style = {{marginBottom:"4px",fontSize: "13px"}}>{acount}</ul>
                            <h5 style ={{float:"left", marginRight:"10px",marginBottom:"4px",fontSize: "13px"}}>실패건수 : </h5><ul style = {{marginBottom:"4px",fontSize: "13px"}}>{scount}</ul>
                        </>
                    )
                    :(
                        ""
                    )}

                    {/* {fileRejectionItems.length > 0 ? (
                        <>
                            <h4>전송불가 파일</h4>
                            <ul>{fileRejectionItems}</ul>
                        </>
                    ) : (
                        ""
                    )} */}
                </aside>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <SnackAlert onClose={handleClose} severity={severity}>
                         {result} 
                    </SnackAlert>
                </Snackbar>
            </section>
        </>
    );
}
export default CustomDropzone;
