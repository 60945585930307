/**
 * @Page REPPA
 * @Description [REPPA]수신자료관리 - 생산정보조회 - Body_Seq_219
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
 import UploadModal from "@/components/organisms/UploadModal";
 import Loading from "src/components/atoms/CustomLoading2";
 
 
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
 const Utils = new CommonUtil();
 
 function REPPA() {
     const token = Common.getToken();
     const userId = Common.getToken("id");
     const tVnt1 = Common.getToken("vand");
     const tComp = Common.getToken("comp");
     const grdReppaList = useRef();   //Grid1

     const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 
     let [loading, setLoading] = useState(false);
 
     //Data
     const [idata, setData] = useState({
        //조회조건
        kVnt1 : ckVnt1,        //T1업체     
        kComp: tComp,       //회사구분
        kWrcVand: "****",
        kGubn : "*",    //그룹코드
        kTelno: "",         //연락처
        kCano: "*",         //차종
        kVnt1Nm : "",        //T1업체명
        kCompNm : tComp==="EHMC"?"현대자동차(주)":"기아자동차(주)",        //회사명

        //코드 데이터
        vnt1List : [],  //T1업체
        docdList : [],  //선택구분
        canoList : [],  //차종
        hList: [""],
        headerList : [{ "comCode" : "", "text": "" },{ "comCode" : "", "text": "" },{ "comCode" : "", "text": "" },{ "comCode" : "", "text": "" }],  //headerList
     }); 
 
 
      
     const [message, setMessage] = React.useState( {
         openAlert : false,      
         severity : "success",   
         msg : ""                
     });
     const { openAlert, severity, msg } = message;
     const [uploadInfo, setUploadInfo] = useState({ vnt1: tVnt1, subDir: "DATA" });
     const [panded, setPanded] = React.useState(true);
     const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 50px)"});
     //Dialog
     const [dlgMessage, setDlgMessage] = React.useState( {
         openDlg : false,    
         dlgTrans : "",      
         dlgMsg : ""         
     });
     const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
 
 
     //Grid Search, Insert, Update, Delete Api
     let [searchApi, setSearchApi] = useState(false); 
     
     const [openUpload, setOpenUpload] = React.useState(false); // Modal 항목 setting
     const onClearApi = useCallback(async (e) => {
         setSearchApi(false);
     }, []);

 
     /**
      * 공통코드 조회
      */
      const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data;
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }
 
 /**
     * 회사구분
     * @param {*} para 
     * @param {*} name 
     */
  const onSearchCommon1 = (para, name) => {
    let commonData = idata;

    const fetchData1 = async (para, name) => {

        let param = encodeURI(para);
        const query = SERVER_URL + "/comcd/search1?query=" + param; 
        await fetch(query, { headers: { Authorization: token } })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.success) 
                {
                    commonData[name] = data.data;
                    setData({...commonData});
                }
            })
    }
    fetchData1(para, name);
}
 
 /**
     * 동적 헤더용 구분 2
     * @param {*} para 
     * @param {*} name 
     */
      const onSearchCommon2 = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data;
                        let arr = [];
                        data.data.map((option, index) => {
                            arr.push(option.comCode);
                        });
                        commonData["hList"] = arr;
                        setData({...commonData});
                    }
                    
                    // clear 
                    if(data.data.length === 0){
                        commonData[name] = [{ "comCode" : "", "text": "" },{ "comCode" : "", "text": "" },{ "comCode" : "", "text": "" },{ "comCode" : "", "text": "" }];                        
                        commonData["hList"] = [""];
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }
    /**
        * 공통코드 조회 (조회조건)
        */
    const onSearchCommon3 = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                    commonData[name] = data.data[0].text;
                    setData({...commonData});
                    }
                })
        }
        fetchData(para, name);   
    }
 
     /**
      *MOUNT 
      */
     useEffect( () => {
         
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
        //공통코드조회(차종)
        onSearchCommon1(`{"pgmid":"WRCCARC", "vand":"****", "kComp":"${idata.kComp}"}`, "canoList");
        //공통코드 조회(문서코드)
        onSearchCommon1(`{"pgmid":"DOC_ERPPA","Key1":"3", "kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "docdList");
        //공통코드 조회 (회사명)
        onSearchCommon3(`{"pgmid":"WRCVAND","kVnt1":"${idata.kVnt1}"}`, "kVnt1Nm");


        if(idata.canoList.length > 0){
            
            let commonData = idata;
            // if(idata.kCano === '*' || idata.kCano === '' || idata.kCano === undefined){
            if(idata.kCano === '*' || idata.kCano === undefined){
                commonData["kCano"] = idata.canoList[0].comCode;
                // commonData["kCanoNm"] = data.data[0].text;
                setData({...commonData});
            };
        }

        setTimeout(() => 
            onSearchCommon2(`{"pgmid":"body_Search","kComp":"${idata.kComp}","kVnt1":"${idata.kVnt1}","kCarc":"${idata.kCano}"}`, "headerList")
        , 1000);

     }, []);
 
 
 
     /**
      * Control 변경 Event
      * @param {*} e 
      */
     const onChange = async (e) => {
         const { name, value, desc } = e.target;
 
         let newSearchs = idata;
         let newUploadInfo = uploadInfo;
         newSearchs[name] = value
 
         setData({ ...newSearchs });
 
         
 
        //T1업체, 회사구분 변경시 
        if (name === "kVnt1")  {
            newSearchs["kDocd"] = "";
            newUploadInfo["vnt1"] = value;
            newSearchs[name+"Nm"] = desc;

            setData({ ...newSearchs });
            setUploadInfo({ ...newUploadInfo });

            onSearchCommon1(`{"pgmid":"DOC_ERPPA","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "docdList");

        }
        if(name === "kComp"){
            newSearchs["kDocd"] = "";
            newSearchs[name+"Nm"] = desc;
            setData({ ...newSearchs });
            onSearchCommon1(`{"pgmid":"DOC_ERPPA","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}"}`, "docdList");
        }
         //조회 Call    
         //onSearch(); 
     }
 
 
 
     /**
      * Grid Event 
      * @param {*} e 
      */
     const onCellValueChanged = async (e) => {
     }
 
     const onRowClicked = async (e) => {
     }
 
 
 
     /**
      * 검색조건
      */
      const optionsX = {
         0: [
             //T1업체
           {
            "formatter": "select", "name": "kVnt1", "labelName": "1차업체", "type": "text", "defaultValue": idata.kVnt1,  
            "codeMode": true,
            "selectAll": "",
            "codes": null,
            "comms": idata.vnt1List,
            "align": "left", 
            },  
             //회사구분,
           {
               "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
               "codeMode": false,
               "selectAll": "",
               "codes": CODES["comp"],// Case 1 - codes :: Code List
               "comms": null,
               "align": "left", 
           }, 
           //문서코드
           {
               "formatter": "select", "name": "kDocd", "labelName": "문서코드", "type": "text", "defaultValue": idata.kDocd,  
               "codeMode": false,
               "selectAll": "",
               "codes": null,
               "comms": idata.docdList,
               "align": "right", 
           }, 
            //차종
           {
                 "formatter": "select", "name": "kCano", "labelName": "차종", "type": "text", "defaultValue": idata.kCano,  
                 "codeMode": false,
                 "selectAll": false,
                 "comms": idata.canoList,
            }, 
            //전체건수
            {
                "formatter": "input", "name": "kTotcnt", "labelName": "전체건수", "type": "text", "defaultValue": idata.kTotcnt, "readOnly" : true,
                "codeMode": false,
                "selectAll": "none",
                "codes": null,
                "comms": "",
                "align": "right", 
            }, 
             {"formatter": "blank", "align": "left" }
         ]
     }
 
 
     //조회건수
     const onSetCount = () => {
        //전체건수 Setting
        let newSearchs = idata; 
        newSearchs["kTotcnt"] = (grdReppaList.current.getRowCount()).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        setData({ ...newSearchs });
    }
 
     /**
      * 조회 
      * @param {*} e 
      * @returns 
      */
     const onSearch = async (e) => {
        //공통코드 조회 (그리드 헤더)
        onSearchCommon2(`{"pgmid":"body_Search","kComp":"${idata.kComp}","kVnt1":"${idata.kVnt1}","kCarc":"${idata.kCano}"}`, "headerList");

        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);

        let para = `{"kComp":"${idata.kComp}","kCano":"${idata.kCano}","kGubn":"${idata.kGubn}","kVnt1":"${idata.kVnt1}", "hList":"${idata.hList}"}`;
        let param = encodeURI(para);
 
        const query = SERVER_URL + "/reppa/search?query=" + param;
                
        setTimeout(() => 
            setSearchApi(query)
        , 1000);
    }
   
   
     /**
     * 파일생성
     * @param {*} e 
     */
    const onUpload = async (e) => {
        let newSearchs = idata; 

        newSearchs["vnt1"] = idata.kWrcVand;
        setData({ ...newSearchs });
        setOpenUpload(true);
   }
   
   const handleUploadClickOpen = () => {
       
       setOpenUpload(true);
   };

   const handleUploadClose = () => {
       setOpenUpload(false);
       //onSearchDocd();
   };
  
 
 
     /**
      * 신규 Row추가
      * @param {*} e 
      */
     const onAddRow = async (e) => {
     }
 
 
     
     /**
      * 입력
      * @param {*} e 
      */
     const onInsert2 = async (e) => {
 
   
     }
 
 
 
     /**
      * 수정 
      * @param {*} e 
      * @returns 
      */
     const onUpdate2 = async (e) => {
 
   
     }
 
 
 
     /**
      * 선택/취소   삭제
      * @param {*} e 
      */
     const onDelete = async (e) => {
   

     }

     /**
      * 엑셀
      * @param {*} e 
      */

     const onExcel = async (e) => {
        if(idata.kDocd === undefined){
            idata.kDocd = idata.docdList[0].comCode;
        }
        if(idata.kCano === ''||idata.kCano === '*'){
            idata.kCano=idata.canoList[0].comCode;
        }
        const page = "ERPPA"
        let para = `{"kDocument":"BODY SEQUENCE(219)", "kGubn":"${page}","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kDocd":"${idata.kDocd}","kCano":"${idata.kCano}","kTotcnt": "${idata.kTotcnt}"
        ,"vnt1Nm":"${idata.kVnt1Nm}","compNm":"${idata.kCompNm}","docdNm":"${idata.kDocd===""?"전체":idata.kDocd}"}`;
        let param = encodeURIComponent(para);
        const query = SERVER_URL + "/repp/excel?query=" + param;
        //엑셀파일 조회 시 반응
        setLoading(true);
        await fetch(query, {headers: {Authorization: token } })
            .then((resp) =>{
                return resp.blob();
            }).then((blob)=>{
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'REPPA.xls');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                return Promise.reject({ Error: 'error', err });
            })
        setLoading(false);
    
        // 엑셀파일 생성 메세지
        setMessage({ openAlert : true, 
                     severity : "success",
                     msg : "엑셀파일이 생성되었습니다."});
    }

 
 
     // input check
     const inputCheck = (gbn) => { 
 
         let inputFlag = true;
         //select nodes
         let selectedData = grdReppaList.current.getSelectedNodes();
 
         // 선택 값 체크
         if (selectedData.length === 0) {
             //Alert 창 OPEN
             setMessage({ openAlert : true, 
                          severity : "info",
                          msg : "선택한 데이터가 없습니다."});
             return false;
         }
 
        
         return inputFlag;
     }

     //그리드 Row Click
     const grdReppaList_onRowClicked = async (e) => {

        let comp = e.data.comp;
        let car1 = e.data.car1;
        let optn = e.data.optn;

     //   onSearch2(comp,car1,optn);
    }
 
 
     //Transaction AlertDialogResult
     const AlertDialogResult = async (e) => {
 
         //AlertDialog 초기화
         setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
 
         //DialogResult
         if (e.result == "N") return;
 
         //Transaction 구분
         switch (e.Trans) 
         {
             //등록
             case "I" :
                 break;
             //수정
             case "U" :
                 break;
             //삭제
             case "D" :
                 break;
         }
     }

     const grdReppaDetail_onCellValueChanged = async (e) => {

        let data = e.data;
        if (data.comp === " " || data.comp === "") {
            e.node.setSelected(false);
        }
    }
     
     
 
 
     /**
      *Grid Column 정의 
      */
    //  const columnDefs = [
       
        
    //  ];
     const columnDefs = [
        { headerName: "공장", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 100, cellClass: ["nocheckbox", "alignC"], pinned: 'left', singleClickEdit: true, hide: false }, 
        { headerName: "Line_No", field: "lino", sortable: true, filter: true, flex: 1, minWidth: 100, cellClass: ["nocheckbox", "alignC"], pinned: 'left', singleClickEdit: true, hide: false }, 
        { headerName: "차종", field: "carc", sortable: true, filter: true, flex: 1, minWidth: 100, cellClass: ["nocheckbox", "alignC"], pinned: 'left', singleClickEdit: true, hide: false }, 
        { headerName: "Seq-Date", field: "sqdt", sortable: true, filter: true, flex: 1, minWidth: 140, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"],valueFormatter: Utils.dateFormatter, hide: false  },
        { headerName: "Seri", field: "seri", sortable: true, filter: true, flex: 1, minWidth: 100, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], hide: false  },
        { headerName: "VIN", field: "vinj", sortable: true, filter: true, flex: 1, minWidth: 250, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], hide: false  },
        { headerName: "SPEC", field: "spac", sortable: true, filter: true, flex: 1, minWidth: 150, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], hide: false  },
        { headerName: "OCN", field: "ocn", sortable: true, filter: true, flex: 1, minWidth: 100, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], hide: false  },
        { headerName: "Ver", field: "ver", sortable: true, filter: true, flex: 1, minWidth: 100, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], hide: false  },
        { headerName: "Order-Number", field: "odno", sortable: true, filter: true, flex: 1, minWidth: 180, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], hide: false  },
        { headerName: "외색", field: "oclr", sortable: true, filter: true, flex: 1, minWidth: 80, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], hide: false  },
        { headerName: "내색", field: "iclr", sortable: true, filter: true, flex: 1, minWidth: 80, editable: true, headerClass: "", cellClass: ["nocheckbox", "alignC"], hide: false  },
    ].concat(
        // 월별이면 12컬럼, 아니면 31컬럼으로
        Array((idata.headerList).length).fill(0).map((_, i) => {
            return {
                // 수량 혹은 금액 구분. 수량+금액은 엑셀만 출력하므로 제외
                headerName: idata.headerList[i].comCode, field:"opt"+(i), sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false
            }
        })
    );

    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 55px  - 50px)"
              });
        }
    }
    


     return(
         <>
             {/* Dialog FRAME */}
             <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />
 
             {/* Alert FRAME */}
             <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} />
 
             {/* Upload FRAME */}
             <UploadModal
                open={openUpload}
                onClose={handleUploadClose} 
                onClickOpen={handleUploadClickOpen}
                title={"CSV 업로드"}
                fileId={"ERPPA"}
                filePath={"C:\\HKC\\VAN\\ERPPA.CSV"}
                uploadInfo={uploadInfo}
                accp=".csv"
                maxFiles={1}
                validatorName={"csv"}
                uploadApi={SERVER_URL + "/erp/upload1"}
                data={idata.vnt1List}
            />
             {/* Loading */}
             <Loading loading={loading} /> 

             {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
             <Ribbon
                onSearch={()=>{onSearch()}} onSearchName={null} //조회
                onUpload={onUpload} onUploadName={"생성"}//생성   
                onExcel={onExcel} onExcelName ={null}   //Excel
             >
                 {"수신자료관리 > 생산정보조회 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>BODY SEQUENCE 219 [ PA ]</b>
             </Ribbon>
 
 
             {/* ContentXearch Frame : 검색조건 */}
             <ContentXearch
                 optionsX={optionsX}
                 onChange={onChange}
                 onClick={onClick}/>
 
 
             {/* Content FRAME (Data / Grid Frame) START -------------- */}
             <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:55px */}
             <div className="grid_type02">{/* 하단 FRAME */}
                    <div className="grid_wrap02" style={{width: "100%"}}> {/* 하단 wrap 좌측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}

                            <div className="grid_cont_box" style={{ height: "calc(100%)"}}>

                                {/* Grid1 */}
                                <Grid
                                    ref={grdReppaList}
                                    gridId={"REPPA"}                   //  (필수) Grid Id 정의
                                    columnDefs={columnDefs}            //  (필수) 컬럼 정의 
                                    pagination={true}                  //  쪽수 매기기 여부
                                    paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={false}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={false}      // cell selection drag mode
                                    //isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    // onClearApi={onClearApi}            // 초기화  
                                    onClearApi={()=> {onSetCount();onClearApi()}}
                                    searchApi={searchApi}              // 조회
                                    //deleteApi={deleteApi}              // 삭제
                                    //deleteRefresh={()=>{onSearch();}}    // 삭제이후 조회
                                    onRowClickEvent={null}
                                    //onCellValueChangedEvent={grdREPPAList_onCellValueChanged}
                                    // onCellValueChangedEvent={onCellValueChanged}  
                                />
                            </div>
                        </div>
                    </div>

                   
                </div>
             </Content>
         </>
     )
 }
 export default REPPA;