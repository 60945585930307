import React from 'react';

import '../../css/main.css';

import {Checkbox, FormControlLabel, FormGroup} from '@material-ui/core';


function CustomCheckbox3({
    id, name, labelName, defaultValue, options,
    codes, comms, items,
    codeMode, selectAll, onChange, 
    paddingLeft, alignType, labelType }) {

        if(id === "kCarc"){
            paddingLeft = "40px";
        }

    const handleChange = (e) => { 
        const { name, checked } = e.target;
        let event = {
            target: {}
        };
        event.target["name"] = name;
        if (checked) {
            event.target["value"] = "Y"; 
        } else { event.target["value"] = "N"; }

        if (onChange) {
            onChange(event);
        }
    };

    return (
        <>
            <div className={"h_Label1"+(alignType?alignType:'')+" checks" + (labelType ? " h_Label1"+(alignType?alignType:'')+"_" + labelType : "")} style={{ whiteSpace:"pre", paddingTop: "9px", paddingLeft:paddingLeft,width: "230px"}}>

                {options ?
                    options.map((option, i) =>
                        <React.Fragment key={"checkbox" + option.name + option.defaultValue + i}>

                            <div style={{float:"left"}}>
                            <FormGroup>
                                <FormControlLabel  control=
                                    {<Checkbox 
                                        id={option.name}
                                        checked={option.defaultValue === "N" ? false  : true}
                                        name={option.name}
                                        onChange={handleChange} />} label={option.labelName}       
                                />
                            </FormGroup>
                            </div>
                        </React.Fragment>
                    )
                    : ""}
            </div>
        </>
    );
}

export default CustomCheckbox3;