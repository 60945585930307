import * as JWT from 'jwt-decode';

/* Common.js */
/* 콘솔로그 표출 제거 - 개발 운영 배포시 추가필요 */
console.log = function no_console() {};
console.warn = function no_console() {};


/* Common.js - 공통 Function  */
export function JSONtoString(search) {
    var results = [];
    var object = search;
    for (var property in object) {
        var value = object[property];
        if (value) {
            results.push(`"${property.toString()}":"${value.toString().trim()}"`);
        }
    }
    return '{' + results.join(',') + '}';
}

// jwtToken authority getToken(gubn)
let t;
//const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null;
let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null;
if (token) {
    t = JWT(token);
}

//월별 일 가져오기
export function getMonthDays(stdt){
	
	let year = parseInt(stdt.substring(0,4));
	let month = parseInt(stdt.substring(4,6));
	
	return getMonthDay(year, month);
	
}

export function getMonthDay(year1, month1){
		
	let year = year1;
	let month = month1-1;
    let days = new Array(12);
	 days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

	if ( ((year % 4 == 0) && !(year % 100 == 0))|| (year % 400 == 0) )
		days[1] = 29;
	return days[month];
}

export function getLpad(src, mark, max){
		
    let temp = "";
    if (src === null)
        src = "";
    const len = src.length;
    for (let i = len; i < max; i++) {
        temp += mark;
    }

    temp += src;
    return temp;
    
}

export function getCommon() {
    token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null;
    if (token) {
        t = JWT(token);
    }
} 


export function getNumberFormatter(number) {

    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

}

export function getTokenAll() {
    getCommon();

    let tokenValue = "";
    if (t) {
        tokenValue = t;
    } else {
        tokenValue = "";
    }
    return tokenValue;
}
export function getToken(gubn) {
    getCommon();

    let tokenValue = "";
    if (t) {
        if (gubn) {
            tokenValue = t[gubn] ? t[gubn] : "";
        } else {
            tokenValue = token;
        }
    } else {
        tokenValue = "";
    }
    return tokenValue;
}
/*
 * dt   : 기준일자
 * date : 증,감소 값
 * gubn : "m"=월단위/"y"=년단위/나머지는 일단위
 * dateCa("2009-10-28",10);
 * 결과   : 2009-11-07
 * dateCa("2009-10-28",10,"m");
 * 결과   : 2010-08-28
 * dateCa("2009-10-28",10,"y");
 * 결과   : 2019-10-28
 */
export function getDateCa(dt,date,gubn) {
    var ye = dt.substr(0,4);
	var mo = dt.substr(5,2);
	if(dt.substr(5,2) < 10) {
		mo = parseInt(dt.substr(6,1));
	} else {
		mo = parseInt(dt.substr(5,2));
	}
	
	var da = dt.substr(8,2);
	var mi = date<0?-1:0;
	var resultDt = 0;
	if(gubn==null){
		  resultDt = date;
	}else if(gubn.toUpperCase()=="M"){//월단위 계산
		var moSt = mo;
		for(var i=mo;i<mo+date;i++){
		   if(moSt>12){
			   moSt = 1;
		   }
		   
		   if (moSt+mi==4 || moSt+mi==6 || moSt+mi==9 || moSt+mi==11){
			   resultDt += 30;
		   }else if(moSt+mi==2){
			   if(((ye%4)==0 && (ye%100) != 0  || (ye%400)==0)){
				   resultDt += 29;
			   }else{
				   resultDt += 28;
			   }
		   }else{
			   resultDt += 31;
		   }
		   moSt++;
		}
	} else if(gubn.toUpperCase()=="Y"){//년단위 계산
		for(var i= ye; i<ye; i++){
			if(((ye%4)==0 && (ye%100) != 0  || (ye%400)==0)){
				resultDt += 366;
			}else{
				resultDt += 365;
			}
		}
	}else{
		resultDt = date;
	}
	var d = new Date(ye,mo-1,da);
	d = new Date(Date.parse(d) + resultDt * 1000 * 60 * 60 * 24);
	ye = d.getFullYear();
	mo = d.getMonth()+1;
	da = d.getDate();
	
	return (ye + '-' + (mo<10?"0"+mo:mo) + '-' +  (da<10?"0"+da:da));
}

export function getToday() {
    var today = new Date();
    var year = today.getFullYear();
    var month = ("0" + (1 + today.getMonth())).slice(-2);
    var day = ("0" + today.getDate()).slice(-2);

    return year + "-" + month + "-" + day;
}
export function getTime() {
    var today = new Date();
    var hours = ("0" + today.getHours()).slice(-2);
    var minutes = ("0" + today.getMinutes()).slice(-2);
    // var seconds = today.getSeconds();  // 초
    // var milliseconds = today.getMilliseconds(); // 밀리초

    return hours + ':' + minutes;
    // return hours + ':' + minutes + ':' + seconds + ':' + milliseconds;
}
export function getHour() {
    var today = new Date();
    var hours = ("0" + today.getHours()).slice(-2);
    // var minutes = ("0" + today.getMinutes()).slice(-2);
    // var seconds = today.getSeconds();  // 초
    // var milliseconds = today.getMilliseconds(); // 밀리초

    return hours;
    // return hours + ':' + minutes + ':' + seconds + ':' + milliseconds;
}
export function getTodayTime() {
    var today = new Date();
    var year = today.getFullYear();
    var month = ("0" + (1 + today.getMonth())).slice(-2);
    var day = ("0" + today.getDate()).slice(-2);

    var hours = ("0" + today.getHours()).slice(-2);
    var minutes = ("0" + today.getMinutes()).slice(-2);
    // var seconds = today.getSeconds();  // 초
    // var milliseconds = today.getMilliseconds(); // 밀리초

    return year + "-" + month + "-" + day + "T" + hours + ':' + minutes;
    // return hours + ':' + minutes + ':' + seconds + ':' + milliseconds;
}


export function changeEDTM(startDate, startHour) {
    // 시작 시간 변경시 종료일자 및 시간 변경 Event
    // var comp = kComp;
    // var plnt = kPlnt;
    // var plnt2 = kPlnt2;
    var sttm = startDate;
    var sthh = startHour;
    var yyyy = parseFloat(sttm.substring(0, 4));
    var m = parseFloat(sttm.substring(5, 7));
    var d = parseFloat(sttm.substring(8, 10));
    var hh = parseFloat(sthh);

    let end = new Array(12);
    end = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if ((yyyy % 4 === 0 && yyyy % 100 !== 0) || yyyy % 400 === 0) {
        end[1] = 29;
    }
    // for (i = 0; i < form.plnt2.options.length; i++) {
    //     if (comp + "|" + plnt == form.plnt2.options(i).value) {
    //         hh = hh + parseFloat(form.plnt2.options(i).text);
    //         break;
    //     }
    // }
    if (hh === parseFloat(sthh)) hh++;
    if (hh > 23) {   //24시 이상이면 하루 늘리기
        hh = hh - 24;
        d++;
    }
    if (d > end[m - 1]) {
        d = 1;
        m++;
    }
    if (m > 12) {
        m = 1;
        yyyy++;
    }
    let endDate = yyyy + "-" + (m < 10 ? ("0" + m) : m) + "-" + (d < 10 ? ("0" + d) : d);
    let endHour = (hh < 10 ? ("0" + hh) : hh);
    return { endDate, endHour };
}


export function setCookie( name, value, expiredays ) { 
    var todayDate = new Date();
    todayDate.setDate( todayDate.getDate() + expiredays ); 
    //return document.cookie = name + "=" + escape( value ) + "; path=/; expires=" + todayDate.toGMTString() + ";" 
    return document.cookie = name + "=" + encodeURI(value) + "; path=/; expires=" + todayDate.toGMTString() + ";" 
}

export function getCookie(name)
{
// var arg = name + "=";
// var alen = arg.length;
// var clen = document.cookie.length;
// var i = 0;
// 	while(i < clen)
// 	{
// 		var j = i + alen;
// 		if(document.cookie.substring(i, j) == arg)
// 		{
// 			var end = document.cookie.indexOf(";", j);
// 			if(end == -1) end = document.cookie.length;
// 			return unescape(document.cookie.substring(j, end));
// 		}
// 		i = document.cookie.indexOf(" ", i) + 1;
// 		if(i == 0) break;
// 	}

// 	return null;
    let cookieKey = name + "="; 
    let result = "";
    const cookieArr = document.cookie.split(";");

    for(let i = 0; i < cookieArr.length; i++) {
    if(cookieArr[i][0] === " ") {
        cookieArr[i] = cookieArr[i].substring(1);
    }
    
    if(cookieArr[i].indexOf(cookieKey) === 0) {
        result = cookieArr[i].slice(cookieKey.length, cookieArr[i].length);
        //return result;
        return decodeURI(result);
    }
    }
    return result;
}
    
export function getTextLength( str ) { 
    var len = 0;

    if(str===undefined) return 0;
    for (var i = 0; i < str.length; i++) {
        if (escape(str.charAt(i)).length === 6) {
            len++;
        }
        len++;
    }
    return len;
}

export function getLOT( lot ) { 
    var TMP1, TMP2, TMP3 = 0;
    var rtmp1, rtmp2, rtmp3 = 0;
    
    TMP1 = parseInt( lot.substr(2, 2), 10);
    TMP2 = parseInt( lot.substr(5, 2), 10);
    TMP3 = parseInt( lot.substr(8, 2), 10);

    // console.log("TMP1 :: "+ TMP1 + ", TMP2 :: "+ TMP2 + ", TMP3 :: "+ TMP3)
    
         switch (TMP1) {
            case 1 :
                rtmp1 = "W"; break;
            case 2 :
                rtmp1 = "X"; break;
            case 3 :
                rtmp1 = "Y"; break;
            case 4 :
                rtmp1 = "Z"; break;
            case 5 :
                rtmp1 = "A"; break;
            case 6 :
                rtmp1 = "B"; break;
            case 7 :
                rtmp1 = "C"; break;
            case 8 :
                rtmp1 = "D"; break;
            case 9 :
                rtmp1 = "E"; break;
            case 10 :
                rtmp1 = "F"; break;
            default : 
                rtmp1 = String.fromCharCode(64 + TMP1 - 4); break; //11년에 G 부터 시작해야 함 -> G는 7 번째 글자 -> 11-7 = 4
         }

         switch (TMP2) {
            case 1 :
                rtmp2 = "A"; break;
            case 2 :
                rtmp2 = "B"; break;
            case 3 :
                rtmp2 = "C"; break;
            case 4 :
                rtmp2 = "D"; break;
            case 5 :
                rtmp2 = "E"; break;
            case 6 :
                rtmp2 = "F"; break;
            case 7 :
                rtmp2 = "G"; break;
            case 8 :
                rtmp2 = "H"; break;
            case 9 :
                rtmp2 = "I"; break;
            case 10 :
                rtmp2 = "J"; break;
            case 11 :
                rtmp2 = "K"; break;
            case 12 :
                rtmp2 = "L"; break;
         }

         switch (TMP3) {
            case 1 :
                rtmp3 = "A"; break;
            case 2 :
                rtmp3 = "B"; break;
            case 3 :
                rtmp3 = "C"; break;
            case 4 :
                rtmp3 = "D"; break;
            case 5 :
                rtmp3 = "E"; break;
            case 6 :
                rtmp3 = "F"; break;
            case 7 :
                rtmp3 = "G"; break;
            case 8 :
                rtmp3 = "H"; break;
            case 9 :
                rtmp3 = "I"; break;
            case 10 :
                rtmp3 = "J"; break;
            case 11 :
                rtmp3 = "K"; break;
            case 12 :
                rtmp3 = "L"; break;
            case 13 :
                rtmp3 = "M"; break;
            case 14 :
                rtmp3 = "N"; break;
            case 15 :
                rtmp3 = "O"; break;
            case 16 :
                rtmp3 = "P"; break;
            case 17 :
                rtmp3 = "Q"; break;
            case 18 :
                rtmp3 = "R"; break;
            case 19 :
                rtmp3 = "S"; break;
            case 20 :
                rtmp3 = "T"; break;
            case 21 :
                rtmp3 = "U"; break;
            case 22 :
                rtmp3 = "V"; break;
            case 23 :
                rtmp3 = "W"; break;
            case 24 :
                rtmp3 = "X"; break;
            case 25 :
                rtmp3 = "Y"; break;
            case 26 :
                rtmp3 = "Z"; break;
            case 27 :
                rtmp3 = "1"; break;
            case 28 :
                rtmp3 = "2"; break;
            case 29 :
                rtmp3 = "3"; break;
            case 30 :
                rtmp3 = "4"; break;
            case 31 :
                rtmp3 = "5"; break;
         }

    return rtmp1+""+rtmp2+""+rtmp3;
}

export function addDescAttribute(idata,para) {
    var json_para=JSON.parse(para);
    for(var key in idata) {
        var id= key.substring(1,2).toLowerCase()+ key.substring(2)+"Nm";
        json_para[id]=idata[key+"Nm"];
    }
    return JSONtoString(json_para);
}

//fetch API 커스텀 인터셉터 작성
const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
    let [resource, config] = args;
    //request interceptor
    let response = await originalFetch(resource, config);
  
    // response interceptor
    // ip중복로그인 처리
    if (response.status === 371 && response.ok===false && window.location.pathname!="/multiIp") {
        window.location.href="/multiIp";
        return Promise.reject(response);
    }

    // 유효하지 않은 인증
    if (response.status === 372 && response.ok===false && window.location.pathname!="/unauthorized" && window.location.pathname!="/login") {
        window.location.href="/unauthorized";
        return Promise.reject(response);
    }

    //404처리
    if (response.status === 404) {
        window.location.href = "/NotFoundPage";
        return Promise.reject(response);
    }

    const json = () =>
      response
        .clone()
        .json()
        .then((data) => ({ ...data, title: `Intercepted: ${data.title}` }));
  
    response.json = json;
    return response;
  };