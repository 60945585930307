//수신자료관리 - CLAIM정보자료조회
import { Route, Switch } from 'react-router-dom';
import RNTW0 from '@/pages/B05/RNTW0';  //클래임

function B05() {
    return ( 
        <Switch>
            <Route path='/B05/RNTW0' component={RNTW0} />
        </Switch>
    );
}

export default B05;
