import React, { Component,useState,useRef } from 'react';
// import { Modal, Box } from "@material-ui/core";
import Ribbon from '@/components/organisms/Ribbon';
// import ContentXearch from '@/components/organisms/ContentXearch';
// import Content from '@/components/organisms/Content';
// import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 

import Popup from '@/pages/Popup/Popup';     

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';

import { Search, Today } from '@material-ui/icons';
import UploadModal from '@/components/organisms/UploadModal';
import CustomInputDateCheck from '@/components/atoms/CustomInputDateCheck';
import { TextField } from '@material-ui/core';
import Loading from "@/components/atoms/CustomLoading";
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import { data } from 'jquery';
import { useEffect } from 'react';

 
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 

class PopupMngDetail extends Component {

    constructor(props)  {
        super(props);
        //this.grdCarTypeMain = React.createRef();
        this.state = {
            //Common
            token:Common.getToken(),
            userName : Common.getToken("name"),
            tVnt1 : Common.getToken("vand"),
            readOnly: true,
            mode : "",
            vand : props.data.vand, 
            idat:props.data.idat, 
            wrctitl:props.data.wrctitl,            
            wrcseqn: props.data.wrcseqn == undefined || props.data.wrcseqn == "" || props.data.wrcseqn == null  ? "0" : props.data.wrcseqn,
            wrcfile : props.data.fileId == undefined || props.data.fileId == "" || props.data.fileId == null  ? "" : props.data.fileId, // fileId 와 wrcfile이 각각 크로스됨. 수정 필요...
            fileId : props.data.wrcfile == undefined || props.data.wrcfile == "" || props.data.wrcfile == null  ? "" : props.data.wrcfile,
            wrclink : props.data.wrclink == undefined ? "" : props.data.wrclink,
            // wrcfrom : props.data.wrcfrom ,
            wrcfrom : props.data.wrcfrom == undefined ? "" : props.data.wrcfrom.substring(0,4) + "-" + props.data.wrcfrom.substring(4,6) + "-" + props.data.wrcfrom.substring(6,8) + "T" + props.data.wrcfrom.substring(8,10) + ":00",            
            wrcto : props.data.wrcto == undefined ? "" : props.data.wrcto.substring(0,4) + "-" + props.data.wrcto.substring(4,6) + "-" + props.data.wrcto.substring(6,8) + "T" + props.data.wrcto.substring(8,10) + ":00",            
            fileName : props.data.fileName,
            wrcidat : props.data.wrcidat,
            useYn : props.data.useYn == undefined ? "Y" : props.data.useYn,
            wrccont:props.data.wrccont,
            wrctype:props.data.type,
            open:props.data.typeOpen,
            previewYN:"Y", // 팝업 미리보기에서 열 경우 :Y, 
            setOpen:props.data.setTypeOpen,
            updateCheck:props.data.updateCheck,
            wrclocation:props.data.wrclocation == undefined ? "M" : props.data.wrclocation,
            wrctype:props.data.wrctype == undefined ? "I" : props.data.wrctype,
            wrcperiod:props.data.wrcperiod == undefined ? "D" : props.data.wrcperiod,
            wrccontsize:props.data.wrccontsize == undefined ? "28" : props.data.wrccontsize,

            insertCheck:props.data.chk, // 신규 입력 / 수정 구분( "0" : 신규입력)
            
            selectedFile:null,

            //Alert 창 ( Alert 창 OPEN,  Alert 창 구분, Alert 창 메세지)
            openAlert : false, severity : "success", msg : "",
    
            //Dialog 창 (Dialog 창 OPEN, Dialog Transaction(I,U,D),  Dialog 메세지)
            openDlg : false, dlgTrans : "", dlgMsg : "", 
    
            //Api (조회, 등록, 수정, 삭제)
            searchApi : false, insertApi : false, updateApi : false, deleteApi : false,

            loadingFlag : false,

            data : [], setDatas : [],
            typeOpen : false,setTypeOpen : false, setModalOpen : false,
            loading : false,
            imgBase64:null,
            upload_file:null,         
            closeSeq :[]
        }
        this.fileRef=React.createRef();
    }
   

    handleFileInput(e) {                 
        for (var i = 0; i < e.target.files.length; i++) {
            if (e.target.files[i]) {
                let reader = new FileReader();
                reader.readAsDataURL(e.target.files[i]); // 1. 파일을 읽어 버퍼에 저장합니다.

                // 파일 상태 업데이트
                reader.onloadend = () => {
                    // 2. 읽기가 완료되면 아래코드가 실행됩니다.
                    const base64 = reader.result;
                    var previewImg = document.getElementById('previewImg');
                    previewImg.src = base64; //previewImg : 미리보기                   
                    if (base64) {
                        //  images.push(base64.toString())
                        var base64Sub = base64.toString()
                        var tmp=[...base64Sub];
                    }
                }
            }      
        }
        this.setState({
            selectedFile: e.target.files[0],
            fileName: e.target.files[0].name // 파일 업로드시 파일명 가져오기                                
        })        
    }

    /**
     * WillMount
     */
    async componentWillMount() {
        //Loading Flag
        this.setState({loadingFlag : true}); 
    }

    /**
     * DidMount (바로조회)
     */
    componentDidMount() {       
    }

    handleFileClick = async (e) => {
        e.preventDefault();
        this.fileRef.current.click();
    }

    /**
     * 공통코드 조회
    */
     onSearchCommon = () => {
      
    }
    
    

    render() {
        
        if (this.state.loadingFlag === false) {
            return null
        }
        else {
            //State
            const {token,  tVnt1,  kDelGubn, mode,uid,wrcseqn,
                   openAlert, severity, msg,idat,wrctitl,vdnm,vand,
                   fileId, wrclink, wrcfrom, wrcto, wrcidat, useYn ,fileName,wrclocation,wrctype,wrccont,data,wrcfile,insertCheck,
                   openDlg, dlgTrans, dlgMsg, compList, searchApi, insertApi, updateApi, deleteApi,imgBase64,updateCheck, wrcperiod,
                   typeOpen,setModalOpen,open,setOpen,setTypeOpen,upload_file,previewYN, wrccontsize
                 } = this.state;

            
            /**
             * Control 변경 Event
             * @param {*} e 
             */
            const onChange = async (e) => {

                 
                
                const { name, value } = e.target;

                // 텍스트 크기는 숫자만 입력
                if (name === "wrccontsize" ) {
                    if (pattern_spc.test(value) || pattern_kor.test(value) || pattern_eng.test(value)) return
                }

                let newSearchs = this.state;
                newSearchs[name] = value
                this.setState({ ...newSearchs });
            }

            
            //사용여부 List
            const useYnList = [                
                { "comCode" : "Y",  "text": "사용" }, 
                { "comCode" : "N",  "text": "미사용" }, ]; 
            
            //적용화면 List
            const loactionList = [                
                { "comCode" : "M",  "text": "메인" },
                { "comCode" : "L",  "text": "로그인" }, ];                 
            
            //출력타입 List
            const typeList = [                
                { "comCode" : "I",  "text": "이미지" }, 
                { "comCode" : "T",  "text": "텍스트" }, ];                 
            
            //체크 시 팝업 띄우지 않을 기간 List
            const periodList = [                
                { "comCode" : "D",  "text": "하루" }, 
                { "comCode" : "W",  "text": "일주일" }, ];
                
            // type="datetime-local" 데이터를 YYYYMMDDHH24 형태로 변환
            const fromformat = wrcfrom.substring(0,4) + wrcfrom.substring(5,7) + wrcfrom.substring(8,10)+ wrcfrom.substring(11,13); 
            const toformat = wrcto.substring(0,4) + wrcto.substring(5,7) + wrcto.substring(8,10)+ wrcto.substring(11,13);                                  
                                                       
            /**
             * 조회
             * @param {*} e 
             */
            const onSearch = async (e) => { 
                // let para = `{"wrcseqn":"${wrcseqn}"}`;
                // let param = encodeURIComponent(para);

                // const query = SERVER_URL + "/wrtpopup/search?query=" + param;

                // const fetchSearchData = async (query) => {
                //     let inputData = this.state; 
                //     await fetch(query,  { headers: { Authorization: token } })
                //         .then((resp) => resp.json())
                //         .then((data) => {
                //             if (data.success) {
                //                 inputData = data.data[0];
                //                 this.setState({ ...inputData });
                //             }
                //             if (data.error) {
                //                 this.setState({ openAlert : true, 
                //                                 severity : "error",
                //                                 msg : "조회 실패"});
                //             }
                //         })
                //         .catch((error) => {
                //             // 에러 응답 처리
                //             if (error) {
                //                 this.setState({ openAlert : true, 
                //                                 severity : "error",
                //                                 msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                //             }
                //         });
                // };
                // fetchSearchData(query);
            }


            /**
             * 신규 Row추가
             * @param {*} e 
             */
            const onAddRow = async (e) => {
            }

            const handleOpenA = async (e) => {
                this.setState({
                    typeOpen:true,
                    setTypeOpen:true,
                });
            }

            const handleClickCloseA = async (e) => {
                this.setState({
                    typeOpen:false,
                    setTypeOpen:false
                })
            }

                        
            /**
             * 팝업 미리보기       
             * @param {*} e       
             */
            const onPreview = async (e) => {
                
                let data = this.state; 
                data.data["wrcseqn"] = this.state.wrcseqn;   
                data.data["wrccont"] = this.state.wrccont;   
                data.data["wrclink"] = this.state.wrclink;   
                data.data["fileId"] = this.state.wrctype == "I" ? document.getElementById('previewImg').src : "";
                data.data["previewYN"] = this.state.previewYN;   
                data.data["wrctype"] = this.state.wrctype;   
                data.data["wrcperiod"] = this.state.wrcperiod;   
                data.data["wrccontsize"] = this.state.wrccontsize;
                
                this.setState({ data : [{...data.data}] });

                handleOpenA()
            }
            
                            
             /**
             * 입력
             * @param {*} e 
             * @returns 
             */
            const onInsert = async (e) => { 
                if (wrctitl == null) {
                    
                    this.setState({ openAlert : true, 
                                    severity : "error",
                                    msg : "제목을 입력해주세요."});
                    return false;
                }      
                if (fromformat > toformat) {
                    
                    this.setState({ openAlert : true, 
                                    severity : "error",
                                    msg : "종료일이 시작일보다 빠릅니다."});
                    return false;
                }
             
                const query = SERVER_URL + "/wrtpopup/insert"; 
                const formData = new FormData();

                if(!(this.state.selectedFile === undefined || this.state.selectedFile==null)){
                    formData.append('file', this.state.selectedFile);
                }


                for (const [key, value] of Object.entries(this.state)) {
                    var tmpValue= `${value}`
                    if(`${value}`== undefined ||`${value}`== null ||`${value}`== "undefined"){
                        tmpValue= "";
                    }
                    formData.append(`${key}`, tmpValue);
                }
                formData.append('fromformat', fromformat); // 시작일 전처리 데이터
                formData.append('toformat', toformat);     // 종료일 전처리 데이터
                
                this.setState({loading : true}); 
                const fetchUpdateData = async () => {                   
                    await fetch(query, { method: "POST", headers: { Authorization: token}, body: formData})                        
                        .then((resp) => resp.json())
                        .then((data) => {
                            if (data.success) {
                                this.setState({ openAlert : true, 
                                                severity : "info",
                                                msg : "저장 되었습니다 "});                                                
                            }
                            else if (data.errors) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.errors.message});

                            }
                            else if (data.error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.message});
                            }
                        })
                        .catch((error) => {
                          
                        });
                };
                this.setState({loading : false}); 
                fetchUpdateData();
            }
            

            /**
             * 수정 
             * @param {*} e 
             * @returns 
             */
            const onUpdate = async (e) => {                   

                if (wrctitl == null) { // 제목 누락 에러메시지 
                    
                    this.setState({ openAlert : true, 
                                    severity : "error",
                                    msg : "제목을 입력해주세요."});
                    return false;
                }      
                                
                if (fromformat > toformat) { // 비정상적 기간설정 에러메시지 
                    
                    this.setState({ openAlert : true, 
                                    severity : "error",
                                    msg : "종료일이 시작일보다 빠릅니다."});
                    return false;
                }            

                const query = SERVER_URL + "/wrtpopup/update"; 

                const formData = new FormData();

                if(!(this.state.selectedFile === undefined || this.state.selectedFile==null)){
                    formData.append('file', this.state.selectedFile);
                }

                for (const [key, value] of Object.entries(this.state)) {
                    var tmpValue= `${value}`
                    if(`${value}`== undefined ||`${value}`== null ||`${value}`== "undefined"){
                        tmpValue= "";
                    }                    
                    formData.append(`${key}`, tmpValue);
                }
                formData.append('fromformat', fromformat); // 시작일 전처리 데이터 추가
                formData.append('toformat', toformat);     // 종료일 전처리 데이터 추가                

                this.setState({loading : true}); 

                const fetchUpdateData = async () => {                   
                    await fetch(query, { method: "POST", headers: { Authorization: token}, body: formData})                        
                        .then((resp) => resp.json())
                        .then((data) => {
                            if (data.success) {
                                this.setState({ openAlert : true, 
                                                severity : "info",
                                                msg : "저장 되었습니다 "});                                                
                            }
                            else if (data.errors) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.errors.message});

                            }
                            else if (data.error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.message});
                            }
                        })
                        .catch((error) => {
                          
                        });
                };
                this.setState({loading : false}); 
                
                fetchUpdateData();                
                // this.props.onClose();
            }



            /**
             * 삭제
             * @param {*} e 
             */
            const onDelete = async (e) => {
                
                const fetchData = async () => {
                    const query = SERVER_URL + "/wrtpopup/delete"; 
                    let param = JSON.stringify({
                        wrcseqn : wrcseqn,
                        wrcfile : fileId
                    });

                    await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })                        
                        .then((resp) => resp.json())
                        .then((data) => {
                            if (data.success) {
                                this.setState({ openAlert : true, 
                                                severity : "info",
                                                msg : data.count + "건 작업을 수행하였습니다. "});
                            }
                            else if (data.errors) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.errors.message});

                            }
                            else if (data.error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.message});
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) { 
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                };
                        
                fetchData(); //WRTPOPUP 테이블 데이터 제거
                onDelete2(); //FILE_ATTACH 테이블 데이터 제거
            }

              /**
             * 삭제
             * @param {*} e 
             */
               const onDelete2 = async (e) => { //WRTPOPUP 데이터와 같이 삭제해야하는 FILE_ATTACH 테이블 데이터 삭제
                
                const fetchData = async () => {
               
                        const query = SERVER_URL + "/wrtpopup/delete2"; 
                        let param = JSON.stringify({
                            wrcseqn : wrcseqn,
                            wrcfile : wrcfile
                        });

                        await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })                        
                            .then((resp) => resp.json())
                            .then((data) => {
                                if (data.success) {
                                    this.setState({ openAlert : true, 
                                                    severity : "info",
                                                    msg : data.count + "건 작업을 수행하였습니다. "});
                                }
                                else if (data.errors) {
                                    this.setState({ openAlert : true, 
                                                    severity : "error",
                                                    msg : data.errors.message});

                                }
                                else if (data.error) {
                                    this.setState({ openAlert : true, 
                                                    severity : "error",
                                                    msg : data.message});
                                }
                            })
                            .catch((error) => {
                                // 에러 응답 처리
                                if (error) { 
                                    this.setState({ openAlert : true, 
                                                    severity : "error",
                                                    msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                                }
                            });
                    };                    
                if(wrcfile != null && wrcfile != undefined && wrcfile != ""){
                    fetchData();            
                }
            }


            //deleteBlank
            function deleteBlank(argval){

            }


            // input check
            const inputCheck = () => {
 
            }
            

            //AlertDialogResult
            const AlertDialogResult = async (e) => {

                //AlertDialog 초기화
                this.setState({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        
                //DialogResult
                if (e.result == "N") return;
        
                //Transaction 구분
                switch (e.Trans) 
                {
                    //등록
                    case "I" :
                        break;
                    //수정
                    case "U" :
                        break;
                    //삭제
                    case "D" :
                        break;
                }
            }
            const pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크
            const pattern_num = /[0-9]/;	// 숫자 
            const pattern_spc = /[~!.'/@#$%^&*()_+|<>?\\:={}-]/; // 특수문자
            const pattern_eng = /[a-zA-Z]/;	// 문자             
            
            return (
                <>
                    <Loading loading={this.state.loading} type={"bubbles"} />
                    {/* Dialog FRAME */}
                    <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

                    {/* Alert FRAME */}
                    <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {this.setState({openAlert:false, severity:"", msg:""})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
                    <button onClick={() => {this.props.onClose()}} style = {{border:"none", float:"right"}}> <img src="/images/button/close_button.png" alt="File" /></button> 

                    {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
                    <Ribbon
                        // newTabUnused={true}                             // newTabUnused : newTab 표출 버튼 true : 사용안함 // 선언안할경우 default = newTab 사용
                        // onSearch={onSearch} onSearchName={null}     //조회                        
                        onInsert={onInsert} onInsertVisible = {insertCheck == "0" && tVnt1 === "M000"}   // 입력 버튼(마스터 계정, 최초 입력 시)
                        onUpdate={onUpdate} onUpdateVisible = {insertCheck !== "0" && tVnt1 === "M000"}  // 수정 버튼(마스터 계정, 기존 데이터 수정 시)
                        onDelete={onDelete} onDeleteVisible = {insertCheck !== "0" && tVnt1 === "M000" } // 삭제 버튼(마스터 계정, 기존 데이터 삭제 시)
                    >    
                    <b style={{ color: "#cd5c5c", fontFamily: "PretendardMedium, NanumGothic, sans-serif" }}>팝업관리 상세</b>
                    </Ribbon>

                    {/* ContentXearch Frame : 검색조건 */}

                    <div style={{paddingBottom:"30px", marginTop: "8px"}}>                        
                        <table>
                            <tr>
                                {wrctype == "I" ?
                                <td style={{height:"610px", width:"740px", background: "#F1F3F7"}}>
                                <td style={{paddingTop:"5px", paddingLeft:"20px", verticalAlign: "top", whiteSpace:"nowrap", fontFamily: "'PretendardMedium', sans-serif",fontSize : "large", alignItems:"center"}}>이미지 </td>                       
                                    <img style={{height:"550px", width:"700px", alt:"File", marginLeft:"20px",marginRight:"20px", resize:"none", outline:"none", border:"none", backgroundColor:"#FFFFFF"}} id='previewImg' src = {"/file/popup/" + wrcfile}/>
                                </td>
                                :""}
                                {wrctype == "T" ?
                                <td style={{height:"610px", width:"740px", background: "#F1F3F7"}}>
                                   <td style={{paddingTop:"5px", paddingLeft:"20px", verticalAlign: "top", whiteSpace:"nowrap", fontFamily: "'PretendardMedium', sans-serif",fontSize : "large"}}>텍스트 </td>                       
                                   <textarea name="wrccont"
                                        placeholder='출력타입이 "텍스트"일때 출력될 내용을 입력해주세요.'               
                                        style = {{padding:"15px", height:"550px", width:"700px",fontFamily: "'PretendardMedium', sans-serif", fontSize : this.state.wrccontsize + "px" , textAlign:"left", resize:"none", outline:"none", border:"none", marginLeft:"20px",marginRight:"20px"}} 
                                        value = {wrccont}  onChange={onChange}>
                                    </textarea>            
                                </td>   
                                :""}                                
                                <td style={{height:"500px", width:"400px",background: "#F1F3F7" }}>
                                    <ul>          
                                        <li style={{ border: "0px" , padding: "0% 0% 0% 5%", fontFamily: "'PretendardMedium', sans-serif",fontSize : "large" }}>
                                            출력타입 : 
                                                <select name="wrctype" style={{ width: "20%", height: "26px", marginLeft: "15px", border: "1px solid #b8b8b8", padding: "2px", margin: "10px"} } onChange={onChange} value={wrctype} >
                                                {typeList.map(element => ( 
                                                    <option key={element.comCode} value={element.comCode}>
                                                            {element.text} 
                                                    </option>
                                                ))}                                       
                                                </select>                                            
                                        </li>                                                                    
                                        <li style={{ border: "0px" , padding: "0% 0% 0% 5%", fontFamily: "'PretendardMedium', sans-serif", fontSize : "large"}}>
                                            제목 : <input name="wrctitl" onChange={onChange} style={{ width: "75%", height: "26px", marginLeft: "15px", border: "1px solid #b8b8b8", padding: "5px", margin: "10px" }} value={wrctitl} />
                                        </li>
                                        <li style={{ border: "0px" , padding: "0% 0% 0% 5%", fontFamily: "'PretendardMedium', sans-serif", fontSize : "large", display:"none"}}>
                                            fileId : <input name="fileId" onChange={onChange} style={{ width: "70%", height: "26px", marginLeft: "15px", border: "1px solid #b8b8b8", padding: "5px", margin: "10px"}} value={fileId}/>
                                        </li>                                        
                                        <li style={{ border: "0px" , padding: "0% 0% 0% 5%", fontFamily: "'PretendardMedium', sans-serif",fontSize : "large" }}>
                                            링크 : <input name="wrclink" placeholder='팝업 클릭시 페이지 이동 https://www.naver.com' onChange={onChange} style={{ width: "75%", height: "26px", marginLeft: "15px", border: "1px solid #b8b8b8", padding: "5px", margin: "10px" }} value={wrclink} />
                                        </li> 
                                        <li style={{ border: "0px" , padding: "0% 0% 0% 5%", fontFamily: "'PretendardMedium', sans-serif",fontSize : "large" }}>
                                            시작일 : <input name="wrcfrom" type="datetime-local" onChange={onChange} style={{ width: "60%", height: "26px", marginLeft: "15px", border: "1px solid #b8b8b8", padding: "5px", margin: "10px"}}  value={wrcfrom} 
                                            defaultValue ={(Common.getToday() + "T" + Common.getTime())}/>
                                        </li>                                    
                                        <li style={{ border: "0px" , padding: "0% 0% 0% 5%", fontFamily: "'PretendardMedium', sans-serif",fontSize : "large", display:"none" }}>
                                            시작일 포맷 : <input name="fromformat" type="text" onChange={onChange} style={{ width: "40%", height: "26px", marginLeft: "15px", border: "1px solid #b8b8b8", padding: "5px", margin: "10px"}} pattern="\d*" value={fromformat} />
                                        </li>                                    
                                        <li style={{ border: "0px" , padding: "0% 0% 0% 5%", fontFamily: "'PretendardMedium', sans-serif",fontSize : "large" }}>
                                            종료일 : <input name="wrcto" type="datetime-local" onChange={onChange} style={{ width: "60%", height: "26px", marginLeft: "15px", border: "1px solid #b8b8b8", padding: "5px", margin: "10px"}}  value={wrcto} />
                                        </li>
                                        <li style={{ border: "0px" , padding: "0% 0% 0% 5%", fontFamily: "'PretendardMedium', sans-serif",fontSize : "large" , display:"none"}}>
                                            종료일 포맷 : <input name="wrcto" type="text" onChange={onChange} style={{ width: "40%", height: "26px", marginLeft: "15px", border: "1px solid #b8b8b8", padding: "5px", margin: "10px"}}  value={toformat} />
                                        </li>
                                        <li style={{ border: "0px" , padding: "0% 0% 0% 5%", fontFamily: "'PretendardMedium', sans-serif",fontSize : "small", color:"red"}}>
                                            * 시작일과 종료일에서 분 단위 시간은 무시됩니다.
                                        </li>            
                                        <li style={{ border: "0px" , padding: "0% 0% 0% 5%", fontFamily: "'PretendardMedium', sans-serif",fontSize : "large" }}>
                                            사용여부 : 
                                                <select name="useYn" style={{ width: "20%", height: "26px", marginLeft: "15px", border: "1px solid #b8b8b8", padding: "2px", margin: "10px" } } onChange={onChange} value={useYn == undefined ? "Y" : useYn} >
                                                {useYnList.map(element => ( 
                                                    <option key={element.comCode} value={element.comCode}>
                                                            {element.text} 
                                                    </option>
                                                ))}                                       
                                                </select>                                            
                                        </li>                        
                                        <li style={{ border: "0px" , padding: "0% 0% 0% 5%", fontFamily: "'PretendardMedium', sans-serif",fontSize : "large" }}>
                                            적용화면 : 
                                                <select name="wrclocation" style={{ width: "20%", height: "26px", marginLeft: "15px", border: "1px solid #b8b8b8", padding: "2px", margin: "10px" } } onChange={onChange} value={wrclocation} >
                                                {loactionList.map(element => ( 
                                                    <option key={element.comCode} value={element.comCode}>
                                                            {element.text} 
                                                    </option>
                                                ))}                                       
                                                </select>                                            
                                        </li>                                     
                                      
                                        {wrctype == "T" ?
                                        <li style={{ border: "0px" , padding: "0% 0% 0% 5%", fontFamily: "'PretendardMedium', sans-serif", fontSize : "large"}}>
                                            텍스트 크기 : <input name="wrccontsize" onChange={onChange} style={{ width: "12%", height: "26px", marginLeft: "15px", border: "1px solid #b8b8b8", padding: "5px", margin: "10px" }} value={wrccontsize}/>px
                                        </li>
                                        :""}
                                        <li style={{ border: "0px" , padding: "0% 0% 0% 5%", fontFamily: "'PretendardMedium', sans-serif",fontSize : "small", color:"red"}}>
                                            * [주의] 선택한 출력 타입에 따라 팝업이 출력됩니다.<br></br>
                                                등록 전 팝업 미리보기로 확인해주세요.
                                        </li>
                                        <li style={{ border: "0px" , padding: "0% 0% 0% 5%", fontFamily: "'PretendardMedium', sans-serif",fontSize : "large" }}>
                                            체크 시 팝업 
                                                <select name="wrcperiod" style={{ width: "20%", height: "26px", marginLeft: "15px", border: "1px solid #b8b8b8", padding: "2px", margin: "10px"} } onChange={onChange} value={wrcperiod} >                                            
                                                {periodList.map(element => ( 
                                                    <option key={element.comCode} value={element.comCode}>
                                                            {element.text} 
                                                    </option>
                                                ))}                                       
                                                </select>                                            
                                            동안 띄우지 않기
                                        </li>    
                                        {wrctype == "I" ?
                                        <li style={{ border: "0px" , padding: "0% 0% 2% 5%", fontFamily: "'PretendardMedium', sans-serif",fontSize : "large"}}>
                                            파일명 : <input name="fileName" readOnly style={{ width: "70%", height: "26px", marginLeft: "15px", border: "1px solid #b8b8b8", padding: "5px", margin: "10px"}} value={fileName}/>
                                        </li>            
                                        :""}                                
                                        {imgBase64 && (
                                            <div className="image_area">
                                                <img src={imgBase64} alt={fileName} />
                                            </div>
                                        )}  
                                        <li>          
                                            <input ref={this.fileRef} type="file" id="upload_file" multiple="multiple" className='imgInput' accept='image/*' name="file_name"
                                                onChange={e => this.handleFileInput(e) } 
                                                style={{width: "89%", height: "34px", marginLeft: "15px", border: "1px solid #b8b8b8", padding: "5px", margin: "10px", display:"none"
                                                }}/>                                           
                                        </li>
                                        {wrctype == "I" ?
                                        <li>                                            
                                            <button title="file_attach" onClick={e => this.handleFileClick(e)} style ={{height:"30px",  width:"120px"}}>
                                                <img src="/images/button/btn_update.png" alt="File" />
                                                <span>파일 업로드 </span>                                        
                                            </button>                                            
                                        </li>   
                                        :""}   
                                        <li>
                                            <button title="popup_preview" onClick={onPreview} style ={{height:"30px", width:"120px", }}>
                                                <img src="/images/button/btn_search.png" alt="File" />
                                                <span>팝업 미리보기 </span>                                        
                                            </button>           
                                        </li>
                                    </ul>                                    
                                </td>                                                                        
                            </tr>    
                        </table>                                                                                                  
                    </div>              
                    <Popup type="PopupMngPopup" open={typeOpen} closeSeqid = {this.state.closeSeq} onClose={handleClickCloseA} onClick = {()=> {this.setState({setOpen:false})}} data = {data}/>
                </>
            );
        }        
    }
}
export default PopupMngDetail;