import React, { useEffect } from 'react';

import '../../css/main.css';

import { TextField } from '@material-ui/core';

function CustomInputDateHour({ //children, ref,
    id, name, defaultValue,
    id2, name2, defaultValue2,
    onChange }) {

    useEffect(() => {

        if (defaultValue) {
            let temp = document.getElementById(id);
            temp.value = defaultValue;
        }
        if (defaultValue2) {
            let temp2 = document.getElementById(id2);
            temp2.value = defaultValue2;
        }

    }, [id, id2, defaultValue, defaultValue2]);


    const handleChange = (e) => { // 검색조건 변경시 이벤트 처리 - 검색조건
        const { name, value } = e.target;
        let event = {
            target: {}
        };
        event.target["name"] = name;
        event.target["value"] = value;

        if (onChange) {
            onChange(event);
        }
    };

    const arrayHour = new Array(24);

    return (
        <>
            <div className={"h_Label1L"}>


                <TextField
                    id={id}
                    name={name}
                    className={"k_input"}
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        max:"9999-12-31"
                      }}
                    variant="outlined"
                    onChange={handleChange}
                    defaultValue={defaultValue}
                    style={{ float: "left", width: "65%" }}
                />

                <TextField
                    id={id2}
                    name={name2}
                    className={"k_combo"}
                    select
                    onChange={handleChange}
                    SelectProps={{
                        native: true,
                    }}
                    variant="outlined"
                    defaultValue={defaultValue2}
                    style={{ float: "left", width: "35%", paddingLeft: "5px" }}
                >
                    {Array.from(arrayHour, (v, i) =>
                        <option key={"hour" + id + i} value={("0" + i).slice(-2)}>{("0" + i).slice(-2)}</option>
                    )}

                </TextField>
            </div>
        </>
    );
}

export default CustomInputDateHour;