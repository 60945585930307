/**
 * @Page RSY32
 * @Description [RSY32]시스템 관리 - 운영관리 - 사용현황
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import Ribbon from '@/components/organisms/Ribbon';
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
 
 
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const gbn = [{ "comCode" : "****",  "text": "공통" }];  //조회조건 구분 
 const Utils = new CommonUtil();
 
 function RSY32() {
     const token = Common.getToken();
     const userId = Common.getToken("id");
     const userName = Common.getToken("name");
     const tVnt1 = Common.getToken("vand");
     const tComp = Common.getToken("comp");
     const grdRSY32Main = useRef();     //Grid Main
     const grdRSY32Detail = useRef();   //Grid Detail
 

     //Data
     const [idata, setData] = useState({
        //조회조건
        Kfndt : Common.getToday(),   //조회기간 시작일
        Ktndt : Common.getToday()   //조회기간 시작일
     }); 


      
     const [message, setMessage] = React.useState( {
         openAlert : false,      
         severity : "success",   
         msg : ""                
     });
     const { openAlert, severity, msg } = message;
 
 
     //Dialog
     const [dlgMessage, setDlgMessage] = React.useState( {
         openDlg : false,    
         dlgTrans : "",      
         dlgMsg : ""         
     });
     const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
 
 
     //Grid Search, Insert, Update, Delete Api
     let [searchApi, setSearchApi] = useState(false); 
     let [searchApi2, setSearchApi2] = useState(false); 
     const [panded, setPanded] = React.useState(true);
     const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 55px  - 55px)"});

     const onClearApi = useCallback(async (e) => {
         setSearchApi(false);
         setSearchApi2(false);
         
     }, []);

     /**
      * 공통코드 조회
      */
     const onSearchCommon = (para) => {
 
         let commonData = idata;
 
         //그룹코드 조회
         let param = encodeURI(para);
         const query = SERVER_URL + "/comcd/search1?query=" + param; 
     }    

     /**
      *MOUNT 
      */
     useEffect( () => {
        //  //공통코드 조회
         onSearchCommon(`{"pgmid":"CODE", "vand":"****", "gocd" : "00"}`);

         //조회
        //  setTimeout(() => onSearch(), 300);
     }, []);
 
     /**
      * Control 변경 Event
      * @param {*} e 
      */
     const onChange = async (e) => {
         const { name, value } = e.target;
 
         let newSearchs = idata;
         newSearchs[name] = value
 
         setData({ ...newSearchs });
 
         //조회 Call    
        //  onSearch(); 
 
     }


     /**
      * Grid Event 
      * @param {*} e 
      */
      const onCellValueChanged = async (e) => {
     }

     const onRowClicked = async (e) => {
     }
 
 
     /**
      * 검색조건
      */
      const optionsX = {
         0: [{
                formatter: "date2", name: "Kfndt", type: "date", labelName: "조회기간"
                , name2: "Ktndt",defaultValue2: idata.Ktndt
                , defaultValue: idata.Kfndt
                , align: "left" // (우측 정렬)
                , labelType: "none" 
                , page:"RSY32"
            },
             //업체명
             {"formatter": "blank", "align": "left"}
             
         ]
     }

     /**
      * 조회 
      * @param {*} e 
      * @returns 
      */
     const onSearch = async (e) => {
        
        if(idata.Kfndt !== "" && idata.Ktndt !== ""){
            // 조회기간 제한(7일)
            if(Common.getDateCa(idata.Kfndt,7,'d') < idata.Ktndt){
                setMessage({ openAlert : true, 
                    severity : "info",
                    msg : "조회기간은 일주일 이내로 설정바랍니다."});   
                return ;
            }

            //조회 종료일이 시작일보다 빠를경우 ex) 2022-08-05 ~ 2022-08-02
            else if(idata.Kfndt > idata.Ktndt){
                setMessage({ openAlert : true, 
                    severity : "info",
                    msg : "종료일이 시작일보다 빠릅니다."});   
                return ;
            }
        }

        // 당일 조회(00시 ~ 23시 59분 59초 999999999)
        if(idata.Kfndt === idata.Ktndt){
            idata.Kfndt = idata.Kfndt + ' 00:00:00.000000000';
            idata.Ktndt = idata.Ktndt + ' 23:59:59.999999999';
        }
       
        // 조회기간 하이픈 제거
        let para = `{
            "Kfndt": "${idata.Kfndt.replaceAll('-','')}", 
            "Ktndt": "${idata.Ktndt.replaceAll('-','')}"
         }`;
     
        let param = encodeURI(para);

        const query = SERVER_URL + "/rsy32/search?query=" + param;
        const query2 = SERVER_URL + "/rsy32/search2?query=" + param;
         
        setSearchApi(query); // Search API

        setSearchApi2(query2); // Search API2
        
        // 당일 조회시 추가된 시간 제거(검색 후 Kfndt, Ktndt 유지 관련)
        idata.Kfndt = idata.Kfndt.substring(0,10);
        idata.Ktndt = idata.Ktndt.substring(0,10);   
     }
     
     //Transaction AlertDialogResult
     const AlertDialogResult = async (e) => {
 
         //AlertDialog 초기화
         setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
 
         //DialogResult
         if (e.result == "N") return;
 
         //Transaction 구분
        //  switch (e.Trans) 
        //  {
        //      //등록
        //      case "I" :
        //          break;
        //      //수정
        //      case "U" :
        //          break;
        //      //삭제
        //      case "D" :
        //          const query = SERVER_URL + "/RSY32/delete";
               
        //          break; 
        //  }
     }
    

     /**
      *Grid Column 정의 
      */
      const columnDefs = [
        { headerName: "NO", valueGetter: "node.rowIndex + 1", pinned: 'left', flex: 1, minWidth: 20, width: 80, filter: true, cellClass: ["nocheckbox", "alignC"]}, 
        { headerName: "업체코드", field: "wrcuser", sortable: true, flex: 1, minWidth: 350, filter: true, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false}, 
        { headerName: "업체명", field: "wrcname", sortable: true, flex: 1, minWidth: 350, filter: true,  editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false} 
       
    ];
    const columnDefs1 = [
        { headerName: "NO", valueGetter: "node.rowIndex + 1", pinned:'left', flex: 1, minWidth: 20, width: 80, filter: true, cellClass: ["nocheckbox", "alignC"]}, 
        { headerName: "사용자 아이디", field: "userid", sortable: true, filter: true, flex: 1, minWidth: 350, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false}, 
        { headerName: "사용자명", field: "username", sortable: true, filter: true, flex: 1, minWidth: 350, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false} 
       
    ];

    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 50px)"
                });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 55px  - 55px"
                });
        }
    }
    
     return(
         <>
             {/* Dialog FRAME */}
             <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />
 
             {/* Alert FRAME */}
             <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({openAlert:false, severity:"", msg:"조회 실패"})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
               
             {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
             <Ribbon
                 onInfoManual={false}// onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                 onSearch={onSearch} onSearchName={null} onSearchVisible = {tVnt1 === "M000"} //조회 
            >
                 {"시스템관리 > 운영관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>사용현황</b>
             </Ribbon>
 
 
             {/* ContentXearch Frame : 검색조건 */}
             <ContentXearch
                 optionsX={optionsX}
                 onChange={onChange} 
                 onClick={onClick}
                 />
 
            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:55px */}
             <div className="grid_type02">{/* 하단 FRAME */}
                    <div className="grid_wrap02"> {/* 하단 wrap 좌측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                                {/* Grid1 */}
                                <Grid
                                    ref={grdRSY32Main}
                                    gridId={"RSY32"}                    //  (필수) Grid Id 정의
                                    columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                    pagination={true}                   //  쪽수 매기기 여부
                                    paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    // paginationCsv={tVnt1 === "M000"}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                    paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                    rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                    suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                    rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                    enableRangeSelection={false}        //  
                                    enableCellTextSelection={true}      // cell selection drag mode
                                    isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                    displayNo = {false}
                                    searchApi={searchApi}               // 조회
                                    onClearApi={onClearApi}
                                    onRowClickEvent={onRowClicked}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid_wrap03"> {/* 하단 wrap 우측 FRAME */}
                        <div className="grid_area"> {/* Grid area */}
                            <div className="grid_cont_box" style={{ height: "calc(100%)" }}>

                                {/* Grid2 */}
                                <Grid
                                   ref={grdRSY32Detail}
                                   gridId={"RSY322"}                    //  (필수) Grid Id 정의
                                   columnDefs={columnDefs1}             //  (필수) 컬럼 정의 
                                   pagination={true}                   //  쪽수 매기기 여부
                                   paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                   // paginationCsv={tVnt1 === "M000"}           //  CSV 사용구분 - pagination과 동시에 사용 필요
                                   paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                   rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                   suppressRowClickSelection={true}    //  클릭으로 선택 하는것을 방지
                                   rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                   enableRangeSelection={false}        //  
                                   enableCellTextSelection={true}      // cell selection drag mode
                                   isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                   displayNo = {false}
                                   searchApi={searchApi2}               // 조회2                        
                                   onClearApi={onClearApi}
                                   onRowClickEvent={onRowClicked}
                                />
                            </div>
                        </div>
                    </div>
                </div>
              
             </Content>
         </>
     )
 }
 export default RSY32;
