import React, {useState, useEffect } from "react";

import '../../css/main.css';

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles } from "@material-ui/core/styles";

let codesList;
let commsList; 
let itemsList; // Case 3 - items :: Hard Coding List

function CustomAutoInput({
    id, codes, comms, onChange, name, 
    defaultValue, items, onInit, selectAll}) {

     useEffect(() => {
        comms.unshift({text : codes[0].text, comCode : codes[0].comCode});                                         
    },[comms]);

    const [value, setValue] = useState();

    const handleChange = (e) => { 
        const { name, value } = e.target;
        let event = {
            target: {}
        };
        event.target["name"] = name;
        event.target["value"] = value;
        
        if (onChange) {
            onChange(event);
        }
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9e9ea7"
            },

            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#3f51b5"
            },
          },
        inputRoot: {                              
            '&[class*="MuiOutlinedInput-root"]': { 
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 0
            },
          },
         
      }));

    const classes = useStyles();    
        return (
            <div className={"h_Label1L"} >
                <div name = "h_comCode" id = {"h_" + name} style={{display:"none"}}>  
                    {(JSON.stringify(value, ['comCode'])) == "" ? "00" : (JSON.stringify(value, ['comCode']))}    
                </div>      
                <Autocomplete  
                    classes={classes}
                    id = {id}                
                    name = {name}                    
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    options={comms} 
                    getOptionLabel={(option)=>(option.text)}  /*getOptionLabel : combobox 항목 선택시 적용될 value값*/                      
                    style={{ width: 200, height :32, backgroundColor: "#FFFFFF"}}
                    defaultValue ={codes[0]} /*defaultValue : 초기값*/                                        
                    renderOption={(option) => ( /*renderOption : combolist 출력 형식(여러개 가능)*/ 
                        <React.Fragment>
                            {option.text}
                        </React.Fragment>
                    )}
                    renderInput={(params) => 
                        <TextField         
                            {...params}                      
                            label="" 
                            variant="outlined"                                                                            
                    />}
                    />    
                </div>                 
          );
}
export default CustomAutoInput;