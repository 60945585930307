/**
 * @Page WrapCkdLabelPrint_QR_B_NEW
 * @Description [ROM4K / ROM4R / ROM4X]납입지시 - ERP_CKD출하관리 - 부품식별표(중포장/용기/중포장2D) QR  ::  B 6매
 **/
import React from "react";
import CustomDataMatrix from '@/components/atoms/CustomDataMatrix';
import * as Common from "../../utils/Common"; 
import styled from 'styled-components';
import QRCode from "react-qr-code";

class WrapCkdLabelPrint_QR_B_NEW extends React.Component {

constructor(props) {
    super(props);
    this.state = {
        pathNm: null
    };
}

componentDidMount() {
    if(window.location.pathname.split('/')[2] === "ROM4X") { // ROM4X 부품식별표(중포장 2D) check
        this.setState({pathNm:window.location.pathname.split('/')[2]});
    }
}


    // '바코드 = 업체코드(4) + 품번(15) + 수량(5) + LOTNO(3) + 일련번호(4)
    // tmp(0) '회사구분 /tmp(1) '지역 /tmp(2) '공장 /tmp(3) '차종 /tmp(4) '납품장소(하치장)
    // tmp(5) '품번/tmp(6) '품명/tmp(7) '납품수량/tmp(8) '바코드/tmp(9) '납품일자
    // tmp(10) '업체명/tmp(11) "" '공정(공백)/tmp(12) '업체코드/tmp(13) "" ' LOT NO/tmp(14) "" 'HPC(공백)
    // tmp(15) 'CASE(CKD)/tmp(16) "" '발행번호(바코드만들어서 던짐)/tmp(17) "" '용기번호/tmp(18) '부착매수/tmp(19) 'LH/RH
    // tmp(20) "" '사양/tmp(21)  '발주번호(공백)/tmp(22)  'FROM-ORDER(CKD , OEM은 공정)/tmp(23)  'CKD는 공백, OEM은 HPC/tmp(24)  '용기당수
    // tmp(25) = '출력1/tmp(26) = 'x좌표1/tmp(27) = 'y좌표1/tmp(28) = '색상1/tmp(29) = 'FONT/S1
    // tmp(30) = '출력2/tmp(31) = 'x좌표2/tmp(32) = 'y좌표2/tmp(33) = '색상2/tmp(34) = 'FONT/S2
    // tmp(35) = '한글품명/tmp(36) = 'RHD


    render() {

    // Print Style CSS Setting
    const PrintStyleB = styled.div`
    @media all {
        body {
            background-color: ;
            margin: 0px;
            padding: 8px;
            font-family: "HY견고딕";
        }
        .ChosunGu12 {
            font-family: "NanumGothic";
            font-weight:bold;
            font-size:12pt;
        }
        .ChosunGu13 {
            font-family: "NanumGothic";
            font-weight:bold;
            font-size:13pt;
        }
        .ChosunBg {
            font-family: "ChosunBg";
        }

        .row2 > div { display : flex; white-space: nowrap; z-index: 99; }

        .cell { display : flex; white-space: nowrap; z-index: 99; }

        .cell_hori{ display : flex; justify-content: center; } // 수평 중앙정렬 → 

        .cell_veri{ display : flex; align-items: center; } // 수직 중앙정렬 ↓

        .aligR { flex-direction: row-reverse; }
        
        .row2  { display: flex; flex-flow: row nowrap; }

        .row2>.cell:nth-child(1)  { width: 1mm;  } // background : red ; 
        .row2>.cell:nth-child(2)  { width: 15mm; } // background : blue ; 
        .row2>.cell:nth-child(3)  { width: 4mm;  } // background : orange ; 
        .row2>.cell:nth-child(4)  { width: 4mm;  } // background : purple ; 
        .row2>.cell:nth-child(5)  { width: 26mm; } // background : beige ; 
        .row2>.cell:nth-child(6)  { width: 17mm; } // background : pink ; 
        .row2>.cell:nth-child(7)  { width: 4mm;  } // background : orange ; 
        .row2>.cell:nth-child(8)  { width: 23mm; } // background : green ; 
        .row2>.cell:nth-child(9)  { width: 10mm; } // background : yellow ; 
        .row2>.cell:nth-child(10) { width: 25mm; } // background : red ; 
        .row2>.cell:nth-child(11) { width: 9mm;  } // background : navy ; 
        .row2>.cell:nth-child(12) { width: 1mm;  } // background : green ; 
        .row2>.cell:nth-child(13) { width: 1mm;  } // background : green ; 

    }
    
    @media print { margin : 0  !important} 

    `;

    const { printData } = this.props;

    let { kStart, chkMetal, kCkdOem, chkNpdt, chkLtno, kNpdt } = this.props.idata;

    const listSize = printData.length;
    let idx = -1;
    let str = parseInt(kStart)-1;
    
    idx =idx + str;
    
    let vseq = 0;
    let remainChk = 0;
    let mainSearchedDivArr = []; 

    const mmToPx =(mm) => { let px = mm * 3.7795275591; return px; }

    const cardInfo_S = 
        printData ? printData.map((data, index) => {

        if(index === 0){
            vseq = parseInt(data.SEQ)===0?0: parseInt(data.SEQ)-1; // 바코드 표출 vseq용
        }

        // remain 표출 체크용 - remainChk -- 신규 품번의 vseq 담기
        remainChk = vseq;  // 새 품번의 remain 여부 체크하기

        let npqt = parseInt(data.NPQT); // 납품수량
        let qypc = parseInt(data.QYPC); // 용기당 수
        let stck = parseInt(data.STCK); // 부착면 수 

        // 추가 표시 컬러 체크 -- START
        let tmp25 = data.TMP25; // WRCLBL1	이중인쇄1 내용
        let tmp28 = data.TMP28; // WRCCOL1	이중인쇄1 컬러

        let tmp30 = data.TMP30; // WRCLBL2	이중인쇄2 내용
        let tmp33 = data.TMP33; // WRCCOL2	이중인쇄2 컬러

        let color1 = "";
        let color2 = "";

        let dualPrint1 = "F"; 
        let dualPrint2 = "F"; 
        
        if(tmp25!==""){

            dualPrint1 = "T";

            if(tmp28==="B"){ color1 = "blue";
            }else if(tmp28==="G"){ color1 = "green";
            }else if(tmp28==="R"){ color1 = "red";
            }else if(tmp28==="O"){ color1 = "#ff6600";
            }else if(tmp28==="X"){ color1 = "rgba(0, 0, 0, 0.7)"; }
            
        }
        if(tmp30!==""){

            dualPrint2 = "T";

            if(tmp33==="B"){ color2 = "blue";
            }else if(tmp33==="G"){ color2 = "green";
            }else if(tmp33==="R"){ color2 = "red";
            }else if(tmp33==="O"){ color2 = "#ff6600";
            }else if(tmp33==="X"){ color2 = "rgba(0, 0, 0, 0.7)"; }
            
        }


        let result = Math.floor(npqt / qypc); // 몫
        let resultIdx = Math.floor(npqt / qypc); // 목 + 나머지의 카운트
        let remain = npqt % qypc; // 나머지

        if(remain > 0){ resultIdx = result + 1; }

        const newSearched = [];
        let n = 0;
        
        Array.from(new Array(resultIdx), (i) =>{  // Main

            Array.from(new Array(stck), (w, j) =>{  // 부착면수 만큼 반복

                idx = idx+1;
                if(j===0){ // 부착면수 1일때
                    vseq = vseq + 1;    // 새 vseq 마다 + 1 증가 
                }

                let iCnt = i+1;
                const newArray = {}; // new Array 선언
                let jCnt = j+1;

                // 새 배열의 n 번째 구하기
                if(i===0 && j===0){ n=0; }else{ n=n+1; }

                Object.keys(data).forEach(key => { // 기존값 배열에 추가
                    newArray[key] = data[key];
                });

                newArray["idx"] = idx;
                newArray["vseq"] = vseq;
                
                //납품수량
                let newQty = 0;
                newArray["qty"] = qypc; 
                newQty = qypc; 

                newArray["stck"] = stck;

                newArray["endpage"] = "F"; 
                
                newArray["color1"] = color1; 
                newArray["color2"] = color2; 

                newArray["dualPrint1"] = dualPrint1; 
                newArray["dualPrint2"] = dualPrint2;
                
                if(resultIdx === (vseq-remainChk)){
                    if(remain > 0){
                        newArray["qty"] = remain; 
                        newQty = remain; 
                    }
                }else{
                    if(index===0 && resultIdx === 1){
                        if(remain > 0){
                            newArray["qty"] = remain; 
                            newQty = remain; 
                        }
                    }
                }

                // END Page 구하기
                if(listSize === (index+1)){   
                    if(resultIdx === iCnt){
                        if(stck === jCnt){
                            newArray["endpage"] = "T"; 
                        }
                    }
                }

                //LOTNO(3)
                const lotNo = data.TMP13===""?" ":Common.getLOT(data.TMP13); // LOT NO 날짜 가져오기  data.TMP13

                //일련번호(4)
                const prno = (data.TMP0===undefined?"":data.TMP0) + "" + ("0000" + parseInt(vseq, 10)).slice(-4);

                // DataMatrix - code128 바코드 값 만들기
                let barcodeValu = "";

                if(kCkdOem === "CKD"){// 중포장일때
                    // 바코드 = 업체코드(4)   + 품번(13)                       + 수량(6)                           + 납품일자(yymmdd 8)                      + 일련번호(4) 
                    // barcodeValu = data.TMP21 +""+ (data.TMP5).padEnd(13," ") +""+ ("000000" + newQty).slice(-6) + "" + ((data.TMP13).slice(-8)).replace(/-/g, '') + ""  + prno ;
                        
                    // 수량 6자리에서 7자리로 변경 2022-06-22
                    // 바코드 = 업체코드(4)              + 품번(13)                       + 수량(7)                                         + 납품일자(yymmdd 8)                      + 일련번호(4) 
                    // barcodeValu = data.TMP21 +""+ (data.TMP5.slice(0,13)).padEnd(13," ") +""+ ("0000000" + newQty).slice(-7) + "" + ((data.TMP13).slice(-8)).replace(/-/g, '') + ""  + prno ;
                
                    //검색조건에서 받아온 kNpdt=납품일자로 변경 2022-06-23
                    // 바코드 = 업체코드(4)              + 품번(13)                       + 수량(7)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4) 
                    //  barcodeValu = data.TMP21 +""+ (data.TMP5.slice(0,13)).padEnd(13," ") +""+ ("0000000" + newQty).slice(-7) + "" + ((kNpdt).replace(/-/g, '').slice(-6)) + ""  + prno ;
                    
                    // 수량 7 -> 6 으로 변경 셋팅 2022-06-30
                    // 바코드 = 업체코드(4)              + 품번(13)                       + 수량(6)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4) 
                    barcodeValu = data.TMP21 +""+ (data.TMP5.slice(0,14)).padEnd(14," ") +""+ ("00000000" + newQty).slice(-8) + "" + ((kNpdt).replace(/-/g, '').slice(-6)) + ""  + prno ;
                    
                }else{//용기일때
                    // 바코드 = 업체코드(4)   + 품번(13)                       + 수량(6)                           + 납품일자(yymmdd 8)                      + 일련번호(4) 
                    // barcodeValu = data.TMP22 +""+ (data.TMP5).padEnd(13," ") +""+ ("000000" + newQty).slice(-6) + "" + ((data.TMP13).slice(-8)).replace(/-/g, '') + ""  + prno ;
                                                
                    // 수량 6자리에서 7자리로 변경 2022-06-22
                    // 바코드 = 업체코드(4)              + 품번(13)                       + 수량(7)                                         + 납품일자(yymmdd 8)                      + 일련번호(4) 
                    // barcodeValu = data.TMP22 +""+ (data.TMP5.slice(0,13)).padEnd(13," ") +""+ ("0000000" + newQty).slice(-7) + "" + ((data.TMP13).slice(-8)).replace(/-/g, '') + ""  + prno ;
                    
                    //검색조건에서 받아온 kNpdt=납품일자로 변경 2022-06-23
                    // 바코드 = 업체코드(4)              + 품번(13)                       + 수량(7)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4) 
                    //  barcodeValu = data.TMP22 +""+ (data.TMP5.slice(0,13)).padEnd(13," ") +""+ ("0000000" + newQty).slice(-7) + "" + ((kNpdt).replace(/-/g, '').slice(-6)) + ""  + prno ;
                        
                    // 수량 7 -> 6 으로 변경 셋팅 2022-06-30
                    // 바코드 = 업체코드(4)              + 품번(13)                       + 수량(6)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4) 
                    // 수량 7 -> 6 으로 변경 셋팅 2022-06-30
                    // 바코드 = 업체코드(4)              + 품번(13)                       + 수량(6)                                 + 검색조건 납품일자(yymmdd 6)                      + 일련번호(4) 
                    barcodeValu = data.TMP22 +""+ (data.TMP5.slice(0,14)).padEnd(14," ") +""+ ("00000000" + newQty).slice(-8) + "" + ((kNpdt).replace(/-/g, '').slice(-6)) + ""  + prno ;
                }
                
                // DataMatrix Barcode 만들기 - code128
                const dataMatrixBarcode128 = <CustomDataMatrix val={barcodeValu} height={12} bcid={"code128"} scale={1.9} width={320}/>;
                newArray["barcode"] = dataMatrixBarcode128; 

                // DataMatrix Barcode 만들기  - dataMatrix
                const dataMatrixBarcode = (this.state.pathNm !== null) && (this.state.pathNm === "ROM4X") ? <CustomDataMatrix val={barcodeValu} height={17} scale={1} /> : "";
                newArray["barcodeMatrix"] = dataMatrixBarcode; 
                
                const dataMatrixBarcodeQR =  <QRCode size={150} viewBox={`0 0 350 350`} style={{ height: mmToPx(17), maxWidth:mmToPx(17), width: mmToPx(17) }} value={barcodeValu} level={'Q'}/>
                newArray["qrcode"] = dataMatrixBarcodeQR; 

                let serialNo =  ((kNpdt).replace(/-/g, '').slice(-6)) + ""  + prno ;
                const ptnm = (data.TMP6).slice(0,18);
                newArray["ptnm"] = ptnm; 

                newArray["lotno"] = lotNo;
                newArray["bno"] = barcodeValu;
                newArray["serialNo"] = serialNo;

                const posi = idx%6;                    
                newArray["posi"] = posi;                             
                newSearched[n] = newArray;
            })
        })
        mainSearchedDivArr.push(...newSearched)

    })
    :"";

    let mainSearchedDivArr2 = mainSearchedDivArr.filter(Boolean);

    let cardInfo = 
                <div className="forfor" style={{ display : "flex",flexWrap : "wrap", overflow : "hidden", width : mmToPx(280), marginLeft :mmToPx(10.5 + parseInt( ( Common.getToken("bplx") ? Common.getToken("bplx") : 0 ),10)) }}>
                    
                {Array.from(new Array(kStart-1), (v, i) =>{ 
                    return  <div className="start_from_test"  
                            style={{  marginTop :  i == 0 || i == 1 ? mmToPx(7.1 +  parseInt( ( Common.getToken("bply") ? Common.getToken("bply") : 0 ),10)) : "0"
                                    , height: mmToPx(65)
                                    , flexBasis : mmToPx(140) }}></div>
                                    }
                            )
                }
                {mainSearchedDivArr2.map((sData, dataIdx) => (
                    <div className="start_from" key={"WrapCkdLabelPrint_QR_B_NEW"+dataIdx}
                            style={{ marginTop :   sData.posi == 0 ||sData.posi == 1 ? mmToPx( 7.1 +  parseInt( ( Common.getToken("bply") ? Common.getToken("bply") : 0 ),10)) : "0"
                                    , height : mmToPx(65)
                                    , flexBasis: mmToPx(140) }}>

                    <div className="dep_div" style={{ overflow:"visible", zIndex:"200", position : "relative" , height : "100%", width : "100%"/* , opacity : "0" */ }}>
                        { sData.dualPrint1 === "T" ?
                                <div style={{ position:"absolute"
                                            , fontFamily: "HY견고딕" 
                                            , top:parseInt(sData.TMP27,10) + "mm"  
                                            , left:parseInt(sData.TMP26,10) + "mm" 
                                            , color:sData.color1
                                            , fontSize:sData.TMP29+"pt" 
                                            , fontWeight:"bold"
                                            , whiteSpace:"nowrap"
                                            , zIndex:"13"} }> {sData.TMP25} </div>
                            :"" }

                            { sData.dualPrint2 === "T" ?
                                <div style={{ position:"absolute" 
                                            , fontFamily: "HY견고딕" 
                                            , top:parseInt(sData.TMP32,10) + "mm" 
                                            , left:parseInt(sData.TMP31,10) + "mm" 
                                            , color:sData.color2 
                                            , fontSize:sData.TMP34+"pt" 
                                            , fontWeight:"bold" 
                                            , whiteSpace:"nowrap"
                                            , zIndex:"13"}}>{sData.TMP30}</div>
                            : "" }

                    <div className="row2" style={{ height : mmToPx(1)}}> <div className="cell"></div> </div>

                    <div className="row2" style={{height:mmToPx(5)}}>
                        <div className="cell" style={{width:mmToPx(67)}}></div>
                        <div className="cell ChosunBg cell_hori cell_veri" style={{width:mmToPx(27),fontSize:sData.TMP1.length>6?"9pt":"11pt",textAlign:"center",paddingTop:"4px"}}>{sData.TMP1}</div>{/* 지역 */}
                        <div className="cell ChosunBg cell_hori cell_veri" style={{width:mmToPx(46),fontSize:"9pt", letterSpacing:"0.02px",textAlign:"center",paddingTop:"4px"}}>
                            {sData.TMP0==="MPMC"?"현대파워텍(주)용ⓡ" : sData.TMP4==="MAMC"? "글로비스(주)용ⓡ" : "현대자동차(주)/기아(주)ⓡ"}
                        </div>
                    </div>

                    <div className="row2" style={{height:mmToPx(5)}}>
                        <div className="cell" style={{width:mmToPx(16)}}></div>
                        <div className="cell ChosunBg cell_hori cell_veri" style={{width:mmToPx(34),textAlign:"center",fontSize:"12pt"}}>{sData.TMP2}</div>{/* 공장 */}
                        <div className="cell" style={{width:mmToPx(17 )}}></div>
                        <div className="cell ChosunBg cell_hori cell_veri" style={{width:mmToPx(27),fontSize:"12pt",textAlign:"center"}}>{sData.TMP4}</div>{/* 납품장소 */}
                        <div className="cell" style={{width:mmToPx(10),textAlign:"center"}}></div>
                        <div className="cell" style={{width:mmToPx(25), marginTop : "1mm"}}>{sData.qrcode}</div>
                        <div className="cell" style={{width:mmToPx(11)}}></div>
                    </div>

                    <div className="row2" style={{height:mmToPx(5)}}>
                        <div className="cell ChosunGu12 cell_hori cell_veri" style={{width:mmToPx(16)}}></div>
                        <div className="cell ChosunBg cell_hori" style={{width:mmToPx(34),letterSpacing: "0px",textAlign:"center",fontSize:"12pt"}}>{sData.TMP3}</div>{/* 차종 */}
                        <div className="cell ChosunGu12" style={{width:mmToPx(17)}}></div>
                        <div className="cell ChosunBg cell_hori cell_veri" style={{width:mmToPx(27),fontSize:"12pt", textAlign:"center"}}>{sData.qty}</div>
                    </div> 

                    <div className="row2" style={{height:mmToPx(9)}}>
                        <div className="cell ChosunGu12" style={{width:mmToPx(20)}}></div>
                        <div className="cell ChosunBg cell_veri" style={{width:mmToPx(74),letterSpacing: "0px",textAlign:"left",fontSize:"16pt"}}>{sData.TMP5.substr(0, 5) + "-" + sData.TMP5.substr(5)}{/* 품번 */}</div>
                        <div className="cell" style={{width:mmToPx(46)}}></div>
                    </div> 

                    <div className="row2" style={{height:mmToPx(9)}}>
                        <div className="cell ChosunGu12" style={{width:mmToPx(20)}}></div>
                        <div className="cell ChosunBg cell_veri" style={{width:mmToPx(94),textAlign:"left",fontSize:sData.TMP6.length>18?"14pt":"16pt",marginTop:"2px"}}>{sData.TMP6}</div>
                        <div className="cell ChosunBg" style={{width:mmToPx(25),fontSize:"34pt", color:(sData.TMP19==="좌" || sData.TMP19==="L") ?"red":"blue", textAlign:"left", zIndex:"10",marginTop:"-5px"}}>{sData.TMP19}</div>{/* LH/RH */}
                        <div style={{width:mmToPx(1)}}></div>
                    </div> 


                    <div className="row2" style={{height:mmToPx(11)}}>
                        <div style={{width:mmToPx(2)}}></div>
                        <div className="cell ChosunBg cell_veri" style={{width:mmToPx(45),fontSize:"12pt"}}>G/W: {sData.TMP35}kg</div>
                        <div className="cell ChosunBg cell_veri" style={{width:mmToPx(44),fontSize:"12pt"}}>N/W: {sData.TMP20}kg</div>
                        <div className="cell ChosunBg cell_veri" style={{width:mmToPx(43),fontSize:"12pt"}}>{sData.serialNo}</div>
                        <div className="cell" style={{width:mmToPx(5)}}>
                            {chkMetal==="Y" ?
                                    <div style={{position : "absolute", marginLeft : "-13mm"}}>
                                        <img src ="/images/heavy_metal_confirm.gif" style={{width:"12mm"}} alt="heavy_metal_confirm" />
                                    </div>
                                :""} 
                        </div>
                    </div> 

                    <div className="row2" style={{height:mmToPx(5)}}>
                        <div className="cell ChosunBg cell_veri" style={{width:mmToPx(24)}}></div>
                        <div className="cell ChosunBg" style={{width:mmToPx(26),fontSize:"10pt"}}>{chkNpdt&&chkNpdt==="Y"?sData.TMP9:""}</div>{/* 납품일자 chkNpdt sData.TMP9=납기일자(원복) 2022-06-27 */}
                        <div className="cell" style={{width:mmToPx(17)}}></div>
                        <div className="cell" style={{width:mmToPx(4)}}></div>
                        {/* 체크 */}
                        <div className="cell ChosunBg cell_veri" style={{width:mmToPx(68),fontSize:"10pt"}}>{sData.TMP12} {sData.TMP10}</div>
                        <div style={{width:mmToPx(1)}}></div>
                    </div> 

                    <div className="row2" style={{height:mmToPx(5)}}>
                        <div className="cell ChosunBg cell_veri aligR" style={{width:mmToPx(20),fontSize:"10pt",textAlign:"center"}}>{kCkdOem==="CKD"?"Order":kCkdOem==="CKD2"?"From":""}</div>
                        <div className="cell" style={{width:mmToPx(4)}}></div>
                        <div className="cell ChosunBg cell_veri" style={{width:mmToPx(26),fontSize:"10pt"}}>{sData.TMP22}</div>{/* 공정NO*/}
                        <div className="cell ChosunBg" style={{width:mmToPx(17),fontSize:"21pt"}}>{sData.TMP36==="Y"?"RHD":""}</div>{/* RHD */}
                        <div className="cell" style={{width:mmToPx(4)}}></div>
                        <div className="cell ChosunBg" style={{width:mmToPx(69),fontSize:"18pt"}}>{sData.TMP15}</div>
                    </div> 

                    <div className="row2" style={{height:mmToPx(5)}}>
                        <div className="cell ChosunBg cell_veri aligR" style={{width:mmToPx(20),fontSize:"10pt",textAlign:"center"}}>{kCkdOem==="CKD"?"":kCkdOem==="CKD2"?"To":""}</div>
                        <div className="cell" style={{width:mmToPx(4)}}></div>
                        <div className="cell ChosunBg cell_veri cell_veri" style={{width:mmToPx(26),fontSize:"10pt"}}>{sData.TMP23}</div>{/* order*/}
                        <div className="cell ChosunBg cell_veri cell_hori" style={{width:mmToPx(17),fontSize:"10pt",textAlign:"center"}}>{chkLtno&&chkLtno==="Y"?sData.lotno:""}</div>{/* getLOT() chkLtno  */} 
                        <div className="cell" style={{width:mmToPx(73)/* ,fontSize:"18pt" */}}></div>        
                    </div> 

                    <div className="row2" style={{height:mmToPx(4)}}>
                        <div className="cell" style={{width:mmToPx(20)}}></div>
                        <div className="cell" style={{width:mmToPx(4)}}></div>
                        <div className="cell ChosunBg cell_veri" style={{width:mmToPx(70),fontSize:"8pt"}}>{sData.bno}</div>{/* bno*/}
                        <div className="cell" style={{width:mmToPx(10)}}></div>
                        <div className="cell ChosunBg cell_veri" style={{width:mmToPx(36),fontSize:"10pt"}}>{sData.TMP17}</div>{/* 용기 No */}   

                    </div> 

                </div>

                <div className="row2" style={{ height : mmToPx(1)}}> <div className="cell"></div> </div>

                {sData.posi===5 && sData.endpage==="F" ?<div className="page-break"></div> :""}

                </div>
            ),"")}
        </div>

    return <PrintStyleB key={"WrapCkdLabelPrint_QR_B_NEW"+this.props.SEQ}>{cardInfo}</PrintStyleB>;
    }
}
export default WrapCkdLabelPrint_QR_B_NEW;
