//라우터
import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import LoginPage from '@/pages/LoginPage';
import Dashboard from '@/pages/Dashboard/Dashboard';


//import RSY43 from '@/pages/C02/RSY43';


import MainLayout from '@/components/layout/MainLayout';


//납입지시
import A01 from './modules/A/A01';  //기초관리
import A02 from './modules/A/A02';  //ERP_OEM 출하관리
import A03 from './modules/A/A03';  //ERP_CKD 출하관리
import A04 from './modules/A/A04';  //시작차출하관리
import A05 from "./modules/A/A05"; // 납품서 발행현황
import A07 from "./modules/A/A07"; // 납품서 재고관리

//수신자료관리
import B01 from './modules/B/B01';  //기초관리
import B02 from "./modules/B/B02";  // 자재정보조회
import B03 from "./modules/B/B03";  // 자재정보조회
import B04 from './modules/B/B04';  //모듈단가자료조회
import B05 from './modules/B/B05';  //클레임
import B06 from './modules/B/B06';  //ERP_모듈정보조회

//시스템관리
import C01 from './modules/C/C01';
import C02 from './modules/C/C02';

//고객지원
import D01 from './modules/D/D01';


import RefusePage from '@/pages/RefusePage';
import NotFoundPage from "@/pages/NotFoundPage";



const Router = () => {
    const jwtToken = sessionStorage.getItem("token");
    const currPath = window.location.pathname;

    return (
        <>
            {!jwtToken ? <Redirect to="/login" /> : <Redirect to={currPath} />}

            <Switch>
                <Route path='/login' component={LoginPage} />
                <MainLayout currPath={currPath}>
                    <Switch>
                        <Route exact path='/multiIp'>
                            <RefusePage message="동일한 아이디로 이미 로그인 되어 있습니다."/>
                        </Route>
                        <Route exact path='/unauthorized'>
                            <RefusePage message="유효하지 않은 인증입니다."/>
                        </Route>
                        <Route exact path='/' component={Dashboard} />
                        <Route>
                            <A01 />
                            <A02 />
                            <A03 />
                            <A04 />
                            <A05 />
                            <A07 />
                            <B01 />
                            <B02 />
                            <B03 />
                            <B04 />
                            <B05 />
                            <B06 />
                            <C01 />
                            <C02 />
                            <D01 />
                            <Route path='/*' component={NotFoundPage} />
                        </Route>
                    </Switch>
                </MainLayout>
            </Switch>
        </>
    )
};

export default Router;
