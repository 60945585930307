/**
 * @Page Dashboard
 * @Description [Dashboard] Main portal PAGE
 **/
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import MainTemplates from '@/components/templates/MainTemplates';
import Ribbon from '@/components/organisms/Ribbon';
import { BrowserRouter as Link } from 'react-router-dom';
import '@/css/tooltip_style.css'

// import Content from '@/components/organisms/Content';
// import Grid from '@/components/organisms/Grid';
import Grid from '@/components/grid/agGrid';
import CustomTooltip  from '@/components/grid/customTooltip';

import CustomChart from '@/components/atoms/CustomChart';
import ButtonSet from '@/components/organisms/ButtonSet';
// import Ribbon from '@/components/organisms/Ribbon';
import * as Common from '@/utils/Common'; 
import CommonUtil from '@/utils/CommonUtil'; 
import Popup from '@/pages/Popup/Popup';    
import HomeIcon from '@mui/icons-material/Home';
const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const CODES = require('@/config/commonCode.json');
const Utils = new CommonUtil();


function formattedDate(date){
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();
    var hh = date.getHours();
  
    return [date.getFullYear(),
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd,
            (hh>9 ? '' : '0') + hh
           ].join('');
  }
 let cnt =0 ; // 팝업 띄울때 렌더링 2번 체크용
let t = 0;
function Dashboard() {
    const history = useHistory();
    const token = Common.getToken();
    const userId = Common.getToken("id");
    const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    // const tComp = Common.getToken("comp");   

    const grdNoticeMain = useRef();   //Grid Main
    const grdNotice2Main = useRef();   //Grid Main
    const grdNotice3Main = useRef();   //Grid Main
    const grdNotice4Main = useRef();   //Grid Main

    let [clearGrid, setClearGrid] = useState(false);  
    let [searchApi, setSearchApi] = useState(false); 
    const [searchApi2, setSearchApi2] = useState(false); 
    const [searchApi3, setSearchApi3] = useState(false); 
    const [searchApi4, setSearchApi4] = useState(false); 
    let [headerDayList, setHeaderDayList] = useState([]);

    const [typeOpen, setTypeOpen] = React.useState(false); // 생산현황 등록 팝업
    const [typeOpen2, setTypeOpen2] = React.useState(); // 팝업 리스트 팝업

    const [popupList, setPopupList] = React.useState([]); // 팝업 리스트 
    const [data, setDatas] = useState([]); //Data for Popup    

    const [closeSeq, setCloseSeq] = useState([]); 


    const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 

    //Data
    const [idata, setData] = useState({
        //조회조건
        kVnt1 : ckVnt1,        //T1업체     
        kSval : "",
        kSear : ""
    });

    const onSearch = () => { // 자료생성현황
        let para = `{"vnt1": "${idata.kVnt1}"}`;
        let param = encodeURI(para);
        const query = SERVER_URL + "/main/search1?query=" + param;
        setSearchApi(query); // API
    }
    const onSearch2 = () => { // 공통코드 조회 onSearch3 Function
        const query = SERVER_URL + "/main/notice";
        setSearchApi2(query); // API
    }

    const onSearch3 = () => { // faq
        const query = SERVER_URL + "/main/faq";
        setSearchApi3(query); // API
    }
    
    const onSearch4 = () => { // qna
        const query = SERVER_URL + "/main/qna";
        setSearchApi4(query); // API
    }

    const onSearchWait = () => { // 공통코드 조회 onSearchWait Function
        //alert("준비중입니다");
        setTypeOpen(true);
    }

    const onSearchLinkA = () => { // 공통코드 조회 onSearchLink Function
        history.push("/A01/REP02");
    }
    const onSearchLinkB = () => { // 공통코드 조회 onSearchLink Function
        history.push("/B01/REP01");
    }

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
        // setInsertApi(false);
        // setUpdateApi(false);
        // setDeleteApi(false);
    }, []);

    const onChangeApi = useCallback(async (e) => {
        setSearchApi(false);
    }, []);

    const onChangeApi2 = useCallback(async (e) => {
        setSearchApi2(false);
    }, []);

    const onChangeApi3 = useCallback(async (e) => {
        setSearchApi3(false);
    }, []);
    const onChangeApi4 = useCallback(async (e) => {
        setSearchApi4(false);
    }, []);
      

    const onSearchPopup = () => { // 팝업 리스트 조회
        const fetchData = async () => {

            let para = `{"wrclocation":"M"}`;  //팝업 위치여부 하드코딩 (M: 메인화면, L: 로그인화면))
            let param = encodeURIComponent(para);

            const query = SERVER_URL + "/wrtpopup/contents?query=" + param; 
            
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())                
                .then((data) => {                  
                    if (data.success) { 
                        setPopupList(data.data); 
                    }
                    if (data.error) {                   
                        setPopupList(null);
                    }
                })
                .catch(error => { // 에러 응답 처리
                    if (error) {
                        setPopupList(null);
                    }
                });
        }
        fetchData();        
    }

    useEffect(onSearchPopup,[]); // Top Notice 값이 변경되면 재조회 할 수 있도록 !! 

    useEffect(() => {    
        
        const today = new Date();
        var formatToday = formattedDate(today); // 오늘 날짜 포맷 : ex)2022102812 : yyyymmdd24hh

        const allKeys = Object.keys(localStorage);
        var keysLength = allKeys.length;
        
        for(var i = 0; i < keysLength; i++){  // localstorage에 저장된 value 중 오늘보다 이전일 경우 삭제 
            if(localStorage.getItem(allKeys[i]) <= formatToday){             
                localStorage.removeItem(allKeys[i]);
            }
        }

        if(popupList != "" && popupList != undefined && popupList != ""){     
            let data = [];       
                popupList.map((row,i)=>{
                    if(localStorage.getItem(popupList[i].wrcseqn) == null){
                        let detail = {};
                            detail["wrcseqn"] = popupList[i].wrcseqn;
                            detail["fileId"] = popupList[i].fileId;
                            detail["wrclink"] = popupList[i].wrclink;
                            detail["wrccont"] = popupList[i].wrccont;                
                            detail["wrctype"] = popupList[i].wrctype;
                            detail["wrcperiod"] = popupList[i].wrcperiod;                
                            detail["wrccontsize"] = popupList[i].wrccontsize;
                            data.push(detail);
                        }
                })
            
                setDatas({ data });
                setTypeOpen2(true);
        }


        /* if(popupList != "" && popupList != undefined && popupList != ""){     
            let data = [];          

            if(localStorage.getItem(popupList[0].wrcseqn) == null){
                popupList.map((row,i)=>{
                    let detail = {};
                    detail["wrcseqn"] = popupList[i].wrcseqn;
                    detail["fileId"] = popupList[i].fileId;
                    detail["wrclink"] = popupList[i].wrclink;
                    detail["wrccont"] = popupList[i].wrccont;                
                    detail["wrctype"] = popupList[i].wrctype;
                    detail["wrcperiod"] = popupList[i].wrcperiod;                
                    detail["wrccontsize"] = popupList[i].wrccontsize;
                    data.push(detail);
                })
                
                 setDatas({ data });
                 setTypeOpen2(true);
            }
            else{
                setTypeOpen2(false);
            }
        } */
                        
        /* 변경 전 기본소스
         if(popupList != "" && popupList != undefined && popupList != ""){                                     
            if(localStorage.getItem(popupList[0].wrcseqn) == null){
                let data = [];                
                data["wrcseqn"] = popupList[0].wrcseqn;
                data["fileId"] = popupList[0].fileId;
                data["wrclink"] = popupList[0].wrclink;
                data["wrccont"] = popupList[0].wrccont;                
                data["wrctype"] = popupList[0].wrctype;
                data["wrcperiod"] = popupList[0].wrcperiod;                
                data["wrccontsize"] = popupList[0].wrccontsize;
                setDatas({ ...data });            
                setTypeOpen2(true);
            }
            else{
                setTypeOpen2(false);
            }
                
        } */
    },[popupList]); // Top Notice 값이 변경되면 재조회 할 수 있도록 !! 

    //const headerDayList=[];

    // (최초실행) - 0. 화면 오픈시 실행 
    useEffect(() => {
        onSearch();  //자료 생성현황
        onSearch2(); //공지사항
        onSearch3(); //FAQ         
        onSearch4(); //Q&A            
        //컬럼 헤더 정리
        let now = new Date();
        var dayList={};
        for (var i = 0; i < 7; i++) {
            dayList[i] = now.toISOString().substring(5,7)+"월"+now.toISOString().substring(8,10)+"일";
            now.setDate(now.getDate() - 1);
        }
        setHeaderDayList(dayList);

        // 빈창을 열고 내용을 동적으로 작성
        // var win = window.open("", "PopupWin0", "width=500,height=600");
        // win.document.write("<p>새창에 표시될 내용 입니다.</p>");

        // 사이트에서 팝업 및 리디렉션 허용
        // 컴퓨터에서 Chrome. 을 엽니다.
        // 팝업이 차단된 페이지로 이동합니다.
        // 주소 표시줄에서 팝업 차단됨 을 클릭합니다.
        // 표시할 팝업의 링크를 클릭합니다.
        // 사이트의 팝업을 항상 표시하려면 [사이트]에서 팝업 및 리디렉션을 항상 허용 완료를 선택합니다.


              
        //이미지 팝업안내 - Try - 로그인 화면 / 대시보드 화면 구분 필요
        // var win = window.open("", "PopupWin999", "toolbar=no,location=no,statusbar=no,status=no,titlebar=no,menubar=no,scrollbars=1,resizable=no,width=540,height=720,top=30", false);
        // win.title="안내팝업"; 
        // // win.document.write("<img src='http://new.rapdos.co.kr/images/rapdos_notice_20221011.png' alt='안내팝업' style='width: 520;height: 700;'/>");
        // win.document.write("<img src='http://new.rapdos.co.kr/file/notice/221011114843814_notice_rapdos_notice_20221011.png' alt='안내팝업' style='width: 520;height: 700;'/>");
        // win.focus();

//         setTimeout(() => 
//         alert(`안녕하세요
// 금일 운영서버 긴급점검으로
//  17:00 ~ 18:00 (오후5시~ 오후6시)
// 해당 사이트(신사이트) 사용이 어렵습니다\n
// 기존 구사이트를 이용해주십시요 (www.rapdos.co.kr)\n
// 점검이 완료되면 해당 팝업알림을 내리도록 하겠습니다
// 이 점 유의하시고 사용해주십시오`)
//         , 500);

        
    }, []); // (최초실행) - 1. vnt1 공통코드 호출  2. onSearch2() 납품서발행현황 조회 


    const editCellClicked = (params) => {//qna row click
        let data = params.data;
        if (params.column.colId === "replyYn") {
            if (data.latestReply !== 0 && data.latestReply !== "") {
                history.push("/D01/RSY51/" + data.latestReply);
            }
        } else {
            if (data.seq !== 0 && data.seq !== "") {
                history.push("/D01/RSY51/" + data.seq);
            }
        }
    };

    let chartData = [

        { /* type : "monotone", */ dataKey : "pv" , name : "OEM납품서", color : "rgb(119, 198, 33)", groupId : "a" },
        { /* type : "monotone", */ dataKey : "uv" , name : "OEM식별표", color : "rgb(88, 44, 131)", groupId : "a" },
        { /* type : "monotone", */ dataKey : "ab" , name : "CKD납품서", color : "rgb(224, 41, 37)", groupId : "a" },
        { /* type : "monotone", */ dataKey : "cd" , name : "CKD식별표", color : "rgb(109, 215, 232)", groupId : "a" }
    ]

    
    /* 자료 생성현황 */
    const columnDefs = [ 
        { headerName: "구분", field: "gubn", sortable: true, editable: false, filter: true, flex: 1, minWidth: 100, maxWidth: 150, cellClass: ["nocheckbox", "alignC"] },
        { headerName: "자료", field: "wrcdcgb", sortable: true, editable: false, filter: true, flex: 1, minWidth: 100, maxWidth: 150, cellClass: ["nocheckbox", "alignC"] /* , minWidth: 150 */ },
        { headerName: "자료명", field: "wrcname", sortable: true, editable: false, filter: true, flex: 1, cellClass: ["nocheckbox", "alignC"] /* , minWidth: 150 */ },
        { headerName: headerDayList[0], field: "pcnt0", sortable: true, editable: false, filter: true, flex: 1, minWidth: 100, tooltipField: 'tooltip0',cellStyle: Utils.underLineCellStyle, cellClass: ["nocheckbox", "alignC"] },
        { headerName: headerDayList[1], field: "pcnt1", sortable: true, editable: false, filter: true, flex: 1, minWidth: 100, tooltipField: 'tooltip1',cellStyle: Utils.underLineCellStyle, cellClass: ["nocheckbox", "alignC"] },
        { headerName: headerDayList[2], field: "pcnt2", sortable: true, editable: false, filter: true, flex: 1, minWidth: 100, tooltipField: 'tooltip2',cellStyle: Utils.underLineCellStyle, cellClass: ["nocheckbox", "alignC"] },
        { headerName: headerDayList[3], field: "pcnt3", sortable: true, editable: false, filter: true, flex: 1, minWidth: 100, tooltipField: 'tooltip3',cellStyle: Utils.underLineCellStyle, cellClass: ["nocheckbox", "alignC"] },
        { headerName: headerDayList[4], field: "pcnt4", sortable: true, editable: false, filter: true, flex: 1, minWidth: 100, tooltipField: 'tooltip4',cellStyle: Utils.underLineCellStyle, cellClass: ["nocheckbox", "alignC"] },
        { headerName: headerDayList[5], field: "pcnt5", sortable: true, editable: false, filter: true, flex: 1, minWidth: 100, tooltipField: 'tooltip5',cellStyle: Utils.underLineCellStyle, cellClass: ["nocheckbox", "alignC"] },
        { headerName: headerDayList[6], field: "pcnt6", sortable: true, editable: false, filter: true, flex: 1, minWidth: 100, tooltipField: 'tooltip6',cellStyle: Utils.underLineCellStyle, cellClass: ["nocheckbox", "alignC"] },
        { headerName: "마지막 생성일", field: "lsdt", sortable: true, editable: false, filter: true, flex: 1, minWidth: 120, valueFormatter: Utils.dateFormatter, cellClass: ["nocheckbox", "alignC"] /* , minWidth: 150 */ }
    ];

    /* 공지사항 */
    const columnDefs2 = [
        { headerName: "No", field: "seq", sortable: false, editable: false, filter: false, flex: 1, minWidth: 60, maxWidth: 80, cellClass: ["nocheckbox", "alignC", "onclick"] },
        { headerName: "제목", field: "titl", sortable: false, editable: false, filter: true, flex: 1, cellClass: ["nocheckbox", "alignL", "onclick"] /* , minWidth: 150 */ },
        { headerName: "날짜", field: "idat", sortable: false, editable: false, filter: true, flex: 1, minWidth: 120, maxWidth: 150, valueFormatter: Utils.dateFormatter, cellClass: ["nocheckbox", "alignC", "onclick"] /* , minWidth: 150 */ }
    ];

    /* FAQ */ 
    const columnDefs3 = [
        { headerName: "No", field: "seq", sortable: false, editable: false, filter: false, flex: 1, minWidth: 60, maxWidth: 80, cellClass: ["nocheckbox", "alignC", "onclick"]  },
        { headerName: "제목", field: "titl", sortable: false, editable: false, filter: true, flex: 1, cellClass: ["nocheckbox", "alignL", "onclick"] },
    ];

    /* Q&A */ 
    const columnDefs4 = [
        { headerName: "No", field: "seq", sortable: false, editable: false, filter: false, flex: 1, minWidth: 60, maxWidth: 80, cellClass: ["nocheckbox", "alignC", "onclick"], onCellClicked: editCellClicked  },
        { headerName: "제목", field: "titl", sortable: false, editable: false, filter: true, flex: 1, cellClass: ["nocheckbox", "alignL", "onclick"], onCellClicked: editCellClicked },
        { headerName: "글쓴이", field: "usnm", sortable: false, editable: false, filter: true, flex: 1, minWidth: 120, maxWidth: 150, cellClass: ["nocheckbox", "alignC", "onclick"], onCellClicked: editCellClicked },
        { headerName: "답글", field: "replyYn", sortable: false, editable: false, filter: true, flex: 1, minWidth: 120, maxWidth: 150, cellClass: ["nocheckbox", "alignC", "onclick"], minWidth: 70, onCellClicked: editCellClicked
            , cellStyle: params => { 
                // return {color: "#3f51b5"}; 
                return {color: "#8d21ff"}; 
            }
        }
    ];

    const dataList = [ // ▶ 차트 데이터 샘플 ▶
        {name: '07-27', uv: 4000, pv: 2400, ab: 2400, cd: 2400},
        {name: '07-28', uv: 3000, pv: 1398, ab: 3400, cd: 2400},
        {name: '07-29', uv: 2000, pv: 9800, ab: 2400, cd: 2500},
        {name: '07-30', uv: 2780, pv: 3908, ab: 4400, cd: 2600},
        {name: '07-31', uv: 1890, pv: 4800, ab: 400, cd: 2700},
        {name: '08-01', uv: 1890, pv: 4800, ab: 5400, cd: 2800},
        {name: '08-02', uv: 1890, pv: 4800, ab: 4400, cd: 2900}
    ];
    

    const grdNoticeMain_onRowClicked = async (e) => {
    }

    const grdNotice2Main_onRowClicked = async (e) => {
        let data = e.data;
        e.data.numb = e.data.seq
        history.push("/D01/RSY50/"+e.data.seq);


        // if(navGubn !=history.location.pathname.substring(1,2) ){
        //     navGubn = history.location.pathname.substring(1,2)
        // }
        
    }

    const grdNotice3Main_onRowClicked = async (e) => {//FAQ
        let data = e.data;
        e.data.numb = e.data.seq
        history.push("/D01/RSY52/"+e.data.seq);

    }

    const grdNotice4Main_onRowClicked = async (e) => {//QNA
        let data = e.data;
        e.data.numb = e.data.seq
        history.push("/D01/RSY51/"+e.data.seq);
    }

    const custClose = (e,gubn) => {

        if(gubn){
            setTypeOpen2(false);
            return
        }
        let seqid = e.toString();

        let newSeqid = closeSeq;
        newSeqid.push(seqid)

        setCloseSeq([...newSeqid]);

    }
    
    return(
        
        <>
            {/* <h2>Dashboard==================================================================</h2> */}
            <MainTemplates>
                {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
                <Ribbon
                    onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                    // onSearch={onSearchLinkA}  onSearchName={"납입지시 생성"}
                    onAddRow={onSearchLinkB}  onAddRowName={"자료 생성"}
                    onInsert={onSearchWait}  onInsertName={"생성현황 설정"}
                >
                    
                    <div style={{float: "left",marginTop: "2px"}}><HomeIcon/> </div>  <b style={{ color: "#33353b", fontSize:"10pt",fontFamily: "'PretendardLight', NanumGothic, sans-serif", marginLeft:"5px", marginRight:"10px" }}> {">"} 대시보드</b>
                </Ribbon>
                <Popup type="PopupMngPopup" open={typeOpen2} onClose={custClose} closeSeqid = {closeSeq} /* onClose={()=> { console.log("언제타냐"); setTypeOpen2(false)}} */ data = {data.data}/>  
                <div style={{height : "100%",width : "100%", /* padding : "10px",  */background : "rgb(249, 249, 249)"}}>

                <div style={{ width: "100%", height: "425px", float : "left"  ,padding : "10px 10px 5px 10px" }} >
                    <div style={{ width: "100%" , height : "100%" , /* background : "#fff", */ /* padding : "12px" */}}>
                        <span style={{ fontFamily: "'PretendardMedium','NanumGothic', sans-serif", fontSize : "21px", paddingLeft:"10px",color:"#1c1e25" }}> 생성현황 </span> &nbsp;
                            <div className="tit_section" style={{ border : "none", height : "10px", background : "none" , borderBottom: "1px solid #BFBDCD"}}></div>
                                <div className="grid_cont_box" style={{ height: "calc(100% - 37px)" }}>
                                    <Grid
                                        ref={grdNoticeMain}
                                        gridId={"NOTICE"}                    //  (필수) Grid Id 정의
                                        columnDefs={columnDefs}             //  (필수) 컬럼 정의 
                                        pagination={false}                  //  쪽수 매기기 여부
                                        paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                        paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                        rowMultiSelectWithClick={false}      //  멀티 클릭 가능 여부
                                        suppressRowClickSelection={false}   //  클릭으로 선택 하는것을 방지
                                        rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                        enableRangeSelection={false}        //  
                                        enableCellTextSelection={false}      // cell selection drag mode
                                        isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                        displayNo = {false}
                                        searchApi={searchApi} onClearApi={onChangeApi}    //  조회 / 초기화
                                        clearGrid={clearGrid}
                                        onClearGridFlag={setClearGrid}
                                        // onCellValueChangedEvent={grdNotice2Main_onCellValueChanged}
                                        onRowClickEvent={grdNoticeMain_onRowClicked}
                                        //onCellKeyDownEvent ={grdNotice2Main_onCellKeyDown}
                                        // insertApi={insertApi}               // 등록
                                        // insertParam={inserts}               // 등록 및 수정시 Parmeter
                                        // insertRefresh={()=> {onSearch(); onSearch2();} } // 등록이후 
                                        //updateApi={updateApi}               // 수정
                                        //deleteApi={deleteApi}               // 삭제
                                        //updateRefresh={onSearch}            // 수정이후 
                                        //deleteRefresh={onSearch}            // 삭제이후 
                                        headerHeight={35}
                                        rowHeight={34}                                            
                                    />
                                </div>
                            </div>
                </div>

                <div style={{ width: "35%", height: "50%", float : "left"}} >
                    <div style={{ width: "100%" , height : "100%", paddingTop:"10px"}}>
                    <a href="/D01/RSY50"><span style={{ fontFamily: "'PretendardMedium', 'NanumGothic', sans-serif", color:"#1c1e25",fontSize : "21px", paddingLeft:"21px" }}> 공지사항 </span> &nbsp;</a>
                    <a href="/D01/RSY50"><span style={{ float:"right",fontFamily: "'PretendardMedium', 'NanumGothic', sans-serif", color:"#0057f4",fontSize : "14px",marginRight: "14px" }}> + 전체보기 </span></a>
                            <div className="tit_section" style={{ border : "none", height : "10px", background : "none"}}></div>
                                <div className={"cont_section"} style={{ height: "calc(100vh - 60px - 50px - 146px)" }}>
                                    <div className="grid_cont_box" style={{ height: "calc(100% - 77px - 261px)", width:"100%" }}>
                                        <Grid
                                            ref={grdNotice2Main}
                                            gridId={"NOTICE2"}                    //  (필수) Grid Id 정의
                                            columnDefs={columnDefs2}             //  (필수) 컬럼 정의 
                                            pagination={false}                  //  쪽수 매기기 여부
                                            paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                            paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                            rowMultiSelectWithClick={false}      //  멀티 클릭 가능 여부
                                            suppressRowClickSelection={false}   //  클릭으로 선택 하는것을 방지
                                            rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                            enableRangeSelection={false}        //  
                                            enableCellTextSelection={false}      // cell selection drag mode
                                            isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                            displayNo = {false}
                                            searchApi={searchApi2} onClearApi={onChangeApi2}    //  조회 / 초기화
                                            clearGrid={clearGrid}
                                            onClearGridFlag={setClearGrid}
                                            // onCellValueChangedEvent={grdNotice2Main_onCellValueChanged}
                                            onRowClickEvent={grdNotice2Main_onRowClicked}
                                            //onCellKeyDownEvent ={grdNotice2Main_onCellKeyDown}
                                            // insertApi={insertApi}               // 등록
                                            // insertParam={inserts}               // 등록 및 수정시 Parmeter
                                            // insertRefresh={()=> {onSearch(); onSearch2();} } // 등록이후 
                                            //updateApi={updateApi}               // 수정
                                            //deleteApi={deleteApi}               // 삭제
                                            //updateRefresh={onSearch}            // 수정이후 
                                            //deleteRefresh={onSearch}            // 삭제이후 
                                            headerHeight={30}
                                            rowHeight={30}                                            
                                        />
                                    </div>                                        
                                </div>
                            </div>
                </div>

                <div style={{ width: "33%", height: "50%", float : "left" /*,padding : "5px 10px 10px 5px"*/ /* ,border : "3px solid blue" */ , /* , margin : "8px" */ }} >
                    <div style={{ width: "100%" , height : "100%", paddingTop:"10px", /* background : "#fff", */ /* padding : "12px" */}}>
                    <a href="/D01/RSY52"><span style={{ fontFamily: "'PretendardMedium', 'NanumGothic', sans-serif", color:"#1c1e25",fontSize : "21px", paddingLeft:"20px" }}> {"FAQ"} </span> &nbsp;</a>
                    <a href="/D01/RSY52"><span style={{ float:"right",fontFamily: "'PretendardMedium', 'NanumGothic', sans-serif", color:"#0057f4",fontSize : "14px",marginRight: "14px" }}> + 전체보기 </span></a>
                            <div className="tit_section" style={{ border : "none", height : "10px", background : "none" }}></div>
                                <div style={{height : "calc(100% - 77px)"  }}> 

                                <div className={"cont_section"} style={{ height: "calc(100vh - 60px - 50px - 157px)" }}>

                                    <div className="grid_cont_box" style={{ height: "calc(100% - 77px - 250px)", width:"100%" }}>
                                        <Grid
                                            ref={grdNotice3Main}
                                            gridId={"NOTICE3"}                    //  (필수) Grid Id 정의
                                            columnDefs={columnDefs3}             //  (필수) 컬럼 정의 
                                            pagination={false}                  //  쪽수 매기기 여부
                                            paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                            paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                            rowMultiSelectWithClick={false}      //  멀티 클릭 가능 여부
                                            suppressRowClickSelection={false}   //  클릭으로 선택 하는것을 방지
                                            rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                            enableRangeSelection={false}        //  
                                            enableCellTextSelection={false}      // cell selection drag mode
                                            isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                            displayNo = {false}
                                            searchApi={searchApi3} onClearApi={onChangeApi3}    //  조회 / 초기화
                                            clearGrid={clearGrid}
                                            onClearGridFlag={setClearGrid}
                                            // onCellValueChangedEvent={grdNotice2Main_onCellValueChanged}
                                            onRowClickEvent={grdNotice3Main_onRowClicked}
                                            //onCellKeyDownEvent ={grdNotice2Main_onCellKeyDown}
                                            // insertApi={insertApi}               // 등록
                                            // insertParam={inserts}               // 등록 및 수정시 Parmeter
                                            // insertRefresh={()=> {onSearch(); onSearch2();} } // 등록이후 
                                            //updateApi={updateApi}               // 수정
                                            //deleteApi={deleteApi}               // 삭제
                                            //updateRefresh={onSearch}            // 수정이후 
                                            //deleteRefresh={onSearch}            // 삭제이후                                     
                                            headerHeight={30}
                                            rowHeight={30}                                            
                                        />
                                    </div>
                                    </div>
                                </div>
                    </div>
                </div>

                <div style={{ width: "32%", height: "50%", float : "right" /*,padding : "5px 10px 10px 5px"*/ /* ,border : "3px solid blue" */ , /* , margin : "8px" */ }} >
                    <div style={{ width: "100%" , height : "100%", paddingTop:"10px", /* background : "#fff", */ /* padding : "12px" */}}>
                    <a href="/D01/RSY51"><span style={{ fontFamily: "'PretendardMedium', 'NanumGothic', sans-serif", color:"#1c1e25",fontSize : "21px", paddingLeft:"20px" }}> {"Q&A"} </span> &nbsp;</a>
                    <a href="/D01/RSY51"><span style={{ float:"right",fontFamily: "'PretendardMedium', 'NanumGothic', sans-serif", color:"#0057f4",fontSize : "14px",marginRight: "14px" }}> + 전체보기 </span></a>
                            <div className="tit_section" style={{ border : "none", height : "10px", background : "none" }}></div>
                                <div style={{height : "calc(100% - 77px)"  }}> 

                                <div className={"cont_section"} style={{ height: "calc(100vh - 60px - 50px - 157px)" }}>

                                    <div className="grid_cont_box" style={{ height: "calc(100% - 77px - 250px)", width:"100%" }}>
                                        <Grid
                                            ref={grdNotice4Main}
                                            gridId={"NOTICE4"}                    //  (필수) Grid Id 정의
                                            columnDefs={columnDefs4}             //  (필수) 컬럼 정의 
                                            pagination={false}                  //  쪽수 매기기 여부
                                            paginationCsv={true}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                            paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                                            rowMultiSelectWithClick={false}      //  멀티 클릭 가능 여부
                                            suppressRowClickSelection={false}   //  클릭으로 선택 하는것을 방지
                                            rowSelection={"single"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                            enableRangeSelection={false}        //  
                                            enableCellTextSelection={false}      // cell selection drag mode
                                            isFirst={false}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                            displayNo = {false}
                                            searchApi={searchApi4} onClearApi={onChangeApi4}    //  조회 / 초기화
                                            clearGrid={clearGrid}
                                            onClearGridFlag={setClearGrid}
                                            // onCellValueChangedEvent={grdNotice2Main_onCellValueChanged}
                                            onRowClickEvent={grdNotice4Main_onRowClicked}
                                            //onCellKeyDownEvent ={grdNotice2Main_onCellKeyDown}
                                            // insertApi={insertApi}               // 등록
                                            // insertParam={inserts}               // 등록 및 수정시 Parmeter
                                            // insertRefresh={()=> {onSearch(); onSearch2();} } // 등록이후 
                                            //updateApi={updateApi}               // 수정
                                            //deleteApi={deleteApi}               // 삭제
                                            //updateRefresh={onSearch}            // 수정이후 
                                            //deleteRefresh={onSearch}            // 삭제이후                                     
                                            headerHeight={30}
                                            rowHeight={30}                                            
                                        />
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>

                
                
                </div>
            </MainTemplates>
        </>
    )
}

export default Dashboard;