/**
 * @Page ROM4A
 * @Description [ROM4A]납입지시 - ERP_OEM출하관리 - 용기당수량관리
 **/
 import React, { useState, useEffect, useRef, useCallback  } from "react";
 import ReactDOM from "react-dom";
 import Ribbon from '@/components/organisms/Ribbon';
 import GridRibbon from "@/components/organisms/GridRibbon";
 import ContentXearch from '@/components/organisms/ContentXearch';
 import Content from '@/components/organisms/Content';
 import Grid from '@/components/grid/agGrid';
 import * as GridUtil from '@/components/grid/agGrid';
 import CustomAlert from "@/components/molecules/CustomAlert";
 import AlertDialog from '@/components/material-UI/AlertDialog';
 import * as Common from '@/utils/Common'; 
 import CommonUtil from '@/utils/CommonUtil'; 
  
 const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
 const CODES = require('@/config/commonCode.json');
 const Utils = new CommonUtil();

 
 function ROM4A() {
 
    const token = Common.getToken();
    const userId = Common.getToken("id");
    const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    const tComp = Common.getToken("comp");
    const grdRom4aMain = useRef();   //Grid Main

    //Data
    const [idata, setData] = useState({
        //조회조건
        kVnt1 : tVnt1,      //T1업체
        kComp: tComp,       //회사구분
        kPlnt: "",          //공장
        kGate: "",          //하치장
        kPtno : "",         //품번
        kEmgubn :"1",// 품번사용여부
        kVnyn:"", //용기당수량 사용여부
        
        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장
        gateList : [],  //하치장
    }); 
    
         
    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;
  
  
    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      // Dialog Transaction(I,U,D)
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;
  
    // Params (Insert, Update Parameter)
    let [inserts, setInsert] = useState({}); 

    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    let [clearGrid, setClearGrid] = useState(false);  
    let [updateApi, setUpdateApi] = useState(false); 
    
    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 100px  - 50px)"});
    
    
    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
    }, []);


    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData(para, name);
    }

    /**
     * 식별표 타입 조회
     * @param {*} para 
     * @param {*} name 
     */
    const onSearchCommon1 = (para, name) => {
        let commonData = idata;

        const fetchData1 = async (para, name) => {

            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search1?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success) 
                    { 
                        commonData["kPlnt"] = data.data[0].comCode
                        commonData["kPlntNm"] = data.data[0].text
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        fetchData1(para, name);
    }


    
    /**
     *MOUNT 
    */
    useEffect( () => {
        
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
        //공통코드 조회 (회사구분)
        onSearchCommon(`{"pgmid":"CODE", "vand":"****", "gocd":"COMP"}`, "compList");
        //공통코드 조회 (공장)
        onSearchCommon1(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":""}`, "plntList");
        
    }, []);
  
  
  
    /**
     * Control 변경 Event
     * @param {*} e 
     */
    const onChange = async (e) => {
        const { name, value, desc } = e.target;

        let newSearchs = idata; 
        newSearchs[name] = value;     

        setData({ ...newSearchs });
        if (name === "kPlnt") {
            if(idata.kGubn===""){
                setMessage({ openAlert : true, 
                    severity : "info",
                    msg : "1차업체를 선택하십시요 !"});
                document.getElementById("kGubn").focus();                         
            }else{
                onSearchCommon(`{"pgmid":"VGATE","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}"}`, "gateList");
            }
        }

        //T1업체, 회사구분 변경시 
        else if (name === "kVnt1" || name === "kComp")  {
            
            newSearchs["plntList"] = [];
            newSearchs["kPlnt"] = "";
            setData({ ...newSearchs });

            //공통코드 조회 (공장)
            onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList")
        }
    }
 
  
  
    /**
     * Grid Event 
     * @param {*} e 
    */
    const grdRom4aMain_onCellValueChanged = async (e) => {
        if (e.column.colId === "vnqt") {
            if((e.data.vnqt === "0" || e.data.vnqt === "")){
                e.node.setDataValue('vnyn', 'N');
                e.node.setSelected(false);
            }
            else {
                e.node.setDataValue('vnyn', 'Y');
                e.node.setSelected(true);
            }   
        }
    }
    
    const grdRom4aMain_onRowClicked = async (e) => {
    }

    //Cell Key Down
    const grdRom4aMain_onCellKeyDown = async (e) => {
    }
    
  
  
    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //T1업체
            {
                "formatter": "select", "name": "kVnt1", "labelName": "T1업체", "type": "text", "defaultValue": idata.kVnt1,  
                "codeMode": true,
                "selectAll": "",
                "codes": null,
                "comms": idata.vnt1List,
                // "comms": (tVnt1 === "M000" ? [{ "comCode": "M000", "text": "" }] : idata.vnt1List),
                "align": "left", 
            }, 
              //회사구분
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": true,
                "selectAll": "",
                "codes": CODES["comp"],
                "comms": null,
                "align": "left", 
            }, 
            //공장
            {
                "formatter": "select", "name": "kPlnt", "labelName": "공장", "type": "text", "defaultValue": idata.kPlnt,  
                "codeMode": false,
                "selectAll": "",
                "codes": null,
                "comms": idata.plntList,
            }, 
            //용기당수량사용여부
            {
                "formatter": "select", "name": "kVnyn", "labelName": "용기당수량　사용여부　", "type": "text", "defaultValue": idata.kVnyn,  
                "codeMode": false,
                "selectAll": "",
                "codes": [ { "text": "전체", "comCode": "1" }, { "text": "사용", "comCode": "2" }, { "text": "미사용", "comCode": "3" }],
                "comms": null,
                "align": "left", 
            },
            {"formatter": "blank", "align": "right" }
        ],
        1:[

            //품번
            {
                "formatter": "input", "name": "kPtno", "labelName": "품번", "type": "text", "desc": "", "readOnly" : false,
                "defaultValue": idata.kPtno,  
                "align": "left", 
                "labelType": "none", 
            }, 
            //사용여부
            {
                "formatter": "select", "name": "kEmgubn", "labelName": "품번 사용여부", "type": "text", "defaultValue": idata.kEmgubn,  
                "codeMode": false,
                "selectAll": "",
                "codes": [ { "text": "전체", "comCode": "1" }, { "text": "사용", "comCode": "2" }, { "text": "미사용", "comCode": "3" }],
                "comms": null,
                "align": "left", 
            }, 
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
        ]
    }
  
    
  
    /**
     * 조회 
     * @param {*} e 
     * @returns 
     */
    const onSearch = async (e) => {
        
        setClearGrid(true); // Detail Grid 초기화

        let query  = "";

        let para = `{"kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}", 
                     "kGate":"${idata.kGate}","kPtno":"${idata.kPtno}","kEmgubn":"${idata.kEmgubn}",
                     "kVnyn":"${idata.kVnyn}"
                    }`;
        let param = encodeURI(para);
        
        query = SERVER_URL + "/rom4a/search?query=" + param;
    
        setSearchApi(query); // Search API
    }


    //deleteBlank
    function deleteBlank(argval){
        if (argval === null || argval === undefined) return "";

        for(var i=argval.length-1 ;i >= 0; i--){
        
            if(argval.charCodeAt(i) !== 12288 && argval.charCodeAt(i) !== 32 ) //charCodeAt(i)  
                return(argval.substring(0,i+1));
        }

        return(argval.length>0?argval.substring(0,0):"")
    }



    /**
     * 인쇄 
     * @param {*} e 
     * @returns 
     */
     const onPrint = async (e) => {
       
        
    }
  
  

    /**
     * 신규 Row추가
     * @param {*} e 
     */
    const onAddRow = async (e) => {
    }

  
      
    /**
     * 차종등록
     * @param {*} e 
     */
    const onInsert = async (e) => {
    }
  
  
  
    /**
     * 수정 
     * @param {*} e 
     * @returns 
     */
    const onUpdate = async (e) => {
        
        let selectedData = grdRom4aMain.current.getSelectedData();

        if (selectedData.length === 0) {
            setMessage({ openAlert : true, 
                        severity : "info",
                        msg : "수정할 데이터를 선택해 주세요."});
            return false;
        }

        const query = SERVER_URL + "/rom4a/update";
        setUpdateApi(query); // Update API
    }
  


    /**
     * 삭제
     * @param {*} e 
     */
    const onDelete = async (e) => {
    }

   
    
    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        // setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        setDlgMessage({ ...dlgMessage, openDlg : false});

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans) 
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //출력
            case "P" :
                
                break;

        }
    }
      
    

    /**
     * Grid Bind Data
     * @returns 
    */
    const onGridBindData = (para) => {

    }


    const editCellClicked = (params) => {

        let gridApi = params.api;
        let rowIdx = params.rowIndex;
        let data = params.data;
        var rowNode2 = gridApi.getDisplayedRowAtIndex(rowIdx);

        if(data.vnyn ==="Y"){
            rowNode2.setDataValue("vnyn", "N");
        }else{
            rowNode2.setDataValue("vnyn", "Y");
        }
    };
 
    const onClick = async (e) => {
        setPanded(!panded);

        if(panded===true){
            setStyle({       
                height :"calc(100vh - 60px - 50px - 45px - 10px)",
              });
        }else{
            setStyle({
                height :"calc(100vh - 60px - 50px - 100px  - 50px)",
              });
        }
    }
     
  
    /**
     *Grid Column 정의 
    */
    //Main Grid Column
    const columnDefs = [
        { headerName: "vt1(H)", field: "vnt1", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true }, 
        { headerName: "comp(H)", field: "comp", sortable: true, filter: true, flex: 1, minWidth: 0, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: true }, 
        { headerName: "공장", field: "plnt", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false }, 
        { headerName: "하치장", field: "gate", sortable: true, filter: true, flex: 1, minWidth: 80, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false }, 
        { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 200, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], valueGetter: Utils.partnoGetter, singleClickEdit: true,  hide: false }, 
        { headerName: "품명", field: "ptnm", sortable: true, filter: true, flex: 1, minWidth: 300, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false}, 
        { headerName: "D0 용기당 수량", field: "qypc", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], cellEditor:"numericEditor", singleClickEdit: true, hide: false}, 
        { headerName: "사용자 지정 용기당 수량", field: "vnqt", sortable: true, filter: true, flex: 1, minWidth: 150, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false}, 
        { headerName: "사용자지정 사용여부", field: "vnyn", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "editCell",onCellClicked: editCellClicked, cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false}, 
    ];

   
    return(
        <>

            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {setMessage({...message, openAlert:false})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

            <Ribbon
                onInfoMsg={"용기당 수량을 입력하시면 자동 Y/N 표시가 됩니다."}
                onSearch={onSearch} onSearchName={null}            
                onUpdate={onUpdate} onUpdateName={null}            
            >
                {"납입지시 > 기초관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>용기당수량관리</b>
            </Ribbon>

            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick = {onClick} />

            <Content style={style}>
                <div className={"grid_type01"} >
                    <div className={"grid_wrap01"}>
                        <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                            <Grid
                                ref={grdRom4aMain}
                                gridId={"ROM4A"}                    //  (필수) Grid Id 정의
                                columnDefs={columnDefs}            //  (필수) 컬럼 정의 
                                pagination={true}                   //  쪽수 매기기 여부
                                paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                                paginationPageSize={"500"}         //  쪽수 1Page 당 Row수
                                rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                                suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                                rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                                enableRangeSelection={false}        //  
                                enableCellTextSelection={true}      // cell selection drag mode
                                isFirst={true}                      // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                                displayNo = {false}
                                searchApi={searchApi}               // 조회
                                updateApi={updateApi}              // 수정
                                onClearApi={onClearApi}             // 초기화
                                clearGrid={clearGrid}
                                onClearGridFlag={setClearGrid}
                                onCellValueChangedEvent={grdRom4aMain_onCellValueChanged}
                                onRowClickEvent={grdRom4aMain_onRowClicked}
                            />
                        </div>
                    </div>
                </div>
            </Content>
        </>
    )
  }
  export default ROM4A;