/**
 * @Page WrapStartLabelPrint
 * @Description  [ROM84]납입지시 - 시작차 입고현황 - 부품식별표 :: [시작차] 부품식별표 인쇄  ::  A 4매
 **/
import React from 'react';
import * as Common from '@/utils/Common';
import styled from 'styled-components';

class WrapStartLabelPrint extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			splx: '0',
			sply: '0',
		};
	}

	componentDidMount() {
		const info_splx = Common.getToken('splx') === null ? 0 : 8 + parseInt(Common.getToken('splx'), 10) * 2; // 로그인한 사용자의 시작차식별표 의 x좌표값 받아오기
		const info_sply = Common.getToken('sply') === null ? 0 : 6 + parseInt(Common.getToken('sply'), 10) * 2; // 로그인한 사용자의 시작차식별표 의 y좌표값 받아오기
		this.setState({ splx: info_splx });
		this.setState({ sply: info_sply });
	}

	render() {
		// Print Style CSS Setting
		const PrintStyleStartLabel = styled.div`
			@media print {
				@page {
					size: A4 landscape;
					margin-top: ${this.state.sply}mm;
					margin-left: ${this.state.splx}mm;
					margin-right: 8mm;
					margin-bottom: 8mm;
				}

				body {
					background-color: ;
					margin: 0px;
					padding: 0px;
					font-family: 'HY견고딕';
				}
				div {
					background-color: ;
					margin: 0px;
					padding: 0px;
					box-sizing: border-box;
				}
				table {
					border-collapse: collapse;
					border-style: none;
					margin: 0px;
					padding: 0px;
					box-sizing: border-box;
				}
				tr {
					width: 100%;
					margin: 0px;
					padding: 0px;
				}
				td {
					margin: 0px;
					padding: 0px;
					background-color: white;
					font-family: 'HY견고딕';
					border: 0px solid green;
					box-sizing: border-box;
				}

				.gulim12 {
					font-family: 'NanumGothic', '굴림', Gulim;
					font-size: 12pt;
				}

				.gulim10 {
					font-family: 'NanumGothic', '굴림', Gulim;
					font-size: 10pt;
				}
				.HYheadM20 {
					font-family: 'HY견고딕';
					font-size: 20pt;
					word-break: break-all;
				}
				.HYheadM10 {
					font-family: 'HY헤드라인M';
					font-size: 10pt;
					word-break: break-all;
				}
				.HYheadM12 {
					font-family: 'HY견고딕';
					font-size: 20pt;
				}
				.HYheadM14 {
					font-family: 'HY견고딕';
					font-size: 16pt;
				}

				div.pagebreak {
					break-after: always !important;
					page-break-after: always !important;
					page-break-inside: avoid !important;
				}
			}

			@media screen {
				body {
					background-color: ;
					margin: 0;
					padding: 0;
				}
				div {
					background-color: ;
					margin: 0;
					padding: 0;
				}
				table {
					border-collapse: collapse;
					border-style: none;
					margin: 0;
					padding: 0;
				}
				td {
					background-color: white;
				}
				.gulim12 {
					font-family: 'NanumGothic', '굴림', Gulim;
					font-size: 12pt;
				}

				.gulim10 {
					font-family: 'NanumGothic', '굴림', Gulim;
					font-size: 10pt;
				}
				.HYheadM20 {
					font-family: 'HY견고딕';
					font-size: 20pt;
					word-break: break-all;
				}
				.HYheadM10 {
					font-family: 'HY헤드라인M';
					font-size: 10pt;
					word-break: break-all;
				}
				.HYheadM12 {
					font-family: 'HY견고딕';
					font-size: 20pt;
				}
				.HYheadM14 {
					font-family: 'HY견고딕';
					font-size: 16pt;
				}
			}
		`;

		const { printData } = this.props;

		// 검색조건 파라메터 가져오기
		const { kStart, kNpdt } = this.props.idata;
		const listSize = printData.length; // data length

		let idx = -1; // -1 부터 start
		let str = parseInt(kStart) - 1;

		idx = idx + str; // 시작 위치 (like 배열)

		// 순서 표출용 - vseq
		let vseq = 0;

		// remain 표출 체크용 - remainChk -- 신규 품번의 vseq 담기
		let remainChk = 0;

		const cardInfo = printData
			? printData.map((data, index) => {
					if (index === 0) {
						vseq = 0; // 바코드 표출 vseq용
					}

					remainChk = vseq; // 새 품번의 remain 여부 체크하기

					let npqt = parseInt(data.NPQT); // 납품수량
					let qypc = parseInt(data.QYPC); // 용기당 수
					let stck = parseInt(data.STCK); // 부착면 수

					let result = Math.floor(npqt / qypc); // 몫
					let resultIdx = Math.floor(npqt / qypc); // 목 + 나머지의 카운트
					let remain = npqt % qypc; // 나머지

					if (remain > 0) {
						resultIdx = result + 1;
					}

					const newSearched = []; // 새 프린트용 배열
					let n = 0;

					Array.from(new Array(resultIdx), (i) => {
						// 메인 출력물

						Array.from(new Array(stck), (j) => {
							// 부착면수 만큼 선 반복..

							// 시작시 idx 셋팅
							idx = idx + 1;

							// v seq count 셋팅
							if ((idx - str) % stck === 0) {
								vseq = vseq + 1; // 새 vseq 마다 + 1 증가
							}

							let iCnt = i + 1;
							const newArray = {}; // new Array 선언

							let jCnt = j + 1;

							// 새 배열의 n 번째 구하기
							if (i === 0 && j === 0) {
								n = 0;
							} else {
								n = n + 1;
							}

							// newArray["idx"] = n;
							newArray['idx'] = idx;
							newArray['vseq'] = vseq;

							//납품수량
							let newQty = 0;
							newArray['qty'] = qypc;
							newQty = qypc;

							newArray['stck'] = stck;
							newArray['firstpage'] = 'F';
							newArray['endpage'] = 'F';

							// 첫번째 Page 구하기
							if (i === 0 && j === 0 && index === 0) {
								newArray['firstpage'] = 'T';
							}

							// 나머지 표출하기  - 수정
							// remain 표출 체크용 - remainChk -- 신규 품번의 vseq 담기
							if (resultIdx === vseq - remainChk) {
								if (remain > 0) {
									newArray['qty'] = remain;
									newQty = remain;
								}
							}

							// END Page 구하기
							if (listSize === index + 1) {
								if (resultIdx === iCnt) {
									if (stck === jCnt) {
										newArray['endpage'] = 'T';
									}
								}
							}

							// 자기 위치 셋팅 (출력매수)
							const posi = idx % 4;
							newArray['posi'] = posi;
							let divCss = '';

							// page별  (출력매수)1장 이상일경우 100% 씩 추가 ... 다음장 노출을 위함.
							let pagebreaky = Math.floor(idx / 4) * 100;

							if (posi === 0) {
								divCss = { position: 'absolute', top: pagebreaky + '%', left: '0mm', padding: '2mm', border: '0px solid red', zIndex: '20' };
							} else if (posi === 1) {
								divCss = { position: 'absolute', top: pagebreaky + '%', left: '142mm', padding: '2mm', border: '0px solid red', zIndex: '20' };
							} else if (posi === 2) {
								divCss = { position: 'absolute', top: pagebreaky + 49.6 + '%', left: '0mm', padding: '4mm 2mm 2mm 2mm', border: '0px solid red', zIndex: '20' };
							} else if (posi === 3) {
								divCss = { position: 'absolute', top: pagebreaky + 49.6 + '%', left: '142mm', padding: '4mm 2mm 2mm 2mm', border: '0px solid red', zIndex: '20' };
							}
							newArray['divCss'] = divCss;
							newSearched[n] = newArray;
						});
					});

					return (
						<>
							{newSearched.map(
								(sData, dataIdx) => (
									<form key={'WrapStartLabelPrint' + dataIdx}>
										<div style={sData.divCss}>
											<table style={{ width: '140mm', height: '94mm', tableLayout: 'fixed', zIndex: '20' }}>
												<colgroup>
													<col width="10%" />
													<col width="2%" />
													<col width="23%" />
													<col width="10%" />
													<col width="20%" />
													<col width="7%" />
													<col width="5%" />
													<col width="*" />
												</colgroup>
												<tbody>
													<tr style={{ height: '8mm' }}>
														<td colSpan="3"></td>
														<td></td>
														<td></td>
														<td colSpan="3" className="gulim12" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '2px' }}>
															현대/기아자동차
														</td>
													</tr>
													<tr style={{ height: '8mm' }}>
														<td></td>
														<td colSpan="2" className="gulim12" style={{ textAlign: 'left' }}>
															{data.PLNT}
														</td>
														<td colSpan="4" style={{ textAlign: 'left' }}>
															{data.CDSC}
														</td>
														<td className="gulim12" style={{ textAlign: 'left', paddingLeft: '5px' }}>
															시작동하역장
														</td>
													</tr>
													<tr style={{ height: '14mm' }}>
														<td></td>
														<td colSpan="5" className="HYheadM12" style={{ textAlign: 'left', paddingLeft: '8px', whiteSpace: 'nowrap' }}>
															{data.PTNO.substr(0, 5) + '-' + data.PTNO.substr(5)}
															{/* 품번 */}
														</td>
														<td colSpan="2" className="HYheadM12" style={{ textAlign: 'right', paddingRight: '25px' }}>
															{sData.qty}
														</td>
														{/* 수량 */}
													</tr>
													<tr style={{ height: '16mm' }}>
														<td></td>
														<td colSpan="7" className="HYheadM14" style={{ textAlign: 'left', paddingLeft: '8px' }}>
															{data.PTNM}
														</td>
														{/* 품명 */}
													</tr>
													<tr style={{ height: '20mm' }}>
														<td
															colSpan="8"
															className={Common.getTextLength(data.OPTN) > 24 ? 'HYheadM10' : 'HYheadM20'}
															style={{ textAlign: 'center', paddingTop: '8px', paddingRight: '20px' }}
														>
															{data.OPTN}
														</td>
														{/* 사양 */}
													</tr>
													<tr style={{ height: '19mm' }}>
														<td colSpan="2"></td>
														<td className="gulim12" style={{ textAlign: 'center' }}>
															{kNpdt.replace(/-/g, '.')}
														</td>
														<td></td>
														<td colSpan="4" className="gulim12">
															{' ' + data.VAND + ' ' + data.PLNM}
														</td>
													</tr>
													<tr style={{ height: '9mm' }}>
														<td colSpan="8"></td>
													</tr>
												</tbody>
											</table>
										</div>

										{sData.posi === 3 && sData.endpage === 'F' ? (
											// (출력매수-1)
											<div key={'pagebreak' + sData.idx} style={{ background: 'red', breakAfter: 'page', pageBreakAfter: 'always', pageBreakInside: 'avoid' }}></div>
										) : (
											''
										)}
									</form>
								),
								'',
							)}
						</>
					);

					// 최초 1 close - 품번 별 row  -- END
			  })
			: '';

		return <PrintStyleStartLabel key={'WrapStartLabelPrint'}>{cardInfo}</PrintStyleStartLabel>;
	}
}
export default WrapStartLabelPrint;
