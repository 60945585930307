import React, { Component, useState } from 'react';
// import { Modal, Box } from "@material-ui/core";
import Ribbon from '@/components/organisms/Ribbon';
// import ContentXearch from '@/components/organisms/ContentXearch';
// import Content from '@/components/organisms/Content';
// import Grid from '@/components/grid/agGrid';
import CustomAlert from "@/components/molecules/CustomAlert";
import AlertDialog from '@/components/material-UI/AlertDialog';
import * as Common from '@/utils/Common'; 
// import CommonUtil from '@/utils/CommonUtil'; 
// import { stringToArray } from 'ag-grid-community';
// import Popup from '@/pages/Popup/Popup';     
// import QNA from '@/pages/NOTICE/QNA';     
// import {Checkbox} from '@material-ui/core';


import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';
// import { EditorState } from 'draft-js';
import { EditorState, convertToRaw, ContentState } from "draft-js";
// import { EditorState, convertToRaw, getCurrentContent } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
// const CODES = require('@/config/commonCode.json');
// const Utils = new CommonUtil();

const MyBlock = styled.div`
    .wrapper-class{
        width: 97%;
        margin: 0 auto;
        margin-bottom: 4rem;
    }
    .editor-class {
        height: 500px !important;
        border: 1px solid #b8b8b8 !important;
        padding: 5px !important;
        border-radius: 2px !important;
    }
    .toolbar-class {      
        border: 1px solid #f1f1f1 !important;  
    }
    
    .editor-n-class {
        height: 600px !important;
        border: 1px solid #b8b8b8 !important;
        padding: 5px !important;
        border-radius: 2px !important;
    }
    .toolbar-n-class {      
        border: 1px solid #f1f1f1 !important; 
        display: none; 
    }
`;
    
class NoteTopPopup extends Component {

    constructor(props)  {

        super(props);
        //this.grdCarTypeMain = React.createRef();
        this.state = {
            //Common
            token:Common.getToken(),
            userId :Common.getToken("id"),
            userName : Common.getToken("name"),
            tVnt1 : Common.getToken("vand"),
            tComp : Common.getToken("comp"),
            readOnly: true,
            //checked: true,
            gubn : props.data.gubn,
            numb : props.data.numb != " " ? props.data.numb : props.data.num,
            gubn2 : props.data.gubn2,
            mode : "",
            idat:Common.getToday(),// 날짜
            cont:"",//내용
            close:"",
            check:"N",
            content:"",
            //Alert 창 ( Alert 창 OPEN,  Alert 창 구분, Alert 창 메세지)
            openAlert : false, severity : "success", msg : "",
    
            //Dialog 창 (Dialog 창 OPEN, Dialog Transaction(I,U,D),  Dialog 메세지)
            openDlg : false, dlgTrans : "", dlgMsg : "", 
    
            //Api (조회, 등록, 수정, 삭제)
            searchApi : false, insertApi : false, updateApi : false, deleteApi : false,

            loadingFlag : false,

            data : [], setDatas : [],
            typeOpen : false,setTypeOpen : false, setModalOpen : false,onClose:"",
            editorState: EditorState.createEmpty() // 에디터 용
        }
        //this.onCheck2 = this.onCheck2.bind(this);   

    }

 
   
    /**
     * WillMount
     */
    async componentWillMount() {
        //Loading Flag
        this.setState({loadingFlag : true}); 
    }

    

    /**
     * DidMount (바로조회)
     */
    componentDidMount() {
        if(this.state.gubn==="search"){
            let para = `{"numb":"${this.state.numb}","num":"${this.state.numb}"}`;//조회수 업데이트-> num , 내용조회-> numb
                let param = encodeURIComponent(para);

                const query = SERVER_URL + "/note/top/search2?query=" + param;

                const fetchSearchData = async (query) => {
                    let inputData = this.state; 
                    await fetch(query,  { headers: { Authorization: this.state.token } })
                        .then((resp) => resp.json())
                        .then((data) => {
                            if (data.success) {
                                inputData["cont"] = data.data[0].cont; //내용
                                inputData["titl"] = data.data[0].titl; //제목
                                inputData["gubn2"] = data.data[0].gubn2; //구분

                                //에디터에 값 추가 
                                const blocksFromHtml = htmlToDraft(data.data[0].cont);
                                // const blocksFromHtml = htmlToDraft(`${data.data[0].cont}`);

                                if (blocksFromHtml) { 
                                    const {contentBlocks, entityMap} = blocksFromHtml; 
                                    // https://draftjs.org/docs/api-reference-content-state/#createfromblockarray 
                                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap); 
                                    // ContentState를 EditorState기반으로 새 개체를 반환. 
                                    // https://draftjs.org/docs/api-reference-editor-state/#createwithcontent 
                                    const editorState = EditorState.createWithContent(contentState); 

                                    // const contentBlock = htmlToDraft(blocksFromHtml);
                                    // const editorState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

                                    //setEditorState(editorState); // <== 이부분 
                                    inputData["editorState"] = editorState;
                                }


                                this.setState({ ...inputData });
                            }
                            if (data.error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "조회 실패"});
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                };
                fetchSearchData(query);
                
                // if(data.data[0].gubn2==="Y"){
                //  this.setState(prevState => ({checked: true}));    
                // }else{
                //     this.setState(prevState => ({checked: false}));
                // }
        }
    }


    // /**
    //  * WillReceiveProps
    //  */
    // componentWillReceiveProps () {
    // }

    // /**
    //  * WillUpdate
    //  * @param {*} nextProps 
    //  * @param {*} nextState 
    //  */
    // componentWillUpdate(nextProps, nextState) {
    // }

    // /**
    //  * DidUpdate
    //  * @param {*} prevProps 
    //  * @param {*} prevState 
    //  */
    // componentDidUpdate(prevProps, prevState) {
    // }

    // /**
    //  * WillUnmount
    //  */
    // componentWillUnmount() {
    // }


    /**
     * 공통코드 조회
    */
     onSearchCommon = () => {
    }
   
    // 에디터 값 변경 시 
    onEditorStateChange = (editorState) => {
        let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));


        let newSearchs = this.state;
        newSearchs["editorState"] = editorState;
        newSearchs["cont"] = value;
        this.setState({ ...newSearchs });
    }


    render() {
        this.state.gubn2 === "Y" ? this.defaultProps = {checked: true} :this.defaultProps = {checked: false}
//        this.defaultProps = {checked: false}
        if (this.state.loadingFlag === false) {
            return null
        }
        else {
            // const [typeOpen, setTypeOpen] = React.useState(false);
            // const [data, setDatas] = useState([]);
            //State

            const {token, userId, userName, tVnt1, tComp, kDelGubn, mode,uid,numb,close,
                   openAlert, severity, msg,idat,titl,vdnm,cont,usnm,vand,iusa,check,
                   openDlg, dlgTrans, dlgMsg, compList, searchApi, insertApi, updateApi, deleteApi,
                   data,setDatas,typeOpen,setModalOpen,open,setOpen,onClose,content
                 } = this.state;


            /**
             * Control 변경 Event
             * @param {*} e 
             */
            const onChange = async (e) => {
                const { name, value } = e.target;

                let newSearchs = this.state;
                newSearchs[name] = value
                this.setState({ ...newSearchs });
            }

        
            /**
             * 검색조건
            */
            const optionsX = {
                
            }


        
            /**
             * 조회
             * @param {*} e 
             */
            const onSearch = async (e) => { 
                //업체코드 입력 Check
               
            
                let para = `{"numb":"${numb}"}`;
                let param = encodeURIComponent(para);

                const query = SERVER_URL + "/note/top/search2?query=" + param;

                const fetchSearchData = async (query) => {
                    let inputData = this.state; 
                    await fetch(query,  { headers: { Authorization: token } })
                        .then((resp) => resp.json())
                        .then((data) => {

                            if (data.success) {
                                inputData["cont"] = data.data[0].cont; //이름
                                inputData["titl"] = data.data[0].titl; //제목
                                this.setState({ ...inputData });
                                replace(data.data[0].cont);
                                
                            }
                            if (data.error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "조회 실패"});
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                };
                fetchSearchData(query);
                
            }



            /**
             * 신규 Row추가
             * @param {*} e 
             */
            const onAddRow = async (e) => {
            }



            /**
             * 입력
             * @param {*} e 
             */
            const onInsert = async (e) => {
                const fetchUpdateData = async () => {

                    const query = SERVER_URL + "/note/top/insert"; 
                    let param = JSON.stringify({
                        iusa : userId,
                        idat : idat,
                        titl : titl,
                        cont : cont,
                        gubn2 : this.state.gubn2
                    });

                    await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })                        
                        .then((resp) => resp.json())
                        .then((data) => {

                            if (data.success) {
                                this.setState({ openAlert : true, 
                                                severity : "info",
                                                msg : data.count + "건 작업을 수행하였습니다. "});
                            }
                            else if (data.errors) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.errors.message});

                            }
                            else if (data.error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.message});
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                };
                fetchUpdateData();                

                setTimeout(() => this.props.onClose(), 1000); //refresh onClose 호출
            }
                
         
            /**
             * 수정 
             * @param {*} e 
             * @returns 
             */
            const onUpdate = async (e) => {

                let param = JSON.stringify({
                   
                });
                const fetchUpdateData = async () => {

                    const query = SERVER_URL + "/note/top/update"; 
                    let param = JSON.stringify({
                        titl : titl,
                        numb : numb,
                        cont : cont,
                        gubn2 : this.state.gubn2
                    });

                    await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })                        
                        .then((resp) => resp.json())
                        .then((data) => {
                            if (data.success) {
                                this.setState({ openAlert : true, 
                                                severity : "info",
                                                msg : data.count + "건 작업을 수행하였습니다. "});
                            }
                            else if (data.errors) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.errors.message});

                            }
                            else if (data.error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.message});
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                };
                fetchUpdateData();
            }



            /**
             * 삭제
             * @param {*} e 
             */
            const onDelete = async (e) => {

                const fetchData = async () => {

                    const query = SERVER_URL + "/note/top/delete"; 
                    let param = JSON.stringify({
                        numb : numb,
                    });

                    await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })                        
                        .then((resp) => resp.json())
                        .then((data) => {
                            if (data.success) {
                                this.setState({ openAlert : true, 
                                                severity : "info",
                                                msg : data.count + "건 작업을 수행하였습니다. "});
                            }
                            else if (data.errors) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.errors.message});

                            }
                            else if (data.error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : data.message});
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {
                                this.setState({ openAlert : true, 
                                                severity : "error",
                                                msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                };
        
                fetchData();

            }



            //deleteBlank
            function deleteBlank(argval){

            }



            // input check
            const inputCheck = () => {
 
            }
            

            //AlertDialogResult
            const AlertDialogResult = async (e) => {

                //AlertDialog 초기화
                this.setState({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        
                //DialogResult
                if (e.result == "N") return;
        
                //Transaction 구분
                switch (e.Trans) 
                {
                    //등록
                    case "I" :
                        break;
                    //수정
                    case "U" :
                        break;
                    //삭제
                    case "D" :
                        break;
                }
            }

        

            //Grid DropDownList Value
            const extractValues = (mappings) => {
                return  Object.keys(mappings);
            }

            //Grid DropDownList Display
            const lookupValue = (mappings, key) => {
                return mappings[key];
            }        
            
            const onCheck = async (e) => {
                 if(e.target.checked === true){
                    //수정모드
                    this.state.mode = "1";
                    this.setState(prevState => ({readOnly: false}))
                }else{
                    this.state.mode = "";   
                    this.setState(prevState => ({readOnly: true}))
                }
            }

            const onCheck2 = async (e) => {
                if(e.target.checked === true){
                   //상단
                   this.state.gubn2 = "Y";
                   e.target.checked = true;
               }else{
                   this.state.gubn2 = "N"; 
                   //e.target.checked = false;  
               }
           }

           const urlRegex = /(https?:\/\/[^\s]+)/g;

           const replace = (content) => {
            
            const convertContent = content.replace(urlRegex, function (url) {
              return '<a href="' + url + '" target="_blank">' + url + '</a>';
            })
        
            const htmlArr = [];
            convertContent.split('\n').forEach(function (text) {
              const textHtml = "<p>" + text + "</p>";
              htmlArr.push(textHtml)
            })
        
            return {__html: htmlArr};
          }

            /**
             *Grid Column 정의 
            */
            const columnDefs = [
            ];

            return (
                <>
                    {/* Dialog FRAME */}
                    <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

                    {/* Alert FRAME */}
                    <CustomAlert open={openAlert} severity={severity} duration={3000} message={msg} onClose={()=> {this.setState({openAlert:false, severity:"", msg:""})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
                    <button onClick={() => {this.props.onClose()}} style = {{border:"none", float:"right"}}> <img src="/images/button/close_button.png" alt="logout" /></button> 
                    
                    {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
                    <Ribbon
                        newTabUnused={true}                         // newTabUnused : newTab 표출 버튼 true : 사용안함 // 선언안할경우 default = newTab 사용
                        onInfoManual={false}                        // onInfoManual : 매뉴얼 표출 버튼 무조건 사용 // false : 사용안함 (popup 의경우 false 셋팅)
                        onSearch={onSearch} onSearchName={null}     //조회
                        onInsert={onInsert}  onInsertVisible = {tVnt1 === "M000" && this.state.gubn === "write"} 
                        onUpdate={onUpdate} onUpdateName={"수정"} onUpdateVisible = {tVnt1 === "M000" && this.state.gubn === "search"} 
                        onDelete={onDelete} onDeleteName={null}    onDeleteVisible = {tVnt1 === "M000" && this.state.gubn === "search"}   //삭제
                    >
    
                    <b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>질문과 답변</b>
                    </Ribbon>
        
                    {/* ContentXearch Frame : 검색조건 */}
                    {/* <ContentXearch
                        optionsX={optionsX}
                        onChange={onChange} /> */}
                     <div style={{ height: "100px",width:"100%", paddingTop: "10px", background:" rgb(241, 243, 247)",marginTop: "8px",borderTop: "1px solid #cecdd9",borderBottom: "1px solid #cecdd9" }}> {/*Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:100px  */}
                        
                        <div className={"grid_type06"} style= {{width:"100%",minWidth: "600px"}}>{/* 우측 FRAME */}
                        <div className="grid_wrap06" style={{height: "15%"}} > {/* 우측 wrap FRAME */}
                            <div className="grid_area" style={{ height: "13%", border: "0px solid #babfc7", width:"100%" }}> {/* Grid area */}
    
                                <table  style={{ width: "100%" }}>
                                    <colgroup>
                                        <col width="10%" />
                                        <col width="22.5%" />
                                        <col width="22.5%" />
                                        <col width="22.5%" />
                                        <col width="22.5%" />
                                    </colgroup>
                                    <tbody>   
                                    <tr style={{ height:"28px",border:"0px"}}>
                                        <td style={{border:"0px",background: "#F1F3F7",textAlign:"Right", whiteSpace:"nowrap"}}>작성자 : </td>            
                                        <td colSpan = "2" style={{border:"0px",background: "#F1F3F7"}}>
                                        <input name="uid" onChange={onChange} style={{width:"319px",height:"26px",marginLeft:"15px",border:"1px solid #b8b8b8",padding: "5px"}} value={userId+" "+userName} readOnly/> 
                                        </td>
                                        <td colSpan = "2" style={{border:"0px",background: "#F1F3F7"}}>
                                        {userId === "M000001" ?//관리자만 수정모드,상단위치 체크 가능
                                        this.state.gubn === "search"?
                                        <>
                                        {/* <input type="checkbox" id="mode" name="mode" value="1" onChange={onCheck} style={{ marginLeft: "15px" }} />
                                            <label htmlFor="mode"> 수정모드</label> */}
                                        <input type="checkbox" id="gubn2" name="gubn2"  onChange={onCheck2} style={{ marginLeft: "15px" }} defaultChecked = {this.state.gubn2 === "Y" ? true:false} />{/*checked ={this.state.gubn2 === "Y" ? true:false}*/}
                                            <label htmlFor="gubn2"> <b>긴급공지</b> </label>        
                                        </>
                                        :
                                        <>
                                            
                                        <input type="checkbox" id="gubn2" name="gubn2" value="Y" onChange={onCheck2} style={{ marginLeft: "15px" }} />
                                        <label htmlFor="gubn2"> <b>긴급공지</b> </label>        
                                        </>
                                        :""}
                                        </td>
                                    </tr>
                                    <tr style={{ height:"10px",border:"0px"}}></tr>
                                    <tr style={{ height:"28px",border:"0px",background: "#F1F3F7"}}>   
                                        <td className="k_upload" style={{verticalAlign: "middle",border:"0px",background: "#F1F3F7",textAlign:"Right", whiteSpace:"nowrap"}}><b>제목 : </b></td>
                                        <td colSpan = "4" style={{border:"0px",background: "#F1F3F7"}}>
                                        <input name="titl" onChange={onChange}  style={{width:"519px",height:"26px",marginLeft:"15px",border:"1px solid #b8b8b8",padding: "5px"}} value = {titl} readOnly={userId === "M000001"?false:true}
                                               placeholder="상단 공지 제목을 작성해주세요."/> 
                                        </td>
                                        
                                    </tr>
                                    </tbody>
                                </table>   {/*<!-- DO 테이블 END --> */}
                            </div>
                        </div>

                        <table  style={{ width: "100%",marginTop: "15px" ,borderTop: "1px solid #cecdd9"}}>
                                    <colgroup>
                                        <col width="10%" />
                                        <col width="90%" />
                                    </colgroup>
                                    <tbody>
                                    <tr style={{ height:"500px",border:"0px"}}>
                                        <td style={{border:"0px",textAlign:"Right",verticalAlign: "top",whiteSpace:"nowrap",paddingTop:"15px"}}>&nbsp;&nbsp;내용 : </td>            
                                        <td  style={{border:"0px"}}>
                                            {/* {this.state.gubn === "search"?
                                             //row클릭으로 들어오면 수정모드를 선택해야 수정가능
                                             <textarea name="cont" id = "cont" onChange={onChange} readOnly={this.state.readOnly}
                                                style={{width:"840px",height:"650px",padding:"5px 5px 5px 5px",marginLeft:"12px",border:"0px solid #b8b8b8",fontSize: "13px"}} value = {this.state.cont}/> 
                                            ://입력버튼으로 들어오면 바로 작성할수있음
                                            <textarea name="cont" id = "cont" onChange={replace} 
                                                style={{width:"840px",height:"650px",padding:"5px 5px 5px 5px",marginLeft:"12px",border:"0px solid #b8b8b8",fontSize: "13px"}} value = {this.state.cont}>
                                                // <div onChange={replace}></div>
                                                </textarea>
                                            } */}

                                            <MyBlock>

                                                <Editor                                                    
                                                    wrapperClassName="wrapper-class"    // 에디터와 툴바 모두에 적용되는 클래스
                                                    editorState={this.state.editorState}// 초기값 설정
                                                    editorClassName={userId === "M000001"?"editor-class":"editor-n-class"}  // 에디터 주변에 적용된 클래스 "editor-class"
                                                    toolbarClassName={userId === "M000001"?"toolbar-class":"toolbar-n-class"} // 툴바 주위에 적용된 클래스 "toolbar-class"
                                                    
                                                    // toolbar={{
                                                    //     // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
                                                    //     list: { inDropdown: true },
                                                    //     textAlign: { inDropdown: true },
                                                    //     link: { inDropdown: true },
                                                    //     history: { inDropdown: false },
                                                    // }}  // 툴바 설정

                                                    //placeholder="내용을 작성해주세요."
                                                    placeholder="※ 내용은 없어도 됩니다. 입력/수정 후 반영확인은 좌측상단 Rapdos 로고를 클릭 또는, 좌측 하위메뉴를 열어서 확인 가능합니다."
                                                                                                        
                                                    localization={{
                                                        locale: 'ko',
                                                    }}  // 한국어 설정

                                                    onEditorStateChange={this.onEditorStateChange}  // 에디터의 값이 변경될 때마다 onEditorStateChange 호출
                                                    readOnly={userId === "M000001"?false:true} //관리자만 수정모드 가능
                                                />

                                            </MyBlock>

                                        </td>
                                    </tr>     
                                    </tbody>
                        </table>   {/*<!-- DO 테이블 END --> */}
                    </div>
    
                    
                    </div>
                </>
            );
        }
        
    }

}


export default NoteTopPopup;