import React from "react";
// import { Link, withRouter } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import '../../css/main.css';

import "../../css/common.css";
import "../../css/style.css";
import "../../css/main_style.css";
import "../../css/maindash.css";
import "../../css/table.css";
// import sty from '../css/main.css';

import Buttonwrapper from "../../components/organisms/Buttonwrapper";

function GridRibbon({
    children,
    title,
    tooltip,
    onSearch,
    onSearchName,
    onInsert,
    onInsertName,
    onUpdate,
    onUpdateName,
    onDelete,
    onDeleteName,
    onExcel,
    onExcelName,
    onUpload,
    onUploadName,
    onPrint,
    onPrintName,
    onPrintVisible,
    onFunction,
    onFunctionName,
    onFunctionType,
    onFunction2,
    onFunctionName2,
    onFunctionType2,
    onAddRow,
    onAddRowVisible,
    onInsertVisible,
    onUpdateVisible,
    onDeleteVisible,
}) {
    return (
        <>
            <div className="grid_tit_box">
                <div className="grid_tit">
                    <p>{title ? title : ""}</p>
                </div>

                <Buttonwrapper
                    onSearch={onSearch}
                    onSearchName={onSearchName}
                    onInsert={onInsert}
                    onInsertName={onInsertName}
                    onUpdate={onUpdate}
                    onUpdateName={onUpdateName}
                    onDelete={onDelete}
                    onDeleteName={onDeleteName}
                    onExcel={onExcel}
                    onExcelName={onExcelName}
                    onPrint={onPrint}
                    onPrintName={onPrintName}
                    onPrintVisible={onPrintVisible}
                    onUpload={onUpload}
                    onUploadName={onUploadName}

                    onFunction={onFunction}
                    onFunctionName={onFunctionName}
                    onFunctionType={onFunctionType}
                    
                    onFunction2={onFunction2}
                    onFunctionName2={onFunctionName2}
                    onFunctionType2={onFunctionType2}

                    onAddRow={onAddRow}

                    onAddRowVisible={onAddRowVisible}
                    onInsertVisible={onInsertVisible}
                    onUpdateVisible = {onUpdateVisible}
                    onDeleteVisible={onDeleteVisible}
                    tooltip={tooltip}
                >
                    {children}
                </Buttonwrapper>
                {/* </div> */}





                {/* <div id="BTs">
                            <button onclick="if(this.className!='btn')return; retrieve('','1')" className="btn" alt='조회'><img src="/images/button/btn_search.png" alt="아이콘" />조회</button>
                            <button onclick="if(this.className!='btn')return;check_vand('IS')" className="btn" alt='입력' src=""><img src="/images/button/btn_insert.png" alt="아이콘" />입력</button>
                            <button onclick="if(this.className!='btn')return;check_vand('UP')" className="btn" alt="수정" src=""><img src="/images/button/btn_update.png" alt="아이콘" />수정</button>
                            <button onclick="if(this.className!='btn')return;check_vand('DL');" className="btn" alt='삭제' src=""><img src="/images/button/btn_delete.png" alt="아이콘" />삭제</button>
                            <button onclick="if(this.className!='btn')return; changePLNT1();callExcel();" className="btn" alt='엑셀' src=""><img src="/images/button/btn_excel.png" alt="아이콘" />엑셀</button>&nbsp;
                        </div> */}
                {/* </div>
                </div> */}

                {/* <Buttonwrapper> */}
                {/* <button href="#">
                        <span className={sty.btn_search}></span><span>조회</span>
                    </button>
                    <button href="#">
                        <span className={sty.btn_insert}></span><span>입력</span>
                    </button>
                    <button href="#">
                        <span className={sty.btn_update}></span><span>수정</span>
                    </button>
                    <button href="#">
                        <span className={sty.btn_delete}></span><span>삭제</span>
                    </button>
                    <button href="#">
                        <span className={sty.btn_excel}></span><span>엑셀</span>
                    </button> */}
                {/* </Buttonwrapper> */}
            </div>
        </>
    );
}

export default GridRibbon;
// export default React.memo(GridRibbon);
