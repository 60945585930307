// import React from "react";
class CommonUtil {
  // VALUE PARSER
  numberParser = (params) => {
    return Number(params.newValue);
  };

  // valueGetter와 valueFormatter는 함께 쓸수 없다. 조회용, csv 다운로드 기능의  그리드라면 Getter를 쓸 것.
  // csv 다운로드 기능을 안 쓴다면 Formatter 쪽을 쓰도록 함

  // VALUE FORMMATER
  partnoFormatter = (params) => {
    return params.value.replace(/(\S{5})(\S*$)/g, "$1-$2");
  };
  dateFormatter = (params) => {
    let val = '';
    if(params.value !== undefined){
      val = params.value.toString().replaceAll('-','')
    }
    return  params.value === undefined ? "" : val.replace(/(\S{4})(\S{2})(\S{2})/g, "$1-$2-$3") 
  };
  dateFormatter2 = (params) => {
    let val = '';
    if(params.value !== undefined){
      val = params.value.toString().replaceAll('-','')
    }
    return  params.value === undefined ? "" : val.replace(/(\S{4})(\S{2})/g, "$1-$2") 
  };

  dateTiemFormatter = (params) => {
    return  params.value === undefined ? "" : params.value.replace(/(\S{4})(\S{2})(\S{2})(\S{2})/g, "$1-$2-$3 ($4시)") 
  };

  dateTiemFormatter2 = (params) => {
    return  params.value === undefined ? "" : params.value.replace(/(\S{4})(\S{2})(\S{2})(\S{2})(\S{2})/g, "$1-$2-$3 $4:$5") 
  };

  monthFormatter = (params) => {
    return params.value.replace(/(\S{4})(\S{2})/g, "$1-$2");
  };
  numberFormatter = (number) => {
    return Math.floor(number.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  numberFormatterFixed1 = (number) => {
    if (number.value === "" || number.value  === null) return ""
     //Math.floor(number.value).toFixed(1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
     return parseFloat(number.value).toFixed(1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  numberFormatterFixed2 = (number) => {
    if (number.value === "" || number.value  === null) return ""

    //return Math.floor(number.value).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return parseFloat(number.value).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  numberFormatterFixed3 = (number) => {
    if (number.value === "" || number.value  === null) return ""
    //return Math.floor(number.value).toFixed(3).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return parseFloat(number.value).toFixed(3).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

    
  underLineCellStyle = (params) => {
    let value = "";
    value = params.data[params.colDef.field];

      if (value !== "0" && value !== "" && value !== null) {
        return {color: "blue", cursor:"pointer", textDecoration:"underline", textUnderlinePosition:"under"};
      } else {
        return null;
      }
  };

  
  boldCellStyle = (params) => {
    let value = "";
    value = params.data[params.colDef.field];

      if (value !== "0" && value !== "" && value !== null) {
        return {cursor:"pointer",fontWeight: "bold"};
      } else {
        return null;
      }
  };

  // VALUE Getter
  partnoGetter = function (params) {
    let partnoValue = "";
    partnoValue = params.data[params.colDef.field];

    if(partnoValue.length > 5){
      return partnoValue.replace(/((?:\S| ){5})(.*)/g, "$1-$2");
    }else{      
      return partnoValue;
    }
    
  };

  dateGetter = function (params) {
    
    
    let dateValue = "";

    dateValue = params.data[params.colDef.field];
    
    
    return dateValue.replace(/(\S{4})(\S{2})(\S{2})/g, "$1-$2-$3") 
    
  };

  cellValueChanged = function (params) {
    if (!params.node.selected) {
      params.node.setSelected(true);
    }
  };
}
export default CommonUtil;

